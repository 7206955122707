// TODO VNX 2023-03-14: remove this in the future, temp
import React from 'react';
import styled from '@emotion/styled';
import { Icon, Switch, Tooltip } from 'antd';
import OrganisationApp from '../../../../../store/types/organisation-app';
import DeviceTypeEnum from '../../../../../store/types/device-type.enum';

interface TemporarySettingsVersionSwitcherProps {
  value: boolean;
  onValueChange: (value: boolean) => void;
  loading: boolean;
  isEditAllowed: boolean;
}

export const canUserSwitchSettingsV2 = (app: OrganisationApp | null) =>
  !!app
    && [DeviceTypeEnum.WPA, DeviceTypeEnum.IOT].includes(app.deviceType)
    && !app.spaceSettingsOverriding
    && !app.deviceSettingsOverriding;

export default function TemporarySettingsVersionSwitcher({
  value,
  onValueChange,
  loading,
  isEditAllowed,
}: TemporarySettingsVersionSwitcherProps) {
  if (!isEditAllowed) {
    return null;
  }

  return (
    <NewLayoutSwitchContainer>
      <NewLayoutSwitchTitle>Enable new layout</NewLayoutSwitchTitle>
      <StyledSwitch
        defaultChecked={value}
        onChange={() => onValueChange(!value)}
        loading={loading}
      />
      <Tooltip title="New content page with settigs overriding feature. You can still switch back to legacy layout later as long as you have not modified the settings on the new layout.">
        <NewLayoutSwitchIcon type="info-circle" />
      </Tooltip>
    </NewLayoutSwitchContainer>
  );
}

const StyledSwitch = styled(Switch)`
  display: flex;
`;

const NewLayoutSwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
`;

const NewLayoutSwitchTitle = styled.p`
  margin-right: 8px;
  font-size: 14px;
`;

const NewLayoutSwitchIcon = styled(Icon)`
  margin-left: 8px;
  color: #f0ad4e;
`;
