import InstallationGroup from '../../types/installation-group';
import createKeyedCrudModel, {
  KeyedCrudModel,
} from '../../common/keyed-crud-model/keyed-crud.model';

export type OrganisationInstallationGroupsModel = KeyedCrudModel<
  InstallationGroup,
  { organizationId: string }
>;

const organisationInstallationGroupsModel: OrganisationInstallationGroupsModel = createKeyedCrudModel<
  InstallationGroup,
  { organizationId: string }
>('/api/installation-groups', 'organizationId');

export default organisationInstallationGroupsModel;
