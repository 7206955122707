import { action, Action, Actions, select, Select, thunk, Thunk } from 'easy-peasy';
import { flatten } from 'lodash';
import Injections from '../../injections.interface';
import { ApiError } from '../../../services/api/api-error';
import transformDeviceExpectedAvailability from '../../../utils/transform-device-expected-availability';
import LegacyWindowsDevice from '../../types/legacy-windows-device';

// TO DO: Revisit end-of-support plan for legacy windows devices
// Legacy Windows Devices is already deprecated and will be removed soon (timeline tbd by lead)
// This is a temporary model to support such device as we move to paginated fetch for universal devices and grid-devices api
export interface OrganisationLegacyWindowsDevicesModel {
  data: {
    [key: string]: {
      [id: string]: LegacyWindowsDevice;
    };
  };
  lastUpdated: {
    [key: string]: Date;
  };
  loading: {
    [key: string]: boolean;
  };
  error: {
    [key: string]: ApiError | null;
  };
  values: Select<
    OrganisationLegacyWindowsDevicesModel,
    (organisationId: string) => LegacyWindowsDevice[]
  >;
  ids: Select<
    OrganisationLegacyWindowsDevicesModel,
    (organisationId: string) => string[]
  >;
  loaded: Select<
    OrganisationLegacyWindowsDevicesModel,
    (organisationId: string) => boolean
  >;
  setLoading: Action<
    OrganisationLegacyWindowsDevicesModel,
    { key: string; loading: boolean }
  >;
  setError: Action<
    OrganisationLegacyWindowsDevicesModel,
    { key: string; error: ApiError | null }
  >;
  setData: Action<
    OrganisationLegacyWindowsDevicesModel,
    { key: string; data: LegacyWindowsDevice[] }
  >;
  setLastUpdated: Action<
    OrganisationLegacyWindowsDevicesModel,
    { key: string; data: Date }
  >;
  fetch: Thunk<
    OrganisationLegacyWindowsDevicesModel,
    { organizationId: string; silent?: boolean },
    Injections
  >;
}

const fetchDevices = async (
  urls: string[],
  actions: Actions<OrganisationLegacyWindowsDevicesModel>,
  { key, silent }: { key: string; silent: boolean },
  injections: Injections,
) => {
  if (!silent) {
    actions.setLoading({ key, loading: true });
  }
  actions.setError({ key, error: null });
  try {
    const result = await Promise.all(
      urls.map((url) => injections.apiService.get<LegacyWindowsDevice[]>(url)),
    );
    const data = flatten(result).map(transformDeviceExpectedAvailability);
    actions.setLastUpdated({ key, data: new Date() });
    actions.setData({ key, data });
  } catch (error) {
    actions.setError({ key, error });
  } finally {
    if (!silent) {
      actions.setLoading({ key, loading: false });
    }
  }
};

const organisationLegacyWindowsDevicesModel: OrganisationLegacyWindowsDevicesModel = {
  data: {},
  loading: {},
  error: {},
  lastUpdated: {},
  loaded: select((state) => (organisationId: string) =>
    !!state.data[organisationId] && !state.loading[organisationId],
  ),
  values: select((state) => (organisationId: string) =>
    Object.values(state.data[organisationId] || {}).sort(
      (a, b) => a.status.localeCompare(b.status),
    ),
  ),
  ids: select((state) => (organisationId: string) =>
    Object.keys(state.data[organisationId] || {}),
  ),
  setLoading: action((state, { key, loading }) => {
    state.loading[key] = loading;
  }),
  setError: action((state, { key, error }) => {
    state.error[key] = error;
  }),
  setData: action((state, { key, data }) => {
    state.data[key] = {};
    data.forEach((item) => {
      state.data[key][item.id] = item;
    });
  }),
  setLastUpdated: action((state, { key, data }) => {
    state.lastUpdated[key] = data;
  }),
  fetch: thunk(async (actions, { organizationId, silent = false }, { injections }) => {
    const urls = [`/api/v3/devices?organizationId=${organizationId}`];

    await fetchDevices(urls, actions, { key: organizationId, silent }, injections);
  }),
};

export default organisationLegacyWindowsDevicesModel;
