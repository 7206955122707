import { thunk, Thunk } from 'easy-peasy';
import { OrganizationAccountBalance } from '../../types/organisation';
import createKeyedCrudModel, {
  KeyedCrudModel,
} from '../../common/keyed-crud-model/keyed-crud.model';
import Injections from '../../injections.interface';

const path = '/api/organizations';

export const KEY_ACCOUNT_BALANCE = 'accountBalance';

export type OrganizationAccountBalanceModel = KeyedCrudModel<
  OrganizationAccountBalance,
  { organizationId: string }
> & {
  fetchAccountBalance: Thunk<
    OrganizationAccountBalanceModel,
    { organizationId: string; silent?: boolean },
    Injections
  >;
  addVoucher: Thunk<
    OrganizationAccountBalanceModel,
    { organizationId: string; voucherCode: string },
    Injections
  >;
};

const organizationAccountBalanceModel: OrganizationAccountBalanceModel = {
  ...createKeyedCrudModel<OrganizationAccountBalance, { organizationId: string }>(
    path,
    'organizationId',
  ),
  fetchAccountBalance: thunk(
    async (actions, { organizationId, silent = false }, { injections }) => {
      const key = KEY_ACCOUNT_BALANCE;

      if (!silent) {
        actions.setLoading({ key, loading: true });
      }
      actions.setError({ key, error: null });

      try {
        const data = await injections.apiService.get<OrganizationAccountBalance>(
          `${path}/${organizationId}/account-balance`,
        );

        if (Object.values(data).length) {
          data.id = organizationId; // as item id
        }

        actions.setSingle({ key, data });
      } catch (error) {
        actions.setError({ key, error });
      } finally {
        if (!silent) {
          actions.setLoading({ key, loading: false });
        }
      }
    },
  ),
  addVoucher: thunk(async (actions, { organizationId, voucherCode }, { injections }) => {
    try {
      const data = await injections.apiService.post(
        `${path}/${organizationId}/add-voucher`,
        {
          voucherCode,
        },
      );

      return data;
    } catch (error) {
      throw error;
    }
  }),
};

export default organizationAccountBalanceModel;
