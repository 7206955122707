import React, { useCallback, useMemo, useState } from 'react';
import {
  DataMatrixColumn,
  DataMatrixColumnDataType,
  DataMatrixDataSource,
  GridStyles,
  InteractionType,
} from '@ombori/grid-reports';
import { DataResidencyEnum } from '../../../../../../store/types/organisation';
import CardContainer from '../../card-container';
import DevicesTable from '../devices-table';
import { useParams } from 'react-router';
import { useTenantDevices } from '../../../../use-devices';
import UniversalDevice from '../../../../../../store/types/universal-device';
import getEventType from '../get-event-type';
import {
  useDevicesEventsAndSessionsByDay,
} from '../../../../use-analytics-report';
import { TitleWithSelect } from '../common/data-matrix-common.component';

interface DataMatrixProps {
  tenantId: string;
  dateFrom: string;
  dateTo: string;
  dataResidency: DataResidencyEnum;
  title?: string;
  dataSource: DataMatrixDataSource;
  columns: DataMatrixColumn[];
  gridStyles?: GridStyles;
  isVisibleWithoutData?: boolean;
}

const DevicesDataMatrixLegacy: React.FC<Omit<DataMatrixProps, 'dataSource'>> = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  title = '',
  columns,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const eventType = getEventType(columns);

  const isEventsQueryEnabled = columns.some(
    (column) => column.dataType.type === DataMatrixColumnDataType.Events,
  );

  const isSessionsQueryEnabled = columns.some(
    (column) => column.dataType.type === DataMatrixColumnDataType.Sessions,
  );

  const dataFetchingState = useDevicesEventsAndSessionsByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
    interactionType: InteractionType.Interactive,
    eventType,
    isEventsQueryEnabled,
    isSessionsQueryEnabled,
  });

  const eventsData = useMemo(
    () => (dataFetchingState.data ? dataFetchingState.data.events : []),
    [dataFetchingState],
  );

  const sessionsData = useMemo(
    () => (dataFetchingState.data ? dataFetchingState.data.sessions : []),
    [dataFetchingState],
  );

  const [selectedDevice, setSelectedDevice] = useState<UniversalDevice | undefined>(
    undefined,
  );

  const { spaceId, appId: installationId } = useParams<{
    spaceId?: string;
    appId?: string;
  }>();

  const selectBySpace = (spaceId: string) => (devices: UniversalDevice[]) => {
    return devices.filter((device) => device.spaces.includes(spaceId));
  };

  const selectByInstallation = (installationId: string) => (
    devices: UniversalDevice[],
  ) => {
    return devices.filter((device) => device.appIds.includes(installationId));
  };

  const select = useMemo(() => {
    if (spaceId) {
      return selectBySpace(spaceId);
    }

    if (installationId) {
      return selectByInstallation(installationId);
    }

    return undefined;
  }, [spaceId, installationId]);

  const devicesFetchingState = useTenantDevices({ tenantId, select });

  const { deviceOptions, devices } = useMemo(() => {
    return {
      deviceOptions: devicesFetchingState.data
        ? devicesFetchingState.data.map((device) => {
            return { key: device.id, label: device.deviceName };
          })
        : [],
      devices: devicesFetchingState.data ? devicesFetchingState.data : [],
    };
  }, [devicesFetchingState]);

  const onSelect = useCallback(
    (key: string | undefined) => {
      const selectedDevice =
        key === 'all' || !key ? undefined : devices.find((device) => device.id === key);
      setSelectedDevice(selectedDevice);
    },
    [setSelectedDevice, devices],
  );

  const isLoading = devicesFetchingState.isLoading || dataFetchingState.isLoading;
  const isSuccess = devicesFetchingState.isSuccess && dataFetchingState.isSuccess;
  const isError = devicesFetchingState.isError || dataFetchingState.isError;
  const hasData =
    devices.length > 0 && (eventsData.length > 0 || sessionsData.length > 0);

  return (
    <CardContainer
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      hasData={hasData}
      title={
        devicesFetchingState.isSuccess ? (
          <TitleWithSelect title={title} items={deviceOptions} onSelect={onSelect} />
        ) : (
          title
        )
      }
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {devicesFetchingState.isSuccess && (
        <DevicesTable
          tenantId={tenantId}
          devices={selectedDevice ? [selectedDevice] : devices}
          columns={columns}
          eventsData={eventsData}
          sessionsData={sessionsData}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      )}
    </CardContainer>
  );
};

export default DevicesDataMatrixLegacy;