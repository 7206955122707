import React, { useCallback, useEffect, useRef } from 'react';
import { isString } from 'lodash';
import { Terminal } from 'xterm';
import * as fit from 'xterm/lib/addons/fit/fit';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import 'xterm/dist/xterm.css';
import chalk from 'chalk';
import { RouteComponentProps } from 'react-router';
import * as Sentry from '@sentry/browser';
import DeviceLog from '../../../../../../store/types/device-log';
import PanelCard from '../../../../../common/panel-card/panel-card.component';

Terminal.applyAddon(fit);

const TerminalWrapper = styled.div`
  width: 100%;
  height: 450px;
`;

const browserChalk = new chalk.constructor({ enabled: true, level: 2 });

const terminalOptions = {
  theme: {
    background: '#202639',
  },
};

interface LogsProps extends RouteComponentProps<{ deviceUuid: string }> {
  subscribe: (params: {
    deviceUuid: string;
    onLog: (log: DeviceLog) => void;
  }) => () => void;
}

const colors: { [key: string]: (msg: string) => string } = {
  error: browserChalk.redBright,
  warn: browserChalk.yellow,
};

const Logs = (props: LogsProps) => {
  const {
    subscribe,
    match: {
      params: { deviceUuid },
    },
  } = props;

  const terminal = useRef<Terminal | null>(null);
  const terminalContainer = useRef<HTMLDivElement | null>(null);

  const handleLog = useCallback(
    (log: DeviceLog) => {
      if (terminal.current) {
        const colorMsg = colors[log.level] || ((msg) => msg);
        const timestamp = browserChalk.dim(
          dayjs(log.timestamp).format('YYYY-MM-DD HH:mm:ss'),
        );
        const rawMessage = isString(log.message)
          ? log.message
          : JSON.stringify(log.message);
        const message = colorMsg(rawMessage.trimEnd());
        terminal.current.writeln(`${timestamp} ${message}`);
      }
    },
    [terminal],
  );

  useEffect(() => {
    let unsubscribe = () => {};

    const init = async () => {
      if (terminal.current) {
        try {
          unsubscribe = await subscribe({ deviceUuid, onLog: handleLog });
        } catch (error) {
          Sentry.captureException(error);
          terminal.current.writeln(
            browserChalk.bgRed('Error during log initialization!'),
          );
        }
      }
    };

    terminal.current = new Terminal(terminalOptions);

    if (terminalContainer.current) {
      terminal.current.open(terminalContainer.current);
      // TODO: handle resize
      // @ts-ignore
      terminal.current.fit();
      init();
    }

    return () => {
      if (terminal.current) {
        terminal.current.dispose();
      }
      unsubscribe();
    };
  }, [terminalContainer, deviceUuid, handleLog, subscribe]);

  return (
    <PanelCard>
      <TerminalWrapper ref={terminalContainer} />
    </PanelCard>
  );
};

export default Logs;
