import React, { useMemo, useEffect, forwardRef } from 'react';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import SchemaForm, { SchemaFormRef } from '../../schema-form/schema-form.component';
import useSchemaForm from '../../use-schema-form/use-schema-form';
import Environment from '../../../../store/types/environment';
import Browser from '../../../../store/types/browser';
import Organisation from '../../../../store/types/organisation';
import OrganisationApp from '../../../../store/types/organisation-app';
import DeviceTypeEnum from '../../../../store/types/device-type.enum';
import Spaces from '../../../../store/types/organisation-space';
import MultiSelect from '../../schema-form/widgets/multi-select/multi-select.component';
import UniversalDevice from '../../../../store/types/universal-device';

export interface BrowserFormValues {
  uuid: string;
  displayName: string;
  url: string;
  env: string;
  notes: string;
  isProvisioned: boolean;
}

interface CreateBrowserModalProps {
  onSubmit: (values: BrowserFormValues) => Promise<void>;
  browser?: Partial<Browser>;
  spaces: Spaces[];
  environments: Environment[];
  organizations?: Organisation[];
  apps?: OrganisationApp[];
  fetchApps?: (params: { organizationId: string }) => void;
  fetchEnvironments: (params: { organizationId: string }) => void;
  fetchSpaces: (params: { organizationId: string; silent?: boolean; }) => void;
  showOrgAndAppFields?: boolean;
  headlessEdgeDevices?: UniversalDevice[];
}

const BrowsersCreateForm = forwardRef(
  (props: CreateBrowserModalProps, ref: React.Ref<SchemaFormRef>) => {
    const { t } = useTranslation();
    const {
      onSubmit,
      spaces,
      environments,
      browser,
      organizations,
      apps,
      showOrgAndAppFields,
      fetchApps,
      fetchEnvironments,
      fetchSpaces,
      headlessEdgeDevices,
    } = props;

    const [formData, , , formInit, handleFormChange, handleFormSubmit] = useSchemaForm<
      any
    >(onSubmit, t('managedBrowserSaved'), t('managedBrowserSaveError'));

    const selectedOrganizationId = (formData as Partial<Browser>).organizationId;

    const organizationApps: OrganisationApp[] = useMemo(
      () =>
        selectedOrganizationId
          ? (Object.values(
              get(apps, selectedOrganizationId, {}),
            ) as OrganisationApp[]).filter(
              (orgApp) => orgApp.deviceType === DeviceTypeEnum.WPA,
            )
          : [],
      [selectedOrganizationId, apps],
    );

    const organizationEnvironments: Environment[] = useMemo(() => {
      if (Array.isArray(environments)) return environments;
      if (selectedOrganizationId)
        return Object.values(get(environments, selectedOrganizationId, {}));
      return [];
    }, [environments, selectedOrganizationId]);

    const organizationSpaces: Spaces[] = useMemo(() => {
      if (Array.isArray(spaces)) return spaces;
      if (selectedOrganizationId)
        return Object.values(get(spaces, selectedOrganizationId, {}));
      return [];
    }, [spaces, selectedOrganizationId]);

    const formSchema: any = useMemo(() => {
      let orgAndAppsProps = {};
      const spacesProps: any = {};
      let parentDeviceProps = {};

      if (
        browser &&
        !browser.parentDevice &&
        headlessEdgeDevices &&
        headlessEdgeDevices.length > 0
      ) {
        parentDeviceProps = {
          deviceId: {
            type: 'string',
            title: 'Parent device',
            enum: headlessEdgeDevices.map((device) => device.id),
            enumNames: headlessEdgeDevices.map((device) => device.deviceName),
          },
        };
      }

      if (showOrgAndAppFields && organizations) {
        orgAndAppsProps = {
          organizationId: {
            type: 'string',
            title: t('organization'),
            enum: organizations.map((organisation) => organisation.id),
            enumNames: organizations.map((organisation) => organisation.displayName),
          },
          appId: {
            type: 'string',
            title: t('installation'),
            enum: organizationApps.map((app) => app.id),
            enumNames: organizationApps.map((app) => app.displayName),
            readOnly: organizationApps.length < 1,
          },
        };
      }

      if (organizationSpaces.length) {
        spacesProps.spaces = {
          title: t('spaces'),
          type: 'array',
          uniqueItems: true,
          minItems: 0,
          items: {
            type: 'string',
            enum: organizationSpaces.map((b) => b.id),
            enumNames: organizationSpaces.map((b) => b.displayName),
          },
          default: [],
        };
      }

      const schema = {
        type: 'object',
        properties: {
          uuid: {
            type: 'string',
            minLength: 1,
            title: `${t('managedBrowser')} UUID`,
          },
          displayName: {
            type: 'string',
            minLength: 1,
            title: t('displayName'),
          },
          ...orgAndAppsProps,
          env: {
            type: 'string',
            title: t('environment'),
            enum: organizationEnvironments.map((env) => env.environmentName),
            enumNames: organizationEnvironments.map((env) => env.displayName),
            readOnly: organizationEnvironments.length < 1,
          },
          notes: {
            type: 'string',
            title: t('notes'),
          },
          ...spacesProps,
          isProvisioned: {
            type: 'boolean',
            title: t('isProvisioned'),
          },
          ...parentDeviceProps,
        },
        required: ['uuid', 'displayName', 'url', 'env', 'isProvisioned'],
      };

      return schema;
    }, [
      organizations,
      showOrgAndAppFields,
      t,
      organizationApps,
      organizationEnvironments,
      organizationSpaces,
      browser,
      headlessEdgeDevices,
    ]);

    useEffect(() => {
      if (selectedOrganizationId) {
        if (showOrgAndAppFields && fetchApps)
          fetchApps({ organizationId: selectedOrganizationId });
        fetchEnvironments({ organizationId: selectedOrganizationId });
        fetchSpaces({ organizationId: selectedOrganizationId, silent: true });
      }
    }, [
      selectedOrganizationId,
      fetchApps,
      fetchEnvironments,
      showOrgAndAppFields,
      fetchSpaces,
    ]);

    useEffect(() => {
      let initialValues: any = {};
      if (browser) initialValues = { ...browser };

      formInit({
        uuid: initialValues.uuid || '',
        displayName: initialValues.displayName || '',
        url: initialValues.url || '',
        env: initialValues.env || '',
        notes: initialValues.notes || '',
        isProvisioned: !!initialValues.isProvisioned,
        organizationId: initialValues.organizationId || '',
        appId: initialValues.appId || '',
        spaces: initialValues.spaces || [],
        deviceId: initialValues.parentDevice ? initialValues.parentDevice.id : undefined,
      });
    }, [formInit, browser]);

    const metaSchema = {
      spaces: {
        'ui:field': MultiSelect,
      },
    };

    return (
      <SchemaForm
        schema={formSchema}
        uiSchema={metaSchema}
        onSubmit={handleFormSubmit}
        onChange={handleFormChange}
        ref={ref}
        data={formData}
        liveValidate={false}
      />
    );
  },
);

export default BrowsersCreateForm;
