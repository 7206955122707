import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Divider as AntdDivider, Popover, Typography } from 'antd';
import { Icon } from '../../common';

const { Title: AntdTitle, Text: AntdText } = Typography;

interface VersionReleaseNotesPopoverProps {
  version?: string;
  buildDate?: string;
  releaseNotes?: string;
  popoverOverlayStyle?: React.CSSProperties;
}

const VersionReleaseNotesPopover = (props: VersionReleaseNotesPopoverProps) => {
  const { version, buildDate, releaseNotes, popoverOverlayStyle } = props;

  const { t } = useTranslation();

  return (
    <Popover
      placement="left"
      overlayStyle={{
        ...popoverOverlayStyle,
        maxWidth: 600,
        zIndex: 1051, // Antd Select dropdown z-index: 1050
      }}
      content={
        <>
          <Title>{version}</Title>
          {buildDate && <Text>{buildDate}</Text>}
          {releaseNotes && (
            <>
              <Divider />
              <Title>{t('releaseNotes.title')}</Title>
              <Content
                className="ombori-thin-scroll"
                dangerouslySetInnerHTML={{ __html: releaseNotes }}
              />
            </>
          )}
        </>
      }
    >
      <Icon type="exclamation-circle" color="#40A9FF" size={14} />
    </Popover>

  );
};

const Title = styled(AntdTitle)<{ size?: number }>`
  font-size: ${({ size = 14 }) => size}px !important;
  margin-bottom: 0 !important;
`;

const Text = styled(AntdText)`
  font-weight: 300;
  font-size: 12px;
`;

const Divider = styled(AntdDivider)`
  margin: 10px -16px 10px -16px;
  width: -webkit-fill-available;
`;

const Content = styled.div`
  min-width: 300px;
  max-height: 250px;
  overflow-y: scroll;
  white-space: pre-line;
  margin-top: 10px;

  /* move thin scroll to the right */
  margin-right: -16px;
`;

export default VersionReleaseNotesPopover;
