import React, { useMemo } from 'react';
import {
  useTenantMediaByDay,
  useInstallationMediaByDay,
  useSpaceMediaByDay,
  useDeviceMediaByDay,
  useAppMediaByDay,
} from '../../../use-analytics-report';
import { MediaCardPrimaryKeyType } from '@ombori/grid-reports';
import {
  useTenantDevices,
  useInstallationByIdDevices,
  useSpaceDevices,
} from '../../../use-devices';
import ViewByMedia from './view-by-media';
import ViewByTag from './view-by-tag';
import ViewByDevice from './view-by-device';
import { BaseProps } from './types';

export const TenantMedia: React.FC<BaseProps> = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
  primaryKey,
  title,
}) => {
  const mediaFetchingState = useTenantMediaByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
    isEnabled: primaryKey === MediaCardPrimaryKeyType.Media,
  });

  const devicesFetchingState = useTenantDevices({
    tenantId,
    isEnabled: primaryKey === MediaCardPrimaryKeyType.Device,
  });

  const component = useMemo(() => {
    switch (primaryKey) {
      case MediaCardPrimaryKeyType.Media:
        return (
          <ViewByMedia
            dataFetchingState={mediaFetchingState}
            title={title}
            gridStyles={gridStyles}
            isVisibleWithoutData={isVisibleWithoutData}
          />
        );

      case MediaCardPrimaryKeyType.Tag:
        return (
          <ViewByTag
            dataFetchingState={mediaFetchingState}
            title={title}
            gridStyles={gridStyles}
            isVisibleWithoutData={isVisibleWithoutData}
          />
        );

      case MediaCardPrimaryKeyType.Device:
        return (
          <ViewByDevice
            tenantId={tenantId}
            dateFrom={dateFrom}
            dateTo={dateTo}
            dataResidency={dataResidency}
            gridStyles={gridStyles}
            isVisibleWithoutData={isVisibleWithoutData}
            title={title}
            devicesFetchingState={devicesFetchingState}
          />
        );

      default:
        throw new Error(`Unknown media analytics card primary key type: ${primaryKey}`);
    }
  }, [
    mediaFetchingState,
    devicesFetchingState,
    dataResidency,
    dateFrom,
    dateTo,
    gridStyles,
    isVisibleWithoutData,
    primaryKey,
    tenantId,
    title,
  ]);

  return <>{component}</>;
};

interface InstallationMediaProps extends BaseProps {
  installationId: string;
}

export const InstallationMedia: React.FC<InstallationMediaProps> = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
  primaryKey,
  title,
  installationId,
}) => {
  const mediaFetchingState = useInstallationMediaByDay({
    tenantId,
    installationId,
    dateFrom,
    dateTo,
    dataResidency,
    isEnabled: primaryKey === MediaCardPrimaryKeyType.Media,
  });

  const devicesFetchingState = useInstallationByIdDevices({
    organisationId: tenantId,
    installationId,
    isEnabled: primaryKey === MediaCardPrimaryKeyType.Device,
  });

  const component = useMemo(() => {
    switch (primaryKey) {
      case MediaCardPrimaryKeyType.Media:
        return (
          <ViewByMedia
            dataFetchingState={mediaFetchingState}
            title={title}
            gridStyles={gridStyles}
            isVisibleWithoutData={isVisibleWithoutData}
          />
        );

      case MediaCardPrimaryKeyType.Tag:
        return (
          <ViewByTag
            dataFetchingState={mediaFetchingState}
            title={title}
            gridStyles={gridStyles}
            isVisibleWithoutData={isVisibleWithoutData}
          />
        );

      case MediaCardPrimaryKeyType.Device:
        return (
          <ViewByDevice
            tenantId={tenantId}
            dateFrom={dateFrom}
            dateTo={dateTo}
            dataResidency={dataResidency}
            gridStyles={gridStyles}
            isVisibleWithoutData={isVisibleWithoutData}
            title={title}
            devicesFetchingState={devicesFetchingState}
          />
        );

      default:
        throw new Error(`Unknown media analytics card primary key type: ${primaryKey}`);
    }
  }, [
    mediaFetchingState,
    devicesFetchingState,
    title,
    gridStyles,
    isVisibleWithoutData,
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
    primaryKey,
  ]);

  return <>{component}</>;
};

interface SpaceMediaProps extends BaseProps {
  spaceId: string;
}

export const SpaceMedia: React.FC<SpaceMediaProps> = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
  primaryKey,
  title,
  spaceId,
}) => {
  const mediaFetchingState = useSpaceMediaByDay({
    tenantId,
    spaceId,
    dateFrom,
    dateTo,
    dataResidency,
    isEnabled: primaryKey === MediaCardPrimaryKeyType.Media,
  });

  const devicesFetchingState = useSpaceDevices({
    organisationId: tenantId,
    spaceId,
    isEnabled: primaryKey === MediaCardPrimaryKeyType.Device,
  });

  const component = useMemo(() => {
    switch (primaryKey) {
      case MediaCardPrimaryKeyType.Media:
        return (
          <ViewByMedia
            dataFetchingState={mediaFetchingState}
            title={title}
            gridStyles={gridStyles}
            isVisibleWithoutData={isVisibleWithoutData}
          />
        );

      case MediaCardPrimaryKeyType.Tag:
        return (
          <ViewByTag
            dataFetchingState={mediaFetchingState}
            title={title}
            gridStyles={gridStyles}
            isVisibleWithoutData={isVisibleWithoutData}
          />
        );

      case MediaCardPrimaryKeyType.Device:
        return (
          <ViewByDevice
            tenantId={tenantId}
            dateFrom={dateFrom}
            dateTo={dateTo}
            dataResidency={dataResidency}
            gridStyles={gridStyles}
            isVisibleWithoutData={isVisibleWithoutData}
            title={title}
            devicesFetchingState={devicesFetchingState}
          />
        );

      default:
        throw new Error(`Unknown media analytics card primary key type: ${primaryKey}`);
    }
  }, [
    mediaFetchingState,
    devicesFetchingState,
    title,
    gridStyles,
    isVisibleWithoutData,
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
    primaryKey,
  ]);

  return <>{component}</>;
};

interface DeviceMediaProps extends BaseProps {
  deviceId: string;
}

export const DeviceMedia: React.FC<DeviceMediaProps> = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
  primaryKey,
  title,
  deviceId,
}) => {
  const mediaFetchingState = useDeviceMediaByDay({
    tenantId,
    deviceId,
    dateFrom,
    dateTo,
    dataResidency,
    isEnabled: primaryKey === MediaCardPrimaryKeyType.Media,
  });

  const component = useMemo(() => {
    switch (primaryKey) {
      case MediaCardPrimaryKeyType.Media:
        return (
          <ViewByMedia
            dataFetchingState={mediaFetchingState}
            title={title}
            gridStyles={gridStyles}
            isVisibleWithoutData={isVisibleWithoutData}
          />
        );

      case MediaCardPrimaryKeyType.Tag:
        return (
          <ViewByTag
            dataFetchingState={mediaFetchingState}
            title={title}
            gridStyles={gridStyles}
            isVisibleWithoutData={isVisibleWithoutData}
          />
        );

      case MediaCardPrimaryKeyType.Device:
        return null;

      default:
        throw new Error(`Unknown media analytics card primary key type: ${primaryKey}`);
    }
  }, [mediaFetchingState, primaryKey, title, gridStyles, isVisibleWithoutData]);

  return <>{component}</>;
};

interface AppMediaProps extends BaseProps {
  appId: string;
}

export const AppMedia: React.FC<AppMediaProps> = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
  primaryKey,
  title,
  appId,
}) => {
  const mediaFetchingState = useAppMediaByDay({
    tenantId,
    appId,
    dateFrom,
    dateTo,
    dataResidency,
    isEnabled: primaryKey === MediaCardPrimaryKeyType.Media,
  });

  const component = useMemo(() => {
    switch (primaryKey) {
      case MediaCardPrimaryKeyType.Media:
        return (
          <ViewByMedia
            dataFetchingState={mediaFetchingState}
            title={title}
            gridStyles={gridStyles}
            isVisibleWithoutData={isVisibleWithoutData}
          />
        );

      case MediaCardPrimaryKeyType.Tag:
        return (
          <ViewByTag
            dataFetchingState={mediaFetchingState}
            title={title}
            gridStyles={gridStyles}
            isVisibleWithoutData={isVisibleWithoutData}
          />
        );

      case MediaCardPrimaryKeyType.Device:
        return null;

      default:
        throw new Error(`Unknown media analytics card primary key type: ${primaryKey}`);
    }
  }, [mediaFetchingState, gridStyles, isVisibleWithoutData, title, primaryKey]);

  return <>{component}</>;
};
