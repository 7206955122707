import { Modal, Icon, Input, Select, Row, Col, Card } from 'antd';
import styled from '@emotion/styled';
import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import urlToFile from '../../../../utils/url-to-file';
import { usePexelsMediaCollection, OrientationEnum, MediaTypeEnum } from '../../use-pexels-collection';
import Media from '../../../../store/types/media';
import { formatPercentage } from '../../overview-report/cards/data-matrix/get-percentage';

const { Option } = Select;

const StyledIcon = styled(Icon)<{ color?: string }>`
  margin-right: 8px;
  ${({ color }) => color ? `color: ${color};` : ''}
`;

enum DownloadStatus {
  'DOWNLOADING' = 'downloading',
  'DONE' = 'done',
  'ERROR' = 'error',
  'NOT_DOWNLOADED' = 'stale',
}

const ImagesRow = styled(Row)`
  padding-bottom: 40px;
`;

const ModalBodyContainer = styled.div`
  overflow-y: scroll;
  overflow-x: clip;
  padding: 24px 32px 24px 48px;
  height: 464px;
`;

const StyledHeaderRow = styled(Row)`
  padding: 24px 48px 16px 48px;
`;

const StyledLoadingIcon = styled(Icon)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 24px;
`;

interface MediaEditModalProps {
  organisationId: string;
  visible: boolean;
  onClose: () => void;
  parentFolderId?: string;
  uploadMedia: (params: {
    organization: string;
    file: File;
    folderId: string | undefined;
    onProgress?: (percent: number) => void;
  }) => Promise<Media | void>;
}

type DownloadedMediaObject = Record<string, { status: string; progress: number }>;

const getMediaDownloadStatus = (downloadedMediaObj: DownloadedMediaObject, id: string) => {
  return downloadedMediaObj[id] && downloadedMediaObj[id]
    ? {
      status: downloadedMediaObj[id].status,
      progress: downloadedMediaObj[id].progress,
    } : {
      status: DownloadStatus.NOT_DOWNLOADED,
      progress: 0,
    };
}

const MediaEditModal = (props: MediaEditModalProps) => {
  const { visible, onClose, uploadMedia, organisationId, parentFolderId } = props;
  const { t } = useTranslation();

  const modalContentRef = useRef<HTMLDivElement>(null);
  const hasStartedScrollListening = useRef<boolean>(false);

  const [downloadedMedia, setDownloadedMedia] = useState<DownloadedMediaObject>({});
  const [query, setQuery] = useState('Spring background');
  const [orientation, setOrientation] = useState(OrientationEnum.PORTRAIT);
  const [mediaType, setMediaType] = useState(MediaTypeEnum.PHOTO);
  const [videoPreviewLink, setVideoPreviewLink] = useState('');

  const { data, fetchNextPage, isLoading } = usePexelsMediaCollection({
    pageSize: 12,
    query,
    orientation,
    mediaType,
  });

  const handleScrollEnd = useCallback((e: Event) => {
    const target = e.target as HTMLDivElement;
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      fetchNextPage();
    }
  }, [fetchNextPage]);

  useEffect(() => {
    if (modalContentRef.current) {
      modalContentRef!.current!.addEventListener('scroll', handleScrollEnd);
      hasStartedScrollListening.current = true;
      return () => {
        hasStartedScrollListening.current = false;
        modalContentRef!.current!.removeEventListener('scroll', handleScrollEnd);
      };
    }
  // eslint-disable-next-line
  }, [handleScrollEnd, modalContentRef, query]);

  const handleClose = useCallback(() => {
    setDownloadedMedia({});
    onClose();
  }, [onClose]);

  const handleSaveResult = useCallback(async (url: string) => {
    let downloadProgress = 0;
    let uploadProgress = 0;
    let totalProgress = 0;

    const setTotalProgress = (totalProgress: number) => {
      setDownloadedMedia(val => ({
        ...val,
        [url]: {
          status: +totalProgress !== 100 ? DownloadStatus.DOWNLOADING : DownloadStatus.DONE,
          progress: +totalProgress,
        }
      }));
    };

    const handleDownloadProgress = (percent: number) => {
      downloadProgress = (+percent);
      totalProgress = (downloadProgress + uploadProgress) / 2;
      setTotalProgress(totalProgress);
    }

    const handleUploadProgress = (percent: number) => {
      uploadProgress = (+percent);
      totalProgress = (downloadProgress + uploadProgress) / 2;
      setTotalProgress(totalProgress);
    }

    const mimeType = mediaType === MediaTypeEnum.PHOTO ? 'image/jpeg' : 'video/mp4';
    const fileType = mimeType.split('/')[1];
    const downloadUrl = mediaType === MediaTypeEnum.PHOTO ? url.split('?')[0] : url;
    const file = await urlToFile(downloadUrl, `${query}-${new Date().getTime()}.${fileType}`, handleDownloadProgress);
  
    try {
      await uploadMedia({
        file,
        organization: organisationId,
        folderId: parentFolderId || undefined,
        onProgress: handleUploadProgress,
      });
    } catch (error) {
      console.error(error);
    }
  }, [query, mediaType, organisationId, uploadMedia, parentFolderId]);

  const getAction = useCallback((url: string, currentMediaStatus: DownloadedMediaObject) => {
    const mediaStatus = getMediaDownloadStatus(currentMediaStatus, url);

    switch (mediaStatus.status) {
      case DownloadStatus.DOWNLOADING:
        return (
          <span>
            <StyledIcon type="loading" key="loading"/>
            {`Saving... ${formatPercentage(mediaStatus.progress)}}`}
          </span>
        );
      case DownloadStatus.DONE:
        return (
          <span>
            <StyledIcon type="check-circle" key="done" color="green" />
            Saved to library
          </span>
        );
      case DownloadStatus.ERROR:
        return (
          <span onClick={() => handleSaveResult(url)}>
            <StyledIcon type="cloud-download" key="done" color="red" />
            Failed to save. Try again
          </span>
        );
      default:
        return (
          <span onClick={() => handleSaveResult(url)}>
            <StyledIcon type="cloud-download" key="setting"/>
            Save to library
          </span>
        );
    }
  }, [handleSaveResult]);

  const selectBefore = useMemo(() => {
    return (
      <Select value={mediaType} onChange={(val) => setMediaType(val)} style={{ width: 90 }} data-testid="media-free-type">
        <Option value={MediaTypeEnum.PHOTO} data-testid="media-free-collection-option-photo">Photo</Option>
        <Option value={MediaTypeEnum.VIDEO} data-testid="media-free-collection-option-video">Video</Option>
      </Select>
    );
  }, [mediaType]);

  const selectAfter = useMemo(() => {
    return (
      <Select value={orientation}  onChange={(val) => setOrientation(val)} style={{ width: 112 }} data-testid="media-free-orientation">
        <Option value={OrientationEnum.PORTRAIT} data-testid="media-free-collection-option-orientation-portrait">Portrait</Option>
        <Option value={OrientationEnum.LANDSCAPE} data-testid="media-free-collection-option-orientation-landscape">Landscape</Option>
        <Option value={OrientationEnum.SQUARE} data-testid="media-free-collection-option-orientation-square">Square</Option>
      </Select>
    );
  }, [orientation]);

  const results = useMemo(() => {
    const pages = data ? data.pages : [];
  
    switch (mediaType) {
      case MediaTypeEnum.PHOTO:
        const photos = pages.reduce((allPhotos: { id: string, link: string }[], page) => {
          return [
            ...allPhotos,
            ...(page.photos ? page.photos.map(photo => ({
              id: photo.id.toString(),
              link: photo.src.medium,
            })) : [])
          ]
        }, []);

        return photos.map(photo => (
          <Col span={6}  key={photo.id}>
            <Card
              bodyStyle={{ padding: 0 }}
              hoverable
              cover={<img alt="result" src={photo.link} />}
              actions={[getAction(photo.link, downloadedMedia)]}
              title={null}
            />
          </Col>
        ));
      case MediaTypeEnum.VIDEO:
        const videos = pages.reduce((allVideos: { id: string, link: string }[], page) => {
          return [
            ...allVideos,
            ...(page.videos ? page.videos.map(video => ({
              id: video.id.toString(),
              link: video.video_files[0].link,
            })) : [])
          ]
        }, []);

        return videos.map(video => (
          <Col span={6} key={video.id}>
            <Card
              bodyStyle={{ padding: 0 }}
              hoverable
              cover={<video src={video.link} onClick={() => setVideoPreviewLink(video.link)} />}
              actions={[getAction(video.link, downloadedMedia)]}
              title={null}
            />
          </Col>
        ));
      default:
        return null;
    }
  }, [data, mediaType, downloadedMedia, getAction]);

  return (
    <>
      <Modal
        bodyStyle={{
          padding: '0px',
          maxHeight: '540px',
          minHeight: '540px',
          height: '532px',
        }}
        width="50%"
        footer={null}
        title={t('explorePhotos')}
        visible={visible && !videoPreviewLink}
        onCancel={handleClose}
        centered
      >
        <>
          <StyledHeaderRow>
            <Col>
              <Input
                addonBefore={selectBefore}
                addonAfter={selectAfter}
                placeholder="Input search text"
                onChange={(ev) => setQuery(ev.target.value)}
                value={query}
                data-testid="media-free-collection-search-input"
              />
            </Col>
            <Col>
            </Col>
          </StyledHeaderRow>
          <ModalBodyContainer ref={modalContentRef}>
            <ImagesRow gutter={[16, 16]} type="flex" justify="center" align="middle">
              {results}
            </ImagesRow>
            {isLoading && (<StyledLoadingIcon type="loading" />)}
          </ModalBodyContainer>
        </>
      </Modal>
      <Modal
        bodyStyle={{
          paddingTop: '24px',
          paddingBottom: '24px',
          maxHeight: '540px',
          minHeight: '540px',
          height: '532px',
          display: 'flex',
          justifyContent: 'center',
        }}
        width="auto"
        footer={null}
        title={t('filePicker.preview')}
        visible={!!videoPreviewLink} 
        onCancel={() => setVideoPreviewLink('')}
        centered
        destroyOnClose
      >
        {videoPreviewLink && <video controls src={videoPreviewLink} autoPlay />}
      </Modal>
    </>
  );
};

export default MediaEditModal;
