import React, { createContext, useContext } from 'react';
import ApiService from '../../../services/api/api.service';

const ApiServiceContext = createContext<ApiService | null>(null);

interface ApiServiceProviderProps {
  children: React.ReactNode;
  service: ApiService;
}

const ApiServiceProvider: React.FC<ApiServiceProviderProps> = ({
  children,
  service,
}): JSX.Element => {
  return (
    <ApiServiceContext.Provider value={service}>{children}</ApiServiceContext.Provider>
  );
};

export const useApiService = (): ApiService => {
  const ctx = useContext(ApiServiceContext);

  if (!ctx) {
    throw new Error(
      'Error caught while consuming ApiServiceContext context. Make sure you wrap the component inside the "ApiServiceProvider".',
    );
  }

  return ctx;
};

export default ApiServiceProvider;
