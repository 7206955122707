import React, { useCallback } from 'react';
import { useActions, useStore } from 'easy-peasy';
import { RootModel, RootState } from '../../../../../store/models/root.model';
import Report from '../../../../common/report/report.component';

type QueueOverviewContanier = Omit<
  React.ComponentProps<typeof Report>,
  'embed' | 'loaded' | 'error' | 'fetchEmbed'
>;

const QueueOverviewContanier = (props: QueueOverviewContanier) => {
  const {
    match: {
      params: { organisationId },
    },
  } = props;

  const reportName = '_queues';
  const { embed, error } = useStore<RootState>(
    (state) => ({
      embed:
        (state.analytics.embeds[organisationId] &&
          state.analytics.embeds[organisationId][reportName]) ||
        null,
      error:
        state.analytics.embedError[organisationId] &&
        state.analytics.embedError[organisationId][reportName],
    }),
    [organisationId, reportName],
  );

  const { fetchOrganizationQueuesEmbed } = useActions<RootModel>((actions) => ({
    fetchOrganizationQueuesEmbed: actions.analytics.fetchOrganizationQueuesEmbed,
  }));

  const fetchEmbed = useCallback(() => {
    if (fetchOrganizationQueuesEmbed && organisationId) {
      const params: any = { organizationId: organisationId, silent: true };
      fetchOrganizationQueuesEmbed(params);
    }
  }, [fetchOrganizationQueuesEmbed, organisationId]);

  return (
    <Report
      {...props}
      fetchEmbed={fetchEmbed}
      embed={embed}
      error={error}
      loaded={!!embed}
    />
  );
};

export default QueueOverviewContanier;
