import groupBy from 'lodash/groupBy';
import range from 'lodash/range';
import sum from 'lodash/sum';
import dayjs from 'dayjs';
import {
  TenantEventsByHour,
  TenantSessionsByHour,
  InstallationEventsByHour,
  InstallationSessionsByHour,
  SpaceEventsByHour,
  SpaceSessionsByHour,
  DeviceEventsByHour,
  DeviceSessionsByHour,
  AppEventsByHour,
  AppSessionsByHour,
} from '../../../use-analytics-report/aggregated-reports/types';

type RawEventsData =
  | TenantEventsByHour
  | InstallationEventsByHour
  | SpaceEventsByHour
  | DeviceEventsByHour
  | AppEventsByHour;

type RawSessionsData =
  | TenantSessionsByHour
  | InstallationSessionsByHour
  | SpaceSessionsByHour
  | DeviceSessionsByHour
  | AppSessionsByHour;

type RawDataGroupedByWeekDay<T> = {
  day: number;
  data: T[];
}[];

function groupDataByWeekDay<T extends RawEventsData | RawSessionsData>(
  rawData: T[],
): RawDataGroupedByWeekDay<T> {
  const groupedData = groupBy(rawData, (dataItem) => dayjs(dataItem.date).day());

  const result = [
    { day: 1, data: groupedData[1] || [] },
    { day: 2, data: groupedData[2] || [] },
    { day: 3, data: groupedData[3] || [] },
    { day: 4, data: groupedData[4] || [] },
    { day: 5, data: groupedData[5] || [] },
    { day: 6, data: groupedData[6] || [] },
    { day: 7, data: groupedData[0] || [] },
  ];

  return result;
}

type RawDataGroupedByHour<T> = {
  hour: number;
  data: T[];
}[];

function groupDataByHour<T extends RawEventsData | RawSessionsData>(
  data: RawDataGroupedByWeekDay<T>,
) {
  const result = data.map((dataItem) => {
    const newData = groupBy(dataItem.data, (item) => item.hour);

    const dataGroupedByHour: RawDataGroupedByHour<T> = range(0, 24).map((_, index) => ({
      hour: index,
      data: newData[index] || [],
    }));

    const result = {
      ...dataItem,
      data: dataGroupedByHour,
    };

    return result;
  });

  return result;
}

const getDayIndex = (day: number) => day - 1;

const getHourIndex = (hour: number) => Math.abs(hour - 23);

const getValue = (data: number[]): number => {
  const total = sum(data);

  const dataLength = data.length;

  const result = dataLength === 0 ? 0 : Math.floor(total / dataLength);

  return result;
};

export const buildSessionsData = (
  rawData: RawSessionsData[],
): [number, number, number][] => {
  const dataGroupedByDay = groupDataByWeekDay<RawSessionsData>(rawData);

  const processedData = groupDataByHour<RawSessionsData>(dataGroupedByDay);

  const result = processedData.flatMap(({ day, data: dayData }) => {
    const result = dayData.map(({ hour, data: hourData }) => {
      const result = [
        getDayIndex(day),
        getHourIndex(hour),
        getValue(hourData.map((dataItem) => dataItem.sessionCount)),
      ] as [number, number, number];

      return result;
    });

    return result;
  });

  return result;
};

export const buildEventsData = (
  rawData: RawEventsData[],
  eventType: string,
): [number, number, number][] => {
  const filteredData = rawData.filter((dataItem) => dataItem.eventType === eventType);

  const dataGroupedByDay = groupDataByWeekDay<RawEventsData>(filteredData);

  const processedData = groupDataByHour<RawEventsData>(dataGroupedByDay);

  const result = processedData.flatMap(({ day, data: dayData }) => {
    const result = dayData.map(({ hour, data: hourData }) => {
      const result = [
        getDayIndex(day),
        getHourIndex(hour),
        getValue(hourData.map((dataItem) => dataItem.count)),
      ] as [number, number, number];

      return result;
    });

    return result;
  });

  return result;
};
