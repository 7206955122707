import { useMemo } from 'react';
import OrganisationApp from '../../../../../../store/types/organisation-app';
import useOverriddenSettings from './use-overridden-settings';
import { useSettingsForm } from './settings-form.component';

interface UseSpaceSettingsProps {
  app: OrganisationApp;
  spaceId: string;
}

const useSpaceSettingsFromApp = ({
  app,
  spaceId,
}: UseSpaceSettingsProps) => {
  const settingsDiff = useMemo(() => {
    const { spaceSettingsOverriding } = app;

    if (!spaceSettingsOverriding || !spaceId) {
      return [];
    }

    const result = spaceSettingsOverriding[spaceId] || [];

    return result;
  }, [app, spaceId]);

  const initialSettings = useOverriddenSettings({
    appSettings: app.settings,
    settingsDiff,
  });

  const settingsForm = useSettingsForm(initialSettings);

  return settingsForm;
};

export default useSpaceSettingsFromApp;
