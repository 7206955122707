import { useCallback } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { GridKnowledgeSource, useGridLlmService } from '@ombori/grid-llm-react';
import { ApiError } from '../../../services/api/api-error';

type UseStoreAiAssistantKnowledgeBaseFilesResponse = UseQueryResult<
  GridKnowledgeSource[],
  ApiError
>;

const useStoreAiAssistantKnowledgeBaseFiles = (assistantId?: string, isEnabled = true): UseStoreAiAssistantKnowledgeBaseFilesResponse => {
  const gridLlmService = useGridLlmService();

  const fetch = useCallback(async (): Promise<GridKnowledgeSource[]> => {
    if (!assistantId) {
      return [];
    }

    return await gridLlmService.getAssistantKnowledgeSources(assistantId);
  }, [assistantId, gridLlmService]);

  const result = useQuery(
    assistantId ? getStoreAiAssistantKnowledgeBaseFilesKeys(assistantId) : [],
    () => fetch(),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      keepPreviousData: true,
      onError: (_error: ApiError) => {},
      enabled: isEnabled,
    },
  );

  return result;
};

export const getStoreAiAssistantKnowledgeBaseFilesKeys = (assistantId: string): string[] => {
  return [
    'get-store-ai-knowledge-base-files',
    'assistantId',
    assistantId,
  ];
};

export default useStoreAiAssistantKnowledgeBaseFiles;
