import { useApiService } from '../../../app/common/api-service-provider';
import { useMutation, useQueryClient } from 'react-query';
import { ApiError } from '../../../services/api/api-error';
import * as Sentry from '@sentry/browser';
import organizationUsersQueryKeys from './use-organization-users-query-keys';
import TenantUser from '../../types/tenant-user';

export interface UseOrganizationUsersEditFormFieldsProps {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  roleId: string;
  id: string;
  organizationId: string;
  allowedSpaceIds: string[];
  hasAccessToAllSpaces: boolean | undefined;
}


const useOrganizationUsersEdit = () => {
  const queryClient = useQueryClient();
  const apiService = useApiService();

  return useMutation(
    async ({ userId, organisationId, payload }: { userId: string, organisationId: string, payload: UseOrganizationUsersEditFormFieldsProps }) => {
      return apiService.put<TenantUser>(`/api/organizations/${organisationId}/users/${userId}`, payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([organizationUsersQueryKeys.queryPrefix()]);
      },
      onError: (error: ApiError) => {
        Sentry.captureException(error);
      },
    },
  );
};

export default useOrganizationUsersEdit;
