/* eslint-disable import/no-unresolved */
import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Typography, List, Row, Col, Button } from 'antd';
import styled from '@emotion/styled';
import Overlay from '../../overlay/overlay.component';
import Spinner from '../../spinner/spinner.component';
import PanelCard from '../../panel-card/panel-card.component';
import BrowserListItem from './browsers-list-item.component';
import Browser from '../../../../store/types/browser';
import sortBrowsers from '../../../../utils/browsers/sort-browsers';
import OrganisationApp from '../../../../store/types/organisation-app';

const { Title } = Typography;

interface BrowsersListComponent
  extends Omit<React.ComponentProps<typeof BrowserListItem>, 'browser'> {
  loaded: boolean;
  canCreate: boolean;
  onCreate: () => void;
  browsers: Browser[];
  fetchBrowsers: (params: { silent: boolean }) => void;
  apps?: {
    [organisationName: string]: OrganisationApp[];
  };
}

const FiltersRow = styled(Row)`
  margin-bottom: 16px;
`;

const BrowsersListComponent = (props: BrowsersListComponent) => {
  const {
    loaded,
    browsers,
    environments,
    spaces,
    onDelete,
    onUpdate,
    onConnect,
    canUpdate,
    canDelete,
    canCreate,
    onCreate,
    fetchBrowsers,
    moveBrowser,
    apps = {},
    history,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => {
      fetchBrowsers({ silent: true });
    }, 40 * 1000);

    fetchBrowsers({ silent: false });

    return () => {
      clearInterval(interval);
    };
  }, [fetchBrowsers]);

  const sortedBrowsers = useMemo(() => sortBrowsers(browsers), [browsers]);

  return (
    <>
      {!loaded && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}
      {loaded && !browsers.length && (
        <Overlay>
          <Title level={4}>
            {t('noManagedBrowsersFound')} <Icon type="frown" />
          </Title>
        </Overlay>
      )}
      {canCreate && (
        <FiltersRow type="flex" justify="end">
          <Col>
            <Button size="large" icon="plus" onClick={onCreate}>
              {t('createBrowser')}
            </Button>
          </Col>
        </FiltersRow>
      )}
      {loaded && !!browsers.length && (
        <PanelCard>
          <List
            itemLayout="horizontal"
            dataSource={sortedBrowsers}
            renderItem={(browser: Browser) => (
              <BrowserListItem
                history={history}
                moveBrowser={moveBrowser}
                key={browser.id}
                spaces={spaces}
                browser={browser}
                apps={apps}
                environments={environments}
                onDelete={onDelete}
                onUpdate={onUpdate}
                onConnect={onConnect}
                canUpdate={canUpdate}
                canDelete={canDelete}
              />
            )}
          />
        </PanelCard>
      )}
    </>
  );
};

export default BrowsersListComponent;
