import { useQuery } from 'react-query';
import { useApiService } from '../../../app/common/api-service-provider';
import tagQueryKeys from './tag-query-keys';
import ContentTag from '../../types/content-tag';
import { PaginationCollection } from '../../types/pagination';

const useTagsList = (tenantId: string, limit = 100, page = 1) => {
  const apiService = useApiService();

  return useQuery(
    tagQueryKeys.getTagsList(tenantId, limit, page),
    () => apiService.get<PaginationCollection<ContentTag>>('/api/content-tag', { tenantId, limit, page }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export default useTagsList;
