import React from 'react';
import { useLocation, useRouteMatch, match } from 'react-router';
import ReactGA from 'react-ga4';
import { useStore } from 'easy-peasy';
import { RootState } from './store/models/root.model';
import User from './store/types/user';
import Organisation from './store/types/organisation';
import { getGATrackingId } from './utils/env';

const GA_TRACKING_ID = getGATrackingId();

interface OrganizationURLParams {
  organizationId: string;
}

const GATracker = () => {
  const { user, organizations }: { user: User; organizations: RootState['organisations'] } =
    useStore<RootState>(({ auth, organisations }) => {
      return {
        user: auth.user,
        organizations: organisations,
      };
    });

  const location = useLocation();
  const matched = useRouteMatch<OrganizationURLParams>('/organisations/:organizationId');

  let matchedParams: match<OrganizationURLParams> = matched || {
    params: { organizationId: '' },
    path: '',
    url: '',
    isExact: false,
  };

  const organizationId = matchedParams.params.organizationId;

  // instead of sending current url in the browser, we'll replace it with placeholder
  // e.g. /organisations/abc123 -> /organisations/:tenant
  const path = location.pathname.replace(organizationId, ':tenantId');

  let email = '';
  if (user) {
    email = user.email;
  }

  let organizationSlug = '';
  if (organizations && organizations.values && organizations.values.length > 0) {
    const currentOrganization = organizations.values.find(
      (organization: Organisation) => organization.id === organizationId,
    );
    if (currentOrganization) {
      organizationSlug = currentOrganization.organizationName;
    }
  }

  ReactGA.initialize([
    {
      trackingId: GA_TRACKING_ID,
      gaOptions: {
        userId: email,
        page: path,
      },
    },
  ]);

  // a custom dimension added to track tenant slug
  ReactGA.set({
    tenant: organizationSlug,
  });

  ReactGA.send({
    hitType: 'pageview',
    page: path,
    title: document.title,
  });

  return null;
};

export default GATracker;
