import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import OnboardingRegistrationComponent from './onboarding-signup.component';
import { RootModel, RootState } from '../../../store/models/root.model';

type OnboardingRegistrationContainerProps = Omit<
  React.ComponentProps<typeof OnboardingRegistrationComponent>,
  | 'loading'
  | 'error'
  | 'user'
  | 'signup'
  | 'validateVatId'
  | 'validateReferralCode'
  | 'fetchOnboarding'
  | 'onboardingInfo'
>;

const OnboardingSignupContainer = (props: OnboardingRegistrationContainerProps) => {
  const { fetchOnboarding, signup, validateVatId, validateReferralCode } = useActions<
    RootModel
  >((actions) => ({
    signup: actions.onboarding.signup,
    validateVatId: actions.onboarding.validateVatId,
    validateReferralCode: actions.onboarding.validateReferralCode,
    fetchOnboarding: actions.onboarding.fetchByToken,
  }));

  const { loading, error, user, onboardingInfo } = useStore<RootState>((state) => {
    return {
      loading: state.onboarding.loading,
      error: state.onboarding.error,
      user: state.auth.user,
      onboardingInfo: state.onboarding.data,
    };
  }, []);

  return (
    <OnboardingRegistrationComponent
      {...props}
      loading={loading}
      error={error}
      user={user}
      validateVatId={validateVatId}
      signup={signup}
      validateReferralCode={validateReferralCode}
      fetchOnboarding={fetchOnboarding}
      onboardingInfo={onboardingInfo}
    />
  );
};

export default OnboardingSignupContainer;
