import React from 'react';
import { Typography } from 'antd';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import Media, {
  MediaMetadata,
  VideoAnalysisResultValue,
  VideoVariant,
  VideoVariantState,
} from '../../../../store/types/media';
import MediaItemVideoVariant from '../media-item-video-variant/media-item-video-variant.component';
import useCurrentDate from '../../../../utils/date/use-current-date';
import {
  checkMediaItemCreatedRecently,
  getVideoPresetDisplayName,
  MEDIA_ITEM_VIDEO_VARIANT_TYPE,
} from '../../../../utils/media-item-utils';

const { Text } = Typography;

const VideoSectionContainer = styled.div`
  margin-left: 20px;
  flex-direction: column;
`;

const VideoVariantsContainer = styled.div`
  margin-bottom: 8px;
`;

interface MediaItemVideoSectionProps {
  item: Media;
  disabled: boolean;
  onSelect: (item: Media) => void;
}

const MediaItemVideoSection: React.FC<MediaItemVideoSectionProps> = ({
  item,
  onSelect,
  disabled,
}) => {
  const { t } = useTranslation();

  const currentDate = useCurrentDate();
  const createdRecently = checkMediaItemCreatedRecently(item, currentDate);

  const handleSelection = (videoVariant: VideoVariant) => {
    if (videoVariant.state !== VideoVariantState.finished || videoVariant.url == null) {
      return;
    }
    onSelect({
      ...item,
      url: videoVariant.url,
      type: MEDIA_ITEM_VIDEO_VARIANT_TYPE,
      name: `${item.name} / ${getVideoPresetDisplayName(videoVariant.preset, t)}`,
    });
  };

  const renderContents = () => {
    const metadata: MediaMetadata = item.metadata || {};
    const { videoAnalysisResult, videoVariants } = metadata;
    if (videoAnalysisResult == null) {
      return createdRecently ? (
        <Text type="secondary">{t('videoAnalysisResultInProgress')}</Text>
      ) : (
        <Text type="secondary">{t('videoAnalysisResultMissing')}</Text>
      );
    }
    if (videoAnalysisResult.value === VideoAnalysisResultValue.conversionNotNeeded) {
      return <Text type="secondary">{t('videoAnalysisResultConversionNotNeeded')}</Text>;
    }
    if (videoAnalysisResult.value === VideoAnalysisResultValue.notSupported) {
      return <Text type="secondary">{t('videoAnalysisResultNotSupported')}</Text>;
    }
    if (videoAnalysisResult.value === VideoAnalysisResultValue.conversionRequired) {
      const presets = videoAnalysisResult.presets || [];
      const variants = videoVariants || {};
      const variantRows = presets.map((preset) => {
        const videoVariant = variants[preset];
        if (videoVariant == null) {
          return null;
        }
        return (
          <MediaItemVideoVariant
            variant={videoVariant}
            key={preset}
            onSelect={() => handleSelection(videoVariant)}
            disabled={disabled}
          />
        );
      });
      return (
        <>
          <VideoVariantsContainer>{variantRows}</VideoVariantsContainer>
          <Text type="secondary">{t('videoAnalysisResultConversionRequired')}</Text>
        </>
      );
    }
    return <Text type="secondary">{t('videoAnalysisResultUnknown')}</Text>;
  };
  return <VideoSectionContainer>{renderContents()}</VideoSectionContainer>;
};

export default MediaItemVideoSection;
