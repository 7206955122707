import React, { useMemo } from 'react';
import moment from 'moment';
import { TimePicker, Button } from 'antd';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const timeFormat = 'HH:mm';

export const defaultFromTime = '08:00';
export const defaultToTime = '20:00';
export const defaultAutoResetTime = '00:00';

export default function TimePickerAdapter({
  defaultTime,
  input: { onChange, value },
  minuteStep = 1,
  midnightSlotIsAvailable = false,
  ...rest
}: {
  defaultTime?: string;
  input: { onChange: any; value: string };
  minuteStep?: number;
  midnightSlotIsAvailable?: boolean;
}) {
  const { t } = useTranslation();

  const timeDefault = useMemo(() => {
    if (!value && !defaultTime) {
      return undefined;
    }

    return moment(value || defaultTime, timeFormat);
  }, [value, defaultTime]);

  return (
    <TimePicker
      defaultValue={timeDefault}
      defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
      format={timeFormat}
      onChange={(selectedTime) => onChange(moment(selectedTime).format(timeFormat))}
      value={timeDefault}
      // if we want this idea work we need calculate time after start time from From, not just specify step. Put 1 for keeping minutes step for future
      minuteStep={1 || minuteStep}
      addon={
        midnightSlotIsAvailable
          ? () => (
              <Button size="small" type="primary" onClick={() => onChange('23:59')}>
                {t('timePickerMidnightSlot')}
              </Button>
            )
          : undefined
      }
      {...rest}
    />
  );
}

export const TimeDescription = styled.span`
  font-weight: 700;
`;

export const HoursContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
`;

export const SingleHour = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0 8px;
`;
