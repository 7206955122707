import * as React from 'react';
import { useStoreActions, useStoreState } from '../../../../store/initialize-store';
import OrganisationDeviceSetupComponent from './organisation-device-setup.component';
import { AppInstallBody } from '../../../../store/models/organisation-apps/organisation-apps.model';
import { useInstallationGroups } from '../../../common/use-installation-groups';

type OrganisationDeviceSetupContainerProps = Omit<
  React.ComponentProps<typeof OrganisationDeviceSetupComponent>,
  | 'organisation'
  | 'appsAndGroupsLoaded'
  | 'apps'
  | 'installationGroups'
  | 'installApp'
  | 'fetchApps'
>;

const OrganisationDeviceSetupContainer = (
  props: OrganisationDeviceSetupContainerProps,
) => {
  const {
    match: {
      params: { organisationId },
    },
  } = props;

  const installationGroupsQuery = useInstallationGroups({ organisationId });
  const installationGroups = installationGroupsQuery.data || [];

  const {
    organisation,
    appsAndGroupsLoaded,
    apps,
    user
  } = useStoreState(
    ({ organisationApps, organisations, organisationInstallationGroups, auth }) => ({
      organisation: organisations.data && organisations.data[organisationId],
      appsAndGroupsLoaded:
        organisationApps.loaded && organisationInstallationGroups.loaded(organisationId),
      apps: organisationApps.values(organisationId),
      user: auth.user,
      permissions: auth.permissions
    }),
    [organisationId],
  );

  const { createDevice, installApp, fetchApps } = useStoreActions((actions) => ({
    fetchApps: actions.organisationApps.fetch,
    createDevice: actions.appDevicesUniversal.create,
    installApp: (data: AppInstallBody) => actions.organisationApps.install(data),
  }));

  return (
    <OrganisationDeviceSetupComponent
      {...props}
      appsAndGroupsLoaded={appsAndGroupsLoaded}
      organisation={organisation}
      apps={apps}
      installationGroups={installationGroups}
      user={user}
      installApp={installApp}
      createDevice={createDevice}
      fetchApps={fetchApps}
    />
  );
};

export default OrganisationDeviceSetupContainer;
