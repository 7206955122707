import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Button, Dropdown, Menu } from 'antd';
import { Icon } from '../../schema-form/common';
import { useTranslation } from 'react-i18next';

interface CardTitleDownloadButtonWrapperProps {
  isDownloadButtonVisible: boolean;
  children: React.ReactNode;
  onDownloadButtonClicked: () => void;
  isDownloading: boolean;
}

const CardTitleDownloadButtonWrapper = (props: CardTitleDownloadButtonWrapperProps) => {
  const { isDownloadButtonVisible, children, onDownloadButtonClicked, isDownloading = false } = props;

  const { t } = useTranslation();

  const downloadButtonMenu = useMemo(() => {
    return (
      <Menu>
        <Menu.Item onClick={onDownloadButtonClicked}>{t('fileDownload.CSV')}</Menu.Item>
      </Menu>
    );
  }, [t, onDownloadButtonClicked]);

  return (
    <DownloadButtonWrapperContainer>
      <div>{children}</div>

      {isDownloadButtonVisible && (
        <div>
          <Dropdown overlay={downloadButtonMenu}>
            <Button type="link" loading={isDownloading} disabled={isDownloading}>
              <span>{t('fileDownload.download')}</span> &nbsp;
              <Icon type="down" />
            </Button>
          </Dropdown>
        </div>
      )}
    </DownloadButtonWrapperContainer>
  );
};

export default CardTitleDownloadButtonWrapper;

const DownloadButtonWrapperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;