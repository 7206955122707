import React, { useCallback } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { DataResidencyEnum } from '../../../store/types/organisation';
import { getApiUrl } from '../../../utils/env';

interface DownloadReportProps {
  tenantId: string;
  dataResidency: DataResidencyEnum;
  installationId?: string;
  spaceId?: string ;
  deviceId?: string;
  filters?: Record<string, string>;
  downloadPath?: string;
}

const DownloadReport = (props: DownloadReportProps) => {
  const { tenantId, dataResidency, installationId,  spaceId, deviceId, filters, downloadPath } = props;

  const { t } = useTranslation();

  const handleDownload = useCallback(() => {
    if (downloadPath) {
      document.location.href = `${getApiUrl()}${downloadPath}`;
      return;
    }

    const updatedFilters = {...filters, ...{ timespanType: 'day', interactionType: 'all' }};
    const params = new URLSearchParams(updatedFilters).toString();
    const baseApiUrl = buildBaseUrl(dataResidency);

    if (installationId) {
      document.location.href = `${baseApiUrl}/reports/v1/excel/tenants/${tenantId}/installations/${installationId}/events?${params}`;
      return;
    }

    if (spaceId) {
      document.location.href = `${baseApiUrl}/reports/v1/excel/tenants/${tenantId}/spaces/${spaceId}/events?${params}`;
      return;
    }

    if (deviceId) {
      document.location.href = `${baseApiUrl}/reports/v1/excel/tenants/${tenantId}/devices/${deviceId}/events?${params}`;
      return;
    }

    document.location.href = `${baseApiUrl}/reports/v1/excel/tenants/${tenantId}/events?${params}`;
  }, [tenantId, dataResidency, installationId, spaceId, deviceId, filters, downloadPath]);

  return (
    <Button size="small" type="primary" icon="download" onClick={handleDownload}>
      {t('downloadReport')}
    </Button>
  );
};

const buildBaseUrl = (env: DataResidencyEnum) => {
  const baseApiUrl = getApiUrl();
  if (env === DataResidencyEnum.DEV) {
    return `${baseApiUrl}/regions/dev`;
  }
  if (env === DataResidencyEnum.QA) {
    return `${baseApiUrl}/regions/qa`;
  }

  return `${baseApiUrl}/regions/${env.toLowerCase()}`;
};

export default DownloadReport;
