import React, { memo, useMemo, useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { Cascader, DatePicker, Select } from 'antd';
import styled from '@emotion/styled';
import moment from 'moment';
import qs from 'query-string';
import { listTimeZones } from 'timezone-support';
import OrganisationApp from '../../../../../store/types/organisation-app';
import { ApiError } from '../../../../../services/api/api-error';
import OverviewReport from '../../../../common/overview-report/overview-report.component';
import ContentWrap from '../../../../common/app-layout/content/content-wrap.component';

interface LegacyOverviewProps
  extends RouteComponentProps<{ appId: string; organisationId: string }> {
  fetchDevices: () => void;
  fetchReport: (device?: string, location?: string) => any;
  fromDate: string;
  toDate: string;
  deviceLocation?: string;
  device?: string;
  timeZone: string;
  loading: boolean;
  report: any | null;
  options: any;
  app: OrganisationApp | null;
  error: ApiError | null;
}

const Controls = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  padding-bottom: 24px;
  width: 100%;

  @media (min-width: 1280px) {
    width: calc(75% - ${24 / 4}px);
  }

  @media (min-width: 1920px) {
    width: calc(50% - ${24 / 2}px);
  }
`;

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';

const LegacyOverview = (props: LegacyOverviewProps) => {
  const {
    fetchReport,
    fetchDevices,
    fromDate,
    toDate,
    deviceLocation,
    device,
    timeZone,
    loading,
    report,
    options,
    error,
    history,
  } = props;
  const onChangeLocationDevice = useCallback(
    ([nextLocation, nextDevice]: string[]) => {
      history.replace({
        pathname: history.location.pathname,
        search: qs.stringify({
          ...qs.parse(history.location.search),
          location: nextLocation,
          device: nextDevice,
        }),
      });
    },
    [history],
  );
  const onChangeRange = useCallback(
    (date: any[], [nextFromDate, nextToDate]: [string, string]) => {
      history.replace({
        pathname: history.location.pathname,
        search: qs.stringify({
          ...qs.parse(history.location.search),
          fromDate: nextFromDate,
          toDate: nextToDate,
        }),
      });
    },
    [history],
  );
  const onChangeTimeZone = useCallback(
    (nextTimeZone: string) => {
      history.replace({
        pathname: history.location.pathname,
        search: qs.stringify({
          ...qs.parse(history.location.search),
          timeZone: nextTimeZone,
        }),
      });
    },
    [history],
  );
  const cascaderValue = useMemo(() => {
    if (deviceLocation && device) {
      return [deviceLocation, device];
    }

    if (deviceLocation) {
      return [deviceLocation];
    }

    return [];
  }, [deviceLocation, device]);
  const timeZones = useMemo(() => listTimeZones(), []);

  useEffect(() => {
    fetchDevices();
    fetchReport();
  }, [fetchReport, fetchDevices]);

  return (
    <ContentWrap>
      <Controls>
        <RangePicker
          value={[moment(fromDate, dateFormat), moment(toDate, dateFormat)]}
          format={dateFormat}
          ranges={{
            Today: [moment(), moment()],
            'Past 7 days': [moment().subtract(8, 'd'), moment().subtract(1, 'd')],
            'Past 30 days': [moment().subtract(31, 'd'), moment().subtract(1, 'd')],
            'Past 60 days': [moment().subtract(61, 'd'), moment().subtract(1, 'd')],
            // 'Past 90 days': [moment().subtract(91, 'd'), moment().subtract(1, 'd')],
          }}
          onChange={onChangeRange}
          disabledDate={(current: moment.Moment | undefined) =>
            !!current &&
            (current.isBefore(moment().subtract(61, 'd')) || current.isAfter(moment()))
          }
        />
        <Select
          showSearch
          placeholder="Select a time zone"
          value={timeZone}
          optionFilterProp="children"
          onChange={onChangeTimeZone}
          filterOption={(input, option) => {
            if (typeof option.props.children === 'string') {
              return (
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }
            return false;
          }}
        >
          {timeZones.map((tz) => (
            <Select.Option key={tz} value={tz}>
              {tz.replace(/\//g, ' / ').replace('_', ' ')}
            </Select.Option>
          ))}
        </Select>
        <Cascader
          options={options}
          onChange={onChangeLocationDevice}
          value={cascaderValue}
          changeOnSelect
          placeholder="Select location / device"
        />
      </Controls>
      <OverviewReport
        fetchReport={fetchReport}
        loading={loading}
        report={report}
        error={error}
      />
    </ContentWrap>
  );
};

export default memo(LegacyOverview, (prevProps, nextProps) => {
  if (
    prevProps.fetchReport !== nextProps.fetchReport ||
    prevProps.fetchDevices !== nextProps.fetchDevices ||
    prevProps.loading !== nextProps.loading ||
    prevProps.report !== nextProps.report ||
    prevProps.options !== nextProps.options ||
    prevProps.error !== nextProps.error
  ) {
    return false;
  }

  return true;
});
