import { FieldArray } from 'react-final-form-arrays';
import { Button, Col, Row, Switch } from 'antd';
import { Field } from 'react-final-form';
import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { UsergroupAddOutline } from '@ant-design/icons';
import Icon from '@ant-design/icons-react';
import TimePickerAdapter, {
  defaultFromTime,
  defaultToTime,
  SingleHour,
  TimeDescription,
} from '../time-picker-adapter';
import { capitalize } from '../utils';
import { Label, PanelCardMain, RowStyled, FormTextInput } from '../styled-block';
import { WeekdayPeriods } from '../types';

interface WeekdayHourPeriodsProps {
  name: string;
  value?: WeekdayPeriods;
  change: (name: string, value: any) => void;
  label: string;
  isCapacityAdjustable?: boolean;
  minuteStep?: number;
  midnightSlotIsAvailable?: boolean;
}

const HourPeriodsControlButton = styled(Button)`
  margin-top: 20px;
` as any;

const WeekDayToggleSwitch = styled(Switch)`
  margin-right: 16px;
`;

const WeekDayNameContainer = styled(Row)`
  min-height: 70px;
`;

const InputField = styled(FormTextInput)`
  max-width: 100px;
`;

const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const defaultPeriod = {
  from: defaultFromTime,
  to: defaultToTime,
};

const WeekdayHourPeriods = ({
  label,
  name,
  change,
  isCapacityAdjustable = false,
  minuteStep,
  midnightSlotIsAvailable,
}: WeekdayHourPeriodsProps) => {
  const handleWeekDayToggleSwitchChange = useCallback(
    (checked: boolean, onChange: (val: any) => void, arrayFieldName: string) => {
      change(arrayFieldName, checked ? [defaultPeriod] : []);
      onChange(checked);
    },
    [change],
  );

  return (
    <RowStyled>
      <Label>{label}</Label>
      {days.map((day) => (
        <FieldArray key={`${name}.${day}.periods`} name={`${name}.${day}.periods`}>
          {({ fields }) => (
            <PanelCardMain>
              <Row type="flex" align="top" justify="space-between">
                <WeekDayNameContainer type="flex" align="middle">
                  <Field name={`${name}.${day}.enabled`}>
                    {({ input }) => (
                      <WeekDayToggleSwitch
                        checked={!!input.value}
                        onChange={(checked) =>
                          handleWeekDayToggleSwitchChange(
                            checked,
                            input.onChange,
                            `${name}.${day}.periods`,
                          )
                        }
                      />
                    )}
                  </Field>
                  <Label>{capitalize(day)}</Label>
                </WeekDayNameContainer>
                <Row align="top" type="flex">
                  <Col>
                    {fields.map((fieldName, index) => {
                      return (
                        <Row type="flex" key={fieldName}>
                          <Field name={`${fieldName}.from`}>
                            {({ input: filedInput }) => (
                              <SingleHour>
                                <TimeDescription>From</TimeDescription>
                                <TimePickerAdapter
                                  defaultTime={defaultFromTime}
                                  input={filedInput}
                                  minuteStep={minuteStep}
                                  midnightSlotIsAvailable={false}
                                />
                              </SingleHour>
                            )}
                          </Field>
                          <Field name={`${fieldName}.to`}>
                            {({ input: filedInput }) => (
                              <SingleHour>
                                <TimeDescription>To</TimeDescription>
                                <TimePickerAdapter
                                  defaultTime={defaultToTime}
                                  input={filedInput}
                                  minuteStep={minuteStep}
                                  midnightSlotIsAvailable={midnightSlotIsAvailable}
                                />
                              </SingleHour>
                            )}
                          </Field>

                          {isCapacityAdjustable && (
                            <Field name={`${fieldName}.capacity`}>
                              {({ input: filedInput, meta }) => (
                                <SingleHour>
                                  <TimeDescription>Capacity</TimeDescription>
                                  <InputField
                                    input={filedInput}
                                    meta={meta}
                                    icon={<Icon type={UsergroupAddOutline} />}
                                  />
                                </SingleHour>
                              )}
                            </Field>
                          )}

                          {index > 0 ? (
                            <HourPeriodsControlButton
                              icon="minus"
                              onClick={() => {
                                fields.remove(index);
                              }}
                            />
                          ) : (
                            <HourPeriodsControlButton
                              icon="plus"
                              onClick={() => fields.push(defaultPeriod)}
                            />
                          )}
                        </Row>
                      );
                    })}
                  </Col>
                </Row>
              </Row>
            </PanelCardMain>
          )}
        </FieldArray>
      ))}
    </RowStyled>
  );
};

export default WeekdayHourPeriods;
