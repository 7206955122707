import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import AppsLibraryListContainer from './apps-library-list/apps-library-list.container';
import AppsLibraryDetailsContainer from '../../../common/apps-library/gridapp-details/gridapp-details.container';

const AppsLibrary = (props: RouteComponentProps) => {
  const { match } = props;

  return (
    <Switch>
      <Route exact path={match.path} component={AppsLibraryListContainer} />
      <Route path={`${match.path}/:gridappId`} component={AppsLibraryDetailsContainer} />
    </Switch>
  );
};

export default AppsLibrary;
