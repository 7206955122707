import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Trash2 } from 'react-feather';
import { Button, Popconfirm, message, Tooltip } from 'antd';
import { Icon } from '../../../common/schema-form/common';
import { useDeviceBundleDelete } from '../../../common/use-device-bundle';
import styled from '@emotion/styled';
import { ButtonType } from '../../../../types/styled-component';

interface DeletePopconfirmProps {
  deviceBundleId: string;
  onDelete?: (status: boolean) => void;
}

const DeletePopconfirm = (props: DeletePopconfirmProps) => {
  const { deviceBundleId, onDelete = () => {} } = props;

  const refTooltip = useRef<Tooltip>(null);

  const [isDeleting, setIsDeleting] = useState<boolean>();

  const { t } = useTranslation();

  const { mutateAsync: deleteDeviceBundle, invalidateQueries } = useDeviceBundleDelete();

  const handleConfirmDelete = useCallback(
    async (deviceBundleId: string) => {
      try {
        setIsDeleting(true);

        onDelete(true);

        await deleteDeviceBundle(deviceBundleId);
        await invalidateQueries();

        message.success(t('deviceBundleConfig.successDeletedData'));
      } catch (e) {
        message.error(t('deviceBundleConfig.errorDeletingData'));

        setIsDeleting(false);
        onDelete(false);

        throw e;
      }
    },
    [deleteDeviceBundle, invalidateQueries, onDelete, t],
  );

  const handleVisibility = useCallback((isVisible: boolean) => {
    if (refTooltip.current && isVisible) {
      refTooltip.current.setState({ visible: false });
    }
  }, []);

  return (
    <Popconfirm
      title={t('deviceBundleConfig.areYouSureYouWantToDeleteThis')}
      onConfirm={() => handleConfirmDelete(deviceBundleId)}
      onVisibleChange={handleVisibility}
      okText={t('yes')}
      cancelText={t('no')}
      placement="topRight"
      disabled={isDeleting}
    >
      <Tooltip ref={refTooltip} title={isDeleting ? t('deleting') : t('deleteAlt')}>
        <ActionButton size="small" type="link">
          {isDeleting ? (
            <Icon type="loading" spin />
          ) : (
            <Icon component={() => <Trash2 color="#FF4D4F" />} />
          )}
        </ActionButton>
      </Tooltip>
    </Popconfirm>
  );
};

const ActionButton = styled(Button)`
  height: fit-content;
` as ButtonType;

export default React.memo(DeletePopconfirm);
