import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useApiService } from '../api-service-provider';
import { routes } from '../../../store/models/app-builds/app-builds.model';
import Build from '../../../store/types/build';
import toString from 'lodash/toString';

const buildQueryKey = (appId: string) => ['appBuilds', appId];

const buildBuildsQueryKey = (appId: string, limit: number, skip: number) => [
  ...buildQueryKey(appId),
  'limit',
  limit,
  'skip',
  skip,
];

const buildLatestDeployedBuildQueryKey = (appId: string, env: string) => [
  ...buildQueryKey(appId),
  'env',
  env,
  'latest',
];

const buildAppBuildQueryKey = (appId: string, buildId: string) => [
  ...buildQueryKey(appId),
  'buildId',
  buildId,
];

interface UseBuildsProps {
  appId: string;
  limit?: number;
  skip?: number;
}

export const useAppBuilds = ({ appId, limit = 50, skip = 0 }: UseBuildsProps) => {
  const apiService = useApiService();

  return useQuery(
    buildBuildsQueryKey(appId, limit, skip),
    () =>
      apiService.get<{ total: number; builds: Build[] }>(routes.getBuilds(appId), {
        limit: toString(limit),
        skip: toString(skip),
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseLatestDeployedBuildProps {
  appId: string;
  env: string;
}

export const useLatestDeployedBuild = ({ appId, env }: UseLatestDeployedBuildProps) => {
  const apiService = useApiService();

  return useQuery(
    buildLatestDeployedBuildQueryKey(appId, env),
    () => apiService.get<Build>(routes.getLatestDeployedBuild(appId, env)),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseDeployProps {
  buildId: string;
}

export const useDeploy = (appId: string, env: string) => {
  const apiService = useApiService();

  const queryClient = useQueryClient();

  return useMutation(
    ({ buildId }: UseDeployProps) =>
      apiService.post<void>(routes.deployBuild(buildId), {
        environmentName: env,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(buildQueryKey(appId));
      },
    },
  );
};

interface UseAppBuildByBuildIdProps {
  buildId: string;
  appId: string;
}

export const useAppBuildByBuildId = ({buildId, appId}:UseAppBuildByBuildIdProps) => {
  const apiService = useApiService();

  return useQuery(
    buildAppBuildQueryKey(appId,buildId),
    () => apiService.get<Build>(routes.getBuild(buildId)),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};


interface UseDeleteBuildProps {
  buildId: string;
}

export const useDeleteBuild = (appId: string) => {
  const apiService = useApiService();

  const queryClient = useQueryClient();

  return useMutation(
    ({ buildId }: UseDeleteBuildProps) =>
      apiService.delete<void>(routes.deleteBuild(buildId)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(buildQueryKey(appId));
      },
    },
  );
};
