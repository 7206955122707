import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import { RootModel, RootState } from '../../../../../../store/models/root.model';
import EnvVariables from './env-variables.component';
import usePermissions from '../../../../../../utils/auth/use-permissions';
import { permissionKeys } from '../../../../../../utils/auth/permissions';

type EnvVariablesContainerProps = Omit<
  React.ComponentProps<typeof EnvVariables>,
  | 'appVariables'
  | 'device'
  | 'loaded'
  | 'envVariables'
  | 'fetchEnvVariables'
  | 'updateEnvVariables'
  | 'error'
>;

const EnvVariablesContainer = (props: EnvVariablesContainerProps) => {
  const {
    match: {
      params: { deviceUuid, organisationId },
    },
  } = props;

  const { device, loaded, envVariables, appVariables, error } = useStore<RootState>(
    (state) => ({
      device: state.azureDeviceDetails.data[deviceUuid] || null,
      loaded: state.azureDeviceEnvVariables.loaded(deviceUuid),
      envVariables: state.azureDeviceEnvVariables.data[deviceUuid] || null,
      appVariables: state.azureDeviceEnvVariables.appVariables[deviceUuid] || [],
      error: state.azureDeviceEnvVariables.error[deviceUuid] || null,
    }),
    [deviceUuid],
  );

  const { fetchEnvVariables, updateEnvVariables } = useActions<RootModel>((actions) => ({
    fetchEnvVariables: actions.azureDeviceEnvVariables.fetch,
    updateEnvVariables: actions.azureDeviceEnvVariables.update,
  }));

  const { isAllowed } = usePermissions(organisationId);

  return (
    <EnvVariables
      {...props}
      appVariables={appVariables}
      envVariables={envVariables}
      device={device}
      fetchEnvVariables={fetchEnvVariables}
      updateEnvVariables={updateEnvVariables}
      loaded={loaded}
      error={error}
      canUpdate={isAllowed(permissionKeys.devices.updateDeviceVariables)}
    />
  );
};

export default EnvVariablesContainer;
