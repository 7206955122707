import Browser from '../../store/types/browser';

// Note: Temporarily sort alphabetically for now
export default function sortBrowsers(browsers: Browser[]) {
  return browsers.sort((a, b) => {
    if (a.status === 'OFFLINE' && b.status === 'OK') {
      return -1;
    }

    if (a.status === 'OK' && b.status === 'OFFLINE') {
      return 1;
    }

    if (
      a.status === 'OFFLINE' &&
      b.status === 'OFFLINE' &&
      a.lastSeenAt &&
      !b.lastSeenAt
    ) {
      return -1;
    }

    if (
      a.status === 'OFFLINE' &&
      b.status === 'OFFLINE' &&
      a.lastSeenAt &&
      b.lastSeenAt
    ) {
      return new Date(b.lastSeenAt).getTime() - new Date(a.lastSeenAt).getTime();
    }

    if (a.displayName < b.displayName) return -1;
    if (a.displayName > b.displayName) return 1;
    return 0;
  });
}
