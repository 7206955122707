import React from 'react';
import { Select } from 'antd';
import styled from '@emotion/styled';
import ReactCountryFlag from 'react-country-flag';
import LocaleCode from 'locale-code';
import { getShortLocaleCodeLabel } from '../../../utils/language/language.util';

interface LanguageSwitcherProps {
  value: string;
  options: string[];
  onChange: (lang: string) => void;
  fullLabel?: boolean;
  disabled?: boolean;
}

const OptionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const Label = styled.div`
  text-align: flex-start;
  font-size: 14px;
`;

const FlagIconWrapper = styled.div`
  align-items: center;
  justify-content: center;
  margin-top: -1px;
`;

const countryFlagStyle = {
  width: '24px',
  height: '16px',
  backgroundSize: 'cover',
  borderWidth: '2px',
  borderColor: 'blue',
  marginRight: '8px',
};

export default function LanguageSwitcher({
  value,
  options,
  onChange,
  fullLabel,
  disabled,
  ...props
}: LanguageSwitcherProps) {
  return (
    <Select
      {...props}
      id="language_switcher"
      onChange={(val: string) => onChange(val)}
      value={value}
      disabled={!!disabled}
    >
      {options.map((option) => (
        <Select.Option key={option}>
          <OptionWrapper>
            <FlagIconWrapper>
              <ReactCountryFlag
                svg
                code={LocaleCode.getCountryCode(option)}
                styleProps={countryFlagStyle}
              />
            </FlagIconWrapper>
            <Label>{fullLabel ? getShortLocaleCodeLabel(option) : option}</Label>
          </OptionWrapper>
        </Select.Option>
      ))}
    </Select>
  );
}
