import React, { useMemo } from 'react';
import { RouteComponentProps, Switch } from 'react-router-dom';
import ErrorBoundary from '../../../../common/error-boundary/error-boundary.component';
import ProtectedRouteContainer from '../../../../common/protected-route/protected-route.container';
import permissionKeys, { getPermissionPath } from '../../../../../utils/auth/permissions';
import StoreAIAssistantList from './store-ai-assistants-list/store-ai-assistants-list.component';
import StoreAIAssistantCreateForm from './store-ai-asssistant-forms/store-ai-assistant-create-form.component';
import StoreAIAssistantUpdateForm from './store-ai-asssistant-forms/store-ai-assistant-update-form.component';
import StoreAIAssistantViewForm from './store-ai-asssistant-forms/store-ai-assistant-view-form.component';
import ScrollContentToTop from '../../../../common/app-layout/content/scroll-content-to-top.component';
import usePermissions from '../../../../../utils/auth/use-permissions';

interface StoreAIAssistantProps extends RouteComponentProps<{ organisationId: string }> {}

const StoreAIAssistant = (props: StoreAIAssistantProps) => {
  const { match } = props;

  const { organisationId: tenantId } = match.params;

  const { isAllowed } = usePermissions(tenantId);

  const isUpdateAllowed = useMemo(() => isAllowed(permissionKeys.storeAIAssistants.update), [
    isAllowed,
  ]);

  const isViewAllowed = useMemo(() => isAllowed(permissionKeys.storeAIAssistants.viewSingle), [
    isAllowed,
  ]);

  return (
    <ErrorBoundary>
      <ScrollContentToTop />
      <Switch>
        <ProtectedRouteContainer
          exact
          permissionPath={getPermissionPath(tenantId, permissionKeys.storeAIAssistants.create)}
          path={`${match.path}/add`}
          component={StoreAIAssistantCreateForm}
        />
        <ProtectedRouteContainer
          exact
          permissionPath={getPermissionPath(tenantId, permissionKeys.storeAIAssistants.viewAll)}
          path={match.path}
          component={StoreAIAssistantList}
        />
        {isUpdateAllowed && (
          <ProtectedRouteContainer
            exact
            permissionPath={getPermissionPath(tenantId, permissionKeys.storeAIAssistants.update)}
            path={`${match.path}/:storeAIAssistantId`}
            component={StoreAIAssistantUpdateForm}
          />
        )}
        {isViewAllowed && (
          <ProtectedRouteContainer
            exact
            permissionPath={getPermissionPath(tenantId, permissionKeys.storeAIAssistants.viewSingle)}
            path={`${match.path}/:storeAIAssistantId`}
            component={StoreAIAssistantViewForm}
          />
        )}
      </Switch>
    </ErrorBoundary>
  );
};

export default StoreAIAssistant;
