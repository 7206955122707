import React from 'react';
import styled from '@emotion/styled';
import { Divider, Tag } from 'antd';
import { useTranslation } from 'react-i18next';

const SegmentConditionDivider = () => {
  const { t } = useTranslation();

  return (
    <SegmentConditionDividerContainer>
      <Divider className="ant-divider-horizontal-left" />

      <SegmentConditionOrTag>{t('contents.or')}</SegmentConditionOrTag>

      <Divider className="ant-divider-horizontal-right" />
    </SegmentConditionDividerContainer>
  )
}

export default SegmentConditionDivider;

const SegmentConditionDividerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: -20px;
  width: calc(100% + 70px);
  
  .ant-divider-horizontal-left {
    min-width: 0% !important;
  }

  .ant-divider-horizontal-right {
    min-width: 0% !important;
  }
`;

const SegmentConditionOrTag = styled(Tag)`
  margin-right: 0px !important;
`;
