import React, { useEffect } from 'react';
import { useActions, useStore } from 'easy-peasy';
import { RootModel, RootState } from '../../../../../store/models/root.model';
import DevicesUniversal from './devices-universal.component';
import { getPermissions } from '../../../../../utils/auth/permissions';
import { UniversalDeviceConnectParams } from '../../../../../store/models/app-devices-universal/app-devices-universal.model';

type DevicesContainerProps = Omit<
  React.ComponentProps<typeof DevicesUniversal>,
  'devices' | 'loaded' | 'lastUpdated' | 'fetchDevices' | 'app' | 'canCreate'
>;

const DevicesUniversalContainer = (props: DevicesContainerProps) => {
  const {
    match: {
      params: { appId, organisationId },
    },
  } = props;

  const {
    devices,
    loaded,
    lastUpdated,
    app,
    permissions,
    environments,
    isPaginationLoading,
    pagination,
    spaces,
  } = useStore<RootState>(
    (state) => {
      const organisationApp =
        (state.organisationApps.data &&
          state.organisationApps.data[organisationId] &&
          state.organisationApps.data[organisationId][appId]) ||
        null;

      return {
        permissions: getPermissions(state.auth.user, props.match.params.organisationId),
        environments: state.environments.values(organisationId),
        app: organisationApp,
        devices: organisationApp
          ? state.appDevicesUniversal.values(organisationApp.appName)
          : [],
        loaded: organisationApp
          ? state.appDevicesUniversal.loaded(organisationApp.appName)
          : false,
        lastUpdated: organisationApp
          ? state.appDevicesUniversal.lastUpdated[organisationApp.appName]
          : undefined,
        isPaginationLoading: organisationApp
          ? state.appDevicesUniversal.isPaginationLoading[organisationApp.appName]
          : false,
        pagination: organisationApp
          ? state.appDevicesUniversal.pagination[organisationApp.appName]
          : {},
        spaces: state.organisationSpaces.values(organisationId),
      };
    },
    [appId, organisationId],
  );

  const {
    fetchDevices,
    createDevice,
    fetchEnvironments,
    disconnectDevice,
    fetchSpaces,
  } = useActions<RootModel>((actions) => ({
    fetchDevices: actions.appDevicesUniversal.fetch,
    createDevice: actions.appDevices.create,
    fetchEnvironments: actions.environments.fetch,
    disconnectDevice: async (params: UniversalDeviceConnectParams) => {
      await actions.appDevicesUniversal.disconnect(params);

      if (app) {
        await actions.appDevicesUniversal.fetch({
          appName: app.appName,
          page: 1,
          pageSize: pagination ? pagination.limit : 5,
        });
      }
    },
    fetchSpaces: actions.organisationSpaces.fetch,
  }));

  useEffect(() => {
    fetchSpaces({ silent: true, organizationId: organisationId });
  }, [fetchSpaces, organisationId]);

  return (
    <DevicesUniversal
      {...props}
      app={app}
      environments={environments}
      devices={devices}
      lastUpdated={lastUpdated}
      loaded={loaded}
      isPaginationLoading={isPaginationLoading}
      fetchDevices={fetchDevices}
      createDevice={createDevice}
      fetchEnvironments={fetchEnvironments}
      canCreate={permissions.devices.create}
      disconnectDevice={disconnectDevice}
      pagination={pagination}
      spaces={spaces}
    />
  );
};

export default DevicesUniversalContainer;
