import i18next from 'i18next';
import { requiredAll } from '../../organisations/organisation-details/queue-details/queue-setup/validate-form';

 export type Validator<T = string> = (value: T) => string | undefined;

 export const URL_REGEX = '^(https?:\\/\\/)?((([-a-z0-9]{1,63}\\.)*?[a-z0-9]([-a-z0-9]{0,253}[a-z0-9])?\\.[a-z]{2,63})|((\\d{1,3}\\.){3}\\d{1,3}))(:\\d{1,5})?((\\/|\\?)((%[0-9a-f]{2})|[-\\w\\+\\.\\?\\/@~#&=])*)?$';

export const composeValidators = <T>(validators: (Validator<T> | undefined)[]) => (
  value: T,
): string | undefined => {
  return validators.filter(Boolean).reduce((error: string | undefined, validator) => {
    if (validator) {
      return error || validator(value);
    }

    return error;
  }, undefined);
};

export const validateCharacterLength = (min: number, max: number) => (value: string): string | undefined => {
  if(!value){
    return i18next.t('formValidation.characterMinMaxLength', { min, max })
  } else {
    return value.length < min || value.length > max
      ? i18next.t('formValidation.characterMinMaxLength', { min, max })
      : undefined;
  }
};

// for empty string, the validation will return true and will only check for min/max when there's some value
export const optionalCharacterLength = (min: number, max: number) => (value: string) => {
  return !value || !value.length
    ? undefined
    : validateCharacterLength(min, max)(value);
}

export const validateMaxLength = (max: number) => (value: string) => {
  return value && value.length > max
    ? i18next.t('formValidation.characterMaxLength', { max })
    : undefined;
}

export const validateEntriesMaxLength = <T>(max: number) => (value: T) => {
  const message =
    max > 1
      ? i18next.t('formValidation.maxEntries', { max })
      : i18next.t('formValidation.maxEntry', { max });

  return Array.isArray(value) && value.length > max ? message : undefined;
};

export const validateSimpleId = (value: string): string | undefined => {
  const pattern = /^[A-Za-z0-9_-]+$/;

  return pattern.test(value)
    ? undefined
    : i18next.t('formValidation.invalidSimpleId');
};

export const validateStandardName = (value: string): string | undefined => {
  const pattern = /^[A-Za-z0-9_ -]+$/;

  return pattern.test(value)
    ? undefined
    : i18next.t('formValidation.invalidStandardName');
};

// for empty string, the validation will return true and will only check for standard name when there's some value
export const validateOptionalStandardName = (value: string): string | undefined => {
  return !value || !value.length
    ? undefined
    : validateStandardName(value);
}

export const validateMinimumRequired = (min: number) => (value: any): string | undefined => {
  const message = min > 1 ? i18next.t('formValidation.atLeastMoreThanOneEntriesRequired') : i18next.t('formValidation.atLeastOneEntryRequired');

  return value && value.length >= min ? undefined : message;
};

export const validateByRegExPattern = (regEx: string, errorMessage: string) => (value: any): string | undefined => {
  const regExPattern = new RegExp(regEx, 'gm');
  return regExPattern.test(value) ? undefined : errorMessage;
};

export const validateRequired = (errorMessage?: string) => (value: any): string | undefined => {
  const message = requiredAll(value);

  if (message) {
    return errorMessage || message;
  }

  return undefined;
};
