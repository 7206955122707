import {
  GridProduct,
  IsoLanguageIds,
  ProductFeature,
  ProductPriceList,
} from '@ombori/grid-products';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import isNil from 'lodash/isNil';
import {
  GridProductsFormValuesSubmit,
  ProductFeaturesGrouping,
  ProductPriceListWithVariantOption,
  VariantOptionObj,
} from '../grid-products-details/grid-products-details.component';

export const DEFAULT_PRODUCTS_ENVIRONMENT = 'prod';

export function getBestMatchByLanguage<T extends { isoLanguageId: string }>(
  items: (T)[],
  language?: string,
): T {
  const bestMatch = language
    ? items.find((item) => `${item.isoLanguageId}`.startsWith(language))
    : null;
  const firstEnOption = items.find((item) => `${item.isoLanguageId}`.startsWith('en'));
  const firstOption = items[0];

  return bestMatch || firstEnOption || firstOption;
}

export interface GetFormPathByLanguageProps<T> {
  items?: T[];
  language: string;
  propertyName: string;
  fieldName?: string;
  nestedPropertyName?: string;
}

export function getFormPathByLanguage<T extends { isoLanguageId: string }>({
  items,
  language,
  propertyName,
  fieldName,
  nestedPropertyName,
}: GetFormPathByLanguageProps<T>): string {
  const getIndex = () => {
    if (!items || items.length === 0) {
      return 0;
    }
    const index = items.findIndex((item) => `${item.isoLanguageId}`.startsWith(language));
    return index;
  };

  const index = getIndex();
  // we assume item was added by isReadyToShowTheItem check on the previous step
  if (index < 0) {
    throw new Error('Can not find item by the language');
  }

  return `${nestedPropertyName || propertyName}[${index}].${fieldName || propertyName}`;
}

export const getDataForSelectMapper = (i: string) => {
  return { value: i, label: i.toUpperCase() };
};

const convertNilToEmptyString = (value: string): string => (isNil(value) ? '' : value);

export const isMatchingVariantOptions = (
  variantOptions1: Partial<VariantOptionObj>,
  variantOptions2: Partial<VariantOptionObj>,
) => {
  return (
    convertNilToEmptyString(get(variantOptions1, 'color', '')) ===
      convertNilToEmptyString(get(variantOptions2, 'color', '')) &&
    convertNilToEmptyString(get(variantOptions1, 'size', '')) ===
      convertNilToEmptyString(get(variantOptions2, 'size', '')) &&
    convertNilToEmptyString(get(variantOptions1, 'style', '')) ===
      convertNilToEmptyString(get(variantOptions2, 'style', ''))
  );
};

export const getMatchingProductId = (
  formValues: GridProductsFormValuesSubmit,
  values: Partial<VariantOptionObj>,
): string => {
  const matchedVariant = formValues.variants.find((variant) =>
    isMatchingVariantOptions(variant, values),
  );

  if (matchedVariant) {
    return matchedVariant.productId;
  }

  return '';
};

export const getVariantOptionLabel = ({ color, size, style }: VariantOptionObj) => {
  const variantLabel =
    [color, size, style].filter((item) => !!item).join('/') || 'Default';

  return variantLabel;
};

export const getCurrProductFeaturesGrouping = (
  currProduct: Partial<GridProduct>,
): ProductFeaturesGrouping[] => {
  if (!currProduct) {
    return [];
  }

  const groupedDataKey: Record<string, ProductFeature[]> = groupBy(
    currProduct.productFeature,
    'productFeatureType',
  );

  const groupedDataKeyByLanguage = map(groupedDataKey, (value, id) => {
    const groupedLanguage = groupBy(value, 'isoLanguageId');

    const currLanguageValues: {
      isoLanguageId: IsoLanguageIds;
      productFeatureValueGrouped: string;
    }[] = map(groupedLanguage, (langValue, langId) => {
      const uniqValues: string[] = uniq(
        langValue.map((item) => item.productFeatureValue),
      );

      return {
        isoLanguageId: langId as IsoLanguageIds,
        productFeatureValueGrouped: uniqValues.join(','),
      };
    });
    return { productFeatureType: id, valuesByLanguage: currLanguageValues };
  });

  return groupedDataKeyByLanguage;
};

export const removeVariantOptionPriceList = (
  priceList: ProductPriceListWithVariantOption[],
): ProductPriceList[] =>
  priceList.map((item) => ({
    isoCurrencyCode: item.isoCurrencyCode,
    listPrice: item.listPrice,
    priceListType: item.priceListType,
    productId: item.productId,
    spaceId: item.spaceId,
    periodEndTimestamp: item.periodEndTimestamp,
    periodStartTimestamp: item.periodStartTimestamp,
    pricingUomId: item.pricingUomId,
    suggestedRetailPrice: item.suggestedRetailPrice,
  }));
