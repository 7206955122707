import { TFunction } from 'i18next';

type BuildSchemaResponse = Record<string, string | number | boolean | object>;

export const buildFieldSchema = (t: TFunction): BuildSchemaResponse => {
  const fieldSchema = {
    type: 'object',
    properties: {
      colors: {
        type: 'object',
        title: t('brandingColors.title'),
        properties: {
          primary: {
            type: 'object',
            title: t('brandingColors.primaryTitle'),
            description: t('brandingColors.primaryDescription'),
            default: {
              mainColor: '#5F06E4',
              contrastColor: '#FAFAFA',
            },
          },
          secondary: {
            type: 'object',
            title: t('brandingColors.secondaryTitle'),
            description: t('brandingColors.secondaryDescription'),
            default: {
              mainColor: '#DCD30D',
              contrastColor: '#111111',
            },
          },
          success: {
            type: 'object',
            title: t('brandingColors.successTitle'),
            description: t('brandingColors.successDescription'),
            default: {
              mainColor: '#00AE48',
              contrastColor: '#FFFFFF',
            },
          },
          warning: {
            type: 'object',
            title: t('brandingColors.warningTitle'),
            description: t('brandingColors.warningDescription'),
            default: {
              mainColor: '#FFC107',
              contrastColor: '#111111',
            },
          },
          danger: {
            type: 'object',
            title: t('brandingColors.dangerTitle'),
            description: t('brandingColors.dangerDescription'),
            default: {
              mainColor: '#F02424',
              contrastColor: '#FFFFFF',
            },
          },
        },
      },
    },
  };

  return fieldSchema;
};

export const buildUiSchema = (t: TFunction): BuildSchemaResponse => {
  const metaSchema = {
    objectFieldTemplate: 'none',
    colors: {
      objectFieldTemplate: 'accordion',
      'ui:options': {
        isContentPadded: false,
        hasDivider: false,
        gap: 16,
      },
      primary: {
        'ui:field': 'colorPicker',
        'ui:options': {
          mainColorTitle: t('brandingColors.primaryColorLabel'),
          editButton: {
            text: t('brandingColors.primaryColorEdit'),
          }
        },
      },
      secondary: {
        'ui:field': 'colorPicker',
        'ui:options': {
          mainColorTitle: t('brandingColors.secondaryColorLabel'),
          editButton: {
            text: t('brandingColors.secondaryColorEdit'),
          }
        }
      },
      success: {
        'ui:field': 'colorPicker',
        'ui:options': {
          mainColorTitle: t('brandingColors.successColorLabel'),
          editButton: {
            text: t('brandingColors.successColorEdit'),
          }
        }
      },
      warning: {
        'ui:field': 'colorPicker',
        'ui:options': {
          mainColorTitle: t('brandingColors.warningColorLabel'),
          editButton: {
            text: t('brandingColors.warningColorEdit'),
          }
        }
      },
      danger: {
        'ui:field': 'colorPicker',
        'ui:options': {
          mainColorTitle: t('brandingColors.dangerColorLabel'),
          editButton: {
            text: t('brandingColors.dangerColorEdit'),
          }
        }
      },
    },
  };

  return metaSchema;
};
