import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryObserverResult } from 'react-query';
import { GridStyles } from '@ombori/grid-reports';
import groupBy from 'lodash/groupBy';
import flatMap from 'lodash/flatMap';
import { DeviceMediaByDay, useDevicesMediaByDay } from '../../../use-analytics-report';
import Table, { TableCell, TableHeader, TableRow, useTablePagination } from './table';
import CardContainer from '../card-container';
import buildData from './build-data';
import { DataResidencyEnum } from '../../../../../store/types/organisation';
import { Link } from 'react-router-dom';
import UniversalDevice from '../../../../../store/types/universal-device';
import { prepareDateRangeSearchParams } from '../../utils';

const aggregateDataByDevice = (rawData: DeviceMediaByDay[]) => {
  const aggregatedEvents = groupBy(rawData, (dataItem) => dataItem.deviceId);

  const initialResult = {
    playsCount: 0,
    playsDuration: 0,
  };

  const result = buildData(aggregatedEvents).reduce((accumulator, item) => {
    accumulator.playsCount += item.playsCount;
    accumulator.playsDuration += item.playsDuration;

    return accumulator;
  }, initialResult);

  return result;
};

interface ViewByDeviceProps {
  tenantId: string;
  dateFrom: string;
  dateTo: string;
  dataResidency: DataResidencyEnum;
  gridStyles?: GridStyles;
  isVisibleWithoutData?: boolean;
  title?: string;
  devicesFetchingState: QueryObserverResult<UniversalDevice[], unknown>;
}

const ViewByDevice: React.FC<ViewByDeviceProps> = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  devicesFetchingState,
  title,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { t } = useTranslation();

  const devices = useMemo(() => devicesFetchingState.data || [], [devicesFetchingState]);

  const { currentPage, onChangePage, totalPages, getDataByPage } = useTablePagination(
    devices.length,
  );

  const pageDevices = useMemo(
    () => getDataByPage({ data: devices, currentPage, totalPages }),
    [currentPage, devices, totalPages, getDataByPage],
  );

  const mediaFetchingState = useDevicesMediaByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
    devicesIds: pageDevices.map((device) => device.uuid),
  });

  const mediaData = useMemo(
    () =>
      groupBy(
        flatMap(mediaFetchingState, (item) => item.data || []),
        (item) => item.deviceId,
      ),
    [mediaFetchingState],
  );

  const searchParams = useMemo(() => {
    return prepareDateRangeSearchParams(dateFrom, dateTo);
  }, [dateFrom, dateTo]);

  const isAnyMediaLoading = mediaFetchingState.some((item) => item.isLoading);
  const isAnyMediaError = mediaFetchingState.some((item) => item.isError);
  const isEveryMediaSuccess = mediaFetchingState.every((item) => item.isSuccess);

  const isLoading = devicesFetchingState.isLoading || isAnyMediaLoading;
  const isError = devicesFetchingState.isError || isAnyMediaError;
  const isSuccess = devicesFetchingState.isSuccess && isEveryMediaSuccess;

  const hasData = devices.length > 0;

  const aggregatedData = useMemo(
    () =>
      pageDevices.map((device) => {
        const deviceData = mediaData[device.uuid] || [];

        const { playsCount, playsDuration } = aggregateDataByDevice(deviceData);

        return {
          playsCount,
          playsDuration,
          device,
        };
      }),
    [pageDevices, mediaData],
  );

  const rows = useMemo(
    () =>
      aggregatedData.map((item, index) => {
        return (
          <TableRow key={index}>
            <TableCell>
              <Link
                to={{ pathname: `/organisations/${tenantId}/devices/v3/${item.device.uuid}/overview`,  search: `${searchParams}` }}
              >
                {item.device.deviceName}
              </Link>
            </TableCell>

            <TableCell>{item.playsCount}</TableCell>

            <TableCell>{Math.round(item.playsDuration / 60000)}</TableCell>
          </TableRow>
        );
      }),
    [aggregatedData, tenantId, searchParams],
  );

  const headers = [t('device'), t('mediaPlaysCount'), t('mediaPlaysDuration')];

  return (
    <CardContainer
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      hasData={hasData}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {devicesFetchingState.isSuccess && (
        <Table
          header={<TableHeader columns={headers} />}
          rows={rows}
          currentPage={currentPage}
          total={devices.length}
          onChangePage={onChangePage}
        />
      )}
    </CardContainer>
  );
};

export default ViewByDevice;
