export const stringToColour = (str: string): string => {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    // eslint-disable-next-line
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let colour = '#';
  for (let i = 0; i < 3; i += 1) {
    // eslint-disable-next-line
    const value = (hash >> (i * 8)) & 0xff;
    colour += `00${value.toString(16)}`.substr(-2);
  }

  return colour;
};

export const convertHexToRGBA = (
  hexCode: string,
  opacity: number = 100,
): { r: number; g: number; b: number; a: number } => {
  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return { r, g, b, a: opacity / 100 };
};

export const colorInverse = (
  backgroundColor: string,
  darkColor: string = '#010101a6',
  lightColor: string = '#FFFFFF',
): string => {
  const { r, g, b } = convertHexToRGBA(backgroundColor);
  const brightness = r * 0.2126 + g * 0.7152 + b * 0.0722;

  return brightness < 140 ? lightColor : darkColor;
};
