import { get } from 'lodash';
import Contact from '../../../../store/types/contact';

enum FIXED_META_KEYS {
  FIRSTNAME = 'FIRSTNAME',
  LASTNAME = 'LASTNAME',
}
type FIXED_META_KEYS_TYPE = keyof typeof FIXED_META_KEYS;

export const getInfoFromIdentities = (contact: Contact) => {
  const { identities } = contact;
  if (!identities || identities.length === 0) {
    return [];
  }
  const desiredInfo = identities.filter(
    (identity) => identity.key === 'PHONE' || identity.key === 'EMAIL',
  );

  return desiredInfo;
};

export const getInfoFromMetadata = (contact: Contact, keys?: FIXED_META_KEYS_TYPE[]) => {
  const { meta } = contact;
  if (!meta) {
    return [];
  }
  const getFilter = () => {
    if (keys) {
      const func = (metaKeyName: string) => keys.find((key) => key === metaKeyName);
      return func;
    }
    return () => true;
  };

  const results = Object.keys(meta)
    .filter(getFilter())
    .map((key) => ({ key, data: meta[key] }));

  return results;
};

export const getFirstNameFromContact = (contact: Contact) => {
  const firstNameObj = getInfoFromMetadata(contact, [FIXED_META_KEYS.FIRSTNAME])[0];

  return get(firstNameObj, 'data.value');
};

export const getLastNameFromContact = (contact: Contact) => {
  const lastNameObj = getInfoFromMetadata(contact, [FIXED_META_KEYS.LASTNAME])[0];

  return get(lastNameObj, 'data.value');
};
