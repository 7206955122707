import { ChannelFormTag } from "./channel-form-fields.component";
import { ContentTagType } from "../../../../../store/common/content-tags/content-tag-type";
import ContentTag from "../../../../../store/types/content-tag";
import { Option } from "../../../../common/react-final-form";
import { TFunction } from "i18next";

export function convertFormTagsToChannelTags(formTags: string[] = []) {
  const channelTags = formTags.map<ChannelFormTag>((formTag) => {
    if (formTag === ContentTagType.ORIENTATION) {
      return { tagType: ContentTagType.ORIENTATION, tagId: formTag };
    } else {
      return { tagType: ContentTagType.CONTENT_TAG, tagId: formTag };
    }
  });

  return channelTags;
}

export function prepareTagOptions(tags: ContentTag[], t: TFunction): Option[] {
  const baseTags: Option[] = [
    { label: t('contents.orientation'), value: ContentTagType.ORIENTATION },
  ];

  if (!tags) {
    return baseTags;
  }

  const contentTags = tags.map<Option>((tag) => {
    return { label: tag.name, value: tag.id };
  });

  return [...baseTags, ...contentTags];
}
