import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import { get } from 'lodash';
import { RootModel, RootState } from '../../../../../../store/models/root.model';
import { KEY_ACCOUNT_BALANCE } from '../../../../../../store/models/organization-account-balance/organization-account-balance.model';
import AccountBalance from './account-balance.component';

interface AccountBalanceContainerProps {
  organizationId: string;
}

const AccountBalanceContainer = (props: AccountBalanceContainerProps) => {
  const { organizationId } = props;

  const { loading, error, organizationAccountBalance } = useStore<RootState>((state) => {
    const key = KEY_ACCOUNT_BALANCE;

    return {
      loading: state.organizationAccountBalance.loading[key],
      error: state.organizationAccountBalance.error[key],
      organizationAccountBalance: get(
        state.organizationAccountBalance.data,
        `${key}.${organizationId}`,
      ),
    };
  }, []);

  const { fetchAccountBalance, addVoucher } = useActions<RootModel>((actions) => ({
    fetchAccountBalance: actions.organizationAccountBalance.fetchAccountBalance,
    addVoucher: actions.organizationAccountBalance.addVoucher,
  }));

  return (
    <AccountBalance
      {...props}
      loading={loading}
      error={error}
      accountBalance={organizationAccountBalance}
      fetchAccountBalance={fetchAccountBalance}
      addVoucher={addVoucher}
    />
  );
};

export default AccountBalanceContainer;
