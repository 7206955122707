import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Tag } from 'antd';
import { IntegrationStatusTagEnum } from '../types';

interface IntegrationStatusTagProps {
  status: IntegrationStatusTagEnum;
}

const IntegrationStatusTag = (props: IntegrationStatusTagProps) => {
  const { status } = props;

  const { t } = useTranslation();

  switch (status) {
    case IntegrationStatusTagEnum.SUCCESS:
      return <SuccessTag>{t('productIntegration.success')}</SuccessTag>;
    case IntegrationStatusTagEnum.FAILED:
      return <FailedTag>{t('productIntegration.failed')}</FailedTag>;
    case IntegrationStatusTagEnum.INPROGRESS:
      return <InProgressTag>{t('productIntegration.inProgress')}</InProgressTag>;
    default:
      return <UnknownTag>{t('productIntegration.unknown')}</UnknownTag>;
  }
};

const BaseTag = styled(Tag)`
  border-radius: 40px;
  padding: 1px 8px;
  font-size: 12px;
`;

const SuccessTag = styled(BaseTag)`
  background-color: #d9f7be;
  color: #135200;
  border-color: #135200;
  border-width: 0px;
`;

const FailedTag = styled(BaseTag)`
  background-color: #ffa39e;
  color: #a8071a;
  border-color: #a8071a;
  border-width: 0px;
`;

const InProgressTag = styled(BaseTag)`
  background-color: #f0f5ff;
  color: #2f54eb;
  border-color: #2f54eb;
  border-width: 0.5px;
`;

const UnknownTag = styled(BaseTag)`
  border-width: 0.5px;
`;

export default React.memo(IntegrationStatusTag);
