import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Row, Typography } from 'antd';
import { ArrayFields } from '../../../../common/react-final-form';
import Select, { SelectProps } from '../../../../common/react-final-form/select';
import { useAlertActionGroup } from '../../../../../store/hooks/alert-action-groups';

interface AlertRuleFormActionsSectionProps {
  tenantId: string;
}

const AlertRuleFormActionsSection = (
  props: AlertRuleFormActionsSectionProps,
) => {
  const { tenantId } = props;

  const { t } = useTranslation();

  const { data: actionsData } = useAlertActionGroup({ tenantId, limit: 1000 });

  const actionsOptions = useMemo<SelectProps['options']>(() => {
    if (!actionsData) {
      return [];
    }

    return actionsData.docs.map((action) => ({
      value: action.id,
      label: action.displayName,
    }));
  }, [actionsData]);

  return (
    <>
      <Typography.Title level={4}>Actions</Typography.Title>
      <ActionsContainer>
        <ArrayFields
          propertyName="actions"
          className='actions-container'
          addButtonLabel={t('alertRules.addActionLabel')}
          itemRowContent={(parentPropertyName, index) => (
            <ActionContainer>
              <FieldsContainer>
                <ActionNumber>
                  {t('alertRules.actionN', { n: `${index + 1}` })}
                </ActionNumber>
                <Select
                  name={`${parentPropertyName}.actionId`}
                  options={actionsOptions}
                  isRequired={true}
                />
              </FieldsContainer>
            </ActionContainer>
          )}
        />
      </ActionsContainer>
    </>
  );
};

export default AlertRuleFormActionsSection;

const ActionsContainer = styled.div`
  .actions-container .array-fields-delete-btn-container {
    align-self: start;
    transform: translateY(58px);
  }
`;

const ActionContainer = styled.div`
  width: 100% !important;
  margin-top: 15px;
`;

const ActionNumber = styled.h5`
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 0px;
`;

const FieldsContainer = styled(Row)`
  width: 100% !important;
  padding-right: 20px;
`;
