import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import SolutionDetails from './solution-details.component';
import { RootModel, RootState } from '../../../../../store/models/root.model';
import { AppInstallBody } from '../../../../../store/models/organisation-apps/organisation-apps.model';

type SolutionDetailsContainerProps = Omit<
  React.ComponentProps<typeof SolutionDetails>,
  'solution'
>;

const SolutionDetailsContainer = (props: SolutionDetailsContainerProps) => {
  const {
    match: {
      params: { solutionId, organisationId },
    },
  } = props;

  const { fetchApps, installApp, installQueue, fetchMarketplaceSolutions } = useActions<
    RootModel
  >((actions) => ({
    fetchApps: actions.organisationApps.fetch,
    installApp: (data: AppInstallBody) => actions.organisationApps.install(data),
    installQueue: (body: any) => actions.queues.install({ organisationId, body }),
    fetchMarketplaceSolutions: actions.marketplaceSolutions.fetch,
  }));

  const { organisation, solution, loaded } = useStore<RootState>(
    (state) => ({
      organisation: state.organisations.data && state.organisations.data[organisationId],
      solution: state.marketplaceSolutions.data[organisationId]
        ? state.marketplaceSolutions.data[organisationId].find(
            (marketplaceSolution) => marketplaceSolution.id === solutionId,
          )
        : null,
      loaded: state.marketplaceSolutions.loaded(organisationId),
    }),
    [organisationId, solutionId],
  );

  return (
    <SolutionDetails
      {...props}
      fetchMarketplaceSolutions={fetchMarketplaceSolutions}
      organisation={organisation}
      solution={solution}
      loaded={loaded}
      fetchApps={fetchApps}
      installApp={installApp}
      installQueue={installQueue}
    />
  );
};

export default SolutionDetailsContainer;
