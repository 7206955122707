import styled from '@emotion/styled';
import { Button } from 'antd';
import React from 'react';

const AvatarContainer = styled(Button)`
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  border-radius: 50%;
  pointer-events: none;
` as any;

interface AvatarProps {
  initials: string;
}

const Avatar: React.FC<AvatarProps> = ({ initials }) => {
  return <AvatarContainer type="primary">{initials}</AvatarContainer>;
};

export default Avatar;
