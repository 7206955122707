import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Icon as AntdIcon, Row, Col, Tooltip, Typography } from 'antd';
import styled from '@emotion/styled';
import { FieldProps } from 'react-jsonschema-form';
import { HelpCircle } from 'react-feather';
import trim from 'lodash/trim';
import { Edit } from 'react-feather';
import { ColorResult } from 'react-color';
import SketchColorPicker from './sketch-color-picker.component';
import { ColorSet, convertColorResultToColorSet, getColorSet } from './util';
import { Button, Container, Text } from '../../common';
import { ChevronUp } from 'react-feather';

const { Title: AntdTitle } = Typography;

const DEFAULT_MAIN_COLOR = '#000000';
const DEFAULT_CONTRAST_COLOR = '#FFFFFF';

interface ColorPickerUiOptions {
  isBordered?: boolean;
  mainColorTitle?: string;
  contrastColorTitle?: string;
  editButton?: {
    text?: string;
    icon?: string;
  };
}

interface Color {
  mainColor: string;
  contrastColor: string;
}

interface ColorPickerProps extends FieldProps<Color> {
  rawErrors?: string[];
}

const ColorPicker = (props: ColorPickerProps) => {
  const {
    rawErrors = [],
    formData,
    onChange,
    schema,
    uiSchema,
    required,
    readonly,
  } = props;

  const { isBordered = true, mainColorTitle, contrastColorTitle, editButton } = (uiSchema[
    'ui:options'
  ] || {}) as ColorPickerUiOptions;
  const helpText = uiSchema['ui:help'] as string;
  const {
    mainColor: defaultMainColor,
    contrastColor: defaultContrastColor,
  } = (schema.default || {}) as unknown as Color;

  const [isEditColor, setIsEditColor] = useState<boolean>(false);

  const { t } = useTranslation();

  const { initialMainColor, initialContrastColor } = useMemo<{
    initialMainColor: ColorSet;
    initialContrastColor: ColorSet;
  }>(() => {
    return {
      initialMainColor: getColorSet(defaultMainColor, DEFAULT_MAIN_COLOR),
      initialContrastColor: getColorSet(defaultContrastColor, DEFAULT_CONTRAST_COLOR),
    };
  }, [defaultMainColor, defaultContrastColor]);

  const [colorMain, setColorMain] = useState<ColorSet>(initialMainColor);
  const [colorContrast, setColorContrast] = useState<ColorSet>(initialContrastColor);

  const editButtonIcon = editButton && editButton.icon;
  const editButtonText = (editButton && editButton.text) || t('colorPicker.edit');

  useEffect(() => {
    if (formData && Object.keys(formData).length) {
      setColorMain((prevState) =>
        formData.mainColor ? getColorSet(formData.mainColor, DEFAULT_MAIN_COLOR) : prevState,
      );
      setColorContrast((prevState) =>
        formData.contrastColor
          ? getColorSet(formData.contrastColor, DEFAULT_CONTRAST_COLOR)
          : prevState,
      );
    }
  }, [formData, onChange]);

  useEffect(() => {
    if (!formData || !Object.keys(formData).length) {
      onChange({
        mainColor: initialMainColor.value,
        contrastColor: initialContrastColor.value,
      });
    }
  }, [formData, onChange, initialMainColor, initialContrastColor]);

  const errors = useMemo(() => {
    return rawErrors
      .map((err) => trim(err, '.'))
      .join(',')
      .toLowerCase();
  }, [rawErrors]);

  const handleEdit = useCallback(() => setIsEditColor((prevState) => !prevState), []);

  const handleMainColorChange = useCallback((color: ColorResult) => {
    const colorValue = convertColorResultToColorSet(color);
    setColorMain(colorValue);

    onChange({
      ...(formData || {}),
      ...(colorValue.value ? { mainColor: colorValue.value } : {}),
    });
  }, [formData, onChange]);

  const handleContrastColorChange = useCallback((color: ColorResult) => {
    const colorValue = convertColorResultToColorSet(color);
    setColorContrast(colorValue);

    onChange({
      ...(formData || {}),
      ...(colorValue.value ? { contrastColor: colorValue.value } : {}),
    });
  }, [formData, onChange]);

  const handleClose = useCallback(() => {
    setIsEditColor(false);
  }, []);

  return (
    <Container $isBordered={isBordered} $isDisabled={readonly}>
      <SectionPadded>
        <TitleWrap>
          {schema.title && <Title>{schema.title}{required ? ' *' : ''}</Title>}
          {helpText && (
            <div>
              <Tooltip
                placement="left"
                title={<div dangerouslySetInnerHTML={{ __html: helpText }} />}
              >
                <Icon size={18} component={() => <HelpCircle />} />
              </Tooltip>
            </div>
          )}
        </TitleWrap>
        {schema.description && (
          <Description dangerouslySetInnerHTML={{ __html: schema.description }} />
        )}
        {errors && <ErrorAlert message={errors} type="error" showIcon />}
        {!isEditColor && (
          <>
            <Preview onClick={handleEdit}>
              <ColorBoxWrap>
                <Text type="secondary" size={12} weight={300}>
                  {t('colorPicker.preview')}
                </Text>
                <ColorBox>
                  <BackgroundColor color={colorMain.value} />
                  <ForegroundColor color={colorContrast.value} />
                </ColorBox>
              </ColorBoxWrap>
              <PreviewColorInfo>
                <Row>
                  <Col xs={24} md={7} lg={7}>
                    <TextLabel strong>
                      {mainColorTitle || t('colorPicker.mainColorLabel')}
                    </TextLabel>
                  </Col>
                  <Col xs={24} md={17} lg={17}>
                    <TextColor weight={300}>{colorMain.value}</TextColor>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={7} lg={7}>
                    <TextLabel strong>
                      {contrastColorTitle || t('colorPicker.contrastColorLabel')}
                    </TextLabel>
                  </Col>
                  <Col xs={24} md={17} lg={17}>
                    <TextColor weight={300}>{colorContrast.value}</TextColor>
                  </Col>
                </Row>
              </PreviewColorInfo>
            </Preview>
            {!readonly && (
              <EditButton type="default" onClick={handleEdit}>
                {editButtonIcon ? (
                  <Icon size={18} type={editButtonIcon} />
                ) : (
                  <Icon size={18} component={() => <Edit />} />
                )}
                {editButtonText}
              </EditButton>
            )}
          </>
        )}
      </SectionPadded>
      {isEditColor && (
        <SectionNonPadded>
          <EditBox>
            <Text strong>{t('colorPicker.editTitle')}</Text>
            <Description
              dangerouslySetInnerHTML={{ __html: t('colorPicker.editDescription') }}
            />
            <Row gutter={[10, 10]}>
              <Col xs={24} md={12} lg={12}>
                <SketchColorPicker
                  initialColor={colorMain.value}
                  onChangeComplete={handleMainColorChange}
                  isEnabled={!readonly}
                >
                  {(colorResult: ColorResult) => {
                    return (
                      <FieldWrap>
                        <FieldLabel>
                          {mainColorTitle || t('colorPicker.editMainColorLabel')}
                        </FieldLabel>
                        <FieldInputWrap>
                          <ColorBoxSmall>
                            <BackgroundColor color={colorResult.hex} />
                          </ColorBoxSmall>
                          <FieldInput readOnly value={colorResult.hex} />
                        </FieldInputWrap>
                      </FieldWrap>
                    );
                  }}
                </SketchColorPicker>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <SketchColorPicker
                  initialColor={colorContrast.value}
                  onChangeComplete={handleContrastColorChange}
                  isEnabled={!readonly}
                >
                  {(colorResult: ColorResult) => {
                    return (
                      <FieldWrap>
                        <FieldLabel>
                          {contrastColorTitle || t('colorPicker.editContrastColorLabel')}
                        </FieldLabel>
                        <FieldInputWrap>
                          <ColorBoxSmall>
                            <BackgroundColor color={colorResult.hex} />
                          </ColorBoxSmall>
                          <FieldInput readOnly value={colorResult.hex} />
                        </FieldInputWrap>
                      </FieldWrap>
                    );
                  }}
                </SketchColorPicker>
              </Col>
            </Row>
          </EditBox>
          <ButtonWrap>
            <Button type="secondary" onClick={handleClose}>
              <Icon size={18} component={() => <ChevronUp />} />
              {t('filePicker.close')}
            </Button>
          </ButtonWrap>
        </SectionNonPadded>
      )}
    </Container>
  );
};

const EditBox = styled.div`
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  padding: 12px 16px 0 16px;
  gap: 5px;
`;

const ColorBoxWrap = styled.div`
  height: min-content;
  margin: auto 0;
  max-width: 80px;
  word-break: break-word;
  text-align: center;
`;

const ColorBox = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #dddddd;
  padding: 4px;
  margin: 5px auto auto;
`;

const TextLabel = styled(Text)`
  word-break: break-all;
`;

const TextColor = styled(Text)`
  text-transform: uppercase;

  @media only screen and (min-width: 768px) {
    margin-left: 10px;
  }
`;

const BackgroundColor = styled.div<{ color?: string }>`
  position: relative;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  ${({ color }) => (color ? `background-color: ${color};` : '')}
  border-radius: 2px;
`;

const ForegroundColor = styled.div<{ color?: string }>`
  position: absolute;
  right: 12px;
  bottom: 12px;
  height: 8px;
  width: 8px;
  ${({ color }) => (color ? `background-color: ${color};` : '')}
  border-radius: 6px;
`;

const Title = styled(AntdTitle)`
  font-size: 16px !important;
`;

const SectionPadded = styled.div`
  padding: 10px;
`;

const SectionNonPadded = styled.div`
  padding: 0;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
`;

const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;

  .ant-typography {
    margin-bottom: 0;
  }
`;

const ErrorAlert = styled(Alert)`
  margin: 8px 10px;

  .ant-alert-message {
    display: inline-block;
  }
  .ant-alert-message::first-letter {
    text-transform: uppercase;
  }
`;

const Preview = styled.div`
  display: flex;
  background: rgb(242, 242, 247);
  border-radius: 4px;
  width: -webkit-fill-available;
  min-height: 110px;
  margin-top: 10px;
  cursor: pointer;
  gap: 10px;
  padding: 16px 24px 24px;
`;

const Description = styled.div`
  padding: 0;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 16px;
`;

const EditButton = styled(Button)`
  margin-top: 10px;
`;

const Icon = styled(AntdIcon)<{ size?: number }>`
  svg {
    height: ${({ size = 16 }) => `${size}px`};
    width: ${({ size = 16 }) => `${size}px`};
  }

  &.anticon + span {
    margin-left: 5px;
  }
`;

const FieldWrap = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  cursor: pointer;
`;

const FieldLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
`;

const FieldInputWrap = styled.div`
  display: flex;
  gap: 5px;
`;

const FieldInput = styled.input`
  all: unset;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
`;

const ColorBoxSmall = styled(ColorBox)`
  margin: 0;
  padding: 1px;
  width: 20px;
  height: 20px;
`;

const PreviewColorInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: auto;
  gap: 5px;

  @media only screen and (min-width: 768px) {
    justify-content: flex-end;
    padding-bottom: 3px;
  }
`;

export default ColorPicker;
