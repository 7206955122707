import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Tag } from 'antd';
import { getLanguageOptionStatus } from '../../../../../utils/language/language.util';

interface MultiLanguageWidgetTitleProps {
  title: string | undefined;
  values: any;
  supportedLanguages: string[];
  defaultLanguage: string;
}
const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  align-items: center;
`;

const Title = styled.label`
  min-width: auto;
  width: auto;
  margin-right: 14px;
`;

const StyledTag = styled(Tag)`
  height: 16px;
  font-size: 8px;
  vertical-align: middle;
  line-height: 14px;
  padding: 0 4px;
  margin-left: -4px;
`;

export default function MultiLanguageWidgetTitle({
  title,
  values,
  supportedLanguages,
  defaultLanguage,
}: MultiLanguageWidgetTitleProps) {
  const filledOptionsStatus = useMemo(() => {
    return getLanguageOptionStatus(values, supportedLanguages, defaultLanguage);
  }, [values, supportedLanguages, defaultLanguage]);

  return (
    <TitleWrapper>
      <Title>{title || 'Untitled field'}</Title>
      {filledOptionsStatus.map((o) => (
        <StyledTag color={o.color} key={o.key}>
          {o.key}
        </StyledTag>
      ))}
    </TitleWrapper>
  );
}
