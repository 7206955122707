import { action, Action, thunk, Thunk } from 'easy-peasy';
import { ApiError } from '../../../services/api/api-error';
import Injections from '../../injections.interface';
import InvoicesPaginatedResult from '../../types/invoice';

export interface OrganisationInvoicesModel {
  data: { [organisationId: string]: InvoicesPaginatedResult };
  error: { [organisationId: string]: Error | null };
  loading: { [organisationId: string]: boolean };
  setData: Action<
    OrganisationInvoicesModel,
    { key: string; data: InvoicesPaginatedResult }
  >;
  setError: Action<OrganisationInvoicesModel, { key: string; error: ApiError | null }>;
  setLoading: Action<OrganisationInvoicesModel, { key: string; loading: boolean }>;
  fetch: Thunk<
    OrganisationInvoicesModel,
    {
      organisationId: string;
      page: number;
      limit: number;
      startDate: string;
      endDate: string;
    },
    Injections
  >;
}

const organisationInvoicesModel: OrganisationInvoicesModel = {
  loading: {},
  error: {},
  data: {},
  setLoading: action((state, { key, loading }) => {
    state.loading[key] = loading;
  }),
  setError: action((state, { key, error }) => {
    state.error[key] = error;
  }),
  setData: action((state, { key, data }) => {
    state.data[key] = data;
  }),
  fetch: thunk(
    async (
      actions,
      { organisationId, startDate, endDate, page, limit },
      { injections },
    ) => {
      actions.setLoading({ key: organisationId, loading: true });
      actions.setError({ key: organisationId, error: null });
      try {
        const data = await injections.apiService.get<InvoicesPaginatedResult>(
          `/api/organizations/${organisationId}/invoices`,
          {
            page,
            limit,
            startDate,
            endDate,
          } as any,
        );
        actions.setData({ key: organisationId, data });
      } catch (error) {
        actions.setError({ key: organisationId, error });
      } finally {
        actions.setLoading({ key: organisationId, loading: false });
      }
    },
  ),
};

export default organisationInvoicesModel;
