import { thunk, Thunk } from 'easy-peasy';
import { PlanTypeEnum } from '../../types/organisation-plan';
import { OrganizationPlans } from '../../types/organisation';
import createKeyedCrudModel, {
  KeyedCrudModel,
} from '../../common/keyed-crud-model/keyed-crud.model';
import Injections from '../../injections.interface';

const path = '/api/organizations';

export const KEY_ACTIVE_PLANS = 'activePlansKey';
export const KEY_AVAILABLE_PLANS = 'availablePlansKey';
export const KEY_SCHEDULED_PLANS = 'scheduledPlansKey';

export type OrganizationPlansModel = KeyedCrudModel<
  OrganizationPlans,
  { organizationId: string }
> & {
  fetchActivePlans: Thunk<
    OrganizationPlansModel,
    { organizationId: string; silent?: boolean },
    Injections
  >;
} & {
  fetchScheduledPlans: Thunk<
    OrganizationPlansModel,
    { organizationId: string; silent?: boolean },
    Injections
  >;
} & {
  fetchAvailablePlans: Thunk<
    OrganizationPlansModel,
    { organizationId: string; planType: PlanTypeEnum; silent?: boolean },
    Injections
  >;
} & {
  schedulePlanUpdate: Thunk<
    OrganizationPlansModel,
    { organizationId: string; planType: PlanTypeEnum; planId: string },
    Injections
  >;
};

const organizationPlansModel: OrganizationPlansModel = {
  ...createKeyedCrudModel<OrganizationPlans, { organizationId: string }>(
    path,
    'organizationId',
  ),
  fetchActivePlans: thunk(
    async (actions, { organizationId, silent = false }, { injections }) => {
      const key = KEY_ACTIVE_PLANS;

      if (!silent) {
        actions.setLoading({ key, loading: true });
      }
      actions.setError({ key, error: null });

      try {
        const data = await injections.apiService.get<OrganizationPlans>(
          `${path}/${organizationId}/plans`,
        );

        data.id = organizationId; // as item id
        actions.setSingle({ key, data });
      } catch (error) {
        actions.setError({ key, error });
      } finally {
        if (!silent) {
          actions.setLoading({ key, loading: false });
        }
      }
    },
  ),
  fetchScheduledPlans: thunk(
    async (actions, { organizationId, silent = false }, { injections }) => {
      const key = KEY_SCHEDULED_PLANS;

      if (!silent) {
        actions.setLoading({ key, loading: true });
      }
      actions.setError({ key, error: null });

      try {
        const data = await injections.apiService.get<OrganizationPlans>(
          `${path}/${organizationId}/scheduled-plans`,
        );

        data.id = organizationId; // as item id
        actions.setSingle({ key, data });
      } catch (error) {
        actions.setError({ key, error });
      } finally {
        if (!silent) {
          actions.setLoading({ key, loading: false });
        }
      }
    },
  ),
  fetchAvailablePlans: thunk(
    async (actions, { organizationId, planType, silent = false }, { injections }) => {
      const key = KEY_AVAILABLE_PLANS;

      if (!silent) {
        actions.setLoading({ key, loading: true });
      }
      actions.setError({ key, error: null });

      try {
        // TODO: The cake is a lie! What we get is an array of plans, but we dangerously
        //  set an `id` property on that array and save it, pretending it was an
        //  `OrganizationPlans` instance.
        const data = await injections.apiService.get<OrganizationPlans>(
          `${path}/${organizationId}/available-plans`,
          { type: planType },
        );

        data.id = organizationId; // as item id
        actions.setSingle({ key, data });
      } catch (error) {
        actions.setError({ key, error });
      } finally {
        if (!silent) {
          actions.setLoading({ key, loading: false });
        }
      }
    },
  ),
  schedulePlanUpdate: thunk(
    async (actions, { organizationId, planType, planId }, { injections }) => {
      await injections.apiService.post<OrganizationPlans>(
        `${path}/${organizationId}/schedule-plan-update`,
        { planId, planType },
      );
    },
  ),
};

export default organizationPlansModel;
