import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { TextAsElementEnum } from './utils';

const CLASS_NAME = 'sampleText';

interface SampleTextProps {
  text?: string;
  asElement?: TextAsElementEnum;
  previewCss?: string;
}

const SampleTextPreview = (props: SampleTextProps) => {
  const { asElement = TextAsElementEnum.H3, previewCss, text } = props;

  const previewElement = useMemo<JSX.Element>(() => {
    if (asElement === TextAsElementEnum.SPAN) {
      return <Span className={CLASS_NAME}>{text}</Span>;
    }

    if (asElement === TextAsElementEnum.PARAGRAPH) {
      return <Paragraph className={CLASS_NAME}>{text}</Paragraph>;
    }

    switch (asElement) {
      case TextAsElementEnum.H1:
        return <Heading1 className={CLASS_NAME}>{text}</Heading1>;
      case TextAsElementEnum.H2:
        return <Heading2 className={CLASS_NAME}>{text}</Heading2>;
      case TextAsElementEnum.H4:
        return <Heading4 className={CLASS_NAME}>{text}</Heading4>;
      case TextAsElementEnum.H5:
        return <Heading5 className={CLASS_NAME}>{text}</Heading5>;
      case TextAsElementEnum.H6:
        return <Heading6 className={CLASS_NAME}>{text}</Heading6>;
      case TextAsElementEnum.H3:
      default:
        return <Heading3 className={CLASS_NAME}>{text}</Heading3>;
    }
  }, [asElement, text]);

  return <div css={css(previewCss)}>{previewElement}</div>;
};

const Heading1 = styled.h1`
  all: unset;
  display: block;
  font-size: 2em;
  font-weight: bold;
`;

const Heading2 = styled.h2`
  all: unset;
  display: block;
  font-size: 1.5em;
  font-weight: bold;
`;

const Heading3 = styled.h3`
  all: unset;
  display: block;
  font-size: 1.17em;
  font-weight: bold;
`;

const Heading4 = styled.h4`
  all: unset;
  display: block;
  font-weight: bold;
`;

const Heading5 = styled.h5`
  all: unset;
  display: block;
  font-size: 0.83em;
  font-weight: bold;
`;

const Heading6 = styled.h6`
  all: unset;
  display: block;
  font-size: 0.67em;
  font-weight: bold;
`;

const Span = styled.span`
  all: unset;
`;

const Paragraph = styled.p`
  all: unset;
  display: block;
`;

export default SampleTextPreview;
