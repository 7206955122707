import React, { useState } from 'react';
import CrudModal from '../crud-list/crud-modal/crud-modal.component';

const EMPTY = {};

const useModal = ({
  title,
  schema,
  onSubmit,
  showMessage,
  uiSchema = {},
}: {
  title: string;
  schema: any;
  onSubmit: (data: any, params: any) => Promise<void>;
  showMessage?: boolean;
  uiSchema?: any;
}): [any, (params: any) => void] => {
  const [isVisible, setVisible] = useState<boolean>(false);
  const [params, setParams] = useState<any>(false);

  const handleSubmit = async (data: any) => {
    await onSubmit(data, params);
    setVisible(false);
  };

  const modal = (
    <CrudModal
      title={title}
      schema={schema}
      metaSchema={uiSchema}
      initialValues={EMPTY}
      onSubmit={handleSubmit}
      visible={isVisible}
      onClose={() => setVisible(false)}
      showMessage={showMessage}
    />
  );
  const show = (p: any) => {
    setVisible(true);
    setParams(p);
  };

  return [modal, show];
};

export default useModal;
