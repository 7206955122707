import React from 'react';
import EditableListOfInputs from './editable-list-of-inputs';

export default function EmailsForTeamsCurbsideNotifications() {
  return (
    <EditableListOfInputs
      title="Emails for curbside notifications"
      keyFromValues="emailsForTeamsCurbsideNotifications"
      getFieldName={(t) => t}
      getInitialValue={() => ''}
    />
  );
}
