import React from 'react';
import styled from '@emotion/styled';
import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import KnowledgeBaseFileLibrary, {
  KnowledgeBaseFileLibraryProps,
} from './knowledge-base-file-library.component';
import KnowledgeBaseFileUploader from './knowledge-base-file-uploader.component';

interface KnowledgeBaseFileModalProps
  extends Omit<ModalProps, 'visible'>, KnowledgeBaseFileLibraryProps {
  isOpen?: boolean;
  isLibraryInitialized?: boolean;
  children?: React.ReactNode;
}

const KnowledgeBaseFileModal = (props: KnowledgeBaseFileModalProps) => {
  const {
    tenantId,
    assistantId,
    onFileSelect,
    isOpen = false,
    isLibraryInitialized = false,
    children,
    ...restModalProps
  } = props;

  return (
    <ModalStyled
      {...restModalProps}
      centered
      visible={isOpen}
      footer={null}
      forceRender={isLibraryInitialized}
    >
      <KnowledgeBaseFileUploader tenantId={tenantId} />
      <KnowledgeBaseFileLibrary
        tenantId={tenantId}
        assistantId={assistantId}
        onFileSelect={onFileSelect}
      />
    </ModalStyled>
  );
};

const ModalStyled = styled(Modal)`
  @media screen and (min-width: 768px) {
    &.ant-modal {
      min-width: 700px;
    }
  }
`;

export default KnowledgeBaseFileModal;
