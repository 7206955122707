import { useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router";
import qs from 'query-string';

export type UseQueryParam = [ string, (newQueryParamValue: string) => void ];

/**
 * Hook to manage query params in URL (retrieve, save, update, remove)
 */
const useQueryParam = (queryParam: string, queryParamDefaultValue = '') => {
  const history = useHistory();

  const location = useLocation();

  const queryParamValue = qs.parse(location.search, { sort: false })[queryParam] || queryParamDefaultValue;

  const onChangeQueryParam = useCallback((newQueryParamValue: string) => {
    const {[queryParam]: _, ...queryParams} = qs.parse(history.location.search, { sort: false });
    history.replace({
      pathname: history.location.pathname,
      search: qs.stringify({
        ...queryParams,
        ...(newQueryParamValue && {[queryParam]: newQueryParamValue})
      })
    })
  }, [history, queryParam]);

  const result = useMemo(() => {
    return (
      [queryParamValue, onChangeQueryParam]
    ) as UseQueryParam;
  }, [queryParamValue, onChangeQueryParam]);

  return result;
}

export default useQueryParam;
