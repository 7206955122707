import React from 'react';
import styled from '@emotion/styled';
import { Menu, Icon, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import MeatBallsMenu from '../../../../common/meat-balls-menu';
import { permissionKeys } from '../../../../../utils/auth/permissions';
import useHasPermissions from '../../../../../utils/auth/use-has-permissions';

const MenuButton = styled(Button)`
  color: #676973;
` as any;

interface MeatBallsMenuProps {
  onEdit: () => void;
  organisationId: string;
}

const MeatBallsMenuView: React.FC<MeatBallsMenuProps> = ({ onEdit, organisationId }) => {
  const { t } = useTranslation();
  const isEditAllowed = useHasPermissions(organisationId, permissionKeys.apps.update);

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <MenuButton type="link" onClick={onEdit}>
          <Icon type={isEditAllowed ? 'edit' : 'setting'} /> {t(isEditAllowed ? 'edit' : 'view')}
        </MenuButton>
      </Menu.Item>
    </Menu>
  );

  return <MeatBallsMenu menu={menu} placement="bottomRight" />;
};

export default MeatBallsMenuView;
