import React, { useCallback, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Table } from 'antd';
import { useActions, useStore } from 'easy-peasy';
import { useTranslation } from 'react-i18next';
import PanelCard from '../../../../common/panel-card/panel-card.component';
import { loadingIcon } from '../../../../common/spinner/spinner.component';
import { RootModel, RootState } from '../../../../../store/models/root.model';
import Organisation, { DataResidencyEnum } from '../../../../../store/types/organisation';
import { DEFAULT_PRODUCTS_ENVIRONMENT } from '../utils';
import { CountInfo, MainHeader } from '../grid-products.components';
import Environment from '../../../../../store/types/environment';
import routes from '../routes';
import { PaginationConfig } from 'antd/lib/pagination';
import { appendQueryParams } from '../../../../../utils/url';
import usePaginationQueryParams from '../../../../common/use-pagination-query-params';
import { GridProductsPaginationQueryParams } from '../types';
import { ProductEnvironmentSelect } from '../common';
import { ColumnProps } from 'antd/lib/table';
import { useProductTypes } from '../../../../common/use-product-type';

const defaultPageNum = 1;

interface TableDataRow {
  isRoot: boolean;
  productType: {
    id: string;
    title: string;
  };
  parentProductType?: {
    id: string;
    title: string;
  };
}

export type OverviewProps = RouteComponentProps<{ organisationId: string }> & {
  dataResidency: DataResidencyEnum;
};

const Overview = ({ match, history, dataResidency }: OverviewProps) => {
  const {
    params: { organisationId: tenantId },
  } = match;
  const { t } = useTranslation();

  const [queryParams, setQueryParams] = usePaginationQueryParams<
    GridProductsPaginationQueryParams
  >({ env: DEFAULT_PRODUCTS_ENVIRONMENT, page: defaultPageNum }, { parseNumbers: true });

  const { env, page } = queryParams;

  const { fetchProductTypes, fetchEnvironments } = useActions<RootModel>((actions) => {
    const results = {
      fetchProductTypes: actions.gridProductTypes.fetchProductTypes,
      fetchEnvironments: actions.environments.fetch,
    };
    return results;
  });

  const fetchEnvironmentsRef = React.useRef({ fetchCount: 0 });

  const { isEnvLoading, tenant, envs } = useStore<
    RootState,
    {
      isEnvLoading: boolean;
      tenant: Organisation | null;
      envs: Environment[];
    }
  >((state) => {
    const result = {
      isEnvLoading: state.environments.loading[tenantId],
      tenant: state.organisations.data && state.organisations.data[tenantId],
      envs: state.environments.values(tenantId),
    };

    return result;
  });

  const handlePagination = useCallback(
    (paginationConfig: PaginationConfig) => {
      const { current } = paginationConfig;

      setQueryParams((prevParams) => {
        return {
          ...prevParams,
          page: current,
        };
      });
    },
    [setQueryParams],
  );

  const handleProductsEnvironmentChange = useCallback(
    (value: string) => {
      setQueryParams((prevParams) => {
        return {
          ...prevParams,
          env: value,
        };
      });
    },
    [setQueryParams],
  );

  useEffect(() => {
    if (
      envs.length === 0 &&
      fetchEnvironmentsRef.current.fetchCount === 0 &&
      !isEnvLoading
    ) {
      fetchEnvironmentsRef.current.fetchCount += 1;
      fetchEnvironments({ organizationId: tenantId });
    }
  }, [envs, fetchEnvironments, tenantId, isEnvLoading]);

  useEffect(() => {
    const logic = async () => {
      if (tenant) {
        await fetchProductTypes({
          tenantId,
          dataResidency: tenant.dataResidency,
          env,
        });
      }
    };
    logic();
  }, [tenant, tenantId, fetchProductTypes, env]);

  const { data: productTypesData, isLoading: isProductTypesLoading } = useProductTypes({
    tenantId,
    dataResidency,
    environment: env,
  });

  const mappedProductTypes = useMemo((): TableDataRow[] => {
    if (!productTypesData) {
      return [];
    }

    return productTypesData.data.map((productType) => {
      const { isRoot, productTypeId, title, parentId } = productType;

      const productTypeTitle = title.length ? title[0].label : '';

      const parentProductType = productTypesData.data.find(
        (parentProductType) => parentProductType.productTypeId === parentId,
      );
      const parentProductTypeTitle =
        parentProductType && parentProductType.title.length
          ? parentProductType.title[0].label
          : '';

      return {
        isRoot,
        productType: {
          id: productTypeId,
          title: productTypeTitle,
        },
        parentProductType: parentId
          ? {
              id: parentId,
              title: parentProductTypeTitle,
            }
          : undefined,
      };
    });
  }, [productTypesData]);

  const columns = useMemo<ColumnProps<TableDataRow>[]>(() => {
    return [
      {
        title: <ColumnHeader>{t('gridProductTypes.title')}</ColumnHeader>,
        key: 'productTypeTitle',
        render: (_, record) => {
          const productTypeId = record.productType.id;
          const path = appendQueryParams(routes.productTypePath(tenantId, productTypeId), {
            env,
          });

          return <CategoryLink to={path}>{record.productType.title || productTypeId}</CategoryLink>
        },
      },
      {
        title: <ColumnHeader>{t('gridProductTypes.parent')}</ColumnHeader>,
        key: 'parentProductTypeTitle',
        render: (_, record) =>
          record.parentProductType && (
            <span>{record.parentProductType.title || record.parentProductType.id}</span>
          ),
      },
    ];
  }, [env, tenantId, t]);

  return (
    <>
      <MainHeader match={match} />

      <Content>
        <ProductHeading>
          <ProductEnvironmentSelect
            label={t('environment')}
            options={envs}
            value={env}
            onChange={handleProductsEnvironmentChange}
          />
          <CountInfo
            count={mappedProductTypes.length}
            isLoading={isProductTypesLoading}
            label={
              mappedProductTypes.length > 1
                ? t('gridProductTypes.categories')
                : t('gridProductTypes.category')
            }
          />
        </ProductHeading>

        <TableContent>
          <Table
            expandIcon={() => null}
            expandIconAsCell={false}
            pagination={{ current: page }}
            dataSource={mappedProductTypes}
            columns={columns}
            onChange={handlePagination}
            loading={{
              indicator: loadingIcon,
              spinning: isProductTypesLoading,
            }}
            scroll={{ x: true }}
          />
        </TableContent>
      </Content>
    </>
  );
};

const ColumnHeader = styled.span`
  text-transform: capitalize;
`;

const TableContent = styled(PanelCard)`
  margin-top: 8px;
  .ant-card-body {
    padding: 0px;
  }
  .ant-table-pagination.ant-pagination {
    margin: 16px 8px;
  }
`;

const Content = styled.div`
  margin: 20px 32px;
`;

const ProductHeading = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 17px 0;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const CategoryLink = styled(Link)`
  display: flex;
  align-items: center;
  width: fit-content;
`;

export default Overview;
