enum GridappTypeEnum {
  SCREEN = 'screen',
  IOT_MODULE = 'module',
  MOBILE = 'mobile',
  /** @deprecated replaced with `IOT_MODULE` */
  IOT = 'iot',
  CLOUD = 'cloud',
}

export default GridappTypeEnum;
