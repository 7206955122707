import React, { useCallback, useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Select, Menu } from 'antd';
import SearchBar from '../../../../common/search-bar/search-bar.component';
import { Icon } from '../../../../common/schema-form/common';
import { Plus } from 'react-feather';
import {
  ContentLibraryItemTypeEnum,
  ContentLibraryItemResolutionEnum,
  getContentLibraryItemTypeOptions,
  getOrientationOptions,
  getResolutionOptions,
  isAppsContentTypeSelected,
} from './library-utils';
import { Option } from '../../../../common/react-final-form';

export interface ContentLibraryFilter {
  contentType?: string;
  orientation?: string;
  resolution?: ContentLibraryItemResolutionEnum;
  search?: string;
}

interface ContentLibraryFiltersProps {
  filters?: ContentLibraryFilter;
  onFiltersChange?: (filters: ContentLibraryFilter) => void;
  onNewFolderUpload?: () => void;
  onNewFileUpload?: (file: File[]) => void;
  onStockImageUpload?: () => void;
}

const ContentLibraryFilters = (props: ContentLibraryFiltersProps) => {
  const { filters = {}, onFiltersChange, onNewFolderUpload, onNewFileUpload, onStockImageUpload } = props;

  const { t } = useTranslation();

  const fileRef = useRef<HTMLInputElement | null>(null);

  const contentLibraryItemTypeOptions = useMemo<Option[]>(() => {
    return getContentLibraryItemTypeOptions();
  }, []);

  const orientationOptions = useMemo(() => {
    return getOrientationOptions();
  }, []);

  const resolutionOptions = useMemo(() => {
    return getResolutionOptions();
  }, []);

  const isAppsContentType = useMemo(() => {
    return isAppsContentTypeSelected(filters.contentType);
  }, [filters]);

  const newMediaOptions = useMemo(() => {
    return (
      <Menu>
        <Menu.Item
          key="folder"
          onClick={() => {
            if (onNewFolderUpload) {
              onNewFolderUpload();
            }
          }}
        >
          <Icon type="folder" /> &nbsp;
          {t('contents.folder')}
        </Menu.Item>

        <Menu.Item
          key="file"
          onClick={() => {
            if (fileRef.current) {
              fileRef.current.click();
            }
          }}
        >
          <Icon type="upload" /> &nbsp;
          {t('contents.uploadFile')}
        </Menu.Item>

        <Menu.Item
          key="stock-photos"
          onClick={() => {
            if (onStockImageUpload) {
              onStockImageUpload();
            }
          }}
        >
          <Icon type="picture" /> &nbsp;
          {t('contents.freeHighQualityStockPhotos')}
        </Menu.Item>
      </Menu>
    );
  }, [onNewFolderUpload, onStockImageUpload, t]);

  const handleContentTypeChange = useCallback(
    (contentType: string[] | string) => {
      if (!onFiltersChange) {
        return;
      }

      if (!Array.isArray(contentType)) {
        contentType = [contentType];
      }

      const isAppsContentType = contentType.includes(ContentLibraryItemTypeEnum.APP);

      onFiltersChange({
        ...filters,
        contentType: isAppsContentType ? ContentLibraryItemTypeEnum.APP : contentType.join(','),
        orientation: isAppsContentType ? '' : filters.orientation,
      });
    },
    [filters, onFiltersChange],
  );

  const handleOrientationChange = useCallback(
    (orientation: string[]) => {
      if (!onFiltersChange) {
        return;
      }

      let contentType = '';
      if (filters.contentType) {
        const contentTypes = filters.contentType.split(',');
        contentType = contentTypes.filter((contentType) => contentType !== ContentLibraryItemTypeEnum.APP).join(',');
      }

      onFiltersChange({
        ...filters,
        contentType,
        orientation: orientation.join(','),
      });
    },
    [filters, onFiltersChange],
  );

  const handleResolutionChange = useCallback(
    (resolution: ContentLibraryItemResolutionEnum) => {
      if (!onFiltersChange) {
        return;
      }

      onFiltersChange({
        ...filters,
        resolution,
      });
    },
    [filters, onFiltersChange],
  );

  const handleSearchChange = useCallback(
    (search: string) => {
      if (!onFiltersChange) {
        return;
      }

      onFiltersChange({
        ...filters,
        search,
      });
    },
    [filters, onFiltersChange],
  );

  return (
    <Controls>
      <FilterControls>
        <Select
          size="large"
          placeholder={t('contents.contentType')}
          allowClear={true}
          value={filters.contentType ? filters.contentType.split(',') : []}
          {...(!isAppsContentType ? { mode: 'multiple' } : {})}
          onChange={handleContentTypeChange}
        >
          {contentLibraryItemTypeOptions.map((contentTypeOption) => {
            return (
              <Select.Option
                key={contentTypeOption.value}
                value={contentTypeOption.value}
                disabled={isAppsContentType && contentTypeOption.value !== ContentLibraryItemTypeEnum.APP}
              >
                {contentTypeOption.label}
              </Select.Option>
            );
          })}
        </Select>

        <Select
          size="large"
          placeholder={t('contents.orientation')}
          allowClear={true}
          value={filters.orientation ? filters.orientation.split(',') : []}
          mode="multiple"
          onChange={handleOrientationChange}
        >
          {orientationOptions.map((orientationOption) => {
            return (
              <Select.Option key={orientationOption.value} value={orientationOption.value}>
                {orientationOption.label}
              </Select.Option>
            );
          })}
        </Select>

        <Select
          size="large"
          placeholder={t('contents.resolution')}
          allowClear={true}
          value={filters.resolution}
          onChange={handleResolutionChange}
        >
          {resolutionOptions.map((resolutionOption) => {
            return (
              <Select.Option key={resolutionOption.value} value={resolutionOption.value}>
                {resolutionOption.label}
              </Select.Option>
            );
          })}
        </Select>
      </FilterControls>

      <SearchControl>
        <SearchBar
          key={'contents.searchFiles'}
          searchInputProps={{
            placeholder: t('contents.searchFiles'),
            onSearch: handleSearchChange,
            defaultValue: filters.search,
            minLength: 3,
          }}
        />
      </SearchControl>

      <ActionButton>
        <Dropdown overlay={newMediaOptions} trigger={['click']}>
          <Button size="large">
            <Icon component={() => <Plus />} />
            {t('contents.new')}
          </Button>
        </Dropdown>
      </ActionButton>

      <FileControl>
        <input
          type="file"
          ref={fileRef}
          multiple
          onChange={(event) => {
            const { files } = event.target;
            if (files && files.length && onNewFileUpload) {
              const filesToUpload: File[] = [];
              for (let i = 0; i < files.length; i++) {
                filesToUpload.push(files[i]);
              }
              onNewFileUpload(filesToUpload);
            }
          }}
        />
      </FileControl>
    </Controls>
  );
};

export default ContentLibraryFilters;

const Controls = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

const FilterControls = styled.div`
  display: flex;
  gap: 10px;
  width: 40%;

  .ant-select {
    min-height: 43px;
    width: inherit !important;
  }

  .ant-select-selection {
    height: inherit !important;
  }
`;

const SearchControl = styled.div`
  flex-grow: 1;
  height: 43px;
`;

const ActionButton = styled.div`
  height: 43px;

  .ant-btn-lg {
    height: 43px !important;
  }
`;

const FileControl = styled.div`
  position: absolute;
  top: -1000000px;
`;
