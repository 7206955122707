import React from 'react';
import styled from '@emotion/styled';

interface IframeViewProps {
  url: string;
}

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
`;

export default function IframeView({ url }: IframeViewProps) {
  return <Iframe src={url} />;
}
