import React, { useMemo } from 'react';
import round from 'lodash/round';
import { useTranslation } from 'react-i18next';
import { DataResidencyEnum } from '../../../../store/types/organisation';
import {
  useTenantNpsByDay,
  useInstallationNpsByDay,
  useSpaceNpsByDay,
  useDeviceNpsByDay,
  useAppNpsByDay,
} from '../../use-analytics-report';
import ListChart from '../charts/list';
import CardContainer from './card-container';
import { GridStyles } from '@ombori/grid-reports';
import { getPrecedingPeriod } from './preceding-period-data';

const title = 'NPS';

const buildDataItem = (
  label: string,
  data: { label: string; value: number }[],
): [string, { label: string; value: number }[]] => [label, data];

const buildData = (score: number, replyCount: number) => {
  const result = [
    buildDataItem('NPS', [{ label: 'NPS', value: round(score, 2) }]),
    buildDataItem('Number of replies', [
      { label: 'Number of replies', value: replyCount },
    ]),
  ];

  return result;
};

const sort = ([a]: [string, number], [b]: [string, number]) => a.localeCompare(b);

interface NpsProps {
  tenantId: string;
  dateFrom: string;
  dateTo: string;
  dataResidency: DataResidencyEnum;
  gridStyles?: GridStyles;
  isVisibleWithoutData?: boolean;
}

export const TenantNps: React.FC<NpsProps> = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { t } = useTranslation();

  const { dateFrom: precedingDateFrom, dateTo: precedingDateTo } = getPrecedingPeriod(
    dateFrom,
    dateTo,
  );

  const precedingNpsFetchingState = useTenantNpsByDay({
    tenantId,
    dateFrom: precedingDateFrom,
    dateTo: precedingDateTo,
    dataResidency,
  });

  const npsFetchingState = useTenantNpsByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const isLoading = precedingNpsFetchingState.isLoading || npsFetchingState.isLoading;
  const isSuccess = precedingNpsFetchingState.isSuccess && npsFetchingState.isSuccess;
  const isError = precedingNpsFetchingState.isError || npsFetchingState.isError;

  const hasData = useMemo(() => {
    const hasCurrentPeriodData =
      npsFetchingState.isSuccess && npsFetchingState.data.replyCount > 0;
    const hasPrecedingPeriodData =
      precedingNpsFetchingState.isSuccess &&
      precedingNpsFetchingState.data.replyCount > 0;

    const result = hasCurrentPeriodData || hasPrecedingPeriodData;

    return result;
  }, [npsFetchingState, precedingNpsFetchingState]);

  return (
    <CardContainer
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      hasData={hasData}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {precedingNpsFetchingState.isSuccess && npsFetchingState.isSuccess && (
        <ListChart
          data={buildData(npsFetchingState.data.score, npsFetchingState.data.replyCount)}
          precedingPeriodData={buildData(
            precedingNpsFetchingState.data.score,
            precedingNpsFetchingState.data.replyCount,
          )}
          hidePercentage={true}
          header={t('currentPeriod')}
          sort={sort}
        />
      )}
    </CardContainer>
  );
};

interface InstallationNpsProps extends NpsProps {
  installationId: string;
}

export const InstallationNps: React.FC<InstallationNpsProps> = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { t } = useTranslation();

  const { dateFrom: precedingDateFrom, dateTo: precedingDateTo } = getPrecedingPeriod(
    dateFrom,
    dateTo,
  );

  const precedingNpsFetchingState = useInstallationNpsByDay({
    tenantId,
    installationId,
    dateFrom: precedingDateFrom,
    dateTo: precedingDateTo,
    dataResidency,
  });

  const npsFetchingState = useInstallationNpsByDay({
    tenantId,
    installationId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const isLoading = precedingNpsFetchingState.isLoading || npsFetchingState.isLoading;
  const isSuccess = precedingNpsFetchingState.isSuccess && npsFetchingState.isSuccess;
  const isError = precedingNpsFetchingState.isError || npsFetchingState.isError;

  const hasData = useMemo(() => {
    const hasCurrentPeriodData =
      npsFetchingState.isSuccess && npsFetchingState.data.replyCount > 0;
    const hasPrecedingPeriodData =
      precedingNpsFetchingState.isSuccess &&
      precedingNpsFetchingState.data.replyCount > 0;

    const result = hasCurrentPeriodData || hasPrecedingPeriodData;

    return result;
  }, [npsFetchingState, precedingNpsFetchingState]);

  return (
    <CardContainer
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      hasData={hasData}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {precedingNpsFetchingState.isSuccess && npsFetchingState.isSuccess && (
        <ListChart
          data={buildData(npsFetchingState.data.score, npsFetchingState.data.replyCount)}
          precedingPeriodData={buildData(
            precedingNpsFetchingState.data.score,
            precedingNpsFetchingState.data.replyCount,
          )}
          hidePercentage={true}
          header={t('currentPeriod')}
          sort={sort}
        />
      )}
    </CardContainer>
  );
};

interface SpaceNpsProps extends NpsProps {
  spaceId: string;
}

export const SpaceNps: React.FC<SpaceNpsProps> = ({
  tenantId,
  spaceId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { t } = useTranslation();

  const { dateFrom: precedingDateFrom, dateTo: precedingDateTo } = getPrecedingPeriod(
    dateFrom,
    dateTo,
  );

  const precedingNpsFetchingState = useSpaceNpsByDay({
    tenantId,
    spaceId,
    dateFrom: precedingDateFrom,
    dateTo: precedingDateTo,
    dataResidency,
  });

  const npsFetchingState = useSpaceNpsByDay({
    tenantId,
    spaceId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const isLoading = precedingNpsFetchingState.isLoading || npsFetchingState.isLoading;
  const isSuccess = precedingNpsFetchingState.isSuccess && npsFetchingState.isSuccess;
  const isError = precedingNpsFetchingState.isError || npsFetchingState.isError;

  const hasData = useMemo(() => {
    const hasCurrentPeriodData =
      npsFetchingState.isSuccess && npsFetchingState.data.replyCount > 0;
    const hasPrecedingPeriodData =
      precedingNpsFetchingState.isSuccess &&
      precedingNpsFetchingState.data.replyCount > 0;

    const result = hasCurrentPeriodData || hasPrecedingPeriodData;

    return result;
  }, [npsFetchingState, precedingNpsFetchingState]);

  return (
    <CardContainer
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      hasData={hasData}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {precedingNpsFetchingState.isSuccess && npsFetchingState.isSuccess && (
        <ListChart
          data={buildData(npsFetchingState.data.score, npsFetchingState.data.replyCount)}
          precedingPeriodData={buildData(
            precedingNpsFetchingState.data.score,
            precedingNpsFetchingState.data.replyCount,
          )}
          hidePercentage={true}
          header={t('currentPeriod')}
          sort={sort}
        />
      )}
    </CardContainer>
  );
};

interface DeviceNpsProps extends NpsProps {
  deviceId: string;
}

export const DeviceNps: React.FC<DeviceNpsProps> = ({
  tenantId,
  deviceId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { t } = useTranslation();

  const { dateFrom: precedingDateFrom, dateTo: precedingDateTo } = getPrecedingPeriod(
    dateFrom,
    dateTo,
  );

  const precedingNpsFetchingState = useDeviceNpsByDay({
    tenantId,
    deviceId,
    dateFrom: precedingDateFrom,
    dateTo: precedingDateTo,
    dataResidency,
  });

  const npsFetchingState = useDeviceNpsByDay({
    tenantId,
    deviceId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const isLoading = precedingNpsFetchingState.isLoading || npsFetchingState.isLoading;
  const isSuccess = precedingNpsFetchingState.isSuccess && npsFetchingState.isSuccess;
  const isError = precedingNpsFetchingState.isError || npsFetchingState.isError;

  const hasData = useMemo(() => {
    const hasCurrentPeriodData =
      npsFetchingState.isSuccess && npsFetchingState.data.replyCount > 0;
    const hasPrecedingPeriodData =
      precedingNpsFetchingState.isSuccess &&
      precedingNpsFetchingState.data.replyCount > 0;

    const result = hasCurrentPeriodData || hasPrecedingPeriodData;

    return result;
  }, [npsFetchingState, precedingNpsFetchingState]);

  return (
    <CardContainer
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      hasData={hasData}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {precedingNpsFetchingState.isSuccess && npsFetchingState.isSuccess && (
        <ListChart
          data={buildData(npsFetchingState.data.score, npsFetchingState.data.replyCount)}
          precedingPeriodData={buildData(
            precedingNpsFetchingState.data.score,
            precedingNpsFetchingState.data.replyCount,
          )}
          hidePercentage={true}
          header={t('currentPeriod')}
          sort={sort}
        />
      )}
    </CardContainer>
  );
};

interface AppNpsProps extends NpsProps {
  appId: string;
}

export const AppNps: React.FC<AppNpsProps> = ({
  tenantId,
  appId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { t } = useTranslation();

  const { dateFrom: precedingDateFrom, dateTo: precedingDateTo } = getPrecedingPeriod(
    dateFrom,
    dateTo,
  );

  const precedingNpsFetchingState = useAppNpsByDay({
    tenantId,
    appId,
    dateFrom: precedingDateFrom,
    dateTo: precedingDateTo,
    dataResidency,
  });

  const npsFetchingState = useAppNpsByDay({
    tenantId,
    appId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const isLoading = precedingNpsFetchingState.isLoading || npsFetchingState.isLoading;
  const isSuccess = precedingNpsFetchingState.isSuccess && npsFetchingState.isSuccess;
  const isError = precedingNpsFetchingState.isError || npsFetchingState.isError;

  const hasData = useMemo(() => {
    const hasCurrentPeriodData =
      npsFetchingState.isSuccess && npsFetchingState.data.replyCount > 0;
    const hasPrecedingPeriodData =
      precedingNpsFetchingState.isSuccess &&
      precedingNpsFetchingState.data.replyCount > 0;

    const result = hasCurrentPeriodData || hasPrecedingPeriodData;

    return result;
  }, [npsFetchingState, precedingNpsFetchingState]);

  return (
    <CardContainer
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      hasData={hasData}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {precedingNpsFetchingState.isSuccess && npsFetchingState.isSuccess && (
        <ListChart
          data={buildData(npsFetchingState.data.score, npsFetchingState.data.replyCount)}
          precedingPeriodData={buildData(
            precedingNpsFetchingState.data.score,
            precedingNpsFetchingState.data.replyCount,
          )}
          hidePercentage={true}
          header={t('currentPeriod')}
          sort={sort}
        />
      )}
    </CardContainer>
  );
};
