import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { rgba } from 'polished';
import { useTranslation } from 'react-i18next';

export enum NetworkStatusType {
  Online = 'ONLINE',
  Offline = 'OFFLINE',
  Failing = 'FAILING',
}

const Container = styled.div<{ status: NetworkStatusType }>`
  display: inline-flex;
  height: 16px;
  padding: 4px;
  border-radius: 4px;
  
  ${({ status }) =>
    status === NetworkStatusType.Online &&
    `
      color: #00AE48;
      background-color: ${rgba('#00AE48', 0.12)};
  `}

  ${({ status }) =>
    status === NetworkStatusType.Offline &&
    `
      color: #F02424;
      background-color: ${rgba('#F02424', 0.12)};
  `}

  ${({ status }) =>
    status === NetworkStatusType.Failing &&
    `
      color: #FFC107;
      background-color: ${rgba('#FFC107', 0.12)};
  `}
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  font-size: 10px;
  text-transform: uppercase;

  span {
    color: #111111;
  }

  &:before {
    content: '';
    position: relative;
    display: inline-flex;
    width: 8px;
    height: 8px;
    margin-right: 4px;
    border-radius: 100%;
    background: currentColor;
  }
`;

interface NetworkStatusProps {
  status: NetworkStatusType;
}

const NetworkStatus: React.FC<NetworkStatusProps> = ({ status }) => {
  const { t } = useTranslation();

  const text = useMemo(() => {
    switch (status) {
      case NetworkStatusType.Online:
        return t('devicesReportOnline');
      case NetworkStatusType.Failing:
        return t('devicesReportFailing');
      case NetworkStatusType.Offline:
        return t('devicesReportOffline');
      default:
        return t('unknownDeviceStatus');
    }
  }, [status, t]);

  return (
    <Container status={status}>
      <Text>
        <span>{text}</span>
      </Text>
    </Container>
  );
};

export default NetworkStatus;
