type AnyObject = { [key: string]: any };

export function removeEmptyProperties(obj: AnyObject): AnyObject {
  const cleanedObj: AnyObject = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      if (typeof value === 'object' && value !== null) {
        const cleanedValue = removeEmptyProperties(value);

        if (Object.keys(cleanedValue).length > 0) {
          cleanedObj[key] = cleanedValue;
        }
      } else if (value !== '' && value !== null && value !== undefined) {
        cleanedObj[key] = value;
      }
    }
  }

  return cleanedObj;
}