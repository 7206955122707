import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import BrowsersList from './browsers-list.component';
import { RootModel, RootState } from '../../../../store/models/root.model';

type BrowsersListContainer = Pick<
  React.ComponentProps<typeof BrowsersList>,
  'match' | 'history' | 'location'
>;

const BrowsersContainer = (props: BrowsersListContainer) => {
  const {
    match: {
      params: { organisationId },
    },
  } = props;

  const { fetchBrowsers, fetchEnvironments, fetchSpaces } = useActions<RootModel>(
    (actions) => ({
      fetchBrowsers: actions.organisationBrowsers.fetch,
      fetchEnvironments: actions.environments.fetch,
      fetchSpaces: actions.organisationSpaces.fetch,
    }),
  );

  const {
    browsers,
    environments,
    spaces,
    browsersLoaded,
    spacesLoaded,
    environmentsLoaded,
  } = useStore<RootState>(
    (state) => ({
      environments: state.environments.values(organisationId),
      environmentsLoaded: state.environments.loaded(organisationId),
      browsers: state.organisationBrowsers.values(organisationId),
      browsersLoaded: state.organisationBrowsers.loaded(organisationId),
      spaces: state.organisationSpaces.values(organisationId),
      spacesLoaded: state.organisationSpaces.loaded(organisationId),
    }),
    [organisationId],
  );

  return (
    <BrowsersList
      {...props}
      browsers={browsers}
      environments={environments}
      spaces={spaces}
      loaded={browsersLoaded && environmentsLoaded && spacesLoaded}
      fetchSpaces={fetchSpaces}
      fetchEnvironments={fetchEnvironments}
      fetchBrowsers={fetchBrowsers}
    />
  );
};

export default BrowsersContainer;
