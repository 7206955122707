import * as React from 'react';
import styled from '@emotion/styled';
import { Spin, Icon } from 'antd';
import { ApiError } from '../../../services/api/api-error';
import Spinner from '../spinner/spinner.component';
import Overlay from '../overlay/overlay.component';
import ErrorView from '../error-view/error-view.component';

interface LoaderProps {
  loading?: boolean;
  hasMounted?: boolean;
  error?: ApiError | JSX.Element | null;
  errorFillPage?: boolean;
  opacity?: number;
  processingTip?: string;
  children: React.ReactNode;
  errorAsOverlay?: boolean;
}

// TO DO: Deprecate this component. Use Overlay (overlay-v2.component.tsx)
const Loader = (props: LoaderProps) => {
  const {
    loading,
    hasMounted = true,
    error,
    children,
    opacity = undefined,
    processingTip,
    errorFillPage = false,
  } = props;
  if (!opacity && (loading || loading === undefined || !hasMounted)) {
    return (
      <Overlay>
        <Spinner />
      </Overlay>
    );
  }

  if (error && error instanceof ApiError) {
    return <ErrorView fillPage={errorFillPage} error={error} />;
  }

  // TO DO: Check actual component of typeof ErrorView
  if (error && React.isValidElement(error)) {
    return <>{error}</>;
  }

  return (
    <>
      {loading && (
        <Loading opacity={opacity}>
          <Spin tip={processingTip} indicator={<Icon type="loading" spin />} />
        </Loading>
      )}
      {children}
    </>
  );
};

const Loading = styled.div<{ opacity?: number }>`
  position: absolute;
  width: 100%;
  background-color: rgb(199, 199, 199, ${({ opacity }) => opacity || 0.3});
  z-index: 10;
  height: 100%;
  top: 0px;
  left: 0px;
  border-radius: 4px;
  display: flex;

  .ant-spin-spinning {
    margin: auto;
  }
  .anticon-loading {
    font-size: 50px;
  }
  .ant-spin-text {
    text-transform: capitalize;
  }
`;

export default Loader;
