import { UseTranslationResponse } from 'react-i18next';
import { SegmentConditionType, SegmentConditionOperator } from '../../../../../store/hooks/segments/types';
import { Option } from '../../../../common/react-final-form';

export function getSegmentConditionTagIdOptions(t: UseTranslationResponse['t']): Option[] {
  return [
    { label: t('contents.location'), value: SegmentConditionType.LOCATION },
    { label: t('contents.region'), value: SegmentConditionType.REGION },
    { label: t('contents.section'), value: SegmentConditionType.SECTION },
    { label: t('contents.floor'), value: SegmentConditionType.FLOOR },
    { label: t('contents.custom'), value: SegmentConditionType.CUSTOM },
  ];
}

export function getSegmentConditionOperatorOptions(t: UseTranslationResponse['t']): Option[] {
  return [
    { label: t('contents.equal-to'), value: SegmentConditionOperator.EQUAL_TO },
    { label: t('contents.not-equal-to'), value: SegmentConditionOperator.NOT_EQUAL_TO },
    { label: t('contents.is-set'), value: SegmentConditionOperator.SET },
    { label: t('contents.is-not-set'), value: SegmentConditionOperator.NOT_SET },
  ];
}
