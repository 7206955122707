import i18next from 'i18next';
import {
  IntegrationFormValues,
  IntegrationTypeEnum,
  IntegrationValidateErrorSchedule,
  ValidationErrorIntegrationForm,
} from '../types';
import { urlRegExp } from '../../../../../../utils/validation/url';
import moment from 'moment';
import { CronSchedule } from '../../../../../common/react-final-form';

const maxSchedules = 10;
const maxFeeds = 10;
const minimumTimeIntervalMin = 15;

// Validation schema using yup is not well-supported possibly due to out-dated ts and react-final-form versions
export const validateIntegrationForm = (values: object): object | Promise<object> => {
  const formValues = (values as unknown) as IntegrationFormValues | undefined;
  const { name = undefined, type = undefined, schedule = [], config = undefined } =
    formValues || {};

  const errors = {} as ValidationErrorIntegrationForm;

  if (name && (name.length < 3 || name.length > 100)) {
    errors.name = i18next.t('formValidation.characterMinMaxLength', { min: 3, max: 100 });
  }

  errors.schedule = validateIntegrationSchedules(schedule);

  if (type === IntegrationTypeEnum.GOOGLE_PRODUCT_FEED) {
    if (!config || !config.feeds || !config.feeds.length) {
      errors.config = {
        feeds: [{ _error: i18next.t('formValidation.atLeastOneEntryRequired') }],
      };
    } else if (config.feeds.length > maxFeeds) {
      errors.config = {
        feeds: [{ _error: i18next.t('formValidation.maxEntries', { max: maxFeeds }) }],
      };
    } else {
      const feeds = config.feeds.map(
        (feed): { locale?: string; url?: string } => {
          const { url = undefined } = feed || {};

          if (url && !urlRegExp.test(url)) {
            return { locale: undefined, url: i18next.t('formValidation.invalidUrl') };
          }

          if (url && (url.length < 10 || url.length > 2000)) {
            return {
              locale: undefined,
              url: i18next.t('formValidation.characterMinMaxLength', {
                min: 10,
                max: 2000,
              }),
            };
          }

          return { locale: undefined, url: undefined };
        },
      );

      const hasError = feeds.some((feed) => !!feed.locale || !!feed.url);

      if (hasError) {
        errors.config = { feeds };
      }
    }
  }

  if (type === IntegrationTypeEnum.GOOGLE_LOCAL_INVENTORY && config && config.feedUrl) {
    if (!urlRegExp.test(config.feedUrl)) {
      errors.config = {
        feedUrl: i18next.t('formValidation.invalidUrl'),
      };
    } else if (config.feedUrl.length < 30 || config.feedUrl.length > 200) {
      errors.config = {
        feedUrl: i18next.t('formValidation.characterMinMaxLength', { min: 30, max: 200 }),
      };
    }
  }

  return errors;
};

export const validateIntegrationSchedules = (
  schedules: CronSchedule[],
): IntegrationValidateErrorSchedule | undefined => {
  if (!schedules.length) {
    return [{ _error: i18next.t('formValidation.atLeastOneEntryRequired') }];
  } else if (schedules.length > maxSchedules) {
    return [{ _error: i18next.t('formValidation.maxEntries', { max: maxSchedules }) }];
  } else {
    const schedulesWithIndex = schedules.map((schedule, index) => ({
      // TO DO: Change input time from string to moment, so there is no need to convert on revalidation
      time: schedule.time ? moment(schedule.time, 'HH:mm') : undefined,
      originalIndex: index,
    }));

    const sortedSchedules = schedulesWithIndex.sort((prevSchedule, currSchedule) => {
      if (!prevSchedule.time || !currSchedule.time) {
        return 0;
      }

      return prevSchedule.time.isBefore(currSchedule.time) ? -1 : 1;
    });

    const validatedSchedules = sortedSchedules.map((schedule, index, arr) => {
      if (index === 0) {
        return { ...schedule, time: undefined };
      }

      const prevTime = arr[index - 1].time;
      const currTime = schedule.time;

      if (!prevTime || !currTime) {
        return { ...schedule, time: undefined };
      }

      const diff = currTime.diff(prevTime, 'minutes');

      if (diff < minimumTimeIntervalMin) {
        return {
          ...schedule,
          time: i18next.t('formValidation.minimumTimeIntervalMin', {
            interval: minimumTimeIntervalMin,
          }),
        };
      }

      return { ...schedule, time: undefined };
    });

    const reorderedSchedules = validatedSchedules
      .sort(
        (prevSchedule, currSchedule) =>
          prevSchedule.originalIndex - currSchedule.originalIndex,
      )
      .map((schedule) => ({ time: schedule.time }));

    const hasError = reorderedSchedules.some((schedule) => !!schedule.time);

    return hasError ? reorderedSchedules : undefined;
  }
};
