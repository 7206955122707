import { thunk, action, Action, Thunk } from 'easy-peasy';
import Injections from '../../injections.interface';
import { Subscription } from '../../types/subscription';

export const CardBrandEnum = {
  AMEX: 'American Express',
  CARTES_BANCAIRES: 'Cartes Bancaires',
  DINERS_CLUB: 'Diners Club',
  DISCOVER: 'discover',
  JCB: 'JCB',
  MASTERCARD: 'Mastercard',
  VISA: 'Visa',
  UNIONPAY: 'UnionPay',
};

export const BillingTypeEnum = {
  LEGACY: 'legacy',
  AZURE_SAAS: 'azure-saas',
  STRIPE: 'stripe',
  DIRECT_INVOICE: 'direct-invoice',
  PARTNER_INVOICE: 'partner-invoice',
};

export const SubscriptionStateEnum = {
  UNSUBSCRIBE: 'unsubscribe',
  REINSTATE: 'reinstate',
  SUSPEND: 'suspend',
  SUBSCRIBE: 'subscribe',
};

export interface SubscriptionsModel {
  data: {
    subscription: Subscription | null;
  };
  setData: Action<SubscriptionsModel, { data: Subscription }>;
  createStripeSubscriptionAndRedirect: Thunk<
    SubscriptionsModel,
    { successPath: string; cancelPath: string; organisationId: string },
    Injections
  >;
  cancelSubscription: Thunk<
    SubscriptionsModel,
    { organizationId: string; subscriptionId: string },
    Injections
  >;
  fetchSubscription: Thunk<SubscriptionsModel, { organizationId: string }, Injections>;
}

const subscriptionsModel: SubscriptionsModel = {
  data: {
    subscription: null,
  },
  setData: action((state, { data }: { data: Subscription }) => {
    state.data.subscription = data;
  }),
  createStripeSubscriptionAndRedirect: thunk(
    async (actions, { successPath, organisationId, cancelPath }, { injections }) => {
      const baseClientUrl = `${document.location.protocol}//${document.location.host}`;
      const { stripeSessionUrl } = await injections.apiService.post<{
        stripeSessionUrl: string;
      }>('/api/subscriptions/stripe', {
        organizationId: organisationId,
        successUrl: `${baseClientUrl}${successPath}`,
        cancelUrl: `${baseClientUrl}${cancelPath}`,
      });

      document.location.href = stripeSessionUrl;
    },
  ),
  cancelSubscription: thunk(async (actions, payload, { injections }) => {
    const { organizationId } = payload;

    await injections.apiService.post<{}>(
      `/api/organizations/${organizationId}/unsubscribe`,
      {},
    );
  }),
  fetchSubscription: thunk(async (actions, payload, { injections }) => {
    const { organizationId } = payload;

    const data = await injections.apiService.get<Subscription>(
      `/api/organizations/${organizationId}/active-subscription`,
    );

    actions.setData({ data });
  }),
};

export default subscriptionsModel;
