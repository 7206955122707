import AppMobileEndpoint from '../../types/mobile-endpoint';
import createKeyedCrudModel, {
  KeyedCrudModel,
} from '../../common/keyed-crud-model/keyed-crud.model';

export type AppMobileEndpointModel = KeyedCrudModel<AppMobileEndpoint, { appId: string }>;

const appMobileEndpointModel: AppMobileEndpointModel = createKeyedCrudModel<
  AppMobileEndpoint,
  { appId: string }
>('/api/mobile-endpoints', 'appId');

export default appMobileEndpointModel;
