import React, { useRef, useCallback, useEffect, useMemo } from 'react';
import { message, Button } from 'antd';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { DataResidencyEnum } from '@ombori/grid-products';
import PanelCard from '../../../../common/panel-card/panel-card.component';
import SchemaForm, {
  SchemaFormRef,
} from '../../../../common/schema-form/schema-form.component';
import useSchemaForm from '../../../../common/use-schema-form/use-schema-form';
import User from '../../../../../store/types/user';
import OrganisationSpace from '../../../../../store/types/organisation-space';
import Environment from '../../../../../store/types/environment';
import { DEFAULT_PRODUCTS_ENVIRONMENT } from '../utils';
import useQueryParams from '../../../../common/use-query-params/use-query-params';

interface GridProductsUploadComponentProps {
  tenantId: string;
  environments: Environment[];
  spaces: OrganisationSpace[];
  user: User;
  dataResidency: DataResidencyEnum;
  fetchMedia: (params: { organization: string }) => void;
  fetchEnvironments: (params: { organizationId: string }) => void;
  fetchSpaces: (params: { organizationId: string; silent?: boolean; }) => void;
  uploadExcel: (params: {
    dataResidency: string;
    env: string;
    tenantId: string;
    spaceId: string;
    fileUrl: string;
  }) => { data: { isError: boolean; message: string } };
}

const GridProductsUploadComponent = ({
  tenantId,
  environments,
  spaces,
  user,
  dataResidency,
  fetchEnvironments,
  fetchSpaces,
  uploadExcel,
  fetchMedia,
}: GridProductsUploadComponentProps) => {
  const { t } = useTranslation();

  const formElement = useRef<SchemaFormRef>(null);

  const [queryParams] = useQueryParams({ env: DEFAULT_PRODUCTS_ENVIRONMENT });
  const { env } = queryParams;

  const handleError = () => {
    message.error(t('thereAreErrorsInTheContentForm'));
  };

  const handleUpload = useCallback(() => {
    if (formElement.current) {
      formElement.current.submit();
    }
  }, [formElement]);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        const response = await uploadExcel({
          dataResidency,
          env: data.environment,
          tenantId,
          fileUrl: data.excelFile.url,
          spaceId: data.spaceId,
        });

        if (response.data.isError) {
          throw response.data.message;
        }
      } catch (err) {
        throw err;
      }
    },
    [uploadExcel, dataResidency, tenantId],
  );

  useEffect(() => {
    fetchMedia({ organization: tenantId });
    fetchEnvironments({ organizationId: tenantId });
  }, [fetchEnvironments, tenantId, fetchMedia]);

  useEffect(() => {
    fetchSpaces({ organizationId: tenantId, silent: true });
  }, [fetchSpaces, tenantId]);

  const formSchema = useMemo(
    () => ({
      type: 'object',
      properties: {
        environment: {
          type: 'string',
          title: t('environment'),
          enum: environments.map((env: any) => env.environmentName),
          enumNames: environments.map((env: any) => env.displayName),
          readOnly: environments.length < 1,
          default: env,
        },
        spaceId: {
          title: t('space'),
          type: 'string',
          enum: ['', ...spaces.map((space: any) => space.id)],
          enumNames: ['', ...spaces.map((space: any) => space.displayName)],
          default: spaces.length ? spaces[0].id : '',
          readOnly: spaces.length < 1,
        },
        excelFile: {
          title: t('gridProductUpload.selectExcelFile'),
          type: 'object',
          properties: {
            ref: {
              const: 'media',
            },
            type: {
              type: 'string',
              enum: [
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.ms-excel',
              ],
            },
            id: {
              type: 'string',
            },
            url: {
              type: 'string',
            },
            name: {
              type: 'string',
            },
          },
        },
      },
      required: ['environment', 'spaceId', 'excelFile'],
    }),
    [environments, env, spaces, t],
  );

  const metaSchema = {
    excelFile: {
      'ui:field': 'mediaPicker',
    },
  };

  const [
    formData,
    formLoading,
    formDirty,
    ,
    handleFormChange,
    handleFormSubmit,
  ] = useSchemaForm(
    handleSubmit,
    t('gridProductUpload.excelUploadSuccessful'),
    t('gridProductUpload.errorUploadingExcel'),
  );

  const steps = t('gridProductUpload.excelUploadInstructions', {
    returnObjects: true,
  }) as string[];

  return (
    <Content>
      <FormContainer>
        <SchemaForm
          ref={formElement}
          organisationId={tenantId}
          data={formData}
          onChange={handleFormChange}
          uiSchema={metaSchema}
          schema={formSchema}
          onSubmit={handleFormSubmit}
          onError={handleError}
          formContext={{ user }}
          liveValidate={false}
        />

        <ActionsWrapper>
          <DownloadButton
            // Hardcoded reference file on https://console.omborigrid.com/organisations/5cbac8a388e174147b878cdd/library/606df4d6b9b8d312e5fa61f4
            href="https://media.omborigrid.com/media/5cbac8a388e174147b878cdd/80d0e140-dc2b-11ec-b0cf-d96564828fee"
            type="secondary"
            size="large"
            icon="download"
            data-testid="grid-products-download-excel-template-button"
          >
            {t('gridProductUpload.downloadExcelFile')}
          </DownloadButton>
          <UploadButton
            size="large"
            type="primary"
            loading={formLoading}
            onClick={handleUpload}
            disabled={!formDirty}
            data-testid="grid-products-upload-excel-button"
          >
            {t('gridProductUpload.upload')}
          </UploadButton>
        </ActionsWrapper>
      </FormContainer>

      <Instruction>
        <Title>{t('instructions')}</Title>
        <Steps>
          {steps.map((step) => (
            <StepWrapper key={`${step}`}>
              <Step dangerouslySetInnerHTML={{ __html: step }} />
            </StepWrapper>
          ))}
        </Steps>
      </Instruction>
    </Content>
  );
};

const Content = styled(PanelCard)`
  margin: 20px;
  position: relative;
  flex: 1;
  display: flex;

  .ant-card-body {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 0px;
  }
`;

const FlexContent = styled.div`
  flex-direction: column;
  flex: 1;

  .ant-card-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 12px;
  }
`;

const FormContainer = styled(FlexContent)`
  margin: 0;
  padding: 12px;
`;

const Instruction = styled(FlexContent)`
  flex: 2;
  background-color: #f5f5fa;
  padding: 16px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const Title = styled.div`
  font-weight: bold;
  padding: 0px 0px 12px 0px;
`;

const Steps = styled.ol`
  list-style: none;
  counter-reset: step-counter;
`;

const Step = styled.span`
  padding-top: 1px;
`;

const StepWrapper = styled.li`
  padding: 8px 0px;
  counter-increment: step-counter;
  display: flex;
  flex: 1;
  word-break: break-word;

  &::before {
    content: counter(step-counter);
    font-size: 11px;
    background-color: #fff;
    min-width: 22px;
    height: 22px;
    line-height: 22px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    margin-right: 12px;
    margin-top: 1px;
  }
`;

const ActionsWrapper = styled.div`
  margin-top: 24px;
`;

const UploadButton = styled(Button)`
  width: 100%;
` as any;

const DownloadButton = styled(UploadButton)`
  margin-bottom: 12px;
` as any;

export default GridProductsUploadComponent;
