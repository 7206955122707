import { TimeSpanType } from './types';

interface BuildQueryKeyProps {
  tenantId: string;
  dateFrom: string;
  dateTo: string;
  timespanType: TimeSpanType;
}

const buildQueryKey = ({
  tenantId,
  dateFrom,
  dateTo,
  timespanType,
}: BuildQueryKeyProps) => [
  'analytics',
  'report',
  'tenantId',
  tenantId,
  'dateFrom',
  dateFrom,
  'dateTo',
  dateTo,
  'timespanType',
  timespanType,
];

export default buildQueryKey;
