import React, { useMemo } from 'react';
import uniq from 'lodash/uniq';
import { useActions, useStore } from 'easy-peasy';
import UsersList from '../../common/users-list/users-list.component';
import { RootModel, RootState } from '../../../store/models/root.model';
import { getPermissions } from '../../../utils/auth/permissions';

type UsersListContainerProps = Omit<
  React.ComponentProps<typeof UsersList>,
  | 'users'
  | 'organisations'
  | 'loaded'
  | 'fetchUsers'
  | 'createUser'
  | 'updateUser'
  | 'deleteUser'
  | 'fetchOrganisations'
  | 'canCreate'
  | 'canUpdate'
  | 'canDelete'
>;

const UsersListContainer = (props: UsersListContainerProps) => {
  const {
    fetchUsers,
    createUser,
    updateUser,
    deleteUser,
    validateUserEmail,
    fetchOrganisations,
    fetchUserRoles,
  } = useActions<RootModel>((actions) => ({
    fetchUsers: actions.users.fetch,
    createUser: actions.users.create,
    updateUser: actions.users.update,
    deleteUser: actions.users.delete,
    validateUserEmail: actions.users.validateUserEmail,
    fetchOrganisations: actions.organisations.fetch,
    fetchUserRoles: actions.userRoles.fetchAll,
  }));

  const { users, organisations, loaded, permissions, userRoles, loggedInUser } = useStore<
    RootState
  >((state) => ({
    users: state.users.values,
    organisations: state.organisations.values,
    loaded: state.users.loaded && state.userRoles.loadedAll,
    permissions: getPermissions(state.auth.user, 'grid'),
    userRoles: state.userRoles.data,
    loggedInUser: state.auth.user,
  }));

  const userRolesWithGlobal = useMemo(() => {
    const organizationRecords: {
      id: string;
      parentOrganizationId: string | undefined;
    }[] = uniq(
      organisations.map((org: any) => {
        return {
          id: org.id,
          parentOrganizationId: org.parentOrganizationId || undefined,
        };
      }),
    );

    const globalUserRoles = userRoles.global || {};
    return organizationRecords.reduce(
      (organizationRoles, nextOrg) => {
        return {
          ...organizationRoles,
          [nextOrg.id]: {
            ...globalUserRoles,
            ...userRoles[nextOrg.id],
            ...(nextOrg.parentOrganizationId
              ? userRoles[nextOrg.parentOrganizationId]
              : []),
          },
        };
      },
      { global: globalUserRoles },
    );
  }, [userRoles, organisations]);

  return (
    <UsersList
      {...props}
      users={users}
      organisations={organisations}
      loaded={loaded}
      fetchUserRoles={fetchUserRoles}
      fetchUsers={fetchUsers}
      userRoles={userRolesWithGlobal}
      createUser={createUser}
      updateUser={updateUser}
      deleteUser={deleteUser}
      validateUserEmail={validateUserEmail}
      fetchOrganisations={fetchOrganisations}
      canCreate={permissions.users.create}
      canUpdate={permissions.users.update}
      canDelete={permissions.users.remove}
      loggedInUser={loggedInUser}
    />
  );
};

export default UsersListContainer;
