import { useMutation, UseMutationResult } from 'react-query';
import { ApiError } from '../../../../../../services/api/api-error';
import { IntegrationFormValues, IntegrationSettings } from '../types';
import { useGridProductsFeedService } from '../../../../../common/grid-products-connector-service-provider';
import { DataResidencyEnum } from '../../../../../../store/types/organisation';

interface UseUpdateIntegrationProps {
  tenantId: string;
  dataResidency: DataResidencyEnum;
  integrationId: string;
}

export interface UpdateIntegrationVariables
  extends Partial<Omit<IntegrationFormValues, 'schedule'>> {
  schedule?: string[];
}

type UseUpdateIntegrationResponse = UseMutationResult<
  IntegrationSettings,
  ApiError,
  UpdateIntegrationVariables
>;

const useUpdateIntegration = (
  props: UseUpdateIntegrationProps,
): UseUpdateIntegrationResponse => {
  const { tenantId, dataResidency, integrationId } = props;

  const gridProductsFeedService = useGridProductsFeedService(dataResidency);

  const result = useMutation(
    async (body: UpdateIntegrationVariables) => {
      const url = `/${tenantId}/integrations/${integrationId}`;

      return gridProductsFeedService.patch<IntegrationSettings>(url, {
        ...body,
        dataResidency,
      });
    },
    {
      onError: (_error: ApiError) => {},
    },
  );

  return result;
};

export default useUpdateIntegration;
