import React from 'react';
import styled from '@emotion/styled';
import { Row, Col, Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { RawMonitoringEvent } from '../../use-analytics-report';
import OrganisationSpace from '../../../../store/types/organisation-space';
import UniversalDevice from '../../../../store/types/universal-device';

export type StatusReferenceType = Record<
  string | number,
  {
    label: string;
    color: string;
  }
>;

type RealtimeStatusChartProps = {
  data: RawMonitoringEvent[];
  spaces: Record<string, OrganisationSpace>;
  devices: Record<string, UniversalDevice>;
  statusReference: StatusReferenceType;
};

const getFromNow = (datetime: Date) => {
  const dayJsFormattedTime = dayjs(datetime);
  return dayJsFormattedTime.fromNow();
};

const getStatusLabel = (
  statusReference: StatusReferenceType,
  status: number | undefined,
) => {
  if (status === undefined) {
    return 'Undefined';
  }

  return statusReference[status] ? statusReference[status].label : 'Undefined';
};

const getStatusColor = (
  statusReference: StatusReferenceType,
  status: number | undefined,
) => {
  if (status === undefined) {
    return '#292b2c';
  }

  return statusReference[status] ? statusReference[status].color : '#292b2c';
};

const getSpaceName = (spaces: Record<string, OrganisationSpace>, spaceId: string) => {
  if (spaces[spaceId]) {
    return spaces[spaceId].displayName;
  }

  return 'Unknown space';
};

const getDeviceName = (devices: Record<string, UniversalDevice>, deviceId: string) => {
  if (devices[deviceId]) {
    return devices[deviceId].deviceName;
  }

  return `<${deviceId}>`;
};

export default function RealtimeStatusComponent({
  data,
  spaces,
  devices,
  statusReference,
}: RealtimeStatusChartProps) {
  return (
    <>
      {data.map((d, index) => (
        <StyledRow type="flex" key={`realtime-status-${index}`}>
          <Col
            span={10}
            dangerouslySetInnerHTML={{
              __html: `<b>${getSpaceName(spaces, d.spaceId)}</b> ${getDeviceName(
                devices,
                d.deviceId,
              )}`,
            }}
          />
          <Col span={3}>
            <Tooltip title={d.str2}>{d.str2 && <span>{d.str1}</span>}</Tooltip>
          </Col>
          <StyledStatusCol span={10}>
            <Tooltip title={d.eventTime}>
              <StyledStatusTag>
                <StatusIndicator color={getStatusColor(statusReference, d.int1)} />
                <b>{getStatusLabel(statusReference, d.int1)}</b>&nbsp;{' '}
                {`Since ${getFromNow(d.eventTime)}`}
              </StyledStatusTag>
            </Tooltip>
          </StyledStatusCol>
        </StyledRow>
      ))}
    </>
  );
}

const StyledRow = styled(Row)`
  padding-bottom: 8px;
  padding-top: 8px;
`;

const StyledStatusCol = styled(Col)`
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const StyledStatusTag = styled(Tag)`
  display: flex;
  align-items: center;
  padding: 2px 12px;
  border-radius: 24px;
`;

const StatusIndicator = styled.span<{ color: string }>`
  height: 8px;
  width: 8px;
  display: flex;
  background: ${({ color }) => color};
  margin-right: 4px;
  border-radius: 32px;
`;
