import { ColorResult } from 'react-color';
import tinycolor from 'tinycolor2';
import { RGBColor } from 'react-color';

export interface ColorSet {
    hex: string;
    rgb: string;
    hsl: string;
    // `value` is default to hex/a for now. If necessary, identify which color type above that the user wants to apply
    // Currently, picker just returns all above color types
    value: string;
}

export const convertColorResultToColorSet = (color: ColorResult): ColorSet => {
    const hex = color.hex;
    const rgb = tinycolor(color.rgb);
    const hsl = tinycolor(color.hsl);

    return {
        hex,
        rgb: rgb.toRgbString(),
        hsl: hsl.toHslString(),
        value: hex,
    };
};

export const getHexStringFromRGB = (rgb: RGBColor | tinycolor.ColorFormats.RGB): string => {
    const colorRgb  = tinycolor(rgb);
    const isTransparent = colorRgb.toRgb().a < 1;

    return isTransparent ? colorRgb.toHex8String() : colorRgb.toHexString();
};

export const getColorSet = (primaryColor?: string, defaultColor?: string): ColorSet => {
    const colorPrimary = tinycolor(primaryColor);

    if (colorPrimary.isValid()) {
        // Get the rgb instead of initial hex bec it preserves alpha info
        const hex = getHexStringFromRGB(colorPrimary.toRgb());

        return {
            hex,
            rgb: colorPrimary.toRgbString(),
            hsl: colorPrimary.toHslString(),
            value: hex,
        };
    }

    const colorDefault = tinycolor(defaultColor);
    if (colorDefault.isValid()) {
        const hex = getHexStringFromRGB(colorDefault.toRgb());

        return {
            hex,
            rgb: colorDefault.toRgbString(),
            hsl: colorDefault.toHslString(),
            value: hex,
        };
    }

    const colorFallback = tinycolor();
    const hex = getHexStringFromRGB(colorFallback.toRgb());

    return {
        hex,
        rgb: colorFallback.toRgbString(),
        hsl: colorFallback.toHslString(),
        value: hex,
    };
};
