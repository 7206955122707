import { useQuery } from 'react-query';
import { useApiService } from '../../../app/common/api-service-provider';
import libraryQueryKeys from './library-query-keys';
import GridappBuild from '../../types/gridapp-build';

const useContentLibraryGridAppBuild = (params: { tenantId: string, gridAppId: string, gridAppBuildId: string }) => {
  const apiService = useApiService();

  const { tenantId, gridAppId, gridAppBuildId } = params;

  return useQuery(
    libraryQueryKeys.getGridAppBuild(
      tenantId,
      gridAppBuildId,
    ),
    () =>
      apiService.get<GridappBuild>(`/api/gridapps/${gridAppId}/builds/${gridAppBuildId}`, {
        tenantId,
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      enabled: tenantId && gridAppId && gridAppBuildId ? true : false,
    },
  );
};

export default useContentLibraryGridAppBuild;

