import * as React from 'react';
import styled from '@emotion/styled';
import { Card, Typography } from 'antd';
import Build from '../../../../../../store/types/build';
import ProtectedSectionContainer from '../../../../../common/protected-section/protected-section.container';
import BuildStatusComponent from '../../../../../common/build-status/build-status.component';
import BuildProgress from '../../../../../common/build-progress/build-progress.component';

const { Text } = Typography;

interface BuildItemProps {
  build: Build;
  permissionPath: string;
  isSysAdmin: boolean;
}

const BuildItemWrapper = styled(Card)`
  margin-top: 16px;
`;

const BuildItem = ({ build, permissionPath, isSysAdmin }: BuildItemProps) => {
  const title = [build.release, build.provider]
    .filter((value) => value != null)
    .join(' - ');

  return (
    <BuildItemWrapper>
      <Text strong>{title}</Text>
      <ProtectedSectionContainer permissionPath={permissionPath}>
        <BuildStatusComponent isSysAdmin={isSysAdmin} build={build} />
      </ProtectedSectionContainer>
      <BuildProgress build={build} />
    </BuildItemWrapper>
  );
};

export default BuildItem;
