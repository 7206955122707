import { action, Action, Select, select, thunk, Thunk } from 'easy-peasy';
import { DeviceModules } from '../../types/device-module';

import { ApiError } from '../../../services/api/api-error';
import Injections from '../../injections.interface';

export interface DeviceModulesModel {
  data: { [id: string]: DeviceModules };
  loading: {
    [key: string]: boolean;
  };
  error: {
    [key: string]: ApiError | null;
  };
  loaded: Select<DeviceModulesModel, (id: string) => boolean>;
  setSingle: Action<DeviceModulesModel, { id: string; modules: DeviceModules }>;
  setError: Action<DeviceModulesModel, { id: string; err: ApiError | null }>;
  setLoading: Action<DeviceModulesModel, { id: string; loading: boolean }>;
  fetch: Thunk<DeviceModulesModel, { id: string }, Injections>;
  update: Thunk<DeviceModulesModel, { id: string; modules: DeviceModules }, Injections>;
}

const model: DeviceModulesModel = {
  loading: {},
  error: {},
  data: {},
  loaded: select((state) => (id: string) => !!state.data[id] && !state.loading[id]),
  setLoading: action((state, { id, loading }) => {
    state.loading[id] = loading;
  }),
  setError: action((state, { id, err }) => {
    state.error[id] = err;
  }),
  setSingle: action((state, payload) => {
    if (!state.data) {
      state.data = {};
    }
    state.data[payload.id] = payload.modules;
  }),
  fetch: thunk(async (actions, { id }, { injections }) => {
    actions.setLoading({ id, loading: true });
    actions.setError({ id, err: null });
    try {
      const modules = await injections.apiService.get<DeviceModules>(
        `/api/v2/devices/${id}/modules`,
      );
      actions.setSingle({ id, modules });
    } catch (err) {
      actions.setError({ id, err });
    } finally {
      actions.setLoading({ id, loading: false });
    }
  }),
  update: thunk(async (actions, payload, { injections }) => {
    const modules = await injections.apiService.put<DeviceModules>(
      `/api/v2/devices/${payload.id}/modules`,
      payload.modules,
    );
    actions.setSingle({ id: payload.id, modules });
  }),
};

export default model;
