import { useCallback, useMemo } from 'react';
import { TFunction } from 'i18next';
import { FilterOption } from '../../store/types/filters';

export enum StageEnum {
  PRODUCTION = 'production',
  QA = 'qa',
  OMBORI_INTERNAL = 'ombori-internal',
}

export interface StageOption extends FilterOption {}

interface UseStageResponse {
  stageOptions: StageOption[];
  getStageLabel: (stage: StageEnum) => string;
}

const useStage = (t: TFunction): UseStageResponse => {
  const stageOptions = useMemo(() => {
    return [
      {
        id: StageEnum.PRODUCTION,
        label: t('stageOption.production'),
      },
      {
        id: StageEnum.OMBORI_INTERNAL,
        label: t('stageOption.omboriInternal'),
      },
      {
        id: StageEnum.QA,
        label: t('stageOption.qa'),
      },
    ];
  }, [t]);

  const getStageLabel = useCallback(
    (stage: StageEnum): string => {
      const matchedOption = stageOptions.find((option) => option.id === stage);

      return matchedOption ? matchedOption.label : '';
    },
    [stageOptions],
  );

  return {
    stageOptions,
    getStageLabel,
  };
};

export default useStage;
