import * as React from 'react';
import * as Sentry from '@sentry/browser';
import ErrorView from '../error-view/error-view.component';

interface ErrorBoundaryState {
  hasError: boolean;
}

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError = () => {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  };

  public componentDidCatch = (error: Error, errorInfo: React.ErrorInfo) => {
    if (process.env.NODE_ENV === 'production') {
      // in dev mode, error boundary errors are re-thrown by React and will be logged by global handlers
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        Sentry.captureException(error);
      });
    }
  };

  public render = () => {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <ErrorView />;
    }

    return children;
  };
}

export default ErrorBoundary;
