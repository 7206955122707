import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { Button, Col, Dropdown, Menu, message, Row, Typography, Icon, Modal } from 'antd';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { get } from 'lodash';
import moment from 'moment';
import validateFormData from 'react-jsonschema-form/lib/validate';
import OrganisationApp from '../../../../../store/types/organisation-app';
import SchemaForm, {
  SchemaFormRef,
} from '../../../../common/schema-form/schema-form.component';
import User from '../../../../../store/types/user';
import Overlay from '../../../../common/overlay/overlay.component';
import Spinner from '../../../../common/spinner/spinner.component';
import { ApiError } from '../../../../../services/api/api-error';
import ErrorView from '../../../../common/error-view/error-view.component';
import ErrorBoundary from '../../../../common/error-boundary/error-boundary.component';
import { CreateAppBuildParams } from '../../../../../store/models/app-builds/app-builds.model';
import Build from '../../../../../store/types/build';
import BuildItem from './build-item/build-item.component';
import PanelCard from '../../../../common/panel-card/panel-card.component';
import DeviceTypeEnum from '../../../../../store/types/device-type.enum';
import Environment from '../../../../../store/types/environment';
import LanguageSwitcher from '../../../../common/language-switcher/language-switcher.component';
import { getPermissionPath, permissionKeys } from '../../../../../utils/auth/permissions';
import ContentWrap from '../../../../common/app-layout/content/content-wrap.component';
import usePermissions from '../../../../../utils/auth/use-permissions';
import TemporarySettingsVersionSwitcher from '../temporary-settings-version-switcher/temporary-settings-version-switcher.component';
import useHasPermissions from '../../../../../utils/auth/use-has-permissions';

const { Paragraph, Title } = Typography;

interface ContentProps
  extends RouteComponentProps<{ appId: string; organisationId: string }> {
  schema: any | null;
  app: OrganisationApp | null;
  metaSchema: any | null;
  user: User | null;
  loaded: boolean;
  fetchSchema: (params: { appId: string; release: string; provider: string }) => void;
  error: ApiError | null;
  updateApp: (app: OrganisationApp) => void;
  fetchMedia: (params: { organization: string }) => void;
  createBuild: (params: CreateAppBuildParams) => void;
  fetchBuilds: (params: { appId: string }) => void;
  activeBuilds: Build[];
  fetchApp: (params: { appId: string }) => void;
  environments: Environment[];
  fetchEnvironments: (params: { organizationId: string }) => void;
  fetchGridapps: (params: { organizationId: string }) => void;
  canUpdate: boolean;
  canPublish: boolean;
  gridapp?: string;
  appBuilds?: { [id: string]: { [id: string]: any } };
  onEnableSettingsV2: (enable: boolean) => void;
  canSwitchSettingsV2: boolean;
}

const LanguageSwitcherWrapper = styled.div`
  display: flex;
  z-index: 999;
  width: 100%;
  flex-direction: column;
`;

const LanguageSwitcherCard = styled(PanelCard)`
  margin-top: 14px;
  width: 100%;
  display: flex;
`;

const LanguageSwitcherTitle = styled.p`
  margin-bottom: 8px;
`;

const LanguageSelect = styled(LanguageSwitcher)`
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const StickyColumn = styled(Col)`
  position: sticky;
  top: 16px;
`;

const PublishContainer = styled.div`
  display: flex;
`;

const PublishDropdown = styled(Dropdown)`
  margin-left: 4px;
` as any;

const PublishDropdownMenuIcon = styled(Icon)`
  margin-right: 4px;
`;

const Content = (props: ContentProps) => {
  const {
    schema,
    app,
    metaSchema,
    user,
    loaded,
    fetchSchema,
    error,
    updateApp,
    fetchMedia,
    createBuild,
    fetchBuilds,
    activeBuilds,
    fetchApp,
    environments,
    fetchEnvironments,
    fetchGridapps,
    canUpdate,
    canPublish,
    history,
    gridapp,
    appBuilds,
    match,
    staticContext,
    location: routeLocation,
    onEnableSettingsV2,
    canSwitchSettingsV2,
  } = props;
  const [languageFrom, setLanguageFrom] = useState('');
  const [languageTo, setLanguageTo] = useState('');
  const formElement = useRef<SchemaFormRef>(null);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({});
  const [formDirty, setFormDirty] = useState<boolean>(false);
  const [formInitiallyValid, setFormInitiallyValid] = useState<boolean>(false);
  const { t } = useTranslation();
  const { isSysAdmin } = usePermissions(match.params.organisationId);
  const [isSettingsV2SwitchLoading, setIsSettingsV2SwitchLoading] = useState(false);

  const isEditAllowed = useHasPermissions(
    match.params.organisationId,
    permissionKeys.apps.update,
  );

  const { supportedLanguages, defaultLanguage } = useMemo(() => {
    return {
      supportedLanguages:
        app && app.multiLanguageSupport ? app.supportedLanguages || [] : [],
      defaultLanguage: app && app.multiLanguageSupport ? app.defaultLanguage || '' : '',
    };
  }, [app]);

  useEffect(() => {
    setLanguageFrom(defaultLanguage);
    const otherLanguage = supportedLanguages.find((lang) => lang !== defaultLanguage);
    setLanguageTo(otherLanguage || defaultLanguage);
  }, [defaultLanguage, supportedLanguages]);

  const handleSave = useCallback(() => {
    if (formElement.current) {
      formElement.current.submit();
    }
  }, [formElement]);

  // preselect gridapp when new app is created
  const ga =
    formData &&
    formData.provider &&
    formData.provider.app &&
    formData.provider.app.gridApp;
  useEffect(() => {
    if (gridapp && typeof ga === 'object' && ga.gridapp === undefined) {
      // app not yet selected
      setFormData((data: any) => {
        data.provider.app.gridApp.gridapp = {
          id: gridapp,
          buildId: undefined,
        };
        return data;
      });
    }
  }, [ga, gridapp, setFormData]);

  // preselect build when new app is created
  useEffect(() => {
    if (!gridapp) return;
    if (!appBuilds) return;
    if (ga && ga.gridapp && ga.gridapp.id && ga.gridapp.buildId === undefined) {
      const builds = appBuilds[ga.gridapp.id];
      if (!builds) return;
      const id = Object.keys(builds).shift(); // pick the last build
      if (!id) return;

      setFormData((data: any) => {
        data.provider.app.gridApp.gridapp.buildId = id;
        data.provider.app.gridApp.ref = 'media';
        data.provider.app.gridApp.type = 'application/x-gridapp';
        data.provider.app.gridApp.id = id;
        data.provider.app.gridApp.name = builds[id].filename;
        data.provider.app.gridApp.url = builds[id].url;
        return data;
      });
    }
  }, [appBuilds, ga, gridapp, setFormData]);

  const handleChange = useCallback((data: any) => {
    setFormData(data);
    setFormDirty(true);
  }, []);

  const hasAppTarget = !!get(schema, 'properties.provider.properties.app');
  const hasWebTarget = !!get(schema, 'properties.provider.properties.web');

  const publish = useCallback(
    async (environmentName: string) => {
      if (app) {
        setFormLoading(true);
        // TODO: consider extracting this as separate endpoint
        try {
          await createBuild({
            appId: app.id,
            autoDeployEnvironment: environmentName,
            balena: hasAppTarget && app.deviceType === DeviceTypeEnum.BALENA,
            webpack: hasWebTarget,
            windows: hasAppTarget && app.deviceType === DeviceTypeEnum.WINDOWS,
            linux: hasAppTarget && app.deviceType === DeviceTypeEnum.LINUX,
            wpa: hasAppTarget && app.deviceType === DeviceTypeEnum.WPA,
            mobilewpa: hasAppTarget && app.deviceType === DeviceTypeEnum.MOBILE_WPA,
          });
          await fetchApp({ appId: app.id });
          message.success(t('publishStarted'));
        } catch {
          message.error(t('publishFailed'));
        } finally {
          setFormLoading(false);
        }
      }
    },
    [app, createBuild, fetchApp, hasAppTarget, hasWebTarget, t],
  );

  const handleEnableSettingsV2Layout = useCallback((enable: boolean) => {
    setIsSettingsV2SwitchLoading(true);
    onEnableSettingsV2(enable);
  }, [onEnableSettingsV2]);

  const handlePublishClick = useCallback(
    (env: Environment) => {
      Modal.confirm({
        title: t('confirmOperation'),
        content: (
          <>
            {t('doYouWantToPublishToAllDevicesIn')} <b>{env.displayName}</b> environment?
          </>
        ),
        okText: t('yes'),
        okType: 'danger',
        cancelText: t('no'),
        width: '30%',
        onOk: () => publish(env.environmentName),
      });
    },
    [publish, t],
  );

  const handleSubmit = useCallback(
    async (data: any) => {
      if (app) {
        const updatedApp = {
          ...app,
          settings: data,
        };
        setFormLoading(true);
        try {
          await updateApp(updatedApp);
          message.success(t('contentSaved'));
          setFormDirty(false);
        } catch (e) {
          const apiMessage =
            (e.response &&
              e.response.data.message &&
              e.response.data &&
              e.response.data.message) ||
            t('errorSavingContent');
          message.error(apiMessage);
        } finally {
          setFormLoading(false);
        }
      }
    },
    [app, updateApp, t],
  );

  const handleError = useCallback(
    (errors: any) => {
      console.error(errors); // eslint-disable-line no-console
      message.error(t('thereAreErrorsInTheContentForm'));
    },
    [t],
  );

  useEffect(() => {
    if (app) {
      if (schema == null || metaSchema == null) {
        fetchSchema({
          appId: app.id,
          release: app.release,
          provider: app.provider,
        });
      }
      setFormData(app.settings);
      setFormLoading(false);
      // TODO: seriously, figure out how to do it properly
      setTimeout(() => {
        setFormDirty(false);
      });
    }
  }, [app, schema, metaSchema, fetchSchema]);

  useEffect(() => {
    if (app && schema) {
      // lib type is not valid here
      const hasError = (validateFormData(app.settings, schema) as any).errors.length > 0;
      setFormInitiallyValid(!hasError);
    }
  }, [app, schema]);

  useEffect(() => {
    if (app) {
      fetchMedia({ organization: app.organizationId });
      fetchBuilds({ appId: app.id });
      fetchEnvironments({ organizationId: app.organizationId });
      fetchGridapps({ organizationId: app.organizationId });
    }
  }, [app, fetchMedia, fetchBuilds, fetchEnvironments, fetchGridapps]);

  const handleEnvironmentsPublishMenuClick = useCallback(
    ({ key: envName }) => {
      const environment = environments.find((env) => env.environmentName === envName);
      if (environment) {
        handlePublishClick(environment);
      }
    },
    [environments, handlePublishClick],
  );

  const renderPublishButton = useCallback(() => {
    const prodEnv = environments.find((env) => env.environmentName === 'prod');

    if (!prodEnv) {
      return null;
    }

    const otherEnv = environments.filter((env) => env !== prodEnv);

    const menu = otherEnv.length ? (
      <Menu onClick={handleEnvironmentsPublishMenuClick}>
        {otherEnv.map((env) => (
          <Menu.Item key={env.environmentName}>
            <PublishDropdownMenuIcon type="cloud-upload" />
            {`${t('publishTo')} ${env.displayName}`}
          </Menu.Item>
        ))}
      </Menu>
    ) : null;

    return (
      <PublishContainer>
        <Button
          onClick={() => handlePublishClick(prodEnv)}
          icon="cloud-upload"
          loading={formLoading}
          size="large"
          block
          type="danger"
        >
          {t('publish')}
        </Button>
        {menu && (
          <PublishDropdown trigger={['click']} overlay={menu}>
            <Button icon="ellipsis" size="large" block />
          </PublishDropdown>
        )}
      </PublishContainer>
    );
  }, [
    environments,
    handleEnvironmentsPublishMenuClick,
    handlePublishClick,
    formLoading,
    t,
  ]);

  if (error) {
    return <ErrorView />;
  }

  if (!loaded) {
    return (
      <Overlay>
        <Spinner />
      </Overlay>
    );
  }

  if (!app || !metaSchema || !schema) {
    return null;
  }

  return (
    <ContentWrap>
      <ErrorBoundary>
        <Row gutter={40}>
          <Col md={24} xl={16}>
            <PanelCard
              title={<Title level={3}>{t('appContent')}</Title>}
              bodyStyle={{
                padding: 16,
              }}
            >
              <SchemaForm
                organisationId={app.organizationId}
                ref={formElement}
                uiSchema={metaSchema}
                schema={schema}
                data={formData}
                // TODO: drop this here, just connect elements of form
                formContext={{
                  user,
                  appId: app.id,
                  hideMainGridAppPicker: true,
                  languageOptions: {
                    supportedLanguages,
                    defaultLanguage,
                    languageTo,
                    languageFrom,
                  },
                  history,
                  location: routeLocation,
                  match,
                  staticContext,
                }}
                onSubmit={handleSubmit}
                onChange={handleChange}
                onError={handleError}
              />
            </PanelCard>
          </Col>
          <StickyColumn md={24} xl={8}>
            <PanelCard bodyStyle={{ padding: 16 }}>
              <Paragraph>
                {`${t('lastModified')} ${moment(app.updatedAt).fromNow()}`}
              </Paragraph>
              {app.isPublished || formDirty || !formInitiallyValid
                ? canUpdate && (
                  <Button
                    loading={formLoading}
                    size="large"
                    block
                    type="primary"
                    onClick={handleSave}
                    disabled={!formDirty && formInitiallyValid}
                  >
                    {t('saveAllChanges')}
                  </Button>
                )
                : canPublish && renderPublishButton()}
            </PanelCard>
            {canSwitchSettingsV2 ? (
              <TemporarySettingsVersionSwitcher
                value={app.enableLayoutV2}
                onValueChange={handleEnableSettingsV2Layout}
                loading={isSettingsV2SwitchLoading}
                isEditAllowed={isEditAllowed}
              />
            ) : null}
            {app.multiLanguageSupport && (
              <LanguageSwitcherCard bodyStyle={{ padding: 16, width: '100%' }}>
                <LanguageSwitcherWrapper>
                  <LanguageSwitcherTitle>{t('languageFrom')}</LanguageSwitcherTitle>
                  <LanguageSelect
                    fullLabel
                    value={languageFrom}
                    options={supportedLanguages || []}
                    onChange={(lang: string) => setLanguageFrom(lang)}
                  />
                  <LanguageSwitcherTitle>{t('languageTo')}</LanguageSwitcherTitle>
                  <LanguageSelect
                    fullLabel
                    value={languageTo}
                    options={supportedLanguages || []}
                    onChange={(lang: string) => setLanguageTo(lang)}
                  />
                </LanguageSwitcherWrapper>
              </LanguageSwitcherCard>
            )}
            {activeBuilds.map((build) => (
              <BuildItem
                key={build.id}
                build={build}
                isSysAdmin={isSysAdmin}
                permissionPath={getPermissionPath(
                  app.organizationId,
                  permissionKeys.builds.viewSingle,
                )}
              />
            ))}
          </StickyColumn>
        </Row>
      </ErrorBoundary>
    </ContentWrap>
  );
};

export default Content;
