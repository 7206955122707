import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import Spinner from '../../../../common/spinner/spinner.component';
import ErrorViewComponent from '../../../../common/error-view/error-view.component';
import Overlay from '../../../../common/overlay/overlay.component';
import usePagination from '../../../../common/pagination/hook/use-pagination';
import DataExportListViewComponent from './data-export-list-view.component';
import AnalyticsDataExport from '../../../../../store/types/analytics-data-export';
import Pagination from '../../../../common/pagination/pagination.component';

interface DataExportListProps {
  organizationId?: string;
  reports: AnalyticsDataExport[];
  loaded: boolean;
  isLoading: boolean;
  isError: boolean;
  isFetching: boolean;
  totalDocs: number;
  fetchData: ({ page, pageSize }: { page: number; pageSize: number }) => void;
}

const defaultPageSize = 10;

const DataExportList = (props: DataExportListProps) => {
  const { reports, loaded, isLoading, isError, fetchData, isFetching, totalDocs } = props;

  const { page, pageSize, setPage, setPageSize } = usePagination({
    defaultPageSize,
  });

  const handlePaginationSizeChange = useCallback(
    (page: number, pageLimit: number) => {
      setPage(page);
      if (!fetchData) {
        return;
      }
      fetchData({
        page: page,
        pageSize: pageLimit || defaultPageSize,
      });
      setPageSize(pageLimit);
    },
    [fetchData, setPageSize, setPage],
  );

  const handlePageChange = useCallback(
    (pageNum: number, pageLimit?: number) => {
      setPage(pageNum);

      if (!fetchData) {
        return;
      }

      fetchData({
        page: pageNum,
        pageSize: pageLimit || defaultPageSize,
      });
    },
    [fetchData, setPage],
  );

  return (
    <>
      <Container>
        {isLoading && !isError && (
          <OverlayRelative>
            <Spinner />
          </OverlayRelative>
        )}
        {isFetching && !!totalDocs && !isError && (
          <OverlayMask>
            <Spinner />
          </OverlayMask>
        )}
        {isError && <ErrorViewComponent position="relative" />}
        {!isError && loaded && (
          <>
            <DataExportListViewComponent reports={reports} />
            {!!totalDocs && (
              <Pagination
                isLoading={isLoading}
                align="right"
                showSizeChanger
                onShowSizeChange={handlePaginationSizeChange}
                current={page}
                pageSize={pageSize}
                total={totalDocs}
                onChange={handlePageChange}
                pageSizes={[5, 10, 30, 50, 100, 1000]}
              />
            )}
          </>
        )}
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const OverlayRelative = styled(Overlay)`
  position: relative;
  flex-direction: column;
  margin-top: 40px;
`;

const OverlayMask = styled(Overlay)`
  align-items: flex-start;
  opacity: 1 !important;
  background: rgb(245 246 250 / 46%);
  z-index: 1000;

  .ant-spin-spinning {
    margin-top: 150px;
  }
`;

export default DataExportList;
