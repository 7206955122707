import { useQuery } from 'react-query';
import { useApiService } from '../api-service-provider';
import { AnalyticsSchema } from '@ombori/grid-reports';

const useSpaceSchema = ({
  organizationId,
  spaceId,
}: {
  organizationId: string;
  spaceId: string;
}) => {
  const apiService = useApiService();

  return useQuery(
    ['tenantId', organizationId, 'spaceId', spaceId],
    () =>
      apiService.get<AnalyticsSchema>(`/api/analytics/space-schema/${spaceId}`, {
        organizationId,
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export default useSpaceSchema;
