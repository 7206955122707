import { DataMatrixColumn } from '@ombori/grid-reports';
import OrganisationSpace from '../../../../../store/types/organisation-space';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Button } from 'antd';
import { Link } from 'react-router-dom';
import {
  SpaceEventsByDay,
  SpaceInstallationEventsByDay,
  SpaceInstallationSessionsByDay,
  SpaceSessionsByDay,
} from '../../../use-analytics-report';
import groupBy from 'lodash/groupBy';
import flatten from 'lodash/flatten';
import toLower from 'lodash/toLower';
import snakeCase from 'lodash/snakeCase';
import getColumnValue from './get-column-value';
import { TableRecord } from './types';
import getSortFn from './get-sort-fn';
import { getSpaceAverageOrLowestPercentage } from './get-percentage';
import { prepareDateRangeSearchParams } from '../../utils';

interface SpaceTableRecord extends TableRecord {
  spaceId: string;
}

export interface OrganisationSpaceWithChildren extends OrganisationSpace {
  children: OrganisationSpace[];
}

type EventsData = SpaceEventsByDay | SpaceInstallationEventsByDay;

type SessionData = SpaceSessionsByDay | SpaceInstallationSessionsByDay;

interface SpacesTableProps {
  tenantId: string;
  columns: DataMatrixColumn[];
  spaces: OrganisationSpaceWithChildren[];
  eventsData: EventsData[];
  sessionsData: SessionData[];
  dateFrom: string;
  dateTo: string;
  pageSize?: number;
}

const SpacesTable: React.FC<SpacesTableProps> = (
  {
    tenantId,
    spaces,
    columns,
    eventsData,
    sessionsData,
    dateFrom,
    dateTo,
    pageSize = 5,
  }) => {
  const { t } = useTranslation();

  const groupedEventsData = useMemo(
    () => groupBy(eventsData, (dataItem) => dataItem.spaceId),
    [eventsData],
  );

  const groupedSessionsData = useMemo(
    () => groupBy(sessionsData, (dataItem) => dataItem.spaceId),
    [sessionsData],
  );

  const data: SpaceTableRecord[] = spaces
    .filter((space) => !space.parentSpaceId)
    .map((space) => {
      const childrenIds = space.children.map((child) => child.id);

      const spaceIds = [space.id, ...childrenIds];

      const spaceEventsData = flatten(
        spaceIds.map((spaceId) => groupedEventsData[spaceId] || []),
      );

      const spaceSessionsData = flatten(
        spaceIds.map((spaceId) => groupedSessionsData[spaceId] || []),
      );

      return { space, spaceEventsData, spaceSessionsData };
    })
    .map(({ space, spaceEventsData, spaceSessionsData }) => {
      const result = columns
        .map((column) => {
          const columnValue = getColumnValue<EventsData>({
            column,
            eventsData: spaceEventsData,
            sessionData: spaceSessionsData,
            getEventsAvgValue: getSpaceAverageOrLowestPercentage,
            getEventsLowestValue: getSpaceAverageOrLowestPercentage,
          });
          return { [toLower(snakeCase(column.title))]: columnValue || 0 }; // Assign 0 if columnValue is falsy
        })
        .reduce((accumulator, currentValue) => ({ ...accumulator, ...currentValue }), {});

      return {
        key: space.id,
        location: space.displayName,
        spaceId: space.id,
        ...result,
      };
    });

  const tableColumns = columns.map((column) => {
    const sortFn = getSortFn(column);

    return {
      title: column.title,
      dataIndex: toLower(snakeCase(column.title)),
      key: toLower(snakeCase(column.title)),
      sorter: sortFn,
    };
  });

  const searchParams = useMemo(() => {
    return prepareDateRangeSearchParams(dateFrom, dateTo);
  }, [dateFrom, dateTo]);

  const allColumns = [
    {
      title: t('location'),
      dataIndex: 'location',
      key: 'location',
      render: (text: string, record: SpaceTableRecord) => {
        return (
          <Button type="link">
            <Link
              to={{
                pathname: `/organisations/${tenantId}/spaces/${record.spaceId}`,
                search: `${searchParams}`,
              }}>
              {text}
            </Link>
          </Button>
        );
      },
    },
    ...tableColumns,
  ];

  return (
    <Table
      columns={allColumns}
      dataSource={data}
      size="small"
      scroll={{ x: 'max-content' }} // will keep the width consistent across all pages
      pagination={{
        pageSize,
      }}
    />
  );
};

export default SpacesTable;
