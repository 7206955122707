import { useMutation, useQueryClient } from 'react-query';
import { useApiService } from '../../../app/common/api-service-provider';
import tagQueryKeys from './tag-query-keys';
import ContentTagBody from './types';
import ContentTag from '../../types/content-tag';

const useUpdateTag = (tenantId: string, tagId: string) => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  return useMutation(
    (tagBody: ContentTagBody) => {
      return apiService.put<ContentTag>(`/api/content-tag/${tagId}`, tagBody, { tenantId });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(tagQueryKeys.tenantTags(tenantId));
        queryClient.invalidateQueries(tagQueryKeys.getTag(tagId));
      },
    },
  );
};

export default useUpdateTag;
