import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import SpacesListComponent from './spaces-list.component';
import { RootModel, RootState } from '../../../../store/models/root.model';
import OrganisationSpace from '../../../../store/types/organisation-space';
import { getPermissions } from '../../../../utils/auth/permissions';

type SpacesListContainer = Pick<
  React.ComponentProps<typeof SpacesListComponent>,
  'match' | 'history' | 'location'
>;

const SpacesListContainer = (props: SpacesListContainer) => {
  const {
    match: {
      params: { organisationId },
    },
  } = props;

  // TODO: how to memoize this properly?
  const {
    fetchSpaces,
    createSpace,
    updateSpace,
    deleteSpace,
    fetchBrowsers,
    fetchMobileEndpoints,
  } = useActions<RootModel>((actions) => ({
    fetchSpaces: actions.organisationSpaces.fetch,
    createSpace: (data: Partial<OrganisationSpace>) =>
      actions.organisationSpaces.create({ data, key: organisationId }),
    updateSpace: (data: Partial<OrganisationSpace>) =>
      actions.organisationSpaces.update({ data, key: organisationId }),
    deleteSpace: (id: string) =>
      actions.organisationSpaces.delete({ id, key: organisationId }),
    fetchBrowsers: actions.organisationBrowsers.fetch,
    fetchMobileEndpoints: actions.organisationMobileEndpoints.fetch,
  }));

  const {
    spaces,
    browsers,
    spacesLoaded,
    browsersLoaded,
    permissions,
    mobileEndpoints,
    mobileEndpointsLoaded,
    organisation,
  } = useStore<RootState>(
    (state) => ({
      organisation: state.organisations.data && state.organisations.data[organisationId],
      spaces: state.organisationSpaces.values(organisationId),
      spacesLoaded: state.organisationSpaces.loaded(organisationId),
      browsers: state.organisationBrowsers.values(organisationId),
      browsersLoaded: state.organisationBrowsers.loaded(organisationId),
      permissions: getPermissions(state.auth.user, props.match.params.organisationId),
      mobileEndpoints: state.organisationMobileEndpoints.values(organisationId),
      mobileEndpointsLoaded: state.organisationMobileEndpoints.loaded(organisationId),
    }),
    [organisationId],
  );

  return (
    <SpacesListComponent
      {...props}
      organisation={organisation}
      loaded={spacesLoaded && browsersLoaded && mobileEndpointsLoaded}
      spaces={spaces}
      browsers={browsers}
      mobileEndpoints={mobileEndpoints}
      fetchBrowsers={fetchBrowsers}
      fetchMobileEndpoints={fetchMobileEndpoints}
      fetchSpaces={fetchSpaces}
      createSpace={createSpace}
      updateSpace={updateSpace}
      deleteSpace={deleteSpace}
      canCreate={permissions.spaces.create}
      canUpdate={permissions.spaces.update}
      canDelete={permissions.spaces.remove}
    />
  );
};

export default SpacesListContainer;
