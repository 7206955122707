import { useQuery } from 'react-query';
import { useApiService } from '../../../app/common/api-service-provider';
import { PaginationCollection } from '../../types/pagination';
import Plan from '../../types/plan';
import plansQueryKeys from './use-plans-query-keys';

const usePlansList = (
  page = 1,
  limit = 50,
  sortField = 'sku',
  sortOrder = 'asc',
  searchTerm = '',
) => {
  const apiService = useApiService();

  return useQuery(
    plansQueryKeys.getPlansListKeys(page, limit, sortField, sortOrder, searchTerm),
    () =>
      apiService.get<PaginationCollection<Plan>>('/api/plans', {
        page,
        limit,
        sortField,
        sortOrder,
        searchTerm,
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export default usePlansList;
