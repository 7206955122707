import { action, Action, select, Select, thunk, Thunk } from 'easy-peasy';
import Device from '../../types/device';
import { ApiError } from '../../../services/api/api-error';
import Injections from '../../injections.interface';
import transformDeviceExpectedAvailability from '../../../utils/transform-device-expected-availability';
import UniversalDevice, { UniversalDeviceTags } from '../../types/universal-device';

export interface UniversalDeviceUpdateParams {
  displayName: string;
  env: string;
  spaces: string[];
  notes: string;
  isProvisioned: boolean;
  uuid: string;
  deviceMonitored: boolean;
  externalId?: string;
  deviceExpectedAvailability?: {
    days: number[];
    start?: string;
    stop?: string;
  };
  extras?: {
    rebootSchedule?: string;
  };
  tags?: UniversalDeviceTags[];
}

export interface UniversalDeviceDetailsModel {
  data: {
    [deviceUuid: string]: UniversalDevice;
  };
  loading: {
    [deviceUuid: string]: boolean;
  };
  error: {
    [deviceUuid: string]: ApiError | null;
  };
  lastUpdated: {
    [deviceUuid: string]: Date;
  };

  loaded: Select<UniversalDeviceDetailsModel, (deviceUuid: string) => boolean>;
  setLastUpdated: Action<UniversalDeviceDetailsModel, { deviceUuid: string; data: Date }>;
  setLoading: Action<
    UniversalDeviceDetailsModel,
    { deviceUuid: string; loading: boolean }
  >;
  setError: Action<
    UniversalDeviceDetailsModel,
    { deviceUuid: string; error: ApiError | null }
  >;
  setData: Action<UniversalDeviceDetailsModel, { data: UniversalDevice }>;
  fetch: Thunk<
    UniversalDeviceDetailsModel,
    { deviceUuid: string; silent?: boolean },
    Injections
  >;
  update: Thunk<UniversalDeviceDetailsModel, UniversalDeviceUpdateParams, Injections>;
  reboot: Thunk<UniversalDeviceDetailsModel, { deviceUuid: string }, Injections>;
  restart: Thunk<UniversalDeviceDetailsModel, { deviceUuid: string }, Injections>;
  delete: Thunk<UniversalDeviceDetailsModel, { deviceUuid: string }, Injections>;
}

const universalDeviceDetailsModel: UniversalDeviceDetailsModel = {
  data: {},
  loading: {},
  error: {},
  lastUpdated: {},
  loaded: select((state) => (deviceUuid: string) =>
    !!state.data[deviceUuid] && !state.loading[deviceUuid],
  ),
  setLastUpdated: action((state, { deviceUuid, data }) => {
    state.lastUpdated[deviceUuid] = data;
  }),
  setData: action((state, { data }) => {
    state.data[data.uuid] = data;
  }),
  setLoading: action((state, { deviceUuid, loading }) => {
    state.loading[deviceUuid] = loading;
  }),
  setError: action((state, { deviceUuid, error }) => {
    state.error[deviceUuid] = error;
  }),
  fetch: thunk(async (actions, { deviceUuid, silent = false }, { injections }) => {
    actions.setError({ deviceUuid, error: null });
    if (!silent) {
      actions.setLoading({ deviceUuid, loading: true });
    }
    try {
      const data = await injections.apiService
        .get<Device>(`/api/v3/devices/${deviceUuid}`)
        .then(transformDeviceExpectedAvailability);
      actions.setData({ data });
      actions.setLastUpdated({ deviceUuid, data: new Date() });
    } catch (error) {
      actions.setError({ deviceUuid, error });
    } finally {
      if (!silent) {
        actions.setLoading({ deviceUuid, loading: false });
      }
    }
  }),
  update: thunk(async (actions, device, { injections }) => {
    const data = await injections.apiService.put<UniversalDevice>(
      `/api/v3/devices/${device.uuid}`,
      device,
    );
    actions.setData({ data });
  }),
  delete: thunk(async (actions, { deviceUuid }, { injections }) => {
    const data = await injections.apiService.delete<any>(
      `/api/v3/devices/${deviceUuid}`,
      {},
    );
    actions.setData({ data });
  }),
  reboot: thunk(async (actions, { deviceUuid }, { injections }) => {
    await injections.apiService.post<void>(`/api/v3/devices/${deviceUuid}/reboot`);
  }),
  restart: thunk(async (actions, { deviceUuid }, { injections }) => {
    await injections.apiService.post<void>(`/api/v3/devices/${deviceUuid}/restart`);
  }),
};

export default universalDeviceDetailsModel;
