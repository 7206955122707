import React from 'react';
import DevicesListContainer from '../../../../common/devices-list/devices-list.container';

type SpaceDetailsDevicesProps = Pick<
  React.ComponentProps<typeof DevicesListContainer>,
  'match' | 'history' | 'location'
>;

const SpaceDevices = (props: SpaceDetailsDevicesProps) => {
  return <DevicesListContainer {...props} hideDevicesReport showBackButton />;
};

export default SpaceDevices;
