import { Select, Icon } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import ApiService from '../../../services/api/api.service';
import DeviceTypeEnum from '../../../store/types/device-type.enum';
import { getApiUrl } from '../../../utils/env';

const api = new ApiService(getApiUrl());
const getAppsForTenant = (tenantId: string): Promise<any[]> => api.get(`api/installations?organizationId=${tenantId}`);

const iconTypes: { [n: string]: string } = {
  'mobilewpa': 'mobile',
  'wpa': 'desktop',
}

const InstallationPicker = ({ tenantId, onChange }: { tenantId: string | null, onChange: (id: string) => void }) => {
  const [apps, setApps] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState();

  useEffect(() => {
    const loadTenants = async () => {
      try {
        setLoading(true);
        if (tenantId) {
          const data = await getAppsForTenant(tenantId);
          // TODO: properly support IOT later
          setApps(data.filter(item => item.type === 'gdm' && (item.deviceType === DeviceTypeEnum.WPA)));
        }
      } finally {
        setLoading(false);
      }
    }
    if (tenantId) loadTenants();
  }, [setApps, tenantId]);

  useEffect(() => {
    setSelected(undefined)
  }, [tenantId])

  const onChangeSelected = useCallback((value: any) => {
    onChange(value);
    setSelected(value)
  }, [onChange]);

  return <Select disabled={!tenantId || loading} style={{ width: '45%' }} onChange={onChangeSelected} placeholder="Select installation..." value={selected}>
    {apps.map(app => (
      <Select.Option key={app.id} value={app.id}><Icon style={{ marginRight: '.5em' }} type={iconTypes[app.deviceType] || 'question-circle'} />{app.displayName}</Select.Option>
    ))}
  </Select>
}

export default InstallationPicker;