import { useCallback } from 'react';
import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { useApiService } from '../api-service-provider';
import queryKeys from './build-query-key';
import { ApiError } from '../../../services/api/api-error';
import { DeviceBundle } from './type';

export interface UpdateDeviceBundleData
  extends Pick<DeviceBundle, 'stage' | 'releaseNotes'> {}

interface UpdateDeviceBundleVariables {
  deviceBundleId: string;
  body: UpdateDeviceBundleData;
}

interface UseDeviceBundleUpdateProps {
  canInvalidateQueries?: boolean;
}

type UseDeviceBundleUpdateResponse = UseMutationResult<
  DeviceBundle,
  ApiError,
  UpdateDeviceBundleVariables
> & {
  invalidateQueries: () => Promise<void>;
};

const useDeviceBundleUpdate = (
  props?: UseDeviceBundleUpdateProps,
): UseDeviceBundleUpdateResponse => {
  const { canInvalidateQueries = false } = props || {};

  const apiService = useApiService();
  const queryClient = useQueryClient();

  const result = useMutation(
    async (variables: UpdateDeviceBundleVariables) => {
      const { deviceBundleId, body } = variables;

      const endpoint = `/api/device-bundle/${deviceBundleId}`;

      return apiService.patch<DeviceBundle>(endpoint, body);
    },
    {
      onSuccess: (_data, _variables) => {
        if (canInvalidateQueries) {
          invalidateQueries();
        }
      },
      onError: (_error: ApiError) => {},
    },
  );

  const invalidateQueries = useCallback(async (): Promise<void> => {
    const key = queryKeys.getDeviceBundles();

    await queryClient.invalidateQueries(key[0] as string);
  }, [queryClient]);

  return { ...result, invalidateQueries };
};

export default useDeviceBundleUpdate;
