import { action, Action, select, Select, thunk, Thunk } from 'easy-peasy';
import Device from '../../types/device';
import { ApiError } from '../../../services/api/api-error';
import Injections from '../../injections.interface';
import transformDeviceExpectedAvailability from '../../../utils/transform-device-expected-availability';

// For V3 devices
export interface DeviceUpdateParams {
  deviceName: string;
  deviceSerial: string;
  deviceProvisioned: boolean;
  deviceMonitored: boolean;
  deviceExpectedAvailability: {
    days: number[];
    start?: string;
    stop?: string;
  };
  env: string;
  note: string;
  tags: { [key: string]: string };
  uuid: string;
  edgeModules: {
    [moduleName: string]: {
      enabled: boolean;
      [propName: string]: any;
    };
  };
  arch: string;
  spaces: string[];
}

export interface DeviceUpdateSettingsParams {
  deviceName: string;
  deviceSerial: string;
  deviceProvisioned: boolean;
  env: string;
  note: string;
  tags: { [key: string]: string };
  uuid: string;
  edgeModules: {
    [moduleName: string]: {
      enabled: boolean;
      [propName: string]: any;
    };
  };
  arch: string;
  spaces: string[];
}

export interface AzureDeviceDetailsModel {
  data: {
    [deviceUuid: string]: Device;
  };
  loading: {
    [deviceUuid: string]: boolean;
  };
  error: {
    [deviceUuid: string]: ApiError | null;
  };
  lastUpdated: {
    [deviceUuid: string]: Date;
  };

  loaded: Select<AzureDeviceDetailsModel, (deviceUuid: string) => boolean>;
  setLastUpdated: Action<AzureDeviceDetailsModel, { deviceUuid: string; data: Date }>;
  setLoading: Action<AzureDeviceDetailsModel, { deviceUuid: string; loading: boolean }>;
  setError: Action<
    AzureDeviceDetailsModel,
    { deviceUuid: string; error: ApiError | null }
  >;
  setData: Action<AzureDeviceDetailsModel, { data: Device }>;
  fetch: Thunk<
    AzureDeviceDetailsModel,
    { deviceUuid: string; silent?: boolean },
    Injections
  >;
  update: Thunk<AzureDeviceDetailsModel, DeviceUpdateParams, Injections>;
  restart: Thunk<AzureDeviceDetailsModel, { deviceUuid: string }, Injections>;
  reboot: Thunk<AzureDeviceDetailsModel, { deviceUuid: string }, Injections>;
  purge: Thunk<AzureDeviceDetailsModel, { deviceUuid: string }, Injections>;
  move: Thunk<
    AzureDeviceDetailsModel,
    { appName: string; deviceUuid: string },
    Injections
  >;
}

const azureDeviceDetailsModel: AzureDeviceDetailsModel = {
  data: {},
  loading: {},
  error: {},
  lastUpdated: {},
  loaded: select((state) => (deviceUuid: string) =>
    !!state.data[deviceUuid] && !state.loading[deviceUuid],
  ),
  setLastUpdated: action((state, { deviceUuid, data }) => {
    state.lastUpdated[deviceUuid] = data;
  }),
  setData: action((state, { data }) => {
    state.data[data.uuid] = data;
  }),
  setLoading: action((state, { deviceUuid, loading }) => {
    state.loading[deviceUuid] = loading;
  }),
  setError: action((state, { deviceUuid, error }) => {
    state.error[deviceUuid] = error;
  }),
  fetch: thunk(async (actions, { deviceUuid, silent = false }, { injections }) => {
    actions.setError({ deviceUuid, error: null });
    if (!silent) {
      actions.setLoading({ deviceUuid, loading: true });
    }
    try {
      const data = await injections.apiService
        .get<Device>(`/api/v2/devices/${deviceUuid}`)
        .then(transformDeviceExpectedAvailability);
      actions.setData({ data });
      actions.setLastUpdated({ deviceUuid, data: new Date() });
    } catch (error) {
      actions.setError({ deviceUuid, error });
    } finally {
      if (!silent) {
        actions.setLoading({ deviceUuid, loading: false });
      }
    }
  }),
  update: thunk(async (actions, device, { injections }) => {
    const data = await injections.apiService.put<Device>(
      `/api/v2/devices/${device.uuid}`,
      device,
    );
    actions.setData({ data });
  }),
  restart: thunk(async (actions, { deviceUuid }, { injections }) => {
    await injections.apiService.post<void>(`/api/v2/devices/${deviceUuid}/restart`);
  }),
  reboot: thunk(async (actions, { deviceUuid }, { injections }) => {
    await injections.apiService.post<void>(`/api/v2/devices/${deviceUuid}/reboot`);
  }),
  purge: thunk(async (actions, { deviceUuid }, { injections }) => {
    await injections.apiService.post<void>(`/api/v2/devices/${deviceUuid}/purge`);
  }),
  move: thunk(async (actions, { deviceUuid, appName }, { injections }) => {
    await injections.apiService.post<void>(
      `/api/v2/devices/${deviceUuid}/move/${appName}`,
    );
  }),
};

export default azureDeviceDetailsModel;
