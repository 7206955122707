import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Icon, Radio, Tooltip } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { useTranslation } from 'react-i18next';

export type LayoutViewType = 'list' | 'grid';

export interface LayoutViewProps {
  onChange?: (layout: LayoutViewType) => void;
  defaultLayout?: LayoutViewType;
}

const LayoutView = (props: LayoutViewProps) => {
  const { defaultLayout = 'list', onChange = () => {} } = props;

  const { t } = useTranslation();

  const [layout, setLayout] = useState<LayoutViewType>(defaultLayout);

  useEffect(() => {
    setLayout(defaultLayout);
  }, [defaultLayout]);

  const handleLayoutChange = useCallback(
    (e: RadioChangeEvent): void => {
      const { value } = e.target;

      setLayout(value);
      onChange(value);
    },
    [onChange],
  );

  return (
    <>
      <LayoutToggleGroup value={layout} onChange={handleLayoutChange}>
        <Tooltip title={t('listView')}>
          <LayoutToggleButton value="list">
            <LayoutIcon type="unordered-list" />
          </LayoutToggleButton>
        </Tooltip>
        <Tooltip title={t('gridView')}>
          <LayoutToggleButton value="grid">
            <LayoutIcon type="appstore" />
          </LayoutToggleButton>
        </Tooltip>
      </LayoutToggleGroup>
    </>
  );
};

const LayoutToggleGroup = styled(Radio.Group)`
  display: inline-flex;
  margin-left: 15px;
`;

const LayoutToggleButton = styled(Radio.Button)`
  height: 100%;
  color: unset;

  &.ant-radio-button-wrapper-checked,
  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-of-type {
    box-shadow: inset 0 0 5px #dfdcdc !important;
    border-color: #2364aa40 !important;
  }
`;

const LayoutIcon = styled(Icon)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
`;

export default LayoutView;
