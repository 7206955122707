import { thunk, Thunk } from 'easy-peasy';
import Browser from '../../types/browser';
import createKeyedCrudModel, {
  KeyedCrudModel,
} from '../../common/keyed-crud-model/keyed-crud.model';
import Injections from '../../injections.interface';

export const GRID_ALL_BROWSERS_KEY = '__grid-all-browsers';
const path = '/api/browsers';

export type OrganisationBrowsersModel = KeyedCrudModel<
  Browser,
  { organizationId: string }
> & {
  fetchAll: Thunk<OrganisationBrowsersModel, { silent?: boolean }, Injections>;
};

const organisationBrowsersModel: OrganisationBrowsersModel = {
  ...createKeyedCrudModel<Browser, { organizationId: string }>(path, 'organizationId'),
  fetchAll: thunk(async (actions, { silent = false }, { injections }) => {
    const key = GRID_ALL_BROWSERS_KEY;

    if (!silent) {
      actions.setLoading({ key, loading: true });
    }
    actions.setError({ key, error: null });
    try {
      const data = await injections.apiService.get<Browser[]>(`${path}/overview`);
      actions.setData({ key, data });
    } catch (error) {
      actions.setError({ key, error });
    } finally {
      if (!silent) {
        actions.setLoading({ key, loading: false });
      }
    }
  }),
};

export default organisationBrowsersModel;
