import React from 'react';
import { Field } from 'react-final-form';
import styled from '@emotion/styled';
import { Select as AntdSelect } from 'antd';
import { SelectProps as AntdSelectProps } from 'antd/lib/select';
import InputWrap from './input-wrap';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, SelectType } from '../../../types';
import { requiredAll } from '../../organisations/organisation-details/queue-details/queue-setup/validate-form';
import { Validator, composeValidators } from './validator';

interface TagInputProps extends AntdSelectProps<string[]> {
  name?: string;
  label?: string;
  isRequired?: boolean;
  validators?: Validator[];
}

const TagInput = (props: TagInputProps) => {
  const {
    name = '',
    label,
    isRequired = false,
    className,
    onChange = () => {},
    disabled: isDisabled,
    defaultValue,
    validators = [],
    placeholder,
    ...restProps
  } = props;

  const { t } = useTranslation();

  const inputValidators = [...(isRequired ? [requiredAll] : []), ...validators];

  const fieldPlaceholder =
    placeholder === undefined ? t('finalFormField.tagInputPlaceholder') : placeholder;

  return (
    <Field name={name} validate={composeValidators(inputValidators)}>
      {({ input, meta }) => {
        const isError = !!meta.error && meta.touched;

        return (
          <InputWrap
            className={className}
            label={label}
            isRequired={isRequired}
            error={isError ? meta.error : undefined}
          >
            <SelectStyled
              {...restProps}
              mode="tags"
              className={className}
              data-name={input.name}
              showArrow={false}
              placeholder={fieldPlaceholder}
              value={input.value || defaultValue}
              onChange={(selectedValue, option) => {
                input.onChange(({
                  target: { value: selectedValue },
                } as unknown) as ChangeEvent<string[]>);

                onChange(selectedValue, option);
              }}
              disabled={isDisabled}
              dropdownMenuStyle={{
                maxHeight: '0px',
              }}
            />
          </InputWrap>
        );
      }}
    </Field>
  );
};

const SelectStyled = styled(AntdSelect)`
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;

  .ant-select-selection {
    height: 32px;
  }
  .ant-select-selection-selected-value {
    height: 32px;

    div:first-of-type {
      margin: 0px;
    }
  }
` as SelectType<string[]>;

export default TagInput;
