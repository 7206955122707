import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import { Icon } from 'antd';
import { SortCriteria } from '../../../store/types/sort';
import transientOptions from '../../../utils/transient-emotion-styled-options';

interface ActiveSortItemProps {
  item: SortCriteria;
  onClick: (value: SortCriteria) => void;
  isActive?: boolean;
}

const SortCriteriaItem = (props: ActiveSortItemProps) => {
  const { item, onClick, isActive = false } = props;

  const handleClick = useCallback(() => onClick(item), [item, onClick]);

  return (
    <Container onClick={handleClick}>
      <RadioInput type="radio" name="sort" value={item.id} checked={isActive} readOnly />
      <IconCheckStyle type="check" $isActive={isActive} />
      <Label active={isActive}>{item.label}</Label>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 4px;
  cursor: pointer;
`;

const RadioInput = styled.input`
  :checked {
    display: none;
  }
  :checked + span {
    display: inline-block;
    vertical-align: middle;
    width: 35px;
    height: 80px;
    position: relative;
    fill: #0064b6;
  }
`;

const Label = styled.span<{ active?: boolean | undefined }>`
  text-transform: capitalize;
  display: inline;
  font-size: ${({ active }) => (active ? 13 : 14)}px;
  margin: auto 10px;
  color: ${({ active }) => (active ? '#0064b6' : 'unset')};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
`;

const IconCheckStyle = styled(Icon, transientOptions)<{ $isActive: boolean }>`
  display: ${({ $isActive }) => ($isActive ? 'inline-block' : 'none')};
  color: #0064b6;
`;

export default SortCriteriaItem;
