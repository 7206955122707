import React, { useState, useCallback } from 'react';
import { Modal, Button, Row, Select } from 'antd';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { FormApi } from 'final-form';
import { Form, Field } from 'react-final-form';
import { requiredAll } from '../../queue-details/queue-setup/validate-form';
import { InputWrap, validateByRegExPattern } from '../../../../common/react-final-form';
import { FormTextInput } from '../../queue-details/queue-setup/styled-block';
import { useEnvironments } from '../../../../common/use-environments';
import { ChangeEvent } from '../../../../../types';

export interface NetworkWhitelistRuleFormFields {
  id?: string;
  env: string;
  hostname: string;
}

interface NetworkWhitelistRuleFormProps {
  tenantId: string;
  isModalVisible: boolean;
  initialValues: NetworkWhitelistRuleFormFields;
  updateIsModalVisibile: (visibility: boolean) => void;
  handleFormSubmit: (values: NetworkWhitelistRuleFormFields | Object, form: FormApi) => Promise<void>;
}

const NetworkWhitelistRuleForm = (props: NetworkWhitelistRuleFormProps) => {
  const { tenantId, isModalVisible, initialValues, updateIsModalVisibile, handleFormSubmit } = props;

  const { t } = useTranslation();

  const { data: environments, isLoading: isEnvironmentsLoading } = useEnvironments(tenantId);

  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);

  const onFormSubmit = useCallback(async (values: NetworkWhitelistRuleFormFields | Object, form: FormApi) => {
    setIsFormSubmitting(true);
    await handleFormSubmit(values, form);
    setIsFormSubmitting(false);
  }, [handleFormSubmit]);

  return (
    <>
      <Modal
        title={t('networking.networkWhitelistRuleSaveLabel')}
        visible={isModalVisible}
        destroyOnClose={true}
        onCancel={() => updateIsModalVisibile(false)}
        footer={[
          <Button
            key="cancel"
            size="default"
            onClick={() => {
              updateIsModalVisibile(false);
            }}
          >
            {t('cancel')}
          </Button>,
          <Button
            loading={isFormSubmitting}
            form="whitelist-rule-form"
            type="primary"
            size="default"
            key="submit"
            htmlType="submit"
          >
            {t('save')}
          </Button>,
        ]}
      >
        <Form
          onSubmit={onFormSubmit}
          initialValues={initialValues}
          render={({ handleSubmit }) => {
            return (
              <form id="whitelist-rule-form" onSubmit={handleSubmit}>
                {initialValues.id && (
                  <Field
                    name="id"
                    render={({ input }) => {
                      return <input type="hidden" value={input.value} />;
                    }}
                  />
                )}
                <FieldContainer>
                  <Field
                    name="env"
                    validate={requiredAll}
                    render={({ input, meta }) => {
                      return (
                        <InputWrap
                          label={t('environment')}
                          isRequired={true}
                          error={meta.error && meta.touched ? meta.error : null}
                        >
                          <Select
                            disabled={isEnvironmentsLoading}
                            defaultValue={input.value ? input.value : ""}
                            onChange={(value: string) => {
                              input.onChange({
                                target: {
                                  value,
                                },
                              } as unknown as ChangeEvent<string>);
                            }}
                          >
                            {environments && environments.length > 0 &&
                              environments.map((environment) => {
                                return (
                                  <Select.Option
                                    key={environment.environmentName}
                                    value={environment.environmentName}
                                  >
                                    {environment.displayName}
                                  </Select.Option>
                                );
                              })}
                          </Select>
                        </InputWrap>
                      );
                    }}
                  />
                </FieldContainer>
                <FieldContainer>
                  <Field
                    name="hostname"
                    validate={validateByRegExPattern(
                      // Note: use https://regex101.com/ to test regex; ^(\.?[\w-]+(\.[\w-]+)+)$
                      '^(\\.?[\\w-]+(\\.[\\w-]+)+)$',
                      (t('networking.invalidHostname'))
                    )}
                    render={({ input, meta }) => {
                      return (
                        <FormTextInput
                          label={t('networking.hostname')}
                          input={input}
                          meta={meta}
                          required
                          type="text"
                        />
                      );
                    }}
                  />
                </FieldContainer>
              </form>
            );
          }}
        />
      </Modal>
    </>
  );
}

export default NetworkWhitelistRuleForm;

const FieldContainer = styled(Row)`
  margin-bottom: 20px;
`;
