import React from 'react';
import { useActions } from 'easy-peasy';
import { RootModel } from '../../../store/models/root.model';
import Login from './login.component';

const LoginContainer = () => {
  const login = useActions<RootModel>((actions) => actions.auth.login);

  return <Login login={login} />;
};

export default LoginContainer;
