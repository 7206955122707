import { action, Action, Select, select, thunk, Thunk } from 'easy-peasy';
import qs from 'querystring';
import Injections from '../../injections.interface';
import OnboardingInfo from '../../types/onboarding-info';
import { ApiError } from '../../../services/api/api-error';
import { getOnboardingLoginUrl } from '../../../utils/onboarding';
import { RootModel } from '../root.model';
import Organisation from '../../types/organisation';
import Voucher from '../../types/voucher';
import { getApiUrl } from '../../../utils/env';

export interface LoginParams {
  email: string;
  deviceSerial?: string | null;
}

export interface SignupRequestBody {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  vatId?: string;
  password?: string;
  tosConsent: boolean;
  marketingConsent: boolean;
  country: string;
  tenantDisplayName: string;
  token: string;
}

export interface OnboardingModel {
  data: OnboardingInfo | null;
  error: ApiError | null;
  loading: boolean;
  loaded: Select<OnboardingModel, boolean>;
  setData: Action<OnboardingModel, OnboardingInfo>;
  setError: Action<OnboardingModel, ApiError | null>;
  setLoading: Action<OnboardingModel, boolean>;
  fetchByToken: Thunk<
    OnboardingModel,
    { token: string; countryCode?: string },
    Injections
  >;
  tryLogin: Thunk<
    {},
    { email: string; returnPath: string; fallbackPath: string },
    Injections
  >;
  setupTenantForExistingUser: Thunk<{}, { deviceSerial?: string }, Injections>;
  signup: Thunk<OnboardingModel, SignupRequestBody, Injections, RootModel>;
  validateVatId: Thunk<OnboardingModel, { token: string; vatId: string }, Injections>;
  validateReferralCode: Thunk<
    OnboardingModel,
    { code: string; countryCode?: string },
    Injections
  >;
}

const onboardingModel: OnboardingModel = {
  data: null,
  error: null,
  loading: false,
  loaded: select((state) => !!state.data && !!state.data && !state.loading),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setData: action((state, data) => {
    state.data = data;
  }),
  fetchByToken: thunk(async (actions, { token, countryCode }, { injections }) => {
    actions.setLoading(true);
    try {
      const data = await injections.apiService.get<OnboardingInfo>(`/api/onboarding`, {
        token,
        countryCode: countryCode as string,
      });
      actions.setData(data);
    } catch (error) {
      actions.setError(error);
    } finally {
      actions.setLoading(false);
    }
  }),
  tryLogin: thunk(async (actions, { email, returnPath, fallbackPath }) => {
    document.location.href = getOnboardingLoginUrl({
      email,
      returnPath,
      fallbackPath,
    });
  }),
  setupTenantForExistingUser: thunk(async (actions, params) => {
    document.location.href = `${getApiUrl()}/api/onboarding/tenant-setup?${qs.stringify({
      ...(params || {}),
    })}`;
  }),
  signup: thunk(async (actions, params, { injections, dispatch }) => {
    const data = await injections.apiService.post<Organisation>(
      '/api/onboarding/signup',
      params,
    );
    await Promise.all([dispatch.organisations.fetch(), dispatch.auth.getCurrentUser()]);
    return data;
  }),
  validateVatId: thunk(async (actions, params, { injections }) => {
    const data = await injections.apiService.post<{
      valid: boolean;
      companyName?: string;
    }>('/api/onboarding/validate-vatid', params);
    return data;
  }),
  validateReferralCode: thunk(async (actions, { code, countryCode }, { injections }) => {
    const data = await injections.apiService.post<{
      valid: boolean;
      voucher?: Voucher;
    }>(
      '/api/vouchers/validate-code',
      {
        code,
      },
      {
        countryCode: countryCode as string,
      },
    );
    return data;
  }),
};

export default onboardingModel;
