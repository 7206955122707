import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useActions, useStore } from 'easy-peasy';
import styled from '@emotion/styled';
import Settings from './settings.component';
import { RootModel, RootState } from '../../../../../store/models/root.model';
import useSkus from '../../../../common/use-skus';
import Spinner from '../../../../common/spinner/spinner.component';
import Overlay from '../../../../common/overlay/overlay.component';
import {
  useInstallationGroups,
} from '../../../../common/use-installation-groups';
import useCreateInstallationGroupCallback from '../../../../common/use-create-installation-group-callback';

type SettingsContainerProps = Omit<
  React.ComponentProps<typeof Settings>,
  | 'app'
  | 'updateApp'
  | 'user'
  | 'createInstallationGroup'
  | 'deleteApp'
>;

const SettingsContainer = (props: SettingsContainerProps) => {
  const {
    match: {
      params: { appId, organisationId },
    },
  } = props;
  const skusState = useSkus(1, 1000);
  const { t } = useTranslation();

  const installationGroupsQuery = useInstallationGroups({ organisationId });
  const installationGroups = installationGroupsQuery.data || [];

  const { app, user } = useStore<RootState>(
    (state) => ({
      app:
        (state.organisationApps.data &&
          state.organisationApps.data[organisationId] &&
          state.organisationApps.data[organisationId][appId]) ||
        null,
      user: state.auth.user,
    }),
    [appId],
  );

  const {
    updateApp,
    deleteApp,
  } = useActions<RootModel>((actions) => ({
    updateApp: actions.organisationApps.update,
    deleteApp: actions.organisationApps.delete,
  }));

  const createInstallationGroup = useCreateInstallationGroupCallback(organisationId);

  if (user.isSysAdmin && skusState.isLoading) {
    return (
      <Overlay>
        <Spinner />
      </Overlay>
    );
  }

  if (user.isSysAdmin && skusState.isError) {
    return <ErrorMessage>{t('error')}</ErrorMessage>;
  }

  return (
    <Settings
      {...props}
      app={app}
      updateApp={updateApp}
      user={user}
      installationGroups={installationGroups}
      createInstallationGroup={createInstallationGroup}
      skus={(skusState.data && skusState.data.docs) || []}
      deleteApp={deleteApp}
    />
  );
};

export default SettingsContainer;

const ErrorMessage = styled.div`
  padding: 15px 0;
  color: rgb(255, 85, 0);
`;
