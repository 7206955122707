import React, { useContext } from 'react';
import { Field, FieldProps } from 'react-final-form';
import { ModalFormContext } from '../modal-form.component';

function ModalFormField<T extends HTMLElement = any>(props: FieldProps<T>) {
  const { handlers } = useContext(ModalFormContext);

  return <Field {...props} onKeyDown={handlers.onKeyDown} />;
}

export default ModalFormField;
