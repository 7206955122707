import { action, Action, thunk, Thunk, Select, select } from 'easy-peasy';
import { UserRolePermissionsMatrix } from '../../types/user-roles';
import Injections from '../../injections.interface';
import { ApiError } from '../../../services/api/api-error';

export interface UserRolePermissionsMatrixModel {
  data: UserRolePermissionsMatrix | null;
  fetch: Thunk<UserRolePermissionsMatrixModel, void, Injections>;
  loading: boolean;
  loaded: Select<UserRolePermissionsMatrixModel, boolean>;
  error: ApiError | null;
  setLoading: Action<UserRolePermissionsMatrixModel, boolean>;
  setError: Action<UserRolePermissionsMatrixModel, ApiError | null>;
  setData: Action<UserRolePermissionsMatrixModel, UserRolePermissionsMatrix>;
}

const userRolePermissionsMatrixModel: UserRolePermissionsMatrixModel = {
  loading: false,
  error: null,
  data: null,
  loaded: select((state) => !!state.data && !state.loading),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setData: action((state, payload) => {
    state.data = payload;
  }),
  fetch: thunk(async (actions, _, { injections }) => {
    actions.setLoading(true);
    actions.setError(null);
    try {
      const permissionsMatrix = await injections.apiService.get<
        UserRolePermissionsMatrix
      >('/api/user-roles/permissions');
      actions.setData(permissionsMatrix);
    } catch (err) {
      actions.setError(err);
    } finally {
      actions.setLoading(false);
    }
  }),
};

export default userRolePermissionsMatrixModel;
