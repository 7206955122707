import { v4 as uuidV4 } from 'uuid';
import {
  RegistrationFieldType,
  BaseRegistrationField,
  ValidationRules,
  RegistrationFieldTextarea,
  RegistrationFieldInput,
  RegistrationFieldEmail,
  RegistrationFieldCheckbox,
  RegistrationFieldRadio,
  RegistrationFieldPhone,
  RegistrationFieldRadioOption,
  buildLocalizedField,
  RegistrationFieldCategory,
  RegistrationFieldCategoryOption,
  Category,
} from '../../../../../../store/types/queue';

interface BuildValidationRulesProps {
  required?: boolean;
  regexp?: string;
  phone?: boolean;
  email?: boolean;
}

const buildValidationRules = (props: BuildValidationRulesProps = {}): ValidationRules => {
  const required = props.required === undefined ? false : props.required;
  const regexp = props.regexp === undefined ? '' : props.regexp;
  const phone = props.phone === undefined ? false : props.phone;
  const email = props.email === undefined ? false : props.email;

  const result = { required, regexp, phone, email };

  return result;
};

const buildBaseField = (): Omit<BaseRegistrationField, 'type'> => ({
  id: uuidV4(),
  name: '',
  value: '',
  validationRules: buildValidationRules(),
});

export const buildTextarea = (): RegistrationFieldTextarea => ({
  ...buildBaseField(),
  type: RegistrationFieldType.TEXTAREA,
  label: buildLocalizedField(),
});

export const buildInput = (): RegistrationFieldInput => ({
  ...buildBaseField(),
  type: RegistrationFieldType.INPUT,
  label: buildLocalizedField(),
  placeholder: buildLocalizedField(),
});

export const buildCheckbox = (): RegistrationFieldCheckbox => ({
  ...buildBaseField(),
  type: RegistrationFieldType.CHECKBOX,
  checked: false,
  label: buildLocalizedField(),
});

export const buildRadioOption = (): RegistrationFieldRadioOption => ({
  id: uuidV4(),
  label: buildLocalizedField(),
  value: '',
});

export const buildRadio = (): RegistrationFieldRadio => ({
  ...buildBaseField(),
  type: RegistrationFieldType.RADIO,
  label: buildLocalizedField(),
  options: [],
});

export const buildPhone = (): RegistrationFieldPhone => ({
  ...buildBaseField(),
  type: RegistrationFieldType.PHONE,
  label: buildLocalizedField(),
  placeholder: buildLocalizedField(),
  validationRules: buildValidationRules({ phone: true }),
});

export const buildEmail = (): RegistrationFieldEmail => ({
  ...buildBaseField(),
  type: RegistrationFieldType.EMAIL,
  label: buildLocalizedField(),
  placeholder: buildLocalizedField(),
  validationRules: buildValidationRules({ email: true }),
});

export const buildCategoryOption = (
  category: Category,
): RegistrationFieldCategoryOption => ({
  id: category.id,
  label: category.title,
  value: category.id,
});

export const buildCategory = (categories: Category[]): RegistrationFieldCategory => {
  const options = categories.map(buildCategoryOption);

  const result = {
    ...buildBaseField(),
    type: RegistrationFieldType.CATEGORY as RegistrationFieldType.CATEGORY,
    label: buildLocalizedField(),
    placeholder: buildLocalizedField(),
    options,
  };

  return result;
};
