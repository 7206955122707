import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldProps } from 'react-jsonschema-form';
import { Select as SelectField } from '../../common';
import { SelectValue } from 'antd/lib/select';

interface SelectUiOptions {
  isBordered?: boolean;
}

type SelectType = string | number | boolean | undefined;

interface SelectProps extends FieldProps<SelectType> {
  rawErrors?: string[];
}

const Select = (props: SelectProps) => {
  const {
    rawErrors = [],
    formData,
    onChange,
    schema,
    uiSchema,
    required: isRequired, 
    readonly,
  } = props;

  const { defaultValue, formValue } = useMemo(() => {
    if (schema.type === 'boolean') {
      return {
        defaultValue: schema.default ? schema.default.toString() : undefined,
        formValue: formData === true || formData === false ? formData.toString() : undefined,
      };
    }

    if (schema.type === 'number') {
      return {
        defaultValue: schema.default ? schema.default.toString() : undefined,
        formValue: !isNaN(Number(formData)) ? (formData as number).toString() : undefined,
      };
    }

    const fallbackValue = isRequired ? undefined : '';

    return {
      defaultValue: (schema.default ? schema.default.toString() : undefined) || fallbackValue,
      formValue: formData as string || fallbackValue,
    };
  }, [formData, isRequired, schema]);

  const {
    isBordered = true,
  } = (uiSchema['ui:options'] || {}) as SelectUiOptions;
  const helpText = uiSchema['ui:help'] as string;
  const placeholder = uiSchema['ui:placeholder'] as string;
  const isDisabled = uiSchema['ui:disabled'] as boolean;

  const { t } = useTranslation();

  // TO DO: Deprecate usage of oneOf. It cannot be empty
  const optionsOneOf = useMemo(() => {
    const list = (schema.oneOf || []) as { const: string | number; title: string; }[];

    return list.map((option) => {
      return { [option.const]: option.title };
    });
  }, [schema]);

  const optionsEnum = useMemo(() => {
    const keyList = (schema.enum || []) as string[];
    const valueList = schema.enumNames || [];

    const options = keyList.map((key, index) => {
      const label = valueList[index] || '';

      return { [key]: label || key };
    });

    return options;
  }, [schema]);

  const options = optionsOneOf.length ? optionsOneOf : optionsEnum;

  const handleSelect = useCallback((value: SelectValue) => {
    let typedValue: string | number | boolean | undefined;

    if (schema.type === 'boolean') {
      typedValue = value === 'true';
    } else if (schema.type === 'number') {
      typedValue = Number(value);
    } else {
      const fallbackValue = isRequired ? undefined : '';
      typedValue = (value as unknown as string) || fallbackValue;
    }

    onChange(typedValue);
  }, [onChange, schema.type, isRequired]);

  return (
    <SelectField
      title={schema.title}
      description={schema.description}
      defaultValue={defaultValue}
      value={formValue}
      placeholder={placeholder || t('select.placeholder')}
      rawErrors={rawErrors}
      helpText={helpText}
      onSelect={handleSelect}
      isReadonly={readonly}
      isDisabled={isDisabled}
      isRequired={isRequired}
      isBordered={isBordered}
      options={options}
    />
  );
};

export default Select;
