import * as React from 'react';
import { Icon, Spin } from 'antd';
import styled from '@emotion/styled';

interface Props {
  className?: string;
  size?: number;
  tip?: string;
  dataCy?: string;
}

const StyledLoadingIcon = styled(Icon)<{ size: number; dataCy?: string }>`
  font-size: ${({ size }) => size}px;
`;

const defaultSize = 32;

export const loadingIcon = <StyledLoadingIcon type="loading" spin size={defaultSize} />;

const Spinner: React.FC<Props> = ({ size = defaultSize, tip, dataCy = '', className }) => {
  return (
    <Spin
      className={className}
      tip={tip}
      indicator={<StyledLoadingIcon type="loading" spin size={size} data-cy={dataCy} />}
    />
  );
};

export default Spinner;
