export interface ApiErrorData {
  message: string;
  stack: string[];
  errors: string[];
  incidentId?: string;
}

export interface ApiValidationErrorData {
  errors: Array<{
    field: string[],
    messages: string[],
    types: string[],
  }>
}

export interface ApiErrorResponse {
  data?: ApiErrorData | any;
  statusCode: number;
}

export class ApiError extends Error {
  public response?: ApiErrorResponse;

  public constructor(message?: string, response?: ApiErrorResponse) {
    super(message);
    this.response = response;
  }
}
