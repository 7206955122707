import * as React from 'react';
import styled from '@emotion/styled';
import { Typography } from 'antd';
import DataCard from '../../../data-card/data-card.component';

const { Title, Paragraph } = Typography;

const ObjectCardWrapper = styled.div`
  h4 {
    font-size: 18px;
  }

  /* stylelint-disable-next-line */
  & > ${DataCard} & {
    margin-top: 0;
  }

  label {
    display: inline-block;
    max-width: 100%;
    font-weight: 700;
    min-width: 80px;
    width: 90%;
  }
`;

const FormDataCard = styled(DataCard)`
  .field-string,
  .field-boolean,
  .field-number,
  .field-array,
  .field-undefined {
    padding: 0;

    label {
      display: flex;
      align-items: center;
      font-size: 14px;
      padding-bottom: 2px;
    }
    &:first-of-type {
      margin-top: 0;
    }
  }

  .ant-card-body {
    padding: 0 8px;
    gap: 8px;
    display: flex;
    flex-direction: column;
  }
`;

interface ObjectCardProps {
  title?: string;
  description?: string;
  children: React.ReactNode;
}

const ObjectCard = ({ title, description, children }: ObjectCardProps) => {
  return (
    // TODO: remove the class name, used as selector in other object fields templates
    <ObjectCardWrapper className="object-card-wrapper">
      {title && (
        <Title className="control-label" level={4}>
          {title}
        </Title>
      )}
      {description && <Paragraph>{description}</Paragraph>}
      <FormDataCard>{children}</FormDataCard>
    </ObjectCardWrapper>
  );
};

export default ObjectCard;
