import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import SearchBar from '../../search-bar/search-bar.component';
import FilterItem, { ActiveFilter } from '../../../../store/types/filters';
import { SortCriteria, SortOrder } from '../../../../store/types/sort';

interface PhyhubDevicesSearchAndSortProps {
  filterOptions: FilterItem[];
  sortCriteriaOptions: SortCriteria[];
  onCriteriaChange: (criteria: SortCriteria, order: SortOrder) => void;
  onFiltersChange: (filters: ActiveFilter[]) => void;
  onSearch: (phrase: string) => void;
}

const PhyhubDevicesSearchAndSort: FC<PhyhubDevicesSearchAndSortProps> = (props) => {
  const { filterOptions, sortCriteriaOptions } = props;

  const { t } = useTranslation();

  return (
    <Wrapper>
      <SearchBar
        searchInputProps={{
          placeholder: t('searchDevicePlaceholder'),
          onSearch: props.onSearch,
        }}
        searchInputFilterProps={{
          filterOptions,
          onChange: props.onFiltersChange,
        }}
        sortProps={{
          sortCriteria: sortCriteriaOptions,
          defaultCriteria: 'displayName',
          onChange: props.onCriteriaChange,
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  padding: 9px 40px;
  background-color: rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(217, 217, 217, 1);
`;

export default PhyhubDevicesSearchAndSort;
