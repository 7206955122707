import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Button, Row, Tooltip } from 'antd';
import { PanelCard, Title } from '../store-ai-assistants-common.component';
import { ButtonType } from '../../../../../../types';
import { useTranslation } from 'react-i18next';
import { Select } from '../../../../../common/react-final-form';
import { utcToLocalDate } from '../../../../../../utils/dayjs';
import { GridAssistantStatusEnum } from '@ombori/grid-llm-react';

interface StoreAISidePanelProps {
  isSubmitEnabled?: boolean;
  isSubmitting?: boolean;
  isStatusEnabled?: boolean;
  lastProcessedDate?: Date;
  isSubmitAllowed?: boolean;
}

const StoreAISidePanel = (props: StoreAISidePanelProps) => {
  const {
    isSubmitEnabled = true,
    isSubmitting = false,
    isStatusEnabled = true,
    lastProcessedDate,
    isSubmitAllowed = true,
  } = props;

  const { t } = useTranslation();

  const { lastEditDate, lastEditAge } = useMemo(() => {
    return {
      lastEditDate: utcToLocalDate(lastProcessedDate).format('YYYY-MM-DD HH:mm'),
      lastEditAge: utcToLocalDate(lastProcessedDate).fromNow(),
    };
  }, [lastProcessedDate]);

  return (
    <PanelCard>
      <Row>
        <Title>{t('storeAI.status')}</Title>
        <Select
          name="status"
          options={[
            { value: GridAssistantStatusEnum.ACTIVE, label: t('storeAI.active') },
            { value: GridAssistantStatusEnum.DISABLED, label: t('storeAI.disabled') },
          ]}
          disabled={!isStatusEnabled || !isSubmitAllowed}
        />
      </Row>
      <Row>
        <TextWrap>
          {lastProcessedDate && (
            <Tooltip placement="bottom" title={lastEditDate}>
              <LastProcessedText>
                {t('storeAI.lastEditedTimestamp', { timestamp: lastEditAge })}
              </LastProcessedText>
            </Tooltip>
          )}
        </TextWrap>
      </Row>
      {isSubmitAllowed && (
        <SaveButton
          type="primary"
          htmlType="submit"
          size="large"
          disabled={!isSubmitEnabled || isSubmitting}
          loading={isSubmitting}
        >
          {t('storeAI.saveAllChanges')}
        </SaveButton>
      )}
    </PanelCard>
  );
};

const TextWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0 5px 0;
`;

const LastProcessedText = styled.span`
  color: rgba(0, 0, 0, 0.45);
`;

const SaveButton = styled(Button)`
  width: 100%;
` as ButtonType;

export default StoreAISidePanel;
