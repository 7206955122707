import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Select, Table as AntdTable, Tag } from 'antd';
import { get, head } from 'lodash';
import { useActions, useStore } from 'easy-peasy';
import { PaginationConfig } from 'antd/lib/pagination';
import { useTranslation } from 'react-i18next';
import {
  CatalogPageLocationProduct,
  GridProduct,
  ProductName,
  ProductStatusEnum,
  Variant,
} from '@ombori/grid-products';
import { loadingIcon } from '../../../../common/spinner/spinner.component';
import PanelCard from '../../../../common/panel-card/panel-card.component';
import { RootModel, RootState } from '../../../../../store/models/root.model';
import { DataResidencyEnum } from '../../../../../store/types/organisation';
import { getBestMatchByLanguage, DEFAULT_PRODUCTS_ENVIRONMENT } from '../utils';
import Environment from '../../../../../store/types/environment';
import { CountInfo, MainHeader } from '../grid-products.components';
import routes from '../routes';
import SearchBar from '../../../../common/search-bar/search-bar.component';
import { appendQueryParams } from '../../../../../utils/url';
import { GridProductsPaginationQueryParams } from '../types';
import usePaginationQueryParams from '../../../../common/use-pagination-query-params';
import { MediaPreview, ProductEnvironmentSelect } from '../common';
import { ColumnProps } from 'antd/lib/table';
import { useProductTypes } from '../../../../common/use-product-type';
import { TableType } from '../../../../../types';

const { REACT_APP_DEV_PRODUCTS_TENANT_ID } = process.env;

const defaultPageNum = 1;
const defaultPageSize = 50;

interface TableDataRow {
  key: string;
  productInfo: {
    productName: string;
    productMediaUrl: string;
    productMediaType: string;
    productGroupId: string;
  };
  variantsInfo: { variantsCount: number; productGroupId: string; variants: Variant[] };
  category: {
    categoryId: string;
    categoryName: string;
  }[];
}

interface ProductStatusBySpace {
  productStatus: ProductStatusEnum;
  spaceName: string;
}

export type OverviewProps = RouteComponentProps<{ organisationId: string }> & {
  tenantId?: string;
  selectable?: boolean;
  setEnvironmentName?: (envName: string) => void;
  dataResidency: DataResidencyEnum;
};

const Overview = ({
  match,
  history,
  tenantId: tenantIdRaw,
  setEnvironmentName,
  dataResidency,
}: OverviewProps) => {
  const {
    params: { organisationId: tenantIdRawParam },
  } = match;
  const { t, i18n } = useTranslation();
  const tenantId = REACT_APP_DEV_PRODUCTS_TENANT_ID || tenantIdRaw || tenantIdRawParam;

  const language = useMemo(() => {
    return i18n.languages[0];
  }, [i18n]);

  const fetchEnvironmentsRef = React.useRef({ fetchCount: 0 });

  const { fetchProducts, fetchEnvironments, fetchSpaces } = useActions<RootModel>(
    (actions) => {
      const results = {
        fetchProducts: actions.gridProducts.fetchProducts,
        fetchEnvironments: actions.environments.fetch,
        fetchSpaces: actions.organisationSpaces.fetch,
      };
      return results;
    },
  );

  const { products, isProductsLoading, isEnvLoading, envs } = useStore<
    RootState,
    {
      products: GridProduct[];
      isProductsLoading: boolean;
      isEnvLoading: boolean;
      envs: Environment[];
    }
  >((state) => {
    const result = {
      products: state.gridProducts.values(tenantId) || [],
      isProductsLoading: state.gridProducts.loading[tenantId],
      isEnvLoading: state.environments.loading[tenantId],
      envs: state.environments.values(tenantId),
    };

    return result;
  });

  const [totalCount, setTotalCount] = useState<number>(0);

  const [queryParams, setQueryParams] = usePaginationQueryParams<
    GridProductsPaginationQueryParams
  >({ env: DEFAULT_PRODUCTS_ENVIRONMENT, page: defaultPageNum, limit: defaultPageSize });

  const { env, search: searchKeyword, page, limit } = queryParams;

  const handleSearch = useCallback(
    (value: string) => {
      setQueryParams((prevParams) => {
        return {
          ...prevParams,
          search: value,
          page: prevParams.page && defaultPageNum,
        };
      });
    },
    [setQueryParams],
  );

  const handlePagination = useCallback(
    (paginationConfig: PaginationConfig) => {
      const { current, pageSize } = paginationConfig;

      setQueryParams((prevParams) => {
        return {
          ...prevParams,
          ...(current ? { page: current } : {}),
          ...(pageSize ? { limit: pageSize } : {}),
        };
      });
    },
    [setQueryParams],
  );

  useEffect(() => {
    if (
      envs.length === 0 &&
      fetchEnvironmentsRef.current.fetchCount === 0 &&
      !isEnvLoading
    ) {
      fetchEnvironmentsRef.current.fetchCount += 1;
      fetchEnvironments({ organizationId: tenantId });
    }
  }, [envs, fetchEnvironments, tenantId, isEnvLoading]);

  useEffect(() => {
    fetchSpaces({ organizationId: tenantId, silent: true });
  }, [tenantId, fetchSpaces]);

  useEffect(() => {
    const fetchProductList = async () => {
      const extraInfo = await fetchProducts({
        tenantId,
        select: [],
        dataResidency,
        ...queryParams,
      });
      setTotalCount(extraInfo.totalCount);
    };
    fetchProductList();
  }, [dataResidency, tenantId, fetchProducts, queryParams]);

  const paginationConfig = useMemo((): PaginationConfig => {
    return {
      total: totalCount,
      current: page,
      pageSize: limit,
      showQuickJumper: true,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '40', '50'],
    };
  }, [totalCount, page, limit]);

  const handleProductsEnvironmentChange = useCallback(
    (value: string) => {
      if (setEnvironmentName) {
        setEnvironmentName(value);
      }

      setQueryParams((prevParams) => {
        return {
          ...prevParams,
          env: value,
          page: prevParams.page && defaultPageNum,
        };
      });
    },
    [setEnvironmentName, setQueryParams],
  );

  const { data: productTypeData, isLoading: isProductTypesLoading } = useProductTypes({
    tenantId,
    dataResidency,
    environment: env,
  });

  const tableDataSource = useMemo(() => {
    const dataRows = products.map((product) => {
      const firstMedia: CatalogPageLocationProduct | undefined = head(
        product.catalogPageLocationProduct,
      );

      const productMediaUrl =
        firstMedia && firstMedia.catalogPageLocationProduct
          ? firstMedia.catalogPageLocationProduct
          : '';

      const productMediaType =
        firstMedia && firstMedia.catalogType ? firstMedia.catalogType : '';

      const productName = getBestMatchByLanguage<ProductName>(
        product.productName,
        language,
      );

      const variantsCount = product.variants.length;

      const productTypes = productTypeData ? productTypeData.data : [];

      const result: TableDataRow = {
        key: product.productGroupId,
        productInfo: {
          productName: get(productName, 'productName', ''),
          productMediaUrl,
          productMediaType,
          productGroupId: product.productGroupId,
        },
        variantsInfo: {
          variantsCount,
          productGroupId: product.productGroupId,
          variants: product.variants,
        },
        category: product.productType.map((categoryId) => {
          const productType = productTypes.find(
            (type) => type.productTypeId === categoryId,
          );
          const categoryName =
            productType && productType.title.length
              ? productType.title[0].label
              : categoryId;

          return { categoryName, categoryId };
        }),
      };

      return result;
    });

    return dataRows;
  }, [products, productTypeData, language]);

  const columns = useMemo<ColumnProps<TableDataRow>[]>(() => {
    return [
      {
        title: <ColumnHeader>{t('gridProducts.columnTitleProduct')}</ColumnHeader>,
        key: 'productMediaUrl',
        render: (_, record) => {
          const { productInfo } = record;

          const path = appendQueryParams(
            routes.productOverviewPath(tenantIdRawParam, productInfo.productGroupId),
            { env },
          );

          return (
            <ProductLink to={path}>
              <MediaPreview
                src={productInfo.productMediaUrl}
                mediaType={productInfo.productMediaType}
                size={48}
              />

              <span>{productInfo.productName}</span>
            </ProductLink>
          );
        },
      },
      {
        title: <ColumnHeader>{t('gridProducts.columnTitleVariants')}</ColumnHeader>,
        key: 'variantsInfo',
        render: (_, record) => {
          const { variantsInfo } = record;

          return (
            <SelectStyled
              size="large"
              placeholder={`${variantsInfo.variantsCount} ${t(
                'gridProducts.productVariantsTitle',
              )}`}
            >
              {variantsInfo.variants.map((variant) => {
                const variantSelectors = [
                  variant.color,
                  variant.size,
                  variant.style,
                ].filter((item) => !!item);

                const variantSelectorText =
                  variantSelectors.length > 0
                    ? variantSelectors.join('/')
                    : t('gridProducts.noVariantInfo');

                return (
                  <Select.Option
                    key={variant.productId}
                    value={variant.productId}
                    disabled
                  >
                    {variantSelectorText}
                  </Select.Option>
                );
              })}
            </SelectStyled>
          );
        },
      },
      {
        title: <ColumnHeader>{t('gridProducts.columnTitleCategory')}</ColumnHeader>,
        key: 'productCategory',
        render: (_, record) => {
          return record.category.map((category) => (
            <CategoryTag>{category.categoryName}</CategoryTag>
          ));
        },
      },
    ];
  }, [env, tenantIdRawParam, t]);

  return (
    <Container>
      <MainHeader match={match} />

      <Content>
        <ProductHeading>
          <ProductEnvironmentSelect
            label={t('environment')}
            options={envs}
            value={env}
            onChange={handleProductsEnvironmentChange}
            dataTestId="grid-products-overview-environment-select"
          />
          <CountInfo
            count={totalCount}
            isLoading={isProductsLoading}
            label={totalCount > 1 ? t('gridProducts.products') : t('gridProducts.product')}
          />
        </ProductHeading>

        <SearchBar
          searchInputProps={{
            placeholder: t('gridProducts.searchProductsPlaceholder'),
            onSearch: handleSearch,
            defaultValue: searchKeyword,
          }}
        />

        <ListContainer>
          <Table
            expandIcon={() => null}
            expandIconAsCell={false}
            pagination={paginationConfig}
            dataSource={tableDataSource}
            columns={columns}
            onChange={handlePagination}
            loading={{
              indicator: loadingIcon,
              spinning: isProductsLoading || isProductTypesLoading,
            }}
            scroll={{ x: 600 }}
          />
        </ListContainer>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  background-color: #fff;
`;

const ColumnHeader = styled.span`
  text-transform: capitalize;
`;

const Content = styled.div`
  margin: 20px 32px;
`;

const Table = styled(AntdTable)`
  .ant-table, .ant-spin-blur {
    clear: unset;
  }
` as TableType<TableDataRow>;

const ProductLink = styled(Link)`
  display: flex;
  align-items: center;
  width: fit-content;
`;

const ListContainer = styled(PanelCard)`
  margin-top: 8px;
  .ant-card-body {
    padding: 0px;
  }
` as any;

const SelectStyled = styled(Select)<{ minWidth?: number }>`
  min-width: ${({ minWidth }) => (minWidth ? minWidth : 140)}px;
  font-size: 14px;

  .ant-select-selection__placeholder {
    color: #111111;
  }
`;

const ProductHeading = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 17px 0;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const CategoryTag = styled(Tag)`
  margin: 5px;
  padding: 1px 8px;
  font-size: 14px;
  overflow: hidden;
  white-space: break-spaces;
  word-break: break-all;
`;

export default Overview;
