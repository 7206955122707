import { Field } from 'react-final-form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import en from 'react-phone-number-input/locale/en.json';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input';
import {
  FormTextInput,
  InputCheckbox,
  Label,
  MainSectionTitle,
  PanelBody,
  PanelCardMain,
  RowStyled,
  Select,
} from './styled-block';
import PhoneNumberCountries from './phone-number-countries';

const { Title } = Typography;

interface NotificationsSectionProps {
  smsEnabled?: boolean;
  emailEnabled?: boolean;
  webPushEnabled?: boolean;
  applePassEnabled?: boolean;
}

const NotificationsSection: React.FC<NotificationsSectionProps> = ({
  smsEnabled,
  emailEnabled,
  webPushEnabled,
  applePassEnabled,
}) => {
  const { t } = useTranslation();
  return (
    <PanelCardMain bodyStyle={{ padding: 0 }}>
      <MainSectionTitle>
        <Title level={2}>{t('queueNotificationSectionTitle')}</Title>
      </MainSectionTitle>
      <PanelBody>
        <Field name="webPushEnabled" type="checkbox">
          {({ input }) => (
            <RowStyled>
              <Label>
                <InputCheckbox {...input} type="checkbox" />
                {t('enableWebPush')}
              </Label>
            </RowStyled>
          )}
        </Field>
        <Field name="applePassEnabled" type="checkbox">
          {({ input }) => (
            <RowStyled>
              <Label>
                <InputCheckbox {...input} type="checkbox" />
                {t('queueEnableApplePass')}
              </Label>
            </RowStyled>
          )}
        </Field>
        <Field name="smsEnabled" type="checkbox">
          {({ input }) => (
            <RowStyled>
              <Label>
                <InputCheckbox {...input} type="checkbox" />
                {t('enableSms')}
              </Label>
            </RowStyled>
          )}
        </Field>
        <Field name="emailEnabled" type="checkbox">
          {({ input }) => (
            <RowStyled>
              <Label>
                <InputCheckbox {...input} type="checkbox" />
                {t('queueEnableEmailNotification')}
              </Label>
            </RowStyled>
          )}
        </Field>

        {(smsEnabled || emailEnabled || webPushEnabled || applePassEnabled) && (
          <>
            {smsEnabled && (
              <div>
                <Field name="phoneNumber">
                  {({ input, meta }) => (
                    <FormTextInput input={input} meta={meta} label={t('phoneNumber')} />
                  )}
                </Field>
                <Field name="shortDisplayName">
                  {({ input, meta }) => (
                    <FormTextInput input={input} meta={meta} label={t('code')} />
                  )}
                </Field>
                <Field name="defaultPhoneNumberCountry">
                  {({ input }) => (
                    <RowStyled>
                      <Label>{t('defaultPhoneNumberCountry')}</Label>
                      <Select {...input}>
                        {getCountries().map((country) => (
                          <option value={country} key={country}>
                            {(en as any)[country]} +{getCountryCallingCode(country)}
                          </option>
                        ))}
                      </Select>
                    </RowStyled>
                  )}
                </Field>
                <PhoneNumberCountries />
              </div>
            )}
            {emailEnabled && (
              <div>
                <Field name="emailFrom">
                  {({ input, meta }) => (
                    <FormTextInput input={input} meta={meta} label="Email sender" />
                  )}
                </Field>
              </div>
            )}
            {smsEnabled && (
              <div>
                <Field name="phoneNumberFrom">
                  {({ input, meta }) => (
                    <FormTextInput
                      input={input}
                      meta={meta}
                      label={t('phoneNumberFrom')}
                      description={t('phoneNumberFromDescription')}
                    />
                  )}
                </Field>
              </div>
            )}
            <Field name="leaveAfterMinutes">
              {({ input, meta }) => (
                <FormTextInput input={input} meta={meta} label={t('leaveAfterMinutes')} />
              )}
            </Field>
            <Field name="leaveReminderNotificationAfterMinutes">
              {({ input, meta }) => (
                <FormTextInput
                  input={input}
                  meta={meta}
                  label={t('leaveReminderNotificationAfterMinutes')}
                />
              )}
            </Field>
          </>
        )}
      </PanelBody>
    </PanelCardMain>
  );
};

export default NotificationsSection;
