import React, { useMemo } from 'react';
import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { ModuleStatus } from './types';

interface ModulesStatusTableProps {
  data: ModuleStatus[];
}

const ModulesStatusTable = (props: ModulesStatusTableProps) => {
  const { data } = props;

  const columns = useMemo<ColumnProps<ModuleStatus>[]>(() => {
    return [
      {
        title: 'Device',
        key: 'deviceName',
        render: (_, record) => record.deviceName,
      },
      {
        title: 'Module',
        key: 'moduleName',
        render: (_, record) => record.moduleName,
      },
      {
        title: 'Offline count',
        key: 'offline-count',
        align: 'center',
        sorter: (a, z) => a.offlineCount - z.offlineCount,
        render: (_, record) => record.offlineCount,
      },
      {
        title: 'Online count',
        key: 'online-count',
        align: 'center',
        sorter: (a, z) => a.onlineCount - z.onlineCount,
        render: (_, record) => record.onlineCount,
      },
    ];
  }, []);

  return (
    <>
      <Table
        rowKey={(record) => `${record.deviceName}-${record.moduleName}`}
        dataSource={data}
        columns={columns}
        pagination={{
          total: data && data.length ? data.length : 0,
          defaultCurrent: 1,
          defaultPageSize: 5,
        }}
      />
    </>
  );
};

export default ModulesStatusTable;
