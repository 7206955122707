import React, { useCallback, useEffect, useRef } from 'react';
import { Button, Col, message, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import PanelCard from '../../../../../common/panel-card/panel-card.component';
import SchemaForm, {
  SchemaFormRef,
} from '../../../../../common/schema-form/schema-form.component';
import useSchemaForm from '../../../../../common/use-schema-form/use-schema-form';
import Device from '../../../../../../store/types/device';
import User from '../../../../../../store/types/user';
import { DeviceUpdateParams } from '../../../../../../store/models/azure-device-details/azure-device-details.model';
import MultiSelect from '../../../../../common/schema-form/widgets/multi-select/multi-select.component';
import DeviceTags from '../../common/device-tags/device-tags.component';
import DeviceDetailsScreenshot from '../../common/device-details-screenshot/device-details-screenshot.component';
import DeviceDetailsInfo from '../../common/device-details-info/device-details-info.component';

interface SettingsProps {
  canManageDeviceSettings: boolean;
  device: Device | null;
  updateDevice: (device: DeviceUpdateParams) => Promise<void>;
  user: User | null;
}

const injectTagEditor = (schema: any) => {
  if (typeof schema !== 'object') {
    return schema;
  }

  Object.keys(schema).forEach((key) => {
    if (schema[key] === '$DeviceTags') {
      schema[key] = DeviceTags;
    } else if (schema[key] === '$MultiSelect') {
      schema[key] = MultiSelect;
    } else {
      schema[key] = injectTagEditor(schema[key]);
    }
  });

  return schema;
};

const Settings = (props: SettingsProps) => {
  const { t } = useTranslation();
  const { canManageDeviceSettings, device, updateDevice, user } = props;
  const formElement = useRef<SchemaFormRef>(null);
  const isFormInitialized = useRef<boolean>(false);

  const handleError = () => {
    message.error(t('thereAreErrorsInTheContentForm'));
  };

  const handleSave = useCallback(() => {
    if (formElement.current) {
      formElement.current.submit();
    }
  }, [formElement]);

  const handleSubmit = async (data: any) => {
    if (device) {
      await updateDevice({
        note: data.note,
        deviceName: data.deviceName,
        deviceSerial: data.deviceSerial,
        deviceProvisioned: data.deviceProvisioned,
        deviceMonitored: data.deviceMonitored,
        deviceExpectedAvailability: {
          ...data.deviceExpectedAvailability,
          days: JSON.stringify(data.deviceExpectedAvailability.days),
        },
        tags: data.tags,
        env: data.env,
        uuid: device.uuid,
        edgeModules: data.edgeModules,
        arch: data.arch,
        spaces: data.spaces || [],
      });
    }
  };

  const [
    formData,
    formLoading,
    formDirty,
    formInit,
    handleFormChange,
    handleFormSubmit,
  ] = useSchemaForm(
    handleSubmit,
    t('deviceSettingsSaved'),
    t('errorSavingDeviceSettings'),
  );

  useEffect(() => {
    // TODO: consider switching to device uuid router param instead?
    if (!isFormInitialized.current && device && !Object.keys(formData).length) {
      formInit({
        note: device.note || '',
        deviceName: device.deviceName,
        deviceSerial: device.deviceSerial,
        deviceProvisioned: device.deviceProvisioned,
        deviceMonitored: device.deviceMonitored,
        deviceExpectedAvailability: device.deviceExpectedAvailability || {},
        tags: device.tags || {},
        env: device.deviceTags && device.deviceTags.env,
        id: device.id,
        connectionString: device.deviceConnectionString,
        appBuild:
          device.properties &&
          device.properties.reported &&
          device.properties.reported.appBuild,
        edgeModules: device.properties ? device.properties.desired.edgeModules : {},
        arch: device.arch,
        spaces: device.spaces || [],
      });

      isFormInitialized.current = true;
    }
  }, [device, formInit, formData]);

  const propertiesMetaSchema = device
    ? injectTagEditor(device.propertiesMetaSchema)
    : null;
  const propertiesSchema = device ? device.propertiesSchema : null;

  // TODO: handle error here as well

  if (!device) {
    return null;
  }

  return (
    <Row gutter={40}>
      <Col md={24} xl={16}>
        <PanelCard>
          <SchemaForm
            ref={formElement}
            uiSchema={propertiesMetaSchema}
            data={formData}
            onChange={handleFormChange}
            schema={propertiesSchema}
            onSubmit={handleFormSubmit}
            onError={handleError}
            formContext={{ user }}
          />
        </PanelCard>
      </Col>
      <Col md={24} xl={8}>
        <PanelCard>
          <DeviceDetailsScreenshot device={device} />
          <DeviceDetailsInfo device={device} />
          {canManageDeviceSettings && (
            <Button
              loading={formLoading}
              size="large"
              block
              type="primary"
              onClick={handleSave}
              disabled={!formDirty}
            >
              {t('saveChanges')}
            </Button>
          )}
        </PanelCard>
      </Col>
    </Row>
  );
};

export default Settings;
