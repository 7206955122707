import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { FileImageSettings } from '../../../../../../store/types/media';

export interface ImageSettingsData {
  altText: string;
}

interface ImageSettingsProps {
  onChange?: (settings: ImageSettingsData) => void;
  value: FileImageSettings;
  isReadonly?: boolean;
}

const ImageSettings = (props: ImageSettingsProps) => {
  const { onChange = () => {}, value, isReadonly } = props;

  const { t } = useTranslation();

  const handleBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    onChange({
      altText: e.target.value,
    });
  }, [onChange]);

  return (
    <FieldWrap>
      <FieldLabel>{t('filePicker.altText')}</FieldLabel>
      <FieldInput
        defaultValue={value.altText}
        autoFocus
        onBlur={handleBlur}
        maxLength={100}
        placeholder={t('filePicker.settingsInputPlaceholder')}
        readOnly={isReadonly}
      />
    </FieldWrap>
  );
};

const FieldWrap = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
`;

const FieldLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
`;

const FieldInput = styled.input`
  all: unset;
  font-size: 14px;
  font-weight: 400;
`;

export default ImageSettings;
