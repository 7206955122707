import styled from '@emotion/styled';
import transientOptions from '../../../../utils/transient-emotion-styled-options';

const Section = styled('div', transientOptions)<{ $isLast?: boolean }>`
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  padding: 12px 16px;
  gap: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  ${({ $isLast }) => $isLast ? 'padding-bottom: 0;' : ''}
`;

export default Section;
