import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import MediaPlaceholder from '../../../../../assets/images/product-placeholder.svg';

interface MediaPreviewProps {
  className?: string;
  src: string;
  mediaType: string;
  size?: number;
}

const MediaPreview = (props: MediaPreviewProps) => {
  const { className, src, mediaType, size = 60 } = props;

  const [showPreviewPlaceholder, setShowPreviewPlaceholder] = useState<boolean>(false);

  useEffect(() => {
    setShowPreviewPlaceholder(false);
  }, [src, mediaType]);

  const handleMediaLoad = useCallback(() => setShowPreviewPlaceholder(false), []);

  const handleMediaError = useCallback(() => setShowPreviewPlaceholder(true), []);

  if (showPreviewPlaceholder || !src || !mediaType) {
    return (
      <PreviewImage
        className={className}
        src={MediaPlaceholder}
        alt={'preview'}
        size={size}
      />
    );
  }

  if (mediaType.includes('video') && !showPreviewPlaceholder) {
    return (
      <PreviewVideo
        className={className}
        src={src}
        onLoad={handleMediaLoad}
        onError={handleMediaError}
        size={size}
      />
    );
  }

  return (
    <PreviewImage
      className={className}
      src={src}
      alt={'preview'}
      onLoad={handleMediaLoad}
      onError={handleMediaError}
      size={size}
    />
  );
};

const PreviewImage = styled.img<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  object-fit: cover;
`;

const PreviewVideo = styled.video<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  object-fit: cover;
`;

export default React.memo(MediaPreview);
