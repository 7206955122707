import { InteractionType, SessionInteractionType } from '@ombori/grid-reports';
import { useQuery } from 'react-query';
import { useGridDataService } from '../../grid-data-service-provider';
import {
  AggregatedSpaceNpsByDay,
  BaseProps,
  SpaceCategoriesByDay,
  SpaceEventsByDay,
  SpaceInstallationEventsByDay,
  SpaceEventsByHour,
  SpaceEventsFlowByDay,
  SpaceInteractiveSessionsByDay,
  SpaceInteractiveSessionsByHour,
  SpaceProductsByDay,
  SpacePurchaseProductByDay,
  SpacePurchasesByDay,
  SpaceQrCodesByDay,
  SpaceMediaByDay,
  SpaceSessionsByDay,
  SpaceInstallationSessionsByDay,
  SpaceSessionsByHour,
  TimeSpanType,
  UseEventsFlowByDayProps,
} from './types';
import buildQueryKey from './build-query-key';
import routes from '../routes';

interface UseSpaceEventsByDayProps extends BaseProps {
  spaceId: string;
  interactionType?: InteractionType;
  eventType?: string;
}

export const useSpaceEventsByDay = ({
  tenantId,
  spaceId,
  dateFrom,
  dateTo,
  interactionType = InteractionType.All,
  dataResidency,
  eventType,
}: UseSpaceEventsByDayProps) => {
  const gridDataService = useGridDataService();

  const buildSpaceEventsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'spaceId',
    spaceId,
    'interactionType',
    interactionType,
    'events',
    ...(eventType ? ['eventType', eventType] : []),
  ];

  const baseQuery = {
    dateFrom,
    dateTo,
    timespanType: TimeSpanType.Day,
    interactionType,
  };

  const query = eventType ? { ...baseQuery, eventType } : baseQuery;

  return useQuery(
    buildSpaceEventsQuery(),
    () =>
      gridDataService.get<SpaceEventsByDay[]>(
        routes.getSpaceEvents(tenantId, spaceId),
        dataResidency,
        query,
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseSpacesEventsByDayProps extends BaseProps {
  interactionType?: InteractionType;
  eventType?: string;
}

export const useSpacesEventsByDay = ({
  tenantId,
  dateFrom,
  dateTo,
  interactionType = InteractionType.All,
  dataResidency,
  eventType,
  isEnabled = true,
}: UseSpacesEventsByDayProps) => {
  const gridDataService = useGridDataService();

  const buildSpaceEventsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'spaces',
    'interactionType',
    interactionType,
    'events',
    ...(eventType ? ['eventType', eventType] : []),
  ];

  const baseQuery = {
    dateFrom,
    dateTo,
    timespanType: TimeSpanType.Day,
    interactionType,
  };

  const query = eventType ? { ...baseQuery, eventType } : baseQuery;

  return useQuery(
    buildSpaceEventsQuery(),
    () =>
      gridDataService.get<SpaceEventsByDay[]>(
        routes.getSpacesEvents(tenantId),
        dataResidency,
        query,
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      enabled: isEnabled,
    },
  );
};

interface UseSpaceSessionsByDayProps extends BaseProps {
  spaceId: string;
  interactionType?: SessionInteractionType;
  select?: (data: SpaceSessionsByDay[]) => SpaceSessionsByDay[];
}

export const useSpaceSessionsByDay = ({
  tenantId,
  spaceId,
  dateFrom,
  dateTo,
  dataResidency,
  interactionType = SessionInteractionType.All,
  select,
}: UseSpaceSessionsByDayProps) => {
  const gridDataService = useGridDataService();

  const buildSpaceSessionsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'interactionType',
    interactionType,
    'spaceId',
    spaceId,
    'sessions',
  ];

  return useQuery(
    buildSpaceSessionsQuery(),
    () =>
      gridDataService.get<
        typeof interactionType extends SessionInteractionType.Interactive
          ? SpaceInteractiveSessionsByDay[]
          : SpaceSessionsByDay[]
      >(routes.getSpaceSessions(tenantId, spaceId), dataResidency, {
        dateFrom,
        dateTo,
        interactionType,
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      select,
    },
  );
};

interface UseSpacesEventsAndSessionsByDayProps extends BaseProps {
  interactionType?: InteractionType;
  eventType?: string;
  isEventsQueryEnabled?: boolean;
  isSessionsQueryEnabled?: boolean;
}

export const useSpacesEventsAndSessionsByDay = ({
  tenantId,
  dateFrom,
  dateTo,
  interactionType = InteractionType.All,
  dataResidency,
  eventType,
  isEventsQueryEnabled = true,
  isSessionsQueryEnabled = true,
}: UseSpacesEventsAndSessionsByDayProps) => {
  const gridDataService = useGridDataService();

  const buildSpaceEventsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'spaces',
    'interactionType',
    interactionType,
    'eventsAndSessions',
    ...(eventType ? ['eventType', eventType] : []),
  ];

  const baseQuery = {
    dateFrom,
    dateTo,
    timespanType: TimeSpanType.Day,
    interactionType,
  };

  const query = eventType ? { ...baseQuery, eventType } : baseQuery;

  const fetchEvents = () =>
    isEventsQueryEnabled
      ? gridDataService.get<SpaceEventsByDay[]>(
          routes.getSpacesEvents(tenantId),
          dataResidency,
          query,
        )
      : Promise.resolve([]);

  const fetchSessions = () =>
    isSessionsQueryEnabled
      ? gridDataService.get<SpaceSessionsByDay[]>(
          routes.getSpacesSessions(tenantId),
          dataResidency,
          query,
        )
      : Promise.resolve([]);

  const queryFn = async () => {
    const [events, sessions] = await Promise.all([fetchEvents(), fetchSessions()]);

    return { events, sessions };
  };

  return useQuery(buildSpaceEventsQuery(), queryFn, {
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
  });
};

interface UseSpacesInstallationEventsAndSessionsByDayProps extends BaseProps {
  installationId: string;
  interactionType?: InteractionType;
  eventType?: string;
  isEventsQueryEnabled?: boolean;
  isSessionsQueryEnabled?: boolean;
}

export const useSpacesInstallationEventsAndSessionsByDay = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  interactionType = InteractionType.All,
  dataResidency,
  eventType,
  isEventsQueryEnabled = true,
  isSessionsQueryEnabled = true,
}: UseSpacesInstallationEventsAndSessionsByDayProps) => {
  const gridDataService = useGridDataService();

  const buildSpaceEventsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'spaces',
    'installations',
    installationId,
    'interactionType',
    interactionType,
    'eventsAndSessions',
    ...(eventType ? ['eventType', eventType] : []),
  ];

  const baseQuery = {
    dateFrom,
    dateTo,
    timespanType: TimeSpanType.Day,
    interactionType,
  };

  const query = eventType ? { ...baseQuery, eventType } : baseQuery;

  const fetchEvents = () =>
    isEventsQueryEnabled
      ? gridDataService.get<SpaceInstallationEventsByDay[]>(
          routes.getSpacesInstallationEvents(tenantId, installationId),
          dataResidency,
          query,
        )
      : Promise.resolve([]);

  const fetchSessions = () =>
    isSessionsQueryEnabled
      ? gridDataService.get<SpaceInstallationSessionsByDay[]>(
          routes.getSpacesInstallationSessions(tenantId, installationId),
          dataResidency,
          query,
        )
      : Promise.resolve([]);

  const queryFn = async () => {
    const [events, sessions] = await Promise.all([fetchEvents(), fetchSessions()]);

    return { events, sessions };
  };

  return useQuery(buildSpaceEventsQuery(), queryFn, {
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
  });
};

interface UseSpacesSessionsByDayProps extends BaseProps {
  interactionType?: SessionInteractionType;
  select?: (data: SpaceSessionsByDay[]) => SpaceSessionsByDay[];
}

export const useSpacesSessionsByDay = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  interactionType = SessionInteractionType.All,
  select,
  isEnabled = true,
}: UseSpacesSessionsByDayProps) => {
  const gridDataService = useGridDataService();

  const buildSpaceSessionsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'interactionType',
    interactionType,
    'spaces',
    'sessions',
  ];

  return useQuery(
    buildSpaceSessionsQuery(),
    () =>
      gridDataService.get<
        typeof interactionType extends SessionInteractionType.Interactive
          ? SpaceInteractiveSessionsByDay[]
          : SpaceSessionsByDay[]
      >(routes.getSpacesSessions(tenantId), dataResidency, {
        dateFrom,
        dateTo,
        interactionType,
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      select,
      enabled: isEnabled,
    },
  );
};

interface UseSpaceNpsByDayProps extends BaseProps {
  spaceId: string;
}

export const useSpaceNpsByDay = ({
  tenantId,
  spaceId,
  dateFrom,
  dateTo,
  dataResidency,
}: UseSpaceNpsByDayProps) => {
  const gridDataService = useGridDataService();

  const buildSpaceNpsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'spaceId',
    spaceId,
    'nps',
  ];

  return useQuery(
    buildSpaceNpsQuery(),
    () =>
      gridDataService.get<AggregatedSpaceNpsByDay>(
        routes.getSpaceNps(tenantId, spaceId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseSpaceEventsFlowByDayProps extends UseEventsFlowByDayProps {
  spaceId: string;
}

export const useSpaceEventsFlowByDay = ({
  tenantId,
  spaceId,
  dateFrom,
  dateTo,
  dataResidency,
  eventsFlowDepth,
}: UseSpaceEventsFlowByDayProps) => {
  const gridDataService = useGridDataService();

  const buildSpaceEventsFlowQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'spaceId',
    spaceId,
    'eventsFlowDepth',
    eventsFlowDepth,
    'eventsFlow',
  ];

  return useQuery(
    buildSpaceEventsFlowQuery(),
    () =>
      gridDataService.get<SpaceEventsFlowByDay[]>(
        routes.getSpaceEventsFlow(tenantId, spaceId),
        dataResidency,
        {
          dateFrom,
          dateTo,
          eventsFlowDepth,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseSpaceProductsByDayProps extends BaseProps {
  spaceId: string;
}

export const useSpaceProductsByDay = ({
  tenantId,
  spaceId,
  dateFrom,
  dateTo,
  dataResidency,
}: UseSpaceProductsByDayProps) => {
  const gridDataService = useGridDataService();

  const buildSpaceProductsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'spaceId',
    spaceId,
    'products',
  ];

  return useQuery(
    buildSpaceProductsQuery(),
    () =>
      gridDataService.get<SpaceProductsByDay[]>(
        routes.getSpaceProducts(tenantId, spaceId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseSpaceCategoriesByDayProps extends BaseProps {
  spaceId: string;
}

export const useSpaceCategoriesByDay = ({
  tenantId,
  spaceId,
  dateFrom,
  dateTo,
  dataResidency,
}: UseSpaceCategoriesByDayProps) => {
  const gridDataService = useGridDataService();

  const buildSpaceCategoriesQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'spaceId',
    spaceId,
    'categories',
  ];

  return useQuery(
    buildSpaceCategoriesQuery(),
    () =>
      gridDataService.get<SpaceCategoriesByDay[]>(
        routes.getSpaceCategories(tenantId, spaceId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseSpacePurchasesByDayProps extends BaseProps {
  spaceId: string;
}

export const useSpacePurchasesByDay = ({
  tenantId,
  spaceId,
  dateFrom,
  dateTo,
  dataResidency,
}: UseSpacePurchasesByDayProps) => {
  const gridDataService = useGridDataService();

  const buildSpacePurchasesQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'spaceId',
    spaceId,
    'purchases',
  ];

  return useQuery(
    buildSpacePurchasesQuery(),
    () =>
      gridDataService.get<SpacePurchasesByDay[]>(
        routes.getSpacePurchases(tenantId, spaceId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseSpacePurchasedProductsByDayProps extends BaseProps {
  spaceId: string;
}

export const useSpacePurchasedProductsByDay = ({
  tenantId,
  spaceId,
  dateFrom,
  dateTo,
  dataResidency,
}: UseSpacePurchasedProductsByDayProps) => {
  const gridDataService = useGridDataService();

  const buildSpacePurchasesQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'spaceId',
    spaceId,
    'purchased-products',
  ];

  return useQuery(
    buildSpacePurchasesQuery(),
    () =>
      gridDataService.get<SpacePurchaseProductByDay[]>(
        routes.getSpacePurchasedProducts(tenantId, spaceId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseSpaceQrCodesByDayProps extends BaseProps {
  spaceId: string;
}

export const useSpaceQrCodesByDay = ({
  tenantId,
  spaceId,
  dateFrom,
  dateTo,
  dataResidency,
}: UseSpaceQrCodesByDayProps) => {
  const gridDataService = useGridDataService();

  const buildSpaceQrCodesQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'spaceId',
    spaceId,
    'qr-codes',
  ];

  return useQuery(
    buildSpaceQrCodesQuery(),
    () =>
      gridDataService.get<SpaceQrCodesByDay[]>(
        routes.getSpaceQrCodes(tenantId, spaceId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseSpaceMediaByDayProps extends BaseProps {
  spaceId: string;
}

export const useSpaceMediaByDay = ({
  tenantId,
  spaceId,
  dateFrom,
  dateTo,
  dataResidency,
}: UseSpaceMediaByDayProps) => {
  const gridDataService = useGridDataService();

  const buildSpaceMediaQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'spaceId',
    spaceId,
    'media',
  ];

  return useQuery(
    buildSpaceMediaQuery(),
    () =>
      gridDataService.get<SpaceMediaByDay[]>(
        routes.getSpaceMedia(tenantId, spaceId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export const useSpaceSessionsByHour = ({
  tenantId,
  spaceId,
  dateFrom,
  dateTo,
  dataResidency,
  interactionType = SessionInteractionType.All,
}: UseSpaceSessionsByDayProps) => {
  const gridDataService = useGridDataService();

  const timespanType = TimeSpanType.Hour;

  const buildSpaceSessionsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType }),
    'interactionType',
    interactionType,
    'spaceId',
    spaceId,
    'sessions',
  ];

  return useQuery(
    buildSpaceSessionsQuery(),
    () =>
      gridDataService.get<
        typeof interactionType extends SessionInteractionType.Interactive
          ? SpaceInteractiveSessionsByHour[]
          : SpaceSessionsByHour[]
      >(routes.getSpaceSessions(tenantId, spaceId), dataResidency, {
        dateFrom,
        dateTo,
        timespanType,
        interactionType,
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export const useSpaceEventsByHour = ({
  tenantId,
  spaceId,
  dateFrom,
  dateTo,
  interactionType = InteractionType.All,
  dataResidency,
}: UseSpaceEventsByDayProps) => {
  const gridDataService = useGridDataService();

  const timespanType = TimeSpanType.Hour;

  const buildSpaceEventsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType }),
    'spaceId',
    spaceId,
    'interactionType',
    interactionType,
    'events',
  ];

  return useQuery(
    buildSpaceEventsQuery(),
    () =>
      gridDataService.get<SpaceEventsByHour[]>(
        routes.getSpaceEvents(tenantId, spaceId),
        dataResidency,
        {
          dateFrom,
          dateTo,
          timespanType,
          interactionType,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};
