import groupBy from 'lodash/groupBy';
import keys from 'lodash/keys';
import { SpaceSessionsByDay } from './types';

function mergeNestedSpacesDayData<T extends { date: string }>(
  mergeFn: (a: T, b: T) => T,
): (data: T[]) => T[] {
  return (data: T[]) => {
    const groupedByDate = groupBy(data, 'date');

    const result = keys(groupedByDate).map((date) => {
      const dateData = groupedByDate[date];

      const [head, ...tail] = dateData;

      const mergedData = tail.reduce((acc, item) => mergeFn(acc, item), head);

      return mergedData;
    });

    return result;
  };
}

export const mergeNestedSpacesSessionsByDay = mergeNestedSpacesDayData<
  SpaceSessionsByDay
>((a, b) => ({
  ...a,
  sessionCount: a.sessionCount + b.sessionCount,
}));
