import {
  AbsentSuccessValueInput,
  DataMatrixColumn,
  DataMatrixColumnOperationType,
  DownloadFileColumnType,
  DownloadFileColumn,
  DataSourceBaseEntity,
  DataSourceTypeEntityEnum,
} from '@ombori/grid-reports';
import { keyBy, assign } from 'lodash';
import { DataMatrixColumnDataType } from '@ombori/grid-reports';
import getEventType from '../get-event-type';
import {
  SpaceEventsByDay,
  SpaceSessionsByDay,
  DeviceAndSpaceEventsByDay,
  DeviceEventsByDay,
  DeviceSessionsByDay,
  ReportContext,
} from '../../../../use-analytics-report';
import OrganisationSpace from '../../../../../../store/types/organisation-space';
import UniversalDevice from '../../../../../../store/types/universal-device';
import OrganisationSpaceTypeEnum from '../../../../../../store/types/organisation-space-type.enum';
import getColumnValue from '../get-column-value';
import { formatPercentage, getSpaceAverageOrLowestPercentage, getDeviceAverageOrLowestPercentage } from '../get-percentage';
import { SpaceDataMatrixDataRow } from './use-space-data-matrix-data';
import { DeviceDataMatrixDataRow } from './use-device-data-matrix-data';
import { buildData as buildSuccessRateData } from '../../events-succes-failure-rate';

export interface SpaceEventAndSessionData {
  events: SpaceEventsByDay[];
  sessions: SpaceSessionsByDay[];
}

type SpacesEventAndSessionData = Record<
  string,
  SpaceEventAndSessionData
>;

export interface DeviceEventAndSessionData {
  events: DeviceEventsByDay[];
  sessions: DeviceSessionsByDay[];
};

export interface SpaceSuccessRateData {
  spaceId: string;
  successPercentage: string;
  failPercentage: string;
  hasData: boolean
}

interface ColumnParams {
  dataMatrixColumns: DataMatrixColumn[];
  inputTypeColumns: AbsentSuccessValueInput[];
  dataMatrixEventsTypes: string;
  inputTypeEventsTypes: string;
  isEventsQueryEnabled: boolean;
  isSessionsQueryEnabled: boolean;
  eventTypesQueryString: string;
}

interface DeviceSpaceSuccessFailCalculatedData {
  spaceData: SpaceSuccessRateData[];
  hasData: boolean;
  lowestSuccessRate: string;
  deviceId: string;
}

const getDeviceSpaceSuccessFailCalculatedData = ({
  deviceAndSpaceData,
  devices,
  spaces,
  columnAbsentSuccessValueInput,
}: {
  deviceAndSpaceData: DeviceAndSpaceEventsByDay[],
  devices: UniversalDevice[],
  spaces: OrganisationSpace[],
  columnAbsentSuccessValueInput: AbsentSuccessValueInput,
}): DeviceSpaceSuccessFailCalculatedData[] => {
  const getLowestSuccessRate = (data: SpaceSuccessRateData[]) => {
      // Filter out objects where hasData is false
    const validData = data.filter(d => d.hasData);

    // Check if there is any valid data
    if (validData.length === 0) {
      return '';
    }

    // Convert successPercentage to number and find the minimum
    let minPercentage = parseFloat(validData[0].successPercentage.replace('%', ''));

    for (let i = 1; i < validData.length; i++) {
      const currentPercentage = parseFloat(validData[i].successPercentage.replace('%', ''));
      if (currentPercentage < minPercentage) {
        minPercentage = currentPercentage;
      }
    }

    return `${minPercentage}%`;
  };

  const subSpaces = spaces
    .filter((space) => space.type === OrganisationSpaceTypeEnum.SECTION);

  const deviceSuccessFailData = devices.map((dataItem) => {
    const deviceEvents = deviceAndSpaceData
      .filter((deviceData) => deviceData.deviceId === dataItem.id);

    const successFailData = buildSuccessRateData({
      rawData: deviceEvents,
      events: columnAbsentSuccessValueInput,
      dataSource: DataSourceBaseEntity.Space,
      dataSourceType: DataSourceTypeEntityEnum.SECTION,
      reportContext: ReportContext.Device,
      spaces: subSpaces,
    });

    const spaceResult = successFailData.eventsResult.reduce(
      (acc: SpaceSuccessRateData[], [spaceId, successAndFailData]) => {
        let successRate: string = '';
        let failRate: string = '';
  
        successAndFailData.forEach((result) => {
          const data = result[1][0];
          if (data.type === 'success') {
            successRate = data.percentage;
          } else {
            failRate = data.percentage;
          }
        });

        return [
          ...acc,
          {
            spaceId,
            hasData: Boolean(successRate || failRate),
            successPercentage: successRate,
            failPercentage: failRate,
          }
        ];
      }, []);

    const lowestSuccessRate = deviceEvents.length ? getLowestSuccessRate(spaceResult) || '0%' : '';

    return {
      spaceData: spaceResult,
      hasData: spaceResult.every((dataItem) => dataItem.hasData),
      lowestSuccessRate,
      deviceId: dataItem.id,
    };
  });

  return deviceSuccessFailData;
}

const getDeviceSuccessRate = ({
  deviceId,
  column,
  deviceEventAndSessionData,
  deviceSpaceSuccessFailCalculatedData,
}: {
  deviceId: string;
  column: AbsentSuccessValueInput,
  deviceEventAndSessionData: DeviceEventAndSessionData,
  deviceSpaceSuccessFailCalculatedData: DeviceSpaceSuccessFailCalculatedData[],
}) => {
  if (column.operationType
    && column.operationType.type === DataMatrixColumnOperationType.Lowest
    && column.operationType.distinctKey === 'parentSpaceId'
  ) {
    const title = column.title ? column.title : 'Success rate';
    const deviceData = deviceSpaceSuccessFailCalculatedData.find((dataItem) => dataItem.deviceId === deviceId);
    const lowestSuccessRate = deviceData ? deviceData.lowestSuccessRate : '';

    return { [title]: lowestSuccessRate };
  }

  const successRate = calculateSuccessRate(column, deviceEventAndSessionData.events);
  
  return {
    [column.title ? column.title : 'Success rate']:
      deviceEventAndSessionData.events.length
        ? formatPercentage(successRate)
        : ''
  };
}

export const getDeviceTableAndDownloadDataMatrix = ({
  devicesEventsAndSessionsData,
  deviceAndSpaceData,
  spaces,
  devices,
  tableColumnsParams,
  downloadColumnsParams,
  spaceId,
}: {
  devicesEventsAndSessionsData: DeviceEventAndSessionData;
  deviceAndSpaceData: DeviceAndSpaceEventsByDay[],
  spaces: OrganisationSpace[];
  devices: UniversalDevice[];
  tableColumnsParams: ColumnParams;
  downloadColumnsParams: ColumnParams;
  spaceId?: string;
}) => {
  let targetDevices = devices;

  if (spaceId) {
    targetDevices = devices.filter((device) => device.spaces.includes(spaceId));
  }

  const devicesRecord: Record<string, UniversalDevice> = {};
  const devicesEventAndSessionData: Record<
    string,
    { events: DeviceEventsByDay[]; sessions: DeviceSessionsByDay[] }
  > = {};

  targetDevices.forEach((device) => {
    devicesRecord[device.uuid] = device;
    devicesEventAndSessionData[device.uuid] = { events: [], sessions: [] };
  });

  devicesEventsAndSessionsData.events.forEach((event) => {
    const deviceData = devicesEventAndSessionData[event.deviceId];
    if (deviceData) {
      devicesEventAndSessionData[event.deviceId].events.push(event);
    }
  });

  devicesEventsAndSessionsData.sessions.forEach((event) => {
    const deviceData = devicesEventAndSessionData[event.deviceId];
    if (deviceData) {
      devicesEventAndSessionData[event.deviceId].sessions.push(event);
    }
  });

  const deviceSpaceSuccessFailCalculatedData = getDeviceSpaceSuccessFailCalculatedData({
    deviceAndSpaceData,
    devices,
    spaces,
    columnAbsentSuccessValueInput: tableColumnsParams.inputTypeColumns[0],
  });

  const tableData: DeviceDataMatrixDataRow[] = [];
  const downloadData: DeviceDataMatrixDataRow[] = [];

  const hasDownloadColumns = downloadColumnsParams.dataMatrixColumns.length > 0;

  Object.keys(devicesEventAndSessionData).forEach((deviceId) => {
    const device = devicesRecord[deviceId];
    const deviceEventAndSessionData = devicesEventAndSessionData[deviceId];
    const space = spaces.find((space) => device.spaces.includes(space.id));

    const tableMatrixResults = assign(
      {},
      ...tableColumnsParams.dataMatrixColumns
        .map((column) => {
          const columnValue = getColumnValue({
            column,
            eventsData: deviceEventAndSessionData.events,
            sessionData: deviceEventAndSessionData.sessions,
            getEventsAvgValue: getDeviceAverageOrLowestPercentage,
            getEventsLowestValue: getDeviceAverageOrLowestPercentage,
          });

          return { [column.title]: columnValue };
        })
    );

    const downloadMatrixResults = assign(
      {},
      ...downloadColumnsParams.dataMatrixColumns
        .map((column) => {
          const columnValue = getColumnValue({
            column,
            eventsData: deviceEventAndSessionData.events,
            sessionData: deviceEventAndSessionData.sessions,
            getEventsAvgValue: getDeviceAverageOrLowestPercentage,
            getEventsLowestValue: getDeviceAverageOrLowestPercentage,
          });

          return { [column.title]: columnValue };
        })
    );

    const tableMatrixInputTypeColumnsResult = assign(
      {},
      ...tableColumnsParams.inputTypeColumns
        .map((column) => getDeviceSuccessRate({
          deviceId,
          column,
          deviceEventAndSessionData,
          deviceSpaceSuccessFailCalculatedData,
        }))
    );

    const downloadMatrixInputTypeColumnsResult = assign(
      {},
      ...downloadColumnsParams.inputTypeColumns
        .map((column) => getDeviceSuccessRate({
          deviceId,
          column,
          deviceEventAndSessionData,
          deviceSpaceSuccessFailCalculatedData,
        }))
    );

    const commonProps = {
      key: device.uuid,
      space,
      device,
    };

    tableData.push({
      ...commonProps,
      columns: {
        ...tableMatrixResults,
        ...tableMatrixInputTypeColumnsResult,
      },
    });

    if (hasDownloadColumns) {
      downloadData.push({
        ...commonProps,
        columns: {
          ...downloadMatrixResults,
          ...downloadMatrixInputTypeColumnsResult,
        },
      });
    }
  });

  return {
    tableData,
    downloadData,
  };
}

const getSpaceSuccessRate = ({
  column,
  space,
  spaces,
  spaceEventAndSessionData,
  spacesEventAndSessionData,
}: {
  column: AbsentSuccessValueInput,
  space: OrganisationSpace,
  spaces: OrganisationSpace[],
  spaceEventAndSessionData: SpaceEventAndSessionData,
  spacesEventAndSessionData: SpacesEventAndSessionData,
}) => {
  if (column.operationType && column.operationType.type === DataMatrixColumnOperationType.Lowest) {
    const distinctKey = column.operationType.distinctKey as keyof OrganisationSpace;
    const subSpaces = spaces.filter((subSpace) => subSpace[distinctKey] === space.id);

    const subSpacesSuccessRates: number[] = [];
    subSpaces.forEach((subSpace) => {
      const { events = [] } = spacesEventAndSessionData[subSpace.id] || {};
      const successRate = calculateSuccessRate(column, events);
      if (successRate) { // don't show 0% as per current business requirements
        subSpacesSuccessRates.push(successRate);
      }
    });

    // when subSpacesSuccessRates is empty, it'll return Infinity
    const lowestSuccessRate = Math.min(...subSpacesSuccessRates);

    const title = column.title ? column.title : 'Lowest Success Rate';
    return {
      [title]: spaceEventAndSessionData.events.length
        ? formatPercentage(isFinite(lowestSuccessRate) ? lowestSuccessRate : 0)
        : ''
    };
  }

  const successRate = calculateSuccessRate(column, spaceEventAndSessionData.events);
  
  return {
    [column.title ? column.title : 'Success rate']:
      spaceEventAndSessionData.events.length ? formatPercentage(successRate) : ''
  };
}

export const getSpaceTableAndDownloadDataMatrix = ({
  spacesEventsAndSessionsData,
  deviceAndSpaceData,
  spaces,
  devices,
  tableColumnsParams,
  downloadColumnsParams,
}: {
  spacesEventsAndSessionsData: SpaceEventAndSessionData;
  deviceAndSpaceData: DeviceAndSpaceEventsByDay[],
  spaces: OrganisationSpace[];
  devices: UniversalDevice[];
  tableColumnsParams: ColumnParams;
  downloadColumnsParams: ColumnParams;
}) => {
  const spacesRecord = keyBy(spaces, 'id');

  const rootSpaces = spaces.filter((space) => !space.parentSpaceId);
  const rootSpacesRecord = keyBy(rootSpaces, 'id');

  const spacesWithEventsAndValidHierarchy = spaces
    .filter((space) => {
      const isParentSpace = !space.parentSpaceId;
      const hasEventsData = spacesEventsAndSessionsData.events.some((event) => event.spaceId === space.id);
      const isValidHierarchy = space.parentSpaceId
        && rootSpacesRecord[space.parentSpaceId]
        && rootSpacesRecord[space.parentSpaceId].type === OrganisationSpaceTypeEnum.LOCATION;

      return isParentSpace || (hasEventsData && isValidHierarchy);
    });
  
  const spacesEventAndSessionData: Record<string, { events: SpaceEventsByDay[]; sessions: SpaceSessionsByDay[] }> =
    {};

  spacesWithEventsAndValidHierarchy.forEach((space) => {
    spacesEventAndSessionData[space.id] = { events: [], sessions: [] };
  });

  spacesEventsAndSessionsData.events.forEach((event) => {
    const spaceId = event.spaceId;
    const space = spacesRecord[spaceId];

    // handle case where space is undefined
    if (!space) {
      return;
    }

    const rootSpace = space && space.parentSpaceId ? rootSpacesRecord[space.parentSpaceId] : null;

    // add child sub-space events to its parent
    if (space && space.parentSpaceId && rootSpace && spacesRecord[rootSpace.id]) {
      spacesEventAndSessionData[rootSpace.id].events.push({ ...event, spaceId: rootSpace.id });
    }

    if (spacesEventAndSessionData[spaceId]) {
      spacesEventAndSessionData[spaceId].events.push({ ...event });
    }
  });

  spacesEventsAndSessionsData.sessions.forEach((session) => {
    const rootSpace = rootSpacesRecord[session.spaceId];
    if (rootSpace && spacesEventAndSessionData[rootSpace.id]) {
      spacesEventAndSessionData[rootSpace.id].sessions.push({ ...session });
    }

    if (spacesEventAndSessionData[session.spaceId]) {
      spacesEventAndSessionData[session.spaceId].sessions.push({ ...session });
    }
  });

  const tableData: SpaceDataMatrixDataRow[] = [];
  const downloadData: SpaceDataMatrixDataRow[] = [];

  const hasDownloadColumns = downloadColumnsParams.dataMatrixColumns.length > 0;

  Object.keys(spacesEventAndSessionData)
    .forEach((spaceId) => {
      const space = spacesRecord[spaceId];
      const parentSpace = space && space.parentSpaceId
        ? spacesRecord[space.parentSpaceId] : null;

      const spaceEventAndSessionData = spacesEventAndSessionData[spaceId];

      const tableMatrixResults = assign(
        {},
        ...tableColumnsParams.dataMatrixColumns
          .map((column) => {
            const columnValue = getColumnValue({
              column,
              eventsData: spaceEventAndSessionData.events,
              sessionData: spaceEventAndSessionData.sessions,
              getEventsAvgValue: getSpaceAverageOrLowestPercentage,
              getEventsLowestValue: getSpaceAverageOrLowestPercentage,
            });

            return { [column.title]: columnValue };
          })
      );

      const downloadMatrixResults = assign(
        {},
        ...downloadColumnsParams.dataMatrixColumns
          .map((column) => {
            const columnValue = getColumnValue({
              column,
              eventsData: spaceEventAndSessionData.events,
              sessionData: spaceEventAndSessionData.sessions,
              getEventsAvgValue: getSpaceAverageOrLowestPercentage,
              getEventsLowestValue: getSpaceAverageOrLowestPercentage,
            });

            return { [column.title]: columnValue };
          })
      );

      const tableMatrixInputTypeColumnsResult = assign(
        {},
        ...tableColumnsParams.inputTypeColumns
          .map((column) => getSpaceSuccessRate({
            column,
            space,
            spaces,
            spaceEventAndSessionData,
            spacesEventAndSessionData,
          }))
      );

      const downloadMatrixInputTypeColumnsResult = assign(
        {},
        ...downloadColumnsParams.inputTypeColumns
          .map((column) => getSpaceSuccessRate({
            column,
            space,
            spaces,
            spaceEventAndSessionData,
            spacesEventAndSessionData,
          }))
      );

      const commonProps = {
        key: spaceId,
        devices: deviceAndSpaceData.reduce((deviceData: UniversalDevice[], deviceAndSpaceData) => {
          if (deviceAndSpaceData.spaceId === spaceId) {
            const device = devices.find((d) => d.uuid === deviceAndSpaceData.deviceId);
            const deviceExists = deviceData.some((d) => d.uuid === deviceAndSpaceData.deviceId);
            if (device && !deviceExists) {
              deviceData.push(device);
            }
          }
          return deviceData;
        }, []),
        space,
        parentSpace,
      };

      tableData.push({
        ...commonProps,
        columns: {
          ...tableMatrixResults,
          ...tableMatrixInputTypeColumnsResult,
        },
      });
  
      if (hasDownloadColumns) {
        downloadData.push({
          ...commonProps,
          columns: {
            ...downloadMatrixResults,
            ...downloadMatrixInputTypeColumnsResult,
          },
        });
      }
    });

  return {
    tableData,
    downloadData,
    spacesWithData: spacesWithEventsAndValidHierarchy,
  };
}

const getInputTypeEventsTypes = (inputTypeColumns: AbsentSuccessValueInput[]): string => {
  if (!inputTypeColumns.length) {
    return '';
  }

  const failedEvents: string[] = [];
  inputTypeColumns.forEach((inputTypeColumn) => {
    failedEvents.push(...inputTypeColumn.failureData.eventTypes)
  });

  const totalEvents: string[] = [];
  inputTypeColumns.forEach((inputTypeColumn) => {
    totalEvents.push(...inputTypeColumn.totalData.eventTypes);
  });

  const allEvents = [...failedEvents, ...totalEvents];

  return allEvents.join(',');
};

const getEventTypesQueryString = (dataMatrixEventsTypes: string, inputTypeEventsTypes: string) => {
  const eventTypes: string[] = [];

  if (dataMatrixEventsTypes) {
    eventTypes.push(dataMatrixEventsTypes);
  }
  if (inputTypeEventsTypes && inputTypeEventsTypes.length > 0) {
    eventTypes.push(inputTypeEventsTypes);
  }

  return eventTypes.join(',');
}

const getEventTypesCount = (events: SpaceEventsByDay[] | DeviceEventsByDay[]) => {
  const eventTypesCount: Record<string, number> = {};
  events.forEach((event: SpaceEventsByDay | DeviceEventsByDay) => {
    const { eventType, count } = event;
    if (typeof eventTypesCount[eventType] === 'undefined') {
      eventTypesCount[eventType] = 0;
    }

    eventTypesCount[eventType] += count;
  });

  return eventTypesCount;
}

const calculateSuccessRate = (column: AbsentSuccessValueInput, events: SpaceEventsByDay[] | DeviceEventsByDay[]): number => {
  const eventTypesCount = getEventTypesCount(events);

  let totalCount = 0;

  column.totalData.eventTypes.forEach((event) => {
    totalCount += eventTypesCount[event] || 0;
  });

  let failedCount = 0;
  column.failureData.eventTypes.forEach((event) => {
    failedCount += eventTypesCount[event] || 0;
  });

  let successRate = 0;
  if (totalCount > 0) {
    const failureRate = failedCount / totalCount;
    successRate = (1 - failureRate) * 100;
  }

  return successRate;
}

export const getColumnParams = (columns: DownloadFileColumn[]): ColumnParams => {
  const dataMatrixColumns = columns
    .filter((column) => column.type === DownloadFileColumnType.DataMatrix)
    .map((column) => column.column as DataMatrixColumn);
  const inputTypeColumns = columns
    .filter((column) => column.type === DownloadFileColumnType.InputType)
    .map((column) => column.column as AbsentSuccessValueInput);
  const dataMatrixEventsTypes = getEventType(dataMatrixColumns);
  const inputTypeEventsTypes = getInputTypeEventsTypes(inputTypeColumns);
  const isEventsQueryEnabled = dataMatrixColumns.some(
    (column) => column.dataType.type === DataMatrixColumnDataType.Events,
  );
  const isSessionsQueryEnabled = dataMatrixColumns.some(
    (column) => column.dataType.type === DataMatrixColumnDataType.Sessions,
  );
  const eventTypesQueryString = getEventTypesQueryString(dataMatrixEventsTypes, inputTypeEventsTypes);

  return {
    dataMatrixColumns,
    inputTypeColumns,
    dataMatrixEventsTypes,
    inputTypeEventsTypes,
    isEventsQueryEnabled,
    isSessionsQueryEnabled,
    eventTypesQueryString,
  };
}

