import dayjs, { Dayjs } from 'dayjs';

export const getPrecedingPeriod = (dateFrom: string, dateTo: string) => {
  const dateFromDayjs = dayjs(dateFrom);
  const dateToDayjs = dayjs(dateTo);

  const periodLength = dateToDayjs.diff(dateFromDayjs, 'day') + 1;

  const precedingDateFrom = dateFromDayjs
    .subtract(periodLength, 'day')
    .format('YYYY-MM-DD');
  const precedingDateTo = dateFromDayjs.subtract(1, 'day').format('YYYY-MM-DD');

  return { dateFrom: precedingDateFrom, dateTo: precedingDateTo };
};

const dayjsRange = (start: Dayjs, end: Dayjs) => {
  const range = [];

  let current = start;

  while (!current.isAfter(end)) {
    range.push(current);
    current = current.add(1, 'day');
  }

  return range;
};

export type Data = [string, { label: string; value: number }[]];

interface SyncDataProps {
  data: Data[];
  dateFrom: string;
  dateTo: string;
  buildDataItem: (
    date: string,
    data: { label: string; value: number }[],
  ) => [string, { label: string; value: number }[]];
}

/**
 * Sync data with date range to make sure that current period data and preceding period data have the same length
 * @param data
 * @param dateFrom
 * @param dateTo
 * @param buildDataItem
 */
export const syncData = ({
  data,
  dateFrom,
  dateTo,
  buildDataItem,
}: SyncDataProps): [string, { label: string; value: number }[]][] => {
  const range = dayjsRange(dayjs(dateFrom), dayjs(dateTo));

  const result = range.map((date) => {
    const item = data.find((dataItem) => dayjs(dataItem[0]).isSame(date));

    if (!item) {
      const item = buildDataItem(date.format('YYYY-MM-DD'), []);

      return item;
    }

    return item;
  });

  return result;
};
