import React, { useCallback, useEffect, useState } from 'react';
import { FieldProps } from 'react-jsonschema-form';
import { Dropdown, Button } from 'antd';
import { useActions, useStore } from 'easy-peasy';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { GridProduct } from '@ombori/grid-products/dist';
import { uniq } from 'lodash';
import { RootModel, RootState } from '../../../../../store/models/root.model';
import GridProductsOverview from '../../../../organisations/organisation-details/grid-products/grid-products-overview/grid-products-overview.component';
import { getBestMatchByLanguage } from '../../../../organisations/organisation-details/grid-products/utils';
import { defaultLng } from '../../../../organisations/organisation-details/grid-products/grid-products.components';
import { DataResidencyEnum } from '../../../../../store/types/organisation';

interface ProductsQueryDataProps {
  productGroupsIds: string;
  productsIds: string;
  ref: 'grid-products';
  productSourceURL: string;
  env: string;
}

const ProductsQuery = ({
  schema: { title },
  formData: formDataRaw,
  onChange,
  formContext,
}: FieldProps<ProductsQueryDataProps | undefined> & {
  onChange: (data: Partial<ProductsQueryDataProps>) => void;
}) => {
  const formData =
    formDataRaw && formDataRaw.productGroupsIds
      ? formDataRaw
      : {
          productGroupsIds: [],
          productsIds: [],
          productSourceURL: '',
          ref: 'grid-products',
          env: 'prod',
        };
  const defaultOrExistingEnv = formData.env;
  const { organisationId: tenantId, history } = formContext;
  const match = {
    params: { organisationId: tenantId },
    url: `/organisations/${tenantId}/grid-products/overview`,
    isExact: false,
    path: 'provided-from-product-queury-not-real',
  };
  const { t } = useTranslation();

  const { getFetchUrl } = useActions<RootModel>((actions) => ({
    getFetchUrl: actions.gridProducts.getURLToFetchOne,
  }));

  const [env, setEnv] = useState(defaultOrExistingEnv);
  const { products, isLoading, tenantDataResidency } = useStore<
    RootState,
    {
      products: GridProduct[];
      isLoading: boolean;
      tenantDataResidency: DataResidencyEnum | null;
    }
  >((state) => {
    const result = {
      products: state.gridProducts.values(tenantId) || [],
      isLoading: state.gridProducts.loading[tenantId],
      tenantDataResidency:
        state.organisations.data &&
        state.organisations.data[tenantId] &&
        state.organisations.data[tenantId].dataResidency,
    };

    return result;
  });

  useEffect(() => {
    const logic = async () => {
      if (!tenantDataResidency) {
        return;
      }
      const url = await getFetchUrl({
        tenantId,
        dataResidency: tenantDataResidency,
        env,
      });
      if (formData.productSourceURL === url) {
        return;
      }
      onChange({ ...formData, productSourceURL: url });
    };
    logic();
  }, [getFetchUrl, tenantDataResidency, tenantId, env, formData, onChange]);

  const onSave = useCallback(() => {
    onChange({
      ...formData,
      productsIds: [], // should be added later
      productsGroupsIds: uniq([...products.map((product) => product.productGroupId)]),
    });
  }, [formData, onChange, products]);

  return (
    <Container>
      <div>
        <label className="control-label">{title}</label>
      </div>
      <Button.Group size="large">
        {tenantDataResidency && (
          <Dropdown
            overlay={
              <GridProductsOverview
                history={history}
                match={match}
                location={history.location}
                setEnvironmentName={setEnv}
                dataResidency={tenantDataResidency}
              />
            }
            trigger={['click']}
            placement="bottomLeft"
          >
            <ListProducts>
              <Button>{t('gridProducts.productQuerySearchClickToSeeMore')}</Button>
              <br />
              {products.length > 0 ? (
                <span>
                  {products.map((p) => {
                    const { productName } = getBestMatchByLanguage(
                      p.productName,
                      defaultLng,
                    );
                    return <ProductNameLine>{productName}</ProductNameLine>;
                  })}
                </span>
              ) : (
                <span>{t('gridProducts.productsAreNotSelected')}&ensp;</span>
              )}
            </ListProducts>
          </Dropdown>
        )}
        <Button
          disabled={isLoading}
          type={products.length === 0 ? 'danger' : 'default'}
          onClick={onSave}
        >
          {t('gridProducts.productQuerySearchSaveToApply')}
        </Button>
      </Button.Group>
    </Container>
  );
};

const ListProducts = styled.div`
  -webkit-line-clamp: 4;
`;

const ProductNameLine = styled.span`
  margin-right: 10px;
`;

const Container = styled.div`
  margin-bottom: 16px;
`;

export default ProductsQuery;
