export interface SkuDropdownOptions {
  label: string;
  value: string;
};

export const SkuPriceListOptions: SkuDropdownOptions[] = [
  {
    label: 'Cloud apps',
    value: 'cloud_apps',
  },
  {
    label: 'Edge apps',
    value: 'edge_apps',
  },
  {
    label: 'Mobile apps',
    value: 'mobile_apps',
  },      
  {
    label: 'Screen apps',
    value: 'screen_apps',
  },
  {
    label: 'SMS',
    value: 'sms',
  },
  {
    label: 'Support plans',
    value: 'support_plans',
  },
  {
    label: 'Tenant plans',
    value: 'tenant_plans',
  },
  {
    label: 'Grid usage',
    value: 'usage',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const SkuRevenueTypeOptions: SkuDropdownOptions[] = [
  {
    label: 'Saas Revenue',
    value: 'saasRevenue',
  },
  {
    label: 'Professional Services',
    value: 'professionalServices',
  },
  {
    label: 'Third Party',
    value: 'thirdParty',
  },
];

export const SkuUnitOptions: SkuDropdownOptions[] = [
  {
    label: 'Day',
    value: 'day',
  }, 
  {
    label: 'Month',
    value: 'month',
  }, 
  {
    label: 'GB',
    value: 'GB',
  }, 
  {
    label: 'Minute',
    value: 'minute',
  }, 
  {
    label: 'Product',
    value: 'product',
  }, 
  {
    label: 'MTU',
    value: 'MTU',
  }, 
  {
    label: 'Unit',
    value: 'unit',
  }, 
  {
    label: 'Message',
    value: 'message',
  }, 
  {
    label: 'Segment',
    value: 'segment',
  },
];
