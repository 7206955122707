import React, { useMemo } from 'react';
import { Typography, Tag as AntdTag } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { InputWrap, Option } from '../../../../common/react-final-form';
import useTagsList from '../../../../../store/hooks/content-tags/use-tags-list';
import { Select } from '../../../../common/react-final-form';
import { prepareTagOptions } from './utils';
import { Field } from 'react-final-form';

interface ChannelFormTagsSectionProps {
  tenantId: string;
  disableTagsSelection?: boolean;
}

const ChannelFormTagsSection = (props: ChannelFormTagsSectionProps) => {
  const { tenantId, disableTagsSelection } = props;

  const { t } = useTranslation();

  const { data: tagsData } = useTagsList(tenantId, 1000);

  const tagOptions = useMemo<Option[]>(() => {
    const tags = tagsData && tagsData.docs ? tagsData.docs : [];

    return prepareTagOptions(tags, t);
  }, [tagsData, t]);

  return (
    <>
      <Typography.Title level={4}>{t('contents.contentVariants')}</Typography.Title>
      <ChannelFormTagSectionSubheading>
        {t('contents.channelFormTagSectionSubheading')}
      </ChannelFormTagSectionSubheading>

      {!disableTagsSelection && (
          <TagsContainer>
            <InputWrap label={t('contents.addContentVariant')}>
              <Select name="tags" options={tagOptions} mode="multiple" />
            </InputWrap>
          </TagsContainer>
      )}

      {disableTagsSelection && (
        <Field
          name="tags"
          render={({ input }) => {
            return (
              <TagsContainer>
                {(!input.value || !input.value.length) && (
                  <NoContentVariantMessage>
                    <Typography.Text disabled={true}>{t('contents.noContentVariants')}</Typography.Text>
                  </NoContentVariantMessage>
                )}

                {(input.value && input.value.length) && (
                  tagOptions
                  .filter((tagOption) => input.value.includes(tagOption.value))
                  .map((tag) => {
                    return <AntdTag>{tag.label}</AntdTag>;
                  })
                )}
              </TagsContainer>
            )
          }}
        />
      )}
    </>
  );
};

export default ChannelFormTagsSection;

const ChannelFormTagSectionSubheading = styled(Typography.Text)`
  white-space: pre-line;
`;

const TagsContainer = styled.div`
  margin-top: 10px;
`;

const NoContentVariantMessage = styled.div`
  text-align: center;
  margin-top: 15px;
`;
