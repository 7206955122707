import { thunk, Thunk } from 'easy-peasy';
import Injections from '../../injections.interface';
import Contact from '../../types/contact';
import createKeyedCrudModel, {
  KeyedCrudModel,
} from '../../common/keyed-crud-model/keyed-crud.model';
import { DataResidencyEnum } from '../../types/organisation';

interface BaseProps {
  tenantId: string;
  dataResidency: DataResidencyEnum;
}

interface BasePropsWithContactId extends BaseProps {
  contactId: string;
}

export interface ContactsModel extends KeyedCrudModel<Contact, { tenantId: string }> {
  fetchForTenant: Thunk<
    ContactsModel,
    BaseProps & {
      identifier?: string;
      page: number;
      limit: number;
    },
    Injections,
    {},
    Promise<{ totalCount: number; totalPages: number; hasMore: boolean }>
  >;
  fetchOne: Thunk<ContactsModel, BasePropsWithContactId, Injections>;
  deleteOne: Thunk<ContactsModel, BasePropsWithContactId, Injections>;
}

const contactsModel: ContactsModel = {
  ...createKeyedCrudModel<Contact, { tenantId: string }>('/api/contacts', 'tenantId'),
  fetchForTenant: thunk(
    async (
      actions,
      { tenantId, identifier, dataResidency, page, limit },
      { injections },
    ) => {
      actions.setLoading({ key: tenantId, loading: true });
      actions.setError({ key: tenantId, error: null });
      try {
        const result = await injections.gridDataService.get<{
          contacts: Contact[];
          totalCount: number;
          totalPages: number;
          hasMore: boolean;
        }>(
          `/v1/${tenantId}/contacts?identifier=${identifier}&page=${page}&limit=${limit}`,
          dataResidency,
        );
        const { contacts, totalPages, hasMore } = result;

        actions.setData({ key: tenantId, data: contacts });

        return { totalCount: result.totalCount, totalPages, hasMore };
      } catch (error) {
        actions.setError({ key: tenantId, error });
        return { totalCount: 0, totalPages: 0, hasMore: false };
      } finally {
        actions.setLoading({ key: tenantId, loading: false });
      }
    },
  ),
  fetchOne: thunk(
    async (actions, { tenantId, contactId, dataResidency }, { injections }) => {
      actions.setLoading({ key: tenantId, loading: true });
      actions.setError({ key: tenantId, error: null });
      try {
        const data = await injections.gridDataService.get<Contact>(
          `/v1/${tenantId}/contacts/${contactId}`,
          dataResidency,
        );
        actions.setSingle({ key: tenantId, data });
      } catch (error) {
        actions.setError({ key: tenantId, error });
      } finally {
        actions.setLoading({ key: tenantId, loading: false });
      }
    },
  ),
  deleteOne: thunk(
    async (actions, { tenantId, contactId, dataResidency }, { injections }) => {
      actions.setLoading({ key: tenantId, loading: true });
      actions.setError({ key: tenantId, error: null });
      try {
        const data = await injections.gridDataService.delete<Contact>(
          `/v1/${tenantId}/contacts/${contactId}`,
          dataResidency,
        );
        actions.setSingle({ key: tenantId, data });
      } catch (error) {
        actions.setError({ key: tenantId, error });
      } finally {
        actions.setLoading({ key: tenantId, loading: false });
      }
    },
  ),
};

export default contactsModel;
