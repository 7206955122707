import { useQuery } from 'react-query';
import { VariantInfo, ProductType } from '@ombori/grid-products';
import { useGridProductsService } from '../grid-products-service-provider';
import { DataResidencyEnum } from '../../../store/types/organisation';

const routes = {
  getVariantsList: (tenantId: string) => ['', tenantId, 'variants'].join('/'),
  getProductTypesList: (tenantId: string, environment: string) =>
    ['', tenantId, environment, 'product-types'].join('/'),
};

interface UseProductListProps {
  tenantId: string;
  productIds: string[];
  dataResidency: DataResidencyEnum;
}

export const useVariantsList = ({
  tenantId,
  productIds,
  dataResidency,
}: UseProductListProps) => {
  const gridProductsService = useGridProductsService();

  const buildQueryKey = () => [
    'variantsList',
    'tenantId',
    tenantId,
    'productIds',
    productIds.join(','),
  ];

  return useQuery(
    buildQueryKey(),
    () =>
      gridProductsService.get<{ data: VariantInfo[] }>(
        routes.getVariantsList(tenantId),
        dataResidency,
        {
          productIds: productIds.join(','),
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      enabled: productIds.length > 0,
    },
  );
};

interface UseProductTypesListProps {
  tenantId: string;
  ids: string[];
  dataResidency: DataResidencyEnum;
}

export const useProductTypesList = ({
  tenantId,
  ids,
  dataResidency,
}: UseProductTypesListProps) => {
  const gridProductsService = useGridProductsService();

  const buildQueryKey = () => [
    'productTypesList',
    'tenantId',
    tenantId,
    'ids',
    ids.join(','),
  ];

  return useQuery(
    buildQueryKey(),
    () =>
      gridProductsService.get<{ data: ProductType[] }>(
        routes.getProductTypesList(tenantId, 'prod'),
        dataResidency,
        {
          ids: ids.join(','),
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      enabled: ids.length > 0,
    },
  );
};
