import { Card, Col, Icon, Row, Tag } from 'antd';
import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DeviceScreenshot from '../../../../../common/device-screenshot/device-screenshot.component';
import PanelCard from '../../../../../common/panel-card/panel-card.component';
import DeviceStatusEnum from '../../../../../../store/types/device-status.enum';
import getDeviceInfo from '../../../../../../utils/get-device-info';
import Device from '../../../../../../store/types/device';
import UwfStateEnum from '../../../../../../store/types/uwf-state.enum';
import DeviceStatus from '../../../../../common/device-status/device-status.component';

interface DevicesListItemProps {
  device: Device;
  lastUpdated?: Date;
}

const DeviceListItemScreenshot = styled(DeviceScreenshot)`
  height: 192px;
  border-radius: 7px 7px 0 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const DeviceCard = styled(PanelCard) <{ status: DeviceStatusEnum }>`
  margin: 8px;
  .ant-card-cover img {
    border-radius: 0;
  }

  .ant-card-actions {
    border-radius: 0 0 7px 7px;
  }
`;

const DeviceDescription = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DeviceEnv = styled.div`
  margin-left: 8px;
  text-transform: uppercase;
  white-space: nowrap;
`;

const EnvIcon = styled(Icon)`
  font-size: 16px;
`;

const DeviceCoverContainer = styled.div`
  position: relative;
`;

const DeviceInfo = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
  right: 24px;
  z-index: 1;
  pointer-events: none;
`;

const DeviceTitle = styled.div`
  height: 45px;
`;

const Hostname = styled.div`
  font-size: 14px;
  white-space: nowrap;
  margin-top: 2px;
  font-weight: 400;
  color: #5e5e5e;
`;

const getTagColor = (percentage: number) => {
  if (percentage > 90) {
    return 'red';
  }
  if (percentage > 85) {
    return 'volcano';
  }
  if (percentage > 80) {
    return 'orange';
  }
  if (percentage > 75) {
    return 'gold';
  }
  if (percentage > 70) {
    return 'lime';
  }
  if (percentage > 65) {
    return 'green';
  }
  if (percentage > 60) {
    return 'cyan';
  }

  return 'blue';
};

const DeviceCover = ({ device }: { device: Device }) => {
  const deviceInfo = getDeviceInfo(device);

  return (
    <DeviceCoverContainer>
      {device.status !== DeviceStatusEnum.OFFLINE && (
        <DeviceInfo>
          {deviceInfo.cpuUsage && (
            <Tag color={getTagColor(deviceInfo.cpuUsage)}>
              {`CPU: ${deviceInfo.cpuUsage}%`}
            </Tag>
          )}
          {deviceInfo.memUsage && (
            <Tag color={getTagColor(deviceInfo.memUsage)}>
              {`MEM: ${deviceInfo.memUsage}%`}
            </Tag>
          )}
        </DeviceInfo>
      )}
      <DeviceListItemScreenshot device={device} />
    </DeviceCoverContainer>
  );
};

const DevicesListItem = ({ device, lastUpdated }: DevicesListItemProps) => {
  const { t } = useTranslation();
  const deviceInfo = getDeviceInfo(device);
  return (
    <DeviceCard status={device.status} cover={<DeviceCover device={device} />}>
      <Card.Meta
        title={
          <DeviceTitle>
            {device.appName
              ? `${device.appName}/${device.deviceName}`
              : device.deviceName}
            <Hostname>
              <Row>
                <Col>
                  {deviceInfo.uwfState === UwfStateEnum.ENABLED && (
                    <Icon type="lock" style={{ marginRight: '4px' }} />
                  )}
                  {device.deviceTags && device.deviceTags.deviceMonitored && (
                    <Icon type="eye" style={{ marginRight: '4px' }} />
                  )}
                  {device.iotedge && (
                    <>
                      <Icon type="apartment" style={{ marginRight: '4px' }} />
                      Grid OS
                    </>
                  )}
                  {deviceInfo.hostname}
                </Col>
              </Row>
            </Hostname>
          </DeviceTitle>
        }
        description={
          <DeviceDescription>
            <DeviceStatus device={device} lastUpdated={lastUpdated} />
            {device.properties &&
              device.properties.desired &&
              device.properties.desired.env && (
                <DeviceEnv title={t('environment')}>
                  <EnvIcon type="cloud" /> {device.properties.desired.env}
                </DeviceEnv>
              )}
          </DeviceDescription>
        }
      />
    </DeviceCard>
  );
};

export default DevicesListItem;
