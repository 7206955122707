import diff from 'microdiff';
import set from 'lodash/set';
import unset from 'lodash/unset';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import validateFormData from 'react-jsonschema-form/lib/validate';
import { AjvError } from 'react-jsonschema-form';
import OrganisationApp from '../../../../../../store/types/organisation-app';

export type Diff = ReturnType<typeof diff>;

export const buildDiff = (data1: any, data2: any) => diff(data1, data2);

type BuildSettingsFromDiffResult = Partial<OrganisationApp['settings']>;

const SYSTEM_REMOVE_FLAG = '_SYSTEM_REMOVED_ARRAY_ELEMENT';
const filterRemovedArrayElementsFromSettings = (settings: Record<string, any>) => {
  const newSettings: any = {};

  for (const key in settings) {
    if (Array.isArray(settings[key])) {
      newSettings[key] = settings[key].filter((item: any) => item !== SYSTEM_REMOVE_FLAG);
    } else if (typeof settings[key] === 'object') {
      newSettings[key] = filterRemovedArrayElementsFromSettings(settings[key]);
    } else {
      newSettings[key] = settings[key];
    }
  }

  return newSettings;
};

export const applyDiff = (
  diffValue: Diff,
  settings: BuildSettingsFromDiffResult,
): BuildSettingsFromDiffResult => {
  const result = diffValue.reduce<BuildSettingsFromDiffResult>((accumulator, item) => {
    switch (item.type) {
      case 'CREATE':
      case 'CHANGE': {
        const path = item.path.join('.');

        const newAccumulator = cloneDeep(accumulator);

        set(newAccumulator, path, item.value);

        return newAccumulator;
      }

      case 'REMOVE': {
        const path = item.path.join('.');

        const newAccumulator = cloneDeep(accumulator);

        const parentPath = path.slice(0, path.length - 1);
        const parentValue = get(newAccumulator, parentPath);
        const lastItemPath = path.slice(-1)[0];

        // If remove an element from array
        if (!isNaN(+lastItemPath) && typeof Array.isArray(parentValue)) {
          set(newAccumulator, path, SYSTEM_REMOVE_FLAG);
        } else {
          unset(newAccumulator, path);
        }

        return newAccumulator;
      }

      default:
        throw new Error(`Unknown diff type: ${item}`);
    }
  }, settings);

  return filterRemovedArrayElementsFromSettings(result);
};

const injectAdditionalPropertiesInSchema = (schema: any): any => {
  if (schema.type === 'object') {
    // Creating a new schema object for 'object' type
    const newProperties = Object.keys(schema.properties || {}).reduce((acc: any, key) => {
      // Recursively apply the function to each property and accumulate the result
      acc[key] = injectAdditionalPropertiesInSchema(schema.properties[key]);
      return acc;
    }, {});

    return {
      ...schema,
      additionalProperties: false,
      properties: newProperties
    };
  } else if (schema.type === 'array') {
    // Handling the 'array' type by applying the function to 'items'
    return {
      ...schema,
      items: injectAdditionalPropertiesInSchema(schema.items)
    };
  }

  // Return the original schema if no modification is needed
  return schema;
};

export const removeAdditionalPropertiesInAppSettings = (app: OrganisationApp, schema: any): any => {
  const updatedApp = cloneDeep(app);
  const settings = get(updatedApp, 'settings.provider.app.gridApp.settings', null);

  if (!settings) {
    return app;
  }

  const schemaWithStrictAdditionalProperties = injectAdditionalPropertiesInSchema(schema);

  const formErrors = (validateFormData(settings, schemaWithStrictAdditionalProperties) as any).errors as AjvError[];
  const additionalPropertyErrors = formErrors.filter((error) => error.name === 'additionalProperties');

  if (additionalPropertyErrors.length > 0) {
    const additionalPropertyErrorPaths = additionalPropertyErrors.map((error) => {
      const result = error.property.replace(/\['([^']+)'\]/g, '.$1');
      const propPath = result.replace(/^\./, '');

      return propPath;
    });

    additionalPropertyErrorPaths.forEach((prop) => {
      unset(settings, prop);
    });

    const updatedSpaceSettingsOverriding: any = {};
    const updatedDeviceSettingsOverriding: any = {};

    Object.keys(app.spaceSettingsOverriding || {}).forEach(spaceId => {
      const spaceSettingsOverride = app.spaceSettingsOverriding
        ? app.spaceSettingsOverriding[spaceId]
          .filter(({ path }: any) => {
            const strPath = path.join('.').replace('provider.app.gridApp.settings.', '');
            return  !additionalPropertyErrorPaths
              .some(errorPath => strPath.indexOf(errorPath) === 0);
          })
        : [];

      if (spaceSettingsOverride.length > 0) {
        updatedSpaceSettingsOverriding[spaceId] = spaceSettingsOverride;
      }
    });

    Object.keys(app.deviceSettingsOverriding || {}).forEach(deviceId => {
      const deviceSettingsOverride = app.deviceSettingsOverriding
        ? app.deviceSettingsOverriding[deviceId]
          .filter(({ path }: any) => {
            const strPath = path.join('.').replace('provider.app.gridApp.settings.', '');
            return !additionalPropertyErrorPaths
              .some(errorPath => strPath.indexOf(errorPath) === 0);
          })
        : [];

      if (deviceSettingsOverride.length > 0) {
        updatedDeviceSettingsOverriding[deviceId] = deviceSettingsOverride;
      }
    });

    set(updatedApp, 'settings.provider.app.gridApp.settings', settings);
    updatedApp.spaceSettingsOverriding = updatedSpaceSettingsOverriding;
    updatedApp.deviceSettingsOverriding = updatedDeviceSettingsOverriding;

    return updatedApp;
  }

  return app;
}
