import React, { useMemo } from 'react';
import {
  WeekHeatmapProps,
  WeekHeatmapSessionsProps,
  WeekHeatmapEventsProps,
} from './types';
import CardContainer from '../card-container';
import WeekHeatmap from '../../charts/week-heatmap';
import { useAppSessionsByHour, useAppEventsByHour } from '../../../use-analytics-report';
import { buildSessionsData, buildEventsData } from './generate';
import { WeekHeatmapDataSourceType } from '@ombori/grid-reports';

interface AppWeekHeatmapBySessionsProps extends WeekHeatmapSessionsProps {
  appId: string;
}

const AppWeekHeatmapBySessions: React.FC<AppWeekHeatmapBySessionsProps> = ({
  tenantId,
  appId,
  dateFrom,
  dateTo,
  dataResidency,
  title,
  dataSource,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const appSessionsState = useAppSessionsByHour({
    tenantId,
    appId,
    dateFrom,
    dateTo,
    dataResidency,
    interactionType: dataSource.interactionType,
  });

  const data = useMemo(() => buildSessionsData(appSessionsState.data || []), [
    appSessionsState,
  ]);

  return (
    <CardContainer
      isLoading={appSessionsState.isLoading}
      isSuccess={appSessionsState.isSuccess}
      isError={appSessionsState.isError}
      hasData={appSessionsState.isSuccess && appSessionsState.data.length > 0}
      isVisibleWithoutData={isVisibleWithoutData}
      title={title}
      gridStyles={gridStyles}
    >
      <WeekHeatmap data={data} />
    </CardContainer>
  );
};

interface AppWeekHeatmapByEventsProps extends WeekHeatmapEventsProps {
  appId: string;
}

const AppWeekHeatmapByEvents: React.FC<AppWeekHeatmapByEventsProps> = ({
  tenantId,
  appId,
  dateFrom,
  dateTo,
  dataResidency,
  title,
  dataSource,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const appEventsState = useAppEventsByHour({
    tenantId,
    appId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const data = useMemo(
    () => buildEventsData(appEventsState.data || [], dataSource.eventType),
    [appEventsState, dataSource],
  );

  return (
    <CardContainer
      isLoading={appEventsState.isLoading}
      isSuccess={appEventsState.isSuccess}
      isError={appEventsState.isError}
      hasData={appEventsState.isSuccess && appEventsState.data.length > 0}
      isVisibleWithoutData={isVisibleWithoutData}
      title={title}
      gridStyles={gridStyles}
    >
      <WeekHeatmap data={data} />
    </CardContainer>
  );
};

interface AppWeekHeatmapProps extends WeekHeatmapProps {
  appId: string;
}

const AppWeekHeatmap: React.FC<AppWeekHeatmapProps> = ({
  tenantId,
  appId,
  dateFrom,
  dateTo,
  title,
  dataResidency,
  dataSource,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const view = useMemo(() => {
    switch (dataSource.type) {
      case WeekHeatmapDataSourceType.Sessions:
        return (
          <AppWeekHeatmapBySessions
            tenantId={tenantId}
            appId={appId}
            dateFrom={dateFrom}
            dateTo={dateTo}
            title={title}
            dataResidency={dataResidency}
            dataSource={dataSource}
            gridStyles={gridStyles}
            isVisibleWithoutData={isVisibleWithoutData}
          />
        );
      case WeekHeatmapDataSourceType.Events:
        return (
          <AppWeekHeatmapByEvents
            tenantId={tenantId}
            appId={appId}
            dateFrom={dateFrom}
            dateTo={dateTo}
            title={title}
            dataResidency={dataResidency}
            dataSource={dataSource}
            gridStyles={gridStyles}
            isVisibleWithoutData={isVisibleWithoutData}
          />
        );
      default:
        throw new Error(`Unknown data source: ${JSON.stringify(dataSource)}`);
    }
  }, [
    tenantId,
    appId,
    dateFrom,
    dateTo,
    title,
    dataResidency,
    dataSource,
    gridStyles,
    isVisibleWithoutData,
  ]);

  return <>{view}</>;
};

export default AppWeekHeatmap;
