import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import { RouteComponentProps } from 'react-router';
import { RootModel, RootState } from '../../../../../../store/models/root.model';
import { getPermissions } from '../../../../../../utils/auth/permissions';
import Settings from './settings.component';

type SettingsContainerProps = RouteComponentProps<{
  deviceUuid: string;
  organisationId: string;
}>;

const SettingsContainer = (props: SettingsContainerProps) => {
  const {
    match: {
      params: { organisationId, deviceUuid },
    },
  } = props;

  const { device, user, permissions } = useStore<RootState>(
    (state) => ({
      device: state.azureDeviceDetails.data[deviceUuid] || null,
      user: state.auth.user,
      permissions: getPermissions(state.auth.user, organisationId),
    }),
    [deviceUuid, organisationId],
  );

  const { updateDevice } = useActions<RootModel>((actions) => ({
    updateDevice: actions.azureDeviceDetails.update,
  }));

  return (
    <Settings
      {...props}
      user={user}
      updateDevice={updateDevice}
      device={device}
      canManageDeviceSettings={permissions.devices.manageDeviceSettings}
    />
  );
};

export default SettingsContainer;
