import React, { useEffect, useMemo } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import OrganisationDetailsContainer from './organisation-details/organisation-details.container';
import SubscriptionSetupContainer from './organisation-details/setup-subscription/setup-subscription.container';
import Organisation from '../../store/types/organisation';
import OrganisationDeviceSetup from './organisation-details/organisation-device-setup/organisation-device-setup.container';
import TenantAwareRoute from '../common/subscription-aware-route/tenant-aware-route.component';

interface OrganisationsProps extends RouteComponentProps {
  organisations: Organisation[];
  loading: boolean;
  fetchOrganisations: () => void;
  setupTenantForExistingUser: () => void;
}

const Organisations = (props: OrganisationsProps) => {
  const { match, organisations, setupTenantForExistingUser } = props;

  const defaultOrganisation = useMemo(() => {
    const activeOrganisation = organisations.find(
      (organisation) => organisation.hasActiveSubscription && organisation.enabled,
    );

    return activeOrganisation || organisations[0];
  }, [organisations]);

  useEffect(() => {
    if (!defaultOrganisation) {
      setupTenantForExistingUser();
    }
  }, [defaultOrganisation, setupTenantForExistingUser]);

  if (!defaultOrganisation) {
    // above use effect redirects to tenant setup page through api if no default org is present
    return null;
  }

  return (
    <Switch>
      <Route
        exact
        path={match.path}
        render={() => <Redirect to={`/organisations/${defaultOrganisation.id}`} />}
      />
      <Route
        path={`${match.path}/:organisationId/subscription`}
        component={SubscriptionSetupContainer}
      />
      <TenantAwareRoute
        path={`${match.path}/:organisationId/device-setup`}
        component={OrganisationDeviceSetup}
      />
      <TenantAwareRoute
        path={`${match.path}/:organisationId`}
        component={OrganisationDetailsContainer}
      />
    </Switch>
  );
};

export default Organisations;
