import * as React from 'react';
import { Select as SelectSearch } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Field } from 'react-final-form';
import Spinner from '../spinner/spinner.component';
import User from '../../../store/types/user';
import {
  Label,
  RowStyled,
} from '../../organisations/organisation-details/queue-details/queue-setup/styled-block';
import SelectMultipleAdapter from '../../organisations/organisation-details/queue-details/queue-setup/select-multiple-adapter';

interface Props {
  fetchState: string;
  users: User[];
  fieldName: string;
  fieldLabel: string;
  placeholder?: string;
  error?: string;
  required?: boolean;
}

const getEmailOptions = (users: User[]): JSX.Element[] => {
  return users
    .map((user: User) => user.email)
    .map((email: string) => (
      <SelectSearch.Option key={email} value={email}>
        {email}
      </SelectSearch.Option>
    ));
};

const EmailListPicker: React.FC<Props> = ({
  fetchState,
  users,
  fieldName,
  fieldLabel,
  placeholder = '',
  error,
  required = false,
}) => {
  const { t } = useTranslation();

  if (fetchState === 'requested') {
    return <Spinner size={20} />;
  }

  if (fetchState === 'failed') {
    // TO DO: Add Reload/refetch mechanism here
    return <Warning>{error || t('errorDuringFetchingEmails')}</Warning>;
  }

  if (fetchState === 'finished') {
    return (
      <Field name={fieldName}>
        {({ input, meta }) => {
          return (
            <RowStyled>
              <Label>{fieldLabel}</Label>
              {required && <span className="required">*</span>}
              <SelectMultipleAdapter
                options={getEmailOptions(users)}
                input={input}
                meta={meta}
                placeholder={placeholder}
              />
            </RowStyled>
          );
        }}
      </Field>
    );
  }

  return <></>;
};

const Warning = styled.div`
  padding: 15px 0;
  color: rgb(255, 85, 0);
`;

export default EmailListPicker;
