import { useQuery } from 'react-query';
import { useApiService } from '../api-service-provider';
import OrganisationApp from '../../../store/types/organisation-app';

const useGridappInstallationInstances = (gridappId: string) => {
  const apiService = useApiService();

  return useQuery(
    ['gridappId'],
    () =>
      apiService.get<OrganisationApp[]>(`/api/gridapps/${gridappId}/installations`),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export default useGridappInstallationInstances;
