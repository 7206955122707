import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Badge } from 'antd';
import get from 'lodash/get';
import DeviceStatusEnum from '../../../store/types/device-status.enum';
import Device from '../../../store/types/device';
import safeRelativeTime from '../../../utils/date/safe-relative-time';
import UniversalDevice from '../../../store/types/universal-device';
import LegacyWindowsDevice from '../../../store/types/legacy-windows-device';

type BadgeSize = 'small' | 'large';

interface DeviceStatusProps {
  device: Device | UniversalDevice | LegacyWindowsDevice;
  lastUpdated?: Date;
  size?: BadgeSize;
}

const statusText: { [status: string]: string } = {
  [DeviceStatusEnum.OK]: 'Online',
  [DeviceStatusEnum.FAILING]: 'Failing',
  [DeviceStatusEnum.OFFLINE]: 'Offline',
};

const StatusBadge = styled(Badge)`
  margin-left: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  .ant-badge-status-dot {
    ${(props: any) =>
    props.size === 'large' &&
    `width: 14px;
        height: 14px;
        top: -2px;`}
  }
  .ant-badge-status-text {
    ${(props: any) => props.size === 'large' && `font-size: 16px;`}
  }
`;

export const getBadgeText = (
  device: Device | UniversalDevice | LegacyWindowsDevice,
  lastUpdated?: Date,
) => {
  let text = statusText[device.status] || 'Unknown';
  let then;
  let now;

  // Check first if device follows the new architecture "parentDevice.type"
  // Otherwise, fallback to "type"
  let isIotHub;
  const type = get(device, 'parentDevice.type');
  if (type === undefined) {
    isIotHub = get(device, 'type') === 'iothub';
  } else {
    isIotHub = type === 'iothub';
  }

  if (isIotHub) {
    now = lastUpdated;
  } else {
    now = new Date();
  }
  then = device.screenshotUpdated;

  if (now && then) {
    const thenDayjs = dayjs(then);
    const nowDayjs = dayjs(now);
    if (thenDayjs.isValid() && nowDayjs.isValid()) {
      if (nowDayjs.isAfter(thenDayjs)) {
        const relativeTime = safeRelativeTime(thenDayjs, nowDayjs);
        if (relativeTime.length) {
          text += ` (${relativeTime})`;
        }
      } else {
        text += ` (now)`;
      }
    }
  }

  return text;
};

export const getBadgeProps = (deviceStatus: DeviceStatusEnum) => {
  let props: any = {};
  switch (deviceStatus) {
    case DeviceStatusEnum.OFFLINE: {
      props = { status: 'error' };
      break;
    }
    case DeviceStatusEnum.FAILING: {
      props = { status: 'warning' };
      break;
    }
    case DeviceStatusEnum.OK: {
      props = { color: 'green', status: 'processing' };
      break;
    }
    default: {
      props = { status: 'default' };
      break;
    }
  }
  return props;
};

const DeviceStatus = ({ device, lastUpdated, size }: DeviceStatusProps) => {
  const badgeText = useMemo(() => {
    return getBadgeText(device, lastUpdated);
  }, [device, lastUpdated]);

  const badgeProps = useMemo(() => {
    return getBadgeProps(device.status);
  }, [device.status]);

  return <StatusBadge {...badgeProps} text={badgeText} size={size} />;
};

DeviceStatus.defaultProps = {
  size: 'small',
};

export default DeviceStatus;
