import Organisation from '../store/types/organisation';
import OrganisationApp from '../store/types/organisation-app';
import DeviceTypeEnum from '../store/types/device-type.enum';

const getAppsByOrganisationName = (
  organisations: Organisation[],
  apps: { [organisationId: string]: { [appId: string]: OrganisationApp } },
  deviceType?: DeviceTypeEnum,
) => {
  return organisations.reduce((result, organisation) => {
    const organisationApps = deviceType
      ? Object.values(apps[organisation.id] || {}).filter(
          (app) => app.deviceType !== deviceType,
        )
      : Object.values(apps[organisation.id] || {});

    return organisationApps.length
      ? {
          ...result,
          [organisation.displayName]: organisationApps,
        }
      : result;
  }, {});
};

export default getAppsByOrganisationName;
