import { Action, action, thunk, Thunk } from 'easy-peasy';
import * as microsoftTeams from '@microsoft/teams-js';
import { RootModel } from '../root.model';
import Injections from '../../injections.interface';
import getAuthUrl from '../../../utils/auth/get-auth-url';

export interface TeamsModel {
  isInit: boolean;
  setIsInit: Action<TeamsModel, boolean>;
  init: Thunk<TeamsModel, void, Injections, RootModel>;
  login: Thunk<TeamsModel>;
  teamsLoginStart: Thunk<TeamsModel>;
  teamsLoginSuccess: Thunk<TeamsModel>;
}

const teamsModel: TeamsModel = {
  isInit: false,
  setIsInit: action((state) => {
    state.isInit = true;
  }),
  init: thunk(async (actions) => {
    microsoftTeams.initialize(() => {
      actions.setIsInit(true);
    });
    // in development outside of Teams success callback is not called
    if (process.env.NODE_ENV !== 'production') {
      actions.setIsInit(true);
    }
  }),
  login: thunk(() => {
    microsoftTeams.authentication.authenticate({
      url: `${document.location.protocol}//${document.location.host}/teams/login/start`,
      width: 600,
      height: 535,
      successCallback: () => {
        window.location.reload();
      },
      failureCallback: () => {
        window.location.reload();
      },
    });
  }),
  teamsLoginStart: thunk(() => {
    window.location.href = getAuthUrl('/teams/login/callback');
  }),
  teamsLoginSuccess: thunk(() => {
    microsoftTeams.authentication.notifySuccess();
  }),
};

export default teamsModel;
