import { useQuery } from 'react-query';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import qs from 'query-string';
import { useApiService } from '../api-service-provider';

import transformDeviceExpectedAvailability from '../../../utils/transform-device-expected-availability';
import {
  PaginationCollection,
  PaginationSearchParam,
} from '../../../store/types/pagination';
import UniversalDevice from '../../../store/types/universal-device';

const transformDevice = (data: any) => {
  const result = transformDeviceExpectedAvailability(data) as UniversalDevice;

  return result;
};

const queryKeys = {
  getDevice: (deviceUUID: string) => [
    'device',
    deviceUUID,
  ]
}

const useDevice = (deviceUuid: string) => {
  const apiService = useApiService();

  return useQuery(
    queryKeys.getDevice(deviceUuid),
    () =>
      apiService
        .get<UniversalDevice>(`/api/v3/devices/${deviceUuid}`)
        .then(transformDevice),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      enabled: deviceUuid.length > 0,
    },
  );
};

interface UseInstallationByAppNameDevicesProps {
  appName: string;
  searchParam?: PaginationSearchParam;
  page?: number;
  pageSize?: number;
}

export const useInstallationByAppNameDevices = ({
  appName,
  searchParam
}: UseInstallationByAppNameDevicesProps) => {
  const apiService = useApiService();

  const updatedSearchParam = omitBy(searchParam, isEmpty);
  const searchQueryParam =
    updatedSearchParam && Object.keys(updatedSearchParam).length
      ? `&${qs.stringify(updatedSearchParam, { arrayFormat: 'comma' })}`
      : '';

  const url = `/api/v4/devices?appName=${appName}${searchQueryParam}`;

  return useQuery(
    ['devices', 'appName', appName],
    () => apiService.get<UniversalDevice[]>(url),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseInstallationByIdDevicesProps {
  organisationId: string;
  installationId: string;
  isEnabled?: boolean;
}

export const useInstallationByIdDevices = ({
  organisationId,
  installationId,
  isEnabled = true,
}: UseInstallationByIdDevicesProps) => {
  const apiService = useApiService();

  const url = `/api/v4/devices?installationId=${installationId}`;

  return useQuery(
    ['installationDevices', organisationId, installationId],
    () => apiService.get<UniversalDevice[]>(url),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      enabled: isEnabled,
    },
  );
};

interface UseTenantDevicesProps {
  tenantId: string;
  select?: (devices: UniversalDevice[]) => UniversalDevice[];
  isEnabled?: boolean;
}

export const useTenantDevices = ({
  tenantId,
  select,
  isEnabled = true,
}: UseTenantDevicesProps) => {
  const apiService = useApiService();

  const url = `/api/v4/devices?organizationId=${tenantId}`;

  return useQuery(
    ['allTenantDevices', tenantId],
    () => apiService.get<UniversalDevice[]>(url),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      select,
      enabled: isEnabled,
    },
  );
};

interface UseSpaceDevicesProps {
  organisationId: string;
  spaceId: string;
  isEnabled?: boolean;
}

export const useSpaceDevices = ({
  organisationId,
  spaceId,
  isEnabled = true,
}: UseSpaceDevicesProps) => {
  const apiService = useApiService();

  const url = `/api/v4/devices?organizationId=${organisationId}&spaceId=${spaceId}`;

  return useQuery(
    ['spaceDevices', organisationId, spaceId],
    () => apiService.get<UniversalDevice[]>(url),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      enabled: isEnabled,
    },
  );
};

export default useDevice;
