import { useCallback } from 'react';
import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { useApiService } from '../api-service-provider';
import queryKeys from './build-query-key';
import { ApiError } from '../../../services/api/api-error';

interface UseDeviceBundleDeleteProps {
  canInvalidateQueries?: boolean;
}

type UseDeviceBundleDeleteResponse = UseMutationResult<{}, ApiError, string> & {
  invalidateQueries: () => Promise<void>;
};

const useDeviceBundleDelete = (
  props?: UseDeviceBundleDeleteProps,
): UseDeviceBundleDeleteResponse => {
  const { canInvalidateQueries = false } = props || {};

  const apiService = useApiService();
  const queryClient = useQueryClient();

  const result = useMutation(
    async (id: string) => {
      const endpoint = `/api/device-bundle/${id}`;

      return apiService.delete<{}>(endpoint);
    },
    {
      onSuccess: (_data: {}, _id: string) => {
        if (canInvalidateQueries) {
          invalidateQueries();
        }
      },
      onError: (_error: ApiError) => {},
    },
  );

  const invalidateQueries = useCallback(async (): Promise<void> => {
    const key = queryKeys.getDeviceBundles();

    await queryClient.invalidateQueries(key[0] as string);
  }, [queryClient]);

  return { ...result, invalidateQueries };
};

export default useDeviceBundleDelete;
