import axios, { AxiosError, AxiosInstance } from 'axios';
import { ApiError } from '../api-error';
import onInterceptorsReject from '../utils';
import { getApiUrl, getEnv, Env } from '../../../utils/env';

class RndAI {
  private readonly axiosInstance: AxiosInstance;

  public constructor() {
    const rootUrl = getApiUrl();
    const env = getEnv();
  
    let baseUrl = process.env.REACT_APP_LOCAL_GRID_RND_AI_API_URL;

    switch (env) {
      case Env.LOCAL:
        baseUrl = process.env.REACT_APP_LOCAL_GRID_RND_AI_API_URL;
        break;
      case Env.PROD:
        baseUrl = `${rootUrl}/regions/eu/rndai/v1`;
        break;
      default:
        baseUrl = `${rootUrl}/regions/${env}/rndai/v1`;
    }
  
    this.axiosInstance = axios.create({
      baseURL: baseUrl,
    });
  }

  public setInterceptors = () => {
    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return onInterceptorsReject(err);
      },
    );
  };

  public async get<T>(
    path: string,
    params?: { [key: string]: string | number | undefined },
  ): Promise<T> {
    try {
      const result = await this.axiosInstance.get(path, {
        params,
        withCredentials: true,
      });
      return result.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  public async put<T>(
    path: string,
    body: any,
    params?: { [key: string]: string },
  ): Promise<T> {
    try {
      const result = await this.axiosInstance.put(path, body, {
        params,
        withCredentials: true,
      });

      return result.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  public async post<T>(
    path: string,
    body?: any,
    params?: { [key: string]: string },
  ): Promise<T> {
    try {
      const result = await this.axiosInstance.post(path, body, {
        params,
        withCredentials: true,
      });

      return result.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  public async delete<T>(
    path: string,
    params?: { [key: string]: string },
    data?: any,
  ): Promise<T> {
    try {
      const result = await this.axiosInstance.delete(path, {
        params,
        data,
        withCredentials: true,
      });

      return result.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  private handleError = (error: AxiosError): never => {
    throw new ApiError(
      error.message,
      error.response
        ? { statusCode: error.response.status, data: error.response.data }
        : undefined,
    );
  };
}

export default RndAI;
