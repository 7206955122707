import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'antd';
import styled from '@emotion/styled';

const UserFormFields = () => {
  const { t } = useTranslation();

  return (
    <TitleContainer>
      <IconContainer>
        <UserIconContainer>
          <UserIcon type="user" />
        </UserIconContainer>
      </IconContainer>
      <Title>{t('userManagement.userSettings')}</Title>
    </TitleContainer>
  );
};

const TitleContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 12px;
  padding: 10px;
  margin-left: 22px;
`;

const IconContainer = styled.div`
  width: 56px;
  height: 56px;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  place-content: center;
  align-items: center;
  gap: 0px;
  background-color: rgb(32, 32, 32);
  opacity: 1;
  border-radius: 8px;
`;

const UserIconContainer = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  flex: 0 0 auto;
  color: #fff;
`;

const UserIcon = styled(Icon)`
  width: 100%;
  cursor: auto;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const Title = styled.div`
  place-content: center flex-start;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  color: #111;
  font-size: 24px;
  letter-spacing: 0px;
  text-transform: none;
  text-decoration: none;
  line-height: 1em;
`;

export default UserFormFields;
