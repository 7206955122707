import React from 'react';
import styled from '@emotion/styled';
import { Typography, Tabs } from 'antd';
import Media from '../../../../../store/types/media';
import CardContainer from '../card-container';

export interface ImageCardProps {
  title: string;
  isLoading: boolean;
  isError: boolean;
  images: Media[] | null;
  isVisibleWithoutData: boolean;
}

const ImageCard = (props: ImageCardProps) => {
  const { title, isLoading, isError, images, isVisibleWithoutData } = props;

  const hasImages = images && images.length > 0 ? true : false;

  return (
    <>
      <CardContainer
        isLoading={isLoading}
        isError={isError}
        isSuccess={true}
        hasData={hasImages}
        isVisibleWithoutData={isVisibleWithoutData}
        title={
          <CardTitleContainer>
            <Typography.Text>{title}</Typography.Text>
          </CardTitleContainer>
        }
      >
        {hasImages && (
          <ImageContainer>
            <Tabs defaultActiveKey="0" animated={false}>
              {images!.map((image) => {
                return (
                  <Tabs.TabPane tab={image.name} key={image.name}>
                    <img alt={image.name} src={image.url} />
                  </Tabs.TabPane>
                )
              })}
            </Tabs>
          </ImageContainer>
        )}
      </CardContainer>
    </>
  );
};

export default ImageCard;

const ImageContainer = styled.div``;

const CardTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
