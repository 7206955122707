import React from 'react';
import { Icon, Tag } from 'antd';
import styled from '@emotion/styled';

interface TagsListProps {
  tags: string[];
  onTagRemove?: (tags: string) => void;
  onTagAdd?: () => void;
  onTagEdit?: (tag: string) => void;
  readOnly?: boolean;
}

const TagsListItemWrapper = styled.div`
  display: inline-flex;
`;

const TagsListItemBase = styled(Tag)`
  align-items: center;
  padding: 8px 12px;
  font-size: 14px;
  margin-top: 8px;
  .anticon-close {
    font-size: 14px !important;
    padding: 8px;
    margin: -8px -8px -8px 0;
  }
`;

const TagsListItem = styled(TagsListItemBase)<{ editable: boolean }>`
  cursor: ${({ editable }) => (editable ? 'pointer' : undefined)};
`;

const TagsListAddItem = styled(TagsListItemBase)`
  border-style: dashed;
  background: #fff;
  cursor: pointer;
`;

const TagsList = ({
  tags,
  onTagRemove,
  onTagAdd,
  onTagEdit,
  readOnly,
}: TagsListProps) => {
  const handleTagClose = (event: Event, tag: string) => {
    event.stopPropagation();
    if (onTagRemove) {
      onTagRemove(tag);
    }
  };

  return (
    <>
      {tags.map((tag) => (
        <TagsListItemWrapper
          onClick={!readOnly && onTagEdit ? () => onTagEdit(tag) : undefined}
          role="button"
          key={tag}
        >
          <TagsListItem
            editable={!readOnly && !!onTagEdit}
            closable={!readOnly}
            onClose={onTagRemove && ((ev: Event) => handleTagClose(ev, tag))}
          >
            {tag}
          </TagsListItem>
        </TagsListItemWrapper>
      ))}
      {!readOnly && (
        <TagsListAddItem onClick={onTagAdd}>
          <Icon type="plus" /> New Tag
        </TagsListAddItem>
      )}
    </>
  );
};

export default TagsList;
