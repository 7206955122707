import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import EnterpriseAgreementContainer from './enterprise-agreement-list/enterprise-agreement-list.container';
import ProtectedRouteContainer from '../common/protected-route/protected-route.container';
import WithSalesMenu from './with-sales-menu.component';
import { getPermissionPath, permissionKeys } from '../../utils/auth/permissions';
import DeviceMonitoring from '../grid/device-monitoring/device-monitoring';

const SalesTools = (props: RouteComponentProps) => {
  const { match } = props;
  return (
    <WithSalesMenu {...props}>
      <Switch>
        <Route
          exact
          path={match.path}
          render={() => <Redirect to={`${match.path}/enterprise-agreements`} />}
        />
        <ProtectedRouteContainer
          permissionPath={getPermissionPath('grid', permissionKeys.grid.manageSales)}
          path={`${match.path}/enterprise-agreements`}
          component={EnterpriseAgreementContainer}
        />
        <ProtectedRouteContainer
          permissionPath={getPermissionPath('grid', permissionKeys.grid.manageSales)}
          path={`${match.path}/monitoring`}
          component={DeviceMonitoring}
        />
      </Switch>
    </WithSalesMenu>
  );
};

export default SalesTools;
