import React from 'react';
import styled from '@emotion/styled';
import { Menu } from 'antd';

interface MenuData {
  key: string;
  className?: string;
  enabled: boolean;
  title: string | JSX.Element;
  content?: JSX.Element;
  children?: MenuData[];
};

interface NestedMenuProps {
  data: MenuData[];
  menuProps: React.ComponentProps<typeof Menu>;
  emptyListTitle: string;
}

const NestedMenu: React.FC<NestedMenuProps> = ({ emptyListTitle, data, menuProps }) => {

  const renderMenu = (menu: MenuData) => {
    if (menu.children) {
      return (
        <SubMenuStyled
          key={menu.key}
          theme="dark"
          className={menu.className}
          title={menu.title}
          enabled={menu.enabled === true ? 'true' : 'false'}
        >
          {menu.children.map(renderMenu)}
        </SubMenuStyled>
      );
    }

    return <MenuItemStyled key={menu.key}>{menu.content}</MenuItemStyled>;
  }

  return (
    <Menu {...menuProps} mode="inline">
      {!data.length ? <EmptyList>{emptyListTitle}</EmptyList> : data.map(renderMenu)}
    </Menu>
  );
}

const EmptyList = styled(Menu.Item)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  cursor: default;
  margin-top: 20px !important;

  &:hover {
    color: rgba(255, 255, 255, 0.65) !important;
  }
`;

const MenuItemStyled = styled(Menu.Item)`
  padding-left: 32px !important;
  white-space: normal;
  height: fit-content !important;
`;

const SubMenuStyled = styled(Menu.SubMenu)`
  &.${({ className }) => className} [role='menuitem'] > a {
    > img {
      width: 28px;
      height: 28px;
      margin: auto 10px auto -15px;
      vertical-align: middle;
    }
    > i {
      position: relative;
      right: -11px;
      vertical-align: middle;
    }
  }
  > .ant-menu-submenu-title {
    font-weight: 500 !important;
    margin-top: 10px;
    height: 100% !important;
  }
}
` as any;

export default NestedMenu;
