import * as React from 'react';
import { Icon, Tooltip, Button } from 'antd';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { get } from 'lodash';
import Build from '../../../store/types/build';
import Pipeline from '../../../store/types/pipeline';
import Deploy from '../../../store/types/deploy';
import safeRelativeTime from '../../../utils/date/safe-relative-time';

interface BuildStatusProps {
  build: Build;
  isSysAdmin: boolean;
}

/* eslint-disable */
const statusColors: { [status: string]: string } = {
  failed: 'red',
  deploy_failed: 'red',
  error: 'red',

  deploy_waiting: 'geekblue',
  waiting: 'geekblue',

  scaffolding: 'orange',
  gradlew: 'orange',
  produce: 'orange',
  match: 'orange',
  gym: 'orange',
  building: 'orange',

  deploying: 'lime',
  upload: 'lime',

  built: 'green',
  deployed: 'green',
  done: 'green',
};
/* eslint-enable */

/* eslint-disable */
const statusIcons: { [status: string]: string } = {
  failed: 'close-circle',
  error: 'close-circle',
  deploy_failed: 'close-circle',

  deploy_waiting: 'loading',
  waiting: 'loading',

  scaffolding: 'loading',
  gradlew: 'loading',
  produce: 'loading',
  match: 'loading',
  gym: 'loading',
  building: 'loading',

  deploying: 'loading',
  upload: 'loading',

  built: 'check-circle',
  deployed: 'check-circle',
  done: 'check-circle',
};
/* eslint-enable */

const BuildInfoRow = styled.span`
  padding: 0 6px;
`;

const BuildStatus = ({ build, isSysAdmin }: BuildStatusProps) => {
  const getTitle = (item: Pipeline | Deploy) => {
    let title;

    if (item.data && item.data.queueTime) {
      const { queueTime } = item.data;
      const queueDate = dayjs(queueTime);
      if (queueDate.isValid()) {
        const relativeTime = safeRelativeTime(queueDate);
        if (relativeTime.length) {
          title = `${queueDate.format('YYYY-MM-DD HH:mm')} - ${relativeTime}`;
        }
      }
    }
    return title;
  };

  const renderItem = (
    item: Pipeline | Deploy,
    index: number,
    children: React.ReactNode,
  ) => {


    const wrappedChildren = isSysAdmin ? (
      <a rel="noopener noreferrer" target="_blank" href={item.url}>
        {children}
      </a>
    ) : (
      children
    );

    return (
      <span key={index} title={getTitle(item)}>
        {wrappedChildren}
      </span>
    );
  };

  return (
    <BuildInfoRow>
      {(build.pipelines.length + build.deploys.length === 0) && (
        <Icon theme="filled" style={{ color: statusColors.waiting, marginRight: 4 }} type={statusIcons.waiting} />
      )}
      {build.pipelines.map((pipeline, index) =>
        renderItem(
          pipeline,
          index,
          <Tooltip title={`Build: ${  pipeline.status}`}>
            <Icon theme="filled" style={{ color: statusColors[pipeline.status], marginRight: 4 }} type={statusIcons[pipeline.status]} />
          </Tooltip>,
        ),
      )}
      {build.deploys.map((deploy, index) =>
        renderItem(
          deploy,
          index,
          <Tooltip title={`Deploy: ${  deploy.status}`}>
            <Icon theme="filled" style={{ color: statusColors[deploy.status], marginRight: 4 }} type={statusIcons[deploy.status]} />
          </Tooltip>,
        ),
      )}
      {build.pipelines.map((item) => {
        let appUrl = null;
        if (item.type === 'wpa' || item.type === 'mobilewpa') {
          appUrl = get(item, 'metadata.url', null) as string | null;
        }
        return appUrl ? (
          <a rel="noopener noreferrer" target="_blank" href={appUrl} key={appUrl}>
            <Button size="small" type="link" color="darkblue">
              <Icon type="link" />
            </Button>
          </a>
        ) : null;
      })}
    </BuildInfoRow>
  );
};

export default BuildStatus;
