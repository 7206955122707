import { useMutation, useQueryClient } from 'react-query';
import { useApiService } from '../../../app/common/api-service-provider';
import tagQueryKeys from './tag-query-keys';
import ContentTagBody from './types';
import ContentTag from '../../types/content-tag';

const useCreateTag = (tenantId: string) => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  return useMutation(
    (tagBody: ContentTagBody) => {
      return apiService.post<ContentTag>('/api/content-tag', tagBody, { tenantId });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(tagQueryKeys.tenantTags(tenantId));
      },
    },
  );
};

export default useCreateTag;
