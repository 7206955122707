import { useMemo } from 'react';
import { useStore } from 'easy-peasy';
import { RootState } from '../../models/root.model';
import OrganisationSpace from '../../types/organisation-space';
import Organisation from '../../types/organisation';

export interface SpacesAccessInterface {
  hasAccessToAllSpaces?: boolean | undefined;
  spacesAccess?: OrganisationSpace[] | string[];
}

interface OrganisationWithRole extends SpacesAccessInterface {
  organizationId: string;
  roleId: string;
}

const useUserSpacePermissions = (tenantId: string): SpacesAccessInterface => {

  const {
    organisationsList,
    user,
  } = useStore<RootState>(
    ({ organisations, auth }) => ({
      organisationsList: organisations.values,
      user: auth.user,
    }),
    [tenantId],
  );

  return useMemo(() => {
    if (user) {
      if (user.isSysadmin) {
        return {
          hasAccessToAllSpaces: true,
          spacesAccess: []
        };
      }

      const tenantRole = user.organizationsWithRole.find(
        (orgWithRole: OrganisationWithRole) => orgWithRole.organizationId === tenantId
      );

      if (tenantRole) {
        return {
          hasAccessToAllSpaces: !!tenantRole.hasAccessToAllSpaces,
          spacesAccess: tenantRole.spacesAccess
        };
      }

      // check if has access to all spaces in parent tenant
      const childTenant = organisationsList.find((organisation: Organisation) => organisation.id === tenantId);
      if (childTenant && childTenant.parentOrganizationId) {
        const parentTenantRole = user.organizationsWithRole.find(
          (orgWithRole: OrganisationWithRole) => orgWithRole.organizationId === childTenant.parentOrganizationId
        );

        if (parentTenantRole) {
          return {
            hasAccessToAllSpaces: !!parentTenantRole.hasAccessToAllSpaces,
            spacesAccess: []
          };
        }
      }

      // check in last to handle the case where when creating the user, no tenant is assigned
      // the user will have isSales true by default
      if (user.isSales) {
        return {
          hasAccessToAllSpaces: true,
          spacesAccess: []
        };
      }
    }

    return {
      hasAccessToAllSpaces: false,
      spacesAccess: []
    };
  }, [user, tenantId, organisationsList]);
};

export default useUserSpacePermissions;
