import React from 'react';
import styled from '@emotion/styled';
import { useQueryString } from '../use-query-string-param/use-query-string-param';
import ErrorView from '../error-view/error-view.component';

const ErrorPage = () => {
  const queryStringObject = useQueryString();
  const params = {
    title: queryStringObject.title as string,
    errorKeyOrMessage: queryStringObject.msg as string,
    incidentId: queryStringObject.id as string,
    fillPage: (queryStringObject.fillPage as string) === 'true',
  };

  return (
    <Container>
      <ErrorView
        title={params.title}
        error={{ message: params.errorKeyOrMessage, incidentId: params.incidentId }}
        fillPage={params.fillPage}
      />
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
`;

export default ErrorPage;
