import React, { useMemo, useState } from 'react';
import { Alert, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { AlertProps } from 'antd/lib/alert';
import { SegmentConditions } from '../../../../../store/hooks/segments/types';
import useSegmentContentTargetingDevices from '../../../../../store/hooks/segments/use-segment-content-targeting-devices';
import SegmentContentTargetingDevicesList from './segment-content-targeting-devices-list.component';

interface SegmentContentTargetingDevicesProps {
  tenantId: string;
  conditions: SegmentConditions | undefined;
}

const SegmentContentTargetingDevices = (props: SegmentContentTargetingDevicesProps) => {
  const { tenantId, conditions } = props;

  const { t } = useTranslation();

  const [isDevicesListModalVisible, setIsDevicesListModelVisible] = useState(false);

  const {
    data: devices,
    isLoading,
    isError,
  } = useSegmentContentTargetingDevices(tenantId, conditions);

  const { type, message } = useMemo<{ type: AlertProps['type']; message: React.ReactNode | string }>(() => {
    if (!conditions || !conditions.length) {
      return { type: 'info', message: t('contents.addConditions') };
    }

    if (isLoading) {
      return { type: 'info', message: t('contents.fetching') };
    }

    if (isError || !devices) {
      return { type: 'error', message: t('contents.getDeviceCountError') };
    }

    return {
      type: 'info',
      message: (
        <DevicesListMessage>
          <span className="ant-alert-message">
            {t('contents.deviceCountMessage', { count: devices.length })}
          </span>
         {devices.length > 0 && (
           <Button size="small" onClick={() => setIsDevicesListModelVisible(true)}>
             {t('contents.showDevices')}
          </Button>
         )}
        </DevicesListMessage>
      ),
    };    
  }, [conditions, isLoading, devices, isError, t]);

  return (
    <>
      <Alert type={type} message={message} />

      {isDevicesListModalVisible && (
        <SegmentContentTargetingDevicesList
          tenantId={tenantId}
          devices={devices && devices.length ? devices : []}
          visible={isDevicesListModalVisible}
          onClose={() => setIsDevicesListModelVisible(false)}
        />
      )}
    </>
  );
};

export default SegmentContentTargetingDevices;

const DevicesListMessage = styled.div`
  display: flex;
  justify-content: space-between;
`;
