import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function useLanguageHelper() {
  const { i18n } = useTranslation();
  const supportedLanguages = useMemo(() => {
    return i18n.options.supportedLngs ? i18n.options.supportedLngs.slice(0, -1) : [];
  }, [i18n.options.supportedLngs]);

  return {
    supportedLanguages,
    activeLanguage: i18n.language,
  };
}
