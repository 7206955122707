import React, { useMemo } from 'react';
import { AvatarProps } from 'antd/lib/avatar';
import styled from '@emotion/styled';
import { Avatar } from 'antd';
import Gridapp from '../../../../store/types/gridapp';
import AppBuildIcon from '../app-build-icon/app-build-icon.component';
import { getDefaultAppIcon } from '../../../../utils/apps-library/apps-library.util';

interface AppIconProps extends AvatarProps {
  gridapp: Gridapp;
}

const DefaultAppIconAvatar = styled(Avatar)`
  background-color: rgba(0, 0, 0, 0.02);
`;

const AppIcon = ({ gridapp, ...props }: AppIconProps) => {
  const defaultIcon = useMemo(() => getDefaultAppIcon(gridapp.type), [gridapp]);

  return gridapp.latestBuild ? (
    <AppBuildIcon {...props} build={gridapp.latestBuild} defaultIcon={defaultIcon} />
  ) : (
    <DefaultAppIconAvatar {...props} shape="square" icon={defaultIcon} />
  );
};

export default AppIcon;
