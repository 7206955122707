import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { List, Modal, Typography } from 'antd';
import * as antdColors from '@ant-design/colors';
import Device from '../../../../../store/types/device';
import { useAllSpaces } from '../../../../common/use-spaces';
import OrganisationSpace from '../../../../../store/types/organisation-space';
import Overlay from '../../../../common/overlay/overlay.component';
import { Spinner } from '../../../../common/spinner';
import DeviceStatusEnum from '../../../../../store/types/device-status.enum';

interface SegmentContentTargetingDevicesListProps {
  tenantId: string;
  devices: Device[];
  visible: boolean;
  onClose: () => void;
}

interface SpaceMapping {
  [spaceId: string]: OrganisationSpace;
}

interface SpaceDevicesMapping {
  [spaceId: string]: { space: OrganisationSpace; devices: Device[] };
}

const SegmentContentTargetingDevicesList = (props: SegmentContentTargetingDevicesListProps) => {
  const { tenantId, devices, visible, onClose } = props;

  const { t } = useTranslation();

  const { data: spacesData, isLoading, isFetching, isError } = useAllSpaces({ organisationId: tenantId });

  const spaceDevices = useMemo<SpaceDevicesMapping | null>(() => {
    if (isLoading || isFetching || !devices || !devices.length || !spacesData || !spacesData.spaces.length) {
      return null;
    }

    // create a map of spaces by their ids to fetch them in O(1)
    const spaceMapping: SpaceMapping = {};
    spacesData.spaces.forEach((space) => {
      const { id: spaceId } = space;
      spaceMapping[spaceId] = space;
    });

    // create a map of space devices such that each space has their list of devices
    const spaceDevicesMapping: SpaceDevicesMapping = {};
    devices.forEach((device) => {
      const spaceId = device.spaces[0];
      const space = spaceMapping[spaceId];
      if (space) {
        if (!spaceDevicesMapping[spaceId]) {
          spaceDevicesMapping[spaceId] = { space, devices: [] };
        }

        spaceDevicesMapping[spaceId].devices.push(device);
      }
    });

    return spaceDevicesMapping;
  }, [spacesData, devices, isLoading, isFetching]);

  console.log(spaceDevices);

  const spaceIds = spaceDevices ? Object.keys(spaceDevices) : [];

  return (
    <Modal
      title={t('contents.devicesList')}
      visible={visible}
      centered={true}
      onCancel={onClose}
      okButtonProps={{ hidden: true }}
      cancelText={t('contents.close')}
    >
      {isError && <Typography.Text>{t('contents.getDeviceCountError')}</Typography.Text>}

      {!isError && (isLoading || isFetching) && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}

      {!isLoading &&
        !isFetching &&
        spaceDevices &&
        spaceIds.length &&
        spaceIds.map((spaceId) => {
          const { space, devices } = spaceDevices[spaceId];
          return (
            <List
              key={space.id}
              header={<Typography.Title level={4}>{space.displayName}</Typography.Title>}
              dataSource={devices}
              renderItem={(device) => {
                return (
                  <List.Item>
                    {device.status && device.status.toUpperCase() === DeviceStatusEnum.OK ? (
                      <SuccessIndicator />
                    ) : (
                      <AlertIndicator />
                    )}{' '}
                    {device.deviceName}
                  </List.Item>
                );
              }}
            ></List>
          );
        })}
    </Modal>
  );
};

export default SegmentContentTargetingDevicesList;

const Indicator = styled.div`
  display: inline-flex;
  width: 8px;
  height: 8px;
  margin-right: 4px;
  border-radius: 50%;
`;

const SuccessIndicator = styled(Indicator)`
  background-color: ${antdColors.green[6]};
`;

const AlertIndicator = styled(Indicator)`
  background-color: ${antdColors.red[4]};
`;
