import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { keyBy, debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { FormValidation } from 'react-jsonschema-form';
import User from '../../../store/types/user';
import Organisation from '../../../store/types/organisation';
import UserRole from '../../../store/types/user-roles';
import Header from '../app-layout/header/header.component';
import CrudList from '../crud-list/crud-list.component';
import UserListitem from './user-list-item/user-list-item.component';
import { passwordPattern } from '../../../utils/validation/password';
import PhoneNumberInput, {
  isValidPhoneNumberInput,
} from '../schema-form/widgets/phone-number-input/phone-number-input.component';

interface UsersListProps extends RouteComponentProps<{ organisationId: string }> {
  users: User[];
  userRoles: {
    [organizationId: string]: {
      [roleId: string]: UserRole;
    };
  };
  organisations: Organisation[];
  loaded: boolean;
  fetchUserRoles: ({ silent }: { silent?: boolean }) => void;
  validateUserEmail: (payload: {
    organizationId: string;
    email: string;
  }) => Promise<boolean>;
  fetchUsers: (payload: { organizationId: string }) => void;
  createUser: (user: Partial<User> & { organizationId: string }) => Promise<void>;
  updateUser: (user: Partial<User> & { organizationId: string }) => Promise<void>;
  deleteUser: (payload: { id: string; organizationId: string }) => Promise<void>;
  fetchOrganisations: () => void;
  isPartnerOrganisationUserMode?: boolean;
  canCreate: boolean;
  canUpdate: boolean;
  canDelete: boolean;
  conditionalCanUpdate?: (user: Partial<User>) => boolean;
  conditionalCanDelete?: (user: Partial<User>) => boolean;
  loggedInUser: User;
}

const UsersList = (props: UsersListProps) => {
  const {
    users,
    userRoles,
    loaded,
    fetchUserRoles,
    validateUserEmail,
    fetchUsers,
    fetchOrganisations,
    organisations,
    createUser,
    updateUser,
    deleteUser,
    isPartnerOrganisationUserMode,
    canCreate,
    canUpdate,
    canDelete,
    conditionalCanUpdate,
    conditionalCanDelete,
    loggedInUser,
    match: {
      params: { organisationId },
    },
  } = props;

  const { t } = useTranslation();

  // Form Schema related state
  const [emailCheck, setEmailCheck] = useState<{
    email: string;
    exists: boolean;
  }>({
    email: '',
    exists: false,
  });

  const { isSysadmin } = loggedInUser;

  useEffect(() => {
    fetchUsers({
      organizationId: isSysadmin ? '' : organisationId,
    });
    fetchUserRoles({ silent: false });
    fetchOrganisations();
  }, [fetchUsers, fetchOrganisations, fetchUserRoles, organisationId, isSysadmin]);

  const usersData = useMemo(() => {
    if (!isPartnerOrganisationUserMode) {
      return users;
    }

    return users.map((user) => ({
      ...user,
      roleId: user.organizationsWithRole[0] ? user.organizationsWithRole[0].roleId : '',
    }));
  }, [users, isPartnerOrganisationUserMode]);

  const keyedOrganizations = useMemo(() => {
    return keyBy(organisations, (organisation) => organisation.id);
  }, [organisations]);

  const {
    organizationKeyedUserRoleOptions,
    partnerOrganizationUserRoleOptions,
  } = useMemo(() => {
    const orgKeyedOptions: {
      [organizationId: string]: UserRole[];
    } = Object.keys(userRoles).reduce(
      (options, organizationId) => ({
        ...options,
        [organizationId]: Object.keys(userRoles[organizationId]).map((roleId) => ({
          ...userRoles[organizationId][roleId],
        })),
      }),
      {},
    );

    let partnerOrgUserRoleOptions = [];

    if (isSysadmin || loggedInUser.allowedManageRoles[organisationId].manageAllRoles) {
      partnerOrgUserRoleOptions = orgKeyedOptions[organisationId];
    } else {
      partnerOrgUserRoleOptions = orgKeyedOptions[organisationId]
        ? orgKeyedOptions[organisationId].filter((role) =>
            loggedInUser.allowedManageRoles[organisationId].managedRoleIds.includes(
              role.id,
            ),
          )
        : [];
    }

    return {
      organizationKeyedUserRoleOptions: orgKeyedOptions,
      partnerOrganizationUserRoleOptions: partnerOrgUserRoleOptions,
    };
  }, [userRoles, isSysadmin, loggedInUser.allowedManageRoles, organisationId]);

  const getUserData = useCallback(
    (user: Partial<User>) => ({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      password: user.password,
      isSysadmin: !!user.isSysadmin,
      isSales: !!user.isSales,
      organizationsWithRole: isPartnerOrganisationUserMode
        ? [{ organizationId: organisationId, roleId: user.roleId || '' }]
        : user.organizationsWithRole || [],
      isPartnerOrganization: !!isPartnerOrganisationUserMode,
    }),
    [isPartnerOrganisationUserMode, organisationId],
  );

  const handleUserDelete = useCallback(
    async (user: User) => {
      await deleteUser({ id: user.id, organizationId: organisationId });
    },
    [deleteUser, organisationId],
  );

  const handleUserEdit = useCallback(
    async (user: Partial<User>) => {
      await updateUser({
        ...getUserData(user),
        id: user.id,
        organizationId: organisationId,
      });
    },
    [getUserData, organisationId, updateUser],
  );

  const handleUserCreate = useCallback(
    async (user: Partial<User>) => {
      await createUser({ ...getUserData(user), organizationId: organisationId });
    },
    [createUser, getUserData, organisationId],
  );

  const renderUsersListItem = useCallback(
    (user: User) => (
      <UserListitem
        organisationId={organisationId}
        organizations={keyedOrganizations}
        userRoles={userRoles}
        user={user}
      />
    ),
    [keyedOrganizations, organisationId, userRoles],
  );

  const { createSchema, updateSchema, metaSchema } = useMemo(() => {
    const dependencies = {
      dependencies: {
        isSysadmin: {
          oneOf: [
            {
              properties: {
                isSysadmin: {
                  enum: [true],
                },
              },
            },
            {
              properties: {
                isSysadmin: {
                  enum: [false],
                },
                organizationsWithRole: {
                  type: 'array',
                  title: t('organizationsWithRole'),
                  uniqueItems: true,
                  items: {
                    type: 'object',
                    title: '',
                    properties: {
                      organizationId: {
                        type: 'string',
                        title: t('organization'),
                        enum: organisations.map((organisation) => organisation.id),
                        enumNames: organisations.map(
                          (organisation) => organisation.displayName,
                        ),
                        default: organisationId,
                      },
                    },
                    dependencies: {
                      organizationId: {
                        oneOf: organisations.map((organisation) => {
                          const options: { id: string; displayName: string }[] =
                            organizationKeyedUserRoleOptions[organisation.id] &&
                            organizationKeyedUserRoleOptions[organisation.id].length
                              ? organizationKeyedUserRoleOptions[organisation.id]
                              : [{ id: '', displayName: '' }];

                          return {
                            properties: {
                              organizationId: {
                                enum: [organisation.id],
                              },
                              roleId: {
                                type: 'string',
                                title: t('role'),
                                enum: options.map((role) => role.id),
                                enumNames: options.map((role) => role.displayName),
                              },
                            },
                          };
                        }),
                      },
                    },
                    required: ['organizationId', 'roleId'],
                  },
                },
              },
            },
          ],
        },
      },
    };

    const getCommonSchemaProps = ({isUpdate}: {isUpdate: boolean}) => ({
      firstName: {
        type: 'string',
        title: t('firstName'),
        minLength: 2,
        maxLength: 100,
      },
      lastName: {
        type: 'string',
        title: t('lastName'),
        minLength: 2,
        maxLength: 100,
      },
      email: {
        type: 'string',
        minLength: 1,
        title: t('email'),
        format: 'email',
        readOnly: isUpdate
      },
      phoneNumber: {
        type: 'string',
        title: t('phoneNumberAlt'),
        maxLength: 50,
      },
      ...(isPartnerOrganisationUserMode
        ? {
            roleId: {
              type: 'string',
              title: t('role'),
              enum:
                partnerOrganizationUserRoleOptions &&
                partnerOrganizationUserRoleOptions.map((role) => role.id),
              enumNames:
                partnerOrganizationUserRoleOptions &&
                partnerOrganizationUserRoleOptions.map((role) => role.displayName),
            },
          }
        : {
            isSysadmin: {
              title: t('isSysadmin'),
              description: t('isSysadminDescription'),
              type: 'boolean',
              default: false,
            },
            isSales: {
              title: t('isSales'),
              description: t('isSalesDescription'),
              type: 'boolean',
              default: false,
            },
          }),
    });

    const conditionalPasswordWidget =
      !emailCheck.exists || !isPartnerOrganisationUserMode
        ? {
            password: {
              title: t('password'),
              type: 'string',
              pattern: passwordPattern,
            },
          }
        : {};

    const conditionalRequired = emailCheck.exists
      ? ['email', 'roleId']
      : ['email', 'password', 'roleId'];

    return {
      createSchema: {
        type: 'object',
        properties: {
          ...getCommonSchemaProps({isUpdate: false}),
          ...conditionalPasswordWidget,
        },
        required: isPartnerOrganisationUserMode
          ? conditionalRequired
          : ['email', 'password'],
        ...(!isPartnerOrganisationUserMode && dependencies),
      },
      updateSchema: {
        type: 'object',
        properties: {
          ...getCommonSchemaProps({isUpdate: true}),
          ...(isPartnerOrganisationUserMode ? {} : conditionalPasswordWidget),
        },
        required: ['email'],
        if: {
          properties: {
            password: {
              const: 'null',
            },
          },
        },
        then: {},
        else: {
          properties: {
            password: {
              type: 'string',
              pattern: passwordPattern,
            },
          },
        },
        ...(!isPartnerOrganisationUserMode && dependencies),
      },
      metaSchema: {
        email:
          emailCheck.exists && isPartnerOrganisationUserMode
            ? {
                classNames: 'email',
                'ui:help': t('addExistingUser'),
              }
            : {
                classNames: 'email',
              },
        phoneNumber: {
          'ui:widget': PhoneNumberInput,
        },
        password: {
          classNames: 'password',
          'ui:widget': 'password',
          'ui:help': t('passwordHint'),
        },
      },
    };
  }, [
    emailCheck.exists,
    isPartnerOrganisationUserMode,
    organisationId,
    organisations,
    organizationKeyedUserRoleOptions,
    partnerOrganizationUserRoleOptions,
    t,
  ]);

  const checkEmailExist = useCallback(
    async (user: Partial<User>, rootFormChange: any) => {
      if (user.email && user.email !== emailCheck.email) {
        const exists =
          user.email.length > 0
            ? await validateUserEmail({
                organizationId: isSysadmin ? '' : organisationId,
                email: user.email,
              })
            : false;

        if (exists !== emailCheck.exists) {
          await setEmailCheck({
            email: user.email,
            exists,
          });

          if (exists && isPartnerOrganisationUserMode) {
            user.password = undefined;
          }
          if (rootFormChange) {
            await rootFormChange(user);
          }
        }
      }
    },
    [
      emailCheck.email,
      emailCheck.exists,
      isPartnerOrganisationUserMode,
      isSysadmin,
      organisationId,
      validateUserEmail,
    ],
  );

  const customFormValidation = (user: Partial<User> = {}, error: FormValidation) => {
    const { phoneNumber = '', id } = user;
    if (!isPartnerOrganisationUserMode && emailCheck.exists && !id) {
      error.email.addError(t('emailExists'));
    }

    if (phoneNumber && !isValidPhoneNumberInput({ value: phoneNumber })) {
      // @ts-ignore
      error.phoneNumber.addError(t('invalidPhoneNumber'));
    }

    return error;
  };

  return (
    <>
      {!props.match.url.includes('user-management') &&
        <Header title={t('users')} />
      }
      <div className="content-body">
        <CrudList<User>
          onCreate={handleUserCreate}
          onEdit={handleUserEdit}
          onDelete={handleUserDelete}
          loaded={loaded}
          createSchema={createSchema}
          updateSchema={updateSchema}
          metaSchema={metaSchema}
          dataSource={usersData}
          renderItem={renderUsersListItem}
          createButtonText={t('createUser')}
          modalTitle={t('user')}
          canCreate={canCreate}
          canUpdate={canUpdate}
          canDelete={canDelete}
          conditionalCanUpdate={conditionalCanUpdate}
          conditionalCanDelete={conditionalCanDelete}
          onFormChange={debounce(checkEmailExist, 1000)}
          externalFormChange
          customFormValidation={customFormValidation}
        />
      </div>
    </>
  );
};

export default UsersList;
