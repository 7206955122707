import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { Tree, Select as AntdSelect, Icon } from 'antd';
import styled, { StyledComponent } from '@emotion/styled';
import { FieldContainer } from '.';
import { SelectProps as AntdSelectProps, SelectValue } from 'antd/lib/select';
import { AntTreeNodeCheckedEvent, AntTreeNodeProps } from 'antd/lib/tree';
import Spinner from '../../spinner/spinner.component';

const { TreeNode } = Tree;

interface AntTreeNodeInnerProps {
  icon?: React.ReactNode;
  eventKey: string;
  title: string | JSX.Element;
  unHighlightedTitle: string | JSX.Element;
  parentKey: string;
  children?: TreeNode[];
}

export interface TreeNode extends AntTreeNodeProps {
  icon?: React.ReactNode;
  key: string;
  title: string | JSX.Element;
  children?: TreeNode[];
}

export interface SelectTag {
  icon?: React.ReactNode;
  key: string;
  label: React.ReactNode;
  parentKey: string;
}

const dataList: TreeNode[] = [];

interface SelectTreeProps {
  className?: string;
  isBordered?: boolean;
  isReadonly?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  rawErrors?: string[];
  title?: string;
  subtitle?: string;
  description?: string;
  helpText?: string;
  placeholder?: string;
  onSelect?: (value: any) => void;
  beforeExtra?: React.ReactNode;
  treeOptions?: TreeNode[];
  treeExpandedKeys?: string[];
  onChange?: (value: SelectTag[]) => void;
  value?: SelectTag[];
  isLoading?: boolean;
}

const SelectTree = (props: SelectTreeProps) => {
  const {
    className,
    isBordered,
    isReadonly,
    isRequired,
    isDisabled,
    rawErrors = [],
    title,
    subtitle,
    description,
    helpText,
    placeholder,
    beforeExtra,
    treeOptions = [],
    treeExpandedKeys,
    onChange = () => {},
    value,
    isLoading,
  } = props;

  const [expandedKeys, setExpandedKeys] = useState<string[] | undefined>(
    treeExpandedKeys,
  );
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string>('');
  const [tags, setTags] = useState<SelectTag[]>(value || []);
  const refTree = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (value) {
      setTags(value);
    }
  }, [value]);

  const loadingPlaceholder = useMemo(() => {
    return isLoading ? (
      <Loading>
        <Spinner size={18} />
        {t('select.pleaseWait')}
      </Loading>
    ) : (
      undefined
    );
  }, [isLoading, t]);

  const renderTreeNodes = useCallback(
    ({
      treeNodes,
      parentKey,
      searchValue,
    }: {
      treeNodes: TreeNode[];
      parentKey: string;
      searchValue: string;
    }) => {
      return treeNodes.map((node) => {
        const searchableTitle = React.isValidElement(node.title)
          ? ReactDOMServer.renderToString(node.title as JSX.Element).toLowerCase()
          : (node.title as string).toLowerCase();
        const index =
          searchableTitle && searchValue ? searchableTitle.indexOf(searchValue) : -1;

        const icon = typeof node.icon === 'string' ? <Icon type={node.icon}></Icon> : node.icon;

        const title = 
          index > -1 ? (
            <TitleHighlighted className="search-highlight">
              {icon}
              {node.title}
            </TitleHighlighted>
          ) : (
            <Title>{icon}{node.title}</Title>
          );

        if (node.children) {
          return (
            <TreeNode
              {...node}
              title={title}
              unHighlightedTitle={node.title}
              parentKey={parentKey}
              selectable={false}
            >
              {renderTreeNodes({
                treeNodes: node.children,
                parentKey: node.key,
                searchValue,
              })}
            </TreeNode>
          );
        }

        return (
          <TreeNode
            {...node}
            title={title}
            unHighlightedTitle={node.title}
            parentKey={parentKey}
            selectable={false}
          />
        );
      });
    },
    [],
  );

  const options = useMemo<JSX.Element[]>(() => {
    return renderTreeNodes({
      treeNodes: treeOptions,
      parentKey: 'root',
      searchValue,
    });
  }, [renderTreeNodes, searchValue, treeOptions]);

  const generateSearchList = useCallback((treeNodes: TreeNode[]) => {
    for (let i = 0; i < treeNodes.length; i++) {
      const node = treeNodes[i];
      const { key, title } = node;
      dataList.push({ key, title });
      if (node.children) {
        generateSearchList(node.children);
      }
    }
  }, []);

  const getParentKey = useCallback((key: string, treeNodes: TreeNode[]) => {
    let parentKey = '';
    for (let i = 0; i < treeNodes.length; i++) {
      const node = treeNodes[i];
      if (node.children) {
        if (node.children.some((item) => item.key === key)) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }
    return parentKey;
  }, []);

  useEffect(() => {
    generateSearchList(treeOptions);
  }, [generateSearchList, treeOptions]);

  const handleSearch = useCallback(
    (value: string) => {
      const searchedValue = value.toLowerCase();

      const expandedKeys = dataList
        .map((item) => {
          const searchableTitle = React.isValidElement(item.title)
            ? ReactDOMServer.renderToString(item.title as JSX.Element).toLowerCase()
            : (item.title as string).toLowerCase();

          if (searchableTitle && searchableTitle.indexOf(searchedValue) > -1) {
            return getParentKey(item.key, treeOptions);
          }
          return '';
        })
        .filter((item, i, self) => item && self.indexOf(item) === i);

      setExpandedKeys(expandedKeys);
      setAutoExpandParent(true);
      setSearchValue(searchedValue);

      const optionElement =
        refTree.current && refTree.current.querySelector('.search-highlight');

      if (optionElement) {
        optionElement.scrollIntoView();
      }
    },
    [getParentKey, treeOptions],
  );

  const handleCheck = useCallback(
    (
      checkedKeys: string[] | { checked: string[]; halfChecked: string[] },
      e: AntTreeNodeCheckedEvent,
    ) => {
      const checkedTags =
        (e.checkedNodes &&
          e.checkedNodes.map((node: AntTreeNodeProps) => {
            const nodeInnerProps = node.props as AntTreeNodeInnerProps;

            return {
              icon: nodeInnerProps.icon,
              parentKey: nodeInnerProps.parentKey,
              key: node.key as string,
              label: nodeInnerProps.unHighlightedTitle,
              hasChildren: !!nodeInnerProps.children && !!nodeInnerProps.children.length,
              isRemoved: false,
            };
          })) ||
        [];

      const parentNodes = checkedTags.filter((tag) => tag.parentKey === 'root');

      parentNodes.forEach((node) => {
        checkedTags.forEach((tag) => {
          if (tag.parentKey === node.key) {
            tag.isRemoved = true;
          }

          checkedTags.forEach((tag) => {
            if (tag.parentKey === tag.key) {
              tag.isRemoved = true;
            }
          });
        });
      });

      const leafNodes = checkedTags.filter((tag) => tag.hasChildren && tag.parentKey !== 'root');

      leafNodes.forEach((node) => {
        checkedTags.forEach((tag) => {
          if (tag.parentKey === node.key) {
            tag.isRemoved = true;
          }
        });
      });

      const finalTags = checkedTags.filter((tag) => !tag.isRemoved);

      setTags(finalTags);
      onChange(finalTags);
    },
    [onChange],
  );

  const handleExpand = useCallback((expandedKeys: string[]) => {
    if (expandedKeys.length) {
      setExpandedKeys(expandedKeys);
    }
    setAutoExpandParent(false);
  }, []);

  const handleTreeClose = useCallback(() => {
    setSearchValue('');
  }, []);

  const handleTagDeselect = useCallback(
    (value) => {
      const tagValue = value as TreeNode;

      setTags((tags) => tags.filter((tag) => tag.key !== tagValue.key) || []);
    },
    [],
  );

  const handleSearchTagChange = useCallback((value: SelectValue) => {
    const list = value as { key: string; label: string }[];
    const keys = list.map((value) => value.key);
    const updatedTags = tags.filter((tag) => keys.includes(tag.key));

    onChange(updatedTags)
  }, [tags, onChange]);

  const checkedKeys = useMemo(() => {
    return tags.map((tag) => tag.key);
  }, [tags]);

  return (
    <FieldContainer
      className={className}
      type="field"
      isBordered={isBordered}
      isDisabled={isDisabled}
      isRequired={isRequired}
      title={title}
      subtitle={subtitle}
      helpText={helpText}
      errors={rawErrors}
      description={description}
    >
      <FieldWrap>
        {beforeExtra}
        <FieldSelect
          mode="tags"
          showSearch={!isReadonly}
          showArrow={!isDisabled && !isReadonly && !isLoading}
          placeholder={loadingPlaceholder || placeholder || t('select.placeholder')}
          disabled={isDisabled || isReadonly || isLoading}
          isReadonly={isReadonly || isLoading}
          onSearch={handleSearch}
          onChange={handleSearchTagChange}
          labelInValue
          value={tags}
          onBlur={handleTreeClose}
          onDeselect={handleTagDeselect}
          {...(isLoading ? { notFoundContent: null } : {})}
          dropdownStyle={{
            maxHeight: 300,
            overflow: 'auto',
          }}
          dropdownRender={() => (
            <div ref={refTree} onMouseDown={(e) => e.preventDefault()}>
              <Tree
                checkable
                defaultExpandAll
                defaultExpandParent
                {...(expandedKeys ? { expandedKeys: expandedKeys } : {})}
                onCheck={handleCheck}
                onExpand={handleExpand}
                autoExpandParent={autoExpandParent}
                checkedKeys={checkedKeys}
              >
                {options}
              </Tree>
            </div>
          )}
        />
      </FieldWrap>
    </FieldContainer>
  );
};

const FieldWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
`;

type StyledSelectType = StyledComponent<
  AntdSelectProps & React.ClassAttributes<AntdSelectProps> & { isReadonly?: boolean },
  Pick<AntdSelectProps & React.ClassAttributes<AntdSelectProps>, keyof AntdSelectProps>,
  any
>;
const FieldSelect = styled(AntdSelect)<{ isReadonly?: boolean; value?: string }>`
  all: unset;
  cursor: pointer;

  > .ant-select-selection {
    all: unset;
    display: flex;
    background-color: inherit;
    height: unset;

    > .ant-select-selection__rendered {
      line-height: unset;
      margin: auto 5px auto 0;
      width: -webkit-fill-available;

      > .ant-select-selection__placeholder {
        margin-left: 0;
      }
    }

    > .ant-select-arrow {
      display: flex;
      position: unset;
      margin-top: auto;
      align-items: center;
      justify-content: center;
      height: 20px;
      width: 20px;
      cursor: pointer;

      > .anticon-loading > svg {
        color: #2364aa;
        width: 18px;
        height: 18px;
      }
    }
  }

  :not(&.ant-select-open) {
    > .ant-select-selection > .ant-select-arrow > .ant-select-arrow-icon > svg {
      color: #676973;
      height: 10px;
      transform: scale(1.2, 1) rotate(0deg);
    }
  }
  &.ant-select-open {
    > .ant-select-selection > .ant-select-arrow > .ant-select-arrow-icon > svg {
      color: #676973;
      height: 10px;
      transform: scale(1.2, 1) rotate(180deg);
    }
  }

  &.ant-select-disabled {
    > .ant-select-selection {
      ${({ isReadonly }) =>
        !isReadonly
          ? `
          cursor: not-allowed;
          background-color: #f7f7f7;
          border: 1px solid rgba(118,118,118,0.3);
          padding: 0 5px;
          border-radius: 4px;
          height: 32px;
        `
          : 'cursor: default;'}
    }
  }
` as StyledSelectType;

const Title = styled.span`
  .anticon {
    margin-right: 5px;
  }
`;

const TitleHighlighted = styled(Title)`
  color: #3b759e;
  background-color: #e1ecf4;

  .anticon {
    margin-right: 5px;
  }
`;

const Loading = styled.div`
  display: flex;
  gap: 8px;
`;

export default SelectTree;
