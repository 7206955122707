import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';
import OnboardingSetupContainer from './onboarding-setup/onboarding-setup.container';
import OnboardingSignupContainer from './onboarding-signup/onboarding-signup.container';
import DeviceSetupContainer from './onboarding-device/device-setup.container';
import OnboardingConfirmEmail from './onboarding-confirm-email/onboarding-confirm-email.component';

const Onboarding = (props: RouteComponentProps) => {
  const { match } = props;

  return (
    <Switch>
      <Route
        path={`${match.path}/setup`}
        render={(routeProps) => <OnboardingSetupContainer {...routeProps} />}
      />
      <Route
        path={`${match.path}/signup`}
        render={(routeProps) => <OnboardingSignupContainer {...routeProps} />}
      />
      <Route
        path={`${match.path}/device`}
        render={(routeProps) => <DeviceSetupContainer {...routeProps} />}
      />
      <Route
        path={`${match.path}/confirm-email`}
        render={(routeProps) => <OnboardingConfirmEmail {...routeProps} />}
      />
    </Switch>
  );
};

export default Onboarding;
