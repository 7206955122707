import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import CustomShortUrlListComponent from './custom-short-url-list.component';
import { RootModel, RootState } from '../../../../store/models/root.model';
import CustomShortUrl from '../../../../store/types/custom-short-urls';
import { getPermissions } from '../../../../utils/auth/permissions';

type CustomShortUrlListContainer = Pick<
  React.ComponentProps<typeof CustomShortUrlListComponent>,
  'match' | 'history' | 'location'
>;

const CustomShortUrlListContainer = (props: CustomShortUrlListContainer) => {
  const {
    match: {
      params: { organisationId },
    },
  } = props;

  const {
    fetchCustomShortUrl,
    createCustomShortUrl,
    updateCustomShortUrl,
    deleteCustomShortUrl,
  } = useActions<RootModel>((actions) => ({
    fetchCustomShortUrl: actions.organisationCustomShortUrls.fetch,
    createCustomShortUrl: (data: Partial<CustomShortUrl>) =>
      actions.organisationCustomShortUrls.create({ data, key: organisationId }),
    updateCustomShortUrl: (data: Partial<CustomShortUrl>) =>
      actions.organisationCustomShortUrls.update({ data, key: organisationId }),
    deleteCustomShortUrl: (id: string) =>
      actions.organisationCustomShortUrls.delete({ id, key: organisationId }),
  }));

  const { customShortUrls, loaded, permissions } = useStore<RootState>(
    (state) => ({
      customShortUrls: state.organisationCustomShortUrls.values(organisationId),
      loaded: state.organisationCustomShortUrls.loaded(organisationId),
      permissions: getPermissions(state.auth.user, organisationId),
    }),
    [organisationId],
  );

  return (
    <CustomShortUrlListComponent
      {...props}
      loaded={loaded}
      customShortUrls={customShortUrls}
      fetchCustomShortUrls={fetchCustomShortUrl}
      createCustomShortUrl={createCustomShortUrl}
      updateCustomShortUrl={updateCustomShortUrl}
      deleteCustomShortUrl={deleteCustomShortUrl}
      canCreate={permissions.customShortUrls.create}
      canUpdate={permissions.customShortUrls.update}
      canDelete={permissions.customShortUrls.remove}
    />
  );
};

export default CustomShortUrlListContainer;
