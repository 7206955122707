import styled from '@emotion/styled';
import { Typography } from 'antd';

const { Title } = Typography;

const SidebarTitleWrapper = styled.div`
  flex-wrap: wrap;
`;

const SidebarTitle = styled(Title)`
  color: #fff !important;
  text-align: center;
  padding: 0;
`;

export { SidebarTitleWrapper, SidebarTitle };
