import Device from '../store/types/device';
import UwfStateEnum from '../store/types/uwf-state.enum';
import UniversalDevice from '../store/types/universal-device';
import LegacyWindowsDevice from '../store/types/legacy-windows-device';

export interface Result {
  [key: string]: any;
  hostname?: string;
  manufacturer?: string;
  model?: string;
  serialNumber?: string;
  osName?: string;
  osVersion?: string;
  osBuild?: string;
  biosVendor?: string;
  biosVersion?: string;
  biosReleaseDate?: string;
  cpuUsage?: number;
  cpuTemp?: number;
  memUsage?: number;
  memSize?: number;
  diskUsage?: number;
  diskSize?: number;
  deviceStart?: string;
  supervisorStart?: string;
  appStart?: string;
  uwfState?: UwfStateEnum;
  passwordExpiration?: string;
  supervisorRelease?: string;
  modules?: {
    [key: string]: {
      version?: string;
      status: string;
    };
  };
  iotedge?: boolean;
  memSeries?: string;
  cpuSeries?: string;
  diskSeries?: string;
  cpuModel?: string;
  powerCharge?: number;
  powerStatus?: string;
  ips?: {
    wifi?: string;
    eth?: string;
  } | {
    wifi?: {
      ip: string;
      mac: string;
    };
    lan?: {
      ip: string;
      mac: string;
    };
  };
}

export default function getDeviceInfo(
  device: Device | UniversalDevice | LegacyWindowsDevice,
) {
  const result: Result = {};

  const properties = [
    'hostname',
    'manufacturer',
    'model',
    'serialNumber',
    'osName',
    'osVersion',
    'osBuild',
    'biosVendor',
    'biosVersion',
    'biosReleaseDate',
    'cpuUsage',
    'cpuTemp',
    'memUsage',
    'memSize',
    'diskUsage',
    'diskSize',
    'deviceStart',
    'supervisorStart',
    'appStart',
    'supervisorRelease',
    'uwfState',
    'passwordExpiration',
    'modules',
    'memSeries',
    'cpuSeries',
    'diskSeries',
    'cpuModel',
    'powerCharge',
    'powerStatus',
    'ips',
  ];

  properties.forEach((property) => {
    if (
      device &&
      device.properties &&
      device.properties.reported &&
      property in device.properties.reported
    ) {
      result[property] = (device.properties.reported as any)[property];
    }
  });

  result.iotedge = device.iotedge;

  return result;
}
