import styled from '@emotion/styled';

const ContentWrap = styled.div`
  padding: 40px;
  position: relative;
  flex: 1;

  @media screen and (max-width: 991px) {
    padding: 20px;
  }
`;

export default ContentWrap;
