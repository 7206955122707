import dayjs from 'dayjs';
import Build from '../../store/types/build';

export const isBuildError = (build: Build) => {
  return (
    build.deploys.some((deploy) => ['failed', 'deploy_failed'].includes(deploy.status)) ||
    build.pipelines.some((pipeline) =>
      ['failed', 'deploy_failed'].includes(pipeline.status),
    )
  );
};

export const isBuildDone = (build: Build) => {
  return (
    build.deploys.every((deploy) =>
      ['done', 'built', 'deployed'].includes(deploy.status),
    ) &&
    build.pipelines.every((pipeline) =>
      ['done', 'built', 'deployed'].includes(pipeline.status),
    )
  );
};

export const isBuildFinished = (build: Build) => {
  return (
    build.deploys.every((deploy) =>
      ['done', 'built', 'deployed', 'failed', 'error', 'deploy_failed'].includes(deploy.status),
    ) &&
    build.pipelines.every((pipeline) =>
      ['done', 'built', 'deployed', 'failed', 'error', 'deploy_failed'].includes(pipeline.status),
    )
  );
};

export const isBuildNew = (build: Build) => {
  const now = dayjs();
  const createdAt = dayjs(build.createdAt);
  return (
    dayjs().diff(createdAt, 'hour') < 1 ||
    (now.diff(createdAt, 'day') < 1 && (!isBuildDone(build) || isBuildError(build)))
  );
};
