import { FieldArray } from 'react-final-form-arrays';
import { Button, Col, Row, DatePicker } from 'antd';
import { Field } from 'react-final-form';
import React from 'react';
import styled from '@emotion/styled';
import { UsergroupAddOutline } from '@ant-design/icons';
import Icon from '@ant-design/icons-react';
import moment from 'moment';
import TimePickerAdapter, {
  defaultFromTime,
  defaultToTime,
  SingleHour,
  TimeDescription,
} from '../time-picker-adapter';
import { Label, PanelCardMain, RowStyled, FormTextInput } from '../styled-block';
import { WeekdayPeriods } from '../types';

interface SpecialHoursPeriodsProps {
  name: string;
  value?: WeekdayPeriods;
  change: (name: string, value: any) => void;
  label: string;
  isCapacityAdjustable?: boolean;
  defaultCapacity: number;
  minuteStep: number;
  midnightSlotIsAvailable?: boolean;
}

const HourPeriodsControlButton = styled(Button)`
  margin-top: 20px;
` as any;

// in case if we want to add toggle
// const WeekDayToggleSwitch = styled(Switch)`
//   margin-right: 16px;
// `;

// const WeekDayNameContainer = styled(Row)`
//   min-height: 70px;
// `;

const InputField = styled(FormTextInput)`
  max-width: 100px;
`;

const defaultPeriod = {
  from: defaultFromTime,
  to: defaultToTime,
};

const SpecialHoursPeriods = ({
  label,
  name,
  isCapacityAdjustable = false,
  defaultCapacity = 0,
  minuteStep = 1,
  midnightSlotIsAvailable = false,
}: SpecialHoursPeriodsProps) => {
  // in case if we want to add toggle
  // const handleWeekDayToggleSwitchChange = useCallback(
  //   (checked: boolean, onChange: (val: any) => void, arrayFieldName: string) => {
  //     change(arrayFieldName, checked ? [defaultPeriod] : []);
  //     onChange(checked);
  //   },
  //   [change],
  // );

  return (
    <RowStyled>
      <Label>{label}</Label>
      <FieldArray key={`${name}.periods`} name={`${name}.periods`}>
        {({ fields }) => (
          <>
            <Row type="flex" align="top" justify="space-between">
              {/* <WeekDayNameContainer type="flex" align="middle"> */}
              {/* in case if we want to add toggle */}
              {/* <Field name={`${name}.${periods}.enabled`}> */}
              {/*  {({ input }) => ( */}
              {/*    <WeekDayToggleSwitch */}
              {/*      checked={!!input.value} */}
              {/*      onChange={(checked) => */}
              {/*        handleWeekDayToggleSwitchChange( */}
              {/*          checked, */}
              {/*          input.onChange, */}
              {/*          `${name}.${day}.periods`, */}
              {/*        ) */}
              {/*      } */}
              {/*    /> */}
              {/*  )} */}
              {/* </Field> */}
              {/* </WeekDayNameContainer> */}
              {/* <Row align="top" type="flex"> */}
              <Col span={24}>
                {fields.map((fieldName, index) => {
                  return (
                    <PanelCardMain key={fieldName}>
                      <Row type="flex">
                        <Field name={`${fieldName}.date`}>
                          {({ input: filedInput }) => (
                            <SingleHour>
                              <TimeDescription>Date</TimeDescription>
                              <Field
                                name={`${fieldName}.date`}
                                render={(props) => {
                                  return (
                                    <DatePicker
                                      onChange={(date) =>
                                        filedInput.onChange((
                                          date || new Date()
                                        ).toISOString() as any)
                                      }
                                      style={{ width: '250px' }}
                                      value={moment(props.input.value || moment.now())}
                                      format="dddd, MMMM D, YYYY"
                                    />
                                  );
                                }}
                              />
                            </SingleHour>
                          )}
                        </Field>
                        <Field name={`${fieldName}.from`}>
                          {({ input: filedInput }) => (
                            <SingleHour>
                              <TimeDescription>From</TimeDescription>
                              <TimePickerAdapter
                                defaultTime={defaultFromTime}
                                input={filedInput}
                                minuteStep={minuteStep}
                                midnightSlotIsAvailable={false}
                              />
                            </SingleHour>
                          )}
                        </Field>
                        <Field name={`${fieldName}.to`}>
                          {({ input: filedInput }) => (
                            <SingleHour>
                              <TimeDescription>To</TimeDescription>
                              <TimePickerAdapter
                                defaultTime={defaultToTime}
                                input={filedInput}
                                minuteStep={minuteStep}
                                midnightSlotIsAvailable={midnightSlotIsAvailable}
                              />
                            </SingleHour>
                          )}
                        </Field>

                        {isCapacityAdjustable && (
                          <Field name={`${fieldName}.capacity`}>
                            {({ input: filedInput, meta }) => (
                              <SingleHour>
                                <TimeDescription>Capacity</TimeDescription>
                                <InputField
                                  input={filedInput}
                                  meta={meta}
                                  icon={<Icon type={UsergroupAddOutline} />}
                                />
                              </SingleHour>
                            )}
                          </Field>
                        )}

                        <HourPeriodsControlButton
                          icon="minus"
                          onClick={() => {
                            fields.remove(index);
                          }}
                        />
                      </Row>
                    </PanelCardMain>
                  );
                })}
              </Col>
              {/* </Row> */}
            </Row>
            <PanelCardMain>
              <Button
                icon="plus"
                onClick={() => {
                  fields.push({
                    ...defaultPeriod,
                    capacity: defaultCapacity,
                    date: moment().toISOString(),
                  });
                }}
              />
            </PanelCardMain>
          </>
        )}
      </FieldArray>
    </RowStyled>
  );
};

export default SpecialHoursPeriods;
