/* stylelint-disable property-no-vendor-prefix */
import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
  isPossiblePhoneNumber,
} from 'react-phone-number-input';
import { FieldProps } from 'react-jsonschema-form';
import styled from '@emotion/styled';
import { CountryCode } from 'libphonenumber-js/types';

interface PhoneNumberProps extends FieldProps {
  international?: boolean;
  defaultCountry?: CountryCode;
  limitMaxLength?: boolean;
  countries?: CountryCode[];
  inputClassName?: string;
}

const PhoneNumberInput = (props: PhoneNumberProps) => {
  const {
    id,
    value,
    placeholder,
    disabled,
    readonly,
    autoFocus,
    onChange,
    rawErrors,
    inputClassName = 'form-control',
    international = true,
    defaultCountry = 'SE',
    limitMaxLength = false,
    countries,
  } = props;
  const inputRef = useRef(null);

  const classNames = useMemo(() => {
    const hasErrorClass = `${rawErrors && rawErrors.length ? 'has-error' : ''}`;
    const readOnlyClass = `${readonly ? 'read-only' : ''}`;
    return `${hasErrorClass} ${readOnlyClass}`.trim();
  }, [rawErrors, readonly]);

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }

    if (readonly) {
      // @ts-ignore
      inputRef.current.setAttribute('readonly', 'true');
    }

    if (inputClassName) {
      // @ts-ignore
      inputRef.current.setAttribute('class', inputClassName);
    }
  }, [readonly, inputClassName]);

  const handleChange = useCallback(
    (fieldValue: string) => {
      onChange(fieldValue);
    },
    [onChange],
  );

  return (
    <PhoneInputStyled
      id={id}
      ref={inputRef}
      className={classNames}
      international={international}
      addInternationalOption={international}
      value={value}
      defaultCountry={defaultCountry}
      limitMaxLength={limitMaxLength}
      countries={countries}
      onChange={handleChange}
      placeholder={placeholder}
      disabled={disabled}
      autoFocus={autoFocus}
    />
  );
};

export const isValidPhoneNumberInput = ({
  value,
  allowedCountries,
  strict = true,
}: {
  value: string;
  allowedCountries?: string[];
  strict?: boolean;
}) => {
  let isValid = strict ? isValidPhoneNumber(value) : isPossiblePhoneNumber(value);

  if (!isValid) {
    return false;
  }

  if (!allowedCountries) {
    return isValid;
  }

  const parsedPhoneNumber = parsePhoneNumber(value);
  const country = parsedPhoneNumber ? parsedPhoneNumber.country : '';
  isValid = isValid && !!country && allowedCountries.includes(country);

  return isValid;
};

const PhoneInputStyled = styled(PhoneInput)`
  .PhoneInputCountry {
    margin-left: 7px;
  }
`;

export default PhoneNumberInput;
