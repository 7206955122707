import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from '@emotion/styled';
import { Global, keyframes } from '@emotion/core';
import qs from 'query-string';
import { Alert, Button } from 'antd';
import { get } from 'lodash';
import { ApiError } from '../../../services/api/api-error';
import OnboardingInfo from '../../../store/types/onboarding-info';
import { useQueryString } from '../../common/use-query-string-param/use-query-string-param';
import ErrorView from '../../common/error-view/error-view.component';
import ImgOmboriGridLogo from '../../../assets/images/ombori_grid_logo_light.svg';
import ImgOmboriGridLogoLarge from '../../../assets/images/ombori_grid_large_white.svg';
import ImgBrandShape from '../../../assets/images/brand-shape.png';
import User from '../../../store/types/user';
import AppButton from '../../common/app-button/app-button.component';
import Voucher from '../voucher/voucher.component';
import { guessClientCountry } from '../../../utils/client-location';
import { getApiUrl } from '../../../utils/env';
import { EMAIL_REGEXP } from '../../common/react-final-form';

interface OnboardingComponentProps extends RouteComponentProps {
  onboardingInfo: OnboardingInfo;
  fetchOnboarding: (params: { token: string; countryCode?: string }) => Promise<void>;
  loaded: boolean;
  error: ApiError | null;
  tryLogin: (params: { email: string; returnPath: string; fallbackPath: string }) => void;
  user: User | null;
  logout: () => void;
}

const isValidEmailAddress = (email: string) => {
  return EMAIL_REGEXP.test(String(email).toLowerCase());
};

const OnboardingComponent = ({
  tryLogin,
  user,
  logout,
  fetchOnboarding,
  error,
  loaded,
  onboardingInfo,
}: OnboardingComponentProps) => {
  const [formError, setFormError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const queryStringObject = useQueryString();
  const params = {
    token: queryStringObject.t as string,
  };

  const [email, setEmail] = useState('');

  const getSignupPath = useCallback(() => {
    return `/onboarding/signup?${qs.stringify({
      t: params.token,
    })}`;
  }, [params.token]);

  const getOnboardingContinuePath = useCallback(() => {
    if (onboardingInfo && onboardingInfo.deviceSerial) {
      return `/onboarding/device?deviceSerial=${onboardingInfo.deviceSerial}`;
    }
    return getSignupPath();
  }, [onboardingInfo, getSignupPath]);

  const getConfirmEmailAndRedirectLink = useCallback((email: string) => {
    return `${getApiUrl()}/api/signup/confirm-email?${qs.stringify(
      {
        token: params.token,
        email,
      },
    )}`
  }, [params.token]);

  const handleEmailFormSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (isValidEmailAddress(email)) {
        setIsSubmitting(true);

        tryLogin({
          email,
          returnPath: getOnboardingContinuePath(),
          fallbackPath: getConfirmEmailAndRedirectLink(email),
        });
      } else {
        setFormError('Invalid email address');
      }
    },
    [email, tryLogin, getOnboardingContinuePath, getConfirmEmailAndRedirectLink],
  );

  const handleEmailFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
      setFormError('');
    },
    [],
  );

  useEffect(() => {
    guessClientCountry().then((country) => {
      if (params.token) {
        fetchOnboarding({ token: params.token, countryCode: country });
      }
    });
  }, [fetchOnboarding, params.token]);

  useEffect(() => {
    if (onboardingInfo && onboardingInfo.email) {
      setEmail(onboardingInfo.email);
    }
  }, [onboardingInfo]);

  const handleLogoutButtonClick = useCallback(() => {
    logout();
  }, [logout]);

  const handleContinueOnboardingButtonClick = useCallback(
    (userEmail: string, isEmailConfirmationRequired: boolean) => {
      setIsSubmitting(true);
      debugger;

      tryLogin({
        email: userEmail,
        returnPath: getOnboardingContinuePath(),
        fallbackPath: isEmailConfirmationRequired ? getConfirmEmailAndRedirectLink(userEmail) : getSignupPath(),
      });
    },
    [tryLogin, getOnboardingContinuePath, getSignupPath, getConfirmEmailAndRedirectLink],
  );

  const { messageCallToAction, messageHello } = useMemo(() => {
    const { enterpriseAgreement = null } = onboardingInfo || {};

    let cta = 'Press continue to get started';
    let hello = 'Your first three devices are always free';

    if (enterpriseAgreement) {
      cta = get(enterpriseAgreement, 'customMessages.signUpCallToActionMessage', cta);
      hello = get(enterpriseAgreement, 'customMessages.signUpLandingMessage', hello);
    }

    return {
      messageHello: hello,
      messageCallToAction: cta,
    };
  }, [onboardingInfo]);

  const renderAnonymousContinueSection = useCallback(() => {
    if (onboardingInfo && onboardingInfo.email) {
      return (
        <div>
          <Label dangerouslySetInnerHTML={{ __html: messageCallToAction }} />
          <p>
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: messageHello }} />
          </p>
          <FormRow>
            <EmailContainer>
              Sign up as <strong>{onboardingInfo.email}</strong>
            </EmailContainer>
            <AppButton
              onClick={() =>
                handleContinueOnboardingButtonClick(onboardingInfo.email as string, onboardingInfo.isEmailConfirmationRequired || false)
              }
              loading={isSubmitting}
              htmlType="submit"
            >
              <span>Continue</span>
            </AppButton>
          </FormRow>
        </div>
      );
    }

    return (
      <form onSubmit={handleEmailFormSubmit}>
        <Label htmlFor="email">Enter your email address to get started</Label>
        <p>Plus your first three devices are always free</p>
        <FormRow>
          <Input
            id="email"
            type="email"
            placeholder="you@company.com"
            value={email}
            onChange={handleEmailFieldChange}
          />
          <AppButton loading={isSubmitting} htmlType="submit">
            <span>Continue</span>
          </AppButton>
        </FormRow>
        <div>{formError}</div>
      </form>
    );
  }, [
    email,
    formError,
    handleContinueOnboardingButtonClick,
    handleEmailFieldChange,
    handleEmailFormSubmit,
    isSubmitting,
    messageCallToAction,
    messageHello,
    onboardingInfo,
  ]);

  const renderUserContinueSection = useCallback(() => {
    if (!user || !onboardingInfo) {
      return null;
    }

    const isEnterpriseAgreementFlow = !!(
      onboardingInfo && onboardingInfo.enterpriseAgreement
    );
    const isContinueAsExistingUserPossible =
      !isEnterpriseAgreementFlow || onboardingInfo.email === user.email;

    return (
      <div>
        {/* eslint-disable-next-line react/no-danger */}
        <Label>Press continue to get started</Label>
        <p>Your first three devices are always free</p>
        {!isContinueAsExistingUserPossible && (
          <ContinueWarningAlert
            message="Setup problem"
            description={
              <span>
                The setup can only be completed as <b>{onboardingInfo.email}</b>. <br />{' '}
                You are already logged in as another user.
                <Button onClick={handleLogoutButtonClick} size="small" type="link">
                  Please log out to continue.
                </Button>
              </span>
            }
            type="warning"
            showIcon
          />
        )}
        <FormRow>
          <EmailContainer>
            <strong>{user.email}</strong>
          </EmailContainer>
          <LogoutButton onClick={handleLogoutButtonClick} size="small" type="link">
            Not you?
          </LogoutButton>
          <AppButton
            disabled={!isContinueAsExistingUserPossible}
            loading={isSubmitting}
            onClick={() => handleContinueOnboardingButtonClick(user.email, false)}
          >
            <span>Continue</span>
          </AppButton>
        </FormRow>
      </div>
    );
  }, [
    user,
    onboardingInfo,
    handleLogoutButtonClick,
    isSubmitting,
    handleContinueOnboardingButtonClick,
  ]);

  if (!params.token || error) {
    return <ErrorView />;
  }

  const hasVoucher = !!(onboardingInfo && onboardingInfo.voucher);

  return (
    <>
      <Global
        styles={{
          body: {
            height: 'fill-available',
          },
          '#root': {
            minHeight: '100%',
            height: '100%',
          },
        }}
      />
      <Container>
        <Masthead>
          {hasVoucher && <Logo src={ImgOmboriGridLogo} alt="Ombori Grid" />}
        </Masthead>
        {loaded && (
          <>
            <Main>
              {hasVoucher && <Voucher voucher={onboardingInfo.voucher as any} />}
              {!hasVoucher && <MainLogo src={ImgOmboriGridLogoLarge} alt="Ombori Grid" />}
            </Main>
            <Form>
              {user ? renderUserContinueSection() : renderAnonymousContinueSection()}
            </Form>
          </>
        )}
      </Container>
    </>
  );
};

const ContinueWarningAlert = styled(Alert)`
  margin: 16px 0;
`;

const slideIn = keyframes`
  from {
    transform: translate(0,100%);
  }
  to {
    transform: translate(0,0);
  }
`;

const LogoutButton = styled(Button)`
  margin: 0 16px;
` as any;

const Masthead = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 24px 0 24px;
`;
const Logo = styled.img`
  display: block;
  height: 48px;
  margin: auto;
`;

const Main = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 48px;
  max-width: 768px;
  min-height: 200px;

  @media (min-width: 768px) {
    flex: inherit;
  }
`;

const MainLogo = styled.img`
  height: 200px;
`;

const Form = styled.div`
  width: 100%;
  background: #fff;
  padding: 16px 16px 24px 16px;
  display: block;
  transform: translate(0, 100%);
  animation-name: ${slideIn};
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  max-width: 768px;

  p {
    margin: 8px 0;
  }
`;
const Label = styled.label`
  width: 100%;
  color: #1d1921;
  font-size: 24px;
  letter-spacing: 0px;
  line-height: 1.3;
  span {
    display: block;
    font-size: 16px;
    color: #676973;
    margin-top: 4px;
    font-weight: normal;
  }
`;
const FormRow = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
`;

const EmailContainer = styled.div`
  flex: 1;
  font-size: 16px;
  padding-right: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Input = styled.input`
  height: 40px;
  border: 1px solid #ccc;
  font-size: 16px;
  border-radius: 4px;
  padding: 0 8px;
  box-shadow: 0 0 0;
  flex: 1;
  margin-right: 10px;

  &:focus,
  &:active,
  &:focus-within,
  &:focus-visible {
    border-color: #5524da;
    outline-color: #5524da;
  }
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: env(safe-area-inset-bottom, 16px);
  background: #1d1921 url(${ImgBrandShape}) 0 0 no-repeat;
  background-size: 250%;
`;

export default OnboardingComponent;
