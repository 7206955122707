export const generateButtonCss = ({
  backgroundColor,
  textColor,
  css = '',
}: {
  backgroundColor?: string;
  textColor?: string;
  css?: string;
}) => {
  const defaultCss = `
        display: flex !important;
        align-items: center;
        height: 40px;
        padding: 0 15px;
        font-size: 16px;
        border-radius: 4px;
        cursor: pointer;
        outline: 0;
        line-height: 1.5;
        font-weight: 400;
        white-space: nowrap;
        text-align: center;
        background-image: none;
        border: 1px solid transparent;
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
        cursor: pointer;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        user-select: none;
        touch-action: manipulation;
        background-color: #FFFFFF;
        color: #2364AA;
        border-color: rgba(0, 0, 0, 0.1);
        margin: 0;
        text-transform: none;
        overflow: visible;

        :not([disabled]):active {
            outline: 0;
            box-shadow: none;
        }
        :not([disabled]):hover {
            text-decoration: none;
        }

        :active {
            ${textColor ? `color: ${textColor}` : '#154785'};
            ${backgroundColor ? `background-color: ${backgroundColor}` : '#FFFFFF'};
            ${backgroundColor ? `border-color: ${backgroundColor}` : '#154785'};
        }

        :hover, :focus {
            color: ${textColor ? textColor : '#4480b8'};
            ${backgroundColor ? `background-color: ${backgroundColor}` : '#FFFFFF'};
            ${backgroundColor ? `border-color: ${backgroundColor}` : '#4480b8'};
        }
    `;

  const prevailingCss = `
        ${textColor ? `color: ${textColor}` : ''};
        ${backgroundColor ? `background-color: ${backgroundColor}` : ''};
        ${backgroundColor ? `border-color: ${backgroundColor}` : ''};
    `;

  return `${defaultCss}${css};${prevailingCss}`;
};
