import {
  DataMatrixColumn,
  DataMatrixColumnDataType,
  DataMatrixColumnOperationType,
} from '@ombori/grid-reports';
import sumBy from 'lodash/sumBy';

interface GetEventsAvgValueProps<EventsType> {
  eventsData: EventsType[];
  eventTypes: string[];
  totalEventTypes: string[];
  distinctKey: string;
  operationType: DataMatrixColumnOperationType.Avg;
}

interface GetEventsLowestValueProps<EventsType> {
  eventsData: EventsType[];
  eventTypes: string[];
  totalEventTypes: string[];
  distinctKey: string;
  operationType: DataMatrixColumnOperationType.Lowest;
}

interface GetColumnValueProps<EventsType> {
  sessionData: { sessionCount: number }[];
  eventsData: EventsType[];
  column: DataMatrixColumn;
  getEventsAvgValue: (props: GetEventsAvgValueProps<EventsType>) => string;
  getEventsLowestValue: (props: GetEventsLowestValueProps<EventsType>) => string;
}

const getColumnValue = <EventsType extends { eventType: string; count: number }>({
  column,
  eventsData,
  sessionData,
  getEventsAvgValue,
  getEventsLowestValue,
}: GetColumnValueProps<EventsType>): number | string => {
  const sumEvents = (eventTypes: string[]) => {
    if (eventTypes.length === 0) {
      return sumBy(eventsData, (event) => event.count);
    }

    const filteredEventsData = eventsData.filter((event) =>
      eventTypes.includes(event.eventType),
    );

    return sumBy(filteredEventsData, (event) => event.count);
  };

  const sumSessions = () => {
    return sumBy(sessionData, (session) => session.sessionCount);
  };

  switch (column.dataType.type) {
    case DataMatrixColumnDataType.Sessions: {
      return sumSessions();
    }

    case DataMatrixColumnDataType.Events: {
      const eventTypes = column.dataType.eventTypes || [];
      const totalEventTypes = column.dataType.totalData
        ? column.dataType.totalData.eventTypes
        : [];

      if (column.dataType.operationType === undefined) {
        return sumEvents(eventTypes);
      }

      switch (column.dataType.operationType.type) {
        case DataMatrixColumnOperationType.Sum: {
          return sumEvents(eventTypes);
        }

        case DataMatrixColumnOperationType.Avg: {
          return getEventsAvgValue({
            eventsData,
            eventTypes,
            totalEventTypes,
            distinctKey: column.dataType.operationType.distinctKey,
            operationType: column.dataType.operationType.type,
          });
        }

        case DataMatrixColumnOperationType.Lowest: {
          return getEventsLowestValue({
            eventsData,
            eventTypes,
            totalEventTypes,
            distinctKey: column.dataType.operationType.distinctKey,
            operationType: column.dataType.operationType.type,
          });
        }

        default:
          throw new Error(
            `Unknown data matrix column operation type ${column.dataType.operationType}`,
          );
      }
    }

    default:
      throw new Error(`Unknown data matrix column data type ${column.dataType}`);
  }
};

export default getColumnValue;
