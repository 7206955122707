import React, { useCallback, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SchemaForm from '../../../../common/schema-form/schema-form.component';
import useSchemaForm from '../../../../common/use-schema-form/use-schema-form';
import { buildFieldSchema, buildUiSchema } from './build-schema';
import { SocialMediaTypes, TenantSettings } from '../../../../../store/types/branding';

type SocialMediaProps = RouteComponentProps<{ organisationId: string }> & {
  onFormChangeHandler: (data: Partial<TenantSettings>) => void;
  socialMediaSettings: Partial<SocialMediaTypes>;
};

const SocialMedia = (props: SocialMediaProps) => {
  const { match, socialMediaSettings, onFormChangeHandler } = props;
  const {
    params: { organisationId: tenantId },
  } = match;

  const { t } = useTranslation();

  const { fieldSchema, uiSchema } = useMemo(() => {
    return {
      fieldSchema: buildFieldSchema(t),
      uiSchema: buildUiSchema(t),
    };
  }, [t]);

  const [, , , formInit, handleFormChange, handleSubmittedForm] = useSchemaForm(
    async (data) => {},
    t('brandingSocialMedia.successSavingData'),
    t('brandingSocialMedia.errorSavingData'),
  );

  useEffect(() => {
    if (socialMediaSettings) {
      formInit(socialMediaSettings);
    }
  }, [formInit, socialMediaSettings]);

  const handleFormDataChange = useCallback(
    (formDataChange) => {
      onFormChangeHandler(formDataChange);
      handleFormChange(formDataChange);
    },
    [onFormChangeHandler, handleFormChange],
  );

  return (
    <SchemaForm
      organisationId={tenantId}
      schema={fieldSchema}
      uiSchema={uiSchema}
      data={socialMediaSettings}
      onSubmit={handleSubmittedForm}
      onChange={handleFormDataChange}
    />
  );
};

export default SocialMedia;
