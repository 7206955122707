import React, { useCallback, useState } from 'react';
import {
  Row,
  Col,
  Typography,
  Select,
  Radio,
  DatePicker,
  Divider,
  Button,
} from 'antd';
import { FormApi } from 'final-form';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import moment from 'moment';
import PanelCard from '../../../common/panel-card/panel-card.component';
import { requiredAll, requiredNumber } from '../../../organisations/organisation-details/queue-details/queue-setup/validate-form';
import { FormTextInput } from '../../../organisations/organisation-details/queue-details/queue-setup/styled-block';
import { InputWrap, composeValidators, validateCharacterLength } from '../../../common/react-final-form';
import { ArrayFields } from '../../../common/react-final-form';
import { BillingCurrencyEnum } from '../../../../store/types/organisation';
import { ChangeEvent } from '../../../../types';
import {
  SkuRevenueTypeOptions,
  SkuUnitOptions,
  SkuPriceListOptions,
} from './common/sku-field-options';
import { StickyColumn } from '../../../organisations/organisation-details/queue-details/queue-setup/styled-block';
import { ButtonType } from '../../../../types';
import {
  SkuAvailability,
  SkuTags,
  SkuPayload,
} from '../../../common/use-skus';
import { isNumber } from '../../../common/react-final-form';
import { getGridIntegerPrice } from '../../../../utils/currency';

interface ErrorFormat {
  _error: string;
}

export interface SkuApiValidationErrors {
  sku?: string;
}

export interface SkuValidationErrors {
  sku?: string;
  availability?: string;
  name?: string;
  unit?: string;
  revenueType?: string;
  priceInformation?: ErrorFormat[] | PriceInformationValidationError[];
}

interface PriceInformationValidationError {
  startDate?: string;
  endDate?: string;
  prices?: ErrorFormat[] | PriceValidationError[];
}

interface PriceValidationError {
  currency?: string;
  value?: string;
}

interface SkuFormPrice {
  currency: BillingCurrencyEnum;
  value?: number;
}

export interface SkuFormPriceInformation {
  startDate: string;
  endDate: string;
  prices: SkuFormPrice[];
}

export interface SkuFormValues {
  id?: string;
  sku: string;
  name: string;
  unit: string;
  availability: SkuAvailability;
  tags?: SkuTags;
  revenueType: string;
  priceInformation: SkuFormPriceInformation[];
}

interface SkuFormFieldsProps {
  title: string;
  initialValues: SkuFormValues;
  onFormSubmit: (
    values: SkuPayload | Object,
  ) => Promise<null | SkuApiValidationErrors>;
}

export const PricesFieldsForAllCurrencies = Object.entries(
  BillingCurrencyEnum,
).map(([_, label]) => {
  return {
    currency: label,
  };
});

const formatPrices = (prices: SkuFormPrice[]) => {
  return prices.map((price) => {
    return {
      currency: price.currency,
      value: getGridIntegerPrice(price.value!),
    };
  });
};

const formatPriceInformation = (priceInformation: SkuFormPriceInformation[]) => {
  return priceInformation.map((priceInfo) => {
    return {
      ...priceInfo,
      prices: priceInfo.prices ? formatPrices(priceInfo.prices) : [],
    };
  });
};

const SkuFormFields = (props: SkuFormFieldsProps) => {
  const { title, initialValues, onFormSubmit } = props;

  const { t } = useTranslation();

  const defaultRevenueType = SkuRevenueTypeOptions[0].value;
  const defaultUnit = SkuUnitOptions[0].value;

  const handleFormSubmit = useCallback(
    async (values: SkuFormValues | Object, form: FormApi) => {
      setIsFormSubmitting(true);

      const {
        id,
        sku,
        name,
        unit,
        availability,
        tags,
        revenueType,
        priceInformation,
      } = values as SkuFormValues;

      const result = await onFormSubmit({
        ...(id && { id }),
        sku,
        name,
        unit,
        availability,
        tags,
        revenueType,
        priceInformation: formatPriceInformation(priceInformation),
      });

      setIsFormSubmitting(false);

      return result;
    },
    [onFormSubmit],
  );

  const handleValidation = useCallback((values: SkuFormValues | Object) => {
    const formData = values as SkuFormValues;

    let validationErrors: SkuValidationErrors = {};

    if (!formData.priceInformation.length) {
      validationErrors.priceInformation = [
        { _error: t('formValidation.atLeastOneEntryRequired') },
      ];
    } else {
      validationErrors.priceInformation = [];

      // loop over each priceInformation section to check its errors
      formData.priceInformation.forEach(
        (priceInformation, index) => {
          const errors: PriceInformationValidationError = {};
          if (!priceInformation.prices.length) {
            errors.prices = [
              {
                _error: t('formValidation.atLeastOneEntryRequired'),
              },
            ];
          } else {
            const priceErrors = [] as PriceValidationError[];
            priceInformation.prices.forEach((price, index) => {
              priceErrors.push({});
              if (!isNumber(price.value!)) {
                priceErrors[index].value =
                  'enter a valid decimal value';
              }
            });

            errors.prices = priceErrors;
          }

          const { startDate, endDate } = priceInformation;
          if (startDate && endDate && startDate >= endDate) {
            errors.endDate = 'should be after start date';
          }

          validationErrors.priceInformation![index] = errors;
        },
      );
    }

    return validationErrors;
  }, [t]);

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  return (
    <>
      <Row gutter={{ md: 20, xl: 40 }}>
        <Col md={40} xl={15}>
          <FormFieldsContainer>
            <TitleContainer>
              <Typography.Title level={3}>{title}</Typography.Title>
            </TitleContainer>

            <Form
              onSubmit={handleFormSubmit}
              validate={handleValidation}
              initialValues={initialValues}
              mutators={{
                ...arrayMutators,
              }}
              render={({
                handleSubmit,
              }) => {
                return (
                  <form id='sku-form' onSubmit={handleSubmit}>
                    <Row>
                      <Col span={24}>
                        <Row>
                          <Field
                            name="sku"
                            parse={(value: string) => value ? value.toUpperCase() : ''}
                            validate={requiredAll}
                            render={({ input, meta }) => (
                              <FormTextInput
                                input={input}
                                meta={meta}
                                disabled={initialValues.id ? true : false}
                                label={t('sku')}
                                placeholder={'GAS001'}
                                required
                                type="text"
                              />
                            )}
                          />
                        </Row>

                        <Row>
                          <Field
                            name="availability"
                            render={({ input, meta }) => (
                              <InputWrapContainer
                                label={t('billingSkus.availability')}
                                isRequired={true}
                                error={
                                  !!meta.error && meta.touched
                                    ? meta.error
                                    : null
                                }
                              >
                                <Radio.Group
                                  defaultValue="private"
                                  value={input.value ? input.value : 'private'}
                                  onChange={({ target: { value } }) => {
                                    input.onChange({
                                      target: {
                                        value,
                                      },
                                    } as unknown as ChangeEvent<string>);
                                  }}
                                >
                                  <Radio value="private">
                                    {t('billingSkus.private')}
                                  </Radio>
                                  <Radio value="public">
                                    {t('billingSkus.public')}
                                  </Radio>
                                </Radio.Group>
                              </InputWrapContainer>
                            )}
                          />
                        </Row>

                        <Row>
                          <Field
                            name="name"
                            validate={composeValidators([requiredAll, validateCharacterLength(3, 200)])}
                            render={({ input, meta }) => (
                              <FormTextInput
                                input={input}
                                meta={meta}
                                label={t('name')}
                                placeholder={'Signage Playlist'}
                                required
                              />
                            )}
                          />
                        </Row>

                        <Row>
                          <Field
                            name="unit"
                            validate={requiredAll}
                            render={({ input, meta }) => (
                              <InputWrapContainer
                                label="Unit"
                                isRequired={true}
                                error={
                                  !!meta.error && meta.touched
                                    ? meta.error
                                    : null
                                }
                              >
                                <Select
                                  defaultValue={defaultUnit}
                                  value={
                                    input.value ? input.value : defaultUnit
                                  }
                                  onChange={(value) => {
                                    input.onChange({
                                      target: {
                                        value,
                                      },
                                    } as unknown as ChangeEvent<string>);
                                  }}
                                >
                                  {SkuUnitOptions.map((unit) => {
                                    return (
                                      <Select.Option
                                        key={unit.value}
                                        value={unit.value}
                                      >
                                        {unit.label}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </InputWrapContainer>
                            )}
                          />
                        </Row>

                        <Row>
                          <Field
                            name="tags.pricingSection"
                            render={({ input }) => (
                              <InputWrapContainer
                                label="Price List Section"
                                isRequired={false}
                                error={''}
                              >
                                <Select
                                  allowClear={true}
                                  value={input.value ? input.value : undefined}
                                  placeholder={'Please select'}
                                  onChange={(value) => {
                                    input.onChange({
                                      target: {
                                        value: value,
                                      },
                                    } as unknown as ChangeEvent<string>);
                                  }}
                                >
                                  {SkuPriceListOptions.map((price) => (
                                    <Select.Option
                                      key={price.value}
                                      value={price.value}
                                    >
                                      {price.label}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </InputWrapContainer>
                            )}
                          />
                        </Row>

                        <Row>
                          <Field
                            name="revenueType"
                            render={({ input, meta }) => (
                              <InputWrapContainer
                                label="Revenue Type"
                                isRequired={true}
                                error={
                                  !!meta.error && meta.touched
                                    ? meta.error
                                    : null
                                }
                              >
                                <Select
                                  defaultValue={defaultRevenueType}
                                  value={
                                    input.value
                                      ? input.value
                                      : defaultRevenueType
                                  }
                                  onChange={(value) => {
                                    input.onChange({
                                      target: {
                                        value,
                                      },
                                    } as unknown as ChangeEvent<string>);
                                  }}
                                >
                                  {SkuRevenueTypeOptions.map((revenueType) => {
                                    return (
                                      <Select.Option
                                        key={revenueType.value}
                                        value={revenueType.value}
                                      >
                                        {revenueType.label}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </InputWrapContainer>
                            )}
                          />
                        </Row>

                        <DividerFull />

                        <Row>
                          <Typography.Title level={4}>
                            Price Information
                          </Typography.Title>
                          <ArrayFields
                            propertyName="priceInformation"
                            defaultValue={{
                              startDate: '',
                              endDate: '',
                              prices: PricesFieldsForAllCurrencies,
                            }}
                            itemRowContent={(parentPropertyName: string) => {
                              return (
                                <PriceInformationContainer>
                                  <Col span={12}>
                                    <Field
                                      name={`${parentPropertyName}.startDate`}
                                      validate={requiredAll}
                                      render={({ input, meta }) => (
                                        <InputWrapContainer
                                          label="Start date"
                                          isRequired={true}
                                          error={
                                            !!meta.error && meta.touched
                                              ? meta.error
                                              : null
                                          }
                                        >
                                          <DatePicker
                                            value={
                                              input.value
                                                ? moment(input.value)
                                                : undefined
                                            }
                                            onChange={(_, dateStr) => {
                                              input.onChange({
                                                target: {
                                                  value: dateStr,
                                                },
                                              } as unknown as ChangeEvent<string>);
                                            }}
                                          />
                                        </InputWrapContainer>
                                      )}
                                    />
                                  </Col>

                                  <Col span={12}>
                                    <Field
                                      name={`${parentPropertyName}.endDate`}
                                      render={({ input, meta }) => (
                                        <InputWrapContainer
                                          label="End date"
                                          isRequired={false}
                                          error={
                                            !!meta.error && meta.touched
                                              ? meta.error
                                              : null
                                          }
                                        >
                                          <DatePicker
                                            value={
                                              input.value
                                                ? moment(input.value).utc(false)
                                                : undefined
                                            }
                                            onChange={(_, dateStr) => {
                                              input.onChange({
                                                target: {
                                                  value: dateStr ? `${dateStr}T23:59:59Z` : '',
                                                },
                                              } as unknown as ChangeEvent<string>);
                                            }}
                                          />
                                        </InputWrapContainer>
                                      )}
                                    />
                                  </Col>

                                  <PricesContainer>
                                    <ArrayFields
                                      propertyName={`${parentPropertyName}.prices`}
                                      canDelete={false}
                                      canAdd={false}
                                      itemRowContent={(
                                        parentPropertyName: string,
                                      ) => {
                                        return (
                                          <>
                                            <Col span={12}>
                                              <Field
                                                name={`${parentPropertyName}.currency`}
                                                validate={requiredAll}
                                                render={({ input, meta }) => (
                                                  <InputWrap
                                                    label="Currency"
                                                    isRequired={true}
                                                    error={
                                                      !!meta.error &&
                                                      meta.touched
                                                        ? meta.error
                                                        : null
                                                    }
                                                  >
                                                    <FormTextInput
                                                      input={input}
                                                      meta={meta}
                                                      disabled={true}
                                                    />
                                                  </InputWrap>
                                                )}
                                              />
                                            </Col>
                                            <Col span={12}>
                                              <Field
                                                name={`${parentPropertyName}.value`}
                                                validate={requiredNumber}
                                                render={({ input, meta }) => (
                                                  <FormTextInput
                                                    input={input}
                                                    meta={meta}
                                                    type='number'
                                                    min='0'
                                                    step='any'
                                                    label="Value"
                                                    required
                                                  />
                                                )}
                                              />
                                            </Col>
                                          </>
                                        );
                                      }}
                                    />
                                    <Divider />
                                  </PricesContainer>
                                </PriceInformationContainer>
                              );
                            }}
                          />
                        </Row>
                      </Col>
                    </Row>
                  </form>
                );
              }}
            />
          </FormFieldsContainer>
        </Col>

        <StickyColumn md={24} xl={9}>
          <PanelCard>
            <SaveButton
              disabled={isFormSubmitting}
              type="primary"
              form='sku-form'
              htmlType="submit"
              size="large"
            >
              {t('saveAllChanges')}
            </SaveButton>
          </PanelCard>
        </StickyColumn>
      </Row>
    </>
  );
};

const FormFieldsContainer = styled(PanelCard)`
  padding: 20px;
  width: 100% !important;
`;

const TitleContainer = styled(Row)`
  margin-bottom: 20px;
`;

const InputWrapContainer = styled(InputWrap)`
  margin-top: 12px;
  margin-bottom: 12px;
`;

const PriceInformationContainer = styled.div`
  margin-top: 20px;
  margin-left: 10px !important;
  width: 100%;

  .ant-calendar-picker {
    width: 100%;
  }
`;

const PricesContainer = styled.div`
  margin-left: 15px !important;
  margin-top: 130px !important;
`;

const DividerFull = styled(Divider)`
  width: calc(100% + 58px);
  margin-left: -29px;
  overflow: none;
`;

const SaveButton = styled(Button)`
  width: 100%;
` as ButtonType;

export default SkuFormFields;
