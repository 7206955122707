import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import { RouteComponentProps } from 'react-router';
import { RootModel, RootState } from '../../../../../../store/models/root.model';
import { getPermissions } from '../../../../../../utils/auth/permissions';
import UniversalDeviceDetailsSettings from './universal-device-details-settings.component';

interface UniversalDeviceDetailsSettingsContainerProps
  extends RouteComponentProps<{ deviceUuid: string; organisationId: string }> {
  azureDeviceUuid: string;
}

const UniversalDeviceDetailsSettingsContainer = (
  props: UniversalDeviceDetailsSettingsContainerProps,
) => {
  const { azureDeviceUuid } = props;

  const {
    match: {
      params: { organisationId, deviceUuid },
    },
  } = props;

  const { azureDevice, universalDevice, user, permissions, azureDeviceLoaded } = useStore<
    RootState
  >(
    (state: RootState) => ({
      azureDevice: state.azureDeviceDetails.data[azureDeviceUuid] || null,
      azureDeviceLoaded: state.azureDeviceDetails.loaded(azureDeviceUuid),
      universalDevice: state.universalDeviceDetails.data[deviceUuid] || null,
      user: state.auth.user,
      permissions: getPermissions(state.auth.user, organisationId),
    }),
    [azureDeviceUuid, deviceUuid, organisationId],
  );

  const { fetchAzureDevice, updateDevice } = useActions<RootModel>((actions) => ({
    fetchAzureDevice: actions.azureDeviceDetails.fetch,
    updateDevice: async (deviceUpdateBody: any) => {
      await actions.azureDeviceDetails.update(deviceUpdateBody);
      await actions.universalDeviceDetails.fetch({ deviceUuid, silent: true });
    },
  }));

  return (
    <UniversalDeviceDetailsSettings
      azureDeviceLoaded={azureDeviceLoaded}
      fetchAzureDevice={fetchAzureDevice}
      azureDevice={azureDevice}
      universalDevice={universalDevice}
      user={user}
      updateDevice={updateDevice}
      canManageDeviceSettings={permissions.devices.manageDeviceSettings}
    />
  );
};

export default UniversalDeviceDetailsSettingsContainer;
