import styled from '@emotion/styled';
import PanelCardComponent from '../../../../../common/panel-card/panel-card.component';

export const PanelCard = styled(PanelCardComponent)`
  padding: 20px;
  margin-bottom: 16px;
  width: 100%;

  .ant-card-head {
    border-bottom: unset;
  }

  .ant-card-body {
    padding: 0;
  }
`;

export const Title = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
`;

export const Description = styled.p`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 8px;
`;
