export const colors = [
  '#1890FF',
  '#13C2C2',
  '#2FC25B',
  '#FACC14',
  '#F04864',
  '#8543E0',
  '#3436C7',
  '#223273',
];

export const extendedColors = [
  '#1890FF',
  '#73C9E6',
  '#13C2C2',
  '#6CD9B3',
  '#2FC25B',
  '#9DD96C',
  '#FACC14',
  '#E6965C',
  '#F04864',
  '#D66BCA',
  '#8543E0',
  '#8E77ED',
  '#3436C7',
  '#737EE6',
  '#223273',
  '#7EA2E6',
];

export function getColor(i: number, max: number) {
  const c = max > colors.length ? extendedColors : colors;
  return c[i >= c.length ? c.length - 1 : i];
}
