import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import { FormApi } from 'final-form';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Input, Select, Validator } from '../../../../../common/react-final-form';
import { getOperatorOptions, getStringOperatorOptions } from '../../../../../../utils/alerts';
import {
  AnalyticsEventFilterTypeEnum,
  AnalyticsEventPropertyEnum,
  AnalyticsEventPropertyNumeric,
  AnalyticsEventPropertyNumericValues,
  AnalyticsEventPropertyString,
  AnalyticsEventPropertyStringValues,
} from '../../../../../../store/types/alerts';
import { requiredNumber } from '../../../queue-details/queue-setup/validate-form';

const eventPropertyOptions = Object.entries(AnalyticsEventPropertyEnum).map(([label, value]) => {
  return {
    label: label,
    value: value,
  };
});

const DeviceAnalyticsEventsCountConditionFilters = (props: {
  parentFieldName: string;
  updateForm: FormApi['change'];
}) => {
  const { parentFieldName, updateForm } = props;

  const { t } = useTranslation();

  const numericOperators = useMemo(() => {
    return getOperatorOptions(t).map(({ id, label }) => {
      return {
        value: id as string,
        label,
      };
    });
  }, [t]);

  const stringOperators = useMemo(() => {
    return getStringOperatorOptions(t).map(({ id, label }) => {
      return {
        value: id as string,
        label,
      };
    });
  }, [t]);

  const requiredNumberValidator: Validator<number> = requiredNumber;

  return (
    <FilterFieldsContainer gutter={[20, 20]}>
      <Field
        name={`${parentFieldName}.type`}
        render={({ input: inputFilterType }) => (
          <>
            <input type="hidden" value={inputFilterType.value} />

            <FilterFields gutter={[20, 20]}>
              <Col span={8}>
                <Select
                  name={`${parentFieldName}.eventProperty`}
                  label={t('alertRules.eventProperty')}
                  options={eventPropertyOptions}
                  isRequired={true}
                  onChange={(value: string) => {
                    // update operators, value when user selects other category of event property
                    if (
                      AnalyticsEventPropertyNumericValues.includes(value as AnalyticsEventPropertyNumeric) &&
                      inputFilterType.value !== AnalyticsEventFilterTypeEnum.NUMERIC
                    ) {
                      updateForm(`${parentFieldName}.type`, AnalyticsEventFilterTypeEnum.NUMERIC);
                      updateForm(`${parentFieldName}.operator`, '');
                      updateForm(`${parentFieldName}.value`, 0);
                    } else if (
                      AnalyticsEventPropertyStringValues.includes(value as AnalyticsEventPropertyString) &&
                      inputFilterType.value !== AnalyticsEventFilterTypeEnum.STRING
                    ) {
                      updateForm(`${parentFieldName}.type`, AnalyticsEventFilterTypeEnum.STRING);
                      updateForm(`${parentFieldName}.operator`, '');
                      updateForm(`${parentFieldName}.value`, '');
                    }
                  }}
                />
              </Col>

              <Col span={8}>
                <Select
                  name={`${parentFieldName}.operator`}
                  label={t('alertRules.operator')}
                  options={
                    inputFilterType.value === AnalyticsEventFilterTypeEnum.NUMERIC
                      ? numericOperators
                      : stringOperators
                  }
                  isRequired={true}
                />
              </Col>

              <Col span={8}>
                {inputFilterType.value === AnalyticsEventFilterTypeEnum.NUMERIC && (
                  <Input<number>
                    name={`${parentFieldName}.value`}
                    label={`${t('alertRules.value')} *`}
                    type="number"
                    validators={[requiredNumberValidator]}
                  />
                )}

                {inputFilterType.value === AnalyticsEventFilterTypeEnum.STRING && (
                  <Input name={`${parentFieldName}.value`} label={t('alertRules.value')} isRequired={true} />
                )}
              </Col>
            </FilterFields>
          </>
        )}
      />
    </FilterFieldsContainer>
  );
};

export default DeviceAnalyticsEventsCountConditionFilters;

const FilterFieldsContainer = styled(Row)`
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  width: 100% !important;
`;

const FilterFields = styled(Row)`
  width: calc(100% + 50px) !important;
  padding-left: 10px;
  padding-right: 13px;
`;
