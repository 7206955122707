import React from 'react';
import styled from '@emotion/styled';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import { convertMetaError } from './utils';
import { Icon } from '../schema-form/common';

export interface InputWrapProps {
  label?: React.ReactNode;
  isRequired?: boolean;
  hint?: React.ReactNode;
}

interface InputWrapExtendedProps extends InputWrapProps {
  className?: string;
  children?: React.ReactNode;
  error?: string | { _error: string } | null;
}

const InputWrap = (props: InputWrapExtendedProps) => {
  const { className, error, isRequired, label, hint, children } = props;

  return (
    <InputWrapContainer
      className={classNames('form-group', !!error && 'has-error has-danger', className)}
    >
      <LabelSection>
        <Label className="control-label">
          {label && (
            <>
              <div>{label}</div>
              {isRequired && <Asterisk className="required">*</Asterisk>}
            </>
          )}
        </Label>
        {hint && (
          <Tooltip title={hint}>
            <Icon type="info-circle" />
          </Tooltip>
        )}
      </LabelSection>

      {children}

      {error && (
        <div>
          <ul className="error-detail bs-callout bs-callout-info">
            <li className="text-danger">{convertMetaError(error)}</li>
          </ul>
        </div>
      )}
    </InputWrapContainer>
  );
};

const InputWrapContainer = styled.div`
  flex-basis: min-content;
`;

const LabelSection = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 3px;
`;

const Label = styled.div`
  display: flex;
  width: auto;
`;

const Asterisk = styled.span`
  margin-left: 2px;
`;

export default InputWrap;
