import * as React from 'react';
import { useActions, useStore } from 'easy-peasy';
import DeviceSetupComponent from './device-setup.component';
import { RootModel, RootState } from '../../../store/models/root.model';

type DeviceSetupContainerProps = Omit<
  React.ComponentProps<typeof DeviceSetupComponent>,
  'organisations' | 'setupTenantForExistingUser'
>;

const DeviceSetupContainer = (props: DeviceSetupContainerProps) => {
  const { organisations } = useStore<RootState>((state) => ({
    organisations: state.organisations.values,
  }));

  const setupTenantForExistingUser = useActions<RootModel>(
    (actions) => actions.onboarding.setupTenantForExistingUser,
  );

  return (
    <DeviceSetupComponent
      setupTenantForExistingUser={setupTenantForExistingUser}
      organisations={organisations}
      {...props}
    />
  );
};

export default DeviceSetupContainer;
