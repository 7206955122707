import React from 'react';
import styled from '@emotion/styled';
import { Icon } from '../../../../../common/schema-form/common';
import googleIcon from '../../../../../../assets/icons/google-icon.svg';
import { IntegrationTypeEnum } from '../types';

const SelectValueOptionIcon = styled(Icon)`
  display: flex;
  align-items: center;
`;

export const integrationTypes: {
  value: IntegrationTypeEnum;
  label?: string;
  icon?: React.ReactNode;
}[] = [
  {
    value: IntegrationTypeEnum.GOOGLE_PRODUCT_FEED,
    label: 'Google Product Feed',
    icon: <SelectValueOptionIcon size={20} src={googleIcon} />,
  },
  {
    value: IntegrationTypeEnum.GOOGLE_LOCAL_INVENTORY,
    label: 'Google Local Inventory',
    icon: <SelectValueOptionIcon size={20} src={googleIcon} />,
  },
];
