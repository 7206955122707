import React from 'react';
import { useStore } from 'easy-peasy';
import GridappInfo from './gridapp-info.component';
import { RootState } from '../../../../../store/models/root.model';

type GridappInfoContainerProps = Pick<
  React.ComponentProps<typeof GridappInfo>,
  'match' | 'history' | 'location'
>;

const GridappInfoContainer = (props: GridappInfoContainerProps) => {
  const {
    match: {
      params: { organisationId, gridappId },
    },
  } = props;

  const { gridapp, organization } = useStore<RootState>(
    (state) => ({
      gridapp: state.organisationAppsLibrary.data[organisationId]
        ? state.organisationAppsLibrary.data[organisationId][gridappId]
        : null,
      organization: state.organisations.data
        ? state.organisations.data[organisationId]
        : null,
    }),
    [organisationId, gridappId],
  );

  return (
    <GridappInfo
      {...props}
      gridapp={gridapp}
      organizationName={organization ? organization.organizationName : null}
    />
  );
};

export default GridappInfoContainer;
