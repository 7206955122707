import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { Menu, Dropdown, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { ButtonType } from '../../../../../types';
import { permissionKeys } from '../../../../../utils/auth/permissions';
import useDeleteModal from '../../../../common/use-delete-modal';
import { Icon } from '../../../../common/schema-form/common';
import useHasPermissions from '../../../../../utils/auth/use-has-permissions';
import useOrganizationUsersDelete from '../../../../../store/hooks/organization-users/use-organization-users-delete';
import { useLocation, useHistory } from 'react-router-dom';
import TenantUser from '../../../../../store/types/tenant-user';


interface ActionComponentProps {
  user: TenantUser;
  organizationId: string;
}

const OrganizationUserListItemActions = (props: ActionComponentProps) => {
  const { user, organizationId } = props;

  const { t } = useTranslation();
  const [showDeleteModal] = useDeleteModal();
  const isDeleteAllowed = useHasPermissions(organizationId, permissionKeys.users.remove);
  const isEditAllowed = useHasPermissions(organizationId, permissionKeys.users.update);
  const { mutateAsync: deleteUser } = useOrganizationUsersDelete();
  const { pathname } = useLocation();
  const history = useHistory();

  const handleDelete = useCallback(() => {
    showDeleteModal(
      t('userManagement.removeUserFromTenantActionTitle'),
      t('userManagement.removeUserFromTenantActionBody', { userEmail: user.email }),
      undefined,
      async () => {
        try {
          await deleteUser({ organizationId: organizationId, userId: user.id });
          message.success(`${t('userManagement.removeSuccessMessage')}`);
        } catch {
          message.error(t('userManagement.removeFailureMessage'));
        }
      },
    );
  }, [user, showDeleteModal, t, organizationId, deleteUser]);


  const handleEdit = useCallback(
    (userId: string) => {
      history.push(pathname + '/' + userId);
    },
    [history, pathname],
  );

  return (
    <Dropdown
      placement="bottomRight"
      overlay={
        <Menu>
          {isEditAllowed && (
            <Menu.Item key={`${user.id}-edit`}>
              <EditButton
                type="link"
                icon="edit"
                size="small"
                value={user.id}
                onClick={() => handleEdit(user.id)}
              >
                {t('edit')}
              </EditButton>
            </Menu.Item>
          )}
          {isDeleteAllowed && (
            <Menu.Item key={`${user.id}-remove`}>
              <RemoveButton
                type="link"
                icon="delete"
                size="small"
                value={user.id}
                onClick={handleDelete}
              >
                {t('delete')}
              </RemoveButton>
            </Menu.Item>
          )}
        </Menu>
      }
    >
      <StyledIcon type="small-dash" />
    </Dropdown>
  );
};

const RemoveButton = styled(Button)`
  color: rgb(255, 85, 0);
  text-transform: capitalize;

  &:hover,
  &:active,
  &:focus {
    color: rgb(255, 85, 0);
  }
` as ButtonType;

const EditButton = styled(Button)`
  color: rgb(35, 100, 170);
  text-transform: capitalize;

  &:hover,
  &:active,
  &:focus {
    color: rgb(40, 113, 194);
  }
` as ButtonType;

const StyledIcon = styled(Icon)`
  transform: rotate(90deg);
`;

export default OrganizationUserListItemActions;
