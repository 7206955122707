import {
  DataResidencyEnum,
  GridProduct,
  GridProductServiceClient,
  IsoLanguageIds,
} from '@ombori/grid-products';
import { useQuery, UseQueryResult } from 'react-query';

interface UseProductsListProps {
  tenantId: string;
  dataResidency: DataResidencyEnum;
  env: string;
  enabled: boolean;
}

const useProductsList = ({
  tenantId,
  dataResidency,
  env,
  enabled,
}: UseProductsListProps): UseQueryResult<{ list: Partial<GridProduct>[] }, unknown> => {
  const gridProductsClient = new GridProductServiceClient({
    dataResidency: dataResidency as DataResidencyEnum,
    environment: env,
    tenantId,
    locale: IsoLanguageIds.en_GB,
  });

  return useQuery(
    ['productList', tenantId, dataResidency, env],
    () =>
      gridProductsClient.getProductList({
        select:
          'productGroupId,variants,catalogPageLocationProduct,productPriceList,productName',
        limit: 50,
        isSourceSearch: 'true',
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      staleTime: 600000, // 10 min stale time
      enabled,
    },
  );
};

export default useProductsList;
