import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Row, Typography } from 'antd';
import styled from '@emotion/styled';
import { FormApi } from 'final-form';
import {
  ArrayFields,
  Input,
  SelectedFile,
  TextArea,
  validateCharacterLength,
  validateEntriesMaxLength,
  validateRequired,
  validateStandardName,
} from '../../../../../common/react-final-form';
import { Description, PanelCard, Title } from '../store-ai-assistants-common.component';
import { Divider } from '../../common';
import { KnowledgeBaseFileModal } from '../../knowledge-base';
import { GridAssistantStatusEnum, GridKnowledgeSource } from '@ombori/grid-llm-react';
import { FormSpy } from 'react-final-form';
import { ButtonType } from '../../../../../../types';
import useStoreAiAssistantKnowledgeBaseFiles from '../../../../../../store/hooks/store-ai/use-store-ai-assistant-knowledge-base-files';
import Overlay from '../../../../../common/overlay/overlay-v2.component';

interface KnowledgeBaseFilesFormValues {
  fileId: string;
  fileName: string;
}

export interface StoreAIAssistantFormValues {
  name: string;
  instructions: string;
  status: GridAssistantStatusEnum;
  knowledgeBaseFiles?: KnowledgeBaseFilesFormValues[];
}

interface StoreAIAssistantFormFieldsProps {
  formApi: FormApi;
  tenantId: string;
  assistantId?: string;
  title: string;
  isViewOnly?: boolean;
}

const StoreAIAssistantFormFields = (props: StoreAIAssistantFormFieldsProps) => {
  const { formApi, tenantId, assistantId, title, isViewOnly = false } = props;

  const {
    mutators: { push, setValue },
    getFieldState,
  } = formApi;

  const [isKnowledgeBaseFileModalOpen, setIsKnowledgeBaseFileModalOpen] = useState<
    boolean
  >(false);

  const [isKnowledgeBaseEmpty, setIsKnowledgeBaseEmpty] = useState<boolean>(true);

  const { t } = useTranslation();

  const {
    data: knowledgeBaseFilesData = [],
    isLoading: isKnowledgeBaseFilesLoading,
    isFetching: isKnowledgeBaseFilesFetching,
    error: knowledgeBaseFilesError,
    isSuccess: isKnowledgeBaseFilesSuccess,
  } = useStoreAiAssistantKnowledgeBaseFiles(assistantId, !!assistantId && !isKnowledgeBaseEmpty);

  const isKnowledgeBaseFilesProcessing = isKnowledgeBaseFilesLoading || isKnowledgeBaseFilesFetching;

  const handleKnowledgeBaseFileModalOpen = useCallback(
    (): void => setIsKnowledgeBaseFileModalOpen(true),
    [],
  );

  const handleKnowledgeBaseFileModalClose = useCallback((): void => {
    setIsKnowledgeBaseFileModalOpen(false);
  }, []);

  const getKnowledgeBaseFiles = useCallback((): { fileId: string; fileName: string }[] => {
    const { value = [] } = getFieldState('knowledgeBaseFiles') || {};

    return value as { fileId: string; fileName: string }[];
  }, [getFieldState]);

  useEffect(() => {
    if (!isKnowledgeBaseFilesSuccess || !knowledgeBaseFilesData.length) {
      return;
    }

    const files = getKnowledgeBaseFiles();
    
    const updatedFileNames = files.map((file) => {
      const matchedFile = knowledgeBaseFilesData.find((knowledgeBaseFile) => knowledgeBaseFile.id === file.fileId);
      const { name: matchedFileName = '' } = matchedFile || {};

      return {
        fileId: file.fileId,
        fileName: matchedFileName || file.fileId,
      }
    });

    setValue('knowledgeBaseFiles', updatedFileNames);
  }, [getKnowledgeBaseFiles, knowledgeBaseFilesData, isKnowledgeBaseFilesSuccess, setValue]);

  const handleKnowledgeBaseFileSelect = useCallback(
    (file: GridKnowledgeSource): void => {
      const knowledgeBaseFiles = getKnowledgeBaseFiles();

      const isFileExisting = knowledgeBaseFiles.some(
        (knowledgeBaseFile) => knowledgeBaseFile.fileId === file.id,
      );

      if (!isFileExisting) {
        push(`knowledgeBaseFiles`, { fileId: file.id, fileName: file.name });
      }

      setIsKnowledgeBaseFileModalOpen(false);
    },
    [getKnowledgeBaseFiles, push],
  );

  return (
    <>
      <Row>
        <PanelCard>
          <Row>
            <FormTitle level={3}>{title}</FormTitle>
            <Description>{t('storeAI.assistantDescription')}</Description>
          </Row>
          <Divider />
          <Row>
            <Input
              name="name"
              label={t('storeAI.assistantName')}
              isRequired
              disabled={isViewOnly}
              validators={[validateCharacterLength(3, 200), validateStandardName]}
            />
          </Row>
          <Divider />
          <Row>
            <TextArea
              name="instructions"
              label={t('storeAI.instructions')}
              placeholder={t('storeAI.assistantInstructionsPlaceholder')}
              isRequired
              disabled={isViewOnly}
              validators={[validateCharacterLength(3, 7000)]}
            />
          </Row>
        </PanelCard>
      </Row>
      <Row>
        <PanelCard>
          <Row>
            <Title>{t('storeAI.knowledgeBase')}</Title>
            <Description>{t('storeAI.knowledgeBaseDescription')}</Description>
          </Row>
          {knowledgeBaseFilesError && !isKnowledgeBaseFilesProcessing && (
            <Alert type="error" showIcon message={t('storeAI.errorFetchingKnowledgeBaseFileNames')} />
          )}
          <Overlay
            spinnerOverlay={{
              isLoading: isKnowledgeBaseFilesProcessing,
              processingTip: t('storeAI.fetchingKnowledgeBaseFileNames'),
            }}
          >
            <ArrayFieldsKnowledgeBase
              propertyName="knowledgeBaseFiles"
              canAdd={false}
              canDelete={!isViewOnly}
              validators={[validateEntriesMaxLength(10)]}
              itemRowContent={(propertyName) => {
                return (
                  <SelectedFileStyled
                    name={propertyName}
                    validators={[validateRequired(t('knowledgeBase.invalidFileInfo'))]}
                  />
                );
              }}
              emptyContent={
                isKnowledgeBaseEmpty && isViewOnly ? (
                  <EmptyKnowledgeBaseMessage>
                    {t('storeAI.emptyKnowledgeBase')}
                  </EmptyKnowledgeBaseMessage>
                ) : (
                  undefined
                )
              }
            />
            {!isViewOnly && (
              <>
                <AddButton
                  type="link"
                  icon="plus"
                  onClick={handleKnowledgeBaseFileModalOpen}
                >
                  {t('add')}
                </AddButton>
                <KnowledgeBaseFileModal
                  tenantId={tenantId}
                  assistantId={assistantId}
                  title={t('storeAI.knowledgeBase')}
                  isOpen={isKnowledgeBaseFileModalOpen}
                  isLibraryInitialized
                  onCancel={handleKnowledgeBaseFileModalClose}
                  onFileSelect={handleKnowledgeBaseFileSelect}
                />
              </>
            )}
          </Overlay>
        </PanelCard>
      </Row>
      <FormSpy subscription={{ values: true }}>
        {() => {
          const files = getKnowledgeBaseFiles();

          setIsKnowledgeBaseEmpty(!files.length);

          return null;
        }}
      </FormSpy>
    </>
  );
};

const FormTitle = styled(Typography.Title)`
  margin-bottom: 8px !important;
`;

const ArrayFieldsKnowledgeBase = styled(ArrayFields)`
  margin-top: 10px;

  .array-fields-items-filled {
    display: flex;
    flex-flow: column;
    gap: 5px;
  }

  .array-fields-item {
    background: #fafafa;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    padding: 10px;
  }
`;

const AddButton = styled(Button)`
  margin-top: 10px;
` as ButtonType;

const SelectedFileStyled = styled(SelectedFile)`
  .selected-file-name > span {
    cursor: auto;

    &, &:focus, &:hover {
      color: rgba(0, 0, 0, 0.65);
    }
  }
`;

const EmptyKnowledgeBaseMessage = styled.div`
  display: flex;
  justify-content: center;
  background: #fafafa;
  color: rgba(0,0,0,0.45);
  padding: 20px;
`;

export default StoreAIAssistantFormFields;
