import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Tooltip } from 'antd';
import * as antColors from '@ant-design/colors';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const colors: { [name: string]: string } = {
  ok: antColors.green[6] || 'darkgreen',
  offline: antColors.grey[0] || '#aaa',
  noedge: antColors.red[4] || 'red',
  noscreen: antColors.magenta.primary || 'magenta',
  noagent: antColors.yellow.primary || 'pink',
  noshot: antColors.orange.primary || 'orange',
  noreboot: antColors.cyan.primary || 'cyan',
  updating: antColors.blue.primary || 'blue',
  backoff: antColors.orange.primary || 'orange',
  mnok: antColors.purple.primary || 'purple',
}

const codes: { [char: string]: string } = {
  '-': 'offline',
  '+': 'ok',
  a: 'noagent',
  e: 'noedge',
  S: 'noshot',
  s: 'noscreen',
  h: 'nohub',
  r: 'noreboot',
  u: 'updating',
  b: 'backoff',
  m: 'mnok',
};

const ProgressContainer = styled.div<{ narrow: boolean, small: boolean }>`
  overflow: hidden;
  width: ${p => p.narrow ? "112px" : "288px"};
  max-width: ${p => p.narrow ? "144px" : "288px"};
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  border-radius: 4px;
  margin-right: .5em;
  margin-top: ${p => p.small ? "7px" : 0};
  margin-bottom: ${p => p.small ? "5px" : 0};
  color: ${p => p.small ? "#0000" : "auto"};
  height: ${p => p.small ? "10px" : "auto"};
  vertical-align: middle;
  cursor: default;
  // &:hover {
  //   height: 22px;
  //   margin-top: 0;
  //   margin-bottom: 0;
  //   color: inherit;
  // }
`

const ProgressItem = styled.span`
  padding: 0 6px 2px 6px;
  position: absolute;
  top: 0;
  display: inline-block;
  width: 300px;
`

const MIN5 = (5 * 60 * 1000);
export function DeviceStatusHistory({ history = '', updateTime, small = false, narrow = false }: { updateTime: string, history: string, small?: boolean, narrow?: boolean }) {
  const { t } = useTranslation();
  const statuses = useMemo(() => {
    // filter out some garbage data
    let d = history
      .replace(/_.([a-zA-Z+-])_/g, "__$1_") // remove 2 consequental state changes
      .replace(/_..([a-zA-Z+-])_/g, "___$1_"); // remove 3 consequental state changes
    while (/([a-zA-Z+-])_+\1/g.test(d)) { // remove entries where state did not change
      d = d.replace(/([a-zA-Z+-])(_+)\1/g, "$1$2_");
    }
    const d2 = d.split('');
    let t = new Date(updateTime).getTime();
    const now = Date.now();
    while ((now - t) > MIN5) {
      t += MIN5;
      d2.push('_');

      const item = d2.shift();
      if (item && d2[0] === '_') d2[0] = item;
    }
    return d2;
  }, [history, updateTime]);

  const date = new Date(Math.floor(Date.now() / MIN5) * MIN5).getTime() - (MIN5 * 288);

  return (
    <ProgressContainer small={small} narrow={narrow}>
      {
        statuses.map((st, off) => {
          if (st === '_') return null;
          const status = codes[st] || st;
          return (
            <Tooltip key={off} title={`${t(`deviceStatus.${status}`)}, ${moment(date + MIN5 * off).format("HH:mm")}`} placement='topLeft' >
              <ProgressItem style={{
                backgroundColor: colors[status] || 'red',
                left: narrow ? 0.5 * off : off,
                position: off === 0 ? 'relative' : 'absolute'
              }}>
                {t(`deviceStatus.${status}`)}
              </ProgressItem>
            </Tooltip>
          )
        })
      }
    </ProgressContainer >
  )
}

const StatusContainer = styled.span<{ color: string }>`
  overflow: hidden;
  white-space: nowrap;
  max-width: 70px;
  text-overflow: ellipsis;
  color: ${p => p.color};
`

export function Status({ status }: { status: string }) {
  const { t } = useTranslation();
  if (status === 'enabled') status = 'offline';
  return <StatusContainer color={colors[status] || 'red'}>{t(`deviceStatus.${status}`)}</StatusContainer>
}

export default {};