import React, { useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AnalyticsEmbed from '../../../store/types/analytics-embed';
import EmbeddedReport from './embedded-report/embedded-report.component';
import ErrorView from '../error-view/error-view.component';
import Overlay from '../overlay/overlay.component';
import Spinner from '../spinner/spinner.component';
import ErrorBoundary from '../error-boundary/error-boundary.component';

interface Report
  extends RouteComponentProps<{
    appId: string;
    organisationId: string;
    spaceId: string;
  }> {
  embed: AnalyticsEmbed;
  reportName?: string;
  loaded: boolean;
  error: boolean;
  fetchEmbed: () => void;
}

export default function Report({ embed, loaded, error, fetchEmbed }: Report) {
  useEffect(() => {
    fetchEmbed();
  }, [fetchEmbed]);

  const onReportLoaded = useCallback(() => {
    // Define what do here
  }, []);

  if (error) {
    return <ErrorView />;
  }

  if (!loaded) {
    return (
      <Overlay>
        <Spinner />
      </Overlay>
    );
  }

  return (
    <ErrorBoundary>
      <EmbeddedReport embed={embed} onLoaded={onReportLoaded} />
    </ErrorBoundary>
  );
}
