import { useMutation, useQueryClient } from 'react-query';
import { useApiService } from '../../../app/common/api-service-provider';
import organizationUsersQueryKeys from './use-organization-users-query-keys';
import { ApiError } from '../../../services/api/api-error';
import * as Sentry from '@sentry/browser';


const useOrganizationUsersDelete = () => {
  const queryClient = useQueryClient();

  const apiService = useApiService();
  return useMutation(
    async ({organizationId, userId}: Record<string, string>) => {
      return apiService.delete(`/api/organizations/${organizationId}/users/${userId}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([organizationUsersQueryKeys.queryPrefix()]);
      },
      onError: (error: ApiError) => {
        Sentry.captureException(error);
      },
    },
  );
};

export default useOrganizationUsersDelete;
