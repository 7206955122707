const tagQueryKeys = {
  getQueryPrefix: () => {
    return 'content-tag';
  },
  getTagsList: (tenantId: string, limit = 100, page = 1) => {
    return [tagQueryKeys.getQueryPrefix(), 'tenantId', tenantId, 'limit', limit, 'page', page];
  },
  getTag: (tagId: string) => {
    return [tagQueryKeys.getQueryPrefix(), 'tagId', tagId];
  },
  tenantTags: (tenantId: string) => {
    return [tagQueryKeys.getQueryPrefix(), 'tenantId', tenantId];
  },
};

export default tagQueryKeys;
