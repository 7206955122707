import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { FieldProps } from 'react-jsonschema-form';
import { Edit } from 'react-feather';
import { FieldContainer, Text, Button, Icon } from '../../common';
import Settings from './settings.component';
import { buildTypographyCss, buildFontFace, getDefaultPropertyValue, TextAsElementEnum, TypographyProperty } from './utils';
import SampleTextPreview from './sample-text-preview.component';
import { Global, css } from '@emotion/core';

interface ButtonPickerPropsUiOptions {
  isBordered?: boolean;
  previewButtonIcon?: string;
  previewButtonTitle?: string;
  editButton?: {
    text?: string;
    icon?: string;
  };
  subtitle?: string;
  className?: string;
}

interface FormData extends TypographyProperty {
  fontFaceCss?: string;
  typographyCss: string;
}

interface TypographyProps extends FieldProps<FormData> {
  rawErrors?: string[];
}

const Typography = (props: TypographyProps) => {
  const {
    formContext: { organisationId: tenantId },
    rawErrors = [],
    formData: initFormData,
    onChange,
    schema,
    uiSchema,
    required,
    readonly,
  } = props;

  const {
    isBordered = true,
    editButton,
    subtitle,
  } = (uiSchema['ui:options'] || {}) as ButtonPickerPropsUiOptions;

  const helpText = uiSchema['ui:help'] as string;

  const { t } = useTranslation();

  const formData = initFormData && Object.keys(initFormData).length ? initFormData : undefined;

  const defaultValue = useMemo(() => {
    const value = (schema.default || {}) as unknown as TypographyProperty;

    return getDefaultPropertyValue(value, t, schema.title);
  }, [schema.default, schema.title, t]);

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [sampleText, setSampletext] = useState<string>();
  const [asElement, setAsElement] = useState<TextAsElementEnum>();
  const [previewCss, setPreviewCss] = useState<string>();
  const [fontFaceCss, setFontFaceCss] = useState<string>();

  const editButtonIcon = editButton && editButton.icon;
  const editButtonText = (editButton && editButton.text) || t('typographyPicker.edit');

  const handleEdit = useCallback(() => setIsEdit((prevState) => !prevState), []);

  const handleClose = useCallback(() => {
    setIsEdit((prevState) => !prevState);
  }, []);

  const handleChange = useCallback((value: TypographyProperty) => {
    const { textNonCssProperty, font } = value;

    const { fontFamily, fontFaceCss } = buildFontFace(font);
    setFontFaceCss(fontFaceCss);

    const { typographyCss, typographyPreviewCss } = buildTypographyCss(value, fontFamily);
    setPreviewCss(typographyPreviewCss);

    if (textNonCssProperty.sampleText) {
      setSampletext(textNonCssProperty.sampleText);
    }

    if (textNonCssProperty.asElement) {
      setAsElement(textNonCssProperty.asElement);
    }

    onChange({
      ...value,
      fontFaceCss,
      typographyCss,
    });
  }, [onChange]);

  return (
    <FieldContainer
      isBordered={isBordered}
      isDisabled={readonly}
      isRequired={required}
      title={schema.title}
      subtitle={subtitle}
      helpText={helpText}
      errors={rawErrors}
      description={schema.description}
      isExpandContent={isEdit}
      onCloseExpandableContent={handleClose}
      expandableContent={
        <Settings
          onChange={handleChange}
          defaultValue={formData || defaultValue}
          isReadonly={readonly}
          tenantId={tenantId}
        />
      }
    >
      <Preview onClick={handleEdit}>
        <PreviewText type="secondary" size={12} weight={300}>{t('typographyPicker.preview')}</PreviewText>
        <Global
          styles={css(fontFaceCss)}
        />
        <SampleTextPreview text={sampleText} asElement={asElement} previewCss={previewCss} />
      </Preview>
      {!isEdit && !readonly && (
        <EditButton type="default" onClick={handleEdit}>
          {editButtonIcon ? (
            <Icon size={18} type={editButtonIcon} />
          ) : (
            <Icon size={18} component={() => <Edit />} />
          )}
          {editButtonText}
        </EditButton>
      )}
    </FieldContainer>
  );
};

const Preview = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: rgb(242, 242, 247);
  border-radius: 4px;
  width: -webkit-fill-available;
  padding: 10px 25px;
  margin-top: 10px;
  gap: 10px;
  cursor: pointer;
  padding: 16px 24px 24px;
`;

const EditButton = styled(Button)`
  margin-top: 10px;
`;

const PreviewText = styled(Text)`
  margin-right: auto;
`;

export default Typography;
