import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import { RootModel, RootState } from '../../../../store/models/root.model';
import ConnectDevicesUniversal from './connect-device-universal.component';
import { UniversalDeviceConnectParams } from '../../../../store/models/app-devices-universal/app-devices-universal.model';
import DeviceTypeEnum from '../../../../store/types/device-type.enum';

type DevicesContainerProps = Omit<
  React.ComponentProps<typeof ConnectDevicesUniversal>,
  | 'lastUpdated'
  | 'devices'
  | 'loaded'
  | 'error'
  | 'fetchDevicesToConnect'
  | 'fetchEnvironments'
  | 'environments'
  | 'connectDevice'
  | 'app'
  | 'pagination'
  | 'isPaginationLoading'
>;

const ConnectDeviceUniversalContainer = (props: DevicesContainerProps) => {
  const {
    query,
    match: {
      params: { organisationId, appId },
    },
  } = props;

  const {
    devices,
    loaded,
    lastUpdated,
    error,
    environments,
    app,
    isPaginationLoading,
    pagination,
  } = useStore(
    (state: RootState) => {
      const deviceConnectKey = `${organisationId}-device-connect`;

      return {
        app: (appId && state.organisationApps.data &&
            state.organisationApps.data[organisationId] &&
            state.organisationApps.data[organisationId][appId]
        ) || null,
        devices: state.organisationDevices.values(deviceConnectKey),
        loaded: state.organisationDevices.loaded(deviceConnectKey),
        lastUpdated: state.organisationDevices.lastUpdated[deviceConnectKey],
        error: state.organisationDevices.error[deviceConnectKey],
        environments: state.environments.values(organisationId),
        isPaginationLoading:
          state.organisationDevices.isPaginationLoading[deviceConnectKey],
        pagination: state.organisationDevices.pagination[deviceConnectKey],
      };
    },
    [organisationId],
  );

  const { fetchDevicesToConnect, fetchEnvironments, connectDevice } = useActions<
    RootModel
  >((actions) => ({
    fetchDevicesToConnect: actions.organisationDevices.fetchDevicesToConnect,
    connectDevice: async (params: UniversalDeviceConnectParams) => {
      await actions.appDevicesUniversal.connect(params);

      if (app) {
        const isIoTAppInstallation = app.deviceType === DeviceTypeEnum.IOT;

        if (isIoTAppInstallation) {
          await actions.appV3DevicesUniversal.fetch({
            appName: app.appName,
          });
        } else {
          await actions.appDevicesUniversal.fetch({
            appName: app.appName,
            page: 1,
            pageSize: pagination ? pagination.limit : 5,
          });
        }
      }
    },
    fetchEnvironments: actions.environments.fetch,
  }));

  return (
    <ConnectDevicesUniversal
      {...props}
      lastUpdated={lastUpdated}
      devices={devices}
      loaded={loaded}
      error={error}
      fetchDevicesToConnect={fetchDevicesToConnect}
      connectDevice={connectDevice}
      fetchEnvironments={fetchEnvironments}
      environments={environments}
      query={query}
      app={app}
      isPaginationLoading={isPaginationLoading}
      pagination={pagination}
    />
  );
};

export default ConnectDeviceUniversalContainer;
