import { TFunction } from 'i18next';
import dayjs from 'dayjs';
import Media, { VideoVariantState } from '../store/types/media';

const CREATED_RECENTLY_TIMEOUT_MINUTES = 5;

export const MEDIA_ITEM_VIDEO_VARIANT_TYPE = 'video/mp4';

export enum VideoPreset {
  h264Vertical1080p = 'h264Vertical1080p',
  h264Horizontal1080p = 'h264Horizontal1080p',
}

export const getVideoPresetDisplayName = (preset: string, t: TFunction) => {
  switch (preset) {
    case VideoPreset.h264Horizontal1080p:
      return t('videoPresetH264Horizontal1080p');
    case VideoPreset.h264Vertical1080p:
      return t('videoPresetH264Vertical1080p');
    default:
      return t('videoPresetGeneric');
  }
};

export const getVideoVariantStateDisplayName = (state: string, t: TFunction) => {
  switch (state) {
    case VideoVariantState.error:
      return t('videoVariantStateError');
    case VideoVariantState.finished:
      return t('videoVariantStateFinished');
    case VideoVariantState.processing:
      return t('videoVariantStateProcessing');
    case VideoVariantState.scheduled:
      return t('videoVariantStateScheduled');
    default:
      return null;
  }
};

export enum MediaItemKind {
  folder,
  video,
  image,
  gdmTemplate,
  gridApp,
  other,
}

export const getMediaItemKind = (mediaItem: Media): MediaItemKind => {
  if (mediaItem.type === 'folder') {
    return MediaItemKind.folder;
  }
  if (mediaItem.type === 'application/x-gdm-template') {
    return MediaItemKind.gdmTemplate;
  }
  if (mediaItem.type === 'application/x-gridapp') {
    return MediaItemKind.gridApp;
  }
  if (mediaItem.type.startsWith('video/')) {
    return MediaItemKind.video;
  }
  if (mediaItem.type.startsWith('image/')) {
    return MediaItemKind.image;
  }
  return MediaItemKind.other;
};

export const getVideoAnalysisResultValue = (mediaItem: Media): string | null =>
  (mediaItem.metadata &&
    mediaItem.metadata.videoAnalysisResult &&
    mediaItem.metadata.videoAnalysisResult.value) ||
  null;

export const checkMediaItemCreatedRecently = (
  mediaItem: Media,
  currentDate: dayjs.Dayjs,
): boolean =>
  currentDate.diff(dayjs(mediaItem.createdAt), 'minute') <=
  CREATED_RECENTLY_TIMEOUT_MINUTES;

export const getMediaItemMimeTypeWithMetaInfo = (mediaItem: Media) => {
  const mediaItemKind = getMediaItemKind(mediaItem);
  const isVideo = mediaItemKind === MediaItemKind.video;

  if (isVideo && mediaItem.metadata && mediaItem.metadata.videoDuration) {
    return `${mediaItem.metadata.videoDuration.durationFormatted} - ${mediaItem.type}`;
  }

  return mediaItem.type;
}
