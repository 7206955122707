import { useQuery } from 'react-query';
import { useApiService } from '../../../app/common/api-service-provider';
import libraryQueryKeys from './library-query-keys';
import Media from '../../types/media';
import { ContentLibraryFilter } from '../../../app/organisations/organisation-details/content/library/content-library-filters.component';
import { PaginationCollection } from '../../types/pagination';
import Gridapp from '../../types/gridapp';

interface MediaListFilter extends ContentLibraryFilter {
  selectedFolderId?: string;
}

export interface MediaListResponse {
  media: PaginationCollection<Media>,
  ancestors: Media[],
}

export interface AppListResponse {
  apps: PaginationCollection<Gridapp>,
}

export interface ContentLibraryResponse {
  library: MediaListResponse | AppListResponse;
}

const useContentLibraryMediaList = (tenantId: string, page: number, limit: number, mediaListFilter: MediaListFilter = {}) => {
  const apiService = useApiService();

  const { selectedFolderId: folderId, contentType, orientation, resolution, search } = mediaListFilter;
  return useQuery(
    libraryQueryKeys.getContentLibraryMediaList(
      tenantId,
      mediaListFilter.selectedFolderId,
      mediaListFilter.contentType,
      mediaListFilter.orientation,
      mediaListFilter.resolution,
      mediaListFilter.search,
      limit,
      page,
    ),
    () =>
      apiService.get<ContentLibraryResponse>(`/api/content-library`, {
        tenantId,
        page,
        limit,
        ...(folderId ? { folderId } : {}),
        ...(contentType ? { contentType } : {}),
        ...(orientation ? { orientation } : {}),
        ...(resolution ? { resolution } : {}),
        ...(search ? { search } : {}),
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export default useContentLibraryMediaList;
