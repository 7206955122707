import { useQuery } from 'react-query';
import { useApiService } from '../../../app/common/api-service-provider';

import dimensionQueryKeys from './dimensions-query-keys';
import Dimension from '../../types/dimension';

const useDimensionsGet = () => {
  const apiService = useApiService();

  return useQuery(
    dimensionQueryKeys.getDimensionsListQueryKeys(),
    () => apiService.get<Dimension[]>('/api/dimensions'),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export default useDimensionsGet;
