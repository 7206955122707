import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import flatten from 'lodash/flatten';
import { Link, RouteComponentProps } from 'react-router-dom';
import styled, { StyledComponent } from '@emotion/styled';
import {
  Button,
  Typography as AntdTypography,
  Row as AntdRow,
  Col,
  Menu as AntdMenu,
  message,
} from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { Subheader } from '../../../../common/app-layout/header';
import moment from 'moment';
import SchemaForm, {
  SchemaFormRef,
} from '../../../../common/schema-form/schema-form.component';
import { buildFieldSchema, buildUiSchema } from './build-schema';
import useSchemaForm from '../../../../common/use-schema-form/use-schema-form';
import {
  useAlertActionGroupById,
  useCreateAlertActionGroup,
  useUpdateAlertActionGroup,
} from '../../../../../store/hooks/alert-action-groups';
import ErrorViewComponent from '../../../../common/error-view/error-view.component';
import Overlay from '../../../../common/overlay/overlay.component';
import Spinner from '../../../../common/spinner/spinner.component';
import {
  ActionTypeEnum,
  AlertAction,
  AlertRecipient,
  EmailAlertAction,
  SmsAlertAction,
  WebhookAlertAction,
} from '../../../../../store/types/alerts';

const { Text, Title: AntdTitle } = AntdTypography;

type SubmitData = {
  general: {
    name: string;
    description: string;
  };
  notificationRecipients: {
    webHookUrl: string;
    emailRecipients: AlertRecipient[];
    smsRecipients: AlertRecipient[];
  };
};

interface ActionGroupsUpdateProps
  extends RouteComponentProps<{ organisationId: string; groupId: string }> {}

const ActionGroupsUpdate = (props: ActionGroupsUpdateProps) => {
  const { location, match, history } = props;
  const {
    params: { organisationId: tenantId, groupId },
  } = match;

  const formElement = useRef<SchemaFormRef>(null);

  const { t } = useTranslation();

  const {
    data: actionGroupData,
    isLoading,
    isFetching,
    isError,
  } = useAlertActionGroupById({
    alertActionGroupId: groupId,
  });

  const {
    mutateAsync: updateAlertActionGroup,
    data: updatedActionGroupData,
    isLoading: isUpdating,
  } = useUpdateAlertActionGroup(tenantId, groupId);

  const {
    mutateAsync: createAlertActionGroup,
    isLoading: isCreating,
  } = useCreateAlertActionGroup(tenantId);

  const { fieldSchema, uiSchema } = useMemo(() => {
    return {
      fieldSchema: buildFieldSchema(t),
      uiSchema: buildUiSchema(t),
    };
  }, [t]);

  const viewPath = useMemo(() => {
    const segments = match.url.split('/');
    if (groupId) {
      segments.splice(-2, 2);
    } else {
      segments.pop();
    }
    return segments.join('/');
  }, [match, groupId]);

  const handleMenuClick = useCallback((className: string) => {
    const field = document.querySelector(`div[class*="${className}"]`);

    if (field) {
      field.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);

  const lastEditedTimestamp = useMemo(() => {
    const { updatedAt = '' } = updatedActionGroupData || actionGroupData || {};

    return updatedAt ? moment(updatedAt).format('DD/MM/YYYY hh:mm A') : '';
  }, [actionGroupData, updatedActionGroupData]);

  const handleSubmit = useCallback(
    async (data: SubmitData) => {
      const {
        general: { name, description },
        notificationRecipients: { webHookUrl, emailRecipients, smsRecipients = [] },
      } = data;

      const actions: AlertAction[] = [];

      if (emailRecipients.length > 0) {
        actions.push({
          type: ActionTypeEnum.EMAIL,
          recipients: emailRecipients,
        });
      }

      if (smsRecipients.length > 0) {
        actions.push({
          type: ActionTypeEnum.SMS,
          recipients: smsRecipients,
        });
      }

      if (webHookUrl) {
        actions.push({
          type: ActionTypeEnum.WEBHOOK,
          urls: [webHookUrl],
        });
      }

      const body = {
        tenantId,
        displayName: name,
        description,
        actions,
      };

      try {
        if (groupId) {
          await updateAlertActionGroup(body);
        } else {
          await createAlertActionGroup(body);
          history.push(viewPath);
        }
      } catch (e) {
        message.error(t('errorSavingData'));
        throw e;
      }
    },
    [
      updateAlertActionGroup,
      createAlertActionGroup,
      t,
      groupId,
      history,
      tenantId,
      viewPath,
    ],
  );

  const handleSave = useCallback(() => {
    if (formElement.current) {
      formElement.current.submit();
    }
  }, []);

  const [formData, , , formInit, handleFormChange, handleSubmittedForm] = useSchemaForm(
    handleSubmit,
    t('actionGroups.successSavingData'),
    t('actionGroups.errorSavingData'),
  );

  const { pageActionTitle, isCreateMode } = useMemo(() => {
    const isCreateMode = location.pathname.indexOf('create') > -1;

    return {
      pageActionTitle: isCreateMode
        ? t('actionGroups.createActionGroup')
        : t('actionGroups.updateActionGroup'),
      isCreateMode,
    };
  }, [location.pathname, t]);

  useEffect(() => {
    if (!actionGroupData) {
      return;
    }

    const { displayName, description, actions } = actionGroupData;

    const emailActions = actions.filter(
      (action): action is EmailAlertAction => action.type === ActionTypeEnum.EMAIL,
    );
    const smsActions = actions.filter(
      (action): action is SmsAlertAction => action.type === ActionTypeEnum.SMS,
    );
    const webhookActions = actions.filter(
      (action): action is WebhookAlertAction => action.type === ActionTypeEnum.WEBHOOK,
    );

    const emailRecipients = flatten(emailActions.map((action) => action.recipients));
    const smsRecipients = flatten(smsActions.map((action) => action.recipients));
    const webhookUrl = webhookActions.length > 0 ? webhookActions[0].urls[0] : '';

    formInit({
      general: {
        name: displayName,
        description: description,
      },
      notificationRecipients: {
        webHookUrl: webhookUrl,
        emailRecipients,
        smsRecipients,
      },
    });
  }, [actionGroupData, formInit]);

  return (
    <>
      {isError && <ErrorViewComponent position="relative" />}
      {(isLoading || isFetching) && !isError && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}
      {!isError && (
        <>
          <Subheader
            components={[
              <Link key="back-to-list" to={viewPath}>
                <BackToListButton type="default" icon="arrow-left" />
              </Link>,
              ...(!isCreateMode && lastEditedTimestamp
                ? [
                    <LastEdited key="last-edited">
                      {t('lastEditedTimestamp', { timestamp: lastEditedTimestamp })}
                    </LastEdited>,
                  ]
                : []),
              <SaveButton
                key="save"
                size="large"
                type="primary"
                onClick={handleSave}
                disabled={isUpdating || isCreating}
                loading={isUpdating || isCreating}
              >
                {t('saveAllChanges')}
              </SaveButton>,
            ]}
          />
          <ActionGroupsUpdateContainer>
            <Row gutter={{ md: 20, xl: 40 }}>
              <StickyColumn>
                <Title level={4}>{pageActionTitle}</Title>
                <Menu
                  id="sidebarMenu"
                  mode="inline"
                  selectedKeys={[match.url + location.hash]}
                >
                  <MenuItem key={`${match.url}#general`}>
                    <Link
                      to={`${match.url}#general`}
                      onClick={() => handleMenuClick('action-group-general')}
                    >
                      <span>{t('actionGroups.general')}</span>
                    </Link>
                  </MenuItem>
                  <MenuItem key={`${match.url}#notification-recipients`}>
                    <Link
                      to={`${match.url}#notification-recipients`}
                      onClick={() =>
                        handleMenuClick('action-group-notification-recipients')
                      }
                    >
                      <span>{t('actionGroups.notificationRecipients')}</span>
                    </Link>
                  </MenuItem>
                </Menu>
              </StickyColumn>
              <ContentColumn>
                <SchemaForm
                  organisationId={tenantId}
                  ref={formElement}
                  schema={fieldSchema}
                  uiSchema={uiSchema}
                  data={formData}
                  onSubmit={handleSubmittedForm}
                  onChange={handleFormChange}
                  liveValidate={false}
                />
              </ContentColumn>
            </Row>
          </ActionGroupsUpdateContainer>
        </>
      )}
    </>
  );
};

const ActionGroupsUpdateContainer = styled.div`
  position: relative;
  flex: 1;
  padding: 64px 80px 40px;

  @media screen and (max-width: 991px) {
    padding: 20px;
  }
`;

const LastEdited = styled(Text)`
  color: #676973;
  font-size: 13px;
`;

type StyledButtonType = StyledComponent<
  ButtonProps & React.ClassAttributes<Button>,
  Pick<ButtonProps & React.ClassAttributes<Button>, keyof ButtonProps>,
  any
>;
const SaveButton = styled(Button)`
  background-color: #00ae48;
  border-color: #00ae48;
  height: 36px;
  font-size: 14px;
  margin-left: auto;

  :hover,
  :active,
  :focus {
    background-color: #00ae48e8;
    border-color: #00ae48e8;
  }
` as StyledButtonType;

const BackToListButton = styled(Button)`
  height: 36px;
  font-size: 14px;
  color: #676973;
  border: none;
  margin-right: 5px;
  background-color: transparent;

  :hover,
  :active,
  :focus {
    background-color: #f2f4f8;
  }
` as StyledButtonType;

const Row = styled(AntdRow)`
  @media (min-width: 1200px) {
    display: flex;
  }
`;

export const StickyColumn = styled(Col)`
  width: 100%;
  z-index: 1;

  @media (min-width: 1200px) {
    position: sticky;
    display: block;
    width: 25%;
    top: 16px;
    height: fit-content;
  }
`;

const ContentColumn = styled(Col)`
  width: 100%;

  @media (min-width: 1200px) {
    display: block;
    width: 75%;
  }

  @media (min-width: 1400px) {
    display: block;
    width: 50%;
  }
`;

const Menu = styled(AntdMenu)`
  margin: 0 40px 0 5px;
  background: unset;
  border: none;
  display: block;

  @media (max-width: 1199px) {
    display: -webkit-box;
    overflow-x: auto;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
    width: 100%;

    margin-left: 0;

    .ant-menu-item {
      width: unset;
    }
  }

  @media (min-width: 1200px) {
    display: block;
  }
`;

const MenuItem = styled(AntdMenu.Item)`
  height: 30px !important;

  span {
    padding: 4px;
    color: rgba(0, 0, 0, 0.65);
  }

  &.ant-menu-item {
    padding-left: 0 !important;
  }

  &.ant-menu-item-active {
    background-color: unset !important;

    span {
      background-color: rgb(221 230 235 / 30%);
      border-radius: 4px;
    }
  }

  &.ant-menu-item-selected {
    font-weight: 600;
    background-color: unset !important;

    span {
      background-color: #dde6eb;
      border-radius: 4px;
      color: #2364aa;
    }
  }

  &.ant-menu-item-selected::after {
    opacity: 0;
  }
`;

const Title = styled(AntdTitle)`
  margin: 10px 5px !important;
`;

export default ActionGroupsUpdate;
