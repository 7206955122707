import { Button, Modal, message } from 'antd';
import React, { useCallback, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Input, validateByRegExPattern } from '../../../../common/react-final-form';
import { requiredAll } from '../../queue-details/queue-setup/validate-form';
import { ContentLibraryItemTypeEnum } from './library-utils';
import useContentLibraryUploadMedia from '../../../../../store/hooks/content-library/use-content-library-upload-media';
import Message from '../../../../common/message';

interface ContentLibraryUploadFolderProps {
  tenantId: string;
  parentFolderId: string | undefined;
  isVisible: boolean;
  onCloseModal: () => void;
}

interface NewFolderUploadBody {
  name: string;
}

const ContentLibraryUploadFolder = (props: ContentLibraryUploadFolderProps) => {
  const { tenantId, parentFolderId, isVisible, onCloseModal } = props;

  const { t } = useTranslation();

  const [isUploadingMedia, setIsUploadingMedia] = useState(false);

  const { mutateAsync: uploadMedia } = useContentLibraryUploadMedia(tenantId);

  const handleSubmit = useCallback(
    async (values: NewFolderUploadBody | Object) => {
      const { name } = values as NewFolderUploadBody;
      const file = new File([], name, {
        type: ContentLibraryItemTypeEnum.FOLDER,
        lastModified: Date.now(),
      });

      setIsUploadingMedia(true);

      try {
        await uploadMedia({
          file,
          organization: tenantId,
          folderId: parentFolderId,
          onProgress: (percent: number) => console.log(percent),
        });

        onCloseModal();

        message.success(<Message content={t('contents.uploadFolderSuccess')} />);
      } catch (err) {
        message.error(<Message content={t('contents.uploadFolderFailed')} />);
      }
    },
    [uploadMedia, onCloseModal, setIsUploadingMedia, parentFolderId, tenantId, t],
  );

  return (
    <Modal
      title={t('contents.folder')}
      visible={isVisible}
      onCancel={() => onCloseModal()}
      footer={[
        <Button disabled={isUploadingMedia} key="back" onClick={() => onCloseModal()}>
          {t('cancel')}
        </Button>,
        <Button
          loading={isUploadingMedia}
          htmlType="submit"
          form="upload-folder"
          key="okay"
          onClick={() => {}}
        >
          {t('ok')}
        </Button>,
      ]}
    >
      <Form
        onSubmit={handleSubmit}
        render={({ handleSubmit }) => {
          return (
            <form id="upload-folder" onSubmit={handleSubmit}>
              <Input
                label={t('contents.name')}
                name="name"
                validators={[
                  requiredAll,
                  validateByRegExPattern('^[^\\s]*$', t('contents.spacesAreNotAllowed')),
                ]}
              />
            </form>
          );
        }}
      />
    </Modal>
  );
};

export default ContentLibraryUploadFolder;
