import { useQuery } from 'react-query';
import { useApiService } from '../../../app/common/api-service-provider';
import tagQueryKeys from './tag-query-keys';
import ContentTag from '../../types/content-tag';

const useTagById = (tenantId: string, tagId: string) => {
  const apiService = useApiService();

  return useQuery(
    tagQueryKeys.getTag(tagId),
    () => apiService.get<ContentTag>(`/api/content-tag/${tagId}`, { tenantId, contentTagId: tagId }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export default useTagById;
