import React, { useCallback, useMemo } from 'react';
import ModulesStatusTable from './modules-status-table.component';
import CardContainer from '../card-container';
import {
  RawMonitoringEvent,
  useTenantRealtimeEvents,
} from '../../../use-analytics-report';
import { DataResidencyEnum } from '../../../../../store/types/organisation';
import { useTenantDevices } from '../../../use-devices';
import { ModulesStatusCard } from '@ombori/grid-reports';
import { ModuleStatus } from './types';
import { formatRealtimeEventsForModulesStatus, mapDeviceIdToDeviceName, ModuleStatusWithDeviceId } from './utils';

interface TenantModulesStatusContainerProps {
  tenantId: string;
  title: string;
  dataResidency: DataResidencyEnum;
  eventType: string;
  statusCodes: ModulesStatusCard['statusCodes'];
}

const TenantModulesStatusContainer = (
  props: TenantModulesStatusContainerProps,
) => {
  const {
    tenantId,
    title,
    dataResidency,
    eventType,
    statusCodes,
  } = props;

  const transformDataFn = useCallback((data: RawMonitoringEvent[]) => {
    return formatRealtimeEventsForModulesStatus(data, statusCodes);
  }, [statusCodes]);

  const {
    data: modulesDataWithDeviceId,
    isLoading,
    isError,
    isSuccess,
  } = useTenantRealtimeEvents<ModuleStatusWithDeviceId[]>({
    tenantId,
    dataResidency,
    eventType,
    transformDataFn,
  });

  const { data: tenantDevices } = useTenantDevices({ tenantId });

  const modulesData = useMemo((): ModuleStatus[] => {
    if (tenantDevices && modulesDataWithDeviceId) {
      const modulesData = mapDeviceIdToDeviceName(modulesDataWithDeviceId, tenantDevices);
      return modulesData.sort((a, z) => a.deviceName.localeCompare(z.deviceName));
    }

    return [];
  }, [modulesDataWithDeviceId, tenantDevices]);

  return (
    <>
      <CardContainer
        isLoading={isLoading}
        isError={isError}
        isSuccess={isSuccess}
        hasData={modulesData.length > 0 ? true : false}
        title={title}
      >
        <ModulesStatusTable data={modulesData} />
      </CardContainer>
    </>
  );
};

export default TenantModulesStatusContainer;
