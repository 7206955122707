import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Form, List, Checkbox } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { get } from 'lodash';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import {
  EnterpriseAgreementStatusEnum,
  Filters,
} from '../../../../../store/types/enterprise-agreement';
import { getStatusColor } from '../../../../../utils/enterprise-agreement';
import TotalsExtra from './totals-extra';

interface StatusItemProps extends FormComponentProps {
  filters?: Filters;
  status: { id: string; desc: string };
  form: WrappedFormUtils<any>;
}

const StatusItem = (props: StatusItemProps) => {
  const {
    filters,
    status,
    form: { getFieldDecorator },
  } = props;
  const { id, desc } = status;
  const data = get(filters, `status.${id}`, {});
  const { total } = data;

  const countColor = useMemo(() => getStatusColor(id as EnterpriseAgreementStatusEnum), [
    id,
  ]);

  return (
    <List.Item extra={<TotalsExtra key={id} countColor={countColor} total={total} />}>
      <FormItem>
        {getFieldDecorator(`status[${id}]`, {
          valuePropName: 'checked',
          initialValue: true,
        })(<Checkbox>{desc}</Checkbox>)}
      </FormItem>
    </List.Item>
  );
};

const FormItem = styled(Form.Item)`
  flex: 1 1;
  margin-bottom: 0px;

  .ant-form-item-control {
    line-height: normal;
  }

  .ant-checkbox-wrapper {
    width: 100%;
  }
` as any;

export default StatusItem;
