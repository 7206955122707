import { action, Action, thunk, Thunk } from 'easy-peasy';
import { ApiError } from '../../../services/api/api-error';
import Injections from '../../injections.interface';
import TransactionPaginatedResult from '../../types/transactions';

export interface OrganisationTransactionsModel {
  data: { [organisationId: string]: TransactionPaginatedResult };
  error: { [organisationId: string]: Error | null };
  loading: { [organisationId: string]: boolean };
  setData: Action<
    OrganisationTransactionsModel,
    { key: string; data: TransactionPaginatedResult }
  >;
  setError: Action<
    OrganisationTransactionsModel,
    { key: string; error: ApiError | null }
  >;
  setLoading: Action<OrganisationTransactionsModel, { key: string; loading: boolean }>;
  fetch: Thunk<
    OrganisationTransactionsModel,
    {
      organisationId: string;
      page: number;
      limit: number;
      startDate: string;
      endDate: string;
      searchTerm?: string;
      spaceId?: string;
    },
    Injections
  >;
}

const organisationTransactionsModel: OrganisationTransactionsModel = {
  loading: {},
  error: {},
  data: {},
  setLoading: action((state, { key, loading }) => {
    state.loading[key] = loading;
  }),
  setError: action((state, { key, error }) => {
    state.error[key] = error;
  }),
  setData: action((state, { key, data }) => {
    state.data[key] = data;
  }),
  fetch: thunk(
    async (
      actions,
      { organisationId, startDate, endDate, searchTerm, spaceId, page, limit },
      { injections },
    ) => {
      actions.setLoading({ key: organisationId, loading: true });
      actions.setError({ key: organisationId, error: null });
      try {
        const data = await injections.apiService.get<TransactionPaginatedResult>(
          `/api/organizations/${organisationId}/transactions`,
          {
            page,
            limit,
            startDate,
            endDate,
            searchTerm,
            spaceId,
          } as any,
        );
        actions.setData({ key: organisationId, data });
      } catch (error) {
        actions.setError({ key: organisationId, error });
      } finally {
        actions.setLoading({ key: organisationId, loading: false });
      }
    },
  ),
};

export default organisationTransactionsModel;
