import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import { Button, Select } from 'antd';
import capitalize from 'lodash/capitalize';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import { Label, PanelCardMain } from '../styled-block';
import {
  Category,
  RegistrationField,
  RegistrationFieldCategory,
  RegistrationFieldCheckbox,
  RegistrationFieldEmail,
  RegistrationFieldInput,
  RegistrationFieldPhone,
  RegistrationFieldRadio,
  RegistrationFieldTextarea,
  RegistrationFieldType,
} from '../../../../../../store/types/queue';
import {
  CategoryField,
  CheckboxField,
  EmailField,
  InputField,
  PhoneField,
  RadioField,
  TextareaField,
} from './components';
import {
  buildCategory,
  buildCategoryOption,
  buildCheckbox,
  buildEmail,
  buildInput,
  buildPhone,
  buildRadio,
  buildTextarea,
} from './data-builder';
import usePrevious from '../../../../../../utils/use-previous';

const { Option } = Select;

const isFieldNameFilled = (fieldId: string, registrationFields: RegistrationField[]) => {
  const field = registrationFields.find((fieldItem) => fieldItem.id === fieldId);

  if (!field) return false;

  const result = !!field.name;

  return result;
};

const getFirstFieldIdByType = (type: RegistrationFieldType) => (
  fields: RegistrationField[],
): string => {
  const phoneFields = fields.filter((field) => field.type === type);
  const firstFieldName = get(phoneFields, '[0].id', '');

  return firstFieldName;
};

export const getFirstPhoneFieldId = getFirstFieldIdByType(RegistrationFieldType.PHONE);

export const getFirstEmailFieldId = getFirstFieldIdByType(RegistrationFieldType.EMAIL);

interface GetComponentProps {
  fieldData: RegistrationField;
  fieldName: string;
  onRemoveField: () => void;
  activeLanguage: string;
  isNameDisabled: boolean;
  hint?: string;
  change: (name: string, value: any) => void;
}

const getComponent = ({
  fieldData,
  fieldName,
  onRemoveField,
  activeLanguage,
  isNameDisabled,
  hint,
  change,
}: GetComponentProps): React.ReactElement => {
  // eslint-disable-next-line default-case
  switch (fieldData.type) {
    case RegistrationFieldType.TEXTAREA:
      return (
        <TextareaField
          {...fieldData}
          fieldName={fieldName}
          activeLanguage={activeLanguage}
          onRemove={onRemoveField}
          isNameDisabled={isNameDisabled}
          hint={hint}
          change={change}
        />
      );

    case RegistrationFieldType.INPUT:
      return (
        <InputField
          {...fieldData}
          fieldName={fieldName}
          activeLanguage={activeLanguage}
          onRemove={onRemoveField}
          isNameDisabled={isNameDisabled}
          hint={hint}
          change={change}
        />
      );

    case RegistrationFieldType.CHECKBOX:
      return (
        <CheckboxField
          {...fieldData}
          fieldName={fieldName}
          activeLanguage={activeLanguage}
          onRemove={onRemoveField}
          isNameDisabled={isNameDisabled}
          hint={hint}
          change={change}
        />
      );

    case RegistrationFieldType.RADIO:
      return (
        <RadioField
          {...fieldData}
          fieldName={fieldName}
          activeLanguage={activeLanguage}
          onRemove={onRemoveField}
          isNameDisabled={isNameDisabled}
          hint={hint}
          change={change}
        />
      );

    case RegistrationFieldType.PHONE:
      return (
        <PhoneField
          {...fieldData}
          fieldName={fieldName}
          activeLanguage={activeLanguage}
          onRemove={onRemoveField}
          isNameDisabled={isNameDisabled}
          hint={hint}
          change={change}
        />
      );

    case RegistrationFieldType.EMAIL:
      return (
        <EmailField
          {...fieldData}
          fieldName={fieldName}
          activeLanguage={activeLanguage}
          onRemove={onRemoveField}
          isNameDisabled={isNameDisabled}
          hint={hint}
          change={change}
        />
      );

    case RegistrationFieldType.CATEGORY:
      return (
        <CategoryField
          {...fieldData}
          fieldName={fieldName}
          activeLanguage={activeLanguage}
          onRemove={onRemoveField}
          isNameDisabled={isNameDisabled}
          hint={hint}
          change={change}
        />
      );
  }
};

const registrationFieldTypes: RegistrationFieldType[] = Object.values(
  RegistrationFieldType,
);

interface FiledTypes {
  [RegistrationFieldType.TEXTAREA]: () => RegistrationFieldTextarea;
  [RegistrationFieldType.INPUT]: () => RegistrationFieldInput;
  [RegistrationFieldType.CHECKBOX]: () => RegistrationFieldCheckbox;
  [RegistrationFieldType.RADIO]: () => RegistrationFieldRadio;
  [RegistrationFieldType.PHONE]: () => RegistrationFieldPhone;
  [RegistrationFieldType.EMAIL]: () => RegistrationFieldEmail;
  [RegistrationFieldType.CATEGORY]: (categories: Category[]) => RegistrationFieldCategory;
}

const filedTypes: FiledTypes = {
  textarea: () => buildTextarea(),
  input: () => buildInput(),
  checkbox: () => buildCheckbox(),
  radio: () => buildRadio(),
  phone: () => buildPhone(),
  email: () => buildEmail(),
  category: (categories) => buildCategory(categories),
};

interface RegistrationFieldsBuilderProps {
  fieldName: string;
  activeLanguage: string;
  registrationFields: RegistrationField[];
  formRegistrationFields: RegistrationField[];
  categories: Category[];
  change: (name: string, value: any) => void;
}

const RegistrationFieldsBuilder: React.FC<RegistrationFieldsBuilderProps> = ({
  fieldName,
  activeLanguage,
  registrationFields,
  formRegistrationFields,
  categories,
  change,
}) => {
  const { t } = useTranslation();

  const [fieldType, setFieldType] = useState<RegistrationFieldType>(
    RegistrationFieldType.INPUT,
  );

  const onChangeFieldType = useCallback(
    (type: RegistrationFieldType) => setFieldType(type),
    [],
  );

  const buildField = useCallback(() => {
    const fieldDataSelector = filedTypes[fieldType];

    const fieldData = fieldDataSelector(categories);

    return fieldData;
  }, [fieldType, categories]);

  const firstPhoneFieldId = getFirstPhoneFieldId(formRegistrationFields);
  const firstEmailFieldId = getFirstEmailFieldId(formRegistrationFields);

  const filteredRegistrationFieldTypes = useMemo(() => {
    if (categories.length > 0) {
      return registrationFieldTypes;
    }

    const result = registrationFieldTypes.filter(
      (type) => type !== RegistrationFieldType.CATEGORY,
    );

    return result;
  }, [categories]);

  const prevCategories = usePrevious<Category[]>(categories);

  useEffect(() => {
    if (isEqual(prevCategories, categories)) {
      return;
    }

    formRegistrationFields.forEach((field, index) => {
      if (field.type !== RegistrationFieldType.CATEGORY) {
        return;
      }

      const newOptions = categories.map(buildCategoryOption);

      change(`${fieldName}[${index}].options`, newOptions);
    });
  }, [formRegistrationFields, fieldName, prevCategories, categories, change]);

  return (
    <Container>
      <Header>
        <Label>{t('registrationFields')}</Label>
      </Header>

      <Content>
        <PanelCardMain>
          <FieldArray key={fieldName} name={fieldName}>
            {({ fields }) => (
              <>
                {fields.map((fieldItemName, index) => (
                  <FieldItem key={fieldItemName}>
                    <Field name={fieldItemName}>
                      {({ input }) => {
                        const fieldData: RegistrationField = input.value;

                        // for some reason when we remove the field it is not being removed immediately
                        // and for some short time we have empty string inside input.value of the removed field
                        if (!fieldData) return null;

                        const isNameFilled = isFieldNameFilled(
                          fieldData.id,
                          registrationFields,
                        );

                        const hint =
                          fieldData.id === firstPhoneFieldId ||
                          fieldData.id === firstEmailFieldId
                            ? t('usedForNotification')
                            : undefined;

                        const node = getComponent({
                          fieldData,
                          fieldName: fieldItemName,
                          activeLanguage,
                          onRemoveField: () => fields.remove(index),
                          isNameDisabled: isNameFilled,
                          hint,
                          change,
                        });

                        return <PanelCardMain>{node}</PanelCardMain>;
                      }}
                    </Field>
                  </FieldItem>
                ))}

                <AddFieldPanel>
                  <AddFieldPanelTitle>
                    <Label>{t('addField')}</Label>
                  </AddFieldPanelTitle>

                  <AddFieldPanelContent>
                    <Select
                      defaultValue={RegistrationFieldType.INPUT}
                      onChange={onChangeFieldType}
                    >
                      {filteredRegistrationFieldTypes.map((type) => (
                        <Option key={type} value={type}>
                          {capitalize(type)}
                        </Option>
                      ))}
                    </Select>

                    <AddFieldButton
                      onClick={() => fields.push(buildField())}
                      icon="plus"
                    />
                  </AddFieldPanelContent>
                </AddFieldPanel>
              </>
            )}
          </FieldArray>
        </PanelCardMain>
      </Content>
    </Container>
  );
};

const Container = styled.div``;

const Header = styled.div``;

const Content = styled.div``;

const FieldItem = styled.div``;

const AddFieldButton = styled(Button)`
  margin-left: 15px;
` as any;

const AddFieldPanel = styled.div``;

const AddFieldPanelTitle = styled.div``;

const AddFieldPanelContent = styled.div`
  display: flex;
  align-items: center;
`;

export default RegistrationFieldsBuilder;
