import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Edit } from 'react-feather';
import styled from '@emotion/styled';
import {
  Alert as AntdAlert,
  Button,
  Modal,
  Tooltip,
  message,
} from 'antd';
import { ButtonType } from '../../../../types/styled-component';
import { Icon } from '../../../common/schema-form/common';
import useDeviceBundleUpdate, {
  UpdateDeviceBundleData,
} from '../../../common/use-device-bundle/use-device-bundle-update';
import SchemaForm, {
  SchemaFormRef,
} from '../../../common/schema-form/schema-form.component';
import { buildFieldSchema, buildUiSchema } from './build-schema';
import useSchemaForm from '../../../common/use-schema-form/use-schema-form';
import Message from '../../../common/message';
import { ApiError } from '../../../../services/api/api-error';
import useStage from '../../../common/use-stage';

interface UpdateButtonModalProps extends UpdateDeviceBundleData {
  deviceBundleId: string;
  isEnabled?: boolean;
}

const UpdateButtonModal = (props: UpdateButtonModalProps) => {
  const { deviceBundleId, stage, releaseNotes, isEnabled = true } = props;

  const refTooltip = useRef<Tooltip>(null);

  const initFormData: UpdateDeviceBundleData = {
    stage,
    releaseNotes,
  };

  const formElement = useRef<SchemaFormRef>(null);

  const [isUpdating, setIsUpdating] = useState<boolean>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>();

  const { t } = useTranslation();

  const { stageOptions } = useStage(t);

  const {
    mutateAsync: updateDeviceBundle,
    invalidateQueries,
    isError,
    error,
  } = useDeviceBundleUpdate();

  const handleSubmit = useCallback(
    async (data: UpdateDeviceBundleData) => {
      try {
        setIsUpdating(true);

        await updateDeviceBundle({
          deviceBundleId,
          body: data,
        });
        await invalidateQueries();

        message.success(
          <Message content={t('deviceBundleConfig.successUpdatingData')} />,
        );

        setIsModalOpen(false);
      } catch (e) {
        message.error(
          <Message
            content={t('deviceBundleConfig.errorUpdatingData')}
            error={e as ApiError}
          />,
        );
        throw e;
      } finally {
        setIsUpdating(false);
      }
    },
    [deviceBundleId, updateDeviceBundle, invalidateQueries, t],
  );

  const [formData, , , , handleFormChange, handleSubmittedForm] = useSchemaForm<
    UpdateDeviceBundleData
  >(handleSubmit, undefined, undefined, false);

  const handleSave = useCallback(() => {
    if (formElement.current) {
      formElement.current.submit();
    }
  }, []);

  const handleEditClick = useCallback(() => {
    setIsModalOpen(true);

    if (refTooltip.current) {
      refTooltip.current.setState({ visible: false });
    }
  }, []);

  const handleCancel = useCallback(() => setIsModalOpen(false), []);

  const { fieldSchema, uiSchema } = useMemo(() => {
    return {
      fieldSchema: buildFieldSchema(t, stageOptions),
      uiSchema: buildUiSchema(),
    };
  }, [stageOptions, t]);

  const updatedFormData = Object.keys(formData).length ? formData : initFormData;

  return (
    <>
      <Tooltip title={t('edit')} ref={refTooltip}>
        <ActionButton size="small" type="link" onClick={handleEditClick} disabled={!isEnabled}>
          <Icon component={() => <Edit />} />
        </ActionButton>
      </Tooltip>
      <Modal
        title={t('edit')}
        visible={isModalOpen}
        onOk={handleSave}
        okText={t('save')}
        confirmLoading={isUpdating && !isError}
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={handleCancel}
        closable={!isUpdating || isError}
        maskClosable={false}
        centered
      >
        {isError && (
          <Alert
            type="error"
            showIcon
            message={
              <Message
                content={t('deviceBundleConfig.errorUpdatingData')}
                error={error}
                showCloseButton={false}
              />
            }
          />
        )}
        <SchemaForm
          ref={formElement}
          schema={fieldSchema}
          uiSchema={uiSchema}
          data={updatedFormData}
          onSubmit={handleSubmittedForm}
          onChange={handleFormChange}
          liveValidate={false}
        />
      </Modal>
    </>
  );
};

const ActionButton = styled(Button)`
  height: fit-content;
` as ButtonType;

const Alert = styled(AntdAlert)`
  margin-bottom: 10px;
`;

export default React.memo(UpdateButtonModal);
