import { useQuery, UseQueryOptions, useQueryClient } from 'react-query';
import { GridAssistant, useGridLlmService } from '@ombori/grid-llm-react';
import storeAIAssistantQueryKeys from './store-ai-assistants-query-keys';

export const useStoreAIAssistant = (assistantId: string) => {
  const gridLlmService = useGridLlmService();

  const queryClient = useQueryClient();

  const fetchAssistant = async () => {
    const assistant = await gridLlmService.getAssistantById(assistantId);
    return assistant;
  };

  const queryOptions: UseQueryOptions<GridAssistant, Error> = {
    queryKey: storeAIAssistantQueryKeys.detail(assistantId),
    queryFn: fetchAssistant,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: storeAIAssistantQueryKeys.paginationPrefix(data.tenantId),
      });
    },
  };

  return useQuery(queryOptions);
};