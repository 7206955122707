import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import PanelCard from '../../../../common/panel-card/panel-card.component';
import FormInput from '../../../../common/form-input/form-input.component';

export const Container = styled.div`
  position: relative;
  min-height: 100%;
`;

export const StickyColumn = styled(Col)`
  position: sticky;
  top: 16px;
`;

export const PanelCardMain = styled(PanelCard)`
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const MainSectionTitle = styled.div`
  padding: 16px;
  margin-bottom: 16px;
  border-bottom: solid 1px rgb(235, 237, 244);

  h2 {
    line-height: 1.2;
  }
`;

export const Label = styled.label`
  width: auto;
`;

export const Select = styled.select`
  width: 100%;
  height: 32px;
`;

export const RowStyled = styled(Row)`
  padding-bottom: 8px;
`;

export const Input = styled.input`
  width: 100%;
  height: 32px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const CheckableInput = styled(Input)`
  margin-right: 0.5em !important;
  vertical-align: 0px;
`;

export const InputCheckbox = styled(CheckableInput)``;

export const InputRadio = styled(CheckableInput)``;

export const PanelBody = styled.div`
  padding: 0 16px 0 16px;
`;

export const LabelWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const FormTextInput = styled(FormInput)`
  padding-bottom: 8px;
`;
