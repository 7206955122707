import React from 'react';
import styled from '@emotion/styled';
import { Button, Popconfirm, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import PanelCard from '../../../../common/panel-card/panel-card.component';

interface KeyValueProps {
  key: string;
  value: string;
}

interface InformationProps {
  metaInfo: KeyValueProps[];
  identities: KeyValueProps[];
  onDelete: () => Promise<void>;
}

const Information = ({ metaInfo, identities, onDelete }: InformationProps) => {
  const { t } = useTranslation();

  return (
    <>
      <ContactInfo>
        <InformationSection>
          <Title>{t('appInformation')}</Title>
          {metaInfo.map(({ key, value }) => {
            return (
              <InfoItem key={`${key}-${value}`}>
                <InformationKeyTitle>{key.toLowerCase()}</InformationKeyTitle>
                <InformationKeyValue>{value}</InformationKeyValue>
              </InfoItem>
            );
          })}
        </InformationSection>
        <IdentitiesSection>
          <Title>{t('contactDetailsDetailsIdentitiesTitle')}</Title>
          {identities.map(({ key, value }) => {
            return (
              <InfoItem key={`${key}-${value}`}>
                <InformationKeyTitle>{key.toLowerCase()}</InformationKeyTitle>
                <InformationKeyValue>{value}</InformationKeyValue>
              </InfoItem>
            );
          })}
        </IdentitiesSection>
      </ContactInfo>
      <Popconfirm
        title={t('areYouSureYouWantToDelete')}
        onConfirm={() => onDelete()}
        okText={t('yes')}
        cancelText={t('no')}
      >
        <RemoveButton type="link" icon="delete" size="small">
          {t('delete')}
        </RemoveButton>
      </Popconfirm>
    </>
  );
};

const InfoItem = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
`;

const IdentitiesSection = styled(Row)`
  padding-top: 15px;
`;

const InformationSection = styled(Row)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 15px;
`;

const Title = styled.div`
  font-weight: bold;
  padding-bottom: 8px;
  color: #111;
`;

const InformationKeyTitle = styled.span`
  color: grey;
  text-transform: capitalize;
  width: 100%;
`;

const InformationKeyValue = styled.span``;

const ContactInfo = styled(PanelCard)`
  padding: 10px;
`;

const RemoveButton = styled(Button)`
  color: rgb(255, 85, 0);
  background-color: #fff;
  cursor: pointer;
  border: solid grey 1.5px;
  margin-top: 10px;
  text-transform: capitalize;
  float: right;
  &:hover,
  &:active,
  &:focus {
    color: rgb(255, 85, 0);
  }
` as any;

export default Information;
