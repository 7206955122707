import { Avatar, Tag, Button, Typography, List, Popconfirm, Divider } from 'antd';
import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import MobileEndpoint from '../../../../store/types/mobile-endpoint';
import Environment from '../../../../store/types/environment';
import Space from '../../../../store/types/organisation-space';

const { Text } = Typography;

/* eslint-disable */
const tagsColors: { [tagKey: string]: string } = {
  environment: 'green',
  spaces: 'darkblue',
};
/* eslint-enable */

interface MobileEndpointListItemProps {
  spaces: {
    [spaceId: string]: Space;
  };
  mobileEndpoint: MobileEndpoint;
  environments: Environment[];
  onDelete: (mobileEndpointId: string) => Promise<void>;
  onEdit: (mobileEndpointId: string) => void;
  canDelete: boolean;
  canEdit: boolean;
}

const RemoveButton = styled(Button)`
  color: rgb(255, 85, 0);
  &:hover,
  &:active,
  &:focus {
    color: rgb(255, 85, 0);
  }
` as any;

const EditButton = styled(Button)`
  color: #689bc4;
  &:hover,
  &:active,
  &:focus {
    color: #689bc4;
  }
` as any;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 4px;
`;

const LinkTag = styled(Tag)`
  cursor: pointer;
  margin: 4px 0;
`;

const InfoRow = styled.div`
  padding: 4px 0px 0px 0px;
  flex-direction: row;
`;

const Label = styled(Text)`
  padding-top: 4px;
`;

const MobileEndpointListItem = ({
  mobileEndpoint,
  spaces,
  environments,
  onDelete,
  onEdit,
  canDelete,
  canEdit,
}: MobileEndpointListItemProps) => {
  const { t } = useTranslation();
  const environmentLabel = useMemo(() => {
    const env = environments.find((e) => mobileEndpoint.env === e.environmentName);
    return env ? env.displayName : '';
  }, [environments, mobileEndpoint.env]);

  const mobileEndpointSpaces = mobileEndpoint.spaces || [];

  const renderMobileEndpointDetails = useMemo(() => {
    return (
      <InfoContainer>
        {mobileEndpoint.url && (
          <Label type="secondary">
            URL:{' '}
            {
              <a href={mobileEndpoint.url} target="_blank" rel="noopener noreferrer">
                {mobileEndpoint.url}
              </a>
            }
          </Label>
        )}
        {mobileEndpoint.notes && (
          <Label type="secondary">{`${t('notes')}: ${mobileEndpoint.notes}`}</Label>
        )}
        {!!mobileEndpointSpaces.length && (
          <InfoRow>
            {t('space')}:{' '}
            {mobileEndpointSpaces.map((spaceId, mobileEndpointIndex) => (
              <React.Fragment key={`mobileEndpoint_space_${spaceId}`}>
                {mobileEndpointIndex ? <Divider type="vertical" /> : null}
                <LinkTag color={tagsColors.spaces}>
                  {spaces[spaceId] ? spaces[spaceId].displayName : ''}
                </LinkTag>
              </React.Fragment>
            ))}
          </InfoRow>
        )}
        <InfoRow>
          {environmentLabel && (
            <LinkTag color={tagsColors.environment}>{environmentLabel}</LinkTag>
          )}
        </InfoRow>
      </InfoContainer>
    );
  }, [mobileEndpoint, environmentLabel, mobileEndpointSpaces, spaces, t]);

  return (
    <List.Item
      actions={[
        canDelete && (
          <Popconfirm
            title={t('areYouSureYouWantToDelete')}
            onConfirm={() => onDelete(mobileEndpoint.id)}
            okText={t('yes')}
            cancelText={t('no')}
          >
            <RemoveButton type="link" icon="delete" size="small">
              {t('delete')}
            </RemoveButton>
          </Popconfirm>
        ),
        canEdit && (
          <EditButton
            type="link"
            icon="edit"
            size="small"
            onClick={() => onEdit(mobileEndpoint.id)}
          >
            {t('edit')}
          </EditButton>
        ),
      ]}
    >
      <List.Item.Meta
        title={mobileEndpoint.displayName}
        avatar={<Avatar icon="mobile" />}
        description={renderMobileEndpointDetails}
      />
    </List.Item>
  );
};

export default MobileEndpointListItem;
