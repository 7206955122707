import React, { useMemo } from 'react';
import { groupBy, uniq } from 'lodash';
import keys from 'lodash/keys';
import {
  useTenantEventsByDay,
  useInstallationEventsByDay,
  useSpaceEventsByDay,
  useDeviceEventsByDay,
  useAppEventsByDay,
} from '../../use-analytics-report';
import { DataResidencyEnum } from '../../../../store/types/organisation';
import CardContainer from './card-container';
import PieChart from '../charts/pie';
import {
  DeviceEventsByDay,
  InstallationEventsByDay,
  SpaceEventsByDay,
  TenantEventsByDay,
  AppEventsByDay,
} from '../../use-analytics-report/aggregated-reports/types';
import { EventsListCard, GridStyles, InteractionType } from '@ombori/grid-reports';
import { useTranslation } from 'react-i18next';

const buildDataItem = (
  date: string,
  data: { label: string; value: number }[],
): [string, { label: string; value: number }[]] => [date, data];

const buildData = (
  rawData: (
    | TenantEventsByDay
    | InstallationEventsByDay
    | SpaceEventsByDay
    | DeviceEventsByDay
    | AppEventsByDay)[],
) => {
  const groupedEvents = groupBy(rawData, (dataItem) => dataItem.date);

  const result = keys(groupedEvents).map((date) => {
    const list = groupedEvents[date].map((dataItem) => ({
      label: dataItem.eventType,
      value: dataItem.count,
    }));

    const result = buildDataItem(date, list);

    return result;
  });

  return result;
};

const parseEventsData = (
  eventTypeCounts: { [key: string]: number },
  data: {
    title: string;
    eventTypes: string[];
    color?: string;
    icon?: string;
  }[] = [],
): [string, { label: string; value: number; color?: string }[]][] => {
  return data.map(({ title, eventTypes = [], color, icon }) => {
    const count = eventTypes.reduce(
      (acc, eventType) => acc + (eventTypeCounts[eventType] || 0),
      0,
    );

    const list = [
      {
        label: icon ? icon : title,
        value: count,
        color,
      },
    ];

    return buildDataItem(title, list);
  });
};

interface Result {
  additionalDataResult: ReturnType<typeof parseEventsData>;
  eventsResult: ReturnType<typeof parseEventsData>;
}

const buildEventsData = (
  rawData: (
    | TenantEventsByDay
    | InstallationEventsByDay
    | SpaceEventsByDay
    | DeviceEventsByDay
    | AppEventsByDay)[] = [],
  events: EventsListCard['events'] = [],
  additionalData: EventsListCard['additionalData'] = [],
): Result => {
  if (!rawData.length) {
    return { additionalDataResult: [], eventsResult: [] };
  }

  const eventTypeCounts: { [key: string]: number } = {};

  rawData.forEach((event) => {
    eventTypeCounts[event.eventType] =
      (eventTypeCounts[event.eventType] || 0) + event.count;
  });

  const additionalDataResult = additionalData
    ? parseEventsData(eventTypeCounts, additionalData)
    : [];

  const eventsResult = events ? parseEventsData(eventTypeCounts, events) : [];

  return { additionalDataResult, eventsResult };
};

interface EventsListProps {
  tenantId: string;
  dateFrom: string;
  dateTo: string;
  dataResidency: DataResidencyEnum;
  interactionType?: InteractionType;
  gridStyles?: GridStyles;
  isVisibleWithoutData?: boolean;
}

export const TenantEventsList: React.FC<EventsListProps> = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  interactionType = InteractionType.All,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { t } = useTranslation();
  const title = t('eventsListCardTitle');

  const tenantEventsByDayState = useTenantEventsByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
    interactionType,
  });

  const data = useMemo(() => buildData(tenantEventsByDayState.data || []), [
    tenantEventsByDayState,
  ]);

  return (
    <CardContainer
      isLoading={tenantEventsByDayState.isLoading}
      isSuccess={tenantEventsByDayState.isSuccess}
      isError={tenantEventsByDayState.isError}
      hasData={data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {tenantEventsByDayState.isSuccess && (
        <PieChart title={title} data={data} showTotal={false} />
      )}
    </CardContainer>
  );
};

interface InstallationEventsListProps
  extends EventsListProps,
    Omit<EventsListCard, 'type' | 'interactionType'> {
  installationId: string;
}

export const InstallationEventsList: React.FC<InstallationEventsListProps> = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  dataResidency,
  interactionType = InteractionType.All,
  gridStyles,
  isVisibleWithoutData,
  events,
  additionalData,
  title,
}) => {
  const { t } = useTranslation();
  const componentTitle: string = title || t('eventsListCardTitle');

  // Extract eventTypes from events and additionalData, if they are provided.
  const eventTypesFromEvents = (events || []).flatMap((event) => event.eventTypes);
  const eventTypesFromAdditionalData = (additionalData || []).flatMap(
    (event) => event.eventTypes,
  );

  // Combine, deduplicate and join all extracted eventTypes into a single string, separated by commas.
  const eventType = uniq([...eventTypesFromEvents, ...eventTypesFromAdditionalData]).join(
    ',',
  );

  const installationEventsByDayState = useInstallationEventsByDay({
    tenantId,
    installationId,
    dateFrom,
    dateTo,
    dataResidency,
    interactionType,
    eventType,
  });

  const data = useMemo(() => {
    if (events) {
      return buildEventsData(
        installationEventsByDayState.data || [],
        events,
        additionalData || [],
      );
    }
    return {
      eventsResult: buildData(installationEventsByDayState.data || []),
      additionalDataResult: [],
    };
  }, [installationEventsByDayState, events, additionalData]);

  return (
    <CardContainer
      isLoading={installationEventsByDayState.isLoading}
      isSuccess={installationEventsByDayState.isSuccess}
      isError={installationEventsByDayState.isError}
      hasData={data.eventsResult.length > 0}
      title={componentTitle}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {installationEventsByDayState.isSuccess && (
        <PieChart
          title={componentTitle}
          data={data.eventsResult}
          showTotal={false}
          footerData={data.additionalDataResult}
        />
      )}
    </CardContainer>
  );
};

interface SpaceEventsListProps extends EventsListProps {
  spaceId: string;
}

export const SpaceEventsList: React.FC<SpaceEventsListProps> = ({
  tenantId,
  spaceId,
  dateFrom,
  dateTo,
  dataResidency,
  interactionType = InteractionType.All,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { t } = useTranslation();
  const title = t('eventsListCardTitle');

  const spaceEventsByDayState = useSpaceEventsByDay({
    tenantId,
    spaceId,
    dateFrom,
    dateTo,
    dataResidency,
    interactionType,
  });

  const data = useMemo(() => buildData(spaceEventsByDayState.data || []), [
    spaceEventsByDayState,
  ]);

  return (
    <CardContainer
      isLoading={spaceEventsByDayState.isLoading}
      isSuccess={spaceEventsByDayState.isSuccess}
      isError={spaceEventsByDayState.isError}
      hasData={data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {spaceEventsByDayState.isSuccess && (
        <PieChart title={title} data={data} showTotal={false} />
      )}
    </CardContainer>
  );
};

interface DeviceEventsListProps extends EventsListProps {
  deviceId: string;
}

export const DeviceEventsList: React.FC<DeviceEventsListProps> = ({
  tenantId,
  deviceId,
  dateFrom,
  dateTo,
  dataResidency,
  interactionType = InteractionType.All,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { t } = useTranslation();
  const title = t('eventsListCardTitle');

  const deviceEventsByDayState = useDeviceEventsByDay({
    tenantId,
    deviceId,
    dateFrom,
    dateTo,
    dataResidency,
    interactionType,
  });

  const data = useMemo(() => buildData(deviceEventsByDayState.data || []), [
    deviceEventsByDayState,
  ]);

  return (
    <CardContainer
      isLoading={deviceEventsByDayState.isLoading}
      isSuccess={deviceEventsByDayState.isSuccess}
      isError={deviceEventsByDayState.isError}
      hasData={data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {deviceEventsByDayState.isSuccess && (
        <PieChart title={title} data={data} showTotal={false} />
      )}
    </CardContainer>
  );
};

interface AppEventsListProps extends EventsListProps {
  appId: string;
}

export const AppEventsList: React.FC<AppEventsListProps> = ({
  tenantId,
  appId,
  dateFrom,
  dateTo,
  dataResidency,
  interactionType = InteractionType.All,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { t } = useTranslation();
  const title = t('eventsListCardTitle');

  const appEventsByDayState = useAppEventsByDay({
    tenantId,
    appId,
    dateFrom,
    dateTo,
    dataResidency,
    interactionType,
  });

  const data = useMemo(() => buildData(appEventsByDayState.data || []), [
    appEventsByDayState,
  ]);

  return (
    <CardContainer
      isLoading={appEventsByDayState.isLoading}
      isSuccess={appEventsByDayState.isSuccess}
      isError={appEventsByDayState.isError}
      hasData={data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {appEventsByDayState.isSuccess && (
        <PieChart title={title} data={data} showTotal={false} />
      )}
    </CardContainer>
  );
};
