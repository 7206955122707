import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import { RouteComponentProps } from 'react-router';
import { RootModel, RootState } from '../../../../../../store/models/root.model';
import Modules from '../../common/azure-device-details-modules/modules.component';

type ModulesContainerProps = RouteComponentProps<{
  organisationId: string;
  deviceUuid: string;
}>;

const ModulesContainer = (props: ModulesContainerProps) => {
  const {
    match: {
      params: { organisationId, deviceUuid },
    },
  } = props;

  const { device, user, modules, deviceModules } = useStore<RootState>(
    (state) => ({
      device: state.azureDeviceDetails.data[deviceUuid] || null,
      user: state.auth.user,
      modules: state.modules.values,
      deviceModules: state.deviceModules.data[deviceUuid],
    }),
    [deviceUuid, organisationId],
  );

  const { fetchModules, fetchDeviceModules, updateDeviceModules } = useActions<RootModel>(
    (actions) => ({
      fetchModules: actions.modules.fetch,
      fetchDeviceModules: actions.deviceModules.fetch,
      updateDeviceModules: actions.deviceModules.update,
    }),
  );

  return (
    <Modules
      fetchModules={fetchModules}
      modules={modules}
      user={user}
      device={device}
      canManageModules
      fetchDeviceModules={fetchDeviceModules}
      updateDeviceModules={updateDeviceModules}
      deviceModules={deviceModules}
    />
  );
};

export default ModulesContainer;
