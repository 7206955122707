import React from 'react';
import styled from '@emotion/styled';
import { Spin } from 'antd';
import { SpinProps } from 'antd/lib/spin';
import { Icon } from '../schema-form/common';
import transientOptions from '../../../utils/transient-emotion-styled-options';

enum SpinIconPosition {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

interface SpinnerWrapProps extends SpinProps {
  isLoading?: boolean;
  iconSize?: number;
  iconPosition?: SpinIconPosition;
  children?: React.ReactNode;
}

const SpinnerWrap = (props: SpinnerWrapProps) => {
  const {
    isLoading,
    iconSize = 18,
    iconPosition = SpinIconPosition.RIGHT,
    indicator,
    children,
    ...spinProps
  } = props;

  const iconIndicator = indicator || <Icon type="loading" spin size={iconSize} />;

  if (!isLoading) {
    return <>{children}</>;
  }

  return (
    <SpinStyled
      className="spin-test"
      $iconSize={iconSize}
      $iconPosition={iconPosition}
      indicator={iconIndicator}
      {...spinProps}
    >
      {children}
    </SpinStyled>
  );
};

const SpinStyled = styled(Spin, transientOptions)<{
  $iconSize: number;
  $iconPosition: SpinIconPosition;
}>`
  .ant-spin-dot {
    font-size: ${({ $iconSize }) => $iconSize}px;
    ${({ $iconPosition }) => SpinIconPosition.LEFT === $iconPosition && `left: 2%;`}
    ${({ $iconPosition }) =>
      SpinIconPosition.RIGHT === $iconPosition && `right: 2%; left: unset !important;`}
  }
`;

export default SpinnerWrap;
