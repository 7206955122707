import { useCreateInstallationGroup as useCreateInstallationGroupQuery } from './use-installation-groups';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { message } from 'antd';

const useCreateInstallationGroupCallback = (organisationId: string) => {
  const { t } = useTranslation();

  const createInstallationGroupLogic = useCreateInstallationGroupQuery(organisationId);
  const createInstallationGroup = useCallback(
    async (data: any) => {
      try {
        const result = await createInstallationGroupLogic.mutateAsync({
          displayName: data,
          organizationId: organisationId,
        });
        message.success(t('installationGroupAdded'));
        return result.id;
      } catch (e) {
        message.error(e.response.data.message);
        throw e;
      }
    },
    [createInstallationGroupLogic, organisationId, t],
  );

  return createInstallationGroup;
};

export default useCreateInstallationGroupCallback;
