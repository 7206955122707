import React, { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import sumBy from 'lodash/sumBy';
import round from 'lodash/round';
import { Table, Tooltip, Button, Icon } from 'antd';
import { useTranslation } from 'react-i18next';
import useDevicesReport from '../use-devices-report';
import Spinner from '../spinner/spinner.component';
import DeviceTypeIcon from '../universal-devices/devices-universal-list-item-icon.component/devices-universal-list-item-icon.component';
import UniversalDeviceType from '../../../store/types/universal-device-type.enum';
import transientOptions from '../../../utils/transient-emotion-styled-options';
import * as antdColors from '@ant-design/colors';

const ChevronIcon = styled(Icon, transientOptions) <{ $isRotated: boolean }>`
  ${({ $isRotated }) => $isRotated && `transform: rotate(180deg);`};
`;

const StatusBarContainer = styled.div`
  display: flex;
  width: 100%;
  height: 8px;
  border-radius: 2px;
  overflow: hidden;
`;

const StatusBarItem = styled.div<{ width: number }>`
  ${({ width }) => `width: ${width}%`};
  margin-right: 2px;

  &:nth-last-of-type(-n + 1) {
    margin-right: 0;
  }
`;

const SuccessStatusBarItem = styled(StatusBarItem)`
  background-color: ${antdColors.green[6]};
`;

const WarningStatusBarItem = styled(StatusBarItem)`
  background-color: ${antdColors.orange.primary};
`;

const AlertStatusBarItem = styled(StatusBarItem)`
  background-color: ${antdColors.red[4]};
`;

const getPercentValue = (value: number, total: number): number =>
  round((value / total) * 100, 2);

interface BarProps {
  value: number;
  hint?: string;
}

interface StatusBarProps {
  successBar: BarProps;
  warningBar: BarProps;
  alertBar: BarProps;
}

const StatusBar: React.FC<StatusBarProps> = ({ successBar, warningBar, alertBar }) => {
  const totalValue = successBar.value + warningBar.value + alertBar.value;

  const successBarWidth = getPercentValue(successBar.value, totalValue);
  const warningBarWidth = getPercentValue(warningBar.value, totalValue);
  const alertBarWidth = getPercentValue(alertBar.value, totalValue);

  const isSuccessBarVisible = successBarWidth > 0;
  const isWarningBarVisible = warningBarWidth > 0;
  const isAlertBarVisible = alertBarWidth > 0;

  return (
    <StatusBarContainer>
      {isSuccessBarVisible && (
        <Tooltip placement="top" title={successBar.hint}>
          <SuccessStatusBarItem width={successBarWidth} />
        </Tooltip>
      )}

      {isWarningBarVisible && (
        <Tooltip placement="top" title={warningBar.hint}>
          <WarningStatusBarItem width={warningBarWidth} />
        </Tooltip>
      )}

      {isAlertBarVisible && (
        <Tooltip placement="top" title={alertBar.hint}>
          <AlertStatusBarItem width={alertBarWidth} />
        </Tooltip>
      )}
    </StatusBarContainer>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const Wrapper = styled.div`
  width: 100%;

  .ant-table-footer {
    background-color: #fff;
  }
`;

const Header = styled.div``;

const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderStatusBar = styled.div``;

const Content = styled.div`
  padding-top: 25px;
`;

const Indicator = styled.div`
  display: inline-flex;
  width: 8px;
  height: 8px;
  margin-right: 4px;
  border-radius: 50%;
`;

const SuccessIndicator = styled(Indicator)`
  background-color: ${antdColors.green[6]};
`;

const WarningIndicator = styled(Indicator)`
  background-color: ${antdColors.orange.primary};
`;

const AlertIndicator = styled(Indicator)`
  background-color: ${antdColors.red[4]};
`;

const LegendItemContainer = styled.div`
  margin-right: 48px;
  font-weight: bold;
  font-size: 12px;
  color: #111111;
  text-transform: uppercase;
`;

interface LegendItemProps {
  children: React.ReactNode;
}

const LegendItem: React.FC<LegendItemProps> = ({ children }) => {
  return <LegendItemContainer>{children}</LegendItemContainer>;
};

interface DevicesReportProps {
  tenantId: string;
  installationId?: string;
  spaceId?: string;
}

const DevicesReport: React.FC<DevicesReportProps> = ({
  tenantId,
  installationId,
  spaceId,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const onToggle = useCallback(() => setIsExpanded((prev) => !prev), []);

  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        title: t('devicesReportDeviceType'),
        key: 'type',
        width: 150,
        render: ({ type }: { type: string }) => {
          return <DeviceTypeIcon deviceType={type as UniversalDeviceType} />;
        },
      },
      {
        title: '',
        dataIndex: 'statusBar',
        key: 'statusBar',
        render: ({
          successValue,
          warningValue,
          alertValue,
        }: {
          successValue: number;
          warningValue: number;
          alertValue: number;
        }) => (
          <StatusBar
            successBar={{ value: successValue, hint: t('devicesReportOnline') }}
            warningBar={{ value: warningValue, hint: t('devicesReportFailing') }}
            alertBar={{ value: alertValue, hint: t('devicesReportOffline') }}
          />
        ),
        width: 300,
      },
      {
        title: t('devicesReportOnline'),
        dataIndex: 'online',
        key: 'online',
        render: (value: number) => (
          <>
            <SuccessIndicator /> {value}
          </>
        ),
      },
      {
        title: t('devicesReportFailing'),
        dataIndex: 'failing',
        key: 'failing',
        render: (value: number) => (
          <>
            <WarningIndicator /> {value}
          </>
        ),
      },
      {
        title: t('devicesReportOffline'),
        dataIndex: 'offline',
        key: 'offline',
        render: (value: number) => (
          <>
            <AlertIndicator /> {value}
          </>
        ),
      },
      {
        title: t('devicesReportTotal'),
        dataIndex: 'total',
        key: 'total',
      },
    ],
    [t],
  );

  const devicesReportState = useDevicesReport({ tenantId, installationId, spaceId });

  const reportItems = useMemo(() => devicesReportState.data || [], [devicesReportState]);

  const totalDevicesCount = useMemo(
    () => sumBy(reportItems, (reportItem) => reportItem.total),
    [reportItems],
  );
  const onlineDevicesCount = useMemo(
    () => sumBy(reportItems, (reportItem) => reportItem.online),
    [reportItems],
  );
  const failingDevicesCount = useMemo(
    () => sumBy(reportItems, (reportItem) => reportItem.failing),
    [reportItems],
  );
  const offlineDevicesCount = useMemo(
    () => sumBy(reportItems, (reportItem) => reportItem.offline),
    [reportItems],
  );

  const data = useMemo(() => {
    const result = reportItems.map((reportItem, index) => {
      const dataRow = {
        key: index,
        type: reportItem.type,
        statusBar: {
          successValue: reportItem.online,
          warningValue: reportItem.failing,
          alertValue: reportItem.offline,
        },
        online: reportItem.online,
        failing: reportItem.failing,
        offline: reportItem.offline,
        total: reportItem.total,
      };

      return dataRow;
    });

    return result;
  }, [reportItems]);

  const isAnyReportItemExists = reportItems.length > 0;

  return (
    <Container>
      {devicesReportState.isLoading && <Spinner />}

      {devicesReportState.isSuccess && isAnyReportItemExists && (
        <Wrapper>
          <Header>
            <HeaderWrapper>
              <Legend>
                <LegendItem>
                  {`${totalDevicesCount} ${t('devicesReportDevices')}`}
                </LegendItem>

                <LegendItem>
                  <SuccessIndicator />

                  {`${onlineDevicesCount} ${t('devicesReportOnline')}`}
                </LegendItem>

                <LegendItem>
                  <WarningIndicator />

                  {`${failingDevicesCount} ${t('devicesReportFailing')}`}
                </LegendItem>

                <LegendItem>
                  <AlertIndicator />

                  {`${offlineDevicesCount} ${t('devicesReportOffline')}`}
                </LegendItem>
              </Legend>

              <Button type="link" onClick={onToggle}>
                <ChevronIcon type="down" $isRotated={isExpanded} />

                {t('devicesReportToggle')}
              </Button>
            </HeaderWrapper>

            <HeaderStatusBar>
              <StatusBar
                successBar={{
                  value: onlineDevicesCount,
                  hint: t('devicesReportOnline'),
                }}
                warningBar={{
                  value: failingDevicesCount,
                  hint: t('devicesReportFailing'),
                }}
                alertBar={{
                  value: offlineDevicesCount,
                  hint: t('devicesReportOffline'),
                }}
              />
            </HeaderStatusBar>
          </Header>

          {isExpanded && (
            <Content>
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                scroll={{ x: 800 }}
              />
            </Content>
          )}
        </Wrapper>
      )}
    </Container>
  );
};

export default DevicesReport;
