import GridappTypeEnum from './gridapp-type.enum';
import GridappBuild from './gridapp-build';
import CurrencyAwareValue from './currency-aware-value';

export default interface Gridapp {
  id: string;
  author: string;
  organizationId: string;
  displayName: string;
  packageName?: string;
  description?: string;
  requirements?: string[];
  type: GridappTypeEnum;
  createdAt: string;
  updatedAt: string;
  latestBuild?: GridappBuild;
  access: GridappAccess;
  screenshots?: string[];
  price: CurrencyAwareValue | null;
  sku?: string;
  unit: string | null;
  organizationsAccess?: string[];
}

export enum GridappAccess {
  PUBLIC = 'public',
  PRIVATE = 'private',
  SUBTENANTS = 'subtenants',
  SPECIFIC_TENANTS = 'specific-tenants',
}

export enum GridappSpecialGroupTypes {
  SPECIAL_OFFER_APPS = 'special-offer',
  ALL_APPS = 'all-apps'
}

export interface GroupedGridapps {
  type: string;
  title: string;
  apps: Gridapp[];
};
