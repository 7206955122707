import createKeyedCrudModel, {
  KeyedCrudModel,
} from '../../common/keyed-crud-model/keyed-crud.model';
import User from '../../types/user';

export type OrganisationUsersModel = KeyedCrudModel<User, { organizationId: string }>;

const organisationUsersModel = createKeyedCrudModel<User, { organizationId: string }>(
  '/api/users',
  'organizationId',
);

export default organisationUsersModel;
