import React from 'react';
import styled from '@emotion/styled';
import { Avatar, Tooltip } from 'antd';
import { AvatarProps } from 'antd/lib/avatar';
import GridappBuild from '../../../../store/types/gridapp-build';
import GridappBuildStatusEnum from '../../../../store/types/gridapp-build-status.enum';

const AppLibraryItemAvatar = styled(Avatar)`
  background-color: rgba(0, 0, 0, 0.02);
  img {
    object-fit: cover;
  }
`;

const ErrorAppLibraryItemAvatar = styled(AppLibraryItemAvatar)`
  color: #a94442;
  background-color: rgba(255, 0, 0, 0.04);
`;

interface AppBuildIconProps extends AvatarProps {
  build: GridappBuild;
  defaultIcon: string;
}

const AppBuildIcon = ({ build, defaultIcon, ...props }: AppBuildIconProps) => {
  if (build.status === GridappBuildStatusEnum.ERROR) {
    return (
      <Tooltip
        placement="right"
        title="Error during template processing. Make sure your file is valid Grid Application."
      >
        <ErrorAppLibraryItemAvatar {...props} icon="exclamation-circle" shape="square" />
      </Tooltip>
    );
  }

  let avatarProps: Partial<AvatarProps> = {};

  if (build.status === GridappBuildStatusEnum.PROCESSING) {
    avatarProps = {
      icon: 'loading',
    };
  }

  if (
    [GridappBuildStatusEnum.DRAFT, GridappBuildStatusEnum.PUBLISHED].includes(
      build.status,
    )
  ) {
    avatarProps =
      build.result && build.result.iconUrl
        ? { src: build.result.iconUrl }
        : { icon: defaultIcon };
  }

  return <AppLibraryItemAvatar {...props} {...avatarProps} shape="square" />;
};

export default AppBuildIcon;
