import * as React from 'react';
import { Tabs } from 'antd';

interface TabBarProps {
  children: React.ReactNode;
  activeKey: string;
  onChange: (key: string) => void;
  className?: string;
  lang?: string;
  type?: any;
  size?: any;
  refreshTrigger?: string;
  tabBarGutter?: number;
  tabBarStyle?: object;
}

const TabBar = ({
  children,
  activeKey,
  onChange,
  className,
  type,
  size,
  tabBarGutter,
  tabBarStyle,
  ...restProps
}: TabBarProps) => {
  return (
    <Tabs
      className={className}
      onChange={onChange}
      activeKey={activeKey}
      tabBarStyle={tabBarStyle}
      type={type}
      size={size}
      tabBarGutter={tabBarGutter}
      animated={false}
      {...restProps}
    >
      {children}
    </Tabs>
  );
};

TabBar.defaultProps = {
  tabBarStyle: {
    margin: 0,
    borderBottom: 0,
  },
};

export default React.memo(TabBar, (prevProps, nextProps) => {
  // TODO: children are acting up here causing extra renders, investigate
  return (
    prevProps.activeKey === nextProps.activeKey &&
    prevProps.onChange === nextProps.onChange &&
    prevProps.lang === nextProps.lang &&
    prevProps.refreshTrigger === nextProps.refreshTrigger
  );
});
