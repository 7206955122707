import { formatCurrencyAwareValue } from '../currency';
import GridappTypeEnum from '../../store/types/gridapp-type.enum';
import Gridapp from '../../store/types/gridapp';

export const getEntityUnit = (gridappType: GridappTypeEnum) => {
  switch (gridappType) {
    case GridappTypeEnum.CLOUD:
      return 'installation';
    case GridappTypeEnum.SCREEN:
    case GridappTypeEnum.IOT_MODULE:
    case GridappTypeEnum.IOT:
      return 'device';
    case GridappTypeEnum.MOBILE:
      return 'URL';
    default:
      return '';
  }
}

export const getFormattedPricingFromGridapp = (gridappProperties: Pick<Gridapp, 'type' | 'price' | 'unit'>) => {
  const entityUnit = gridappProperties.type ? getEntityUnit(gridappProperties.type) : '';
  const price = gridappProperties.price ? `${formatCurrencyAwareValue(gridappProperties.price, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 5,
  })} ${gridappProperties.unit ? `/ ${gridappProperties.unit}` : ''}` : '';

  return price && entityUnit ? `${price} / ${entityUnit}` : price;
}
