import React from 'react';
import { Field } from 'react-final-form';
import { Select } from 'antd';
import { getSortedCountries } from '../../../utils/countries';
import { ChangeEvent } from '../../../types';
import InputWrap from './input-wrap';
import { requiredAll } from '../../organisations/organisation-details/queue-details/queue-setup/validate-form';

interface CountryPickerProps {
  name: string;
  label: string;
  isRequired?: boolean;
  showSearch?: boolean;
}

const CountryPicker = (props: CountryPickerProps) => {
  const { name, label, isRequired = false, showSearch } = props;

  const countries = getSortedCountries();

  return (
    <>
      <Field
        name={name}
        validate={isRequired ? requiredAll : undefined}
        render={({ input, meta }) => (
          <InputWrap
            label={label}
            isRequired={isRequired}
            error={meta.error && meta.touched ? meta.error : undefined}
          >
            <Select
              showSearch={showSearch}
              optionFilterProp='children'
              filterOption={(input, option) => {
                const optionKey = (option.key as string) || '';
                return optionKey.includes(input.toLowerCase());
              }}
              value={input.value}
              onChange={(value) => {
                input.onChange({
                  target: {
                    value
                  }
                } as unknown as ChangeEvent<string>)
              }}
            >
              {countries.codes.map((code, index) => (
                <Select.Option key={countries.names[index].toLowerCase()} value={code}>
                  {countries.names[index]}
                </Select.Option>
              ))}
            </Select>
          </InputWrap>
        )}
      />
    </>
  )
}

export default CountryPicker;
