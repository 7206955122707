import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Typography, List, Avatar, Button, Tag, Icon } from 'antd';
import PanelCard from '../../../../../common/panel-card/panel-card.component';
import Plan, { PlanFeature } from '../../../../../../store/types/organisation-plan';
import { BillingCurrencyEnum } from '../../../../../../store/types/organisation';
import { getTranslationFromObject } from '../../../../../../utils/language/language.util';
import { getMonthlyFee } from '../../../../../../utils/plans';
import { fallbackIconAlt } from '../../../../../../utils/installations/get-installation-app-icon-url';

const { Title, Text } = Typography;

interface AvailablePlansCardProps {
  plan: Plan;
  isActivePlan?: boolean;
  tenantCurrency?: BillingCurrencyEnum;
  onSelectAvailablePlan: (plan: Plan) => void;
}

const AvailablePlansCard = (props: AvailablePlansCardProps) => {
  const { t } = useTranslation();
  const {
    plan,
    isActivePlan = false,
    tenantCurrency = BillingCurrencyEnum.USD,
    onSelectAvailablePlan,
  } = props;
  const { name, description, features, activePrice } = plan;
  const { prices = [] } = activePrice || {};

  const { monthlyFee, tierTag, buttonLabel } = useMemo(() => {
    const { formattedFee, price } = getMonthlyFee(tenantCurrency, prices);
    const planName = getTranslationFromObject(name);
    const label =
      price > 0
        ? t('plans.buyButton', { planName })
        : t('plans.freeButton', { planName });

    return {
      monthlyFee: formattedFee,
      tierTag: planName,
      buttonLabel: isActivePlan ? t('plans.activePlanButton') : label,
    };
  }, [t, name, prices, tenantCurrency, isActivePlan]);

  const planItems = useMemo(() => <PlanItems items={features} />, [features]);

  return (
    <PanelCardStyled bodypadding="0px">
      <PlanHeading>
        <NameTag color="#202020">{tierTag}</NameTag>
        <MonthlyFee>{t('plans.pricePerMonth', { price: monthlyFee })}</MonthlyFee>
        <Pitch>
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: getTranslationFromObject(description),
            }}
          />
        </Pitch>
        <PlanSelectButton
          type="primary"
          disabled={isActivePlan}
          onClick={() => {
            onSelectAvailablePlan(plan);
          }}
        >
          {buttonLabel}
        </PlanSelectButton>
      </PlanHeading>
      {planItems}
    </PanelCardStyled>
  );
};

const PlanItems = ({ items }: { items: PlanFeature[] }) => {
  return (
    <PlanItemList
      itemLayout="horizontal"
      dataSource={items}
      shownodataui="false"
      renderItem={(item: PlanFeature) => {
        const { name, description, icon } = item;

        const itemName = getTranslationFromObject(name);
        const itemDescription = getTranslationFromObject(description);

        const iconUrl = icon.includes('http') ? icon : undefined;
        const iconKey = !iconUrl ? icon : undefined;

        return (
          <List.Item>
            <List.Item.Meta
              avatar={
                <PlanItemAvatar shape="square" icon={iconKey} src={iconUrl}>
                  <FallbackIcon component={() => <img src={fallbackIconAlt} alt="" />} />
                </PlanItemAvatar>
              }
              title={<div>{itemName}</div>}
              description={<div>{itemDescription}</div>}
            />
          </List.Item>
        );
      }}
    />
  );
};

const PlanHeading = styled.div`
  flex: 0 1 auto;
  text-align: center;

  > span.ant-tag {
    margin-bottom: 15px;
  }
  > h4.ant-typography {
    margin-bottom: 15px;
  }
  > span.ant-typography {
    font-weight: 400;
    font-size: 13px;
    display: block;
    margin-bottom: 15px;
  }
  > button {
    margin-bottom: 15px;
  }
`;

const PanelCardStyled = styled(PanelCard)`
  .ant-card-head-wrapper {
    align-items: start;
    margin-bottom: 0px;
  }

  padding: 35px 0 10px 0;
  width: 290px;
  margin: 0px 10px 15px 10px;
  box-shadow: 0px 6px 10px 0px rgb(0 0 0 / 10%);
  height: fit-content;

  @media screen and (max-width: 991px) {
    margin: 0px 0px 15px 0px;
  }
`;

const MonthlyFee = styled(Title)`
  font-size: 24px !important;
  padding: 0px 20px;
`;

const NameTag = styled(Tag)`
  font-size: 12px;
  font-weight: 300;
  padding: 2px 8px;
  text-transform: uppercase;
  line-height: 23px;
  max-width: 230px;
  word-break: break-word;
  white-space: break-spaces;
  margin: auto 20px;
  margin: auto 15px;
`;

const Pitch = styled(Text)`
  width: 230px;
  margin-left: auto;
  margin-right: auto;
`;

const PlanItemList = styled(List)<{ shownodataui?: 'true' | 'false' }>`
  .ant-list-item {
    padding-left: 20px;
    padding-right: 20px;
  }

  ${({ shownodataui }) => {
    return (
      shownodataui === 'false' &&
      `
        .ant-list-empty-text {
          margin-bottom: 15px;
          padding: 0px;
        }
        .ant-empty {
          display: none
        }
      `
    );
  }}
` as any;

const PlanItemAvatar = styled(Avatar)`
  font-size: 25px !important;
  top: 3px;

  .ant-avatar-string {
    transform: none !important;
    position: unset !important;
  }
`;

const PlanSelectButton = styled(Button)<{ disabled?: boolean }>`
  text-transform: capitalize;
  width: 230px;
  height: 48px;
  margin: auto 15px;
  ${({ disabled }) => disabled && '&, &:hover { color: #202020a3 !important; }'}
` as any;

const FallbackIcon = styled(Icon)`
  min-height: 25px;
  min-width: 25px;
  position: relative;
  top: -5px;
`;

export default AvailablePlansCard;
