import { TFunction } from 'i18next';
import { AggregationOperatorEnum, AlertConditionTypeEnum, NumericOperatorEnum, SeverityEnum, StringOperatorEnum, UserRoleEnum } from '../store/types/alerts';

interface Option {
  id: string | number | boolean;
  label: string;
}

export const getSeverityOptions = (t: TFunction): Option[] => {
  return [
    {
      id: SeverityEnum.CRITICAL,
      label: t('severity.critical'),
    },
    {
      id: SeverityEnum.ERROR,
      label: t('severity.error'),
    },
    {
      id: SeverityEnum.WARNING,
      label: t('severity.warning'),
    },
    {
      id: SeverityEnum.INFORMATION,
      label: t('severity.information'),
    },
  ];
};

export const getSeverity = (severity: SeverityEnum, t: TFunction): string => {
  const options = getSeverityOptions(t);
  const matchedOption = options.find((option) => option.id === severity) as Option;

  return matchedOption.label;
};

export const getUserRoleOptions = (t: TFunction): Option[] => {
  return [
    {
      id: UserRoleEnum.ADMIN,
      label: t('userRoleType.admin'),
    },
    {
      id: UserRoleEnum.EDITOR,
      label: t('userRoleType.editor'),
    },
    {
      id: UserRoleEnum.VIEW,
      label: t('userRoleType.viewer'),
    },
  ];
};

export const getUserRole = (userRole: UserRoleEnum, t: TFunction): string => {
  const options = getUserRoleOptions(t);
  const matchedOption = options.find((option) => option.id === userRole) as Option;

  return matchedOption ? matchedOption.label : userRole.toString();
};

export const getStatusOptions = (t: TFunction): Option[] => {
  return [
    {
      id: true,
      label: t('alertStatus.enabled'),
    },
    {
      id: false,
      label: t('alertStatus.disabled'),
    },
  ];
};

export const getStatus = (status: boolean, t: TFunction): string => {
  const options = getStatusOptions(t);
  const matchedOption = options.find((option) => option.id === status) as Option;

  return matchedOption.label;
};

export const getConditionOptions = (t: TFunction): Option[] => {
  return [
    {
      id: AlertConditionTypeEnum.DEVICE_STATUS,
      label: t('condition.deviceStatus'),
    },
    // {
    //   id: AlertConditionTypeEnum.CPU_USAGE,
    //   label: t('condition.cpuUsage'),
    // },
    // {
    //   id: AlertConditionTypeEnum.MEMORY_USAGE,
    //   label: t('condition.memoryUsage'),
    // },
    // {
    //   id: AlertConditionTypeEnum.DISK_USAGE,
    //   label: t('condition.diskUsage'),
    // },
    // {
    //   id: AlertConditionTypeEnum.BLACK_SCREEN,
    //   label: t('condition.blackScreen'),
    // },
    // {
    //   id: AlertConditionTypeEnum.WHITE_SCREEN,
    //   label: t('condition.whiteScreen'),
    // },
    // {
    //   id: AlertConditionTypeEnum.SCREEN_MODULE_CRASH,
    //   label: t('condition.screenModuleCrash'),
    // },
    // {
    //   id: AlertConditionTypeEnum.NO_SCREEN_CONNTECTED,
    //   label: t('condition.noScreenConnected'),
    // },
    // {
    //   id: AlertConditionTypeEnum.CUSTOM_MODULE_REPORTED_PROP,
    //   label: t('condition.customModuleReportedProp'),
    // },
    {
      id: AlertConditionTypeEnum.DEVICE_ANALYTICS_SESSION_COUNT,
      label: t('condition.deviceAnalyticsSessionCount'),
    },
  ];
};

export const getCondition = (condition: AlertConditionTypeEnum, t: TFunction): string => {
  const options = getConditionOptions(t);
  const matchedOption = options.find((option) => option.id === condition) as Option;

  return matchedOption.label;
};

export const getOperatorOptions = (t: TFunction): Option[] => {
  return [
    {
      id: NumericOperatorEnum.GREAT_THAN,
      label: t('operator.greaterThan'),
    },
    {
      id: NumericOperatorEnum.EQUAL_TO,
      label: t('operator.equalTo'),
    },
    {
      id: NumericOperatorEnum.GREATER_THAN_OR_EQUAL_TO,
      label: t('operator.greaterThanOrEqualTo'),
    },
    {
      id: NumericOperatorEnum.LESS_THAN,
      label: t('operator.lessThan'),
    },
    {
      id: NumericOperatorEnum.LESS_THAN_OR_EQUAL_TO,
      label: t('operator.lessThanOrEqualTo'),
    },
  ];
};

export const getStringOperatorOptions = (t: TFunction): Option[] => {
  return [
    {
      id: StringOperatorEnum.EQUAL_TO,
      label: t('operator.equalTo'),
    }
  ]
}

export const getAggregationOperatorOptions = (t: TFunction): Option[] => {
  return [
    {
      id: AggregationOperatorEnum.SUM,
      label: t('aggregationOperator.sum'),
    },
    {
      id: AggregationOperatorEnum.AVERAGE,
      label: t('aggregationOperator.average'),
    },
  ]
};

export const getOperator = (operator: NumericOperatorEnum | StringOperatorEnum, t: TFunction): string => {
  const options = getConditionOptions(t);
  const matchedOption = options.find((option) => option.id === operator) as Option;

  return matchedOption.label;
};
