import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Switch, Link, Route } from 'react-router-dom';
import styled from '@emotion/styled';
import { Icon } from 'antd';
import { useTranslation } from 'react-i18next';
import ErrorBoundary from '../../../../common/error-boundary/error-boundary.component';
import Header from '../../../../common/app-layout/header/header.component';
import SpacesOverview from './spaces-report.component';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SpacesReportContainerProps
  extends RouteComponentProps<{ spaceId: string; organisationId: string }> {}

const SpacesReportContainer = (props: SpacesReportContainerProps) => {
  const {
    match: {
      path,
      params: { organisationId },
    },
  } = props;
  const { t } = useTranslation();

  return (
    <ErrorBoundary>
      <BackButton>
        <Link to={`/organisations/${organisationId}/spaces`}>
          <BackIcon type="left" />
          {t('backToSpacesList')}
        </Link>
      </BackButton>
      <HeaderStyled title={<span>{t('spaces')}</span>} />
      <Switch>
        {/* TO DO: Revisit if can be done without a Route */}
        <Route path={path} component={SpacesOverview} />
      </Switch>
    </ErrorBoundary>
  );
};

const HeaderStyled = styled(Header)`
  padding-top: 0;
`;

const BackButton = styled.span`
  display: block;
  background: rgb(245, 245, 250);
  padding: 16px;

  a {
    color: #676973;
    &:hover {
      color: #0f111a;
    }
  }
  @media screen and (min-width: 768px) {
    padding: 16px 40px;
  }
`;

const BackIcon = styled(Icon)`
  margin-right: 5px;
`;

export default SpacesReportContainer;
