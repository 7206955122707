import React, { useCallback, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Tabs, Button, message, Icon } from 'antd';
import { getPermissionPath, permissionKeys } from '../../../../utils/auth/permissions';
import ErrorBoundary from '../../../common/error-boundary/error-boundary.component';
import Header from '../../../common/app-layout/header';
import TabBar from '../../../common/tab-bar/tab-bar.component';
import ProtectedRouteContainer from '../../../common/protected-route/protected-route.container';
import { Download } from 'react-feather';
import DataExportActiveContainer from './data-export-active/data-export-active.container';
import DataExportExpiredContainer from './data-export-expired/data-export-expired.container';
import usePermissions from '../../../../utils/auth/use-permissions';
import AddNewJobModal from './common/add-new-job-modal';
import {
  AanalyticsDataForm,
  AanalyticsDataPostForm,
  AnalyticsExportSchedule,
  ScheduleTypeEnum,
} from '../../../../store/types/analytics-data-export';
import {
  useCreateAnalyticsJob,
  useManageScheduleAnalytics,
} from '../../../common/use-analytics-data-export';
import ManageScheduledModal from './common/managed-scheduled-modal';
import { useAnalyticsDataSchedule } from '../../../common/use-analytics-data-export';
import ScheduledTextComponent from './common/scheduled-text-component';

type DataExportProps = RouteComponentProps<{ organisationId: string }>;

const DataExport = ({ match, history, location }: DataExportProps) => {
  const [showNewJobModal, setShowNewJobModal] = useState<boolean>(false);
  const [showScheduleModal, setShowScheduleModal] = useState<boolean>(false);
  const { organisationId } = match.params;

  const { t, i18n } = useTranslation();
  const { isAllowed } = usePermissions(organisationId);
  const { mutateAsync } = useCreateAnalyticsJob({ organisationId: organisationId });
  const { mutateAsync: scheduleMutateAsync } = useManageScheduleAnalytics(organisationId);
  const { data, isSuccess } = useAnalyticsDataSchedule(organisationId);

  const isActiveTab = useMemo(() => {
    return location.pathname === `/organisations/${organisationId}/data-export/active`;
  }, [location.pathname, organisationId]);

  const {
    showExpiredTab,
    createNewJob,
    createScheduledJob,
    showScheduledMessage,
  } = useMemo(
    () => ({
      showExpiredTab: isAllowed(permissionKeys.analyticsExportJob.viewAll),
      createNewJob: isAllowed(permissionKeys.analyticsExportJob.create),
      createScheduledJob: isAllowed(permissionKeys.analyticsExportSchedule.create),
      showScheduledMessage: isAllowed(permissionKeys.analyticsExportSchedule.viewAll),
    }),
    [isAllowed],
  );

  const scheduledJob = useMemo(() => (isSuccess ? data : {}), [
    isSuccess,
    data,
  ]) as AnalyticsExportSchedule;

  const onTabChange = useCallback(
    (key: string) => {
      history.push(key);
    },
    [history],
  );

  const handleCloseNewJobModal = useCallback(() => {
    setShowNewJobModal(false);
  }, [setShowNewJobModal]);

  const showNewJobModel = useCallback(() => {
    setShowNewJobModal(true);
  }, [setShowNewJobModal]);

  const showManageSchedulModel = useCallback(() => {
    setShowScheduleModal(true);
  }, [setShowScheduleModal]);

  const handleCloseScheduleModal = useCallback(() => {
    setShowScheduleModal(false);
  }, [setShowScheduleModal]);

  const handleNewJobModalOkAction = useCallback(
    async ({ values }: { values: Partial<AanalyticsDataForm> }) => {
      const body = {
        tenantId: organisationId,
        ...values,
      } as AanalyticsDataPostForm;

      if (!mutateAsync) return;
      try {
        await mutateAsync(body);
      } catch (e) {
        message.error(t('errorSavingData'));
        throw e;
      }
      handleCloseNewJobModal();
    },
    [handleCloseNewJobModal, mutateAsync, organisationId, t],
  );

  const handleScheduledModalOkAction = useCallback(
    async ({ values }: { values: Partial<AnalyticsExportSchedule> }) => {
      if (values.type === ScheduleTypeEnum.WEEKLY) {
        values.day = values.weekDay ? +values.weekDay : 0;
        delete values.weekDay;
      }
      const body = {
        tenantId: organisationId,
        ...values,
      } as AnalyticsExportSchedule;

      if (!scheduleMutateAsync) return;
      try {
        await scheduleMutateAsync(body);
      } catch (e) {
        message.error(t('errorSavingData'));
        throw e;
      }
      handleCloseScheduleModal();
    },
    [handleCloseScheduleModal, scheduleMutateAsync, organisationId, t],
  );

  return (
    <ErrorBoundary>
      <Header
        tabTitle={t('dataExport.title')}
        title={t('dataExport.title')}
        icon={<Download size={20} color="#fff" />}
        style={{ paddingBottom: '0px' }}
      >
        <ActionsContainer>
          <TabBarStyled
            onChange={onTabChange}
            activeKey={location.pathname}
            lang={i18n.language}
          >
            <Tabs.TabPane
              tab={<span>{t('dataExport.active')}</span>}
              key={`${match.url}/active`}
            />
            {showExpiredTab && (
              <Tabs.TabPane
                tab={<span>{t('dataExport.expired')}</span>}
                key={`${match.url}/expired`}
              />
            )}
          </TabBarStyled>
          {isActiveTab && (
            <ActionModalContainer>
              {showScheduledMessage &&
                scheduledJob &&
                scheduledJob.enabled &&
                !!Object.keys(scheduledJob).length && (
                  <ScheduledTextComponent scheduledJobData={scheduledJob} />
                )}
              {createScheduledJob && (
                <ScheduledButton
                  onClick={showManageSchedulModel}
                >
                  <>
                    <StyledCalendarIcon type="calendar" />
                    {t('dataExport.manageSchedule')}
                  </>
                </ScheduledButton>
              )}
              {createNewJob && (
                <Button type="primary" onClick={showNewJobModel}>
                  <Icon type="plus" /> {t('dataExport.triggerNow')}
                </Button>
              )}
            </ActionModalContainer>
          )}
        </ActionsContainer>
      </Header>
      <Switch>
        <Route
          exact
          path={match.path}
          render={() => <Redirect to={`${match.url}/active`} />}
        />
        <ProtectedRouteContainer
          permissionPath={getPermissionPath(
            organisationId,
            permissionKeys.analyticsExportJob.viewAll,
          )}
          path={`${match.path}/active`}
          component={DataExportActiveContainer}
        />
        <ProtectedRouteContainer
          exact
          permissionPath={getPermissionPath(
            organisationId,
            permissionKeys.analyticsExportJob.viewAll,
          )}
          path={`${match.path}/expired`}
          component={DataExportExpiredContainer}
        />
      </Switch>
      <AddNewJobModal
        visible={showNewJobModal}
        onClose={handleCloseNewJobModal}
        onOk={handleNewJobModalOkAction}
        organisationId={organisationId}
      />
      <ManageScheduledModal
        visible={showScheduleModal}
        onClose={handleCloseScheduleModal}
        onOk={handleScheduledModalOkAction}
        organisationId={organisationId}
        scheduledJob={scheduledJob}
      />
    </ErrorBoundary>
  );
};

const TabBarStyled = styled(TabBar)`
  margin-top: 10px;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    overflow-y: auto;
  }
`;

const ActionModalContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const ScheduledButton = styled(Button)`
  color: #2364aa;
  background-color: transparent !important;
  border: 1px solid #80808040 !important;
` as any;

const StyledCalendarIcon = styled(Icon)`
  font-weight: bold;
  margin-right: 6px;
`;
export default DataExport;
