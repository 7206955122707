import React, { FC, useCallback, useMemo } from 'react';
import usePagination from '../pagination/hook/use-pagination';
import usePhyhubDevicesList, {
  PhyhubDevicesListQueryParams,
} from '../../../store/hooks/phyhub-devices/use-phyhub-devices-list';
import { SortCriteria, SortOrder } from '../../../store/types/sort';
import FilterItem, { ActiveFilter } from '../../../store/types/filters';
import PhyhubDevices from './phyhub-devices.component';
import usePaginationQueryParams from '../use-pagination-query-params';

interface PhyhubDevicesContainerProps {
  tenantId: string;
  filterOptions: FilterItem[];
  sortCriteriaOptions: SortCriteria[];
  // TODO: Add filters for other Phyhub device pages
}

const PhyhubDevicesContainer: FC<PhyhubDevicesContainerProps> = (props) => {
  const { tenantId, filterOptions, sortCriteriaOptions } = props;

  const { page, defaultPage, pageSize, defaultPageSize } = usePagination();

  const [paginationQueryParams] = usePaginationQueryParams({
    page: page || defaultPage,
    limit: pageSize || defaultPageSize,
  });

  const phyhubDevicesListQueryParams = useMemo<PhyhubDevicesListQueryParams>(
    () => ({
      tenantId,
      limit: paginationQueryParams.limit || defaultPageSize,
      page: paginationQueryParams.page || defaultPage,
    }),
    [tenantId, paginationQueryParams],
  );

  const {
    data: phyhubDevicesCollection,
    isError: isPhyhubDevicesListError,
    isFetching: isPhyhubDevicesListFetching,
    isLoading: isPhyhubDevicesListLoading,
  } = usePhyhubDevicesList(phyhubDevicesListQueryParams);

  const handleCriteriaChange = useCallback((criteria: SortCriteria, order: SortOrder) => {
    // TODO: Implement criteria and order change handling
    console.log('Handle criteria change: ', { criteria, order });
  }, []);

  // TODO: Implement filters change handling
  const handleFiltersChange = useCallback((filters: ActiveFilter[]) => {
    console.log('Handle filters change: ', { filters });
  }, []);

  // TODO: Implement search handling
  const handleSearch = useCallback((phrase: string) => {
    console.log('Handle search: ', { phrase });
  }, []);

  if (!phyhubDevicesCollection) {
    // TODO: Add loading state is necessary
    return null;
  }

  return (
    <PhyhubDevices
      isDataLoading={isPhyhubDevicesListLoading || isPhyhubDevicesListFetching}
      isError={isPhyhubDevicesListError}
      filterOptions={filterOptions}
      paginationCollection={phyhubDevicesCollection}
      sortCriteriaOptions={sortCriteriaOptions}
      onCriteriaChange={handleCriteriaChange}
      onFiltersChange={handleFiltersChange}
      onSearch={handleSearch}
    />
  );
};

export default PhyhubDevicesContainer;
