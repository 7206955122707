import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import ActionLogsList from './action-logs-list.component';
import { RootModel, RootState } from '../../../store/models/root.model';

const ActionLogsListContainer = () => {
  const { logs, loading, error, pagination } = useStore<RootState>((state) => ({
    logs: state.actionLogs.values,
    loading: state.actionLogs.loading,
    error: state.actionLogs.error,
    pagination: state.actionLogs.pagination,
  }));

  const fetchLogs = useActions<RootModel>((actions) => actions.actionLogs.fetch);

  return (
    <ActionLogsList
      pagination={pagination}
      logs={logs}
      loading={loading}
      error={error}
      fetchLogs={fetchLogs}
    />
  );
};

export default ActionLogsListContainer;
