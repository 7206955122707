import React, { useCallback, useRef } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { SchemaFormRef } from '../../../../../common/schema-form/schema-form.component';
import MobileEndpointCreateForm from '../../../../../common/mobile-endpoints/mobile-endpoints-create-form/mobile-endpoints-create-form.component';
import Environment from '../../../../../../store/types/environment';
import MobileEndpoint from '../../../../../../store/types/mobile-endpoint';
import Space from '../../../../../../store/types/organisation-space';

interface CreateMobileEndpointModalProps {
  visible: boolean;
  organizationId: string;
  environments: Environment[];
  mobileEndpoint?: Partial<MobileEndpoint>;
  spaces: Space[];
  fetchSpaces: (params: { organizationId: string; silent?: boolean }) => void;
  fetchEnvironments: (params: { organizationId: string }) => void;
  onClose: () => void;
  onSubmit: (values: Partial<MobileEndpoint>) => Promise<void>;
}

const CreateMobileEndpointModal = (props: CreateMobileEndpointModalProps) => {
  const {
    visible,
    onClose,
    onSubmit,
    environments,
    mobileEndpoint,
    spaces,
    fetchEnvironments,
    fetchSpaces,
  } = props;
  const { t } = useTranslation();
  const formElement = useRef<SchemaFormRef>(null);

  const handleSubmit = useCallback(() => {
    if (formElement.current) {
      formElement.current.submit();
    }
  }, [formElement]);

  return (
    <Modal
      destroyOnClose
      onOk={handleSubmit}
      title={t('mobileEndpoint')}
      visible={visible}
      onCancel={onClose}
    >
      <MobileEndpointCreateForm
        onSubmit={onSubmit}
        ref={formElement}
        mobileEndpoint={mobileEndpoint}
        environments={environments}
        spaces={spaces}
        fetchSpaces={fetchSpaces}
        fetchEnvironments={fetchEnvironments}
      />
    </Modal>
  );
};

export default CreateMobileEndpointModal;
