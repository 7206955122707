import React, { useCallback, useMemo, useState } from 'react';
import { useAnalyticsActiveData } from '../../../../common/use-analytics-data-export';
import DataExportOverviewComponent from '../common/data-export-overview.component';

type DataExportActiveContainerProps = Omit<
  React.ComponentProps<typeof DataExportOverviewComponent>,
  'reports' | 'loaded' | 'fetchData'
>;

const DataExportActiveContainer = (props: DataExportActiveContainerProps) => {
  const [paginationInfo, setPaginationInfo] = useState<{
    page: number;
    pageSize: number;
  }>({ page: 1, pageSize: 10 });

  const {
    match: {
      params: { organisationId },
    },
  } = props;


  const { data, isLoading, isSuccess, isError, isFetching } = useAnalyticsActiveData({
    organisationId,
    page: paginationInfo.page,
    limit: paginationInfo.pageSize,
  });

  const { analyticsData, totalCount } = useMemo(
    () => ({
      analyticsData: isSuccess && data ? data.docs : [],
      totalCount: isSuccess && data ? data.totalDocs : 0,
    }),
    [isSuccess, data],
  );

  const fetchReports = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) => {
      setPaginationInfo((prevState) => ({
        ...prevState,
        ...{  page,  pageSize },
      }));
    },
    [setPaginationInfo],
  );

  return (
    <DataExportOverviewComponent
      {...props}
      reports={analyticsData}
      loaded={isSuccess}
      isLoading={isLoading}
      isFetching={isFetching}
      totalDocs={totalCount}
      isError={isError}
      fetchData={fetchReports}
    />
  );
};

export default DataExportActiveContainer;
