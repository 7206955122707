import React, { useMemo } from 'react';
import { Button, List, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';

interface CrudListItemProps extends React.ComponentProps<typeof List.Item> {
  onEdit: () => void;
  onDelete: () => void;
  canUpdate: boolean;
  canDelete: boolean;
}

const CrudListItem = (props: CrudListItemProps) => {
  const {
    onEdit,
    onDelete,
    actions,
    children,
    canUpdate,
    canDelete,
    ...restProps
  } = props;
  const { t } = useTranslation();
  const actionButtons = useMemo(() => {
    const buttons: any = [];

    if (canDelete) {
      buttons.push(
        <Popconfirm
          title={t('areYouSureYouWantToDelete')}
          onConfirm={onDelete}
          okText={t('yes')}
          cancelText={t('no')}
        >
          <Button size="small" type="link" icon="delete">
            {t('delete')}
          </Button>
        </Popconfirm>,
      );
    }

    if (canUpdate) {
      buttons.push(
        <Button onClick={onEdit} size="small" type="link" icon="edit">
          {t('edit')}
        </Button>,
      );
    }

    return buttons;
  }, [onEdit, onDelete, canDelete, canUpdate, t]);

  return (
    <List.Item actions={[...actionButtons, ...(actions || [])]} {...restProps}>
      {children}
    </List.Item>
  );
};

export default CrudListItem;
