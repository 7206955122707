import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import { SupportedQueueLanguages } from '../types';

const getCustomizableTextsValue = (texts = {}) => {
  if (isEmpty(texts)) {
    return keys(SupportedQueueLanguages).reduce(
      (acc, key) => ({ ...acc, [key]: {} }),
      {},
    );
  }

  return { ...texts };
};

export default getCustomizableTextsValue;
