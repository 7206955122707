// import io from 'socket.io-client';

class SocketService {
  private readonly url: string;

  public constructor(url: string) {
    this.url = url;
  }

  public async subscribeToEvent<T = void>(event: string, callback: (data: T) => void) {
    // TODO: RESTORE BELOW CODE WHEN SOCKET.IO IS FIXED IN NEW INFRA
    // const socket = io(this.url);

    // await new Promise((resolve, reject) => {
    //   const failTimeout = setTimeout(() => {
    //     reject(new Error('Socket connection failed'));
    //   }, 3000);

    //   socket.on('connect', () => {
    //     clearTimeout(failTimeout);
    //     resolve();
    //   });
    // });

    // socket.on(event, (data: T) => {
    //   callback(data);
    // });

    return () => {
      // socket.close();
    };
  }
}

export default SocketService;
