import React, { useCallback } from 'react';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import Browser from '../../../store/types/browser';
import Environment from '../../../store/types/environment';
import Space from '../../../store/types/organisation-space';
import BrowsersListComponent from '../../common/browsers/browsers-list/browsers-list.component';
import Header from '../../common/app-layout/header/header.component';

interface BrowsersProps
  extends Omit<
    React.ComponentProps<typeof BrowsersListComponent>,
    | 'environments'
    | 'browsers'
    | 'spaces'
    | 'loaded'
    | 'canCreate'
    | 'canUpdate'
    | 'canDelete'
    | 'onCreate'
    | 'onUpdate'
    | 'onDelete'
    | 'onConnect'
  > {
  browsers: Browser[];
  spaces?: Space[];
  environments?: Environment[];
  loaded: boolean;
}

const BrowsersComponent = (props: BrowsersProps) => {
  const { loaded, browsers, fetchBrowsers, history } = props;
  const { t } = useTranslation();

  const fetchAllBrowsers = useCallback(
    (params: { silent: boolean }) => {
      fetchBrowsers({ silent: !!params.silent });
    },
    [fetchBrowsers],
  );

  return (
    <>
      <Header title={t('managedBrowsers')} />
      <div className="content-body">
        <BrowsersListComponent
          history={history}
          loaded={loaded}
          fetchBrowsers={fetchAllBrowsers}
          browsers={browsers}
          canCreate={false}
          canUpdate={false}
          canDelete={false}
          onCreate={noop}
          onUpdate={noop}
          onDelete={noop}
          onConnect={noop}
        />
      </div>
    </>
  );
};

export default BrowsersComponent;
