// @ts-ignore
import { getCountries as getCountryCodes } from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';

export const EuropeanUnionCountries = {
  AT: 'Austria',
  BE: 'Belgium',
  BG: 'Bulgaria',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DE: 'Germany',
  DK: 'Denmark',
  EE: 'Estonia',
  ES: 'Spain',
  FI: 'Finland',
  FR: 'France',
  GR: 'Greece',
  HR: 'Croatia',
  HU: 'Hungary',
  IE: 'Ireland',
  IT: 'Italy',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  MT: 'Malta',
  NL: 'Netherlands',
  PL: 'Poland',
  PT: 'Portugal',
  RO: 'Romania',
  SE: 'Sweden',
  SI: 'Slovenia',
  SK: 'Slovakia',
};

export const getSortedCountries = (): { names: string[]; codes: string[] } => {
  const countryMap: { code: string; name: string }[] = getCountryCodes().map(
    (code: string) => {
      return {
        code,
        name: (en as { [key: string]: string })[code] || code,
      };
    },
  );

  const sortedCountryList = countryMap.sort((prev, next) =>
    prev.name.localeCompare(next.name),
  );

  const names: string[] = [];
  const codes: string[] = [];

  sortedCountryList.forEach((country) => {
    codes.push(country.code);
    names.push(country.name);
  });

  return { names, codes };
};

export const getCountryNameByCode = (code: string): string | undefined => {
  return (en as { [key: string]: string })[code];
};
