import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import { RootModel, RootState } from '../../../../../store/models/root.model';
import UniversalDeviceDetails from './universal-device-details.component';
import DeviceTypeEnum from '../../../../../store/types/device-type.enum';
import { getPermissions } from '../../../../../utils/auth/permissions';
import getAppsByOrganisationName from '../../../../../utils/get-apps-by-organisation-name';

type DeviceDetailsContainerProps = Omit<
  React.ComponentProps<typeof UniversalDeviceDetails>,
  'fetchDevice' | 'device' | 'loaded' | 'lastUpdated' | 'isSysAdmin'
>;

const UniversalDeviceDetailsContainer = (props: DeviceDetailsContainerProps) => {
  const {
    match: {
      params: { deviceUuid, organisationId },
    },
  } = props;

  const { device, loaded, lastUpdated, permissions, isSysAdmin } = useStore<RootState>(
    (state: RootState) => ({
      device: state.universalDeviceDetails.data[deviceUuid] || null,
      loaded:
        state.universalDeviceDetails.loaded(deviceUuid) && state.organisationApps.loaded,
      lastUpdated: state.universalDeviceDetails.lastUpdated[deviceUuid],
      apps: getAppsByOrganisationName(
        state.organisations.values,
        state.organisationApps.data || {},
        DeviceTypeEnum.BALENA,
      ),
      isSysAdmin: state.auth.isSysAdmin,
      permissions: getPermissions(state.auth.user, organisationId),
    }),
    [deviceUuid, organisationId],
  );

  const { fetchDevice, rebootDevice, restartApp, deleteDevice } = useActions<RootModel>(
    (actions) => ({
      fetchDevice: actions.universalDeviceDetails.fetch,
      rebootDevice: actions.universalDeviceDetails.reboot,
      restartApp: actions.universalDeviceDetails.restart,
      deleteDevice: actions.universalDeviceDetails.delete,
    }),
  );

  return (
    <UniversalDeviceDetails
      {...props}
      lastUpdated={lastUpdated}
      device={device}
      fetchDevice={fetchDevice}
      deleteDevice={deleteDevice}
      rebootDevice={rebootDevice}
      restartApp={restartApp}
      loaded={loaded}
      isSysAdmin={isSysAdmin}
      canManageDeviceSettings={permissions.devices.manageDeviceSettings}
    />
  );
};

export default UniversalDeviceDetailsContainer;
