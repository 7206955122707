import React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Col } from 'antd';
import LanguagePicker from './language-picker';
import { IsoLanguageIds } from '@ombori/grid-products';
import { SelectProps } from 'antd/lib/select';
import MultiLanguageLabelComponent from '../schema-form/widgets/multi-language/multi-language-label.component';
import { Validator } from './validator';
import TextArea, { TextAreaProps } from './textarea';
import InputWrap from './input-wrap';

interface LocaleFieldProps extends SelectProps {
  className?: string;
  name?: string;
  label?: React.ReactNode;
  supportedLanguages?: IsoLanguageIds[];
  isReadOnly?: boolean;
  validators?: Validator[];
}

interface TextAreaFieldProps extends Omit<TextAreaProps, 'name' | 'isRequired'> {
  name?: string;
}

interface LocalizedTextAreaProps {
  name?: string;
  className?: string;
  isRequired?: boolean;
  locale?: LocaleFieldProps;
  textarea?: TextAreaFieldProps;
  dataTestId?: string;
}

const LocalizedTextArea = (props: LocalizedTextAreaProps) => {
  const { name, className, isRequired = false, locale, textarea, dataTestId } = props;

  const {
    className: localeClassName = undefined,
    name: localeName = 'isoLanguageId',
    label: localeLabel = undefined,
    supportedLanguages = [],
    isReadOnly: isLocaleReadOnly = false,
    validators: localeValidators = [],
    ...restLocalProps
  } = locale || {};

  const {
    className: textAreaClassName = undefined,
    name: textAreaName = 'label',
    label: textAreaLabel = undefined,
    validators = [],
  } = textarea || {};

  const { t } = useTranslation();

  const localeFieldLabel = localeLabel === undefined ? t('finalFormField.locale') : localeLabel;
  const textAreaFieldLabel = textAreaLabel === undefined ? t('finalFormField.text') : textAreaLabel;

  return (
    <LocalizedTextAreaContainer span={24} className={className}>
      {isLocaleReadOnly && (
        <Field name={`${name}.${localeName}`}>
        {({ input, meta }) => {
            const isError = !!meta.error && meta.touched;

            return (
              <InputWrap
                label={localeFieldLabel}
                isRequired={isRequired}
                error={isError ? meta.error : undefined}
              >
                <LanguageLabel language={input.value} />
              </InputWrap>
            );
          }}
        </Field>
      )}
      {!isLocaleReadOnly && (
        <LanguagePickerStyled
          {...restLocalProps}
          className={localeClassName}
          name={`${name}.${localeName}`}
          label={localeFieldLabel}
          supportedLanguages={supportedLanguages}
          isRequired={isRequired}
          validators={localeValidators}
        />
      )}
      <TextArea
        name={`${name}.${textAreaName}`}
        className={textAreaClassName}
        label={textAreaFieldLabel}
        isRequired={isRequired}
        validators={validators}
        data-testid={dataTestId}
      />
    </LocalizedTextAreaContainer>
  );
};

const LocalizedTextAreaContainer = styled(Col)`
  display: flex;
  gap: 10px;
  margin-bottom: 5px;

  > :last-child {
    flex-grow: 1;
  }
`;

const LanguagePickerStyled = styled(LanguagePicker)`
  width: 150px !important;
`;

const LanguageLabel = styled(MultiLanguageLabelComponent)`
  width: 105px;
`;

export default LocalizedTextArea;
