import React, { useCallback, useEffect, useRef } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import SchemaForm, {
  SchemaFormRef,
} from '../../../../../common/schema-form/schema-form.component';
import useSchemaForm from '../../../../../common/use-schema-form/use-schema-form';
import Environment from '../../../../../../store/types/environment';

export interface DeviceFormValues {
  appBuild: string;
  deviceName: string;
  deviceSerial: string;
  type: string;
  env: string;
}

interface CreateDeviceModalProps {
  visible: boolean;
  onClose: () => void;
  onSubmit: (values: DeviceFormValues) => Promise<void>;
  environments: Environment[];
}

const CreateDeviceModal = (props: CreateDeviceModalProps) => {
  const { t } = useTranslation();
  const { visible, onClose, onSubmit, environments } = props;
  const formElement = useRef<SchemaFormRef>(null);

  const handleSubmit = useCallback(() => {
    if (formElement.current) {
      formElement.current.submit();
    }
  }, [formElement]);

  const [
    formData,
    formLoading,
    ,
    formInit,
    handleFormChange,
    handleFormSubmit,
  ] = useSchemaForm<any>(onSubmit, t('deviceSaved'), t('deviceSaveError'));

  const formSchema = {
    type: 'object',
    properties: {
      deviceName: {
        type: 'string',
        title: t('name'),
        description: t('onlyNumbersLowerCaseAndDashAllowed'),
        minLength: 1,
        pattern: '^[a-z0-9-]*$',
      },
      deviceSerial: {
        type: 'string',
        title: t('serialNumber'),
        minLength: 1,
      },
      env: {
        type: 'string',
        title: t('environment'),
        enum: environments.map((env) => env.environmentName),
        enumNames: environments.map((env) => env.displayName),
        default: 'prod',
      },
      type: {
        type: 'string',
        title: t('type'),
        enum: ['iotedge', 'tizen', 'iot'],
        enumNames: ['Grid-OS', 'Tizen', 'Windows IoT'],
        default: 'iotedge',
      },
    },
    required: ['deviceName', 'deviceSerial', 'env'],
  };

  useEffect(() => {
    formInit({
      deviceName: '',
    });
  }, [formInit]);

  return (
    <Modal
      destroyOnClose
      onOk={handleSubmit}
      confirmLoading={formLoading}
      title={t('computeDevice')}
      visible={visible}
      onCancel={onClose}
    >
      <SchemaForm
        schema={formSchema}
        onSubmit={handleFormSubmit}
        onChange={handleFormChange}
        ref={formElement}
        data={formData}
        liveValidate={false}
      />
    </Modal>
  );
};

export default CreateDeviceModal;
