enum OrganisationSpaceTypeEnum {
  LOCATION = 'location',
  SECTION = 'section',
  CUSTOM = 'custom',
  COUNTRY = 'country',
  REGION = 'region',
  CITY = 'city',
  FLOOR = 'floor',
}

export default OrganisationSpaceTypeEnum;
