import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Form, List, Checkbox } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { get } from 'lodash';
import { WrappedFormUtils } from 'antd/es/form/Form';
import { Filters } from '../../../../../store/types/enterprise-agreement';
import { convertEmailToName } from '../../../../../utils/enterprise-agreement';
import TotalsExtra from './totals-extra';

interface StatusItemProps extends FormComponentProps {
  filters?: Filters;
  createdById: string;
  form: WrappedFormUtils<any>;
}

const OwnerItem = (props: StatusItemProps) => {
  const {
    filters,
    createdById,
    form: { getFieldDecorator },
  } = props;
  const data = get(filters, `createdBy.${createdById}`, {});
  const { email, total } = data;

  const name = useMemo(() => convertEmailToName(email), [email]);

  return (
    <List.Item extra={<TotalsExtra key={createdById} total={total} />}>
      <FormItem>
        {getFieldDecorator(`createdBy[${createdById}]`, {
          valuePropName: 'checked',
          initialValue: true,
        })(<Checkbox>{name}</Checkbox>)}
      </FormItem>
    </List.Item>
  );
};

const FormItem = styled(Form.Item)`
  flex: 1 1;
  margin-bottom: 0px;

  .ant-form-item-control {
    line-height: normal;
  }

  .ant-checkbox-wrapper {
    width: 100%;
  }
` as any;

export default OwnerItem;
