import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import DeviceCreateContainer from './device-create/device-create.container';

const Devices = (props: RouteComponentProps) => {
  const { match } = props;

  return (
    <Switch>
      <Route path={`${match.path}/add`} component={DeviceCreateContainer} />
      <Route
        path={`${match.path}/:deviceSerial`}
        render={({ match: detailsMatch }) => (
          <Redirect
            to={`${match.path}/add?deviceSerial=${detailsMatch.params.deviceSerial}`}
          />
        )}
      />
    </Switch>
  );
};

export default Devices;
