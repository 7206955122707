import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Button, Dropdown, Icon, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import SortCriteriaItem from './sort-criteria-item.component';
import SortOrderItem from './sort-order-item.component';
import { SortCriteria, SortOrderType, SortOrder } from '../../../store/types/sort';
import transientOptions from '../../../utils/transient-emotion-styled-options';

export interface SortProps {
  sortCriteria?: SortCriteria[];
  defaultCriteria?: string;
  defaultOrder?: SortOrderType;
  onChange?: (criteria: SortCriteria, order: SortOrder) => void;
}

const defaultSortOrderOption: SortOrder[] = [
  {
    id: 'asc',
    label: 'A - Z',
  },
  {
    id: 'desc',
    label: 'Z - A',
  },
];

const Sort = (props: SortProps) => {
  const {
    defaultCriteria,
    defaultOrder = 'asc',
    sortCriteria = [],
    onChange = () => {},
  } = props;

  const [activeSortCriteria, setActiveSortCriteria] = useState<SortCriteria>();
  const [activeSortOrder, setActiveSortOrder] = useState<SortOrder>();
  const [showDropDown, setShowDropDown] = useState<boolean>(false);

  const { t } = useTranslation();

  const sortOrderOption = useMemo<SortOrder[]>(() => {
    const { labelAsc, labelDesc } =
      activeSortCriteria && activeSortCriteria.type === 'string'
        ? { labelAsc: 'A - Z', labelDesc: 'Z - A' }
        : { labelAsc: t('sortDevice.latest'), labelDesc: t('sortDevice.oldest') };

    return [
      {
        id: 'asc',
        label: labelAsc,
      },
      {
        id: 'desc',
        label: labelDesc,
      },
    ];
  }, [activeSortCriteria, t]);

  useEffect(() => {
    const criteria = defaultCriteria
      ? sortCriteria.find((item) => item.id === defaultCriteria)
      : undefined;

    setActiveSortCriteria(criteria);
  }, [defaultCriteria, sortCriteria]);

  useEffect(() => {
    const order = defaultOrder
      ? defaultSortOrderOption.find((item) => item.id === defaultOrder)
      : undefined;

    setActiveSortOrder(order);
  }, [defaultOrder]);

  const handleClickButton = useCallback(() => {
    setShowDropDown((val) => !val);
  }, []);

  const handleHoverOff = useCallback(() => {
    setShowDropDown(false);
  }, []);

  const handleSortCriteriaChange = useCallback(
    (value: SortCriteria) => {
      setActiveSortCriteria(value);
      onChange(value, activeSortOrder as SortOrder);
    },
    [activeSortOrder, onChange],
  );

  const handleSortOrderChange = useCallback(
    (value: SortOrder) => {
      setActiveSortOrder(value);
      onChange(activeSortCriteria as SortCriteria, value);
    },
    [activeSortCriteria, onChange],
  );

  const sortMenu = useMemo(
    () => (
      <CustomMenu defaultSelectedKeys={['1']}>
        <Menu.ItemGroup>
          {sortCriteria.map((option) => {
            const { id } = option;

            return (
              <Menu.Item key={`${id}-menu-item`}>
                <SortCriteriaItem
                  key={`${id}-sort-criteria`}
                  item={option}
                  onClick={handleSortCriteriaChange}
                  isActive={activeSortCriteria && activeSortCriteria.id === id}
                />
              </Menu.Item>
            );
          })}
        </Menu.ItemGroup>
        <Menu.Divider />
        <Menu.ItemGroup>
          {sortOrderOption.map((option) => {
            const { id } = option;

            return (
              <Menu.Item key={`${id}-menu-item`}>
                <SortOrderItem
                  key={`${id}-sort-order`}
                  item={option}
                  onClick={handleSortOrderChange}
                  isActive={activeSortOrder && activeSortOrder.id === id}
                />
              </Menu.Item>
            );
          })}
        </Menu.ItemGroup>
      </CustomMenu>
    ),
    [
      activeSortCriteria,
      activeSortOrder,
      handleSortCriteriaChange,
      handleSortOrderChange,
      sortCriteria,
      sortOrderOption,
    ],
  );

  return (
    <Container onMouseLeave={handleHoverOff}>
      <Dropdown overlay={sortMenu} visible={showDropDown}>
        <DropdownButton type="link" onClick={handleClickButton}>
          <SortLabel>{t('sort')}</SortLabel>
          <IconDown type="down" $isActive={showDropDown} />
        </DropdownButton>
      </Dropdown>
    </Container>
  );
};

const Container = styled.div`
  height: inherit;
`;

const DropdownButton = styled(Button)`
  height: inherit;
  display: flex;
  align-items: center;
  background: unset;
  border-color: transparent !important;
  box-shadow: unset;
  padding: 0 10px;

  :hover {
    border-color: transparent !important;
    background-color: #f4f5f7;
  }
` as any;

const CustomMenu = styled(Menu)`
  padding: 4px;
  overflow: hidden;
`;

const SortLabel = styled.span`
  font-size: 14px;
  margin: auto 5px;
  text-transform: capitalize;

  @media (max-width: 768px) {
    width: min-content;
    max-width: 45px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const IconDown = styled(Icon, transientOptions)<{ $isActive?: boolean }>`
  display: flex !important;
  font-size: 9px !important;
  margin-left: 0px !important;

  ${({ $isActive }) =>
    $isActive
      ? `transform: scale(1.2,1) rotate(180deg) !important;
         transition: transform 0.25s linear !important;`
      : `transform: scale(1.2,1) rotate(0deg) !important;
         transition: transform 0.25s linear !important;`}
`;

export default Sort;
