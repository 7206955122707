import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import { RootModel, RootState } from '../../../../store/models/root.model';
import EnterpriseAgreementListFilter from './enterprise-agreement-list-filter.component';

type EnterpriseAgreementListFilterContainerProps = Pick<
  React.ComponentProps<typeof EnterpriseAgreementListFilter>,
  'formName' | 'isFiltering' | 'onSubmit'
>;

const EnterpriseAgreementListFilterContainer = (
  props: EnterpriseAgreementListFilterContainerProps,
) => {
  const { loading, error, filters } = useStore<RootState>((state) => {
    return {
      loading: state.enterpriseAgreements.filtersLoading,
      error: state.enterpriseAgreements.filtersError,
      filters: state.enterpriseAgreements.filters,
    };
  }, []);

  const { fetchFilters } = useActions<RootModel>((actions) => ({
    fetchFilters: actions.enterpriseAgreements.fetchFilters,
  }));

  return (
    <EnterpriseAgreementListFilter
      {...props}
      fetchFilters={fetchFilters}
      filters={filters}
      loading={loading}
      error={error}
    />
  );
};

export default EnterpriseAgreementListFilterContainer;
