import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { ConfigProvider, List } from 'antd';
import { GridKnowledgeSource } from '@ombori/grid-llm-react';
import { useStoreAiKnowledgeBaseFileList } from '../../../../../store/hooks/store-ai-knowledge-base';
import Overlay from '../../../../common/overlay/overlay-v2.component';
import KnowledgeBaseFileDeleteButton from './knowledge-base-file-delete-button.component';
import KnowledgeBaseFileIcon from './knowledge-base-file-icon.component';
import { LinkText } from '../common';

export interface KnowledgeBaseFileLibraryProps {
  tenantId: string;
  assistantId?: string;
  onFileSelect?: (file: GridKnowledgeSource) => void;
}

const KnowledgeBaseFileLibrary = (props: KnowledgeBaseFileLibraryProps) => {
  const { tenantId, assistantId, onFileSelect = () => {} } = props;

  const { t } = useTranslation();

  const {
    data,
    isLoading: isListLoading,
    error: listError,
  } = useStoreAiKnowledgeBaseFileList({
    tenantId,
    page: 1,
    limit: 1000,
  });

  const { resources: files = [], totalCount = 0 } = data || {};

  const hasContent = totalCount > 0 && !isListLoading;

  return (
    <OverlayStyled
      spinnerOverlay={{
        isLoading: isListLoading,
        spinnerSize: 15,
        processingTip: t('knowledgeBase.loadingFiles'),
      }}
      errorOverlay={{
        error: listError,
      }}
    >
      {!isListLoading && hasContent && (
        <KnowledgeBaseFileListContainer>
          <ConfigProvider renderEmpty={() => null}>
            <List
              dataSource={files}
              size="small"
              renderItem={(file) => {
                return (
                  <ListItem
                    key={file.id}
                    actions={[
                      <KnowledgeBaseFileDeleteButton
                        tenantId={tenantId}
                        assistantId={assistantId}
                        fileId={file.id}
                      />,
                    ]}
                  >
                    <ListItemMetadata
                      avatar={<KnowledgeBaseFileIcon mimeType={file.type} />}
                      title={
                        <LinkText type="link" onClick={() => onFileSelect(file)}>
                          {file.name}
                        </LinkText>
                      }
                      description={file.type}
                    />
                  </ListItem>
                );
              }}
            />
          </ConfigProvider>
        </KnowledgeBaseFileListContainer>
      )}
    </OverlayStyled>
  );
};

const KnowledgeBaseFileListContainer = styled.div`
  margin-top: 20px;
  max-height: 500px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 20px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 20px;
    border: 7px solid transparent;
    background-clip: padding-box;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #7d7d7d;
    border: 7px solid transparent;
    background-clip: padding-box;
  }
`;

const OverlayStyled = styled(Overlay)`
  .anticon-loading {
    margin-bottom: 0;
  }

  .overlay-mask,
  .overlay-spinner {
    position: relative;
  }

  .overlay-spinner {
    margin-top: 20px;
    flex-direction: row;
  }
`;

const ListItem = styled(List.Item)`
  padding-right: 5px !important;
  padding-left: 15px !important;
`;

const ListItemMetadata = styled(List.Item.Meta)`
  .ant-list-item-meta-avatar {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 40px;
    margin: auto;
  }

  .ant-list-item-meta-content {
    margin: auto 0 auto 10px;
  }
`;

export default KnowledgeBaseFileLibrary;
