import React, { useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { ButtonType } from '../../../../../types';
import useGoBack from '../../../../common/use-go-back';
import AlertRuleForm, {
  AlertRuleFormApiValidationErrors,
  AlertRuleFormValues,
} from './alert-rule-form.component';
import { SeverityEnum } from '../../../../../store/types/alerts';
import { useCreateAlertRule } from '../../../../../store/hooks/alert-rules';
import Message from '../../../../common/message/message.component';
import { extractApiValidationErrors } from '../../../../common/react-final-form';
import { ApiError } from '../../../../../services/api/api-error';

interface AlertRuleCreateFormProps
  extends RouteComponentProps<{ organisationId: string }> {}

const AlertRuleCreateForm = (props: AlertRuleCreateFormProps) => {
  const {
    match: {
      params: { organisationId },
    },
    history,
  } = props;

  const { t } = useTranslation();

  const goBack = useGoBack();

  const handleGoBack = useCallback(() => {
    goBack();
  }, [goBack]);

  const {
    mutateAsync: createAlertRule,
    isLoading,
    isSuccess,
    isError,
  } = useCreateAlertRule(organisationId);

  const onFormSubmit = useCallback(
    async (values: AlertRuleFormValues) => {
      try {
        const {
          displayName,
          description,
          enabled: isEnabled,
          severity,
          scope,
          conditions,
          actions: actionsList,
        } = values;

        const enabled = Boolean(isEnabled);

        const actions = actionsList.map((action) => action.actionId);

        const newAlertRule = await createAlertRule({
          tenantId: organisationId,
          displayName,
          description,
          enabled,
          severity,
          scope,
          conditions,
          actions,
        });

        message.success(<Message content={t('alertRules.createSuccess')} />);

        history.replace(
          `/organisations/${organisationId}/alerts/alert-rules/update/${newAlertRule.id}`,
        );

        return {};
      } catch (error) {
        message.error(<Message content={t('alertRules.createFailed')} />);

        const errors = extractApiValidationErrors<AlertRuleFormApiValidationErrors>(
          error as ApiError,
        );

        return errors;
      }
    },
    [createAlertRule, history, organisationId, t],
  );

  const initialValues = useMemo<AlertRuleFormValues>(() => {
    return {
      displayName: '',
      description: '',
      enabled: 1,
      severity: SeverityEnum.CRITICAL,
      scope: [],
      conditions: [],
      actions: [],
    };
  }, []);

  return (
    <>
      <AlertRuleFormContainer>
        <BackToListButton type="default" icon="arrow-left" onClick={handleGoBack}>
          {t('back')}
        </BackToListButton>

        <AlertRuleForm
          title={t('alertRules.newAlertRuleTitle')}
          subTitle={t('alertRules.newAlertRuleSubTitle')}
          tenantId={organisationId}
          initialValues={initialValues}
          onFormSubmit={onFormSubmit}
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={isError}
        />
      </AlertRuleFormContainer>
    </>
  );
};

export default AlertRuleCreateForm;

const BackToListButton = styled(Button)`
  height: 36px;
  font-size: 14px;
  color: #676973;
  border: none;
  margin-right: 5px;
  background-color: transparent;

  :hover,
  :active,
  :focus {
    background-color: #f2f4f8;
  }
` as ButtonType;

const AlertRuleFormContainer = styled.div`
  position: relative;
  flex: 1;
  padding: 30px 80px 40px;

  @media screen and (max-width: 991px) {
    padding: 0;
  }
`;
