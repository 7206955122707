import React, { useCallback } from 'react';
import { useActions, useStore } from 'easy-peasy';
import styled from '@emotion/styled';
import { RootModel, RootState } from '../../../../store/models/root.model';
import { KEY_GRID_ANALYTICS_OVERVIEW } from '../../../../store/models/analytics/analytics.model';
import Report from '../../../common/report/report.component';

type AnalyticsOverviewProps = Pick<
  React.ComponentProps<typeof Report>,
  'match' | 'history' | 'location'
>;

const AnalyticsOverviewContainer = (props: AnalyticsOverviewProps) => {
  const { embed, error } = useStore<RootState>((state) => {
    const key = KEY_GRID_ANALYTICS_OVERVIEW;
    const reportName = 'grid-sysadmin-overview';

    return {
      embed:
        (state.analytics.embeds[key] && state.analytics.embeds[key][reportName]) || null,
      error:
        state.analytics.embedError[key] && state.analytics.embedError[key][reportName],
    };
  }, []);

  const { fetchGridAnalyticsOverviewReport } = useActions<RootModel>((actions) => ({
    fetchGridAnalyticsOverviewReport: actions.analytics.fetchGridAnalyticsOverviewReport,
  }));

  const fetchEmbed = useCallback(() => {
    fetchGridAnalyticsOverviewReport({ silent: true });
  }, [fetchGridAnalyticsOverviewReport]);

  return (
    <Container className="content-body">
      <Report
        {...props}
        fetchEmbed={fetchEmbed}
        embed={embed}
        error={error}
        loaded={!!embed}
      />
    </Container>
  );
};

const Container = styled.div`
  background-color: #fff;
`;

export default AnalyticsOverviewContainer;
