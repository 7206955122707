import { action, Action, select, Select, thunk, Thunk } from 'easy-peasy';
import Build from '../../types/build';
import Injections from '../../injections.interface';
import { ApiError } from '../../../services/api/api-error';
import { isBuildNew } from '../../../utils/builds/build-status';

export const routes = {
  getBuilds: (appId: string) => ['', 'api', 'v2', 'apps', appId, 'builds'].join('/'),
  getBuild: (buildId: string) => ['', 'api', 'builds', buildId,].join('/'),
  getLatestDeployedBuild: (appId: string, env: string) =>
    ['', 'api', 'apps', appId, 'builds', env, 'latest'].join('/'),
  createBuild: () => ['', 'api', 'builds'].join('/'),
  deleteBuild: (buildId: string) => ['', 'api', 'builds', buildId].join('/'),
  deployBuild: (buildId: string) => ['', 'api', 'builds', buildId, 'deploy'].join('/'),
};

export interface CreateAppBuildParams {
  appId: string;
  balena: boolean;
  webpack: boolean;
  windows: boolean;
  linux: boolean;
  wpa: boolean;
  mobilewpa: boolean;
  autoDeployEnvironment?: string;
}

export interface AppBuildsModel {
  data: {
    [appId: string]: {
      [buildId: string]: Build;
    };
  };
  loading: {
    [appId: string]: boolean;
  };
  error: {
    [appId: string]: ApiError | null;
  };
  values: Select<AppBuildsModel, (appId: string) => Build[]>;
  ids: Select<AppBuildsModel, (appId: string) => string[]>;
  loaded: Select<AppBuildsModel, (appId: string) => boolean>;
  activeBuilds: Select<AppBuildsModel, (appId: string) => Build[]>;
  setLoading: Action<AppBuildsModel, { appId: string; loading: boolean }>;
  setError: Action<AppBuildsModel, { appId: string; error: ApiError | null }>;
  setData: Action<AppBuildsModel, { appId: string; data: Build[] }>;
  setSingle: Action<AppBuildsModel, { appId: string; data: Build }>;
  unsetSingle: Action<AppBuildsModel, { appId: string; buildId: string }>;
  fetch: Thunk<AppBuildsModel, { appId: string }, Injections>;
  create: Thunk<AppBuildsModel, CreateAppBuildParams, Injections>;
  delete: Thunk<AppBuildsModel, { appId: string; buildId: string }, Injections>;
  deploy: Thunk<AppBuildsModel, { buildId: string; environmentName: string }, Injections>;
  initUpdateListener: Thunk<AppBuildsModel>;
}

// TODO: temp logic to change names
const mapBuild = (build: Build) => {
  const pipelines = build.pipelines.map((pipeline) => ({
    ...pipeline,
    type: pipeline.type === 'balena' ? 'app' : pipeline.type,
  }));
  const deploys = build.deploys.map((deploy) => ({
    ...deploy,
    type: deploy.type === 'balena' ? 'app' : deploy.type,
  }));
  return {
    ...build,
    pipelines,
    deploys,
  };
};

const appBuildsModel: AppBuildsModel = {
  data: {},
  loading: {},
  error: {},
  values: select((state) => (appId: string) =>
    Object.values(state.data[appId] || {}).sort(
      (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    ),
  ),
  ids: select((state) => (appId: string) => Object.keys(state.data[appId] || {})),
  // TODO: this logic assumes build + deploy flow - possibly adjust in the future
  activeBuilds: select((state) => (appId: string) =>
    state.values(appId).filter((build) => isBuildNew(build)),
  ),
  loaded: select((state) => (appId: string) =>
    !!state.data[appId] && !state.loading[appId],
  ),
  setSingle: action((state, { appId, data }) => {
    if (!state.data[appId]) {
      state.data[appId] = {};
    }
    state.data[appId][data.id] = mapBuild(data);
  }),
  unsetSingle: action((state, { appId, buildId }) => {
    if (state.data[appId]) {
      delete state.data[appId][buildId];
    }
  }),
  setData: action((state, { appId, data }) => {
    // TODO: revisit this logic
    if (!state.data[appId]) {
      state.data[appId] = {};
    }
    data.forEach((item) => {
      state.data[appId][item.id] = mapBuild(item);
    });
  }),
  setLoading: action((state, { appId, loading }) => {
    state.loading[appId] = loading;
  }),
  setError: action((state, { appId, error }) => {
    state.error[appId] = error;
  }),
  fetch: thunk(async (actions, { appId }, { injections }) => {
    actions.setError({ appId, error: null });
    actions.setLoading({ appId, loading: true });
    try {
      const data = await injections.apiService.get<{ builds: Build[] }>(
        routes.getBuilds(appId),
      );
      actions.setData({ appId, data: data.builds });
    } catch (error) {
      actions.setError({ appId, error });
    } finally {
      actions.setLoading({ appId, loading: false });
    }
  }),
  create: thunk(async (actions, payload, { injections }) => {
    const data = await injections.apiService.post<Build>(routes.createBuild(), payload);
    actions.setSingle({ appId: payload.appId, data });
  }),
  delete: thunk(async (actions, { appId, buildId }, { injections }) => {
    actions.unsetSingle({ appId, buildId });
    await injections.apiService.delete<void>(routes.deleteBuild(buildId));
  }),
  deploy: thunk(async (actions, { buildId, environmentName }, { injections }) => {
    await injections.apiService.post<void>(routes.deployBuild(buildId), {
      environmentName,
    });
  }),
  initUpdateListener: thunk((actions) => {
    // TODO: RESTORE BELOW CODE WHEN SOCKET.IO IS FIXED IN NEW INFRA
    // TODO: subscribe and unsubscribe to channel on relevant screen
    // const socket = io(`${getApiUrl()}/console`);
    // socket.on('update', (data: Build) => {
    //   if (data.id && data.appId) {
    //     actions.setSingle({ appId: data.appId, data });
    //   }
    // });
  }),
};

export default appBuildsModel;
