import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import BrowserCreate from './browser-create.component';
import { RootModel, RootState } from '../../../store/models/root.model';
import Browser from '../../../store/types/browser';

type BrowserAddContainerProps = Omit<
  React.ComponentProps<typeof BrowserCreate>,
  'environments' | 'apps'
>;

const BrowserCreateContainer = (props: BrowserAddContainerProps) => {
  const { apps, environments, organisations, spaces } = useStore<RootState>((state) => {
    return {
      environments: state.environments.data,
      apps: state.organisationApps.data,
      organisations: state.organisations.values,
      spaces: state.organisationSpaces.data,
    };
  }, []);

  const { fetchApps, createBrowser, fetchEnvironments, fetchSpaces } = useActions<
    RootModel
  >((actions) => ({
    fetchApps: actions.organisationApps.fetch,
    createBrowser: (data: Partial<Browser>) =>
      actions.appBrowsers.create({ data, key: data.appId || '' }),
    fetchEnvironments: actions.environments.fetch,
    fetchSpaces: actions.organisationSpaces.fetch,
  }));

  return (
    <BrowserCreate
      {...props}
      organizations={organisations}
      environments={environments}
      apps={apps}
      spaces={spaces}
      fetchApps={fetchApps}
      fetchSpaces={fetchSpaces}
      createBrowser={createBrowser}
      fetchEnvironments={fetchEnvironments}
    />
  );
};

export default BrowserCreateContainer;
