import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FormComponentProps } from 'antd/es/form/Form';
import { Button, Form, Input, message } from 'antd';
import styled from '@emotion/styled';
import Confetti from 'react-confetti';

interface AddVoucherProps extends FormComponentProps {
  organizationId: string;
  addVoucher: (params: {
    organizationId: string;
    voucherCode: string;
  }) => Promise<{ valid: boolean; voucherCode?: string }>;
  fetchAccountBalance?: (params: {
    organizationId: string;
    silent?: boolean;
  }) => Promise<void>;
}

const AddVoucher = React.memo((props: AddVoucherProps) => {
  const {
    organizationId,
    addVoucher,
    fetchAccountBalance,
    form: {
      getFieldDecorator,
      validateFields,
      getFieldsError,
      getFieldValue,
      resetFields,
    },
  } = props;
  const { t } = useTranslation();

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isConfettiActive, setIsConfettiActive] = useState(false);

  const validateVoucherCode = useCallback(async (rule, value = '') => {
    if (value.length < 1) {
      return Promise.resolve();
    }

    if (value.length < 3 || value.length > 50) {
      return Promise.reject(new Error('Invalid voucher'));
    }
  }, []);

  const allowAddVoucher = useCallback(() => {
    const fieldsError = getFieldsError();

    return Object.keys(fieldsError).some((field) => {
      return !Array.isArray(fieldsError[field]) && getFieldValue(field);
    });
  }, [getFieldValue, getFieldsError]);

  const handleAddVoucher = useCallback(async () => {
    if (!allowAddVoucher()) {
      return;
    }

    setIsProcessing(true);
    validateFields(async (err: any, values: { voucherCode: string }) => {
      try {
        if (!err) {
          const { voucherCode } = values;
          const data = await addVoucher({
            organizationId,
            voucherCode: voucherCode.trim(),
          });
          const { valid, voucherCode: duplicateVoucherCode = '' } = data;

          if (valid) {
            if (fetchAccountBalance) {
              await fetchAccountBalance({ organizationId, silent: true });
            }

            message.success(t('voucher.successfullyAddedVoucher'));
            resetFields();
            setIsConfettiActive(true);
          } else {
            const msg = duplicateVoucherCode
              ? t('voucher.duplicateVoucher')
              : t('voucher.invalidVoucher');
            message.error(msg);
          }

          setIsProcessing(false);
        }
      } catch (error) {
        message.error(t('voucher.failedToAddVoucher'));
        setIsProcessing(false);
      }
    });
  }, [
    addVoucher,
    allowAddVoucher,
    fetchAccountBalance,
    organizationId,
    resetFields,
    t,
    validateFields,
  ]);

  return (
    <>
      {isConfettiActive && (
        <ConfettiStyled
          recycle={false}
          onConfettiComplete={() => {
            setIsConfettiActive(false);
          }}
        />
      )}
      <Voucher>
        <Form.Item>
          {getFieldDecorator('voucherCode', {
            rules: [
              {
                validator: validateVoucherCode,
              },
            ],
          })(
            <Input
              placeholder={t('voucher.label')}
              size="large"
              onPressEnter={handleAddVoucher}
            />,
          )}
        </Form.Item>
        <Form.Item>
          <Button
            onClick={handleAddVoucher}
            size="large"
            icon="plus"
            loading={isProcessing}
            disabled={!allowAddVoucher()}
          >
            {t('add')}
          </Button>
        </Form.Item>
      </Voucher>
    </>
  );
});

const ConfettiStyled = styled(Confetti)`
  position: fixed !important;
`;

const Voucher = styled.div`
  display: contents;

  .ant-form-item {
    margin-bottom: 0;
    margin-right: 16px;
  }

  .ant-form-item:first-of-type {
    max-width: 300px;
    width: 100%;
  }

  .ant-form-item:last-child {
    margin-right: 0px;
  }
`;

export default Form.create<AddVoucherProps>()(AddVoucher);
