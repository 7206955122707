import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Alert, Icon as AntdIcon, Tooltip, Typography } from 'antd';
import Container from './container.component';
import { HelpCircle, Info } from 'react-feather';
import trim from 'lodash/trim';

const { Title: AntdTitle } = Typography;

interface InputProps {
  className?: string;
  type?: string | number;
  isBordered?: boolean;
  isReadonly?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  isTrimmed?: boolean;
  rawErrors?: string[];
  title?: string;
  defaultValue?: string | number;
  value?: string | number;
  description?: string;
  helpText?: string;
  placeholder?: string;
  onChange?: (value?: string) => void;
  onBlur?: (value?: string) => void;
  onClick?: () => void;
  beforeExtra?: React.ReactNode;
}

const Input = (props: InputProps) => {
  const {
    className,
    type,
    isBordered = true,
    isReadonly,
    isRequired,
    isDisabled,
    isTrimmed = true,
    rawErrors = [],
    title,
    defaultValue,
    value,
    description,
    helpText,
    placeholder,
    onChange = () => {},
    onBlur = () => {},
    onClick = () => {},
    beforeExtra,
  } = props;

  const refInput = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  const errors = useMemo(() => {
    return rawErrors
      .map((err) => trim(err, '.'))
      .join(', ')
      .toLowerCase();
  }, [rawErrors]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (refInput.current) {
        const value = isTrimmed ? e.target.value.trim() : e.target.value;

        refInput.current.value = value;

        onBlur(value);
      }
    },
    [isTrimmed, onBlur],
  );

  return (
    <InputContainer className={className} $isBordered={isBordered} $isDisabled={isDisabled} onClick={onClick}>
      <InputWrap isBottomPadded={!description} isBordered={isBordered}>
        <TitleWrap>
          {title && <Title>{title}{isRequired ? ' *' : ''}</Title>}
          {helpText && (
            <div>
              <Tooltip
                placement="left"
                title={<div dangerouslySetInnerHTML={{ __html: helpText }} />}
              >
                <Icon size={18} component={() => <HelpCircle />} />
              </Tooltip>
            </div>
          )}
        </TitleWrap>
        {errors && <ErrorAlert message={errors} type="error" showIcon />}
        <FieldWrap>
          {beforeExtra}
          <FieldInput
            ref={refInput}
            type={type as string}
            defaultValue={defaultValue as string}
            {...value ? { value } : {}}
            readOnly={isReadonly}
            disabled={isDisabled}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={placeholder || t('input.placeholder')}
            required={isRequired}
          />
        </FieldWrap>
      </InputWrap>
      {description && (
        <DescriptionWrap isBordered={isBordered}>
          <Icon size={18} component={() => <Info />} />
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </DescriptionWrap>
      )}
    </InputContainer>
  );
};

const InputContainer = styled(Container)`
  padding-top: 0;
`;

const Title = styled(AntdTitle)`
  font-size: 14px !important;
  font-weight: 500;
`;

const InputWrap = styled.div<{ isBottomPadded?: boolean; isBordered?: boolean }>`
  padding: ${({ isBottomPadded }) => (isBottomPadded ? '10px' : '10px 10px 5px')};
  ${({ isBordered }) => (!isBordered ? 'padding: 0 0 5px;' : '')}
`;

const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;

  .ant-typography {
    margin-bottom: 0;
  }
`;

const ErrorAlert = styled(Alert)`
  margin: 8px 0 5px;

  .ant-alert-message {
    display: inline-block;
  }
  .ant-alert-message::first-letter {
    text-transform: uppercase;
  }
`;

const DescriptionWrap = styled.div<{ isBordered?: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  ${({ isBordered }) =>
    isBordered
      ? `
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding: 5px 10px;
    `
      : `
      padding: 5px 0;
    `}
`;

const Description = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled(AntdIcon)<{ size?: number; color?: string }>`
  svg {
    height: ${({ size = 16 }) => `${size}px`};
    width: ${({ size = 16 }) => `${size}px`};
  }

  &.anticon + span {
    margin-left: 5px;
  }

  ${({ color }) => (color ? `color: ${color};` : '')}
`;

const FieldWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
`;

const FieldInput = styled.input`
  all: unset;
  font-size: 14px;
  font-weight: 400;
  width: -webkit-fill-available;

  ::placeholder {
    color: #676973a3;
  }

  :disabled {
    cursor: not-allowed;
    background-color: #f7f7f7;
    border: 1px solid rgba(118, 118, 118, 0.3);
    padding: 0 5px;
    border-radius: 4px;
    height: 32px;
  }
`;

export default Input;
