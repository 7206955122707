import React from 'react';
import { Field } from 'react-final-form';
import styled from '@emotion/styled';
import { Checkbox } from 'antd';
import classNames from 'classnames';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import InputWrap from './input-wrap';
import { ChangeEvent } from '../../../types';
import { Validator, composeValidators } from './validator';
import { requiredAll } from '../../organisations/organisation-details/queue-details/queue-setup/validate-form';

interface CheckboxGroupProps {
  className?: string;
  name: string;
  label?: React.ReactNode;
  options: {
    value: string;
    label?: string;
  }[];
  isRequired?: boolean;
  validators?: Validator[];
}

const CheckboxGroup = (props: CheckboxGroupProps) => {
  const { className, name, label, options, isRequired = false, validators = [] } = props;

  const inputValidators = [...(isRequired ? [requiredAll] : []), ...validators];

  return (
    <Field name={name} type="checkbox" validate={composeValidators(inputValidators)}>
      {({ input, meta }) => {
        const { value, onChange, ...restInput } = input;
        const valueArray = Array.isArray(value) ? value : [];

        const handleChange = (checkedValue: CheckboxValueType[]) => {
          onChange({ target: { value: checkedValue } } as unknown as ChangeEvent<CheckboxValueType[]>);
        };

        const isError = !!meta.error && meta.touched;

        return (
          <>
            <InputWrap
              label={label}
              isRequired={isRequired}
              error={isError ? meta.error : undefined}
            >
              <CheckboxGroupStyled
                {...restInput}
                className={classNames(
                  'form-group',
                  isError && 'has-error has-danger',
                  className,
                )}
                value={valueArray}
                onChange={handleChange}
              >
                {options.map((option) => {
                  const { value, label } = option;
                  return <Checkbox key={value} value={value}>{label || value}</Checkbox>;
                })}
              </CheckboxGroupStyled>
            </InputWrap>
          </>
        );
      }}
    </Field>
  );
};

const CheckboxGroupStyled = styled(Checkbox.Group)`
  &.has-error {
    .ant-checkbox-wrapper {
      color: #a94442 !important;
    }
    .ant-checkbox-inner {
      border-color: #a94442 !important;
    }
  }
`;

export default CheckboxGroup;
