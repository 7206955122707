import { Thunk, thunk } from 'easy-peasy';
import Browser from '../../types/browser';
import Injections from '../../injections.interface';
import createKeyedCrudModel, {
  KeyedCrudModel,
} from '../../common/keyed-crud-model/keyed-crud.model';

export type AppBrowsersModel = KeyedCrudModel<Browser, { appId: string }> & {
  move: Thunk<AppBrowsersModel, { appId: string; browserId: string }, Injections>;
};

const appBrowsersModel: AppBrowsersModel = {
  ...createKeyedCrudModel<Browser, { appId: string }>('/api/browsers', 'appId'),
  move: thunk(async (actions, { appId, browserId }, { injections }) => {
    const result = await injections.apiService.post<Browser>(
      `/api/browsers/${browserId}/move/${appId}`,
    );
    actions.setSingle({ key: appId, data: result });
  }),
};

export default appBrowsersModel;
