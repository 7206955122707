import React, { useMemo } from 'react';
import { useDeviceComponents } from '../use-device-components';
import { TooltipPlacement } from 'antd/lib/tooltip';
import OutdatedDeviceComponentsPopover from './outdated-device-components-popover.component';
import usePermissions from '../../../utils/auth/use-permissions';
import { useRouteMatch } from 'react-router';
import permissionKeys from '../../../utils/auth/permissions';

interface OutdatedDeviceComponentsProps {
  deviceUuid: string;
  popoverPlacement?: TooltipPlacement;
}

const OutdatedDeviceComponents = (props: OutdatedDeviceComponentsProps) => {
  const { deviceUuid, popoverPlacement = 'left' } = props;

  const {
    params: { organisationId },
  } = useRouteMatch<{ organisationId: string }>();

  const { isAllowed, isSysAdmin } = usePermissions(organisationId);

  const { data, isError: isQueryError, refetch, error } = useDeviceComponents(
    deviceUuid,
  );

  const canUserUpdate = useMemo(() => {
    return isAllowed(permissionKeys.devices.updateComponents) || isSysAdmin;
  }, [isAllowed, isSysAdmin]);

  const errorName = error && error.constructor ? error.constructor.name : '';

  const isError = isQueryError && errorName !== 'Cancel';

  return (
    <OutdatedDeviceComponentsPopover
      key={deviceUuid}
      components={data || []}
      popoverPlacement={popoverPlacement}
      isError={isError}
      refetch={refetch}
      canUserUpdate={canUserUpdate}
    />
  );
};

export default OutdatedDeviceComponents;
