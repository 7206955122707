import createKeyedCrudModel, {
  KeyedCrudModel,
} from '../../common/keyed-crud-model/keyed-crud.model';
import ProfessionalServicesUnit from '../../types/professional-services-unit';

export type OrganisationProfessionalServicesUnitsModel = Omit<
  KeyedCrudModel<ProfessionalServicesUnit, { startDate: string; endDate: string }>,
  'update' | 'delete'
>;

const organisationProfessionalServicesUnitsModel: OrganisationProfessionalServicesUnitsModel = createKeyedCrudModel(
  (key) => `/api/organizations/${key}/usage-history/professional-services`,
);

export default organisationProfessionalServicesUnitsModel;
