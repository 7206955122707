import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Typography } from 'antd';
import dayjs from 'dayjs';
import Media from '../../../../store/types/media';
import { isMediaTemplate } from '../../../../utils/media-templates';
import MediaThumbnail from '../media-thumbnail/media-thumbnail.component';
import { getMediaItemMimeTypeWithMetaInfo } from '../../../../utils/media-item-utils';

interface MediaSchemaFormFieldInfoProps {
  mediaItem: Media;
}

const MediaSchemaFormFieldInfo = (props: MediaSchemaFormFieldInfoProps) => {
  const { mediaItem } = props;

  const mediaItemType = useMemo(() => {
    return getMediaItemMimeTypeWithMetaInfo(mediaItem);
  }, [mediaItem]);

  return (
    <MediaPreview>
      <StyledThumbnail size={96} mediaItem={mediaItem} />
      <div>
        <div>
          <Typography.Text>{mediaItem.name}</Typography.Text>
        </div>
        <div>
          <Typography.Text type="secondary">{mediaItemType}</Typography.Text>
        </div>
        {isMediaTemplate(mediaItem) && mediaItem.metadata && Boolean(mediaItem.metadata.createdAt) && (
          <div>
            <Typography.Text type="secondary">
              {dayjs(mediaItem.metadata.createdAt * 1000).format('DD-MM-YYYY HH:mm')}
            </Typography.Text>
          </div>
        )}
      </div>
    </MediaPreview>
  );
};

export default MediaSchemaFormFieldInfo;

const MediaPreview = styled.div`
  margin: 16px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 500px) {
    display: block;
    white-space: normal;
    word-break: break-word;
    text-align: center;
  }
`;

const StyledThumbnail = styled(MediaThumbnail)`
  margin-right: 16px;

  @media screen and (max-width: 500px) {
    margin-right: 0px;
  }
`;
