import { TFunction } from 'i18next';

type BuildSchemaResponse = Record<string, string | number | boolean | object>;

export const buildFieldSchema = ({
  t,
  screenBootReleases,
  mobileBootReleases,
}: {
  t: TFunction;
  screenBootReleases: string[];
  mobileBootReleases: string[];
}): BuildSchemaResponse => {
  const enumScreenBootReleases = screenBootReleases.map((version) => version);
  const enumMobileBootReleases = mobileBootReleases.map((version) => version);

  const fieldSchema = {
    type: 'object',
    properties: {
      screenBootVersion: {
        type: 'string',
        minLength: 1,
        title: t('screenBootVersion'),
        enum: enumScreenBootReleases,
      },
      mobileBootVersion: {
        type: 'string',
        minLength: 1,
        title: t('mobileBootVersion'),
        enum: enumMobileBootReleases,
      },
    },
    required: ['screenBootVersion', 'mobileBootVersion'],
  };

  return fieldSchema;
};

export const buildUiSchema = (): BuildSchemaResponse => {
  const metaSchema = {
    objectFieldTemplate: 'main-section',
    screenBootVersion: {
      'ui:field': 'select',
    },
    mobileBootVersion: {
      'ui:field': 'select',
    },
  };

  return metaSchema;
};
