import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { groupBy, map, keyBy } from 'lodash';
import SearchInput, { SearchInputProps } from '../search-input/search-input-v2.component';
import Filter, { FilterProps } from '../filter/filter.component';
import Sort, { SortProps } from '../sort/sort.component';
import { ActiveFilter } from '../../../store/types/filters';
import FilterTags from '../filter/filter-tags.component';
import LayoutView, { LayoutViewProps } from '../layout-view/layout-view.component';

interface SearchBarProps {
  searchInputProps?: SearchInputProps;
  searchInputTag?: React.ReactNode;
  searchInputFilterProps?: FilterProps;
  sortProps?: SortProps;
  layoutViewProps?: LayoutViewProps;
  extra?: React.ReactNode;
  disabled?: boolean;
}

const SearchBar = (props: SearchBarProps) => {
  const {
    searchInputProps,
    searchInputTag,
    searchInputFilterProps: filterProps,
    sortProps,
    layoutViewProps,
    extra,
    disabled = false,
  } = props;
  const { onChange = () => {} } = (filterProps as FilterProps) || {};

  const [activeFilters, setActiveFilters] = useState<ActiveFilter[]>((filterProps && filterProps.defaultActiveFilters) || []);

  const handleFilterChange = useCallback(
    (filters: ActiveFilter[], lastRemovedFilter?: ActiveFilter | null): void => {
      setActiveFilters(filters);

      const filterTypeIds = groupBy(filters, 'typeId');
      const mappedFilterParams = map(filterTypeIds, (value, id) => {
        return { [id]: Object.keys(keyBy(value, 'valueId')) };
      });

      if (
        lastRemovedFilter &&
        !Object.keys(filterTypeIds).includes(lastRemovedFilter.typeId)
      ) {
        const id = lastRemovedFilter.typeId;
        mappedFilterParams.push({ [id]: [] });
      }

      onChange(filters, lastRemovedFilter);
    },
    [onChange],
  );

  return (
    <Container>
      <SearchInputStyled
        disabled={disabled}
        {...searchInputProps}
        tag={
          <>
            {filterProps && filterProps.filterOptions && (
              <FilterTags activeFilters={activeFilters} />
            )}
            {searchInputTag}
          </>
        }
        extra={
          <>
            {filterProps && filterProps.filterOptions && (
              <FilterWrap>
                <Filter {...filterProps} onChange={handleFilterChange} />
              </FilterWrap>
            )}
            {searchInputProps && searchInputProps.extra}
          </>
        }
      />
      <Extra>
        {sortProps && sortProps.sortCriteria && (
          <ExtraWrap>
            <Sort {...sortProps} />
          </ExtraWrap>
        )}
        {layoutViewProps && <LayoutView {...layoutViewProps} />}
        {extra && <ExtraWrap>{extra}</ExtraWrap>}
      </Extra>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 530px) {
    display: block;
  }
`;

const SearchInputStyled = styled(SearchInput)`
  background-color: #f5f5fa;
`;

const FilterWrap = styled.div`
  height: 100%;
  padding: 3px;
`;

const Extra = styled.div`
  display: inline-flex;
  height: auto;

  @media (max-width: 530px) {
    height: 44px;
    float: right;
    margin-top: 10px;
  }
`;

const ExtraWrap = styled.div`
  height: 100%;
  margin-left: 15px;

  .ant-btn-link {
    color: unset;
  }
`;

export const SearchBarWrap = styled.div<{ padding?: string }>`
  background-color: #ffffff;
  padding: ${({ padding = '10px 40px' }) => padding};
  box-shadow: 0 0 4px 1px rgba(33, 35, 38, 0.3);
  clip-path: polygon(0% 0%, 100% 0%, 100% 120%, 0% 120%);

  @media screen and (max-width: 991px) {
    padding: ${({ padding = '10px 20px' }) => padding};
  }
`;

export default SearchBar;
