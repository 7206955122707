import { useQuery } from 'react-query';
import { useApiService } from '../../../app/common/api-service-provider';
import segmentQueryKeys from './segment-query-keys';
import { Segment } from '../../types/segment';

const useSegmentById = (tenantId: string, segmentId: string) => {
  const apiService = useApiService();

  return useQuery(
    segmentQueryKeys.getSegment(segmentId),
    () => apiService.get<Segment>(`/api/content-segment/${segmentId}`, { tenantId }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export default useSegmentById;
