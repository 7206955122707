import LocaleCode from 'locale-code';
import i18next from 'i18next';
import localeCodeList from './locale-code-list.util';
import LocalizedField from '../../store/types/localized-field';

export const getCurrentLanguage = () => i18next.language;
export const getSupportedLanguages = () =>
  i18next.options.supportedLngs ? i18next.options.supportedLngs.slice(0, -1) : [];

export interface MultiLanguageOptions {
  defaultLanguage: string;
  supportedLanguages: string[];
  languageFrom: string;
  languageTo: string;
}

export const getShortLocaleCodeLabel = (code: string) => {
  if (LocaleCode.validate(code)) {
    return `${LocaleCode.getLanguageName(code)}, ${LocaleCode.getCountryName(code)}`;
  }

  return 'Invalid Locale Code';
};

export const getLocaleCodeLabel = (code: string) => {
  if (LocaleCode.validate(code)) {
    return `${LocaleCode.getLanguageName(code)}, ${LocaleCode.getCountryName(
      code,
    )} [${code}]`;
  }

  return 'Invalid Locale Code';
};

export const getAllLocaleCodes = () => {
  return localeCodeList.map((localeCode) => ({
    key: localeCode,
    label: getLocaleCodeLabel(localeCode),
  }));
};

export const getLanguageOptionStatus = (
  values: any,
  supportedLanguages: string[],
  defaultLanguage: string,
) => {
  return supportedLanguages.map((lang: string) => {
    let color = 'green';

    if (!values[lang] || (typeof values[lang] === 'string' && !values[lang].trim())) {
      color = defaultLanguage === lang ? 'red' : 'gray';
    }

    return { key: lang, color };
  });
};

export const getTranslationFromObject = (obj: LocalizedField): string => {
  const currLang = i18next.language as keyof LocalizedField;
  const fallBackLang = ((i18next.options.fallbackLng &&
    i18next.options.fallbackLng.toString()) ||
    'en') as keyof LocalizedField;

  const text = !obj[currLang] ? obj[fallBackLang] : obj[currLang];

  return text || '';
};

export default {
  getLocaleCodeLabel,
  getAllLocaleCodes,
  getLanguageOptionStatus,
  getShortLocaleCodeLabel,
  getTranslationFromObject,
};
