import { useQuery } from 'react-query';
import fromPairs from 'lodash/fromPairs';
import { useApiService } from '../api-service-provider';

interface DevicesReportItem {
  type: string;
  online: number;
  failing: number;
  offline: number;
  total: number;
}

type QueryParam = [string, string, string];

interface UseDevicesReportProps {
  tenantId: string;
  installationId?: string;
  spaceId?: string;
}

const useDevicesReport = ({
  tenantId,
  installationId,
  spaceId,
}: UseDevicesReportProps) => {
  const apiService = useApiService();

  const paramsList = [
    ['tenantId', tenantId],
    ['installationId', installationId],
    ['spaceId', spaceId],
  ].filter((queryParam): queryParam is QueryParam => queryParam[1] !== undefined);

  const params = fromPairs(paramsList);

  const queryKey = paramsList.map(([, paramValue]) => paramValue);

  return useQuery(
    queryKey,
    () => apiService.get<DevicesReportItem[]>('/api/v4/devices/reports', params),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export default useDevicesReport;
