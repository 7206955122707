import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Modal, Tooltip, Typography } from 'antd';
import styled from '@emotion/styled';
import { FieldProps } from 'react-jsonschema-form';
import Media, { ImageMimeType, MimeType } from '../../../../../store/types/media';
import MediaLibraryContainer from '../../../media-library/media-library.container';
import { Info, HelpCircle } from 'react-feather';
import transientOptions from '../../../../../utils/transient-emotion-styled-options';
import Settings, { SettingsData } from './settings';
import { Edit, Settings as Gear, Trash } from 'react-feather';
import mime from 'mime';
import trim from 'lodash/trim';
import Spinner from '../../../spinner/spinner.component';
import { Button, Container, Icon, JSONSchemaExt } from '../../common';
import MediaSchemaFormFieldInfo from '../../../media-library/media-schema-form-field-info/media-schema-form-field-info.component';
import MediaPreviewModal from '../../../media-library/media-preview/media-preview.component';

const { Title: AntdTitle } = Typography;

interface FilePickerUiOptions {
  isBordered?: boolean;
  hasDefaultDescription?: boolean;
  subtitle?: string;
  addFileButton?: {
    text?: string;
    icon?: string;
  };
  changeFileButton?: {
    text?: string;
    icon?: string;
  };
  removeFileButton?: {
    text?: string;
    icon?: string;
  };
  moreButton?: {
    text?: string;
    icon?: string;
  };
}

interface FilePickerProps extends FieldProps<Media> {
  className?: string;
  mediaItems: Media[];
  onFolderSelect: (folderId: string | null) => void;
  onBackClick: (folderId: string | null) => void;
  selectedFolderId: string | null;
  rawErrors?: string[];
  isFileLibLoading?: boolean;
  isFileLibError?: boolean;
  reinitFileLib?: any;
}

const FilePicker = (props: FilePickerProps) => {
  const {
    className,
    mediaItems,
    onFolderSelect,
    selectedFolderId,
    onBackClick,
    rawErrors = [],
    formContext,
    formData,
    onChange,
    schema,
    uiSchema,
    required,
    isFileLibLoading,
    isFileLibError,
    reinitFileLib,
  } = props;

  const { disabled: isDisabled, readOnly: isReadonly } = schema as JSONSchemaExt;

  const {
    isBordered = true,
    hasDefaultDescription = true,
    subtitle,
    addFileButton,
    changeFileButton,
    removeFileButton,
    moreButton,
  } = (uiSchema['ui:options'] || {}) as FilePickerUiOptions;
  const helpText = uiSchema['ui:help'] as string;

  const [isMediaModalVisible, setIsMediaModalVisible] = useState<boolean>(false);
  const [isMoreOpen, setIsMoreOpen] = useState<boolean>(false);
  const [isMediaPreviewModalVisible, setIsMediaPreviewModalVisible] = useState(false);

  const { t } = useTranslation();

  const errors = useMemo(() => {
    if (isFileLibError) {
      return t('filePicker.errorFetchingFiles');
    }

    return rawErrors
      .map((err) => trim(err, '.'))
      .join(',')
      .toLowerCase();
  }, [isFileLibError, rawErrors, t]);

  const matchedMediaItem = useMemo(() => {
    return formData ? mediaItems.find((item) => item.id === formData.id) : undefined;
  }, [formData, mediaItems]);

  const { settings = {} } = formData || {};

  const handleMediaChange = useCallback(
    (item: Media) => {
      onChange({
        ref: 'media',
        ...(formData || {}),
        id: item.id,
        type: item.type,
        url: item.url,
        name: item.name,
      });

      setIsMediaModalVisible(false);
    },
    [onChange, formData],
  );

  const handleSettingsChange = useCallback(
    (settings: SettingsData) => {
      let mediaItem = {};
      if (matchedMediaItem && Object.keys(matchedMediaItem).length) {
        const { id, type, name, url } = matchedMediaItem;
        mediaItem = { id, type, url, name };
      }
      onChange({
        ref: 'media',
        ...mediaItem,
        settings,
      });
    },
    [matchedMediaItem, onChange],
  );

  const handleRemoveFile = useCallback(() => onChange(undefined), [onChange]);

  useEffect(() => {
    if (
      !isFileLibLoading &&
      !isFileLibError &&
      !matchedMediaItem &&
      (formData && formData.id)
    ) {
      onChange(undefined);
    }
  }, [isFileLibError, isFileLibLoading, matchedMediaItem, formData, onChange]);

  const handlePickFile = useCallback(async () => {
    const result = isFileLibError ? await reinitFileLib() : { isError: false };

    if (!result.isError) {
      setIsMediaModalVisible(true);
    }
  }, [isFileLibError, reinitFileLib]);

  const handleModalCancel = useCallback(() => {
    setIsMediaModalVisible(false);
  }, []);

  const handleToggleMoreSettings = useCallback(() => {
    setIsMoreOpen((prevState) => !prevState);
  }, []);

  const { mediaTypes, fileFormats } = useMemo(() => {
    const mimeTypes =
      schema.properties && schema.properties.type
        ? ((schema.properties.type as any).enum as string[])
        : [];

    const extensions = mimeTypes.map((type) => {
      const ext = mime.getExtension(type) || '';
      return `${ext}`;
    });

    return {
      mediaTypes: mimeTypes,
      fileFormats: extensions
        .filter((ext) => ext)
        .join(', ')
        .toUpperCase(),
    };
  }, [schema.properties]);

  const addFileIcon = addFileButton && addFileButton.icon ? addFileButton.icon : 'plus';
  const addFileText = (addFileButton && addFileButton.text) || t('filePicker.addFile');
  const changeFileIcon = changeFileButton && changeFileButton.icon;
  const changeFileText =
    (changeFileButton && changeFileButton.text) || t('filePicker.edit');

  const removeFileIcon = removeFileButton && removeFileButton.icon;
  const removeFileText =
    (removeFileButton && removeFileButton.text) || t('filePicker.removeFile');

  const moreIcon = moreButton && moreButton.icon;
  const moreText = (moreButton && moreButton.text) || t('filePicker.moreSettings');
  const lessText = (moreButton && moreButton.text) || t('filePicker.lessSettings');

  const description = hasDefaultDescription
    ? t('filePicker.acceptedFormats', {
        formats: fileFormats,
        description: schema.description || '',
      })
    : schema.description;

  const hasMoreSettings =
    matchedMediaItem &&
    Object.values(ImageMimeType).includes(matchedMediaItem.type as ImageMimeType);

  const isAddFileButtonEnabled =
    !isFileLibLoading && !matchedMediaItem && !(formData && formData.id);

  const isPreviewDisabled = isDisabled || isReadonly;

  const handleOpenMediaPreviewModal = useCallback(() => {
    setIsMediaPreviewModalVisible(true);
  }, []);

  const handleCloseMediaPreviewModal = useCallback(() => {
    setIsMediaPreviewModalVisible(false);
  }, []);

  const { previewContent } = useMemo<{
    previewContent: JSX.Element | undefined;
    fileName: string | undefined;
    fileMimeType: string | undefined;
  }>(() => {
    const media = (isFileLibError || isFileLibLoading
      ? formData
      : matchedMediaItem) as Media;

    if (!media || !Object.keys(media).length) {
      return {
        previewContent: undefined,
        fileName: undefined,
        fileMimeType: undefined,
      };
    }

    const { name: fileName, type: mimeType } = media;

    return {
      previewContent: (
        <PreviewContainer onClick={handleOpenMediaPreviewModal}>
          <MediaSchemaFormFieldInfo mediaItem={media} />
        </PreviewContainer>
      ),
      fileName,
      fileMimeType: mimeType,
    };
  }, [isFileLibLoading, isFileLibError, formData, matchedMediaItem, handleOpenMediaPreviewModal]);

  return (
    <Container className={className} $isBordered={isBordered} $isDisabled={isDisabled}>
      <Content>
        <TitleWrap>
          {schema.title && <Title>{schema.title}{required ? ' *' : ''}</Title>}
          {helpText && (
            <div>
              <Tooltip
                placement="left"
                title={<div dangerouslySetInnerHTML={{ __html: helpText }} />}
              >
                <HelpCircle size={18} />
              </Tooltip>
            </div>
          )}
        </TitleWrap>
        {subtitle && <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />}
        {errors && <ErrorAlert message={errors} type="error" showIcon />}
        <Preview
          $isDisabled={isPreviewDisabled}
        >
          {isFileLibLoading && (
            <Loading>
              <Spinner size={18} />
              {t('filePicker.pleaseWait')}
            </Loading>
          )}
          {isAddFileButtonEnabled && (
            <Button type="default" onClick={handlePickFile} disabled={isPreviewDisabled}>
              <Icon size={14} type={addFileIcon} />
              {addFileText}
            </Button>
          )}
          {previewContent}
        </Preview>
        <Controls>
          {description && (
            <DescriptionWrap>
              <IconWrap>
                <Info size={18} />
              </IconWrap>
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </DescriptionWrap>
          )}
          {matchedMediaItem && (
            <ChangeButtons>
              <Button
                type="default"
                onClick={handlePickFile}
                disabled={isPreviewDisabled}
              >
                {changeFileIcon ? (
                  <Icon size={18} type={changeFileIcon} />
                ) : (
                  <Icon size={18} component={() => <Edit />} />
                )}
                {changeFileText}
              </Button>
              <Button
                type="danger"
                onClick={handleRemoveFile}
                disabled={isPreviewDisabled}
              >
                {removeFileIcon ? (
                  <Icon size={18} type={removeFileIcon} />
                ) : (
                  <Icon size={18} component={() => <Trash />} />
                )}
                {removeFileText}
              </Button>
              {hasMoreSettings && (
                <Button
                  type="link"
                  onClick={handleToggleMoreSettings}
                  isActive={isMoreOpen}
                  disabled={isDisabled}
                >
                  {moreIcon ? (
                    <Icon size={18} type={moreIcon} />
                  ) : (
                    <Icon size={18} component={() => <Gear />} />
                  )}
                  {isMoreOpen ? lessText : moreText}
                </Button>
              )}
            </ChangeButtons>
          )}
        </Controls>
      </Content>
      {matchedMediaItem && (
        <Settings
          isExpanded={isMoreOpen}
          mimeType={matchedMediaItem.type as MimeType}
          onChange={handleSettingsChange}
          onClose={handleToggleMoreSettings}
          value={settings}
          isReadonly={isReadonly}
        />
      )}

      {matchedMediaItem && isMediaPreviewModalVisible && (
        <MediaPreviewModal
          mediaItem={matchedMediaItem}
          onClose={handleCloseMediaPreviewModal}
        />
      )}

      <Modal
        centered
        footer={null}
        visible={isMediaModalVisible}
        bodyStyle={{ padding: 0 }}
        width="80%"
        onCancel={handleModalCancel}
      >
        {/* to re-render MediaLibraryContainer with initial values */}
        {isMediaModalVisible && (
          <MediaLibraryContainer
            mediaTypesFilter={mediaTypes}
            onSelectMediaItem={handleMediaChange}
            organisationId={formContext.organisationId}
            onSelectedFolder={onFolderSelect}
            selectedFolderId={selectedFolderId}
            onBackClick={onBackClick}
          />
        )}
      </Modal>
    </Container>
  );
};

const Content = styled.div`
  padding-top: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 500px) {
    > button {
      width: 100%;
    }
  }
`;

const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 10px;

  .ant-typography {
    margin-bottom: 0;
  }
`;

const Subtitle = styled.div`
  margin: 0 10px;
`;

const ErrorAlert = styled(Alert)`
  margin: 8px 10px;

  .ant-alert-message {
    display: inline-block;
  }
  .ant-alert-message::first-letter {
    text-transform: uppercase;
  }
`;

const Preview = styled('div', transientOptions)<{ $isDisabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(242, 242, 247);
  border-radius: 4px;
  width: -webkit-fill-available;
  min-height: 60px;
  gap: 10px;
  margin: 8px;
  cursor: pointer;

  padding: 8px;
  :has(button) {
    padding: 8px;
  }
`;

const Loading = styled.div`
  display: flex;
  gap: 8px;
`;

const DescriptionWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px 10px;
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 10px;
  gap: 5px;

  @media only screen and (max-width: 576px) {
    flex-direction: column;
  }
`;

const ChangeButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 5px;

  @media only screen and (max-width: 576px) {
    flex-direction: column;
  }
`;

const IconWrap = styled.div`
  display: flex;
`;

const Title = styled(AntdTitle)`
  font-size: 16px !important;
`;

const PreviewContainer = styled.div`
  width: 100%;
`;

export default React.memo(FilePicker);
