import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { Divider, Row, Col, Button, Typography } from 'antd';
import arrayMutators from 'final-form-arrays';
import PanelCard from '../../../../common/panel-card/panel-card.component';
import AlertRuleFormMainSection from './alert-rule-form-main-section.component';
import { StickyColumn } from '../../queue-details/queue-setup/styled-block';
import { InputWrap, Select, SelectProps } from '../../../../common/react-final-form';
import { ButtonType, ChangeEvent } from '../../../../../types';
import ScopeLevelPicker from './scope-level-picker.component';
import {
  AlertCondition,
  AlertScope,
  SeverityEnum,
} from '../../../../../store/types/alerts';
import AlertRuleFormConditionsSection from './alert-rule-form-conditions-section.component';
import AlertRuleFormActionsSection from './alert-rule-form-actions-section.component';
import { requiredNumber } from '../../queue-details/queue-setup/validate-form';
import { Validator } from '../../../../common/react-final-form';

interface AlertRuleFormProps {
  title: string;
  subTitle?: string;
  tenantId: string;
  initialValues: AlertRuleFormValues;
  onFormSubmit: (
    values: AlertRuleFormValues,
  ) => Promise<AlertRuleFormApiValidationErrors>;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

interface AlertRuleFormAction {
  actionId: string;
}

export interface AlertRuleFormValues {
  displayName: string;
  description: string;
  enabled: number;
  severity: SeverityEnum;
  scope: AlertScope[];
  conditions: AlertCondition[];
  actions: AlertRuleFormAction[];
}

interface ValidationError {
  _error: string;
}

interface AlertRuleFormValidationErrors {
  scope?: ValidationError[];
  conditions?: ValidationError[];
  actions?: ValidationError[];
}

export interface AlertRuleFormApiValidationErrors extends AlertRuleFormValidationErrors {
  displayName?: string;
  description?: string;
  enabled?: string;
  severity?: string;
}

const AlertRuleForm = (props: AlertRuleFormProps) => {
  const { title, subTitle, initialValues, tenantId, onFormSubmit, isLoading } = props;

  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (values: AlertRuleFormValues | Object) => {
      const result = await onFormSubmit(values as AlertRuleFormValues);

      return result;
    },
    [onFormSubmit],
  );

  const statusOptions = useMemo<SelectProps['options']>(
    () => [
      {
        value: 1,
        label: t('alertRules.enabled'),
      },
      {
        value: 0,
        label: t('alertRules.disabled'),
      },
    ],
    [t],
  );

  const statusRequiredValidator = requiredNumber as Validator<string | number>;

  const checkValidations = useCallback(
    (values: AlertRuleFormValues | Object) => {
      const errors: AlertRuleFormValidationErrors = {};

      const { scope, conditions, actions } = values as AlertRuleFormValues;

      if (!scope.length) {
        errors.scope = [{ _error: t('formValidation.atLeastOneEntryRequired') }];
      }

      if (!conditions.length) {
        errors.conditions = [{ _error: t('formValidation.atLeastOneEntryRequired') }];
      }

      if (!actions.length) {
        errors.actions = [{ _error: t('formValidation.atLeastOneEntryRequired') }];
      }

      return errors;
    },
    [t],
  );

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        mutators={{
          ...arrayMutators,
        }}
        validate={checkValidations}
        render={({ handleSubmit, form: { change: updateForm } }) => {
          return (
            <>
              <form id="alert-rule-form" onSubmit={handleSubmit}>
                <Row gutter={{ md: 20, xl: 40 }}>
                  <Col md={40} xl={15}>
                    <FormFieldsContainer>
                      <Row>
                        <Typography.Title level={3}>{title}</Typography.Title>
                        {subTitle && <SubTitle>{subTitle}</SubTitle>}
                      </Row>

                      <DividerFull />

                      <AlertRuleFormMainSection />

                      <Field
                        name="scope"
                        render={({ input, meta }) => (
                          <InputWrap
                            label={t('alertRules.scope')}
                            isRequired={true}
                            error={meta.error && meta.touched ? meta.error : null}
                          >
                            <ScopeLevelPicker
                              initialScopes={input.value}
                              tenantId={tenantId}
                              onChange={(values) => {
                                input.onChange(({
                                  target: {
                                    value: values,
                                  },
                                } as unknown) as ChangeEvent<string>);
                              }}
                            />
                          </InputWrap>
                        )}
                      />
                    </FormFieldsContainer>

                    <FormFieldsContainer>
                      <AlertRuleFormConditionsSection updateForm={updateForm} />
                    </FormFieldsContainer>

                    <FormFieldsContainer>
                      <AlertRuleFormActionsSection tenantId={tenantId} />
                    </FormFieldsContainer>
                  </Col>

                  <StickyColumn md={24} xl={9}>
                    <FormFieldsContainer>
                      <FieldsContainer>
                        <Select
                          name="enabled"
                          label={`${t('alertRules.status')} *`}
                          validators={[statusRequiredValidator]}
                          options={statusOptions}
                          isSearchEnabled={false}
                        />
                      </FieldsContainer>

                      <SaveButton
                        type="primary"
                        form="alert-rule-form"
                        htmlType="submit"
                        size="large"
                        loading={isLoading}
                      >
                        {t('alertRules.saveAllChanges')}
                      </SaveButton>
                    </FormFieldsContainer>
                  </StickyColumn>
                </Row>
              </form>
            </>
          );
        }}
      />
    </>
  );
};

export default AlertRuleForm;

const FormFieldsContainer = styled(PanelCard)`
  padding: 20px;
  width: 100% !important;
  margin-bottom: 20px;

  .ant-card-body {
    padding: 0px !important;
  }
`;

const SaveButton = styled(Button)`
  width: 100%;
  margin-top: 15px;
` as ButtonType;

const FieldsContainer = styled.div`
  margin-bottom: 15px;
`;

const SubTitle = styled(Typography.Text)`
  color: rgba(0, 0, 0, 0.45);
  line-height: 3;
`;

const DividerFull = styled(Divider)`
  width: calc(100% + 56px);
  margin-left: -28px;
  overflow: none;
`;
