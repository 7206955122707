import React, { useCallback, useState, useMemo } from 'react';
import styled from '@emotion/styled';
import { Row, Col, Typography, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import PanelCard from '../../../common/panel-card/panel-card.component';
import { StickyColumn } from '../../../organisations/organisation-details/queue-details/queue-setup/styled-block';
import { PlanTypeEnum } from '../../../../store/types/organisation-plan';
import PlanFormMainSection from './plan-form-main-section.component';
import { ButtonType } from '../../../../types';
import useSkus from '../../../common/use-skus';
import useDimensionsGet from '../../../../store/hooks/dimensions/use-dimensions-get';
import Dimension from '../../../../store/types/dimension';
import PlanFormItemsSection from './plan-form-items-section.component';
import { OnChange } from 'react-final-form-listeners';
import PlanFormFeaturesSection from './plan-form-features-section.component';

export interface PlanApiValidationErrors {
  sku?: string;
}

interface PlanFormLocalizedField {
  en: string;
}

interface PlanFormSkuConfiguration {
  defaultSku?: string;
}

export interface PlanFormItem {
  dimensionId: string;
  includedQuantity: number;
  skuConfiguration?: PlanFormSkuConfiguration;
}

export interface PlanFormFeature {
  name: PlanFormLocalizedField;
  description: PlanFormLocalizedField;
  icon: string;
}

export interface PlanFormValues {
  id?: string;
  sku: string;
  type: PlanTypeEnum;
  name: PlanFormLocalizedField;
  description?: PlanFormLocalizedField;
  isDefault: boolean;
  icon?: string;
  items?: PlanFormItem[];
  features?: PlanFormFeature[];
}

interface PlanFormProps {
  title: string;
  initialValues: PlanFormValues;
  onFormSubmit: (values: PlanFormValues | Object) => Promise<PlanApiValidationErrors>;
}

const PlanForm = (props: PlanFormProps) => {
  const { title, initialValues, onFormSubmit } = props;

  const { t } = useTranslation();

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const { data: skusData } = useSkus(1, 10000);

  const { data: dimensionsData } = useDimensionsGet();

  const [dimensionsFilter, updateDimensionsFilter] = useState<PlanTypeEnum>(
    initialValues.type,
  );

  const filteredDimensions = useMemo<Dimension[]>(() => {
    if (!dimensionsData || !dimensionsData.length) {
      return [];
    }

    return dimensionsData.filter(
      (dimension) => dimension.planType === dimensionsFilter,
    );
  }, [dimensionsData, dimensionsFilter]);

  const handleFormSubmit = useCallback(
    async (values: PlanFormValues | Object) => {
      setIsFormSubmitting(true);

      const result = await onFormSubmit(values);
      setIsFormSubmitting(false);

      return result;
    },
    [onFormSubmit],
  );

  return (
    <>
      <Row gutter={{ md: 20, xl: 40 }}>
        <Col md={40} xl={15}>
          <FormFieldsContainer>
            <TitleContainer>
              <Typography.Title level={3}>{title}</Typography.Title>
            </TitleContainer>

            <Form
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              mutators={{
                ...arrayMutators,
              }}
              render={({ handleSubmit }) => {
                return (
                  <>
                    <form id="plan-form" onSubmit={handleSubmit}>
                      <OnChange name="type">
                        {(filter: PlanTypeEnum) => {
                          updateDimensionsFilter(filter);
                        }}
                      </OnChange>

                      <PlanFormMainSection
                        skus={skusData && skusData.docs ? skusData.docs : []}
                      />
                      <PlanFormItemsSection
                        skus={skusData && skusData.docs ? skusData.docs : []}
                        dimensions={filteredDimensions}
                      />
                      <PlanFormFeaturesSection />
                    </form>
                  </>
                );
              }}
            />
          </FormFieldsContainer>
        </Col>

        <StickyColumn md={24} xl={9}>
          <PanelCard>
            <SaveButton
              type="primary"
              form="plan-form"
              htmlType="submit"
              disabled={isFormSubmitting}
            >
              {t('plans.form.saveAllChanges')}
            </SaveButton>
          </PanelCard>
        </StickyColumn>
      </Row>
    </>
  );
};

export default PlanForm;

const FormFieldsContainer = styled(PanelCard)`
  padding: 20px;
  width: 100% !important;
`;

const TitleContainer = styled(Row)`
  margin-bottom: 20px;
`;

const SaveButton = styled(Button)`
  width: 100%;
` as ButtonType;
