import React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Col, Divider as AntdDivider, Row, Typography } from 'antd';
import styled from '@emotion/styled';
import { IsoLanguageIds } from '@ombori/grid-products';
import {
  ArrayFields,
  LanguagePicker,
  CheckboxGroup,
  CronSchedulePicker,
  CronSchedule,
  ScheduleFrequencyEnum,
} from '../../../../../common/react-final-form';
import { Description, PanelCard, Title } from '../common';
import { FormTextInput } from '../../../queue-details/queue-setup/styled-block';
import { IntegrationFeedSettings, IntegrationTypeEnum } from '../types';
import { requiredAll } from '../../../queue-details/queue-setup/validate-form';

const languages = Object.values(IsoLanguageIds);

interface IntegrationFormFieldsProps {
  integrationType: IntegrationTypeEnum;
  title: string;
  environments: { value: string; label: string }[];
}

const IntegrationFormFields = (props: IntegrationFormFieldsProps) => {
  const { integrationType, title, environments } = props;

  const { t } = useTranslation();

  const scheduleFrequencyOptions = [
    { value: ScheduleFrequencyEnum.EVERY_DAY, label: t('scheduleFrequency.everyDay') },
  ];

  return (
    <>
      <Row>
        <PanelCard>
          <Row>
            <FormTitle level={3}>{title}</FormTitle>
          </Row>
          <Row>
            <Field name="name" validate={requiredAll}>
              {({ input, meta }) => (
                <FormTextInput
                  input={input}
                  meta={meta}
                  label={t('title')}
                  required
                  type="text"
                />
              )}
            </Field>
          </Row>
          <Divider />
          <Row>
            <Title>{t('schedule')}</Title>
            <Description>{t('productIntegration.scheduleHint')}</Description>
          </Row>
          <ArrayFields<CronSchedule>
            propertyName="schedule"
            defaultValue={{
              frequency: ScheduleFrequencyEnum.EVERY_DAY,
              time: undefined,
              cronExpression: '',
              cronExpressionUtc: '',
            }}
            addButtonLabel={t('addTime')}
            itemRowContent={(parentPropertyName: string) => {
              return (
                <Col span={23}>
                  <CronSchedulePicker
                    name={parentPropertyName}
                    isRequired
                    options={scheduleFrequencyOptions}
                    frequency={{
                      isEnabled: false,
                    }}
                    time={{
                      minuteStep: 15,
                    }}
                  />
                </Col>
              );
            }}
          />
          <Divider />
          <Row>
            <Title>{t('environments')}</Title>
            <Description>{t('productIntegration.environmentHint')}</Description>
          </Row>
          <Row>
            <CheckboxGroup name="environment" isRequired options={environments} />
          </Row>
        </PanelCard>
      </Row>
      <Row>
        <PanelCard>
          {integrationType === IntegrationTypeEnum.GOOGLE_PRODUCT_FEED && (
            <>
              <Row>
                <Title>{t('productIntegration.feedSettings')}</Title>
                <Description>{t('productIntegration.feedSettingsHint')}</Description>
              </Row>
              <ArrayFields<IntegrationFeedSettings>
                propertyName="config.feeds"
                defaultValue={{
                  locale: IsoLanguageIds.en_US,
                  url: '',
                }}
                addButtonLabel={t('addFeed')}
                itemRowContent={(parentPropertyName: string) => {
                  return (
                    <>
                      <ColFeed span={23}>
                        <LanguagePickerStyled
                          name={`${parentPropertyName}.locale`}
                          label={t('locale')}
                          isRequired
                          supportedLanguages={languages}
                        />
                        <Field name={`${parentPropertyName}.url`} validate={requiredAll}>
                          {({ input, meta }) => (
                            <FormTextInput
                              input={input}
                              meta={meta}
                              label={t('feedUrl')}
                              required
                              type="text"
                            />
                          )}
                        </Field>
                      </ColFeed>
                    </>
                  );
                }}
              />
            </>
          )}
          {integrationType === IntegrationTypeEnum.GOOGLE_LOCAL_INVENTORY && (
            <>
              <Row>
                <Title>{t('productIntegration.feedSettings')}</Title>
              </Row>
              <Row>
                <Field name="config.feedUrl" validate={requiredAll}>
                  {({ input, meta }) => (
                    <FormTextInput
                      input={input}
                      meta={meta}
                      label={t('feedUrl')}
                      required
                      type="text"
                    />
                  )}
                </Field>
              </Row>
            </>
          )}
        </PanelCard>
      </Row>
    </>
  );
};

const Divider = styled(AntdDivider)`
  width: calc(100% + 40px);
  margin: 20px -20px;
`;

const LanguagePickerStyled = styled(LanguagePicker)`
  width: 150px !important;
`;

const ColFeed = styled(Col)`
  display: flex;
  gap: 10px;

  > :last-child {
    flex-grow: 1;
  }
`;

const FormTitle = styled(Typography.Title)`
  margin-bottom: 20px !important;
`;

export default IntegrationFormFields;
