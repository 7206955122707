import React from 'react';
import styled from '@emotion/styled';
import { Link, matchPath, RouteComponentProps } from 'react-router-dom';
import { Icon, Menu } from 'antd';
import AppLayout from '../app-layout/app-layout.component';
import logo from '../../../assets/images/logo-only.svg';
import SidebarHeader from '../app-layout/sidebar/sidebar-header/sidebar-header.component';
import { SidebarTitle } from '../app-layout/sidebar/sidebar-title/sidebar-title.component';
import SidebarMenu from '../app-layout/sidebar/sidebar-menu/sidebar-menu.component';

const GridLogo = styled.img`
  margin: 16px auto;
  height: 5em;
  width: 100%;
`;

interface WithMenuProps extends RouteComponentProps {
  children: any;
  title: string;
  menuItems: {
    path: string;
    icon: string;
    label: string;
  }[]
}

const WithMenu = (props: WithMenuProps) => {
  const { location, children, menuItems, title } = props;

  const menuPathMatch = menuItems
    .map((menuItem) =>
      matchPath(location.pathname, {
        path: menuItem.path,
      }),
    )
    .find((pathMatch) => !!pathMatch);

  const selectedMenuKeys = menuPathMatch ? [menuPathMatch.path] : [];

  return (
    <>
      <AppLayout.Navbar title={title} />
      <AppLayout>
        <AppLayout.Sidebar>
          <SidebarHeader>
            <Link to="/">
              <GridLogo src={logo} alt="Ombori logo" />
              <SidebarTitle level={2} type="secondary">
                {title}
              </SidebarTitle>
            </Link>
          </SidebarHeader>
          <SidebarMenu selectedKeys={selectedMenuKeys}>
            {
              menuItems.map((menuItem) => {
                return <Menu.Item key={menuItem.path}>
                  <Link to={menuItem.path}>
                    <Icon type={menuItem.icon} />
                    {menuItem.label}
                  </Link>
                </Menu.Item>
              })
            }
          </SidebarMenu>
        </AppLayout.Sidebar>
        <AppLayout.Content>{children}</AppLayout.Content>
      </AppLayout>
      <AppLayout.Footer />
    </>
  );
};

export default WithMenu;
