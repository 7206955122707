import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Button, Card, Col, Row, Typography, Tag, Popover } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { Link, RouteComponentProps } from 'react-router-dom';
import Overlay from '../../../../common/overlay';
import { Subheader } from '../../../../common/app-layout/header';
import { SortOrderType } from '../../../../../store/types/sort';
import usePaginationQueryParams from '../../../../common/use-pagination-query-params';
import { PaginationQueryParams } from '../../../../common/use-pagination-query-params/use-pagination-query-params';
import {
  useOrganizationUsersList,
  OrganizationUsersListQueryInterface,
} from '../../../../../store/hooks/organization-users/use-organization-users-list';
import PaginatedListTable from '../../../../common/paginated-list/paginatied-list-table';
import PaginatedListSearch from '../../../../common/paginated-list/paginated-list-search';
import { usePagination } from '../../../../common/pagination';
import { Icon } from '../../../../common/schema-form/common';
import { useActions, useStore } from 'easy-peasy';
import { RootModel, RootState } from '../../../../../store/models/root.model';
import { PaginationCollection } from '../../../../../store/types/pagination';
import UserRole from '../../../../../store/types/user-roles';
import OrganizationUserListItemActions from './organization-user-list-actions.component';
import TenantUser from '../../../../../store/types/tenant-user';
import { formatDate } from '../../../../../utils/date-formatter';
import moment from 'moment';
import OrganisationSpace from '../../../../../store/types/organisation-space';

const { Title } = Typography;


interface OrganizationUsersListPaginationParams extends PaginationQueryParams {
  sortField?: string;
  sortOrder?: SortOrderType;
  organizationId?: string;
}

interface OrganizationUsersListProps extends RouteComponentProps<{ organisationId: string }> {
}

type OrgRoles = Record<string, Record<string, UserRole>>;

function generateUserRolesMapping(usersData: PaginationCollection<TenantUser> | undefined, orgRoles: OrgRoles, organisationId: string): Record<string, UserRole> {
  if (!usersData || !orgRoles) return {};

  const rolesMapping: Record<string, UserRole> = {};
  const { docs: users } = usersData;

  users.forEach((user) => {
    if (orgRoles[organisationId] && user.roleId) {
      const orgRole = orgRoles[organisationId];
      rolesMapping[user.id] = orgRole[user.roleId];
    }
  });

  return rolesMapping;
}

const renderPopoverContent = (remainingSpaces: Partial<OrganisationSpace>[]) => (
  <StyledTagRow>
    {remainingSpaces.map((space) => {
      if (space && space.id) {
        return (
          <StyledTag key={'space-' + space.id}>
            {space.displayName}
          </StyledTag>
        );
      }
      return null;
    })}
  </StyledTagRow>
);

const UsersList = (props: OrganizationUsersListProps) => {
  const { match } = props;
  const { t } = useTranslation();

  const { organisationId } = match.params;
  const defaultSortField = 'email';
  const defaultSortOrder = 'asc';

  const { page, defaultPage, pageSize, defaultPageSize } = usePagination();

  const [paginationQueryParams] = usePaginationQueryParams<OrganizationUsersListPaginationParams>({
    page: page || defaultPage,
    limit: pageSize || defaultPageSize,
    sortField: defaultSortField,
    sortOrder: defaultSortOrder,
    organizationId: organisationId,
  });

  const useOrganizationUsersListParams: OrganizationUsersListQueryInterface = {
    limit: paginationQueryParams.limit || defaultPageSize,
    organizationId: organisationId,
    page: paginationQueryParams.page || defaultPage,
    searchTerm: paginationQueryParams.search || '',
    sortField: defaultSortField,
    sortOrder: defaultSortOrder,
  };

  const {
    data: usersData,
    isLoading: isUsersLoading,
    isFetching: isUsersFetching,
    isError: isUserListError,
  } = useOrganizationUsersList(useOrganizationUsersListParams);


  const {
    fetchUserRoles,
  } = useActions<RootModel>((actions) => ({
    fetchUserRoles: actions.userRoles.fetch,
  }));

  useEffect(() => {
    fetchUserRoles({ organizationId: organisationId });
  }, [fetchUserRoles, organisationId]);

  const { userRoles } = useStore<
    RootState
  >((state) => ({
    userRoles: state.userRoles.data,
  }));

  const userRolesMapping = useMemo(() =>
    generateUserRolesMapping(usersData, userRoles, organisationId), [
    usersData,
    userRoles,
    organisationId,
  ]);


  const columns = useMemo<ColumnProps<TenantUser>[]>(() => {
    const renderSpaces = (user: TenantUser) => {
      const spaces = user.userSpaces;

      const maxSpacesToShow = 2;

      if (user.hasAccessToAllSpaces) {
        return (
          <Tag
            color="blue"
            key={user.id}>
            {t('userManagement.allSpaces')}
          </Tag>
        );
      }

      if (!spaces) {
        return (
          <>
            {t('notAvailable')}
          </>
        );
      }

      if (spaces.length > 0) {
        return (
          <>
            {spaces.slice(0, maxSpacesToShow).map((space) => (
              <Tag key={`space-${space.id}-${user.id}`}>
                {space.displayName}
              </Tag>
            ))}
            {spaces.length > maxSpacesToShow && (
              <Popover content={renderPopoverContent(spaces.slice(maxSpacesToShow))}>
                <small>
                  ... {spaces.length - maxSpacesToShow} {t('moreSpaces')}
                </small>
              </Popover>
            )}
          </>
        );
      }

      return (
        <>
          {t('notAvailable')}
        </>
      );

    };


    return [
      {
        title: t('user'),
        key: 'organization-user',
        width: '27%',
        render: (_, record) => {
          record.firstName = record.firstName || '';
          record.lastName = record.lastName || '';
          const fullName = record.firstName + ' ' + record.lastName;
          return (
            <>
              <NameText>{fullName}</NameText>
              <EmailText>{record.email}</EmailText>
            </>
          );
        },
      },
      {
        title: t('spaces'),
        key: 'organization-space',
        width: '23%',
        render: (_, record) => (
          <>
            {renderSpaces(record)}
          </>
        ),
      },
      {
        title: t('role'),
        key: 'organization-user-role',
        width: '15%',
        render: (_, record) => (
          <StyledRow>
            <CapitalizeText>
              {userRolesMapping[record.id] ? userRolesMapping[record.id].displayName : ''}
            </CapitalizeText>
          </StyledRow>
        ),
      },
      {
        title: t('status'),
        key: 'organization-status',
        width: '15%',
        render: (_, record) => (
          <CapitalizeText>
            {t('active')}
          </CapitalizeText>
        ),
      },
      {
        title: t('lastSeen'),
        key: 'organization-last-seen',
        width: '15%',
        render: (_, record) => (
          <>
            <p>
              {record.lastSeenAt ? moment(record.lastSeenAt).fromNow() : t('notAvailable')}
            </p>
            <small>
              {formatDate({ dateToFormat: record.lastSeenAt })}
            </small>
          </>
        ),
      },
      {
        title: <Icon type="setting" />,
        key: 'organization-user-action',
        width: '5%',
        align: 'center',
        render: (_, record) => (
          <OrganizationUserListItemActions
            user={record}
            organizationId={organisationId} />
        ),
      },
    ];
  }, [t, organisationId, userRolesMapping]);

  return (
    <>
      <Overlay>
        {isUserListError &&
          <Row>
            <ErrorCard>
              <ErrorIcon type="frown" />
              <Message level={1}>{t('somethingWentWrong')}</Message>
            </ErrorCard>
          </Row>
        }
        {!isUserListError &&
          <Row>
            <Col>
              <Subheader
                variant="dropshadow"
                components={[
                  <PaginatedListSearch
                    minSearchStringLength={1}
                    key="organization-user-search-bar"
                    searchPlaceholder={t('userManagement.searchUserPlaceholderLabel')}
                  />,
                ]}
              />
              <ButtonWrap>
                <Link to={`${match.url}/create`}>
                  <Button icon="plus" type="primary" size="large">
                    {t('addUser')}
                  </Button>
                </Link>
              </ButtonWrap>
            </Col>
            <Col>
              <PaginatedListTable<TenantUser>
                data={usersData!}
                columns={columns}
                isDataLoading={isUsersLoading || isUsersFetching}
              />
            </Col>
          </Row>
        }
      </Overlay>
    </>
  );
};

const StyledTagRow = styled.div`
    padding: 0;
    margin: 0.5em 0 0 0;
`;

const StyledTag = styled(Tag)`
    display: block;
    margin: 0;
    margin-bottom: 0.5em;
    padding: 0 0.3em 0 0.3em;
`;

export const NameText = styled.span`
    padding-bottom: 0.3em;
    text-transform: capitalize;
    font-weight: bold;
`;

export const CapitalizeText = styled.span`
    text-transform: capitalize;
`;

export const EmailText = styled.span`
    display: block;
`;

const ButtonWrap = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    margin-right: 80px;

    @media screen and (max-width: 991px) {
        margin-right: 20px;
    }
`;

const StyledRow = styled.div`
    padding: 1.3em 0em;
`;

const ErrorCard = styled(Card)`
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
    padding: 40px;
`;

const ErrorIcon = styled(Icon)`
    font-size: 64px;
    margin-bottom: 16px;
`;

const Message = styled(Title)`
    margin-top: 12px;
`;

export default UsersList;