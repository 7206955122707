import { useMutation, useQueryClient } from 'react-query';
import { useApiService } from '../../../app/common/api-service-provider';
import { Channel } from '../../types/channel';
import channelsQueryKeys from './channels-query-keys';

export const useDeleteChannel = (tenantId: string) => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  return useMutation(
    (channelId: string) => {
      return apiService.delete<Channel>(`/api/content-channel/${channelId}`, { tenantId });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(channelsQueryKeys.tenantChannels(tenantId));
      },
    },
  );
};
