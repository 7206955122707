import styled from '@emotion/styled';
import { Card } from 'antd';

const DataCard = styled(Card)`
  background: rgb(251, 251, 253);
  border: solid 1px rgb(235, 237, 244);
  width: 100%;
  margin-top: 4px;
  border-radius: 2px;

  &:hover {
    border: solid 1px #2364aa;
    label {
      color: #16161d;
    }
  }

  .form-group .sticky-title {
    position: relative;
    background: 0;
  }

  .field-object {
    .object-card-wrapper {
      margin-bottom: 16px;
    }
  }
`;

export default DataCard;
