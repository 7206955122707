import { IsoLanguageIds } from '@ombori/grid-products';
import { CronSchedule } from '../../../../../common/react-final-form';
import { TransactionStamp } from '../../../../../../store/types/action-log';
import { DataResidencyEnum } from '../../../../../../store/types/organisation';

export enum IntegrationStatusTagEnum {
  SUCCESS = 'Success',
  INPROGRESS = 'InProgress',
  FAILED = 'Failed',
}

export enum IntegrationTypeEnum {
  GOOGLE_PRODUCT_FEED = 'GoogleProductFeed',
  GOOGLE_LOCAL_INVENTORY = 'GoogleLocalInventory',
}

export enum IntegrationStatusEnum {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface IntegrationSettings extends TransactionStamp {
  id: string;
  name: string;
  status: IntegrationStatusEnum;
  type: IntegrationTypeEnum;
  schedule: string[];
  environment: string[];
  config: {
    format: 'xml'; // Temporary
    feeds?: IntegrationFeedSettings[];
    feedUrl?: string;
  };
  tenantId: string;
  dataResidency: DataResidencyEnum;
}

export interface IntegrationFeedSettings {
  locale?: IsoLanguageIds;
  url: string;
}

export interface IntegrationFormValues {
  name: string;
  status: IntegrationStatusEnum;
  type: IntegrationTypeEnum;
  schedule: CronSchedule[];
  environment: string[];
  config: {
    format: 'xml'; // Temporary
    feeds?: IntegrationFeedSettings[];
    feedUrl?: string;
  };
}

export interface IntegrationFormInitialValues extends Omit<IntegrationFormValues, 'config'> {
  config: {
    feeds?: IntegrationFeedSettings[];
    feedUrl?: string;
  };
}

export interface IntegrationData {
  data: IntegrationSettings;
}
