import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldProps } from 'react-jsonschema-form';
import SelectTree, { TreeNode, SelectTag } from '../../common/select-tree.component';
import capitalize from 'lodash/capitalize';
import orderBy from 'lodash/orderBy';
import useUser from '../../../use-user';
import User from '../../../../../store/types/user';

enum ContactProperty {
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
}

enum Type {
  USER_ROLE = 'UserRole',
  USER = 'User',
}

const DEFAULT_ROLES = [
  { key:  'sysadmin', title: 'All Sys Admins' },
  { key:  'admin', title: 'All Admins' },
  { key:  'editor', title: 'All Editors' },
  { key:  'viewer', title: 'All View Users' },
];

interface UserContactPickerPropsUiOptions {
  isBordered?: boolean;
  subtitle?: string;
  contactProperty?: ContactProperty;
}

interface FormValue {
  type: Type;
  id: string;
}

interface UserContactPickerProps extends FieldProps<FormValue[]> {
  rawErrors?: string[];
}

const UserContactPicker = (props: UserContactPickerProps) => {
  const {
    rawErrors = [],
    formData,
    onChange,
    schema,
    uiSchema,
    required: isRequired,
    readonly: isReadonly,
    formContext: { organisationId = '' },
  } = props;

  const { isBordered = true, subtitle, contactProperty = ContactProperty.EMAIL } = (uiSchema[
    'ui:options'
  ] || {}) as UserContactPickerPropsUiOptions;

  const helpText = uiSchema['ui:help'] as string;
  const placeholder = uiSchema['ui:placeholder'] as string;
  const isDisabled = uiSchema['ui:disabled'] as boolean;

  const { t } = useTranslation();

  // TO DO: Support default values
  const fallbackValue = isRequired ? undefined : [];

  const { isLoading, isError, data: users } = useUser(organisationId);

  const error = isError ? [t('userContactPicker.errorFetching')] : rawErrors;

  const treeOptions = useMemo(() => {
    if (!users) {
      return [];
    }

    const userTree: { [role: string]: TreeNode[] } = {};

    users.forEach((user) => {
      const title = getTitle(user, contactProperty);

      if (user.isSysadmin && !!user[contactProperty]) {
        const roleId = 'sysadmin';

        if (!userTree[roleId]) {
          userTree[roleId] = [];
        }

        userTree[roleId].push({
          key: user.id,
          title: title,
        });

        return;
      }

      const orgUser = user.organizationsWithRole.find(
        (role) => role.organizationId === organisationId && !!user[contactProperty],
      );

      if (orgUser) {
        const { roleId } = orgUser;

        if (!userTree[roleId]) {
          userTree[roleId] = [];
        }

        userTree[roleId].push({
          key: user.id,
          title: title,
        });
      }
    });

    const userTreeByDefaultRolesOrdered = DEFAULT_ROLES.map<TreeNode>(({ key, title }) => {
      return {
        key,
        title,
        children: userTree[key],
      };
    }).filter((user) => user.children && !!user.children.length);

    const defaultRoles = Object.keys(DEFAULT_ROLES);
    const userTreeByCustomRoles = Object.keys(userTree)
      .filter((key) => !!defaultRoles.includes(key))
      .map<TreeNode>((key) => {
        return {
          key,
          title: capitalize(key),
          children: userTree[key],
        };
      })
      .filter((user) => user.children && !!user.children.length);;

    const userTreeByCustomRolesOrdered = orderBy(userTreeByCustomRoles, ['key'], ['asc']);

    return [...userTreeByDefaultRolesOrdered, ...userTreeByCustomRolesOrdered];
  }, [users, organisationId, contactProperty]);

  const mappedFormData = useMemo<SelectTag[]>(() => {
    if (!formData || !users) {
      return [];
    }

    return formData.map((data) => {
      const matchedUser = users.find((user) => {
        return user.id === data.id
      });

      if (matchedUser && matchedUser.isSysadmin && !!matchedUser[contactProperty]) {
        return {
          key: matchedUser ? matchedUser.id : '',
          label: matchedUser ? getTitle(matchedUser, contactProperty) : '',
          parentKey: 'sysadmin',
        };
      }

      const orgUser = matchedUser ? matchedUser.organizationsWithRole.find(
        (role) => role.organizationId === organisationId && !!matchedUser[contactProperty],
      ) : undefined;

      if (orgUser) {
        const { roleId } = orgUser;

        return {
          key: matchedUser ? matchedUser.id : '',
          label: matchedUser ? getTitle(matchedUser, contactProperty) : '',
          parentKey: roleId,
        };
      }

      const defaultRole = DEFAULT_ROLES.find((role) => role.key === data.id);
      return {
        key: data.id,
        label: defaultRole ? defaultRole.title : data.id,
        parentKey: 'root',
      };
    });
  }, [formData, contactProperty, users, organisationId]);

  const handleChange = useCallback(
    (value: SelectTag[]) => {
      const mappedValue = value.map((tag) => {
        return {
          id: tag.key,
          type: tag.parentKey === 'root' ? Type.USER_ROLE : Type.USER,
        };
      });

      const updatedValue = mappedValue.length ? mappedValue : fallbackValue;

      onChange(updatedValue);
    },
    [onChange, fallbackValue],
  );

  return (
    <SelectTree
      title={schema.title}
      subtitle={subtitle}
      description={schema.description}
      placeholder={placeholder}
      rawErrors={error}
      helpText={helpText}
      isReadonly={isReadonly}
      isDisabled={isDisabled}
      isRequired={isRequired}
      isBordered={isBordered}
      treeOptions={treeOptions}
      onChange={handleChange}
      value={mappedFormData}
      isLoading={isLoading}
    />
  );
};

const getTitle = (user: User, contactProperty: ContactProperty): string => {
  const name = `${user.firstName || ''} ${user.lastName || ''}`.trim();
  const contactValue = user[contactProperty] || '';

  if (contactProperty === ContactProperty.EMAIL) {
    return name ? `${name} <${contactValue}>` : contactValue;
  }

  return name ? `${name} (${contactValue})` : contactValue;
};

export default UserContactPicker;
