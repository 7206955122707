export enum PhyhubDeviceArchitectureEnum {
  ARM_64 = 'arm64',
  ARM_V7 = 'armv7',
  X86_64 = 'x86_64',
}
export enum PhyhubDeviceStatusEnum {
  OFFLINE = 'OFFLINE',
  /**
   * @deprecated Use `ONLINE` instead
   */
  OK = 'OK',
  ONLINE = 'ONLINE',
}

// TODO: Add device type additionally if necessary
export enum PhyhubDeviceTwinTypeEnum {
  DEVICE = 'Device',
  SCREEN = 'Screen',
  EDGE = 'Edge',
  CLOUD = 'Cloud',
  WEB = 'Web',
}

export interface PhyhubDeviceOs {
  arch: PhyhubDeviceArchitectureEnum;
  displayName: string;
}

export interface PhyhubDevice {
  id: string;
  displayName: string | null;
  status: PhyhubDeviceStatusEnum;
  deviceSerial: string | null;
  screenshotUrl: string | null;
  os: PhyhubDeviceOs | null;
  env: string | null;
  // TODO: Clarify logic for installations requesting
  installationIds: string[];
  createdAt: string;
  updatedAt: string;
}

// TODO: Move constants to other directory if necessary

// Maps Phyhub device architecture enum to display name translation key
export const PHYHUB_DEVICE_ARCHITECTURE_NAMES_MAP: Record<
  PhyhubDeviceArchitectureEnum,
  string
> = {
  [PhyhubDeviceArchitectureEnum.ARM_64]: 'phyhubDeviceArchitectureOption.arm64',
  [PhyhubDeviceArchitectureEnum.ARM_V7]: 'phyhubDeviceArchitectureOption.armv7',
  [PhyhubDeviceArchitectureEnum.X86_64]: 'phyhubDeviceArchitectureOption.x86_64',
};

export const PHYHUB_TWIN_DEVICE_TYPE_MAP: Record<PhyhubDeviceTwinTypeEnum, string> = {
  [PhyhubDeviceTwinTypeEnum.DEVICE]: 'phyhubDeviceOption.device',
  [PhyhubDeviceTwinTypeEnum.SCREEN]: 'phyhubDeviceOption.screen',
  [PhyhubDeviceTwinTypeEnum.EDGE]: 'phyhubDeviceOption.edge',
  [PhyhubDeviceTwinTypeEnum.CLOUD]: 'phyhubDeviceOption.cloud',
  [PhyhubDeviceTwinTypeEnum.WEB]: 'phyhubDeviceOption.web',
};
