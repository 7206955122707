import React, { useCallback, useState } from 'react';
import { Button, Col, List, Row, message } from 'antd';
import styled from '@emotion/styled';
import { ListProps } from 'antd/lib/list';
import { useTranslation } from 'react-i18next';
import { FormValidation } from 'react-jsonschema-form';
import CrudListItem from './crud-list-item/crud-list-item.component';
import PanelCard from '../panel-card/panel-card.component';
import Overlay from '../overlay/overlay.component';
import Spinner from '../spinner/spinner.component';
import CrudModal from './crud-modal/crud-modal.component';

interface CrudListProps<T> extends ListProps<T> {
  createButtonText?: string;
  modalTitle?: string;
  onCreate?: (item: Partial<T>) => Promise<void>;
  onEdit?: (item: Partial<T>) => Promise<void>;
  onDelete?: (item: T) => Promise<void>;
  loaded: boolean;
  createSchema: any;
  updateSchema: any;
  metaSchema?: any;
  onFormChange?: (values: Partial<T>, rootFormChange?: (data: T) => void) => void;
  canCreate: boolean;
  canUpdate: boolean;
  canDelete: boolean;
  conditionalCanUpdate?: (item: Partial<T>) => boolean;
  conditionalCanDelete?: (item: Partial<T>) => boolean;
  externalFormChange?: boolean;
  customFormValidation?: (data: Partial<T>, errors: FormValidation) => FormValidation;
  createButtonTestId?: string;
}

const ListActionRow = styled(Row)`
  margin-bottom: 16px;
`;

function CrudList<T>(props: CrudListProps<T>) {
  const {
    createSchema,
    updateSchema,
    metaSchema,
    loaded,
    onCreate,
    onEdit,
    onDelete,
    renderItem,
    createButtonText,
    modalTitle,
    onFormChange,
    canCreate,
    canUpdate,
    canDelete,
    conditionalCanUpdate,
    conditionalCanDelete,
    externalFormChange,
    customFormValidation,
    createButtonTestId,
    ...restProps
  } = props;
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState<T | undefined>(undefined);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const handleEdit = useCallback(
    (item: T) => {
      setSelectedItem(item);
      setIsModalVisible(true);
    },
    [],
  );

  const handleDelete = useCallback(
    // eslint-disable-next-line consistent-return
    async (item: T) => {
      if (onDelete) {
        try {
          await onDelete(item);
          message.success(t('successfullyDeleted'));
        } catch (error) {
          if (error.response && error.response.data && error.response.data.message) {
            message.error(error.response.data.message);
          }
          message.error(t('failedToDeleteItem'));
        }
      }
    },
    [onDelete, t],
  );

  const handleCreate = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setIsModalVisible(false);
    setSelectedItem(undefined);
  }, []);

  const handleModalSubmit = useCallback(
    async (values: Partial<T>) => {
      if (selectedItem && onEdit) {
        await onEdit(values);
      } else if (onCreate) {
        await onCreate(values);
      }
      handleModalClose();
    },
    [onEdit, onCreate, handleModalClose, selectedItem],
  );

  const handleFormChange = useCallback(
    (values: Partial<T>, rootFormChange) => {
      if (onFormChange) {
        if (externalFormChange) {
          onFormChange(values, rootFormChange);
        } else {
          onFormChange(values);
        }
      }
    },
    [onFormChange, externalFormChange],
  );

  const renderCrudItem = useCallback(
    (item: T, index: number) => (
      <CrudListItem
        onEdit={() => handleEdit(item)}
        onDelete={() => handleDelete(item)}
        canUpdate={
          conditionalCanUpdate
            ? conditionalCanUpdate(item) && canUpdate
            : canUpdate && (item as any).editable !== false
        }
        canDelete={
          conditionalCanDelete
            ? conditionalCanDelete(item) && canDelete
            : canDelete && (item as any).deletable !== false
        }
      >
        {renderItem && renderItem(item, index)}
      </CrudListItem>
    ),
    [
      conditionalCanUpdate,
      canUpdate,
      conditionalCanDelete,
      canDelete,
      renderItem,
      handleEdit,
      handleDelete,
    ],
  );

  return (
    <>
      {canCreate && (
        <ListActionRow type="flex" justify="end">
          <Col>
            <Button size="large" icon="plus" onClick={handleCreate} data-testid={createButtonTestId}>
              {createButtonText || t('create')}
            </Button>
          </Col>
        </ListActionRow>
      )}
      {loaded ? (
        <PanelCard>
          <List<any> itemLayout="horizontal" renderItem={renderCrudItem} {...restProps} />
        </PanelCard>
      ) : (
        <Overlay>
          <Spinner />
        </Overlay>
      )}
      <CrudModal
        title={modalTitle}
        schema={selectedItem ? updateSchema : createSchema}
        metaSchema={metaSchema}
        initialValues={selectedItem}
        onSubmit={handleModalSubmit}
        visible={isModalVisible}
        onClose={handleModalClose}
        onChange={handleFormChange}
        externalFormChange={externalFormChange}
        customFormValidation={customFormValidation}
      />
    </>
  );
}

export default CrudList;
