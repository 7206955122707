import React from 'react';
import styled from '@emotion/styled';
import { Icon } from 'antd';
import Device from '../../../store/types/device';
import DeviceStatusEnum from '../../../store/types/device-status.enum';
import UniversalDevice from '../../../store/types/universal-device';
import LegacyWindowsDevice from '../../../store/types/legacy-windows-device';

interface DeviceScreenshotProps {
  device: Device | UniversalDevice | LegacyWindowsDevice;
  className?: string;
}

const backgroundStatusColor: { [status: string]: string } = {
  [DeviceStatusEnum.OK]: 'rgba(245, 246, 250, 0.8)',
  [DeviceStatusEnum.FAILING]: '#fffbe6',
  [DeviceStatusEnum.OFFLINE]: '#fff1f0',
};

const DeviceScreenshotWrapper = styled.div<{ status: DeviceStatusEnum }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: ${({ status }) =>
    backgroundStatusColor[status] || 'rgba(245, 246, 250, 0.8)'};
`;

const DeviceScreenshotImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
`;

const ScreenshotPlaceholder = styled.div`
  min-height: 160px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const ScreenshotPlaceholderIcon = styled(Icon)`
  font-size: 80px;
  opacity: 0.1;
`;

const DeviceScreenshot = ({ device, className }: DeviceScreenshotProps) => {
  return (
    <DeviceScreenshotWrapper className={className} status={device.status}>
      {device.screenshotUrl ? (
        <DeviceScreenshotImage
          alt={`${device.deviceName} status`}
          src={device.screenshotUrl}
        />
      ) : (
        <ScreenshotPlaceholder>
          <ScreenshotPlaceholderIcon type="picture" />
        </ScreenshotPlaceholder>
      )}
    </DeviceScreenshotWrapper>
  );
};

export default DeviceScreenshot;
