import React, { FC, memo, useCallback, useMemo } from 'react';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { PhyhubDevice } from '../../../../store/types/phyhub-devices';
import PhyhubDevicesStatus from '../phyhub-devices-status/phyhub-devices-status';
import PhyhubDevicesListTypeCell from './phyhub-devices-list-type-cell/phyhub-devices-list-type-cell.component';
import { Icon } from '../../schema-form/common';
import PhyhubDevicesSettingsCell from './phyhub-devices-settings-cell/phyhub-devices-settings-cell.component';
import PaginatedListTable from '../../paginated-list/paginatied-list-table';
import { PaginationCollection } from '../../../../store/types/pagination';

interface PhyhubDevicesListProps {
  isDataLoading: boolean;
  paginationCollection: PaginationCollection<PhyhubDevice>;
}

const PhyhubDevicesList: FC<PhyhubDevicesListProps> = (props) => {
  const { isDataLoading, paginationCollection } = props;

  const { t } = useTranslation();

  const handleRowSelectionChange = useCallback((selectedRowKeys: string[] | number[]) => {
    console.log('Row selection changes: ', { selectedRowKeys });
  }, []);

  const columns = useMemo<ColumnProps<PhyhubDevice>[]>(
    (): ColumnProps<PhyhubDevice>[] => [
      {
        key: 'displayName',
        title: t('deviceName'),
        dataIndex: 'displayName',
      },
      {
        key: 'status',
        title: t('status'),
        render: (_, deviceItem) => <PhyhubDevicesStatus status={deviceItem.status} />,
      },
      {
        key: 'type',
        title: t('type'),
        render: (_, deviceItem) => (
          <PhyhubDevicesListTypeCell status={deviceItem.status} os={deviceItem.os} />
        ),
      },
      {
        key: 'serialNumber',
        title: t('serialNumber'),
        dataIndex: 'deviceSerial',
      },
      {
        key: 'env',
        title: t('environment'),
        dataIndex: 'env',
      },
      {
        title: t('tags'),
        key: 'tags',
        render: () => t('notAvailable'),
      },
      {
        title: t('installation'),
        key: 'installation',
        render: () => t('notAvailable'),
      },
      // TODO: Add installation column data
      {
        title: <Icon type="setting" />,
        key: 'settings',
        fixed: 'right',
        align: 'center',
        width: 100,
        render: (_, deviceItem) => (
          <PhyhubDevicesSettingsCell
            deviceId={deviceItem.id}
            deviceScreenshotUrl={deviceItem.screenshotUrl}
          />
        ),
      },
    ],
    [t],
  );

  return (
    <PaginatedListTable<PhyhubDevice>
      data={paginationCollection}
      columns={columns}
      isDataLoading={isDataLoading}
    />
  );
};

export default memo(PhyhubDevicesList);
