import React from 'react';
import groupBy from 'lodash/groupBy';
import toPairs from 'lodash/toPairs';
import sumBy from 'lodash/sumBy';
import orderBy from 'lodash/orderBy';
import { DataResidencyEnum } from '../../../../store/types/organisation';
import {
  TenantQrCodesByDay,
  InstallationQrCodesByDay,
  SpaceQrCodesByDay,
  DeviceQrCodesByDay,
  AppQrCodesByDay,
  useTenantQrCodesByDay,
  useInstallationQrCodesByDay,
  useSpaceQrCodesByDay,
  useDeviceQrCodesByDay,
  useAppQrCodesByDay,
} from '../../use-analytics-report';
import CardContainer from './card-container';
import PieChart from '../charts/pie';
import { GridStyles } from '@ombori/grid-reports';

const title = 'QR Codes';

type RawData =
  | TenantQrCodesByDay
  | InstallationQrCodesByDay
  | SpaceQrCodesByDay
  | DeviceQrCodesByDay
  | AppQrCodesByDay;

type CardDataItem = [string, { label: string; value: number }[]];

type CardData = CardDataItem[];

const buildDataItem = (
  date: string,
  data: { label: string; value: number }[],
): CardDataItem => [date, data];

const maxEventsCount = 7;

const aggregateData = (rawData: RawData[]): { qrCodeType: string; count: number }[] => {
  const aggregatedEvents = orderBy(
    toPairs(groupBy(rawData, (dataItem) => dataItem.qrCodeType)).map(
      ([qrCodeType, events]) => {
        const result = {
          qrCodeType,
          count: sumBy(events, (event) => event.count),
        };

        return result;
      },
    ),
    (dataItem) => dataItem.count,
    'desc',
  );

  return aggregatedEvents;
};

const buildData = (rawData: RawData[]): CardData => {
  const aggregatedEvents = aggregateData(rawData);

  const topEvents = aggregatedEvents.slice(0, maxEventsCount);

  const result = topEvents.map((dataItem) => {
    const list = [{ label: dataItem.qrCodeType, value: dataItem.count }];

    const result = buildDataItem(dataItem.qrCodeType, list);

    return result;
  });

  return result;
};

interface BaseProps {
  tenantId: string;
  dateFrom: string;
  dateTo: string;
  dataResidency: DataResidencyEnum;
  gridStyles?: GridStyles;
  isVisibleWithoutData?: boolean;
}

export const TenantQrCodesEventCount: React.FC<BaseProps> = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const qrCodesEventsFetchingState = useTenantQrCodesByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const data = buildData(qrCodesEventsFetchingState.data || []);

  const totalEventsCount = sumBy(
    qrCodesEventsFetchingState.data || [],
    (event) => event.count,
  );

  return (
    <CardContainer
      isLoading={qrCodesEventsFetchingState.isLoading}
      isSuccess={qrCodesEventsFetchingState.isSuccess}
      isError={qrCodesEventsFetchingState.isError}
      hasData={data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {qrCodesEventsFetchingState.isSuccess && (
        <PieChart title={title} data={data} total={totalEventsCount} />
      )}
    </CardContainer>
  );
};

interface InstallationProps extends BaseProps {
  installationId: string;
}

export const InstallationQrCodesEventCount: React.FC<InstallationProps> = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const qrCodesEventsFetchingState = useInstallationQrCodesByDay({
    tenantId,
    installationId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const data = buildData(qrCodesEventsFetchingState.data || []);

  const totalEventsCount = sumBy(
    qrCodesEventsFetchingState.data || [],
    (event) => event.count,
  );

  return (
    <CardContainer
      isLoading={qrCodesEventsFetchingState.isLoading}
      isSuccess={qrCodesEventsFetchingState.isSuccess}
      isError={qrCodesEventsFetchingState.isError}
      hasData={data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {qrCodesEventsFetchingState.isSuccess && (
        <PieChart title={title} data={data} total={totalEventsCount} />
      )}
    </CardContainer>
  );
};

interface SpaceProps extends BaseProps {
  spaceId: string;
}

export const SpaceQrCodesEventCount: React.FC<SpaceProps> = ({
  tenantId,
  spaceId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const qrCodesEventsFetchingState = useSpaceQrCodesByDay({
    tenantId,
    spaceId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const data = buildData(qrCodesEventsFetchingState.data || []);

  const totalEventsCount = sumBy(
    qrCodesEventsFetchingState.data || [],
    (event) => event.count,
  );

  return (
    <CardContainer
      isLoading={qrCodesEventsFetchingState.isLoading}
      isSuccess={qrCodesEventsFetchingState.isSuccess}
      isError={qrCodesEventsFetchingState.isError}
      hasData={data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {qrCodesEventsFetchingState.isSuccess && (
        <PieChart title={title} data={data} total={totalEventsCount} />
      )}
    </CardContainer>
  );
};

interface DeviceProps extends BaseProps {
  deviceId: string;
}

export const DeviceQrCodesEventCount: React.FC<DeviceProps> = ({
  tenantId,
  deviceId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const qrCodesEventsFetchingState = useDeviceQrCodesByDay({
    tenantId,
    deviceId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const data = buildData(qrCodesEventsFetchingState.data || []);

  const totalEventsCount = sumBy(
    qrCodesEventsFetchingState.data || [],
    (event) => event.count,
  );

  return (
    <CardContainer
      isLoading={qrCodesEventsFetchingState.isLoading}
      isSuccess={qrCodesEventsFetchingState.isSuccess}
      isError={qrCodesEventsFetchingState.isError}
      hasData={data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {qrCodesEventsFetchingState.isSuccess && (
        <PieChart title={title} data={data} total={totalEventsCount} />
      )}
    </CardContainer>
  );
};

interface AppProps extends BaseProps {
  appId: string;
}

export const AppQrCodesEventCount: React.FC<AppProps> = ({
  tenantId,
  appId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const qrCodesEventsFetchingState = useAppQrCodesByDay({
    tenantId,
    appId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const data = buildData(qrCodesEventsFetchingState.data || []);

  const totalEventsCount = sumBy(
    qrCodesEventsFetchingState.data || [],
    (event) => event.count,
  );

  return (
    <CardContainer
      isLoading={qrCodesEventsFetchingState.isLoading}
      isSuccess={qrCodesEventsFetchingState.isSuccess}
      isError={qrCodesEventsFetchingState.isError}
      hasData={data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {qrCodesEventsFetchingState.isSuccess && (
        <PieChart title={title} data={data} total={totalEventsCount} />
      )}
    </CardContainer>
  );
};
