import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import { RootModel, RootState } from '../../../../../store/models/root.model';
import DeviceDetails from './azure-device-details.component';
import DeviceTypeEnum from '../../../../../store/types/device-type.enum';
import { getPermissions } from '../../../../../utils/auth/permissions';
import getAppsByOrganisationName from '../../../../../utils/get-apps-by-organisation-name';

type DeviceDetailsContainerProps = Omit<
  React.ComponentProps<typeof DeviceDetails>,
  | 'fetchDevice'
  | 'device'
  | 'loaded'
  | 'lastUpdated'
  | 'apps'
  | 'isSysAdmin'
  | 'restartApp'
  | 'rebootDevice'
  | 'moveDevice'
  | 'purgeDevice'
>;

const DeviceDetailsContainer = (props: DeviceDetailsContainerProps) => {
  const {
    match: {
      params: { deviceUuid, organisationId },
    },
  } = props;

  const { device, loaded, lastUpdated, apps, permissions, isSysAdmin } = useStore<
    RootState
  >(
    (state) => ({
      device: state.azureDeviceDetails.data[deviceUuid] || null,
      loaded:
        state.azureDeviceDetails.loaded(deviceUuid) && state.organisationApps.loaded,
      lastUpdated: state.azureDeviceDetails.lastUpdated[deviceUuid],
      apps: getAppsByOrganisationName(
        state.organisations.values,
        state.organisationApps.data || {},
        DeviceTypeEnum.BALENA,
      ),
      isSysAdmin: state.auth.isSysAdmin,
      permissions: getPermissions(state.auth.user, organisationId),
    }),
    [deviceUuid, organisationId],
  );

  const { fetchDevice, restartApp, rebootDevice, moveDevice, purgeDevice } = useActions<
    RootModel
  >((actions) => ({
    fetchDevice: actions.azureDeviceDetails.fetch,
    restartApp: actions.azureDeviceDetails.restart,
    rebootDevice: actions.azureDeviceDetails.reboot,
    moveDevice: actions.azureDeviceDetails.move,
    purgeDevice: actions.azureDeviceDetails.purge,
  }));

  return (
    <DeviceDetails
      {...props}
      apps={apps}
      lastUpdated={lastUpdated}
      device={device}
      fetchDevice={fetchDevice}
      loaded={loaded}
      restartApp={restartApp}
      rebootDevice={rebootDevice}
      moveDevice={moveDevice}
      purgeDevice={purgeDevice}
      isSysAdmin={isSysAdmin}
      canManageDeviceSettings={permissions.devices.manageDeviceSettings}
    />
  );
};

export default DeviceDetailsContainer;
