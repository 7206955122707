import { OmboriAppsProviderEnums as ScreenAppPackageNames } from '../../../../utils/installations/get-installation-app-icon-url';

enum ScreenAppNames {
  QUEUE_TICKET_SIGNAGE = 'Queue Ticket Signage',
  QUEUE_KIOSK = 'Queue Kiosk',
  OCCUPANCY_SIGNAGE = 'Occupancy Signage',
  DIGITAL_SIGNAGE = 'Signage Playlist',
  WEB_KIOSK = 'Web Kiosk',
}

const publicApps = [
  {
    id: ScreenAppPackageNames.QUEUE_KIOSK,
    displayName: ScreenAppNames.QUEUE_KIOSK,
    description: 'Modern queue ticketing with digital or paper tickets',
    coverPhoto: 'https://api.omborigrid.com/regions/eu/media/v1/assets/media/5cbac8a388e174147b878cdd/30046a3a-7d50-4659-98da-e987465a5cb8',
    organization: 'ombori',
    author: 'Ombori',
    price: '',
  },
  {
    id: ScreenAppPackageNames.DIGITAL_SIGNAGE,
    displayName: ScreenAppNames.DIGITAL_SIGNAGE,
    description: 'Easy content management and conditional settings',
    coverPhoto: 'https://api.omborigrid.com/regions/eu/media/v1/assets/media/5cbac8a388e174147b878cdd/ec55e8d8-e066-44da-b1c6-c4a8c45a5281',
    organization: 'ombori',
    author: 'Ombori',
    price: '€5.00/month',
  },
  {
    id: ScreenAppPackageNames.OCCUPANCY_CONTROL,
    displayName: ScreenAppNames.OCCUPANCY_SIGNAGE,
    description: 'Limit access to a location while providing a great customer experience',
    coverPhoto: 'https://api.omborigrid.com/regions/eu/media/v1/assets/media/5cbac8a388e174147b878cdd/685f9eb1-734e-4fb2-989c-23f109b00eeb',
    organization: 'ombori',
    author: 'Ombori',
    price: '€89.00',
  },
  {
    id: 'web-kiosk',
    displayName: 'Web Kiosk',
    description: 'Bring online experience into the physical space',
    coverPhoto:
      'https://api.omborigrid.com/regions/eu/media/v1/assets/media/5cbac8a388e174147b878cdd/d58dcc89-1520-4f47-890e-8e232104098e',
    organization: 'ombori',
    author: 'Ombori',
    price: '€79.00',
  },
];

export default publicApps;
