import pick from 'lodash/pick';
import get from 'lodash/get';
import set from 'lodash/set';
import Queue, {
  BookingsModeType,
  OpeningHoursPeriod,
  SpecialHoursPeriod,
  DigitalCallType,
} from '../../../../../store/types/queue';
import defaultSelectedLanguageForTextCustomization from './text-in-app-customization';
import { ValuesProps, WeekdayPeriods, WeekdaySpecialPeriods } from './types';
import getCustomizableTextsValue from './get-customizable-texts-value';

function getTransformedHours(
  queue: Queue,
  hoursName: 'bookingHours' | 'regularHours' | 'curbsidePickupHours',
): WeekdayPeriods {
  const hoursConfiguration: WeekdayPeriods = {};
  if (!queue || !queue[hoursName]) {
    return {};
  }

  const queueTypeSafe = queue as { [key: string]: any };
  queueTypeSafe[hoursName].periods.forEach(
    (period: OpeningHoursPeriod | SpecialHoursPeriod) => {
      const dayKey = period.openDay.toLowerCase();
      if (!hoursConfiguration[dayKey]) {
        hoursConfiguration[dayKey] = {
          enabled: true,
          periods: [],
        };
      }
      hoursConfiguration[dayKey].periods.push({
        from: period.openTime,
        to: period.closeTime,
        capacity: period.capacity == null ? '' : period.capacity.toString(),
      });
    },
  );
  return hoursConfiguration;
}

function getTransformedSpecialHours(
  queue: Queue,
  hoursName: 'specialHours' | 'specialBookingHours',
): WeekdaySpecialPeriods {
  const hoursConfiguration: WeekdaySpecialPeriods = { periods: [] };
  if (!queue || !queue[hoursName]) {
    return { periods: [] };
  }

  const queueTypeSafe = queue as { [key: string]: any };
  if (queueTypeSafe[hoursName] && queueTypeSafe[hoursName].periods) {
    queueTypeSafe[hoursName].periods.forEach((period: SpecialHoursPeriod) => {
      const { date } = period;
      hoursConfiguration.periods.push({
        from: period.openTime,
        to: period.closeTime,
        capacity: period.capacity == null ? '' : period.capacity.toString(),
        date,
      });
    });
  }

  return hoursConfiguration;
}

const defaultBookingDaysCount = 7;

// if you need a new value from queue which will be retrieved from queue without changes - please add type here (and check pick logic below)
type QueueWithoutExtraPropertiesFromServer = Pick<
  Queue,
  | 'id'
  | 'status'
  | 'featureList'
  | 'location'
  | 'title'
  | 'displayTitle'
  | 'pin'
  | 'shortDisplayName'
  | 'open'
  | 'region'
  | 'locale'
  | 'timeZone'
  | 'regularHours'
  | 'bookingHours'
  | 'curbsidePickupHours'
  | 'specialHours'
  | 'logoUrl'
  | 'globalStyles'
  | 'defaultPhoneNumberCountry'
  | 'phoneNumberFrom'
  | 'phoneNumberCountries'
  | 'addressLines'
  | 'leaveReminderNotificationAfterMinutes'
  | 'bookingReminderNotificationBeforeMinutes'
  | 'leaveAfterMinutes'
  | 'allowEntryAboveCapacity'
  | 'webPushEnabled'
  | 'applePassEnabled'
  | 'smsEnabled'
  | 'emailEnabled'
  | 'emailFrom'
  | 'registrationEnabled'
  | 'printingManualTicketsEnabled'
  | 'autoCheckOutByNextEnabled'
  | 'registrationFields'
  | 'phoneNumber'
  | 'curbsideLogoUrl'
  | 'isEmailLogin'
  | 'isInStore'
  | 'emailsAllowed'
  | 'automaticallyClearQueueTime'
  | 'availableTagsForQueue'
  | 'customFields'
  | 'bookingsMode'
  | 'emailsForTeamsCurbsideNotifications'
  | 'stations'
  | 'feedback'
  | 'booking'
  | 'positionLabelPrefixes'
  | 'ticketFlowModifiers'
  | 'externalId'
  | 'isAutoCallEnabled'
  | 'maxOccupancyIncreasingWithSensors'
  | 'isPositionWaitingTimeVisible'
  | 'isNumberInTheQueueVisible'
  | 'removedAt'
  | 'categories'
  | '_etag'
  | 'syncId'
  | 'digitalCall'
  | 'autoCheckOutAfterMinutes'
  | 'isKeepDistanceInfoEnabled'
  | 'isTicketTransferEnabled'
>;

interface InitProps {
  queue: Queue | null;
  regions: string[];
  timeZones: string[];
}

export default function initializeValues(props: InitProps): ValuesProps {
  const { queue, regions, timeZones } = props;

  const defaultValues = {
    region: regions[0],
    timeZone: timeZones[0],
    featureList: [],
    defaultPhoneNumberCountry: 'SE',
    timeslotCapacity: '',
    curbsidePickupArrivalWindowDuration: '',
    maximumStoreCapacity: '',
    storeCapacity: '',
    textInAppCustomization: {},
    timeslotDuration: '',
    phoneNumberCountries: [],
    notifyExtraPendingPositionOffset: '1',
    title: '',
    pin: '',
    curbsidePickupArrivalWindowCapacity: '',
    curbsidePickupHours: {},
    regularHours: {},
    bookingHours: {},
    bookingsMode: BookingsModeType.DEFAULT,
    checkInByQRCode: true,
    selectedLanguageForTextCustomization: defaultSelectedLanguageForTextCustomization,
    emailsForTeamsCurbsideNotifications: [],
    webHooks: {},
    emailsAllowed: [],
    stations: [],
    feedback: {
      isActive: false,
    },
    staffNotifications: {
      isEnabled: false,
      emailList: [],
    },
    booking: {
      physicalBooking: { enabled: true },
      isBookingSmsRequired: false,
      isBookingEmailRequired: false,
    },
    applePassEnabled: true,
    webPushEnabled: true,
    availableTagsForQueue: undefined,
    ticketFlowModifiers: {
      anonymous: {
        cancellation: {
          enabled: true,
        },
        rescheduling: {
          enabled: true,
        },
      },
      booking: {
        cancellation: {
          enabled: true,
        },
        rescheduling: {
          enabled: true,
        },
      },
    },
    isAutoCallEnabled: false,
    registrationFields: [],
    categories: [],
    digitalCall: {
      isEnabled: false,
      queueType: DigitalCallType.DEFAULT,
    },
    isKeepDistanceInfoEnabled: true,
    isTicketTransferEnabled: false,
  };

  if (!queue) {
    return defaultValues;
  }

  const regularHours = getTransformedHours(queue, 'regularHours');
  const specialHours = getTransformedSpecialHours(queue, 'specialHours');
  const bookingHours = getTransformedHours(queue, 'bookingHours');
  const specialBookingHours = getTransformedSpecialHours(queue, 'specialBookingHours');
  const curbsidePickupHours = getTransformedHours(queue, 'curbsidePickupHours');

  // if you need a new value from queue which will be retrieved from queue without changes - please add value by key here
  const queueWithoutIncompatibleProps: QueueWithoutExtraPropertiesFromServer = pick(
    queue,
    [
      'id',
      'status',
      'featureList',
      'location',
      'title',
      'displayTitle',
      'pin',
      'shortDisplayName',
      'organization',
      'open',
      'region',
      'locale',
      'timeZone',
      'regularHours',
      'bookingHours',
      'curbsidePickupHours',
      'specialHours',
      'logoUrl',
      'globalStyles',
      'defaultPhoneNumberCountry',
      'phoneNumberFrom',
      'phoneNumberCountries',
      'addressLines',
      'leaveReminderNotificationAfterMinutes',
      'bookingReminderNotificationBeforeMinutes',
      'leaveAfterMinutes',
      'allowEntryAboveCapacity',
      'webPushEnabled',
      'applePassEnabled',
      'smsEnabled',
      'externalId',
      'emailEnabled',
      'emailFrom',
      'registrationEnabled',
      'printingManualTicketsEnabled',
      'autoCheckOutByNextEnabled',
      'registrationFields',
      'phoneNumber',
      'curbsideLogoUrl',
      'isEmailLogin',
      'isInStore',
      'emailsAllowed',
      'textInAppCustomization',
      'automaticallyClearQueueTime',
      'availableTagsForQueue',
      'customFields',
      'bookingsMode',
      'checkInByQRCode',
      'emailsForTeamsCurbsideNotifications',
      'webHooks',
      'numberOfAvailableBookingsPerDay',
      'stations',
      'feedback',
      'staffNotifications',
      'maxNumberOfPeoplePerWalkInTicket',
      'maxNumberOfPeoplePerBookingTicket',
      'booking',
      'positionLabelPrefixes',
      'ticketFlowModifiers',
      'isAutoCallEnabled',
      'maxOccupancyIncreasingWithSensors',
      'isPositionWaitingTimeVisible',
      'isNumberInTheQueueVisible',
      'removedAt',
      'categories',
      'autoCheckOutAfterMinutes',
      '_etag',
      'syncId',
      'isKeepDistanceInfoEnabled',
      'gridAppProperties',
      'isTicketTransferEnabled',
    ],
  );

  const initValues: ValuesProps = {
    ...defaultValues,
    ...queueWithoutIncompatibleProps,
    regularHours,
    bookingHours,
    curbsidePickupHours,
    specialHours,
    specialBookingHours,
    // queue specific things which have another type. Most likely number \ string or more complex structures
    // below list of number \ string
    phoneNumberCountries:
      queue && queue.phoneNumberCountries ? queue.phoneNumberCountries : [],
    timeslotCapacity: queue.timeslotCapacity ? queue.timeslotCapacity.toString() : '',
    maximumStoreCapacity: queue.maximumStoreCapacity
      ? queue.maximumStoreCapacity.toString()
      : '',
    storeCapacity: queue.storeCapacity ? queue.storeCapacity.toString() : '',
    notifyExtraPendingPositionOffset: queue.notifyExtraPendingPositionOffset
      ? queue.notifyExtraPendingPositionOffset.toString()
      : '1',
    curbsidePickupArrivalWindowCapacity: queue.curbsidePickupArrivalWindowCapacity
      ? queue.curbsidePickupArrivalWindowCapacity.toString()
      : '',
    curbsidePickupArrivalWindowDuration: queue.curbsidePickupArrivalWindowDuration
      ? queue.curbsidePickupArrivalWindowDuration.toString()
      : '',
    leaveAfterMinutes: queue.leaveAfterMinutes ? queue.leaveAfterMinutes.toString() : '',
    leaveReminderNotificationAfterMinutes: queue.leaveReminderNotificationAfterMinutes
      ? queue.leaveReminderNotificationAfterMinutes.toString()
      : '',
    bookingReminderNotificationBeforeMinutes: queue.bookingReminderNotificationBeforeMinutes
      ? queue.bookingReminderNotificationBeforeMinutes.toString()
      : '',
    timeslotDuration: '',
    textInAppCustomization: {},
    automaticallyClearQueueTime: queue.automaticallyClearQueueTime
      ? queue.automaticallyClearQueueTime
      : [],
    checkInByQRCode: queue.checkInByQRCode == null ? false : queue.checkInByQRCode,
    selectedLanguageForTextCustomization: defaultSelectedLanguageForTextCustomization,
    applePassEnabled: queue.applePassEnabled == null ? true : queue.applePassEnabled,
    positionLabelPrefixes: {
      anonymous: get(queue, 'positionLabelPrefixes.anonymous', undefined),
      booking: get(queue, 'positionLabelPrefixes.booking', undefined),
      manual: get(queue, 'positionLabelPrefixes.manual', undefined),
    },
    autoCheckOutAfterMinutes: get(queue, 'autoCheckOutAfterMinutes', null),
    ticketFlowModifiers: {
      anonymous: {
        cancellation: {
          enabled: get(queue, 'ticketFlowModifiers.anonymous.cancellation.enabled', true),
        },
        rescheduling: {
          enabled: get(
            queue,
            'ticketFlowModifiers.anonymous.rescheduling.enabled',
            false,
          ),
        },
      },
      booking: {
        cancellation: {
          enabled: get(queue, 'ticketFlowModifiers.booking.cancellation.enabled', true),
        },
        rescheduling: {
          enabled: get(queue, 'ticketFlowModifiers.booking.rescheduling.enabled', true),
        },
      },
    },
    booking: {
      ...queue.booking,
      physicalBooking: { enabled: get(queue, 'booking.physicalBooking.enabled', true) },
      blockedDaysAhead: get(queue, 'booking.blockedDaysAhead', null),
      bookingDaysCount: get(queue, 'booking.bookingDaysCount', defaultBookingDaysCount),
    },
    digitalCall: {
      ...queue.digitalCall,
      isEnabled: (queue.digitalCall && queue.digitalCall.isEnabled) || false,
      routingTimeout:
        queue.digitalCall && queue.digitalCall.routingTimeout
          ? queue.digitalCall.routingTimeout.toString()
          : undefined,
      queueType:
        queue.digitalCall && queue.digitalCall.queueType
          ? queue.digitalCall.queueType
          : DigitalCallType.DEFAULT,
    },
    // if you need to add more complex, please specify default value here
  };

  // more complex construction
  if (queue.bookingHours) {
    initValues.bookingHours = bookingHours;
    initValues.seperateBookingHours = true;
  }

  if (queue.timeslotDuration) {
    initValues.timeslotDuration = (queue.timeslotDuration / 60000).toString(); // to minutes
  }

  if (queue.curbsidePickupArrivalWindowDuration) {
    initValues.curbsidePickupArrivalWindowDuration = (
      queue.curbsidePickupArrivalWindowDuration / 60000
    ).toString(); // to minutes
  }

  if (queue.curbsidePickupHours) {
    initValues.curbsidePickupHours = curbsidePickupHours;
    initValues.seperateCurbsidePickupHours = true;
  }

  // ---------- verifies keys and add features if necessary --------
  if (!initValues.featureList || !initValues.featureList.length) {
    const keysOccupancyFeature = ['storeCapacity', 'allowEntryAboveCapacity'];
    const isOccupancyFeature = !keysOccupancyFeature.every((k) => !(k in queue));
    if (isOccupancyFeature && initValues.featureList) {
      initValues.featureList.push('OCCUPANCY');
    }

    const keysQueueFeature = [
      'webPushEnabled',
      'smsEnabled',
      'emailEnabled',
      'emailFrom',
      'registrationEnabled',
      'printingManualTicketsEnabled',
      'autoCheckOutByNextEnabled',
      'registrationFields',
      'notifyExtraPendingPositionOffset',
      'shortDisplayName',
      'defaultPhoneNumberCountry',
      'phoneNumberFrom',
      'leaveAfterMinutes',
      'leaveReminderNotificationAfterMinutes',
      'phoneNumberCountries',
    ];
    const isQueueFeature = !keysQueueFeature.every((k) => !(k in queue));
    // for backward compatibility
    if (isQueueFeature && initValues.featureList) {
      initValues.featureList.push('QUEUE');
    }

    if (!initValues.smsEnabled) {
      const keysSmsEnabled = [
        'shortDisplayName',
        'leaveAfterMinutes',
        'leaveReminderNotificationAfterMinutes',
        'phoneNumberCountries',
      ];
      initValues.smsEnabled = !keysSmsEnabled.every((k) => !(k in queue));
    }

    const keysBookingFeature = [
      'timeslotDuration',
      'timeslotCapacity',
      'bookingHours',
      'bookingReminderNotificationBeforeMinutes',
    ];
    const isBookingFeature = !keysBookingFeature.every((k) => !(k in queue));
    if (isBookingFeature && initValues.featureList) {
      initValues.featureList.push('BOOKINGS');
    }

    const keysCurbsidePickupFeature = [
      'curbsidePickupArrivalWindowDuration',
      'curbsidePickupArrivalWindowCapacity',
      'curbsideLogoUrl',
      'curbsidePickupHours',
    ];
    const isCurbsidePickupFeature = !keysCurbsidePickupFeature.every(
      (k) => !(k in queue),
    );
    if (isCurbsidePickupFeature && initValues.featureList) {
      initValues.featureList.push('CURBSIDEPICKUP');
    }
  }
  // ---------- end: verifies keys and add features if necessary --------

  // ----------- Text customization init values --------
  const textInAppCustomization = get(queue, 'textInAppCustomization', {});

  set(
    initValues,
    'textInAppCustomization',
    getCustomizableTextsValue(textInAppCustomization),
  );
  // ----------- end Text customization  init values --------

  if (get(queue, 'booking.isBookingSmsRequired') === null && !!initValues.smsEnabled) {
    set(initValues, 'booking.isBookingSmsRequired', initValues.smsEnabled);
  }

  return initValues;
}
