import { useMutation, UseMutationResult } from 'react-query';
import { ApiError } from '../../../../../services/api/api-error';
import { useGridProductsService } from '../../../../common/grid-products-service-provider';
import { DataResidencyEnum } from '../../../../../store/types/organisation';

interface DeleteErrorResponse {
  data: { errorMessage: string }[];
}

interface UseDeleteGridProductProps {
  tenantId: string;
  dataResidency: DataResidencyEnum;
  environment: string;
}

type UseDeleteGridProductResponse = UseMutationResult<
  DeleteErrorResponse,
  ApiError,
  string
>;

const useDeleteGridProduct = (
  props: UseDeleteGridProductProps,
): UseDeleteGridProductResponse => {
  const { tenantId, dataResidency, environment } = props;

  const gridProductsService = useGridProductsService();

  const result = useMutation(
    async (productGroupId: string) => {
      const url = `/${tenantId}/${environment}/admin/products/${productGroupId}`;

      return gridProductsService.delete<DeleteErrorResponse>(url, dataResidency);
    },
    {
      onError: (_error: ApiError) => {},
    },
  );

  return result;
};

export default useDeleteGridProduct;
