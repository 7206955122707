import { Field } from 'react-final-form';
import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import toUpper from 'lodash/toUpper';
import {
  FormTextInput,
  InputCheckbox,
  Label,
  MainSectionTitle,
  PanelBody,
  PanelCardMain,
  RowStyled,
  Select,
} from '../styled-block';
import Queue, { BookingsModeType } from '../../../../../../store/types/queue';
import WeekdayHourPeriods from '../weekday-hour-periods/weekday-hour-periods.component';
import CustomInfoFields from '../custom-info-fields';
import SpecialHoursPeriods from '../special-hours-periods';
import RegistrationFieldsBuilder from '../registration-fields-builder';
import { castToNumber } from '../utils';

const { Title } = Typography;

const castToUpper = (value: string): string => value && toUpper(value);

interface BookingsProps {
  values: any;
  queue: Queue | null;
  change: (name: string, value: any) => void;
}

const Bookings: React.FC<BookingsProps> = ({ values, queue, change }) => {
  const { t } = useTranslation();

  return (
    <PanelCardMain bodyStyle={{ padding: 0 }}>
      <MainSectionTitle>
        <Title level={2}>{t('booking')}</Title>
      </MainSectionTitle>
      <PanelBody>
        {values.registrationEnabled && (
          <Field name="booking.isQueueRegistrationFormUsed" type="checkbox">
            {({ input }) => (
              <RowStyled>
                <Label>
                  <InputCheckbox {...input} type="checkbox" />
                  {t('useQueueRegistrationFields')}
                </Label>
              </RowStyled>
            )}
          </Field>
        )}

        {!values.booking.isQueueRegistrationFormUsed && (
          <RegistrationFieldsBuilder
            fieldName="booking.registrationFields"
            activeLanguage={values.selectedLanguageForTextCustomization}
            registrationFields={get(queue, 'booking.registrationFields', [])}
            formRegistrationFields={get(values, 'booking.registrationFields', [])}
            categories={get(values, 'categories', [])}
            change={change}
          />
        )}

        <Field name="ticketFlowModifiers.booking.cancellation.enabled" type="checkbox">
          {({ input }) => (
            <RowStyled>
              <Label>
                <InputCheckbox {...input} type="checkbox" />
                {t('ticketCancellationIsAbleCancelBookingTitle')}
              </Label>
            </RowStyled>
          )}
        </Field>
        <Field name="ticketFlowModifiers.booking.rescheduling.enabled" type="checkbox">
          {({ input }) => (
            <RowStyled>
              <Label>
                <InputCheckbox {...input} type="checkbox" />
                {t('ticketReschedulingIsBookingReschedulingAvailable')}
              </Label>
            </RowStyled>
          )}
        </Field>
        <Field name="booking.blockedDaysAhead" parse={castToNumber}>
          {({ input, meta }) => (
            <FormTextInput
              input={input}
              meta={meta}
              label={t('blockedDaysAhead')}
              type="number"
              min="0"
            />
          )}
        </Field>
        <Field name="booking.bookingDaysCount" parse={castToNumber}>
          {({ input, meta }) => (
            <FormTextInput
              input={input}
              meta={meta}
              label={t('bookingDaysCount')}
              type="number"
              min="0"
            />
          )}
        </Field>
        <Field name="timeslotDuration">
          {({ input, meta }) => (
            <FormTextInput
              input={input}
              meta={meta}
              label={t('timeslotDuration')}
              required
            />
          )}
        </Field>
        <Field name="timeslotCapacity">
          {({ input, meta }) => (
            <FormTextInput
              input={input}
              meta={meta}
              label={t('timeslotCapacity')}
              required
            />
          )}
        </Field>
        <Field name="maxNumberOfPeoplePerBookingTicket">
          {({ input, meta }) => (
            <FormTextInput
              input={input}
              meta={meta}
              label={t('maxNumberOfPeoplePerBookingTicket')}
              required
            />
          )}
        </Field>
        <Field name="bookingReminderNotificationBeforeMinutes">
          {({ input, meta }) => (
            <FormTextInput
              input={input}
              meta={meta}
              label={t('bookingReminderNotificationBeforeMinutes')}
            />
          )}
        </Field>
        <Field name="bookingsMode">
          {({ input }) => (
            <RowStyled>
              <Label>{t('bookingsMode')}</Label>
              <Select {...input}>
                <option value={BookingsModeType.DEFAULT} key={BookingsModeType.DEFAULT}>
                  {t('defaultBookingMode')}
                </option>
                <option
                  value={BookingsModeType.AUTO_CALL}
                  key={BookingsModeType.AUTO_CALL}
                >
                  Auto call ticket forward
                </option>
                <option
                  value={BookingsModeType.AUTO_CHECKIN}
                  key={BookingsModeType.AUTO_CHECKIN}
                >
                  Auto check-in ticket
                </option>
              </Select>
            </RowStyled>
          )}
        </Field>
        <RowStyled>
          <Field name="addressLines[0]">
            {({ input, meta }) => (
              <FormTextInput input={input} meta={meta} label={t('addressLines')} />
            )}
          </Field>
          <Field name="addressLines[1]">
            {({ input, meta }) => <FormTextInput input={input} meta={meta} />}
          </Field>
        </RowStyled>
        <Field name="numberOfAvailableBookingsPerDay">
          {({ input, meta }) => (
            <FormTextInput
              input={input}
              meta={meta}
              label={t('queueBookingNumberOfAvailableBookingsPerDay')}
              description={t('queueBookingNumberOfAvailableBookingsPerDayDescription')}
            />
          )}
        </Field>
        <Field name="positionLabelPrefixes.booking" parse={castToUpper}>
          {({ input, meta }) => (
            <FormTextInput
              input={input}
              meta={meta}
              maxLength={3}
              label={t('bookingTicketLabel')}
            />
          )}
        </Field>
        <CustomInfoFields
          translationKey="сustomInformationForBookingTicket"
          nameProperty="positionBooking"
        />
        <Label>{t('appointmentTypes')}</Label>
        <PanelCardMain>
          <Field name="booking.physicalBooking.enabled" type="checkbox">
            {({ input }) => (
              <RowStyled>
                <Label>
                  <InputCheckbox {...input} type="checkbox" />
                  {t('physicalBooking')}
                </Label>
              </RowStyled>
            )}
          </Field>
          <Field name="booking.digitalBookingWithMsTeams.enabled" type="checkbox">
            {({ input }) => (
              <RowStyled>
                <Label>
                  <InputCheckbox {...input} type="checkbox" />
                  {t('digitalBookingWithMsTeams')}
                </Label>
              </RowStyled>
            )}
          </Field>
        </PanelCardMain>
        <Field name="seperateBookingHours" type="checkbox">
          {({ input }) => (
            <RowStyled>
              <Label>
                <InputCheckbox {...input} type="checkbox" />
                {t('separateBookingHours')}
              </Label>
            </RowStyled>
          )}
        </Field>
        {values && values.seperateBookingHours && (
          <WeekdayHourPeriods
            name="bookingHours"
            label={t('bookingHours')}
            change={change}
            isCapacityAdjustable
            minuteStep={values.timeslotDuration}
            midnightSlotIsAvailable
          />
        )}
        <SpecialHoursPeriods
          name="specialBookingHours"
          label={t('specialBookingHours')}
          change={change}
          isCapacityAdjustable
          defaultCapacity={values.timeslotCapacity}
          minuteStep={values.timeslotDuration}
          midnightSlotIsAvailable
        />
      </PanelBody>
    </PanelCardMain>
  );
};

export default Bookings;
