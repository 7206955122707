import React, { useCallback, useMemo, useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import { Global, css } from '@emotion/core';
import { Popover } from 'antd';
import tinycolor from 'tinycolor2';
import { getHexStringFromRGB } from './util';

const DEFAULT_COLOR = '#000000';

interface SketchColorPickerProps {
  initialColor?: string;
  onChange?: (color: ColorResult) => void;
  onChangeComplete?: (color: ColorResult) => void;
  children?: React.ReactNode | ((colorResult: ColorResult) => JSX.Element);
  isEnabled?: boolean;
}

const SketchColorPicker = (props: SketchColorPickerProps) => {
  const {
    initialColor = DEFAULT_COLOR,
    onChange = () => {},
    onChangeComplete = () => {},
    children,
    isEnabled = true,
  } = props;

  const [initColor] = useState<string>(initialColor);

  const result = useMemo(() => {
    const color = tinycolor(initColor);
    const hex = getHexStringFromRGB(color.toRgb());

    return {
        hex,
        rgb: color.toRgb(),
        hsl: color.toHsl(),
    };
  }, [initColor]);

  const [colorResult, setColorResult] = useState<ColorResult>(result);

  const handleChange = useCallback(
    (color: ColorResult) => {
      if (!isEnabled) {
        return;
      }

      color.hex = getHexStringFromRGB(color.rgb);

      setColorResult(color);
      onChange(color);
    },
    [isEnabled, onChange],
  );

  const handleChangeComplete = useCallback(
    (color: ColorResult) => {
      if (!isEnabled) {
        return;
      }

      color.hex = getHexStringFromRGB(color.rgb);

      setColorResult(color);
      onChangeComplete(color);
    },
    [isEnabled, onChangeComplete],
  );

  return (
    <>
      {/* Popover is rendered outside root. We need Global to customize its styling */}
      <Global
        styles={css`
          .color-picker-popover {
            .ant-popover-inner-content {
              all: unset;
            }
          }
        `}
      />
      <Popover
        trigger="click"
        overlayClassName="color-picker-popover"
        content={
          <SketchPicker
            color={colorResult.rgb}
            onChange={handleChange}
            onChangeComplete={handleChangeComplete}
          />
        }
      >
        {typeof children === 'function' ? children(colorResult) : children}
      </Popover>
    </>
  );
};

export default SketchColorPicker;
