import React, { useCallback, useEffect, useState, useMemo } from 'react';
import styled from '@emotion/styled';
import { keyBy } from 'lodash';
import { Icon, Typography, Row, Col, Button, List } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Overlay from '../../../../common/overlay/overlay.component';
import Spinner from '../../../../common/spinner/spinner.component';
import CreateMobileEndpointModal from './create-mobile-endpoint-modal/create-mobile-endpoint-modal.component';
import Environment from '../../../../../store/types/environment';
import Space from '../../../../../store/types/organisation-space';
import MobileEndpoint from '../../../../../store/types/mobile-endpoint';
import PanelCard from '../../../../common/panel-card/panel-card.component';
import MobileEndpointsListItem from '../../../../common/mobile-endpoints/mobile-endpoints-list-item/mobile-endpoints-list-item.component';
import usePermissions from '../../../../../utils/auth/use-permissions';
import { permissionKeys } from '../../../../../utils/auth/permissions';
import ContentWrap from '../../../../common/app-layout/content/content-wrap.component';

const { Title } = Typography;

interface MobileEndpointsProps
  extends RouteComponentProps<{ appId: string; organisationId: string }> {
  mobileEndpoints: MobileEndpoint[];
  spaces: Space[];
  environments: Environment[];
  loaded: boolean;
  fetchSpaces: (params: { organizationId: string; silent?: boolean }) => void;
  fetchEnvironments: (params: { organizationId: string }) => void;
  fetchMobileEndpoints: (params: { appId: string }) => void;
  createMobileEndpoint: (mobileEndpoint: Partial<MobileEndpoint>) => Promise<void>;
  updateMobileEndpoint: (mobileEndpoint: Partial<MobileEndpoint>) => Promise<void>;
  deleteMobileEndpoint: (id: string) => Promise<void>;
}

const FiltersRow = styled(Row)`
  padding: 16px 0px;
`;

const getMobileEndpointData = (mobileEndpoint: Partial<MobileEndpoint>) => ({
  appId: mobileEndpoint.appId,
  urlId: mobileEndpoint.urlId,
  organizationId: mobileEndpoint.organizationId,
  displayName: mobileEndpoint.displayName,
  env: mobileEndpoint.env,
  notes: mobileEndpoint.notes || '',
  spaces: mobileEndpoint.spaces || [],
});

const BrowsersComponent = (props: MobileEndpointsProps) => {
  const {
    loaded,
    mobileEndpoints,
    environments,
    spaces,
    fetchSpaces,
    fetchEnvironments,
    fetchMobileEndpoints,
    createMobileEndpoint,
    updateMobileEndpoint,
    deleteMobileEndpoint,
    match: {
      params: { appId, organisationId },
    },
  } = props;
  const { t } = useTranslation();
  const { isAllowed } = usePermissions(organisationId);
  const [selectedItem, setSelectedItem] = useState<MobileEndpoint | undefined>(undefined);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState<boolean>(false);

  const handleCreateClick = useCallback(() => {
    setIsCreateModalVisible(true);
  }, [setIsCreateModalVisible]);

  const handleCreateModalClose = useCallback(() => {
    setIsCreateModalVisible(false);
    setSelectedItem(undefined);
  }, [setIsCreateModalVisible]);

  const handleModalSubmit = useCallback(
    async (values: Partial<MobileEndpoint>) => {
      if (appId) {
        const data = {
          ...getMobileEndpointData({
            ...values,
            appId,
            organizationId: organisationId,
          }),
        };

        if (selectedItem) {
          await updateMobileEndpoint({
            ...getMobileEndpointData(data),
            id: selectedItem.id,
          });
        } else {
          await createMobileEndpoint(data);
        }

        handleCreateModalClose();
      }
    },
    [
      selectedItem,
      handleCreateModalClose,
      createMobileEndpoint,
      appId,
      organisationId,
      updateMobileEndpoint,
    ],
  );

  const handleMobileEndpointDelete = useCallback(
    async (mobileEndpointId: string) => {
      await deleteMobileEndpoint(mobileEndpointId);
    },
    [deleteMobileEndpoint],
  );

  const handleEditClick = useCallback(
    (mobileEndpointId: string) => {
      setSelectedItem(mobileEndpoints.find((mE) => mE.id === mobileEndpointId));
      setIsCreateModalVisible(true);
    },
    [setSelectedItem, setIsCreateModalVisible, mobileEndpoints],
  );

  useEffect(() => {
    fetchMobileEndpoints({ appId });
  }, [fetchMobileEndpoints, appId]);

  useEffect(() => {
    fetchEnvironments({ organizationId: organisationId });
    fetchSpaces({ organizationId: organisationId, silent: true });
  }, [fetchEnvironments, fetchSpaces, organisationId]);

  const keyedSpaces = useMemo(() => keyBy(spaces, (s) => s.id), [spaces]);

  if (!appId) return null;

  return (
    <ContentWrap>
      {!loaded && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}
      {loaded && !mobileEndpoints.length && (
        <Overlay>
          <Title level={4}>
            {t('noMobileEndpointFound')} <Icon type="frown" />
          </Title>
        </Overlay>
      )}
      {isAllowed(permissionKeys.mobileEndpoints.create) && (
        <FiltersRow type="flex" justify="end">
          <Col>
            <Button size="large" icon="plus" onClick={handleCreateClick}>
              {t('createMobileEndpoint')}
            </Button>
          </Col>
        </FiltersRow>
      )}

      {loaded && !!mobileEndpoints.length && (
        <PanelCard>
          <List
            itemLayout="horizontal"
            dataSource={mobileEndpoints}
            renderItem={(mobileEndpoint: MobileEndpoint) => (
              <MobileEndpointsListItem
                key={mobileEndpoint.id}
                spaces={keyedSpaces}
                mobileEndpoint={mobileEndpoint}
                environments={environments}
                onDelete={handleMobileEndpointDelete}
                onEdit={handleEditClick}
                canDelete={isAllowed(permissionKeys.mobileEndpoints.remove)}
                canEdit={false} // Disable editing for now
              />
            )}
          />
        </PanelCard>
      )}
      <CreateMobileEndpointModal
        visible={isCreateModalVisible}
        organizationId={organisationId}
        mobileEndpoint={selectedItem || { appId, organizationId: organisationId }}
        environments={environments}
        spaces={spaces}
        fetchSpaces={fetchSpaces}
        fetchEnvironments={fetchEnvironments}
        onClose={handleCreateModalClose}
        onSubmit={handleModalSubmit}
      />
    </ContentWrap>
  );
};

export default BrowsersComponent;
