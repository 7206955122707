import React, { useMemo } from 'react';
import { useActions, useStore } from 'easy-peasy';
import { RootModel, RootState } from '../../../../../../store/models/root.model';
import PaymentMethod from './payment-method.component';
import Organisation from '../../../../../../store/types/organisation';
import { Subscription } from '../../../../../../store/types/subscription';

interface PaymentMethodContainerProps {
  organizationId: string;
}

const PaymentMethodContainer = (props: PaymentMethodContainerProps) => {
  const { organizationId } = props;

  const {
    organizations,
    subscription,
  }: {
    organizations: { [id: string]: Organisation };
    subscription: Subscription;
  } = useStore<RootState>(
    (state) => {
      return {
        organizations: state.organisations.data,
        subscription: state.subscriptions.data.subscription,
      };
    },
    [organizationId],
  );

  const { billingType } = useMemo(() => {
    return organizations[organizationId];
  }, [organizations, organizationId]);

  const { cancelSubscription, fetchSubscription } = useActions<RootModel>((actions) => ({
    fetchSubscription: actions.subscriptions.fetchSubscription,
    cancelSubscription: actions.subscriptions.cancelSubscription,
  }));

  return (
    <PaymentMethod
      billingType={billingType}
      subscription={subscription}
      organizationId={organizationId}
      onCancelSubscription={cancelSubscription}
      onFetchSubscription={fetchSubscription}
    />
  );
};

export default PaymentMethodContainer;
