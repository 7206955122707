import MobileEndpoint from '../../types/mobile-endpoint';
import createKeyedCrudModel, {
  KeyedCrudModel,
} from '../../common/keyed-crud-model/keyed-crud.model';

export type OrganisationMobileEndpointsModel = KeyedCrudModel<
  MobileEndpoint,
  { organizationId: string }
>;

const organisationMobileEndpointsModel: OrganisationMobileEndpointsModel = createKeyedCrudModel<
  MobileEndpoint,
  { organizationId: string }
>('/api/mobile-endpoints', 'organizationId');

export default organisationMobileEndpointsModel;
