import React, { useCallback, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FileText } from 'react-feather';
import styled from '@emotion/styled';
import { Button, Tooltip } from 'antd';
import { ButtonType } from '../../../../types/styled-component';
import ReleaseNotesModal, { ReleaseNotesModalRef } from '../../../common/release-notes-modal';
import { Icon } from '../../../common/schema-form/common';
import UpdateButtonModal from './update-button-modal.component';
import DeletePopconfirm from './delete-popconfirm.component';
import { StageEnum } from '../../../common/use-stage';

interface ActionButtons {
  deviceBundleId: string;
  data: {
    stage: StageEnum;
    releaseNotes?: string;
  };
}

const ActionButtons = (props: ActionButtons) => {
  const { deviceBundleId, data } = props;
  const { stage, releaseNotes = '' } = data;

  const [isDeleting, setIsDeleting] = useState<boolean>();

  const { t } = useTranslation();

  const refReleaseNotesModal = useRef<ReleaseNotesModalRef>(null);

  const handleOpenReleaseNotesModal = useCallback((releaseNotes?: string) => {
    if (refReleaseNotesModal.current) {
      refReleaseNotesModal.current.open(releaseNotes);
    }
  }, []);

  const handleDelete = useCallback((status: boolean) => setIsDeleting(status), []);

  return (
    <>
      <ActionContainer>
        <Tooltip title={t('releaseNotes.title')}>
          <ActionButton
            size="small"
            type="link"
            onClick={() => handleOpenReleaseNotesModal(releaseNotes)}
            disabled={isDeleting}
          >
            <Icon component={() => <FileText />} />
          </ActionButton>
        </Tooltip>
        <UpdateButtonModal
          deviceBundleId={deviceBundleId}
          stage={stage}
          releaseNotes={releaseNotes}
          isEnabled={!isDeleting}
        />
        <DeletePopconfirm deviceBundleId={deviceBundleId} onDelete={handleDelete}/>
      </ActionContainer>
      <ReleaseNotesModal ref={refReleaseNotesModal} />
    </>
  );
};

const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  color: #689bc4;
`;

const ActionButton = styled(Button)`
  height: fit-content;
` as ButtonType;

export default ActionButtons;
