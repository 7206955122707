import axios, { AxiosError, AxiosInstance } from 'axios';
import { ApiError } from '../api-error';
import { DataResidencyEnum } from '../../../store/types/organisation';

class GridProducts {
  private readonly axiosInstances: {
    [DataResidencyEnum.DEV]: AxiosInstance;
    [DataResidencyEnum.QA]: AxiosInstance;
    [DataResidencyEnum.EU]: AxiosInstance;
    [DataResidencyEnum.US]: AxiosInstance;
    [DataResidencyEnum.IN]: AxiosInstance;
    [DataResidencyEnum.UAE]: AxiosInstance;
    [DataResidencyEnum.AU]: AxiosInstance;
    [key: string]: AxiosInstance;
  };

  public constructor() {
    const axiosInstances: any = {};
    const buildBaseUrl = (baseUrl: string, env: string) => {
      if (env === DataResidencyEnum.LOCAL) {
        return `${baseUrl}/api/tenants`;
      }
      if (env === DataResidencyEnum.DEV) {
        return `https://api.griddeveloper.com/regions/dev/products/v1/tenants`;
      }
      if (env === DataResidencyEnum.QA) {
        return `https://api-qa.omborigrid.com/regions/qa/products/v1/tenants`;
      }
      return `https://api.omborigrid.com/regions/${env.toLowerCase()}/products/v1/tenants`;
    };

    axiosInstances[DataResidencyEnum.DEV] = axios.create({
      baseURL: buildBaseUrl(
        process.env.REACT_APP_GRID_PRODUCTS_BASE_LOCAL_API_URL as string,
        DataResidencyEnum.DEV,
      ),
    });
    axiosInstances[DataResidencyEnum.QA] = axios.create({
      baseURL: buildBaseUrl(
        process.env.REACT_APP_GRID_PRODUCTS_BASE_API_URL as string,
        DataResidencyEnum.QA,
      ),
    });
    [
      DataResidencyEnum.EU,
      DataResidencyEnum.US,
      DataResidencyEnum.IN,
      DataResidencyEnum.UAE,
      DataResidencyEnum.AU,
    ].forEach((dataResidencyEnum) => {
      const baseURL = buildBaseUrl(
        process.env.REACT_APP_GRID_PRODUCTS_BASE_API_URL as string,
        dataResidencyEnum,
      );
      axiosInstances[dataResidencyEnum] = axios.create({
        baseURL,
      });
    });
    this.axiosInstances = axiosInstances;
  }

  public setInterceptors = () => {
    Object.keys(this.axiosInstances).forEach((key) => {
      this.axiosInstances[key].interceptors.response.use(
        (response) => {
          return response;
        },
        (err) => {
          if (
            err.response.status === 401 &&
            !['/', '/login'].includes(document.location.pathname)
          ) {
            document.location.href = `/login?returnPath=${encodeURIComponent(
              `${document.location.pathname}${document.location.search}`,
            )}`;
          }
          return Promise.reject(err);
        },
      );
    });
  };

  public async get<T>(
    path: string,
    dataResidencyEnum: DataResidencyEnum,
    params?: { [key: string]: string },
  ): Promise<T> {
    try {
      const result = await this.axiosInstances[dataResidencyEnum].get(
        path,
        {
              params,
              withCredentials: process.env.NODE_ENV !== 'development',
        }
      );
      return result.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  public async patch<T>(
    path: string,
    dataResidencyEnum: DataResidencyEnum,
    body: any,
    params?: { [key: string]: string },
  ): Promise<T> {
    try {
      const result = await this.axiosInstances[dataResidencyEnum].patch(
        path,
        body,
        process.env.NODE_ENV === 'development'
          ? {
              params,
              withCredentials: false,
              headers: {
                'x-api-key': process.env.REACT_APP_GRID_PRODUCTS_DEV_API_KEY,
              },
            }
          : {
              params,
              withCredentials: true,
            },
      );

      return result.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  public async post<T>(
    path: string,
    dataResidencyEnum: DataResidencyEnum,
    body?: any,
    params?: { [key: string]: string },
  ): Promise<T> {
    try {
      const result = await this.axiosInstances[dataResidencyEnum].post(
        path,
        body,
        process.env.NODE_ENV === 'development'
          ? {
              params,
              withCredentials: false,
              headers: {
                'x-api-key': process.env.REACT_APP_GRID_PRODUCTS_DEV_API_KEY,
              },
            }
          : {
              params,
              withCredentials: true,
            },
      );

      return result.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  public async delete<T>(
    path: string,
    dataResidencyEnum: DataResidencyEnum,
  ): Promise<T> {
    try {
      const result = await this.axiosInstances[dataResidencyEnum].delete(
        path,
        process.env.NODE_ENV === 'development'
          ? {
              withCredentials: false,
              headers: {
                'x-api-key': process.env.REACT_APP_GRID_PRODUCTS_DEV_API_KEY,
              },
            }
          : {
              withCredentials: true,
            },
      );

      return result.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  public getBaseUrlFromInstance(dataResidencyEnum: DataResidencyEnum): string {
    return this.axiosInstances[dataResidencyEnum].defaults.baseURL || '';
  }

  private handleError = (error: AxiosError): never => {
    throw new ApiError(
      error.message,
      error.response
        ? { statusCode: error.response.status, data: error.response.data }
        : undefined,
    );
  };
}

export default GridProducts;
