import { DataMatrixColumn, DataMatrixColumnDataType } from '@ombori/grid-reports';
import uniq from 'lodash/uniq';

interface EventsData {
  eventTypes: string[];
  totalEventTypes: string[];
}

const getEventType = (columns: DataMatrixColumn[]): string => {
  const initialValue: EventsData = { eventTypes: [], totalEventTypes: [] };

  const { eventTypes, totalEventTypes } = columns.reduce((accumulator, column) => {
    switch (column.dataType.type) {
      case DataMatrixColumnDataType.Sessions: {
        return accumulator;
      }

      case DataMatrixColumnDataType.Events: {
        const { eventTypes = [], totalData } = column.dataType;
        const totalEventTypes =
          totalData && totalData.eventTypes ? totalData.eventTypes : [];

        return {
          eventTypes: [...accumulator.eventTypes, ...eventTypes],
          totalEventTypes: [...accumulator.totalEventTypes, ...totalEventTypes],
        };
      }

      default: {
        throw new Error(`Unknown data type: ${column}`);
      }
    }
  }, initialValue);

  const eventType = uniq([...eventTypes, ...totalEventTypes]).join(',');

  return eventType;
};

export default getEventType;
