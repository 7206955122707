import React from 'react';
import styled from '@emotion/styled';
import { Tooltip, Typography } from 'antd';
import { Icon } from '../schema-form/common';

const { Title: AntdTitle, Text: AntdText } = Typography;

interface VersionsInfoProps {
  title: string;
  currentVersion?: string;
  latestVersion: string;
}

const VersionsInfo = (props: VersionsInfoProps) => {
  const { title, currentVersion, latestVersion } = props;

  return (
    <VersionsInfoContainer>
      <Title>{title}</Title>
      <Break />
      <VersionFromTo>
        {currentVersion && (
          <>
            <Tooltip placement="top" title={currentVersion}>
              <VersionNumber>{currentVersion}</VersionNumber>
            </Tooltip>
            <Icon size={14} type="arrow-right" />
          </>
        )}
        <Tooltip placement="top" title={latestVersion}>
          <VersionNumber>{latestVersion}</VersionNumber>
        </Tooltip>
      </VersionFromTo>
    </VersionsInfoContainer>
  );
};

const VersionsInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
`;

const Title = styled(AntdTitle)<{ size?: number }>`
  font-size: ${({ size = 14 }) => size}px !important;
  margin-bottom: 0 !important;
`;

const Text = styled(AntdText)`
  display: flex;
  gap: 5px;
  font-size: 14px !important;
`;

const Break = styled.div`
  flex-basis: 0;
  height: 0;
`;

const VersionFromTo = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const VersionNumber = styled(Text)`
  display: inline-block;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export default VersionsInfo;
