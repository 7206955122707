import { action, Action, select, Select, thunk, Thunk } from 'easy-peasy';
import User, { UserAllowedManageRoles, UserPermissions } from '../../types/user';
import Injections from '../../injections.interface';
import { getApiUrl } from '../../../utils/env';
import getAuthUrl, { getSourceUrl } from '../../../utils/auth/get-auth-url';

export interface AuthModel {
  user: User | null;
  login: Thunk<AuthModel>;
  isSysAdmin: Select<AuthModel, boolean>;
  permissions: Select<AuthModel, UserPermissions>;
  getCurrentUser: Thunk<AuthModel, void, Injections>;
  updatePreferredLanguage: Thunk<AuthModel, string, Injections>;
  setUser: Action<AuthModel, User>;
  logout: Thunk<AuthModel, void, Injections>;
  allowedManageRoles: Select<AuthModel, UserAllowedManageRoles>;
}

let updateCurrentUserInterval: NodeJS.Timeout;

const authModel: AuthModel = {
  user: null,
  login: thunk(() => {
    const params = new URLSearchParams(document.location.search);
    const returnPath = params.get('returnPath') || '';

    document.location.href = getAuthUrl(returnPath);
  }),
  isSysAdmin: select((state) => !!state.user && state.user.isSysadmin),
  permissions: select((state) => (!!state.user && state.user.permissions) || {}),
  allowedManageRoles: select(
    (state) => (!!state.user && state.user.allowedManageRoles) || {},
  ),
  getCurrentUser: thunk(async (actions, _, { injections }) => {
    const user = await injections.apiService.get<User>('/api/users/me');
    actions.setUser(user);
    if (updateCurrentUserInterval) {
      clearInterval(updateCurrentUserInterval);
    }
    updateCurrentUserInterval = setInterval(async () => {
      await injections.apiService.get<User>('/api/users/me');
    }, 1000 * 60 * 31); // Ping every 31 minutes to force logout user if the session has expired
  }),
  setUser: action((state, payload) => {
    state.user = payload;
  }),
  updatePreferredLanguage: thunk(
    async (actions, preferredLanguage, { injections, getState }) => {
      try {
        const { user: currentUser } = getState();

        if (!currentUser) {
          throw Error('Current user not found');
        }

        const user = await injections.apiService.put<User>(
          `/api/users/${currentUser.id}/preferred-language`,
          { preferredLanguage },
        );
        actions.setUser(user);
      } catch (e) {
        // eslint-disable-next-line
        console.error('Failed to update preferred language');
      }
    },
  ),
  logout: thunk(async () => {
    document.location.href = `${getApiUrl()}/auth/logout?source=${getSourceUrl()}`;
  }),
};

export default authModel;
