import React, { useMemo, useEffect, forwardRef, useCallback } from 'react';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import SchemaForm, { SchemaFormRef } from '../../schema-form/schema-form.component';
import useSchemaForm from '../../use-schema-form/use-schema-form';
import Environment from '../../../../store/types/environment';
import MobileEndpoint from '../../../../store/types/mobile-endpoint';
import Organisation from '../../../../store/types/organisation';
import OrganisationApp from '../../../../store/types/organisation-app';
import Spaces from '../../../../store/types/organisation-space';
import MultiSelect from '../../schema-form/widgets/multi-select/multi-select.component';

export interface MobileEndpointFormValues {
  uuid: string;
  displayName: string;
  url: string;
  env: string;
  notes: string;
  spaceId: string;
  isProvisioned: boolean;
}

interface CreateMobileEndpointModalProps {
  onSubmit: (values: Partial<MobileEndpoint>) => Promise<void>;
  mobileEndpoint?: Partial<MobileEndpoint>;
  spaces: Spaces[];
  environments: Environment[];
  organizations?: Organisation[];
  apps?: OrganisationApp[];
  fetchApps?: (params: { organizationId: string }) => void;
  fetchEnvironments: (params: { organizationId: string }) => void;
  fetchSpaces: (params: { organizationId: string; silent?: boolean; }) => void;
}

const MobileEndpointCreateForm = forwardRef(
  (props: CreateMobileEndpointModalProps, ref: React.Ref<SchemaFormRef>) => {
    const {
      onSubmit,
      spaces,
      environments,
      mobileEndpoint,
      fetchApps,
      fetchEnvironments,
      fetchSpaces,
    } = props;
    const { t } = useTranslation();

    const onSubmitForm = useCallback(
      async (values: MobileEndpointFormValues) => {
        const curValues = { ...values, spaces: [values.spaceId] };
        delete curValues.spaceId;
        return onSubmit(curValues);
      },
      [onSubmit],
    );

    const [formData, , , formInit, handleFormChange, handleFormSubmit] = useSchemaForm<
      any
    >(onSubmitForm, t('mobileEndpointSaved'), t('mobileEndpointSaveError'));

    const selectedOrganizationId = (formData as Partial<MobileEndpoint>).organizationId;

    const organizationEnvironments: Environment[] = useMemo(() => {
      if (Array.isArray(environments)) return environments;
      if (selectedOrganizationId)
        return Object.values(get(environments, selectedOrganizationId, {}));
      return [];
    }, [environments, selectedOrganizationId]);

    const formSchema: any = useMemo(() => {
      const schema = {
        type: 'object',
        properties: {
          urlId: {
            type: 'string',
            minLength: 1,
            title: t('urlId'),
            description: t('mustBeInSmallLettersAndKebabCase'),
            pattern: '^([a-z][a-z0-9]*)(-[a-z0-9]+)*$',
            readOnly: !!(mobileEndpoint && mobileEndpoint.id),
          },
          displayName: {
            type: 'string',
            minLength: 1,
            title: t('displayName'),
          },
          env: {
            type: 'string',
            title: t('environment'),
            enum: organizationEnvironments.map((env) => env.environmentName),
            enumNames: organizationEnvironments.map((env) => env.displayName),
            readOnly: organizationEnvironments.length < 1,
          },
          spaceId: {
            type: 'string',
            minLength: 1,
            title: t('space'),
            enum: ['', ...spaces.map((space) => space.id)],
            enumNames: ['', ...spaces.map((space) => space.displayName)],
            default: spaces && spaces[0] ? spaces[0].id : '',
          },
          notes: {
            type: 'string',
            title: t('notes'),
          },
        },
        required: ['urlId', 'displayName', 'env', 'spaceId'],
      };

      return schema;
    }, [t, mobileEndpoint, organizationEnvironments, spaces]);

    useEffect(() => {
      if (selectedOrganizationId) {
        fetchEnvironments({ organizationId: selectedOrganizationId });
        fetchSpaces({ organizationId: selectedOrganizationId, silent: true });
      }
    }, [selectedOrganizationId, fetchApps, fetchEnvironments, fetchSpaces]);

    useEffect(() => {
      let initialValues: any = {};
      if (mobileEndpoint) initialValues = { ...mobileEndpoint };

      formInit({
        urlId: initialValues.urlId || '',
        displayName: initialValues.displayName || '',
        env: initialValues.env || '',
        notes: initialValues.notes || '',
        spaceId: initialValues && initialValues.spaces && initialValues.spaces[0],
      });
    }, [formInit, mobileEndpoint]);

    const metaSchema = {
      spaces: {
        'ui:field': MultiSelect,
      },
    };

    return (
      <SchemaForm
        schema={formSchema}
        uiSchema={metaSchema}
        onSubmit={handleFormSubmit}
        onChange={handleFormChange}
        ref={ref}
        data={formData}
        liveValidate={false}
      />
    );
  },
);

export default MobileEndpointCreateForm;
