import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Row, Col, Tooltip } from 'antd';
import moment from 'moment';
import { AggregatedMonitoringEvent } from '../../use-analytics-report';
import OrganisationSpace from '../../../../store/types/organisation-space';
import UniversalDevice from '../../../../store/types/universal-device';

export type StatusReferenceType = Record<
  string | number,
  {
    label: string;
    color: string;
  }
>;

type RealtimeStatusChartProps = {
  data: AggregatedMonitoringEvent[];
  spaces: Record<string, OrganisationSpace>;
  devices: Record<string, UniversalDevice>;
  statusReference: StatusReferenceType;
  dateFrom: Date;
  dateTo: Date;
};

interface EventData {
  tenantId: string;
  id: string;
  spaceId: string;
  installationId: string;
  deviceId: string;
  eventType: string;
  str1: string;
  str2: string;
  history: Array<{
    int1: number;
    eventTime: string;
  }>;
}

const extractStatusHistory = (
  data: AggregatedMonitoringEvent[],
  start: Date,
  end: Date,
) => {
  const mergedEvents: EventData[] = [];

  for (const event of data) {
    delete event.date;
    const existingEvent = mergedEvents.find(
      (e) =>
        e.spaceId === event.spaceId &&
        e.installationId === event.installationId &&
        e.tenantId === event.tenantId &&
        e.deviceId === event.deviceId &&
        e.str1 === event.str1 &&
        e.str2 === event.str2,
    );

    if (existingEvent) {
      existingEvent.history = existingEvent.history.concat(event.history);
    } else {
      mergedEvents.push(event);
    }
  }

  return mergedEvents.map((ev) => {
    let closestBeforeDateFrom: {
      eventTime: string;
      int1: number;
    } | null = null;

    // Find the closest datetime before the specific datetime
    ev.history.forEach((item) => {
      const currentDatetime = new Date(item.eventTime);
      const targetDatetime = new Date(start);

      if (currentDatetime < targetDatetime) {
        if (
          !closestBeforeDateFrom ||
          currentDatetime > new Date(closestBeforeDateFrom.eventTime)
        ) {
          closestBeforeDateFrom = item;
        }
      }
    });

    const filteredHistory = ev.history
      .filter((h) => moment(h.eventTime).isBetween(start, end, 'day', '[]'))
      .sort((a, b) => {
        const dateA = new Date(a.eventTime);
        const dateB = new Date(b.eventTime);

        return dateA.getTime() - dateB.getTime();
      });

    return {
      ...ev,
      history: [
        closestBeforeDateFrom
          ? { eventTime: start.toISOString(), int1: (closestBeforeDateFrom as any).int1 }
          : { eventTime: start.toISOString(), int1: -1 },
        ...filteredHistory,
        filteredHistory.length > 0
          ? {
              eventTime: end.toISOString(),
              int1: filteredHistory[filteredHistory.length - 1].int1,
            }
          : { eventTime: end.toISOString(), int1: -1 },
      ],
    };
  });
};

const getHHMM = (datetime: Date) => {
  const year = datetime.getUTCFullYear().toString();
  const month = (datetime.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = datetime
    .getUTCDate()
    .toString()
    .padStart(2, '0');
  const hours = datetime
    .getUTCHours()
    .toString()
    .padStart(2, '0');
  const minutes = datetime
    .getUTCMinutes()
    .toString()
    .padStart(2, '0');
  return `${year}${month}${day} ${hours}:${minutes}`;
};

const getTimeDiffInPercentage = (
  start: string,
  end: string,
  dateFrom: Date,
  dateTo: Date,
) => {
  const startTime = new Date(start).getTime();
  const endTime = new Date(end).getTime();
  const totalDiff = dateTo.getTime() - dateFrom.getTime();
  return ((endTime - startTime) * 100) / totalDiff;
};

const getSpaceName = (spaces: Record<string, OrganisationSpace>, spaceId: string) => {
  if (spaces[spaceId]) {
    return spaces[spaceId].displayName;
  }

  return 'Unknown space';
};

const getDeviceName = (devices: Record<string, UniversalDevice>, deviceId: string) => {
  if (devices[deviceId]) {
    return devices[deviceId].deviceName;
  }

  return ``;
};

const getStatusColor = (ref: StatusReferenceType, statusNum: number | string) => {
  const status = ref[+statusNum];
  if (status) {
    return status.color;
  }

  return '';
};

const getStatusLabel = (ref: StatusReferenceType, statusNum: number | string) => {
  const status = ref[+statusNum];
  if (status) {
    return status.label;
  }

  return '';
};

export default function RealtimeStatusComponent({
  data,
  spaces,
  devices,
  statusReference,
  dateFrom,
  dateTo: latestDate,
}: RealtimeStatusChartProps) {
  const dateTo = useMemo(() => {
    return moment(latestDate).isSame(moment(), 'day')
      ? new Date()
      : moment(latestDate)
          .add(1, 'day')
          .toDate();
  }, [latestDate]);

  const parsedData = useMemo(() => {
    return extractStatusHistory(data, dateFrom, dateTo);
  }, [dateFrom, dateTo, data]);

  return (
    <>
      {parsedData.map((d, index) => (
        <StyledRow type="flex" key={`status-history-${index}`}>
          <Col
            span={10}
            dangerouslySetInnerHTML={{
              __html: `<b>${getSpaceName(spaces, d.spaceId)}</b> ${getDeviceName(
                devices,
                d.deviceId,
              )}`,
            }}
          />
          <Col span={3}>
            <Tooltip title={d.str2}>{d.str2 && <span>{d.str1}</span>}</Tooltip>
          </Col>
          <Col span={10}>
            <Tooltip
              title={
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {d.history.map((item, index) =>
                    d.history.length - 1 === index ? null : (
                      <TooltipHistoryItem
                        key={`status-history-item-${index}`}
                        color={getStatusColor(statusReference, item.int1)}
                      >
                        {`${item.eventTime}: ${getStatusLabel(
                          statusReference,
                          item.int1,
                        )}`}
                      </TooltipHistoryItem>
                    ),
                  )}
                </div>
              }
              overlayStyle={{ width: 'fit-content', maxWidth: 'fit-content' }}
            >
              <HistoryContainer key={d.deviceId}>
                {d.history.map((statusHistoryItem, index, arr) => {
                  let start = '';
                  let end = '';
                  let status: number;
                  if (index === 0) {
                    return null;
                  } else {
                    const prevItem = arr[index - 1];

                    start = prevItem.eventTime;
                    end = statusHistoryItem.eventTime;
                    status = prevItem.int1;
                  }

                  const width = getTimeDiffInPercentage(start, end, dateFrom, dateTo);

                  return (
                    <StackedBar
                      key={`${index}-${statusHistoryItem.eventTime}`}
                      width={width}
                      bgColor={getStatusColor(statusReference, status)}
                    />
                  );
                })}
                {index === parsedData.length - 1 && (
                  <>
                    <DateLabelFrom>{getHHMM(dateFrom)}</DateLabelFrom>
                    <DateLabelEnd>{`${getHHMM(dateTo)}`}</DateLabelEnd>
                  </>
                )}
              </HistoryContainer>
            </Tooltip>
          </Col>
        </StyledRow>
      ))}
      {parsedData.length === 0 && <NoData>No Data</NoData>}
    </>
  );
}

const StackedBar = styled.div<{ width: number; bgColor: string | number }>`
  height: 20px;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : '#292b2c')};
  width: ${({ width }) => width}%;
`;

const StyledRow = styled(Row)`
  padding-bottom: 8px;
  padding-top: 8px;
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  font-style: italic;
  min-height: 112px;
  color: #aaadab;
`;

const HistoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 8px;
  border-radius: 4px;
  overflow: hidden;
`;

const DateLabelFrom = styled.span`
  position: absolute;
  background: transparent;
  left: -24px;
  top: 24px;
  font-size: 10px;
`;

const DateLabelEnd = styled(DateLabelFrom)`
  left: auto;
  right: -24px;
`;

const TooltipHistoryItem = styled.span<{ color: string }>`
  color: ${({ color }) => color};
`;
