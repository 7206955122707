import { Field } from 'react-final-form';
import React from 'react';
import styled from '@emotion/styled';
import { SupportedQueueLanguages } from './types';

export default function TextInAppCustomizationLanguagePicker({ ...props }) {
  const options = Object.keys(SupportedQueueLanguages).map((lang) => {
    return lang.toLowerCase();
  });

  return (
    <Container {...props}>
      <Field
        name="selectedLanguageForTextCustomization"
        key="selectedLanguageForTextCustomization"
        component="select"
      >
        {options.map((o) => (
          <option value={o} key={o}>
            {o.toUpperCase()}
          </option>
        ))}
      </Field>
    </Container>
  );
}

const Container = styled.div`
  margin: 8px auto;
`;
