import { Typography, Table } from 'antd';
import { get } from 'lodash';
import dayjs from 'dayjs';
import { RouteComponentProps } from 'react-router';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import PanelCard from '../../../panel-card/panel-card.component';
import Organisation from '../../../../../store/types/organisation';
import OrganisationApp from '../../../../../store/types/organisation-app';
import GridappBuild from '../../../../../store/types/gridapp-build';
import Gridapp from '../../../../../store/types/gridapp';

type DataSource = {
  installationId: string;
  installationName: string;
  tenantId: string;
  tenantName: string;
  gridappBuildId: string;
  gridappBuild: GridappBuild | null;
  lastUpdated: string;
  installationLink: React.ReactNode;
  tenantLink: React.ReactNode;
  bootVersion: string;
}

interface GridappSettingsProps
  extends RouteComponentProps<{ gridappId: string; organisationId: string }> {
  gridappBuilds: GridappBuild[];
  installations: OrganisationApp[];
  organisations: Record<string, Organisation>;
  gridapp: Gridapp | null;
}

const GridappSettings = (props: GridappSettingsProps) => {
  const { t } = useTranslation();
  const {
    organisations,
    installations,
    gridappBuilds,
    gridapp,
  } = props;

  const dataSource: DataSource[] = useMemo(() => {
    return installations.map(installation => {
      const gridappBuildId = get(
        installation,
        ['settings', 'provider', 'app', 'gridApp', 'id'],
        '',
      );
      const gridappBuild = gridappBuilds.find(build => build.id === gridappBuildId) || null;
      const tenantName = get(organisations, [installation.organizationId, 'displayName'], '');
      return {
        installationId: installation.id,
        installationName: installation.displayName,
        tenantId: installation.organizationId,
        tenantName,
        tenantLink: (
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={`/organisations/${installation.organizationId}`}
          >
            {tenantName}
          </Link>
        ),
        gridappBuildId,
        gridappBuild,
        lastUpdated: installation.updatedAt,
        installationLink: (
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={`/organisations/${installation.organizationId}/apps/${installation.id}/content`}
          >
            {installation.displayName}
          </Link>
        ),
        bootVersion: ((installation.release || '')
          .split(" ")[1] || '')
          .substring(1),
      }
    })
      .sort((a, b) =>
        a.tenantName.localeCompare(b.tenantName) ||
        a.installationName.localeCompare(b.installationName),
      )
  }, [organisations, installations, gridappBuilds]);

  const columns = useMemo(
    () => {
      
      return [
        {
          title: t('organization'),
          key: 'tenantId',
          sorter: (a: DataSource, b: DataSource) => a.tenantName.localeCompare(b.tenantName),
          render: ({ tenantLink }: DataSource) => tenantLink,
        },
        {
          title: t('installation'),
          key: 'installationId',
          sorter: (a: DataSource, b: DataSource) =>
            a.installationName.localeCompare(b.installationName),
          render: ({ installationLink }: DataSource) => installationLink,
        },
        {
          title: t('appVersion'),
          key: 'gridappBuildId',
          sorter: (a: DataSource, b: DataSource) => {
            const aVersion = get(a.gridappBuild, ['result', 'version'], 0);
            const bVersion = get(b.gridappBuild, ['result', 'version'], 0);

            return bVersion - aVersion;
          },
          render: ({ gridappBuild }: DataSource) => gridappBuild
            ? get(gridappBuild, ['result', 'version'], '')
            : '',
        },
        {
          title: t('bootVersion'),
          key: 'bootVersion',
          sorter: (a: DataSource, b: DataSource) => a.bootVersion.localeCompare(b.bootVersion),
          render: ({ bootVersion }: DataSource) => bootVersion
        },
        {
          title: t('lastModified'),
          key: 'lastUpdated',
          sorter: (a: DataSource, b: DataSource) => a.lastUpdated.localeCompare(b.lastUpdated),
          render: ({ lastUpdated }: DataSource) => dayjs(lastUpdated).format('DD-MM-YYYY HH:mm')
        },
      ];
    },
    [t],
  );

  return (
    <PanelCard bodyStyle={{ padding: 16 }}>
      <Header>
        <Typography.Title level={3}>{gridapp ? gridapp.displayName : ''}</Typography.Title>
        <Typography.Text>{t('associatedInstallations')}</Typography.Text>
      </Header>
      <Table
        rowKey="id"
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
    </PanelCard>
  );
};

const Header = styled.div`
  padding: 0 0 16px 0;
`;

export default GridappSettings;
