import * as React from 'react';
import { useActions, useStore } from 'easy-peasy';
import { GridLlmService, GridLlmServiceProvider } from '@ombori/grid-llm-react';
import OrganisationDetails from './organisation-details.component';
import { RootModel, RootState } from '../../../store/models/root.model';
import { useInstallationGroups } from '../../common/use-installation-groups';
import useAllSpaces from '../../common/use-all-spaces';
import ActiveTenantAllSpacesProvider from '../../common/spaces-provider/spaces-provider.component';

type OrganisationDetailsContainerProps = Omit<
  React.ComponentProps<typeof OrganisationDetails>,
  | 'organisation'
  | 'fetchApps'
  | 'appsAndGroupsLoaded'
  | 'apps'
  | 'organisationsList'
  | 'installationGroups'
>;

const OrganisationDetailsContainer = (props: OrganisationDetailsContainerProps) => {
  const {
    match: {
      params: { organisationId },
    },
  } = props;
  const [gridLlmServiceInstance, setGridLlmServiceInstance] = React.useState<GridLlmService>(
    new GridLlmService({ dataResidency: 'eu' })
  );
  const installationGroupsQuery = useInstallationGroups({ organisationId });
  const installationGroups = installationGroupsQuery.data || [];

  const {
    organisationsList,
    organisation,
    appsLoaded,
    apps,
    user,
  } = useStore<RootState>(
    ({ organisationApps, organisations, auth, organisationSpaces }) => ({
      organisationsList: organisations.values,
      organisation: organisations.data && organisations.data[organisationId],
      appsLoaded: organisationApps.loaded,
      apps: organisationApps.values(organisationId),
      user: auth.user,
      permissions: auth.permissions,
    }),
    [organisationId],
  );

  const spacesState = useAllSpaces(organisationId);

  const appsAndGroupsLoaded =
    appsLoaded && !installationGroupsQuery.isLoading && spacesState.isFetched;

  const { fetchApps } = useActions<RootModel>((actions) => ({
    fetchApps: actions.organisationApps.fetch,
  }));

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setGridLlmServiceInstance(new GridLlmService({ dataResidency: 'local' }));
      return;
    }

    if (organisation) {
      setGridLlmServiceInstance(new GridLlmService({ dataResidency: organisation.dataResidency }));
      return;
    }
  }, [organisation]);

  return (
    <GridLlmServiceProvider gridLlmService={gridLlmServiceInstance}>
      <ActiveTenantAllSpacesProvider
        tenantId={organisationId}
        isLoading={spacesState.isLoading}
        isSuccess={spacesState.isSuccess}
        isError={spacesState.isError}
        spaces={spacesState.data ? spacesState.data : []}
        total={spacesState.data ? spacesState.data.length : 0}
      >
        <OrganisationDetails
          appsAndGroupsLoaded={appsAndGroupsLoaded}
          fetchApps={fetchApps}
          organisation={organisation}
          organisationsList={organisationsList}
          apps={apps}
          installationGroups={installationGroups}
          user={user}
          spaces={spacesState.data ? spacesState.data : []}
          {...props}
        />
      </ActiveTenantAllSpacesProvider>
    </GridLlmServiceProvider>

  );
};

export default OrganisationDetailsContainer;
