import { ConfigType, Dayjs } from 'dayjs';

const safeRelativeTime = (then: Dayjs, now: ConfigType = new Date()) => {
  try {
    return then.from(now);
  } catch {
    return '';
  }
};

export default safeRelativeTime;
