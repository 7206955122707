import React from 'react';
import styled from '@emotion/styled';
import humanizeDuration from 'humanize-duration';
import dayjs from 'dayjs';
import LabeledValue from './labeled-value.component';
import { sumDays, Day } from '../utils';
import AreaCard from './area-card.component';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  width: 100%;
`;

const Parameter = styled.div`
  margin-right: 16px;
  margin-bottom: 16px;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  font-size: 24px;
  line-height: 24px;
`;

const LabelSmall = styled.div`
  position: relative;
  display: flex;
  padding-top: 5px;
  font-size: 15px;
  line-height: 15px;
  opacity: 0.6;
`;

const labelFormatter = (label: string | number) => {
  if (typeof label === 'number') {
    return label;
  }

  const day = dayjs(label).format('dddd');

  const result = `${label} (${day})`;

  return result;
};

interface SessionsCardProps {
  data: Day[];
  precedingData?: Day[];
  duration?: any;
  avgEventsPerSession?: number;
  precedingAvgEventsPerSession?: number;
}

const SessionsCard: React.FC<SessionsCardProps> = ({
  data,
  precedingData,
  duration,
  avgEventsPerSession,
  precedingAvgEventsPerSession,
}) => {
  const { t } = useTranslation();

  return (
    <AreaCard
      title={t('currentPeriod')}
      data={data}
      precedingData={precedingData}
      labelFormatter={labelFormatter}
      showCard={false}
      isLegendVisible={true}
      areaStyles={[
        {
          stroke: '#3772e0',
          fill: '#3772e0',
          fillOpacity: 0,
          strokeDasharray: '5 5',
        },
        {
          stroke: '#3772e0',
          fill: '#3772e0',
          fillOpacity: 0,
        },
      ]}
    >
      <Container>
        <Parameter>
          <LabeledValue
            size="md"
            label="Total sessions"
            value={
              <LabelContainer>
                <Label>{sumDays(data)}</Label>

                {precedingData && <LabelSmall>vs. {sumDays(precedingData)}</LabelSmall>}
              </LabelContainer>
            }
          />
        </Parameter>

        {!!duration && (
          <Parameter>
            <LabeledValue
              size="md"
              label="Average session duration"
              value={humanizeDuration(duration.mean, {
                delimiter: ' and ',
                round: true,
              })}
            />
          </Parameter>
        )}

        {!!avgEventsPerSession && (
          <Parameter>
            <LabeledValue
              size="md"
              label="Average events per session"
              value={
                <LabelContainer>
                  <Label>{avgEventsPerSession}</Label>

                  {typeof precedingAvgEventsPerSession === 'number' && (
                    <LabelSmall>vs. {precedingAvgEventsPerSession}</LabelSmall>
                  )}
                </LabelContainer>
              }
            />
          </Parameter>
        )}
      </Container>
    </AreaCard>
  );
};

export default SessionsCard;
