import React, {
  useState,
  useCallback,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Input, Icon, Spin, Tooltip } from 'antd';
import { FieldProps } from 'react-jsonschema-form';
import styled from '@emotion/styled';
import ValidationStatusEnum from '../../../../../store/types/validation-status-enum';
import ApiService from '../../../../../services/api/api.service';
import { getApiUrl } from '../../../../../utils/env';

const apiService = new ApiService(getApiUrl());
const validateVatId = async (vatId: string) => {
  const data = await apiService.post<{
    valid: boolean;
    companyName?: string;
  }>('/api/vat-id/validate', { vatId });
  return data;
};

export interface VatIdInputRef {
  validate: (value?: string) => void;
  reset: () => void;
}

interface VatIdInputProps extends FieldProps {
  onValidation?: (status: ValidationStatusEnum, error?: string[]) => void;
  enableValidation?: boolean;
}

const VatIdInput = (props: VatIdInputProps, ref?: React.Ref<VatIdInputRef>) => {
  const {
    id,
    schema,
    formData,
    placeholder,
    disabled,
    defaultValue,
    name,
    required,
    readonly,
    autofocus,
    rawErrors,
    enableValidation = true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onValidation = (status: ValidationStatusEnum, error?: string[]) => {},
    onChange,
    onBlur,
  } = props;
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [status, setStatus] = useState<ValidationStatusEnum>(ValidationStatusEnum.NONE);
  const [error, setError] = useState<string[]>();
  const [focus, setFocus] = useState<boolean>();

  const formValue = formData as string;
  const { minLength = 8, maxLength = 14 } = schema;

  const handleChange = useCallback(
    (e) => {
      const val = e.target.value || undefined;

      onChange(val);
      setIsTouched(true);

      if (val === undefined) {
        setStatus(ValidationStatusEnum.NONE);
        onValidation(ValidationStatusEnum.NONE);
        setError(undefined);
      }
    },
    [onChange, onValidation],
  );

  const validate = useCallback(
    async ({
      fieldFocus = false,
      fieldTouch = false,
      fieldEnableValidation = false,
      fieldValue,
    }: {
      fieldFocus?: boolean;
      fieldTouch?: boolean;
      fieldEnableValidation?: boolean;
      fieldValue?: string;
    }) => {
      const isFieldTouched = isTouched || fieldTouch;
      const enableFieldValidation = enableValidation || fieldEnableValidation;

      if (!enableFieldValidation) {
        return;
      }

      const inputValue = fieldValue || formValue;
      const len = inputValue ? inputValue.length : 0;

      if (!isFieldTouched || (minLength > len || len > maxLength)) {
        return;
      }

      setIsTouched(false);
      setStatus(ValidationStatusEnum.VALIDATING);
      setFocus(fieldFocus);

      onValidation(ValidationStatusEnum.VALIDATING);

      const err: string[] = [];

      try {
        const { valid } = await validateVatId(inputValue);

        if (!valid) {
          setStatus(ValidationStatusEnum.INVALID);
          err.push('VAT Id is invalid.');
          onValidation(ValidationStatusEnum.INVALID, err);
        } else {
          setStatus(ValidationStatusEnum.SUCCESS);
          onValidation(ValidationStatusEnum.SUCCESS);
        }
      } catch {
        setStatus(ValidationStatusEnum.ERROR);
        err.push('Validation failed.');
        onValidation(ValidationStatusEnum.ERROR, err);
      }

      setError(err);
    },
    [isTouched, enableValidation, formValue, minLength, maxLength, onValidation],
  );

  const handleBlur = useCallback(
    async (e) => {
      validate({});

      if (onBlur) {
        onBlur(e);
      }
    },
    [onBlur, validate],
  );

  const handlePressEnter = useCallback(() => {
    validate({ fieldFocus: true });
  }, [validate]);

  const icon = useMemo(() => {
    switch (status) {
      case ValidationStatusEnum.VALIDATING:
        return <Spin indicator={<IconStyled type="loading" spin size={14} />} />;
      case ValidationStatusEnum.SUCCESS:
        return <IconStyled type="check-circle" color="green" size={14} />;
      case ValidationStatusEnum.INVALID:
      case ValidationStatusEnum.ERROR:
        return (
          <Tooltip title={<>{error && error.map((err) => <div key={err}>{err}</div>)}</>}>
            <IconStyled type="close-circle" color="#a94442" size={14} />
          </Tooltip>
        );
      default:
        return <span />;
    }
  }, [error, status]);

  const hasError = useMemo(() => {
    return (error && !!error.length) || (rawErrors && !!rawErrors.length);
  }, [error, rawErrors]);

  useImperativeHandle(
    ref,
    () => ({
      validate: (val?: string) => {
        validate({ fieldTouch: true, fieldEnableValidation: true, fieldValue: val });
      },
      reset: () => {
        setStatus(ValidationStatusEnum.NONE);
        onValidation(ValidationStatusEnum.NONE);
        setError(undefined);
      },
    }),
    [onValidation, validate],
  );

  return (
    <Container>
      <label
        htmlFor={name}
        className={`control-label ${hasError ? 'has-error text-danger' : ''}`}
      >
        {schema.title || name}
        {required && <span className="required">*</span>}
      </label>
      <>
        <TextInput
          id={id}
          value={formValue}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readonly}
          defaultValue={defaultValue}
          autoFocus={autofocus || focus}
          onChange={handleChange}
          onBlur={handleBlur}
          onPressEnter={handlePressEnter}
          suffix={icon}
          haserror={hasError ? 'true' : 'false'}
        />
        {status === ValidationStatusEnum.VALIDATING && (
          <span>Validation is on-going. Please wait.</span>
        )}
      </>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
`;

const IconStyled = styled(Icon)<{ size?: number; color?: string }>`
  font-size: ${({ size = 12 }) => size}px;
  ${({ color }) => (color ? `color: ${color};` : '')}
  font-weight: bold;
`;

const TextInput = styled(Input)<{ haserror?: 'true' | 'false' }>`
  .ant-input {
    display: block;
    width: 100%;
    height: 32px;
    padding: 2px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555555;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    ${({ haserror }) =>
      haserror === 'true'
        ? `
        border-color: #a94442 !important;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        
        &:focus {
          border-color: #843534 !important;
          -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483 !important;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483 !important;
        }
      `
        : `
        border: 1px solid #ccc !important;

        &:focus {
          border-color: #66afe9 !important;
          outline: 0;
          -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) ,
            0 0 8px rgba(102, 175, 233, 0.6) !important;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6) !important;
        }
    `}
  }

  .ant-input[readonly] {
    background-color: #eeeeee !important;
  }
`;

export default forwardRef(VatIdInput);
