import styled from '@emotion/styled';
import React, { CSSProperties } from 'react';

type PannelHeaderProps = {
  icon: React.ReactNode;
  title: string;
  subHeader?: React.ReactNode;
  style?: CSSProperties;
};

const PanelHeader: React.FC<PannelHeaderProps> = ({ icon, title, subHeader, style }) => {
  return (
    <HeaderWrapper>
      <Icon>{icon}</Icon>
      <TitleWrapper>
        <HeaderTitle style={style}>{title}</HeaderTitle>
        {subHeader && <SubHeader>{subHeader}</SubHeader>}
      </TitleWrapper>
    </HeaderWrapper>
  );
};

export default PanelHeader;

const HeaderWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex: 1;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  flex: 1;
`;
const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(223 223 232);
  border-radius: 8px;
  min-height: 48px;
  min-width: 48px;
`;

const HeaderTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4em;
  color: rgb(17, 17, 17);
`;

const SubHeader = styled.div`
  font-size: 14px;
`;
