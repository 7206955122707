import React from 'react';
import styled from '@emotion/styled';

interface InstructionProps {
  logo: string;
  title: string;
  steps: string[];
}

export default function Instruction({ logo, title, steps }: InstructionProps) {
  return (
    <Container>
      <Logo src={logo} alt="Instruction Logo" />
      <Title>{title}</Title>
      <Steps>
        {steps.map((step) => (
          <StepWrapper key={`${step}`}>
            {/* eslint-disable-next-line react/no-danger */}
            <Step dangerouslySetInnerHTML={{ __html: step }} />
          </StepWrapper>
        ))}
      </Steps>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  font-size: 14px;
  padding: 28px 32px;

  @media (max-width: 940px) {
    overflow-y: inherit;
    height: auto;
    padding: 28px;
  }

  @media (min-width: 940px) {
    overflow-y: scroll;
  }
`;

const Logo = styled.img`
  align-self: flex-start;
  height: 54px;
` as any;

const Title = styled.div`
  font-weight: bold;
  padding: 16px 0px 12px 0px;
`;

const Steps = styled.ol`
  list-style: none;
  counter-reset: step-counter;
`;

const Step = styled.span`
  padding-top: 1px;
`;

const StepWrapper = styled.li`
  padding: 8px 0px;
  counter-increment: step-counter;
  display: flex;
  flex: 1;
  word-break: break-word;

  &::before {
    content: counter(step-counter);
    font-size: 11px;
    background-color: #fff;
    min-width: 22px;
    height: 22px;
    line-height: 22px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    margin-right: 12px;
    margin-top: 1px;
  }
`;
