import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Row, Col } from 'antd';
import { ColorResult } from 'react-color';
import {
  SketchColorPicker,
  ColorSet,
  convertColorResultToColorSet,
} from '../../color-picker';
import { Button, Editor, Icon, Text } from '../../../common';
import { ChevronUp } from 'react-feather';
import Input from '../../../common/input.component';

interface PropertySettingsProps {
  initialBackgroundColor: ColorSet;
  initialTextColor: ColorSet;
  initialCss?: string;
  onClose?: () => void;
  onChange?: ({
    backgroundColor,
    textColor,
    cssValue,
  }: {
    backgroundColor: ColorSet;
    textColor: ColorSet;
    cssValue: string;
  }) => void;
  isReadonly?: boolean;
}

const PropertySettings = (props: PropertySettingsProps) => {
  const {
    initialBackgroundColor,
    initialTextColor,
    initialCss = '',
    onChange = () => {},
    onClose = () => {},
    isReadonly,
  } = props;

  const { t } = useTranslation();

  const refBackgroundColor = useRef<ColorSet>(initialBackgroundColor);
  const refTextColor = useRef<ColorSet>(initialTextColor);
  const refCss = useRef<string>(initialCss);

  const handleBackgroundColorPick = useCallback(
    (color: ColorResult) => {
      const colorValue = convertColorResultToColorSet(color);
      refBackgroundColor.current = colorValue;

      onChange({
        backgroundColor: colorValue,
        textColor: refTextColor.current,
        cssValue: refCss.current,
      });
    },
    [onChange],
  );

  const handleTextColorPick = useCallback(
    (color: ColorResult) => {
      const colorValue = convertColorResultToColorSet(color);
      refTextColor.current = colorValue;

      onChange({
        backgroundColor: refBackgroundColor.current,
        textColor: colorValue,
        cssValue: refCss.current,
      });
    },
    [onChange],
  );

  const handleCssEditorChange = useCallback(
    (cssValue: string) => {
      refCss.current = cssValue;

      onChange({
        backgroundColor: refBackgroundColor.current,
        textColor: refTextColor.current,
        cssValue,
      });
    },
    [onChange],
  );

  return (
    <PropertySettingsContainer>
      <ColorEditBox>
        <Text strong>{t('buttonPicker.editTitle')}</Text>
        <Row gutter={[10, 10]}>
          <Col xs={24} md={12}>
            <SketchColorPicker
              initialColor={initialBackgroundColor.value}
              onChangeComplete={handleBackgroundColorPick}
              isEnabled={!isReadonly}
            >
              {(colorResult: ColorResult) => {
                return (
                  <ColorInput
                    title={t('buttonPicker.backgroundColorLabel')}
                    isReadonly
                    value={colorResult.hex}
                    beforeExtra={
                      <ColorBoxSmall>
                        <BackgroundColor color={colorResult.hex} />
                      </ColorBoxSmall>
                    }
                  />
                );
              }}
            </SketchColorPicker>
          </Col>
          <Col xs={24} md={12}>
            <SketchColorPicker
              initialColor={initialTextColor.value}
              onChangeComplete={handleTextColorPick}
              isEnabled={!isReadonly}
            >
              {(colorResult: ColorResult) => {
                return (
                  <ColorInput
                    title={t('buttonPicker.textColorLabel')}
                    isReadonly
                    value={colorResult.hex}
                    beforeExtra={
                      <ColorBoxSmall>
                        <BackgroundColor color={colorResult.hex} />
                      </ColorBoxSmall>
                    }
                  />
                );
              }}
            </SketchColorPicker>
          </Col>
        </Row>
      </ColorEditBox>
      <StyleEditBox>
        <Text strong>{t('buttonPicker.style')}</Text>
        <CssEditor
          defaultValue={initialCss}
          onChange={handleCssEditorChange}
          isReadonly={isReadonly}
        />
      </StyleEditBox>
      <ButtonGroup align="center">
        <Button type="secondary" onClick={onClose}>
          <Icon size={18} component={() => <ChevronUp />} />
          {t('buttonPicker.editClose')}
        </Button>
      </ButtonGroup>
    </PropertySettingsContainer>
  );
};

const PropertySettingsContainer = styled.div`
  padding: 0;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
`;

const ColorEditBox = styled.div`
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  padding: 12px 16px;
  gap: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

const StyleEditBox = styled(ColorEditBox)`
  padding-bottom: 0;
`;

const ColorBox = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #dddddd;
  padding: 4px;
  margin: 5px auto auto;
`;

const BackgroundColor = styled.div<{ color?: string }>`
  position: relative;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  ${({ color }) => (color ? `background-color: ${color};` : '')}
  border-radius: 2px;
`;

const ButtonGroup = styled.div<{ align?: 'start' | 'center' | 'end' }>`
  display: flex;
  justify-content: ${({ align = 'start' }) => align};
  gap: 5px;
  padding: 10px 16px;

  @media only screen and (max-width: 576px) {
    justify-content: center;
  }
`;

const ColorBoxSmall = styled(ColorBox)`
  margin: 0;
  padding: 1px;
  width: 20px;
  height: 20px;
`;

const ColorInput = styled(Input)`
  cursor: pointer;
  border-radius: 4px;

  input {
    text-transform: uppercase;
  }
`;

const CssEditor = styled(Editor)`
  border-radius: 4px;
`;

export default React.memo(PropertySettings);
