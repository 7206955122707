import React, { useCallback } from 'react';
import { useActions, useStore } from 'easy-peasy';
import LegacyWindowsDevicesList from './devices-legacy-windows-list.component';
import { RootModel, RootState } from '../../../store/models/root.model';
import LegacyWindowsDevice from '../../../store/types/legacy-windows-device';
import OrganisationApp from '../../../store/types/organisation-app';

interface DeviceLegacyWindowsContainerProps {
  app?: OrganisationApp | null;
  organizationId: string;
  customQuery?: string;
  enableSearch?: boolean;
  onDeviceListItemClick?: (device: LegacyWindowsDevice) => void;
  renderExtraActions?: (deviceId: string) => JSX.Element;
  disableDevices?: string[];
  autoScroll?: boolean;
}

// TO DO: Revisit end-of-support plan for legacy windows devices
// Legacy Windows Devices is already deprecated and will be removed soon (timeline tbd by lead)
// This is a temporary component to support such device as we move to paginated fetch for universal devices and grid-devices api
const DevicesLegacyWindowsContainer = (props: DeviceLegacyWindowsContainerProps) => {
  const {
    app,
    customQuery,
    enableSearch,
    organizationId,
    onDeviceListItemClick,
    renderExtraActions,
    disableDevices,
    autoScroll,
  } = props;

  const { devices, loaded, error, lastUpdated } = useStore<RootState>(
    (state) => ({
      devices: state.organisationLegacyWindowsDevices.values(organizationId),
      loaded: state.organisationLegacyWindowsDevices.loaded(organizationId),
      error: state.organisationLegacyWindowsDevices.error[organizationId],
      lastUpdated: state.organisationLegacyWindowsDevices.lastUpdated[organizationId],
    }),
    [organizationId],
  );

  const { fetchDevices } = useActions<RootModel>((actions) => ({
    fetchDevices: actions.organisationLegacyWindowsDevices.fetch,
  }));

  const fetchLegacyWindowsDevices = useCallback(
    async ({ silent }: { silent: boolean }) => {
      await fetchDevices({ organizationId, silent });
    },
    [fetchDevices, organizationId],
  );

  return (
    <LegacyWindowsDevicesList
      app={app}
      customQuery={customQuery}
      enableSearch={enableSearch}
      lastUpdated={lastUpdated}
      devices={devices}
      loaded={loaded}
      fetchDevices={fetchLegacyWindowsDevices}
      error={error}
      onDeviceListItemClick={onDeviceListItemClick}
      renderExtraActions={renderExtraActions}
      disableDevices={disableDevices}
      autoScroll={autoScroll}
    />
  );
};

export default DevicesLegacyWindowsContainer;
