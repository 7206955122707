import React, { FocusEvent, useState, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import { Input, Icon } from 'antd';
import { SearchProps } from 'antd/lib/input';
import debounce from 'lodash/debounce';
import transientOptions from '../../../utils/transient-emotion-styled-options';

interface SearchInputProps extends SearchProps {
  onSearch: (value: string) => void;
  loading?: boolean;
  width?: string;
}

const SearchInput = (props: SearchInputProps) => {
  const [value, setValue] = useState('');
  const [isPendingOnSearch, setIsPendingOnSearch] = useState(false);
  const { onSearch, loading, width, ...restProps } = props;

  const onChange = useCallback((event: FocusEvent<HTMLInputElement>) => {
    setValue(event.target.value || '');
  }, []);

  useEffect(() => {
    setIsPendingOnSearch(true);
    const timeout = debounce(() => {
      onSearch(value);
      setIsPendingOnSearch(false);
    }, 1200);

    timeout();

    return () => {
      if (timeout) {
        timeout.cancel();
      }
    };
  }, [onSearch, value]);

  return (
    <InputStyled
      allowClear
      value={value}
      size="large"
      prefix={<Icon type="search" />}
      suffix={loading || isPendingOnSearch ? <Icon type="loading" /> : null}
      onChange={onChange}
      {...restProps}
      $width={width}
    />
  );
};

const InputStyled = styled(Input, transientOptions)<{ $width?: string }>`
  flex-grow: 1;
  ${({ $width }) => ($width ? `width: ${$width};` : '')}

  @media (max-width: 400px) {
    width: 100%;
  }
`;

export default SearchInput;
