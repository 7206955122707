import React, { useMemo } from 'react';
import {
  WeekHeatmapProps,
  WeekHeatmapSessionsProps,
  WeekHeatmapEventsProps,
} from './types';
import CardContainer from '../card-container';
import WeekHeatmap from '../../charts/week-heatmap';
import {
  useDeviceSessionsByHour,
  useDeviceEventsByHour,
} from '../../../use-analytics-report';
import { buildSessionsData, buildEventsData } from './generate';
import { WeekHeatmapDataSourceType } from '@ombori/grid-reports';

interface DeviceWeekHeatmapBySessionsProps extends WeekHeatmapSessionsProps {
  deviceId: string;
}

const DeviceWeekHeatmapBySessions: React.FC<DeviceWeekHeatmapBySessionsProps> = ({
  tenantId,
  deviceId,
  dateFrom,
  dateTo,
  dataResidency,
  title,
  dataSource,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const deviceSessionsState = useDeviceSessionsByHour({
    tenantId,
    deviceId,
    dateFrom,
    dateTo,
    dataResidency,
    interactionType: dataSource.interactionType,
  });

  const data = useMemo(() => buildSessionsData(deviceSessionsState.data || []), [
    deviceSessionsState,
  ]);

  return (
    <CardContainer
      isLoading={deviceSessionsState.isLoading}
      isSuccess={deviceSessionsState.isSuccess}
      isError={deviceSessionsState.isError}
      hasData={deviceSessionsState.isSuccess && deviceSessionsState.data.length > 0}
      isVisibleWithoutData={isVisibleWithoutData}
      title={title}
      gridStyles={gridStyles}
    >
      <WeekHeatmap data={data} />
    </CardContainer>
  );
};

interface DeviceWeekHeatmapByEventsProps extends WeekHeatmapEventsProps {
  deviceId: string;
}

const DeviceWeekHeatmapByEvents: React.FC<DeviceWeekHeatmapByEventsProps> = ({
  tenantId,
  deviceId,
  dateFrom,
  dateTo,
  dataResidency,
  title,
  dataSource,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const spaceEventsState = useDeviceEventsByHour({
    tenantId,
    deviceId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const data = useMemo(
    () => buildEventsData(spaceEventsState.data || [], dataSource.eventType),
    [spaceEventsState, dataSource],
  );

  return (
    <CardContainer
      isLoading={spaceEventsState.isLoading}
      isSuccess={spaceEventsState.isSuccess}
      isError={spaceEventsState.isError}
      hasData={spaceEventsState.isSuccess && spaceEventsState.data.length > 0}
      isVisibleWithoutData={isVisibleWithoutData}
      title={title}
      gridStyles={gridStyles}
    >
      <WeekHeatmap data={data} />
    </CardContainer>
  );
};

interface DeviceWeekHeatmapProps extends WeekHeatmapProps {
  deviceId: string;
}

const DeviceWeekHeatmap: React.FC<DeviceWeekHeatmapProps> = ({
  tenantId,
  deviceId,
  dateFrom,
  dateTo,
  title,
  dataResidency,
  dataSource,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const view = useMemo(() => {
    switch (dataSource.type) {
      case WeekHeatmapDataSourceType.Sessions:
        return (
          <DeviceWeekHeatmapBySessions
            tenantId={tenantId}
            deviceId={deviceId}
            dateFrom={dateFrom}
            dateTo={dateTo}
            title={title}
            dataResidency={dataResidency}
            dataSource={dataSource}
            gridStyles={gridStyles}
            isVisibleWithoutData={isVisibleWithoutData}
          />
        );
      case WeekHeatmapDataSourceType.Events:
        return (
          <DeviceWeekHeatmapByEvents
            tenantId={tenantId}
            deviceId={deviceId}
            dateFrom={dateFrom}
            dateTo={dateTo}
            title={title}
            dataResidency={dataResidency}
            dataSource={dataSource}
            gridStyles={gridStyles}
            isVisibleWithoutData={isVisibleWithoutData}
          />
        );
      default:
        throw new Error(`Unknown data source: ${JSON.stringify(dataSource)}`);
    }
  }, [
    tenantId,
    deviceId,
    dateFrom,
    dateTo,
    title,
    dataResidency,
    dataSource,
    gridStyles,
    isVisibleWithoutData,
  ]);

  return <>{view}</>;
};

export default DeviceWeekHeatmap;
