import Joi from 'joi';
import {
  AnalyticsSchema,
  Card,
  Group,
  GridStyles,
  CardType,
  InteractionType,
  EventsListCard,
  SessionsCard,
  EventsFlowCard,
  NpsCard,
  ProductsEventCountCard,
  CategoriesEventCountCard,
  EventsFunnelCard,
  WeekHeatmapCard,
  EventsCount,
  SessionInteractionType,
  WeekHeatmapDataSourceType,
  MonitoringRealtimeStatus,
  MonitoringRealtimeStatusAllowedDistinctColumnKeys,
  MonitoringStatusHistory,
  DataMatrixCard,
  AverageSalesCard,
  AverageTimeBetweenTransactionsCard,
  AverageNumberOfPurchasedProductsCard,
  PurchasedProductsEventCountCard,
  PurchasedProductsCategoriesEventCountCard,
  QrCodesCountCard,
  MediaCard,
  MediaCardPrimaryKeyType,
  ModulesStatusCard,
  ImageCard,
} from '@ombori/grid-reports';
import { gridStylesKeys } from '../types';
import {
  DataMatrixColumnDataType,
  DataMatrixColumnOperationType,
  DataMatrixDataSource,
  EventsSuccessFailureRateCard,
  SuccessFailureInputType,
} from '@ombori/grid-reports/dist/types';

const gridStylesSchema = Joi.object<GridStyles>({
  ...gridStylesKeys.reduce(
    (accumulator, key) => ({
      ...accumulator,
      [key]: Joi.alternatives(Joi.string(), Joi.number()),
    }),
    {},
  ),
});

const eventsListCardSchema = Joi.object<EventsListCard>({
  type: Joi.string()
    .valid(CardType.EventsList)
    .required(),
  interactionType: Joi.string()
    .valid(...Object.values(InteractionType))
    .optional(),
  gridStyles: gridStylesSchema.optional(),
  isVisibleWithoutData: Joi.boolean().optional(),
});

const sessionsCardSchema = Joi.object<SessionsCard>({
  type: Joi.string()
    .valid(CardType.Sessions)
    .required(),
  interactionType: Joi.string()
    .valid(...Object.values(SessionInteractionType))
    .optional(),
  gridStyles: gridStylesSchema.optional(),
  isVisibleWithoutData: Joi.boolean().optional(),
});

const eventsFlowCardSchema = Joi.object<EventsFlowCard>({
  type: Joi.string()
    .valid(CardType.EventsFlow)
    .required(),
  gridStyles: gridStylesSchema.optional(),
  isVisibleWithoutData: Joi.boolean().optional(),
});

const npsCardSchema = Joi.object<NpsCard>({
  type: Joi.string()
    .valid(CardType.Nps)
    .required(),
  gridStyles: gridStylesSchema.optional(),
  isVisibleWithoutData: Joi.boolean().optional(),
});

const productsEventCountCardSchema = Joi.object<ProductsEventCountCard>({
  type: Joi.string()
    .valid(CardType.ProductsEventCount)
    .required(),
  eventType: Joi.string().required(),
  title: Joi.string().required(),
  gridStyles: gridStylesSchema.optional(),
  isVisibleWithoutData: Joi.boolean().optional(),
});

const categoriesEventCountCardSchema = Joi.object<CategoriesEventCountCard>({
  type: Joi.string()
    .valid(CardType.CategoriesEventCount)
    .required(),
  gridStyles: gridStylesSchema.optional(),
  isVisibleWithoutData: Joi.boolean().optional(),
});

const averageSalesCardSchema = Joi.object<AverageSalesCard>({
  type: Joi.string()
    .valid(CardType.AverageSales)
    .required(),
  gridStyles: gridStylesSchema.optional(),
  isVisibleWithoutData: Joi.boolean().optional(),
});

const averageTimeBetweenTransactionsCardSchema = Joi.object<
  AverageTimeBetweenTransactionsCard
>({
  type: Joi.string()
    .valid(CardType.AverageTimeBetweenTransactions)
    .required(),
  gridStyles: gridStylesSchema.optional(),
  isVisibleWithoutData: Joi.boolean().optional(),
});

const averageNumberOfPurchasedProductsCardSchema = Joi.object<
  AverageNumberOfPurchasedProductsCard
>({
  type: Joi.string()
    .valid(CardType.AverageNumberOfPurchasedProducts)
    .required(),
  gridStyles: gridStylesSchema.optional(),
  isVisibleWithoutData: Joi.boolean().optional(),
});

const purchasedProductsEventCountCardSchema = Joi.object<PurchasedProductsEventCountCard>(
  {
    type: Joi.string()
      .valid(CardType.PurchasedProductsEventCount)
      .required(),
    gridStyles: gridStylesSchema.optional(),
    isVisibleWithoutData: Joi.boolean().optional(),
  },
);

const purchasedProductsCategoriesEventCountCardSchema = Joi.object<
  PurchasedProductsCategoriesEventCountCard
>({
  type: Joi.string()
    .valid(CardType.PurchasedProductsCategoriesEventCount)
    .required(),
  gridStyles: gridStylesSchema.optional(),
  isVisibleWithoutData: Joi.boolean().optional(),
});

const qrCodesCountCardSchema = Joi.object<QrCodesCountCard>({
  type: Joi.string()
    .valid(CardType.QrCodesCount)
    .required(),
  gridStyles: gridStylesSchema.optional(),
  isVisibleWithoutData: Joi.boolean().optional(),
});

const mediaCardSchema = Joi.object<MediaCard>({
  type: Joi.string()
    .valid(CardType.Media)
    .required(),
  title: Joi.string().optional(),
  gridStyles: gridStylesSchema.optional(),
  isVisibleWithoutData: Joi.boolean().optional(),
  primaryKey: Joi.string()
    .valid(...Object.values(MediaCardPrimaryKeyType))
    .required(),
});

const eventsFunnelCardSchema = Joi.object<EventsFunnelCard>({
  type: Joi.string()
    .valid(CardType.EventsFunnel)
    .required(),
  title: Joi.string().required(),
  events: Joi.array()
    .items(Joi.string())
    .required(),
  gridStyles: gridStylesSchema.optional(),
  isVisibleWithoutData: Joi.boolean().optional(),
});

const weekHeatmapCardSchema = Joi.object<WeekHeatmapCard>({
  type: Joi.string()
    .valid(CardType.WeekHeatmap)
    .required(),
  title: Joi.string().required(),
  dataSource: Joi.alternatives(
    Joi.object({
      type: Joi.string()
        .valid(WeekHeatmapDataSourceType.Sessions)
        .required(),
      interactionType: Joi.string()
        .valid(...Object.values(SessionInteractionType))
        .optional(),
    }),
    Joi.object({
      type: Joi.string()
        .valid(WeekHeatmapDataSourceType.Events)
        .required(),
      eventType: Joi.string().required(),
    }),
  ),
  gridStyles: gridStylesSchema.optional(),
  isVisibleWithoutData: Joi.boolean().optional(),
});

const monitoringRealtimeStatusSchema = Joi.object<MonitoringRealtimeStatus>({
  type: Joi.string()
    .valid(CardType.MonitoringRealtimeStatus)
    .required(),
  eventType: Joi.string().required(),
  distinctColumns: Joi.array()
    .items(Joi.string().valid(...MonitoringRealtimeStatusAllowedDistinctColumnKeys))
    .unique()
    .required(),
  gridStyles: gridStylesSchema.optional(),
  isVisibleWithoutData: Joi.boolean().optional(),
});

const monitoringStatusHistorySchema = Joi.object<MonitoringStatusHistory>({
  type: Joi.string()
    .valid(CardType.MonitoringStatusHistory)
    .required(),
  eventType: Joi.string().required(),
  gridStyles: gridStylesSchema.optional(),
  isVisibleWithoutData: Joi.boolean().optional(),
});

const eventsCountCardSchema = Joi.object<EventsCount>({
  type: Joi.string()
    .valid(CardType.EventsCount)
    .required(),
  eventType: Joi.string().required(),
  title: Joi.string().required(),
  gridStyles: gridStylesSchema.optional(),
  isVisibleWithoutData: Joi.boolean().optional(),
});

const eventsSuccessFailureCardSchema = Joi.object<EventsSuccessFailureRateCard>({
  type: Joi.string()
    .valid(CardType.EventsSuccessFailureRate)
    .required(),
  inputType: Joi.object({
    type: Joi.string()
      .valid(...Object.values(SuccessFailureInputType))
      .optional(),
  }),
  gridStyles: gridStylesSchema.optional(),
  isVisibleWithoutData: Joi.boolean().optional(),
});

const dataMatrixCardCardSchema = Joi.object<DataMatrixCard>({
  type: Joi.string()
    .valid(CardType.DataMatrix)
    .required(),
  dataSource: Joi.string()
    .valid(...Object.values(DataMatrixDataSource))
    .required(),
  columns: Joi.array().items(
    Joi.object({
      title: Joi.string().required(),
      dataType: Joi.object({
        type: Joi.string()
          .valid(...Object.values(DataMatrixColumnDataType))
          .required(),
        eventTypes: Joi.array()
          .items(Joi.string())
          .optional(),
        operationType: Joi.object({
          type: Joi.string()
            .valid(...Object.values(DataMatrixColumnOperationType))
            .optional(),
          distinctKey: Joi.string().optional(),
        }).optional(),
        totalData: Joi.object({
          eventTypes: Joi.array().items(Joi.string()),
        }).optional(),
      }).required(),
    }),
  ),
  gridStyles: gridStylesSchema.optional(),
  isVisibleWithoutData: Joi.boolean().optional(),
});

const modulesStatusSchema = Joi.object<ModulesStatusCard>({
  type: Joi.string().valid(CardType.ModulesStatus).required(),
  gridStyles: gridStylesSchema.optional(),
  isVisibleWithoutData: Joi.boolean().optional(),
})

const imageCardSchema = Joi.object<ImageCard>({
  type: Joi.string().valid(CardType.Image).required(),
  title: Joi.string().required(),
  imageProperty: Joi.string().required(),
});

const cardSchema = Joi.alternatives<Card>(
  eventsListCardSchema,
  sessionsCardSchema,
  eventsFlowCardSchema,
  npsCardSchema,
  productsEventCountCardSchema,
  categoriesEventCountCardSchema,
  eventsFunnelCardSchema,
  weekHeatmapCardSchema,
  eventsCountCardSchema,
  monitoringRealtimeStatusSchema,
  monitoringStatusHistorySchema,
  eventsSuccessFailureCardSchema,
  dataMatrixCardCardSchema,
  averageSalesCardSchema,
  averageTimeBetweenTransactionsCardSchema,
  averageNumberOfPurchasedProductsCardSchema,
  purchasedProductsEventCountCardSchema,
  purchasedProductsCategoriesEventCountCardSchema,
  qrCodesCountCardSchema,
  mediaCardSchema,
  modulesStatusSchema,
  imageCardSchema,
);

const groupSchema = Joi.object<Group>({
  name: Joi.string().required(),
  cards: Joi.array()
    .items(cardSchema)
    .required(),
  columnsCount: Joi.number().optional(),
  gridStyles: gridStylesSchema.optional(),
});

const validationSchema = Joi.object<AnalyticsSchema>({
  groups: Joi.array()
    .items(groupSchema)
    .required(),
});

export default validationSchema;
