import React, { useCallback, useEffect, useMemo } from 'react';
import { Avatar, List, message, Typography } from 'antd';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import CustomShortUrl from '../../../../store/types/custom-short-urls';
import CrudList from '../../../common/crud-list/crud-list.component';
import Header from '../../../common/app-layout/header/header.component';
import { getApiUrl } from '../../../../utils/env';

const { Text } = Typography;

interface CustomShortUrlsListProps
  extends RouteComponentProps<{ organisationId: string }> {
  customShortUrls: CustomShortUrl[];
  loaded: boolean;
  fetchCustomShortUrls: (params: { organizationId: string }) => void;
  createCustomShortUrl: (customShortUrl: Partial<CustomShortUrl>) => Promise<void>;
  updateCustomShortUrl: (customShortUrl: Partial<CustomShortUrl>) => Promise<void>;
  deleteCustomShortUrl: (id: string) => Promise<void>;
  canCreate: boolean;
  canUpdate: boolean;
  canDelete: boolean;
}

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 2px;
`;

const Label = styled(Text)`
  padding-top: 8px;
`;

const Link = styled.a`
  margin-left: 4px;
`;

const getCustomShortUrlData = (customShortUrl: Partial<CustomShortUrl>) => ({
  customId: customShortUrl.customId,
  displayName: customShortUrl.displayName,
  redirectUrl: customShortUrl.redirectUrl,
});

const CustomShortUrlsList = (props: CustomShortUrlsListProps) => {
  const {
    loaded,
    customShortUrls,
    fetchCustomShortUrls,
    createCustomShortUrl,
    updateCustomShortUrl,
    deleteCustomShortUrl,
    canCreate,
    canUpdate,
    canDelete,
    match: {
      params: { organisationId },
    },
  } = props;
  const { t } = useTranslation();

  const baseApiUrl = useMemo(() => getApiUrl(), []);

  useEffect(() => {
    fetchCustomShortUrls({ organizationId: organisationId });
  }, [organisationId, fetchCustomShortUrls]);

  const handleDelete = useCallback(
    async (customShortUrl: CustomShortUrl) => {
      await deleteCustomShortUrl(customShortUrl.id);
    },
    [deleteCustomShortUrl],
  );

  const handleEdit = useCallback(
    async (customShortUrl: Partial<CustomShortUrl>) => {
      await updateCustomShortUrl({
        ...getCustomShortUrlData(customShortUrl),
        id: customShortUrl.id,
        organizationId: organisationId,
      });
    },
    [organisationId, updateCustomShortUrl],
  );

  const handleCreate = useCallback(
    async (customShortUrl: Partial<CustomShortUrl>) => {
      try {
        await createCustomShortUrl({
          ...getCustomShortUrlData(customShortUrl),
          organizationId: organisationId,
        });
      } catch (err) {
        setTimeout(() => message.error(t('customIdTaken')), 700);
        throw new Error('');
      }
    },
    [createCustomShortUrl, organisationId, t],
  );

  const renderCustomShortUrlsListItem = useCallback(
    (customShortUrl: CustomShortUrl) => (
      <List.Item.Meta
        avatar={<Avatar icon="qrcode" />}
        title={customShortUrl.displayName}
        description={
          <DescriptionWrapper>
            <Label type="secondary">ID: {customShortUrl.id}</Label>
            <Label type="secondary">
              {`${t('redirectUrl')}: `}
              <Link href={customShortUrl.redirectUrl}>{customShortUrl.redirectUrl}</Link>
            </Label>
            <Label type="secondary">
              {`${t('shortUrl')}: `}
              <Link href={customShortUrl.shortUrl}>{customShortUrl.shortUrl}</Link>
            </Label>
            <Label type="secondary">
              {`${t('nfcEmbedUrl')}: `}
              <Link href={customShortUrl.shortUrl}>
                {`${customShortUrl.shortUrl}?nfc=1`}
              </Link>
            </Label>
            <Label>
              {`${t('printableQrCode')}: `}
              <Link
                href={`${baseApiUrl}/api/qr-code?url=${encodeURIComponent(
                  `${customShortUrl.shortUrl}?scan=1`,
                )}&width=1800&shorten=false`}
                download
              >
                {t('clickToDownload')}
              </Link>
            </Label>
          </DescriptionWrapper>
        }
      />
    ),
    [baseApiUrl, t],
  );

  const updateSchema = {
    type: 'object',
    properties: {
      displayName: {
        type: 'string',
        minLength: 1,
        title: t('displayName'),
      },
      redirectUrl: {
        type: 'string',
        title: t('redirectUrl'),
      },
    },
    required: ['displayName', 'redirectUrl'],
  };

  const createSchema = {
    ...updateSchema,
    properties: {
      customId: {
        type: 'string',
        minLength: 1,
        title: t('customId'),
        description: t('customIdRule'),
        pattern: '^[^\\s]*$',
      },
      ...updateSchema.properties,
    },
    required: [...updateSchema.required, 'customId'],
  };

  return (
    <>
      <Header title={t('customShortUrls')} />
      <div className="content-body">
        <CrudList<CustomShortUrl>
          onCreate={handleCreate}
          onEdit={handleEdit}
          onDelete={handleDelete}
          loaded={loaded}
          createSchema={createSchema}
          updateSchema={updateSchema}
          renderItem={renderCustomShortUrlsListItem}
          dataSource={customShortUrls}
          createButtonText={t('create')}
          modalTitle={t('customShortUrl')}
          canCreate={canCreate}
          canUpdate={canUpdate}
          canDelete={canDelete}
        />
      </div>
    </>
  );
};

export default CustomShortUrlsList;
