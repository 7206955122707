import { useStore } from 'easy-peasy';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RootModel } from '../../../store/models/root.model';
import ErrorView from '../error-view/error-view.component';
import Organisation from '../../../store/types/organisation';

const TenantAwareRoute = ({ render, component, ...props }: RouteProps) => {
  const {
    organisationsData,
    isSysAdmin,
  }: {
    organisationsData: { [id: string]: Organisation };
    isSysAdmin: boolean;
  } = useStore<RootModel>(
    (state) => ({
      organisationsData: state.organisations.data,
      isSysAdmin: state.auth.isSysAdmin,
    }),
    [],
  );
  const { t } = useTranslation();

  const renderRoute = useCallback(
    (routeProps: RouteComponentProps<{ organisationId: string }>) => {
      const {
        match: {
          params: { organisationId },
        },
        location: { pathname, search },
      } = routeProps;

      const organisation = organisationsData[organisationId];

      if (!organisationId || !organisation) {
        // eslint-disable-next-line no-console
        console.error(
          'organisationId match param and organisation data is required for SubscriptionAwareRoute',
        );
        return <ErrorView />;
      }

      if (!organisation.enabled && !isSysAdmin) {
        return (
          <ErrorView
            title={t('tenantDisabledTitle')}
            error={{ message: t('tenantDisabledDesc') }}
          />
        );
      }

      if (!organisation.hasActiveSubscription && !isSysAdmin) {
        return (
          <Redirect
            to={`/organisations/${organisationId}/subscription?returnPath=${encodeURIComponent(
              `${pathname}${search}`,
            )}`}
          />
        );
      }

      if (component) {
        const Component = component;
        return <Component {...routeProps} />;
      }

      if (render) {
        return render(routeProps);
      }

      return null;
    },
    [component, isSysAdmin, organisationsData, render, t],
  );

  return <Route {...props} render={renderRoute} />;
};

export default TenantAwareRoute;
