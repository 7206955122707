import React from 'react';
import { Field } from 'react-final-form';
import styled from '@emotion/styled';
import { Validator, composeValidators } from './validator';
import InputWrap, { InputWrapProps } from './input-wrap';
import { Icon } from '../schema-form/common';
import { requiredAll } from '../../organisations/organisation-details/queue-details/queue-setup/validate-form';
import { LinkText } from '../../organisations/organisation-details/store-ai/common';

interface SelectedFileProps extends InputWrapProps {
  className?: string;
  name?: string;
  validators?: Validator[];
}

const SelectedFile = (props: SelectedFileProps) => {
  const { className, name, validators = [], label, isRequired } = props;

  const inputValidators = [...(isRequired ? [requiredAll] : []), ...validators];

  return (
    <SelectedFileContainer className={className}>
      <IconStyled type="paper-clip" size={18} />
      <FieldsWrap>
        <Field name={`${name}.fileName`} validate={composeValidators(inputValidators)}>
          {({ input, meta }) => {
            const isError = !!meta.error && meta.touched;

            return (
              <InputWrap
                className="selected-file-name"
                label={label}
                isRequired={isRequired}
                error={isError ? meta.error : undefined}
              >
                <Filename>{input.value}</Filename>
              </InputWrap>
            );
          }}
        </Field>
        <Field name={`${name}.fileId`} validate={composeValidators(inputValidators)}>
          {({ meta }) => {
            const isError = !!meta.error && meta.touched;

            return (
              <InputWrap
                className="selected-file-id"
                isRequired={isRequired}
                error={isError ? meta.error : undefined}
              />
            );
          }}
        </Field>
      </FieldsWrap>
    </SelectedFileContainer>
  );
};

const SelectedFileContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-grow: 1;
`;

const FieldsWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const IconStyled = styled(Icon)`
  margin: auto 0;
`;

const Filename = styled(LinkText)`
  color: rgba(0, 0, 0, 0.65) !important;
  cursor: default;

  &:hover,
  &:focus {
    color: rgba(0, 0, 0, 0.65) !important;
  }

  &:active {
    color: rgba(0, 0, 0, 0.65) !important;
  }
`;

export default SelectedFile;
