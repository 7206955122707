import React, { useCallback, useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { Redirect, RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import Header, { Subheader } from '../../../common/app-layout/header';
import { Icon } from '../../../common/schema-form/common';
import TabBar from '../../../common/tab-bar/tab-bar.component';
import ErrorBoundary from '../../../common/error-boundary/error-boundary.component';
import ProtectedRouteContainer from '../../../common/protected-route/protected-route.container';
import ContentLibraryContainer from './library/content-library.container';
import OrganisationMediaChannelsListComponent
  from '../organisation-media-library/organisation-media-channels/organisation-media-channels-list.component';
import OrganisationMediaSegmentsListComponent
  from '../organisation-media-library/organisation-media-segments/organisation-media-segments-list.component';
import OrganisationMediaTagsListComponent
  from '../organisation-media-library/organisation-media-tags/organisation-media-tags-list.component';
import ChannelCreateForm from './channels/channel-create-form.component';
import ChannelEditForm from './channels/channel-edit-form.component';
import SegmentCreateForm from './segments/segment-create-form.component';
import permissionKeys, { getPermissionPath } from '../../../../utils/auth/permissions';
import TagCreateForm from './tags/tag-create-form.component';
import TagEditForm from './tags/tag-edit-form.component';
import SegmentCloneForm from './segments/segment-clone-form.component';
import SegmentEditForm from './segments/segment-edit-form.component';

interface ContentContainerProps extends RouteComponentProps<{ organisationId: string }> {};

enum MenuKeysEnum {
  LIBRARY = 'library',
  CHANNELS = 'channels',
  TAGS = 'tags',
};

const MenuTabs = {
  [MenuKeysEnum.LIBRARY]: {
    path: '/library',
  },
  [MenuKeysEnum.CHANNELS]: {
    path: '/channels',
  },
  [MenuKeysEnum.TAGS]: {
    path: '/tags',
  },
};

const ContentContainer = (props: ContentContainerProps) => {
  const { match, history, location } = props;

  const { organisationId } = match.params;

  const { t } = useTranslation();

  const [tabKey, setTabKey] = useState('');

  useEffect(() => {
    const key = location.pathname.replace(match.url, '').split('/')[1];
    setTabKey(key);
  }, [match.url, location]);

  const handleTabChange = useCallback((key: string) => {
    const tab = MenuTabs[key as MenuKeysEnum];
    setTabKey(key);

    history.push(`${match.url}${tab.path}`);
  }, [history, match.url]);

  return (
    <>
      <Header
        tabTitle={t('contents.content')}
        title={t('contents.content')}
        icon={<Icon type="picture" color="white" />}
      />
      <Subheader
        components={[
          <TabBar key="content" activeKey={tabKey} onChange={handleTabChange}>
            <Tabs.TabPane key={MenuKeysEnum.LIBRARY} tab={<span>{t('contents.library')}</span>} />

            <Tabs.TabPane key={MenuKeysEnum.CHANNELS} tab={<span>{t('contents.channels')}</span>} />

            <Tabs.TabPane key={MenuKeysEnum.TAGS} tab={<span>{t('contents.tags')}</span>} />
          </TabBar>,
        ]}
      />

      <ErrorBoundary>
        <ProtectedRouteContainer
          exact
          permissionPath={getPermissionPath(
            organisationId,
            permissionKeys.media.viewAll,
          )}
          path={`${match.path}/library/:selectedFolderId?`}
          component={ContentLibraryContainer}
        />

        <ProtectedRouteContainer
          exact
          permissionPath={getPermissionPath(
            organisationId,
            permissionKeys.contentChannels.viewAll,
          )}
          path={`${match.path}/channels`}
          component={OrganisationMediaChannelsListComponent}
        />

        <ProtectedRouteContainer
          exact
          permissionPath={getPermissionPath(
            organisationId,
            permissionKeys.contentChannels.create,
          )}
          path={`${match.path}/channels/create`}
          component={ChannelCreateForm}
        />

        <ProtectedRouteContainer
          exact
          permissionPath={getPermissionPath(
            organisationId,
            permissionKeys.contentChannels.update,
          )}
          path={`${match.path}/channels/update/:channelId`}
          component={ChannelEditForm}
        />

        <ProtectedRouteContainer
          exact
          permissionPath={getPermissionPath(
            organisationId,
            permissionKeys.contentSegments.viewAll,
          )}
          path={`${match.path}/channels/:channelId/segments`}
          component={OrganisationMediaSegmentsListComponent}
        />

        <ProtectedRouteContainer
          exact
          permissionPath={getPermissionPath(
            organisationId,
            permissionKeys.contentSegments.create,
          )}
          path={`${match.path}/channels/:channelId/segments/create`}
          component={SegmentCreateForm}
        />

        <ProtectedRouteContainer
          exact
          permissionPath={getPermissionPath(
            organisationId,
            permissionKeys.contentSegments.create,
          )}
          path={`${match.path}/channels/:channelId/segments/clone/:segmentId`}
          component={SegmentCloneForm}
        />

        <ProtectedRouteContainer
          exact
          permissionPath={getPermissionPath(
            organisationId,
            permissionKeys.contentSegments.create,
          )}
          path={`${match.path}/channels/:channelId/segments/edit/:segmentId`}
          component={SegmentEditForm}
        />

        <ProtectedRouteContainer
          exact
          permissionPath={getPermissionPath(
            organisationId,
            permissionKeys.contentTags.viewAll,
          )}
          path={`${match.path}/tags`}
          component={OrganisationMediaTagsListComponent}
        />

        <ProtectedRouteContainer
          exact
          permissionPath={getPermissionPath(
            organisationId,
            permissionKeys.contentTags.create,
          )}
          path={`${match.path}/tags/create`}
          component={TagCreateForm}
        />

        <ProtectedRouteContainer
          exact
          permissionPath={getPermissionPath(
            organisationId,
            permissionKeys.contentTags.update,
          )}
          path={`${match.path}/tags/update/:tagId`}
          component={TagEditForm}
        />

        <ProtectedRouteContainer
          exact
          permissionPath={getPermissionPath(
            organisationId,
            permissionKeys.media.viewAll,
          )}
          path={match.path}
          render={() => <Redirect to={`${match.url}/library`} />}
        />
      </ErrorBoundary>
    </>
  );
};

export default ContentContainer;
