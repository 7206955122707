import isArray from 'lodash/isArray';

export default function transformDeviceExpectedAvailability(data: any) {
  if (data && data.deviceExpectedAvailability && data.deviceExpectedAvailability.days) {
    // For non-iothub devices, days is array of numbers
    // For iothub devices, days is array of numbers as string
    // To Do: If iothub devices implements array of numbers, make sure to remove the unnecessary condition
    const parsedDays = isArray(data.deviceExpectedAvailability.days)
      ? data.deviceExpectedAvailability.days
      : JSON.parse(data.deviceExpectedAvailability.days);

    if (
      data.deviceTags &&
      data.deviceTags.deviceExpectedAvailability &&
      data.deviceTags.deviceExpectedAvailability.days
    ) {
      const { days } = data.deviceTags.deviceExpectedAvailability;
      const parsedTagsDays = isArray(days) ? days : JSON.parse(days);

      return {
        ...data,
        deviceExpectedAvailability: {
          ...data.deviceExpectedAvailability,
          days: Array.isArray(parsedDays) ? parsedDays : [],
        },
        deviceTags: {
          ...data.deviceTags,
          deviceExpectedAvailability: {
            ...data.deviceTags.deviceExpectedAvailability,
            days: Array.isArray(parsedTagsDays) ? parsedTagsDays : [],
          },
        },
      };
    }
    return {
      ...data,
      deviceExpectedAvailability: {
        ...data.deviceExpectedAvailability,
        days: Array.isArray(parsedDays) ? parsedDays : [],
      },
    };
  }

  return data;
}
