import { useMutation, UseMutationResult } from 'react-query';
import { ApiError } from '../../../../../../services/api/api-error';
import { IntegrationSettings } from '../types';
import { useGridProductsFeedService } from '../../../../../common/grid-products-connector-service-provider';
import { DataResidencyEnum } from '../../../../../../store/types/organisation';

interface UseDeleteIntegrationProps {
  tenantId: string;
  dataResidency: DataResidencyEnum;
}

type UseAddIntegrationResponse = UseMutationResult<IntegrationSettings, ApiError, string>;

const useDeleteIntegration = (
  props: UseDeleteIntegrationProps,
): UseAddIntegrationResponse => {
  const { tenantId, dataResidency } = props;

  const gridProductsFeedService = useGridProductsFeedService(dataResidency);

  const result = useMutation(
    async (id: string) => {
      const url = `/${tenantId}/integrations/${id}`;

      return gridProductsFeedService.delete<IntegrationSettings>(url);
    },
    {
      onError: (_error: ApiError) => {},
    },
  );

  return result;
};

export default useDeleteIntegration;
