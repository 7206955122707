import React from 'react';
import styled from '@emotion/styled';
import { Typography, Badge, Row, Col } from 'antd';
import { formatAmount } from '../../../../../utils/currency';
import { BillingCurrencyEnum } from '../../../../../store/types/organisation';

const { Text } = Typography;

interface TotalsExtraProps {
  total: { [currency: string]: { count: number; sum: number } };
  countColor?: { foreground: string; background: string };
}

const TotalsExtra = (props: TotalsExtraProps) => {
  const { total = {}, countColor } = props;
  const { foreground = '#010101a6', background = '#dddddd' } = countColor || {};

  if (!Object.keys(total).length) {
    return (
      <FilterStat>
        <Row>
          <Col span={20} />
          <Col span={4}>
            <CountBadge
              foregroundcolor={foreground}
              backgroundcolor={background}
              count={0}
              showZero
            />
          </Col>
        </Row>
      </FilterStat>
    );
  }

  return (
    <FilterStat>
      {Object.keys(total).map((currency: string) => {
        const { count, sum } = total[currency];
        const amount =
          currency in BillingCurrencyEnum ? formatAmount(currency, sum) : '-';

        return (
          <Row key={currency}>
            <Col span={20}>
              <Amount>{amount}</Amount>
            </Col>
            <Col span={4}>
              <CountBadge
                foregroundcolor={foreground}
                backgroundcolor={background}
                count={count}
              />
            </Col>
          </Row>
        );
      })}
    </FilterStat>
  );
};

const FilterStat = styled.div`
  margin-right: 0px;
  margin-left: auto;
  position: relative;
  text-align: right;
  width: 50%;
`;

const Amount = styled(Text)`
  margin-right: 5px;
`;

// eslint-disable-next-line no-unexpected-multiline
const CountBadge = styled(Badge)<{
  foregroundcolor?: string;
  backgroundcolor?: string;
}>`
  .ant-badge-count {
    ${({ foregroundcolor }) => foregroundcolor && `color: ${foregroundcolor};`}
    ${({ backgroundcolor }) => backgroundcolor && `background-color: ${backgroundcolor};`}
  }
`;

export default React.memo(TotalsExtra);
