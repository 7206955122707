import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { PaginationSearchParam } from '../../../../store/types/pagination';

interface UsePaginationResponse {
  page: number;
  pageSize: number;
  searchParams: PaginationSearchParam;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  setSearchParams: Dispatch<SetStateAction<PaginationSearchParam>>;
  defaultPage: number;
  defaultPageSize: number;
  defaultPageSizeOption: number[];
}

interface UsePaginationProps {
  defaultPage?: number;
  defaultPageSize?: number;
  defaultPageSizeOption?: number[];
}

const usePagination = (props?: UsePaginationProps): UsePaginationResponse => {
  const {
    defaultPage = 1,
    defaultPageSize = 20,
    defaultPageSizeOption = [5, 20, 50, 100],
  } = (props as UsePaginationResponse) || {};

  const [pageNum, setPageNum] = useState<number>(defaultPage);
  const [pageSizeNum, setPageSizeNum] = useState<number>(defaultPageSize);
  const [searchParams, setSearchParams] = useState<PaginationSearchParam>({});

  const setPage = useCallback((page: number) => setPageNum(page), []);
  const setPageSize = useCallback((pageSize: number) => setPageSizeNum(pageSize), []);

  return {
    page: pageNum,
    pageSize: pageSizeNum,
    searchParams,
    setPage,
    setPageSize,
    setSearchParams,
    defaultPage,
    defaultPageSize,
    defaultPageSizeOption,
  };
};

export default usePagination;
