import { getApiUrl } from './env';

const getRedirectUrl = (path: string) =>
  /^https?:\/\//i.test(path)
    ? path
    : `${document.location.protocol}//${document.location.host}${path}`;

export const getOnboardingLoginUrl = ({
  email,
  returnPath,
  fallbackPath,
}: {
  email: string;
  returnPath: string;
  fallbackPath: string;
}) => {
  const returnUrl = getRedirectUrl(returnPath);
  const fallbackUrl = getRedirectUrl(fallbackPath);

  return `${getApiUrl()}/api/onboarding/login?email=${encodeURIComponent(
    email,
  )}&returnPath=${encodeURIComponent(returnUrl)}&fallbackPath=${encodeURIComponent(
    fallbackUrl,
  )}`;
};

export default {
  getOnboardingLoginUrl,
};
