import React, { useMemo, useCallback } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import styled from '@emotion/styled';
import { ButtonType } from '../../../../types';
import { RouteComponentProps } from 'react-router';
import SkuFormFields, {
  PricesFieldsForAllCurrencies,
  SkuFormValues,
  SkuApiValidationErrors,
} from './sku-form-fields';
import {
  SkuAvailability,
  SkuPayload,
  useAddSku,
} from '../../../common/use-skus';
import Message from '../../../common/message';
import { ApiError } from '../../../../services/api/api-error';
import useGoBack from '../../../common/use-go-back';
import { SkuRevenueTypeOptions, SkuUnitOptions } from './common/sku-field-options';
import { extractApiValidationErrors } from '../../../common/react-final-form';

const SkuCreateForm = (props: RouteComponentProps) => {
  const { t } = useTranslation();

  const { history } = props;

  const initialValues = useMemo<SkuFormValues>(() => {
    return {
      sku: '',
      name: '',
      priceInformation: [
        {
          startDate: '',
          endDate: '',
          prices: PricesFieldsForAllCurrencies,
        },
      ],
      availability: SkuAvailability.PRIVATE,
      unit: SkuUnitOptions[0].value,
      revenueType: SkuRevenueTypeOptions[0].value,
    };
  }, []);

  const { mutateAsync: addSku } = useAddSku();

  const onFormSubmit = useCallback(
    async (values: SkuPayload | Object) => {
      try {
        const {
          sku,
          name,
          unit,
          availability,
          tags,
          revenueType,
          priceInformation,
        } = values as SkuPayload;

        const newSku = await addSku({
          sku,
          name,
          unit,
          availability,
          ...(tags ? { tags } : {}),
          revenueType,
          priceInformation,
        });

        message.success(<Message content="SKU successfully saved!" />);

        history.replace(`/grid/billing/skus/${newSku.id}`);

        return null;
      } catch (error) {
        const validationErrors = extractApiValidationErrors<SkuApiValidationErrors>(error as ApiError);
        return validationErrors;
      }
    },
    [addSku, history],
  );

  const goBack = useGoBack();

  const handleGoBack = useCallback(() => {
    goBack();
  }, [goBack]);

  return (
    <>
      <SkuFormContainer>
        <BackToListButton
          type="default"
          icon="arrow-left"
          onClick={handleGoBack}
        >
          {t('back')}
        </BackToListButton>
        <SkuFormFields
          title={t('billingSkus.addSku')}
          initialValues={initialValues}
          onFormSubmit={onFormSubmit}
        />
      </SkuFormContainer>
    </>
  );
};

const SkuFormContainer = styled.div`
  position: relative;
  flex: 1;
  padding: 30px 80px 40px;

  @media screen and (max-width: 991px) {
    padding: 0;
  }
`;

const BackToListButton = styled(Button)`
  height: 36px;
  font-size: 14px;
  color: #676973;
  border: none;
  margin-right: 5px;
  background-color: transparent;

  :hover,
  :active,
  :focus {
    background-color: #f2f4f8;
  }
` as ButtonType;

export default SkuCreateForm;
