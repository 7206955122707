import React, { useEffect } from 'react';
import WithMenu from '../common/with-menu/with-menu.component';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const WithAdminMenu = (props: RouteComponentProps & { children: any }) => {
  const { children, ...restProps } = props;
  const { t } = useTranslation();

  // Google analytics
  useEffect(() => {
    document.cookie = `tenant=${encodeURIComponent('grid-overview')}; expires=0; path=/`;
  }, []);

  const adminMenuItems = [
    {
      label: t('organizations'),
      icon: 'solution',
      path: '/grid/organisations',
    },
    {
      label: t('users'),
      icon: 'user',
      path: '/grid/users',
    },
    {
      label: t('actionLogs'),
      icon: 'file-search',
      path: '/grid/action-logs',
    },
    {
      label: t('federatedDomains'),
      icon: 'safety-certificate',
      path: '/grid/federated-domains',
    },
    {
      label: t('deviceMonitoring.title'),
      icon: 'monitor',
      path: '/grid/monitoring',
    },
    {
      label: t('billing.title'),
      icon: 'credit-card',
      path: '/grid/billing'
    },
    {
      label: t('globalConfigurations'),
      icon: 'setting',
      path: '/grid/global-configuration',
    }
  ]

  return <WithMenu {...restProps} title={t('adminTools')} menuItems={adminMenuItems}>
    {children}
  </WithMenu>
}

export default WithAdminMenu;
