import React from 'react';
import { AppReport } from '../../../overview-report';
import {
  AnalyticsSchema,
  CardType,
  InteractionType,
  SessionInteractionType,
  WeekHeatmapDataSourceType,
} from '@ombori/grid-reports';
import PanelCard from '../../../panel-card/panel-card.component';

const GridappOverview = () => {
  const reportSchema: AnalyticsSchema = {
    groups: [
      {
        name: 'Overview',
        cards: [
          {
            type: CardType.Sessions,
            interactionType: SessionInteractionType.Interactive,
          },
          { type: CardType.Nps },
          { type: CardType.EventsList, interactionType: InteractionType.Interactive },
          {
            type: CardType.EventsFunnel,
            title: 'Sales conversions funnel',
            events: ['CATEGORY_VIEW', 'PRODUCT_VIEW', 'CART_ADD', 'CHECKOUT', 'PURCHASE'],
          },
          {
            type: CardType.ProductsEventCount,
            eventType: 'PRODUCT_VIEW',
            title: 'Product views',
          },
          { type: CardType.CategoriesEventCount },
        ],
      },
      {
        name: 'Events flow',
        cards: [{ type: CardType.EventsFlow }],
        columnsCount: 1,
      },
      {
        name: 'Sessions week heatmap',
        cards: [
          {
            type: CardType.WeekHeatmap,
            title: 'Sessions week heatmap',
            dataSource: {
              type: WeekHeatmapDataSourceType.Sessions,
              interactionType: SessionInteractionType.Interactive,
            },
          },
        ],
        columnsCount: 1,
      },
    ],
  };

  return (
    <PanelCard
      bodyStyle={{
        padding: 16,
      }}
    >
      <AppReport schema={reportSchema} />
    </PanelCard>
  );
};

export default GridappOverview;
