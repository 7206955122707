import React from 'react';
import styled from '@emotion/styled';
import { Icon } from 'antd';
import { green, red } from '@ant-design/colors';
import numeral from 'numeral';

type Size = 'sm' | 'md' | 'lg';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  value: string | number | React.ReactNode;
  flag?: 'up' | 'down';
  size?: Size;
}

export default function LabeledValue({
  label,
  value,
  flag,
  size = 'sm',
  ...props
}: Props) {
  return (
    <Container {...props} size={size}>
      <Label>{label}</Label>
      <Value size={size}>
        {typeof value === 'number' ? numeral(value).format('0,0') : value}
      </Value>
      {flag === 'up' && (
        <Icon
          type="caret-up"
          style={{
            color: green.primary,
            fontSize: 10,
            marginLeft: 4,
          }}
        />
      )}
      {flag === 'down' && (
        <Icon
          type="caret-down"
          style={{
            color: red.primary,
            fontSize: 10,
            marginLeft: 4,
          }}
        />
      )}
    </Container>
  );
}

const Container = styled.div<{ size: Size }>`
  display: flex;
  flex-direction: ${({ size }) => (size === 'sm' ? 'row' : 'column')};
  align-items: ${({ size }) => (size === 'sm' ? 'center' : 'flex-start')};
`;

const Label = styled.span`
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.65);
`;

const Value = styled.span<{ size: Size }>`
  font-size: ${({ size }) => ({ sm: 14, md: 24, lg: 30 }[size])}px;
  line-height: ${({ size }) => ({ sm: 22, md: 32, lg: 38 }[size])}px;
  color: rgba(0, 0, 0, 0.85);
  margin: ${({ size }) => (size === 'sm' ? '0 0 0 8px' : ' 4px 0 0 0')};
`;
