export enum VideoAnalysisResultValue {
  notSupported = 'NotSupported',
  conversionNotNeeded = 'ConversionNotNeeded',
  conversionRequired = 'ConversionRequired',
}

export enum VideoVariantState {
  scheduled = 'Scheduled',
  processing = 'Processing',
  finished = 'Finished',
  error = 'Error',
}

export interface VideoAnalysisResult {
  value: string;
  presets?: string[];
}

export interface VideoVariant {
  preset: string;
  state: string;
  url?: string;
}

interface VideoDuration {
  duration: number;
  durationFormatted: string;
}

export enum ImageMimeType {
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  SVG = 'image/svg+xml',
  GIF = 'image/gif',
  WEBP = 'image/webp',
}
export enum AudioMimeType {
  MPEG = 'audio/mpeg',
  WAV = 'audio/wav',
  WEBM = 'audio/webm',
}
export enum FontMimeType {
  OTF = 'font/otf',
  TTF = 'font/ttf',
  WOFF = 'font/woff',
  WOFF2 = 'font/woff2',
}
export enum VideoMimeType {
  MP4 = 'video/mp4',
  WEBM = 'video/webm',
}
export enum ApplicatonMimeType {
  JSON = 'application/json',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  XLS = 'application/vnd.ms-excel',
  PDF = 'application/pdf',
}
export enum TextMimeType {
  TEXT = 'text/plain',
}

export type MimeType = ImageMimeType | VideoMimeType | AudioMimeType | FontMimeType | ApplicatonMimeType | TextMimeType;

export interface FileImageSettings {
  altText?: string;
}

export interface FileVideoSettings {
  isLoop?: boolean;
  autoPlay?: boolean;
  volume?: number;
}

export type FileSettings = FileImageSettings | FileVideoSettings;

export interface MediaMetadata {
  [key: string]: any;
  videoAnalysisResult?: VideoAnalysisResult;
  videoVariants?: Record<string, VideoVariant | undefined>;
  videoDuration?: VideoDuration;
}

export default interface Media {
  createdAt: string;
  id: string;
  name: string;
  organization: string;
  size: number;
  type: string | MimeType;
  updatedAt: string;
  url: string;
  uuid: string;
  thumbnailUrl?: string;
  ancestors?: string[];
  folderId?: string;
  metadata?: MediaMetadata;
  settings?: FileSettings;
}
