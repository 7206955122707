import React from 'react';
import styled from '@emotion/styled';
import { Select, Row, Typography } from 'antd';
import { Field } from 'react-final-form';
import { ArrayFields, InputWrap } from '../../../common/react-final-form';
import { useTranslation } from 'react-i18next';
import { Sku } from '../../../common/use-skus';
import { PlanFormItem } from './plan-form.component';
import { requiredAll } from '../../../organisations/organisation-details/queue-details/queue-setup/validate-form';
import { ChangeEvent } from '../../../../types';
import Dimension from '../../../../store/types/dimension';
import { OnChange } from 'react-final-form-listeners';
import { Col } from 'antd/es/grid';
import { FormTextInput } from '../../../organisations/organisation-details/queue-details/queue-setup/styled-block';

interface PlanFormItemsSectionProps {
  skus: Sku[];
  dimensions: Dimension[];
}

const PlanFormItemsSection = (props: PlanFormItemsSectionProps) => {
  const { skus, dimensions } = props;

  const { t } = useTranslation();

  return (
    <PlanItemsContainer>
      <Row>
        <Typography.Title level={4}>Items</Typography.Title>
        <ArrayFields<PlanFormItem>
          propertyName="items"
          defaultValue={{
            dimensionId: '',
            includedQuantity: 0,
            skuConfiguration: {
              defaultSku: '',
            },
          }}
          itemRowContent={(parentPropertyName: string) => {
            return (
              <ItemsContainer gutter={[20, 20]}>
                <Col span={8}>
                  <Field
                    name={`${parentPropertyName}.dimensionId`}
                    validate={requiredAll}
                    render={({ input, meta }) => (
                      <InputWrap
                        isRequired={true}
                        error={meta.touched && meta.error ? meta.error : null}
                        label={t('plans.form.dimension')}
                      >
                        {/* to reset dropdown selection when plan type is changed as it'll also update dropdown options */}
                        <OnChange name="type">
                          {() => {
                            input.onChange({
                              target: {
                                value: '',
                              },
                            } as unknown as ChangeEvent<string>);
                          }}
                        </OnChange>

                        <Select
                          value={input.value ? input.value : undefined}
                          disabled={!dimensions || !dimensions.length}
                          placeholder={'Please select'}
                          onChange={(value) => {
                            input.onChange({
                              target: {
                                value,
                              },
                            } as unknown as ChangeEvent<string>);
                          }}
                        >
                          {dimensions &&
                            dimensions.map((dimension) => (
                              <Select.Option
                                key={dimension.id}
                                value={dimension.id}
                              >
                                {dimension.name.en}
                              </Select.Option>
                            ))}
                        </Select>
                      </InputWrap>
                    )}
                  />
                </Col>

                <Col span={6}>
                  <Field
                    name={`${parentPropertyName}.includedQuantity`}
                    render={({ input, meta }) => (
                      <FormTextInput
                        input={input}
                        meta={meta}
                        label={t('plans.form.includedQuantity')}
                        type="number"
                        min="0"
                        max="999999"
                      />
                    )}
                  />
                </Col>

                <Col span={10}>
                  <Field
                    name={`${parentPropertyName}.skuConfiguration.defaultSku`}
                    render={({ input, meta }) => (
                      <InputWrap label={t('plans.form.customSkuOverride')}>
                        <Select
                          defaultValue={input.value ? input.value : undefined}
                          disabled={!skus || !skus.length}
                          placeholder={'Please select'}
                          allowClear={true}
                          showSearch={true}
                          onChange={(value) => {
                            input.onChange({
                              target: {
                                value,
                              },
                            } as unknown as ChangeEvent<string>);
                          }}
                        >
                          {skus &&
                            skus.map(({ sku }) => (
                              <Select.Option key={sku} value={sku}>
                                {sku}
                              </Select.Option>
                            ))}
                        </Select>
                      </InputWrap>
                    )}
                  />
                </Col>
              </ItemsContainer>
            );
          }}
        />
      </Row>
    </PlanItemsContainer>
  );
};

export default PlanFormItemsSection;

const PlanItemsContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ItemsContainer = styled(Row)`
  width: 100% !important;
  display: flex;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
`;
