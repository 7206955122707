import React, { useMemo, useCallback } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from '@emotion/styled';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useGoBack from '../../../common/use-go-back';
import { ButtonType } from '../../../../types';
import PlanForm, { PlanApiValidationErrors, PlanFormValues } from './plan-form.component';
import { PlanTypeEnum } from '../../../../store/types/organisation-plan';
import usePlanCreate from '../../../../store/hooks/plans/use-plan-create';
import Message from '../../../common/message/message.component';
import { ApiError } from '../../../../services/api/api-error';
import { extractApiValidationErrors } from '../../../common/react-final-form';

interface PlanCreateFormProps extends RouteComponentProps {}

const PlanCreateForm = (props: PlanCreateFormProps) => {
  const { history } = props;

  const { t } = useTranslation();

  const goBack = useGoBack();

  const initialValues = useMemo<PlanFormValues>(() => {
    return {
      sku: '',
      type: PlanTypeEnum.SUPPORT,
      name: { en: '' },
      description: { en: '' },
      isDefault: false,
      icon: 'https://omborigrid.com/hubfs/raw_assets/public/ombori-theme/assets/ombori-grid-logotype.svg',
      items: [],
      features: [],
    };
  }, []);

  const handleGoBack = useCallback(() => {
    goBack();
  }, [goBack]);

  const { mutateAsync: createPlan } = usePlanCreate();

  const onFormSubmit = useCallback(
    async (values: PlanFormValues | Object) => {
      try {
        const {
          sku,
          type,
          name,
          description,
          isDefault,
          icon,
          items,
          features,
        } = values as PlanFormValues;

        const newPlan = await createPlan({
          sku,
          type,
          name,
          description,
          isDefault,
          icon,
          items,
          features,
        });

        message.success(<Message content={t('plans.form.planAddSuccess')} />);

        history.replace(`/grid/billing/plans/${newPlan.id}`);

        return {};
      } catch (error) {
        message.error(<Message content={t('plans.form.planAddFailed')} />);

        const errors = extractApiValidationErrors<PlanApiValidationErrors>(error as ApiError);
        return errors;
      }
    },
    [t, createPlan, history],
  );

  return (
    <>
      <PlanFormContainer>
        <BackToListButton
          type="default"
          icon="arrow-left"
          onClick={handleGoBack}
        >
          {t('back')}
        </BackToListButton>
        <PlanForm
          title={t('plans.list.addPlan')}
          initialValues={initialValues}
          onFormSubmit={onFormSubmit}
        />
      </PlanFormContainer>
    </>
  );
};

export default PlanCreateForm;

const PlanFormContainer = styled.div`
  position: relative;
  flex: 1;
  padding: 30px 80px 40px;

  @media screen and (max-width: 991px) {
    padding: 0;
  }
`;

const BackToListButton = styled(Button)`
  height: 36px;
  font-size: 14px;
  color: #676973;
  border: none;
  margin-right: 5px;
  background-color: transparent;

  :hover,
  :active,
  :focus {
    background-color: #f2f4f8;
  }
` as ButtonType;
