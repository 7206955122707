import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Router } from 'react-router-dom';
import { StoreProvider } from 'easy-peasy';
import * as Sentry from '@sentry/browser';
import { createBrowserHistory } from 'history';
import { css, Global } from '@emotion/core';
import emotionMinireset from 'emotion-minireset';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import initializeStore from './store/initialize-store';
import AppContainer from './app/app.container';
import ApiService from './services/api/api.service';
import { getApiUrl, getEnv } from './utils/env';
import ErrorBoundary from './app/common/error-boundary/error-boundary.component';
import SchemaFormStyleGlobal from './app/common/schema-form/schema-form-style-global.component';
import SocketService from './services/socket/socket.service';
import GridDataServiceService from './services/api/grid-data-service';
import GridProducts from './services/api/grid-products';
import AppThemeProvider from './utils/use-app-theme';
import ApiServiceProvider from './app/common/api-service-provider';
import GridDataServiceProvider from './app/common/grid-data-service-provider';
import GridProductsServiceProvider from './app/common/grid-products-service-provider';
import GridRndAIProvider from './app/common/grid-rndai-provider';
import GridRndAI from './services/api/omg-rndai';
import GridProductsConnectorServiceProvider from './app/common/grid-products-connector-service-provider';
import GridProductsFeedService from './services/api/grid-products-feed-service';
import AnalyticsParamsProvider from './app/common/use-analytics-params';
import GATracker from './ga-tracker';

const queryClient = new QueryClient();

/** We do not want sentry logs for local and dev */
if (getEnv() !== 'dev' && getEnv() !== 'local') {
  Sentry.init({
    dsn: 'https://13cb949ad12946e097fab02eb3e5a343@sentry.io/1468913',
    environment: getEnv() as string,
  });
}

dayjs.extend(relativeTime);

const GlobalStyles = () => (
  <>
    <Global
      styles={css`
        ${emotionMinireset}
        body {
          height: 100vh;
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
            Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
          overflow-y: hidden;
        }

        code.inline {
          display: inline;
          font-family: Consolas, 'courier new';
          color: crimson;
          background-color: #f1f1f1;
          padding: 2px;
          border: 1px solid #1d1c1d21;
          border-radius: 3px;
          padding: 2px 3px 1px;
        }
      `}
    />
    <SchemaFormStyleGlobal />
  </>
);

const history = createBrowserHistory();

const apiService = new ApiService(getApiUrl());

const gridDataService = new GridDataServiceService();

const gridProductsService = new GridProducts();

const gridProductsFeedService = new GridProductsFeedService();

const gridRndAI = new GridRndAI();

const store = initializeStore(
  {},
  {
    apiService,
    socketService: new SocketService(`${getApiUrl()}/console`),
    gridDataService,
    gridProductService: gridProductsService,
    gridRndAI,
  },
);

const Init = () => {
  return (
    <AppThemeProvider>
      <ErrorBoundary>
        <ApiServiceProvider service={apiService}>
          <GridDataServiceProvider service={gridDataService}>
            <GridRndAIProvider service={gridRndAI}>
              <GridProductsServiceProvider service={gridProductsService}>
                <GridProductsConnectorServiceProvider service={gridProductsFeedService}>
                  <QueryClientProvider client={queryClient}>
                    <StoreProvider store={store}>
                      <Router history={history}>
                        <>
                          <AnalyticsParamsProvider>
                            <GlobalStyles />
                            <AppContainer />
                          </AnalyticsParamsProvider>
                          <GATracker />
                        </>
                      </Router>
                    </StoreProvider>
                  </QueryClientProvider>
                </GridProductsConnectorServiceProvider>
              </GridProductsServiceProvider>
            </GridRndAIProvider>
          </GridDataServiceProvider>
        </ApiServiceProvider>
      </ErrorBoundary>
    </AppThemeProvider>
  );
};

ReactDOM.render(<Init />, document.getElementById('root'));
