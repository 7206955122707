import { Card } from 'antd';
import styled from '@emotion/styled';

const PanelCard = styled(Card)<{ bodypadding?: string }>`
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.04);
  ${({ bodypadding }) => {
    return bodypadding
      ? `> .ant-card-body {
           padding: ${bodypadding} !important;
         }`
      : '';
  }}
`;

export default PanelCard;
