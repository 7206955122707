import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { FieldProps } from 'react-jsonschema-form';
import { useTranslation } from 'react-i18next';
import SchemaForm from '../../schema-form.component';
import validateForm from '../../../../../utils/validation/validate-form';
import OrganisationSpace from '../../../../../store/types/organisation-space';

interface SpacePickerProps extends FieldProps {
  spaces: OrganisationSpace[];
}

interface SpacePickerSettingsForm {
  id?: string;
}

interface SpacePickerUiOptions {
  showOnlyWithExternalId?: boolean;
  filterTypes?: string[];
}

const spaceSettingsFormUiSchema = {
  objectFieldTemplate: 'none',
};

const FormWrapper = styled.div<{ hasError: boolean }>`
  ${({ hasError }) =>
    hasError &&
    `
    .control-label {
      color: #a94442;
    }
    .form-control {
      border-color: red;

      :focus {
        border-color: #843534;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
      }
    }
  `}
`;

const SpacePickerComponent = React.memo(({
  schema,
  errorSchema,
  spaces,
  formData = {},
  onChange,
  uiSchema,
}: SpacePickerProps) => {
  const { showOnlyWithExternalId = false, filterTypes } = (uiSchema['ui:options'] ||
    {}) as SpacePickerUiOptions;

  const { t } = useTranslation();
  const hasError = useMemo(() => !!errorSchema && !!Object.keys(errorSchema).length, [
    errorSchema,
  ]);

  const filteredSpaces = useMemo(() => {
    let spacesList = spaces;

    if (showOnlyWithExternalId) {
      spacesList = spacesList.filter((space) => !!space.externalId);
    }

    if (Array.isArray(filterTypes)) {
      spacesList = spacesList.filter((space) => filterTypes.includes(space.type));
    }

    return spacesList;
  }, [spaces, showOnlyWithExternalId, filterTypes]);

  const handleFormChange = useCallback(
    (settings: SpacePickerSettingsForm) => {
      const valid = !Object.keys(validateForm(settings, schema)).length;
      const space = filteredSpaces.find((space) => space.id === settings.id);

      onChange({
        ref: 'space',
        id: settings.id,
        externalId: space ? space.externalId : '',
        valid,
      });
    },
    [onChange, schema, filteredSpaces],
  );

  const spaceSettingsFormSchema = useMemo(() => {
    return {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          title: schema.title ? schema.title : t('space'),
          enum: filteredSpaces.map((space) => space.id),
          enumNames: filteredSpaces.map((space) => space.displayName),
        },
      },
    };
  }, [t, filteredSpaces, schema]);

  return (
    <FormWrapper hasError={hasError}>
      <SchemaForm
        tagName="div"
        uiSchema={spaceSettingsFormUiSchema}
        schema={spaceSettingsFormSchema}
        data={formData || {}}
        onChange={handleFormChange}
      />
    </FormWrapper>
  );
});

export default SpacePickerComponent;
