import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, message } from 'antd';
import {
  useStoreAiKnowledgeBaseFileDelete,
  useStoreAiKnowledgeBaseFileListInvalidateQuery,
} from '../../../../../store/hooks/store-ai-knowledge-base';
import Message from '../../../../common/message/message.component';
import { ApiError } from '../../../../../services/api/api-error';

interface KnowledgeBaseFileDeleteButtonProps {
  tenantId: string;
  assistantId?: string;
  fileId: string;
}

const KnowledgeBaseFileDeleteButton = (props: KnowledgeBaseFileDeleteButtonProps) => {
  const { tenantId, assistantId, fileId } = props;

  const { t } = useTranslation();

  const {
    mutateAsync: deleteFile,
    isLoading: isDeletingFile,
    isSuccess: isDeleteFileSuccess,
  } = useStoreAiKnowledgeBaseFileDelete(tenantId, assistantId);

  const invalidateKnowledgeBaseFileListQuery = useStoreAiKnowledgeBaseFileListInvalidateQuery();

  const handleFileDelete = useCallback(async () => {
    try {
      await deleteFile(fileId);

      message.success(<Message content={t('knowledgeBase.successDeletingFile')} />);

      await invalidateKnowledgeBaseFileListQuery({ tenantId });
    } catch (error) {
      const err = (error as unknown) as ApiError;

      message.error(
        <Message content={t('knowledgeBase.errorDeletingFile')} error={err} />,
      );
    }
  }, [deleteFile, fileId, invalidateKnowledgeBaseFileListQuery, t, tenantId]);

  return (
    <Button
      size="default"
      icon="delete"
      type="link"
      loading={isDeletingFile}
      disabled={isDeleteFileSuccess}
      onClick={handleFileDelete}
    />
  );
};

export default KnowledgeBaseFileDeleteButton;
