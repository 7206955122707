export default [
  'af-ZA',
  'am-ET',
  'ar-AE',
  'ar-BH',
  'ar-DZ',
  'ar-EG',
  'ar-IQ',
  'ar-JO',
  'ar-KW',
  'ar-LB',
  'ar-LY',
  'ar-MA',
  // 'arn-CL',
  'ar-OM',
  'ar-QA',
  'ar-SA',
  'ar-SY',
  'ar-TN',
  'ar-YE',
  'as-IN',
  'ba-RU',
  'be-BY',
  'bg-BG',
  'bn-BD',
  'bn-IN',
  'bo-CN',
  'br-FR',
  'ca-ES',
  'co-FR',
  'cs-CZ',
  'cy-GB',
  'da-DK',
  'de-AT',
  'de-CH',
  'de-DE',
  'de-LI',
  'de-LU',
  // 'dsb-DE',
  'dv-MV',
  'el-GR',
  'en-AU',
  'en-BZ',
  'en-CA',
  'en-GB',
  'en-IE',
  'en-IN',
  'en-JM',
  'en-MY',
  'en-NZ',
  'en-PH',
  'en-SG',
  'en-TT',
  'en-US',
  'en-ZA',
  'en-ZW',
  'es-AR',
  'es-BO',
  'es-CL',
  'es-CO',
  'es-CR',
  'es-DO',
  'es-EC',
  'es-ES',
  'es-GT',
  'es-HN',
  'es-MX',
  'es-NI',
  'es-PA',
  'es-PE',
  'es-PR',
  'es-PY',
  'es-SV',
  'es-US',
  'es-UY',
  'es-VE',
  'et-EE',
  'eu-ES',
  'fa-IR',
  'fi-FI',
  // 'fil-PH',
  'fo-FO',
  'fr-BE',
  'fr-CA',
  'fr-CH',
  'fr-FR',
  'fr-LU',
  'fr-MC',
  'fy-NL',
  'ga-IE',
  'gd-GB',
  'gl-ES',
  // 'gsw-FR',
  'gu-IN',
  'he-IL',
  'hi-IN',
  'hr-BA',
  'hr-HR',
  // 'hsb-DE',
  'hu-HU',
  'hy-AM',
  'id-ID',
  'ig-NG',
  'ii-CN',
  'is-IS',
  'it-CH',
  'it-IT',
  'ja-JP',
  'ka-GE',
  'kk-KZ',
  'kl-GL',
  'km-KH',
  'kn-IN',
  // 'kok-IN',
  'ko-KR',
  'ky-KG',
  'lb-LU',
  'lo-LA',
  'lt-LT',
  'lv-LV',
  'mi-NZ',
  'mk-MK',
  'ml-IN',
  'mn-MN',
  // 'moh-CA',
  'mr-IN',
  'ms-BN',
  'ms-MY',
  'mt-MT',
  'nb-NO',
  'ne-NP',
  'nl-BE',
  'nl-NL',
  'nn-NO',
  // 'nso-ZA',
  'oc-FR',
  'or-IN',
  'pa-IN',
  'pl-PL',
  // 'prs-AF',
  'ps-AF',
  'pt-BR',
  'pt-PT',
  // 'qut-GT',
  // 'quz-BO',
  // 'quz-EC',
  // 'quz-PE',
  'rm-CH',
  'ro-RO',
  'ru-RU',
  'rw-RW',
  // 'sah-RU',
  'sa-IN',
  'se-FI',
  'se-NO',
  'se-SE',
  'si-LK',
  'sk-SK',
  'sl-SI',
  // 'sma-NO',
  // 'sma-SE',
  // 'smj-NO',
  // 'smj-SE',
  // 'smn-FI',
  // 'sms-FI',
  'sq-AL',
  'sv-FI',
  'sv-SE',
  'sw-KE',
  // 'syr-SY',
  'ta-IN',
  'te-IN',
  'th-TH',
  'tk-TM',
  'tn-ZA',
  'tr-TR',
  'tt-RU',
  'ug-CN',
  'uk-UA',
  'ur-PK',
  'vi-VN',
  'wo-SN',
  'xh-ZA',
  'yo-NG',
  'zh-CN',
  'zh-HK',
  'zh-MO',
  'zh-SG',
  'zh-TW',
  'zu-ZA',
];
