import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Button, Col, message, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { omit } from 'lodash';
import PanelCard from '../../../../../common/panel-card/panel-card.component';
import SchemaForm, {
  SchemaFormRef,
} from '../../../../../common/schema-form/schema-form.component';
import useSchemaForm from '../../../../../common/use-schema-form/use-schema-form';
import Device from '../../../../../../store/types/device';
import User from '../../../../../../store/types/user';
import { DeviceUpdateSettingsParams } from '../../../../../../store/models/azure-device-details/azure-device-details.model';
import MultiSelect from '../../../../../common/schema-form/widgets/multi-select/multi-select.component';
import DeviceDetailsScreenshot from '../../common/device-details-screenshot/device-details-screenshot.component';
import DeviceDetailsInfo from '../../common/device-details-info/device-details-info.component';
import UniversalDevice from '../../../../../../store/types/universal-device';
import DeviceTags from '../../common/device-tags/device-tags.component';
import Overlay from '../../../../../common/overlay/overlay.component';
import Spinner from '../../../../../common/spinner/spinner.component';

interface UniversalDeviceDetailsSettingsProps {
  canManageDeviceSettings: boolean;
  azureDevice: Device | null;
  universalDevice: UniversalDevice;
  updateDevice: (device: DeviceUpdateSettingsParams) => Promise<void>;
  user: User | null;
  fetchAzureDevice: (params: { deviceUuid: string; silent?: boolean }) => void;
  azureDeviceLoaded: boolean;
}

const injectTagEditor = (schema: any) => {
  if (typeof schema !== 'object') {
    return schema;
  }

  Object.keys(schema).forEach((key) => {
    if (schema[key] === '$DeviceTags') {
      schema[key] = DeviceTags;
    } else if (schema[key] === '$MultiSelect') {
      schema[key] = MultiSelect;
    } else {
      schema[key] = injectTagEditor(schema[key]);
    }
  });

  return schema;
};

const UniversalDeviceDetailsSettings = (props: UniversalDeviceDetailsSettingsProps) => {
  const { t } = useTranslation();
  const {
    canManageDeviceSettings,
    azureDevice,
    updateDevice,
    universalDevice,
    user,
    fetchAzureDevice,
    azureDeviceLoaded,
  } = props;

  const formElement = useRef<SchemaFormRef>(null);

  const handleError = () => {
    message.error(t('thereAreErrorsInTheContentForm'));
  };

  const handleSave = useCallback(() => {
    if (formElement.current) {
      formElement.current.submit();
    }
  }, [formElement]);

  const handleSubmit = async (data: any) => {
    if (azureDevice) {
      await updateDevice({
        note: data.note,
        deviceName: data.deviceName,
        deviceSerial: data.deviceSerial,
        deviceProvisioned: data.deviceProvisioned,
        tags: data.tags,
        env: data.env,
        uuid: azureDevice.uuid,
        edgeModules: data.edgeModules,
        arch: data.arch,
        spaces: data.spaces || [],
      });
    }
  };

  const [
    formData,
    formLoading,
    formDirty,
    formInit,
    handleFormChange,
    handleFormSubmit,
  ] = useSchemaForm(
    handleSubmit,
    t('deviceSettingsSaved'),
    t('errorSavingDeviceSettings'),
  );

  useEffect(() => {
    // TODO: consider switching to device uuid router param instead?
    if (azureDevice && !Object.keys(formData).length) {
      formInit({
        note: azureDevice.note || '',
        deviceName: azureDevice.deviceName,
        deviceSerial: azureDevice.deviceSerial,
        deviceProvisioned: azureDevice.deviceProvisioned,
        tags: azureDevice.tags || {},
        env: azureDevice.deviceTags && azureDevice.deviceTags.env,
        id: azureDevice.id,
        connectionString: azureDevice.deviceConnectionString,
        appBuild:
          azureDevice.properties &&
          azureDevice.properties.reported &&
          azureDevice.properties.reported.appBuild,
        edgeModules: azureDevice.properties
          ? azureDevice.properties.desired.edgeModules
          : {},
        arch: azureDevice.arch,
        spaces: azureDevice.spaces || [],
      });
    }
  }, [azureDevice, formInit, formData]);

  const propertiesMetaSchema = azureDevice
    ? injectTagEditor(azureDevice.propertiesMetaSchema)
    : null;
  const propertiesSchema = azureDevice ? azureDevice.propertiesSchema : null;
  const universalPropertiesSchema = useMemo(() => {
    if (propertiesSchema !== null) {
      return omit(propertiesSchema, [
        'properties.arch',
        'properties.appBuild',
        'properties.deviceProvisioned',
        'properties.env',
        'properties.note',
        'properties.deviceName',
        'properties.deviceMonitored',
      ]);
    }
    return null;
  }, [propertiesSchema]);

  const universalDeviceParentId =
    universalDevice && universalDevice.parentDevice
      ? universalDevice.parentDevice.id
      : null;

  useEffect(() => {
    if (universalDeviceParentId != null) {
      fetchAzureDevice({ deviceUuid: universalDeviceParentId });
    }
  }, [fetchAzureDevice, universalDeviceParentId]);

  if (!azureDeviceLoaded) {
    return (
      <Overlay>
        <Spinner />
      </Overlay>
    );
  }

  return (
    <>
      <Row gutter={40}>
        <Col md={24} xl={16}>
          <PanelCard>
            <SchemaForm
              ref={formElement}
              uiSchema={propertiesMetaSchema}
              data={formData}
              onChange={handleFormChange}
              schema={universalPropertiesSchema}
              onSubmit={handleFormSubmit}
              onError={handleError}
              formContext={{ user }}
            />
          </PanelCard>
        </Col>
        <Col md={24} xl={8}>
          <PanelCard>
            <DeviceDetailsScreenshot device={universalDevice} />
            <DeviceDetailsInfo device={universalDevice} />
            {canManageDeviceSettings && (
              <Button
                loading={formLoading}
                size="large"
                block
                type="primary"
                onClick={handleSave}
                disabled={!formDirty}
              >
                {t('saveChanges')}
              </Button>
            )}
          </PanelCard>
        </Col>
      </Row>
    </>
  );
};

export default UniversalDeviceDetailsSettings;
