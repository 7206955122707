import React, { useMemo } from 'react';
import ImageCard from './image-card.component';
import Media from '../../../../../store/types/media';
import UniversalDevice from '../../../../../store/types/universal-device';
import { useAppsSettings } from '../../../use-app';
import { get, isEmpty } from 'lodash';

interface DeviceImageCardProps {
  title: string;
  imageProperty: string;
  isVisibleWithoutData: boolean;
  device: UniversalDevice;
}

const DeviceImageCard = (props: DeviceImageCardProps) => {
  const { title, imageProperty, isVisibleWithoutData, device } = props;

  const { data: appsSettings, isLoading, isError } = useAppsSettings(device.organizationId, [device.appIds[0]]);

  const deviceImage = useMemo<Media[] | null>(() => {
    if (!appsSettings) {
      return null;
    }

    const appSettings = appsSettings[device.appIds[0]];
    if (!appSettings) {
      return null;
    }

    if (appSettings.devicesSettings && appSettings.devicesSettings[device.uuid as keyof Object]) {
      const deviceSettings = appSettings.devicesSettings[device.uuid as keyof Object] as Object;
      const deviceImage = get(deviceSettings, imageProperty, null);
      if (deviceImage && !isEmpty(deviceImage)) {
        return [deviceImage];
      }
    }

    const spaceImages: Media[] = [];
    device.spaces.forEach((spaceId) => {
      if (appSettings.spacesSettings && appSettings.spacesSettings[spaceId as keyof Object]) {
        const spaceSettings = appSettings.spacesSettings[spaceId as keyof Object] as Object;
        const spaceImage = get(spaceSettings, imageProperty, null);
        if (spaceImage && !isEmpty(spaceImage)) {
          spaceImages.push(spaceImage);
        }
      }
    });

    if (spaceImages.length) {
      return spaceImages;
    }

    const globalImage = get(appSettings, `provider.app.gridApp.settings.${imageProperty}`, null);
    if (globalImage && !isEmpty(globalImage)) {
      return [globalImage];
    }

    return null;

  }, [appsSettings, device.uuid, device.appIds, device.spaces, imageProperty]);

  return (
    <ImageCard
      title={title}
      isLoading={isLoading}
      isError={isError}
      images={deviceImage}
      isVisibleWithoutData={isVisibleWithoutData}
    />
  );
};

export default DeviceImageCard;
