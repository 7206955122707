import { Dictionary } from 'lodash';
import toPairs from 'lodash/toPairs';
import sumBy from 'lodash/sumBy';
import { RawData } from './types';

const buildData = (aggregatedData: Dictionary<RawData[]>) => {
  const result = toPairs(aggregatedData).map(([rowName, events]) => {
    const playsCount = sumBy(events, (event) => event.count);
    const playsDuration = sumBy(events, (event) => {
      const eventVersion = event.version ? event.version : 'v1';

      if (eventVersion === 'v2') {
        return event.mediaDuration;
      }

      return event.mediaDuration * event.count;
    });

    return {
      rowName,
      playsCount,
      playsDuration,
    };
  });

  return result;
};

export default buildData;
