import React, { useEffect, useMemo, useRef } from 'react';
import AntdPagination, {
  PaginationProps as AntdPaginationProps,
} from 'antd/lib/pagination';
import styled from '@emotion/styled';
import Overlay from '../overlay/overlay.component';
import Spinner from '../spinner/spinner.component';

type Alignment = 'left' | 'center' | 'right';

interface PaginationProps extends AntdPaginationProps {
  parentContainerRef?: React.MutableRefObject<HTMLElement | null>;
  className?: string;
  isLoading?: boolean;
  align?: Alignment;
  pageSizes?: number[];
  onShowSizeChange?: (current: number, size: number) => Promise<void> | void;
  onChange?: (page: number, pageSize?: number) => Promise<void> | void;
  noData?: React.ReactNode;
  showSizeChanger?: boolean;
}

const Pagination = (props: PaginationProps) => {
  const {
    parentContainerRef,
    className,
    isLoading,
    align,
    onShowSizeChange,
    onChange,
    pageSizes = [5, 10, 30, 50, 100],
    current,
    pageSize,
    total,
    noData,
    showSizeChanger = true,
  } = props;

  const childContainerRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    const container = getOverlayMaskContainer(parentContainerRef, childContainerRef);
    if (container) {
      container.style.position = isLoading ? 'relative' : 'unset';
      const { children } = container;
      Array.from(children).forEach((child) => {
        (child as HTMLElement).style.opacity = isLoading ? '0.4' : '1';
      });
    }
  }, [isLoading, parentContainerRef, childContainerRef]);

  const pageSizeOptions = useMemo(() => {
    return pageSizes.map((size) => size.toString());
  }, [pageSizes]);

  return (
    <>
      {isLoading && (
        <OverlayMask>
          <Spinner />
        </OverlayMask>
      )}
      {total === 0 && noData}
      <PaginationContainer
        ref={childContainerRef}
        className={className}
        align={getAlignment(align)}
      >
        <AntdPagination
          {...(showSizeChanger && {showSizeChanger})}
          onShowSizeChange={onShowSizeChange}
          current={current}
          pageSizeOptions={pageSizeOptions}
          pageSize={pageSize}
          total={total}
          showTotal={(totalItems, range) =>
            `${range[0]}-${range[1]} of ${totalItems} items`
          }
          onChange={onChange}
        />
      </PaginationContainer>
    </>
  );
};

const getOverlayMaskContainer = (
  parentContainerRef?: React.MutableRefObject<HTMLElement | null>,
  childContainerRef?: React.MutableRefObject<HTMLDivElement>,
): HTMLElement | null => {
  if (parentContainerRef && parentContainerRef.current) {
    return parentContainerRef.current;
  }

  return childContainerRef && childContainerRef.current
    ? childContainerRef.current.parentElement
    : null;
};

const getAlignment = (align?: Alignment): string => {
  switch (align) {
    case 'left':
      return 'flex-start';
    case 'center':
      return 'center';
    default:
      return 'flex-end';
  }
};

const OverlayMask = styled(Overlay)`
  z-index: 10;
  opacity: 1 !important;
`;

const PaginationContainer = styled.div<{ align: string }>`
  padding: 16px;
  display: flex;
  justify-content: ${({ align }) => align};

  @media (max-width: 768px) {
    justify-content: center;
  }

  @media (max-width: 768px) {
    justify-content: center;
  }

  @media (max-width: 400px) {
    .ant-pagination-total-text {
      display: flex;
      justify-content: center;
      margin-right: 0;
    }
  }

  @media (max-width: 576px) {
    .ant-pagination-options {
      display: flex;
      justify-content: center;
      margin-left: 0;
      margin-top: 10px;

      .ant-pagination-options-size-changer {
        margin-right: 0;
      }
    }
  }
`;

export default React.memo(Pagination);
