/* eslint-disable import/prefer-default-export */
import { Action, action } from 'easy-peasy';
import { PaginationData } from '../../types/pagination';

export default interface PaginationModel<T> {
  isPaginationLoading: {
    [key: string]: boolean;
  };
  pagination: {
    [key: string]: PaginationData;
  };
  setPagination: Action<PaginationModel<T>, { key: string; payload: PaginationData }>;
  setPaginationLoading: Action<PaginationModel<T>, { key: string; isLoading: boolean }>;
}

export const createPaginationModel = <T>() => {
  const model: PaginationModel<T> = {
    isPaginationLoading: {},
    pagination: {},
    setPaginationLoading: action((state, { key, isLoading }) => {
      state.isPaginationLoading[key] = isLoading;
    }),
    setPagination: action((state, { key, payload }) => {
      state.pagination[key] = payload;
    }),
  };

  return model;
};
