import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import { RootModel, RootState } from '../../../../store/models/root.model';
import AppsList from './apps-list.component';
import OrganisationApp from '../../../../store/types/organisation-app';
import { getPermissions } from '../../../../utils/auth/permissions';

type AppsListContainer = Pick<
  React.ComponentProps<typeof AppsList>,
  'match' | 'history' | 'location'
>;

const AppsListContainer = (props: AppsListContainer) => {
  const {
    match: {
      params: { organisationId },
    },
  } = props;

  // TODO: how to memoize this properly?
  const {
    fetchApps,
    createApp,
    updateApp,
    deleteApp,
    fetchReleases,
    fetchProviders,
    refreshApps,
  } = useActions<RootModel>((actions) => ({
    fetchApps: actions.apps.fetch,
    createApp: (data: Partial<OrganisationApp>) =>
      actions.apps.create({ data, key: organisationId }),
    updateApp: (data: Partial<OrganisationApp>) =>
      actions.apps.update({ data, key: organisationId }),
    deleteApp: (app: OrganisationApp) => actions.organisationApps.delete(app),
    fetchReleases: actions.releases.fetch,
    fetchProviders: actions.providers.fetch,
    // TODO: remove this once organisationApps is refactored and merged with apps
    refreshApps: actions.organisationApps.fetch,
  }));

  const {
    apps,
    loaded,
    releases,
    providers,
    permissions,
    isCurrentUserSysadmin,
  } = useStore<RootState>(
    (state) => ({
      apps: state.apps.values(organisationId),
      loaded: state.apps.loaded(organisationId),
      releases: state.releases.data,
      providers: state.providers.data,
      permissions: getPermissions(state.auth.user, props.match.params.organisationId),
      isCurrentUserSysadmin: state.auth.isSysAdmin,
    }),
    [organisationId],
  );

  return (
    <AppsList
      {...props}
      apps={apps}
      loaded={loaded}
      fetchApps={fetchApps}
      createApp={createApp}
      updateApp={updateApp}
      deleteApp={deleteApp}
      releases={releases}
      providers={providers}
      fetchReleases={fetchReleases}
      fetchProviders={fetchProviders}
      refreshApps={refreshApps}
      canCreate={isCurrentUserSysadmin} // retain support for windows set-up
      canUpdate={permissions.apps.update}
      canDelete={permissions.apps.remove}
    />
  );
};

export default AppsListContainer;
