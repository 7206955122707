import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Form, Input as InputAntd } from 'antd';
import { WrappedFormUtils } from 'antd/es/form/Form';
import { omit } from 'lodash';
import Address from '../../../../store/types/address';

const FormItem = Form.Item;

interface BillingAddressProps {
  form: WrappedFormUtils<any>;
  billingAddress?: Address;
}

const BillingAddress = (props: BillingAddressProps) => {
  const {
    form: { getFieldDecorator, setFieldsValue },
    billingAddress,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    setFieldsValue({
      ...(billingAddress && { billingAddress: omit(billingAddress, 'country') }),
    });
  }, [billingAddress, setFieldsValue]);

  return (
    <>
      <FormRow>
        <Label htmlFor="billingAddress.name">{t('address.name')}</Label>
        <FormItem label="" hasFeedback>
          {getFieldDecorator('billingAddress.name', {
            rules: [
              {
                max: 200,
                message: 'Company name is too long',
              },
            ],
          })(<Input />)}
        </FormItem>
      </FormRow>
      <FormRow>
        <Label htmlFor="billingAddress.addressLine1">{t('address.addressLine1')}</Label>
        <FormItem label="" hasFeedback>
          {getFieldDecorator('billingAddress.addressLine1', {
            rules: [
              {
                required: true,
                message: 'Enter address line 1',
              },
              {
                min: 2,
                message: 'Address line is too short',
              },
              {
                max: 200,
                message: 'Address line is too long',
              },
            ],
          })(<Input />)}
        </FormItem>
      </FormRow>
      <FormRow>
        <Label htmlFor="billingAddress.addressLine2">{t('address.addressLine2')}</Label>
        <FormItem hasFeedback>
          {getFieldDecorator('billingAddress.addressLine2', {
            rules: [
              {
                min: 2,
                message: 'Address line is too short',
              },
              {
                max: 200,
                message: 'Address line is too long',
              },
            ],
          })(<Input />)}
        </FormItem>
      </FormRow>
      <FormRow>
        <Label htmlFor="billingAddress.city">{t('address.city')}</Label>
        <FormItem hasFeedback>
          {getFieldDecorator('billingAddress.city', {
            rules: [
              {
                required: true,
                message: 'Enter city name',
              },
              {
                min: 2,
                message: 'City name is too short',
              },
              {
                max: 200,
                message: 'City name is too long',
              },
            ],
          })(<Input />)}
        </FormItem>
      </FormRow>
      <FormRow>
        <Label htmlFor="billingAddress.state">{`${t(
          'address.stateProvinceRegion',
        )}`}</Label>
        <FormItem hasFeedback>
          {getFieldDecorator('billingAddress.state', {
            rules: [
              {
                min: 2,
                message: 'State/province/region name is too short',
              },
              {
                max: 200,
                message: 'State/province/region name is too long',
              },
            ],
          })(<Input />)}
        </FormItem>
      </FormRow>
      <FormRow>
        <Label htmlFor="billingAddress.postal">{t('address.postalZipCode')}</Label>
        <FormItem hasFeedback>
          {getFieldDecorator('billingAddress.postal', {
            rules: [
              {
                required: true,
                message: 'Enter postal/zip code',
              },
              {
                min: 2,
                message: 'Postal/zip code is too short',
              },
              {
                max: 20,
                message: 'Postal/zip code is too long',
              },
            ],
          })(<Input />)}
        </FormItem>
      </FormRow>
    </>
  );
};

const FormRow = styled.div`
  display: block;
  margin-top: 24px;
  width: 100%;
`;

const Label = styled.label`
  color: #1d1921;
  font-weight: 700;
  display: block;
  width: 100%;
  font-size: 16px;
  padding-bottom: 4px;
`;

const Input = styled(InputAntd)`
  width: 100%;
  height: 40px;
  border: 1px solid #ccc;
  font-size: 16px;
  border-radius: 4px;
  padding: 0 8px;
  box-shadow: 0 0 0;

  &:focus,
  &:active,
  &:focus-within,
  &:focus-visible,
  &:checked {
    border-color: #5524da;
    outline-color: #5524da;
  }
`;

export default BillingAddress;
