interface StoreAIAssistantsListQueryProps {
  tenantId: string;
  page: number;
  limit?: number;
  sortOrder?: 'asc' | 'desc';
  sortField?: string;
  searchTerm?: string;
}

export const storeAIAssistantQueryKeys = {
  details: ['assistants'],
  detail: (id: string) => [...storeAIAssistantQueryKeys.details, id],
  paginationPrefix: (tenantId: string) => [
    tenantId,
    'assistants',
  ],
  pagination: (props: StoreAIAssistantsListQueryProps) => [
    ...storeAIAssistantQueryKeys.paginationPrefix(props.tenantId),
    props.page,
    'limit',
    props.limit,
    'sortField',
    props.sortField,
    'sortOrder',
    props.sortOrder,
    'searchTerm',
    props.searchTerm,
  ],
};

export default storeAIAssistantQueryKeys;
