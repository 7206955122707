import { action, Action, Select, select, thunk, Thunk } from 'easy-peasy';
import { ApiError } from '../../../services/api/api-error';
import Injections from '../../injections.interface';
import EnvVariables from '../../types/env-variables';

const sortVariables = (variablesToSort: EnvVariables, appVariables: string[]) => {
  const appVariablesArr = appVariables && Object.values(appVariables);
  const sortedVariables: EnvVariables = {};
  Object.keys(variablesToSort).forEach((key) => {
    if (appVariablesArr.includes(key)) {
      sortedVariables[key] = variablesToSort[key];
    }
  });
  Object.keys(variablesToSort).forEach((key) => {
    if (!appVariablesArr.includes(key)) {
      sortedVariables[key] = variablesToSort[key];
    }
  });

  return sortedVariables;
};

export interface AzureDeviceEnvVariablesModel {
  data: {
    [deviceUuid: string]: EnvVariables;
  };
  appVariables: {
    [deviceUuid: string]: string[];
  };
  loading: {
    [deviceUuid: string]: boolean;
  };
  error: {
    [deviceUuid: string]: ApiError | null;
  };
  loaded: Select<AzureDeviceEnvVariablesModel, (deviceUuid: string) => boolean>;
  setLoading: Action<
    AzureDeviceEnvVariablesModel,
    { deviceUuid: string; loading: boolean }
  >;
  setError: Action<
    AzureDeviceEnvVariablesModel,
    { deviceUuid: string; error: ApiError | null }
  >;
  setData: Action<
    AzureDeviceEnvVariablesModel,
    { deviceUuid: string; data: EnvVariables }
  >;
  setAppVariables: Action<
    AzureDeviceEnvVariablesModel,
    { deviceUuid: string; appVariables: string[] }
  >;
  fetch: Thunk<AzureDeviceEnvVariablesModel, { deviceUuid: string }, Injections>;
  update: Thunk<
    AzureDeviceEnvVariablesModel,
    { deviceUuid: string; envVariables: EnvVariables },
    Injections
  >;
}

const azureDeviceEnvVariablesModel: AzureDeviceEnvVariablesModel = {
  data: {},
  appVariables: {},
  loading: {},
  error: {},
  loaded: select((state) => (deviceUuid: string) =>
    !!state.data[deviceUuid] && !state.loading[deviceUuid],
  ),
  setData: action((state, { deviceUuid, data }) => {
    state.data[deviceUuid] = data;
  }),
  setAppVariables: action((state, { deviceUuid, appVariables }) => {
    state.appVariables[deviceUuid] = appVariables;
  }),
  setLoading: action((state, { deviceUuid, loading }) => {
    state.loading[deviceUuid] = loading;
  }),
  setError: action((state, { deviceUuid, error }) => {
    state.error[deviceUuid] = error;
  }),
  fetch: thunk(async (actions, { deviceUuid }, { injections }) => {
    actions.setError({ deviceUuid, error: null });
    try {
      const { variables, appVariables } = await injections.apiService.get<{
        variables: EnvVariables;
        appVariables: EnvVariables;
      }>(`/api/v2/devices/${deviceUuid}/variables`);

      const appVariableKeys = Object.keys(appVariables);
      const sortedVariables = sortVariables(variables, appVariableKeys);
      actions.setData({ deviceUuid, data: sortedVariables });
      actions.setAppVariables({ deviceUuid, appVariables: appVariableKeys });
    } catch (error) {
      actions.setError({ deviceUuid, error });
    } finally {
      actions.setLoading({ deviceUuid, loading: false });
    }
  }),
  update: thunk(async (actions, { deviceUuid, envVariables }, { injections }) => {
    const { variables, appVariables } = await injections.apiService.put<{
      variables: EnvVariables;
      appVariables: string[];
    }>(`/api/v2/devices/${deviceUuid}/variables`, envVariables);
    const appVariableKeys = Object.keys(appVariables);
    const sortedVariables = sortVariables(variables, appVariableKeys);
    actions.setData({ deviceUuid, data: sortedVariables });
    actions.setAppVariables({ deviceUuid, appVariables: appVariableKeys });
  }),
};

export default azureDeviceEnvVariablesModel;
