import { useCallback, useEffect, useMemo, useState } from 'react';
import { Variant } from '@ombori/grid-products';
import uniqBy from 'lodash/uniqBy';
import head from 'lodash/head';
import isNil from 'lodash/isNil';
import { sortBy } from 'lodash';

const sortVariantDefaultSizeValues = (items: Variant[]) => {
  const sizeSortOrder = [
    'XXS',
    '2XS',
    'XS',
    'S',
    'M',
    'L',
    'XL',
    'XXL',
    '2XL',
    'XXXL',
    '3XL',
  ];

  const initialSortedSize = items.sort((a, b) => {
    const sizeA = a.size ? a.size.toLowerCase() : '';
    const sizeB = b.size ? b.size.toLowerCase() : '';

    if (sizeA < sizeB) {
      return -1;
    }
    if (sizeA > sizeB) {
      return 1;
    }
    return 0;
  });

  return sortBy(initialSortedSize, (item) => {
    return item.size && sizeSortOrder.indexOf(item.size) !== -1
      ? sizeSortOrder.indexOf(item.size)
      : initialSortedSize.length;
  });
};

const getInitialVariant = (
  variants: Variant[],
  productId?: string,
): Variant | undefined => {
  const firstVariant = head(variants);

  const selectedVariant = variants.find((variant) => variant.productId === productId);

  if (!selectedVariant) {
    return firstVariant;
  }

  return selectedVariant;
};

interface UseProductVariantsProps {
  variants: Variant[];
  productId?: string;
}

export interface VariantColorImage {
  color: string;
  colorImageUrl: string;
}

interface UseProductVariantsResult {
  selectedVariant?: Variant;
  colorVariants: Variant[];
  sizeVariants: Variant[];
  styleVariants: Variant[];
  variantColorImages: VariantColorImage[];
  onChangeVariant: (variant: Variant) => void;
}

const useProductVariants = ({
  variants,
  productId,
}: UseProductVariantsProps): UseProductVariantsResult => {
  const initialVariant = getInitialVariant(variants, productId);
  const [selectedVariant, setSelectedVariant] = useState<Variant | undefined>(
    initialVariant,
  );

  useEffect(() => {
    setSelectedVariant(initialVariant);
  }, [initialVariant]);

  const onChangeVariant = useCallback((variant: Variant) => {
    setSelectedVariant(variant);
  }, []);

  const colorVariants = useMemo(
    () => uniqBy(variants.filter((variant) => variant.color), (variant) => variant.color),
    [variants],
  );

  const variantColorImages = useMemo(() => {
    const imageUrls: VariantColorImage[] = colorVariants.reduce(
      (prev: VariantColorImage[], curr: Variant) => {
        const variantWithImageUrl = variants.find(
          (variant) =>
            curr.color === variant.color &&
            !isNil(variant.colorImageUrl) &&
            variant.colorImageUrl !== '',
        );

        if (!variantWithImageUrl || !variantWithImageUrl.colorImageUrl || !curr.color) {
          return prev;
        }

        return prev.concat([
          {
            color: curr.color,
            colorImageUrl: variantWithImageUrl.colorImageUrl,
          },
        ]);
      },
      [],
    );

    return imageUrls;
  }, [variants, colorVariants]);
  const sizeVariants = useMemo(
    () =>
      sortVariantDefaultSizeValues(
        uniqBy(
          variants
            .filter((variant) => variant.size)
            .filter(
              (variant) =>
                variant.color === (selectedVariant ? selectedVariant.color : null),
            ),
          (variant) => variant.size,
        ),
      ),
    [variants, selectedVariant],
  );
  const styleVariants = useMemo(
    () =>
      uniqBy(
        variants
          .filter((variant) => variant.style)
          .filter(
            (variant) =>
              variant.color === (selectedVariant ? selectedVariant.color : null) &&
              variant.size === (selectedVariant ? selectedVariant.size : null),
          ),
        (variant) => variant.style,
      ),
    [variants, selectedVariant],
  );

  const result = useMemo(
    () => ({
      selectedVariant,
      colorVariants,
      sizeVariants,
      styleVariants,
      variantColorImages,
      onChangeVariant,
    }),
    [
      selectedVariant,
      colorVariants,
      sizeVariants,
      styleVariants,
      variantColorImages,
      onChangeVariant,
    ],
  );

  return result;
};

export default useProductVariants;
