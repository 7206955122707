import { Field } from 'react-final-form';
import React from 'react';
import { Button } from 'antd';
import styled from '@emotion/styled';
import { FieldArray } from 'react-final-form-arrays';
import { Label, PanelCardMain } from './styled-block';
import TimePickerAdapter, {
  defaultAutoResetTime,
  HoursContainer,
  SingleHour,
} from './time-picker-adapter';

export default function AutoResetTime() {
  return (
    <>
      <Label>Auto reset queue (clear occupancy and close all tickets)</Label>
      <PanelCardMain>
        <HoursContainer>
          <FieldArray
            key="automaticallyClearQueueTime"
            name="automaticallyClearQueueTime"
          >
            {({ fields }) => (
              <>
                {fields.map((time, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Field name={`${time}`} key={`${time}-${index}`}>
                    {({ input: filedInput }) => {
                      // "dirty" fix - if we remove item from array, field array doesn't recognise it as a change for some reason
                      // so we keep it as 'Invalid date' and filter it out before send data to backend
                      if (filedInput.value === 'Invalid date') {
                        return null;
                      }
                      return (
                        <SingleHour>
                          <TimePickerAdapter
                            input={filedInput}
                            defaultTime={defaultAutoResetTime}
                          />
                        </SingleHour>
                      );
                    }}
                  </Field>
                ))}
                <AddTime onClick={() => fields.push(defaultAutoResetTime)} icon="plus" />
              </>
            )}
          </FieldArray>
        </HoursContainer>
      </PanelCardMain>
    </>
  );
}

const AddTime = styled(Button)`` as any;
