import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { Button, Dropdown, Icon, Menu, message, Modal, Popconfirm, Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import PanelCard from '../../../../../common/panel-card/panel-card.component';
import Device from '../../../../../../store/types/device';
import User from '../../../../../../store/types/user';
import Module from '../../../../../../store/types/module';
import { DeviceModules, DeviceModule } from '../../../../../../store/types/device-module';
import Spinner from '../../../../../common/spinner/spinner.component';
import ModuleSettings from './module-settings.container';

const EMPTY_OBJECT: DeviceModules = {};
const UNKNOWN_STATUS = { status: 'unknown', version: 'none' };

const confirmMigration = (t: any) =>
  new Promise((resolve) =>
    Modal.confirm({
      title: t('confirmMigration'),
      content: (
        <>
          <p>
            When you press {`"${t('yes')}"`}, the device will be migrated to a new module
            framework.
          </p>
          <p>{t('NBSP')}</p>
          <p>
            All modules will be re-downloaded and <strong>up to 2Gb</strong> of traffic
            will be consumed.
          </p>
          <p>{t('NBSP')}</p>
          <p>You can rollback the migration later.</p>
          <p>{t('NBSP')}</p>
          <p>Are you sure you want to migrate this device?</p>
        </>
      ),
      okText: t('yes'),
      okButtonProps: { type: 'danger' },
      onOk: () => resolve(true),
      onCancel: () => resolve(false),
    }),
  );

interface ModulesProps {
  canManageModules: boolean;
  device: Device | null;
  user: User | null;
  fetchModules: () => {};
  modules: Module[];
  deviceModules: DeviceModules;
  fetchDeviceModules: ({ id }: { id: string }) => Promise<void>;
  updateDeviceModules: ({
    id,
    modules,
  }: {
    id: string;
    modules: DeviceModules;
  }) => Promise<void>;
}

const Modules = ({
  fetchModules,
  device,
  modules,
  deviceModules,
  fetchDeviceModules,
  updateDeviceModules,
}: ModulesProps) => {
  const { t } = useTranslation();

  const [data, setData] = useState<DeviceModules>(null);
  const [isModified, setModified] = useState<boolean>(false);
  const [newModules, setNewModules] = useState<boolean>(false);

  useEffect(() => {
    fetchModules();
  }, [fetchModules]);

  const usingNewModules = device ? !!device.usingNewModules : false;
  useEffect(() => setNewModules(usingNewModules), [usingNewModules]);

  const { uuid = null } = device || {};
  useEffect(() => {
    if (uuid) {
      fetchDeviceModules({ id: uuid });
    }
  }, [uuid, fetchDeviceModules]);

  useEffect(() => {
    setData((prevData) => prevData || deviceModules);
  }, [setData, deviceModules]);

  const [saving, setSaving] = useState<boolean>(false);
  const onPressSave = useCallback(async () => {
    if (!newModules) {
      const res = await confirmMigration(t);
      if (!res) return;
    }

    setModified(false);
    if (uuid) {
      setSaving(true);
      try {
        await updateDeviceModules({ id: uuid, modules: data || {} });
        message.success(t('contentSaved'));
        setNewModules(true);
      } catch (e) {
        message.error(t('errorSavingContent'));
      }
      setSaving(false);
    }
  }, [
    setModified,
    data,
    uuid,
    updateDeviceModules,
    setSaving,
    t,
    newModules,
    setNewModules,
  ]);

  const onChangeModule = useCallback(
    async (moduleId, settings) => {
      setModified(true);
      setData((prevData) => {
        if (!prevData) return prevData;

        const item = prevData[moduleId];
        if (!item) return prevData;

        return {
          ...prevData,
          [moduleId]: {
            ...item,
            moduleId,
            settings,
          },
        };
      });
    },
    [setModified, setData],
  );

  const onAddModule = useCallback(
    (moduleId: string) => {
      setModified(true);
      setData((prevData) => {
        if (!prevData) return prevData;

        const info: DeviceModule = {
          moduleId,
          versionId: '',
          settings: {},
        };

        return {
          ...prevData,
          [moduleId]: info,
        };
      });
    },
    [setData, setModified],
  );

  const onDeleteModule = useCallback(
    (moduleId: string) => {
      setModified(true);
      setData((prevData) => {
        if (!prevData) return prevData;
        return { ...prevData, [moduleId]: null };
      });
    },
    [setData, setModified],
  );

  const onChangeVersion = useCallback(
    (moduleId: string, versionId: string) => {
      setModified(true);
      setData((prevData) => {
        if (!prevData) return prevData;

        const item = prevData[moduleId];
        if (!item) return prevData;

        return {
          ...prevData,
          [moduleId]: {
            ...item,
            versionId,
          },
        };
      });
    },
    [setData, setModified],
  );

  const onRollback = useCallback(async () => {
    if (!uuid) return;
    setSaving(true);
    try {
      await updateDeviceModules({ id: uuid, modules: null });
      setNewModules(false);
      message.success(t('contentSaved'));
    } catch (e) {
      message.error(t('errorSavingContent'));
    }
    setSaving(false);
  }, [uuid, updateDeviceModules, t, setNewModules]);

  const activeModules = data || EMPTY_OBJECT;
  const menu = useMemo(
    () => (
      <Menu>
        {modules.map((module: Module) => (
          <Menu.Item
            disabled={!!activeModules[module.id]}
            key={module.id}
            onClick={() => onAddModule(module.id)}
          >
            {module.displayName}
          </Menu.Item>
        ))}
      </Menu>
    ),
    [modules, activeModules, onAddModule],
  );

  const getModuleStatus = useCallback(
    (moduleId) => {
      if (!device) return UNKNOWN_STATUS;
      if (!device.properties) return UNKNOWN_STATUS;

      const mod = modules.find((module) => module.id === moduleId);
      if (!mod) return UNKNOWN_STATUS;

      let reportedModules: { [name: string]: any } = {};
      if (device && device.properties && device.properties.reported) {
        reportedModules = device.properties.reported.modules || {};
      }

      return reportedModules[mod.moduleName] || UNKNOWN_STATUS;
    },
    [modules, device],
  );

  if (!device) {
    return null;
  }

  return (
    <>
      <Button.Group size="large">
        <Dropdown overlay={menu}>
          <Container>
            <Button disabled={!data || saving}>
              {t('addModule')}
              <Icon type="down" />
            </Button>
          </Container>
        </Dropdown>
      </Button.Group>
      {!data && (
        <Container>
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        </Container>
      )}
      {Object.keys(activeModules).map(
        (moduleId) =>
          activeModules[moduleId] && (
            <Container key={moduleId} disabled={saving}>
              <PanelCard>
                <ModuleSettings
                  moduleId={moduleId}
                  versionId={(activeModules[moduleId] || { versionId: '' }).versionId}
                  settings={(activeModules[moduleId] || { settings: null }).settings}
                  module={modules.find((module) => module.id === moduleId)}
                  status={getModuleStatus(moduleId)}
                  onChange={onChangeModule}
                  onDelete={onDeleteModule}
                  onChangeVersion={onChangeVersion}
                  organisationId={device.organizationId}
                />
              </PanelCard>
            </Container>
          ),
      )}
      {newModules ? (
        <>
          <Button disabled={!isModified || saving} onClick={onPressSave} type="primary">
            {t('saveChanges')}
          </Button>
          <Right>
            <Popconfirm
              title={t('areYouSure')}
              onConfirm={onRollback}
              okText={t('yes')}
              cancelText={t('no')}
              placement="topRight"
            >
              <Popover content={t('rollbackModules')} placement="topRight">
                <Button
                  disabled={!activeModules || !data || saving}
                  type="link"
                  icon="undo"
                />
              </Popover>
            </Popconfirm>
          </Right>
        </>
      ) : (
        <>
          <Button disabled={!isModified || saving} onClick={onPressSave} type="danger">
            {t('migrateAndSave')}
          </Button>
        </>
      )}
    </>
  );
};

const SpinnerContainer = styled.div`
  text-align: center;
  margin: 32px;
`;

const Container = styled.div<{ disabled?: boolean }>`
  margin-bottom: 16px;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1.0)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

const Right = styled.span`
  float: right;
`;

export default Modules;
