import React from 'react';
import { Icon } from '../../../../common/schema-form/common';

interface KnowledgeBaseFileIconProps {
  mimeType?: string;
  size?: number;
}

const KnowledgeBaseFileIcon = (props: KnowledgeBaseFileIconProps) => {
  const { mimeType, size = 30 } = props;

  if (mimeType === 'application/pdf') {
    return <Icon type="file-pdf" size={size} />;
  }

  return <Icon type="file-unknown" size={size} />;
};

export default KnowledgeBaseFileIcon;
