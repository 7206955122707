import React, { useState, useCallback, useMemo, useRef } from 'react';
import { Input, Button, Divider } from 'antd';
import noop from 'lodash/noop';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

interface Option {
  label: string;
  value: string;
}

interface CustomDropdownProps {
  options: Option[];
  value: string | number;
  onChange: (value: string) => void;
  onCreate?: (name: string) => Promise<string | undefined>;
  disabled?: boolean;
}

export default function CustomDropdown({
  options,
  value,
  onChange,
  onCreate,
  disabled,
}: CustomDropdownProps) {
  const inputRef = useRef<Input>(null);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [newOptionFieldValue, setNewOptionFieldValue] = useState('');
  const [showMenu, setShowMenu] = useState(false);
  const onMenuClick = useCallback(
    () => !disabled && setShowMenu((prevValue) => !prevValue),
    [disabled],
  );
  const { t } = useTranslation();

  const activateMenuAndFocusInputField = useCallback(() => {
    setShowMenu(true);
    const timeout = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
      if (timeout) {
        clearTimeout(timeout);
      }
    }, 500);
  }, []);

  const onOptionCreate = useCallback(async () => {
    try {
      if (onCreate) {
        setIsFormLoading(true);
        const id = await onCreate(newOptionFieldValue.trim());
        id && onChange(id);
        setShowMenu(false);
      }
    } catch {
      // no action
    }
    setIsFormLoading(false);
  }, [onChange, onCreate, newOptionFieldValue]);

  const onOptionSelect = useCallback(
    (option: Option) => {
      if (!isFormLoading) {
        setShowMenu(false);
        onChange(option.value);
      }
    },
    [onChange, isFormLoading],
  );

  const onInputFieldChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    setNewOptionFieldValue(ev.target.value || '');
  }, []);

  const currentValue = useMemo(() => {
    const currentOption = options.find((option) => option.value === value);
    return currentOption ? currentOption.label : '';
  }, [options, value]);

  return (
    <Container>
      <Form className="form-control">
        <Value onClick={onMenuClick}>{currentValue}</Value>
        <Icon onClick={onMenuClick}>
          <Arrow isOpen={showMenu} />
        </Icon>
        <Menu showMenu={showMenu}>
          <ScrollableOptions enableScroll={options.length > 4}>
            {options.map((option) => (
              <MenuOption
                key={option.value}
                onClick={() => onOptionSelect(option)}
                onMouseDown={noop}
                onKeyDown={noop}
                role="button"
                tabIndex={0}
              >
                {option.label}
              </MenuOption>
            ))}
          </ScrollableOptions>
          {!!options.length && <InputDivider />}
          <CreateInputContainer>
            <InputField
              id="dropdown-create-field"
              ref={inputRef}
              onChange={onInputFieldChange}
            />
            <InputSubmitButton
              id="dropdown-create-button"
              type="link"
              icon={isFormLoading ? 'loading' : 'plus'}
              onClick={onOptionCreate}
              disabled={!newOptionFieldValue || isFormLoading}
            >
              {t('installationGroup.createGroup')}
            </InputSubmitButton>
          </CreateInputContainer>
        </Menu>
      </Form>
      {!disabled && (
        <CreateGroupFooterButton
          type="link"
          icon={isFormLoading ? 'loading' : 'plus'}
          onClick={activateMenuAndFocusInputField}
        >
          {t('installationGroup.createGroup')}
        </CreateGroupFooterButton>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Form = styled.div`
  height: 34px;
  width: 100%;
  margin: 0;
  padding: 0;

  box-sizing: border-box;
  position: relative;
`;

const Value = styled.div`
  display: inline-block;
  height: 100%;
  width: 100%;
  padding: 6px 4px;
  vertical-align: middle;
`;

const Menu = styled.div<{ showMenu: boolean }>`
  background: #fff;
  position: absolute;
  width: 100%;
  display: ${({ showMenu }) => (showMenu ? 'block' : 'none')};
  z-index: 99;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgb(0 0 0 / 25%);
  max-height: 312px;
  margin-top: 2px;
`;

const MenuOption = styled.div`
  padding: 6px;

  &:first-of-type {
    padding-top: 12px;
  }

  &:last-of-type {
    padding-bottom: 16px;
  }
`;

const ScrollableOptions = styled.div<{ enableScroll: boolean }>`
  ${({ enableScroll }) =>
    !!enableScroll &&
    `
    height: 148px;
    overflow-y: scroll;
  `}
`;

const CreateInputContainer = styled(MenuOption)`
  display: flex;
  flex-direction: row;
  padding: 8px 12px !important;
`;

const Icon = styled.div`
  width: 12px;
  height: 40px;
  margin: 0;
  padding: 0;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 2px;
  bottom: 0;
`;

const Arrow = styled.i<{ isOpen: boolean }>`
  border: solid #616161;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2.5px;
  transform-origin: center;

  ${({ isOpen }) =>
    isOpen
      ? `
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin-top: 11px;
  `
      : `
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  `}
`;

const CreateGroupFooterButton = styled(Button)`
  padding: 2px;
  width: fit-content;
` as any;

const InputSubmitButton = styled(CreateGroupFooterButton)`
  padding: 4px 0px 4px 12px;
` as any;

const InputField = styled(Input)`
  padding: 8px;
` as any;

const InputDivider = styled(Divider)`
  margin: 0px;
`;
