import React from 'react';
import styled from '@emotion/styled';
import { Pagination } from 'antd';

const pageSize = 8;

interface GetDataByPageProps<T> {
  data: T[];
  currentPage: number;
  totalPages: number;
}

function getDataByPage<T>({ data, currentPage, totalPages }: GetDataByPageProps<T>): T[] {
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(currentPage * pageSize, totalPages);

  return data.slice(startIndex, endIndex);
}

export const useTablePagination = (totalPages: number) => {
  const [currentPage, setCurrentPage] = React.useState(1);

  const onChangePage = React.useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  return {
    pageSize,
    currentPage,
    onChangePage,
    totalPages,
    getDataByPage,
  };
};

export const TableCell = styled.td`
  padding: 6px 8px;
  border-bottom: 1px solid #e8e8e8;
`;

export const TableRow = styled.tr``;

const TableHeaderContainer = styled.thead``;

const TableHeaderRow = styled.tr``;

const TableHeaderCell = styled.th`
  font-size: 14px;
  line-height: 1;
  padding: 4px 8px;
`;

interface TableHeaderProps {
  columns: string[];
}

export const TableHeader: React.FC<TableHeaderProps> = ({ columns }) => {
  return (
    <TableHeaderContainer>
      <TableHeaderRow>
        {columns.map((column, index) => (
          <TableHeaderCell key={index}>{column}</TableHeaderCell>
        ))}
      </TableHeaderRow>
    </TableHeaderContainer>
  );
};

const Container = styled.div``;

const TableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
`;

const TableBodyWrapper = styled.tbody``;

const PaginationContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 15px;
`;

interface TableProps {
  header: React.ReactNode;
  rows: React.ReactNode[];
  currentPage: number;
  total: number;
  onChangePage: (page: number) => void;
}

const Table: React.FC<TableProps> = ({
  header,
  rows,
  currentPage,
  total,
  onChangePage,
}) => {
  return (
    <Container>
      <TableContainer>
        {header}

        <TableBodyWrapper>
          {rows.map((row, index) => (
            <React.Fragment key={index}>{row}</React.Fragment>
          ))}
        </TableBodyWrapper>
      </TableContainer>

      {total > pageSize && (
        <PaginationContainer>
          <Pagination
            current={currentPage}
            total={total}
            onChange={onChangePage}
            showSizeChanger={false}
            pageSize={pageSize}
          />
        </PaginationContainer>
      )}
    </Container>
  );
};

export default Table;
