import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { v4 as uuidV4 } from 'uuid';
import { Typography, Button, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import {
  FormTextInput,
  MainSectionTitle,
  PanelBody,
  PanelCardMain,
} from './styled-block';
import { Category, buildLocalizedField } from '../../../../../store/types/queue';
import LanguagePickerComponent from './text-in-app-customization-lanugage-picker';
import { required } from './validate-form';

const { Title, Text } = Typography;
const { Option } = Select;

const silverTree = '#52B596';
const mojo = '#C63F3A';
const royalBlue = '#3684DD';
const goldenSand = '#F6CD7C';
const mediumPurple = '#6861DE';
const japonica = '#D5735A';
const downy = '#65C6BB';
const cranberry = '#D65291';
const deepSea = '#055E6C';
const saffron = '#F4C430';
const slateGray = '#708090';
const forestGreen = '#228B22';
const coral = '#FF7F50';
const plum = '#8E4585';
const cobalt = '#0047AB';
const terraCotta = '#E2725B';

const colors = [
  silverTree,
  mojo,
  royalBlue,
  goldenSand,
  mediumPurple,
  japonica,
  downy,
  cranberry,
  deepSea,
  saffron,
  slateGray,
  forestGreen,
  coral,
  plum,
  cobalt,
  terraCotta,
];

interface CategoriesProps {
  activeLanguage: string;
}

const buildCategory = (props: Partial<Category> = {}): Category => {
  const {
    id = uuidV4(),
    title = buildLocalizedField(),
    labelPrefix = '',
    color = mediumPurple,
    isRoot = true,
    parentId = null,
  } = props;

  return {
    id,
    title,
    labelPrefix,
    color,
    isRoot,
    parentId,
  };
};

const categories = (fields: FieldArrayRenderProps['fields'], parentId: string | null) => {
  const getFieldName = ({ category, formFields }: any) => {
    return `${formFields.name}[${formFields.value.findIndex(
      (cat: Category) => cat.id === category.id,
    )}]`;
  };
  const checkIfHasChildren = ({
    formFields,
    category,
  }: {
    formFields: FieldArrayRenderProps['fields'];
    category: Category;
  }) => {
    return formFields.value.some((cat: Category) => cat.parentId === category.id);
  };

  if (!fields.value || !Array.isArray(fields.value)) return null;
  return fields.value
    .filter((category: Category) => category.parentId === parentId)
    .map((category: Category) => {
      const fieldName =
        getFieldName({
          formFields: fields,
          category,
        }) || '';
      const hasChildren = checkIfHasChildren({ formFields: fields, category });

      return {
        fieldName,
        hasChildren,
        ...category,
      };
    });
};
const CategoryTree: React.FC<{
  fields: FieldArrayRenderProps['fields'];
  activeLanguage: string;
}> = ({ fields, activeLanguage }) => {
  const getFilteredColors = useCallback((inputValue: string, colors: string[]) => {
    return colors.filter((color) => !inputValue.includes(color));
  }, []);

  const handleColorChange = useCallback(
    (input: any) => (value: any) => {
      input.onChange(value as React.ChangeEvent);
    },
    [],
  );
  const removeCategory = (
    categoryId: string,
    fields: FieldArrayRenderProps['fields'],
  ) => {
    const currentIndex = fields.value.findIndex((cat: Category) => cat.id === categoryId);
    if (currentIndex !== -1) {
      fields.remove(currentIndex);
    }
  };

  const renderCategories = useCallback(
    (parentId: string | null) => {
      const categoryList = categories(fields, parentId);
      if (!categoryList) return <></>;
      return categoryList.map((category) => {
        return (
          <div key={category.id}>
            <CategoryItem>
              <CategoryTitle>
                <Field
                  name={`${category.fieldName}.title[${activeLanguage}]`}
                  validate={required}
                >
                  {({ input, meta }) => <FormInput input={input} meta={meta} required />}
                </Field>
              </CategoryTitle>

              {!category.hasChildren && (
                <>
                  <CategoryPrefix>
                    <Field name={`${category.fieldName}.labelPrefix`} validate={required}>
                      {({ input, meta }) => (
                        <FormInput input={input} meta={meta} required />
                      )}
                    </Field>
                  </CategoryPrefix>
                  <CategoryColor>
                    <Field name={`${category.fieldName}.color`} validate={required}>
                      {({ input }) => {
                        const onChange = handleColorChange(input);
                        const filteredColors = getFilteredColors(input.value, colors);

                        return (
                          <SelectContainer>
                            <SelectComponent onChange={onChange} value={input.value}>
                              {filteredColors.map((color) => (
                                <Option value={color} key={color}>
                                  <Color style={{ backgroundColor: color }} />
                                </Option>
                              ))}
                            </SelectComponent>

                            {!!input.value && (
                              <SelectValue>
                                <Color style={{ backgroundColor: input.value }} />
                              </SelectValue>
                            )}
                          </SelectContainer>
                        );
                      }}
                    </Field>
                  </CategoryColor>
                </>
              )}
              <CategoryActions hasChildren={category.hasChildren}>
                {!category.hasChildren && (
                  <RemoveCategoryButton
                    onClick={() => removeCategory(category.id, fields)}
                    icon="minus"
                  />
                )}
                <Button
                  style={{ marginLeft: '10px' }}
                  onClick={() =>
                    fields.push(
                      buildCategory({
                        isRoot: false,
                        parentId: category.id,
                      }),
                    )
                  }
                  icon="plus"
                />
              </CategoryActions>
            </CategoryItem>
            <div style={{ marginLeft: '20px' }}>{renderCategories(category.id)}</div>
          </div>
        );
      });
    },
    [fields, activeLanguage, getFilteredColors],
  );

  return <>{renderCategories(null)}</>;
};

const Categories: React.FC<CategoriesProps> = ({ activeLanguage }) => {
  const { t } = useTranslation();

  return (
    <PanelCardMain bodyStyle={{ padding: 0 }}>
      <MainSectionTitle>
        <Title level={2}>{t('categoriesTitle')}</Title>
        <Text>{t('categoriesSubtitle')}</Text>
      </MainSectionTitle>

      <PanelBody>
        <FieldArray name="categories">
          {({ fields }) => (
            <>
              <Content>
                <LanguagePicker />
                <CategoryItem>
                  <CategoryTitle>
                    <Heading>{t('categoryTitle')}</Heading>
                  </CategoryTitle>
                  <CategoryPrefix>
                    <Heading>{t('categoryLabelPrefix')}</Heading>
                  </CategoryPrefix>
                  <CategoryColor>
                    <Heading>{t('categoryColor')}</Heading>
                  </CategoryColor>
                </CategoryItem>
                <CategoryTree fields={fields} activeLanguage={activeLanguage} />
              </Content>

              <Footer>
                <AddCategoryButton
                  onClick={() => fields.push(buildCategory({ isRoot: true }))}
                  icon="plus"
                />
              </Footer>
            </>
          )}
        </FieldArray>
      </PanelBody>
    </PanelCardMain>
  );
};

const categoryColumnMargin = 15;
const smallCategoryColumnWidth = 100;

const Content = styled.div``;

const CategoryActions = styled.div<{ hasChildren: boolean }>`
  display: flex;
  align-items: center;
  ${({ hasChildren }) =>
    hasChildren &&
    `
    position: absolute;
    right: 1rem;
  `}
`;

const CategoryItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

const CategoryColumn = styled.div`
  margin-right: ${categoryColumnMargin}px;
`;

const CategoryTitle = styled(CategoryColumn)`
  width: calc(90% - ${smallCategoryColumnWidth * 2 + categoryColumnMargin * 2}px);
`;

const CategoryPrefix = styled(CategoryColumn)`
  width: ${smallCategoryColumnWidth}px;
`;

const CategoryColor = styled(CategoryColumn)`
  width: ${smallCategoryColumnWidth}px;
`;

const Heading = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: #000000;
`;

const Footer = styled.div`
  padding-bottom: 15px;
`;

const AddCategoryButton = styled(Button)`` as any;

const RemoveCategoryButton = styled(Button)`
  margin-right: 10px;
` as any;

const LanguagePicker = styled(LanguagePickerComponent)`
  margin-bottom: 15px;
`;

const FormInput = styled(FormTextInput)`
  padding: 0;
`;

const Color = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 2px;
`;

const SelectComponent = styled(Select)`
  .ant-select-selection__rendered {
    display: flex;
    width: 100%;
    align-items: center;
    opacity: 0;
  }
`;

const SelectContainer = styled.div`
  position: relative;
`;

const SelectValue = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  pointer-events: none;
`;

export default Categories;
