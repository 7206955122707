import { action, Action, select, thunk, Thunk } from 'easy-peasy';
import mime from 'mime-types';
import { BlobServiceClient, BlockBlobUploadStreamOptions } from "@azure/storage-blob";
import createLoadableCollectionModel, {
  LoadableCollectionModel,
} from '../../common/loadable-collection/loadable-collection.model';
import Injections from '../../injections.interface';
import Media from '../../types/media';
import { getMediaItemKind, MediaItemKind } from '../../../utils/media-item-utils';

const getFileType = (file: File) => {
  if (file.name.endsWith('.gdmt')) {
    return 'application/x-gdm-template';
  }

  if (file.name.endsWith('.gridapp')) {
    return 'application/x-gridapp';
  }

  return file.type || mime.lookup(file.name) || 'application/octet-stream';
};

export interface OrganisationMediaModel
  extends Omit<
    LoadableCollectionModel<Media, { organization: string; folderId?: string }>,
    'fetch'
  > {
  allValues: Media[];
  ancestors: Media[] | null;
  setSingle: Action<OrganisationMediaModel, Media>;
  unsetSingle: Action<OrganisationMediaModel, string>;
  setAncestors: Action<OrganisationMediaModel, Media[]>;
  setAllValues: Action<OrganisationMediaModel, Media[]>;
  update: Thunk<OrganisationMediaModel, Media, Injections>;
  create: Thunk<OrganisationMediaModel, Partial<Media>, Injections>;
  upload: Thunk<
    OrganisationMediaModel,
    {
      file: File;
      organization: string;
      folderId?: string;
      onProgress?: (percent: number) => void;
    },
    Injections
  >;
  delete: Thunk<OrganisationMediaModel, string, Injections>;
  fetch: Thunk<
    OrganisationMediaModel,
    { organization: string; folderId?: string },
    Injections
  >;
  fetchAll: Thunk<
    OrganisationMediaModel,
    { organization: string; folderId?: string },
    Injections
  >;
  subscribe: Thunk<OrganisationMediaModel, string, Injections>;
}

const organisationMediaModel: OrganisationMediaModel = {
  ...createLoadableCollectionModel<Media, { organization: string; folderId?: string }>(
    '/api/media',
  ),
  // TODO: move this to a separate model, CRUD-like
  allValues: [],
  ancestors: null,
  values: select((state) =>
    Object.values(state.data || {}).sort((first, second) => {
      const isFirstFolder = getMediaItemKind(first) === MediaItemKind.folder;
      const isSecondFolder = getMediaItemKind(second) === MediaItemKind.folder;
      if (isFirstFolder === isSecondFolder) {
        return new Date(second.createdAt).getTime() - new Date(first.createdAt).getTime();
      }
      return isFirstFolder ? -1 : 1;
    }),
  ),
  setSingle: action((state, data) => {
    if (!state.data) {
      state.data = {};
    }
    if (!state.allValues) {
      state.allValues = [];
    }
    state.data[data.id] = data;
    state.allValues = [...state.allValues.filter((media) => media.id !== data.id), data];
  }),
  unsetSingle: action((state, id) => {
    if (state.data) {
      delete state.data[id];
    }
    if (state.allValues) {
      state.allValues = state.allValues.filter((media) => media.id !== id);
    }
  }),
  setAncestors: action((state, data) => {
    state.ancestors = [];
    if (data.length) {
      state.ancestors = data;
    }
  }),
  setAllValues: action((state, data) => {
    state.allValues = [];
    if (data.length) {
      state.allValues = data;
    }
  }),
  update: thunk(async (actions, media, { injections }) => {
    const data = await injections.apiService.put<Media>(`/api/media/${media.id}`, media);
    actions.setSingle(data);
  }),
  create: thunk(async (actions, media, { injections }) => {
    const data = await injections.apiService.post<Media>(`/api/media `, media);
    actions.setSingle(data);
  }),
  delete: thunk(async (actions, id, { injections }) => {
    actions.unsetSingle(id);
    await injections.apiService.delete(`/api/media/${id}`);
  }),
  upload: thunk(
    async (actions, { file, organization, folderId, onProgress }: { file: File; organization: string; folderId?: string; onProgress?: (percent: number) => void }, { injections }) => {
      const { uri, sas, container, uuid }: { uri: string; sas: string; container: string; uuid: string } = await injections.apiService.get(
        `/api/util/sas?tenantId=${organization}`,
      );

      const fileType = getFileType(file);

      // Create a blobServiceClient object which will be used to create a container client
      const blobServiceClient = new BlobServiceClient(`${uri}?${sas}`);
      // Get a reference to a container
      const containerClient = blobServiceClient.getContainerClient(container);
      // Get a block blob client
      const blockBlobClient = containerClient.getBlockBlobClient(`${organization}/${uuid}`);

      // Set the blob upload options
      const options: BlockBlobUploadStreamOptions = {
        blobHTTPHeaders: { blobContentType: fileType },
        onProgress: (ev) => {
          if (onProgress) {
            const percent = (ev.loadedBytes / file.size) * 100;
            onProgress(percent);
          }
        },
      };

      // Upload the file to Azure Blob Storage
      await blockBlobClient.uploadBrowserData(file, options);

      if (!fileType) throw new Error('The type of the file you uploaded cannot be recognized.');

      await actions.create({
        name: file.name,
        type: fileType,
        size: file.size,
        uuid,
        organization,
        folderId,
      });
    },
  ),
  fetch: thunk(async (actions, { organization, folderId }, { injections }) => {
    actions.setLoading(true);
    actions.setError(null);
    try {
      const data = await injections.apiService.get<{
        files: Media[];
        ancestors: Media[];
      }>('api/media', folderId ? { organization, folderId } : { organization });
      actions.setData(data.files);
      actions.setAncestors(data.ancestors);
    } catch (err) {
      actions.setError(err);
    } finally {
      actions.setLoading(false);
    }
  }),
  fetchAll: thunk(async (actions, { organization }, { injections }) => {
    actions.setLoading(true);
    actions.setError(null);
    try {
      const data = await injections.apiService.get<{
        files: Media[];
      }>('api/media/all', { organization });
      actions.setAllValues(data.files);
    } catch (err) {
      actions.setError(err);
    } finally {
      actions.setLoading(false);
    }
  }),
  subscribe: thunk(async (actions, organisationId, { injections }) => {
    const onFileUpdate = (file: Media) => {
      actions.setSingle(file);
    };

    const unsubscribe = await injections.socketService.subscribeToEvent<Media>(
      `files_${organisationId}`,
      onFileUpdate,
    );
    return () => {
      unsubscribe();
    };
  }),
};

export default organisationMediaModel;
