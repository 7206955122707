import styled from '@emotion/styled';

const ButtonRow = styled.div<{ align?: 'start' | 'center' | 'end' }>`
  display: flex;
  justify-content: ${({ align = 'start' }) => align};
  gap: 5px;
  padding: 10px 16px;

  @media only screen and (max-width: 576px) {
    justify-content: center;
  }
`;

export default ButtonRow;
