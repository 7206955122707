import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { Button, message } from 'antd';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ButtonType } from '../../../../../types';
import useGoBack from '../../../../common/use-go-back';
import TagFormFields, { TagFormApiValidationErrors, TagFormValues } from './tag-form-fields.component';
import { ApiError } from '../../../../../services/api/api-error';
import Message from '../../../../common/message';
import { extractApiValidationErrors } from '../../../../common/react-final-form';
import useCreateTag from '../../../../../store/hooks/content-tags/use-create-tag';

interface TagCreateFormProps extends RouteComponentProps<{ organisationId: string }> {}

const TagCreateForm = (props: TagCreateFormProps) => {
  const { t } = useTranslation();

  const {
    match: {
      params: { organisationId: tenantId },
    },
    history,
  } = props;

  const goBack = useGoBack();

  const handleBack = useCallback(() => {
    goBack();
  }, [goBack]);

  const initialValues = useMemo<TagFormValues>(() => {
    return {
      name: '',
      description: '',
    };
  }, []);

  const { mutateAsync: createAlertTag } = useCreateTag(tenantId);

  const onFormSubmit = useCallback(
    async (formValues: TagFormValues): Promise<TagFormApiValidationErrors> => {
      try {
        const { name, description, type, values } = formValues;

        const tag = await createAlertTag({
          tenantId,
          name,
          description,
          ...(values && values.length > 0 ? { type, values } : {}),
        });

        message.success(<Message content={t('contents.tagAddSuccess')} />);
        history.replace(`/organisations/${tenantId}/content/tags/update/${tag.id}`);

        return {};
      } catch (error) {
        message.error(<Message content={t('contents.tagAddFailed')} />);

        const errors = extractApiValidationErrors(error as ApiError);
        return errors;
      }
    },
    [createAlertTag, history, tenantId, t],
  );

  return (
    <TagFormContainer>
      <BackToListButton type="default" icon="arrow-left" onClick={handleBack}>
        {t('back')}
      </BackToListButton>

      <TagFormFields
        title={t('contents.createTag')}
        initialValues={initialValues}
        onFormSubmit={onFormSubmit}
      />
    </TagFormContainer>
  );
};

export default TagCreateForm;

const BackToListButton = styled(Button)`
  height: 36px;
  font-size: 14px;
  color: #676973;
  border: none;
  margin-right: 5px;
  background-color: transparent;

  :hover,
  :active,
  :focus {
    background-color: #f2f4f8;
  }
` as ButtonType;

const TagFormContainer = styled.div`
  position: relative;
  flex: 1;
  padding: 30px 80px 40px;

  @media screen and (max-width: 991px) {
    padding: 0;
  }
`;
