import React, { useMemo } from 'react';
import groupBy from 'lodash/groupBy';
import round from 'lodash/round';
import { DataResidencyEnum } from '../../../../store/types/organisation';
import {
  useTenantEventsByDay,
  useInstallationEventsByDay,
  useSpaceEventsByDay,
  useDeviceEventsByDay,
  useAppEventsByDay,
} from '../../use-analytics-report';
import CardContainer from './card-container';
import EventsFunnel, { FunnelDataItem } from '../charts/events-funnel';
import {
  TenantEventsByDay,
  InstallationEventsByDay,
  SpaceEventsByDay,
  DeviceEventsByDay,
  AppEventsByDay,
} from '../../use-analytics-report/aggregated-reports/types';
import { GridStyles } from '@ombori/grid-reports';

type RawData = (
  | TenantEventsByDay
  | InstallationEventsByDay
  | SpaceEventsByDay
  | DeviceEventsByDay
  | AppEventsByDay)[];

const countEventSessions = (rawData: RawData): number =>
  rawData.reduce((accumulator, dataItem) => {
    const sessionCount = dataItem.sessionCount || 0;

    return accumulator + sessionCount;
  }, 0);

const buildData = (rawData: RawData, events: string[]): FunnelDataItem[] => {
  const filteredData = rawData.filter((dataItem) => events.includes(dataItem.eventType));

  const groupedEvents = groupBy(filteredData, (dataItem) => dataItem.eventType);

  const firstEvent = events[0];

  const firstEventSessionsCount = countEventSessions(groupedEvents[firstEvent] || []);

  const result = events.map((event) => {
    const eventSessionsCount = countEventSessions(groupedEvents[event] || []);

    const percent =
      firstEventSessionsCount > 0
        ? round((eventSessionsCount / firstEventSessionsCount) * 100, 1)
        : 0;

    const name = `${event} ${percent} %`;

    const data = {
      name,
      value: eventSessionsCount,
    };

    return data;
  });

  return result;
};

interface EventsFunnelProps {
  tenantId: string;
  dateFrom: string;
  dateTo: string;
  title: string;
  events: string[];
  dataResidency: DataResidencyEnum;
  gridStyles?: GridStyles;
  isVisibleWithoutData?: boolean;
}

export const TenantEventsFunnel: React.FC<EventsFunnelProps> = ({
  tenantId,
  dateFrom,
  dateTo,
  events,
  title,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const tenantEventsState = useTenantEventsByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const data = useMemo(() => buildData(tenantEventsState.data || [], events), [
    tenantEventsState,
    events,
  ]);

  return (
    <CardContainer
      isLoading={tenantEventsState.isLoading}
      isSuccess={tenantEventsState.isSuccess}
      isError={tenantEventsState.isError}
      hasData={tenantEventsState.isSuccess && tenantEventsState.data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {tenantEventsState.isSuccess && <EventsFunnel data={data} />}
    </CardContainer>
  );
};

interface InstallationEventsFunnel extends EventsFunnelProps {
  installationId: string;
}

export const InstallationEventsFunnel: React.FC<InstallationEventsFunnel> = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  events,
  title,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const installationEventsState = useInstallationEventsByDay({
    tenantId,
    installationId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const data = useMemo(() => buildData(installationEventsState.data || [], events), [
    installationEventsState,
    events,
  ]);

  return (
    <CardContainer
      isLoading={installationEventsState.isLoading}
      isSuccess={installationEventsState.isSuccess}
      isError={installationEventsState.isError}
      hasData={
        installationEventsState.isSuccess && installationEventsState.data.length > 0
      }
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {installationEventsState.isSuccess && <EventsFunnel data={data} />}
    </CardContainer>
  );
};

interface SpaceEventsFunnel extends EventsFunnelProps {
  spaceId: string;
}

export const SpaceEventsFunnel: React.FC<SpaceEventsFunnel> = ({
  tenantId,
  spaceId,
  dateFrom,
  dateTo,
  events,
  title,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const spaceEventsState = useSpaceEventsByDay({
    tenantId,
    spaceId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const data = useMemo(() => buildData(spaceEventsState.data || [], events), [
    spaceEventsState,
    events,
  ]);

  return (
    <CardContainer
      isLoading={spaceEventsState.isLoading}
      isSuccess={spaceEventsState.isSuccess}
      isError={spaceEventsState.isError}
      hasData={spaceEventsState.isSuccess && spaceEventsState.data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {spaceEventsState.isSuccess && <EventsFunnel data={data} />}
    </CardContainer>
  );
};

interface DeviceEventsFunnel extends EventsFunnelProps {
  deviceId: string;
}

export const DeviceEventsFunnel: React.FC<DeviceEventsFunnel> = ({
  tenantId,
  deviceId,
  dateFrom,
  dateTo,
  events,
  title,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const deviceEventsState = useDeviceEventsByDay({
    tenantId,
    deviceId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const data = useMemo(() => buildData(deviceEventsState.data || [], events), [
    deviceEventsState,
    events,
  ]);

  return (
    <CardContainer
      isLoading={deviceEventsState.isLoading}
      isSuccess={deviceEventsState.isSuccess}
      isError={deviceEventsState.isError}
      hasData={deviceEventsState.isSuccess && deviceEventsState.data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {deviceEventsState.isSuccess && <EventsFunnel data={data} />}
    </CardContainer>
  );
};

interface AppEventsFunnel extends EventsFunnelProps {
  appId: string;
}

export const AppEventsFunnel: React.FC<AppEventsFunnel> = ({
  tenantId,
  appId,
  dateFrom,
  dateTo,
  events,
  title,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const appEventsState = useAppEventsByDay({
    tenantId,
    appId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const data = useMemo(() => buildData(appEventsState.data || [], events), [
    appEventsState,
    events,
  ]);

  return (
    <CardContainer
      isLoading={appEventsState.isLoading}
      isSuccess={appEventsState.isSuccess}
      isError={appEventsState.isError}
      hasData={appEventsState.isSuccess && appEventsState.data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {appEventsState.isSuccess && <EventsFunnel data={data} />}
    </CardContainer>
  );
};
