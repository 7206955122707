import { useCallback } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { PaginationCollectionForProductsFeeds } from '../../../../../../store/types/pagination';
import { ApiError } from '../../../../../../services/api/api-error';
import { useGridProductsFeedService } from '../../../../../common/grid-products-connector-service-provider';
import { DataResidencyEnum } from '../../../../../../store/types/organisation';
import { IntegrationJobHistory } from '../types';

interface UseIntegrationJobHistoriesProps {
  tenantId: string;
  dataResidency: DataResidencyEnum;
  integrationId: string;
}

type UseIntegrationsResponse = UseQueryResult<
  PaginationCollectionForProductsFeeds<IntegrationJobHistory>,
  ApiError
>;

const useIntegrationJobHistories = (
  props: UseIntegrationJobHistoriesProps,
): UseIntegrationsResponse => {
  const { tenantId, dataResidency, integrationId } = props;

  const gridProductsFeedService = useGridProductsFeedService(dataResidency);

  const fetch = useCallback(async () => {
    const url = `/${tenantId}/integrations/${integrationId}/jobs`;

    return gridProductsFeedService.get<
      PaginationCollectionForProductsFeeds<IntegrationJobHistory>
    >(url);
  }, [gridProductsFeedService, integrationId, tenantId]);

  const result = useQuery(getJobHistoriesKeys(tenantId, integrationId), () => fetch(), {
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    keepPreviousData: true,
    onError: (_error: ApiError) => {},
  });

  return result;
};

export const getJobHistoriesKeys = (
  tenantId?: string,
  integrationId?: string,
): string[] => {
  return [
    'get-integrations-job-histories',
    ...(tenantId ? ['tenantId', tenantId] : []),
    ...(integrationId ? ['query', integrationId] : []),
  ];
};

export default useIntegrationJobHistories;
