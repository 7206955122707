import React from 'react';
import { Avatar, List, Tag, Divider } from 'antd';
import styled from '@emotion/styled';
import { Dictionary } from 'lodash';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import User from '../../../../store/types/user';
import UserRole from '../../../../store/types/user-roles';
import Organisation from '../../../../store/types/organisation';

interface UserListitemProps {
  organisationId: string;
  user: User;
  userRoles: {
    [organization: string]: Dictionary<UserRole>;
  };
  organizations: Dictionary<Organisation>;
}

export default function UserListitem({
  organisationId,
  user,
  userRoles,
  organizations,
}: UserListitemProps) {
  const { t } = useTranslation();
  const currentOrganizationWithRole =
    user.organizationsWithRole &&
    user.organizationsWithRole.find((o) => o.organizationId === organisationId);
  let partnerRole = t('noRole');
  if (
    currentOrganizationWithRole &&
    userRoles[organisationId] &&
    userRoles[organisationId][currentOrganizationWithRole.roleId]
  ) {
    partnerRole =
      userRoles[organisationId][currentOrganizationWithRole.roleId].displayName;
  }

  return (
    <List.Item.Meta
      avatar={<Avatar icon="user" />}
      title={<UserLabel>{user.email}</UserLabel>}
      description={
        <>
          <Label>{user.isSysadmin && t('sysadmin')}</Label>
          {!!organisationId && <Label>{partnerRole}</Label>}
          {!user.isSysadmin &&
            !organisationId &&
            user.organizationsWithRole &&
            !!user.organizationsWithRole.length && (
              <InfoRow>
                {t('organizations')}:{' '}
                {user.organizationsWithRole.map(
                  (orgWithRole, orgWithRoleIndex) =>
                    organizations[orgWithRole.organizationId] && (
                      <React.Fragment key={`organizations_${orgWithRole.organizationId}`}>
                        {!!orgWithRoleIndex && <Divider type="vertical" />}
                        <Link to={`/organisations/${orgWithRole.organizationId}`}>
                          <LinkTag color="green">
                            {`${
                              organizations[orgWithRole.organizationId].displayName
                            } / ${
                              userRoles[orgWithRole.organizationId] &&
                              userRoles[orgWithRole.organizationId][orgWithRole.roleId]
                                ? userRoles[orgWithRole.organizationId][
                                    orgWithRole.roleId
                                  ].displayName
                                : t('noRole')
                            }`}
                          </LinkTag>
                        </Link>
                      </React.Fragment>
                    ),
                )}
              </InfoRow>
            )}
        </>
      }
    />
  );
}

const UserLabel = styled.div`
  @media only screen and (max-width: 576px) {
    word-break: break-all;
  }
`;

const Label = styled.div`
  padding-top: 2px;
`;

const InfoRow = styled(Label)`
  flex-direction: row;
`;

const LinkTag = styled(Tag)`
  cursor: pointer;
  margin-left: 8px;
  margin-bottom: 4px;

  @media only screen and (max-width: 576px) {
    white-space: pre-wrap;
  }
`;
