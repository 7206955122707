import { useMutation, useQueryClient } from 'react-query';
import mime from 'mime-types';
import { BlobServiceClient, BlockBlobUploadStreamOptions } from '@azure/storage-blob';
import { useApiService } from '../../../app/common/api-service-provider';
import libraryQueryKeys from './library-query-keys';
import Media from '../../types/media';

export enum ContentUploadMediaStatusEnum {
  INITiALIZING = 'initializing',
  UPLOADING = 'uploading',
  FINISHING = 'finishing',
  DONE = 'done',
  ERROR = 'error',
}

export interface UploadMediaBody {
  file: File;
  organization: string;
  folderId: string | undefined;
  onProgress?: (percent: number) => void;
  onStatusChange?: (status: ContentUploadMediaStatusEnum) => void;
}

interface FileUploadMeta {
  uri: string;
  sas: string;
  container: string;
  uuid: string;
}

const useContentLibraryUploadMedia = (tenantId: string) => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  return useMutation(
    async (mediaBody: UploadMediaBody) => {
      const { file, folderId, onStatusChange, onProgress } = mediaBody;

      updateUploadStatus(ContentUploadMediaStatusEnum.INITiALIZING, onStatusChange);

      const { uri, sas, container, uuid } = await apiService.get<FileUploadMeta>(
        `/api/util/sas?tenantId=${tenantId}`,
      );

      const fileType = getFileType(file);

      // Create a blobServiceClient object which will be used to create a container client
      const blobServiceClient = new BlobServiceClient(`${uri}?${sas}`);
      // Get a reference to a container
      const containerClient = blobServiceClient.getContainerClient(container);
      // Get a block blob client
      const blockBlobClient = containerClient.getBlockBlobClient(`${tenantId}/${uuid}`);

      // Set the blob upload options
      const options: BlockBlobUploadStreamOptions = {
        blobHTTPHeaders: { blobContentType: fileType },
        onProgress: (ev) => {
          if (onProgress) {
            const percent = (ev.loadedBytes / file.size) * 100;
            onProgress(percent);
          }
        },
      };

      updateUploadStatus(ContentUploadMediaStatusEnum.UPLOADING, onStatusChange);

      // Upload the file to Azure Blob Storage
      await blockBlobClient.uploadData(file, options);

      if (!fileType) throw new Error('The type of the file you uploaded cannot be recognized.');

      updateUploadStatus(ContentUploadMediaStatusEnum.FINISHING, onStatusChange);

      return apiService.post<Media>(
        `/api/media`,
        {
          name: file.name,
          type: fileType,
          size: file.size,
          uuid,
          organization: tenantId,
          folderId,
        },
        { tenantId },
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(libraryQueryKeys.tenantContentLibraryMediaList(tenantId));
      },
    },
  );
};

export default useContentLibraryUploadMedia;

const getFileType = (file: File) => {
  if (file.name.endsWith('.gdmt')) {
    return 'application/x-gdm-template';
  }

  if (file.name.endsWith('.gridapp')) {
    return 'application/x-gridapp';
  }

  return file.type || mime.lookup(file.name) || 'application/octet-stream';
};

function updateUploadStatus(
  status: ContentUploadMediaStatusEnum,
  updateFn: UploadMediaBody['onStatusChange'],
) {
  if (updateFn) {
    updateFn(status);
  }
}
