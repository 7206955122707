import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Avatar, Modal, Alert, Button, message, Row, Card, Typography } from 'antd';
import Token from '../../../../../store/types/token';
import { FormValidation } from 'react-jsonschema-form';
import { ColumnProps } from 'antd/lib/table';
import { Icon } from '../../../../common/schema-form/common';
import PaginatedListTable from '../../../../common/paginated-list/paginatied-list-table';
import usePaginationQueryParams, {
  PaginationQueryParams,
} from '../../../../common/use-pagination-query-params/use-pagination-query-params';
import { SortOrderType } from '../../../../../store/types/sort';
import { usePagination } from '../../../../common/pagination';
import useAccessTokensList, {
  AccessTokensListQueryInterface,
} from '../../../../../store/hooks/access-tokens/use-access-tokens-list';
import DevelopersSectionTokensActionsComponent from './developers-section-tokens-actions.component';
import styled from '@emotion/styled';
import { Subheader } from '../../../../common/app-layout/header';
import PaginatedListSearch from '../../../../common/paginated-list/paginated-list-search';

const { Title } = Typography;

interface Props {
  tokens: Token[];
  fetchTokens: () => Promise<void>;
  createToken: (token: Partial<Token>) => Promise<void>;
  updateToken: (token: Partial<Token>) => Promise<void>;
  deleteToken: (id: string) => Promise<void>;
  clearToken: (id: string) => void;
  loaded: boolean;
}

interface AccessTokensListPaginationParams extends PaginationQueryParams {
  sortField?: string;
  sortOrder?: SortOrderType;
}

const formatDate = (dateToFormat: string | undefined, format: string = 'DD/MM/YYYY') => {
  if (dateToFormat === undefined || !moment(dateToFormat).isValid()) {
    return 'N/A';
  }
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const convertedDate = moment.tz(dateToFormat, userTimezone).format(format);
  return convertedDate + ` (${userTimezone})`;
};

const createSchema = {
  type: 'object',
  properties: {
    name: {
      title: 'Name',
      type: 'string',
      minLength: 3,
      maxLength: 100,
    },
    expiry: {
      title: 'Expiry date (Local Time)',
      type: 'string',
      format: 'datetime',
      default: moment()
        .add(1, 'day')
        .startOf('day')
        .toISOString(),
    },
  },
  required: ['name', 'expiry'],
};

const updateSchema = {
  type: 'object',
  properties: {
    name: {
      title: 'Name',
      type: 'string',
      minLength: 3,
      maxLength: 100,
    },
  },
  required: ['name'],
};

export default (
  {
    tokens,
    createToken,
    deleteToken,
    updateToken,
    fetchTokens,
    clearToken,
  }: Props) => {
  const { t } = useTranslation();


  const defaultSortField = 'lastUsedAt';
  const defaultSortOrder = 'desc';

  const { page, defaultPage, pageSize, defaultPageSize } = usePagination();

  const [paginationQueryParams] = usePaginationQueryParams<AccessTokensListPaginationParams>({
    page: page || defaultPage,
    limit: pageSize || defaultPageSize,
    sortField: defaultSortField,
    sortOrder: defaultSortOrder,
  });

  const useAccessTokensListParams: AccessTokensListQueryInterface = {
    limit: paginationQueryParams.limit || defaultPageSize,
    page: paginationQueryParams.page || defaultPage,
    sortField: defaultSortField,
    sortOrder: defaultSortOrder,
    searchTerm: paginationQueryParams.search || '',
  };

  const {
    data: accessTokens,
    isLoading: isAccessTokenLoading,
    isFetching: isAccessTokenFetching,
    isError: isAccessTokenListError,
    refetch: refetchAccessTokens,
  } = useAccessTokensList(useAccessTokensListParams);


  const onDelete = useCallback(
    async (token: Partial<Token>) => {
      try {
        if (!token.id) {
          throw new Error('Token ID is not available');
        }
        await deleteToken(token.id);
        await refetchAccessTokens();
      } catch (error) {
        message.error(t('accessToken.tokenDeletionFailed'));
      }
    },
    [t, deleteToken, refetchAccessTokens],
  );

  const activeToken = tokens.find((token) => !!token.value);
  const activeTokenValue = activeToken ? activeToken.value : null;

  const onCloseDialog = useCallback(() => {
    if (activeToken) {
      clearToken(activeToken.id);
    }
  }, [activeToken, clearToken]);

  const handleCreateToken = useCallback(async (data: Partial<Token>) => {
    try {
      await createToken(data);
      await refetchAccessTokens();
    } catch (error) {
      message.error(t('accessToken.tokenCreationFailed'));
    }
  }, [createToken, t, refetchAccessTokens]);

  const handleUpdateToken = useCallback(async (data: Partial<Token>) => {
    try {
      await updateToken(data);
      await refetchAccessTokens();
    } catch (error) {
      message.error(t('accessToken.tokenUpdateFailed'));
    }
  }, [updateToken, t, refetchAccessTokens]);

  const customFormValidation = useCallback((token: Partial<Token>, error: FormValidation) => {
    const { expiry, createdAt } = token;

    if (!createdAt && expiry && moment(expiry).isBefore(moment())) {
      error.expiry.addError(t('accessToken.tokenExpiryValidity'));
    }

    return error;
  }, [t]);

  const columns = useMemo<ColumnProps<Token>[]>(() => {
    return [
      {
        title: t('accessToken.token'),
        key: 'token-name',
        width: '26%',
        render: (_, record) => {
          return (
            <p>
              <Avatar icon="tag" />&nbsp;
              {record.name}
            </p>
          );
        },
      },
      {
        title: t('accessToken.created'),
        key: 'token-created-at',
        width: '23%',
        render: (_, record) => {
          return (
            <p>
              {formatDate(record.createdAt)}
            </p>
          );
        },
      },
      {
        title: t('accessToken.expires'),
        key: 'token-expires-at',
        width: '23%',
        render: (_, record) => {
          return (
            <>
              <p>
                {moment(record.expiry).fromNow()}
              </p>
              <small>
                {formatDate(record.expiry, 'DD/MM/YYYY hh:mm:ss A')}
              </small>
            </>
          );
        },
      },
      {
        title: t('accessToken.lastUsed'),
        key: 'token-last-used',
        width: '23%',
        render: (_, record) => {
          return (
            <>
              {record.lastUsedAt &&
                <>
                  <p>
                    {moment(record.lastUsedAt).fromNow()}
                  </p>
                  <small>
                    {formatDate(record.lastUsedAt, 'DD/MM/YYYY hh:mm:ss A')}
                  </small>
                </>
              }
              {!record.lastUsedAt &&
                <p>
                  N/A
                </p>
              }
            </>
          );
        },
      },
      {
        title: <Icon type="setting" />,
        key: 'token-settings',
        width: '5%',
        render: (_, record) => {
          return (
            <DevelopersSectionTokensActionsComponent<Token>
              key={record.name + '-actions'}
              onCreate={handleCreateToken}
              onEdit={handleUpdateToken}
              onDelete={onDelete}
              loaded
              canCreate
              canUpdate
              canDelete
              modalTitle={t('accessToken.editModalTitle')}
              createSchema={createSchema}
              updateSchema={updateSchema}
              customFormValidation={customFormValidation}
              selectedToken={record}
              showActionButton={true}
            />
          );
        },
      },
    ];
  }, [customFormValidation, handleCreateToken, handleUpdateToken, onDelete, t]);

  return (
    <>
      <Modal visible={!!activeToken} closable={false} footer={null}>
        {t('accessToken.tokenCreated')}
        <Alert
          style={{ marginTop: '1em', marginBottom: '1.5em' }}
          showIcon
          type="success"
          message={activeTokenValue}
        />
        <div style={{ textAlign: 'right' }}>
          <Button type="primary" onClick={onCloseDialog}>
            {t('tokenCopied')}
          </Button>
        </div>
      </Modal>

      {isAccessTokenListError &&
        <Row>
          <ErrorCard>
            <ErrorIcon type="frown" />
            <MessageBox level={1}>{t('somethingWentWrong')}</MessageBox>
          </ErrorCard>
        </Row>
      }

      {!isAccessTokenListError &&
        <>
          <Subheader
            variant="dropshadow"
            components={[
              <PaginatedListSearch
                minSearchStringLength={1}
                key="access-token-search-bar"
                searchPlaceholder={t('accessToken.searchTokenPlaceholderLabel')}
              />,
            ]}
          />

          <ButtonWrap>
            <DevelopersSectionTokensActionsComponent<Token>
              onCreate={handleCreateToken}
              onEdit={handleUpdateToken}
              onDelete={onDelete}
              loaded
              canCreate
              canUpdate
              canDelete
              modalTitle={t('accessToken.createModalTitle')}
              createSchema={createSchema}
              updateSchema={updateSchema}
              customFormValidation={customFormValidation}
              showCreateButton={true}
            />
          </ButtonWrap>

          <Row>
            <PaginatedListTable<Token>
              data={accessTokens!}
              columns={columns}
              isDataLoading={isAccessTokenLoading || isAccessTokenFetching}
            />
          </Row>
        </>
      }

    </>
  );
};

const ErrorCard = styled(Card)`
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
    padding: 40px;
`;

const ErrorIcon = styled(Icon)`
    font-size: 64px;
    margin-bottom: 16px;
`;

const MessageBox = styled(Title)`
    margin-top: 12px;
`;

const ButtonWrap = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    margin-right: 80px;

    @media screen and (max-width: 991px) {
        margin-right: 20px;
    }
`;