import React from 'react';
import ContentWrap from '../../../../../common/app-layout/content/content-wrap.component';
import ErrorBoundary from '../../../../../common/error-boundary/error-boundary.component';
import {
  AnalyticsSchema,
  CardType,
  InteractionType,
  SessionInteractionType,
  WeekHeatmapDataSourceType,
} from '@ombori/grid-reports';
import { DeviceReport } from '../../../../../common/overview-report';
import styled from '@emotion/styled';

const Container = styled(ContentWrap)`
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
`;

const DeviceReportContainer = () => {
  const reportSchema: AnalyticsSchema = {
    groups: [
      {
        name: 'Overview',
        cards: [
          {
            type: CardType.Sessions,
            interactionType: SessionInteractionType.Interactive,
          },
          { type: CardType.Nps },
          { type: CardType.EventsList, interactionType: InteractionType.Interactive },
          {
            type: CardType.EventsFunnel,
            title: 'Sales conversions funnel',
            events: ['CATEGORY_VIEW', 'PRODUCT_VIEW', 'CART_ADD', 'CHECKOUT', 'PURCHASE'],
          },
          {
            type: CardType.ProductsEventCount,
            eventType: 'PRODUCT_VIEW',
            title: 'Product views',
          },
          { type: CardType.CategoriesEventCount },
        ],
      },
      {
        name: 'Events flow',
        cards: [{ type: CardType.EventsFlow }],
        columnsCount: 1,
      },
      {
        name: 'Sessions week heatmap',
        cards: [
          {
            type: CardType.WeekHeatmap,
            title: 'Sessions week heatmap',
            dataSource: {
              type: WeekHeatmapDataSourceType.Sessions,
              interactionType: SessionInteractionType.Interactive,
            },
          },
        ],
        columnsCount: 1,
      },
    ],
  };

  return (
    <Container>
      <ErrorBoundary>
        <DeviceReport schema={reportSchema} />
      </ErrorBoundary>
    </Container>
  );
};

export default DeviceReportContainer;
