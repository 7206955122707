import React, { useMemo } from 'react';
import { Icon, Tag, Popover } from 'antd';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const QueueItem = ({ queue, small = false }: { queue: any; small?: boolean }) => {
  const color = useMemo(() => {
    if (!queue.fulfilledPositionsCount) return 'green';
    return (queue.fulfilledPositionsCount || 0) < queue.storeCapacity ? 'yellow' : 'red';
  }, [queue.fulfilledPositionsCount, queue.storeCapacity]);

  const { t } = useTranslation();

  return (
    <Container small={small}>
      <QueueIcon type="team" />
      {queue.title}
      <FloatingTag color={color} small={small}>
        {queue.fulfilledPositionsCount || 0}/{queue.storeCapacity || 0}
      </FloatingTag>
      <Popover
        content={t('queuePeopleWaiting', { people: queue.pendingPositionsCount || 0 })}
      >
        <FloatingTag color="blue" small={small}>
          {queue.pendingPositionsCount || 0}
        </FloatingTag>
      </Popover>
      {queue.open ? null : (
        <Popover content={t('queueIsClosed')}>
          <LockIcon type="lock" small={small} />
        </Popover>
      )}
    </Container>
  );
};

// NOTE: `unused=small ? 1 : 0` is added here to avoid runtime TypeScript warning about boolean field passed down to an unknown property
// eslint-disable-next-line
const FloatingTag = styled(({ small, ...rest }) => (
  <Tag {...rest} unused={small ? 1 : 0} />
))<{
  small: boolean;
}>`
  float: right;
  margin-top: ${({ small }) => (small ? 0 : 8)}px;
`;

// eslint-disable-next-line
const LockIcon = styled(({ small, ...rest }) => (
  <Icon {...rest} unused={small ? 1 : 0} />
))<{
  small: boolean;
}>`
  margin: ${({ small }) => (small ? '4px' : '11px 6px 6px 6px')};
  float: right;
`;

const QueueIcon = styled(({ small, ...rest }) => (
  <Icon {...rest} unused={small ? 1 : 0} />
))`
  align-self: center;
  margin-right: 4px;
`;

const Container = styled.div<{ small: boolean }>`
  min-width: ${({ small }) => (small ? 200 : 300)}px;
  text-align: left;
`;

export default QueueItem;
