import React from 'react';
import styled from '@emotion/styled';
import moment from 'moment';
import { Field } from 'react-final-form';
import { DatePicker as AntdDatePicker } from 'antd';
import InputWrap from './input-wrap';
import { requiredAll } from '../../organisations/organisation-details/queue-details/queue-setup/validate-form';
import { ChangeEvent } from '../../../types';

export interface DatePickerProps {
  name: string;
  label: string;
  isRequired: boolean;
  showTime?: boolean;
  dateFormat: string;
  onChange?: (dateStr: string) => void;
}

const DatePicker = (props: DatePickerProps) => {
  const { name, label, isRequired, dateFormat, showTime = false, onChange } = props;

  return (
    <Field
      name={name}
      validate={isRequired ? requiredAll : undefined}
      render={({ input, meta }) => {
        return (
          <InputWrap
            label={label}
            isRequired={isRequired}
            error={meta.error && meta.touched ? meta.error : undefined}
          >
            <DatePickerContainer
              showTime={showTime ? { format: 'HH:mm' } : false}
              format="YYYY-MM-DD HH:mm"
              value={input.value ? moment(input.value) : undefined}
              onChange={(date) => {
                if (!date) {
                  if (onChange) {
                    onChange('');
                  }

                  return;
                }

                const dateFormatted = date.utc().format(dateFormat);
                input.onChange({
                  target: {
                    value: dateFormatted,
                  },
                } as unknown as ChangeEvent<string>);

                if (onChange) {
                  onChange(dateFormatted);
                }
              }}
            />
          </InputWrap>
        );
      }}
    />
  );
};

export default DatePicker;

const DatePickerContainer = styled(AntdDatePicker)`
  width: 100%;
`;
