import uniqBy from 'lodash/uniqBy';
import { DataMatrixColumnOperationType } from '@ombori/grid-reports';
import sum from 'lodash/sum';
import {
  InstallationEventsByDay,
  SpaceEventsByDay,
  DeviceEventsByDay,
  SpaceInstallationEventsByDay,
} from '../../../use-analytics-report';

export const formatPercentage = (value: number): string => {
  if (isNaN(value)) return '';

  return value % 1 === 0 ? `${value.toFixed(0)  }%` : `${value.toFixed(2)  }%`;
};

const formatIfNonZero = (value: number, count: number): string => {
  if (count && value === 0) return '0%';

  return value !== 0 ? formatPercentage(value) : '';
};

const calculateAverage = (value: number, count: number): number =>
  count !== 0 ? value / count : 0;

const calculatePercentage = (numerator: number, total: number): number =>
  numerator && total !== 0 ? (numerator / total) * 100 : 0;

const countEventTypes = (
  eventsData: { eventType: string; count: number }[],
  eventTypes: string[],
): number => {
  const eventTypeCounts: { [key: string]: number } = {};

  eventsData.forEach((event) => {
    eventTypeCounts[event.eventType] =
      (eventTypeCounts[event.eventType] || 0) + event.count;
  });

  return eventTypes.reduce(
    (acc, eventType) => acc + (eventTypeCounts[eventType] || 0),
    0,
  );
};

interface CalculateDataProps {
  operationType: DataMatrixColumnOperationType.Avg | DataMatrixColumnOperationType.Lowest;
  percentages: number[];
}

const calculateData = ({ operationType, percentages }: CalculateDataProps) => {
  switch (operationType) {
    case DataMatrixColumnOperationType.Avg: {
      const avgPercentage = calculateAverage(sum(percentages), percentages.length);

      const result = formatIfNonZero(avgPercentage, percentages.length);

      return result;
    }

    case DataMatrixColumnOperationType.Lowest: {
      const lowestPercentage = Math.min(...percentages);

      const result = formatIfNonZero(lowestPercentage, percentages.length);

      return result;
    }

    default:
      throw new Error('Invalid operation type');
  }
};

interface EventData {
  eventTypes: string[];
  totalEventTypes: string[];
  distinctKey: string | undefined;
  operationType: DataMatrixColumnOperationType.Avg | DataMatrixColumnOperationType.Lowest;
}

interface InstallationEventData extends EventData {
  eventsData: InstallationEventsByDay[];
}

export const getInstallationAverageOrLowestPercentage = ({
  eventsData = [],
  eventTypes,
  totalEventTypes,
  distinctKey,
  operationType,
}: InstallationEventData): string => {
  const uniqueInstallations = uniqBy(eventsData, distinctKey || 'installationId');

  const percentages = uniqueInstallations.map((installation) => {
    const installationEvents = eventsData.filter(
      (event) => event.installationId === installation.installationId,
    );
    const eventTypeCount = countEventTypes(installationEvents, eventTypes);
    const totalCount = countEventTypes(installationEvents, totalEventTypes);

    return calculatePercentage(eventTypeCount, totalCount);
  });

  const result = calculateData({ operationType, percentages });

  return result;
};

interface SpaceEventData extends EventData {
  eventsData: (SpaceEventsByDay | SpaceInstallationEventsByDay)[];
}

export const getSpaceAverageOrLowestPercentage = ({
  eventsData = [],
  eventTypes,
  totalEventTypes,
  distinctKey,
  operationType,
}: SpaceEventData): string => {
  const uniqueSpaces = uniqBy(eventsData, distinctKey || 'spaceId');

  const percentages = uniqueSpaces.map((space) => {
    const spaceEvents = eventsData.filter((event) => event.spaceId === space.spaceId);
    const eventTypeCount = countEventTypes(spaceEvents, eventTypes);
    const totalCount = countEventTypes(spaceEvents, totalEventTypes);

    if (totalCount === 0) {
      return 0; // Return 0 if totalCount is 0 to avoid division by zero
    }

    return calculatePercentage(eventTypeCount, totalCount);
  });

  // return if empty i.e invalid or 0
  if(!percentages.length){
    return '0%';
  }

  const result = calculateData({ operationType, percentages });

  return result;
};

interface DeviceEventData extends EventData {
  eventsData: DeviceEventsByDay[];
}

export const getDeviceAverageOrLowestPercentage = ({
  eventsData = [],
  eventTypes,
  totalEventTypes,
  distinctKey,
  operationType,
}: DeviceEventData): string => {
  const uniqueSpaces = uniqBy(eventsData, distinctKey || 'deviceId');

  const percentages = uniqueSpaces.map((space) => {
    const spaceEvents = eventsData.filter((event) => event.deviceId === space.deviceId);
    const eventTypeCount = countEventTypes(spaceEvents, eventTypes);
    const totalCount = countEventTypes(spaceEvents, totalEventTypes);

    return calculatePercentage(eventTypeCount, totalCount);
  });

  const result = calculateData({ operationType, percentages });

  return result;
};
