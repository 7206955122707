import React, { useCallback, useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import { Table as AntdTable, Button, Dropdown, Menu, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { TableType } from '../../../../../../types';
import Pagination, { usePagination } from '../../../../../common/pagination';
import { Link, RouteComponentProps } from 'react-router-dom';
import { integrationTypes, IntegrationCronSchedules } from '../common';
import { useIntegrations, IntegrationListData } from '../hooks';
import Overlay from '../../../../../common/overlay';
import { DataResidencyEnum } from '../../../../../../store/types/organisation';

const { Title } = Typography;

export interface IntegrationsListProps extends RouteComponentProps<{ organisationId: string }> {
  dataResidency: DataResidencyEnum;
}

const IntegrationsList = (props: IntegrationsListProps) => {
  const { match, dataResidency } = props;
  const tenantId = match.params.organisationId;

  const paginationContainerRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const { t } = useTranslation();

  const {
    page,
    pageSize,
    setPage,
    setPageSize,
    defaultPage,
    defaultPageSize,
    defaultPageSizeOption,
  } = usePagination();

  const {
    data: integrations,
    isSuccess,
    isLoading: isInitialLoading,
    isFetched,
    isFetching,
    isRefetching,
    error,
  } = useIntegrations({
    tenantId,
    dataResidency,
    queryParams: {
      page,
      limit: pageSize,
    },
  });

  const isDataInvalidating = isFetching && isFetched;
  const currentPage = page ? page : defaultPage;
  const currentPageSize = pageSize ? pageSize : defaultPageSize;
  const totalCount = isSuccess && integrations ? integrations.data.totalItems : 0;

  const columns = useMemo<ColumnProps<IntegrationListData>[]>(() => {
    return [
      {
        title: t('productIntegration.integrationName'),
        key: 'integrationName',
        align: 'left',
        width: '30%',
        ellipsis: true,
        render: (_, record) => {
          return <Link to={`${match.url}/${record.id}/${record.type}`}>{record.name}</Link>;
        },
      },
      {
        title: t('productIntegration.integrationType'),
        key: 'integrationType',
        align: 'left',
        render: (_, record) => {
          const { icon = null, label = null } =
            integrationTypes.find((type) => type.value === record.type) || {};

          return (
            <IntegrationType>
              {icon}
              <span>{label || record.type}</span>
            </IntegrationType>
          );
        },
      },
      {
        title: t('schedules'),
        key: 'schedules',
        align: 'left',
        render: (_, record) => <IntegrationCronSchedules schedules={record.schedule} />,
      },
    ];
  }, [match, t]);

  const handlePageChange = useCallback((pageNum: number) => setPage(pageNum), [setPage]);

  const handlePaginationSizeChange = useCallback(
    (page: number, pageLimit: number) => {
      setPage(page);
      setPageSize(pageLimit);
    },
    [setPage, setPageSize],
  );

  const newIntegrationsMenu = useMemo(() => {
    return (
      <Menu>
        {integrationTypes.map((type) => {
          const { label, icon, value } = type;

          return (
            <Menu.Item key={value}>
              <LinkStyled to={`${match.url}/add/${value}`}>
                {icon}
                <span>{label}</span>
              </LinkStyled>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  }, [match.url]);

  return (
    <>
      <Overlay
        errorOverlay={{
          error,
        }}
      >
        <IntegrationListContainer>
          <Heading>
            <Title level={3}>{t('productIntegration.title')}</Title>
            <Dropdown
              overlay={newIntegrationsMenu}
              placement="bottomRight"
              trigger={['click']}
            >
              <Button icon="plus" type="primary" size="large">
                {t('productIntegration.newIntegration')}
              </Button>
            </Dropdown>
          </Heading>
          <div ref={paginationContainerRef}>
            <Table
              rowKey="id"
              dataSource={integrations ? integrations.data.items : []}
              columns={columns}
              pagination={false}
              size="small"
            />
            <Pagination
              parentContainerRef={paginationContainerRef}
              isLoading={
                !isDataInvalidating && (isFetching || isRefetching || isInitialLoading)
              }
              align="right"
              showSizeChanger
              onShowSizeChange={handlePaginationSizeChange}
              current={currentPage}
              pageSize={currentPageSize}
              total={totalCount}
              onChange={handlePageChange}
              pageSizes={defaultPageSizeOption}
            />
          </div>
        </IntegrationListContainer>
      </Overlay>
    </>
  );
};

const IntegrationListContainer = styled.div`
  position: relative;
  padding: 20px 80px 40px;

  @media screen and (max-width: 991px) {
    padding: 0;
  }
`;

const Table = styled(AntdTable)`
  margin-top: 20px;

  .ant-table-thead > tr > th {
    background: rgba(245, 246, 250, 0.8) !important;
    height: 56px;
  }
  .ant-table-scroll > .ant-table-body {
    overflow-x: auto !important;
  }
  .ant-table-body {
    margin: 0 !important;
  }
` as TableType<IntegrationListData>;

const LinkStyled = styled(Link)`
  display: flex !important;
  align-items: center;
  gap: 5px;
  line-height: 1px;
  width: fit-content;
`;

const IntegrationType = styled.div`
  display: flex !important;
  align-items: center;
  gap: 5px;
  width: fit-content;
`;

const Heading = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
`;

export default IntegrationsList;
