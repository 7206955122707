import * as React from 'react';
import AceEditor from 'react-ace';
import 'brace/mode/css';
import 'brace/mode/javascript';
import 'brace/theme/github';
import { WidgetProps } from 'react-jsonschema-form';

interface CodeEditorProps extends WidgetProps {
  mode: 'css' | 'javascript';
}

const CodeEditor = (props: CodeEditorProps) => {
  const { id, onChange, onBlur, onFocus, disabled, readonly, value, mode } = props;

  const handleFocus: any = (_: never, editor: any) => {
    onFocus(id, editor.getValue());
  };

  const handleBlur: any = (_: never, editor: any) => {
    const code = editor.getValue();
    onBlur(id, code);
    onChange(code);
  };

  return (
    <AceEditor
      value={value}
      width="100%"
      height="100px"
      mode={mode}
      theme="github"
      readOnly={disabled || readonly}
      onBlur={handleBlur}
      onFocus={handleFocus}
    />
  );
};

export default CodeEditor;
