import React from 'react';
import SpaceUsers from './space-details-users.component';
import { SpaceUsersListProps, useSpaceUsersList } from '../../../../../common/use-spaces';
import { usePagination } from '../../../../../common/pagination';
import usePaginationQueryParams from '../../../../../common/use-pagination-query-params';
import { PaginationQueryParams } from '../../../../../common/use-pagination-query-params/use-pagination-query-params';
import { SortOrderType } from '../../../../../../store/types/sort';

type SpaceUsersContainer = Pick<
  React.ComponentProps<typeof SpaceUsers>,
  'match' | 'history' | 'location'
>;

interface SpaceUsersListPaginationParams extends PaginationQueryParams {
  sortField?: string;
  sortOrder?: SortOrderType;
  organizationId?: string;
}

const SpaceUsersContainer = (props: SpaceUsersContainer) => {
  const {
    match: {
      params: { organisationId, spaceId},
    },
  } = props;

  const { page, defaultPage, pageSize, defaultPageSize } = usePagination();

  const [paginationQueryParams] = usePaginationQueryParams<SpaceUsersListPaginationParams>({
    page: page || defaultPage,
    limit: pageSize || defaultPageSize,
    organizationId: organisationId,
  });

  const useOrganizationUsersListParams: SpaceUsersListProps = {
    limit: paginationQueryParams.limit || defaultPageSize,
    organizationId: organisationId,
    page: paginationQueryParams.page || defaultPage,
    spaceId
  };

  const {
    data: users,
    isFetching: isUserListFetching,
    isLoading: isUserListLoading,
    isError
  } = useSpaceUsersList(useOrganizationUsersListParams)

  return (
    <SpaceUsers
      {...props}
      isError={isError}
      isUserListFetching={isUserListFetching}
      isUserListLoading={isUserListLoading}
      usersList={users!}
      organisationId={organisationId}
    />
  );
};

export default SpaceUsersContainer;
