import React, { useCallback, useMemo, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { FieldProps } from 'react-jsonschema-form';
import DataCard from '../../../data-card/data-card.component';
import MultiLanguageWidgetTitle from './multi-language-title.component';
import MultiLanguageLabel from './multi-language-label.component';

type FormData = Record<string, string>;

const Container = styled(DataCard)`
  margin-bottom: 16px;
  padding: 8px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  width: 124px;
`;

// TODO: use global styles if possible
const TextInput = styled.input`
  display: block;
  width: 100%;
  height: 32px;
  padding: 2px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555555;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  margin-left: 8px;
  ${({ disabled }) => `
    background-color: ${disabled ? '#f2f2f2' : '#fff'};
  `}
`;

const MultiLanguageText = ({ schema, formData, onChange, formContext }: FieldProps) => {
  const formDataRef = useRef<FormData | undefined>(undefined);

  const {
    languageFrom = '',
    languageTo = '',
    supportedLanguages = [],
    defaultLanguage = '',
  } = formContext.languageOptions || {};

  const hasMultipleSupportedLanguages = supportedLanguages.length > 1;

  const values: FormData = formData || {};

  const onLanguageToValueChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      onChange({
        ...(formData || {}),
        [languageTo]: e.target.value,
      }),
    [languageTo, onChange, formData],
  );

  const { languageFromValue, languageToValue } = useMemo(
    () => ({
      languageFromValue: values[languageFrom] || '',
      languageToValue: values[languageTo] || '',
    }),
    [languageFrom, languageTo, values],
  );

  useEffect(() => {
    formDataRef.current = formData || {};
  }, [formData]);

  useEffect(() => {
    const schemaProps = schema.properties || {};
    const defaultValues: FormData = Object.keys(schemaProps).reduce(
      (defaultValuesObj: FormData, prop) => {
        const curProps = schemaProps[prop] as any;
        const defaultValue = curProps && curProps.constant ? curProps.constant : '';
        return { ...defaultValuesObj, [prop]: defaultValue };
      },
      {},
    );

    // there's a race condition happening in react-json-schema-form component
    // this solves the initialition of form values based on schema default values
    const timeout = setTimeout(() => {
      onChange({
        ...supportedLanguages.reduce(
          (defaultTranslations: FormData, langCode: string) => ({
            ...defaultTranslations,
            [langCode]: '',
          }),
          {},
        ),
        ...defaultValues,
        ...formDataRef.current,
      });
    }, 1000);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
    // onChange is removed as dep here as it changes on formData change
    // which we don't want. This effect only happens once on mount/during initialization
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schema, supportedLanguages]);

  return (
    <Container>
      <MultiLanguageWidgetTitle
        title={schema.title}
        values={values}
        supportedLanguages={supportedLanguages}
        defaultLanguage={defaultLanguage}
      />
      {hasMultipleSupportedLanguages && (
        <ContentWrapper>
          <SelectWrapper>
            {languageFrom && <MultiLanguageLabel language={languageFrom} />}
          </SelectWrapper>
          {/* TODO: Add proper Validations. Use SchemaForm if possible */}
          <TextInput
            disabled
            type="text"
            value={languageFromValue}
            placeholder=""
            formNoValidate
          />
        </ContentWrapper>
      )}
      <ContentWrapper>
        <SelectWrapper>
          {languageTo && <MultiLanguageLabel language={languageTo} />}
        </SelectWrapper>
        {/* TODO: Add proper Validations. Use SchemaForm if possible */}
        <TextInput
          type="text"
          value={languageToValue}
          onChange={onLanguageToValueChange}
          placeholder=""
          formNoValidate
        />
      </ContentWrapper>
    </Container>
  );
};

export default MultiLanguageText;
