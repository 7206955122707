import * as React from 'react';
import { Typography } from 'antd';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';

const { Title } = Typography;

interface HeaderProps {
  title: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  headerTitleTestId?: string;
}

const StyledHeader = styled.header`
  background: rgb(245, 245, 250);
  box-shadow: inset 0 -1px 0 0 #eaecf4, 0 2px 24px 0 rgba(0, 0, 0, 0.02);
  padding: 40px;
`;

const HeaderTitle = styled(Title)`
  margin-bottom: 0.2em !important;
`;

const titleSuffix = "Ombori Grid";
const Header = ({ title, children, className, headerTitleTestId }: HeaderProps) => {
  return (
    <StyledHeader className={className}>
      <Helmet>
        <title>{(title && (typeof title === "string")) ? `${title} - ${titleSuffix}` : titleSuffix}</title>
      </Helmet>
      <HeaderTitle data-testid={headerTitleTestId}>{title}</HeaderTitle>
      {children}
    </StyledHeader>
  );
};

export default Header;
