import OrganisationSpace from '../../types/organisation-space';
import createKeyedCrudModel, {
  KeyedCrudModel,
} from '../../common/keyed-crud-model/keyed-crud.model';

export const routes = {
  getSpaces: () => ['', 'api', 'spaces'].join('/'),
};

export type OrganisationSpacesModel = KeyedCrudModel<
  OrganisationSpace,
  { organizationId: string }
>;

const organisationSpacesModel: OrganisationSpacesModel = createKeyedCrudModel<
  OrganisationSpace,
  { organizationId: string }
>(routes.getSpaces(), 'organizationId');

export default organisationSpacesModel;
