import snakeCase from 'lodash/snakeCase';

const convertCSV = <T extends string,>(data: T[][]) : string =>  {
  return data.map(row => {
    return row.map(value =>
      `"${value.toString().replace(/"/g, '""')}"`  // Handle values that contain commas or quotes
    ).join(',');
  }).join('\r\n');
}

export default convertCSV;

/**
 * receives an array of objects, converts them into array of records to convert to CSV
 */

export const convertToCSVFromObject = (data: Record<string, string>[]) : string => {
  if (!data || !data.length) {
    return '';
  }

  const columns = Object.keys(data[0]);
  const csvData: string[][] = data.map((item, index) => {
    const row = columns.map((column) => {
      return typeof item[column] !== 'undefined' ? item[column] : '';
    });

    return row;
  });

  return convertCSV([columns, ...csvData]);
};

export const getCsvDataFileName = (
  {
    dateFrom,
    dateTo,
    title,
  }: {
    dateFrom: string;
    dateTo: string;
    title: string;
  }) => {
  return `${snakeCase(title)}-${dateFrom}-${dateTo}.csv`;
};