import { useQuery } from 'react-query';
import { useApiService } from '../../../app/common/api-service-provider';
import segmentQueryKeys from './segment-query-keys';
import { Segment } from '../../types/segment';
import { PaginationCollection } from '../../types/pagination';

const useSegmentList = (tenantId: string, channelId: string, limit = 100, page = 1) => {
  const apiService = useApiService();


  return useQuery(
    segmentQueryKeys.getSegmentsList(tenantId, channelId, limit, page),
    () => apiService.get<PaginationCollection<Segment>>('/api/content-segment', { tenantId, channelId, limit, page }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export default useSegmentList;
