import { action, Action, Select, select, thunk, Thunk } from 'easy-peasy';
import { set } from 'lodash';
import Injections from '../../injections.interface';
import Queue from '../../types/queue';
import { ApiError } from '../../../services/api/api-error';

export interface QueuesModel {
  data: {
    [organisationId: string]: Queue[];
  } | null;
  error: ApiError | null;
  values: Select<QueuesModel, (organisationId: string) => Queue[]>;
  loading: boolean;
  loaded: Select<QueuesModel, boolean>;
  setData: Action<QueuesModel, { organisationId: string; data: Queue[] }>;
  setError: Action<QueuesModel, ApiError | null>;
  setLoading: Action<QueuesModel, boolean>;
  fetch: Thunk<QueuesModel, { organisationId: string }, Injections>;
  create: Thunk<QueuesModel, { organisationId: string; body: Queue }, Injections>;
  setSingle: Action<QueuesModel, { organisationId: string; data: Queue }>;
  update: Thunk<
    QueuesModel,
    { organisationId: string; id: string; body: Queue },
    Injections
  >;
  install: Thunk<
    QueuesModel,
    { organisationId: string; body: { name: string; solution: string } },
    Injections
  >;
}

const queuesModel: QueuesModel = {
  data: null,
  error: null,
  loading: false,
  loaded: select((state) => !!state.data && !!state.data && !state.loading),
  values: select((state) => (organisationId: string) =>
    Object.values((state.data && state.data[organisationId]) || {}).sort((a, b) =>
      a.title.localeCompare(b.title),
    ),
  ),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setSingle: action((state, { organisationId, data }) => {
    const stateData = state.data || {};
    set(stateData, [organisationId, data.id], data);
    state.data = stateData;
  }),
  setData: action((state, { organisationId, data }) => {
    const stateData = {};
    data.forEach((item) => {
      set(stateData, [organisationId, item.id], item);
    });
    state.data = stateData;
  }),
  fetch: thunk(async (actions, { organisationId }, { injections }) => {
    actions.setLoading(true);
    try {
      const data = await injections.apiService.get<Queue[]>('/api/queues/', {
        organizationId: organisationId,
      });
      actions.setData({ organisationId, data });
    } catch (error) {
      actions.setError(error);
    } finally {
      actions.setLoading(false);
    }
  }),
  create: thunk(async (actions, { organisationId, body }, { injections }) => {
    try {
      const data = await injections.apiService.post<Queue>('/api/queues/', body, {
        organizationId: organisationId,
      });
      actions.setSingle({ organisationId, data });
      return data;
    } catch (error) {
      actions.setError(error);
    }
    return null;
  }),
  update: thunk(async (actions, { organisationId, id, body }, { injections }) => {
    const data = await injections.apiService.put<Queue>(`/api/queues/${id}`, body, {
      organizationId: organisationId,
    });
    actions.setSingle({ organisationId, data });
    return data;
  }),
  install: thunk(async (actions, { organisationId, body }, { injections }) => {
    try {
      const data = await injections.apiService.post<Queue>('/api/queues/install', body, {
        organizationId: organisationId,
      });
      actions.setSingle({ organisationId, data });
      return data;
    } catch (error) {
      actions.setError(error);
    }
    return null;
  }),
};

export default queuesModel;
