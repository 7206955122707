import React, { useEffect } from 'react';
import { get } from 'lodash';
import { useActions, useStore } from 'easy-peasy';
import Transactions from './transactions.component';
import { RootModel, RootState } from '../../../../../../store/models/root.model';
import { KEY_ACCOUNT_BALANCE } from '../../../../../../store/models/organization-account-balance/organization-account-balance.model';

type TransactionsContainerProps = Omit<
  React.ComponentProps<typeof Transactions>,
  'paginatedTransactions' | 'loading' | 'error' | 'fetchTransactions' | 'accountBalance' | 'spaces' | 'tenant'
>;

const TransactionsContainer = (props: TransactionsContainerProps) => {
  const { organisationId } = props;

  const { paginatedTransactions, loading, error, accountBalance, spaces, tenant } = useStore<RootState>(
    (state) => ({
      paginatedTransactions: state.organisationTransactions.data[organisationId],
      loading: state.organisationTransactions.loading[organisationId],
      error: state.organisationTransactions.error[organisationId],
      accountBalance: get(
        state.organizationAccountBalance.data,
        `${KEY_ACCOUNT_BALANCE}.${organisationId}`,
      ),
      spaces: state.organisationSpaces.values(organisationId),
      tenant: state.organisations.data && state.organisations.data[organisationId],
    }),
    [organisationId],
  );

  const { fetchTransactions, fetchSpaces } = useActions<RootModel>((actions) => ({
    fetchTransactions: actions.organisationTransactions.fetch,
    fetchSpaces: actions.organisationSpaces.fetch,
  }));

  useEffect(() => {
    fetchSpaces({ silent: true, organizationId: organisationId });
  }, [fetchSpaces, organisationId]);

  return (
    <Transactions
      {...props}
      accountBalance={accountBalance}
      fetchTransactions={fetchTransactions}
      paginatedTransactions={paginatedTransactions}
      loading={loading}
      error={error}
      organisationId={organisationId}
      spaces={spaces}
      tenant={tenant}
    />
  );
};

export default TransactionsContainer;
