import React, { useMemo } from 'react';
import groupBy from 'lodash/groupBy';
import head from 'lodash/head';
import { DataResidencyEnum } from '../../../../store/types/organisation';
import {
  useTenantCategoriesByDay,
  useInstallationCategoriesByDay,
  useSpaceCategoriesByDay,
  useDeviceCategoriesByDay,
  useAppCategoriesByDay,
} from '../../use-analytics-report';
import {
  TenantCategoriesByDay,
  InstallationCategoriesByDay,
  SpaceCategoriesByDay,
  DeviceCategoriesByDay,
  AppCategoriesByDay,
} from '../../use-analytics-report/aggregated-reports/types';
import CardContainer from './card-container';
import PieChart from '../charts/pie';
import orderBy from 'lodash/orderBy';
import toPairs from 'lodash/toPairs';
import sumBy from 'lodash/sumBy';
import { useProductTypesList } from '../../use-grid-products';
import { getProductTypeTitle, IsoLanguageIds } from '@ombori/grid-products';
import { GridStyles } from '@ombori/grid-reports';

const defaultEventType = 'CATEGORY_VIEW';

const title = 'Category views';

type RawData =
  | TenantCategoriesByDay
  | InstallationCategoriesByDay
  | SpaceCategoriesByDay
  | DeviceCategoriesByDay
  | AppCategoriesByDay;

type CardDataItem = [string, { label: string; value: number }[]];

type CardData = CardDataItem[];

const buildDataItem = (
  date: string,
  data: { label: string; value: number }[],
): CardDataItem => [date, data];

const maxEventsCount = 7;

const aggregateData = (
  rawData: RawData[],
  eventType: string,
): { categoryId: string; count: number }[] => {
  const filteredData = rawData.filter((dataItem) => dataItem.eventType === eventType);

  const aggregatedEvents = orderBy(
    toPairs(groupBy(filteredData, (dataItem) => dataItem.categoryId)).map(
      ([categoryId, events]) => {
        const result = {
          categoryId,
          count: sumBy(events, (event) => event.count),
        };

        return result;
      },
    ),
    (dataItem) => dataItem.count,
    'desc',
  );

  return aggregatedEvents;
};

const buildData = (rawData: { categoryId: string; count: number }[]): CardData => {
  const result = rawData.map((dataItem) => {
    const list = [{ label: dataItem.categoryId, value: dataItem.count }];

    const result = buildDataItem(dataItem.categoryId, list);

    return result;
  });

  return result;
};

interface UseCardDataProps {
  tenantId: string;
  dataResidency: DataResidencyEnum;
  rawData: RawData[];
  eventType: string;
}

interface UseCardDataResult {
  data: CardData;
  totalEventsCount: number;
}

const useCardData = ({
  tenantId,
  dataResidency,
  rawData,
  eventType,
}: UseCardDataProps): UseCardDataResult => {
  const aggregatedEvents = useMemo(() => aggregateData(rawData, eventType), [
    rawData,
    eventType,
  ]);

  const topEvents = aggregatedEvents.slice(0, maxEventsCount);

  const productTypesListState = useProductTypesList({
    ids: topEvents.map((event) => event.categoryId),
    dataResidency,
    tenantId,
  });

  const cardData = useMemo(() => {
    const productTypesList = productTypesListState.data
      ? productTypesListState.data.data
      : [];

    const firstProductType = head(productTypesList);

    const productTypeTitle = firstProductType ? firstProductType.title : undefined;

    const firstTitle = head(productTypeTitle);

    const languageId = firstTitle ? firstTitle.isoLanguageId : IsoLanguageIds.en_GB;

    const newData = topEvents.map((event) => {
      const productType = productTypesList.find(
        (productType) => productType.productTypeId === event.categoryId,
      );

      if (!productType) {
        return event;
      }

      const productTypeTitle = getProductTypeTitle(productType, languageId);

      if (!productTypeTitle) {
        return event;
      }

      return {
        ...event,
        categoryId: productTypeTitle.label,
      };
    });

    const result = buildData(newData);

    return result;
  }, [topEvents, productTypesListState]);

  const totalEventsCount = sumBy(aggregatedEvents, (event) => event.count);

  const result = useMemo(
    () => ({
      data: cardData,
      totalEventsCount,
    }),
    [cardData, totalEventsCount],
  );

  return result;
};

interface CategoriesEventCountProps {
  tenantId: string;
  dateFrom: string;
  dateTo: string;
  dataResidency: DataResidencyEnum;
  gridStyles?: GridStyles;
  isVisibleWithoutData?: boolean;
}

export const TenantCategoriesEventCount: React.FC<CategoriesEventCountProps> = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const tenantCategoriesEventsState = useTenantCategoriesByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const { data, totalEventsCount } = useCardData({
    rawData: tenantCategoriesEventsState.data || [],
    tenantId,
    eventType: defaultEventType,
    dataResidency,
  });

  return (
    <CardContainer
      isLoading={tenantCategoriesEventsState.isLoading}
      isSuccess={tenantCategoriesEventsState.isSuccess}
      isError={tenantCategoriesEventsState.isError}
      hasData={data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {tenantCategoriesEventsState.isSuccess && (
        <PieChart title={title} data={data} total={totalEventsCount} />
      )}
    </CardContainer>
  );
};

interface InstallationCategoriesEventCountProps extends CategoriesEventCountProps {
  installationId: string;
}

export const InstallationCategoriesEventCount: React.FC<
  InstallationCategoriesEventCountProps
> = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const installationCategoriesEventsState = useInstallationCategoriesByDay({
    tenantId,
    installationId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const { data, totalEventsCount } = useCardData({
    rawData: installationCategoriesEventsState.data || [],
    tenantId,
    eventType: defaultEventType,
    dataResidency,
  });

  return (
    <CardContainer
      isLoading={installationCategoriesEventsState.isLoading}
      isSuccess={installationCategoriesEventsState.isSuccess}
      isError={installationCategoriesEventsState.isError}
      hasData={data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {installationCategoriesEventsState.isSuccess && (
        <PieChart title={title} data={data} total={totalEventsCount} />
      )}
    </CardContainer>
  );
};

interface SpaceCategoriesEventCountProps extends CategoriesEventCountProps {
  spaceId: string;
}

export const SpaceCategoriesEventCount: React.FC<SpaceCategoriesEventCountProps> = ({
  tenantId,
  spaceId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const spaceCategoriesEventsState = useSpaceCategoriesByDay({
    tenantId,
    spaceId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const { data, totalEventsCount } = useCardData({
    rawData: spaceCategoriesEventsState.data || [],
    tenantId,
    eventType: defaultEventType,
    dataResidency,
  });

  return (
    <CardContainer
      isLoading={spaceCategoriesEventsState.isLoading}
      isSuccess={spaceCategoriesEventsState.isSuccess}
      isError={spaceCategoriesEventsState.isError}
      hasData={data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {spaceCategoriesEventsState.isSuccess && (
        <PieChart title={title} data={data} total={totalEventsCount} />
      )}
    </CardContainer>
  );
};

interface DeviceCategoriesEventCountProps extends CategoriesEventCountProps {
  deviceId: string;
}

export const DeviceCategoriesEventCount: React.FC<DeviceCategoriesEventCountProps> = ({
  tenantId,
  deviceId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const deviceCategoriesEventsState = useDeviceCategoriesByDay({
    tenantId,
    deviceId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const { data, totalEventsCount } = useCardData({
    rawData: deviceCategoriesEventsState.data || [],
    tenantId,
    eventType: defaultEventType,
    dataResidency,
  });

  return (
    <CardContainer
      isLoading={deviceCategoriesEventsState.isLoading}
      isSuccess={deviceCategoriesEventsState.isSuccess}
      isError={deviceCategoriesEventsState.isError}
      hasData={data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {deviceCategoriesEventsState.isSuccess && (
        <PieChart title={title} data={data} total={totalEventsCount} />
      )}
    </CardContainer>
  );
};

interface AppCategoriesEventCountProps extends CategoriesEventCountProps {
  appId: string;
}

export const AppCategoriesEventCount: React.FC<AppCategoriesEventCountProps> = ({
  tenantId,
  appId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const appCategoriesEventsState = useAppCategoriesByDay({
    tenantId,
    appId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const { data, totalEventsCount } = useCardData({
    rawData: appCategoriesEventsState.data || [],
    tenantId,
    eventType: defaultEventType,
    dataResidency,
  });

  return (
    <CardContainer
      isLoading={appCategoriesEventsState.isLoading}
      isSuccess={appCategoriesEventsState.isSuccess}
      isError={appCategoriesEventsState.isError}
      hasData={data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {appCategoriesEventsState.isSuccess && (
        <PieChart title={title} data={data} total={totalEventsCount} />
      )}
    </CardContainer>
  );
};
