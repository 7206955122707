import * as React from 'react';
import styled from '@emotion/styled';
import { Typography } from 'antd';

const { Title } = Typography;

const MainSectionContent = styled.div`
  & > .field-object > .object-card-wrapper {
    margin: 5px auto;
  }
  .field-string,
  .field-boolean,
  .field-number,
  .field-array,
  .field-undefined {
    padding: 0;
  }

  label {
    display: inline-block;
    max-width: 100%;
    font-weight: 700;
    min-width: 80px;
    width: 90%;
  }

  gap: 8px;
  display: flex;
  flex-direction: column;
`;

const MainSectionTitle = styled.div`
  padding: 16px;
  margin-bottom: 16px;
  border-bottom: solid 1px rgb(235, 237, 244);

  h2 {
    line-height: 1.2;
  }
`;

interface MainSectionProps {
  title?: string;
  children: React.ReactNode;
}

const MainSection = ({ title, children }: MainSectionProps) => {
  return (
    <>
      {title && (
        <MainSectionTitle>
          <Title level={2}>{title}</Title>
        </MainSectionTitle>
      )}
      <MainSectionContent>{children}</MainSectionContent>
    </>
  );
};

export default MainSection;
