import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'antd';
import styled from '@emotion/styled';
import { RouteComponentProps } from 'react-router';
import { Redirect } from 'react-router-dom';
import { useQueryString } from '../../../common/use-query-string-param/use-query-string-param';
import Organisation from '../../../../store/types/organisation';
import ErrorView from '../../../common/error-view/error-view.component';
import AppButton from '../../../common/app-button/app-button.component';
import OnboardingPanelLayout from '../../../onboarding/onboarding-panel-layout/onboarding-panel-layout.component';

const FailedAlert = styled(Alert)`
  margin-bottom: 32px;
`;

const SubscriptionInfoContent = styled.p`
  margin: 24px 0;
  font-size: 16px;

  ul {
    list-style: disc;
    margin: 16px;
    li {
      margin: 8px 0;
    }
  }
`;

interface SubscriptionSetupProps extends RouteComponentProps<{ organisationId: string }> {
  organisation: Organisation | null;
  createSubscription: (data: {
    successPath: string;
    cancelPath: string;
    organisationId: string;
  }) => Promise<void>;
}

const SubscriptionSetup = ({
  match,
  organisation,
  createSubscription,
}: SubscriptionSetupProps) => {
  const { t } = useTranslation();
  const {
    params: { organisationId },
  } = match;
  const { returnPath, result } = useQueryString();
  const [isCreateSubscriptionLoading, setIsCreateSubscriptionLoading] = useState(false);
  const [isFailed, setIsFailed] = useState(result === 'fail');

  const subscriptionSuccessPath =
    (returnPath as string) || `/organisations/${organisationId}`;

  const handleContinueSubscriptionClick = useCallback(async () => {
    try {
      setIsCreateSubscriptionLoading(true);
      await createSubscription({
        organisationId,
        successPath: subscriptionSuccessPath,
        cancelPath: `/organisations/${organisationId}/subscription?result=fail&returnPath=${encodeURIComponent(
          subscriptionSuccessPath,
        )}`,
      });
    } catch (err) {
      setIsCreateSubscriptionLoading(false);
      setIsFailed(true);
    }
  }, [createSubscription, subscriptionSuccessPath, organisationId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!organisation) {
    return <ErrorView />;
  }

  if (organisation.hasActiveSubscription) {
    return <Redirect to={subscriptionSuccessPath} />;
  }

  return (
    <OnboardingPanelLayout>
      {isFailed && (
        <FailedAlert
          message="Subscription error"
          description={t('subscriptionSetupErrorMessage')}
          type="error"
          showIcon
        />
      )}
      <SubscriptionInfoContent>
        <h1>We need to verify your identity</h1>
        <p>For security reasons we need to verify your identity using a credit card.</p>
        <ul>
          <li>You will not be charged at this time</li>
          <li>Your first three devices are always free</li>
          <li>
            You need to manually opt in to paid offers before your card will be charged
          </li>
        </ul>
        <b>Total due today: $0</b>
      </SubscriptionInfoContent>
      <AppButton
        loading={isCreateSubscriptionLoading}
        onClick={handleContinueSubscriptionClick}
        block
      >
        {t('subscriptionSetupContinue')}
      </AppButton>
    </OnboardingPanelLayout>
  );
};

export default SubscriptionSetup;
