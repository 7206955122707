import React from 'react';
import GridappBuild from '../../../../../store/types/gridapp-build';
import VersionPicker from '../../internal-fields/version-picker';
import Gridapp from '../../../../../store/types/gridapp';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { BuildSchemaResponse } from '../../../../../types';

const CHEVRON_RIGHT = '\u203A';

export const buildFieldSchema = ({
  gridAppId,
  gridApps,
  gridAppBuilds,
  isGridAppNamePickerVisible,
  isGridAppVersionPickerVisible,
  t,
}: {
  gridAppId: string;
  gridApps: Gridapp[];
  gridAppBuilds: GridappBuild[];
  isGridAppNamePickerVisible: boolean;
  isGridAppVersionPickerVisible: boolean;
  t: TFunction;
}): BuildSchemaResponse => {
  return {
    type: 'object',
    properties: {
      ...(isGridAppNamePickerVisible && {
        id: {
          type: 'string',
          title: t('application'),
          enum: gridApps.map((app) => app.id),
          enumNames: gridApps.map((app) =>
            app.author
              ? `${app.author} ${CHEVRON_RIGHT} ${app.displayName}`
              : app.displayName,
          ),
        },
      }),
      ...(isGridAppVersionPickerVisible && {
        buildId: {
          type: 'string',
          title: t('version'),
          readOnly: !gridAppId || !gridAppBuilds.length,
          enum: gridAppBuilds.map((build) => build.id),
          enumNames: gridAppBuilds.map((build) => {
            const label = `${t('ver')}${build.result ? build.result.version : ''}`;

            return label;
          }),
        },
      }),
    },
  };
};

export const buildUiSchema = (gridAppBuilds: GridappBuild[]): BuildSchemaResponse => {
  return {
    objectFieldTemplate: 'none',
    buildId: {
      'ui:field': (fromProps: any) => <VersionPicker {...fromProps} />,
      'ui:options': {
        enumExtra: gridAppBuilds.map((build) => {
          const buildDate = `${dayjs(build.createdAt).format('YYYY-MM-DD HH:mm')},
                          ${dayjs(build.createdAt).fromNow()}`;
          const releaseNotes = build.result ? build.result.releaseNotes : undefined;

          return {
            buildDate,
            releaseNotes,
          };
        }),
      },
    },
  };
};
