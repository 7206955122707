import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import BrowsersList from './managed-browsers-list.component';
import { RootModel, RootState } from '../../../store/models/root.model';
import { GRID_ALL_BROWSERS_KEY } from '../../../store/models/organisation-browsers/organisation-browsers.model';

type BrowsersListContainer = Omit<
  React.ComponentProps<typeof BrowsersList>,
  'browsers' | 'loaded' | 'fetchBrowsers'
>;

const BrowsersContainer = () => {
  const { fetchBrowsers } = useActions<RootModel>((actions) => ({
    fetchBrowsers: actions.organisationBrowsers.fetchAll,
  }));

  const { browsers, loaded } = useStore<RootState>(
    (state) => ({
      browsers: state.organisationBrowsers.values(GRID_ALL_BROWSERS_KEY),
      loaded: state.organisationBrowsers.loaded(GRID_ALL_BROWSERS_KEY),
    }),
    [],
  );

  return (
    <BrowsersList browsers={browsers} loaded={loaded} fetchBrowsers={fetchBrowsers} />
  );
};

export default BrowsersContainer;
