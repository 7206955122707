import {
  DataResidencyEnum,
  GridProduct,
  GridProductServiceClient,
  IsoLanguageIds,
  ProductType,
} from '@ombori/grid-products';
import { useQuery, UseQueryResult } from 'react-query';

interface UseProductsSearchProps {
  tenantId: string;
  dataResidency: DataResidencyEnum;
  env: string;
  enabled: boolean;
  term: string;
}

const useProductsSearch = ({
  tenantId,
  dataResidency,
  env,
  enabled,
  term,
}: UseProductsSearchProps): UseQueryResult<
  { products: Partial<GridProduct>[]; productTypes: ProductType[] },
  unknown
> => {
  const gridProductsClient = new GridProductServiceClient({
    dataResidency: dataResidency as DataResidencyEnum,
    environment: env,
    tenantId,
    locale: IsoLanguageIds.en_GB,
  });

  return useQuery(
    ['productSearch', tenantId, dataResidency, env, term],
    () =>
      gridProductsClient.search({
        term,
        select:
          'productGroupId,variants,catalogPageLocationProduct,productPriceList,productItemQuantity,productName,productDescription',
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      enabled,
    },
  );
};

export default useProductsSearch;
