import { action, Action, Select, select, thunk, Thunk } from 'easy-peasy';
import { ApiError } from '../../../services/api/api-error';
import Injections from '../../injections.interface';
import PricingSection from '../../types/pricing-section';

export interface OrganisationPricingModel {
  data: { [organisationId: string]: PricingSection[] };
  error: { [organisationId: string]: Error | null };
  loading: { [organisationId: string]: boolean };
  loaded: Select<OrganisationPricingModel, (organisationId: string) => boolean>;
  setData: Action<OrganisationPricingModel, { key: string; data: PricingSection[] }>;
  setError: Action<OrganisationPricingModel, { key: string; error: ApiError | null }>;
  setLoading: Action<OrganisationPricingModel, { key: string; loading: boolean }>;
  fetch: Thunk<OrganisationPricingModel, { organisationId: string }, Injections>;
}

const organisationPricingModel: OrganisationPricingModel = {
  loading: {},
  error: {},
  data: {},
  loaded: select((state) => (key: string) => !!state.data[key] && !state.loading[key]),
  setLoading: action((state, { key, loading }) => {
    state.loading[key] = loading;
  }),
  setError: action((state, { key, error }) => {
    state.error[key] = error;
  }),
  setData: action((state, { key, data }) => {
    state.data[key] = data;
  }),
  fetch: thunk(async (actions, { organisationId }, { injections }) => {
    actions.setLoading({ key: organisationId, loading: true });
    actions.setError({ key: organisationId, error: null });
    try {
      const data = await injections.apiService.get<PricingSection[]>(
        `/api/organizations/${organisationId}/pricing`,
      );
      actions.setData({ key: organisationId, data });
    } catch (error) {
      actions.setError({ key: organisationId, error });
    } finally {
      actions.setLoading({ key: organisationId, loading: false });
    }
  }),
};

export default organisationPricingModel;
