import React from 'react';
import { useActions } from 'easy-peasy';
import Logs from './logs.component';
import { RootModel } from '../../../../../../store/models/root.model';

type LogsContainerProps = Omit<React.ComponentProps<typeof Logs>, 'subscribe'>;

const LogsContainer = (props: LogsContainerProps) => {
  const { subscribe } = useActions<RootModel>((actions) => ({
    subscribe: actions.deviceDetails.subscribeLogs,
  }));

  return <Logs {...props} subscribe={subscribe} />;
};

export default LogsContainer;
