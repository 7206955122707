import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import ApiService from '../../../services/api/api.service';
import { getApiUrl } from '../../../utils/env';
import Organisation from '../../../store/types/organisation';

const api = new ApiService(getApiUrl());
const getAllTenants = (): Promise<Organisation[]> => api.get('/api/organizations');

const TenantPicker = ({ onChange, excludeTenantId }: { excludeTenantId: string; onChange: (id: string) => void }) => {
  const [tenants, setTenants] = useState<Organisation[]>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const loadTenants = async () => {
      try {
        setLoading(true);
        const data = await getAllTenants();

        setTenants(data.filter(tenant => tenant.id !== excludeTenantId));
      } finally {
        setLoading(false);
      }
    }
    loadTenants();
  }, [setTenants, excludeTenantId]);

  return (
    <Select disabled={loading} style={{ width: '45%' }} onChange={onChange} placeholder="Select tenant...">
      {tenants.map(tenant => (
        <Select.Option key={tenant.id} value={tenant.id}>{tenant.displayName}</Select.Option>
      ))}
    </Select>
  );
}

export default TenantPicker;