import React, { useMemo } from 'react';
import { useActions, useStore } from 'easy-peasy';
import { RootModel, RootState } from '../../../../store/models/root.model';
import OrganisationsList from '../../../common/organisations-list/organisations-list.component';
import Organisation from '../../../../store/types/organisation';
import { getPermissions } from '../../../../utils/auth/permissions';

type OrganisationsListContainerProps = Omit<
  React.ComponentProps<typeof OrganisationsList>,
  | 'organisations'
  | 'loaded'
  | 'fetchOrganisations'
  | 'createOrganisation'
  | 'updateOrganisation'
  | 'deleteOrganisation'
  | 'isCreateChildOrganisationMode'
  | 'parentOrganization'
  | 'canCreate'
  | 'canUpdate'
  | 'canDelete'
  | 'validateSlug'
  | 'isCurrentUserSysadmin'
>;

const OrganisationsListContainer = (props: OrganisationsListContainerProps) => {
  const {
    match: {
      params: { organisationId },
    },
  } = props;

  const {
    fetchOrganisations,
    createOrganisation,
    updateOrganisation,
    deleteOrganisation,
    fetchCurrentUser,
    validateSlug,
  } = useActions<RootModel>((actions) => ({
    fetchOrganisations: actions.organisations.fetch,
    fetchCurrentUser: actions.auth.getCurrentUser,
    createOrganisation: actions.organisations.create,
    updateOrganisation: actions.organisations.update,
    deleteOrganisation: actions.organisations.delete,
    validateSlug: actions.organisations.validateSlug,
  }));

  const { organisations, loaded, permissions } = useStore<
    RootState
  >((state) => ({
    organisations: state.organisations.values,
    loaded: state.organisations.loaded,
    permissions: getPermissions(state.auth.user, props.match.params.organisationId),
  }));

  const { childrenOrganisations, parentOrganization } = useMemo(() => {
    return organisations.reduce(
      (
        orgs: {
          childrenOrganisations: Organisation[];
          parentOrganization: Organisation | undefined;
        },
        org: Organisation,
      ) => {
        const updatedOrgs = { ...orgs };
        if (org.parentOrganizationId === organisationId) {
          updatedOrgs.childrenOrganisations = [...orgs.childrenOrganisations, org];
        }

        if (org.id === organisationId) {
          updatedOrgs.parentOrganization = org;
        }

        return updatedOrgs;
      },
      { childrenOrganisations: [] },
    );
  }, [organisations, organisationId]);

  return (
    <OrganisationsList
      isCreateChildOrganisationMode
      parentOrganization={parentOrganization}
      organisations={childrenOrganisations}
      loaded={loaded}
      fetchOrganisations={fetchOrganisations}
      createOrganisation={createOrganisation}
      updateOrganisation={updateOrganisation}
      deleteOrganisation={deleteOrganisation}
      validateSlug={validateSlug}
      canCreate={permissions.organizations.create}
      canUpdate={permissions.organizations.update}
      canDelete={permissions.organizations.remove}
      isCurrentUserSysadmin={false}
      fetchCurrentUser={fetchCurrentUser}
      {...props}
    />
  );
};

export default OrganisationsListContainer;
