import React from 'react';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/core';
import Tooltip, { TooltipProps } from 'antd/lib/tooltip';

const ScrollableTooltip = (props: TooltipProps) => {
  return (
    <ScrollableTooltipContainer>
      <Global
        styles={css`
          .scrollable-tooltip {
            max-height: 30vh;
            max-width: 300px;

            .ant-tooltip-content {
              max-height: inherit;
              padding: 5px 0;
              background-color: rgba(0, 0, 0, 0.75);
              box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
              border-radius: 4px;
            }

            .ant-tooltip-inner {
              max-height: calc(30vh - 20px);
              overflow-y: auto;
              background-color: unset;
              box-shadow: unset;
            }
          }
        `}
      />
      <Tooltip overlayClassName="scrollable-tooltip" {...props} />
    </ScrollableTooltipContainer>
  );
};

const ScrollableTooltipContainer = styled.div``;

export default ScrollableTooltip;
