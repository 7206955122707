import { useQuery } from 'react-query';
import { useApiService } from '../api-service-provider';
import { AnalyticsSchema } from '@ombori/grid-reports';

const useTenantSchema = ({ organizationId }: { organizationId: string }) => {
  const apiService = useApiService();

  return useQuery(
    ['tenantId', organizationId],
    () =>
      apiService.get<AnalyticsSchema>(`/api/analytics/tenant-schema/${organizationId}`),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export default useTenantSchema;
