import { useMutation, UseMutationResult } from 'react-query';
import { GridKnowledgeSource, KnowledgeSourceUploadProps, useGridLlmService } from '@ombori/grid-llm-react';
import { ApiError } from '../../../services/api/api-error';

type UseStoreAiKnowledgeBaseFileUploadResponse = UseMutationResult<GridKnowledgeSource, ApiError, KnowledgeSourceUploadProps>;

const useStoreAiKnowledgeBaseFileUpload = (): UseStoreAiKnowledgeBaseFileUploadResponse => {
  const gridLlmService = useGridLlmService();

  const result = useMutation(
    async (variables: KnowledgeSourceUploadProps) => {
      return gridLlmService.uploadKnowledgeSource(variables);
    },
    {
      onError: (_error: ApiError) => {},
    },
  );

  return result;
};

export default useStoreAiKnowledgeBaseFileUpload;
