import React from 'react';
import styled from '@emotion/styled';

const SidebarHeaderContent = styled.div`
  max-height: 180px;
  align-items: left;
  text-align: left;
  margin: 0 20px 24px 20px;
`;

interface SidebarHeaderProps {
  children: React.ReactNode;
}

const SidebarHeader = ({ children }: SidebarHeaderProps) => {
  return <SidebarHeaderContent>{children}</SidebarHeaderContent>;
};

export default SidebarHeader;
