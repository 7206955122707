import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { FieldProps } from 'react-jsonschema-form';
import { useTranslation } from 'react-i18next';
import Installation from '../../../../../store/types/organisation-app';
import SchemaForm from '../../schema-form.component';
import validateForm from '../../../../../utils/validation/validate-form';

interface InstallationPickerProps extends FieldProps {
  installations: Installation[];
}

interface InstallationSettingsForm {
  id?: string;
}

const installationSettingsFormUiSchema = {
  objectFieldTemplate: 'none',
};

const FormWrapper = styled.div<{ hasError: boolean }>`
  ${({ hasError }) =>
    hasError &&
    `
    .control-label {
      color: #a94442;
    }
    .form-control {
      border-color: red;

      :focus {
        border-color: #843534;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
      }
    }
  `}
`;

const InstallationPickerComponent = ({
  schema,
  errorSchema,
  installations,
  formData = {},
  onChange,
}: InstallationPickerProps) => {
  const { t } = useTranslation();
  const hasError = useMemo(() => !!errorSchema && !!Object.keys(errorSchema).length, [
    errorSchema,
  ]);

  const handleFormChange = useCallback(
    (settings: InstallationSettingsForm) => {
      const valid = !Object.keys(validateForm(settings, schema)).length;

      onChange({
        ref: 'installation',
        id: settings.id,
        valid,
      });
    },
    [onChange, schema],
  );

  const installationSettingsFormSchema = useMemo(() => {
    return {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          title: t('installation'),
          enum: installations.map((installation) => installation.id),
          enumNames: installations.map((installation) => installation.displayName),
        },
      },
    };
  }, [installations, t]);

  return (
    <FormWrapper hasError={hasError}>
      <SchemaForm
        tagName="div"
        uiSchema={installationSettingsFormUiSchema}
        schema={installationSettingsFormSchema}
        data={formData || {}}
        onChange={handleFormChange}
      />
    </FormWrapper>
  );
};

export default InstallationPickerComponent;
