import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import App from './app.component';
import { RootModel, RootState } from '../store/models/root.model';

const AppContainer = () => {
  const { isReady, user, permissions } = useStore<RootState>(({ app, auth }) => ({
    isReady: app.isReady,
    user: auth.user,
    permissions: auth.permissions,
  }));
  const init = useActions<RootModel>((actions) => actions.app.init);

  return <App user={user} permissions={permissions} isReady={isReady} init={init} />;
};

export default AppContainer;
