import React, { useCallback } from 'react';
import Media from '../../../../../store/types/media';
import { Button, Dropdown, Icon, Menu, message } from 'antd';
import styled from '@emotion/styled';
import { ButtonType } from '../../../../../types';
import useDeleteModal from '../../../../common/use-delete-modal';
import { useTranslation } from 'react-i18next';
import Message from '../../../../common/message';
import useContentLibraryDeleteMedia from '../../../../../store/hooks/content-library/use-content-library-delete-media';

interface ContentLibraryMediaListItemActionsProps {
  tenantId: string;
  mediaItem: Media;
}

const ContentLibraryMediaListItemActions = (props: ContentLibraryMediaListItemActionsProps) => {
  const { tenantId, mediaItem } = props;

  const { t } = useTranslation();

  const [showDeleteModal] = useDeleteModal();

  const { mutateAsync: deleteMedia } = useContentLibraryDeleteMedia(tenantId);

  const handleDelete = useCallback(() => {
    showDeleteModal(t('contents.delete'), t('contents.mediaDelete'), undefined, async () => {
      try {
        await deleteMedia(mediaItem.id);
        message.success(<Message content={t('contents.mediaDeleteSuccess')} />);
      } catch (err) {
        message.error(<Message content={t('contents.mediaDeleteFailed')} />);
      }
    });
  }, [showDeleteModal, deleteMedia, mediaItem, t]);

  return (
    <Dropdown
      placement="bottomRight"
      overlay={
        <Menu>
          <Menu.Item key={`${mediaItem.id}-remove`}>
            <RemoveButton type="link" icon="delete" size="small" onClick={handleDelete}>
              {t('delete')}
            </RemoveButton>
          </Menu.Item>
        </Menu>
      }
    >
      <StyledIcon type="small-dash" />
    </Dropdown>
  );
};

export default ContentLibraryMediaListItemActions;

const RemoveButton = styled(Button)`
  color: rgb(255, 85, 0);
  text-transform: capitalize;

  &:hover,
  &:active,
  &:focus {
    color: rgb(255, 85, 0);
  }
` as ButtonType;

const StyledIcon = styled(Icon)`
  transform: rotate(90deg);
`;
