import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Typography } from 'antd';
import styled from '@emotion/styled';
import { AlertInfo, Divider, PanelCard } from '../common';
import { ChatAssistant, ChatAssistantRef } from '@ombori/grid-llm-react';
import { Icon } from '../../../../common/schema-form/common';
import sendIcon from '../../../../../assets/icons/send-icon.svg';
import transientOptions from '../../../../../utils/transient-emotion-styled-options';

interface StoreAIPlaygroundChatBoxProps {
  assistantId?: string;
  assistantName?: string;
}

const StoreAIPlaygroundChatBox = (
  props: StoreAIPlaygroundChatBoxProps,
  ref: React.Ref<ChatAssistantRef>,
) => {
  const { assistantId, assistantName = '' } = props;

  const { t } = useTranslation();

  return (
    <PanelCardStyled $isFullHeight={!!assistantId}>
      <Row>
        <FormTitle level={4}>{t('storeAI.storeAIPlayground')}</FormTitle>
        <AlertInfo
          message={t('storeAI.storeAIPlaygroundDescription')}
          type="info"
          showIcon
        />
      </Row>
      <Divider />
      {assistantId ? (
        <ChatAssistantStyled
          ref={ref}
          assistantId={assistantId}
          assistant={{ displayName: assistantName }}
          user={{ displayName: t('storeAI.playgroundUser') }}
          isThreadStarted
          sendButtonIcon={<Icon size={16} src={sendIcon} />}
          labels={{
            startConversation: t('storeAI.playgroundStartConversation'),
            sendButton: t('storeAI.playgroundSend'),
            inputPlaceholder: t('storeAI.playgroundInputPlaceholder')
          }}
        />
      ) : (
        <StartConversationHint>{t('storeAI.startConversationHint')}</StartConversationHint>
      )}
    </PanelCardStyled>
  );
};

const PanelCardStyled = styled(PanelCard, transientOptions)<{ $isFullHeight: boolean }>`
  height: calc(-340px + 100vh);

  .ant-card-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    height: -webkit-fill-available;
  }
`;

const FormTitle = styled(Typography.Title)`
  margin-bottom: 8px !important;
`;

const StartConversationHint = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-grow: 1;
  width: 100%;
  color: #8f8d8d;
`;

const ChatAssistantStyled = styled(ChatAssistant)`
  .chat-assistant-input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2px 0 10px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    transition: all 0.3s;

    &:hover {
      border-color: #2364aa;
      border-right-width: 1px !important;
    }

    &:focus {
      border-color: #90b6d1;
      border-right-width: 1px !important;
      outline: 0;
      box-shadow: 0 0 0 2px rgba(104, 155, 196, 0.2);
    }
  }

  .chat-assistant-input {
    border: none;
    resize: none;
    display: flex;
    width: 100%;
    margin-bottom: 5px;

    &:focus {
      border: none;
      box-shadow: none;
      display: flex;
      width: 100%;
    }
  }

  .chat-assistant-send-button {
    margin-left: auto;
    margin: auto 10px auto auto;
    background-color: #52c41a;
    border-color: #52c41a;
    color: #fff;

    &:hover,
    &:focus {
      color: #dde6eb;
      background-color: #52c41ae6;
      border-color: #52c41ae6;
    }

    &:active {
      color: #dde6eb;
      background-color: #52c41a;
      border-color: #52c41a;
    }
  }
`;

export default forwardRef(StoreAIPlaygroundChatBox);
