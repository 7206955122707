import React, { useCallback, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import {
  Icon,
  Typography,
  Row,
  Col,
  Button,
  Table,
  Popconfirm,
  Dropdown,
  Menu,
} from 'antd';
import { RouteComponentProps, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OrganisationSpace from '../../../../store/types/organisation-space';
import Overlay from '../../../common/overlay/overlay.component';
import Spinner from '../../../common/spinner/spinner.component';
import PanelCard from '../../../common/panel-card/panel-card.component';
import Browser from '../../../../store/types/browser';
import MobileEndpoint from '../../../../store/types/mobile-endpoint';
import Header from '../../../common/app-layout/header/header.component';
import Organisation from '../../../../store/types/organisation';

const { Title } = Typography;
type Alignment = boolean | 'left' | 'right' | undefined;

const RemoveButton = styled(Button)`
  color: rgb(255, 85, 0);
  text-transform: capitalize;
  &:hover,
  &:active,
  &:focus {
    color: rgb(255, 85, 0);
  }
` as any;

const DisabledButton = styled(Button)`
  color: #b3b3b3;
  text-transform: capitalize;
` as any;

const EditButton = styled(Button)`
  color: #689bc4;
  text-transform: capitalize;
  &:hover,
  &:active,
  &:focus {
    color: #689bc4;
  }
` as any;

const StyledIcon = styled(Icon)`
  font-size: 21px;
`;

interface SpaceComponentProps extends RouteComponentProps<{ organisationId: string }> {
  organisation: Organisation;
  loaded: boolean;
  spaces: OrganisationSpace[];
  browsers: Browser[];
  mobileEndpoints: MobileEndpoint[];
  fetchBrowsers: (params: { organizationId: string }) => void;
  fetchMobileEndpoints: (params: { organizationId: string }) => void;
  fetchSpaces: (params: { organizationId: string; silent?: boolean; }) => void;
  createSpace: (space: Partial<OrganisationSpace>) => Promise<void>;
  updateSpace: (space: Partial<OrganisationSpace>) => Promise<void>;
  deleteSpace: (id: string) => Promise<void>;
  canCreate: boolean;
  canUpdate: boolean;
  canDelete: boolean;
}

const FiltersRow = styled(Row)`
  margin-bottom: 16px;
`;

const SpacesComponent = (props: SpaceComponentProps) => {
  const {
    loaded,
    spaces,
    browsers,
    mobileEndpoints,
    fetchBrowsers,
    fetchSpaces,
    fetchMobileEndpoints,
    deleteSpace,
    canCreate,
    canUpdate,
    canDelete,
    match: {
      params: { organisationId },
      url,
    },
    history,
  } = props;
  const { t } = useTranslation();

  const handleSpaceDelete = useCallback(
    async (spaceId: string) => {
      await deleteSpace(spaceId);
    },
    [deleteSpace],
  );

  const handleSpaceEdit = useCallback(
    (spaceId: string) => {
      history.push(
        `/organisations/${organisationId}/spaces/${spaceId}/details-v2/settings`,
      );
    },
    [history, organisationId],
  );

  useEffect(() => {
    if (organisationId) {
      fetchSpaces({ organizationId: organisationId, silent: true, });
      fetchBrowsers({ organizationId: organisationId });
      fetchMobileEndpoints({ organizationId: organisationId });
    }
  }, [fetchBrowsers, fetchMobileEndpoints, fetchSpaces, organisationId]);

  const { spacesList } = useMemo(() => {
    const spacesWithChildren = spaces.map((space) => {
      const spaceBrowsers = browsers.filter((b) => (b.spaces || []).includes(space.id));
      const spaceMobileEndpoints = mobileEndpoints.filter((mE) =>
        (mE.spaces || []).includes(space.id),
      );
      return {
        ...space,
        browsers: spaceBrowsers.map((b) => b.id),
        mobileEndpoints: spaceMobileEndpoints.map((mE) => mE.id),
        devicesCount: spaceBrowsers.length,
        mobileEndpointsCount: spaceMobileEndpoints.length,
      };
    });

    return {
      spacesList: spacesWithChildren,
    };
  }, [spaces, browsers, mobileEndpoints]);

  const columns = useMemo(
    () => [
      {
        title: 'ID',
        key: 'id',
        sorter: (a: { id: string }, b: { id: string }) => a.id.localeCompare(b.id),
        render: ({ id }: { id: string }) => id,
        width: 250,
      },
      {
        title: t('spaceName').toUpperCase(),
        key: 'displayName',
        sorter: (a: { displayName: string }, b: { displayName: string }) =>
          a.displayName.localeCompare(b.displayName),
        render: ({ id, displayName }: { id: string; displayName: string }) => (
          <Link to={`${url}/${id}`}>{displayName}</Link>
        ),
      },
      {
        title: t('externalId').toUpperCase(),
        key: 'externalId',
        render: ({ externalId }: { externalId: string }) => externalId,
      },
      {
        title: `${t('devices').toUpperCase()}`,
        key: 'devicesCount',
        sorter: (a: { devicesCount: number }, b: { devicesCount: number }) =>
          a.devicesCount - b.devicesCount,
        render: ({ devicesCount }: { devicesCount: number }) => <>{devicesCount}</>,
      },
      {
        title: `${t('mobileEndpoints').toUpperCase()}`,
        key: 'mobileEndpointsCount',
        sorter: (
          a: { mobileEndpointsCount: number },
          b: { mobileEndpointsCount: number },
        ) => a.mobileEndpointsCount - b.mobileEndpointsCount,
        render: ({ mobileEndpointsCount }: { mobileEndpointsCount: number }) => (
          <>{mobileEndpointsCount}</>
        ),
      },
      {
        title: t('longitude').toUpperCase(),
        key: 'longitude',
        sorter: (a: { longitude: number }, b: { longitude: number }) =>
          a.longitude - b.longitude,
        render: ({ longitude }: { longitude: string }) => longitude,
        width: 200,
      },
      {
        title: t('latitude').toUpperCase(),
        key: 'latitude',
        sorter: (a: { latitude: number }, b: { latitude: number }) =>
          a.latitude - b.latitude,
        render: ({ latitude }: { latitude: string }) => latitude,
        width: 200,
      },
      {
        title: <Icon type="setting" />,
        fixed: 'right' as Alignment,
        width: 100,
        render: ({ id }: { id: string }) => {
          return (
            <Dropdown
              placement="bottomRight"
              overlay={
                <Menu>
                  <Menu.Item key={`${id}-remove`}>
                    {canDelete ? (
                      <Popconfirm
                        title={t('areYouSureYouWantToDelete')}
                        onConfirm={() => handleSpaceDelete(id)}
                        okText={t('yes')}
                        cancelText={t('no')}
                      >
                        <RemoveButton type="link" icon="delete" size="small">
                          {t('delete')}
                        </RemoveButton>
                      </Popconfirm>
                    ) : (
                      <DisabledButton icon="delete" size="small" disabled>
                        {t('delete')}
                      </DisabledButton>
                    )}
                  </Menu.Item>
                  <Menu.Item key={`${id}-update`}>
                    {canUpdate ? (
                      <EditButton
                        type="link"
                        icon="edit"
                        size="small"
                        onClick={() => handleSpaceEdit(id)}
                      >
                        {t('edit')}
                      </EditButton>
                    ) : (
                      <DisabledButton icon="edit" size="small" disabled>
                        {t('edit')}
                      </DisabledButton>
                    )}
                  </Menu.Item>
                </Menu>
              }
            >
              <StyledIcon type="ellipsis" />
            </Dropdown>
          );
        },
      },
    ],
    [canDelete, canUpdate, handleSpaceDelete, handleSpaceEdit, t, url],
  );

  return (
    <>
      <Header title={t('spaces')} />
      <div className="content-body">
        {!loaded && (
          <Overlay>
            <Spinner />
          </Overlay>
        )}
        {loaded && !spaces.length && (
          <Overlay>
            <Title level={4}>
              {t('noSpacesFound')} <Icon type="frown" />
            </Title>
          </Overlay>
        )}
        {canCreate && (
          <FiltersRow type="flex" justify="end">
            <Col>
              <Link to={`/organisations/${organisationId}/spaces/new`}>
                <Button size="large" icon="plus">
                  {t('createSpace')}
                </Button>
              </Link>
            </Col>
          </FiltersRow>
        )}
        {loaded && !!spacesList.length && (
          <PanelCard>
            <Table rowKey="id" dataSource={spacesList} columns={columns} />
          </PanelCard>
        )}
      </div>
    </>
  );
};

export default SpacesComponent;
