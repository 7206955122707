import { thunk, Thunk } from 'easy-peasy';
import Injections from '../../injections.interface';
import { Session } from '../../types/session';
import createKeyedCrudModel, {
  KeyedCrudModel,
} from '../../common/keyed-crud-model/keyed-crud.model';
import { DataResidencyEnum } from '../../types/organisation';

interface BaseProps {
  tenantId: string;
  dataResidency: DataResidencyEnum;
}

interface BasePropsWithSessionID extends BaseProps {
  sessionId: string;
}

export interface SessionModel extends KeyedCrudModel<Session, { tenantId: string }> {
  fetchOne: Thunk<SessionModel, BasePropsWithSessionID, Injections>;
  fetchSessionsForContact: Thunk<
    SessionModel,
    BaseProps & { contactId: string },
    Injections
  >;
}

const sessionModel: SessionModel = {
  ...createKeyedCrudModel<Session, { tenantId: string }>('/api/Session', 'tenantId'),
  fetchOne: thunk(
    async (actions, { tenantId, sessionId, dataResidency }, { injections }) => {
      actions.setLoading({ key: tenantId, loading: true });
      actions.setError({ key: tenantId, error: null });
      try {
        const data = await injections.gridDataService.get<Session>(
          `/v1/${tenantId}/sessions/${sessionId}`,
          dataResidency,
        );
        actions.setSingle({ key: tenantId, data });
      } catch (error) {
        actions.setError({ key: tenantId, error });
      } finally {
        actions.setLoading({ key: tenantId, loading: false });
      }
    },
  ),
  fetchSessionsForContact: thunk(
    async (actions, { tenantId, contactId, dataResidency }, { injections }) => {
      actions.setLoading({ key: tenantId, loading: true });
      actions.setError({ key: tenantId, error: null });
      try {
        const data = await injections.gridDataService.get<Session[]>(
          `/v1/${tenantId}/sessions?contactId=${contactId}`,
          dataResidency,
        );
        actions.setData({ key: tenantId, data });
      } catch (error) {
        actions.setError({ key: tenantId, error });
      } finally {
        actions.setLoading({ key: tenantId, loading: false });
      }
    },
  ),
};

export default sessionModel;
