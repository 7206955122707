import { Action, action, Select, select, thunk, Thunk } from 'easy-peasy';
import {
  createBlobServiceWithSas,
  ExponentialRetryPolicyFilter,
  // @ts-ignore
} from 'azure-storage/browser/azure-storage.blob.export';
import Injections from '../../injections.interface';
import { ApiError } from '../../../services/api/api-error';
import GridappBuild from '../../types/gridapp-build';

export const routes = {
  fetchBuilds: (gridappId: string) =>
    ['', 'api', 'gridapps', gridappId, 'builds'].join('/'),
};

export interface OrganisationAppsLibraryBuildsModel {
  data: {
    [key: string]: {
      [id: string]: GridappBuild;
    };
  };
  loading: {
    [key: string]: boolean;
  };
  error: {
    [key: string]: ApiError | null;
  };
  values: Select<OrganisationAppsLibraryBuildsModel, (key: string) => GridappBuild[]>;
  ids: Select<OrganisationAppsLibraryBuildsModel, (key: string) => string[]>;
  loaded: Select<OrganisationAppsLibraryBuildsModel, (key: string) => boolean>;
  setLoading: Action<
    OrganisationAppsLibraryBuildsModel,
    { key: string; loading: boolean }
  >;
  setError: Action<
    OrganisationAppsLibraryBuildsModel,
    { key: string; error: ApiError | null }
  >;
  setData: Action<
    OrganisationAppsLibraryBuildsModel,
    { key: string; data: GridappBuild[] }
  >;
  setSingle: Action<
    OrganisationAppsLibraryBuildsModel,
    { key: string; data: GridappBuild }
  >;
  unsetSingle: Action<OrganisationAppsLibraryBuildsModel, { key: string; id: string }>;
  fetch: Thunk<
    OrganisationAppsLibraryBuildsModel,
    { gridappId: string; silent?: true },
    Injections
  >;
  create: Thunk<
    OrganisationAppsLibraryBuildsModel,
    { data: Partial<GridappBuild>; gridappId: string },
    Injections
  >;
  delete: Thunk<
    OrganisationAppsLibraryBuildsModel,
    { id: string; gridappId: string },
    Injections
  >;
  uploadBuild: Thunk<
    OrganisationAppsLibraryBuildsModel,
    {
      file: File;
      gridappId: string;
      onProgress?: (percent: number) => void;
    },
    Injections
  >;
  subscribe: Thunk<OrganisationAppsLibraryBuildsModel, string, Injections>;
}

const organisationAppsLibraryBuildsModel: OrganisationAppsLibraryBuildsModel = {
  data: {},
  loading: {},
  error: {},
  loaded: select((state) => (key: string) => !!state.data[key] && !state.loading[key]),
  values: select((state) => (key: string) =>
    Object.values(state.data[key] || {}).sort((a, b) =>
      b.createdAt.localeCompare(a.createdAt),
    ),
  ),
  ids: select((state) => (key: string) => Object.keys(state.data[key] || {})),
  setLoading: action((state, { key, loading }) => {
    state.loading[key] = loading;
  }),
  setError: action((state, { key, error }) => {
    state.error[key] = error;
  }),
  setData: action((state, { key, data }) => {
    state.data[key] = {};
    data.forEach((item) => {
      state.data[key][item.id] = item;
    });
  }),
  setSingle: action((state, { key, data }) => {
    if (!state.data[key]) {
      state.data[key] = {};
    }
    state.data[key][data.id] = data;
  }),
  unsetSingle: action((state, { id, key }) => {
    if (state.data[key]) {
      delete state.data[key][id];
    }
  }),
  fetch: thunk(async (actions, { silent, ...params }, { injections }) => {
    if (!silent) {
      actions.setLoading({ key: params.gridappId, loading: true });
    }
    actions.setError({ key: params.gridappId, error: null });
    try {
      const data = await injections.apiService.get<GridappBuild[]>(
        routes.fetchBuilds(params.gridappId),
      );
      actions.setData({ key: params.gridappId, data });
    } catch (error) {
      actions.setError({ key: params.gridappId, error });
    } finally {
      if (!silent) {
        actions.setLoading({ key: params.gridappId, loading: false });
      }
    }
  }),
  create: thunk(async (actions, { data, gridappId }, { injections }) => {
    const result = await injections.apiService.post<GridappBuild>(
      `/api/gridapps/${gridappId}/builds`,
      data,
    );
    actions.setSingle({ key: gridappId, data: result });
    return result.id;
  }),
  delete: thunk(async (actions, { gridappId, id }, { injections }) => {
    actions.unsetSingle({ key: gridappId, id });
    await injections.apiService.delete<void>(`/api/gridapps/${gridappId}/builds/${id}`);
  }),
  uploadBuild: thunk(async (actions, { file, gridappId, onProgress }, { injections }) => {
    const { uri, sas, container, uuid } = await injections.apiService.get(
      '/api/gridapps/token',
    );

    // TODO: extract thi to a blob service
    const blobService = createBlobServiceWithSas(uri, sas).withFilter(
      new ExponentialRetryPolicyFilter(),
    );
    const checkMD5 = false;
    const blockSize = file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
    const options = {
      storeBlobContentMD5: checkMD5,
      blockSize,
      contentSettings: { contentType: 'application/x-gridapp' },
    };
    blobService.singleBlobPutThresholdInBytes = blockSize;
    const filename = `${gridappId}/${uuid}`;
    await new Promise((resolve, reject) => {
      const speedSummary = blobService.createBlockBlobFromBrowserFile(
        container,
        filename,
        file,
        options,
        (error: any) => {
          if (error) {
            reject(error);
          } else {
            resolve();
          }
        },
      );
      speedSummary.on('progress', () => {
        if (onProgress) {
          const percent = speedSummary.getCompletePercent();
          if (percent != null) {
            onProgress(percent);
          }
        }
      });
    });

    await actions.create({
      data: {
        filename: file.name,
        size: file.size,
        fileUuid: uuid,
        gridappId,
      },
      gridappId,
    });
  }),
  subscribe: thunk(async (actions, gridappId, { injections }) => {
    const onGridappUpdate = (data: GridappBuild) => {
      actions.setSingle({ data, key: data.gridapp });
    };

    const unsubscribe = await injections.socketService.subscribeToEvent<GridappBuild>(
      `gridapp_builds_${gridappId}`,
      onGridappUpdate,
    );
    return () => {
      unsubscribe();
    };
  }),
};

export default organisationAppsLibraryBuildsModel;
