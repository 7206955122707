import React from 'react';
import { Field } from 'react-final-form';
import styled from '@emotion/styled';
import { Select as AntdSelect } from 'antd';
import { SelectProps as AntdSelectProps, SelectValue } from 'antd/lib/select';
import InputWrap from './input-wrap';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, SelectType } from '../../../types';
import { requiredAll } from '../../organisations/organisation-details/queue-details/queue-setup/validate-form';
import { Validator, composeValidators } from './validator';

export interface Option {
  value: string | number;
  label?: string;
  icon?: React.ReactNode;
}

export interface SelectProps<T = string> extends AntdSelectProps<T> {
  name?: string;
  label?: string;
  hint?: string;
  isRequired?: boolean;
  isSearchEnabled?: boolean;
  options?: Option[];
  validators?: Validator[];
  dataTestId?: string;
}

const Select = <T extends SelectValue>(props: SelectProps<T>) => {
  const {
    name = '',
    label,
    hint,
    isRequired = false,
    options = [],
    className,
    onChange = () => {},
    disabled: isDisabled,
    isSearchEnabled = true,
    defaultValue,
    validators = [],
    dataTestId,
    ...restProps
  } = props;

  const { t } = useTranslation();

  const inputValidators = [...(isRequired ? [requiredAll] : []), ...validators];

  const showSearch = isSearchEnabled && !isDisabled;

  return (
    <Field name={name} validate={composeValidators(inputValidators)}>
      {({ input, meta }) => {
        const isError = !!meta.error && meta.touched;

        return (
          <InputWrap
            className={className}
            label={label}
            hint={hint}
            isRequired={isRequired}
            error={isError ? meta.error : undefined}
          >
            <SelectStyled
              {...restProps}
              className={className}
              data-name={input.name}
              showSearch={showSearch}
              showArrow={!isDisabled}
              placeholder={t('finalFormField.select')}
              value={
                typeof input.value !== 'undefined' && input.value !== ''
                  ? input.value
                  : defaultValue
              }
              onChange={(selectedValue, option) => {
                input.onChange(({
                  target: { value: selectedValue as T },
                } as unknown) as ChangeEvent<T>);

                onChange(selectedValue as T, option);
              }}
              disabled={isDisabled}
              data-testid={dataTestId}
            >
              {options.map((option) => {
                const { value, label, icon } = option;

                return (
                  <AntdSelect.Option key={value} value={value} data-label={label}>
                    {icon && <IconWrap>{icon}</IconWrap>}
                    {label || value}
                  </AntdSelect.Option>
                );
              })}
            </SelectStyled>
          </InputWrap>
        );
      }}
    </Field>
  );
};

const SelectStyled = styled(AntdSelect)`
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;

  .ant-select-selection {
    min-height: 32px;
  }
  .ant-select-selection-selected-value {
    min-height: 32px;

    div:first-of-type {
      margin: 0px;
    }
  }
` as SelectType<any>;

const IconWrap = styled.span`
  margin-right: 5px;
`;

export default Select;
