/* stylelint-disable property-no-vendor-prefix */
/* stylelint-disable value-no-vendor-prefix */
import React, { useCallback, useEffect, useState } from 'react';
import { Tabs, message } from 'antd';
import { Global } from '@emotion/core';
import { RouteComponentProps } from 'react-router-dom';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import Spinner from '../../../common/spinner/spinner.component';
import OrganisationApp from '../../../../store/types/organisation-app';
import Organisation from '../../../../store/types/organisation';
// TODO: add error view - import ErrorView from '../../../common/error-view/error-view.component';
import Overlay from '../../../common/overlay/overlay.component';
import User from '../../../../store/types/user';
import InstallationGroup from '../../../../store/types/installation-group';
import { UniversalDeviceCreateParams } from '../../../../store/models/app-devices-universal/app-devices-universal.model';
import useQueryStringParam from '../../../common/use-query-string-param/use-query-string-param';
import AppsLibraryList from '../../../common/apps-library/apps-library-solutions/apps-library-solutions-list.component';
import publicApps from './public-apps';
import { AppInstallBody } from '../../../../store/models/organisation-apps/organisation-apps.model';
import DeviceTypeEnum from '../../../../store/types/device-type.enum';
import getInstallationAppIcon from '../../../../utils/installations/get-installation-app-icon-url';
import OnboardingPanelLayout from '../../../onboarding/onboarding-panel-layout/onboarding-panel-layout.component';
import useGroupAppsByInstallationGroups from '../../../../utils/group-apps-by-installation-group';

interface OrganisationDeviceSetupComponentProps
  extends RouteComponentProps<{ organisationId: string }> {
  organisation: Organisation | null;
  fetchApps: ({
    organizationId,
    silent,
  }: {
    organizationId: string;
    silent?: boolean;
  }) => void;
  appsAndGroupsLoaded: boolean;
  apps: OrganisationApp[];
  installationGroups: InstallationGroup[];
  user: User | null;
  installApp: (data: AppInstallBody) => Promise<OrganisationApp>;
  createDevice: (params: UniversalDeviceCreateParams) => void;
}

const { TabPane } = Tabs;

const OrganisationDeviceSetupComponent = (
  props: OrganisationDeviceSetupComponentProps,
) => {
  const [hasAppInit, setHasAppInit] = useState(false);
  const [apps, setApps] = useState<OrganisationApp[]>([]);
  const [loadingMessage, setLoadingMessage] = useState('');
  const deviceSerial = useQueryStringParam('deviceSerial');

  const { t } = useTranslation();
  const {
    history,
    apps: latestApps,
    createDevice,
    installApp,
    installationGroups,
    fetchApps,
    appsAndGroupsLoaded,
    match: {
      params: { organisationId },
    },
  } = props;

  useEffect(() => {
    if (!hasAppInit && appsAndGroupsLoaded) {
      setApps([...latestApps.filter((app) => app.deviceType === DeviceTypeEnum.WPA)]);
      setHasAppInit(true);
    }
  }, [hasAppInit, latestApps, appsAndGroupsLoaded]);

  useEffect(() => {
    fetchApps({ organizationId: organisationId, silent: false });
  }, [fetchApps, organisationId]);

  const groupedApps = useGroupAppsByInstallationGroups({ apps, installationGroups });

  const handleExistingInstallationSelect = useCallback(
    async (app: OrganisationApp) => {
      const displayName = `device-${deviceSerial}`;
      setLoadingMessage(`Adding your device`);

      try {
        await createDevice({
          appId: app.id,
          appName: app.appName,
          displayName,
          env: 'prod',
          type: 'iotedge',
          deviceSerial: deviceSerial || '',
        });
        message.success(`${t('successfullyConnectedDevice')} ${displayName}`);
        history.replace(
          `/organisations/${organisationId}/apps/${app.id}/devices-universal`,
        );
      } catch (e) {
        message.error(t('failedToConnectDevice'));
      }

      setLoadingMessage('');
    },
    [createDevice, deviceSerial, history, organisationId, t],
  );

  const handleNewGridappInstall = useCallback(
    async (gridapp: any) => {
      const deviceName = `device-${deviceSerial}`;
      setLoadingMessage(`Adding your device`);

      try {
        const app = await installApp({
          packageName: gridapp.id,
          displayName: gridapp.displayName,
          organizationId: organisationId,
          installationGroupId: '',
        });

        await createDevice({
          appId: app.id,
          appName: app.appName,
          displayName: deviceName,
          env: 'prod',
          type: 'iotedge',
          deviceSerial: deviceSerial || '',
        });
        message.success(`${t('successfullyConnectedDevice')} ${deviceName}`);
        history.replace(
          `/organisations/${organisationId}/apps/${app.id}/devices-universal`,
        );
      } catch (e) {
        message.error(t('failedToConnectDevice'));
      }

      setLoadingMessage('');
    },
    [createDevice, deviceSerial, history, installApp, organisationId, t],
  );

  if (!appsAndGroupsLoaded || !hasAppInit) {
    return (
      <Overlay>
        <Spinner />
      </Overlay>
    );
  }

  return (
    <>
      <Global
        styles={{
          body: {
            height: 'auto',
            overflow: 'auto',
            background: '#ffffff',
          },
          html: {
            height: 'auto',
          },
        }}
      />
      <OnboardingPanelLayout>
        <PageTitle>Your new device is ready to go &#128640;</PageTitle>
        <PageSubTitle>What would you like to run on it?</PageSubTitle>
        <CustomStyledTabs
          animated={false}
          defaultActiveKey={apps.length ? 'existing_installation' : 'new_installation'}
          isScrollable
          tabPosition="top"
        >
          {apps.length && (
            <TabPane tab="Existing installation" key="existing_installation">
              <TempContent>
                {groupedApps.map((appsGroup: any) => (
                  <InstallationGroupList key={appsGroup.groupid}>
                    <InstallationGroupTitle>{appsGroup.groupName}</InstallationGroupTitle>
                    {appsGroup.apps.map((app: OrganisationApp) => (
                      <InstallationGroupItem
                        onClick={() => handleExistingInstallationSelect(app)}
                      >
                        <img alt="app" src={getInstallationAppIcon(app)} />
                        <LabelInstallation>
                          <span>{app.displayName}</span>
                        </LabelInstallation>
                      </InstallationGroupItem>
                    ))}
                  </InstallationGroupList>
                ))}
              </TempContent>
            </TabPane>
          )}
          <TabPane tab="New Installation" key="new_installation">
            <TempContent>
              <DeviceSetupAppsLibrary
                hoverable={false}
                onItemSelect={() => null}
                onInstall={handleNewGridappInstall}
                gridapps={publicApps}
              />
            </TempContent>
          </TabPane>
        </CustomStyledTabs>
        {loadingMessage && (
          <MessageOverlay>
            <Spinner />
            <p>{loadingMessage}</p>
          </MessageOverlay>
        )}
      </OnboardingPanelLayout>
    </>
  );
};

const CustomStyledTabs = styled(Tabs)<{ isScrollable: boolean }>`
  ${({ isScrollable }) => !!isScrollable && 'overflow-y: scroll;'}
`;

const InstallationGroupTitle = styled.li`
  font-weight: 700;
  padding-bottom: 12px;
  padding-top: 12px;
`;

const InstallationGroupItem = styled(InstallationGroupTitle)`
  display: flex;
  font-weight: normal;
  flex-direction: row;
  align-items: center;
  padding: 16px 0;
  width: 100%;
  cursor: pointer;
  min-height: 48px;
  transition: all 0.1s;
  border-bottom: solid 1px rgb(235, 237, 244);
  &:last-child {
    border-bottom: 0;
  }
  > img {
    width: 28px;
    height: 28px;
    margin-right: 8px;
  }

  &:hover,
  &:active,
  &:focus {
    opacity: 0.8;
  }
`;

const LabelInstallation = styled.div`
  width: 170px !important;
  text-overflow: ellipsis;
  display: flex;
  flex: 1;
`;

const InstallationGroupList = styled.ul`
  display: flex;
  flex-direction: column;
  font-size: 16px;
`;

const TempContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .ant-tabs-nav-container {
    height: 100% !important;
  }
`;

const MessageOverlay = styled(Overlay)`
  flex-direction: column;
  background: rgba(0, 0, 0, 0.9);
  z-index: 999;

  p {
    margin-top: 12px;
    color: #fff;
  }
`;
const PageTitle = styled.h1`
  font-size: 32px;
  font-weight: 700;
  color: #1d1921;
  margin-top: 32px;
`;

const PageSubTitle = styled.h2`
  font-size: 20px;
  font-weight: 400;
  color: #4f4c52;
  margin-bottom: 24px;
`;

const DeviceSetupAppsLibrary = styled(AppsLibraryList)`
  display: block;

  @media screen and (min-width: 768px) {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);
    padding: 16px;
  }
`;

export default OrganisationDeviceSetupComponent;
