import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import { PaginationConfig } from 'antd/lib/pagination';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import qs from 'query-string';
import { loadingIcon } from '../../../../../common/spinner/spinner.component';
import { ApiError } from '../../../../../../services/api/api-error';
import TransactionsPaginatedResult, {
  TransactionsListGroupItem,
} from '../../../../../../store/types/transactions';
import Organisation, {
  BillingCurrencyEnum,
  OrganizationAccountBalance,
} from '../../../../../../store/types/organisation';
import { formatAmount } from '../../../../../../utils/currency';
import RangePicker, { RangePickerProps } from '../../../../../common/range-picker/range-picker.component';
import useDateRange from '../../../../../common/use-date-range';
import useQueryParam from '../../../../../common/use-query-param';
import SearchBar from '../../../../../common/search-bar/search-bar.component';
import OrganisationSpace from '../../../../../../store/types/organisation-space';
import useFilterOptions from '../../../../../common/use-filter-options/use-filter-options';
import { ActiveFilter } from '../../../../../../store/types/filters';
import DownloadReport from '../../../../../common/overview-report/download-report.component';
import moment from 'moment';
import OrganisationSpaceTypeEnum from '../../../../../../store/types/organisation-space-type.enum';

const { Column } = Table;

const formatBalance = (currency: BillingCurrencyEnum, value: number | null) =>
  value != null ? formatAmount(currency, value) : '';

const formatQuantity = (value: number) => {
    if (value > 0) {
      return Number.isInteger(value) ? value.toString() : value.toFixed(2);
    } else {
      return '';
    }
}

const formatTransactionAmount = (
  currency: BillingCurrencyEnum,
  amount: number,
): string => {
  return formatAmount(currency, amount, undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 5,
    signDisplay: 'exceptZero',
  } as any);
};

interface TransactionsProps {
  error: ApiError | null;
  loading: boolean;
  fetchTransactions: (params: {
    organisationId: string;
    page: number;
    limit: number;
    startDate: string;
    endDate: string;
    searchTerm?: string;
    spaceId?: string;
  }) => void;
  paginatedTransactions: TransactionsPaginatedResult;
  organisationId: string;
  accountBalance: OrganizationAccountBalance | null;
  spaces: OrganisationSpace[];
  tenant: Organisation;
}

const Transactions = (props: TransactionsProps) => {
  const {
    loading,
    fetchTransactions,
    paginatedTransactions,
    organisationId,
    accountBalance,
    spaces,
    tenant,
  } = props;
  const { t } = useTranslation();

  const { dateFrom, dateTo, onChangeRange } = useDateRange(364, 0);

  const [ searchTerm, updateSearchTermQueryParam ] = useQueryParam('searchTerm');

  const [ spaceId, updateSpaceIdQueryParam ] = useQueryParam('spaceId');

  const { filterOptions } = useFilterOptions({ spaces: spaces.filter((space) => space.type === OrganisationSpaceTypeEnum.LOCATION) });

  const spaceFilterOption = filterOptions.find((filterOption) => filterOption.label === t('filterDevice.space'));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const defaultActiveFilters =
    spaceFilterOption &&
    spaceFilterOption.options.filter((spaceFilterOption) => spaceFilterOption.id === spaceId)
    .map<ActiveFilter>((matchedFilterOption) => ({
      typeId: spaceFilterOption.id,
      typeLabel: spaceFilterOption.label,
      valueId: matchedFilterOption.id,
      valueLabel: matchedFilterOption.label
    }));

  const paginationParams = useMemo(() => {
    return {
      startDate: dateFrom,
      endDate: dateTo,
      ...(searchTerm && searchTerm.trim().length && { searchTerm }),
      ...(spaceId && { spaceId }),
      organisationId,
    };
  }, [organisationId, dateFrom, dateTo, searchTerm, spaceId]);

  const ranges = useMemo<RangePickerProps['ranges']>(() => {
    return {
      'Yesterday': [moment().subtract(1, 'd'), moment().subtract(1, 'd')],
      'Past 7 days': [moment().subtract(8, 'd'), moment().subtract('1', 'd')],
      'This month': [moment().startOf('month'), moment()],
      'Past month': [moment().subtract(1, 'M').startOf('month'), moment().subtract(1, 'M').endOf('month')]
    }
  }, []);

  useEffect(() => {
    fetchTransactions({
      ...paginationParams,
      page: 1,
      limit: 50,
    });
  }, [fetchTransactions, paginationParams]);

  const renderAmountColumnCell = useCallback(
    (value: number | null, item: TransactionsListGroupItem) => {
      return value != null ? (
        <TransactionAmountWrapper isPositive={value > 0}>
          {formatTransactionAmount(item.currency, value as number)}
        </TransactionAmountWrapper>
      ) : (
        ''
      );
    },
    [],
  );

  const handleTableChange = useCallback(
    (paginationConfig: PaginationConfig) => {
      const { current, pageSize } = paginationConfig;
      if (current && pageSize) {
        fetchTransactions({
          ...paginationParams,
          page: current,
          limit: pageSize,
        });
      }
    },
    [fetchTransactions, paginationParams],
  );

  const getPaginationConfig = useCallback(() => {
    return paginatedTransactions
      ? {
          total: paginatedTransactions.totalItems,
          current: paginatedTransactions.page,
          pageSize: paginatedTransactions.limit,
        }
      : {};
  }, [paginatedTransactions]);

  const dataSource = paginatedTransactions ? paginatedTransactions.items : [];

  const onSearch = useCallback((searchTerm) => {
    updateSearchTermQueryParam(searchTerm);
  }, [updateSearchTermQueryParam]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSpaceSelected = useCallback((activeFilters: ActiveFilter[]) => {
    updateSpaceIdQueryParam(activeFilters && activeFilters[0] ? activeFilters[0].valueId! : '');
  }, [updateSpaceIdQueryParam]);

  const downloadPath = useMemo(() => {
    return `/api/organizations/${organisationId}/transactions-report?${qs.stringify(
      {
        startDate: dateFrom,
        endDate: dateTo,
        ...(searchTerm && { searchTerm }),
        ...(spaceId && { spaceId }),
      },
    )}`
  }, [organisationId, dateFrom, dateTo, searchTerm, spaceId]);

  if (!accountBalance) {
    return null;
  }

  const { isSponsorshipAccountActive } = accountBalance;

  return (
    <>
      <FiltersContainer>
        <SearchBarWrapper>
          <SearchBar searchInputProps={{
            placeholder: t('billing.searchTransactions'),
            defaultValue: searchTerm && searchTerm.trim().length ? searchTerm.trim() : '',
            onSearch
          }}
          searchInputFilterProps={{
            filterOptions: filterOptions.filter((filterOption) => filterOption.label === t('filterDevice.space')),
            onChange: onSpaceSelected,
            defaultActiveFilters: defaultActiveFilters || [],
            showAddFilter: false
          }}
           />
        </SearchBarWrapper>
        <RangePickerContainer>
          <RangePicker dateFrom={dateFrom} dateTo={dateTo} maxDaysInRange={364} ranges={ranges} onChangeRange={onChangeRange} />
        </RangePickerContainer>
        <DownloadReportContainer>
          <DownloadReport tenantId={tenant.id} dataResidency={tenant.dataResidency} downloadPath={downloadPath} />
        </DownloadReportContainer>
      </FiltersContainer>
      <Table
        rowKey="id"
        scroll={{ x: isSponsorshipAccountActive ? 1500 : 1100 }}
        pagination={getPaginationConfig()}
        onChange={handleTableChange}
        dataSource={dataSource}
        loading={{
          indicator: loadingIcon,
          spinning: loading,
        }}
      >
        <Column
          fixed="left"
          width={200}
          title={t('billing.balanceTransactionDate')}
          dataIndex="transactionDate"
          key="transactionDate"
          render={(value: string) => dayjs(value).format('YYYY-MM-DD')}
        />
        <Column
          ellipsis
          title={t('billing.balanceTransactionDescription')}
          key="description"
          dataIndex="description"
        />
        <Column
          ellipsis
          width={150}
          title={t('billing.balanceTransactionSku')}
          dataIndex="sku"
          key="sku"
        />
        <Column
          width={100}
          title={t('billing.balanceTransactionQuantity')}
          dataIndex="quantity"
          key="quantity"
          render={formatQuantity}
        />
        {isSponsorshipAccountActive && (
          <Column
            width={200}
            title={t('billing.balanceTransactionSponsorshipAmount')}
            dataIndex="sponsorshipValue"
            key="sponsorshipValue"
            render={renderAmountColumnCell}
          />
        )}
        {isSponsorshipAccountActive && (
          <Column
            width={200}
            title={t('billing.balanceTransactionSponsorshipBalance')}
            dataIndex="sponsorshipEndingBalance"
            key="sponsorshipEndingBalance"
            render={(value: number | null, item: TransactionsListGroupItem) =>
                formatBalance(item.currency, value)
            }
          />
        )}
        <Column
          width={200}
          title={t('billing.balanceTransactionAmount')}
          dataIndex="monetaryValue"
          key="monetaryValue"
          render={renderAmountColumnCell}
        />
        <Column
          width={200}
          title={t('billing.balanceTransactionAccountBalance')}
          dataIndex="monetaryEndingBalance"
          key="monetaryEndingBalance"
          render={(value: number | null, item: TransactionsListGroupItem) =>
            formatBalance(item.currency, value)
          }
        />
      </Table>
    </>
  );
};

const TransactionAmountWrapper = styled.span<{ isPositive: boolean }>`
  border-radius: 4px;
  background-color: ${({ isPositive }) =>
    isPositive ? 'rgb(228, 239, 231)' : 'transparent'};
  padding: 4px;
`;

const RangePickerContainer = styled.div`
  align-self: center;
`;

const FiltersContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 20px;
`;

const SearchBarWrapper = styled.div`
  flex-grow: 1;
`;

const DownloadReportContainer = styled.div`
  align-self: center;
`;

export default Transactions;
