import React, { useMemo } from 'react';
import {
  getProductPrice,
  GridProduct,
  IsoLanguageIds,
  PriceListTypeEnum,
  Variant,
} from '@ombori/grid-products';
import styled from '@emotion/styled';
import { Button } from 'antd';
import { getProductVariantPrice, getSizePriceStyleText } from './utils';
import { getBestMatchByLanguage } from '../../../../organisations/organisation-details/grid-products/utils';
import { defaultLng } from '../../../../organisations/organisation-details/grid-products/grid-products.components';

interface ProductInfoProps {
  product: Variant;
  productGroup: GridProduct;
  defaultImage: string;
  onRemovingSelectedProduct: () => void;
  onUpdateSelectedProduct: () => void;
}

const ProductInfo = ({
  product,
  productGroup,
  defaultImage,
  onRemovingSelectedProduct,
  onUpdateSelectedProduct,
}: ProductInfoProps) => {
  const { productId } = product;

  const isoLanguageId = defaultLng as IsoLanguageIds;

  const standardPriceData = getProductVariantPrice({
    productId,
    productGroup,
    type: PriceListTypeEnum.Standard,
  });

  const standardPrice = useMemo(
    () =>
      standardPriceData ? getProductPrice(standardPriceData, isoLanguageId) : undefined,
    [isoLanguageId, standardPriceData],
  );

  const promoPriceData = getProductVariantPrice({
    productId,
    productGroup,
    type: PriceListTypeEnum.Promotional,
  });

  const promoPrice = useMemo(
    () => (promoPriceData ? getProductPrice(promoPriceData, isoLanguageId) : undefined),
    [isoLanguageId, promoPriceData],
  );

  const { productName } = getBestMatchByLanguage(productGroup.productName);
  const categories = getSizePriceStyleText(product);

  return (
    <Container>
      <LeftPartContainer onClick={onUpdateSelectedProduct}>
        <Picture src={defaultImage} />
        <InfoContainer>
          <ProductName>{productName}</ProductName>
          <ProductCategories>{categories}</ProductCategories>
        </InfoContainer>
      </LeftPartContainer>
      <PriceContainer>
        {promoPrice && (
          <div>
            {standardPrice && <SlashedPrice>{standardPrice.label}</SlashedPrice>}
            <ActivePrice>{promoPrice.label}</ActivePrice>
          </div>
        )}
        {!promoPrice && standardPrice && <ActivePrice>{standardPrice.label}</ActivePrice>}
        <DeleteButton icon="delete" onClick={onRemovingSelectedProduct} />
      </PriceContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const LeftPartContainer = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const Picture = styled.img`
  width: 90px;
  height: 100px;
  margin-right: 16px;
  object-fit: cover;
` as any;

const InfoContainer = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
`;

const ProductName = styled.div`
  font-weight: 700;
`;

const ProductCategories = styled.div``;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ActivePrice = styled.div`
  font-size: 14px;
  text-align: right;
`;

const SlashedPrice = styled.div`
  font-size: 12px;
  text-decoration: line-through;
  text-align: right;
`;

const DeleteButton = styled(Button)`
  margin-top: 10px;
  margin-left: 56px;
` as any;

export default ProductInfo;
