import { useQuery, useMutation, useQueryClient } from "react-query";
import { useApiService } from "../api-service-provider";
import { ApiError } from "../../../services/api/api-error";
import * as Sentry from '@sentry/browser';
import { PaginationCollection } from "../../../store/types/pagination";

export interface NetworkWhitelistRule {
  id: string;
  env: string;
  hostname: string;
  createdAt: string;
  updatedAt: string;
  tenantId: string;
};

export type NetworkWhitelistRuleCreatePayload = Pick<NetworkWhitelistRule, 'env' | 'hostname' | 'tenantId'>;
export type NetworkWhitelistRuleUpdatePayload = NetworkWhitelistRuleCreatePayload & Pick<NetworkWhitelistRule, 'id'>;

export type NetworkWhitelistRulesPaginated = PaginationCollection<NetworkWhitelistRule>;

let networkWhitelistRulesQueryKeys: Record<string, (string | number)[]> = {};

const getNetworkWhitelistRulesQueryKeys = (tenantId: string, page: number, limit: number, sortField: string, sortOrder: string) => {
  return [
    'network-whitelist-rules',
    'tenantId',
    tenantId,
    'page',
    page,
    'limit',
    limit,
    'sortField',
    sortField,
    'sortOrder',
    sortOrder,
  ];
}

export const useGetNetworkWhitelistRules = (tenantId: string, page = 1, limit = 50, sortField = 'id', sortOrder = 'asc') => {
  const apiService = useApiService();

  const queryKeys = getNetworkWhitelistRulesQueryKeys(tenantId, page, limit, sortField, sortOrder);

  // storing them for later use to save, update, invalidate cached data
  networkWhitelistRulesQueryKeys[tenantId] = queryKeys;

  return useQuery(
    queryKeys,
    () => {
      return apiService.get<NetworkWhitelistRulesPaginated>(`/api/network-whitelist-rules/organization/${tenantId}`, {
        page,
        limit,
        sortField,
        sortOrder,
      });
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    }
  )
}

export const useAddNetworkWhitelistRule = () => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  return useMutation(async (payload: NetworkWhitelistRuleCreatePayload) => {
    return apiService.post<NetworkWhitelistRule>(`/api/network-whitelist-rules`, payload);
  }, {
    onSuccess: (newNetworkWhitelistRule: NetworkWhitelistRule) => {
      const { tenantId } = newNetworkWhitelistRule;
      queryClient.setQueryData<NetworkWhitelistRulesPaginated | undefined>(networkWhitelistRulesQueryKeys[tenantId], (currentData: NetworkWhitelistRulesPaginated | undefined) => {
        if (currentData) {
          return {
            ...currentData,
            totalDocs: currentData.totalDocs + 1,
            docs: [newNetworkWhitelistRule, ...currentData.docs],
          }
        }
        return currentData;
      });
    },
    onError: (error: ApiError) => {
      Sentry.captureException(error)
    }
  });
}

export const useUpdateNetworkWhitelistRule = () => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  return useMutation(async (payload: NetworkWhitelistRuleUpdatePayload) => {
    const { id, ...networkWhitelistRule } = payload;
    return apiService.put<NetworkWhitelistRule>(`/api/network-whitelist-rules/${id}`, networkWhitelistRule);
  }, {
    onSuccess: (updatedNetworkWhitelistRule: NetworkWhitelistRule) => {
      const { tenantId } = updatedNetworkWhitelistRule;
      queryClient.setQueryData<NetworkWhitelistRulesPaginated | undefined>(networkWhitelistRulesQueryKeys[tenantId], (currentData: NetworkWhitelistRulesPaginated | undefined) => {
        if (currentData) {
          const updatedNetworkWhitelistRules = currentData.docs.map(
            (item) => item.id === updatedNetworkWhitelistRule.id ? updatedNetworkWhitelistRule : item
          );
          return {
            ...currentData,
            docs: updatedNetworkWhitelistRules,
          }
        }
        return currentData;
      });
    },
    onError: (error: ApiError) => {
      Sentry.captureException(error);
    } 
  })
}

export const useDeleteNetworkWhitelistRule = () => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  return useMutation(async (networkWhitelistRuleId: string) => {
    return apiService.delete(`/api/network-whitelist-rules/${networkWhitelistRuleId}`);
  }, { 
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['network-whitelist-rules'], // only invalidate network-whitelist-rules queries
      });
    },
    onError: (error: ApiError) => {
      Sentry.captureException(error);
    }
  })
}
