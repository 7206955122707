import { useCallback, useMemo } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { useApiService } from '../api-service-provider';

export const buildQueryKey = (deviceUuid: string) => ['outdated-device-components', deviceUuid];

export enum ComponentEnum {
  APP = 'app',
  GRIDOS = 'gridos',
  BOOT_SCREEN = 'boot-screen',
  MODULE = 'module',
  DEVICE_BUNDLE = 'device-bundle',
};

export interface ComponentVersion {
  uuid: string;
  appId?: string;
  component: ComponentEnum;
  key: string;
  latestVersion: string;
  currentVersion: string;
  displayName: string;
  packageName?: string;
  iconUrl?: string;
  isAutoUpdate: boolean;
  appEnableLayoutV2?: boolean;
  bundleComponents?: ComponentVersion[];
}

const useDeviceComponents = (deviceUuid: string): UseQueryResult<ComponentVersion[], Error> => {
  const apiService = useApiService();

  const fetch = useCallback(async (uuid: string) => {
    const url = `/api/v4/devices/${uuid}/component-versions`;
    return apiService.get<ComponentVersion[]>(url);
  }, [apiService]);

  const query = useMemo(() => {
    return {
      queryKey: buildQueryKey(deviceUuid),
      queryFn: () => fetch(deviceUuid),
      retry: false,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      keepPreviousData: true,
    };
  }, [fetch, deviceUuid]);

  const result = useQuery<ComponentVersion[], Error>(query);

  return result;
};

export default useDeviceComponents;
