import { setIn } from "final-form";
import { ApiError, ApiValidationErrorData } from "../../../services/api/api-error";

export const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const scrollIntoView = (fieldName: string) => {
  const element =
    document.querySelector(`[data-name*="${fieldName}"]`) ||
    document.querySelector(`[name*="${fieldName}"]`) ||
    document.querySelector('.error-detail');

  if (!element) {
    return;
  }

  element.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  });
};

export const getFieldNamesWithError = (object: Record<string, any>, parentPath = '') => {
  let fieldNames: string[] = [];

  for (let key in object) {
    if (Array.isArray(object[key])) {
      for (let i = 0; i < object[key].length; i++) {
        fieldNames = [...fieldNames, ...getFieldNamesWithError(object[key][i], `${parentPath}${key}[${i}].`)];
      }

      if (object[key]['FINAL_FORM/array-error']) {
        fieldNames = [...fieldNames, key];
      }
    } else if (typeof object[key] === 'object' && object[key] !== null) {
      fieldNames = [...fieldNames, ...getFieldNamesWithError(object[key], `${parentPath}${key}.`)];
    } else {
      fieldNames.push(`${parentPath}${key}`);
    }
}

  return fieldNames;
};

export const convertMetaError = (errors: unknown): string | null => {
  if (typeof errors === 'string') {
    return errors;
  }

  if (!Array.isArray(errors)) {
    return null;
  }

  const errorObject = errors.find(
    (error) => typeof error === 'object' && '_error' in error,
  ) as { _error: string };

  return errorObject ? errorObject._error : null;
}

export const isNumber = (num: number | string): boolean => {
  return isNaN(Number(num)) ? false : true;
};

/**
* extracts api validation errors into an object which could be passed on react-final-form to render validations errors
* on relevant fields
* @example
* converts following:
* errors: [
* {
*    field: ['name'],
*    messages: ['Name is required'],
*    types: ['required']
*  },
*  {
*    field: ['item', '0', 'type'],
*    messages: ['Invalid item type'],
*    types: ['invalidType']
*  }
* ]
*
* to:
* {
*   'name': 'Name is required',
*   'items': [
*     {
*        'type': 'Invalid item type'
*     }
*   ]
* }
*/
//
export const extractApiValidationErrors = <T = {}>(error: ApiError): T => {
  const err = error as ApiError;
  const data = (err.response ? err.response.data : {}) as ApiValidationErrorData;
  const errors = data.errors || [];

  const validationErrors = formatApiValidationErrors(errors);
  return validationErrors as unknown as T;
}

export const formatApiValidationErrors = (errors: ApiValidationErrorData['errors']) => {
  let validationErrors = {};

  errors.forEach((error) => {
    const fieldName = error.field.join('.');
    validationErrors = setIn(validationErrors, fieldName, error.messages[0]);
  });

  return validationErrors;
}
