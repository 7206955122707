function convertHourTo12Hour(hour: number): string {
    const period = hour >= 12 ? 'PM' : 'AM';
    const convertedHour = hour % 12 || 12;
    return `${convertedHour} ${period}`;
  }

  export function convertDayToWeekday(day: number = 0): string {
    const weekdays = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ];
  
    if (day < 0 || day > 6) {
      throw new Error('Invalid day number. Must be between 0 and 6.');
    }
  
    return weekdays[day];
  }

  export function convertDayToOrdinal(day: number = 0): string {
    if (day < 1 || day > 31) {
      throw new Error('Invalid day number. Must be between 1 and 31.');
    }
  
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const lastDigit = day % 10;
    const suffix = (day % 100 >= 11 && day % 100 <= 13) ? suffixes[0] : suffixes[lastDigit] || suffixes[0];
  
    return `${day}${suffix}`;
  }

  export function formatFileSize(sizeInBytes: number): string {
    if (sizeInBytes < 1024) {
      return `${sizeInBytes} bytes`;
    } else if (sizeInBytes < 1024 * 1024) {
      const sizeInKB = sizeInBytes / 1024;
      return `${sizeInKB.toFixed(2)} KB`;
    } else if (sizeInBytes < 1024 * 1024 * 1024) {
      const sizeInMB = sizeInBytes / (1024 * 1024);
      return `${sizeInMB.toFixed(2)} MB`;
    } else {
      const sizeInGB = sizeInBytes / (1024 * 1024 * 1024);
      return `${sizeInGB.toFixed(2)} GB`;
    }
  }

  export default convertHourTo12Hour;