import React from 'react';
import styled from '@emotion/styled';
import { Dropdown } from 'antd';

const DropdownMenu = styled(Dropdown)`
  display: inline-flex;
` as any;

const BallContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 34px;
  height: 24px;
  padding: 0 5px;
  cursor: pointer;
`;

const Ball = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #444444;
`;

interface MeatBallsMenuProps {
  menu: React.ReactNode;
  placement?:
    | 'bottomRight'
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomCenter';
}

const MeatBallsMenu: React.FC<MeatBallsMenuProps> = ({
  menu,
  placement = 'bottomRight',
}) => {
  return (
    <DropdownMenu overlay={menu} trigger={['click']} placement={placement}>
      <BallContainer>
        <Ball />
        <Ball />
        <Ball />
      </BallContainer>
    </DropdownMenu>
  );
};

export default MeatBallsMenu;
