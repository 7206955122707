import { TFunction } from 'i18next';
import Media from '../../../../../store/types/media';
import camelCase from 'lodash/camelCase';
import uniqueId from 'lodash/uniqueId';

export const FONT_WEIGHT_OPTIONS: { [key: string]: string }[] = [
  { lighter: "<span style='font-weight: lighter'>Lighter</span>" },
  { normal: "<span style='font-weight: normal'>Normal</span>" },
  { bold: "<span style='font-weight: bold'>Bold</span>" },
  { bolder: "<span style='font-weight: bolder'>Bolder</span>" },
  { '100': '100' },
  { '200': '200' },
  { '300': '300' },
  { '400': '400' },
  { '500': '500' },
  { '600': '600' },
  { '700': '700' },
  { '800': '800' },
  { '900': '900' },
];

export const FONT_STYLE_OPTIONS: { [key: string]: string }[] = [
  { normal: "<span style='font-style: normal'>Normal</span>" },
  { italic: "<span style='font-style: italic'>Italic</span>" },
];

export const TEXT_TRANSFORM_OPTIONS: { [key: string]: string }[] = [
  { capitalize: "<span style='text-transform: capitalize'>Capitalize text</span>" },
  { lowercase: "<span style='text-transform: lowercase'>Lowercase</span>" },
  { uppercase: "<span style='text-transform: uppercase'>Uppercase</span>" },
  { none: 'None' },
];

export const TEXT_DECORATION_OPTIONS: { [key: string]: string }[] = [
  { overline: "<span style='text-decoration: overline'>Overline</span>" },
  { 'line-through': "<span style='text-decoration: line-through'>Line through</span>" },
  { underline: "<span style='text-decoration: underline'>Underline</span>" },
  {
    'underline overline':
      "<span style='text-decoration: underline overline'>Underline overline</span>",
  },
  {
    'underline dotted':
      "<span style='text-decoration: underline dotted'>Underline dotted</span>",
  },
  {
    'underline wavy':
      "<span style='text-decoration: underline wavy'>Underline wavy</span>",
  },
  { none: 'None' },
];

export enum TextAsElementEnum {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  PARAGRAPH = 'p',
  SPAN = 'span',
}

export const TEXT_AS_ELEMENT_OPTIONS: { [key: string]: string }[] = [
  { [TextAsElementEnum.H1]: 'Heading 1' },
  { [TextAsElementEnum.H2]: 'Heading 2' },
  { [TextAsElementEnum.H3]: 'Heading 3' },
  { [TextAsElementEnum.H4]: 'Heading 4' },
  { [TextAsElementEnum.H5]: 'Heading 5' },
  { [TextAsElementEnum.H6]: 'Heading 6' },
  { [TextAsElementEnum.PARAGRAPH]: 'Paragraph' },
  { [TextAsElementEnum.SPAN]: 'Span' },
];

export interface TextNonCssProperty {
  sampleText?: string;
  asElement?: TextAsElementEnum;
  className?: string;
}

export interface TextCssProperty {
  fontFamily?: string;
  fontWeight?: string;
  fontStyle?: string;
  textTransform?: string;
  letterSpacing?: string;
  textDecoration?: string;
  textDecorationThickness?: string;
}

export interface DefaulValue extends TextNonCssProperty, TextCssProperty {
  font?: Media;
}

export interface TypographyProperty {
  font?: Media;
  textCssProperty: TextCssProperty;
  textNonCssProperty: TextNonCssProperty;
}

export const textCssPropertyMap: Record<keyof TextCssProperty, string> = {
  fontFamily: 'font-family',
  fontWeight: 'font-weight',
  fontStyle: 'font-style',
  textTransform: 'text-transform',
  letterSpacing: 'letter-spacing',
  textDecoration: 'text-decoration',
  textDecorationThickness: 'text-decoration-thickness',
};

export const buildTypographyCss = (property: TypographyProperty, customFontFamily?: string): { typographyCss: string; typographyPreviewCss: string } => {
  const { textCssProperty, textNonCssProperty } = property;
  const { className } = textNonCssProperty;

  const previewCss = Object.keys(textCssProperty).reduce((acc, propKey) => {
    const key = propKey as keyof TextCssProperty;
    const value = textCssProperty[key];

    if (!(key in textCssPropertyMap)) {
      return acc;
    }

    const customFontCss = customFontFamily && key === 'fontFamily' ? `font-family: '${customFontFamily}';` : undefined;

    if (customFontCss) {
      return `${acc}${customFontCss}\n`;
    }

    const css = value ? `${textCssPropertyMap[key as keyof TextCssProperty]}: ${value};` : '';

    return `${acc}${css}\n`;
  }, '');

  return {
    typographyCss: `
      .${className} {
        ${previewCss}
      }
    `,
    typographyPreviewCss: `
      .sampleText {
        ${previewCss}
      }
    `,
  };
};

export const buildFontFace = (
  font?: Media,
): { fontFamily: string; fontFaceCss: string } => {
  const fontId = font && font.id;
  const fontUrl = font && font.url;

  return {
    fontFamily: fontId || '',
    fontFaceCss: fontUrl
      ? `
          @font-face {
            font-family: '${fontId}';
            src: url(${fontUrl});
          }
        `
      : '',
  };
};

export const getDefaultPropertyValue = (
  defaultValue: DefaulValue,
  t: TFunction,
  title?: string,
): TypographyProperty => {
  const className = defaultValue.className || title || uniqueId('typography');

  return {
    textCssProperty: {
      fontFamily: defaultValue.fontFamily,
      fontWeight: defaultValue.fontWeight || 'normal',
      fontStyle: defaultValue.fontStyle || 'normal',
      textTransform: defaultValue.textTransform || 'none',
      letterSpacing: defaultValue.letterSpacing || '0px',
      textDecoration: defaultValue.textDecoration || 'none',
      textDecorationThickness: defaultValue.textDecorationThickness || 'auto',
    },
    textNonCssProperty: {
      sampleText: defaultValue.sampleText || t('typographyPicker.sampleText'),
      asElement: defaultValue.asElement || TextAsElementEnum.SPAN,
      className: camelCase(className),
    },
    font: defaultValue.font,
  };
};
