import { Thunk, thunk } from 'easy-peasy';
import createKeyedCrudModel, {
  KeyedCrudModel,
} from '../../common/keyed-crud-model/keyed-crud.model';
import Gridapp from '../../types/gridapp';
import Injections from '../../injections.interface';

export type OrganisationAppsLibraryModel = KeyedCrudModel<
  Gridapp,
  { organizationId: string }
> & {
  fetchSingle: Thunk<
    OrganisationAppsLibraryModel,
    { gridappId: string; organizationId: string },
    Injections
  >;
};

const organisationAppsLibraryModelBase = createKeyedCrudModel<
  Gridapp,
  { organizationId: string }
>('/api/gridapps', 'organizationId');

const organisationAppsLibraryModel: OrganisationAppsLibraryModel = {
  ...organisationAppsLibraryModelBase,
  fetchSingle: thunk(async (actions, { gridappId, organizationId }, { injections }) => {
    const data = await injections.apiService.get<Gridapp>(`/api/gridapps/${gridappId}`);

    actions.setSingle({ key: organizationId, data });
  }),
};

export default organisationAppsLibraryModel;
