import { action, Action, Actions, select, Select, thunk, Thunk } from 'easy-peasy';
import qs from 'query-string';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import axios from 'axios';
import Injections from '../../injections.interface';
import { ApiError } from '../../../services/api/api-error';
import UniversalDevice from '../../types/universal-device';
import { PaginationCollection, PaginationSearchParam } from '../../types/pagination';
import PaginationModel, { createPaginationModel } from '../pagination/pagination.model';

export const GRID_ALL_DEVICES_KEY = '__grid-all-devices';

export interface OrganisationDevicesModel
  extends PaginationModel<OrganisationDevicesModel> {
  data: {
    [key: string]: {
      [id: string]: UniversalDevice;
    };
  };
  lastUpdated: {
    [key: string]: Date;
  };
  loading: {
    [key: string]: boolean;
  };
  error: {
    [key: string]: ApiError | null;
  };
  values: Select<OrganisationDevicesModel, (organisationId: string) => UniversalDevice[]>;
  ids: Select<OrganisationDevicesModel, (organisationId: string) => string[]>;
  loaded: Select<OrganisationDevicesModel, (organisationId: string) => boolean>;
  setLoading: Action<OrganisationDevicesModel, { key: string; loading: boolean }>;
  setError: Action<OrganisationDevicesModel, { key: string; error: ApiError | null }>;
  setData: Action<OrganisationDevicesModel, { key: string; data: UniversalDevice[] }>;
  setLastUpdated: Action<OrganisationDevicesModel, { key: string; data: Date }>;
  fetch: Thunk<
    OrganisationDevicesModel,
    {
      organizationId: string;
      silent?: boolean;
      searchParam?: PaginationSearchParam;
      page: number;
      pageSize: number;
    },
    Injections
  >;
  fetchDevicesToConnect: Thunk<
    OrganisationDevicesModel,
    {
      organizationId: string;
      searchParam?: PaginationSearchParam;
      silent?: boolean;
      page: number;
      pageSize: number;
    },
    Injections
  >;
}

const fetchDevices = async (
  url: string,
  actions: Actions<OrganisationDevicesModel>,
  { key, param, silent }: { key: string; param?: PaginationSearchParam; silent: boolean },
  injections: Injections,
) => {
  injections.apiService.handleCancelRequestGet();

  if (!silent) {
    actions.setLoading({ key, loading: true });
  }
  actions.setPaginationLoading({ key, isLoading: true });
  actions.setError({ key, error: null });

  try {
    const data = await injections.apiService.get<PaginationCollection<UniversalDevice>>(
      url,
    );

    actions.setLastUpdated({ key, data: new Date() });
    actions.setData({ key, data: data.docs });
    actions.setPagination({
      key,
      payload: {
        total: data.totalDocs,
        page: data.page,
        limit: data.limit,
        param,
      },
    });

    if (!silent) {
      actions.setLoading({ key, loading: false });
    }
    actions.setPaginationLoading({ key, isLoading: false });
  } catch (error) {
    if (!axios.isCancel(error)) {
      actions.setError({ key, error });

      if (!silent) {
        actions.setLoading({ key, loading: false });
      }
      actions.setPaginationLoading({ key, isLoading: false });
    }
  }
};

const organisationDevicesModel: OrganisationDevicesModel = {
  ...createPaginationModel<OrganisationDevicesModel>(),
  data: {},
  loading: {},
  error: {},
  lastUpdated: {},
  loaded: select((state) => (organisationId: string) =>
    !!state.data[organisationId] && !state.loading[organisationId],
  ),
  values: select((state) => (organisationId: string) =>
    Object.values(state.data[organisationId] || {}),
  ),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ids: select((state) => (organisationId: string) => Object.keys(state.data || {})),
  setLoading: action((state, { key, loading }) => {
    state.loading[key] = loading;
  }),
  setError: action((state, { key, error }) => {
    state.error[key] = error;
  }),
  setData: action((state, { key, data }) => {
    state.data[key] = {};
    data.forEach((item) => {
      state.data[key][item.id] = item;
    });
  }),
  setLastUpdated: action((state, { key, data }) => {
    state.lastUpdated[key] = data;
  }),
  fetch: thunk(
    async (
      actions,
      { organizationId, silent = false, searchParam, page, pageSize },
      { injections },
    ) => {
      const updatedSearchParam = omitBy(searchParam, isEmpty);
      const searchQueryParam =
        updatedSearchParam && Object.keys(updatedSearchParam).length
          ? `&${qs.stringify(updatedSearchParam, { arrayFormat: 'comma' })}`
          : '';

      const url = `/api/v4/devices?organizationId=${organizationId}${searchQueryParam}&page=${page}&limit=${pageSize}`;

      await fetchDevices(
        url,
        actions,
        { key: organizationId, param: searchParam, silent },
        injections,
      );
    },
  ),
  fetchDevicesToConnect: thunk(
    async (
      actions,
      { organizationId, silent = false, searchParam, page, pageSize },
      { injections },
    ) => {
      const updatedSearchParam = omitBy(searchParam, isEmpty);
      const searchQueryParam =
        updatedSearchParam && Object.keys(updatedSearchParam).length
          ? `&${qs.stringify(updatedSearchParam, { arrayFormat: 'comma' })}`
          : '';

      const url = `/api/v4/devices?organizationId=${organizationId}${searchQueryParam}&page=${page}&limit=${pageSize}`;

      await fetchDevices(
        url,
        actions,
        { key: `${organizationId}-device-connect`, param: searchParam, silent },
        injections,
      );
    },
  ),
};

export default organisationDevicesModel;
