import React, { useState, useEffect } from 'react';
import { useStore } from 'easy-peasy';
import get from 'lodash/get';
import includes from 'lodash/includes';
import { RootState } from '../../../../../store/models/root.model';
import Report from '../../../../common/report/report.component';
import SignalsOverviewContainer from './signals-overview/signals-overview.container';
import { OmboriAppsProviderEnums } from '../../../../../utils/installations/get-installation-app-icon-url';
import styled from '@emotion/styled';
import ContentWrap from '../../../../common/app-layout/content/content-wrap.component';
import {
  AnalyticsSchema,
  CardType,
  InteractionType,
  SessionInteractionType,
  WeekHeatmapDataSourceType,
} from '@ombori/grid-reports';
import { InstallationReport } from '../../../../common/overview-report';
import getAnalyticsSchemaBySku from './sku-analytcis-schema';
import { SchemaType } from '../../../../common/overview-report/reports/installation-report';
import Overlay from '../../../../common/overlay/overlay.component';
import Spinner from '../../../../common/spinner/spinner.component';

const Container = styled(ContentWrap)``;

const isFindYourStyleApp = (app: unknown): boolean => {
  const prodAppId = '633ffb1c893ae30006c07098';

  const appIds = [prodAppId];

  const appId = get(app, 'settings.provider.app.gridApp.gridapp.id');

  return includes(appIds, appId);
};

type OverviewContainerProps = Pick<
  React.ComponentProps<typeof Report>,
  'match' | 'history' | 'location'
>;

const OverviewContainer = React.memo((props: OverviewContainerProps) => {
  const {
    match: {
      params: { appId: installationId, organisationId: tenantId },
    },
  } = props;

  const [showReport, setShowReport] = useState(false);

  const { app } = useStore(
    (state: RootState) => {
      const appData =
        (state.organisationApps.data &&
          state.organisationApps.data[tenantId] &&
          state.organisationApps.data[tenantId][installationId]) ||
        null;

      const result = {
        app: appData,
      };

      return result;
    },
    [installationId],
  );

  const sku = app && app.sku ? app.sku : '';

  const skuAnalyticsSchema = getAnalyticsSchemaBySku(sku);

  if (skuAnalyticsSchema) {
    return (
      <Container>
        <InstallationReport
          schemaInput={{ type: SchemaType.Static, schema: skuAnalyticsSchema }}
        />
      </Container>
    );
  }

  const isTasksApp = app && app.provider === OmboriAppsProviderEnums.TASKS;

  if (isTasksApp) {
    return <SignalsOverviewContainer {...props} />;
  }

  const isFindYourStyle = isFindYourStyleApp(app);

  if (isFindYourStyle) {
    const analyticsSchema: AnalyticsSchema = {
      groups: [
        {
          name: 'Overview',
          cards: [
            {
              type: CardType.Sessions,
              interactionType: SessionInteractionType.Interactive,
            },
            { type: CardType.Nps },
            {
              type: CardType.EventsFunnel,
              title: 'Funnel 1',
              events: [
                'WAKEUP_BY_TAP',
                'LIKED_LOOK',
                'LIKED_PRODUCT',
                'MOBILE_QR_SCANNED',
              ],
            },
            {
              type: CardType.EventsFunnel,
              title: 'Funnel 2',
              events: [
                'WAKEUP_BY_TAP',
                'LIKED_LOOK',
                'LIKED_RECOMMENDATION',
                'MOBILE_QR_SCANNED',
              ],
            },
            { type: CardType.EventsList, interactionType: InteractionType.Interactive },
          ],
        },
        {
          name: 'Events flow',
          cards: [{ type: CardType.EventsFlow }],
          columnsCount: 1,
        },
        {
          name: 'Sessions week heatmap',
          cards: [
            {
              type: CardType.WeekHeatmap,
              title: 'Sessions week heatmap',
              dataSource: {
                type: WeekHeatmapDataSourceType.Sessions,
                interactionType: SessionInteractionType.Interactive,
              },
            },
          ],
          columnsCount: 1,
        },
      ],
    };

    return (
      <Container>
        <InstallationReport
          schemaInput={{ type: SchemaType.Static, schema: analyticsSchema }}
        />
      </Container>
    );
  }

  const fallbackSchema: AnalyticsSchema = {
    groups: [
      {
        name: 'Overview',
        cards: [
          {
            type: CardType.Sessions,
            interactionType: SessionInteractionType.Interactive,
          },
          { type: CardType.Nps },
          { type: CardType.EventsList, interactionType: InteractionType.Interactive },
          {
            type: CardType.EventsFunnel,
            title: 'Sales conversions funnel',
            events: ['CATEGORY_VIEW', 'PRODUCT_VIEW', 'CART_ADD', 'CHECKOUT', 'PURCHASE'],
          },
          {
            type: CardType.ProductsEventCount,
            eventType: 'PRODUCT_VIEW',
            title: 'Product views',
          },
          { type: CardType.CategoriesEventCount },
        ],
      },
      {
        name: 'Events flow',
        cards: [{ type: CardType.EventsFlow }],
        columnsCount: 1,
      },
      {
        name: 'Sessions week heatmap',
        cards: [
          {
            type: CardType.WeekHeatmap,
            title: 'Sessions week heatmap',
            dataSource: {
              type: WeekHeatmapDataSourceType.Sessions,
              interactionType: SessionInteractionType.Interactive,
            },
          },
        ],
        columnsCount: 1,
      },
    ],
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowReport(true);
    }, 500);
    return () => {
      setShowReport(false);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Container>
      {!showReport && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}
      {showReport && <InstallationReport schemaInput={{ type: SchemaType.Dynamic, fallbackSchema }} />}
    </Container>
  );
});

export default OverviewContainer;
