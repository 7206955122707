import { useApiService } from '../../../app/common/api-service-provider';
import { useQuery } from 'react-query';
import { PhyhubDevice } from '../../types/phyhub-devices';
import phyhubDevicesQueryKeys from './phyhub-devices-query-keys';
import { PaginationCollection } from '../../types/pagination';

export interface PhyhubDevicesListQueryParams {
  tenantId: string;
  page: number;
  limit: number;
}

const usePhyhubDevicesList = (phyhubDevicesListParams: PhyhubDevicesListQueryParams) => {
  const apiService = useApiService();

  return useQuery(
    phyhubDevicesQueryKeys.getPhyhubDevicesListKeys(phyhubDevicesListParams),
    () =>
      apiService.get<PaginationCollection<PhyhubDevice>>(`/api/phyhub-devices`, {
        organizationId: phyhubDevicesListParams.tenantId,
        page: phyhubDevicesListParams.page,
        limit: phyhubDevicesListParams.limit,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export default usePhyhubDevicesList;
