import React, { useCallback, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Tree, Icon } from 'antd';
import uniq from 'lodash/uniq';

const { TreeNode } = Tree;

export interface TreeItem {
  title: string;
  key: string;
  icon?: string | null;
  iconColor?: string;
}

export interface TreeData {
  data: TreeItem;
  children: TreeData[];
}

interface InfiniteTreeProps {
  tree: TreeData[];
  onSelect: (id: string) => void;
  activeId: string | null;
}

const getParentKeysFromKey = (tree: TreeData[], key: string) => {
  let result: string[] = [];

  function traverse(nodes: TreeData[], parentKeys: string[] = []) {
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      if (node.data.key === key) {
        result = parentKeys;
        return;
      }
      if (node.children) {
        traverse(node.children, [...parentKeys, node.data.key]);
      }
    }
  }

  traverse(tree);
  return result;
};

const InfiniteTree: React.FC<InfiniteTreeProps> = ({ tree, onSelect, activeId }) => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

  useEffect(() => {
    setSelectedKeys(activeId ? [activeId] : []);

    if (activeId) {
      // make sure parent is expanded
      const parentNodeKeys = getParentKeysFromKey(tree, activeId);
      setExpandedKeys(prev => uniq([...prev, ...parentNodeKeys]));
    }

  }, [activeId, tree]);

  const handleSelect = useCallback((targetSelectedKeys: string[]) => {
    if (targetSelectedKeys[0] && selectedKeys[0] !== targetSelectedKeys[0]) {
      onSelect(targetSelectedKeys[0]);
    }
  }, [selectedKeys, onSelect]);

  const handleManualExpand = useCallback((targetExpandedKeys: string[]) => {
    setExpandedKeys(targetExpandedKeys);
  }, []);

  const renderTreeNodes = (curData: TreeData[]) => curData.map((item) => {
    const icon = item.data.icon ? (
      <StyledIcon type={item.data.icon} color={item.data.iconColor} />
    ) : null;

    if (item.children) {
      return (
        <TreeNode 
          title={item.data.title} 
          key={item.data.key} 
          icon={icon}
        >
          {renderTreeNodes(item.children)}
        </TreeNode>
      );
    }

    return (
      <TreeNode 
        title={item.data.title} 
        key={item.data.key} 
        icon={icon}
      />
    );
  });

  return (
    <StyledTree
      showLine={false}
      showIcon={true}
      selectedKeys={selectedKeys}
      onSelect={handleSelect}
      expandedKeys={expandedKeys}
      onExpand={handleManualExpand}
    >
      {renderTreeNodes(tree)}
    </StyledTree>
  );
};

const StyledIcon = styled(Icon)<{ color?: string }>`
  color: ${({ color }) => color ? color : '#ffffff'};
`;

const StyledTree = styled(Tree)`
  color: rgba(255, 255, 255, 0.65);

  .ant-tree-title {
    color: rgba(255, 255, 255, 0.65);
    padding-left: 2px;

    &:hover {
      color: #ffffff !important;
    }

    .ant-tree-node-content-wrapper.ant-tree-node-selected & {
      color: #ffffff !important;

      &:hover {
        color: #ffffff !important;
      }
    }
  }

  .ant-tree-node-content-wrapper {
    width: 100%;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-left: 2px !important;
    min-height: 32px;
    margin-left: -4px !important;
    padding-left: 0px;

    &:hover {
      background-color: transparent !important;
    }

    &.ant-tree-node-selected {
      .ant-tree-title {
        color: #ffffff !important; // Color when selected
      }
    }
  }

  .ant-tree-switcher {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    min-height: 32px;
    margin-left: -2px !important;
    padding-left: 0px;
  }
`;

export default InfiniteTree;