import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { Button, message } from 'antd';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import usePlanGet from '../../../../store/hooks/plans/use-plan-get';
import Overlay from '../../../common/overlay/overlay.component';
import { Spinner } from '../../../common/spinner';
import { ButtonType } from '../../../../types';
import useGoBack from '../../../common/use-go-back';
import PlanForm, { PlanApiValidationErrors, PlanFormValues } from './plan-form.component';
import { PlanTypeEnum } from '../../../../store/types/organisation-plan';
import usePlanEdit from '../../../../store/hooks/plans/use-plan-edit';
import Message from '../../../common/message/message.component';
import { extractApiValidationErrors } from '../../../common/react-final-form';
import { ApiError } from '../../../../services/api/api-error';

interface PlanEditFormProps extends RouteComponentProps<{ planId: string }> {}

const PlanEditForm = (props: PlanEditFormProps) => {
  const { match } = props;

  const planId = match.params.planId;

  const { data: planData, isLoading: isLoadingPlan } = usePlanGet(planId);

  const { t } = useTranslation();

  const goBack = useGoBack();
  const handleGoBack = useCallback(() => {
    goBack();
  }, [goBack]);

  const { mutateAsync: updatePlan } = usePlanEdit(planId);

  const onFormSubmit = useCallback(
    async (values: PlanFormValues | Object) => {
      try {
        const {
          sku,
          type,
          name,
          description,
          isDefault,
          icon,
          items,
          features,
        } = values as PlanFormValues;

        await updatePlan({
          sku,
          type,
          name,
          description,
          isDefault,
          icon,
          items: items && items.length ? items : [],
          features: features && features.length ? features : [],
        });

        message.success(<Message content={t('plans.form.planEditSuccess')} />);

        return {};
      } catch (error) {
        message.error(<Message content={t('plans.form.planEditFailed')} />);

        const errors = extractApiValidationErrors<PlanApiValidationErrors>(error as ApiError);
        return errors;
      }
    },
    [t, updatePlan],
  );

  const initialValues = useMemo<PlanFormValues>(() => {
    return {
      sku: planData ? planData.sku : '',
      type: planData ? planData.type : PlanTypeEnum.SUPPORT,
      name: planData ? planData.name : { en: '' },
      description: planData ? planData.description : { en: '' },
      isDefault: planData ? planData.isDefault : false,
      icon: planData ? planData.icon : '',
      items: planData && planData.items ? planData.items : [],
      features: planData && planData.features ? planData.features : [],
    };
  }, [planData]);

  return (
    <>
      {isLoadingPlan && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}

      {!isLoadingPlan && !planData && (
        <NotFoundContainer>Plan not found!</NotFoundContainer>
      )}

      {planData && (
        <PlanFormContainer>
          <BackToListButton
            type="default"
            icon="arrow-left"
            onClick={handleGoBack}
          >
            {t('back')}
          </BackToListButton>

          <PlanForm
            title={t('plans.list.editPlan')}
            initialValues={initialValues}
            onFormSubmit={onFormSubmit}
          />
        </PlanFormContainer>
      )}
    </>
  );
};

export default PlanEditForm;

const NotFoundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const PlanFormContainer = styled.div`
  position: relative;
  flex: 1;
  padding: 30px 80px 40px;

  @media screen and (max-width: 991px) {
    padding: 0;
  }
`;

const BackToListButton = styled(Button)`
  height: 36px;
  font-size: 14px;
  color: #676973;
  border: none;
  margin-right: 5px;
  background-color: transparent;

  :hover,
  :active,
  :focus {
    background-color: #f2f4f8;
  }
` as ButtonType;
