import Environment from '../../types/environment';
import createKeyedCrudModel, {
  KeyedCrudModel,
} from '../../common/keyed-crud-model/keyed-crud.model';

export type EnvironmentsModel = KeyedCrudModel<Environment, { organizationId: string }>;

const environmentsModel: EnvironmentsModel = createKeyedCrudModel<
  Environment,
  { organizationId: string }
>('/api/environments', 'organizationId');

export default environmentsModel;
