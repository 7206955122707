import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import Confetti from 'react-confetti';
import VoucherDataType from '../../../store/types/voucher';

const Voucher = ({ voucher }: { voucher: VoucherDataType }) => {
  const [isConfettiActive, setIsConfettiActive] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsConfettiActive(true);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [setIsConfettiActive]);

  return (
    <>
      {isConfettiActive && <Confetti recycle={false} />}
      <VoucherContainer>
        <VoucherValue>{<div>{voucher.title}</div>}</VoucherValue>
        <VoucherInfo>
          <VoucherDescription>{voucher.description}</VoucherDescription>
          <VoucherFineprint>{voucher.validity}</VoucherFineprint>
          <VoucherFineprint>{voucher.extraInfo}</VoucherFineprint>
        </VoucherInfo>
      </VoucherContainer>
    </>
  );
};

const popIn = keyframes`
  from {
    transform: scale(0);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const VoucherContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #333237;
  color: #fff;
  border-radius: 16px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08), 0px 2px 4px 0px rgba(0, 0, 0, 0.08),
    0px 4px 8px 0px rgba(0, 0, 0, 0.08), 0px 8px 16px 0px rgba(0, 0, 0, 0.08),
    0px 16px 32px 0px rgba(0, 0, 0, 0.08), 0px 32px 64px 0px rgba(0, 0, 0, 0.08),
    0px 64px 128px 0px rgba(0, 0, 0, 0.08);
  transform: scale(0);
  animation-name: ${popIn};
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
`;
const VoucherValue = styled.div`
  display: block;
  background: #ff2424;
  padding: 16px 8px;
  font-size: 48px;
  font-weight: 700;
  text-align: center;
  border-radius: 16px 16px 0 0;
  line-height: 1;
`;
const VoucherInfo = styled.div`
  display: block;
  padding: 20px;
  text-align: center;
  line-height: 1.4;
`;
const VoucherDescription = styled.p`
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 8px;
`;
const VoucherFineprint = styled.p`
  font-size: 14px;
  opacity: 0.56;
`;

export default Voucher;
