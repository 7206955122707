import qs from 'query-string';

export const appendQueryParams = (
  basePath: string,
  parameters: Record<string, string | number | boolean | undefined>,
) => {
  const delimiter = basePath.includes('?') ? '&' : '?';

  return `${basePath}${delimiter}${qs.stringify(parameters)}`;
};
