import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { ColumnProps } from 'antd/lib/table';
import styled from '@emotion/styled';
import { Button, message, Popconfirm } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { AlertActionGroup } from '../../../../../store/types/alerts';
import moment from 'moment';
import { Edit, Trash2 } from 'react-feather';
import usePagination from '../../../../common/pagination/hook/use-pagination';
import {
  useAlertActionGroup,
  useDeleteAlertActionGroupById,
} from '../../../../../store/hooks/alert-action-groups';
import ErrorViewComponent from '../../../../common/error-view/error-view.component';
import usePermissions from '../../../../../utils/auth/use-permissions';
import permissionKeys from '../../../../../utils/auth/permissions';
import { useAlertRules } from '../../../../../store/hooks/alert-rules';
import PaginatedListTable from '../../../../common/paginated-list/paginatied-list-table';
import { ButtonType } from '../../../../../types';
import usePaginationQueryParams from '../../../../common/use-pagination-query-params';
import { Icon } from '../../../../common/schema-form/common';


interface ActionGroupsViewProps extends RouteComponentProps<{ organisationId: string }> {}

const ActionGroupsView = (props: ActionGroupsViewProps) => {
  const {
    match: {
      url,
      params: { organisationId },
    },
  } = props;

  const [actionGroupIdToDelete, setActionGroupIdToDelete] = useState<string>();

  const { isAllowed } = usePermissions(organisationId);

  const { createAlert, updateAlert, deleteAlert, showAlert } = useMemo(
    () => ({
      createAlert: isAllowed(permissionKeys.alerts.create),
      updateAlert: isAllowed(permissionKeys.alerts.update),
      deleteAlert: isAllowed(permissionKeys.alerts.remove),
      showAlert: isAllowed(permissionKeys.alerts.viewAll),
    }),
    [isAllowed],
  );

  const history = useHistory();

  const { t } = useTranslation();

  const {
    page,
    defaultPage,
    pageSize,
    defaultPageSize,
  } = usePagination();

  const [paginationQueryParams] = usePaginationQueryParams({
    page: page || defaultPage,
    limit: pageSize || defaultPageSize
  });

  const {
    data: actionGroupsData,
    isLoading,
    isFetching,
    isRefetching: isRefetchingActionGroup,
    isError,
    refetch: refetchActionGroups,
  } = useAlertActionGroup({
    tenantId: organisationId,
    page: paginationQueryParams.page,
    limit: paginationQueryParams.limit
  });

  const {
    mutateAsync,
    isLoading: isDeleteActionGroupLoading,
  } = useDeleteAlertActionGroupById(organisationId);

  const { refetch: refetchAlertRules, isFetching: isFetchingAlertRules } = useAlertRules({
    tenantId: organisationId,
    actionGroups: actionGroupIdToDelete ? [actionGroupIdToDelete] : [],
    page: 1,
    limit: 5,
    isEnabled: false,
  });

  const handleCreateActionGroup = useCallback(() => {
    history.push(`${url}/create#general`);
  }, [history, url]);

  const handleEditActionGroup = useCallback(
    (id: string) => {
      history.push(`${url}/update/${id}#general`);
    },
    [history, url],
  );

  const handleDeleteActionGroup = useCallback(
    async (id: string) => setActionGroupIdToDelete(id),
    [],
  );

  useEffect(() => {
    const deleteActionGroup = async () => {
      try {
        if (actionGroupIdToDelete) {
          const result = await refetchAlertRules();

          if (result.isError) {
            throw result.error;
          }

          if (result.data && result.data.totalDocs) {
            const alertRule = result.data.docs[0].displayName;

            message.warning(
              <Trans
                defaults={t('actionGroups.deleteNotAllowed', { alertRule })}
                components={{ strong: <strong /> }}
              />,
              5,
            );

            return;
          }

          await mutateAsync(actionGroupIdToDelete);
          await refetchActionGroups();

          message.success(t('actionGroups.successDeletingData'));
        }
      } catch {
        message.error(t('actionGroups.errorDeletingData'));
      } finally {
        setActionGroupIdToDelete(undefined);
      }
    };

    deleteActionGroup();
  }, [actionGroupIdToDelete, refetchActionGroups, refetchAlertRules, mutateAsync, t]);

  const isDeleting =
    isFetchingAlertRules || isRefetchingActionGroup || isDeleteActionGroupLoading;

  const columns = useMemo<ColumnProps<AlertActionGroup>[]>(
    () => [
      {
        title: t('actionGroups.name'),
        key: 'alertRule',
        render: (data: AlertActionGroup) => <Link to={`${url}/update/${data.id}`}>{data.displayName}</Link>
      },
      {
        title: t('alertRules.lastUpdateAt'),
        key: 'lastUpdateAt',
        render: (data: AlertActionGroup) => {
          return moment(data.updatedAt).format('DD/MM/YYYY hh:mm A');
        },
      },
      {
        title: <Icon type="setting" />,
        key: 'settings',
        fixed: 'right',
        width: 50,
        align: 'center',
        render: (data: AlertActionGroup) => {
          const deleteIcon =
            actionGroupIdToDelete === data.id && isDeleting
            ? <Icon type="loading" />
            : <Icon component={() => <Trash2 color="#FF4D4F" />} />;

          return (
            <ActionContainer>
              {updateAlert && (
                <Icon component={() => <Edit />} onClick={() => handleEditActionGroup(data.id)} />
              )}
              {deleteAlert && (
                <Popconfirm
                  title={t('actionGroups.areYouSureYouWantToDeleteThisAlertActionGroup')}
                  onConfirm={() => handleDeleteActionGroup(data.id)}
                  okText={t('yes')}
                  cancelText={t('no')}
                >
                  {deleteIcon}
                </Popconfirm>
              )}
            </ActionContainer>
          );
        },
      },
    ],
    [
      t,
      url,
      handleEditActionGroup,
      deleteAlert,
      handleDeleteActionGroup,
      updateAlert,
      isDeleting,
      actionGroupIdToDelete,
    ],
  );

  return (
    <ActionRulesViewContainer>
      {createAlert && (
        <ButtonWrapper>
          <Button
            type="primary"
            size="large"
            icon="plus"
            onClick={handleCreateActionGroup}
          >
            {t('actionGroups.createActionGroup')}
          </Button>
        </ButtonWrapper>
      )}
      {isError && <ErrorViewComponent position="relative" />}
      {showAlert && <PaginatedListTable<AlertActionGroup>
        data={actionGroupsData!}
        columns={columns}
        isDataLoading={isLoading || isFetching}

      />}
    </ActionRulesViewContainer>
  );
};

const ActionRulesViewContainer = styled.div`
  position: relative;
  flex: 1;

  @media screen and (max-width: 991px) {
    padding: 20px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  margin-right: 80px;
` as ButtonType;

const ActionContainer = styled.div`
  display: flex;
  gap: 10px;
  color: #689bc4;
`;

export default ActionGroupsView;
