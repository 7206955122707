import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import SchemaForm from '../../schema-form.component';
import { useGridAppBuilds } from '../../../use-grid-app-builds';
import GridappBuild from '../../../../../store/types/gridapp-build';
import validateForm from '../../../../../utils/validation/validate-form';
import { buildFieldSchema, buildUiSchema } from './build-schema-item-settings';
import { FieldProps } from 'react-jsonschema-form';

interface GridappItemSettingsProps
  extends Pick<FieldProps, 'formContext' | 'formData' | 'onChange'> {
  isGridAppVersionPickerVisible?: boolean;
}

const GridappItemSettings = (props: GridappItemSettingsProps) => {
  const {
    isGridAppVersionPickerVisible = true,
    formContext,
    formData,
    onChange = () => {},
  } = props;
  // TODO: Fix this ugly hack
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const didBuildIdChangeRefFlag = useRef<boolean | null>(false);
  const prevBuildIdRef = useRef<string | null>(null);

  const { id: gridAppId, buildId } = formData.gridapp || { id: null, buildId: null };

  const [selectedBuild, setSelectedBuild] = useState<GridappBuild | undefined>();

  const { data: gridAppBuilds = [] } = useGridAppBuilds({ gridAppId });

  useEffect(() => {
    if (prevBuildIdRef.current !== null && prevBuildIdRef.current !== buildId) {
      didBuildIdChangeRefFlag.current = true;
    }
    prevBuildIdRef.current = buildId;

    const matchedBuild = gridAppBuilds.find((build) => build.id === buildId);
    setSelectedBuild(matchedBuild);
  }, [gridAppBuilds, buildId]);

  const handleChange = useCallback(
    // TO DO: Define type
    (settings: any) => {
      if (selectedBuild && selectedBuild.result && formData.gridapp != null) {
        const valid = !Object.keys(
          validateForm(settings, selectedBuild.result.settingsSchema),
        ).length;

        // TODO: Fix this ugly hack. There's some race condition in JSON Schema Form!
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        if (didBuildIdChangeRefFlag.current) {
          setTimeout(() => {
            onChange({
              ...formData,
              settings,
              valid,
            });
            timeoutRef.current = null;
            didBuildIdChangeRefFlag.current = false;
          }, 500);
        } else {
          onChange({
            ...formData,
            settings,
            valid,
          });
        }
      }
    },
    [selectedBuild, onChange, formData],
  );

  const { fieldSchema, uiSchema } = useMemo(() => {
    const fieldSchema = buildFieldSchema(selectedBuild);

    const uiSchema = buildUiSchema(isGridAppVersionPickerVisible, selectedBuild);

    return {
      fieldSchema,
      uiSchema,
    };
  }, [selectedBuild, isGridAppVersionPickerVisible]);

  if (!selectedBuild || !selectedBuild.result) {
    return null;
  }

  return (
    <>
      <div style={{ marginTop: 16 }} />
      <SchemaForm
        tagName="div"
        schema={fieldSchema}
        uiSchema={uiSchema}
        data={formData.settings || {}}
        organisationId={formContext.organisationId}
        formContext={{ ...formContext, hideMainGridAppPicker: false }}
        onChange={handleChange}
      />
    </>
  );
};

export default GridappItemSettings;
