import React, { createContext, useContext } from 'react';
import GridDataService from '../../../services/api/grid-data-service';

const GridDataServiceContext = createContext<GridDataService | null>(null);

interface GridDataServiceProviderProps {
  children: React.ReactNode;
  service: GridDataService;
}

const GridDataServiceProvider: React.FC<GridDataServiceProviderProps> = ({
  children,
  service,
}): JSX.Element => {
  return (
    <GridDataServiceContext.Provider value={service}>
      {children}
    </GridDataServiceContext.Provider>
  );
};

export const useGridDataService = (): GridDataService => {
  const ctx = useContext(GridDataServiceContext);

  if (!ctx) {
    throw new Error(
      'Error caught while consuming GridDataServiceContext context. Make sure you wrap the component inside the "GridDataServiceProvider".',
    );
  }

  return ctx;
};

export default GridDataServiceProvider;
