import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import queryString, { ParseOptions } from 'query-string';
import useDeepCompareMemoize from '../use-deep-compare-memoize';

export type UseQueryParamsResponse<T> = [
  T,
  (newParams: T | ((prevParams: T) => T)) => void
];

const useQueryParams = <T = Record<string, string | number | boolean | undefined>>(
  defaultParamValues?: T,
  options?: ParseOptions,
): UseQueryParamsResponse<T> => {
  const history = useHistory();
  const location = useLocation();

  const queryParamValues = useMemo(() => {
    const parsedParams = (queryString.parse(location.search, options) as unknown) as T;
    const parsedDefaultParamValues = defaultParamValues
      ? ((queryString.parse(
          queryString.stringify(defaultParamValues),
          options,
        ) as unknown) as T)
      : {};

    return { ...parsedDefaultParamValues, ...parsedParams };
  }, [defaultParamValues, location.search, options]);

  const setQueryParams = useCallback(
    (newParams: T | ((prevParams: T) => T)) => {
      const parsedParams = (queryString.parse(
        location.search,
        options,
      ) as unknown) as T;

      const updatedParams =
        typeof newParams === 'function'
          ? (newParams as (prevParams: T) => T)(parsedParams)
          : newParams;

      history.replace({
        pathname: location.pathname,
        search: queryString.stringify(Object.assign({}, parsedParams, updatedParams)),
      });
    },
    [history, location, options],
  );

  const memoizedQueryParams = useDeepCompareMemoize<T>(queryParamValues);

  return [memoizedQueryParams, setQueryParams];
};

export default useQueryParams;
