import React, { useCallback } from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Row, Col, Tabs } from 'antd';
import {
  getPermissionPath,
  permissionKeys,
} from '../../../../utils/auth/permissions';
import ErrorBoundary from '../../../common/error-boundary/error-boundary.component';
import Header from '../../../common/app-layout/header/header.component';
import TabBar from '../../../common/tab-bar/tab-bar.component';
import ProtectedRouteContainer from '../../../common/protected-route/protected-route.container';
import AppsLibrary from './developers-section-apps/developer-section-apps.component';
import TokensContainer from './developers-section-tokens/developers-section-tokens.container';
import ResourcesContainer from './developers-section-resources.component';

type DevelopersSectionProps = RouteComponentProps<{ organisationId: string }>;

const DevelopersSection = ({ match, history, location }: DevelopersSectionProps) => {
  const { organisationId } = match.params;
  const { t, i18n } = useTranslation();
 
  const onTabChange = useCallback(
    (key: string) => {
      history.push(key);
    },
    [history],
  );

  return (
    <ErrorBoundary>
      <HeaderStyled title={<span>{t('developer')}</span>}>
        <Row>
          <Col>
            <TabBarStyled
              onChange={onTabChange}
              activeKey={location.pathname}
              lang={i18n.language}
            >
              <Tabs.TabPane
                tab={<span>{t('apps')}</span>}
                key={`${match.url}/apps`}
              />
              <Tabs.TabPane
                tab={<span>{t('tokens')}</span>}
                key={`${match.url}/tokens`}
              />
              <Tabs.TabPane
                tab={<span>{t('resources')}</span>}
                key={`${match.url}/resources`}
              />
            </TabBarStyled>
          </Col>
        </Row>
      </HeaderStyled>
      <Switch>
        <Route
          exact
          path={match.path}
          render={() => <Redirect to={`${match.url}/apps`} />}
        />
        <ProtectedRouteContainer
          permissionPath={getPermissionPath(
            organisationId,
            permissionKeys.gridApps.create,
          )}
          path={`${match.path}/apps`}
          component={AppsLibrary}
        />
        <ProtectedRouteContainer
          permissionPath={getPermissionPath(
            organisationId,
            permissionKeys.gridApps.create,
          )}
          path={`${match.path}/tokens`}
          component={TokensContainer}
        />
        <ProtectedRouteContainer
          permissionPath={getPermissionPath(
            organisationId,
            permissionKeys.gridApps.create,
          )}
          path={`${match.path}/resources`}
          component={ResourcesContainer}
        />
      </Switch>
    </ErrorBoundary>
  );
};

const HeaderStyled = styled(Header)`
  padding-bottom: 0px;
`;

const TabBarStyled = styled(TabBar)`
  margin-top: 24px;
`;

export default DevelopersSection;
