import { action, Action, Select, select, thunk, Thunk } from 'easy-peasy';
import { ApiError } from '../../../services/api/api-error';
import Injections from '../../injections.interface';

export interface KeyedCrudModel<T extends { id: string } = any, P = any> {
  data: {
    [key: string]: {
      [id: string]: T;
    };
  };
  loading: {
    [key: string]: boolean;
  };
  error: {
    [key: string]: ApiError | null;
  };
  values: Select<KeyedCrudModel<T, P>, (key: string) => T[]>;
  ids: Select<KeyedCrudModel<T, P>, (key: string) => string[]>;
  loaded: Select<KeyedCrudModel<T, P>, (key: string) => boolean>;
  setLoading: Action<KeyedCrudModel<T, P>, { key: string; loading: boolean }>;
  setError: Action<KeyedCrudModel<T, P>, { key: string; error: ApiError | null }>;
  setData: Action<KeyedCrudModel<T, P>, { key: string; data: T[] }>;
  upsertData: Action<KeyedCrudModel<T, P>, { key: string; data: T[] }>;
  setSingle: Action<KeyedCrudModel<T, P>, { key: string; data: T }>;
  unsetSingle: Action<KeyedCrudModel<T, P>, { key: string; id: string }>;
  fetch: Thunk<KeyedCrudModel<T, P>, P & { silent?: true; key?: string }, Injections>;
  create: Thunk<KeyedCrudModel<T, P>, { data: Partial<T>; key: string }, Injections>;
  update: Thunk<
    KeyedCrudModel<T, P>,
    { data: Partial<T>; key: string; id?: string },
    Injections
  >;
  delete: Thunk<KeyedCrudModel<T, P>, { id: string; key: string }, Injections>;
}

const getEndpointUrl = (path: string | ((key: string) => string), key: string): string =>
  typeof path === 'string' ? path : path(key);

export default function createKeyedCrudModel<
  T extends { id: string } = any,
  P extends { [key: string]: string } = any
>(
  path: string | ((key: string) => string),
  keyField?: Exclude<keyof P, 'silent' | 'key'>,
): KeyedCrudModel<T, P> {
  return {
    data: {},
    loading: {},
    error: {},
    loaded: select((state) => (key: string) => !!state.data[key] && !state.loading[key]),
    values: select((state) => (key: string) => Object.values(state.data[key] || {})),
    ids: select((state) => (key: string) => Object.keys(state.data[key] || {})),
    setLoading: action((state, { key, loading }) => {
      state.loading[key] = loading;
    }),
    setError: action((state, { key, error }) => {
      state.error[key] = error;
    }),
    setData: action((state, { key, data }) => {
      state.data[key] = {};
      data.forEach((item) => {
        state.data[key][item.id] = item;
      });
    }),
    upsertData: action((state, { key, data }) => {
      data.forEach((item) => {
        state.data[key][item.id] = item;
      });
    }),
    setSingle: action((state, { key, data }) => {
      if (!state.data[key]) {
        state.data[key] = {};
      }
      state.data[key][data.id] = data;
    }),
    unsetSingle: action((state, { id, key }) => {
      if (state.data[key]) {
        delete state.data[key][id];
      }
    }),
    fetch: thunk(
      async (actions, { silent, key: keyParam, ...params }, { injections }) => {
        const key = keyParam || (keyField && params[keyField]);
        if (typeof key !== 'string') {
          throw new Error('Key must be a string');
        }
        if (!silent) {
          actions.setLoading({ key, loading: true });
        }
        actions.setError({ key, error: null });
        try {
          const data = await injections.apiService.get<T[]>(
            getEndpointUrl(path, key),
            params || {},
          );
          actions.setData({ key, data });
        } catch (error) {
          actions.setError({ key, error });
        } finally {
          if (!silent) {
            actions.setLoading({ key, loading: false });
          }
        }
      },
    ),
    create: thunk(async (actions, { data, key }, { injections }) => {
      const result = await injections.apiService.post<T>(getEndpointUrl(path, key), data);
      actions.setSingle({ key, data: result });
      return result.id;
    }),
    update: thunk(async (actions, { data, key, id }, { injections }) => {
      const paramId = id || data.id;
      const result = await injections.apiService.put<T>(
        `${getEndpointUrl(path, key)}/${paramId}`,
        data,
      );
      actions.setSingle({ key, data: result });
    }),
    delete: thunk(async (actions, { key, id }, { injections }) => {
      await injections.apiService.delete<void>(`${getEndpointUrl(path, key)}/${id}`);
      actions.unsetSingle({ key, id });
    }),
  };
}
