/* stylelint-disable property-no-vendor-prefix */
import React, { useCallback, useEffect, useState, useImperativeHandle } from 'react';
import { FieldProps } from 'react-jsonschema-form';
import { AutoComplete } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { get } from 'lodash';
import styled from '@emotion/styled';

const buildDataSource = (
  options: { label: string; value: string }[],
): { text: string; value: string }[] => {
  return options.map((option) => {
    return { text: option.label, value: option.value };
  });
};

export interface SelectSearchRef {
  setValue: (value: string) => void;
}

interface SelectSearchProps extends FieldProps {
  onSelectedValue?: (key: string, label: string) => void;
  innerRef?: React.Ref<SelectSearchRef>;
}

const SelectSearch = (props: SelectSearchProps) => {
  const {
    id,
    value,
    options,
    placeholder,
    disabled,
    readonly,
    autoFocus,
    rawErrors,
    onChange,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSelectedValue = (key: string, label: string) => {},
    innerRef,
  } = props;
  const [selectedValue, setSelectedValue] = useState<SelectValue>();

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  useEffect(() => {
    const input = document.querySelector(`#${id} input.ant-select-search__field`);
    if (input) {
      input.setAttribute('autocomplete', 'autocomplete-off');
    }
  }, [id]);

  const handleChange = useCallback(
    (val: SelectValue) => {
      const inputValue = val || undefined;

      onChange(inputValue);
      setSelectedValue(inputValue);
    },
    [onChange],
  );

  const handleSelect = useCallback(
    (key: SelectValue, option: Record<string, any>) => {
      const label = option.props.children as string;
      onSelectedValue(key as string, label);
    },
    [onSelectedValue],
  );

  useImperativeHandle(
    innerRef,
    () => ({
      setValue: (val: string) => {
        const inputValue = val;
        setSelectedValue(inputValue);
      },
    }),
    [],
  );

  return (
    <AutoCompleteStyled
      id={id}
      value={selectedValue}
      dataSource={buildDataSource(get(options, 'enumOptions', options))}
      placeholder={placeholder || ''}
      autoFocus={autoFocus}
      disabled={disabled || readonly}
      readonly={readonly}
      filterOption={(inputValue: string, option: any) => {
        return (
          option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        );
      }}
      onChange={handleChange}
      onSelect={handleSelect}
      haserror={rawErrors && rawErrors.length}
      allowClear
    />
  );
};

export const arrayToNameIdEnums = <T extends {}>({
  arr,
  idKey,
  nameKey,
}: {
  arr: T[];
  idKey: string;
  nameKey: string;
}): { names: string[]; ids: string[] } => {
  const names: string[] = [];
  const ids: string[] = [];

  arr.forEach((item: T) => {
    const id = get(item, idKey) as string;
    const name = get(item, nameKey) as string;

    ids.push(id);
    names.push(name);
  });

  return { names, ids };
};

// eslint-disable-next-line no-unexpected-multiline
const AutoCompleteStyled = styled(AutoComplete)<{
  readonly?: boolean;
  haserror?: boolean;
}>`
  ${({ readonly }) =>
    readonly &&
    `.ant-input-disabled {
      background-color: #eeeeee !important;
      cursor: auto !important;
    }`}

  .ant-input {
    color: #555555 !important;
    background-image: none !important;
    border-color: ${({ haserror }) => (haserror ? '#a94442' : '#ccc')} !important;
    border-radius: 4px !important;

    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%) !important;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%) !important;
    -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s !important;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s !important;

    :hover {
      border-color: ${({ haserror }) => (haserror ? '#a94442' : '#ccc')} !important;
    }

    :focus {
      border-color: ${({ haserror }) => (haserror ? '#843534' : '#66afe9')} !important;
      outline: 0 !important;
      -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%),
        ${({ haserror }) =>
          haserror ? '0 0 6px #ce8483' : '0 0 8px rgb(102 175 233 / 60%) '} !important;
      box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%),
        ${({ haserror }) =>
          haserror ? '0 0 6px #ce8483' : '0 0 8px rgb(102 175 233 / 60%) '} !important;
    }
  }
`;

export default SelectSearch;
