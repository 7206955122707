import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import {
  InputCheckbox,
  Label,
  MainSectionTitle,
  PanelBody,
  PanelCardMain,
  RowStyled,
  FormTextInput,
} from './styled-block';

const { Title } = Typography;

interface Props {
  isEnabled: boolean;
  emailComponent: JSX.Element;
}

const StaffNotifications: React.FC<Props> = ({ isEnabled, emailComponent }) => {
  const { t } = useTranslation();

  return (
    <PanelCardMain bodyStyle={{ padding: 0 }}>
      <MainSectionTitle>
        <Title level={2}>{t('staffNotificationsTitle')}</Title>
      </MainSectionTitle>
      <PanelBody>
        <Field name="staffNotifications.isEnabled" type="checkbox">
          {({ input }) => (
            <RowStyled>
              <Label>
                <InputCheckbox {...input} type="checkbox" />
                {t('staffNotificationsIsEnabled')}
              </Label>
            </RowStyled>
          )}
        </Field>
        {isEnabled && (
          <>
            {emailComponent}
            <Field name="staffNotifications.thresholdWaitingTime">
              {({ input, meta }) => (
                <FormTextInput
                  input={input}
                  meta={meta}
                  label={t('staffNotificationsThresholdWaitingTime')}
                />
              )}
            </Field>
            <Field name="staffNotifications.thresholdPeopleCount">
              {({ input, meta }) => (
                <FormTextInput
                  input={input}
                  meta={meta}
                  label={t('staffNotificationsThresholdPeopleCount')}
                />
              )}
            </Field>
            <Field name="staffNotifications.sendInterval">
              {({ input, meta }) => (
                <FormTextInput
                  input={input}
                  meta={meta}
                  label={t('staffNotificationsSendInterval')}
                />
              )}
            </Field>
          </>
        )}
      </PanelBody>
    </PanelCardMain>
  );
};

export default StaffNotifications;
