import { useQuery } from 'react-query';
import { useApiService } from '../../../app/common/api-service-provider';
import libraryQueryKeys from './library-query-keys';
import Gridapp from '../../types/gridapp';

const useContentLibraryGridapp = (params: { tenantId: string, gridAppId: string }) => {
  const apiService = useApiService();

  const { tenantId, gridAppId } = params;

  return useQuery(
    libraryQueryKeys.getGridApp(
      tenantId,
      gridAppId,
    ),
    () =>
      apiService.get<Gridapp>(`/api/gridapps/${gridAppId}`, {
        tenantId,
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      enabled: tenantId && gridAppId ? true : false,
    },
  );
};

export default useContentLibraryGridapp;

