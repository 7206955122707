import { Button, Col, message, Row, Typography } from 'antd';
import styled from '@emotion/styled';
import { RouteComponentProps } from 'react-router';
import React, { useEffect, useRef, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PanelCard from '../../../../common/panel-card/panel-card.component';
import OrganisationApp from '../../../../../store/types/organisation-app';
import SchemaForm, {
  SchemaFormRef,
} from '../../../../common/schema-form/schema-form.component';
import useSchemaForm from '../../../../common/use-schema-form/use-schema-form';
import User from '../../../../../store/types/user';
import DeviceTypeEnum from '../../../../../store/types/device-type.enum';
import ArchitectureEnum from '../../../../../store/types/architecture.enum';
import {
  getAllLocaleCodes,
  getLocaleCodeLabel,
} from '../../../../../utils/language/language.util';
import MultiSelect from '../../../../common/schema-form/widgets/multi-select/multi-select.component';
import usePermissions from '../../../../../utils/auth/use-permissions';
import { permissionKeys } from '../../../../../utils/auth/permissions';
import InstallationGroup from '../../../../../store/types/installation-group';
import DropDownWithCreate from '../../../../common/schema-form/internal-widgets/dropdown-with-create/dropdown-with-create.component';
import useDeleteModal from '../../../../common/use-delete-modal';
import ContentWrap from '../../../../common/app-layout/content/content-wrap.component';
import { Sku } from '../../../../common/use-skus';
import { ApiError } from '../../../../../services/api/api-error';

const { Paragraph, Title } = Typography;

interface SettingsProps
  extends RouteComponentProps<{ appId: string; organisationId: string }> {
  app: OrganisationApp | null;
  updateApp: (app: Partial<OrganisationApp>) => Promise<void>;
  user: User | null;
  installationGroups: InstallationGroup[];
  createInstallationGroup: (params: Partial<InstallationGroup>) => Promise<string | undefined>;
  deleteApp: (app: OrganisationApp) => Promise<void>;
  skus: Sku[];
}

const getValueFromFormData = (formData: any, propName: string) => {
  return formData && formData.appSettings ? formData.appSettings[propName] : null;
};

const Settings = (props: SettingsProps) => {
  const {
    app,
    updateApp,
    user,
    installationGroups,
    createInstallationGroup,
    deleteApp,
    history,
    skus,
  } = props;
  const {
    match: {
      params: { organisationId },
    },
  } = props;
  const { t } = useTranslation();
  const { isAllowed } = usePermissions(organisationId);
  const formElement = useRef<SchemaFormRef>(null);
  const localeCodeList = useMemo(() => getAllLocaleCodes(), []);
  const { isCloudApp, isIoTApp } = useMemo(() => {
    return {
      isCloudApp: !!app && app.deviceType === DeviceTypeEnum.CLOUD,
      isIoTApp: !!app && app.deviceType === DeviceTypeEnum.IOT,
    };
  }, [app]);

  const [showDeleteModal] = useDeleteModal();

  const handleSave = useCallback(() => {
    if (formElement.current) {
      formElement.current.submit();
    }
  }, []);

  const handleInstallationDelete = useCallback(() => {
    if (app) {
      showDeleteModal(
        t('confirmDeleteInstallation'),
        t('areYouSureYouWantToDeleteInstallation', { installationName: app.displayName }),
        app.displayName,
        async () => {
          try {
            await deleteApp(app);
            message.success(t('successfullyDeletedInstallation'));
            history.replace(`/organisations/${app.organizationId}/marketplace`);
          } catch (error) {
            if (error instanceof ApiError && error.response && error.response.data && error.response.data.message) {
              message.error(error.response.data.message);
            } else {
              message.error(t('failedToDeleteInstallation'));
            }
          }
        },
      );
    }
  }, [app, showDeleteModal, t, deleteApp, history]);

  const handleSubmit = useCallback(
    async ({ appSettings }: any) => {
      if (app) {
        await updateApp({
          id: app.id,
          updatedAt: app.updatedAt,
          ...appSettings,
          architecture:
            appSettings.deviceType === DeviceTypeEnum.WINDOWS
              ? appSettings.architecture
              : ArchitectureEnum.X64,
          ...(isCloudApp // for backward compatibility
            ? {
              externalId: app.externalId,
              organizationId: organisationId,
              settings: app.settings,
            }
            : {}),
        });
      }
    },
    [app, isCloudApp, organisationId, updateApp],
  );

  const handleError = useCallback((e) => {
    console.log(e)
    message.error(t('thereAreErrorsInTheSettingsForm'));
  }, [t]);

  const [
    formData,
    formLoading,
    formDirty,
    formInit,
    handleFormChange,
    handleFormSubmit,
  ] = useSchemaForm(
    handleSubmit,
    t('installationSettingsSaved'),
    t('installationSettingsSaveError'),
  );

  useEffect(() => {
    if (app) {
      formInit({
        appSettings: {
          appName: app.appName,
          displayName: app.displayName,
          provider: app.provider,
          release: app.release,
          deviceType: app.deviceType,
          architecture: app.architecture,
          multiLanguageSupport: !!app.multiLanguageSupport,
          supportedLanguages: app.supportedLanguages,
          defaultLanguage: app.defaultLanguage,
          installationGroupId: app.installationGroupId || '',
          sku: app.sku || '',
        },
      });
    }
  }, [app, formInit]);

  const deviceType = useMemo(() => getValueFromFormData(formData, 'deviceType'), [
    formData,
  ]);

  const defaultLanguageOptions: string[] = useMemo(() => {
    return formData &&
      formData.appSettings &&
      formData.appSettings.supportedLanguages &&
      formData.appSettings.supportedLanguages.length
      ? formData.appSettings.supportedLanguages
      : [];
  }, [formData]);

  const onFormChange = useCallback(
    (newFormData: any) => {
      const newDeviceType = getValueFromFormData(newFormData, 'deviceType');

      if (deviceType && newDeviceType && deviceType !== newDeviceType) {
        newFormData.appSettings.release = undefined;
        newFormData.appSettings.provider = undefined;
      }
      handleFormChange(newFormData);
    },
    [deviceType, handleFormChange],
  );

  const formSchema: any = useMemo(() => {
    const schema = {
      type: 'object',
      properties: {
        appSettings: {
          type: 'object',
          title: '', // t('appSettings'),
          properties: {
            ...(!isCloudApp
              ? {
                displayName: {
                  type: 'string',
                  title: t('installationName'),
                },
              }
              : {}),
            installationGroupId: {
              type: 'string',
              title: t('addToGroup'),
              enum: ['', ...installationGroups.map((group) => group.id)],
              enumNames: [
                t('myInstallationsDefault'),
                ...installationGroups.map((group) => group.displayName),
              ],
              default: '',
            },
            ...(!isCloudApp && !isIoTApp
              ? {
                multiLanguageSupport: {
                  type: 'boolean',
                  title: t('multiLanguageSupport'),
                  default: false,
                },
              }
              : {}),
            ...(user && user.isSysadmin ? {
              sku: {
                type: 'string',
                title: t('sku'),
                enum: skus.map(sku => sku.sku),
                enumNames: skus.map(sku => `${sku.sku} (${sku.name})`),
              }
            } : {
                sku: {
                  type: 'string',
                  title: 'SKU',
                  default: '',
                  readOnly: true,
                },
              })
          },
          required: ['displayName', 'multiLanguageSupport'],
          dependencies: {
            multiLanguageSupport: {
              oneOf: [
                {
                  properties: {
                    multiLanguageSupport: {
                      enum: [true],
                    },
                    supportedLanguages: {
                      title: t('supportedLanguages'),
                      type: 'array',
                      uniqueItems: true,
                      minItems: 1,
                      items: {
                        type: 'string',
                        enum: localeCodeList.map((localeCode) => localeCode.key),
                        enumNames: localeCodeList.map((localeCode) => localeCode.label),
                      },
                    },
                    ...(defaultLanguageOptions.length
                      ? {
                        defaultLanguage: {
                          type: 'string',
                          title: t('defaultLanguage'),
                          enum: defaultLanguageOptions,
                          enumNames: defaultLanguageOptions.map((code) =>
                            getLocaleCodeLabel(code),
                          ),
                        },
                      }
                      : {}),
                  },
                },
                {
                  properties: {
                    multiLanguageSupport: {
                      enum: [false],
                    },
                  },
                },
              ],
            },
          },
        },
      },
    };
    return schema;
  }, [
    defaultLanguageOptions,
    installationGroups,
    isCloudApp,
    isIoTApp,
    localeCodeList,
    t,
    skus,
    user
  ]);

  const uiSchema = {
    objectFieldTemplate: 'main-section',
    appSettings: {
      objectFieldTemplate: 'main-section',
      supportedLanguages: {
        'ui:field': MultiSelect,
      },
      installationGroupId: {
        'ui:field': (fromProps: any) => (
          <DropDownWithCreate {...fromProps} onCreate={createInstallationGroup} />
        ),
      }
    },
  };

  if (!app) {
    return null;
  }

  return (
    <ContentWrap>
      <Row gutter={40}>
        <Col md={24} xl={16}>
          <PanelCard
            title={<Title level={3}>{t('appSettings')}</Title>}
            bodyStyle={{
              padding: 16,
            }}
          >
            <SchemaForm
              ref={formElement}
              uiSchema={uiSchema}
              data={formData}
              schema={formSchema}
              organisationId={organisationId}
              onSubmit={handleFormSubmit}
              onChange={onFormChange}
              onError={handleError}
              formContext={{ user }}
            />
            {isAllowed(permissionKeys.apps.remove) && (
              <ActionButton
                type="danger"
                size="large"
                icon="delete"
                onClick={handleInstallationDelete}
              >
                {t('deleteInstallation')}
              </ActionButton>
            )}
          </PanelCard>
        </Col>
        <Col md={24} xl={8}>
          <PanelCard bodyStyle={{ padding: 16 }}>
            <Paragraph>
              {`${t('lastModified')} ${moment(app.updatedAt).fromNow()}`}
            </Paragraph>
            {isAllowed(permissionKeys.apps.updateSettings) && (
              <Button
                loading={formLoading}
                size="large"
                block
                type="primary"
                onClick={handleSave}
                disabled={!formDirty}
              >
                {t('saveAllChanges')}
              </Button>
            )}
          </PanelCard>
        </Col>
      </Row>
    </ContentWrap>
  );
};

const ActionButton = styled(Button)`
  margin-top: 14px;
` as any;

export default Settings;
