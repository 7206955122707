import * as React from 'react';
import { useActions, useStore } from 'easy-peasy';
import Organisations from './organisations.component';
import { RootModel, RootState } from '../../store/models/root.model';

type OrganisationsContainerProps = Omit<
  React.ComponentProps<typeof Organisations>,
  'organisations' | 'setupTenantForExistingUser'
>;

const OrganisationsContainer = (props: OrganisationsContainerProps) => {
  const { organisations } = useStore<RootState>((state) => ({
    organisations: state.organisations.values,
  }));

  const setupTenantForExistingUser = useActions<RootModel>(
    (actions) => actions.onboarding.setupTenantForExistingUser,
  );

  return (
    <Organisations
      setupTenantForExistingUser={setupTenantForExistingUser}
      organisations={organisations}
      {...props}
    />
  );
};

export default OrganisationsContainer;
