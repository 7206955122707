import { PhyhubDevicesListQueryParams } from './use-phyhub-devices-list';

const accessTokensQueryKeys = {
  queryPrefix: () => {
    return 'phyhub-devices';
  },
  getPhyhubDevicesListKeys: (phyhubDevicesListParams: PhyhubDevicesListQueryParams) => [
    accessTokensQueryKeys.queryPrefix(),
    'page',
    phyhubDevicesListParams.page,
    'limit',
    phyhubDevicesListParams.limit,
  ],
};

export default accessTokensQueryKeys;
