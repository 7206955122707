import * as React from 'react';
import { useActions, useStore } from 'easy-peasy';
import MediaEditModal from './media-edit-modal.component';
import { RootModel, RootState } from '../../../../store/models/root.model';

type MediaEditModalContainerProps = Omit<
  React.ComponentProps<typeof MediaEditModal>,
  'mediaItem' | 'onSubmit'
> & { mediaItemId: string | null };

const MediaEditModalContainer = (props: MediaEditModalContainerProps) => {
  const { mediaItemId } = props;

  const mediaItem = useStore<RootState>(
    (state) =>
      mediaItemId
        ? state.organisationMedia.data && state.organisationMedia.data[mediaItemId]
        : null,
    [mediaItemId],
  );

  const updateMedia = useActions<RootModel>(
    (actions) => actions.organisationMedia.update,
  );

  return <MediaEditModal mediaItem={mediaItem} onSubmit={updateMedia} {...props} />;
};

export default MediaEditModalContainer;
