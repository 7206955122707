import React, { Suspense, useEffect, useCallback, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { i18n as i18nType } from 'i18next';
import LoginContainer from './auth/login/login.container';
import ProtectedRouteContainer from './common/protected-route/protected-route.container';
import OrganisationsContainer from './organisations/organisations.container';
import Spinner from './common/spinner/spinner.component';
import Overlay from './common/overlay/overlay.component';
import User from '../store/types/user';
import Grid from './grid/grid.component';
import Devices from './devices/devices.component';
import Browsers from './browsers/browsers.component';
import PermissionRules from '../store/types/permissions';
import { getPermissionPath, permissionKeys } from '../utils/auth/permissions';
import initI18n from '../i18n';
import SubscriptionFallback from './fallback-screens/subscription-fallback.component';
import TermsOfService from './terms-of-service/terms-of-service.component';
import Onboarding from './onboarding/onboarding.component';
import ErrorPage from './common/error-page/error-page.component';
import SalesTools from './sales/sales-tools.component';

interface AppProps {
  isReady: boolean;
  user: User | null;
  init: () => void;
  permissions: PermissionRules<boolean>;
}

const TeamsContainerLazy = React.lazy(() => import('./teams/teams.container'));

const App = ({ isReady, init, user }: AppProps) => {
  const [i18n, setI18n] = useState<i18nType | null>(null);
  const initLocales = useCallback(async () => {
    if (!i18n) {
      const supportedLocales = ['en', 'sv'];
      const i18nGlobalInstance = await initI18n(supportedLocales, 'en');
      setI18n(i18nGlobalInstance);
    }
  }, [i18n]);

  useEffect(() => {
    initLocales();
  }, [initLocales]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    if (
      user &&
      i18n &&
      user.preferredLanguage &&
      user.preferredLanguage !== i18n.language
    ) {
      i18n.changeLanguage(user.preferredLanguage);
    }
  }, [user, i18n]);

  // Google analytics
  useEffect(() => {
    if (user) {
      document.cookie = `userid=${encodeURIComponent(user.email)}; expires=0; path=/`;
    }
  }, [user]);

  if (!isReady || !i18n) {
    return (
      <Overlay>
        <Spinner />
      </Overlay>
    );
  }

  return (
    <Switch>
      {/* Set initial screen loginOnly check by default */}
      <ProtectedRouteContainer
        loginOnly
        exact
        path="/"
        render={() => (
          <Redirect
            to={user && user.isSysadmin ? '/grid/organisations' : '/organisations'}
          />
        )}
      />

      {/* Set Organisation screen loginOnly check by default */}
      <ProtectedRouteContainer
        loginOnly
        path="/organisations"
        component={OrganisationsContainer}
      />
      <ProtectedRouteContainer
        permissionPath={getPermissionPath('grid', permissionKeys.grid.viewRootGrid)}
        path="/grid"
        component={Grid}
      />
      <ProtectedRouteContainer
        permissionPath={getPermissionPath('grid', permissionKeys.grid.manageSales)}
        path="/sales"
        component={SalesTools}
      />
      <ProtectedRouteContainer
        permissionPath={getPermissionPath('general', permissionKeys.devices.viewAll)}
        path="/devices"
        component={Devices}
      />
      <ProtectedRouteContainer
        permissionPath={getPermissionPath('general', permissionKeys.browsers.viewAll)}
        path="/browsers"
        component={Browsers}
      />
      <Route
        path="/login"
        render={() => (user ? <Redirect to="/" /> : <LoginContainer />)}
      />
      <Route path="/subscription" render={() => <SubscriptionFallback />} />
      <Route path="/terms-of-service" render={() => <TermsOfService />} />
      <Route path="/onboarding" render={(props) => <Onboarding {...props} />} />
      <Route
        path="/subscription"
        render={() => (user ? <Redirect to="/" /> : <SubscriptionFallback />)}
      />
      <Route path="/error" render={() => <ErrorPage />} />
      <Suspense fallback={null}>
        <Route path="/teams" component={TeamsContainerLazy} />
      </Suspense>
    </Switch>
  );
};

export default App;
