import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Avatar, Divider, List } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps } from 'react-router-dom';
import CrudList from '../../../common/crud-list/crud-list.component';
import Header from '../../../common/app-layout/header/header.component';
import OrganisationApp from '../../../../store/types/organisation-app';
import DeviceTypeEnum from '../../../../store/types/device-type.enum';
import ArchitectureEnum from '../../../../store/types/architecture.enum';
import MultiSelect from '../../../common/schema-form/widgets/multi-select/multi-select.component';
import {
  getAllLocaleCodes,
  getLocaleCodeLabel,
} from '../../../../utils/language/language.util';

interface AppsListProps extends RouteComponentProps<{ organisationId: string }> {
  apps: OrganisationApp[];
  loaded: boolean;
  fetchApps: (params: { organizationId: string; silent: boolean }) => void;
  createApp: (App: Partial<OrganisationApp>) => Promise<void>;
  updateApp: (App: Partial<OrganisationApp>) => Promise<void>;
  deleteApp: (app: OrganisationApp) => Promise<void>;
  fetchReleases: (params: { deviceType?: string }) => void;
  fetchProviders: (params: { release: string }) => void;
  releases: string[];
  providers: { [release: string]: string[] };
  refreshApps: (params: { silent: boolean }) => void;
  canCreate: boolean;
  canUpdate: boolean;
  canDelete: boolean;
}

const appIcons: { [s: string]: string } = {
  [DeviceTypeEnum.IOT]: 'hdd',
  [DeviceTypeEnum.MOBILE_WPA]: 'mobile',
  [DeviceTypeEnum.BALENA]: 'code-sandbox',
  [DeviceTypeEnum.LINUX]: 'linux',
  [DeviceTypeEnum.WINDOWS]: 'windows',
  [DeviceTypeEnum.WPA]: 'desktop',
  [DeviceTypeEnum.CLOUD]: 'cloud',
};

const getAppData = (app: Partial<OrganisationApp>) => ({
  appName: app.appName,
  displayName: app.displayName,
  provider: app.provider,
  release: app.release,
  deviceType: app.deviceType,
  ...(app.architecture ? { architecture: app.architecture } : {}),
  multiLanguageSupport: !!app.multiLanguageSupport,
  defaultLanguage: app.defaultLanguage || '',
  supportedLanguages: app.supportedLanguages || [],
});

const AppsList = (props: AppsListProps) => {
  const {
    loaded,
    fetchApps,
    apps,
    createApp,
    updateApp,
    deleteApp,
    fetchReleases,
    fetchProviders,
    providers,
    releases,
    refreshApps,
    canCreate,
    canUpdate,
    canDelete,
    match: {
      params: { organisationId },
    },
  } = props;
  const { t } = useTranslation();

  const [currentFormValues, setCurrentFormValues] = useState<Partial<
    OrganisationApp
  > | null>(null);

  const currentDeviceType = currentFormValues ? currentFormValues.deviceType : null;
  const currentRelease = currentFormValues ? currentFormValues.release : null;
  const defaultLanguageOptions: string[] =
    currentFormValues &&
      currentFormValues &&
      currentFormValues.supportedLanguages &&
      currentFormValues.supportedLanguages.length
      ? currentFormValues.supportedLanguages
      : [];

  const localeCodeList = useMemo(() => getAllLocaleCodes(), []);

  useEffect(() => {
    fetchApps({ organizationId: organisationId, silent: true });
  }, [fetchApps, organisationId]);

  const handleAppDelete = useCallback(
    async (app: OrganisationApp) => {
      await deleteApp(app);
      refreshApps({ silent: true });
    },
    [deleteApp, refreshApps],
  );

  const handleAppEdit = useCallback(
    async (app: Partial<OrganisationApp>) => {
      await updateApp({
        ...getAppData(app),
        organizationId: organisationId,
        id: app.id,
      });
      // TODO: remove once apps models are refactored and merged with each other
      refreshApps({ silent: true });
    },
    [updateApp, refreshApps, organisationId],
  );

  const handleAppCreate = useCallback(
    async (app: Partial<OrganisationApp>) => {
      await createApp({
        ...getAppData(app),
        organizationId: organisationId,
      });
      // TODO: remove once apps models are refactored and merged with each other
      refreshApps({ silent: true });
    },
    [createApp, organisationId, refreshApps],
  );

  const renderAppsListItem = useCallback(
    (app: OrganisationApp) => (
      <List.Item.Meta
        avatar={<Avatar icon={appIcons[app.deviceType]} />}
        title={
          <Link to={`/organisations/${organisationId}/apps/${app.id}`}>
            {app.displayName}
          </Link>
        }
        description={
          <>
            {app.appName}
            <Divider type="vertical" />
            {app.deviceType}
            <Divider type="vertical" />
            {app.release}
            <Divider type="vertical" />
            {app.provider}
          </>
        }
      />
    ),
    [organisationId],
  );

  useEffect(() => {
    if (currentDeviceType) {
      fetchReleases({ deviceType: currentDeviceType });
    }
  }, [currentDeviceType, fetchReleases, fetchProviders]);

  useEffect(() => {
    if (currentRelease) {
      fetchProviders({ release: currentRelease });
    }
  }, [currentRelease, fetchProviders]);

  const handleAppFormChange = useCallback(
    (values: Partial<OrganisationApp>, rootFormChange: any) => {
      setCurrentFormValues((prevValues) => {
        let { release, provider } = values;
        if (
          values.deviceType &&
          prevValues &&
          prevValues.deviceType &&
          prevValues.deviceType !== values.deviceType &&
          prevValues.id === values.id
        ) {
          release = undefined;
          provider = undefined;
        }

        const newValues = { ...values, release, provider };
        if (rootFormChange) rootFormChange(newValues);
        return newValues;
      });
    },
    [],
  );

  const schema = useMemo(
    () => ({
      type: 'object',
      properties: {
        displayName: {
          type: 'string',
          minLength: 1,
          title: t('name'),
        },
        deviceType: {
          type: 'string',
          title: t('type'),
          enum: [
            DeviceTypeEnum.BALENA,
            DeviceTypeEnum.WINDOWS,
            DeviceTypeEnum.LINUX,
            DeviceTypeEnum.WPA,
            DeviceTypeEnum.MOBILE_WPA,
          ],
          enumNames: ['Classic', 'Windows', 'Linux', 'WPA', 'Mobile WPA'],
          default: DeviceTypeEnum.WPA,
        },
        release: {
          type: 'string',
          title: t('Release'),
          enum: releases,
        },
        provider: {
          type: 'string',
          title: t('provider'),
          enum:
            currentFormValues && currentFormValues.release
              ? providers[currentFormValues.release] || ['']
              : [''],
        },
        multiLanguageSupport: {
          type: 'boolean',
          title: t('multiLanguageSupport'),
          default: false,
        },
      },
      required: ['displayName', 'deviceType', 'provider', 'release'],
      dependencies: {
        multiLanguageSupport: {
          oneOf: [
            {
              properties: {
                multiLanguageSupport: {
                  enum: [true],
                },
                supportedLanguages: {
                  title: t('supportedLanguages'),
                  type: 'array',
                  uniqueItems: true,
                  minItems: 1,
                  items: {
                    type: 'string',
                    enum: localeCodeList.map((localeCode) => localeCode.key),
                    enumNames: localeCodeList.map((localeCode) => localeCode.label),
                  },
                },
                ...(defaultLanguageOptions.length
                  ? {
                    defaultLanguage: {
                      type: 'string',
                      title: t('defaultLanguage'),
                      enum: defaultLanguageOptions,
                      enumNames: defaultLanguageOptions.map((code) =>
                        getLocaleCodeLabel(code),
                      ),
                    },
                  }
                  : {}),
              },
            },
            {
              properties: {
                multiLanguageSupport: {
                  enum: [false],
                },
              },
            },
          ],
        },
        deviceType: {
          oneOf: [
            {
              properties: {
                deviceType: {
                  enum: [DeviceTypeEnum.WINDOWS],
                },
                architecture: {
                  type: 'string',
                  title: t('architecture'),
                  enum: Object.values(ArchitectureEnum),
                  default: ArchitectureEnum.X64,
                },
              },
            },
            {
              properties: {
                deviceType: {
                  enum: [
                    DeviceTypeEnum.LINUX,
                    DeviceTypeEnum.BALENA,
                    DeviceTypeEnum.WPA,
                    DeviceTypeEnum.MOBILE_WPA,
                  ],
                },
              },
            },
          ],
        },
      },
    }),
    [currentFormValues, defaultLanguageOptions, localeCodeList, providers, releases, t],
  );

  const uiSchema = {
    supportedLanguages: {
      'ui:field': MultiSelect,
    },
  };

  const checkIfAppIsCloudType = (app: Partial<OrganisationApp>) =>
    app.deviceType !== DeviceTypeEnum.CLOUD;

  return (
    <>
      <Header title={t('installations')} />
      <div className="content-body">
        <CrudList<OrganisationApp>
          onCreate={handleAppCreate}
          onEdit={handleAppEdit}
          onDelete={handleAppDelete}
          loaded={loaded}
          createSchema={schema}
          updateSchema={schema}
          metaSchema={uiSchema}
          renderItem={renderAppsListItem}
          dataSource={apps}
          createButtonText={t('addInstallation')}
          modalTitle={t('installation')}
          onFormChange={handleAppFormChange}
          canCreate={canCreate}
          canUpdate={canUpdate}
          canDelete={canDelete}
          externalFormChange
          conditionalCanUpdate={checkIfAppIsCloudType}
        />
      </div>
    </>
  );
};

export default AppsList;
