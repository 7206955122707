import { Button, Modal } from 'antd';
import React, { useEffect, useState, useCallback } from 'react';
import styled from '@emotion/styled';
import { FieldProps } from 'react-jsonschema-form';
import Media from '../../../../../store/types/media';
import MediaLibraryContainer from '../../../media-library/media-library.container';
import DataCard from '../../../data-card/data-card.component';
import { isMediaTemplate } from '../../../../../utils/media-templates';
import SchemaForm from '../../schema-form.component';
import validateForm from '../../../../../utils/validation/validate-form';
import MediaSchemaFormFieldInfo from '../../../media-library/media-schema-form-field-info/media-schema-form-field-info.component';
import MediaPreviewModal from '../../../media-library/media-preview/media-preview.component';

const MediaPickerWrapper = styled.div`
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 500px) {
    > button {
      width: 100%;
    }
  }
`;

const Label = styled.label`
  width: 100%;
`;

const MediaCard = styled(DataCard)`
  margin-bottom: 8px;
  cursor: pointer;
`;

interface MediaPickerProps extends FieldProps {
  mediaItems: Media[];
  onFolderSelect: (folderId: string | null) => void;
  onBackClick: (folderId: string | null) => void;
  selectedFolderId: string | null;
}

const getFileName = (fileName: string) => {
  const regex = /(-v[0-9a-zA-Z]+)?\.[0-9a-zA-Z]+$/;
  return fileName.replace(regex, '').trim();
};

const MediaPicker = (props: MediaPickerProps) => {
  const {
    formContext,
    formData,
    onChange,
    mediaItems,
    schema,
    errorSchema,
    onFolderSelect,
    selectedFolderId,
    onBackClick,
    disabled,
  } = props;
  const [isMediaModalVisible, setIsMediaModalVisible] = useState<boolean>(false);
  const hasError = !!errorSchema && !!Object.keys(errorSchema).length;

  const [isMediaPreviewModalVisible, setIsMediaPreviewModalVisible] = useState(false);

  const mediaItem = formData
    ? mediaItems.find((item) => item.id === formData.id)
    : undefined;

  const handleChange = (item: Media) => {
    let settings: any;

    if (mediaItem && getFileName(item.name) === getFileName(mediaItem.name) && formData) {
      settings = formData.settings || {};
    }

    onChange({
      ref: 'media',
      id: item.id,
      type: item.type,
      url: item.url,
      name: item.name,
      ...(settings ? { settings } : {}),
    });

    setIsMediaModalVisible(false);
  };

  const handleSettingsChange = (settings: any) => {
    // TODO: we should support default values for media template forms
    // TODO: consider extracting media template logic to whole new widget and create new media type (object type with media, settings and valid fields)
    if (mediaItem) {
      const valid = !Object.keys(
        validateForm(settings, mediaItem.metadata && mediaItem.metadata.schema),
      ).length;

      onChange({
        ...formData,
        settings,
        valid,
      });
    }
  };

  useEffect(() => {
    if (!mediaItem && formData && formData.id) {
      // media item is no longer available - clear the value
      onChange(undefined);
    }
  }, [mediaItem, formData, onChange]);

  const mediaTypes =
    schema.properties && schema.properties.type
      ? (schema.properties.type as any).enum
      : undefined;

  const onPickAssetClicked = useCallback(() => {
    setIsMediaModalVisible(true);
  }, [setIsMediaModalVisible]);

  const handleModalCancel = useCallback(() => {
    setIsMediaModalVisible(false);
  }, [setIsMediaModalVisible]);

  const handleOpenMediaPreviewModal = useCallback(() => {
    setIsMediaPreviewModalVisible(true);
  }, []);

  const handleCloseMediaPreviewModal = useCallback(() => {
    setIsMediaPreviewModalVisible(false);
  }, []);

  return (
    <MediaPickerWrapper>
      {schema.title && <Label>{schema.title}</Label>}
      {mediaItem && (
        <MediaCard bodyStyle={{ padding: 8 }} onClick={handleOpenMediaPreviewModal}>
          <MediaSchemaFormFieldInfo mediaItem={mediaItem} />
          {isMediaTemplate(mediaItem) &&
            mediaItem.metadata &&
            mediaItem.metadata.schema && (
              <SchemaForm
                onChange={handleSettingsChange}
                tagName="div"
                schema={mediaItem.metadata.schema}
                uiSchema={mediaItem.metadata.metaSchema || undefined}
                data={formData.settings || {}}
                organisationId={formContext.organisationId}
              />
            )}
        </MediaCard>
      )}
      {!disabled && (
        <Button
          type={hasError ? 'danger' : 'default'}
          onClick={onPickAssetClicked}
          size="large"
          icon="picture"
        >
          Pick an asset
        </Button>
      )}

      {mediaItem && isMediaPreviewModalVisible &&
        <MediaPreviewModal
          mediaItem={mediaItem}
          onClose={handleCloseMediaPreviewModal}
        />
      }

      {/*
      TODO: move this modal outside of this component
*/}
      <Modal
        centered
        footer={null}
        visible={isMediaModalVisible}
        bodyStyle={{ padding: 0 }}
        width="80%"
        onCancel={handleModalCancel}
      >
        {/* to re-render MediaLibraryContainer with initial values */}
        {isMediaModalVisible && (
          <MediaLibraryContainer
            mediaTypesFilter={mediaTypes}
            onSelectMediaItem={handleChange}
            organisationId={formContext.organisationId}
            onSelectedFolder={onFolderSelect}
            selectedFolderId={selectedFolderId}
            onBackClick={onBackClick}
          />
        )}
      </Modal>
    </MediaPickerWrapper>
  );
};

export default MediaPicker;
