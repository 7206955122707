import createKeyedCrudModel, {
  KeyedCrudModel,
} from '../../common/keyed-crud-model/keyed-crud.model';
import OrganisationApp from '../../types/organisation-app';

export type AppsModel = KeyedCrudModel<OrganisationApp, { organizationId: string }>;

const appsModel: AppsModel = createKeyedCrudModel<
  OrganisationApp,
  { organizationId: string }
>('/api/apps', 'organizationId');

export default appsModel;
