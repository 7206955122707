import {
  AnalyticsSchema,
  CardType,
  SessionInteractionType,
  WeekHeatmapDataSourceType,
} from '@ombori/grid-reports';

enum SkuType {
  QUEUE_PEOPLE_COUNTER = 'GAC001',
  QUEUE_OCCUPANCY_CONTROL = 'GAC002',
  QUEUE_OMNI_VISIT = 'GAC003',
  QUEUE_QUEUE_MANAGEMENT = 'GAC004',
  IKEA_KSA_LEGACY_QUEUE_QUEUE_MANAGEMENT = 'ikea-ksa-legacy-queue',
  QUEUE_QUEUE_MANAGEMENT_ADVANCED = 'GAC005',
  QUEUE_APPOINTMENT_BOOKING = 'GAC006',
  QUEUE_ORDER_PICKUP_BOPAC = 'GAC007',
  QUEUE_QUEUE_MANAGEMENT_WITH_BAMBUSER = 'GAC008',
  QUEUE_TASKS = 'GAC010',
}

type AnalyticsSchemaMap = { [key in SkuType]: AnalyticsSchema };

const analyticsSchemaMap: AnalyticsSchemaMap = {
  [SkuType.QUEUE_PEOPLE_COUNTER]: {
    groups: [
      {
        name: 'Overview',
        cards: [
          {
            type: CardType.Sessions,
            interactionType: SessionInteractionType.Interactive,
          },
          { type: CardType.Nps },
        ],
      },
      {
        name: 'Sessions week heatmap',
        cards: [
          {
            type: CardType.WeekHeatmap,
            title: 'Sessions week heatmap',
            dataSource: {
              type: WeekHeatmapDataSourceType.Sessions,
              interactionType: SessionInteractionType.Interactive,
            },
          },
        ],
        columnsCount: 1,
      },
    ],
  },
  [SkuType.QUEUE_OCCUPANCY_CONTROL]: {
    groups: [
      {
        name: 'Overview',
        cards: [
          {
            type: CardType.Sessions,
            interactionType: SessionInteractionType.Interactive,
          },
          { type: CardType.Nps },
        ],
      },
      {
        name: 'Sessions week heatmap',
        cards: [
          {
            type: CardType.WeekHeatmap,
            title: 'Sessions week heatmap',
            dataSource: {
              type: WeekHeatmapDataSourceType.Sessions,
              interactionType: SessionInteractionType.Interactive,
            },
          },
        ],
        columnsCount: 1,
      },
    ],
  },
  [SkuType.QUEUE_OMNI_VISIT]: {
    groups: [
      {
        name: 'Overview',
        cards: [
          {
            type: CardType.Sessions,
            interactionType: SessionInteractionType.Interactive,
          },
          { type: CardType.Nps },
        ],
      },
      {
        name: 'Sessions week heatmap',
        cards: [
          {
            type: CardType.WeekHeatmap,
            title: 'Sessions week heatmap',
            dataSource: {
              type: WeekHeatmapDataSourceType.Sessions,
              interactionType: SessionInteractionType.Interactive,
            },
          },
        ],
        columnsCount: 1,
      },
    ],
  },
  [SkuType.QUEUE_QUEUE_MANAGEMENT]: {
    groups: [
      {
        name: 'Overview',
        cards: [
          {
            type: CardType.Sessions,
            interactionType: SessionInteractionType.Interactive,
          },
          { type: CardType.Nps },
        ],
      },
      {
        name: 'Sessions week heatmap',
        cards: [
          {
            type: CardType.WeekHeatmap,
            title: 'Sessions week heatmap',
            dataSource: {
              type: WeekHeatmapDataSourceType.Sessions,
              interactionType: SessionInteractionType.Interactive,
            },
          },
        ],
        columnsCount: 1,
      },
    ],
  },
  [SkuType.IKEA_KSA_LEGACY_QUEUE_QUEUE_MANAGEMENT]: {
    groups: [
      {
        name: 'Overview',
        cards: [
          {
            type: CardType.Sessions,
            interactionType: SessionInteractionType.Interactive,
          },
          { type: CardType.Nps },
        ],
      },
      {
        name: 'Sessions week heatmap',
        cards: [
          {
            type: CardType.WeekHeatmap,
            title: 'Sessions week heatmap',
            dataSource: {
              type: WeekHeatmapDataSourceType.Sessions,
              interactionType: SessionInteractionType.Interactive,
            },
          },
        ],
        columnsCount: 1,
      },
    ],
  },
  [SkuType.QUEUE_QUEUE_MANAGEMENT_ADVANCED]: {
    groups: [
      {
        name: 'Overview',
        cards: [
          {
            type: CardType.Sessions,
            interactionType: SessionInteractionType.Interactive,
          },
          { type: CardType.Nps },
        ],
      },
      {
        name: 'Sessions week heatmap',
        cards: [
          {
            type: CardType.WeekHeatmap,
            title: 'Sessions week heatmap',
            dataSource: {
              type: WeekHeatmapDataSourceType.Sessions,
              interactionType: SessionInteractionType.Interactive,
            },
          },
        ],
        columnsCount: 1,
      },
    ],
  },
  [SkuType.QUEUE_APPOINTMENT_BOOKING]: {
    groups: [
      {
        name: 'Overview',
        cards: [
          {
            type: CardType.Sessions,
            interactionType: SessionInteractionType.Interactive,
          },
          { type: CardType.Nps },
        ],
      },
      {
        name: 'Sessions week heatmap',
        cards: [
          {
            type: CardType.WeekHeatmap,
            title: 'Sessions week heatmap',
            dataSource: {
              type: WeekHeatmapDataSourceType.Sessions,
              interactionType: SessionInteractionType.Interactive,
            },
          },
        ],
        columnsCount: 1,
      },
    ],
  },
  [SkuType.QUEUE_ORDER_PICKUP_BOPAC]: {
    groups: [
      {
        name: 'Overview',
        cards: [
          {
            type: CardType.Sessions,
            interactionType: SessionInteractionType.Interactive,
          },
          { type: CardType.Nps },
        ],
      },
      {
        name: 'Sessions week heatmap',
        cards: [
          {
            type: CardType.WeekHeatmap,
            title: 'Sessions week heatmap',
            dataSource: {
              type: WeekHeatmapDataSourceType.Sessions,
              interactionType: SessionInteractionType.Interactive,
            },
          },
        ],
        columnsCount: 1,
      },
    ],
  },
  [SkuType.QUEUE_QUEUE_MANAGEMENT_WITH_BAMBUSER]: {
    groups: [
      {
        name: 'Overview',
        cards: [
          {
            type: CardType.Sessions,
            interactionType: SessionInteractionType.Interactive,
          },
          { type: CardType.Nps },
        ],
      },
      {
        name: 'Sessions week heatmap',
        cards: [
          {
            type: CardType.WeekHeatmap,
            title: 'Sessions week heatmap',
            dataSource: {
              type: WeekHeatmapDataSourceType.Sessions,
              interactionType: SessionInteractionType.Interactive,
            },
          },
        ],
        columnsCount: 1,
      },
    ],
  },
  [SkuType.QUEUE_TASKS]: {
    groups: [
      {
        name: 'Overview',
        cards: [
          {
            type: CardType.Sessions,
            interactionType: SessionInteractionType.Interactive,
          },
          { type: CardType.Nps },
        ],
      },
      {
        name: 'Sessions week heatmap',
        cards: [
          {
            type: CardType.WeekHeatmap,
            title: 'Sessions week heatmap',
            dataSource: {
              type: WeekHeatmapDataSourceType.Sessions,
              interactionType: SessionInteractionType.Interactive,
            },
          },
        ],
        columnsCount: 1,
      },
    ],
  },
};

const getAnalyticsSchemaBySku = (sku: string): AnalyticsSchema | undefined => {
  const isValidSku = Object.values(SkuType).includes(sku);

  if (!isValidSku) {
    return undefined;
  }

  return analyticsSchemaMap[sku as SkuType];
};

export default getAnalyticsSchemaBySku;
