import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { useApiService } from '../api-service-provider';
import { buildQueryKey } from './use-device-components';

interface BundleComponentParam {
  component: string;
  key: string;
  version: string;
}

export interface UseDeviceBundleUpdateProps {
  uuid: string;
  bundleVersion: string;
  bundleComponents: BundleComponentParam[];
}

const useDeviceBundleUpdate = (): UseMutationResult<
  unknown,
  unknown,
  UseDeviceBundleUpdateProps
> => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  return useMutation(
    async (props: UseDeviceBundleUpdateProps) => {
      const { uuid, bundleVersion, bundleComponents } = props;
      const endpoint = `/api/v4/devices/${uuid}/update-bundle-components`;

      return apiService.put(endpoint, {
        bundleVersion,
        bundleComponents,
      });
    },
    {
      onSuccess: (_data, props) => {
        const key = buildQueryKey(props.uuid);

        queryClient.invalidateQueries(key);
      },
    },
  );
};

export default useDeviceBundleUpdate;
