import { useQuery } from 'react-query';
import { useApiService } from '../../../app/common/api-service-provider';
import { PaginationCollection } from '../../types/pagination';
import organizationUsersQueryKeys from './use-organization-users-query-keys';
import TenantUser from '../../types/tenant-user';

export interface OrganizationUsersListQueryInterface {
  page: number;
  limit: number;
  sortField: string;
  sortOrder: string;
  searchTerm: string;
  organizationId: string;
}

export const useOrganizationUsersList = (organizationUsersListParams: OrganizationUsersListQueryInterface) => {
  const apiService = useApiService();
  const { page, limit, sortField, sortOrder, searchTerm, organizationId } = organizationUsersListParams;

  return useQuery(
    organizationUsersQueryKeys.getOrganizationUsersListKeys(organizationUsersListParams),
    () =>
      apiService.get<PaginationCollection<TenantUser>>(`/api/organizations/${organizationId}/users`, {
        page,
        limit,
        sortField,
        sortOrder,
        searchTerm,
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export const useOrganizationUserById = ({ userId, organisationId }: { userId: string, organisationId: string }) => {
  const apiService = useApiService();

  const url = `/api/organizations/${organisationId}/users/${userId}`;

  return useQuery(
    organizationUsersQueryKeys.getOrganizationUserById(userId, organisationId),
    () => apiService.get<TenantUser>(url),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      enabled: !!userId,
    },
  );
};