import { TFunction } from 'i18next';

type BuildSchemaResponse = Record<string, string | number | boolean | object>;

export const buildFieldSchema = (t: TFunction): BuildSchemaResponse => {
  const fieldSchema = {
    type: 'object',
    properties: {
      socialMedia: {
        type: 'object',
        title: t('brandingSocialMedia.socialMediaTitle'),
        properties: {
          facebook: {
            type: 'string',
            title: t('brandingSocialMedia.facebook'),
            maxLength: 100,
          },
          instagram: {
            type: 'string',
            title: t('brandingSocialMedia.instagram'),
            maxLength: 100,
          },
          linkedIn: {
            type: 'string',
            title: t('brandingSocialMedia.linkedIn'),
            maxLength: 100,
          },
          twitter: {
            type: 'string',
            title: t('brandingSocialMedia.twitter'),
            maxLength: 100,
          },
          website: {
            type: 'string',
            title: t('brandingSocialMedia.website'),
            maxLength: 100,
          },
        }
      },
    },
  };

  return fieldSchema;
};

export const buildUiSchema = (t: TFunction): BuildSchemaResponse => {
  const metaSchema = {
    objectFieldTemplate: 'main-section',
    socialMedia: {
      objectFieldTemplate: 'accordion',
      'ui:options': {
        isContentPadded: false,
        hasDivider: false,
        gap: 16,
      },
      facebook: {
        'ui:field': 'input',
      },
      instagram: {
        'ui:field': 'input',
      },
      linkedIn: {
        'ui:field': 'input',
      },
      twitter: {
        'ui:field': 'input',
      },
      website: {
        'ui:field': 'input',
      },
    },
  };

  return metaSchema;
};
