import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Row, Col, Button, Icon, Checkbox, Dropdown, Menu } from 'antd';
import { ClickParam } from 'antd/lib/menu';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useHistory } from 'react-router';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import OrganisationApp from '../../../../../../store/types/organisation-app';
import { permissionKeys } from '../../../../../../utils/auth/permissions';
import HardDriveIcon from '../../../../../../assets/images/hard-drive.svg';
import Environment from '../../../../../../store/types/environment';
import useHasPermissions from '../../../../../../utils/auth/use-has-permissions';

interface SettingsConfigurationProps {
  title: string;
  subTitle: string;
  icon?: string;
  app: OrganisationApp;
  organisationId: string;
  children?: React.ReactNode;
  onSave: (autoDeployEnvironment: string) => void;
  isSaving?: boolean;
  isSaveButtonDisabled?: boolean;
  errorMessage?: string;
  environments: Environment[];
}

const SettingsConfiguration = ({
  icon,
  title,
  subTitle,
  app,
  organisationId,
  children,
  onSave,
  isSaving = false,
  isSaveButtonDisabled,
  errorMessage,
  environments,
}: SettingsConfigurationProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [autoDeployEnv, setAutodeployEnv] = useState('');
  const [enableAutoDeploy, setEnableAutoDeploy] = useState(true);

  const isEditAllowed = useHasPermissions(organisationId, permissionKeys.apps.update);

  useEffect(() => {
    const defaultEnv = environments.find(env => env.environmentName === 'prod');
    const fallbackEnv = environments[0];

    if (defaultEnv || fallbackEnv) {
      setAutodeployEnv(defaultEnv ? defaultEnv.environmentName : fallbackEnv.environmentName);
    }
  }, [environments]);
  // TBD: behavior for search
  // const handleSearch = useCallback((): void => {}, []);

  const handleMenuClick = useCallback((e: ClickParam) => {
    setAutodeployEnv(e.key);
  }, []);

  const handleAutoDeployOptionChange = useCallback((ev: CheckboxChangeEvent) => {
    setEnableAutoDeploy(ev.target.checked);
  }, []);

  const handleBackButton = useCallback(
    () => history.push(`/organisations/${organisationId}/apps/${app.id}/settings-v2`),
    [history, organisationId, app],
  );

  const isHardDriveIcon = icon === 'hardDrive';

  const envOptions = useMemo(() => {
    return (
      <Menu onClick={handleMenuClick}>
        {environments.map((env) => (
          <Menu.Item key={env.environmentName}>
            {env.displayName}
          </Menu.Item>
        ))}
      </Menu>
    );
  }, [environments, handleMenuClick]);

  const currentEnvName = useMemo(() => {
    const currentEnv = environments.find(env => env.environmentName === autoDeployEnv);
    return currentEnv ? currentEnv.displayName : '';
  }, [environments, autoDeployEnv]);

  return (
    <>
      <HeaderTop>
        <Row type="flex" align="middle" justify="start">
          <Col>
            <HeaderIconContainer>
              {!isHardDriveIcon && <Icon type={icon} />}
              {isHardDriveIcon && <HardDriveImage src={HardDriveIcon} />}
            </HeaderIconContainer>
          </Col>

          <Col span={18}>
            <HeaderTitleContainer>
              <HeaderSubTitleText>{subTitle}</HeaderSubTitleText>
              <HeaderTitleText>{title}</HeaderTitleText>
            </HeaderTitleContainer>
          </Col>
        </Row>
      </HeaderTop>

      <HeaderBottom>
        <HeaderBottomWrapper>
          <StyledBackButton
            size="large"
            block
            type="default"
            icon="arrow-left"
            onClick={handleBackButton}
          >
            {t('settingsConfigurationForm.back')}
          </StyledBackButton>
          {/* <SearchBar>
            <SearchInput
              placeholder={t('settingsConfigurationForm.findSomething')}
              onSearch={handleSearch}
            />
          </SearchBar> */}
          {isEditAllowed && (
            <>
              <AutoDeployWrapper>
                <Checkbox checked={enableAutoDeploy} onChange={handleAutoDeployOptionChange}>Auto Deploy</Checkbox>
                {enableAutoDeploy ? (
                  <Dropdown.Button overlay={envOptions} placement="bottomLeft">
                    {currentEnvName || 'Select env'}
                  </Dropdown.Button>
                ) : null}
              </AutoDeployWrapper>
              <StyledSaveButton
                size="large"
                block
                type="primary"
                onClick={() => onSave(enableAutoDeploy && autoDeployEnv ? autoDeployEnv : '')}
                disabled={isSaving || isSaveButtonDisabled}
                loading={isSaving}
              >
                {t('settingsConfigurationForm.saveAllChanges')}
              </StyledSaveButton>
            </>
          )}

        </HeaderBottomWrapper>
        {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </HeaderBottom>
      <Content>{children}</Content>
    </>
  );
};

const HeaderBottom = styled.div`
  position: sticky;
  top: 1px;
  padding: 8px 32px;
  background-color: #fbfbfd;
  z-index: 100;
`;

const HeaderBottomWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const ErrorMessage = styled.div`
  padding: 15px 0;
  color: rgb(255, 85, 0);
`;

const Content = styled.div`
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 1200px) {
    padding: 40px;
  }
`;

// const SearchBar = styled.div`
//   display: flex;
//   flex: 1;
//   margin: 0 0 0 10px;

//   @media (max-width: 768px) {
//     display: none;
//   }
// `;

const StyledBackButton = styled(Button)`
  width: auto;
  margin: 0;
  text-transform: capitalize;
` as any;

const StyledSaveButton = styled(Button)`
  width: auto;
  margin: 0 0 0 10px;
  text-transform: capitalize;
` as any;

const HeaderTop = styled.div`
  padding: 12px 32px 10px;
  border-bottom: 1px solid #f2f2fa;
  background-color: #fbfbfd;
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderTitleText = styled.div`
  font-weight: bold;
  font-size: 24px;
`;

const HeaderSubTitleText = styled.div`
  margin-bottom: 1px;
  font-weight: normal;
  font-size: 14px;
  color: #676973;
`;

const HeaderIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  margin-right: 10px;
  color: #fff;
  border-radius: 8px;
  background-color: #202020;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const HardDriveImage = styled.img`
  width: 32px;
  height: 32px;
  filter: invert(1);
`;

const AutoDeployWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
`;

export default SettingsConfiguration;
