import React, { useCallback, useEffect, useRef } from 'react';
import { get } from 'lodash';
import styled from '@emotion/styled';
import { RouteComponentProps } from 'react-router';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import AppLayout from '../../common/app-layout/app-layout.component';
import Header from '../../common/app-layout/header/header.component';
import SchemaForm, {
  SchemaFormRef,
} from '../../common/schema-form/schema-form.component';
import useSchemaForm from '../../common/use-schema-form/use-schema-form';
import Environment from '../../../store/types/environment';
import { DeviceCreateParams } from '../../../store/models/app-devices/app-devices.model';
import Organisation from '../../../store/types/organisation';
import OrganisationApp from '../../../store/types/organisation-app';

interface DeviceCreateProps extends RouteComponentProps {
  environments: any;
  apps: any;
  organisations: Organisation[];
  createDevice: (params: DeviceCreateParams) => Promise<string>;
  fetchEnvironments: (params: { organizationId: string }) => void;
  fetchApps: (params: { organizationId: string }) => void;
}

const DeviceAddContent = styled.div`
  position: relative;
  flex: 1;
  padding: 16px 16px 40px 16px;

  @media (min-width: 768px) {
    padding: 40px;
  }
`;

const DeviceAddFormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  margin: 0 -16px;
  background-color: rgb(251, 251, 253);
`;

const DeviceCreate = (props: DeviceCreateProps) => {
  const {
    location,
    fetchApps,
    organisations,
    fetchEnvironments,
    apps,
    environments,
    createDevice,
    history,
  } = props;
  const { t } = useTranslation();
  const formElement = useRef<SchemaFormRef>(null);

  const handleSubmit = useCallback(() => {
    if (formElement.current) {
      formElement.current.submit();
    }
  }, [formElement]);

  const onSubmit = async (formData: any) => {
    // TODO: consider moving this to thunk?
    const deviceId = await createDevice({
      appName: formData.appName,
      deviceName: formData.deviceName,
      deviceSerial: formData.deviceSerial,
      env: formData.env,
      type: formData.type,
    });
    history.push(`/organisations/${formData.organisation}/devices/v2/${deviceId}`);
  };

  const [
    formData,
    formLoading,
    ,
    formInit,
    handleFormChange,
    handleFormSubmit,
  ] = useSchemaForm<any>(onSubmit as any, t('deviceSaved'), t('deviceSaveError'));

  const { organisation: organisationId } = formData;

  const organisationApps: OrganisationApp[] = organisationId
    ? Object.values(get(apps, organisationId, {}))
    : [];
  const organisationEnvironments: Environment[] = organisationId
    ? Object.values(get(environments, organisationId, {}))
    : [];

  const formSchema = {
    type: 'object',
    properties: {
      deviceName: {
        type: 'string',
        title: t('name'),
        minLength: 1,
        description: t('onlyNumbersLowerCaseAndDashAllowed'),
        pattern: '^[a-z0-9-]*$',
      },
      deviceSerial: {
        type: 'string',
        title: t('serialNumber'),
        minLength: 1,
      },
      organisation: {
        type: 'string',
        title: t('organization'),
        enum: organisations.map((organisation) => organisation.id),
        enumNames: organisations.map((organisation) => organisation.displayName),
      },
      appName: {
        type: 'string',
        title: t('application'),
        enum: organisationApps.map((app) => app.appName),
        enumNames: organisationApps.map((app) => app.displayName),
        readOnly: organisationApps.length < 1,
      },
      env: {
        type: 'string',
        title: t('environment'),
        enum: organisationEnvironments.map((env) => env.environmentName),
        enumNames: organisationEnvironments.map((env) => env.displayName),
        readOnly: organisationEnvironments.length < 1,
        default: 'prod',
      },
      type: {
        type: 'string',
        title: t('type'),
        enum: ['iotedge', 'iot'],
        enumNames: ['Grid-OS', 'Windows IoT'],
        default: 'iotedge',
      },
    },
    required: ['deviceName', 'deviceSerial', 'env', 'organisation', 'appName', 'type'],
  };

  useEffect(() => {
    if (organisationId) {
      fetchApps({ organizationId: organisationId });
      fetchEnvironments({ organizationId: organisationId });
    }
  }, [organisationId, fetchApps, fetchEnvironments]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    formInit({
      deviceName: '',
      deviceSerial: params.get('deviceSerial') || '',
      type: params.get('type') || 'iotedge',
    });
  }, [formInit, location.search]);

  return (
    <AppLayout>
      <AppLayout.Content>
        <Header title={t('addDevice')} />
        <DeviceAddContent>
          <SchemaForm
            schema={formSchema}
            onSubmit={handleFormSubmit}
            onChange={handleFormChange}
            ref={formElement}
            data={formData}
            liveValidate={false}
          />
          <DeviceAddFormFooter>
            <Button
              size="large"
              type="primary"
              loading={formLoading}
              onClick={handleSubmit}
            >
              {t('addDevice')}
            </Button>
          </DeviceAddFormFooter>
        </DeviceAddContent>
      </AppLayout.Content>
    </AppLayout>
  );
};

export default DeviceCreate;
