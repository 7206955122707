export type FeatureListOptions = 'OCCUPANCY' | 'QUEUE' | 'BOOKINGS' | 'CURBSIDEPICKUP';

export enum DayOfWeek {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
}

export interface Date {
  year: number;
  month: number;
  day: number;
}

export interface OpeningHoursPeriod {
  /** Indicates the day of the week this period starts on. */
  openDay: DayOfWeek;
  /** time in 24hr ISO 8601 extended format (hh:mm).
   * Valid values are 00:00-24:00, where 24:00 represents midnight at the end of the specified day field.
   * */
  openTime: string;
  /** Indicates the day of the week this period ends on. */
  closeDay: DayOfWeek;
  /**
   * time in 24hr ISO 8601 extended format (hh:mm).
   * Valid values are 00:00-24:00, where 24:00 represents midnight at the end of the specified day field.
   */
  closeTime: string;
  capacity?: number;
}

export interface SpecialHoursPeriod extends OpeningHoursPeriod {
  /** Date in ISO format */
  date: string;
  /** Specify if date is closed or not. Workaround - specify capacity to 0 */
  isClosed?: boolean;
  /** For future imporvements - you can specify recurrency for some cases */
  recurrency?: any;
}

export interface TextInAppCustomizationTextsForLanguage {
  [key: string]: string | undefined;
}

export interface Tag {
  id: string;
  iconUrl?: string;
  title: {
    [lng: string]: string;
  };
}

export type CustomizableFieldType = 'text' | 'checkbox' | 'input';

export interface CustomizableField {
  key: string;
  type: CustomizableFieldType;
  data?: {
    [lng: string]: {
      value?: any;
      description?: any;
      conditions?: any[];
      icon?: string;
      required?: boolean;
    };
  };
}

export enum BookingsModeType {
  DEFAULT = 'default',
  AUTO_CALL = 'autoCall',
  AUTO_CHECKIN = 'autoCheckin',
}

export enum DigitalCallQueuesType {
  DEFAULT = 'default',
  GLOBAL = 'global',
}

export interface Station {
  id: string;
  label: string;
}

export interface Feedback {
  isActive: boolean;
}

export interface StaffNotifications {
  isEnabled: boolean;
  emailList?: string[];
  thresholdWaitingTime?: number | null;
  thresholdPeopleCount?: number | null;
  sendInterval?: number | null;
}

export enum Languages {
  en = 'en',
  ar = 'ar',
  sv = 'sv',
}

export type LocalizedField = { [lng in Languages]?: string };

export const buildLocalizedField = (
  props: Partial<LocalizedField> = {},
): LocalizedField => {
  const { en = '', ar = '', sv = '' } = props;

  const result = { en, ar, sv };

  return result;
};

export interface ValidationRules {
  required?: boolean;
  regexp?: string;
  phone?: boolean;
  email?: boolean;
}

export enum RegistrationFieldType {
  TEXTAREA = 'textarea',
  INPUT = 'input',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  PHONE = 'phone',
  EMAIL = 'email',
  CATEGORY = 'category',
}

export interface BaseRegistrationField {
  id: string;
  name: string;
  type: RegistrationFieldType;
  value?: string;
  validationRules: ValidationRules;
}

export interface RegistrationFieldInput extends BaseRegistrationField {
  type: RegistrationFieldType.INPUT;
  label?: LocalizedField;
  placeholder?: LocalizedField;
}

export interface RegistrationFieldTextarea extends BaseRegistrationField {
  type: RegistrationFieldType.TEXTAREA;
  label: LocalizedField;
}

export interface RegistrationFieldCheckbox extends BaseRegistrationField {
  type: RegistrationFieldType.CHECKBOX;
  checked: boolean;
  label: LocalizedField;
}

export interface RegistrationFieldRadioOption {
  id: string;
  label: LocalizedField;
  value: string;
}

export interface RegistrationFieldRadio extends BaseRegistrationField {
  type: RegistrationFieldType.RADIO;
  label: LocalizedField;
  options: RegistrationFieldRadioOption[];
}

export interface RegistrationFieldPhone extends BaseRegistrationField {
  type: RegistrationFieldType.PHONE;
  label?: LocalizedField;
  placeholder?: LocalizedField;
}

export interface RegistrationFieldEmail extends BaseRegistrationField {
  type: RegistrationFieldType.EMAIL;
  label?: LocalizedField;
  placeholder?: LocalizedField;
}

export interface RegistrationFieldCategoryOption {
  id: string;
  label: LocalizedField;
  value: string;
}

export interface RegistrationFieldCategory extends BaseRegistrationField {
  type: RegistrationFieldType.CATEGORY;
  label?: LocalizedField;
  placeholder?: LocalizedField;
  options: RegistrationFieldCategoryOption[];
}

export type RegistrationField =
  | RegistrationFieldInput
  | RegistrationFieldTextarea
  | RegistrationFieldCheckbox
  | RegistrationFieldRadio
  | RegistrationFieldPhone
  | RegistrationFieldEmail
  | RegistrationFieldCategory;

interface BaseFlowModifiers {
  cancellation?: {
    enabled?: boolean;
  };
  rescheduling?: {
    enabled?: boolean;
  };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AnonymousFlowModifiers extends BaseFlowModifiers {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface BookingFlowModifiers extends BaseFlowModifiers {}

export interface Booking {
  physicalBooking: {
    enabled: boolean;
  };
  digitalBookingWithMsTeams?: {
    enabled: boolean;
  };
  isBookingSmsRequired?: boolean; // Deprecated and should not be used. We have this functionality in booking registration fields.
  isBookingEmailRequired?: boolean; // Deprecated and should not be used. We have this functionality in booking registration fields.
  registrationFields?: RegistrationField[];
  blockedDaysAhead?: number | null;
  bookingDaysCount?: number;
}

export interface TicketFlowModifiers {
  anonymous?: AnonymousFlowModifiers;
  booking?: BookingFlowModifiers;
}

interface RegularPeriods {
  periods: OpeningHoursPeriod[];
}

interface SpecialPeriods {
  periods: SpecialHoursPeriod[];
}

export enum QueueStatus {
  Active = 'active',
  Removed = 'removed',
}

export interface Category {
  id: string;
  title: LocalizedField;
  labelPrefix: string;
  color: string;
  isRoot: boolean;
  parentId: string | null;
}

export enum DigitalCallType {
  DEFAULT = 'default',
  GLOBAL = 'global',
}
export interface DigitalCall {
  isEnabled: boolean;
  globalQueueId?: string;
  apiToken?: string;
  customerUrlTemplate?: string;
  agentBaseUrl?: string;
  routingTimeout?: number | null;
  queueType: DigitalCallType;
}

export interface QueueGridAppsProperties {
  appId: string;
  appVersion: string;
  installationId: string;
  installationVersion: string;
  spaceId: string;
  tenantId: string;
  dataResidency: string;
  country: string;
  environment: string;
  sku: string;
}

export default interface Queue {
  id: string;
  status?: QueueStatus;
  featureList: FeatureListOptions[];
  organization: string;
  organizationId: string;
  location?: string;
  timeslotDuration?: number;
  timeslotCapacity?: number;
  title: string;
  displayTitle?: LocalizedField;
  description: string;
  pin: string;
  shortDisplayName?: string;
  open: boolean;
  region: string;
  locale?: string;
  /**
   * The IANA time zone of the queue.
   * See https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
   * @example "Europe/Stockholm"
   */
  timeZone: string;
  regularHours: RegularPeriods;
  specialHours?: SpecialPeriods;
  bookingHours?: RegularPeriods | null;
  specialBookingHours?: SpecialPeriods;
  curbsidePickupHours?: RegularPeriods | null;
  storeCapacity?: number;
  maximumStoreCapacity?: number;
  logoUrl?: string;
  globalStyles?: string;
  defaultPhoneNumberCountry?: string;
  phoneNumberCountries?: string[] | null;
  phoneNumberFrom?: string | null;
  addressLines?: string[];
  leaveReminderNotificationAfterMinutes?: number | null;
  bookingReminderNotificationBeforeMinutes?: number | null;
  leaveAfterMinutes?: number | null;
  notifyExtraPendingPositionOffset?: number;
  pendingPositionsCount?: number;
  notifiedPositionsCount?: number;
  fulfilledPositionsCount?: number;
  allowEntryAboveCapacity?: boolean;
  webPushEnabled?: boolean;
  applePassEnabled?: boolean;
  smsEnabled?: boolean;
  emailEnabled?: boolean;
  emailFrom?: string;
  registrationEnabled?: boolean;
  printingManualTicketsEnabled?: boolean;
  autoCheckOutByNextEnabled?: boolean;
  registrationFields?: RegistrationField[];
  phoneNumber?: string;
  curbsideLogoUrl?: string;
  curbsidePickupArrivalWindowCapacity?: number;
  curbsidePickupArrivalWindowDuration?: number;
  averageWaitingTime?: number;
  isEmailLogin?: boolean;
  isInStore?: boolean;
  emailsAllowed?: string[];
  textInAppCustomization: {
    [lng: string]: TextInAppCustomizationTextsForLanguage;
  };
  automaticallyClearQueueTime?: string[] | null;
  availableTagsForQueue?: Tag[];
  bookingsMode?: BookingsModeType;
  checkInByQRCode?: boolean;
  emailsForTeamsCurbsideNotifications?: string[];
  webHooks?: any;
  numberOfAvailableBookingsPerDay?: number;
  stations: Station[];
  maxNumberOfPeoplePerWalkInTicket?: number;
  maxNumberOfPeoplePerBookingTicket?: number;
  feedback: Feedback;
  staffNotifications?: StaffNotifications;
  booking?: Booking;
  customFields?: {
    position?: CustomizableField[];
    bookingForm?: CustomizableField[];
    positionBooking?: CustomizableField[];
  };
  positionLabelPrefixes?: {
    anonymous?: string;
    booking?: string;
    manual?: string;
  };
  ticketFlowModifiers?: TicketFlowModifiers;
  externalId?: string;
  isAutoCallEnabled?: boolean;
  maxOccupancyIncreasingWithSensors?: number;
  isPositionWaitingTimeVisible?: boolean;
  isNumberInTheQueueVisible?: boolean;
  removedAt?: string;
  categories?: Category[];
  _etag?: string;
  syncId?: string;
  digitalCall?: DigitalCall;
  autoCheckOutAfterMinutes?: number | null;
  isKeepDistanceInfoEnabled?: boolean;
  gridAppProperties?: QueueGridAppsProperties;
  isTicketTransferEnabled?: boolean;
}

export interface Organization {
  id: string;
  title: string;
  description: string;
  name: string;
  locale?: string;
  supportedLocales?: string[];
  tags?: Tag[];
}
