import React, { useMemo } from 'react';
import { FieldProps } from 'react-jsonschema-form';
// @ts-ignore
import { optionsList } from 'react-jsonschema-form/lib/utils';
import CustomDropdown from '../../../custom-dropdown/custom-dropdown.component';

const DropdownWithCreate = (props: FieldProps) => {
  const {
    schema,
    onChange,
    disabled,
    readonly,
    formData,
    name,
    required,
    onCreate,
  } = props;
  const options = useMemo(() => optionsList(schema), [schema]);

  return (
    <>
      <label htmlFor={name} className="control-label">
        {schema.title || name}
        {required && <span className="required">*</span>}
      </label>
      <div>
        <CustomDropdown
          disabled={disabled || readonly}
          options={options}
          value={formData}
          onChange={onChange}
          onCreate={onCreate}
        />
      </div>
    </>
  );
};

export default DropdownWithCreate;
