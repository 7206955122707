import { useMutation, useQueryClient } from 'react-query';
import { useApiService } from '../../../app/common/api-service-provider';
import ContentTag from '../../types/content-tag';
import tagQueryKeys from './tag-query-keys';

const useDeleteTag = (tenantId: string) => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  return useMutation(
    (tagId: string) => {
      return apiService.delete<ContentTag>(`/api/content-tag/${tagId}`, { tenantId });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(tagQueryKeys.tenantTags(tenantId));
      },
    },
  );
};

export default useDeleteTag;
