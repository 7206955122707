import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import { RootModel, RootState } from '../../../../store/models/root.model';
import AppsLibraryDetails from './gridapp-details.component';

type AppsListContainerProps = Pick<
  React.ComponentProps<typeof AppsLibraryDetails>,
  'match' | 'history' | 'location'
>;

const AppsLibraryDetailsContainer = (props: AppsListContainerProps) => {
  const {
    match: {
      params: { organisationId, gridappId },
    },
  } = props;

  const { fetchGridapp } = useActions<RootModel>((actions) => ({
    fetchGridapp: actions.organisationAppsLibrary.fetchSingle,
  }));

  const { gridapp } = useStore<RootState>(
    (state) => ({
      gridapp: state.organisationAppsLibrary.data[organisationId]
        ? state.organisationAppsLibrary.data[organisationId][gridappId]
        : null,
    }),
    [organisationId, gridappId],
  );

  return (
    <AppsLibraryDetails {...props} gridapp={gridapp} fetchGridapp={fetchGridapp} loaded />
  );
};

export default AppsLibraryDetailsContainer;
