import { useQuery, useQueryClient, useMutation } from 'react-query';
import toString from 'lodash/toString';
import { useApiService } from '../api-service-provider';
import InstallationGroup from '../../../store/types/installation-group';

export const routes = {
  createInstallationGroup: () => ['', 'api', 'installation-groups'].join('/'),
  getInstallationGroups: () => ['', 'api', 'installation-groups'].join('/'),
  getInstallationGroup: (installationGroupId: string) =>
    ['', 'api', 'installation-groups', installationGroupId].join('/'),
  updateInstallationGroup: (installationGroupId: string) =>
    ['', 'api', 'installation-groups', installationGroupId].join('/'),
  deleteInstallationGroup: (installationGroupId: string) =>
    ['', 'api', 'installation-groups', installationGroupId].join('/'),
};

const queryKeys = {
  installationGroups: (organisationId: string, limit: number, skip: number) => [
    'installationGroups',
    'organisationId',
    organisationId,
    'limit',
    limit,
    'skip',
    skip,
  ],
  installationGroup: (installationGroupId: string) => [
    'installationGroups',
    'installationGroupId',
    installationGroupId,
  ],
  organisationInstallationGroups: (organisationId: string) => [
    'installationGroups',
    'organisationId',
    organisationId,
  ],
};

interface UseInstallationGroupsProps {
  organisationId: string;
  limit?: number;
  skip?: number;
}

type UseInstallationGroupsResponse = InstallationGroup[];

export const useInstallationGroups = ({
  organisationId,
  limit = 50,
  skip = 0,
}: UseInstallationGroupsProps) => {
  const apiService = useApiService();

  return useQuery(
    queryKeys.installationGroups(organisationId, limit, skip),
    () =>
      apiService.get<UseInstallationGroupsResponse>(routes.getInstallationGroups(), {
        organizationId: organisationId,
        limit: toString(limit),
        skip: toString(skip),
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export const useInstallationGroup = (
  installationGroupId: string,
  organisationId: string,
) => {
  const apiService = useApiService();

  return useQuery(
    queryKeys.installationGroup(installationGroupId),
    () =>
      apiService.get<InstallationGroup>(
        routes.getInstallationGroup(installationGroupId),
        { organizationId: organisationId },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export interface InstallationGroupBody {
  organizationId: string;
  displayName: string;
  notes?: string;
}

export const useCreateInstallationGroup = (organisationId: string) => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  return useMutation(
    (installationGroupBody: InstallationGroupBody): Promise<InstallationGroup> =>
      apiService.post(routes.createInstallationGroup(), installationGroupBody),
    {
      onSuccess: async (data: any) => {
        await queryClient.invalidateQueries(
          queryKeys.organisationInstallationGroups(organisationId),
        );
        return data;
      },
    },
  );
};

export const useUpdateInstallationGroup = (
  organisationId: string,
  installationGroupId: string,
) => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  return useMutation(
    (installationGroupBody: InstallationGroupBody) =>
      apiService.put(
        routes.updateInstallationGroup(installationGroupId),
        installationGroupBody,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          queryKeys.organisationInstallationGroups(organisationId),
        );
        queryClient.invalidateQueries(queryKeys.installationGroup(installationGroupId));
      },
    },
  );
};

export const useDeleteInstallationGroup = (
  organisationId: string,
  installationGroupId: string,
) => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  return useMutation(
    () =>
      apiService.delete(routes.deleteInstallationGroup(installationGroupId), {
        organizationId: organisationId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          queryKeys.organisationInstallationGroups(organisationId),
        );
        queryClient.invalidateQueries(queryKeys.installationGroup(installationGroupId));
      },
    },
  );
};
