import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { Col, Row, Table } from 'antd';
import styled from '@emotion/styled';
import PricingSection from '../../../../../store/types/pricing-section';
import Overlay from '../../../../common/overlay/overlay.component';
import Spinner from '../../../../common/spinner/spinner.component';
import PanelCard from '../../../../common/panel-card/panel-card.component';
import { getTranslationFromObject } from '../../../../../utils/language/language.util';
import CurrencyAwareValue from '../../../../../store/types/currency-aware-value';
import { formatCurrencyAwareValue } from '../../../../../utils/currency';

interface PricingComponentProps extends RouteComponentProps<{ organisationId: string }> {
  fetchPricingSections: (params: { organisationId: string }) => void;
  loaded: boolean;
  pricingSections: PricingSection[];
}

const priceTableColumns = [
  {
    title: 'SKU',
    dataIndex: 'sku',
    width: 100,
    fixed: 'left',
  },
  {
    title: 'Product name',
    dataIndex: 'name',
    ellipsis: true,
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
    render: (unit: string) => unit || '-',
    width: 150,
  },
  {
    title: 'Price',
    dataIndex: 'price',
    render: (price: CurrencyAwareValue) =>
      formatCurrencyAwareValue(price, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 5,
      }),
    width: 150,
  },
] as any;

const PricingComponent = (props: PricingComponentProps) => {
  const { fetchPricingSections, loaded, match, pricingSections } = props;

  const {
    params: { organisationId },
  } = match;

  useEffect(() => {
    fetchPricingSections({ organisationId });
  }, [fetchPricingSections, organisationId]);

  if (!loaded) {
    return (
      <Overlay>
        <Spinner />
      </Overlay>
    );
  }

  return (
    <div className="content-body">
      <Row>
        <Col xl={24} xxl={18}>
          <PricingPanelCard>
            {pricingSections.map((section) => (
              <Table
                pagination={false}
                scroll={{ x: 600 }}
                columns={priceTableColumns}
                dataSource={section.items}
                title={() => (
                  <PricingTableHeader>
                    {getTranslationFromObject(section.displayName)}
                  </PricingTableHeader>
                )}
                key={section.displayName.en}
              />
            ))}
          </PricingPanelCard>
        </Col>
      </Row>
    </div>
  );
};

const PricingPanelCard = styled(PanelCard)`
  padding: 0 8px 8px 8px;
  overflow-x: scroll;
`;

const PricingTableHeader = styled.h2`
  font-size: 20px;
`;

export default PricingComponent;
