import i18next from 'i18next';
import CurrencyAwareValue from '../store/types/currency-aware-value';

const decimalScaleMultiplier = 100000;

export const getGridIntegerPrice = (decimalValue: number) =>
  Math.round(decimalValue * decimalScaleMultiplier);

export const formatAmount = (
  currency: string,
  amount: number,
  language?: string,
  extraOptions: Intl.NumberFormatOptions = {},
): string => {
  const lang = language || i18next.language;
  return new Intl.NumberFormat(lang, {
    style: 'currency',
    currency,
    ...extraOptions,
  })
    .format(amount)
    .replace(/^([^\d\s]+)(\d.*)$/, '$1 $2');
};

export const formatCurrencyAwareValue = (
  value: CurrencyAwareValue,
  extraOptions: Intl.NumberFormatOptions = {},
) => {
  return formatAmount(value.currency, value.valueDecimal, undefined, extraOptions);
};

export default { getGridIntegerPrice };
