import React, { useMemo } from 'react';
import { Col, Row, Select as AntdSelect } from 'antd';
import useTagsList from '../../../store/hooks/content-tags/use-tags-list';
import Select, { Option } from './select';
import ArrayFields from './array-fields';
import { ContentTagType } from '../../../store/common/content-tags/content-tag-type';
import { Field } from 'react-final-form';
import { ChangeEvent } from '../../../types';
import styled from '@emotion/styled';
import { FormApi } from 'final-form';
import { requiredAll } from '../../organisations/organisation-details/queue-details/queue-setup/validate-form';
import InputWrap from './input-wrap';
import { useTranslation } from 'react-i18next';

export interface ContentTagPickerValue {
  tagType: ContentTagType.CONTENT_TAG;
  tagId: string;
  values: string[];
}

interface ContentTagValuePickerProps {
  parentPropertyName: string;
  tagsOptions: Option[];
  tagsMapping: Record<string, Option[]>;
  updateFormValues: FormApi['change'];
}

const ContentTagValuePicker = (props: ContentTagValuePickerProps) => {
  const { parentPropertyName, tagsOptions, tagsMapping, updateFormValues } = props;

  const { t } = useTranslation();

  return (
    <TagPickerValueContainer gutter={[10, 20]}>
      <Col span={12}>
        <Select
          name={`${parentPropertyName}.tagId`}
          label={t('spaceForm.tag')}
          options={tagsOptions}
          isRequired={true}
          onChange={() => {
            updateFormValues(`${parentPropertyName}.tagType`, ContentTagType.CONTENT_TAG);
            updateFormValues(`${parentPropertyName}.values`, undefined);
          }}
        />
      </Col>

      <Col span={12}>
        <Field
          name={`${parentPropertyName}.tagId`}
          validate={requiredAll}
          render={({ input, meta }) => {
            const tagValueOptions = tagsMapping[input.value as string] || [];

            return (
              <Field
                name={`${parentPropertyName}.values`}
                validate={requiredAll}
                render={({ input, meta }) => (
                  <InputWrap
                    label={`${t('spaceForm.value')} *`}
                    error={meta.error && meta.touched ? meta.error : null}
                  >
                    <AntdSelect
                      value={input.value || undefined}
                      onChange={(value: string) => {
                        input.onChange({
                          target: {
                            value: [value],
                          },
                        } as unknown as ChangeEvent<string[]>);
                      }}
                    >
                      {tagValueOptions.map((tagValueOption, index) => {
                        const { label, value } = tagValueOption;
                        return (
                          <AntdSelect.Option key={`${value}-${index}`} value={value}>
                            {label}
                          </AntdSelect.Option>
                        );
                      })}
                    </AntdSelect>
                  </InputWrap>
                )}
              />
            );
          }}
        />
      </Col>
    </TagPickerValueContainer>
  );
};

interface ContentTagPickerProps {
  name: string;
  label: string;
  tenantId: string;
  updateFormValues: FormApi['change'];
}

const ContentTagPicker = (props: ContentTagPickerProps) => {
  const { name, label, tenantId, updateFormValues } = props;

  const { data: tagsData } = useTagsList(tenantId, 1000);

  const tagsOptions = useMemo<Option[]>(() => {
    if (!tagsData) {
      return [];
    }

    const tagOptions = tagsData.docs.map((tag) => {
      return { label: tag.name, value: tag.id };
    });

    return tagOptions;
  }, [tagsData]);

  const tagsMapping: Record<string, Option[]> = useMemo(() => {
    if (!tagsData) {
      return {};
    }

    const tagsMapping: Record<string, Option[]> = {};
    tagsData.docs.forEach((tag) => {
      const { id, values = [] } = tag;
      tagsMapping[id] = values.map((value) => ({ label: value, value }));
    });

    return tagsMapping;
  }, [tagsData]);

  return (
    <Container>
      <Label className="control-label">{label}</Label>

      <ArrayFields<ContentTagPickerValue>
        propertyName={name}
        itemRowContent={(parentPropertyName) => {
          return (
            <TagsPickerContainer>
              <ContentTagValuePicker
                parentPropertyName={parentPropertyName}
                tagsOptions={tagsOptions}
                tagsMapping={tagsMapping}
                updateFormValues={updateFormValues}
              />
            </TagsPickerContainer>
          );
        }}
      />
    </Container>
  );
};

export default ContentTagPicker;

const Container = styled.div`
  .array-fields-delete-btn-container {
    align-self: start !important;
    margin-top: 45px;
  }
`;

const TagsPickerContainer = styled(Row)`
  width: 100% !important;
`;

const TagPickerValueContainer = styled(Row)``;

const Label = styled.h4`
  font-size: 16px;
  padding: 16px 0px;
`;
