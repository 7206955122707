import React, { useCallback, useMemo } from 'react';
import styled, { StyledComponent } from '@emotion/styled';
import { Button, Collapse as AntdCollapse, message, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { CollapseProps } from 'antd/lib/collapse/Collapse';
import { ApiError, ApiErrorData } from '../../../services/api/api-error';

const { Text } = Typography;

interface MessageProps {
  content?: React.ReactNode;
  showCloseButton?: boolean;
  onClose?: () => void;
  error?: ApiError | Error | null;
}

const Message = (props: MessageProps) => {
  const { content, showCloseButton = true, onClose = () => {}, error } = props;

  const { t } = useTranslation();

  const incidentId = useMemo((): string | undefined => {
    if (!error || !(error instanceof ApiError)) {
      return undefined;
    }

    if (!error.response) {
      return undefined;
    }

    const { incidentId = undefined } =
      (error.response.data as ApiErrorData | undefined) || {};

    return incidentId;
  }, [error]);

  const handleClose = useCallback(() => {
    onClose();
    message.destroy();
  }, [onClose]);

  return (
    <MessageContainer>
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <MoreLess>{isActive ? t('less') : t('more')}</MoreLess>
        )}
      >
        <CollapsePanel
          key="component-panel"
          header={<div>{content}</div>}
          showArrow={!!incidentId}
        >
          {incidentId && (
            <EventId>
              <div>{t('incidentId')}</div>
              <Text code copyable>
                {incidentId}
              </Text>
            </EventId>
          )}
        </CollapsePanel>
      </Collapse>
      <div />
      {showCloseButton && (
        <Button type="link" size="small" icon="close" onClick={handleClose} />
      )}
    </MessageContainer>
  );
};

const MessageContainer = styled.div`
  display: inline-flex;

  > button {
    left: 10px;
    top: -2px;

    > .anticon {
      color: #00000073;
      font-size: 12px;
    }
  }
`;

const EventId = styled.div`
  display: flex;
  align-items: center;

  .ant-typography-copy {
    margin-left: 0 !important;

    > .anticon {
      color: #689bc4 !important;
    }
  }
`;

type StyledCollapseType = StyledComponent<
  CollapseProps & React.ClassAttributes<AntdCollapse>,
  Pick<CollapseProps & React.ClassAttributes<AntdCollapse>, keyof CollapseProps>,
  any
>;
const Collapse = styled(AntdCollapse)`
  width: -webkit-fill-available;
  background-color: transparent;
` as StyledCollapseType;

const CollapsePanel = styled(AntdCollapse.Panel)`
  > .ant-collapse-header {
    display: flex;
    cursor: default !important;

    > .ant-collapse-arrow {
      position: relative !important;
      order: 2 !important;
      left: 5px !important;
      transform: none !important;
      line-height: unset !important;
      height: 0;
    }
  }
`;

const MoreLess = styled.span`
  font-size: 10px !important;
  color: #2364aa !important;
  text-transform: lowercase !important;
`;

export default Message;
