import React, { useEffect, useMemo } from 'react';
import { FormApi } from 'final-form';
import { Col, Row } from 'antd';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Input, Select, Option, validateCharacterLength } from '../../../../../common/react-final-form';
import { requiredAll, requiredNumber } from '../../../queue-details/queue-setup/validate-form';
import { AnalyticsEventPropertyNumericValues } from '../../../../../../store/types/alerts';
import { getAggregationOperatorOptions, getOperatorOptions } from '../../../../../../utils/alerts';

const EVENT_PROPERTY_OPTIONS: Option[] = AnalyticsEventPropertyNumericValues.map((numericValue) => {
  return {
    label: numericValue.toUpperCase(),
    value: numericValue,
  };
});

const DURATION_INTERVALS = [5, 10, 15, 20, 25, 30];

const DURATION_OPTIONS: Option[] = DURATION_INTERVALS.map((duration) => {
  return {
    label: `${duration}`,
    value: duration * 60, // convert to seconds
  };
});

interface DeviceAnalyticsEventsAggregationProps {
  parentFieldName: string;
  updateForm: FormApi['change'];
}

const DeviceAnalyticsEventsAggregationConditionFields = (props: DeviceAnalyticsEventsAggregationProps) => {
  const { parentFieldName, updateForm } = props;

  const { t } = useTranslation();

  // unset fields from parent when the component unmounts to avoid having un-necessary fields on form submission
  useEffect(() => {
    return () => {
      updateForm(`${parentFieldName}.eventType`, undefined);
      updateForm(`${parentFieldName}.eventProperty`, undefined);
      updateForm(`${parentFieldName}.aggregationOperator`, undefined);
      updateForm(`${parentFieldName}.operator`, undefined);
      updateForm(`${parentFieldName}.threshold`, undefined);
      updateForm(`${parentFieldName}.duration`, undefined);
    };
  }, [updateForm, parentFieldName]);

  const aggregationOperatorOptions = useMemo<Option[]>(() => {
    return getAggregationOperatorOptions(t).map(({ id, label }) => {
      return {
        label,
        value: id as string,
      };
    });
  }, [t]);

  const operatorOptions = useMemo<Option[]>(() => {
    return getOperatorOptions(t).map(({ id, label }) => {
      return {
        label,
        value: id as string,
      };
    });
  }, [t]);

  return (
    <>
      <FieldsContainer gutter={[20, 20]}>
        <Col span={8}>
          <Input
            name={`${parentFieldName}.eventType`}
            label={`${t('alertRules.eventType')} *`}
            validators={[requiredAll, validateCharacterLength(3, 200)]}
          />
        </Col>

        <Col span={8}>
          <Select
            name={`${parentFieldName}.eventProperty`}
            label={t('alertRules.eventProperty')}
            isRequired={true}
            options={EVENT_PROPERTY_OPTIONS}
          />
        </Col>

        <Col span={8}>
          <Select
            name={`${parentFieldName}.aggregationOperator`}
            label={t('alertRules.aggregationOperator')}
            isRequired={true}
            options={aggregationOperatorOptions}
          />
        </Col>
      </FieldsContainer>

      <FieldsContainer gutter={[20, 20]}>
        <Col span={8}>
          <Select
            name={`${parentFieldName}.operator`}
            label={t('alertRules.operator')}
            isRequired={true}
            options={operatorOptions}
          />
        </Col>

        <Col span={8}>
          <Input
            name={`${parentFieldName}.threshold`}
            label={`${t('alertRules.value')} *`}
            validators={[requiredNumber]}
            min={0}
            type="number"
          />
        </Col>

        <Col span={8}>
          <Select
            name={`${parentFieldName}.duration`}
            label={t('alertRules.duration')}
            isRequired={true}
            options={DURATION_OPTIONS}
          />
        </Col>
      </FieldsContainer>
    </>
  );
};

export default DeviceAnalyticsEventsAggregationConditionFields;

const FieldsContainer = styled(Row)`
  margin-top: 10px !important;
  padding-left: 10px;
  padding-right: 10px;
`;
