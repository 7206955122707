import { Field } from 'react-final-form';
import React, { useMemo } from 'react';
import { Select as SelectSearch } from 'antd';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input';
import en from 'react-phone-number-input/locale/en.json';
import { useTranslation } from 'react-i18next';
import SelectMultipleAdapter from './select-multiple-adapter';
import { Label, RowStyled } from './styled-block';

export default function PhoneNumberCountries() {
  const { t } = useTranslation();
  const phoneCountriesOptions = useMemo(
    () =>
      getCountries().map((country) => (
        <SelectSearch.Option value={country} key={country}>
          {(en as any)[country]} +{getCountryCallingCode(country)}
        </SelectSearch.Option>
      )),
    [],
  );

  return (
    <Field name="phoneNumberCountries">
      {({ input }) => (
        <RowStyled>
          <Label>{t('queuePhoneNumberCountriesAllowedCountries')}</Label>
          <SelectMultipleAdapter
            input={input}
            placeholder="Select available phone countries"
            options={phoneCountriesOptions}
          />
        </RowStyled>
      )}
    </Field>
  );
}
