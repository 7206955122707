import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Select as AntdSelect, Tooltip, Typography } from 'antd';
import { FieldProps } from 'react-jsonschema-form';
import {
  SelectValue,
  OptionProps,
  SelectProps as AntdSelectProps,
} from 'antd/lib/select';
import styled, { StyledComponent } from '@emotion/styled';
import { Global, css } from '@emotion/core';
import { ButtonProps } from 'antd/lib/button/button';
import { Icon } from '../../common';
import VersionReleaseNotesPopover from './version-release-notes-popover.component';

const { Option } = AntdSelect;
const { Title: AntdTitle, Text } = Typography;

interface OptionPropsExt extends OptionProps {
  ['data-label']?: string;
}

type OptionValue = React.ReactElement<
  OptionPropsExt,
  string | React.JSXElementConstructor<any>
>;

type SelectType = string | undefined;

interface EnumExtra {
  buildDate?: string;
  releaseNotes?: string;
}

interface VersionPickerUiOptions {
  enumExtra?: EnumExtra[];
}

interface VersionPickerProps extends FieldProps<SelectType> {}

const VersionPicker = (props: VersionPickerProps) => {
  const {
    formData,
    onChange,
    schema,
    uiSchema,
    required: isRequired,
    readonly: isReadonly,
  } = props;

  const placeholder = uiSchema['ui:placeholder'] as string | undefined;
  const { enumExtra = [] } = (uiSchema['ui:options'] || {}) as VersionPickerUiOptions;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { t } = useTranslation();

  const { formValue } = useMemo(() => {
    const fallbackValue = isRequired ? undefined : '';

    return {
      formValue: (formData as string) || fallbackValue,
    };
  }, [formData, isRequired]);

  useEffect(() => {
    onChange(formValue);
  }, [onChange, formValue]);

  const handleOpen = useCallback((isOpen: boolean) => {
    setIsOpen(isOpen);
  }, []);

  const handleSelect = useCallback((value: SelectValue) => {
    setIsOpen(true);
  }, []);

  const handleChooseClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, value: string) => {
      e.stopPropagation();
      setIsOpen(false);
      onChange(value);
    },
    [onChange],
  );

  const { options, isOutdated } = useMemo(() => {
    const schemaExtended = schema;
    const keyList = (schemaExtended.enum || []) as string[];
    const valueList = schemaExtended.enumNames || [];

    const options = keyList.map((key, index) => {
      const label = valueList[index] || '';
      const { buildDate = undefined, releaseNotes = undefined } = enumExtra[index] || {};

      return { [key]: { label: label || key, releaseNotes, buildDate } };
    });

    return {
      options,
      isOutdated: !!options.length && keyList[0] !== formValue,
    };
  }, [enumExtra, formValue, schema]);

  const mappedOptions = useMemo<JSX.Element[]>(() => {
    const isStringArray = typeof options[0] === 'string';

    if (isStringArray) {
      const list = (options as unknown) as string[];
      return list.map((value) => {
        return (
          <Option key={value} value={value} data-label={value}>
            {value}
          </Option>
        );
      });
    }

    return options.map((option) => {
      const key = Object.keys(option)[0];
      const { label, releaseNotes, buildDate } = option[key];
      const isCurrentVersion = key === formValue;
      const versionLabel = isCurrentVersion ? `${label} (${t('current')})` : label;

      return (
        <Option key={key || '-'} value={key} data-label={label}>
          <OptionItem>
            <Info>
              <Row>
                <Text>{versionLabel}</Text>
              </Row>
            </Info>
            <Extra>
              {/* Unable to select texts inside popover embedded in dropdown. Antd Select Behavior or possibly a bug in Antd. */}
              <VersionReleaseNotesPopover
                version={versionLabel}
                releaseNotes={releaseNotes}
                buildDate={buildDate}
              />
              <ChooseButton
                type="default"
                size="default"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                  handleChooseClick(e, key)
                }
              >
                {t('selectValue')}
              </ChooseButton>
            </Extra>
          </OptionItem>
        </Option>
      );
    });
  }, [formValue, handleChooseClick, options, t]);

  return (
    <>
      <Global styles={globalStyleOption} />
      <Title className="control-label">
        {`${schema.title}${isRequired ? ' *' : ''}`}
        {isOutdated && (
          <Tooltip placement="top" title={t('versionIsOutdated')}>
            <Icon type="exclamation-circle" color="#FF4D4F" size={14} />
          </Tooltip>
        )}
      </Title>
      <Select
        className="testing"
        showSearch={!isReadonly}
        showArrow={!isReadonly}
        optionFilterProp="children"
        optionLabelProp="data-label"
        filterOption={(input: string, option: OptionValue) => {
          const optionLabel = option.props['data-label'];
          return (
            !!optionLabel && optionLabel.toLowerCase().indexOf(input.toLowerCase()) >= 0
          );
        }}
        placeholder={placeholder || t('select.placeholder')}
        onSelect={handleSelect}
        onDropdownVisibleChange={handleOpen}
        disabled={isReadonly}
        isReadonly={isReadonly}
        {...(formValue && options.length ? { value: formValue } : {})}
        open={isOpen}
        dropdownClassName="version-picker-dropdown"
      >
        {mappedOptions}
      </Select>
    </>
  );
};

type StyledSelectType = StyledComponent<
  AntdSelectProps & React.ClassAttributes<AntdSelectProps> & { isReadonly?: boolean },
  Pick<AntdSelectProps & React.ClassAttributes<AntdSelectProps>, keyof AntdSelectProps>,
  any
>;
const Select = styled(AntdSelect)`` as StyledSelectType;

const OptionItem = styled.div`
  display: flex;
  align-items: center;
`;

const globalStyleOption = css`
  .version-picker-dropdown {
    .ant-select-dropdown-menu > li {
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      cursor: default;
    }

    .ant-select-dropdown-menu > li:last-child {
      border-bottom: none;
    }

    .ant-select-dropdown-menu-item-selected {
      font-weight: normal;
    }
  }
`;

const Extra = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
`;

const Info = styled.div`
  flex: 1;
`;

const Title = styled(AntdTitle)`
  display: flex;
  gap: 5px;
  font-size: 14px !important;
  margin-bottom: 0 !important;
  padding-bottom: 2px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 0 0;

  flex-wrap: wrap;
`;

type StyledButtonType = StyledComponent<
  ButtonProps & React.ClassAttributes<Button>,
  Pick<ButtonProps & React.ClassAttributes<Button>, keyof ButtonProps>,
  any
>;
const ChooseButton = styled(Button)`
  color: unset;
  margin: auto;
` as StyledButtonType;

export default VersionPicker;
