/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from 'react';
import { Card, Icon, Typography, Input, Button, Spin } from 'antd';
import styled from '@emotion/styled';
import { useTranslation, Trans } from 'react-i18next';
import { get } from 'lodash';
import { TFunction } from 'i18next';
import { ApiError } from '../../../services/api/api-error';

const { Title, Paragraph } = Typography;
const { TextArea } = Input;

export enum ErrorKey {
  UNAUTHORIZED = 'UNAUTHORIZED',
  ACCESS_DENIED = 'ACCESS_DENIED',
  NOT_FOUND = 'NOT_FOUND',
  TOKEN_EXPIRED = 'TokenExpiredError',
  TOKEN_INVALID = 'JsonWebTokenError',
  TOKEN_NOT_BEFORE = 'NotBeforeError',
}

interface ErrorViewProps {
  className?: string;
  title?: React.ReactNode;
  content?: React.ReactNode;
  icon?: React.ReactNode;
  error?: ApiError | { message?: string | ErrorKey; incidentId?: string };
  onPageRefresh?: () => void;
  position?: 'relative' | 'absolute';
  fillPage?: boolean;
}

const ErrorView = (props: ErrorViewProps) => {
  const {
    className,
    title,
    content,
    icon,
    error,
    onPageRefresh,
    fillPage = false,
    position = 'absolute',
  } = props;

  const [isPageRefreshing, setIsPageRefreshing] = useState<boolean>(false);
  const { t } = useTranslation();

  const { errorDesc, errorId } = useMemo(() => {
    // eslint-disable-next-line no-use-before-define
    const { message, id } = getErrorDetails(t, error);

    return {
      errorDesc: message
        ? `${t('errorView.description', { description: message })}\n`
        : '',
      errorId: id ? `${t('errorView.incidentId', { incidentId: id })}\n` : '',
    };
  }, [error, t]);

  const extraIcon = useMemo(() => {
    if (!icon) {
      return (
        <Icon component={() => <GearImage src="/error_header_animated.png" alt="" />} />
      );
    }

    return typeof icon === 'string' ? <Icon type={icon} /> : icon;
  }, [icon]);

  const isContentVisible = useMemo(() => content !== null, [content]);

  return (
    <ErrorOverlay className={className} position={fillPage ? 'relative' : position}>
      <Container fillpage={fillPage}>
        <ErrorCard
          title={<Title level={3}>{title || t('somethingWentWrong')}</Title>}
          extra={extraIcon}
          contentvisible={isContentVisible ? 'true' : 'false'}
        >
          {content === undefined ? (
            <>
              <Paragraph>
                {onPageRefresh ? (
                  <>
                    <Trans
                      i18nKey={t('errorView.refreshPage')}
                      components={{
                        // TO DO: Improve with actual use-case
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        button: (
                          <RefreshButton
                            type="link"
                            disabled={isPageRefreshing}
                            onClick={() => {
                              setIsPageRefreshing(true);
                              if (onPageRefresh) {
                                onPageRefresh();
                              }
                            }}
                          />
                        ),
                      }}
                    />
                    {isPageRefreshing && (
                      <SpinnerStyled indicator={<Icon type="loading" spin />} />
                    )}
                  </>
                ) : (
                  t('errorView.reloadPage')
                )}
              </Paragraph>
              <Paragraph>
                <Trans
                  i18nKey={
                    title
                      ? t('errorView.ifIssueContactSupport')
                      : t('errorView.problemPeristsContactSupport')
                  }
                  components={{
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    a: <a />,
                  }}
                />
              </Paragraph>
              {(errorDesc || errorId) && (
                <ErrorDetails>
                  <Paragraph>{t('errorView.errorDetails')}</Paragraph>
                  <TextArea rows={3} readOnly value={`${errorDesc}${errorId}`} />
                </ErrorDetails>
              )}
            </>
          ) : (
            content
          )}
        </ErrorCard>
      </Container>
    </ErrorOverlay>
  );
};

const translateErrorFromStatusCode = (statusCode: number, t: TFunction) => {
  switch (statusCode) {
    case 401:
      return t('errorView.unauthorized');
    case 403:
      return t('errorView.accessDenied');
    case 404:
      return t('errorView.notFound');
    default:
      return '';
  }
};

const translateErrorFromErrorKey = (key: ErrorKey, t: TFunction) => {
  switch (key) {
    case ErrorKey.UNAUTHORIZED:
      return t('errorView.unauthorized');
    case ErrorKey.ACCESS_DENIED:
      return t('errorView.accessDenied');
    case ErrorKey.NOT_FOUND:
      return t('errorView.notFound');
    case ErrorKey.TOKEN_EXPIRED:
      return t('errorView.tokenExpired');
    case ErrorKey.TOKEN_INVALID:
      return t('errorView.tokenInvalid');
    case ErrorKey.TOKEN_NOT_BEFORE:
      return t('errorView.tokenNotYetActive');
    default:
      return '';
  }
};

const getErrorDetails = (
  t: TFunction,
  error?: ErrorKey | ApiError | { message?: string; incidentId?: string },
): {
  message: string;
  id: string;
} => {
  if (error instanceof ApiError) {
    const code = get(error, 'response.statusCode', 0);
    const id = get(error, 'response.incidentId', '');

    return {
      message: translateErrorFromStatusCode(code, t),
      id,
    };
  }

  const msg = get(error, 'message', '');

  return {
    message: Object.values(ErrorKey).includes(msg)
      ? translateErrorFromErrorKey(msg as ErrorKey, t)
      : msg,
    id: get(error, 'incidentId', ''),
  };
};

const ErrorOverlay = styled.div<{ position: string }>`
  position: ${({ position }) => position};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Container = styled.div<{ fillpage?: boolean }>`
  ${({ fillpage }) => {
    return fillpage
      ? `
        margin-top: 1px;
        height: 100%;
        .ant-card {
          height: 100%;
          border-radius: 0;
          border: 0;
        }
        .ant-card-head {
          border-bottom: 0px;
        }
        `
      : `
        max-width: 500px;
        margin: 0 10px;
        `;
  }}
  width: 100%;
`;

const ErrorDetails = styled.div`
  margin-top: 20px;

  .ant-typography {
    font-size: 12px;
  }

  textarea {
    background-color: #f2f2f2;
    font-family: courier, courier new, serif;
    font-size: 12px;
    color: #000000;
  }
`;

const ErrorCard = styled(Card)<{ contentvisible?: 'true' | 'false' }>`
  margin-bottom: 20px;

  ${({ contentvisible }) =>
    contentvisible === 'true'
      ? `
        .ant-card-body {
          padding: 24px !important;
          min-height: 0px !important;

          .ant-typography {
            margin-bottom: 10px;
          }
        }
        `
      : `.ant-card-body {
           padding: 0;
           min-height: 0px;
           height: 0px;
        }
        `};

  .ant-card-head-title {
    padding: 16px 0 !important;
    white-space: break-spaces;
  }

  .ant-card-extra {
    padding: 0;
    font-size: 30px;
  }
`;

const GearImage = styled.img`
  height: 60px;
`;

const RefreshButton = styled(Button)`
  padding: 0;
` as any;

const SpinnerStyled = styled(Spin)`
  margin-top: -5px;
  margin-left: 10px;
  .anticon-loading {
    font-size: 10px;
  }
` as any;

export default React.memo(ErrorView);
