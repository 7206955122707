// note: this is used more as 'application platform' type / application type, not actual device type
enum DeviceTypeEnum {
  BALENA = 'balena',
  WINDOWS = 'windows',
  LINUX = 'linux',
  WPA = 'wpa',
  MOBILE_WPA = 'mobilewpa',
  IOT = 'iot',
  CLOUD = 'cloud',
}

export default DeviceTypeEnum;
