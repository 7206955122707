import styled from '@emotion/styled';
import { IsoLanguageIds } from '@ombori/grid-products/dist';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { defaultLng, StyledPanelCard } from './grid-products.components';
import ReactCountryFlag from 'react-country-flag';
import localeCode from 'locale-code';
import { getShortLocaleCodeLabel } from '../../../../utils/language/language.util';
import { Divider, Select, Button } from 'antd';
import { useTranslation } from 'react-i18next';

export const LanguageOption = (params: { option: IsoLanguageIds, hideFullLabel?: boolean }) => (
  <LanguageWrapper key={`language-${params.option}`}>
    <FlagIconWrapper>
      <ReactCountryFlag
        svg
        code={localeCode.getCountryCode(params.option)}
        styleProps={countryFlagStyle}
      />
    </FlagIconWrapper>
    <Label>{`${!params.hideFullLabel ? getShortLocaleCodeLabel(params.option) : ''} [${params.option}]`}</Label>
  </LanguageWrapper>
);

const LanguagePicker = ({
  onChange = () => {},
  onAdd = () => {},
  onRemove = () => {},
  defaultLanguages,
  isRtl,
  dataCy,
}: {
  onChange?: (languages: IsoLanguageIds[]) => void;
  onAdd?: (language: IsoLanguageIds, index: number) => void;
  onRemove?: (language: IsoLanguageIds, index: number) => void;
  defaultLanguages?: IsoLanguageIds[];
  isRtl?: boolean;
  dataCy?: string;
}) => {
  const { t } = useTranslation();

  const [languages, setLanguages] = useState<IsoLanguageIds[]>(
    defaultLanguages || [defaultLng],
  );

  useEffect(() => {
    if (defaultLanguages) {
      // Load initial languages from API data
      setLanguages(defaultLanguages);
    }
  }, [defaultLanguages]);
  const availableOptions = useMemo(
    () => Object.values(IsoLanguageIds).filter((item) => !languages.includes(item)),
    [languages],
  );

  const handleSelectLanguage = useCallback(
    (value) => {
      setLanguages((prevLanguages) => {
        const newLanguages = prevLanguages.concat(value);

        onChange(newLanguages);
        onAdd(value, prevLanguages.length);

        return newLanguages;
      });
    },
    [onAdd, onChange],
  );

  const handleRemoveLanguage = useCallback(
    (value) => {
      setLanguages((prevLanguages) => {
        const index = prevLanguages.findIndex((item) => item === value);
        const newLanguages = prevLanguages.filter((item) => item !== value);

        onChange(newLanguages);
        onRemove(value, index);

        return newLanguages;
      });
    },
    [onChange, onRemove],
  );

  return (
    <StyledPanelCard bodypadding={'16px 24px'}>
      <Title>{t('gridProducts.productLanguagePickerLabel')}</Title>
      <StyledSelect
        showSearch
        value={undefined}
        placeholder={t('gridProducts.productLanguagePickerPlaceholder')}
        onChange={handleSelectLanguage}
        data-testid="grid-products-language-picker-option"
      >
        {availableOptions.map((option) => (
          <Select.Option key={option}>
            <LanguageOption option={option} />
          </Select.Option>
        ))}
      </StyledSelect>
      <StyledDivider />
      <Title>{t('gridProducts.productLanguagePickerSpecifiedLngLabel')}</Title>
      <ExistingLanguageContainer>
        {languages.map((language) => (
          <div key={`existing-${language}`}>
            <LanguageOption option={language} />{' '}
            <Button
              type="danger"
              onClick={() => handleRemoveLanguage(language)}
              icon={'close'}
              size="default"
              data-testid="grid-products-language-remove-button"
            />
          </div>
        ))}
      </ExistingLanguageContainer>
    </StyledPanelCard>
  );
};

export default LanguagePicker;

const LanguageWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  margin: 8px 0px;
`;

const FlagIconWrapper = styled.div`
  align-items: center;
  justify-content: center;
  margin-top: -1px;
`;

const Label = styled.div`
  text-align: flex-start;
  font-size: 14px;
`;

const countryFlagStyle = {
  width: '24px',
  height: '16px',
  backgroundSize: 'cover',
  borderWidth: '2px',
  borderColor: 'blue',
  marginRight: '8px',
};

const StyledSelect = styled(Select)`
  width: 100%;
  margin-right: 16px;
  .ant-select-selection {
    height: 36px;
  }
  .ant-select-selection-selected-value {
    height: 36px;

    div:first-of-type {
      margin: 0px;
    }
  }
`;

const StyledDivider = styled(Divider)`
  margin: 16px 0px;
`;

const Title = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const ExistingLanguageContainer = styled.div``;
