import React, { useMemo } from 'react';
import styled, { StyledComponent } from '@emotion/styled';
import { Select as AntdSelect } from 'antd';
import {
  SelectValue,
  OptionProps,
  SelectProps as AntdSelectProps,
} from 'antd/lib/select';
import { FieldContainer } from '.';

const { Option } = AntdSelect;

interface OptionPropsExt extends OptionProps {
  ['data-label']?: string;
}

type OptionValue = React.ReactElement<OptionPropsExt, string | React.JSXElementConstructor<any>>;

interface SelectProps {
  className?: string;
  isBordered?: boolean;
  isReadonly?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  rawErrors?: string[];
  title?: string;
  subtitle?: string;
  defaultValue?: string | number;
  value?: string | number;
  description?: string;
  helpText?: string;
  placeholder?: string;
  onSelect?: (value: SelectValue, option: OptionValue) => void;
  beforeExtra?: React.ReactNode;
  options?: { [key: string]: string }[] | string[];
}

const Select = (props: SelectProps) => {
  const {
    className,
    isBordered = true,
    isReadonly,
    isRequired,
    isDisabled,
    rawErrors = [],
    title,
    subtitle,
    defaultValue,
    value,
    description,
    helpText,
    placeholder,
    onSelect = () => {},
    beforeExtra,
    options = [],
  } = props;

  const mappedOptions = useMemo<JSX.Element[]>(() => {
    const isStringArray = typeof options[0] === 'string';

    if (isStringArray) {
      const list = options as string[];
      return list.map((value) => {
              return (
                <Option key={value} value={value} data-label={value}>
                  {value}
                </Option>
              );
            });
    }

    const list = options as { [key: string]: string }[];
    return list.map((option) => {
      const opt = option as { [key: string]: string };
      const key = Object.keys(opt)[0];
      const label = opt[key];

      return (
        <Option key={key || '-'} value={key} data-label={label}>
          <div dangerouslySetInnerHTML={{ __html: label }} />
        </Option>
      );
    });
  }, [options]);

  return (
    <FieldContainer
      className={className}
      type="field"
      isBordered={isBordered}
      isDisabled={isDisabled}
      isRequired={isRequired}
      title={title}
      subtitle={subtitle}
      helpText={helpText}
      errors={rawErrors}
      description={description}
    >
      <FieldWrap>
        {beforeExtra}
        <FieldSelect
          showSearch={!isReadonly}
          showArrow={!isDisabled && !isReadonly}
          optionFilterProp="children"
          filterOption={(
            input: string,
            option: OptionValue,
          ) => {
            const optionLabel = option.props['data-label'];
            return !!optionLabel && optionLabel.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
          placeholder={placeholder || "Select value"}
          onSelect={onSelect}
          disabled={isDisabled || isReadonly}
          isReadonly={isReadonly}
          defaultValue={defaultValue}
          {...value ? { value } : {}}
        >
          {mappedOptions}
        </FieldSelect>
      </FieldWrap>
    </FieldContainer>
  );
};

const FieldWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
`;

type StyledSelectType = StyledComponent<
  AntdSelectProps & React.ClassAttributes<AntdSelectProps> & { isReadonly?: boolean },
  Pick<AntdSelectProps & React.ClassAttributes<AntdSelectProps>, keyof AntdSelectProps>,
  any
>;
const FieldSelect = styled(AntdSelect)<{ isReadonly?: boolean; value?: string }>`
  all: unset;
  cursor: pointer;

  > .ant-select-selection {
    all: unset;
    display: flex;
    background-color: inherit;
    height: unset;
    box-shadow: none !important;

    > .ant-select-selection__rendered {
      line-height: unset;
      margin: auto 5px auto 0;
      width: -webkit-fill-available;
    }

    > .ant-select-arrow {
      display: flex;
      position: unset;
      margin-top: auto;
      align-items: center;
      justify-content: center;
      height: 20px;
      width: 20px;
      cursor: pointer;
    }
  }

  :not(&.ant-select-open) {
    > .ant-select-selection > .ant-select-arrow > .ant-select-arrow-icon > svg {
      color: #676973;
      height: 10px;
      transform: scale(1.2, 1) rotate(0deg);
    }
  }
  &.ant-select-open {
    > .ant-select-selection > .ant-select-arrow > .ant-select-arrow-icon > svg {
      color: #676973;
      height: 10px;
      transform: scale(1.2, 1) rotate(180deg);
    }
  }

  &.ant-select-disabled {
    > .ant-select-selection {
      ${({ isReadonly }) => !isReadonly
        ? `
          cursor: not-allowed;
          background-color: #f7f7f7;
          border: 1px solid rgba(118,118,118,0.3);
          padding: 0 5px;
          border-radius: 4px;
          height: 32px;
        `
        : 'cursor: default;'}
    }
  }
` as StyledSelectType;

export default React.memo(Select);
