import React from 'react';
import styled from '@emotion/styled';
import { Button } from 'antd';
import { PanelCard } from '../users-common.component';
import { ButtonType } from '../../../../../../types';
import { useTranslation } from 'react-i18next';

interface OrganizationUserFormSidePanelProps {
  isSubmitEnabled?: boolean;
  isSubmitting?: boolean;
  isSubmitAllowed?: boolean;
  isDirty?: boolean;
}

const OrganizationUserFormSidePanel = (props: OrganizationUserFormSidePanelProps) => {
  const {
    isSubmitEnabled = true,
    isSubmitting = false,
    isSubmitAllowed = true,
    isDirty = false,
  } = props;

  const { t } = useTranslation();


  return (
    <PanelCard>
      {isSubmitAllowed && (
        <SaveButton
          type="primary"
          htmlType="submit"
          size="large"
          disabled={!isSubmitEnabled || isSubmitting || !isDirty}
          loading={isSubmitting}
        >
          {t('save')}
        </SaveButton>
      )}
    </PanelCard>
  );
};

const SaveButton = styled(Button)`
  width: 100%;
` as ButtonType;

export default OrganizationUserFormSidePanel;
