export enum DeployStatus {
  Queued = 'queued',
  Deploying = 'deploying',
  Done = 'done',
  Failed = 'error',
}

export default interface Deploy {
  environmentName: string;
  requiredForDeploy: boolean;
  status: DeployStatus;
  type: string;
  url: string;
  data?: {
    queueTime?: string;
  };
}

export const DeployStatusColors: { [key: string]: string } = {
  [DeployStatus.Done]: '#00ae48',
  [DeployStatus.Queued]: '#dddddd',
  [DeployStatus.Deploying]: 'blue',
  [DeployStatus.Failed]: 'red',
};