import { action, Action, select, Select, thunk, Thunk } from 'easy-peasy';
import { set } from 'lodash';
import { ApiError } from '../../../services/api/api-error';
import Injections from '../../injections.interface';

const getProvider = (provider: string) => provider.replace('@ombori/', '');

export const routes = {
  schema: (release: string, provider: string) =>
    ['api', 'schemas', release, 'provider', getProvider(provider)].join('/'),
  metaSchema: (release: string, provider: string) =>
    ['api', 'schemas', release, 'meta', getProvider(provider)].join('/'),
};

interface SchemaData {
  schema: any;
  metaSchema: any;
}

export interface SchemaModel {
  data: {
    [provider: string]: {
      [release: string]: SchemaData;
    };
  };
  loading: {
    [provider: string]: {
      [release: string]: boolean;
    };
  };
  error: {
    [provider: string]: {
      [release: string]: ApiError | null;
    };
  };
  loaded: Select<SchemaModel, (provider: string, release: string) => boolean>;
  setData: Action<
    SchemaModel,
    { release: string; provider: string; schema: any; metaSchema: any }
  >;
  setError: Action<
    SchemaModel,
    { release: string; provider: string; error: ApiError | null }
  >;
  setLoading: Action<
    SchemaModel,
    { release: string; provider: string; loading: boolean }
  >;
  fetch: Thunk<SchemaModel, { release: string; provider: string }, Injections>;
}

const schemaModel: SchemaModel = {
  data: {},
  loading: {},
  error: {},
  loaded: select((state) => (provider, release) =>
    !!state.data[provider] &&
    !!state.data[provider][release] &&
    state.loading[provider] &&
    !state.loading[provider][release],
  ),
  setData: action((state, { release, provider, schema, metaSchema }) => {
    set(state.data, [provider, release], { schema, metaSchema });
  }),
  setError: action((state, { release, provider, error }) => {
    set(state.error, [provider, release], error);
  }),
  setLoading: action((state, { release, provider, loading }) => {
    set(state.loading, [provider, release], loading);
  }),
  fetch: thunk(async (actions, { release, provider }, { injections }) => {
    actions.setLoading({ release, provider, loading: true });
    actions.setError({ release, provider, error: null });

    try {
      const [schema, metaSchema] = await Promise.all([
        injections.apiService.get<any>(routes.schema(release, provider)),
        injections.apiService.get<any>(routes.metaSchema(release, provider)),
      ]);
      actions.setData({ release, provider, schema, metaSchema });
    } catch (error) {
      actions.setError({ release, provider, error });
    } finally {
      actions.setLoading({ release, provider, loading: false });
    }
  }),
};

export default schemaModel;
