import React, { useMemo } from 'react';
import { Empty } from 'antd';
import { toPairs } from 'lodash';
import styled from '@emotion/styled';
import { ApiError } from '../../../../../../services/api/api-error';
import ErrorView from '../../../../../common/error-view/error-view.component';
import Overlay from '../../../../../common/overlay/overlay.component';
import Spinner from '../../../../../common/spinner/spinner.component';
import ErrorBoundary from '../../../../../common/error-boundary/error-boundary.component';
import { Day, Event, sumDays } from '../../../../../common/overview-report/utils';
import SessionsCard from '../../../../../common/overview-report/charts/sessions-card.component';
import AreaCard from '../../../../../common/overview-report/charts/area-card.component';
import PieCard from '../../../../../common/overview-report/charts/pie-card.component';
import ListCard from '../../../../../common/overview-report/charts/list-card.component';
import LabeledValue from '../../../../../common/overview-report/charts/labeled-value.component';
import transformDeviceId from './transformDeviceId';
import UniversalDevice from '../../../../../../store/types/universal-device';

interface OverviewProps {
  devices: UniversalDevice[];
  fetchReport: any;
  report: any | null;
  loading: boolean;
  error: ApiError | null;
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  justify-items: stretch;

  @media (min-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1920px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const OverviewReport = (props: OverviewProps) => {
  const { loading, report, error, devices } = props;

  const cards = useMemo(() => {
    const result = [];

    if (report) {
      if (report.SESSION_START && report.SESSION_DURATION) {
        result.push(
          <SessionsCard
            key="SESSIONS"
            data={report.SESSION_START}
            duration={report.SESSION_DURATION}
          />,
        );
      }

      if (report.SESSION_START) {
        result.push(
          <PieCard
            key="DEVICE_USAGE"
            title="Sessions per device"
            data={transformDeviceId(report.SESSION_START, devices)}
            showTotal={false}
          />,
        );
      }

      if (report.APP_START) {
        result.push(
          <PieCard
            key="APP_START"
            title="App starts per device"
            data={transformDeviceId(report.APP_START, devices)}
            showTotal={false}
          />,
        );
      }

      if (report.CONTENT_VIEW) {
        result.push(
          <PieCard key="CONTENT_VIEW" title="Content views" data={report.CONTENT_VIEW} />,
        );
      }

      if (report.CATEGORY_VIEW) {
        result.push(
          <PieCard
            key="CATEGORY_VIEW"
            title="Category views"
            data={report.CATEGORY_VIEW}
          />,
        );
      }

      if (report.PRODUCT_VIEW) {
        result.push(
          <PieCard key="PRODUCT_VIEW" title="Product views" data={report.PRODUCT_VIEW} />,
        );
      }

      if (report.RECOMMENDATION) {
        result.push(
          <PieCard
            key="RECOMMENDATION"
            title="Recommendation product views"
            data={report.RECOMMENDATION}
          />,
        );
      }

      if (report.RECOMMENDATION_TYPE) {
        result.push(
          <PieCard
            key="RECOMMENDATION_TYPE"
            title="Recommendation type views"
            data={report.RECOMMENDATION_TYPE}
          />,
        );
      }

      if (report.BARCODE_SCAN) {
        result.push(
          <PieCard
            key="BARCODE_SCAN"
            title="Product barcode scans"
            data={report.BARCODE_SCAN}
          />,
        );
      }

      if (report.RFID_SCAN) {
        result.push(
          <PieCard key="RFID_SCAN" title="Product rfid scans" data={report.RFID_SCAN} />,
        );
      }

      if (report.BRING_ME) {
        result.push(
          <PieCard key="BRING_ME" title="Bring me products" data={report.BRING_ME} />,
        );
      }

      if (report.CART_ADD) {
        result.push(
          <AreaCard
            key="CART_ADD"
            title="Items added to shopping bag"
            data={report.CART_ADD}
          >
            {report.SESSION_START && (
              <div style={{ flex: 1 }}>
                <LabeledValue
                  style={{ marginBottom: 16 }}
                  size="md"
                  label="Total items added to shopping bag"
                  value={sumDays(report.CART_ADD)}
                />
                <LabeledValue
                  style={{ marginBottom: 16 }}
                  size="md"
                  label="Average number of items added to shopping bag per session"
                  value={Math.ceil(
                    sumDays(report.CART_ADD) / sumDays(report.SESSION_START),
                  )}
                />
              </div>
            )}
          </AreaCard>,
        );

        // Insert added to bag product logic here
        const products: Day[] = (report.CART_ADD as Day[]).map(([day, events]) => [
          day,
          toPairs(
            events.reduce((acc: any, ev) => {
              let label = '';

              if (ev.label) {
                const splitLabel = ev.label.split('/');
                label = splitLabel.length > 0 ? splitLabel[0] : '';
              }

              if (label) {
                acc[label] = (acc[label] || 0) + ev.value;
              }

              return acc;
            }, {}),
          ).map(([label, value]): Event => ({ label, value: value as number })),
        ]);

        result.push(
          <ListCard
            key="CART_ADD_PRODUCTS"
            title="Most popular products added to shopping bag"
            info=""
            data={products}
          />,
        );
      }

      if (report.LOOK_VIEW) {
        result.push(
          <AreaCard key="LOOK_VIEW" title="Look views" data={report.LOOK_VIEW} />,
        );
      }

      if (report.CHECKOUT) {
        result.push(<AreaCard key="CHECKOUT" title="Checkouts" data={report.CHECKOUT} />);
      }

      if (report.CART_VIEW) {
        result.push(
          <AreaCard key="CART_VIEW" title="Cart views" data={report.CART_VIEW} />,
        );
      }

      if (report.CART_REMOVE) {
        result.push(
          <AreaCard key="CART_REMOVE" title="Cart removes" data={report.CART_REMOVE} />,
        );
      }

      if (report.CART_CLEAR) {
        result.push(
          <AreaCard key="CART_CLEAR" title="Cart clears" data={report.CART_CLEAR} />,
        );
      }

      if (report.PRINT_BARCODE_RECEIPT) {
        result.push(
          <AreaCard
            key="PRINT_BARCODE_RECEIPT"
            title="Print barcodes"
            data={report.PRINT_BARCODE_RECEIPT}
          />,
        );
      }

      if (report.JOINED_QUEUE) {
        result.push(
          <AreaCard
            key="JOINED_QUEUE"
            title="Created tasks"
            data={report.JOINED_QUEUE}
          />,
        );
      }

      if (report.JOINED_QUEUE) {
        result.push(
          <AreaCard
            key="JOINED_QUEUE"
            title="Tickets created"
            data={report.JOINED_QUEUE}
          />,
        );
      }

      if (report.POSITION_CALLED_NEXT) {
        result.push(
          <AreaCard
            key="POSITION_CALLED_NEXT"
            title="Tickets preparing"
            data={report.POSITION_CALLED_NEXT}
          />,
        );
      }

      if (report.POSITION_CANCELED_BY_CUSTOMER) {
        result.push(
          <AreaCard
            key="POSITION_CANCELED_BY_CUSTOMER"
            title="Tickets cancelled by customer"
            data={report.POSITION_CANCELED_BY_CUSTOMER}
          />,
        );
      }

      if (report.POSITION_REJECTED) {
        result.push(
          <AreaCard
            key="POSITION_REJECTED"
            title="Tickets cancelled by staff"
            data={report.POSITION_REJECTED}
          />,
        );
      }

      if (report.POSITION_CHECKED_IN) {
        result.push(
          <AreaCard
            key="POSITION_CHECKED_IN"
            title="Tickets delivered"
            data={report.POSITION_CHECKED_IN}
          />,
        );
      }

      if (report.POSITION_CHECKED_OUT) {
        result.push(
          <AreaCard
            key="POSITION_CHECKED_OUT"
            title="Tickets completed"
            data={report.POSITION_CHECKED_OUT}
          />,
        );
      }

      if (report.QR_RUN) {
        result.push(<AreaCard key="QR_RUN" title="Qr run counts" data={report.QR_RUN} />);
      }

      if (report.DETECT_MOOD) {
        result.push(
          <PieCard
            key="DETECT_MOOD"
            title="Mood"
            data={report.DETECT_MOOD}
            showTotal={false}
          />,
        );
      }

      if (report.DETECT_AGE) {
        result.push(
          <PieCard
            key="DETECT_AGE"
            title="Age"
            data={report.DETECT_AGE}
            showTotal={false}
          />,
        );
      }

      if (report.DETECT_GENDER) {
        result.push(
          <PieCard
            key="DETECT_GENDER"
            title="Gender"
            data={report.DETECT_GENDER}
            showTotal={false}
          />,
        );
      }

      if (report.CLICK_CALL_FOR_HELP) {
        result.push(
          <AreaCard
            key="CLICK_CALL_FOR_HELP"
            title="Call for help (Click)"
            data={report.CLICK_CALL_FOR_HELP}
          />,
        );
      }

      if (report.REQUEST_CALL_FOR_HELP) {
        result.push(
          <AreaCard
            key="REQUEST_CALL_FOR_HELP"
            title="Call for help (Request)"
            data={report.REQUEST_CALL_FOR_HELP}
          />,
        );
      }

      if (report.STOCK_STATUS_SOURCE) {
        result.push(
          <PieCard
            key="STOCK_STATUS_SOURCE"
            title="Stock status check"
            info=""
            data={report.STOCK_STATUS_SOURCE}
            showTotal={true}
          />,
        );
      }

      if (report.SCREEN_TIMEOUT) {
        result.push(
          <PieCard
            key="SCREEN_TIMEOUT"
            title="Screen before timeout"
            info=""
            data={report.SCREEN_TIMEOUT}
            showTotal={false}
          />,
        );
      }

      if (report.SEARCH) {
        result.push(
          <ListCard
            key="SEARCH"
            title="Searched Keywords"
            info=""
            data={report.SEARCH}
          />,
        );
      }

      if (report.FILTER_OPTION) {
        result.push(
          <ListCard
            key="FILTER_OPTION"
            title="Filter option (Key: Value)"
            info=""
            data={report.FILTER_OPTION}
          />,
        );
      }

      if (report.RATING) {
        result.push(
          <PieCard key="RATING" title="Rating" data={report.RATING} showTotal={false} />,
        );

        const promoterScore = 5;
        const passiveScore = 4;

        const { detractors, promoters, respondents } = (report.RATING as Day[]).reduce(
          (acc, [, events]) => {
            let newPromoters = 0;
            let newDetractors = 0;
            let newRespondents = 0;

            events.forEach((ev) => {
              const currLabel = ev.label || '';
              const currScore = parseInt(currLabel);

              if (currScore === promoterScore) {
                newPromoters += ev.value;
              }

              if (currScore < passiveScore) {
                newDetractors += ev.value;
              }

              newRespondents += ev.value;
            });

            return {
              ...acc,
              detractors: acc.detractors += newDetractors,
              promoters: acc.promoters += newPromoters,
              respondents: acc.respondents += newRespondents,
            };
          },
          {
            promoters: 0,
            detractors: 0,
            respondents: 0,
          },
        );

        const npsPercentage =
          Math.floor(((promoters - detractors) / respondents) * 10000) / 100;

        result.push(
          <ListCard
            key="NPS"
            title="NPS"
            info=""
            data={[['NPS', [{ label: 'NPS', value: npsPercentage }]]]}
            hidePercentage={true}
          />,
        );
      }

      if (report.FEEDBACK) {
        result.push(
          <AreaCard
            key="FEEDBACK_COUNT"
            title="Feedback counts"
            data={report.FEEDBACK}
          />,
        );

        result.push(
          <ListCard
            key="FEEDBACK_LIST"
            title="Feedbacks"
            info=""
            data={report.FEEDBACK}
            hidePercentage={true}
          />,
        );
      }
    }

    return result;
  }, [report, devices]);

  return (
    <ErrorBoundary>
      {error && <ErrorView />}
      {loading && !error && (
        <Overlay
          style={{ backgroundColor: 'rgba(251, 251, 253, 0.5)', zIndex: 1, top: 80 }}
        >
          <Spinner />
        </Overlay>
      )}
      {report && !error && (
        <Container>
          {cards.length > 0 ? (
            cards
          ) : (
            <Empty description="No analytics data" css={{ gridColumn: '1/-1' }} />
          )}
        </Container>
      )}
    </ErrorBoundary>
  );
};

export default OverviewReport;
