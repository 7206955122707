import React, { useCallback, useState } from 'react';
import { omit, mapKeys } from 'lodash';
import { Empty } from 'antd';
import styled from '@emotion/styled';
import { FieldProps } from 'react-jsonschema-form';
import { useTranslation } from 'react-i18next';
import TagsList from '../../../../../../common/tags-list/tags-list.component';
import ModalForm from '../../../../../../common/modal-form/modal-form.component';
import ModalFormField from '../../../../../../common/modal-form/modal-form-field/modal-form-field.component';
import FormInput from '../../../../../../common/form-input/form-input.component';
import DataCard from '../../../../../../common/data-card/data-card.component';

const TagsEmpty = styled(Empty)`
  margin: 0;
`;

interface Tags {
  [key: string]: string;
}

interface TagFormValues {
  key: string;
  value: string;
}

const getDeviceTag = (tag: string) => {
  const [key, value] = tag.split(':').map((val) => val.trim());
  return { key, value };
};

const initialTagFormValues = { key: '', value: '' };

const DeviceTags = ({ formData, schema, onChange, readonly }: FieldProps<Tags>) => {
  const { t } = useTranslation();
  const [selectedTag, setSelectedTag] = useState<TagFormValues | undefined>(undefined);

  const isModalVisible = !!selectedTag;

  const handleTagClose = (tag: string) => {
    onChange(omit(formData, getDeviceTag(tag).key));
  };

  const handleTagAdd = () => {
    setSelectedTag(initialTagFormValues);
  };

  const handleTagEdit = (tag: string) => {
    const deviceTag = getDeviceTag(tag);
    setSelectedTag(deviceTag);
  };

  const handleModalCancel = () => {
    setSelectedTag(undefined);
  };

  const handleModalSubmit = useCallback(
    async (values: TagFormValues) => {
      if (selectedTag) {
        const value = mapKeys(formData, (_, key) =>
          key === selectedTag.key ? values.key : key,
        );
        value[values.key] = values.value;
        onChange(value);
        setSelectedTag(undefined);
      }
    },
    [onChange, setSelectedTag, formData, selectedTag],
  );

  const tagsList = Object.keys(formData).map((key) => `${key}: ${formData[key]}`);

  const tagFormSchema: any = {
    type: 'object',
    properties: {
      key: {
        title: t('key'),
        type: 'string',
        minLength: 1,
        pattern: '^[^\\s]*$',
      },
      value: {
        title: t('value'),
        type: 'string',
        minLength: 1,
        pattern: '^[^\\s]*$',
      },
    },
    required: ['key', 'value'],
  };

  return (
    <>
      {/* eslint-disable-next-line */}
      <label className="control-label">{schema.title}</label>
      <div>
        <TagsList
          readOnly={readonly}
          tags={tagsList}
          onTagRemove={handleTagClose}
          onTagAdd={handleTagAdd}
          onTagEdit={handleTagEdit}
        />
        {!tagsList.length && readonly && (
          <TagsEmpty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t('noTagsPresent')}
          />
        )}
      </div>
      <ModalForm
        schema={tagFormSchema}
        initialValues={selectedTag}
        title="Tag"
        visible={isModalVisible}
        onClose={handleModalCancel}
        onSubmit={handleModalSubmit}
      >
        <DataCard>
          <ModalFormField name="key" label={t('key')} component={FormInput} required />
          <ModalFormField
            name="value"
            label={t('value')}
            component={FormInput}
            required
          />
        </DataCard>
      </ModalForm>
    </>
  );
};

export default DeviceTags;
