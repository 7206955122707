import { Avatar, Button, Icon, Typography } from 'antd';
import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import Markdown from 'react-markdown';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import AppsLibraryInstallModalContainer from '../../../../common/apps-library/apps-library-install-modal/apps-library-install-modal.container';
import Queue from '../../../../../store/types/queue';
import { AppInstallBody } from '../../../../../store/models/organisation-apps/organisation-apps.model';
import Organisation from '../../../../../store/types/organisation';
import usePermissions from '../../../../../utils/auth/use-permissions';
import { permissionKeys } from '../../../../../utils/auth/permissions';
import OrganisationApp from '../../../../../store/types/organisation-app';
import { NON_QUEUE_CLOUD_APP_PACKAGE_NAMES } from '../../../../../utils/installations/installations-constants';
import MarketplaceSolution, {
  MarketplaceSolutionApp,
} from '../../../../../store/types/marketplace-solution';
import GridappTypeEnum from '../../../../../store/types/gridapp-type.enum';

import Overlay from '../../../../common/overlay/overlay.component';
import Spinner from '../../../../common/spinner/spinner.component';
import { formatAmount } from '../../../../../utils/currency';
import { getEntityUnit } from'../../../../../utils/apps-library/gridapp-price';
interface SolutionDetailsProps
  extends RouteComponentProps<{ organisationId: string; solutionId: string }> {
  solution: MarketplaceSolution;
  installQueue: (body: any) => Promise<Queue>;
  installApp: (data: AppInstallBody) => Promise<OrganisationApp>;
  fetchApps: (params: { organizationId: string; silent: boolean }) => void;
  fetchMarketplaceSolutions: (params: { organisationId: string }) => void;
  loaded: boolean;
  organisation?: Organisation;
}

const SolutionDetails = ({
  solution,
  match,
  installApp,
  history,
  fetchApps,
  installQueue,
  loaded,
  fetchMarketplaceSolutions,
  organisation,
}: SolutionDetailsProps) => {
  const {
    params: { organisationId },
  } = match;
  const { t } = useTranslation();
  const [appToInstall, setAppToInstall] = useState<MarketplaceSolutionApp | null>(null);
  const { isAllowed } = usePermissions(match.params.organisationId);

  useEffect(() => {
    if (!solution) {
      fetchMarketplaceSolutions({ organisationId });
    }
  }, [solution, organisationId, fetchMarketplaceSolutions]);

  const handleInstallModalSubmit = useCallback(
    async ({
      installationGroupId,
      displayName,
    }: {
      appName: string;
      displayName: string;
      installationGroupId: string;
    }) => {
      if (appToInstall) {
        const { id, type } = appToInstall;
        if (id && type) {
          let installationId;
          if (
            type === GridappTypeEnum.CLOUD &&
            !NON_QUEUE_CLOUD_APP_PACKAGE_NAMES.includes(id)
          ) {
            const queue = await installQueue({
              name: displayName,
              solution: id,
              installationGroupId,
            });
            installationId = queue.id;
          } else {
            const { id: newInstallationId } = await installApp({
              displayName,
              packageName: id,
              organizationId: organisationId,
              installationGroupId,
            } as any);

            installationId = newInstallationId;
          }
          await fetchApps({ organizationId: organisationId, silent: true });
          let redirectLocation;
          if ([GridappTypeEnum.IOT_MODULE, GridappTypeEnum.SCREEN].includes(type)) {
            redirectLocation = `/organisations/${organisationId}/apps/${installationId}/devices-universal`;
          } else if (type === GridappTypeEnum.CLOUD) {
            redirectLocation = `/organisations/${organisationId}/apps/${installationId}/settings`;
          } else {
            redirectLocation = `/organisations/${organisationId}/apps/${installationId}/builds`;
          }

          history.push(redirectLocation);
        }
      }
    },
    [appToInstall, installApp, fetchApps, history, installQueue, organisationId],
  );

  const showInstallModal = useCallback(
    (app: MarketplaceSolutionApp) => {
      setAppToInstall(app);
    },
    [setAppToInstall],
  );

  const handleInstallModalClose = useCallback(() => {
    setAppToInstall(null);
  }, [setAppToInstall]);

  const canInstallApp = isAllowed(permissionKeys.apps.create);

  const renderMarketplaceSolutionApp = useCallback(
    (app: MarketplaceSolutionApp) => {
      const { price } = app;
      let formattedPrice = null;
      const entityUnit = getEntityUnit(app.type);
      if (price && price.value > 0) {
        if (app.unit === 'day') {
          formattedPrice = `${formatAmount(
            price.currency,
            price.valueDecimal * 30,
          )} / month`;
        } else {
          formattedPrice = `${formatAmount(price.currency, price.valueDecimal)} / ${
            app.unit
          }`;
        }
      }

      formattedPrice = formattedPrice
        ? `${formattedPrice} ${entityUnit ? `/ ${entityUnit}` : ''}`
        : formattedPrice;

      const pricingFormatted = formattedPrice || '';

      return (
        <AppItem key={app.id}>
          <InstallSectionAppIconWrapper src={app.icon} alt={`${app.displayName} icon`} />
          <AppInstallInfo>
            {app.displayName}
            {pricingFormatted && <AppPrice>{pricingFormatted}</AppPrice>}
          </AppInstallInfo>
          {canInstallApp && (
            <Button onClick={() => showInstallModal(app)} size="large" type="primary">
              {t('marketplaceInstallAction')}
            </Button>
          )}
        </AppItem>
      );
    },
    [canInstallApp, t, showInstallModal],
  );

  if (!loaded) {
    return (
      <Overlay>
        <Spinner />
      </Overlay>
    );
  }

  // @ts-ignore
  return (
    <>
      <BackButton>
        <Link to={`/organisations/${organisationId}/marketplace/browse/public-apps`}>
          <span>
            <Icon type="left" />
          </span>
          {t('marketplaceBack')}
        </Link>
      </BackButton>
      <Header>
        <AppIconWrapper>
          <DefaultAppIconAvatar shape="square" size={64} src={solution.icon} />
        </AppIconWrapper>
        <AppInfo>
          <Typography.Title level={2}>{solution.title}</Typography.Title>
          {solution.author && (
            <Subheader>{t('appBy', { author: solution.author })}</Subheader>
          )}
        </AppInfo>
        {canInstallApp && (
          <Button
            onClick={() => {
              window.location.href = '#install';
            }}
            size="large"
            type="primary"
          >
            {t('marketplaceInstallAction')}
          </Button>
        )}
      </Header>
      <MediaContentWrapper className="content-body">
        <MediaContent>
          {solution.videos.length > 0 && (
            <VideoItem>
              <VideoWrapper>
                <iframe src={solution.videos[0]} title="YouTube video player" />
              </VideoWrapper>
            </VideoItem>
          )}
          {solution.screenshots &&
            solution.screenshots.map((url) => (
              <SlideItem key={url}>
                <Slide>
                  <Screenshot src={url} />
                </Slide>
              </SlideItem>
            ))}
        </MediaContent>
      </MediaContentWrapper>
      <Page className="content-body">
        <PageContent>
          <Sidebar>
            <InstallSection id="install">
              <InstallSectionTitle>
                {' '}
                {t('marketplaceInstallSolution')}
              </InstallSectionTitle>
              <SolutionApps>
                {solution.apps.map((app) => renderMarketplaceSolutionApp(app))}
              </SolutionApps>
              <InstallSectionSubTitle>
                {t('marketplaceRelatedSolutions')}
              </InstallSectionSubTitle>
              <RelatedApps>
                {solution.relatedMarketplaceItems.map((relatedItem) => (
                  <RelatedApp key={relatedItem.id}>
                    <RelatedAppContent>
                      <InstallSectionAppIconWrapper src={relatedItem.icon} />
                      {relatedItem.displayName}
                    </RelatedAppContent>
                    <Link
                      to={`/organisations/${organisationId}/marketplace/solution/${
                        relatedItem.id
                      }`}
                    >
                      <Button type="ghost">{t('marketplaceSetupAction')}</Button>
                    </Link>
                  </RelatedApp>
                ))}
              </RelatedApps>
            </InstallSection>
          </Sidebar>
          <Content>
            <ContentBlock>
              {solution && <StyledMarkdown>{solution.description}</StyledMarkdown>}
            </ContentBlock>
          </Content>
        </PageContent>
      </Page>
      <AppsLibraryInstallModalContainer
        app={appToInstall}
        onClose={handleInstallModalClose}
        organisationId={organisationId}
        onSubmit={handleInstallModalSubmit}
      />
    </>
  );
};

const DefaultAppIconAvatar = styled(Avatar)`
  background-color: rgba(0, 0, 0, 0.02);
`;

// Header
const BackButton = styled.span`
  display: block;
  background: rgb(245, 245, 250);
  padding: 16px;

  /* span {
    color: #0f111a;
  } */

  a {
    color: #676973;
    &:hover {
      color: #0f111a;
    }
  }
  @media screen and (min-width: 768px) {
    padding: 16px 40px;
  }
`;
const Header = styled.div`
  background: rgb(245, 245, 250);
  box-shadow: inset 0 -1px 0 0 #eaecf4, 0 2px 24px 0 rgba(0, 0, 0, 0.02);
  padding: 0 16px 16px 16px;
  display: flex;
  flex: 1;
  align-items: center;
  gap: 8px;

  button {
    display: none;
  }

  @media screen and (min-width: 768px) {
    padding: 0 40px 40px 40px;
    gap: 16px;

    button {
      display: block;
    }
  }
`;
const AppInfo = styled.div`
  flex: 1;
`;
const AppIconWrapper = styled.div`
  display: block;
  flex: 0 64px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.04),
    0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #fff;
  background: #fff;

  @media screen and (min-width: 768px) {
    width: 80px !important;
    height: 80px !important;
    flex: 0 80px;
    border-radius: 16px;

    span {
      line-height: 80px !important;
      width: 80px !important;
      height: 80px !important;
    }
  }
`;

const Subheader = styled(Typography.Paragraph)`
  margin: 8px 0 0 0;
  font-size: 14px;
  color: #676973;
`;

// Media Content
const MediaContentWrapper = styled.div`
  width: 100%;
  display: block;

  /* disable content-body @media padding */
  padding: 0px !important;
`;

const MediaContent = styled.ul`
  position: relative;
  width: calc(100% - 8px);
  bottom: 0;
  display: flex;
  overflow: scroll;
  scroll-snap-type: x mandatory;

  right: -8px;
  padding: 20px 0 8px 12px;

  @media screen and (min-width: 1200px) {
    padding: 24px 0 8px 16px;
  }

  @media screen and (min-width: 1600px) {
    padding: 40px 0 8px 32px;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-corner,
  &::-webkit-resizer {
    opacity: 0;
  }

  &::-webkit-scrollbar-track {
    background: 0;
    box-shadow: 0 0 0;
  }

  &::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 8px;
    transform: translateY(4px);
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background: #0f111a;
    }
  }
`;

const VideoItem = styled.li`
  flex: 0 0 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
  display: block;
  margin-right: 8px;
  /* margin-left: 40px; */
  scroll-snap-align: center;

  @media screen and (min-width: 992px) {
    max-width: 48vw;
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  height: 0;
  padding-top: 56.5%;
  display: block;
  position: relative;

  iframe,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 16px;
  }
`;

const SlideItem = styled.li`
  flex: 0 0 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
  display: block;
  margin-right: 8px;
  scroll-snap-align: center;

  @media screen and (min-width: 992px) {
    max-width: 27vw;
  }
`;

const Slide = styled.li`
  width: 100%;
  height: 0;
  padding-top: 56.5%;
  display: block;
  position: relative;

  @media screen and (min-width: 992px) {
    padding-top: 100%;
  }

  &:hover {
    cursor: pointer;
  }
`;

const Screenshot = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
`;

// Main page
// Override minimum @media of class content-body to match Media Content
const Page = styled.div`
  padding: 20px !important;

  @media screen and (min-width: 1200px) {
    padding: 24px !important;
  }
  @media screen and (min-width: 1600px) {
    padding: 40px !important;
  }
`;

const PageContent = styled.div`
  flex: 1;
  display: block;

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
  }
`;

const Content = styled.div`
  grid-column: 1;
`;
const ContentBlock = styled.div`
  display: block;
  padding: 16px;
  border-radius: 16px;

  @media screen and (min-width: 768px) {
    padding: 32px 40px 40px 40px;
    background: rgb(245, 245, 250);
  }
`;

// Sidebar
const Sidebar = styled.div`
  grid-column: 2;
  grid-row: 1;
`;
const InstallSection = styled.div`
  width: 100%;
  flex-shrink: 0;
  display: block;
  padding: 16px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 4px 8px 0px rgba(0, 0, 0, 0.02),
    0px 8px 16px 0px rgba(0, 0, 0, 0.02), 0px 16px 32px 0px rgba(0, 0, 0, 0.02),
    0px 32px 64px 0px rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  overflow: visible;
  border-radius: 16px;
  border: 1px solid #dddddd;
  margin-bottom: 16px;

  @media screen and (min-width: 768px) {
    position: sticky;
    top: 40px;
    padding: 24px;
  }
`;
const InstallSectionTitle = styled.span`
  font-size: 18px;
  display: block;
  font-weight: bold;
`;
const InstallSectionSubTitle = styled(InstallSectionTitle)`
  font-size: 14px;
  margin-top: 24px;
`;
const SolutionApps = styled.ul`
  background: #fbfbfd;
  display: block;
  margin: 8px 0 0 0;
  padding: 0;
  list-style: none;
  border-radius: 4px;
  border: 1px solid #ddd;
`;
const AppItem = styled.li`
  padding: 12px;
  font-weight: bold;
  box-shadow: inset 0px -1px 0px 0px #ddd;
  display: flex;
  align-items: center;

  button {
    margin-left: auto;
  }

  &:last-child {
    box-shadow: 0 0 0;
  }
`;

const AppInstallInfo = styled.div`
  flex: 1;
  display: block;
  margin-right: 8px;
`;
const AppPrice = styled.span`
  display: block;
  font-weight: normal;
  opacity: 0.48;
`;

const RelatedApps = styled(SolutionApps)`
  background: 0;
  border: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  margin-top: 0;
`;
const RelatedApp = styled(AppItem)`
  font-weight: normal;
  padding: 12px 0;
  gap: 10px;
`;
const InstallSectionAppIconWrapper = styled.img`
  width: 40px;
  height: 40px;
  flex: 0 40px;
  border-radius: 12px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.04),
    0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border: 1px solid #fff;
  margin-right: 8px;
  line-height: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    font-size: 20px;
  }
`;

const RelatedAppContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const StyledMarkdown = styled(Markdown)<any>`
  font-size: 14px;
  margin-top: 8px;

  & p {
    margin-bottom: 16px;
    line-height: 1.6em;
  }

  & ul {
    list-style: inside;
    margin-left: 8px;
  }

  & h2 {
    font-size: 2em;
    padding-bottom: 16px;
  }

  & h3 {
    font-size: 1.4em;
    padding: 16px 0px 8px 0px;
  }

  & h4 {
    font-size: 1.2em;
    padding: 16px 0px 4px 0px;
  }

  & li {
    line-height: 1.5em;
  }
`;

export default SolutionDetails;
