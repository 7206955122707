import { TFunction } from 'i18next';
import { IdSchema } from 'react-jsonschema-form';
import { FontMimeType } from '../../../../../store/types/media';

type BuildSchemaResponse = Record<string, string | number | boolean | object>;

export const buildFieldSchema = (t: TFunction): BuildSchemaResponse => {
  const fieldSchema = {
    type: 'object',
    title: 'Font',
    properties: {
      ref: {
        const: 'media',
      },
      type: {
        type: 'string',
        enum: Object.values(FontMimeType),
      },
      id: {
        type: 'string',
      },
    },
  };

  return fieldSchema;
};

export const buildUiSchema = (t: TFunction): BuildSchemaResponse => {
  const metaSchema = {
    'ui:field': 'filePicker',
  };

  return metaSchema;
};

export const buildRegistry = (tenantId = '') =>{
  return {
      fields: {},
      widgets: {},
      definitions: {},
      formContext: {
          organisationId: tenantId,
      },
  };
};


export const buildIdSchema = (): IdSchema => {
  // @ts-ignore
  return {
      $id: 'root_font',
      'ref': {
          $id: 'root_font_ref',
      },
      'type': {
          $id: 'root_font_type',
      },
      'id': {
          $id: 'root_font_id',
      },
  };
};
