import React from 'react';

interface CustomTitleFieldProps {
  id: string;
  title: string;
  required: boolean;
}

const CustomTitleField = ({ title, required }: any) => {
  const legend = 'HI';
  return <div id="custom">{legend}</div>;
};

export default CustomTitleField;
