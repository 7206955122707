import React from 'react';
import styled from '@emotion/styled';
import OmboriLogoSvg from '../../../../assets/images/phygrid-icon.svg';
import { useAppTheme } from '../../../../utils/use-app-theme';

export default function Footer({ showPoweredBy = false }: { showPoweredBy?: boolean }) {
  const appTheme = useAppTheme();

  return (
    <FooterContainer>
      {(showPoweredBy || appTheme.showPoweredByInIdentifiedSession) && (
        <FooterLeft>
          Powered by&nbsp;
          <OmboriLink target="_blank" href="https://omborigrid.com">
            Phygrid, an Ombori company
            <OmboriLogo src={OmboriLogoSvg} />
          </OmboriLink>
        </FooterLeft>
      )}
      <FooterRight>
        <OmboriLink target="_blank" href="https://omborigrid.com/privacy">
          Privacy Policy
        </OmboriLink>
        &nbsp;and&nbsp;
        <OmboriLink target="_blank" href="https://omborigrid.com/terms">
          Terms of Service
        </OmboriLink>
      </FooterRight>
    </FooterContainer>
  );
}

const FooterContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 30px;
  font-size: 10px;
  display: flex;
  width: 100%;
  border-top: solid 1px #f2f2f2;
  background-color: #ffffff;
  padding: 0px 16px;

  @media screen and (max-width: 768px) {
    padding: 12px;
  }
`;

const FooterLeft = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
`;

const FooterRight = styled(FooterLeft)`
  justify-content: flex-end;
`;

const OmboriLink = styled.a`
  color: #5f06e4;
  text-decoration: none;
  justify-content: center;
  display: flex;

  &:hover {
    text-decoration: underline;
  }
`;

const OmboriLogo = styled.img`
  margin-left: 4px;
  height: 16px;
`;
