import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Button } from 'antd';
import { ExternalLink } from 'react-feather';
import Pipeline from '../../../../../../store/types/pipeline';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';

interface PipelineWithUrl extends Pipeline {
  metadata: {
    url: string;
  };
}

const Container = styled.div`
  margin-right: 25px;
`;

const UrlContainer = styled.div``;

const Link = styled(Button)`
  display: flex;
  align-items: center;
  padding: 0;
` as any;

const BuildUrlLink = styled(Link)`
  display: inline;
  line-height: unset !important;
` as any;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  margin-right: 4px;

  svg {
    display: block;
    width: 100%;
  }
`;

const StyledUrl = styled(Text)`
  white-space: normal !important;
  word-break: break-all;
  color: #689bc4;
  text-align: left;
  pointer-events: visible !important;
`;

interface AppLinkProps {
  pipelines: Pipeline[];
  showUrl?: boolean;
  containerStyles?: string;
}

const AppLink: React.FC<AppLinkProps> = ({ pipelines, showUrl = false, containerStyles }) => {
  const {t} = useTranslation();
  const appUrls = useMemo(
    () =>
      pipelines
        .filter(
          (pipeline): pipeline is PipelineWithUrl => {
            const metadata = pipeline.metadata;
            const appUrl = metadata && metadata.url;

            return !!appUrl;
          },
        )
        .map((pipeline) => pipeline.metadata.url),
    [pipelines],
  );

  const AppLinkContainer = showUrl ? UrlContainer : Container;
  const Navigator = showUrl ? BuildUrlLink : Link;

  return (
    <AppLinkContainer css={css(containerStyles || '')}>
      {appUrls.map((url, index) => {
        return (
          <Navigator key={index} type="link" href={url} target="_blank">
            {showUrl ? ( <StyledUrl>{url}</StyledUrl>
            ) : (
              <>
                <Icon>
                  <ExternalLink />
                </Icon>
                App
              </>
            )}
          </Navigator>
        );
      })}
      {showUrl && appUrls.length === 0 && <NoData>{t('builds.noData')}</NoData>}
    </AppLinkContainer>
  );
};

export default AppLink;

const NoData = styled.div`  
  font-style: italic;
  color: #aaadab;
`;