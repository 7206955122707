import React from 'react';
import { useStore } from 'easy-peasy';
import { RouteProps } from 'react-router';
import { RootState } from '../../../store/models/root.model';
import ProtectedRoute from './protected-route.component';

export interface ProtectedRouteContainerProps extends RouteProps {
  permissionPath?: string;
  loginOnly?: boolean;
}

const ProtectedRouteContainer = (props: ProtectedRouteContainerProps) => {
  const user = useStore<RootState>((state) => state.auth.user);

  return <ProtectedRoute user={user} {...props} />;
};

export default ProtectedRouteContainer;
