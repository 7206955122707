import React, { useCallback, useMemo, useState } from 'react';
import {
  DataMatrixColumn,
  DataMatrixColumnDataType,
  DataMatrixDataSource,
  GridStyles,
  InteractionType,
  DownloadFileColumn,
  SpaceDataSourceType,
} from '@ombori/grid-reports';
import { DataResidencyEnum } from '../../../../../store/types/organisation';
import { useSpaces } from '../../../use-spaces';
import useInstallations from '../../../use-installation';
import CardContainer from '../card-container';
import SpacesTable, { OrganisationSpaceWithChildren } from './spaces-table';
import InstallationsTable from './installations-table';
import styled from '@emotion/styled';
import { Select, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { OptionProps } from 'antd/lib/select';
import OrganisationApp from '../../../../../store/types/organisation-app';
import getEventType from './get-event-type';
import {
  useInstallationsEventsAndSessionsByDay,
  useSpacesInstallationEventsAndSessionsByDay,
} from '../../../use-analytics-report';
import CardTitleDownloadButtonWrapper from '../card-title-download-button-wrapper';
import SpacesDataMatrixLegacy from './space-data-matrix/space-data-matrix-legacy';
import SpacesDataMatrixV1 from './space-data-matrix/space-data-matrix-v1';
import DevicesDataMatrixLegacy from './device-data-matrix/device-data-matrix-legacy';
import DevicesDataMatrixV1 from './device-data-matrix/device-data-matrix-v1';
import downloadCSV from '../../../../../utils/download-csv';
import { getCsvDataFileName } from '../../../../../utils/convert-csv';
import Message from '../../../message';
import * as Sentry from '@sentry/browser';
const { Option } = Select;

const SelectComponent = styled(Select)`
    :nth-of-type(1) {
        margin-right: 10px;
    }
    .ant-select-selection__rendered {
        width: 100px;
        text-transform: capitalize;
    }
`;
const Title = styled.div`
    font-size: 16px;
    margin-right: 8px;
`;

const TitleWithSelectWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

type TitleWithSelectProps = {
  title: string;
  items: {
    key: string;
    label: string;
  }[];
  onSelect: (key: string | undefined) => void;
};

const TitleWithSelect: React.FC<TitleWithSelectProps> = ({ title, items, onSelect }) => {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (value: unknown) => {
      const { key } = value as { key: string | undefined };
      onSelect(key);
    },
    [onSelect],
  );

  const handleFilterOption = useCallback(
    (inputValue: string, option: React.ReactElement<OptionProps>): boolean => {
      return typeof option.props.children === 'string'
        ? option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        : false;
    },
    [],
  );

  return (
    <TitleWithSelectWrapper>
      <Title>{title}</Title>

      <SelectComponent
        defaultValue={{ key: 'all', label: t('all') }}
        onChange={handleChange}
        labelInValue
        showSearch
        filterOption={handleFilterOption}
      >
        <Option value="all">{t('all')}</Option>
        {items.map((item) => (
          <Option
            key={item.key}
            value={item.key}
            style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}
          >
            {item.label}
          </Option>
        ))}
      </SelectComponent>
    </TitleWithSelectWrapper>
  );
};

interface DataMatrixProps {
  tenantId: string;
  installationId?: string;
  dateFrom: string;
  dateTo: string;
  dataResidency: DataResidencyEnum;
  title?: string;
  dataSource: DataMatrixDataSource;
  dataSourceType?: SpaceDataSourceType;
  columns: DataMatrixColumn[];
  downloadFileColumns?: DownloadFileColumn[];
  tableColumns?: DownloadFileColumn[];
  gridStyles?: GridStyles;
  isVisibleWithoutData?: boolean;
}

const InstallationsDataMatrix: React.FC<Omit<DataMatrixProps, 'dataSource'>> = React.memo(({
                                                                                             tenantId,
                                                                                             dateFrom,
                                                                                             dateTo,
                                                                                             dataResidency,
                                                                                             title = '',
                                                                                             columns,
                                                                                             downloadFileColumns,
                                                                                             gridStyles,
                                                                                             isVisibleWithoutData,
                                                                                           }) => {
  const eventType = getEventType(columns);

  const isEventsQueryEnabled = columns.some(
    (column) => column.dataType.type === DataMatrixColumnDataType.Events,
  );

  const isSessionsQueryEnabled = columns.some(
    (column) => column.dataType.type === DataMatrixColumnDataType.Sessions,
  );

  const dataFetchingState = useInstallationsEventsAndSessionsByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
    interactionType: InteractionType.Interactive,
    eventType,
    isEventsQueryEnabled,
    isSessionsQueryEnabled,
  });

  const eventsData = useMemo(
    () => (dataFetchingState.data ? dataFetchingState.data.events : []),
    [dataFetchingState],
  );

  const sessionsData = useMemo(
    () => (dataFetchingState.data ? dataFetchingState.data.sessions : []),
    [dataFetchingState],
  );

  const [selectedInstallation, setSelectedInstallation] = useState<
    OrganisationApp | undefined
  >(undefined);

  const installationsFetchingState = useInstallations(tenantId);

  const { installationOptions, installations } = useMemo(() => {
    return {
      installationOptions: installationsFetchingState.data
        ? installationsFetchingState.data.map((installation) => {
          return { key: installation.id, label: installation.displayName };
        })
        : [],
      installations: installationsFetchingState.data
        ? installationsFetchingState.data
        : [],
    };
  }, [installationsFetchingState]);

  const onSelect = useCallback(
    (key: string | undefined) => {
      const selectedInstallation =
        key === 'all' || !key
          ? undefined
          : installations.find((installation) => installation.id === key);
      setSelectedInstallation(selectedInstallation);
    },
    [setSelectedInstallation, installations],
  );

  const isLoading = installationsFetchingState.isLoading || dataFetchingState.isLoading;
  const isSuccess = installationsFetchingState.isSuccess && dataFetchingState.isSuccess;
  const isError = installationsFetchingState.isError || dataFetchingState.isError;
  const hasData =
    installations.length > 0 && (eventsData.length > 0 || sessionsData.length > 0);

  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleDownloadButtonClick = () => {
    try {
      setIsDownloading(true);
      let csvData = '';
      const dataExportFileName = getCsvDataFileName({ dateFrom, dateTo, title });
      if (csvData === '') {
        message.warning(<Message content={t('fileDownload.noDataToDownload')} />);
      } else {
        downloadCSV(csvData, dataExportFileName ? dataExportFileName : 'download-report.csv');
        message.success(<Message content={t('fileDownload.downloadStart')} />);
      }
    } catch (error) {
      Sentry.captureException(error);
      message.error(<Message content={(t('fileDownload.downloadFailure'))} />);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <CardContainer
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      hasData={hasData}
      title={(
        <CardTitleDownloadButtonWrapper
          isDownloadButtonVisible={downloadFileColumns && downloadFileColumns.length > 0 ? true : false}
          onDownloadButtonClicked={handleDownloadButtonClick}
          isDownloading={isDownloading}
        >
          {installationsFetchingState.isSuccess ? (
            <TitleWithSelect
              title={title}
              items={installationOptions}
              onSelect={onSelect}
            />
          ) : (
            title
          )}
        </CardTitleDownloadButtonWrapper>
      )}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {installationsFetchingState.isSuccess && (
        <InstallationsTable
          tenantId={tenantId}
          installations={selectedInstallation ? [selectedInstallation] : installations}
          columns={columns}
          eventsData={eventsData}
          sessionsData={sessionsData}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      )}
    </CardContainer>
  );
});

const SpacesDataMatrix: React.FC<Omit<DataMatrixProps, 'dataSource'>> = React.memo(({
                                                                                      tenantId,
                                                                                      dateFrom,
                                                                                      dateTo,
                                                                                      dataResidency,
                                                                                      title = '',
                                                                                      columns,
                                                                                      downloadFileColumns,
                                                                                      tableColumns,
                                                                                      gridStyles,
                                                                                      isVisibleWithoutData,
                                                                                      dataSourceType,
                                                                                    }) => {
  if (tableColumns) {
    return (
      <SpacesDataMatrixV1
        tenantId={tenantId}
        dateFrom={dateFrom}
        dateTo={dateTo}
        dataResidency={dataResidency}
        title={title}
        tableColumns={tableColumns}
        downloadFileColumns={downloadFileColumns}
        dataSourceType={dataSourceType}
        gridStyles={gridStyles}
        isVisibleWithoutData={isVisibleWithoutData}
      />
    );
  }

  return (
    <SpacesDataMatrixLegacy
      tenantId={tenantId}
      dateFrom={dateFrom}
      dateTo={dateTo}
      dataResidency={dataResidency}
      title={title}
      columns={columns}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    />
  );
});

interface SpacesInstallationDataMatrixProps extends Omit<DataMatrixProps, 'dataSource'> {
  installationId: string;
}

const SpacesInstallationDataMatrix: React.FC<SpacesInstallationDataMatrixProps> = React.memo(({
                                                                                                tenantId,
                                                                                                installationId,
                                                                                                dateFrom,
                                                                                                dateTo,
                                                                                                dataResidency,
                                                                                                title = '',
                                                                                                columns,
                                                                                                downloadFileColumns,
                                                                                                gridStyles,
                                                                                                isVisibleWithoutData,
                                                                                              }) => {
  const eventType = getEventType(columns);

  const isEventsQueryEnabled = columns.some(
    (column) => column.dataType.type === DataMatrixColumnDataType.Events,
  );

  const isSessionsQueryEnabled = columns.some(
    (column) => column.dataType.type === DataMatrixColumnDataType.Sessions,
  );

  const dataFetchingState = useSpacesInstallationEventsAndSessionsByDay({
    tenantId,
    installationId,
    dateFrom,
    dateTo,
    dataResidency,
    interactionType: InteractionType.Interactive,
    eventType,
    isEventsQueryEnabled,
    isSessionsQueryEnabled,
  });

  const eventsData = useMemo(
    () => (dataFetchingState.data ? dataFetchingState.data.events : []),
    [dataFetchingState],
  );

  const sessionsData = useMemo(
    () => (dataFetchingState.data ? dataFetchingState.data.sessions : []),
    [dataFetchingState],
  );

  const spacesFetchingState = useSpaces({
    organisationId: tenantId,
    limit: 15000,
  });

  const [selectedSpace, setSelectedSpace] = useState<
    OrganisationSpaceWithChildren | undefined
  >(undefined);

  const spacesWithChildren = useMemo(() => {
    const spaces = spacesFetchingState.data ? spacesFetchingState.data.spaces : [];

    const result = spaces
      .map((space) => {
        const children = spaces.filter((child) => child.parentSpaceId === space.id);

        return { ...space, children };
      })
      .filter((space) => !space.parentSpaceId);

    return result;
  }, [spacesFetchingState]);

  const spaceOptions = useMemo(() => {
    const result = spacesWithChildren.map((space) => {
      return { key: space.id, label: space.displayName };
    });

    return result;
  }, [spacesWithChildren]);

  const onSelect = useCallback(
    (key: string | undefined) => {
      const selectedSpace =
        key === 'all' || !key
          ? undefined
          : spacesWithChildren.find((space) => space.id === key);

      setSelectedSpace(selectedSpace);
    },
    [setSelectedSpace, spacesWithChildren],
  );

  const isLoading = spacesFetchingState.isLoading || dataFetchingState.isLoading;
  const isSuccess = spacesFetchingState.isSuccess && dataFetchingState.isSuccess;
  const isError = spacesFetchingState.isError || dataFetchingState.isError;

  const hasData =
    spacesWithChildren.length > 0 && (eventsData.length > 0 || sessionsData.length > 0);

  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleDownloadButtonClick = () => {
    try {
      setIsDownloading(true);
      let csvData = '';
      const dataExportFileName = getCsvDataFileName({ dateFrom, dateTo, title });
      if (csvData === '') {
        message.warning(<Message content={t('fileDownload.noDataToDownload')} />);
      } else {
        downloadCSV(csvData, dataExportFileName ? dataExportFileName : 'download-report.csv');
        message.success(<Message content={t('fileDownload.downloadStart')} />);
      }
    } catch (error) {
      Sentry.captureException(error);
      message.error(<Message content={(t('fileDownload.downloadFailure'))} />);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <CardContainer
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      hasData={hasData}
      title={(
        <CardTitleDownloadButtonWrapper
          isDownloadButtonVisible={downloadFileColumns && downloadFileColumns.length > 0 ? true : false}
          onDownloadButtonClicked={handleDownloadButtonClick}
          isDownloading={isDownloading}
        >
          {isSuccess ? (
            <TitleWithSelect title={title} items={spaceOptions} onSelect={onSelect} />
          ) : (
            title
          )}
        </CardTitleDownloadButtonWrapper>
      )}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {isSuccess && (
        <SpacesTable
          pageSize={5}
          tenantId={tenantId}
          spaces={selectedSpace ? [selectedSpace] : spacesWithChildren}
          eventsData={eventsData}
          sessionsData={sessionsData}
          columns={columns}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      )}
    </CardContainer>
  );
});

const DevicesDataMatrix: React.FC<Omit<DataMatrixProps, 'dataSource'>> = React.memo(({
                                                                                       tenantId,
                                                                                       dateFrom,
                                                                                       dateTo,
                                                                                       dataResidency,
                                                                                       title = '',
                                                                                       columns,
                                                                                       downloadFileColumns,
                                                                                       tableColumns,
                                                                                       gridStyles,
                                                                                       isVisibleWithoutData,
                                                                                     }) => {
  if (tableColumns) {
    return (
      <DevicesDataMatrixV1
        tenantId={tenantId}
        dateFrom={dateFrom}
        dateTo={dateTo}
        dataResidency={dataResidency}
        title={title}
        tableColumns={tableColumns}
        downloadFileColumns={downloadFileColumns}
        gridStyles={gridStyles}
        isVisibleWithoutData={isVisibleWithoutData}
      />
    );
  }

  return (
    <DevicesDataMatrixLegacy
      tenantId={tenantId}
      dateFrom={dateFrom}
      dateTo={dateTo}
      dataResidency={dataResidency}
      title={title}
      columns={columns}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    />
  );
});

const DataMatrix: React.FC<DataMatrixProps> = ({
                                                 tenantId,
                                                 installationId,
                                                 dateFrom,
                                                 dateTo,
                                                 dataResidency,
                                                 title = '',
                                                 dataSource,
                                                 dataSourceType,
                                                 columns,
                                                 downloadFileColumns,
                                                 tableColumns,
                                                 gridStyles,
                                                 isVisibleWithoutData = true,
                                               }) => {
  const component = useMemo(() => {
    switch (dataSource) {
      case DataMatrixDataSource.Installation:
        return (
          <InstallationsDataMatrix
            tenantId={tenantId}
            dateFrom={dateFrom}
            dateTo={dateTo}
            dataResidency={dataResidency}
            title={title}
            columns={columns}
            downloadFileColumns={downloadFileColumns}
            gridStyles={gridStyles}
            isVisibleWithoutData={isVisibleWithoutData}
          />
        );

      case DataMatrixDataSource.Space: {
        return (
          <>
            {!installationId && (
              <SpacesDataMatrix
                tenantId={tenantId}
                dateFrom={dateFrom}
                dateTo={dateTo}
                dataResidency={dataResidency}
                title={title}
                columns={columns}
                downloadFileColumns={downloadFileColumns}
                tableColumns={tableColumns}
                gridStyles={gridStyles}
                isVisibleWithoutData={isVisibleWithoutData}
                dataSourceType={dataSourceType}
              />
            )}

            {!!installationId && (
              <SpacesInstallationDataMatrix
                tenantId={tenantId}
                installationId={installationId}
                dateFrom={dateFrom}
                dateTo={dateTo}
                dataResidency={dataResidency}
                title={title}
                columns={columns}
                downloadFileColumns={downloadFileColumns}
                gridStyles={gridStyles}
                isVisibleWithoutData={isVisibleWithoutData}
              />
            )}
          </>
        );
      }

      case DataMatrixDataSource.Device:
        return (
          <DevicesDataMatrix
            tenantId={tenantId}
            dateFrom={dateFrom}
            dateTo={dateTo}
            dataResidency={dataResidency}
            title={title}
            columns={columns}
            downloadFileColumns={downloadFileColumns}
            tableColumns={tableColumns}
            gridStyles={gridStyles}
            isVisibleWithoutData={isVisibleWithoutData}
          />
        );

      default:
        throw new Error(`Unknown data matrix data source ${dataSource}`);
    }
  }, [
    tenantId,
    installationId,
    dateFrom,
    dateTo,
    dataResidency,
    columns,
    downloadFileColumns,
    tableColumns,
    dataSource,
    dataSourceType,
    gridStyles,
    isVisibleWithoutData,
    title,
  ]);

  return <>{component}</>;
};

export default DataMatrix;