import React from 'react';
import styled from '@emotion/styled';
import { Avatar, Button, List, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { VideoVariant, VideoVariantState } from '../../../../store/types/media';
import {
  getVideoPresetDisplayName,
  getVideoVariantStateDisplayName,
} from '../../../../utils/media-item-utils';

const { Text } = Typography;

const AVATAR_SIZE = 44;

const PresetNameButton: any = styled(Button)`
  padding: 0;
  height: auto;
`;

const ErrorTemplateAvatar = styled(Avatar)`
  color: #a94442;
`;

const VideoVariantContents = styled(List.Item.Meta)`
  align-items: center;
`;

interface MediaItemVideoVariantProps {
  variant: VideoVariant;
  onSelect: () => void;
  disabled: boolean;
}

const MediaItemVideoVariant: React.FC<MediaItemVideoVariantProps> = ({
  variant,
  onSelect,
  disabled,
}) => {
  const { t } = useTranslation();

  const { state, url } = variant;

  const renderAvatar = () => {
    switch (variant.state) {
      case VideoVariantState.error:
        return (
          <Tooltip placement="right" title={t('videoConversionFailedTooltip')}>
            <ErrorTemplateAvatar size={AVATAR_SIZE} icon="exclamation-circle" />
          </Tooltip>
        );
      case VideoVariantState.finished:
        return <Avatar size={AVATAR_SIZE} icon="check-circle" />;
      default:
        return <Avatar size={AVATAR_SIZE} icon="loading" />;
    }
  };

  const presetDisplayName = getVideoPresetDisplayName(variant.preset, t);
  const videoVariantStateDisplayName = getVideoVariantStateDisplayName(variant.state, t);

  return (
    <VideoVariantContents
      avatar={renderAvatar()}
      title={
        state === VideoVariantState.finished && url != null && !disabled ? (
          <PresetNameButton type="link" onClick={onSelect}>
            {presetDisplayName}
          </PresetNameButton>
        ) : (
          <Text type="secondary">{presetDisplayName}</Text>
        )
      }
      description={videoVariantStateDisplayName}
    />
  );
};

export default MediaItemVideoVariant;
