import React, { useCallback } from 'react';
import { FieldProps } from 'react-jsonschema-form';
// @ts-ignore
import { optionsList } from 'react-jsonschema-form/lib/utils';
import { Select } from 'antd';

const MultiSelect = (props: FieldProps) => {
  const { schema, onChange, disabled, readonly, formData, name, required } = props;

  const options = optionsList(schema.items);

  const handleChange = useCallback(
    (value: string[]) => {
      onChange(value);
    },
    [onChange],
  );

  return (
    <>
      <label htmlFor={name} className="control-label">
        {schema.title || name}
        {required && <span className="required">*</span>}
      </label>
      <Select
        id={name}
        onChange={handleChange}
        mode="multiple"
        value={formData}
        disabled={disabled || readonly}
        optionFilterProp="children"
        filterOption={(input: any, option: any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {options.map((option: any) => (
          <Select.Option key={option.value}>{option.label}</Select.Option>
        ))}
      </Select>
    </>
  );
};

export default MultiSelect;
