import * as powerbi from 'powerbi-client';
import AnalyticsEmbed from '../../../../store/types/analytics-embed';

export const getReportConfig = (embed: AnalyticsEmbed) => {
  return {
    type: 'report',
    id: embed.id,
    embedUrl: embed.embedUrl,
    tokenType: powerbi.models.TokenType.Embed,
    accessToken: embed.token,
    settings: {
      filterPaneEnabled: false,
      navContentPaneEnabled: true,
      localeSettings: {
        language: 'en',
      },
      background: powerbi.models.BackgroundType.Transparent,
    },
    filters: embed.filters,
  };
};

export default { getReportConfig };
