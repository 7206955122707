import React, { createContext, useContext } from 'react';
import GridRndAI from '../../../services/api/omg-rndai';

const GridRndAIContext = createContext<GridRndAI | null>(null);

interface GridRndAIProviderProps {
  children: React.ReactNode;
  service: GridRndAI;
}

const GridRndAIProvider: React.FC<GridRndAIProviderProps> = ({
  children,
  service,
}): JSX.Element => {
  return (
    <GridRndAIContext.Provider value={service}>
      {children}
    </GridRndAIContext.Provider>
  );
};

export const useGridRndAI = (): GridRndAI => {
  const ctx = useContext(GridRndAIContext);

  if (!ctx) {
    throw new Error(
      'Error caught while consuming GridRndAIContext context. Make sure you wrap the component inside the "GridRndAIProvider".',
    );
  }

  return ctx;
};

export default GridRndAIProvider;
