import { useCallback } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { useApiService } from '../api-service-provider';
import { ApiError } from '../../../services/api/api-error';
import Gridapp from '../../../store/types/gridapp';
import gridAppsQueryKeys from './gridapp-query-keys';

const useGridapps = (
    organizationId: string
): UseQueryResult<Gridapp[], ApiError> => {
  const apiService = useApiService();

  const fetch = useCallback(
    async () => {
      return apiService.get<Gridapp[]>('/api/gridapps', {
        organizationId
      });
    },
    [apiService, organizationId],
  );

  const result = useQuery(
    gridAppsQueryKeys.getGridapps(organizationId),
    () => fetch(),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      onError: (_error: ApiError) => {},
    }
  );

  return result;
};

export default useGridapps;
