import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import OnboardingComponent from './onboarding-setup.component';
import { RootModel, RootState } from '../../../store/models/root.model';

type OnboardingContainerProps = Omit<
  React.ComponentProps<typeof OnboardingComponent>,
  | 'onboardingInfo'
  | 'fetchOnboarding'
  | 'error'
  | 'loaded'
  | 'tryLogin'
  | 'logout'
  | 'user'
>;

const OnboardingSetupContainer = (props: OnboardingContainerProps) => {
  const { onboardingInfo, loaded, error, user } = useStore<RootState>((state) => {
    return {
      error: state.onboarding.error,
      loaded: state.onboarding.loaded,
      onboardingInfo: state.onboarding.data,
      user: state.auth.user,
    };
  }, []);

  const { fetchOnboarding, tryLogin, logout } = useActions<RootModel>((actions) => ({
    fetchOnboarding: actions.onboarding.fetchByToken,
    tryLogin: actions.onboarding.tryLogin,
    logout: actions.auth.logout,
  }));

  return (
    <OnboardingComponent
      {...props}
      loaded={loaded}
      error={error}
      onboardingInfo={onboardingInfo}
      fetchOnboarding={fetchOnboarding}
      tryLogin={tryLogin}
      user={user}
      logout={logout}
    />
  );
};

export default OnboardingSetupContainer;
