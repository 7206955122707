import React, { useCallback } from 'react';
import { useActions, useStore } from 'easy-peasy';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import GridappSettings from './gridapp-settings.component';
import { RootModel, RootState } from '../../../../../store/models/root.model';
import Gridapp from '../../../../../store/types/gridapp';
import useSkus from '../../../use-skus';
import Overlay from '../../../overlay/overlay.component';
import Spinner from '../../../spinner/spinner.component';

type GridappSettingsContainerProps = Pick<
  React.ComponentProps<typeof GridappSettings>,
  'match' | 'history' | 'location'
>;

const GridappSettingsContainer = (props: GridappSettingsContainerProps) => {
  const {
    match: {
      params: { organisationId, gridappId },
    },
    history,
  } = props;

  const { t } = useTranslation();
  const skusState = useSkus(1, 1000);

  // TODO: how to memoize this properly?
  const { updateGridapp, deleteGridapp } = useActions<RootModel>((actions) => ({
    updateGridapp: (data: Partial<Gridapp>) =>
      actions.organisationAppsLibrary.update({ data, key: organisationId }),
    deleteGridapp: (id: string) =>
      actions.organisationAppsLibrary.delete({ id, key: organisationId }),
  }));

  const { gridapp, isSysAdmin, organisations } = useStore<RootState>(
    (state) => ({
      gridapp: state.organisationAppsLibrary.data[organisationId]
        ? state.organisationAppsLibrary.data[organisationId][gridappId]
        : null,
      isSysAdmin: state.auth.isSysAdmin,
      organisations: state.organisations.values,
    }),
    [organisationId, gridappId],
  );

  const handleDelete = useCallback(async () => {
    await deleteGridapp(gridappId);
    history.replace(`/organisations/${organisationId}/marketplace/browse/apps-library`);
  }, [deleteGridapp, gridappId, history, organisationId]);

  if (isSysAdmin && skusState.isLoading) {
    return (
      <Overlay>
        <Spinner />
      </Overlay>
    );
  }

  if (isSysAdmin && skusState.isError) {
    return <ErrorMessage>{t('error')}</ErrorMessage>
  }

  return (
    <GridappSettings
      {...props}
      gridapp={gridapp}
      isSysAdmin={isSysAdmin}
      updateGridapp={updateGridapp}
      deleteGridapp={handleDelete}
      skus={(skusState.data && skusState.data.docs) || []}
      organisations={organisations}
    />
  );
};

export default GridappSettingsContainer;

const ErrorMessage = styled.div`
  padding: 15px 0;
  color: rgb(255, 85, 0);
`;