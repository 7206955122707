import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { FieldProps } from 'react-jsonschema-form';
import { Input, Icon } from 'antd';
import ValidationStatusEnum from '../../../../../store/types/validation-status-enum';

const SlugWithValidation = ({
  schema,
  onChange,
  formData,
  name,
  required,
  readonly,
}: FieldProps) => {
  const currentFormData = formData || {};

  const { value, status, error, isInErrorState } = useMemo(
    () => ({
      value: currentFormData.value || '',
      status: currentFormData.status,
      error: currentFormData.error || '',
      isInErrorState: currentFormData.status === ValidationStatusEnum.ERROR,
    }),
    [currentFormData],
  );

  const validationIconProps = useMemo(() => {
    switch (status) {
      case ValidationStatusEnum.ERROR:
        return { type: 'close-circle', color: 'red' };
      case ValidationStatusEnum.VALIDATING:
        return { type: 'loading', color: 'gray' };
      case ValidationStatusEnum.SUCCESS:
        return { type: 'check-circle', color: 'green' };
      default:
        // important as Icon placeholder to make styles consistent
        // if removed padding will change when input value is filled
        return { type: 'none', color: '' };
    }
  }, [status]);

  const handleChange = useCallback(
    (targetValue: any) => {
      onChange({
        ...(currentFormData || {}),
        ...targetValue,
      });
    },
    [onChange, currentFormData],
  );

  const onInputChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      handleChange({
        value: ev.target.value.replace(/[^a-zA-Z0-9_-]/g, '').toLowerCase(),
      });
    },
    [handleChange],
  );

  return (
    <Container>
      <label htmlFor={name} className="control-label">
        {schema.title || name}
        {required && <span className="required">*</span>}
      </label>
      <>
        <TextInput
          // Hacked to avoid react error in console
          // react is unable to recognize hasError prop,
          // and boolean type haserror, needs to be formatted to string
          haserror={isInErrorState.toString()}
          value={value}
          onChange={onInputChange}
          suffix={
            <IconSuffix
              color={validationIconProps.color}
              type={validationIconProps.type}
            />
          }
          disabled={readonly}
        />
        {error && <span>{error}</span>}
      </>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
`;

const IconSuffix = styled(Icon)<{ color: string }>`
  color: ${({ color }) => color};
`;

const TextInput = styled(Input)<{ haserror: string }>`
  input {
    display: block;
    width: 100%;
    height: 32px;
    padding: 2px 4px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555555;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    ${({ haserror }) =>
      haserror === 'true'
        ? `
        border-color: #a94442 !important;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        
        &:focus {
          border-color: #843534 !important;
          -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483 !important;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483 !important;
        }
      `
        : `
        border: 1px solid #ccc !important;

        &:focus {
          border-color: #66afe9 !important;
          outline: 0;
          -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) ,
            0 0 8px rgba(102, 175, 233, 0.6) !important;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6) !important;
        }
    `}
  }
`;

export default SlugWithValidation;
