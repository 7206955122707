import React, { useCallback, useState } from 'react';
import { FieldProps } from 'react-jsonschema-form';
import FilePicker from './file-picker.component';
import { SchemaFormContext } from '../../schema-form.component';
import { useStoreState } from '../../../../../store/initialize-store';
import useOrganisationMedia from '../../../use-organisation-media';

interface FilePickerContainerProps extends FieldProps {
  className?: string;
  formContext: SchemaFormContext;
}

const FilePickerContainer = (props: FilePickerContainerProps) => {
  const {
    formContext: { organisationId = '' },
  } = props;

  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);

  const { ancestors } = useStoreState((state) => ({
    ancestors: state.organisationMedia.ancestors || [],
  }));

  const media = useOrganisationMedia(organisationId);

  const handleBackClick = useCallback((currentFolderId: string | null) => {
    if (currentFolderId && ancestors && ancestors.length) {
      const parentFolderId = ancestors.length > 1 ? ancestors.slice(-2)[0].id : null;

      setSelectedFolderId(parentFolderId);
    }
  }, [ancestors]);

  return (
    <FilePicker
      {...props}
      mediaItems={media && media.data ? media.data.files : []}
      onFolderSelect={setSelectedFolderId}
      selectedFolderId={selectedFolderId}
      onBackClick={handleBackClick}
      isFileLibLoading={media.isLoading}
      isFileLibError={media.isError}
      reinitFileLib={media.refetch}
    />
  );
};

export default FilePickerContainer;
