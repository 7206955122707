import { DataResidencyEnum } from '../../../../store/types/organisation';

export interface Node {
  name: string;
}

export interface Link {
  source: number;
  target: number;
  value: number;
}

export interface SankeyDiagramData {
  nodes: Node[];
  links: Link[];
}

export enum DayDataType {
  TenantEventsByDay = 'tenantEventsByDay',
  TenantSessionsByDay = 'tenantSessionsByDay',
  TenantInteractiveSessionsByDay = 'tenantInteractiveSessionsByDay',
  TenantEventsFlowByDay = 'tenantEventsFlowByDay',
  TenantProductsByDay = 'tenantProductsByDay',
  TenantCategoriesByDay = 'tenantCategoriesByDay',
  TenantPurchasesByDay = 'tenantPurchasesByDay',
  TenantPurchaseProductByDay = 'tenantPurchaseProductByDay',
  TenantQrCodesByDay = 'tenantQrCodesByDay',
  TenantMediaByDay = 'tenantMediaByDay',
  TenantAndSpaceEventsByDay = 'tenantAndSpaceEventsByDay',

  InstallationEventsByDay = 'installationEventsByDay',
  InstallationSessionsByDay = 'installationSessionsByDay',
  InstallationInteractiveSessionsByDay = 'installationInteractiveSessionsByDay',
  InstallationEventsFlowByDay = 'installationEventsFlowByDay',
  InstallationProductsByDay = 'installationProductsByDay',
  InstallationCategoriesByDay = 'installationCategoriesByDay',
  InstallationPurchasesByDay = 'installationPurchasesByDay',
  InstallationPurchaseProductByDay = 'installationPurchaseProductByDay',
  InstallationAndSpaceEventsByDay = 'installationAndSpaceEventsByDay',
  InstallationQrCodesByDay = 'installationQrCodesByDay',
  InstallationMediaByDay = 'installationMediaByDay',

  SpaceEventsByDay = 'spaceEventsByDay',
  SpaceInstallationEventsByDay = 'spaceInstallationEventsByDay',
  SpaceSessionsByDay = 'spaceSessionsByDay',
  SpaceInstallationSessionsByDay = 'spaceInstallationSessionsByDay',
  SpaceInteractiveSessionsByDay = 'spaceInteractiveSessionsByDay',
  SpaceInstallationInteractiveSessionsByDay = 'spaceInstallationInteractiveSessionsByDay',
  SpaceEventsFlowByDay = 'spaceEventsFlowByDay',
  SpaceProductsByDay = 'spaceProductsByDay',
  SpaceCategoriesByDay = 'spaceCategoriesByDay',
  SpacePurchasesByDay = 'spacePurchasesByDay',
  SpacePurchaseProductByDay = 'spacePurchaseProductByDay',
  SpaceQrCodesByDay = 'spaceQrCodesByDay',
  SpaceMediaByDay = 'spaceMediaByDay',

  DeviceEventsByDay = 'deviceEventsByDay',
  DeviceAndSpaceEventsByDay = 'deviceAndSpaceEventsByDay',
  DeviceSessionsByDay = 'deviceSessionsByDay',
  DeviceInteractiveSessionsByDay = 'deviceInteractiveSessionsByDay',
  DeviceEventsFlowByDay = 'deviceEventsFlowByDay',
  DeviceProductsByDay = 'deviceProductsByDay',
  DeviceCategoriesByDay = 'deviceCategoriesByDay',
  DevicePurchasesByDay = 'devicePurchasesByDay',
  DevicePurchaseProductByDay = 'devicePurchaseProductByDay',
  DeviceQrCodesByDay = 'deviceQrCodesByDay',
  DeviceMediaByDay = 'deviceMediaByDay',

  AppEventsByDay = 'appEventsByDay',
  AppSessionsByDay = 'appSessionsByDay',
  AppInteractiveSessionsByDay = 'appInteractiveSessionsByDay',
  AppEventsFlowByDay = 'appEventsFlowByDay',
  AppProductsByDay = 'appProductsByDay',
  AppCategoriesByDay = 'appCategoriesByDay',
  AppPurchasesByDay = 'appPurchasesByDay',
  AppPurchaseProductByDay = 'appPurchaseProductByDay',
  AppQrCodesByDay = 'appQrCodesByDay',
  AppMediaByDay = 'appMediaByDay',
}

export interface GenericEventFlowByDay {
  id: string;
  type: DayDataType;
  tenantId: string;
  count: number;
  eventType: string;
  eventTypePrevious?: string;
  eventOrder: number;
  date: string;
}

export interface TenantEventsByDay {
  id: string;
  type: DayDataType.TenantEventsByDay;
  tenantId: string;
  date: string;
  eventType: string;
  interaction: boolean;
  count: number;
  sessionCount: number;
}
export interface TenantAndSpaceEventsByDay extends Omit<TenantEventsByDay, 'type'> {
  type: DayDataType.TenantAndSpaceEventsByDay;
  spaceId: string;
}

interface TenantSessionsDay {
  id: string;
  type: DayDataType;
  tenantId: string;
  sessionCount: number;
  date: string;
}

export interface TenantSessionsByDay extends TenantSessionsDay {
  type: DayDataType.TenantSessionsByDay;
}

export interface TenantInteractiveSessionsByDay extends TenantSessionsDay {
  type: DayDataType.TenantInteractiveSessionsByDay;
}

export interface AggregatedTenantNpsByDay {
  score: number;
  replyCount: number;
}

export interface TenantEventsFlowByDay extends GenericEventFlowByDay {
  type: DayDataType.TenantEventsFlowByDay;
}

export interface TenantProductsByDay {
  id: string;
  type: DayDataType.TenantProductsByDay;
  tenantId: string;
  date: string;
  eventType: string;
  interaction: boolean;
  productId: string;
  count: number;
  sessionCount: number;
}

export interface TenantCategoriesByDay {
  id: string;
  type: DayDataType.TenantCategoriesByDay;
  tenantId: string;
  date: string;
  eventType: string;
  interaction: boolean;
  categoryId: string;
  count: number;
  sessionCount: number;
}

export interface TenantPurchasesByDay {
  id: string;
  type: DayDataType.TenantPurchasesByDay;
  tenantId: string;
  date: string;
  eventType: string;
  eventTime: string;
  interaction: boolean;
  transactionId: string;
  revenue: number;
  currency: string;
  count: number;
  sessionCount: number;
}

export interface TenantPurchaseProductByDay {
  id: string;
  type: DayDataType.TenantPurchaseProductByDay;
  tenantId: string;
  date: string;
  eventType: string;
  interaction: boolean;
  transactionId: string;
  productId: string;
  categoryId: string;
  productName: string;
  currency: string;
  quantity: number;
  price: number;
  count: number;
  sessionCount: number;
}

export interface TenantQrCodesByDay {
  id: string;
  type: DayDataType.TenantQrCodesByDay;
  tenantId: string;
  date: string;
  eventType: string;
  interaction: true;
  qrCodeId: string;
  qrCodeContent: string;
  qrCodeEntryMethod: string;
  qrCodeType: string;
  count: number;
  sessionCount: number;
}

type MediaEventVersion = 'v1' | 'v2';

export interface TenantMediaByDay {
  id: string;
  type: DayDataType.TenantMediaByDay;
  tenantId: string;
  date: string;
  eventType: string;
  interaction: boolean;
  mediaId: string;
  mediaType: string;
  mediaName: string;
  mediaTags: string[];
  mediaDuration: number;
  count: number;
  sessionCount: number;
  version?: MediaEventVersion;
}

export interface InstallationEventsByDay {
  id: string;
  type: DayDataType.InstallationEventsByDay;
  tenantId: string;
  date: string;
  installationId: string;
  eventType: string;
  interaction: boolean;
  count: number;
  sessionCount: number;
}

export interface InstallationAndSpaceEventsByDay
  extends Omit<InstallationEventsByDay, 'type'> {
  type: DayDataType.InstallationAndSpaceEventsByDay;
  spaceId: string;
}
interface InstallationSessionsDay {
  id: string;
  type: DayDataType;
  tenantId: string;
  installationId: string;
  sessionCount: number;
  date: string;
}

export interface InstallationSessionsByDay extends InstallationSessionsDay {
  type: DayDataType.InstallationSessionsByDay;
}

export interface InstallationInteractiveSessionsByDay extends InstallationSessionsDay {
  type: DayDataType.InstallationInteractiveSessionsByDay;
}

export interface AggregatedInstallationNpsByDay {
  score: number;
  replyCount: number;
}

export interface InstallationEventsFlowByDay extends GenericEventFlowByDay {
  type: DayDataType.InstallationEventsFlowByDay;
  installationId: string;
}

export interface InstallationProductsByDay {
  id: string;
  type: DayDataType.InstallationProductsByDay;
  tenantId: string;
  date: string;
  eventType: string;
  interaction: boolean;
  productId: string;
  count: number;
  sessionCount: number;
  installationId: string;
}

export interface InstallationCategoriesByDay {
  id: string;
  type: DayDataType.InstallationCategoriesByDay;
  tenantId: string;
  date: string;
  eventType: string;
  interaction: boolean;
  categoryId: string;
  count: number;
  sessionCount: number;
  installationId: string;
}

export interface InstallationPurchasesByDay {
  id: string;
  type: DayDataType.InstallationPurchasesByDay;
  tenantId: string;
  date: string;
  eventType: string;
  eventTime: string;
  interaction: boolean;
  transactionId: string;
  revenue: number;
  currency: string;
  count: number;
  sessionCount: number;
  installationId: string;
}

export interface InstallationPurchaseProductByDay {
  id: string;
  type: DayDataType.InstallationPurchaseProductByDay;
  tenantId: string;
  date: string;
  eventType: string;
  interaction: boolean;
  transactionId: string;
  productId: string;
  categoryId: string;
  productName: string;
  currency: string;
  quantity: number;
  price: number;
  count: number;
  sessionCount: number;
  installationId: string;
}

export interface InstallationQrCodesByDay {
  id: string;
  type: DayDataType.InstallationQrCodesByDay;
  tenantId: string;
  date: string;
  eventType: string;
  interaction: true;
  qrCodeId: string;
  qrCodeContent: string;
  qrCodeEntryMethod: string;
  qrCodeType: string;
  count: number;
  sessionCount: number;
  installationId: string;
}

export interface InstallationMediaByDay {
  id: string;
  type: DayDataType.InstallationMediaByDay;
  tenantId: string;
  date: string;
  eventType: string;
  interaction: boolean;
  mediaId: string;
  mediaType: string;
  mediaName: string;
  mediaTags: string[];
  mediaDuration: number;
  count: number;
  sessionCount: number;
  installationId: string;
  version?: MediaEventVersion;
}

export interface SpaceEventsByDay {
  id: string;
  type: DayDataType.SpaceEventsByDay;
  tenantId: string;
  date: string;
  spaceId: string;
  eventType: string;
  interaction: boolean;
  count: number;
  sessionCount: number;
}

export interface SpaceInstallationEventsByDay {
  id: string;
  type: DayDataType.SpaceInstallationEventsByDay;
  tenantId: string;
  installationId: string;
  date: string;
  spaceId: string;
  eventType: string;
  interaction: boolean;
  count: number;
  sessionCount: number;
}

interface SpaceSessionsDay {
  id: string;
  type: DayDataType;
  tenantId: string;
  spaceId: string;
  sessionCount: number;
  date: string;
}

export interface SpaceSessionsByDay extends SpaceSessionsDay {
  type: DayDataType.SpaceSessionsByDay;
}

export interface SpaceInstallationSessionsByDay extends SpaceSessionsDay {
  type: DayDataType.SpaceInstallationSessionsByDay;
  installationId: string;
}

export interface SpaceInteractiveSessionsByDay extends SpaceSessionsDay {
  type: DayDataType.SpaceInteractiveSessionsByDay;
}

export interface SpaceInstallationInteractiveSessionsByDay extends SpaceSessionsDay {
  type: DayDataType.SpaceInteractiveSessionsByDay;
  installationId: string;
}

export interface AggregatedSpaceNpsByDay {
  score: number;
  replyCount: number;
}

export interface SpaceEventsFlowByDay extends GenericEventFlowByDay {
  type: DayDataType.SpaceEventsFlowByDay;
  spaceId: string;
}

export interface SpaceProductsByDay {
  id: string;
  type: DayDataType.SpaceProductsByDay;
  tenantId: string;
  date: string;
  eventType: string;
  interaction: boolean;
  productId: string;
  count: number;
  sessionCount: number;
  spaceId: string;
}

export interface SpaceCategoriesByDay {
  id: string;
  type: DayDataType.SpaceCategoriesByDay;
  tenantId: string;
  date: string;
  eventType: string;
  interaction: boolean;
  categoryId: string;
  count: number;
  sessionCount: number;
  spaceId: string;
}

export interface SpacePurchasesByDay {
  id: string;
  type: DayDataType.SpacePurchasesByDay;
  tenantId: string;
  date: string;
  eventType: string;
  eventTime: string;
  interaction: boolean;
  transactionId: string;
  revenue: number;
  currency: string;
  count: number;
  sessionCount: number;
  spaceId: string;
}

export interface SpacePurchaseProductByDay {
  id: string;
  type: DayDataType.SpacePurchaseProductByDay;
  tenantId: string;
  date: string;
  eventType: string;
  interaction: boolean;
  transactionId: string;
  productId: string;
  categoryId: string;
  productName: string;
  currency: string;
  quantity: number;
  price: number;
  count: number;
  sessionCount: number;
  spaceId: string;
}

export interface SpaceQrCodesByDay {
  id: string;
  type: DayDataType.SpaceQrCodesByDay;
  tenantId: string;
  date: string;
  eventType: string;
  interaction: true;
  qrCodeId: string;
  qrCodeContent: string;
  qrCodeEntryMethod: string;
  qrCodeType: string;
  count: number;
  sessionCount: number;
  spaceId: string;
}

export interface SpaceMediaByDay {
  id: string;
  type: DayDataType.SpaceMediaByDay;
  tenantId: string;
  date: string;
  eventType: string;
  interaction: boolean;
  mediaId: string;
  mediaType: string;
  mediaName: string;
  mediaTags: string[];
  mediaDuration: number;
  count: number;
  sessionCount: number;
  spaceId: string;
  version?: MediaEventVersion;
}

export interface DeviceEventsByDay {
  id: string;
  type: DayDataType.DeviceEventsByDay;
  tenantId: string;
  date: string;
  hour: number;
  eventType: string;
  deviceId: string;
  count: number;
  sessionCount: number;
}

export interface DeviceAndSpaceEventsByDay extends Omit<DeviceEventsByDay, 'type'> {
  type: DayDataType.DeviceAndSpaceEventsByDay;
  spaceId: string;
}

interface DeviceSessionsDay {
  id: string;
  type: DayDataType;
  tenantId: string;
  deviceId: string;
  sessionCount: number;
  date: string;
}

export interface DeviceSessionsByDay extends DeviceSessionsDay {
  type: DayDataType.DeviceSessionsByDay;
}

export interface DeviceInteractiveSessionsByDay extends DeviceSessionsDay {
  type: DayDataType.DeviceInteractiveSessionsByDay;
}

export interface AggregatedDeviceNpsByDay {
  score: number;
  replyCount: number;
}

export interface DeviceEventsFlowByDay extends GenericEventFlowByDay {
  type: DayDataType.DeviceEventsFlowByDay;
  deviceId: string;
}

export interface DeviceProductsByDay {
  id: string;
  type: DayDataType.DeviceProductsByDay;
  tenantId: string;
  date: string;
  eventType: string;
  interaction: boolean;
  productId: string;
  count: number;
  sessionCount: number;
  deviceId: string;
}

export interface DeviceCategoriesByDay {
  id: string;
  type: DayDataType.DeviceCategoriesByDay;
  tenantId: string;
  date: string;
  eventType: string;
  interaction: boolean;
  categoryId: string;
  count: number;
  sessionCount: number;
  deviceId: string;
}

export interface DevicePurchasesByDay {
  id: string;
  type: DayDataType.DevicePurchasesByDay;
  tenantId: string;
  date: string;
  eventType: string;
  eventTime: string;
  interaction: boolean;
  transactionId: string;
  revenue: number;
  currency: string;
  count: number;
  sessionCount: number;
  deviceId: string;
}

export interface DevicePurchaseProductByDay {
  id: string;
  type: DayDataType.DevicePurchaseProductByDay;
  tenantId: string;
  date: string;
  eventType: string;
  interaction: boolean;
  transactionId: string;
  productId: string;
  categoryId: string;
  productName: string;
  currency: string;
  quantity: number;
  price: number;
  count: number;
  sessionCount: number;
  deviceId: string;
}

export interface DeviceQrCodesByDay {
  id: string;
  type: DayDataType.DeviceQrCodesByDay;
  tenantId: string;
  date: string;
  eventType: string;
  interaction: true;
  qrCodeId: string;
  qrCodeContent: string;
  qrCodeEntryMethod: string;
  qrCodeType: string;
  count: number;
  sessionCount: number;
  deviceId: string;
}

export interface DeviceMediaByDay {
  id: string;
  type: DayDataType.DeviceMediaByDay;
  tenantId: string;
  date: string;
  eventType: string;
  interaction: boolean;
  mediaId: string;
  mediaType: string;
  mediaName: string;
  mediaTags: string[];
  mediaDuration: number;
  count: number;
  sessionCount: number;
  deviceId: string;
  version?: MediaEventVersion;
}

export interface AppEventsByDay {
  id: string;
  type: DayDataType.AppEventsByDay;
  tenantId: string;
  date: string;
  hour: number;
  eventType: string;
  appId: string;
  count: number;
  sessionCount: number;
}

interface AppSessionsDay {
  id: string;
  type: DayDataType;
  tenantId: string;
  appId: string;
  sessionCount: number;
  date: string;
}

export interface AppSessionsByDay extends AppSessionsDay {
  type: DayDataType.AppSessionsByDay;
}

export interface AppInteractiveSessionsByDay extends AppSessionsDay {
  type: DayDataType.AppInteractiveSessionsByDay;
}

export interface AggregatedAppNpsByDay {
  score: number;
  replyCount: number;
}

export interface AppEventsFlowByDay extends GenericEventFlowByDay {
  type: DayDataType.AppEventsFlowByDay;
  appId: string;
}

export interface AppProductsByDay {
  id: string;
  type: DayDataType.AppProductsByDay;
  tenantId: string;
  date: string;
  eventType: string;
  interaction: boolean;
  productId: string;
  count: number;
  sessionCount: number;
  appId: string;
}

export interface AppCategoriesByDay {
  id: string;
  type: DayDataType.AppCategoriesByDay;
  tenantId: string;
  date: string;
  eventType: string;
  interaction: boolean;
  categoryId: string;
  count: number;
  sessionCount: number;
  appId: string;
}

export interface AppPurchasesByDay {
  id: string;
  type: DayDataType.AppPurchasesByDay;
  tenantId: string;
  date: string;
  eventType: string;
  eventTime: string;
  interaction: boolean;
  transactionId: string;
  revenue: number;
  currency: string;
  count: number;
  sessionCount: number;
  appId: string;
}

export interface AppPurchaseProductByDay {
  id: string;
  type: DayDataType.AppPurchaseProductByDay;
  tenantId: string;
  date: string;
  eventType: string;
  interaction: boolean;
  transactionId: string;
  productId: string;
  categoryId: string;
  productName: string;
  currency: string;
  quantity: number;
  price: number;
  count: number;
  sessionCount: number;
  appId: string;
}

export interface AppQrCodesByDay {
  id: string;
  type: DayDataType.AppQrCodesByDay;
  tenantId: string;
  date: string;
  eventType: string;
  interaction: true;
  qrCodeId: string;
  qrCodeContent: string;
  qrCodeEntryMethod: string;
  qrCodeType: string;
  count: number;
  sessionCount: number;
  appId: string;
}

export interface AppMediaByDay {
  id: string;
  type: DayDataType.AppMediaByDay;
  tenantId: string;
  date: string;
  eventType: string;
  interaction: boolean;
  mediaId: string;
  mediaType: string;
  mediaName: string;
  mediaTags: string[];
  mediaDuration: number;
  count: number;
  sessionCount: number;
  appId: string;
  version?: MediaEventVersion;
}

export enum HourDataType {
  TenantEventsByHour = 'tenantEventsByHour',
  TenantSessionsByHour = 'tenantSessionsByHour',
  TenantInteractiveSessionsByHour = 'tenantInteractiveSessionsByHour',

  InstallationEventsByHour = 'installationEventsByHour',
  InstallationSessionsByHour = 'installationSessionsByHour',
  InstallationInteractiveSessionsByHour = 'installationInteractiveSessionsByHour',

  SpaceEventsByHour = 'spaceEventsByHour',
  SpaceSessionsByHour = 'spaceSessionsByHour',
  SpaceInteractiveSessionsByHour = 'spaceInteractiveSessionsByHour',

  DeviceEventsByHour = 'deviceEventsByHour',
  DeviceSessionsByHour = 'deviceSessionsByHour',

  AppEventsByHour = 'appEventsByHour',
  AppSessionsByHour = 'appSessionsByHour',
}

export interface TenantEventsByHour {
  id: string;
  type: HourDataType.TenantEventsByHour;
  tenantId: string;
  date: string;
  hour: number;
  eventType: string;
  interaction: boolean;
  count: number;
}

interface TenantSessionsHour {
  id: string;
  type: HourDataType;
  tenantId: string;
  sessionCount: number;
  date: string;
  hour: number;
}

export interface TenantSessionsByHour extends TenantSessionsHour {
  type: HourDataType.TenantSessionsByHour;
}

export interface TenantInteractiveSessionsByHour extends TenantSessionsHour {
  type: HourDataType.TenantInteractiveSessionsByHour;
}

export interface InstallationEventsByHour {
  id: string;
  type: HourDataType.InstallationEventsByHour;
  tenantId: string;
  date: string;
  hour: number;
  installationId: string;
  eventType: string;
  interaction: boolean;
  count: number;
}

interface InstallationSessionsHour {
  id: string;
  type: HourDataType;
  tenantId: string;
  sessionCount: number;
  date: string;
  hour: number;
  installationId: string;
}

export interface InstallationSessionsByHour extends InstallationSessionsHour {
  type: HourDataType.InstallationSessionsByHour;
}

export interface InstallationInteractiveSessionsByHour extends InstallationSessionsHour {
  type: HourDataType.InstallationInteractiveSessionsByHour;
}

export interface SpaceEventsByHour {
  id: string;
  type: HourDataType.SpaceEventsByHour;
  tenantId: string;
  date: string;
  hour: number;
  spaceId: string;
  eventType: string;
  interaction: boolean;
  count: number;
}

interface SpaceSessionsHour {
  id: string;
  type: HourDataType;
  tenantId: string;
  sessionCount: number;
  date: string;
  hour: number;
  spaceId: string;
}

export interface SpaceSessionsByHour extends SpaceSessionsHour {
  type: HourDataType.SpaceSessionsByHour;
}

export interface SpaceInteractiveSessionsByHour extends SpaceSessionsHour {
  type: HourDataType.SpaceInteractiveSessionsByHour;
}

export interface DeviceEventsByHour {
  id: string;
  type: HourDataType.DeviceEventsByHour;
  tenantId: string;
  date: string;
  hour: number;
  deviceId: string;
  eventType: string;
  interaction: boolean;
  count: number;
}

interface DeviceSessionsHour {
  id: string;
  type: HourDataType;
  tenantId: string;
  sessionCount: number;
  date: string;
  hour: number;
  deviceId: string;
}

export interface DeviceSessionsByHour extends DeviceSessionsHour {
  type: HourDataType.DeviceSessionsByHour;
}

export interface AppEventsByHour {
  id: string;
  type: HourDataType.AppEventsByHour;
  tenantId: string;
  date: string;
  hour: number;
  appId: string;
  eventType: string;
  interaction: boolean;
  count: number;
}

interface AppSessionsHour {
  id: string;
  type: HourDataType;
  tenantId: string;
  sessionCount: number;
  date: string;
  hour: number;
  appId: string;
}

export interface AppSessionsByHour extends AppSessionsHour {
  type: HourDataType.AppSessionsByHour;
}

export enum ReportContext {
  Installation = 'installation',
  Space = 'space',
  Device = 'device',
  Tenant = 'tenant',
}

export interface BaseProps {
  tenantId: string;
  dateFrom: string;
  dateTo: string;
  dataResidency: DataResidencyEnum;
  isEnabled?: boolean;
}

export enum TimeSpanType {
  Day = 'day',
  Hour = 'hour',
}

export interface UseEventsFlowByDayProps extends BaseProps {
  eventsFlowDepth?: number;
}
