import React, { useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import styled from '@emotion/styled';
import { Col, Row, message } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import {
  GridAssistantStatusEnum,
  UpdateGridAssistantPayload,
} from '@ombori/grid-llm-react';
import { StickyColumn } from '../../../queue-details/queue-setup/styled-block';
import StoreAIAssistantFormFields, {
  StoreAIAssistantFormValues,
} from './store-ai-assistant-form-fields.component';
import StoreAISidePanel from './store-ai-assistant-form-side-panel.component';
import {
  getFieldNamesWithError,
  scrollIntoView,
} from '../../../../../common/react-final-form';
import { useTranslation } from 'react-i18next';
import Message from '../../../../../common/message';
import { ApiError } from '../../../../../../services/api/api-error';
import Overlay from '../../../../../common/overlay';
import useGoBack from '../../../../../common/use-go-back/use-go-back';
import { useStoreAIAssistant } from '../../../../../../store/hooks/store-ai/use-store-ai-assistant-get';
import { useStoreAIAssistantUpdate } from '../../../../../../store/hooks/store-ai/use-store-ai-assistant-update';
import { BackToListButton } from '../../common';

export type StoreAIAssistantUpdateFormProps = RouteComponentProps<{
  organisationId: string;
  storeAIAssistantId: string;
}>;

const StoreAIAssistantUpdateForm = (props: StoreAIAssistantUpdateFormProps) => {
  const { match } = props;
  const { organisationId: tenantId, storeAIAssistantId } = match.params;

  const storeAIAssistantState = useStoreAIAssistant(storeAIAssistantId);
  const { mutateAsync: updateAssistant } = useStoreAIAssistantUpdate();

  const { t } = useTranslation();

  const goBack = useGoBack();

  const storeAIAssistantListPath = `/organisations/${tenantId}/store-ai/assistants?page=1`;

  const handleBackClick = useCallback((): void => {
    goBack(storeAIAssistantListPath);
  }, [goBack, storeAIAssistantListPath]);

  const handleFormUpdateSubmit = useCallback(
    async (values): Promise<void> => {
      const formValues = values as StoreAIAssistantFormValues;

      try {
        if (!storeAIAssistantState.data) {
          throw new Error(t('somethingWentWrong'));
        }

        const prevAssistantValue = storeAIAssistantState.data;

        const { knowledgeBaseFiles = [], ...restFormValues } = formValues;

        const knowledgeSourceIds = knowledgeBaseFiles.map(
          (knowledgeBaseFile) => knowledgeBaseFile.fileId,
        );

        const updateAssistantPayload: UpdateGridAssistantPayload = {
          id: prevAssistantValue.id,
          tenantId: prevAssistantValue.tenantId,
          provider: prevAssistantValue.provider,
          description: prevAssistantValue.description,
          ...restFormValues,
          knowledgeSourceIds,
        };

        await updateAssistant(updateAssistantPayload);

        message.success(<Message content={t('storeAI.successfullySavedAssistant')} />);
      } catch (error) {
        const err = (error as unknown) as ApiError;

        message.error(
          <Message content={t('storeAI.errorSavingAssistant')} error={err} />,
        );
      }
    },
    [t, updateAssistant, storeAIAssistantState],
  );

  const initialValues = useMemo((): Partial<StoreAIAssistantFormValues> => {
    if (storeAIAssistantState.data) {
      const {
        name,
        instructions,
        status,
        knowledgeSourceIds,
      } = storeAIAssistantState.data;

      const knowledgeBaseFiles = knowledgeSourceIds.map((fileId) => {
        return {
          fileId,
          fileName: fileId,
        };
      });

      return {
        name,
        instructions,
        status,
        knowledgeBaseFiles,
      };
    }

    return {
      status: GridAssistantStatusEnum.ACTIVE,
    };
  }, [storeAIAssistantState.data]);

  const lastProcessedDate = useMemo((): Date | undefined => {
    return storeAIAssistantState.data && storeAIAssistantState.data.updatedAt
      ? new Date(storeAIAssistantState.data.updatedAt)
      : undefined;
  }, [storeAIAssistantState.data]);

  return (
    <Overlay
      errorOverlay={{
        error: storeAIAssistantState.error,
      }}
      spinnerOverlay={{
        isLoading: storeAIAssistantState.isLoading,
      }}
    >
      <StoreAIAssistantUpdateFormContainer>
        <ActionButtons>
          <BackToListButton type="default" icon="arrow-left" onClick={handleBackClick}>
            {t('storeAI.back')}
          </BackToListButton>
        </ActionButtons>
        {storeAIAssistantState.isSuccess && (
          <Form
            onSubmit={handleFormUpdateSubmit}
            keepDirtyOnReinitialize
            initialValues={initialValues}
            mutators={{
              ...arrayMutators,
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            render={({
              submitting: isSubmitting,
              handleSubmit,
              invalid: isFormInvalid,
              errors,
              form: formApi,
            }) => {
              return (
                <form
                  onSubmit={(event) => {
                    handleSubmit(event);

                    const errorFieldNames = getFieldNamesWithError(errors);

                    if (!isFormInvalid || !errorFieldNames.length) {
                      return;
                    }

                    scrollIntoView(errorFieldNames[0]);
                  }}
                >
                  <Row gutter={{ md: 20, xl: 40 }}>
                    <Col md={24} xl={15}>
                      <StoreAIAssistantFormFields
                        formApi={formApi}
                        tenantId={tenantId}
                        assistantId={storeAIAssistantId}
                        title={t('storeAI.aiAssistant')}
                      />
                    </Col>
                    <StickyColumn md={24} xl={9}>
                      <StoreAISidePanel
                        isSubmitEnabled={!isSubmitting}
                        isSubmitting={isSubmitting}
                        isStatusEnabled={true}
                        lastProcessedDate={lastProcessedDate}
                      />
                    </StickyColumn>
                  </Row>
                </form>
              );
            }}
          />
        )}
      </StoreAIAssistantUpdateFormContainer>
    </Overlay>
  );
};

const StoreAIAssistantUpdateFormContainer = styled.div`
  position: relative;
  flex: 1;
  padding: 0 80px 40px;
  @media screen and (max-width: 991px) {
    padding: 0;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export default StoreAIAssistantUpdateForm;
