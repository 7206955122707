import React, { useMemo } from 'react';
import sumBy from 'lodash/sumBy';
import round from 'lodash/round';
import { DataResidencyEnum } from '../../../../store/types/organisation';
import CardContainer from './card-container';
import SessionsCard from '../charts/sessions-card.component';
import {
  useTenantSessionsByDay,
  useTenantEventsByDay,
  useInstallationSessionsByDay,
  useInstallationEventsByDay,
  useSpaceSessionsByDay,
  useSpaceEventsByDay,
  useDeviceSessionsByDay,
  useDeviceEventsByDay,
  useAppSessionsByDay,
  useAppEventsByDay,
  mergeNestedSpacesSessionsByDay,
} from '../../use-analytics-report';
import {
  TenantSessionsByDay,
  TenantEventsByDay,
  InstallationSessionsByDay,
  InstallationEventsByDay,
  SpaceSessionsByDay,
  SpaceEventsByDay,
  DeviceSessionsByDay,
  DeviceEventsByDay,
  AppSessionsByDay,
  AppEventsByDay,
} from '../../use-analytics-report';
import {
  GridStyles,
  InteractionType,
  SessionInteractionType,
} from '@ombori/grid-reports';
import { getPrecedingPeriod, syncData } from './preceding-period-data';

const title = 'Sessions';

type RawSessions =
  | TenantSessionsByDay
  | InstallationSessionsByDay
  | SpaceSessionsByDay
  | DeviceSessionsByDay
  | AppSessionsByDay;

type RawEvents =
  | TenantEventsByDay
  | InstallationEventsByDay
  | SpaceEventsByDay
  | DeviceEventsByDay
  | AppEventsByDay;

const buildDataItem = (
  date: string,
  data: { label: string; value: number }[],
): [string, { label: string; value: number }[]] => [date, data];

const buildData = (
  rawData: RawSessions[],
): [string, { label: string; value: number }[]][] => {
  const result = rawData.map((item) => {
    return buildDataItem(item.date, [{ label: item.tenantId, value: item.sessionCount }]);
  });

  return result;
};

const getAvgEventsPerSession = (sessions: RawSessions[], events: RawEvents[]): number => {
  const sessionCount = sumBy(sessions, (dataItem) => dataItem.sessionCount);
  const eventCount = sumBy(events, (dataItem) => dataItem.count);

  const result = sessionCount === 0 ? 0 : round(eventCount / sessionCount);

  return result;
};

interface SessionsProps {
  tenantId: string;
  dateFrom: string;
  dateTo: string;
  dataResidency: DataResidencyEnum;
  interactionType?: SessionInteractionType;
  gridStyles?: GridStyles;
  isVisibleWithoutData?: boolean;
}

export const TenantSessions: React.FC<SessionsProps> = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  interactionType,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { dateFrom: precedingDateFrom, dateTo: precedingDateTo } = getPrecedingPeriod(
    dateFrom,
    dateTo,
  );

  const sessionsQueryConfig = useMemo(
    () => ({
      tenantId,
      dataResidency,
      interactionType,
    }),
    [tenantId, dataResidency, interactionType],
  );

  const precedingSessionsFetchingState = useTenantSessionsByDay({
    ...sessionsQueryConfig,
    dateFrom: precedingDateFrom,
    dateTo: precedingDateTo,
  });

  const sessionsFetchingState = useTenantSessionsByDay({
    ...sessionsQueryConfig,
    dateFrom,
    dateTo,
  });

  const eventsQueryConfig = useMemo(
    () => ({
      tenantId,
      dataResidency,
      interactionType:
        interactionType === SessionInteractionType.Interactive
          ? InteractionType.Interactive
          : InteractionType.All,
    }),
    [tenantId, dataResidency, interactionType],
  );

  const precedingEventsFetchingState = useTenantEventsByDay({
    ...eventsQueryConfig,
    dateFrom: precedingDateFrom,
    dateTo: precedingDateTo,
  });

  const eventsFetchingState = useTenantEventsByDay({
    ...eventsQueryConfig,
    dateFrom,
    dateTo,
  });

  const data = useMemo(() => buildData(sessionsFetchingState.data || []), [
    sessionsFetchingState,
  ]);

  const precedingData = useMemo(
    () =>
      syncData({
        data: buildData(precedingSessionsFetchingState.data || []),
        dateFrom: precedingDateFrom,
        dateTo: precedingDateTo,
        buildDataItem,
      }),
    [precedingSessionsFetchingState, precedingDateFrom, precedingDateTo],
  );

  const avgEventsPerSession = useMemo(
    () =>
      getAvgEventsPerSession(
        sessionsFetchingState.data || [],
        eventsFetchingState.data || [],
      ),
    [sessionsFetchingState, eventsFetchingState],
  );

  const precedingAvgEventsPerSession = useMemo(
    () =>
      getAvgEventsPerSession(
        precedingSessionsFetchingState.data || [],
        precedingEventsFetchingState.data || [],
      ),
    [precedingSessionsFetchingState, precedingEventsFetchingState],
  );

  const hasData = useMemo(() => {
    const hasCurrentPeriodData =
      sessionsFetchingState.isSuccess && sessionsFetchingState.data.length > 0;
    const precedingPeriodData =
      precedingSessionsFetchingState.isSuccess &&
      precedingSessionsFetchingState.data.length > 0;

    const result = hasCurrentPeriodData || precedingPeriodData;

    return result;
  }, [sessionsFetchingState, precedingSessionsFetchingState]);

  return (
    <CardContainer
      isLoading={sessionsFetchingState.isLoading}
      isSuccess={sessionsFetchingState.isSuccess}
      isError={sessionsFetchingState.isError}
      hasData={hasData}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {sessionsFetchingState.isSuccess && (
        <SessionsCard
          data={data}
          precedingData={precedingData}
          avgEventsPerSession={avgEventsPerSession}
          precedingAvgEventsPerSession={precedingAvgEventsPerSession}
        />
      )}
    </CardContainer>
  );
};

interface InstallationSessionsProps extends SessionsProps {
  installationId: string;
}

export const InstallationSessions: React.FC<InstallationSessionsProps> = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  dataResidency,
  interactionType,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { dateFrom: precedingDateFrom, dateTo: precedingDateTo } = getPrecedingPeriod(
    dateFrom,
    dateTo,
  );

  const sessionsQueryConfig = useMemo(
    () => ({
      tenantId,
      installationId,
      dataResidency,
      interactionType,
    }),
    [tenantId, installationId, dataResidency, interactionType],
  );

  const precedingSessionsFetchingState = useInstallationSessionsByDay({
    ...sessionsQueryConfig,
    dateFrom: precedingDateFrom,
    dateTo: precedingDateTo,
  });

  const sessionsFetchingState = useInstallationSessionsByDay({
    ...sessionsQueryConfig,
    dateFrom,
    dateTo,
  });

  const eventsQueryConfig = useMemo(
    () => ({
      tenantId,
      installationId,
      dataResidency,
      interactionType:
        interactionType === SessionInteractionType.Interactive
          ? InteractionType.Interactive
          : InteractionType.All,
    }),
    [tenantId, installationId, dataResidency, interactionType],
  );

  const precedingEventsFetchingState = useInstallationEventsByDay({
    ...eventsQueryConfig,
    dateFrom: precedingDateFrom,
    dateTo: precedingDateTo,
  });

  const eventsFetchingState = useInstallationEventsByDay({
    ...eventsQueryConfig,
    dateFrom,
    dateTo,
  });

  const data = useMemo(() => buildData(sessionsFetchingState.data || []), [
    sessionsFetchingState,
  ]);

  const precedingData = useMemo(
    () =>
      syncData({
        data: buildData(precedingSessionsFetchingState.data || []),
        dateFrom: precedingDateFrom,
        dateTo: precedingDateTo,
        buildDataItem,
      }),
    [precedingSessionsFetchingState, precedingDateFrom, precedingDateTo],
  );

  const avgEventsPerSession = useMemo(
    () =>
      getAvgEventsPerSession(
        sessionsFetchingState.data || [],
        eventsFetchingState.data || [],
      ),
    [sessionsFetchingState, eventsFetchingState],
  );

  const precedingAvgEventsPerSession = useMemo(
    () =>
      getAvgEventsPerSession(
        precedingSessionsFetchingState.data || [],
        precedingEventsFetchingState.data || [],
      ),
    [precedingSessionsFetchingState, precedingEventsFetchingState],
  );

  const hasData = useMemo(() => {
    const hasCurrentPeriodData =
      sessionsFetchingState.isSuccess && sessionsFetchingState.data.length > 0;
    const precedingPeriodData =
      precedingSessionsFetchingState.isSuccess &&
      precedingSessionsFetchingState.data.length > 0;

    const result = hasCurrentPeriodData || precedingPeriodData;

    return result;
  }, [sessionsFetchingState, precedingSessionsFetchingState]);

  return (
    <CardContainer
      isLoading={sessionsFetchingState.isLoading}
      isSuccess={sessionsFetchingState.isSuccess}
      isError={sessionsFetchingState.isError}
      hasData={hasData}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {sessionsFetchingState.isSuccess && (
        <SessionsCard
          data={data}
          precedingData={precedingData}
          avgEventsPerSession={avgEventsPerSession}
          precedingAvgEventsPerSession={precedingAvgEventsPerSession}
        />
      )}
    </CardContainer>
  );
};

interface SpaceSessionsProps extends SessionsProps {
  spaceId: string;
}

export const SpaceSessions: React.FC<SpaceSessionsProps> = ({
  tenantId,
  spaceId,
  dateFrom,
  dateTo,
  dataResidency,
  interactionType,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { dateFrom: precedingDateFrom, dateTo: precedingDateTo } = getPrecedingPeriod(
    dateFrom,
    dateTo,
  );

  const sessionsQueryConfig = useMemo(
    () => ({
      tenantId,
      spaceId,
      dataResidency,
      interactionType,
    }),
    [tenantId, spaceId, dataResidency, interactionType],
  );

  const precedingSessionsFetchingState = useSpaceSessionsByDay({
    ...sessionsQueryConfig,
    dateFrom: precedingDateFrom,
    dateTo: precedingDateTo,
    select: mergeNestedSpacesSessionsByDay,
  });

  const sessionsFetchingState = useSpaceSessionsByDay({
    ...sessionsQueryConfig,
    dateFrom,
    dateTo,
    select: mergeNestedSpacesSessionsByDay,
  });

  const eventsQueryConfig = useMemo(
    () => ({
      tenantId,
      spaceId,
      dataResidency,
      interactionType:
        interactionType === SessionInteractionType.Interactive
          ? InteractionType.Interactive
          : InteractionType.All,
    }),
    [tenantId, spaceId, dataResidency, interactionType],
  );

  const precedingEventsFetchingState = useSpaceEventsByDay({
    ...eventsQueryConfig,
    dateFrom: precedingDateFrom,
    dateTo: precedingDateTo,
  });

  const eventsFetchingState = useSpaceEventsByDay({
    ...eventsQueryConfig,
    dateFrom,
    dateTo,
  });

  const data = useMemo(() => buildData(sessionsFetchingState.data || []), [
    sessionsFetchingState,
  ]);

  const precedingData = useMemo(
    () =>
      syncData({
        data: buildData(precedingSessionsFetchingState.data || []),
        dateFrom: precedingDateFrom,
        dateTo: precedingDateTo,
        buildDataItem,
      }),
    [precedingSessionsFetchingState, precedingDateFrom, precedingDateTo],
  );

  const avgEventsPerSession = useMemo(
    () =>
      getAvgEventsPerSession(
        sessionsFetchingState.data || [],
        eventsFetchingState.data || [],
      ),
    [sessionsFetchingState, eventsFetchingState],
  );

  const precedingAvgEventsPerSession = useMemo(
    () =>
      getAvgEventsPerSession(
        precedingSessionsFetchingState.data || [],
        precedingEventsFetchingState.data || [],
      ),
    [precedingSessionsFetchingState, precedingEventsFetchingState],
  );

  const hasData = useMemo(() => {
    const hasCurrentPeriodData =
      sessionsFetchingState.isSuccess && sessionsFetchingState.data.length > 0;
    const precedingPeriodData =
      precedingSessionsFetchingState.isSuccess &&
      precedingSessionsFetchingState.data.length > 0;

    const result = hasCurrentPeriodData || precedingPeriodData;

    return result;
  }, [sessionsFetchingState, precedingSessionsFetchingState]);

  return (
    <CardContainer
      isLoading={sessionsFetchingState.isLoading}
      isSuccess={sessionsFetchingState.isSuccess}
      isError={sessionsFetchingState.isError}
      hasData={hasData}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {sessionsFetchingState.isSuccess && (
        <SessionsCard
          data={data}
          precedingData={precedingData}
          avgEventsPerSession={avgEventsPerSession}
          precedingAvgEventsPerSession={precedingAvgEventsPerSession}
        />
      )}
    </CardContainer>
  );
};

interface DeviceSessionsProps extends SessionsProps {
  deviceId: string;
}

export const DeviceSessions: React.FC<DeviceSessionsProps> = ({
  tenantId,
  deviceId,
  dateFrom,
  dateTo,
  dataResidency,
  interactionType,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { dateFrom: precedingDateFrom, dateTo: precedingDateTo } = getPrecedingPeriod(
    dateFrom,
    dateTo,
  );

  const sessionsQueryConfig = useMemo(
    () => ({
      tenantId,
      deviceId,
      dataResidency,
      interactionType,
    }),
    [tenantId, deviceId, dataResidency, interactionType],
  );

  const precedingSessionsFetchingState = useDeviceSessionsByDay({
    ...sessionsQueryConfig,
    dateFrom: precedingDateFrom,
    dateTo: precedingDateTo,
  });

  const sessionsFetchingState = useDeviceSessionsByDay({
    ...sessionsQueryConfig,
    dateFrom,
    dateTo,
  });

  const eventsQueryConfig = useMemo(
    () => ({
      tenantId,
      deviceId,
      dataResidency,
      interactionType:
        interactionType === SessionInteractionType.Interactive
          ? InteractionType.Interactive
          : InteractionType.All,
    }),
    [tenantId, deviceId, dataResidency, interactionType],
  );

  const precedingEventsFetchingState = useDeviceEventsByDay({
    ...eventsQueryConfig,
    dateFrom: precedingDateFrom,
    dateTo: precedingDateTo,
  });

  const eventsFetchingState = useDeviceEventsByDay({
    ...eventsQueryConfig,
    dateFrom,
    dateTo,
  });

  const data = useMemo(() => buildData(sessionsFetchingState.data || []), [
    sessionsFetchingState,
  ]);

  const precedingData = useMemo(
    () =>
      syncData({
        data: buildData(precedingSessionsFetchingState.data || []),
        dateFrom: precedingDateFrom,
        dateTo: precedingDateTo,
        buildDataItem,
      }),
    [precedingSessionsFetchingState, precedingDateFrom, precedingDateTo],
  );

  const avgEventsPerSession = useMemo(
    () =>
      getAvgEventsPerSession(
        sessionsFetchingState.data || [],
        eventsFetchingState.data || [],
      ),
    [sessionsFetchingState, eventsFetchingState],
  );

  const precedingAvgEventsPerSession = useMemo(
    () =>
      getAvgEventsPerSession(
        precedingSessionsFetchingState.data || [],
        precedingEventsFetchingState.data || [],
      ),
    [precedingSessionsFetchingState, precedingEventsFetchingState],
  );

  const hasData = useMemo(() => {
    const hasCurrentPeriodData =
      sessionsFetchingState.isSuccess && sessionsFetchingState.data.length > 0;
    const precedingPeriodData =
      precedingSessionsFetchingState.isSuccess &&
      precedingSessionsFetchingState.data.length > 0;

    const result = hasCurrentPeriodData || precedingPeriodData;

    return result;
  }, [sessionsFetchingState, precedingSessionsFetchingState]);

  return (
    <CardContainer
      isLoading={sessionsFetchingState.isLoading}
      isSuccess={sessionsFetchingState.isSuccess}
      isError={sessionsFetchingState.isError}
      hasData={hasData}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {sessionsFetchingState.isSuccess && (
        <SessionsCard
          data={data}
          precedingData={precedingData}
          avgEventsPerSession={avgEventsPerSession}
          precedingAvgEventsPerSession={precedingAvgEventsPerSession}
        />
      )}
    </CardContainer>
  );
};

interface AppSessionsProps extends SessionsProps {
  appId: string;
}

export const AppSessions: React.FC<AppSessionsProps> = ({
  tenantId,
  appId,
  dateFrom,
  dateTo,
  dataResidency,
  interactionType,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { dateFrom: precedingDateFrom, dateTo: precedingDateTo } = getPrecedingPeriod(
    dateFrom,
    dateTo,
  );

  const sessionsQueryConfig = useMemo(
    () => ({
      tenantId,
      appId,
      dataResidency,
      interactionType,
    }),
    [tenantId, appId, dataResidency, interactionType],
  );

  const precedingSessionsFetchingState = useAppSessionsByDay({
    ...sessionsQueryConfig,
    dateFrom: precedingDateFrom,
    dateTo: precedingDateTo,
  });

  const sessionsFetchingState = useAppSessionsByDay({
    ...sessionsQueryConfig,
    dateFrom,
    dateTo,
  });

  const eventsQueryConfig = useMemo(
    () => ({
      tenantId,
      appId,
      dataResidency,
      interactionType:
        interactionType === SessionInteractionType.Interactive
          ? InteractionType.Interactive
          : InteractionType.All,
    }),
    [tenantId, appId, dataResidency, interactionType],
  );

  const precedingEventsFetchingState = useAppEventsByDay({
    ...eventsQueryConfig,
    dateFrom: precedingDateFrom,
    dateTo: precedingDateTo,
  });

  const eventsFetchingState = useAppEventsByDay({
    ...eventsQueryConfig,
    dateFrom,
    dateTo,
  });

  const data = useMemo(() => buildData(sessionsFetchingState.data || []), [
    sessionsFetchingState,
  ]);

  const precedingData = useMemo(
    () =>
      syncData({
        data: buildData(precedingSessionsFetchingState.data || []),
        dateFrom: precedingDateFrom,
        dateTo: precedingDateTo,
        buildDataItem,
      }),
    [precedingSessionsFetchingState, precedingDateFrom, precedingDateTo],
  );

  const avgEventsPerSession = useMemo(
    () =>
      getAvgEventsPerSession(
        sessionsFetchingState.data || [],
        eventsFetchingState.data || [],
      ),
    [sessionsFetchingState, eventsFetchingState],
  );

  const precedingAvgEventsPerSession = useMemo(
    () =>
      getAvgEventsPerSession(
        precedingSessionsFetchingState.data || [],
        precedingEventsFetchingState.data || [],
      ),
    [precedingSessionsFetchingState, precedingEventsFetchingState],
  );

  const hasData = useMemo(() => {
    const hasCurrentPeriodData =
      sessionsFetchingState.isSuccess && sessionsFetchingState.data.length > 0;
    const precedingPeriodData =
      precedingSessionsFetchingState.isSuccess &&
      precedingSessionsFetchingState.data.length > 0;

    const result = hasCurrentPeriodData || precedingPeriodData;

    return result;
  }, [sessionsFetchingState, precedingSessionsFetchingState]);

  return (
    <CardContainer
      isLoading={sessionsFetchingState.isLoading}
      isSuccess={sessionsFetchingState.isSuccess}
      isError={sessionsFetchingState.isError}
      hasData={hasData}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {sessionsFetchingState.isSuccess && (
        <SessionsCard
          data={data}
          precedingData={precedingData}
          avgEventsPerSession={avgEventsPerSession}
          precedingAvgEventsPerSession={precedingAvgEventsPerSession}
        />
      )}
    </CardContainer>
  );
};
