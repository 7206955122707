import {
  DataSourceBaseEntity,
  InteractionType,
  SessionInteractionType,
} from '@ombori/grid-reports';
import { useQuery, useQueries, QueryObserverResult } from 'react-query';
import {
  AggregatedDeviceNpsByDay,
  BaseProps,
  DeviceCategoriesByDay,
  DeviceEventsByDay,
  DeviceEventsByHour,
  DeviceEventsFlowByDay,
  DeviceInteractiveSessionsByDay,
  DeviceProductsByDay,
  DevicePurchaseProductByDay,
  DevicePurchasesByDay,
  DeviceQrCodesByDay,
  DeviceMediaByDay,
  DeviceSessionsByDay,
  DeviceSessionsByHour,
  TimeSpanType,
  UseEventsFlowByDayProps,
  DeviceAndSpaceEventsByDay,
} from './types';
import { useGridDataService } from '../../grid-data-service-provider';
import buildQueryKey from './build-query-key';
import routes from '../routes';

interface UseDeviceEventsByDayProps extends BaseProps {
  deviceId: string;
  interactionType?: InteractionType;
  eventType?: string;
}

export const useDeviceEventsByDay = ({
  tenantId,
  deviceId,
  dateFrom,
  dateTo,
  interactionType = InteractionType.All,
  dataResidency,
  eventType,
}: UseDeviceEventsByDayProps) => {
  const gridDataService = useGridDataService();

  const buildDeviceEventsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'deviceId',
    deviceId,
    'interactionType',
    interactionType,
    'events',
    ...(eventType ? ['eventType', eventType] : []),
  ];

  const baseQuery = {
    dateFrom,
    dateTo,
    timespanType: TimeSpanType.Day,
    interactionType,
  };

  const query = eventType ? { ...baseQuery, eventType } : baseQuery;

  return useQuery(
    buildDeviceEventsQuery(),
    () =>
      gridDataService.get<DeviceEventsByDay[]>(
        routes.getDeviceEvents(tenantId, deviceId),
        dataResidency,
        query,
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseDeviceEventsBySpaceAndDay extends BaseProps {
  deviceId: string;
  interactionType?: InteractionType;
  dataSource?: DataSourceBaseEntity;
  eventType?: string;
}

// TODO: remove this
interface UseDeviceListEventsBySpaceAndDay extends BaseProps {
  deviceIds: string[];
  interactionType?: InteractionType;
  dataSource?: DataSourceBaseEntity;
  eventType?: string;
}



export const useDeviceEventsBySpaceAndDay = ({
  tenantId,
  deviceId,
  dateFrom,
  dateTo,
  interactionType = InteractionType.All,
  dataResidency,
  dataSource,
  eventType,
}: UseDeviceEventsBySpaceAndDay) => {
  const gridDataService = useGridDataService();

  const buildDeviceAndSpaceEventsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'de',
    deviceId,
    'deviceId',
    interactionType,
    'events',
    'dataSource',
    dataSource,
    ...(eventType ? ['eventType', eventType] : []),
  ];

  const baseQuery = {
    dateFrom,
    dateTo,
    timespanType: TimeSpanType.Day,
    interactionType,
    dataSource,
  };

  const query = eventType ? { ...baseQuery, eventType } : baseQuery;

  return useQuery(
    buildDeviceAndSpaceEventsQuery(),
    () =>
      gridDataService.get<DeviceAndSpaceEventsByDay[]>(
        routes.getDeviceEvents(tenantId, deviceId),
        dataResidency,
        query,
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export const useDeviceListEventsBySpaceAndDay = ({
  tenantId,
  deviceIds,
  dateFrom,
  dateTo,
  interactionType = InteractionType.All,
  dataResidency,
  dataSource,
  eventType,
}: UseDeviceListEventsBySpaceAndDay) => {
  const gridDataService = useGridDataService();

  const buildDeviceListAndSpaceEventsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'de',
    deviceIds.toString(),
    'deviceId',
    interactionType,
    'events',
    'dataSource',
    dataSource,
    ...(eventType ? ['eventType', eventType] : []),
  ];

  const baseQuery = {
    dateFrom,
    dateTo,
    timespanType: TimeSpanType.Day,
    interactionType,
    dataSource,
  };

  const query = eventType ? { ...baseQuery, eventType } : baseQuery;

  const getAllDevicesEvents = async () => {
    if (deviceIds.length === 0) return [];

    const devicesList = await Promise.all(
      deviceIds.map((deviceId => gridDataService.get<DeviceAndSpaceEventsByDay[]>(
        routes.getDeviceEvents(tenantId, deviceId),
        dataResidency,
        query,
      ))),
    );

    return devicesList.flat();
  }

  return useQuery(
    buildDeviceListAndSpaceEventsQuery(),
    () => getAllDevicesEvents(),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      enabled: deviceIds.length > 0,
    },
  );
};

interface UseDeviceSessionsByDayProps extends BaseProps {
  deviceId: string;
  interactionType?: SessionInteractionType;
}

export const useDeviceSessionsByDay = ({
  tenantId,
  deviceId,
  dateFrom,
  dateTo,
  dataResidency,
  interactionType = SessionInteractionType.All,
}: UseDeviceSessionsByDayProps) => {
  const gridDataService = useGridDataService();

  const buildDeviceSessionsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'interactionType',
    interactionType,
    'deviceId',
    deviceId,
    'sessions',
  ];

  return useQuery(
    buildDeviceSessionsQuery(),
    () =>
      gridDataService.get<
        typeof interactionType extends SessionInteractionType.Interactive
          ? DeviceInteractiveSessionsByDay[]
          : DeviceSessionsByDay[]
      >(routes.getDeviceSessions(tenantId, deviceId), dataResidency, {
        dateFrom,
        dateTo,
        interactionType,
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseDevicesEventsAndSessionsByDayProps extends BaseProps {
  interactionType?: InteractionType;
  eventType?: string;
  isEventsQueryEnabled?: boolean;
  isSessionsQueryEnabled?: boolean;
}

export const useDevicesEventsAndSessionsByDay = ({
  tenantId,
  dateFrom,
  dateTo,
  interactionType = InteractionType.All,
  dataResidency,
  eventType,
  isEventsQueryEnabled = true,
  isSessionsQueryEnabled = true,
}: UseDevicesEventsAndSessionsByDayProps) => {
  const gridDataService = useGridDataService();

  const buildDeviceEventsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'devices',
    'interactionType',
    interactionType,
    'eventsAndSessions',
    ...(eventType ? ['eventType', eventType] : []),
  ];

  const baseQuery = {
    dateFrom,
    dateTo,
    timespanType: TimeSpanType.Day,
    interactionType,
  };

  const query = eventType ? { ...baseQuery, eventType } : baseQuery;

  const fetchEvents = () =>
    isEventsQueryEnabled
      ? gridDataService.get<DeviceEventsByDay[]>(
          routes.getDevicesEvents(tenantId),
          dataResidency,
          query,
        )
      : Promise.resolve([]);

  const fetchSessions = () =>
    isSessionsQueryEnabled
      ? gridDataService.get<DeviceSessionsByDay[]>(
          routes.getDevicesSessions(tenantId),
          dataResidency,
          query,
        )
      : Promise.resolve([]);

  const queryFn = async () => {
    const [events, sessions] = await Promise.all([fetchEvents(), fetchSessions()]);

    return { events, sessions };
  };

  return useQuery(buildDeviceEventsQuery(), queryFn, {
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
  });
};

interface UseDeviceNpsByDayProps extends BaseProps {
  deviceId: string;
}

export const useDeviceNpsByDay = ({
  tenantId,
  deviceId,
  dateFrom,
  dateTo,
  dataResidency,
}: UseDeviceNpsByDayProps) => {
  const gridDataService = useGridDataService();

  const buildDeviceNpsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'deviceId',
    deviceId,
    'nps',
  ];

  return useQuery(
    buildDeviceNpsQuery(),
    () =>
      gridDataService.get<AggregatedDeviceNpsByDay>(
        routes.getDeviceNps(tenantId, deviceId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseDeviceEventsFlowByDayProps extends UseEventsFlowByDayProps {
  deviceId: string;
}

export const useDeviceEventsFlowByDay = ({
  tenantId,
  deviceId,
  dateFrom,
  dateTo,
  dataResidency,
  eventsFlowDepth,
}: UseDeviceEventsFlowByDayProps) => {
  const gridDataService = useGridDataService();

  const buildDeviceEventsFlowQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'deviceId',
    deviceId,
    'eventsFlowDepth',
    eventsFlowDepth,
    'eventsFlow',
  ];

  return useQuery(
    buildDeviceEventsFlowQuery(),
    () =>
      gridDataService.get<DeviceEventsFlowByDay[]>(
        routes.getDeviceEventsFlow(tenantId, deviceId),
        dataResidency,
        {
          dateFrom,
          dateTo,
          eventsFlowDepth,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseDeviceProductsByDayProps extends BaseProps {
  deviceId: string;
}

export const useDeviceProductsByDay = ({
  tenantId,
  deviceId,
  dateFrom,
  dateTo,
  dataResidency,
}: UseDeviceProductsByDayProps) => {
  const gridDataService = useGridDataService();

  const buildDeviceProductsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'deviceId',
    deviceId,
    'products',
  ];

  return useQuery(
    buildDeviceProductsQuery(),
    () =>
      gridDataService.get<DeviceProductsByDay[]>(
        routes.getDeviceProducts(tenantId, deviceId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseDeviceCategoriesByDayProps extends BaseProps {
  deviceId: string;
}

export const useDeviceCategoriesByDay = ({
  tenantId,
  deviceId,
  dateFrom,
  dateTo,
  dataResidency,
}: UseDeviceCategoriesByDayProps) => {
  const gridDataService = useGridDataService();

  const buildDeviceCategoriesQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'deviceId',
    deviceId,
    'categories',
  ];

  return useQuery(
    buildDeviceCategoriesQuery(),
    () =>
      gridDataService.get<DeviceCategoriesByDay[]>(
        routes.getDeviceCategories(tenantId, deviceId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseDevicePurchasesByDayProps extends BaseProps {
  deviceId: string;
}

export const useDevicePurchasesByDay = ({
  tenantId,
  deviceId,
  dateFrom,
  dateTo,
  dataResidency,
}: UseDevicePurchasesByDayProps) => {
  const gridDataService = useGridDataService();

  const buildDevicePurchasesQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'deviceId',
    deviceId,
    'purchases',
  ];

  return useQuery(
    buildDevicePurchasesQuery(),
    () =>
      gridDataService.get<DevicePurchasesByDay[]>(
        routes.getDevicePurchases(tenantId, deviceId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseDevicePurchasedProductsByDayProps extends BaseProps {
  deviceId: string;
}

export const useDevicePurchasedProductsByDay = ({
  tenantId,
  deviceId,
  dateFrom,
  dateTo,
  dataResidency,
}: UseDevicePurchasedProductsByDayProps) => {
  const gridDataService = useGridDataService();

  const buildDevicePurchasesQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'deviceId',
    deviceId,
    'purchased-products',
  ];

  return useQuery(
    buildDevicePurchasesQuery(),
    () =>
      gridDataService.get<DevicePurchaseProductByDay[]>(
        routes.getDevicePurchasedProducts(tenantId, deviceId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseDeviceQrCodesByDayProps extends BaseProps {
  deviceId: string;
}

export const useDeviceQrCodesByDay = ({
  tenantId,
  deviceId,
  dateFrom,
  dateTo,
  dataResidency,
}: UseDeviceQrCodesByDayProps) => {
  const gridDataService = useGridDataService();

  const buildDeviceQrCodesQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'deviceId',
    deviceId,
    'qr-codes',
  ];

  return useQuery(
    buildDeviceQrCodesQuery(),
    () =>
      gridDataService.get<DeviceQrCodesByDay[]>(
        routes.getDeviceQrCodes(tenantId, deviceId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseDeviceMediaByDayProps extends BaseProps {
  deviceId: string;
}

export const useDeviceMediaByDay = ({
  tenantId,
  deviceId,
  dateFrom,
  dateTo,
  dataResidency,
}: UseDeviceMediaByDayProps) => {
  const gridDataService = useGridDataService();

  const buildDeviceMediaQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'deviceId',
    deviceId,
    'media',
  ];

  return useQuery(
    buildDeviceMediaQuery(),
    () =>
      gridDataService.get<DeviceMediaByDay[]>(
        routes.getDeviceMedia(tenantId, deviceId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseDevicesMediaByDayProps extends BaseProps {
  devicesIds: string[];
}

export const useDevicesMediaByDay = ({
  tenantId,
  devicesIds,
  dateFrom,
  dateTo,
  dataResidency,
}: UseDevicesMediaByDayProps) => {
  const gridDataService = useGridDataService();

  const buildDeviceMediaQuery = (deviceId: string) => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'deviceId',
    deviceId,
    'media',
  ];

  const queries = devicesIds.map((deviceId) => ({
    queryKey: buildDeviceMediaQuery(deviceId),
    queryFn: () =>
      gridDataService.get<DeviceMediaByDay[]>(
        routes.getDeviceMedia(tenantId, deviceId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
  }));

  return useQueries(queries) as QueryObserverResult<DeviceMediaByDay[], unknown>[];
};

export const useDeviceSessionsByHour = ({
  tenantId,
  deviceId,
  dateFrom,
  dateTo,
  dataResidency,
  interactionType = SessionInteractionType.All,
}: UseDeviceSessionsByDayProps) => {
  const gridDataService = useGridDataService();

  const timespanType = TimeSpanType.Hour;

  const buildDeviceSessionsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType }),
    'interactionType',
    interactionType,
    'deviceId',
    deviceId,
    'sessions',
  ];

  return useQuery(
    buildDeviceSessionsQuery(),
    () =>
      gridDataService.get<DeviceSessionsByHour[]>(
        routes.getDeviceSessions(tenantId, deviceId),
        dataResidency,
        {
          dateFrom,
          dateTo,
          timespanType,
          interactionType,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export const useDeviceEventsByHour = ({
  tenantId,
  deviceId,
  dateFrom,
  dateTo,
  interactionType = InteractionType.All,
  dataResidency,
}: UseDeviceEventsByDayProps) => {
  const gridDataService = useGridDataService();

  const timespanType = TimeSpanType.Hour;

  const buildDeviceEventsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType }),
    'deviceId',
    deviceId,
    'interactionType',
    interactionType,
    'events',
  ];

  return useQuery(
    buildDeviceEventsQuery(),
    () =>
      gridDataService.get<DeviceEventsByHour[]>(
        routes.getDeviceEvents(tenantId, deviceId),
        dataResidency,
        {
          dateFrom,
          dateTo,
          timespanType,
          interactionType,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};
