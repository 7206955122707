import styled from '@emotion/styled';
import { Alert, Button, Divider as AntdDivider } from 'antd';
import PanelCardComponent from '../../../../common/panel-card/panel-card.component';
import { ButtonType } from '../../../../../types';

export const PanelCard = styled(PanelCardComponent)`
  padding: 20px;
  margin-bottom: 16px;
  width: 100%;
  .ant-card-head {
    border-bottom: unset;
  }
  .ant-card-body {
    padding: 0;
  }
`;

export const Title = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
`;

export const Description = styled.p`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 8px;
`;

export const Divider = styled(AntdDivider)`
  width: calc(100% + 40px);
  margin: 20px -20px;
`;

export const AlertInfo = styled(Alert)`
  background: linear-gradient(0deg, #E6F7FF, #E6F7FF), linear-gradient(0deg, #91D5FF, #91D5FF);
  border: 1px solid #91D5FF;
  font-size: 12px;

  .anticon {
    color: #1890FF;
    top: 50%;
    transform: translateY(-50%);
  }

  span {
    color: #8f8d8d;
  }
`;

export const BackToListButton = styled(Button)`
  height: 36px;
  font-size: 14px;
  color: #676973;
  border: none;
  margin-right: 5px;
  background-color: transparent;
  box-shadow: none;

  :hover,
  :active,
  :focus {
    background-color: #f2f4f8;
  }
` as ButtonType;

export const LinkText = styled.a`
  line-height: 1.5;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-size: 14px;
  color: #689bc4 !important;

  :hover {
    color: #4480b8 !important;
  }

  :active {
    color: #154785 !important;
  }
`;
