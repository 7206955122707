const onInterceptorsReject = (err: any) => {
  if (
    err.response &&
    err.response.status === 401 &&
    !['/', '/login'].includes(document.location.pathname)
  ) {
    document.location.href = `/login?returnPath=${encodeURIComponent(
      `${document.location.pathname}${document.location.search}`,
    )}`;
  }

  if (err.response && err.response.status === 403) {
    // TODO add a window which says that not enough permission
    // eslint-disable-next-line no-console
    console.log('Not enough permissions for the operation');
  }

  return Promise.reject(err);
};

export default onInterceptorsReject;
