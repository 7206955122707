import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import styled from '@emotion/styled';
import { Tabs } from 'antd';
import { Package, Settings } from 'react-feather';
import { useTranslation } from 'react-i18next';
import Header, { Subheader } from '../../common/app-layout/header';
import TabBar from '../../common/tab-bar/tab-bar.component';
import ErrorBoundary from '../../common/error-boundary/error-boundary.component';
import ProtectedRouteContainer from '../../common/protected-route/protected-route.container';
import BootApi from './boot-api/boot-api.component';
import DeviceBundle from './device-bundle/device-bundle.component';
import { Icon } from '../../common/schema-form/common';

interface GlobalConfigurationProps extends RouteComponentProps{}

enum MenuKeysEnum {
  BOOT_API = 'boot-api',
  DEVICE_BUNDLE = 'device-bundle',
}

const MenuTabs = {
  [MenuKeysEnum.BOOT_API]: {
    path: '/boot-api',
  },
  [MenuKeysEnum.DEVICE_BUNDLE]: {
    path: '/device-bundle',
  },
};

const GlobalConfiguration = (props: GlobalConfigurationProps) => {
  const { location, match, history } = props;

  const [tabKey, setTabKey] = useState<MenuKeysEnum>(MenuKeysEnum.BOOT_API);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const segment = location.pathname.replace(match.url, '').split('/')[1];

    setTabKey(segment as MenuKeysEnum);
  }, [match, location]);

  const onTabChange = useCallback(
    (key: string) => {
      const menuKey = key as MenuKeysEnum;
      setTabKey(menuKey);

      const path = MenuTabs[menuKey].path;
      history.push(`${match.url}${path}`);
    },
    [history, match],
  );

  return (
    <>
      <Header
        tabTitle={t('globalConfigurations')}
        title={t('globalConfigurations')}
        icon={<Settings size={20} color="#fff" />}
        isBordered={false}
      />
      <SubheaderStyled
        components={[
          <TabBar
            key="search-bar"
            onChange={onTabChange}
            activeKey={tabKey}
            lang={i18n.language}
          >
            <Tabs.TabPane
              key={MenuKeysEnum.BOOT_API}
              tab={
                <span>
                  <Icon type="api" size={16} />
                  {t('bootApi')}
                </span>
              }
            />
            <Tabs.TabPane
              key={MenuKeysEnum.DEVICE_BUNDLE}
              tab={
                <span>
                  <Icon component={() => <Package size={16} />} />
                  {t('deviceBundle')}
                </span>
              }
            />
          </TabBar>,
        ]}
      />
      <ErrorBoundary>
        <Switch>
          <ProtectedRouteContainer
            exact
            path={`${match.path}/boot-api`}
            component={BootApi}
          />
          <ProtectedRouteContainer
            exact
            path={`${match.path}/device-bundle`}
            component={DeviceBundle}
          />
          <ProtectedRouteContainer
            exact
            path={match.path}
            render={() => <Redirect to={`${match.url}/boot-api`} />}
          />
        </Switch>
      </ErrorBoundary>
    </>
  );
};

const SubheaderStyled = styled(Subheader)`
  background-color: #f2f2f7;
  min-height: unset !important;
`;

export default GlobalConfiguration;
