import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { RouteComponentProps } from 'react-router';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import AppLayout from '../../common/app-layout/app-layout.component';
import Header from '../../common/app-layout/header/header.component';
import { SchemaFormRef } from '../../common/schema-form/schema-form.component';
import Organisation from '../../../store/types/organisation';
import Browser from '../../../store/types/browser';
import { getBrowserData } from '../../../utils/browsers/get-browser-data';
import BrowserCreateForm from '../../common/browsers/browser-create-form/browser-create-form.component';

interface BrowserCreateProps extends RouteComponentProps {
  environments: any;
  apps: any;
  spaces: any;
  organizations: Organisation[];
  fetchSpaces: (params: { organizationId: string }) => void;
  createBrowser: (browser: Partial<Browser>) => Promise<string>;
  fetchEnvironments: (params: { organizationId: string }) => void;
  fetchApps: (params: { organizationId: string }) => void;
}

const BrowserAddContent = styled.div`
  position: relative;
  flex: 1;
  padding: 16px 16px 40px 16px;

  @media (min-width: 768px) {
    padding: 40px;
  }
`;

const BrowserAddFormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  margin: 0 -16px;
  background-color: rgb(251, 251, 253);
`;

const BrowserCreate = (props: BrowserCreateProps) => {
  const {
    location,
    fetchApps,
    organizations,
    fetchEnvironments,
    fetchSpaces,
    apps,
    environments,
    spaces,
    createBrowser,
    history,
  } = props;
  const [initialValues, setInitialValues] = useState<Partial<Browser>>({});
  const formElement = useRef<SchemaFormRef>(null);
  const { t } = useTranslation();

  const handleSubmit = useCallback(() => {
    if (formElement.current) {
      formElement.current.submit();
    }
  }, [formElement]);

  const onSubmit = async (formData: Partial<Browser>) => {
    const data = getBrowserData({ ...formData });
    const params = new URLSearchParams(location.search);
    const deviceId = params.get('deviceId') || undefined;
    const browserId = await createBrowser({
      ...data,
      deviceId,
    });
    history.push(`/organisations/${data.organizationId}/devices/v3/${browserId}`);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setInitialValues({ uuid: params.get('uuid') || '' });
  }, [location.search, setInitialValues]);

  return (
    <AppLayout>
      <AppLayout.Content>
        <Header title={t('addScreen')} />
        <BrowserAddContent>
          <BrowserCreateForm
            browser={initialValues}
            onSubmit={onSubmit}
            ref={formElement}
            environments={environments}
            organizations={organizations}
            apps={apps}
            spaces={spaces}
            fetchSpaces={fetchSpaces}
            fetchEnvironments={fetchEnvironments}
            fetchApps={fetchApps}
            showOrgAndAppFields
          />
          <BrowserAddFormFooter>
            <Button size="large" type="primary" onClick={handleSubmit}>
              {t('submit')}
            </Button>
          </BrowserAddFormFooter>
        </BrowserAddContent>
      </AppLayout.Content>
    </AppLayout>
  );
};

export default BrowserCreate;
