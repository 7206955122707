import React, { useMemo } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePagination } from '../../../../common/pagination';
import usePaginationQueryParams from '../../../../common/use-pagination-query-params';
import { PaginationQueryParams } from '../../../../common/use-pagination-query-params/use-pagination-query-params';
import useChannelsList from '../../../../../store/hooks/channels/use-channels-list';
import { ColumnProps } from 'antd/lib/table';
import { Icon } from '../../../../common/schema-form/common';
import { Channel } from '../../../../../store/types/channel';
import styled from '@emotion/styled';
import Overlay from '../../../../common/overlay';
import { Button, Card, Col, Row, Typography } from 'antd';
import PaginatedListTable from '../../../../common/paginated-list/paginatied-list-table';
import OrganizationMediaChannelsListActions from './organization-media-channels-list-actions.component';
import moment from 'moment/moment';

const { Title } = Typography;

interface OrganizationMediaChannelProps extends RouteComponentProps<{ organisationId: string }> {
}

interface OrganizationMediaChannelsListPaginationParams extends PaginationQueryParams {}

const formatDate = (dateToFormat: string | undefined, format: string = 'DD/MM/YYYY') => {
  if (dateToFormat === undefined || !moment(dateToFormat).isValid()) {
    return 'N/A';
  }
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return moment.tz(dateToFormat, userTimezone).format(format);
};

const OrganisationMediaChannelsListComponent = (props: OrganizationMediaChannelProps) => {
  const { match } = props;
  const { t } = useTranslation();
  const { organisationId } = match.params;

  const { page, defaultPage, pageSize, defaultPageSize } = usePagination();

  const [paginationQueryParams] = usePaginationQueryParams<OrganizationMediaChannelsListPaginationParams>({
    page: page || defaultPage,
    limit: pageSize || defaultPageSize,
  });

  const {
    data: channelsData,
    isLoading: isChannelsLoading,
    isFetching: isChannelsFetching,
    isError: isChannelsListError,
  } = useChannelsList(organisationId, paginationQueryParams.limit, paginationQueryParams.page);

  const columns = useMemo<ColumnProps<Channel>[]>(() => {
    return [
      {
        title: t('contents.name'),
        key: 'organization-media-channel-name',
        width: '30%',
        render: (_, record) => (
          <Link to={`${match.url}/${record.id}/segments`}>
          <CapitalizeText>
            {record.name}
          </CapitalizeText>
          </Link>
        ),
      },
      {
        title: t('contents.description'),
        key: 'organization-media-channel-description',
        width: '50%',
        render: (_, record) => {
          return record.description || '';
        },
      },
      {
        title: t('contents.updatedAt'),
        key: 'organization-media-channel-updated-at',
        width: '15%',
        render: (_, record) => (
          <MutedText>
            {formatDate(record.updatedAt, 'DD-MM-YYYY hh:mm')}
          </MutedText>
        ),
      },
      {
        title: <Icon type="setting" />,
        key: 'organization-media-channel-actions',
        width: '5%',
        align: 'center',
        render: (_, record) => (
          <OrganizationMediaChannelsListActions
            channel={record}
            organizationId={organisationId} />
        ),
      },
    ];
  }, [t, organisationId, match.url]);


  return (
    <>
      <Overlay>
        {isChannelsListError &&
          <Row>
            <ErrorCard>
              <ErrorIcon type="frown" />
              <Message level={1}>{t('somethingWentWrong')}</Message>
            </ErrorCard>
          </Row>
        }
        {!isChannelsListError &&
          <Row>
            <Col>
              <ButtonWrap>
                <Link to={`${match.url}/create`}>
                  <Button icon="plus" type="primary" size="large">
                    {t('contents.createChannel')}
                  </Button>
                </Link>
              </ButtonWrap>
            </Col>
            <Col>
              <PaginatedListTable<Channel>
                data={channelsData!}
                columns={columns}
                isDataLoading={isChannelsLoading || isChannelsFetching}
              />
            </Col>
          </Row>
        }
      </Overlay>
    </>
  );
};

const CapitalizeText = styled.span`
  text-transform: capitalize;
`;

const ErrorCard = styled(Card)`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
`;

const ErrorIcon = styled(Icon)`
  font-size: 64px;
  margin-bottom: 16px;
`;

const Message = styled(Title)`
  margin-top: 12px;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  margin-right: 80px;

  @media screen and (max-width: 991px) {
    margin-right: 20px;
  }
`;

const MutedText = styled.p`
  color: #6c757d !important;
`;

export default OrganisationMediaChannelsListComponent;