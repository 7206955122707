import UniversalDevice from '../store/types/universal-device';
import DeviceStatusEnum from '../store/types/device-status.enum';

export default function sortUniversalDevices(
  devices: UniversalDevice[],
): UniversalDevice[] {
  return devices.sort((a, b) => {
    if (a.status === DeviceStatusEnum.OFFLINE && b.status === DeviceStatusEnum.OK) {
      return -1;
    }

    if (a.status === DeviceStatusEnum.OK && b.status === DeviceStatusEnum.OFFLINE) {
      return 1;
    }

    if (
      a.status === DeviceStatusEnum.OFFLINE &&
      b.status === DeviceStatusEnum.OFFLINE &&
      a.screenshotUpdated &&
      !b.screenshotUpdated
    ) {
      return -1;
    }

    // Sort offline devices by how recently they went offline (screenshotUpdated)
    if (
      a.status === DeviceStatusEnum.OFFLINE &&
      b.status === DeviceStatusEnum.OFFLINE &&
      a.screenshotUpdated &&
      b.screenshotUpdated
    ) {
      return (
        new Date(b.screenshotUpdated).getTime() - new Date(a.screenshotUpdated).getTime()
      );
    }

    // Sort online devices by device name
    if (
      a.status === DeviceStatusEnum.OK &&
      b.status === DeviceStatusEnum.OK &&
      a.deviceName &&
      b.deviceName
    ) {
      // Sort devices running the same app by device name
      if (a.deviceName === b.deviceName) {
        if (a.deviceName < b.deviceName) return -1;
        if (a.deviceName > b.deviceName) return 1;
      }
      if (a.deviceName < b.deviceName) return -1;
      if (a.deviceName > b.deviceName) return 1;
    }

    return 0;
  });
}
