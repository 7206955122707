import React from 'react';
import groupBy from 'lodash/groupBy';
import toPairs from 'lodash/toPairs';
import sumBy from 'lodash/sumBy';
import orderBy from 'lodash/orderBy';
import { DataResidencyEnum } from '../../../../store/types/organisation';
import {
  TenantPurchaseProductByDay,
  InstallationPurchaseProductByDay,
  SpacePurchaseProductByDay,
  DevicePurchaseProductByDay,
  AppPurchaseProductByDay,
  useTenantPurchasedProductsByDay,
  useInstallationPurchasedProductsByDay,
  useSpacePurchasedProductsByDay,
  useDevicePurchasedProductsByDay,
  useAppPurchasedProductsByDay,
} from '../../use-analytics-report';
import CardContainer from './card-container';
import PieChart from '../charts/pie';
import { GridStyles } from '@ombori/grid-reports';

const title = 'Product purchases';

type RawData =
  | TenantPurchaseProductByDay
  | InstallationPurchaseProductByDay
  | SpacePurchaseProductByDay
  | DevicePurchaseProductByDay
  | AppPurchaseProductByDay;

type CardDataItem = [string, { label: string; value: number }[]];

type CardData = CardDataItem[];

const buildDataItem = (
  date: string,
  data: { label: string; value: number }[],
): CardDataItem => [date, data];

const maxEventsCount = 7;

const aggregateData = (
  rawData: RawData[],
): { productId: string; productName: string; count: number }[] => {
  const aggregatedEvents = orderBy(
    toPairs(groupBy(rawData, (dataItem) => dataItem.productId)).map(
      ([productId, events]) => {
        const firstEvent = events[0];

        const result = {
          productId,
          productName: firstEvent.productName,
          count: sumBy(events, (event) => event.count * event.quantity),
        };

        return result;
      },
    ),
    (dataItem) => dataItem.count,
    'desc',
  );

  return aggregatedEvents;
};

const buildData = (rawData: RawData[]): CardData => {
  const aggregatedEvents = aggregateData(rawData);

  const topEvents = aggregatedEvents.slice(0, maxEventsCount);

  const result = topEvents
    .map((event) => ({
      ...event,
      productId: event.productName,
    }))
    .map((dataItem) => {
      const list = [{ label: dataItem.productId, value: dataItem.count }];

      const result = buildDataItem(dataItem.productId, list);

      return result;
    });

  return result;
};

interface BaseProps {
  tenantId: string;
  dateFrom: string;
  dateTo: string;
  dataResidency: DataResidencyEnum;
  gridStyles?: GridStyles;
  isVisibleWithoutData?: boolean;
}

export const TenantPurchasedProductsEventCount: React.FC<BaseProps> = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const purchasedProductsFetchingState = useTenantPurchasedProductsByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const data = buildData(purchasedProductsFetchingState.data || []);

  const totalEventsCount = sumBy(
    purchasedProductsFetchingState.data || [],
    (event) => event.count,
  );

  return (
    <CardContainer
      isLoading={purchasedProductsFetchingState.isLoading}
      isSuccess={purchasedProductsFetchingState.isSuccess}
      isError={purchasedProductsFetchingState.isError}
      hasData={data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {purchasedProductsFetchingState.isSuccess && (
        <PieChart title={title} data={data} total={totalEventsCount} />
      )}
    </CardContainer>
  );
};

interface InstallationProps extends BaseProps {
  installationId: string;
}

export const InstallationPurchasedProductsEventCount: React.FC<InstallationProps> = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const purchasedProductsFetchingState = useInstallationPurchasedProductsByDay({
    tenantId,
    installationId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const data = buildData(purchasedProductsFetchingState.data || []);

  const totalEventsCount = sumBy(
    purchasedProductsFetchingState.data || [],
    (event) => event.count,
  );

  return (
    <CardContainer
      isLoading={purchasedProductsFetchingState.isLoading}
      isSuccess={purchasedProductsFetchingState.isSuccess}
      isError={purchasedProductsFetchingState.isError}
      hasData={data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {purchasedProductsFetchingState.isSuccess && (
        <PieChart title={title} data={data} total={totalEventsCount} />
      )}
    </CardContainer>
  );
};

interface SpaceProps extends BaseProps {
  spaceId: string;
}

export const SpacePurchasedProductsEventCount: React.FC<SpaceProps> = ({
  tenantId,
  spaceId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const purchasedProductsFetchingState = useSpacePurchasedProductsByDay({
    tenantId,
    spaceId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const data = buildData(purchasedProductsFetchingState.data || []);

  const totalEventsCount = sumBy(
    purchasedProductsFetchingState.data || [],
    (event) => event.count,
  );

  return (
    <CardContainer
      isLoading={purchasedProductsFetchingState.isLoading}
      isSuccess={purchasedProductsFetchingState.isSuccess}
      isError={purchasedProductsFetchingState.isError}
      hasData={data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {purchasedProductsFetchingState.isSuccess && (
        <PieChart title={title} data={data} total={totalEventsCount} />
      )}
    </CardContainer>
  );
};

interface DeviceProps extends BaseProps {
  deviceId: string;
}

export const DevicePurchasedProductsEventCount: React.FC<DeviceProps> = ({
  tenantId,
  deviceId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const purchasedProductsFetchingState = useDevicePurchasedProductsByDay({
    tenantId,
    deviceId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const data = buildData(purchasedProductsFetchingState.data || []);

  const totalEventsCount = sumBy(
    purchasedProductsFetchingState.data || [],
    (event) => event.count,
  );

  return (
    <CardContainer
      isLoading={purchasedProductsFetchingState.isLoading}
      isSuccess={purchasedProductsFetchingState.isSuccess}
      isError={purchasedProductsFetchingState.isError}
      hasData={data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {purchasedProductsFetchingState.isSuccess && (
        <PieChart title={title} data={data} total={totalEventsCount} />
      )}
    </CardContainer>
  );
};

interface AppProps extends BaseProps {
  appId: string;
}

export const AppPurchasedProductsEventCount: React.FC<AppProps> = ({
  tenantId,
  appId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const purchasedProductsFetchingState = useAppPurchasedProductsByDay({
    tenantId,
    appId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const data = buildData(purchasedProductsFetchingState.data || []);

  const totalEventsCount = sumBy(
    purchasedProductsFetchingState.data || [],
    (event) => event.count,
  );

  return (
    <CardContainer
      isLoading={purchasedProductsFetchingState.isLoading}
      isSuccess={purchasedProductsFetchingState.isSuccess}
      isError={purchasedProductsFetchingState.isError}
      hasData={data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {purchasedProductsFetchingState.isSuccess && (
        <PieChart title={title} data={data} total={totalEventsCount} />
      )}
    </CardContainer>
  );
};
