import React, { useMemo } from 'react';
import {
  WeekHeatmapProps,
  WeekHeatmapSessionsProps,
  WeekHeatmapEventsProps,
} from './types';
import CardContainer from '../card-container';
import WeekHeatmap from '../../charts/week-heatmap';
import {
  useSpaceSessionsByHour,
  useSpaceEventsByHour,
} from '../../../use-analytics-report';
import { buildSessionsData, buildEventsData } from './generate';
import { WeekHeatmapDataSourceType } from '@ombori/grid-reports';

interface SpaceWeekHeatmapBySessionsProps extends WeekHeatmapSessionsProps {
  spaceId: string;
}

const SpaceWeekHeatmapBySessions: React.FC<SpaceWeekHeatmapBySessionsProps> = ({
  tenantId,
  spaceId,
  dateFrom,
  dateTo,
  dataResidency,
  title,
  dataSource,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const spaceSessionsState = useSpaceSessionsByHour({
    tenantId,
    spaceId,
    dateFrom,
    dateTo,
    dataResidency,
    interactionType: dataSource.interactionType,
  });

  const data = useMemo(() => buildSessionsData(spaceSessionsState.data || []), [
    spaceSessionsState,
  ]);

  return (
    <CardContainer
      isLoading={spaceSessionsState.isLoading}
      isSuccess={spaceSessionsState.isSuccess}
      isError={spaceSessionsState.isError}
      hasData={spaceSessionsState.isSuccess && spaceSessionsState.data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      <WeekHeatmap data={data} />
    </CardContainer>
  );
};

interface SpaceWeekHeatmapByEventsProps extends WeekHeatmapEventsProps {
  spaceId: string;
}

const SpaceWeekHeatmapByEvents: React.FC<SpaceWeekHeatmapByEventsProps> = ({
  tenantId,
  spaceId,
  dateFrom,
  dateTo,
  dataResidency,
  title,
  dataSource,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const spaceEventsState = useSpaceEventsByHour({
    tenantId,
    spaceId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const data = useMemo(
    () => buildEventsData(spaceEventsState.data || [], dataSource.eventType),
    [spaceEventsState, dataSource],
  );

  return (
    <CardContainer
      isLoading={spaceEventsState.isLoading}
      isSuccess={spaceEventsState.isSuccess}
      isError={spaceEventsState.isError}
      hasData={spaceEventsState.isSuccess && spaceEventsState.data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      <WeekHeatmap data={data} />
    </CardContainer>
  );
};

interface SpaceWeekHeatmapProps extends WeekHeatmapProps {
  spaceId: string;
}

const SpaceWeekHeatmap: React.FC<SpaceWeekHeatmapProps> = ({
  tenantId,
  spaceId,
  dateFrom,
  dateTo,
  title,
  dataResidency,
  dataSource,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const view = useMemo(() => {
    switch (dataSource.type) {
      case WeekHeatmapDataSourceType.Sessions:
        return (
          <SpaceWeekHeatmapBySessions
            tenantId={tenantId}
            spaceId={spaceId}
            dateFrom={dateFrom}
            dateTo={dateTo}
            title={title}
            dataResidency={dataResidency}
            dataSource={dataSource}
            gridStyles={gridStyles}
            isVisibleWithoutData={isVisibleWithoutData}
          />
        );
      case WeekHeatmapDataSourceType.Events:
        return (
          <SpaceWeekHeatmapByEvents
            tenantId={tenantId}
            spaceId={spaceId}
            dateFrom={dateFrom}
            dateTo={dateTo}
            title={title}
            dataResidency={dataResidency}
            dataSource={dataSource}
            gridStyles={gridStyles}
            isVisibleWithoutData={isVisibleWithoutData}
          />
        );
      default:
        throw new Error(`Unknown data source: ${JSON.stringify(dataSource)}`);
    }
  }, [
    tenantId,
    spaceId,
    dateFrom,
    dateTo,
    title,
    dataResidency,
    dataSource,
    gridStyles,
    isVisibleWithoutData,
  ]);

  return <>{view}</>;
};

export default SpaceWeekHeatmap;
