import { useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import axios, { CancelTokenSource } from 'axios';

interface Video {
  id: number;
  width: number;
  height: number;
  url: string;
  image: string;
  duration: number;
  user: User;
  video_files: VideoFile[];
  video_pictures: VideoPicture[];
}

interface User {
  id: number;
  name: string;
  url: string;
}

interface VideoFile {
  id: number;
  quality: string;
  file_type: string;
  width: number;
  height: number;
  link: string;
}

interface VideoPicture {
  id: number;
  picture: string;
  nr: number;
}

export interface Photo {
  id: number;
  width: number;
  height: number;
  url: string;
  photographer: string;
  photographer_url: string;
  src: {
    original: string;
    large2x: string;
    large: string;
    medium: string;
    small: string;
    portrait: string;
    landscape: string;
    tiny: string;
  };
}

export enum MediaTypeEnum {
  'PHOTO' = 'photo',
  'VIDEO' = 'video',
}

export enum OrientationEnum {
  'LANDSCAPE' = 'landscape',
  'PORTRAIT' = 'portrait',
  'SQUARE' = 'square'
}

export interface PexelsMediaSearchResponse {
  page: number;
  per_page: number;
  photos?: Photo[];
  videos?: Video[];
  next_page: string;
}

const axiosInstance = axios.create({
  baseURL: `https://api.pexels.com`,
  headers: {
      Authorization: '99G56dW7y0dHYyPRpI3OLFB8FNmfKwUhKoOGm9gjiuaX446DMGzojz9K'
  }
});

export interface UsePexelsCollectionProps {
  pageSize?: number;
  query: string;
  orientation: 'landscape' | 'portrait' | 'square';
  mediaType: MediaTypeEnum;
}

export const usePexelsMediaCollection = ({
  pageSize = 24,
  query,
  orientation,
  mediaType,
}: UsePexelsCollectionProps) => {
  const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource | null>(null);

  return useInfiniteQuery<PexelsMediaSearchResponse, Error>(
    ['search', query, pageSize, orientation, mediaType],
    ({ pageParam = 1 }) => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel();
      }

      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const url = mediaType === MediaTypeEnum.PHOTO
        ? '/v1/search'
        : '/videos/search';

      return axiosInstance.get<PexelsMediaSearchResponse>(url, {
        params: {
          query,
          per_page: pageSize,
          page: pageParam,
          orientation,
        },
        cancelToken: newCancelTokenSource.token,
      }).then(res => res.data);
    },
    {
      getNextPageParam: (lastPage: PexelsMediaSearchResponse) => {
        if (!lastPage || !lastPage.next_page) {
          return false;
        }

        const nextPageUrl = new URL(lastPage.next_page);
        const nextPageParam = nextPageUrl.searchParams.get('page');
        return nextPageParam ? parseInt(nextPageParam, 10) : false;
      },
      onError: () => {
        setCancelTokenSource(null);
      },
      onSuccess: () => {
        setCancelTokenSource(null);
      },
    },
  );
};
