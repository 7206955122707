import { useMutation, useQueryClient } from 'react-query';
import { useApiService } from '../../../app/common/api-service-provider';
import { Segment } from '../../types/segment';
import segmentQueryKeys from './segment-query-keys';
import { SegmentBody } from './types';

const useEditSegment = (tenantId: string, channelId: string, segmentId: string) => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  return useMutation(
    (segmentBody: SegmentBody) => {
      return apiService.put<Segment>(`/api/content-segment/${segmentId}`, segmentBody, { tenantId, channelId });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(segmentQueryKeys.channelSegments(tenantId, channelId));
        queryClient.invalidateQueries(segmentQueryKeys.getSegment(segmentId));
      },
    },
  );
};

export default useEditSegment;
