import React, { useMemo } from 'react';
import { ResponsiveContainer, AreaChart, Tooltip, Area } from 'recharts';
import numeral from 'numeral';
import dayjs from 'dayjs';
import upperFirst from 'lodash/upperFirst';
import identity from 'lodash/identity';
import get from 'lodash/get';
import Card from './card.component';
import LabeledValue from './labeled-value.component';
import { sumDays, sumEvents, getWowChange, getDodChange, Day } from '../utils';
import { getColor } from './colors';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const Legend = styled.div`
  margin-top: 16px;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const LegendItemColor = styled.div`
  margin-right: 8px;
  width: 16px;
  height: 4px;

  svg {
    display: block;
    width: 100%;
  }
`;

const LegendItemLabel = styled.div`
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
`;

const sentenceCase = (string: string) =>
  upperFirst(string.toLowerCase().replace(/[_]/g, ' '));

function renderFooter(data: Day[]) {
  const items: Parameters<typeof LabeledValue>[0][] = [];

  const totalToday = data.find(([date]: any) => date === dayjs().format('YYYY-MM-DD'));

  if (totalToday) {
    items.push({
      label: 'Today',
      value: sumEvents(totalToday[1]),
    });
  }

  const wowChange = getWowChange(data);

  if (typeof wowChange === 'number') {
    items.push({
      label: 'WoW Change',
      value: `${wowChange}%`,
      flag: wowChange >= 0 ? 'up' : 'down',
    });
  }

  const dodChange = getDodChange(data);

  if (typeof dodChange === 'number') {
    items.push({
      label: 'DoD Change',
      value: `${dodChange}%`,
      flag: dodChange >= 0 ? 'up' : 'down',
    });
  }

  if (items.length > 0) {
    return (
      <>
        {items.map((item) => (
          <LabeledValue key={`${item.label}_${item.value}`} {...item} />
        ))}
      </>
    );
  }

  return null;
}

const defaultStrokeWidth = 2;

const defaultFillOpacity = 0.2;

interface AreaStyle {
  stroke?: string;
  fill?: string;
  fillOpacity?: number;
  strokeWidth?: number;
  strokeDasharray?: string;
}

interface AreaCardProps {
  title: string;
  label?: string;
  info?: string;
  data: Day[];
  precedingData?: Day[];
  stacked?: boolean;
  children?: React.ReactNode;
  showCard?: boolean;
  labelFormatter?: (label: string | number) => string | number;
  isLegendVisible?: boolean;
  areaStyles?: AreaStyle[];
}

export default function AreaCard({
  title,
  label,
  info,
  data,
  precedingData,
  stacked = false,
  children,
  showCard = true,
  labelFormatter = identity,
  isLegendVisible = false,
  areaStyles = [],
}: AreaCardProps) {
  const { t } = useTranslation();

  const newData = data.map((item, index) => {
    const currentPeriodData = item;
    const precedingPeriodData = precedingData ? precedingData[index] : [];

    const result = {
      currentPeriodData,
      precedingPeriodData,
    };

    return result;
  });

  const stackedDataKeys: string[] = [];

  const regularDataKeys = precedingData ? [t('precedingPeriod'), title] : [title];

  const dataKeys = new Set(stacked ? stackedDataKeys : regularDataKeys);

  const barChartData = newData
    .sort(
      (a, b) =>
        new Date(a.currentPeriodData[0]).getTime() -
        new Date(b.currentPeriodData[0]).getTime(),
    )
    .map(({ currentPeriodData, precedingPeriodData }) => {
      const [date, events] = currentPeriodData;

      if (stacked) {
        if (events.length === 1 && !events[0].label && !events[0].value) {
          dataKeys.add(title);
          return {
            label: date,
            [title]: sumEvents(events),
          };
        }

        const result = events.reduce(
          (acc: Record<string, number | string>, ev) => {
            if (typeof ev.label === 'string') {
              dataKeys.add(ev.label);

              acc[ev.label] = ev.value;
            }
            return acc;
          },
          { label: date },
        );

        return result;
      }

      const result = {
        label: date,
        [title]: sumEvents(events),
      };

      if (precedingPeriodData.length === 0) {
        return result;
      }

      const [, precedingEvents] = precedingPeriodData;

      const newResult = {
        ...result,
        [t('precedingPeriod')]: sumEvents(precedingEvents),
      };

      return newResult;
    });

  const footer = renderFooter(data);

  const renderElement = useMemo(() => {
    return (
      <>
        {children || (
          <div style={{ flex: 1 }}>
            <LabeledValue
              style={{ marginBottom: 16 }}
              size="md"
              label={label || `Total ${title.toLowerCase()}`}
              value={sumDays(data)}
            />
          </div>
        )}
        <ResponsiveContainer height={48}>
          <AreaChart
            data={barChartData}
            margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
          >
            <Tooltip
              labelFormatter={(i: string | number) => {
                if (typeof i === 'string') {
                  return i;
                }

                const label = barChartData[i] ? barChartData[i].label : '';

                const result = labelFormatter(label);

                return result;
              }}
              formatter={(value, name) => [
                numeral(value).format('0,0'),
                sentenceCase(name),
              ]}
              contentStyle={{
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 8px',
                borderRadius: 4,
                border: 0,
              }}
              wrapperStyle={{ zIndex: 1 }}
            />

            {Array.from(dataKeys).map((dataKey, i, arr) => {
              const customStyle = areaStyles[i] || {};

              const stroke = get(customStyle, 'stroke', getColor(i, arr.length));

              const strokeWidth = get(customStyle, 'strokeWidth', defaultStrokeWidth);

              const fill = get(customStyle, 'fill', getColor(i, arr.length));

              const fillOpacity = get(customStyle, 'fillOpacity', defaultFillOpacity);

              const strokeDasharray = get(customStyle, 'strokeDasharray', '');

              return (
                <Area
                  key={dataKey}
                  dataKey={dataKey}
                  type="basis"
                  stroke={stroke}
                  strokeWidth={strokeWidth}
                  fill={fill}
                  fillOpacity={fillOpacity}
                  strokeDasharray={strokeDasharray}
                  stackId={i}
                />
              );
            })}
          </AreaChart>
        </ResponsiveContainer>

        {isLegendVisible && (
          <Legend>
            {Array.from(dataKeys).map((dataKey, i, arr) => {
              const customStyle = areaStyles[i] || {};

              const stroke = get(customStyle, 'stroke', getColor(i, arr.length));

              const strokeDasharray = get(customStyle, 'strokeDasharray', '');

              return (
                <LegendItem key={dataKey}>
                  <LegendItemColor>
                    <svg viewBox="0 0 16 4" xmlns="http://www.w3.org/2000/svg">
                      <line
                        x1="0"
                        y1="1"
                        x2="16"
                        y2="1"
                        stroke={stroke}
                        strokeWidth={2}
                        strokeDasharray={strokeDasharray}
                      />
                    </svg>
                  </LegendItemColor>
                  <LegendItemLabel>{dataKey}</LegendItemLabel>
                </LegendItem>
              );
            })}
          </Legend>
        )}

        {footer && <Footer>{footer}</Footer>}
      </>
    );
  }, [
    data,
    barChartData,
    children,
    dataKeys,
    label,
    title,
    footer,
    labelFormatter,
    isLegendVisible,
    areaStyles,
  ]);

  return (
    <>
      {showCard ? (
        <Card title={title} info={info}>
          {renderElement}
        </Card>
      ) : (
        <>{renderElement}</>
      )}
    </>
  );
}

const Footer = styled.div`
  border-top: 1px solid rgb(232, 232, 232);
  display: grid;
  grid-auto-flow: column;
  padding: 8px 0;
  margin: 30px 0 0;
  column-gap: 16px;
  -webkit-box-pack: start;
  justify-content: start;
`;
