import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import styled from '@emotion/styled';
import { Input, Select, Text, Section } from '../../common';
import {
  FONT_WEIGHT_OPTIONS,
  FONT_STYLE_OPTIONS,
  TEXT_TRANSFORM_OPTIONS,
  TEXT_DECORATION_OPTIONS,
  TEXT_AS_ELEMENT_OPTIONS,
  TextNonCssProperty,
} from './utils';
import { SelectValue } from 'antd/lib/select';
import { TextCssProperty, TypographyProperty, TextAsElementEnum } from './utils';
import FilePicker from '../file-picker';
import {
  buildFieldSchema,
  buildUiSchema,
  buildRegistry,
  buildIdSchema,
} from './build-font-picker-props';
import Media from '../../../../../store/types/media';
import { useEffect } from 'react';

interface SettingsProps {
  onChange?: (param: TypographyProperty) => void;
  defaultValue?: TypographyProperty;
  isReadonly?: boolean;
  tenantId?: string;
}

const Settings = (props: SettingsProps) => {
  const {
    onChange = () => {},
    defaultValue,
    isReadonly,
    tenantId,
  } = props;

  const { textNonCssProperty = {}, textCssProperty = {}, font = undefined } =
    defaultValue || {};

  const { sampleText, asElement } = textNonCssProperty;
  const {
    fontWeight,
    fontStyle,
    textTransform,
    letterSpacing,
    textDecoration,
    textDecorationThickness,
  } = textCssProperty;

  const refTextNonCssProperties = useRef<TextNonCssProperty>(textNonCssProperty);
  const refTextCssProperties = useRef<TextCssProperty>(textCssProperty);
  const refFont = useRef<Media | undefined>(font);
  const [fontFile, setFontFile] = useState<Media | undefined>(font);

  const { t } = useTranslation();

  const handleChange = useCallback(() => {
    onChange({
      textCssProperty: refTextCssProperties.current,
      textNonCssProperty: refTextNonCssProperties.current,
      font: refFont.current,
    });
  }, [onChange]);

  useEffect(() => {
    handleChange();
  }, [handleChange]);

  const handleSampleTextChange = useCallback((value?: string) => {
    refTextNonCssProperties.current.sampleText = `${value}`;
    handleChange();
  }, [handleChange]);

  const handleAsElementChange = useCallback((value: SelectValue) => {
    refTextNonCssProperties.current.asElement = value as TextAsElementEnum;
    handleChange();
  }, [handleChange]);

  const handleFontWeightSelect = useCallback((value: SelectValue) => {
    refTextCssProperties.current.fontWeight = `${value}`;
    handleChange();
  }, [handleChange]);

  const handleFontStyleSelect = useCallback((value: SelectValue) => {
    refTextCssProperties.current.fontStyle = `${value}`;
    handleChange();
  }, [handleChange]);

  const handleTextTransformSelect = useCallback((value: SelectValue) => {
    refTextCssProperties.current.textTransform = `${value}`;
    handleChange();
  }, [handleChange]);

  const handleLetterSpacingChange = useCallback((value?: string) => {
    refTextCssProperties.current.letterSpacing = `${value}`;
    handleChange();
  }, [handleChange]);

  const handleTextDecorationSelect = useCallback((value: SelectValue) => {
    refTextCssProperties.current.textDecoration = `${value}`;
    handleChange();
  }, [handleChange]);

  const handleTextDecorationThicknessChange = useCallback((value?: string) => {
    refTextCssProperties.current.textDecorationThickness = `${value}`;
    handleChange();
  }, [handleChange]);

  const handleFontFileChange = useCallback((font?: Media) => {
    setFontFile(font);
    refFont.current = font;
    refTextCssProperties.current.fontFamily = font ? font.id : undefined;
    handleChange();
  }, [handleChange]);

  return (
    <>
      <Section>
        {/* This is not the proper usage of schema fields/widgets. This is a workaround and will change. */}
        <FontPicker
          formContext={{ organisationId: tenantId }}
          autofocus={false}
          disabled={false}
          readonly={false}
          required={false}
          formData={fontFile}
          errorSchema={{}}
          rawErrors={[]}
          name="fontPicker"
          onChange={handleFontFileChange}
          schema={buildFieldSchema(t)}
          uiSchema={buildUiSchema(t)}
          registry={buildRegistry(tenantId)}
          idSchema={buildIdSchema()}
        />
      </Section>
      <Section>
        <Row gutter={[10, 10]}>
          <Col xs={24} md={18} lg={18}>
            <FieldInput
              title={t('typographyPicker.previewText')}
              onChange={handleSampleTextChange}
              defaultValue={sampleText}
              isReadonly={isReadonly}
            />
          </Col>
          <Col xs={24} md={6} lg={6}>
            <FieldSelect
              title={t('typographyPicker.asElement')}
              onSelect={handleAsElementChange}
              defaultValue={asElement}
              options={TEXT_AS_ELEMENT_OPTIONS}
              isReadonly={isReadonly}
            />
          </Col>
        </Row>
      </Section>
      <Section>
        <Row gutter={[10, 10]}>
          <Col xs={24} md={12} lg={12}>
            <FieldSelect
              title={t('typographyPicker.fontWeight')}
              options={FONT_WEIGHT_OPTIONS}
              onSelect={handleFontWeightSelect}
              defaultValue={fontWeight}
              isReadonly={isReadonly}
            />
          </Col>
          <Col xs={24} md={12} lg={12}>
            <FieldSelect
              title={t('typographyPicker.fontStyle')}
              options={FONT_STYLE_OPTIONS}
              onSelect={handleFontStyleSelect}
              defaultValue={fontStyle}
              isReadonly={isReadonly}
            />
          </Col>
        </Row>
      </Section>
      <Section $isLast>
        <Text strong>Style</Text>
        <FieldSelect
          title={t('typographyPicker.textTransform')}
          options={TEXT_TRANSFORM_OPTIONS}
          onSelect={handleTextTransformSelect}
          defaultValue={textTransform}
          isReadonly={isReadonly}
        />
        <FieldInput
          title={t('typographyPicker.letterSpacing')}
          onChange={handleLetterSpacingChange}
          defaultValue={letterSpacing}
          isReadonly={isReadonly}
        />
        <Row gutter={[10, 10]}>
          <Col xs={24} md={18} lg={18}>
            <FieldSelect
              title={t('typographyPicker.decoration')}
              options={TEXT_DECORATION_OPTIONS}
              onSelect={handleTextDecorationSelect}
              defaultValue={textDecoration}
              isReadonly={isReadonly}
            />
          </Col>
          <Col xs={24} md={6} lg={6}>
            <FieldInput
              title={t('typographyPicker.thickness')}
              onChange={handleTextDecorationThicknessChange}
              defaultValue={textDecorationThickness}
              isReadonly={isReadonly}
            />
          </Col>
        </Row>
      </Section>
    </>
  );
};

const FieldInput = styled(Input)`
  border-radius: 4px;
`;

const FieldSelect = styled(Select)`
  border-radius: 4px;
`;

const FontPicker = styled(FilePicker)`
  border-radius: 4px;
`;

export default React.memo(Settings);
