import React from 'react';
import styled from '@emotion/styled';
import { Icon as AntdIcon } from 'antd';
import { IconProps as AntdIconProps } from 'antd/lib/icon';

interface IconProps extends AntdIconProps {
  size?: number;
  color?: string;
  src?: string;
}

const Icon = (props: IconProps) => {
  const { size, color, src, ...restProps } = props;

  return (
    <AntdIconStyled
      {...restProps}
      size={size}
      style={{ color }}
      {...(src ? { component: () => <img src={src} alt="" /> } : {})}
    />
  );
};

const AntdIconStyled = styled(AntdIcon)<{ size?: number }>`
  svg,
  img {
    height: ${({ size = 16 }) => `${size}px`};
    width: ${({ size = 16 }) => `${size}px`};
  }

  img {
    vertical-align: unset;
  }
`;

export default Icon;
