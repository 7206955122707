import React, { useCallback } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import SpacesReportContainer from './spaces-report/spaces-report.container';
import SpaceDetailsContainer from './spaces-details.container';
import { getPermissionPath, permissionKeys } from '../../../../utils/auth/permissions';
import ProtectedRouteContainer from '../../../common/protected-route/protected-route.container';

type SpacesReportContainerProps = Pick<
  React.ComponentProps<typeof SpaceDetailsContainer>,
  'match' | 'history' | 'location'
>;

const SpacesContainer = (props: SpacesReportContainerProps) => {
  const {
    match: {
      path,
      params: { organisationId, spaceId },
    },
    location,
  } = props;

  const url = `/organisations/${organisationId}/spaces/${spaceId}`;

  const renderDefaultRedirect = useCallback(() => {
    return <Redirect to={{ pathname: `${url}/details-v2`, search: location.search }} />;
  }, [url, location.search]);

  const getOrganisationPermissionPath = useCallback(
    (permissionKey: string) => getPermissionPath(organisationId, permissionKey),
    [organisationId],
  );

  return (
    <Switch>
      <Route exact path={path} render={renderDefaultRedirect} />
      <ProtectedRouteContainer
        permissionPath={getOrganisationPermissionPath(permissionKeys.spaces.viewAll)}
        path={`${path}/details-v2`}
        component={SpaceDetailsContainer}
      />
      <ProtectedRouteContainer
        permissionPath={getOrganisationPermissionPath(
          permissionKeys.spaces.viewAnalytics,
        )}
        path={`${path}/report`}
        component={SpacesReportContainer}
      />
    </Switch>
  );
};

export default SpacesContainer;
