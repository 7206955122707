import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { Icon, Menu, Table, Button, message } from 'antd';
import { ColumnProps } from 'antd/es/table';
import dayjs from 'dayjs';
import head from 'lodash/head';
import Build from '../../../../../../store/types/build';
import Environment from '../../../../../../store/types/environment';
import { useTranslation } from 'react-i18next';
import Avatar from './avatar';
import Release from './release';
import DeployStatus from './deploy-status';
import AppLink from './app-link';
import {
  getPermissionPath,
  permissionKeys,
} from '../../../../../../utils/auth/permissions';
import MeatBallsMenu from '../../../../../common/meat-balls-menu';
import { useDeleteBuild } from '../../../../../common/use-app-builds';
import { convertEmailToInitials } from '../../../../../../utils/enterprise-agreement';
import { PipelineWithBuildNumber } from '../../../../../../store/types/pipeline';
import DeviceTypeEnum from '../../../../../../store/types/device-type.enum';
import useHasPermissions from '../../../../../../utils/auth/use-has-permissions';

interface DataRow {
  key: string;
  build: Build;
}

export const Container = styled.div`
  .ant-table {
    border: 1px solid #dddddd;
    border-radius: 8px;
    overflow: hidden;
  }

  .ant-table-placeholder {
    border-bottom: 0;
    border-radius: 8px;
  }

  .ant-table-thead tr th {
    padding: 10px 5px;
    font-size: 12px;
    text-transform: uppercase;
  }

  .ant-table tr .deployStatus {
    padding: 1px 5px;
  }

  .ant-table tr td.appLink {
    border-left: 1px solid #dddddd;
  }

  .ant-table tr td.meatBalls {
    padding: 0;
  }

  .ant-table-thead tr th,
  .ant-table-tbody tr td {
    border-bottom: 1px solid #dddddd;
  }

  .ant-table-tbody tr td {
    padding: 5px;
  }

  .ant-table-tbody tr:last-of-type td {
    border-bottom: 0;
  }

  .ant-table-body {
    overflow-x: auto !important;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DeleteButton = styled(Button)`
  color: #f12523;

  &:hover,
  &:focus,
  &:active {
    color: #f12523;
  }
` as any;

const Error = styled.div`
  color: #f12523;
` as any;

const getBuildPipeline = (build: Build) => {
  const pipelines = build.pipelines.filter(
    (pipeline): pipeline is PipelineWithBuildNumber =>
      !!pipeline.data && !!pipeline.data.buildNumber,
  );
  const pipeline = pipelines[0];

  return pipeline;
}

interface BuildsTableProps {
  organisationId: string;
  appId: string;
  builds: Build[];
  environments: Environment[];
  appType: DeviceTypeEnum;
}

const BuildsTable: React.FC<BuildsTableProps> = ({
  organisationId,
  appId,
  builds,
  environments,
  appType,
}) => {
  const { t } = useTranslation();

  const isDeleteBuildAllowed = useHasPermissions(
    organisationId,
    permissionKeys.builds.remove,
  );

  const deleteBuildState = useDeleteBuild(appId);

  const onDelete = useCallback(
    (buildId: string) => {
      deleteBuildState.mutate(
        { buildId },
        {
          onSuccess: () => {
            message.success(t('buildDeleted'));
          },
          onError: () => {
            message.error(t('buildDeleteError'));
          },
        },
      );
    },
    [t, deleteBuildState],
  );

  const columns: ColumnProps<DataRow>[] = useMemo(() => {
    const isAppLinkVisible = [DeviceTypeEnum.MOBILE_WPA, DeviceTypeEnum.WPA].includes(appType);

    const cols: ColumnProps<DataRow>[]  = [
      {
        title: '',
        key: 'avatar',
        align: 'center' as 'center',
        className: 'avatar',
        width: 60,
        render: ({ build }: DataRow) => {
          const user = build.requestedBy;
          const { email = '' } = user || {};

          const initials = convertEmailToInitials(email) || 'UN';

          return <Avatar initials={initials} />;
        },
      },
      {
        title: t('builds.release'),
        key: 'release',
        align: 'left' as 'left',
        className: 'release',
        sorter: (dataRowA, dataRowB) => {
          const buildA = dataRowA.build;
          const buildB = dataRowB.build;

          const pipelineA = head(buildA.pipelines);
          const pipelineB = head(buildB.pipelines);

          const buildNumberA =
            (pipelineA && pipelineA.data && pipelineA.data.buildNumber) || '';
          const buildNumberB =
            (pipelineB && pipelineB.data && pipelineB.data.buildNumber) || '';

          const result = buildNumberA.localeCompare(buildNumberB);

          return result;
        },
        render: ({ build }: DataRow) => {
          const pipeline = getBuildPipeline(build);

          if (pipeline) {
            return (
              <Release pipeline={pipeline} buildId={build.id} />
            );
          }

          return <Error>{t('error')}</Error>
        },
      },
      {
        title: t('builds.gridappVersion'),
        key: 'appVersion',
        align: 'left' as 'left',
        className: 'gridappVersion',
        render: ({ build }: DataRow) => {
          return build.release || t('unknown').toUpperCase();
        },
      },
      {
        title: t('builds.dateAndTime'),
        key: 'dateTime',
        align: 'left' as 'left',
        className: 'dateTime',
        sorter: (dataRowA, dataRowB) => {
          const dateA = dayjs(dataRowA.build.createdAt);
          const dateB = dayjs(dataRowB.build.createdAt);

          if (dateA.isSame(dateB)) {
            return 0;
          }

          const result = dateA.isBefore(dateB) ? -1 : 1;

          return result;
        },
        render: ({ build }: DataRow) => {
          const date = dayjs(build.createdAt);

          const day = date.format('YYYY-MM-DD');

          const time = date.format('h:mm A');

          return t('buildDate', { day, time });
        },
      },
      {
        title: t('builds.deployStatus'),
        key: 'deployStatus',
        align: 'left' as 'left',
        className: 'deployStatus',
        render: ({ build }: DataRow) => {
          const pipeline = getBuildPipeline(build);

          if (pipeline) {
            return (
              <DeployStatus
                pipeline={pipeline}
                appId={appId}
                build={build}
                environments={environments}
                organisationId={organisationId}
                permissionPath={getPermissionPath(
                  organisationId,
                  permissionKeys.builds.viewSingle,
                )}
              />
            );
          }

          return <Error>{t('error')}</Error>
        },
      },
    ];

    if (isAppLinkVisible || isDeleteBuildAllowed) {
      cols.push({
        title: '',
        key: 'appLink',
        align: 'left' as 'left',
        className: 'appLink',
        fixed: 'right',
        width: isDeleteBuildAllowed && isAppLinkVisible ? 125 : 46,
        render: ({ build }: DataRow) => {
          const menu = (
            <Menu>
              <Menu.Item key="0">
                <DeleteButton type="link" onClick={() => onDelete(build.id)}>
                  <Icon type="delete" /> {t('delete')}
                </DeleteButton>
              </Menu.Item>
            </Menu>
          );

          const appLinkStyles = `
            margin: auto ${isAppLinkVisible ? '8px' : '0px'};
          `;

          return (
            <ColumnContainer>
              <AppLink pipelines={build.pipelines} containerStyles={appLinkStyles} />
              {isDeleteBuildAllowed && (
                <MeatBallsMenu menu={menu} placement="bottomRight" />
              )}
            </ColumnContainer>
          );
        },
      });
    }

    return cols;
  }, [appId, environments, onDelete, organisationId, t, isDeleteBuildAllowed, appType]);

  const dataSource = builds.map((build) => {
    const dataRow: DataRow = {
      key: build.id,
      build,
    };

    return dataRow;
  });

  return (
    <Container>
      <Table<DataRow>
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{ x: 1024 }}
      />
    </Container>
  );
};

export default BuildsTable;
