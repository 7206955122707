import React, { useEffect } from 'react';
import { useActions, useStore } from 'easy-peasy';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import GridappInstallations from './gridapp-installations.component';
import { RootModel, RootState } from '../../../../../store/models/root.model';
import useGridappInstallationInstances from '../../../use-gridapp-installation-instances/use-gridapp-installation-instances';
import Overlay from '../../../overlay/overlay.component';
import Spinner from '../../../spinner/spinner.component';

type GridappSettingsContainerProps = Pick<
  React.ComponentProps<typeof GridappInstallations>,
  'match' | 'history' | 'location'
>;

const GridappSettingsContainer = (props: GridappSettingsContainerProps) => {
  const {
    match: {
      params: { organisationId, gridappId },
    },
  } = props;

  const { t } = useTranslation();
  const gridappInstallationsState = useGridappInstallationInstances(gridappId);

  // TODO: how to memoize this properly?
  const { fetchBuilds } = useActions<RootModel>((actions) => ({
    fetchBuilds: actions.organisationAppsLibraryBuilds.fetch,
  }));

  const { gridapp, gridappBuilds, organisations, gridappBuildsLoading, gridappBuildsError } = useStore(
    (state: RootState) => ({
      gridappBuilds: state.organisationAppsLibraryBuilds.values(gridappId) || [],
      gridappBuildsLoading: state.organisationAppsLibraryBuilds.loading[gridappId],
      gridappBuildsError: state.organisationAppsLibraryBuilds.error[gridappId],
      organisations: state.organisations.data || {},
      gridapp: state.organisationAppsLibrary.data[organisationId]
        ? state.organisationAppsLibrary.data[organisationId][gridappId]
        : null,
    }),
    [gridappId, organisationId],
  );

  useEffect(() => {
    fetchBuilds({ gridappId });
  }, [gridappId, fetchBuilds]);

  if (gridappBuildsLoading || gridappInstallationsState.isLoading) {
    return (
      <Overlay>
        <Spinner />
      </Overlay>
    );
  }

  if (gridappBuildsError || gridappInstallationsState.isError) {
    return <ErrorMessage>{t('error')}</ErrorMessage>
  }

  return (
    <GridappInstallations
      {...props}
      gridapp={gridapp}
      installations={gridappInstallationsState.data || []}
      gridappBuilds={gridappBuilds}
      organisations={organisations}
    />
  );
};

export default GridappSettingsContainer;

const ErrorMessage = styled.div`
  padding: 15px 0;
  color: rgb(255, 85, 0);
`;
