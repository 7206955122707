import React from 'react';
import { HardDrive, MapPin, Globe, Grid } from 'react-feather';
import styled from '@emotion/styled';

export const GlobalIcon = () => <Global size={16} />;
export const GridIcon = () => <Grid color="rgb(95, 6, 228)" size={16} />;
export const SpaceIcon = () => <Space color="rgb(228, 12, 134)" size={14} />;
export const DeviceIcon = () => <Device color="rgb(95, 6, 228)" size={14} />;

const Global = styled(Globe)`
  margin-right: 3px;
`;

const Space = styled(MapPin)`
  margin-right: 3px;
`;

const Device = styled(HardDrive)`
  margin-right: 3px;
`;
