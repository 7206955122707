import React, { FC } from 'react';
import styled from '@emotion/styled';

const PhyhubDevicesSummary: FC = () => {
  // TODO: Add Summary component accordingly to the future Phyhub devices report response
  return <Wrapper>Phyhub devices breakdown summary</Wrapper>;
};

const Wrapper = styled.div`
  position: relative;
`;

export default PhyhubDevicesSummary;
