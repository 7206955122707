import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import styled from '@emotion/styled';
import { Tabs } from 'antd';
import { FolderPlus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import ErrorBoundary from '../../../common/error-boundary/error-boundary.component';
import Header, { Subheader } from '../../../common/app-layout/header';
import ProtectedRouteContainer from '../../../common/protected-route/protected-route.container';
import TabBar from '../../../common/tab-bar/tab-bar.component';
import { getPermissionPath, permissionKeys } from '../../../../utils/auth/permissions';
import NetworkingWhitelistComponent from './networking-whitelist/networking-whitelist.component';
import Icon from '../../../common/schema-form/common/icon.component';

interface NetworkingProps extends RouteComponentProps<{ organisationId: string }>{};

enum MenuKeysEnum {
  WHITELIST = 'whitelist',
}

const MenuTabs = {
  [MenuKeysEnum.WHITELIST]: {
    path: '/whitelist',
  },
};

const Networking = (props: NetworkingProps) => {
  const { location, match, history } = props;
  const { organisationId } = match.params;

  const [tabKey, setTabKey] = useState<MenuKeysEnum>(MenuKeysEnum.WHITELIST);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const segment = location.pathname.replace(match.url, '').split('/')[1];

    setTabKey(segment as MenuKeysEnum);
  }, [match, location]);

  const handleTabChange = useCallback(
    (key: string) => {
      const menuKey = key as MenuKeysEnum;

      const path = MenuTabs[menuKey].path;
      history.push(`${match.url}${path}`);
    },
    [history, match],
  );

  return (
    <>
      <Header
        tabTitle={t('networking.title')}
        title={t('networking.title')}
        icon={<Icon type="apartment" color="#ffffff" />}
        isBordered={false}
      />
      <SubheaderStyled
        components={[
          <TabBar
            key="networking-tab-bar"
            onChange={handleTabChange}
            activeKey={tabKey}
            lang={i18n.language}
          >
            <Tabs.TabPane
              tab={
                <span>
                  <Icon component={() => <FolderPlus size={16} />} />
                  {t('networking.whitelist')}
                </span>
              }
              key={MenuKeysEnum.WHITELIST}
            />
          </TabBar>
        ]}
      />
      <ErrorBoundary>
        <Switch>
          <ProtectedRouteContainer
            exact
            permissionPath={getPermissionPath(
              organisationId,
              permissionKeys.networkWhitelistRules.viewAll,
            )}
            path={`${match.path}/${MenuKeysEnum.WHITELIST}`}
            render={() => <NetworkingWhitelistComponent tenantId={organisationId} />}
          />
          <ProtectedRouteContainer
            exact
            permissionPath={getPermissionPath(
              organisationId,
              permissionKeys.networkWhitelistRules.viewAll,
            )}
            path={match.path}
            render={() => <Redirect to={`${match.url}/${MenuKeysEnum.WHITELIST}`} />}
          />
        </Switch>
      </ErrorBoundary>
    </>
  );
};

const SubheaderStyled = styled(Subheader)`
    background-color: #f2f2f7;
    min-height: unset !important;
`;

export default Networking;
