import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import DeviceDetails from './device-details.component';
import { RootModel, RootState } from '../../../../../store/models/root.model';
import getAppsByOrganisationName from '../../../../../utils/get-apps-by-organisation-name';

type DeviceDetailsContainerProps = Omit<
  React.ComponentProps<typeof DeviceDetails>,
  | 'fetchDevice'
  | 'device'
  | 'loaded'
  | 'lastUpdated'
  | 'apps'
  | 'restartApp'
  | 'rebootDevice'
  | 'moveDevice'
>;

const DeviceDetailsContainer = (props: DeviceDetailsContainerProps) => {
  const {
    match: {
      params: { deviceUuid, organisationId },
    },
  } = props;

  const { device, loaded, lastUpdated, apps, spaces } = useStore<RootState>(
    (state) => ({
      device: state.deviceDetails.data[deviceUuid] || null,
      loaded: state.deviceDetails.loaded(deviceUuid) && state.organisationApps.loaded,
      lastUpdated: state.deviceDetails.lastUpdated[deviceUuid],
      apps: getAppsByOrganisationName(
        state.organisations.values,
        state.organisationApps.data || {},
      ),
      spaces: state.organisationSpaces.values(organisationId),
    }),
    [deviceUuid, organisationId],
  );

  const { fetchDevice, fetchSpaces, restartApp, rebootDevice, moveDevice } = useActions<
    RootModel
  >((actions) => ({
    fetchSpaces: actions.organisationSpaces.fetch,
    fetchDevice: actions.deviceDetails.fetch,
    restartApp: actions.deviceDetails.restart,
    rebootDevice: actions.deviceDetails.reboot,
    moveDevice: actions.deviceDetails.move,
  }));

  return (
    <DeviceDetails
      {...props}
      apps={apps}
      lastUpdated={lastUpdated}
      device={device}
      spaces={spaces}
      fetchDevice={fetchDevice}
      fetchSpaces={fetchSpaces}
      loaded={loaded}
      restartApp={restartApp}
      rebootDevice={rebootDevice}
      moveDevice={moveDevice}
    />
  );
};

export default DeviceDetailsContainer;
