import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { Tooltip, Icon as AntdIcon } from 'antd';

interface FieldInputProps extends FieldRenderProps<HTMLInputElement> {
  type?: string;
  min?: string;
  max?: string;
  step?: string;
  required?: boolean;
  label?: string;
  className?: string;
  description?: string;
  icon?: React.ReactNode;
  maxLength?: number;
  disabled?: boolean;
  fullWidthLabel?: boolean;
  hint?: string;
  labelChildren?: React.ReactNode;
  checked?: boolean;
  placeholder?: string;
  dataTestId?: string;
}

const FormInput = (props: FieldInputProps) => {
  const {
    type = 'text',
    min,
    max,
    step,
    input,
    meta,
    label,
    required,
    className,
    description,
    icon,
    disabled,
    fullWidthLabel,
    hint,
    labelChildren,
    placeholder,
    dataTestId,
    ...restProps
  } = props;

  const hasError = (!!meta.error || (!!meta.submitError && !meta.dirtySinceLastSubmit && !meta.submitting)) && meta.touched;

  return (
    <div
      className={classNames('form-group', hasError && 'has-error has-danger', className)}
    >
      {label && (
        <Label
          className={classNames({ 'control-label': true, fullWidth: fullWidthLabel })}
          htmlFor={input.name}
        >
          {label}

          {required && <span className="required">*</span>}

          {!!hint && (
            <TooltipContainer>
              <Tooltip title={hint}>
                <AntdIcon type="info-circle" style={{ fontSize: '16px' }} />
              </Tooltip>
            </TooltipContainer>
          )}

          {!!labelChildren && <LabelChildren>{labelChildren}</LabelChildren>}
        </Label>
      )}

      {description && <Description>{description}</Description>}

      <Field>
        <input
          autoComplete="off"
          {...restProps}
          value={input.value}
          onChange={input.onChange}
          onBlur={input.onBlur}
          onFocus={input.onFocus}
          className="form-control"
          name={input.name}
          id={input.name}
          disabled={disabled}
          placeholder={placeholder}
          type={type}
          min={min}
          max={max}
          step={step}
          data-testid={dataTestId}
        />

        {icon && <Icon>{icon}</Icon>}
      </Field>

      {hasError && (
        <div>
          <ul className="error-detail bs-callout bs-callout-info">
            <li className="text-danger">{meta.error || meta.submitError}</li>
          </ul>
        </div>
      )}
    </div>
  );
};

const Field = styled.div`
  position: relative;
`;

const Description = styled.span`
  display: block;
`;

const Icon = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  top: 50%;
  right: 11px;
  transform: translateY(-50%);
`;

const TooltipContainer = styled.div`
  display: inline-block;
  margin-left: 5px;
`;

const Label = styled.label`
  width: auto;
`;

const LabelChildren = styled.div``;

export default FormInput;
