import React, { useCallback, useMemo, useState } from 'react';
import {
  DataMatrixColumn,
  DataMatrixColumnDataType,
  DataMatrixDataSource,
  GridStyles,
  InteractionType,
} from '@ombori/grid-reports';
import groupBy from 'lodash/groupBy';
import { DataResidencyEnum } from '../../../../../../store/types/organisation';
import { useSpaces } from '../../../../use-spaces';
import CardContainer from '../../card-container';
import SpacesTable, { OrganisationSpaceWithChildren } from '../spaces-table';
import getEventType from '../get-event-type';
import {
  useSpacesEventsAndSessionsByDay,
} from '../../../../use-analytics-report';
import OrganisationSpace from '../../../../../../store/types/organisation-space';
import { TitleWithSelect } from '../common/data-matrix-common.component';

interface DataMatrixProps {
  tenantId: string;
  dateFrom: string;
  dateTo: string;
  dataResidency: DataResidencyEnum;
  title?: string;
  dataSource: DataMatrixDataSource;
  columns: DataMatrixColumn[];
  gridStyles?: GridStyles;
  isVisibleWithoutData?: boolean;
}

const SpacesDataMatrixLegacy: React.FC<Omit<DataMatrixProps, 'dataSource'>> = React.memo(({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  title = '',
  columns,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const spacesFetchingState = useSpaces({ organisationId: tenantId, limit: 15000 });

  const { spaces, spacesMap } = useMemo(() => {
    const spaces = spacesFetchingState.data ? spacesFetchingState.data.spaces : [];

    const spacesMap = spaces.reduce(
      (acc, space) => {
        acc[space.id] = space;
        return acc;
      },
      {} as Record<string, OrganisationSpace>,
    );

    return { spaces, spacesMap };
  }, [spacesFetchingState]);

  const getParentSpaceId = useCallback(
    (spaceId: string) => {
      const space = spacesMap[spaceId];

      if (!space) {
        return spaceId;
      }

      const parentSpaceId = space.parentSpaceId;

      if (!parentSpaceId) {
        return spaceId; // current space is a root space
      }

      return parentSpaceId; // current space is a child space
    },
    [spacesMap],
  );

  const rootSpaceKey = 'root';

  const groupedSpaces = useMemo(
    function groupByParentSpaceId() {
      return groupBy(spaces, (space) =>
        space.parentSpaceId ? space.parentSpaceId : rootSpaceKey,
      );
    },
    [spaces],
  );

  const spacesWithChildren = useMemo(() => {
    const rootSpaces = groupedSpaces[rootSpaceKey] || [];

    const result = rootSpaces.map(function buildSpaceWithChildren(space) {
      const children = groupedSpaces[space.id] || [];

      const spaceWithChildren: OrganisationSpaceWithChildren = { ...space, children };

      return spaceWithChildren;
    });

    return result;
  }, [groupedSpaces]);

  const eventType = getEventType(columns);

  const isEventsQueryEnabled = columns.some(
    (column) => column.dataType.type === DataMatrixColumnDataType.Events,
  );

  const isSessionsQueryEnabled = columns.some(
    (column) => column.dataType.type === DataMatrixColumnDataType.Sessions,
  );

  const dataFetchingState = useSpacesEventsAndSessionsByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
    interactionType: InteractionType.Interactive,
    eventType,
    isEventsQueryEnabled,
    isSessionsQueryEnabled,
  });

  const eventsData = useMemo(() => {
    const events = dataFetchingState.data ? dataFetchingState.data.events : [];

    const newEvents = events.map((event) => {
      return {
        ...event,
        spaceId: getParentSpaceId(event.spaceId),
      };
    });

    return newEvents;
  }, [dataFetchingState, getParentSpaceId]);

  const sessionsData = useMemo(
    () => (dataFetchingState.data ? dataFetchingState.data.sessions : []),
    [dataFetchingState],
  );

  const [selectedSpace, setSelectedSpace] = useState<
    OrganisationSpaceWithChildren | undefined
  >(undefined);

  const spaceOptions = useMemo(() => {
    const result = spacesWithChildren.map((space) => {
      return { key: space.id, label: space.displayName };
    });

    return result;
  }, [spacesWithChildren]);

  const onSelect = useCallback(
    (key: string | undefined) => {
      const selectedSpace =
        key === 'all' || !key
          ? undefined
          : spacesWithChildren.find((space) => space.id === key);

      setSelectedSpace(selectedSpace);
    },
    [setSelectedSpace, spacesWithChildren],
  );

  const isLoading = spacesFetchingState.isLoading || dataFetchingState.isLoading;
  const isSuccess = spacesFetchingState.isSuccess && dataFetchingState.isSuccess;
  const isError = spacesFetchingState.isError || dataFetchingState.isError;

  const hasData =
    spacesWithChildren.length > 0 && (eventsData.length > 0 || sessionsData.length > 0);

  return (
    <CardContainer
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      hasData={hasData}
      title={
        isSuccess ? (
          <TitleWithSelect title={title} items={spaceOptions} onSelect={onSelect} />
        ) : (
          title
        )
      }
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {isSuccess && (
        <SpacesTable
          tenantId={tenantId}
          spaces={selectedSpace ? [selectedSpace] : spacesWithChildren}
          eventsData={eventsData}
          sessionsData={sessionsData}
          columns={columns}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      )}
    </CardContainer>
  );
});

export default SpacesDataMatrixLegacy;
