import LocalizedField from './localized-field';
import CurrencyAwareValue from './currency-aware-value';

export enum PlanTypeEnum {
  SUPPORT = 'support',
  TENANT = 'tenant',
}

interface PlanSkuConfiguration {
  defaultSku: string;
  skuByKind: string;
}

interface PlanItem {
  sku: string;
  name: LocalizedField;
  dimensionId: string;
  billingCycle: string;
  quantity: number;
  includedQuantity: number;
  skuConfiguration: PlanSkuConfiguration;
}

export interface PlanFeature {
  name: LocalizedField;
  description: LocalizedField;
  icon: string;
}

export interface PriceInformation {
  startDate: string;
  endDate?: string;
  prices: CurrencyAwareValue[];
}

export default interface OrganisationPlan {
  id: string;
  type: PlanTypeEnum;
  name: LocalizedField;
  description: LocalizedField;
  items: PlanItem[];
  features: PlanFeature[];
  sku: string;
  availability: string;
  activePrice: PriceInformation;
}
