import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const useSchemaFormErrorMessage = () => {
  const { t } = useTranslation();

  const getErrorMessage = useCallback(
    (errorName: string) => {
      const errorMessages: { [key: string]: string } = {
        required: t('schemaFormErrorMessage.required'),
        enum: t('schemaFormErrorMessage.enum'),
        type: t('schemaFormErrorMessage.type'),
        minLength: t('schemaFormErrorMessage.minLength'),
        maxLength: t('schemaFormErrorMessage.maxLength'),
      };

      return errorMessages[errorName] || t('invalidValue');
    },
    [t],
  );

  return {
    getErrorMessage,
  };
};

export default useSchemaFormErrorMessage;
