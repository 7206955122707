import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Button, Row, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { FieldProps } from 'react-jsonschema-form';

const defaultHasSpecialHoursState: Record<number, boolean> = {
  0: false,
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
  6: false,
};

const defaultDayState: Record<number, boolean> = {
  0: true,
  1: true,
  2: true,
  3: true,
  4: true,
  5: true,
  6: true,
};

const defaultSpecialHoursState: Record<number, string> = {
  0: '',
  1: '',
  2: '',
  3: '',
  4: '',
  5: '',
  6: '',
};

const WeeklySchedulePicker = ({ onChange, formData, disabled = false }: FieldProps) => {
  const { t } = useTranslation();
  const [openingTime, setOpeningTime] = useState('08:00');
  const [closingTime, setClosingTime] = useState('17:30');
  const [specialOpeningHours, setSpecialOpeningHours] = useState(
    defaultSpecialHoursState,
  );
  const [specialClosingHours, setSpecialClosingHours] = useState(
    defaultSpecialHoursState,
  );
  const [hasSpecialHours, setHasSpecialHours] = useState(defaultHasSpecialHoursState);
  const [isDayOpen, setIsDayOpen] = useState(defaultDayState);

  const days = useMemo(
    () => [
      {
        id: 1,
        name: t('monday'),
      },
      {
        id: 2,
        name: t('tuesday'),
      },
      {
        id: 3,
        name: t('wednesday'),
      },
      {
        id: 4,
        name: t('thursday'),
      },
      {
        id: 5,
        name: t('friday'),
      },
      {
        id: 6,
        name: t('saturday'),
      },
      {
        id: 0,
        name: t('sunday'),
      },
    ],
    [t],
  );

  const handleOpeningTimeChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setOpeningTime(e.target.value);
    },
    [],
  );

  const handleClosingTimeChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setClosingTime(e.target.value);
    },
    [],
  );

  const handleHasSpecialOpeningHoursChange = useCallback((value, id) => {
    setSpecialOpeningHours((curState: Record<number, string>) => ({
      ...curState,
      [id]: value,
    }));
  }, []);

  const handleSpecialClosingHoursChange = useCallback((value, id) => {
    setSpecialClosingHours((curState: Record<number, string>) => ({
      ...curState,
      [id]: value,
    }));
  }, []);

  const handleOpenedDayChange = useCallback((e, id) => {
    setIsDayOpen((curState: Record<number, boolean>) => ({
      ...curState,
      [id]: e.target.checked,
    }));
  }, []);

  const handleSpecialHoursAddition = useCallback((dayIndex: number) => {
    setHasSpecialHours((curState) => ({
      ...curState,
      [dayIndex]: true,
    }));
  }, []);

  const handleRemoveSpecialHours = useCallback(
    (dayIndex: number) => {
      setHasSpecialHours((curState) => ({
        ...curState,
        [dayIndex]: false,
      }));
      setSpecialOpeningHours((curState: Record<number, string>) => ({
        ...curState,
        [dayIndex]: openingTime,
      }));
      setSpecialClosingHours((curState: Record<number, string>) => ({
        ...curState,
        [dayIndex]: closingTime,
      }));
    },
    [openingTime, closingTime],
  );

  const weeklyTimeSchedule: {
    [key: number]: { from: string; to: string; isOpen: boolean };
  } = {
    0: {
      from: specialOpeningHours[0] ? specialOpeningHours[0] : openingTime,
      to: specialClosingHours[0] ? specialClosingHours[0] : closingTime,
      isOpen: isDayOpen[0],
    },
    1: {
      from: specialOpeningHours[1] ? specialOpeningHours[1] : openingTime,
      to: specialClosingHours[1] ? specialClosingHours[1] : closingTime,
      isOpen: isDayOpen[1],
    },
    2: {
      from: specialOpeningHours[2] ? specialOpeningHours[2] : openingTime,
      to: specialClosingHours[2] ? specialClosingHours[2] : closingTime,
      isOpen: isDayOpen[2],
    },
    3: {
      from: specialOpeningHours[3] ? specialOpeningHours[3] : openingTime,
      to: specialClosingHours[3] ? specialClosingHours[3] : closingTime,
      isOpen: isDayOpen[3],
    },
    4: {
      from: specialOpeningHours[4] ? specialOpeningHours[4] : openingTime,
      to: specialClosingHours[4] ? specialClosingHours[4] : closingTime,
      isOpen: isDayOpen[4],
    },
    5: {
      from: specialOpeningHours[5] ? specialOpeningHours[5] : openingTime,
      to: specialClosingHours[5] ? specialClosingHours[5] : closingTime,
      isOpen: isDayOpen[5],
    },
    6: {
      from: specialOpeningHours[6] ? specialOpeningHours[6] : openingTime,
      to: specialClosingHours[6] ? specialClosingHours[6] : closingTime,
      isOpen: isDayOpen[6],
    },
  };

  const checkDisable: any = (id: number) => {
    return isDayOpen[id] ? (
      <WeekDayForm>
        <WeekDayFormFrom disabled type="time" value={openingTime} />
        <span>-</span>
        <WeekDayFormTo disabled type="time" value={closingTime} />
        <AddSpecialHours
          icon="plus"
          onClick={() => handleSpecialHoursAddition(id)}
          size="large"
          disabled={disabled}
        >
          {t('weeklySchedule.addSpecialHours')}
        </AddSpecialHours>
      </WeekDayForm>
    ) : (
      <DisabledWeekDayForm>
        <DisabledWeekDayFormFrom disabled type="time" value={openingTime} />
        <span>-</span>
        <DisabledWeekDayFormTo disabled type="time" value={closingTime} />
        <DisableAddSpecialHours
          icon="plus"
          onClick={() => handleSpecialHoursAddition(id)}
          size="large"
          disabled
        >
          {t('weeklySchedule.addSpecialHours')}
        </DisableAddSpecialHours>
      </DisabledWeekDayForm>
    );
  };

  useEffect(() => {
    onChange({
      ...(formData || {}),
      weeklyTime: weeklyTimeSchedule,
    });
  }, [onChange, formData, weeklyTimeSchedule]);

  return (
    <WeeklyScheduleContainer >
      <NormalOpenningHoursContainer>
        <NormalOpenningHoursTitle>
          {t('weeklySchedule.normalOpeningHours')}
        </NormalOpenningHoursTitle>
        <FormFieldContainer>
          <FormField>
            <TimeField>
              <ClockContainer>
                <ClockFormInput>
                  <FromInput
                    type="time"
                    value={openingTime}
                    onChange={handleOpeningTimeChange}
                    min="00:00"
                    max="23:59"
                    disabled={disabled}
                  />
                </ClockFormInput>
                <Text>{t('weeklySchedule.from')}</Text>
              </ClockContainer>
              <ClockContainer>
                <ClockToInput>
                  <ToInput
                    type="time"
                    value={closingTime}
                    onChange={handleClosingTimeChange}
                    min="00:00"
                    max="23:59"
                    disabled={disabled}
                  />
                </ClockToInput>
                <Text>{t('weeklySchedule.to')}</Text>
              </ClockContainer>
            </TimeField>
          </FormField>
        </FormFieldContainer>
      </NormalOpenningHoursContainer>
      <RowStyled>
        {days.map((day) => (
          <PanelCardMain key={day.id}>
            <WeekDayNameContainer type="flex" align="middle">
              <WeekDayCheckbox
                onChange={(e: any) => handleOpenedDayChange(e, day.id)}
                defaultChecked
                disabled={disabled}
              />
              <WeekDayLabel>{day.name}</WeekDayLabel>
              <WeekDayFormContainer>
                {hasSpecialHours[day.id] ? (
                  <>
                    <FormField>
                      <TimeField>
                        <ClockContainer>
                          <ClockFormInput>
                            <FromInput
                              type="time"
                              onChange={(e) =>
                                handleHasSpecialOpeningHoursChange(e.target.value, day.id)
                              }
                              defaultValue={openingTime}
                              disabled={disabled}
                            />
                          </ClockFormInput>
                          <Text>{t('weeklySchedule.from')}</Text>
                        </ClockContainer>
                        <ClockContainer>
                          <ClockToInput>
                            <ToInput
                              type="time"
                              onChange={(e) =>
                                handleSpecialClosingHoursChange(e.target.value, day.id)
                              }
                              defaultValue={closingTime}
                              disabled={disabled}
                            />
                          </ClockToInput>
                          <Text>{t('weeklySchedule.to')}</Text>
                        </ClockContainer>
                      </TimeField>
                    </FormField>
                    <RemoveSpecialHours
                      icon="close"
                      onClick={() => handleRemoveSpecialHours(day.id)}
                      disabled={disabled}
                    />
                  </>
                ) : (
                  checkDisable(day.id)
                )}
              </WeekDayFormContainer>
            </WeekDayNameContainer>
          </PanelCardMain>
        ))}
      </RowStyled>
    </WeeklyScheduleContainer>
  );
};

const WeeklyScheduleContainer = styled.div`
  width: 100%;
  padding-top: 20px;
`;

const FormField = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  place-content: flex-start;
  align-items: flex-start;
  gap: 0px;
  padding: 0px;
`;

const TimeField = styled.div`
  position: relative;
  overflow: visible;
  width: min-content;
  height: min-content;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  place-content: flex-start;
  align-items: flex-start;
  gap: 0px;
  padding: 0px;
`;

const ClockContainer = styled.div`
  position: relative;
  width: 160px;
  height: 40px;
  flex: 0 0 auto;
`;

const ClockFormInput = styled.div`
  position: absolute;
  inset: 1px 0px;
  flex: 0 0 auto;
`;

const Text = styled.div`
  position: absolute;
  width: auto;
  height: auto;
  left: 8px;
  top: 13px;
  flex: 0 0 auto;
  white-space: pre;
  font-weight: 300;
  font-size: 14px;
  color: #000;
  letter-spacing: 0px;
  text-transform: none;
  text-decoration: none;
  line-height: 1em;
`;

const FromInput = styled.input`
  pointer-events: auto;
  border: none;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  margin: 0px;
  color: rgb(103, 105, 115);
  background-color: rgb(255, 255, 255);
  border-radius: 4px 0px 0px 4px;
  text-align: left;
  line-height: 1;
  caret-color: rgb(68, 68, 68);
  height: 100%;
  padding: 12px 12px 12px 48px;
  direction: ltr;
  box-shadow: inset 0 0 0 1px rgb(221, 221, 221);
  font-size: 14px;
  font-weight: 400;
`;

const ToInput = styled.input`
  pointer-events: auto;
  border: none;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  margin: 0px;
  color: rgb(103, 105, 115);
  background-color: rgb(255, 255, 255);
  border-radius: 0px 4px 4px 0px;
  text-align: left;
  line-height: 1;
  caret-color: rgb(68, 68, 68);
  height: 100%;
  padding: 12px 12px 12px 32px;
  direction: ltr;
  box-shadow: inset 0 0 0 1px rgb(221, 221, 221);
  font-size: 14px;
  font-weight: 400;
`;

const ClockToInput = styled.div`
  position: absolute;
  inset: 1px 1px 1px -1px;
  flex: 0 0 auto;
`;

const NormalOpenningHoursContainer = styled.div`
  position: relative;
  overflow: visible;
  z-index: 1;
  width: 100%;
  height: min-content;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  place-content: center flex-start;
  align-items: center;
  gap: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #dddddd;
  border-bottom-width: 100%;
`;

const NormalOpenningHoursTitle = styled.div`
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: break-word;
  transform: none;
  overflow: hidden;
  height: auto;
  flex: 1 0 0px;
  padding-left: 16px;
  font-size: 14px;
  font-weight: 400;
  color: #000;
`;

const FormFieldContainer = styled.div`
  position: relative;
  overflow: visible;
  width: min-content;
  height: min-content;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  place-content: center flex-start;
  align-items: center;
  gap: 0px;
  padding-right: 43px;
`;

const RowStyled = styled(Row)`
  padding-bottom: 8px;
`;

const WeekDayNameContainer = styled(Row)`
  min-height: 70px;
`;

const WeekDayLabel = styled.div`
  font-weight: normal;
  font-size: 14px;
  color: #111;
`;

const WeekDayCheckbox = styled(Checkbox)`
  padding-right: 10px;
  .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0064b6 !important;
  }
` as any;

const PanelCardMain = styled.div`
  padding-left: 16px;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-flow: row nowrap;
  flex: 1 0 0px;
` as any;

const AddSpecialHours = styled(Button)`
  border: none;
  box-shadow: none;
  margin-left: 5px;
  span {
    font-size: 14px;
    font-weight: 500;
  }
` as any;

const RemoveSpecialHours = styled(Button)`
  border: none;
  margin-top: 5px;
  color: #676973;
  margin-right: -17px;
  box-shadow: none;
` as any;

const WeekDayFormContainer = styled.div`
  position: absolute;
  right: 30px;
  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 auto;
`;

const WeekDayForm = styled.div`
  .input:read-only {
    border: none;
  }
`;

const WeekDayFormFrom = styled.input`
  border: none;
  background-color: #fff;
  color: #676973;
  padding-right: 3px;

  &::-webkit-calendar-picker-indicator {
    display: none;
  }
`;

const WeekDayFormTo = styled.input`
  border: none;
  background-color: #fff;
  color: #676973;

  &::-webkit-calendar-picker-indicator {
    display: none;
  }
`;

const DisabledWeekDayForm = styled.div`
  background-color: #f5f5f5;
  border-color: rgba(0, 0, 0, 0.1);
  text-shadow: none;
  box-shadow: none;
  padding-left: 4px;
  margin-right: 10px;
  .input:read-only {
    border: none;
  }
  span {
    color: rgba(0, 0, 0, 0.25);
  }
`;

const DisabledWeekDayFormFrom = styled.input`
  border: none;
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.25);
  padding-right: 3px;

  &::-webkit-calendar-picker-indicator {
    display: none;
  }
`;

const DisabledWeekDayFormTo = styled.input`
  border: none;
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.25);

  &::-webkit-calendar-picker-indicator {
    display: none;
  }
`;

const DisableAddSpecialHours = styled(Button)`
  border: none;
  box-shadow: none;
  margin-left: 5px;
  padding-right: 4px;
  span {
    font-size: 14px;
    font-weight: 500;
  }
` as any;

export default WeeklySchedulePicker;
