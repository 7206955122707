import React, { useMemo } from 'react';
import { DataResidencyEnum } from '../../../../store/types/organisation';
import { GridStyles } from '@ombori/grid-reports';
import styled from '@emotion/styled';
import { Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts';
import dayjs from 'dayjs';
import {
  TenantPurchasesByDay,
  InstallationPurchasesByDay,
  DevicePurchasesByDay,
  SpacePurchasesByDay,
  AppPurchasesByDay,
  useTenantPurchasesByDay,
  useInstallationPurchasesByDay,
  useSpacePurchasesByDay,
  useDevicePurchasesByDay,
  useAppPurchasesByDay,
} from '../../use-analytics-report';
import CardContainer from './card-container';
import { useTranslation } from 'react-i18next';
import groupBy from 'lodash/groupBy';
import keys from 'lodash/keys';
import sumBy from 'lodash/sumBy';
import sortBy from 'lodash/sortBy';

type RawData =
  | TenantPurchasesByDay
  | InstallationPurchasesByDay
  | SpacePurchasesByDay
  | DevicePurchasesByDay
  | AppPurchasesByDay;

const buildData = (rawData: RawData[]) => {
  const groupedEvents = groupBy(rawData, (dataItem) => dataItem.date);

  const result = sortBy(keys(groupedEvents)).map((date) => {
    const events = sortBy(groupedEvents[date], (item) => item.eventTime);

    if (events.length <= 1) {
      const result = {
        date,
        avgDiff: 0,
      };

      return result;
    }

    const totalDiff = events.reduce((accumulator, event, index, array) => {
      if (index === 0) {
        return accumulator;
      }

      const diff =
        dayjs(event.eventTime).valueOf() - dayjs(array[index - 1].eventTime).valueOf();

      return accumulator + diff;
    }, 0);

    const averageDiff = Math.round(totalDiff / (events.length - 1));

    const averageDiffInSeconds = Math.round(averageDiff / 1000);

    const averageDiffInMinutes = Math.round(averageDiffInSeconds / 60);

    const result = {
      date,
      avgDiff: averageDiffInMinutes,
    };

    return result;
  });

  return result;
};

const Container = styled.div``;

const Body = styled.div``;

const Footer = styled.div`
  border-top: 1px solid rgb(232, 232, 232);
  display: grid;
  grid-auto-flow: column;
  padding: 8px 0;
  margin: 30px 0 0;
  column-gap: 16px;
  -webkit-box-pack: start;
  justify-content: start;
`;

interface AverageTimeBetweenTransactionsProps {
  data: RawData[];
}

const AverageTimeBetweenTransactions = ({
  data,
}: AverageTimeBetweenTransactionsProps) => {
  const { t } = useTranslation();

  const newData = buildData(data);

  const chartData = newData.map(({ date, avgDiff }) => {
    return {
      date,
      minutes: avgDiff,
    };
  });

  const totalAverage = Math.round(
    sumBy(newData, (item) => item.avgDiff) / newData.length,
  );

  return (
    <Container>
      <Body>
        <ResponsiveContainer width="100%" height={80}>
          <AreaChart data={chartData} margin={{ top: 0, bottom: 0, left: 0, right: 0 }}>
            <Tooltip
              labelFormatter={(i: string | number) => {
                if (typeof i === 'string') {
                  return i;
                }

                const label = chartData[i] ? chartData[i].date : '';

                return label;
              }}
            />
            <Area
              key="minutes"
              dataKey="minutes"
              type="basis"
              stroke="#3772e0"
              strokeWidth={2}
              fill="#3772e0"
              fillOpacity={0.2}
            />
          </AreaChart>
        </ResponsiveContainer>
      </Body>

      <Footer>
        {t('averageTimeBetweenTransactionsForPeriod')}: {totalAverage}
      </Footer>
    </Container>
  );
};

interface BaseProps {
  tenantId: string;
  dateFrom: string;
  dateTo: string;
  dataResidency: DataResidencyEnum;
  gridStyles?: GridStyles;
  isVisibleWithoutData?: boolean;
}

export const TenantAverageTimeBetweenTransactions = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}: BaseProps) => {
  const { t } = useTranslation();

  const purchasesFetchingState = useTenantPurchasesByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const data = useMemo(() => purchasesFetchingState.data || [], [purchasesFetchingState]);

  return (
    <CardContainer
      isLoading={purchasesFetchingState.isLoading}
      isSuccess={purchasesFetchingState.isSuccess}
      isError={purchasesFetchingState.isError}
      hasData={data.length > 0}
      title={t('averageTimeBetweenTransactions')}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {purchasesFetchingState.isSuccess && <AverageTimeBetweenTransactions data={data} />}
    </CardContainer>
  );
};

interface InstallationProps extends BaseProps {
  installationId: string;
}

export const InstallationAverageTimeBetweenTransactions = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
  installationId,
}: InstallationProps) => {
  const { t } = useTranslation();

  const purchasesFetchingState = useInstallationPurchasesByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
    installationId,
  });

  const data = useMemo(() => purchasesFetchingState.data || [], [purchasesFetchingState]);

  return (
    <CardContainer
      isLoading={purchasesFetchingState.isLoading}
      isSuccess={purchasesFetchingState.isSuccess}
      isError={purchasesFetchingState.isError}
      hasData={data.length > 0}
      title={t('averageTimeBetweenTransactions')}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {purchasesFetchingState.isSuccess && <AverageTimeBetweenTransactions data={data} />}
    </CardContainer>
  );
};

interface SpaceProps extends BaseProps {
  spaceId: string;
}

export const SpaceAverageTimeBetweenTransactions = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
  spaceId,
}: SpaceProps) => {
  const { t } = useTranslation();

  const purchasesFetchingState = useSpacePurchasesByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
    spaceId,
  });

  const data = useMemo(() => purchasesFetchingState.data || [], [purchasesFetchingState]);

  return (
    <CardContainer
      isLoading={purchasesFetchingState.isLoading}
      isSuccess={purchasesFetchingState.isSuccess}
      isError={purchasesFetchingState.isError}
      hasData={data.length > 0}
      title={t('averageTimeBetweenTransactions')}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {purchasesFetchingState.isSuccess && <AverageTimeBetweenTransactions data={data} />}
    </CardContainer>
  );
};

interface DeviceProps extends BaseProps {
  deviceId: string;
}

export const DeviceAverageTimeBetweenTransactions = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
  deviceId,
}: DeviceProps) => {
  const { t } = useTranslation();

  const purchasesFetchingState = useDevicePurchasesByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
    deviceId,
  });

  const data = useMemo(() => purchasesFetchingState.data || [], [purchasesFetchingState]);

  return (
    <CardContainer
      isLoading={purchasesFetchingState.isLoading}
      isSuccess={purchasesFetchingState.isSuccess}
      isError={purchasesFetchingState.isError}
      hasData={data.length > 0}
      title={t('averageTimeBetweenTransactions')}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {purchasesFetchingState.isSuccess && <AverageTimeBetweenTransactions data={data} />}
    </CardContainer>
  );
};

interface AppProps extends BaseProps {
  appId: string;
}

export const AppAverageTimeBetweenTransactions = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
  appId,
}: AppProps) => {
  const { t } = useTranslation();

  const purchasesFetchingState = useAppPurchasesByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
    appId,
  });

  const data = useMemo(() => purchasesFetchingState.data || [], [purchasesFetchingState]);

  return (
    <CardContainer
      isLoading={purchasesFetchingState.isLoading}
      isSuccess={purchasesFetchingState.isSuccess}
      isError={purchasesFetchingState.isError}
      hasData={data.length > 0}
      title={t('averageTimeBetweenTransactions')}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {purchasesFetchingState.isSuccess && <AverageTimeBetweenTransactions data={data} />}
    </CardContainer>
  );
};
