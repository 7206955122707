import axios, { AxiosError, AxiosInstance, CancelTokenSource } from 'axios';
import { ApiError } from './api-error';
import onInterceptorsReject from './utils';

class ApiService {
  private axiosInstance: AxiosInstance;

  private cancelTokenGet?: CancelTokenSource;

  public constructor(baseURL: string) {
    this.axiosInstance = axios.create({
      baseURL,
    });
  }

  public setInterceptors = () => {
    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return onInterceptorsReject(err);
      },
    );
  };

  public async get<T>(path: string, params?: { [key: string]: string | boolean | number }): Promise<T> {
    try {
      const result = await this.axiosInstance.get(path, {
        params,
        withCredentials: true,
        ...(this.cancelTokenGet ? { cancelToken: this.cancelTokenGet.token } : {}),
      });
      return result.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  public async put<T>(
    path: string,
    body: any,
    params?: { [key: string]: string },
  ): Promise<T> {
    try {
      const result = await this.axiosInstance.put(path, body, {
        params,
        withCredentials: true,
      });

      return result.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  public async post<T>(
    path: string,
    body?: any,
    params?: { [key: string]: string },
  ): Promise<T> {
    try {
      const result = await this.axiosInstance.post(path, body, {
        params,
        withCredentials: true,
      });

      return result.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  public async patch<T>(
    path: string,
    body?: any,
    params?: { [key: string]: string },
  ): Promise<T> {
    try {
      const result = await this.axiosInstance.patch(path, body, {
        params,
        withCredentials: true,
      });

      return result.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  public async delete<T>(
    path: string,
    params?: { [key: string]: string },
    data?: any,
  ): Promise<T> {
    try {
      const result = await this.axiosInstance.delete(path, {
        params,
        data,
        withCredentials: true,
      });

      return result.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  private handleError = (error: AxiosError): never => {
    if (axios.isCancel(error)) {
      throw error;
    }

    throw new ApiError(
      error.message,
      error.response
        ? { statusCode: error.response.status, data: error.response.data }
        : undefined,
    );
  };

  public handleCancelRequestGet = (): void => {
    if (this.cancelTokenGet) {
      this.cancelTokenGet.cancel();
    }

    this.cancelTokenGet = axios.CancelToken.source();
  };
}

export default ApiService;
