import { useCallback } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { ApiError } from '../../../../../services/api/api-error';
import { useGridProductsService } from '../../../../common/grid-products-service-provider';
import { DataResidencyEnum } from '../../../../../store/types/organisation';
import { GridProductData } from '../types';

type UseGridProductResponse = UseQueryResult<GridProductData, ApiError>;

interface UseGridProductProps {
  tenantId: string;
  dataResidency: DataResidencyEnum;
  environment: string;
  productGroupId: string;
}

const useGridProduct = (props: UseGridProductProps): UseGridProductResponse => {
  const { tenantId, dataResidency, environment, productGroupId } = props;

  const gridProductsService = useGridProductsService();

  const fetch = useCallback(async () => {
    const url = `/${tenantId}/${environment}/products/${productGroupId}`;

    return gridProductsService.get<GridProductData>(url, dataResidency);
  }, [dataResidency, environment, gridProductsService, productGroupId, tenantId]);

  const result = useQuery(getGridProductKeys(props), () => fetch(), {
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    keepPreviousData: true,
    onError: (_error: ApiError) => {},
    enabled: !!productGroupId,
  });

  return result;
};

export const getGridProductKeys = (props: UseGridProductProps): string[] => {
  const { tenantId, dataResidency, environment, productGroupId } = props;

  return [
    'get-grid-product',
    'tenantId', tenantId,
    'dataResidency', dataResidency,
    'environment', environment,
    'productGroupId', productGroupId,
  ];
};

export default useGridProduct;
