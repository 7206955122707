import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useApiService } from '../api-service-provider';
import AnalyticsDataExport, {
  AanalyticsDataPostForm,
  AnalyticsExportSchedule,
  DataExportStatusEnum,
} from '../../../store/types/analytics-data-export';
import User from '../../../store/types/user';

const queryKeys = {
  analyticsDataExport: (
    organisationId: string,
    apiPath: string,
    limit: number,
    page: number,
  ) => [
    'analytics-data-export',
    'organisationId',
    organisationId,
    'apiPath',
    apiPath,
    'page',
    page,
    'limit',
    limit,
  ],
  userList: (organisationId: string) => ['user-list', 'organisationId', organisationId],
  analyticsDataSchedule: (organisationId: string) => [
    'analytics-data-schedule',
    'organisationId',
    organisationId,
  ],
};

interface UseAnalyticsDataProps {
  organisationId: string;
  page?: number;
  limit?: number;
}

interface UseAnalyticsDataResponse {
  docs: AnalyticsDataExport[];
  totalDocs: number;
}

const shouldRefetch = (data: UseAnalyticsDataResponse | undefined) => {
  if (data && data.docs.length) {
    if (data.docs.some((exportJob) => exportJob.status === DataExportStatusEnum.QUEUED)) {
      return 30000;
    } else if (
      data.docs.some((exportJob) => exportJob.status === DataExportStatusEnum.PROCESSING)
    ) {
      return 60000;
    }
    return false;
  } else {
    return false;
  }
};

export const useAnalyticsActiveData = ({
  organisationId,
  page = 1,
  limit = 10,
}: UseAnalyticsDataProps) => {
  const apiService = useApiService();

  const url = `/api/analytics-export-job/tenant/${organisationId}/active-jobs?page=${page}&limit=${limit}`;

  return useQuery(
    queryKeys.analyticsDataExport(organisationId, 'active-jobs', limit, page),
    () => apiService.get<UseAnalyticsDataResponse>(url),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      keepPreviousData: true,
      refetchInterval: (data) => shouldRefetch(data),
    },
  );
};

export const useAnalyticsExpiredData = ({
  organisationId,
  page = 1,
  limit = 10,
}: UseAnalyticsDataProps) => {
  const apiService = useApiService();

  const url = `/api/analytics-export-job/tenant/${organisationId}/archived-jobs?page=${page}&limit=${limit}`;

  return useQuery(
    queryKeys.analyticsDataExport(organisationId, 'archived-jobs', limit, page),
    () => apiService.get<UseAnalyticsDataResponse>(url),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      keepPreviousData: true,
    },
  );
};

export const useCreateAnalyticsJob = ({
  organisationId,
  page = 1,
  limit = 10,
}: UseAnalyticsDataProps) => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  const url = `/api/analytics-export-job/`;

  return useMutation((body: AanalyticsDataPostForm) => apiService.post(url, body), {
    onSuccess: () => {
      queryClient.invalidateQueries(
        queryKeys.analyticsDataExport(organisationId, 'active-jobs', limit, page),
      );
    },
  });
};

export const useManageScheduleAnalytics = (organisationId: string) => {
  const apiService = useApiService();
  const queryClient = useQueryClient();
  const url = `/api/analytics-export-schedule/`;

  return useMutation((body: AnalyticsExportSchedule) => apiService.post(url, body), {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.analyticsDataSchedule(organisationId));
    },
  });
};

export const useUsers = (organisationId: string, enabled: boolean) => {
  const apiService = useApiService();
  const url = `/api/users?organizationId=${organisationId}`;

  return useQuery(queryKeys.userList(organisationId), () => apiService.get<User[]>(url), {
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    enabled,
  });
};

export const useAnalyticsDataSchedule = (organisationId: string) => {
  const apiService = useApiService();
  const url = `/api/analytics-export-schedule/tenant/${organisationId}`;

  return useQuery(
    queryKeys.analyticsDataSchedule(organisationId),
    () => apiService.get<AnalyticsExportSchedule>(url),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};
