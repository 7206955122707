import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import UserRolesListComponent from './user-roles-list.component';
import { RootModel, RootState } from '../../../../store/models/root.model';
import UserRole, { UserRoleFormData } from '../../../../store/types/user-roles';
import { getPermissions } from '../../../../utils/auth/permissions';

type UserRolesListContainer = Pick<
  React.ComponentProps<typeof UserRolesListComponent>,
  'match' | 'history' | 'location'
>;

const UserRolesListContainer = (props: UserRolesListContainer) => {
  const {
    match: {
      params: { organisationId },
    },
  } = props;

  // TODO: how to memoize this properly?
  const {
    fetchUserRolePermissionsMatrix,
    fetchUserRoles,
    createUserRole,
    deleteUserRole,
    updateUserRole,
  } = useActions<RootModel>((actions) => ({
    fetchUserRolePermissionsMatrix: actions.userRolePermissionsMatrix.fetch,
    fetchUserRoles: actions.userRoles.fetch,
    createUserRole: (data: Partial<UserRole>) =>
      actions.userRoles.create({ data, key: organisationId }),
    deleteUserRole: (id: string) => actions.userRoles.delete({ id, key: organisationId }),
    updateUserRole: (data: Partial<UserRole>) =>
      actions.userRoles.update({ data, key: organisationId }),
  }));

  const { userRolePermissionsMatrix, userRoles, loaded, permissions } = useStore<
    RootState
  >(
    (state) => ({
      userRolePermissionsMatrix: state.userRolePermissionsMatrix.data,
      userRoles: state.userRoles.values(organisationId),
      loaded:
        state.userRoles.loaded(organisationId) && state.userRolePermissionsMatrix.loaded,
      permissions: getPermissions(state.auth.user, props.match.params.organisationId),
    }),
    [organisationId],
  );

  const canManageUserRoleRow = (item: Partial<UserRoleFormData>) => {
    return item.organizationId === organisationId;
  };

  return (
    <UserRolesListComponent
      {...props}
      userRolePermissionsMatrix={userRolePermissionsMatrix}
      userRoles={userRoles}
      loaded={loaded}
      fetchUserRolePermissionsMatrix={fetchUserRolePermissionsMatrix}
      fetchUserRoles={fetchUserRoles}
      createUserRole={createUserRole}
      updateUserRole={updateUserRole}
      deleteUserRole={deleteUserRole}
      canCreate={permissions.userRoles.create}
      canUpdate={permissions.userRoles.update}
      canDelete={permissions.userRoles.remove}
      conditionalCanUpdate={canManageUserRoleRow}
      conditionalCanDelete={canManageUserRoleRow}
    />
  );
};

export default UserRolesListContainer;
