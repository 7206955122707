import Queue, {
  BookingsModeType,
  Tag,
  TextInAppCustomizationTextsForLanguage,
  Station,
  Feedback,
  CustomizableField,
  RegistrationField,
  FeatureListOptions,
  Category,
  DigitalCallType,
  QueueGridAppsProperties,
  LocalizedField,
} from '../../../../../store/types/queue';

export enum SupportedQueueLanguages {
  ar = 'AR',
  de = 'DE',
  en = 'EN',
  es = 'ES',
  it = 'IT',
  nl = 'NL',
  no = 'NO',
  sv = 'SV',
  fr = 'FR',
}

export type LoginMethod = 'pin' | 'email';

export interface ValuesProps {
  // ---- form specific
  seperateBookingHours?: boolean;
  seperateCurbsidePickupHours?: boolean;
  // ----
  title: string;
  displayTitle?: LocalizedField;
  pin: string;
  region: string;
  regularHours: WeekdayPeriods;
  specialHours?: WeekdaySpecialPeriods;
  bookingHours?: WeekdayPeriods;
  specialBookingHours?: WeekdaySpecialPeriods;
  curbsidePickupHours?: WeekdayPeriods;
  featureList: FeatureListOptions[];
  storeCapacity: string;
  maximumStoreCapacity: string;
  allowEntryAboveCapacity?: boolean;
  webPushEnabled?: boolean;
  applePassEnabled?: boolean;
  smsEnabled?: boolean;
  emailEnabled?: boolean;
  emailFrom?: string;
  registrationEnabled?: boolean;
  printingManualTicketsEnabled?: boolean;
  autoCheckOutByNextEnabled?: boolean;
  registrationFields?: RegistrationField[];
  phoneNumber?: string;
  curbsideLogoUrl?: string;
  curbsidePickupArrivalWindowCapacity: string;
  curbsidePickupArrivalWindowDuration: string;
  timeZone: string;
  isEmailLogin?: boolean;
  isInStore?: boolean;
  emailsAllowed?: string[];
  leaveReminderNotificationAfterMinutes?: string;
  bookingReminderNotificationBeforeMinutes?: string;
  leaveAfterMinutes?: string;
  notifyExtraPendingPositionOffset: string;
  defaultPhoneNumberCountry: string;
  phoneNumberFrom?: string | null;
  shortDisplayName?: string;
  addressLines?: string[];
  timeslotDuration: string;
  timeslotCapacity: string;
  phoneNumberCountries?: string[];
  textInAppCustomization: {
    [lng: string]: TextInAppCustomizationTextsForLanguage;
  };
  automaticallyClearQueueTime?: string[];
  availableTagsForQueue?: Tag[];
  bookingsMode?: BookingsModeType;
  checkInByQRCode?: boolean;
  selectedLanguageForTextCustomization: string;
  emailsForTeamsCurbsideNotifications?: string[];
  webHooks?: any;
  numberOfAvailableBookingsPerDay?: string;
  maxNumberOfPeoplePerWalkInTicket?: string;
  maxNumberOfPeoplePerBookingTicket?: string;
  stations: Station[];
  feedback: Feedback;
  staffNotifications?: {
    isEnabled: boolean;
    emailList?: string[];
    thresholdWaitingTime?: string;
    thresholdPeopleCount?: string;
    sendInterval?: string;
  };
  booking?: {
    physicalBooking: {
      enabled: boolean;
    };
    digitalBookingWithMsTeams?: {
      enabled: boolean;
    };
    isBookingSmsRequired?: boolean;
    isBookingEmailRequired?: boolean;
    registrationFields?: RegistrationField[];
    blockedDaysAhead?: number;
    bookingDaysCount?: number;
    isQueueRegistrationFormUsed?: boolean;
  };
  customFields?: {
    position?: CustomizableField[];
    bookingForm?: CustomizableField[];
    positionBooking?: CustomizableField[];
  };
  positionLabelPrefixes?: {
    anonymous?: string;
    booking?: string;
    manual?: string;
  };
  ticketFlowModifiers?: Queue['ticketFlowModifiers'];
  externalId?: string;
  categories?: Category[];
  digitalCall?: {
    isEnabled: boolean;
    globalQueueId?: string;
    apiToken?: string;
    customerUrlTemplate?: string;
    agentBaseUrl?: string;
    routingTimeout?: string;
    queueType: DigitalCallType;
  };
  autoCheckOutAfterMinutes?: number | null;
  isKeepDistanceInfoEnabled?: boolean;
  gridAppProperties?: QueueGridAppsProperties;
  isTicketTransferEnabled?: boolean;
}

export interface WeekdaySpecialPeriods {
  periods: {
    date: string;
    from: string;
    to: string;
    capacity?: string;
  }[];
}

export interface WeekdayPeriods {
  [key: string]: {
    enabled: boolean;
    periods: {
      from: string;
      to: string;
      capacity?: string;
    }[];
  };
}
