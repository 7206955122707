import React from 'react';
import styled from '@emotion/styled';
import SpacesOverview from '../spaces-report/spaces-report.component';
import Report from '../../../../common/report/report.component';

type SpaceHomeReportProps = Pick<
  React.ComponentProps<typeof Report>,
  'match' | 'history' | 'location'
>;

const SpaceHome = (props: SpaceHomeReportProps) => {
  return (
    <Container>
      <SpacesOverview />
    </Container>
  );
};

const Container = styled.div`
  padding: 32px 32px 0px 32px;

  .content-body {
    height: 100vh;
  }
`;

export default SpaceHome;
