import axios, { AxiosError, AxiosInstance } from 'axios';
import { ApiError } from '../api-error';
import { DataResidencyEnum } from '../../../store/types/organisation';
import onInterceptorsReject from '../utils';

class Index {
  private readonly axiosInstances: {
    [DataResidencyEnum.LOCAL]: AxiosInstance;
    [DataResidencyEnum.DEV]: AxiosInstance;
    [DataResidencyEnum.QA]: AxiosInstance;
    [DataResidencyEnum.EU]: AxiosInstance;
    [DataResidencyEnum.US]: AxiosInstance;
    [DataResidencyEnum.IN]: AxiosInstance;
    [DataResidencyEnum.UAE]: AxiosInstance;
    [DataResidencyEnum.AU]: AxiosInstance;
    [key: string]: AxiosInstance;
  };

  public constructor() {
    const axiosInstances: any = {};
    const buildBaseUrl = (baseUrl: string, env: string) => {
      if (env === DataResidencyEnum.LOCAL) {
        return `${baseUrl}/api`;
      }
      if (env === DataResidencyEnum.DEV) {
        return `https://api.griddeveloper.com/regions/${env.toLowerCase()}/reports`;
      }
      if (env === DataResidencyEnum.QA) {
        return `https://api-qa.omborigrid.com/regions/${env.toLowerCase()}/reports`;
      }
      // should be redirected to uniq point of entry later
      return `https://api.omborigrid.com/regions/${env.toLowerCase()}/reports`;
    };

    axiosInstances[DataResidencyEnum.LOCAL] = axios.create({
      baseURL: buildBaseUrl(
        process.env.REACT_APP_GRID_DATA_SERVICE_BASE_LOCAL_API_URL as string,
        DataResidencyEnum.LOCAL,
      ),
    });
    axiosInstances[DataResidencyEnum.DEV] = axios.create({
      baseURL: buildBaseUrl(
        process.env.REACT_APP_GRID_DATA_SERVICE_BASE_DEV_API_URL as string,
        DataResidencyEnum.DEV,
      ),
    });
    axiosInstances[DataResidencyEnum.QA] = axios.create({
      baseURL: buildBaseUrl(
        process.env.REACT_APP_GRID_DATA_SERVICE_BASE_API_URL as string,
        DataResidencyEnum.QA,
      ),
    });
    [
      DataResidencyEnum.EU,
      DataResidencyEnum.US,
      DataResidencyEnum.IN,
      DataResidencyEnum.UAE,
      DataResidencyEnum.AU,
    ].forEach((dataResidencyEnum) => {
      const baseURL = buildBaseUrl(
        process.env.REACT_APP_GRID_DATA_SERVICE_BASE_API_URL as string,
        dataResidencyEnum,
      );
      axiosInstances[dataResidencyEnum] = axios.create({
        baseURL,
      });
    });
    this.axiosInstances = axiosInstances;
  }

  public setInterceptors = () => {
    Object.keys(this.axiosInstances).forEach((key) => {
      this.axiosInstances[key].interceptors.response.use(
        (response) => {
          return response;
        },
        (err) => {
          return onInterceptorsReject(err);
        },
      );
    });
  };

  public async get<T>(
    path: string,
    dataResidencyEnum: DataResidencyEnum,
    params?: { [key: string]: string | number | undefined },
  ): Promise<T> {
    try {
      const result = await this.axiosInstances[dataResidencyEnum].get(path, {
        params,
        withCredentials: true,
      });
      return result.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  public async put<T>(
    path: string,
    dataResidencyEnum: DataResidencyEnum,
    body: any,
    params?: { [key: string]: string },
  ): Promise<T> {
    try {
      const result = await this.axiosInstances[dataResidencyEnum].put(path, body, {
        params,
        withCredentials: true,
      });

      return result.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  public async post<T>(
    path: string,
    dataResidencyEnum: DataResidencyEnum,
    body?: any,
    params?: { [key: string]: string },
  ): Promise<T> {
    try {
      const result = await this.axiosInstances[dataResidencyEnum].post(path, body, {
        params,
        withCredentials: true,
      });

      return result.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  public async delete<T>(
    path: string,
    dataResidencyEnum: DataResidencyEnum,
    params?: { [key: string]: string },
    data?: any,
  ): Promise<T> {
    try {
      const result = await this.axiosInstances[dataResidencyEnum].delete(path, {
        params,
        data,
        withCredentials: true,
      });

      return result.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  private handleError = (error: AxiosError): never => {
    throw new ApiError(
      error.message,
      error.response
        ? { statusCode: error.response.status, data: error.response.data }
        : undefined,
    );
  };
}

export default Index;
