import React, { useMemo } from 'react';
import { Skeleton, Alert, Icon, Tooltip } from 'antd';
import { Empty } from 'antd';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import Card from '../charts/card.component';
import { GridStyles } from '@ombori/grid-reports';
import { processGridStyles } from '../utils';

const Container = styled.div<{ height?: number; gridStyles?: GridStyles }>`
  ${({ height }) =>
    height &&
    `
    height: ${height}px;
  `}
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledSpan = styled.span`
  display: flex;
  align-items: center;

  p {
    font-size: 10px;
    font-weight: 200;
  }
`;

const StyledIcon = styled(Icon)`
  margin-left: 8px;
`;

interface CardContainerProps {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  hasData: boolean;
  isVisibleWithoutData?: boolean;
  height?: number;
  children: React.ReactNode;
  title: string | React.ReactNode;
  hint?: string | React.ReactNode;
  footer?: React.ReactNode;
  gridStyles?: GridStyles;
  onRefetch?: () => void;
  onRefetchLabel?: string;
}

const CardContainer: React.FC<CardContainerProps> = ({
  isLoading,
  isSuccess,
  isError,
  hasData,
  isVisibleWithoutData,
  height,
  children,
  title,
  hint,
  footer,
  gridStyles,
  onRefetch,
  onRefetchLabel,
}) => {
  const { t } = useTranslation();

  const style = useMemo(() => (gridStyles ? processGridStyles(gridStyles) : {}), [
    gridStyles,
  ]);

  const titleComponent = useMemo(() => {
    return (
      <Title>
        {title}

        {!!hint && (
          <Tooltip title={hint}>
            <StyledIcon type="question-circle" />
          </Tooltip>
        )}

        {!!onRefetch && (
          <StyledSpan>
            <p>{onRefetchLabel}</p>
            <StyledIcon type="redo" onClick={() => onRefetch()} />
          </StyledSpan>
        )}
      </Title>
    );
  }, [title, hint, onRefetch, onRefetchLabel]);

  return (
    <>
      {isLoading && (
        <Card title={titleComponent} style={style}>
          <Container height={height}>
            <div data-testid="card-container-skeleton">
              <Skeleton active />
            </div>
          </Container>
        </Card>
      )}

      {isSuccess && (
        <>
          {hasData && (
            <Card title={titleComponent} style={style}>
              <Container height={height}>{children}</Container>

              {!!footer && footer}
            </Card>
          )}

          {!hasData && isVisibleWithoutData && (
            <Card title={titleComponent} style={style}>
              <Container height={height}>
                <Empty />
              </Container>
            </Card>
          )}
        </>
      )}

      {isError && (
        <Card title={titleComponent} style={style}>
          <Container height={height}>
            <Alert
              message={t('errorDuringFetchingAnalyticsReportTitle')}
              description={t('errorDuringFetchingAnalyticsReportDescription')}
              type="error"
              showIcon
            />
          </Container>
        </Card>
      )}
    </>
  );
};

export default CardContainer;
