import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Button, Row, Tooltip } from 'antd';
import { PanelCard, Title } from '../common';
import { ButtonType } from '../../../../../../types';
import { useTranslation } from 'react-i18next';
import { Select } from '../../../../../common/react-final-form';
import { IntegrationStatusEnum } from '../types';
import { utcToLocalDate } from '../../../../../../utils/dayjs';

interface IntegrationSidePanelProps {
  isSubmitEnabled?: boolean;
  isSubmitting?: boolean;
  isStatusEnabled?: boolean;
  lastProcessedDate?: Date;
}

const IntegrationSidePanel = (props: IntegrationSidePanelProps) => {
  const {
    isSubmitEnabled = true,
    isSubmitting = false,
    isStatusEnabled = true,
    lastProcessedDate,
  } = props;

  const { t } = useTranslation();

  const { lastEditDate, lastEditAge } = useMemo(() => {
    return {
      lastEditDate: utcToLocalDate(lastProcessedDate).format('YYYY-MM-DD hh:mm A'),
      lastEditAge: utcToLocalDate(lastProcessedDate).fromNow(),
    };
  }, [lastProcessedDate]);

  return (
    <PanelCard>
      <Row>
        <Title>{t('status')}</Title>
        <Select
          name="status"
          options={[
            { value: IntegrationStatusEnum.ACTIVE, label: t('active') },
            { value: IntegrationStatusEnum.INACTIVE, label: t('inactive') },
          ]}
          disabled={!isStatusEnabled}
        />
      </Row>
      <Row>
        <TextWrap>
          {lastProcessedDate && (
            <Tooltip placement="bottom" title={lastEditDate}>
              <LastProcessedText>
                {t('lastEditedTimestamp', { timestamp: lastEditAge })}
              </LastProcessedText>
            </Tooltip>
          )}
        </TextWrap>
      </Row>
      <SaveButton
        type="primary"
        htmlType="submit"
        size="large"
        disabled={!isSubmitEnabled || isSubmitting}
        loading={isSubmitting}
      >
        {t('saveAllChanges')}
      </SaveButton>
    </PanelCard>
  );
};

const TextWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0 5px 0;
`;

const LastProcessedText = styled.span`
  color: rgba(0, 0, 0, 0.45);
`;

const SaveButton = styled(Button)`
  width: 100%;
` as ButtonType;

export default IntegrationSidePanel;
