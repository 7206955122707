/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import styled from '@emotion/styled';
import { Alert, Icon } from 'antd';
import PanelCard from '../../../../common/panel-card/panel-card.component';
import TitleWithTag from './title-with-tag.component';

export type AlertType = 'info' | 'success' | 'warning' | 'danger';

interface PlanPanelCard {
  children: React.ReactNode;
  icon?: string;
  title?: string;
  tag?: string;
  alertType?: string;
  alertDescription?: string;
  extra?: React.ReactNode | string;
  footer?: React.ReactNode;
}

// TO DO: Filename is temporarily suffixed with "-new". This will replace old component
const PlanPanelCard = (props: PlanPanelCard) => {
  const {
    children,
    extra,
    footer,
    icon,
    title,
    tag,
    alertType = 'info',
    alertDescription,
  } = props;

  return (
    <PanelCardStyled
      title={
        <>
          {icon && <IconTitle type={icon} />}
          <TitleWithTag title={title} titleSize={20} tagText={tag} tagSize={14} />
        </>
      }
      bodyStyle={{ padding: 16 }}
      extra={extra}
      bodypadding="0px"
    >
      {alertDescription && (
        <AlertBanner
          message={
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: alertDescription || '' }}
            />
          }
          // @ts-ignore
          // eslint-disable-next-line no-use-before-define
          type={getAlertType(alertType)}
          icon={
            <div className="hello">
              <Icon type={alertType} />
            </div>
          }
          showIcon
          banner
        />
      )}
      {children}
      {footer && <Footer>{footer}</Footer>}
    </PanelCardStyled>
  );
};

const getAlertType = (type: string): AlertType => {
  if (!['info', 'success', 'warning', 'danger'].includes(type)) {
    return 'info';
  }

  return type as AlertType;
};

const Footer = styled.div`
  display: flex;
  padding: 16px;

  > .ant-btn {
    height: 40px;
    padding: 0 15px;
    font-size: 16px;
    border-radius: 4px;
  }
`;

const IconTitle = styled(Icon)`
  margin-right: 10px;
`;

const PanelCardStyled = styled(PanelCard)`
  .ant-card-head {
    padding-left: 20px;
  }

  .ant-card-head-wrapper {
    align-items: start;
  }

  .ant-card-head-title {
    margin-top: auto;
    margin-bottom: auto;
  }

  .ant-card-extra {
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const AlertBanner = styled(Alert)`
  margin-top: 1px;
  padding: 15px 15px 15px 37px !important;
  color: #111111;

  > .ant-alert-icon {
    font-size: 25px;
    top: 14.5px;
    color: #111111;

    margin-top: -15px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .ant-alert-message {
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    margin-left: 13px;
  }
`;

export default PlanPanelCard;
