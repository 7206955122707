import * as React from 'react';
import { FieldProps } from 'react-jsonschema-form';
import { get } from 'lodash';
// @ts-ignore
import SchemaField from 'react-jsonschema-form/lib/components/fields/SchemaField';
import { SchemaFormContext } from '../schema-form.component';
import ProtectedSection from '../../protected-section/protected-section.component';
import SchemaFieldCustomTitle from './schema-field-custom-title.component';
import { InstallationSettingsLevel } from '../../../../store/types/organisation-app';

type RuleAwareSchemaFieldRegistry = Omit<FieldProps['registry'], 'formContext'> & {
  formContext: SchemaFormContext;
};

interface RuleAwareSchemaFieldProps extends FieldProps {
  registry: RuleAwareSchemaFieldRegistry;
}

const RuleAwareSchemaField = (props: RuleAwareSchemaFieldProps) => {
  const { uiSchema, registry, schema, formData, idSchema, onChange } = props;

  const overridenProps: any = React.useMemo(() => {
    let propsOverride = {};

    if (
      schema.type === 'object' &&
      get(schema, 'properties.ref.const') === 'media' &&
      (get(uiSchema, 'ui:field') !== 'mediaPicker' &&
        get(uiSchema, 'ui:field') !== 'gridappPicker' &&
          get(uiSchema, 'ui:field') !== 'filePicker')
    ) {
      // workaround to display media picker without involving ui schema for media templates
      propsOverride = {
        uiSchema: {
          ...(uiSchema || {}),
          'ui:field': 'mediaPicker',
        },
      };
    }

    const settingsLevel =
      registry && registry.formContext && registry.formContext.settingsLevel;

    if (settingsLevel && settingsLevel !== InstallationSettingsLevel.GLOBAL) {
      propsOverride = {
        ...propsOverride,
        schema: {
          ...(schema || {}),
          title: (
            <SchemaFieldCustomTitle
              schema={schema}
              uiSchema={uiSchema}
              formData={formData}
              idSchema={idSchema}
              onChange={onChange}
              formContext={registry.formContext}
            />
          ),
        },
      };
    }

    return propsOverride;
  }, [schema, uiSchema, registry, onChange, idSchema, formData]);

  if (
    uiSchema &&
    (uiSchema.roles || uiSchema.settingsOverridingRule) &&
    registry.formContext
  ) {
    return (
      <ProtectedSection
        user={registry.formContext.user}
        permissionGroupId={registry.formContext.organisationId}
        roleIds={uiSchema.roles}
        settingsOverridingRule={uiSchema.settingsOverridingRule}
      >
        <SchemaField {...props} {...overridenProps} />
      </ProtectedSection>
    );
  }

  return <SchemaField {...props} {...overridenProps} />;
};

export default React.memo(RuleAwareSchemaField);
