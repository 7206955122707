import React, { useCallback } from 'react';
import { Icon, Select } from 'antd';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import FilterItemType, { FilterOption, ActiveFilter } from '../../../store/types/filters';

const { Option } = Select;

interface FilterItemProps {
  onRemove: (rowIndex: number) => void;
  onFilterItemChange: (rowIndex: number, value: {}) => void;
  onFilterOptionChange: (rowIndex: number, value: {}) => void;
  defaultFilter: ActiveFilter;
  rowIndex: number;
  availableFilters: (FilterItemType | null)[];
  availableOptions: (FilterOption | null)[] | undefined;
}

const FilterItem = (props: FilterItemProps) => {
  const {
    defaultFilter,
    onFilterItemChange,
    onFilterOptionChange,
    onRemove,
    rowIndex,
    availableFilters,
    availableOptions,
  } = props;

  const { t } = useTranslation();

  const handleTypeChange = useCallback(
    (value: {}): void => {
      onFilterItemChange(rowIndex, value);
    },
    [onFilterItemChange, rowIndex],
  );

  const handleValueChange = useCallback(
    (value: {}): void => {
      onFilterOptionChange(rowIndex, value);
    },
    [onFilterOptionChange, rowIndex],
  );

  const handleRemove = useCallback((): void => {
    onRemove(rowIndex);
  }, [onRemove, rowIndex]);

  const handleFilterOption = useCallback((inputValue: string, option: any): boolean => {
    return option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
  }, []);

  return (
    <Container key={`${rowIndex}-container`}>
      <SelectType
        key={`${rowIndex}-select`}
        defaultValue={{
          key: defaultFilter.typeId,
          label: defaultFilter.typeLabel || t('selectFilter'),
        }}
        onChange={handleTypeChange}
        labelInValue
      >
        {availableFilters &&
          availableFilters.map((filter) =>
            filter ? (
              <Option key={`${rowIndex}-${filter.label}`} value={filter.id}>
                {filter.label}
              </Option>
            ) : (
              ''
            ),
          )}
      </SelectType>
      <ValueContainer>
        <ValueWrap>
          <SelectValue
            key={`${rowIndex}-options`}
            defaultValue={{
              key: defaultFilter.valueId || '',
              label: defaultFilter.valueLabel || t('selectFilter'),
            }}
            onChange={handleValueChange}
            labelInValue
            showSearch
            filterOption={handleFilterOption}
          >
            {availableOptions &&
              availableOptions.map((option) =>
                option ? (
                  <Option
                    key={`${rowIndex}-${option.label}`}
                    value={option.id}
                    // Only way to style this component
                    style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}
                  >
                    {option.label}
                  </Option>
                ) : (
                  ''
                ),
              )}
          </SelectValue>
          <CloseIcon key={`${rowIndex}-close`} type="close" onClick={handleRemove} />
        </ValueWrap>
      </ValueContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const SelectType = styled(Select)`
  :nth-of-type(1) {
    margin-right: 10px;
  }
  .ant-select-selection__rendered {
    max-width: 200px;
    min-width: 100px;
  }
`;

const ValueContainer = styled.div`
  flex-grow: 1;
`;

const ValueWrap = styled.div`
  display: inline-flex;
  width: 100%;
`;

const SelectValue = styled(Select)`
  width: 100%;

  :nth-of-type(1) {
    margin-right: 10px;
  }
  .ant-select-selection__rendered {
    max-width: 200px;
    min-width: 100px;
  }
`;

const CloseIcon = styled(Icon)`
  align-self: center;
`;

export default FilterItem;
