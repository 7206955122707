import React from 'react';
import styled from '@emotion/styled';
import { FieldArray } from 'react-final-form-arrays';
import { X } from 'react-feather';
import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { ButtonType } from '../../../types';
import { convertMetaError } from './utils';
import LabelError from './input-wrap';
import { Validator, composeValidators, validateMinimumRequired } from './validator';
import classNames from 'classnames';
import { Icon } from '../schema-form/common';

interface ArrayFieldsProps<T = unknown> {
  className?: string;
  propertyName: string;
  label?: React.ReactNode;
  isRequired?: boolean;
  canAdd?: boolean;
  canDelete?: boolean;
  itemRowContent?: (parentPropertyName: string, index: number, arrayFieldData?: T) => React.ReactNode;
  defaultValue?: T;
  addButtonLabel?: string;
  validators?: Validator<T>[];
  emptyContent?: React.ReactNode;
}

const ArrayFields = <T,>(props: ArrayFieldsProps<T>) => {
  const {
    className,
    propertyName,
    label,
    isRequired,
    canAdd = true,
    canDelete = true,
    itemRowContent,
    defaultValue = {},
    addButtonLabel,
    validators = [],
    emptyContent,
  } = props;

  const { t } = useTranslation();

  const inputValidators = [...(isRequired ? [validateMinimumRequired(1)] : []), ...validators];

  return (
    <>
      <FieldArray name={propertyName} validate={composeValidators(inputValidators)}>
        {({ fields, meta }) => {
          // Type bug in meta.error (boolean), but internally stores string[] or object[]
          const error = convertMetaError(meta.error);
          const isError = !!error && meta.touched;

          return (
            <div className={className}>
              <LabelError label={label} isRequired={isRequired} error={isError ? error : undefined} />
              <div className={classNames('array-fields-items', fields.length ? 'array-fields-items-filled' : 'array-fields-items-empty')}>
                {!fields.length && emptyContent}
                {fields.map((name, index) => {
                  const fieldState: T | undefined = fields.value && fields.value[index] ? fields.value[index] : undefined;
                  return (
                    <RowStyled key={`${propertyName}-${name}`} className="array-fields-item">
                      {itemRowContent ? itemRowContent(name, index, fieldState) : null}
                      {canDelete && (
                        <ItemAction span={1} className="array-fields-delete-btn-container">
                          <DeleteButton
                            // TO DO: Upgrade react-final-form and related packages
                            // form pristine & dirty is not working properly with array field remove
                            // need to upgrade the ff except final-form-arrays 1.1.2: 
                            //  final-form -> 4.13.0
                            //  react-final-form -> 6.0.0
                            //  react-final-form-arrays -> 3.0.0
                            className="array-fields-delete-btn"
                            onClick={() => fields.remove(index)}
                            size="default"
                            type="link"
                          >
                            <Icon size={16} component={() => <X />} />
                          </DeleteButton>
                        </ItemAction>
                      )}
                    </RowStyled>
                  );
                })}
              </div>
              {canAdd && (
                <AddButton
                  className={classNames('array-fields-add-btn', isError ? 'text-danger' : undefined)}
                  onClick={() => fields.push(defaultValue)}
                  size="default"
                  icon="plus"
                  type="link"
                >
                  {addButtonLabel || t('add')}
                </AddButton>
              )}
            </div>
          );
        }}
      </FieldArray>
    </>
  );
};

const RowStyled = styled(Row)`
  display: flex;
`;

const ItemAction = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 !important;
`;

const AddButton = styled(Button)`
  margin-top: 10px;
` as ButtonType;

const DeleteButton = styled(Button)`
  position: relative;
  line-height: 0;
  width: fit-content;
  height: fit-content;
  padding: 0;
` as ButtonType;

export default ArrayFields;
