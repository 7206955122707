import { InteractionType, SessionInteractionType } from '@ombori/grid-reports';
import { useQuery } from 'react-query';
import {
  AggregatedAppNpsByDay,
  AppCategoriesByDay,
  AppEventsByDay,
  AppEventsByHour,
  AppEventsFlowByDay,
  AppInteractiveSessionsByDay,
  AppProductsByDay,
  AppPurchaseProductByDay,
  AppPurchasesByDay,
  AppQrCodesByDay,
  AppMediaByDay,
  AppSessionsByDay,
  AppSessionsByHour,
  BaseProps,
  TimeSpanType,
  UseEventsFlowByDayProps,
} from './types';
import { useGridDataService } from '../../grid-data-service-provider';
import buildQueryKey from './build-query-key';
import routes from '../routes';

interface UseAppEventsByDayProps extends BaseProps {
  appId: string;
  interactionType?: InteractionType;
}

export const useAppEventsByDay = ({
  tenantId,
  appId,
  dateFrom,
  dateTo,
  interactionType = InteractionType.All,
  dataResidency,
}: UseAppEventsByDayProps) => {
  const gridDataService = useGridDataService();

  const buildAppEventsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'appId',
    appId,
    'interactionType',
    interactionType,
    'events',
  ];

  return useQuery(
    buildAppEventsQuery(),
    () =>
      gridDataService.get<AppEventsByDay[]>(
        routes.getAppEvents(tenantId, appId),
        dataResidency,
        {
          dateFrom,
          dateTo,
          timespanType: TimeSpanType.Day,
          interactionType,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseAppSessionsByDayProps extends BaseProps {
  appId: string;
  interactionType?: SessionInteractionType;
}

export const useAppSessionsByDay = ({
  tenantId,
  appId,
  dateFrom,
  dateTo,
  dataResidency,
  interactionType = SessionInteractionType.All,
}: UseAppSessionsByDayProps) => {
  const gridDataService = useGridDataService();

  const buildAppSessionsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'interactionType',
    interactionType,
    'appId',
    appId,
    'sessions',
  ];

  return useQuery(
    buildAppSessionsQuery(),
    () =>
      gridDataService.get<
        typeof interactionType extends SessionInteractionType.Interactive
          ? AppInteractiveSessionsByDay[]
          : AppSessionsByDay[]
      >(routes.getAppSessions(tenantId, appId), dataResidency, {
        dateFrom,
        dateTo,
        interactionType,
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseAppNpsByDayProps extends BaseProps {
  appId: string;
}

export const useAppNpsByDay = ({
  tenantId,
  appId,
  dateFrom,
  dateTo,
  dataResidency,
}: UseAppNpsByDayProps) => {
  const gridDataService = useGridDataService();

  const buildAppNpsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'appId',
    appId,
    'nps',
  ];

  return useQuery(
    buildAppNpsQuery(),
    () =>
      gridDataService.get<AggregatedAppNpsByDay>(
        routes.getAppNps(tenantId, appId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseAppEventsFlowByDayProps extends UseEventsFlowByDayProps {
  appId: string;
}

export const useAppEventsFlowByDay = ({
  tenantId,
  appId,
  dateFrom,
  dateTo,
  dataResidency,
  eventsFlowDepth,
}: UseAppEventsFlowByDayProps) => {
  const gridDataService = useGridDataService();

  const buildAppEventsFlowQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'appId',
    appId,
    'eventsFlowDepth',
    eventsFlowDepth,
    'eventsFlow',
  ];

  return useQuery(
    buildAppEventsFlowQuery(),
    () =>
      gridDataService.get<AppEventsFlowByDay[]>(
        routes.getAppEventsFlow(tenantId, appId),
        dataResidency,
        {
          dateFrom,
          dateTo,
          eventsFlowDepth,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseAppProductsByDayProps extends BaseProps {
  appId: string;
}

export const useAppProductsByDay = ({
  tenantId,
  appId,
  dateFrom,
  dateTo,
  dataResidency,
}: UseAppProductsByDayProps) => {
  const gridDataService = useGridDataService();

  const buildAppProductsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'appId',
    appId,
    'products',
  ];

  return useQuery(
    buildAppProductsQuery(),
    () =>
      gridDataService.get<AppProductsByDay[]>(
        routes.getAppProducts(tenantId, appId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseAppCategoriesByDayProps extends BaseProps {
  appId: string;
}

export const useAppCategoriesByDay = ({
  tenantId,
  appId,
  dateFrom,
  dateTo,
  dataResidency,
}: UseAppCategoriesByDayProps) => {
  const gridDataService = useGridDataService();

  const buildAppCategoriesQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'appId',
    appId,
    'categories',
  ];

  return useQuery(
    buildAppCategoriesQuery(),
    () =>
      gridDataService.get<AppCategoriesByDay[]>(
        routes.getAppCategories(tenantId, appId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseAppPurchasesByDayProps extends BaseProps {
  appId: string;
}

export const useAppPurchasesByDay = ({
  tenantId,
  appId,
  dateFrom,
  dateTo,
  dataResidency,
}: UseAppPurchasesByDayProps) => {
  const gridDataService = useGridDataService();

  const buildAppPurchasesQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'appId',
    appId,
    'purchases',
  ];

  return useQuery(
    buildAppPurchasesQuery(),
    () =>
      gridDataService.get<AppPurchasesByDay[]>(
        routes.getAppPurchases(tenantId, appId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseAppPurchasedProductsByDayProps extends BaseProps {
  appId: string;
}

export const useAppPurchasedProductsByDay = ({
  tenantId,
  appId,
  dateFrom,
  dateTo,
  dataResidency,
}: UseAppPurchasedProductsByDayProps) => {
  const gridDataService = useGridDataService();

  const buildAppPurchasesQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'appId',
    appId,
    'purchased-products',
  ];

  return useQuery(
    buildAppPurchasesQuery(),
    () =>
      gridDataService.get<AppPurchaseProductByDay[]>(
        routes.getAppPurchasedProducts(tenantId, appId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseAppQrCodesByDayProps extends BaseProps {
  appId: string;
}

export const useAppQrCodesByDay = ({
  tenantId,
  appId,
  dateFrom,
  dateTo,
  dataResidency,
}: UseAppQrCodesByDayProps) => {
  const gridDataService = useGridDataService();

  const buildAppQrCodesQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'appId',
    appId,
    'qr-codes',
  ];

  return useQuery(
    buildAppQrCodesQuery(),
    () =>
      gridDataService.get<AppQrCodesByDay[]>(
        routes.getAppQrCodes(tenantId, appId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseAppMediaByDayProps extends BaseProps {
  appId: string;
}

export const useAppMediaByDay = ({
  tenantId,
  appId,
  dateFrom,
  dateTo,
  dataResidency,
}: UseAppMediaByDayProps) => {
  const gridDataService = useGridDataService();

  const buildAppMediaQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'appId',
    appId,
    'media',
  ];

  return useQuery(
    buildAppMediaQuery(),
    () =>
      gridDataService.get<AppMediaByDay[]>(
        routes.getAppMedia(tenantId, appId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export const useAppSessionsByHour = ({
  tenantId,
  appId,
  dateFrom,
  dateTo,
  dataResidency,
  interactionType = SessionInteractionType.All,
}: UseAppSessionsByDayProps) => {
  const gridDataService = useGridDataService();

  const timespanType = TimeSpanType.Hour;

  const buildAppSessionsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType }),
    'interactionType',
    interactionType,
    'appId',
    appId,
    'sessions',
  ];

  return useQuery(
    buildAppSessionsQuery(),
    () =>
      gridDataService.get<AppSessionsByHour[]>(
        routes.getAppSessions(tenantId, appId),
        dataResidency,
        {
          dateFrom,
          dateTo,
          timespanType,
          interactionType,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export const useAppEventsByHour = ({
  tenantId,
  appId,
  dateFrom,
  dateTo,
  interactionType = InteractionType.All,
  dataResidency,
}: UseAppEventsByDayProps) => {
  const gridDataService = useGridDataService();

  const timespanType = TimeSpanType.Hour;

  const buildAppEventsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType }),
    'appId',
    appId,
    'interactionType',
    interactionType,
    'events',
  ];

  return useQuery(
    buildAppEventsQuery(),
    () =>
      gridDataService.get<AppEventsByHour[]>(
        routes.getAppEvents(tenantId, appId),
        dataResidency,
        {
          dateFrom,
          dateTo,
          timespanType,
          interactionType,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};
