import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterOption } from '../../store/types/filters';

export enum DeviceArchitectureEnum {
  AMD64 = 'amd64',
  ROCKCHIP_DN74 = 'rockchip-dn74',
}

export interface DeviceArchitectureOption extends FilterOption {}

interface UseDeviceArchitectureResponse {
  deviceArchitectureOptions: DeviceArchitectureOption[];
  getDeviceArchitectureLabel: (architecture: DeviceArchitectureEnum) => string;
}

const useDeviceArchitecture = (): UseDeviceArchitectureResponse => {
  const { t } = useTranslation();

  const deviceArchitectureOptions = useMemo(() => {
    return [
      {
        id: DeviceArchitectureEnum.AMD64,
        label: t('deviceArchitectureOption.amd64'),
      },
      {
        id: DeviceArchitectureEnum.ROCKCHIP_DN74,
        label: t('deviceArchitectureOption.rockchipdn74'),
      },
    ];
  }, [t]);

  const getDeviceArchitectureLabel = useCallback(
    (architecture: DeviceArchitectureEnum): string => {
      const matchedOption = deviceArchitectureOptions.find(
        (option) => option.id === architecture,
      );

      return matchedOption ? matchedOption.label : '';
    },
    [deviceArchitectureOptions],
  );

  return {
    deviceArchitectureOptions,
    getDeviceArchitectureLabel,
  };
};

export default useDeviceArchitecture;
