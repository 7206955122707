import React, { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import cronstrue from 'cronstrue';
import 'cronstrue/locales/sv';
import { generateCronExpression, parseCronExpression } from '../../../../../common/react-final-form';
import moment from 'moment';

const timeFormat = 'HH:mm';

interface IntegrationCronSchedulesProps {
  schedules: string[];
}

const IntegrationCronSchedules = (props: IntegrationCronSchedulesProps) => {
  const { schedules } = props;

  const count = schedules.length - 1;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const { t, i18n } = useTranslation();

  const handleExpand = useCallback(() => {
    setIsExpanded((prevState) => !prevState);
  }, []);

  const scheduleList = useMemo(() => {
    const schedulesToConvert = !isExpanded && schedules.length > 0 ? [schedules[0]] : schedules;

    return schedulesToConvert.map((schedule) => {
      const { frequency = null, hour = null, minute = null} = parseCronExpression(schedule) || {};

      if (frequency === null || hour === null || minute === null) {
        return t('productIntegration.invalidSchedule');
      }

      const utcTimeString = `${hour}:${minute}`;
      const utcTime = moment.utc(utcTimeString, timeFormat);
      const localTime = utcTime.local();
      const localTimeString = localTime.format(timeFormat);

      const cronExpression = generateCronExpression(frequency, localTimeString);

      try {
        return cronstrue.toString(cronExpression, {
          verbose: true,
          locale: i18n.language,
        });
      } catch {
        t('productIntegration.invalidSchedule');
      }

      return t('productIntegration.invalidSchedule');
    });
  }, [isExpanded, i18n.language, schedules, t]);

  return (
    <IntegrationCronSchedulesContainer>
      {scheduleList.map((schedule, index) => <div className="schedule" key={`${schedule}_${index}`}>{schedule}</div>)}
      {count > 0 && (
        <MoreLess onClick={handleExpand}>
          {isExpanded ? t('less') : t('tagsPlusMore', { count })}
        </MoreLess>
      )}
    </IntegrationCronSchedulesContainer>
  );
};

const IntegrationCronSchedulesContainer = styled.div`
  .schedule:last-of-type {
    display: inline-block;
  }
`;

const MoreLess = styled.span`
  display: inline-block;
  font-size: 10px !important;
  color: #2364aa !important;
  text-transform: lowercase !important;
  height: min-content;
  margin-top: auto;
  margin-left: 5px;
  cursor: pointer;
`;

export default IntegrationCronSchedules;
