import { PaginationRequest } from '../../../store/types/pagination';

const queryKeys = {
  getDeviceBundles: (request?: PaginationRequest) => {
    const { query = undefined, param = undefined, page = undefined, limit = undefined } =
      request || {};

    return [
      'get-device-bundles',
      'query',
      query,
      'param',
      param,
      'page',
      page,
      'limit',
      limit,
    ];
  },
};

export default queryKeys;
