import styled from '@emotion/styled';
import { Icon, Table } from 'antd';
import React, { useState, useCallback } from 'react';

interface RichTableProps {
  title?: string;
  columns: Record<string, any>[];
  dataSource: Record<string, any>[];
  scroll?: { x?: boolean; y?: boolean };
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  font-weight: 600;
  font-style: normal;
  color: #111111;
  line-height: 1;
  text-transform: capitalize;
  font-size: 16px;
  align-items: center;
  padding-left: 20px;
  :hover {
    cursor: pointer;
    background: rgba(245, 246, 250, 0.8);
  }
  height: 50px;
`;

const Chevron = styled(Icon)`
  width: 20px;
  height: 20px;
`;

const ActionContainer = styled.div`
  position: absolute;
  right: 20px;

  flex-direction: row;
  justify-content: right;
`;

const TableContainer = styled.div<{ isHidden: boolean }>`
  display: ${(props) => (props.isHidden ? 'none' : 'block')};
  width: 100%;
  transition: opacity 0.5s linear;
`;

const RichTable = ({
  title,
  columns,
  dataSource,
  scroll = { x: false, y: false },
}: RichTableProps) => {
  const [isTableHidden, setIsTableHidden] = useState(false);

  const handleShowHide = useCallback(() => setIsTableHidden((isHidden) => !isHidden), []);

  return (
    <>
      {title && (
        <Container onClick={handleShowHide}>
          {title}
          {(
            <ActionContainer>
              <Chevron type={isTableHidden ? 'down' : 'up'} />
            </ActionContainer>
          )}
        </Container>
      )}
      <TableContainer isHidden={isTableHidden}>
        <Table
          showHeader
          rowKey="id"
          dataSource={dataSource}
          columns={columns}
          scroll={scroll}
          pagination={false}
        />
      </TableContainer>
    </>
  );
};

export default RichTable;
