import { useMemo, useCallback } from 'react';
import {
  DownloadFileColumn,
  InteractionType,
  DataSourceBaseEntity,
} from '@ombori/grid-reports';
import { uniq } from 'lodash';
import {
  useDevicesEventsAndSessionsByDay,
  useDeviceListEventsBySpaceAndDay,
} from '../../../../use-analytics-report';
import { useTenantDevices } from '../../../../use-devices';
import UniversalDevice from '../../../../../../store/types/universal-device';
import { DataResidencyEnum } from '../../../../../../store/types/organisation';
import { useSpaces } from '../../../../use-spaces';
import OrganisationSpace from '../../../../../../store/types/organisation-space';
import { getColumnParams, getDeviceTableAndDownloadDataMatrix } from './data-matrix-util';

export type DeviceDataMatrixDataRow = {
  key: string;
  space: OrganisationSpace | undefined;
  device: UniversalDevice;
  columns: Record<string, string | number>;
}

export type DeviceDataMatrixStateData = {
  tableData: DeviceDataMatrixDataRow[];
  downloadData: DeviceDataMatrixDataRow[];
}

export interface DeviceDataMatrixState {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: DeviceDataMatrixStateData | null;
  devices: UniversalDevice[];
}

export interface DeviceDataMatrixDataProps {
  tenantId: string;
  spaceId: string | undefined;
  installationId?: string | undefined;
  dateFrom: string;
  dateTo: string;
  dataResidency: DataResidencyEnum;
  columns: DownloadFileColumn[];
  downloadColumns: DownloadFileColumn[];
}

const useDeviceDataMatrixData = ({
  tenantId,
  spaceId,
  installationId,
  dateFrom,
  dateTo,
  dataResidency,
  columns,
  downloadColumns,
}: DeviceDataMatrixDataProps): DeviceDataMatrixState => {
  const {
    tableColumnsParams,
    downloadColumnsParams,
    eventTypesQueryString,
    inputEventTypesQueryString,
  } = useMemo(() => {
    const tableDataParams = getColumnParams(columns);
    const downloadDataParams = getColumnParams(downloadColumns);

    const eventTypesArray = [
      ...tableDataParams.eventTypesQueryString.split(','),
      ...downloadDataParams.eventTypesQueryString.split(','),
    ];

    const inputEventTypesArray = [
      ...tableDataParams.inputTypeEventsTypes.split(','),
      ...downloadDataParams.inputTypeEventsTypes.split(','),
    ];

    return {
      tableColumnsParams: tableDataParams,
      downloadColumnsParams: downloadDataParams,
      eventTypesQueryString: uniq(eventTypesArray).join(','),
      inputEventTypesQueryString: uniq(inputEventTypesArray).join(','),
    };
  }, [columns, downloadColumns]);

  const devicesEventsAndSessionsState = useDevicesEventsAndSessionsByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
    interactionType: InteractionType.Interactive,
    eventType: eventTypesQueryString,
    isEventsQueryEnabled:
      tableColumnsParams.isEventsQueryEnabled || downloadColumnsParams.isEventsQueryEnabled || (
        (tableColumnsParams.inputTypeColumns && tableColumnsParams.inputTypeColumns.length > 0)
        || (downloadColumnsParams.inputTypeColumns && downloadColumnsParams.inputTypeColumns.length > 0)
      ),
    isSessionsQueryEnabled: tableColumnsParams.isSessionsQueryEnabled || downloadColumnsParams.isSessionsQueryEnabled,
  });

  const spacesState = useSpaces({
    organisationId: tenantId,
    limit: 15000,
  });

  const spaces = useMemo(() => {
    return spacesState.data ? spacesState.data.spaces : [];
  }, [spacesState.data]);

  const devicesSelect = useCallback(
    (devices: UniversalDevice[]) => {
      if (installationId) {
        return devices.filter((device) => device.appIds.includes(installationId));
      }

      if (spaceId) {
        return devices.filter((device) => device.spaces.includes(spaceId));
      }

      return devices;
    },
    [spaceId, installationId],
  );

  const tenantDevicesState = useTenantDevices({
    tenantId,
    select: devicesSelect,
  });

  const tenantDevices = useMemo(() => {
    if (!tenantDevicesState.data) {
      return [];
    }

    return tenantDevicesState.data;
  }, [tenantDevicesState]);

  const deviceAndSpaceDataState = useDeviceListEventsBySpaceAndDay({
    tenantId,
    dateFrom,
    deviceIds: tenantDevices.map((device) => device.uuid),
    dateTo,
    dataResidency,
    interactionType: InteractionType.Interactive,
    eventType: inputEventTypesQueryString,
    dataSource: DataSourceBaseEntity.Space,
  });

  const isLoading = tenantDevicesState.isLoading
    || devicesEventsAndSessionsState.isLoading
    || spacesState.isLoading
    || deviceAndSpaceDataState.isLoading;
  const isSuccess = tenantDevicesState.isSuccess
    && devicesEventsAndSessionsState.isSuccess
    && spacesState.isSuccess
    && deviceAndSpaceDataState.isSuccess;
  const isError = tenantDevicesState.isError
    || devicesEventsAndSessionsState.isError
    || spacesState.isError
    || deviceAndSpaceDataState.isError;

  const deviceDataMatrixData = useMemo(() => {
    if (!isSuccess) {
      return null;
    }
    
    const analyticsDataFallback = {
      sessions: [],
      events: [],
    };

    const deviceTableAndDownloadDataMatrix = getDeviceTableAndDownloadDataMatrix({
      devicesEventsAndSessionsData: devicesEventsAndSessionsState.data || analyticsDataFallback,
      deviceAndSpaceData: deviceAndSpaceDataState.data || [],
      spaces,
      devices: tenantDevicesState.data || [],
      tableColumnsParams,
      downloadColumnsParams,
      spaceId,
    });

    return deviceTableAndDownloadDataMatrix;
  }, [
    isSuccess,
    deviceAndSpaceDataState.data,
    devicesEventsAndSessionsState.data,
    spaces,
    tenantDevicesState.data,
    tableColumnsParams,
    downloadColumnsParams,
    spaceId
  ]);

  return {
    isLoading,
    isSuccess,
    isError,
    devices: tenantDevicesState.data || [],
    data: deviceDataMatrixData,
  };
};

export default useDeviceDataMatrixData;
