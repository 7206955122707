import React from 'react';
import styled from '@emotion/styled';
import { ResponsiveContainer, PieChart, Pie, Legend, Label } from 'recharts';
import { toPairs } from 'lodash';
import { Empty, Tooltip } from 'antd';
import { getColor } from './colors';

export type DataProps = [string, Event[]];

export interface Event {
  label?: string;
  value: number;
  color?: string;
  order?: number;
  percentage?: string;
}

const truncate = (str: string) =>
  str.length > 25 ? `${str.substring(0, 25).trim()}...` : str;

const LegendItemName = styled.span`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
`;

const LegendItemPercent = styled.span`
  display: inline-block;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  padding-left: 8px;
`;

const LegendItemValue = styled.span`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  position: absolute;
  text-align: right;
  right: 0;
`;

const Footer = styled.div`
  border-top: 1px solid rgb(232, 232, 232);
  display: flex;
  flex-wrap: wrap;
  padding: 8px 0;
  margin: 60px 0 0;
  justify-content: center;
`;

const FooterItems = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 8px;
  justify-content: center;
`;

const FooterItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FooterImg = styled.img`
  height: 24px;
`;

const ItemsCount = styled.p`
  padding-top: 10px;
`;

const isValidImageUrl = (url: string) => {
  const pattern = /^(http|https):\/\/.*(\.(jpg|jpeg|png|gif|svg|bmp))?$/i;
  return pattern.test(url);
};

const legendFormatter = (name: string, entry: any) => {
  const {
    payload: { value, percentage },
  } = entry;

  return (
    <>
      <Tooltip placement="top" title={name}>
        <LegendItemName>{truncate(name)}</LegendItemName>
      </Tooltip>
      {percentage && <LegendItemPercent>{percentage}</LegendItemPercent>}
      {!percentage && <LegendItemPercent>0%</LegendItemPercent>}
      <LegendItemValue>{value}</LegendItemValue>
    </>
  );
};

export const renderFooterItems = (data: DataProps[]) => {
  return (
    <>
      {data.map(([title, items]) => (
        <Tooltip placement="top" title={title} key={title}>
          <FooterItems>
            {items.map((item) => (
              <FooterItem key={title}>
                {item.label && isValidImageUrl(item.label) ? (
                  <FooterImg src={item.label} alt={title} />
                ) : (
                  <p>{title}</p>
                )}
                <ItemsCount>{item.value.toLocaleString()}</ItemsCount>
              </FooterItem>
            ))}
          </FooterItems>
        </Tooltip>
      ))}
    </>
  );
};

export default function PieCard({
  data,
  showSuccessRate = true,
  successRate,
  footerData,
}: {
  data: DataProps[];
  showSuccessRate?: boolean;
  successRate?: string;
  footerData?: DataProps[];
}) {
  const pieChartData = toPairs(
    data.reduce((acc: any, [, events]) => {
      events.forEach(({ label, value, color, percentage }: Event) => {
        if (label) {
          acc[label] = {
            value: (acc[label] ? acc[label].value : 0) + value,
            color: color,
            percentage: percentage,
          };
        }
      });

      return acc;
    }, {}),
  )
    .sort(([, a]: any, [, b]: any) => b.order - a.order)
    .map(([name, { value, color, percentage }]: any, i, arr) => ({
      name,
      value,
      percentage,
      fill: color ? color : getColor(i, arr.length),
    }));

    const totalCount = data.reduce((total, item) => {
      return total + item[1].map(obj => obj.value).reduce((a, b) => a + b, 0);
    }, 0);
    

  return (
    <>
      {!!totalCount && <ResponsiveContainer height={224}>
        <PieChart>
          <Legend
            layout="vertical"
            align="center"
            verticalAlign="middle"
            wrapperStyle={{
              position: 'relative',
              maxHeight: '100%',
              overflowY: 'auto',
              width: '50%',
              left: '25%',
              top: '10px',
            }}
            iconType="circle"
            iconSize={8}
            formatter={legendFormatter}
          />
          <Pie
            data={pieChartData}
            dataKey="value"
            nameKey="name"
            startAngle={450}
            endAngle={90}
            strokeWidth={1}
            innerRadius={showSuccessRate ? 80 : 0}
            outerRadius={112}
          >
            {showSuccessRate && [
              <Label
                key={'value'}
                value={successRate}
                offset={0}
                position="center"
                fontSize={24}
                fill="rgba(0,0,0,0.85)"
                transform="translate(0 0)"
              />,
            ]}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
}
      {!totalCount &&  <Empty></Empty> }
      {!!(footerData && footerData.length) && (
        <Footer>{renderFooterItems(footerData)}</Footer>
      )}
    </>
  );
}
