import * as React from 'react';
import SetupDeviceUniversalComponent from './setup-device-universal.component';
import { DeviceOrPlatformTypeEnum } from './setup-device-universal-form/setup-device-universal-form.component';
import { useStoreActions, useStoreState } from '../../../../../../store/initialize-store';
import { CreateDeviceModel } from './setup-device-universal.types';
import DeviceTypeEnum from '../../../../../../store/types/device-type.enum';
import { useActiveTenantAllSpaces } from '../../../../../common/spaces-provider/spaces-provider.component';

type DevicesContainerProps = Omit<
  React.ComponentProps<typeof SetupDeviceUniversalComponent>,
  'fetchEnvironments' | 'createDevice' | 'environments' | 'app' | 'spaces' | 'fetchSpaces' | 'user'
>;

const ConnectDeviceUniversalContainer = (props: DevicesContainerProps) => {
  const {
    match: {
      params: { organisationId, appId },
    },
  } = props;

  const { app, environments, user } = useStoreState(
    (state) => ({
      app:
        (state.organisationApps.data &&
          state.organisationApps.data[organisationId] &&
          state.organisationApps.data[organisationId][appId]) ||
        null,
      environments: state.environments.values(organisationId),
      user: state.auth.user,
    }),
    [organisationId],
  );

  const { spaces } = useActiveTenantAllSpaces();

  const { createDevice, fetchEnvironments, fetchSpaces, fetchDevicesV3 } = useStoreActions((actions) => ({
    createDevice: actions.appDevicesUniversal.create,
    fetchEnvironments: () => {
      return actions.environments.fetch({ organizationId: organisationId });
    },
    fetchSpaces: () => {
      return actions.organisationSpaces.fetch({ organizationId: organisationId, silent: true });
    },
    fetchDevicesV3: actions.appV3DevicesUniversal.fetch,
  }));

  const handleCreateDevice = React.useCallback(
    async (params: CreateDeviceModel) => {
      if (app == null) {
        throw new Error('App cannot be null');
      }
      
      const device = await createDevice({
        ...params,
        type: [DeviceOrPlatformTypeEnum.INTEL, DeviceOrPlatformTypeEnum.GIADA_DN74].includes(
          params.type as DeviceOrPlatformTypeEnum,
        )
          ? 'iotedge'
          : params.type,
        appId: app.id,
        appName: app.appName,
      });

      if (app.deviceType === DeviceTypeEnum.IOT) {
        await fetchDevicesV3({ appName: app.appName, silent: true });
      }

      return device;
    },
    [app, createDevice, fetchDevicesV3],
  );

  return (
    <SetupDeviceUniversalComponent
      {...props}
      user={user}
      fetchEnvironments={fetchEnvironments}
      fetchSpaces={fetchSpaces}
      createDevice={handleCreateDevice}
      environments={environments}
      spaces={spaces}
      app={app}
    />
  );
};

export default ConnectDeviceUniversalContainer;
