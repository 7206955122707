import React, { useMemo } from 'react';
import { Row, Col, Select } from 'antd';
import { FieldProps } from 'react-jsonschema-form';
import useChannelsList from '../../../../../store/hooks/channels/use-channels-list';
import { InputWrap, Option } from '../../../react-final-form';
import { extractFieldError } from '../../error-messages';

interface ContentChannelPickerFormData {
  id: string;
  ref: 'channel',
}

interface ContentChannelPickerProps extends FieldProps {}

const ContentChannelPicker = (props: ContentChannelPickerProps) => {
  const { onChange: updateFormField, errorSchema, schema, formData, formContext } = props;

  const { id } = (formData || {}) as ContentChannelPickerFormData;

  const { organisationId: tenantId } = formContext;

  const { data: channelsList } = useChannelsList(tenantId);

  const channelOptions = useMemo<Option[]>(() => {
    if (!channelsList) {
      return [];
    }

    return channelsList.docs.map((channel) => {
      return { label: channel.name, value: channel.id };
    });
  }, [channelsList]);

  return (
    <>
      <label className="control-label">{schema.title}</label>

      <Row gutter={[20, 20]}>
        <Col span={24}>
          <InputWrap error={extractFieldError(errorSchema, 'id')}>
            <Select
              value={id}
              allowClear={true}
              showSearch={true}
              optionFilterProp="children"
              loading={channelOptions.length ? false : true}
              onChange={(value) => {
                const data: ContentChannelPickerFormData = {
                  id: value,
                  ref: 'channel',
                }
                updateFormField(data);
              }}
            >
              {channelOptions.map((channelOption) => {
                return (
                  <Select.Option value={channelOption.value} key={channelOption.value}>
                    {channelOption.label}
                  </Select.Option>
                );
              })}
            </Select>
          </InputWrap>
        </Col>
      </Row>
    </>
  );
};

export default ContentChannelPicker;
