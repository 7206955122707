import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import InvoicesList from './invoices-list.component';
import { RootModel, RootState } from '../../../../../store/models/root.model';

type InvoicesListContainerProps = Omit<
  React.ComponentProps<typeof InvoicesList>,
  'paginatedInvoices' | 'loading' | 'error' | 'fetchInvoices'
>;

const InvoicesListContainer = (props: InvoicesListContainerProps) => {
  const { match } = props;
  const { organisationId } = match.params;

  const { paginatedInvoices, loading, error } = useStore<RootState>(
    (state) => ({
      paginatedInvoices: state.organisationInvoices.data[organisationId],
      loading: state.organisationInvoices.loading[organisationId],
      error: state.organisationInvoices.error[organisationId],
    }),
    [organisationId],
  );

  const fetchInvoices = useActions<RootModel>(
    (actions) => actions.organisationInvoices.fetch,
  );

  return (
    <InvoicesList
      {...props}
      fetchInvoices={fetchInvoices}
      paginatedInvoices={paginatedInvoices}
      loading={loading}
      error={error}
    />
  );
};

export default InvoicesListContainer;
