import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Button, Card, Col, Dropdown, Icon, Row, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import PanelCard from '../../panel-card/panel-card.component';
import DeviceStatusEnum from '../../../../store/types/device-status.enum';
import DeviceStatus from '../../device-status/device-status.component';
import getDeviceInfo from '../../../../utils/get-device-info';
import UniversalDevice from '../../../../store/types/universal-device';
import LegacyWindowsDevice from '../../../../store/types/legacy-windows-device';
import DeviceScreenshot from '../../device-screenshot/device-screenshot.component';
import DeviceTypeIcon from '../devices-universal-list-item-icon.component/devices-universal-list-item-icon.component';

interface DevicesListItemProps {
  device: UniversalDevice | LegacyWindowsDevice;
  lastUpdated?: Date;
  renderExtraActions?: (deviceId: string) => JSX.Element;
  isDisabled?: boolean;
}

const DevicesUniversalListItem = ({
  device,
  lastUpdated,
  renderExtraActions,
  isDisabled,
}: DevicesListItemProps) => {
  const { t } = useTranslation();

  const deviceInfo = useMemo(() => getDeviceInfo(device), [device]);

  return (
    <DeviceCard
      status={device.status}
      cover={
        <DeviceCover
          renderExtraActions={renderExtraActions}
          device={device}
          isDisabled={isDisabled}
        />
      }
      disabled={isDisabled}
    >
      <Card.Meta
        title={
          <DeviceTitle>
            {device.deviceName}
            <Hostname>
              <Row>
                <Col>
                  {device.deviceMonitored && (
                    <Icon type="eye" style={{ marginRight: '4px' }} />
                  )}
                  {device.parentDevice && device.parentDevice.type && (
                    <DeviceTypeIcon deviceType={device.parentDevice.type} />
                  )}
                  {deviceInfo.hostname}
                </Col>
              </Row>
            </Hostname>
          </DeviceTitle>
        }
        description={
          <DeviceDescription>
            <DeviceStatus device={device} lastUpdated={lastUpdated} />
            {device.properties &&
              device.properties.desired &&
              device.properties.desired.env && (
                <DeviceEnv title={t('environment')}>
                  <EnvIcon type="cloud" /> {device.properties.desired.env}
                </DeviceEnv>
              )}
          </DeviceDescription>
        }
      />
    </DeviceCard>
  );
};

const DeviceCover = ({
  device,
  renderExtraActions,
  isDisabled,
}: {
  device: UniversalDevice | LegacyWindowsDevice;
  renderExtraActions?: (deviceId: string) => JSX.Element;
  isDisabled?: boolean;
}) => {
  const deviceInfo = useMemo(() => getDeviceInfo(device), [device]);

  return (
    <DeviceCoverContainer>
      {device.status !== DeviceStatusEnum.OFFLINE && (
        <DeviceInfo>
          {deviceInfo.cpuUsage && (
            <Tag color={getTagColor(deviceInfo.cpuUsage)}>
              {`CPU: ${deviceInfo.cpuUsage}%`}
            </Tag>
          )}
          {deviceInfo.memUsage && (
            <Tag color={getTagColor(deviceInfo.memUsage)}>
              {`MEM: ${deviceInfo.memUsage}%`}
            </Tag>
          )}
        </DeviceInfo>
      )}
      {renderExtraActions && (
        <DeviceExtraActions>
          <Dropdown overlay={renderExtraActions(device.id)} trigger={['click']}>
            <DeviceExtraActionsButton icon="ellipsis" />
          </Dropdown>
        </DeviceExtraActions>
      )}
      <DeviceListItemScreenshot device={device} disabled={isDisabled || false} />
    </DeviceCoverContainer>
  );
};

const getTagColor = (percentage: number): string => {
  if (percentage > 90) {
    return 'red';
  }
  if (percentage > 85) {
    return 'volcano';
  }
  if (percentage > 80) {
    return 'orange';
  }
  if (percentage > 75) {
    return 'gold';
  }
  if (percentage > 70) {
    return 'lime';
  }
  if (percentage > 65) {
    return 'green';
  }
  if (percentage > 60) {
    return 'cyan';
  }

  return 'blue';
};

const DeviceListItemScreenshot = styled(DeviceScreenshot) <{ disabled: boolean }>`
  height: 192px;
  border-radius: 7px 7px 0 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  ${({ disabled }) => (disabled ? `background-color: #f2f2f2;` : ``)}
`;

// eslint-disable-next-line no-unexpected-multiline
const DeviceCard = styled(PanelCard) <{
  status: DeviceStatusEnum;
  disabled?: boolean;
}>`
  margin: 8px;
  .ant-card-cover img {
    border-radius: 0;
  }

  .ant-card-actions {
    border-radius: 0 0 7px 7px;
  }

  cursor: pointer;

  ${({ disabled }) =>
    disabled
      ? `
    .ant-card-body {
      background-color: #f2f2f2;
    }
    .ant-card-meta-title, .ant-badge-status-text {
      color: #a2a2a2;
      filter: grayscale(1);
    }
    cursor: not-allowed;
    `
      : ``}
`;

const DeviceDescription = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DeviceEnv = styled.div`
  margin-left: 8px;
  text-transform: uppercase;
  white-space: nowrap;
`;

const EnvIcon = styled(Icon)`
  font-size: 16px;
`;

const DeviceCoverContainer = styled.div`
  position: relative;
`;

const DeviceInfo = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
  right: 24px;
  z-index: 1;
  pointer-events: none;
`;

const DeviceExtraActions = styled.div`
  top: 24px;
  right: 24px;
  z-index: 1;
  position: absolute;
`;

const DeviceExtraActionsButton = styled(Button)`
  height: 22px;
` as any;

const DeviceTitle = styled.div`
  height: 45px;
`;

const Hostname = styled.div`
  font-size: 14px;
  white-space: nowrap;
  margin-top: 2px;
  font-weight: 400;
`;

export default DevicesUniversalListItem;
