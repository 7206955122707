import React, { useCallback, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SchemaForm from '../../../../common/schema-form/schema-form.component';
import useSchemaForm from '../../../../common/use-schema-form/use-schema-form';
import { buildFieldSchema, buildUiSchema } from './build-schema';
import { TenantSettings, ButtonsTypes } from '../../../../../store/types/branding';

type UserInterfaceProps = RouteComponentProps<{ organisationId: string }> & {
  onFormChangeHandler: (data: Partial<TenantSettings>) => void;
  buttonsSettings: Partial<ButtonsTypes>;
};

const UserInterface = (props: UserInterfaceProps) => {
  const { match, buttonsSettings, onFormChangeHandler } = props;
  const {
    params: { organisationId: tenantId },
  } = match;

  const { t } = useTranslation();

  const { fieldSchema, uiSchema } = useMemo(() => {
    return {
      fieldSchema: buildFieldSchema(t),
      uiSchema: buildUiSchema(t),
    };
  }, [t]);

  const [, , , formInit, handleFormChange, handleSubmittedForm] = useSchemaForm(
    async (data) => {},
    t('brandingUserInterface.successSavingData'),
    t('brandingUserInterface.errorSavingData'),
  );

  useEffect(() => {
    if (buttonsSettings) {
      formInit(buttonsSettings);
    }
  }, [formInit, buttonsSettings]);

  const handleFormDataChange = useCallback(
    (formDataChange: TenantSettings) => {
      onFormChangeHandler(formDataChange);
      handleFormChange(formDataChange);
    },
    [onFormChangeHandler, handleFormChange],
  );

  return (
    <SchemaForm
      organisationId={tenantId}
      schema={fieldSchema}
      uiSchema={uiSchema}
      data={buttonsSettings}
      onSubmit={handleSubmittedForm}
      onChange={handleFormDataChange}
    />
  );
};

export default UserInterface;
