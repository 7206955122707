import React, { useCallback } from 'react';
import { Field } from 'react-final-form';
import styled from '@emotion/styled';
import { Select as AntdSelect } from 'antd';
import {
  OptionProps,
  SelectProps as AntdSelectProps,
  LabeledValue,
} from 'antd/lib/select';
import InputWrap from './input-wrap';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, SelectType } from '../../../types';
import { requiredAll } from '../../organisations/organisation-details/queue-details/queue-setup/validate-form';
import { Validator, composeValidators } from './validator';

interface OptionPropsExt extends OptionProps {
  ['data-label']?: string;
}

type OptionValue = React.ReactElement<
  OptionPropsExt,
  string | React.JSXElementConstructor<any>
>;

export interface MultiSelectOption {
  value: string;
  label?: string;
}

export interface MultiSelectProps extends AntdSelectProps<LabeledValue[]> {
  name?: string;
  label?: string;
  isRequired?: boolean;
  options?: MultiSelectOption[];
  validators?: Validator[];
  dataTestId?: string;
}

const MultiSelect = (props: MultiSelectProps) => {
  const {
    name = '',
    label,
    isRequired = false,
    options = [],
    className,
    onChange = () => {},
    disabled: isDisabled,
    defaultValue,
    validators = [],
    dataTestId,
    ...restProps
  } = props;

  const { t } = useTranslation();

  const inputValidators = [...(isRequired ? [requiredAll] : []), ...validators];

  const handleFilterOption = useCallback(
    (inputValue: string, option: OptionValue): boolean => {
      const optionLabel = option.props['data-label'];
      return (
        !!optionLabel && optionLabel.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
      );
    },
    [],
  );

  return (
    <Field name={name} validate={composeValidators(inputValidators)}>
      {({ input, meta }) => {
        const isError = !!meta.error && meta.touched;

        const ids = (input.value || []) as string[];

        const filteredOptions = options.filter(
          (option) => !ids.includes(option.value),
        );

        const labeledIds = options.filter((option) => ids.includes(option.value)).map((option) => ({ key: option.value, label: option.label }));

        return (
          <InputWrap
            className={className}
            label={label}
            isRequired={isRequired}
            error={isError ? meta.error : undefined}
          >
            <SelectStyled
              {...restProps}
              mode="multiple"
              data-name={input.name}
              className={className}
              showSearch={!isDisabled}
              showArrow={!isDisabled}
              placeholder={t('finalFormField.select')}
              value={labeledIds.length ? labeledIds : defaultValue}
              onChange={(selectedValues, option) => {
                const ids = selectedValues.map((value) => value.key);

                input.onChange(({
                  target: { value: ids },
                } as unknown) as ChangeEvent<LabeledValue[]>);

                onChange(selectedValues, option);
              }}
              disabled={isDisabled}
              optionFilterProp="children"
              filterOption={handleFilterOption}
              labelInValue
              data-testid={dataTestId}
            >
              {filteredOptions.map((option) => {
                const { value, label } = option;

                return (
                  <AntdSelect.Option key={value} value={value} data-label={label}>
                    {label || value}
                  </AntdSelect.Option>
                );
              })}
            </SelectStyled>
          </InputWrap>
        );
      }}
    </Field>
  );
};

const SelectStyled = styled(AntdSelect)`
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
` as SelectType<LabeledValue[]>;

export default MultiSelect;
