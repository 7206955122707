import {
  DataResidencyEnum,
  GridProduct,
  GridProductServiceClient,
  IsoLanguageIds,
} from '@ombori/grid-products';
import { useQuery, UseQueryResult } from 'react-query';

interface UseProductProps {
  tenantId: string;
  dataResidency: DataResidencyEnum;
  env: string;
  productGroupId: string;
  enabled: boolean;
}

const useProduct = ({
  tenantId,
  dataResidency,
  env,
  productGroupId = '',
  enabled,
}: UseProductProps): UseQueryResult<Partial<GridProduct>, unknown> => {
  const gridProductsClient = new GridProductServiceClient({
    dataResidency: dataResidency as DataResidencyEnum,
    environment: env,
    tenantId,
    locale: IsoLanguageIds.en_GB
  });

  return useQuery(
    ['productGroupId', productGroupId, tenantId, dataResidency, env, enabled],
    () =>
      gridProductsClient.getProductById(productGroupId, {
        select:
          'productGroupId,variants,catalogPageLocationProduct,productPriceList,productName',
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      staleTime: 600000, // 10 min stale time
      enabled,
    },
  );
};

export default useProduct;
