import { Button, message, Typography, Popconfirm } from 'antd';
import { RouteComponentProps } from 'react-router';
import React, { useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import Gridapp, { GridappAccess } from '../../../../../store/types/gridapp';
import SchemaForm, {
  SchemaFormRef,
} from '../../../schema-form/schema-form.component';
import useSchemaForm from '../../../use-schema-form/use-schema-form';
import PanelCard from '../../../panel-card/panel-card.component';
import usePermissions from '../../../../../utils/auth/use-permissions';
import { permissionKeys } from '../../../../../utils/auth/permissions';
import { Sku } from '../../../use-skus';
import MultiSelect from '../../../schema-form/widgets/multi-select/multi-select.component';
import Organisation from '../../../../../store/types/organisation';

interface GridappSettingsProps
  extends RouteComponentProps<{ gridappId: string; organisationId: string }> {
  isSysAdmin: boolean;
  gridapp: Gridapp | null;
  updateGridapp: (app: Partial<Gridapp>) => Promise<void>;
  deleteGridapp: () => Promise<void>;
  skus: Sku[];
  organisations: Organisation[];
}

const GridappSettings = (props: GridappSettingsProps) => {
  const { t } = useTranslation();
  const { gridapp, updateGridapp, deleteGridapp } = props;
  const {
    match: {
      params: { organisationId },
    },
    isSysAdmin,
    skus,
    organisations,
  } = props;

  const formElement = useRef<SchemaFormRef>(null);
  const { isAllowed } = usePermissions(organisationId);

  const handleSave = () => {
    if (formElement.current) {
      formElement.current.submit();
    }
  };

  const handleSubmit = async (gridappSettings: any) => {
    if (gridapp) {
      await updateGridapp({
        id: gridapp.id,
        ...gridappSettings,
      });
    }
  };

  const handleError = () => {
    message.error(t('thereAreErrorsInTheSettingsForm'));
  };

  const [
    formData,
    formLoading,
    formDirty,
    formInit,
    handleFormChange,
    handleFormSubmit,
  ] = useSchemaForm(handleSubmit, t('appSettingsSaved'), t('appSettingsSaveError'));

  useEffect(() => {
    if (gridapp) {
      formInit({
        displayName: gridapp.displayName,
        packageName: gridapp.packageName,
        description: gridapp.description,
        access: gridapp.access,
        author: gridapp.author,
        sku: gridapp.sku,
        organizationsAccess: gridapp.organizationsAccess,
      });
    }
  }, [gridapp, formInit]);

  const { formSchema, metaSchema } = useMemo(() => {
    let specialInitialAccessEnum: GridappAccess[] = [];
    let specialInitialAccessEnumName: string[] = [];
    let isAccessReadOnly = false;

    if (!isSysAdmin && gridapp && gridapp.access) {
      switch(gridapp.access) {
        case GridappAccess.PUBLIC:
          specialInitialAccessEnum = [GridappAccess.PUBLIC];
          specialInitialAccessEnumName = [t('appAccessPublic')];
          isAccessReadOnly = true;
          break;
        case GridappAccess.SPECIFIC_TENANTS:
          specialInitialAccessEnum = [GridappAccess.SPECIFIC_TENANTS];
          specialInitialAccessEnumName = [t('appAccessSpecificTenants')];
          isAccessReadOnly = true;
          break;
      }
    }

    const curSchema = {
      type: 'object',
      title: '',
      properties: {
        displayName: {
          type: 'string',
          title: t('displayName'),
        },
        author: {
          type: 'string',
          title: t('author'),
        },
        packageName: {
          type: 'string',
          title: t('packageName'),
          pattern: '^([a-z][a-z0-9]*)(-[a-z0-9]+)*$',
          description: t('mustBeInSmallLettersAndKebabCase'),
        },
        description: {
          type: 'string',
          title: t('description'),
        },
        ...(isSysAdmin ? {
          sku: {
            type: 'string',
            title: t('sku'),
            enum: skus.map(sku => sku.sku),
            enumNames: skus.map(sku => `${sku.sku} (${sku.name})`),
          },
        } : {}),
        access: {
          type: 'string',
          title: t('appAccess'),
          enum: [
            GridappAccess.PRIVATE,
            GridappAccess.SUBTENANTS,
            ...(isSysAdmin
              ? [GridappAccess.PUBLIC, GridappAccess.SPECIFIC_TENANTS]
              : specialInitialAccessEnum),
          ],
          enumNames: [
            t('appAccessPrivate'),
            t('appAccessSubtenants'),
            ...(isSysAdmin ? [t('appAccessPublic'), t('appAccessSpecificTenants')]
            : specialInitialAccessEnumName),
          ],
          readOnly: isAccessReadOnly,
          default: GridappAccess.PRIVATE,
        },
      },
      dependencies: {
        access: {
          oneOf: [
            {
              properties: {
                access: {
                  enum: [GridappAccess.SPECIFIC_TENANTS],
                },
                organizationsAccess: {
                  title: t('selectTenantsToShowTheApp'),
                  type: 'array',
                  uniqueItems: true,
                  minItems: 1,
                  items: {
                    type: 'string',
                    enum: organisations.map((org) => org.id),
                    enumNames: organisations.map(
                      (org) => org.displayName,
                    ),
                  },
                  readOnly: !isSysAdmin,
                },
              },
              required: isSysAdmin ? ['organizationsAccess'] : [],
            },
            {
              properties: {
                access: {
                  enum: [
                    GridappAccess.PRIVATE,
                    GridappAccess.SUBTENANTS,
                    GridappAccess.PUBLIC,
                  ],
                },
              },
            },
          ],
        },
      },
      required: ['displayName', 'packageName', 'access', ...(isSysAdmin ? ['sku'] : [])],
    };

    const curMetaSchema = {
      organizationsAccess: {
        'ui:field': MultiSelect,
      },
      description: {
        'ui:widget': 'textarea',
      },
    }

    return {
      formSchema: curSchema,
      metaSchema: curMetaSchema
    };
  }, [isSysAdmin, skus, t, organisations, gridapp]);

  if (!gridapp) {
    return null;
  }

  return (
    <PanelCard bodyStyle={{ padding: 16 }}>
      <Header>
        <Typography.Title level={3}>{gridapp.displayName}</Typography.Title>
        <Typography.Paragraph>{t(gridapp.type)}</Typography.Paragraph>
      </Header>
      <SchemaForm
        ref={formElement}
        uiSchema={metaSchema}
        data={formData}
        schema={formSchema}
        organisationId={organisationId}
        onSubmit={handleFormSubmit}
        onChange={handleFormChange}
        onError={handleError}
      />
      <ButtonsContainer>
        {isAllowed(permissionKeys.gridApps.remove) && (
          <Popconfirm
            title={t('areYouSureYouWantToDeleteThisApplication')}
            onConfirm={deleteGridapp}
            okText={t('yes')}
            cancelText={t('no')}
          >
            <Button type="danger" loading={formLoading} disabled={formDirty}>
              {t('appDelete')}
            </Button>
          </Popconfirm>
        )}

        <Fill />

        {isAllowed(permissionKeys.gridApps.update) && (
          <Button
            loading={formLoading}
            type="primary"
            onClick={handleSave}
            disabled={!formDirty}
          >
            {t('saveAllChanges')}
          </Button>
        )}
      </ButtonsContainer>
    </PanelCard>
  );
};

const Fill = styled.div`
  flex: 1;
`;

const Header = styled.div`
  padding: 0 0 16px 0;
`;

const ButtonsContainer = styled.div`
  padding: 16px 0 0 0;
  display: flex;
  flex-direction: row;
`;

export default GridappSettings;
