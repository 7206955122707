import React, { useCallback, useRef } from 'react';
import { Input, Modal } from 'antd';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const ModalContent = styled.div`
  margin-top: 20px;
`;

const Slug = styled.div`
  > span {
    top: 9px;
    left: 5px;
    position: relative;
    z-index: 1;
    background-color: white;
    padding: 0 5px;
    color: rgba(0, 0, 0, 0.54);
    font-size: smaller;
  }
  &:hover {
    > span {
      color: #2364aa;
    }
  }
`;

const useDeleteModal = () => {
  const slugInputRef = useRef<Input>(null);
  const { t } = useTranslation();

  const showDeleteModal = useCallback(
    (
      titleText: string,
      descriptionText: string,
      confirmInputText: string = '',
      onConfirm: () => Promise<void>,
    ) => {
      const modal = Modal.confirm({
        title: titleText,
        content: (
          <ModalContent>
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: descriptionText }} />
            {confirmInputText && ( 
              <Slug>
                <span>{t('deleteModalConfirmInputLabel')}</span>
                <Input
                  id='deleteModalInput'
                  ref={slugInputRef}
                  onChange={(e) => {
                    modal.update({
                      // Replacing all non-alphanumeric characters with empty string to allow for non-english characters in comparison
                      okButtonProps: {
                        disabled: e.target.value.replace(/[^A-Za-z0-9]+/g, '') !== confirmInputText.replace(/[^A-Za-z0-9]+/g, ''),
                      },
                    });
                  }}
                />
              </Slug>
            )}
          </ModalContent>
        ),
        width: '30%',
        okText: t('deleteModalConfirmAction'),
        cancelText: t('cancel'),
        okButtonProps: { disabled: !!confirmInputText, name: 'deleteModalOkButton' },
        className: 'modal-content-m-0',
        onOk: async () => {
          modal.update({ cancelButtonProps: { disabled: true } });
          try {
            await onConfirm();
          } finally {
            modal.update({ cancelButtonProps: { disabled: false } });
          }
        },
        onCancel: () => {},
      });
      return modal;
    },
    [t],
  );

  return [showDeleteModal];
};

export default useDeleteModal;
