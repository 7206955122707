/* eslint-disable react/no-danger */
import { Typography, Col, Row, List, Icon } from 'antd';
import styled from '@emotion/styled';
import { RouteComponentProps } from 'react-router';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import OrganisationApp from '../../../../store/types/organisation-app';
import Organisation from '../../../../store/types/organisation';
import OrganisationSpace from '../../../../store/types/organisation-space';
import PanelCard from '../../../common/panel-card/panel-card.component';
import ContentWrap from '../../../common/app-layout/content/content-wrap.component';

const { Title } = Typography;

interface WebSignalsSettingsProps
  extends RouteComponentProps<{ appId: string; organisationId: string }> {
  app: OrganisationApp;
  organisation: Organisation | null;
  spaces: OrganisationSpace[];
  loaded: boolean;
  fetchSpaces: (params: { organizationId: string; silent?: boolean }) => void;
}

const getEmbeddedCode = (
  organisation: Organisation,
  app: OrganisationApp,
  defaultSpace: OrganisationSpace,
) => `
  <script async src="https://app.omborigrid.com/web-signals/signal.js"></script>
  <script>
    window.gridDataLayer = window.gridDataLayer || [];
    function gridSignal(){gridDataLayer.push(arguments);}
    gridSignal('init', 'tenantId', '${organisation.id}');
    gridSignal('init', 'installationId', '${app.id}');
    gridSignal('init', 'spaceId', '${defaultSpace.id}');
    gridSignal('init', 'dataResidency', '${organisation.dataResidency}');
    gridSignal('init', 'country', '${organisation.country}');
  </script>
`;

const Settings = (props: WebSignalsSettingsProps) => {
  const { app, organisation, fetchSpaces, spaces, loaded } = props;
  const {
    match: {
      params: { organisationId },
    },
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    fetchSpaces({ organizationId: organisationId, silent: true });
  }, [organisationId, fetchSpaces]);

  const code = useMemo(() => {
    const defaultSpace = loaded ? spaces[0] : null;
    if (organisation && app && defaultSpace) {
      return getEmbeddedCode(organisation, app, defaultSpace);
    }

    return '';
  }, [app, loaded, organisation, spaces]);

  if (!app || !loaded) {
    return <div>Loading...</div>;
  }

  return (
    <ContentWrap>
      <Row gutter={40}>
        <Col md={24} xl={16}>
          <Documenation itemLayout="horizontal">
            <a
              href="https://developer.omborigrid.com/#/grid-signals/web-signals"
              target="_blank"
              rel="noopener noreferrer"
            >
              <DeveloperSlack>
                {t('Web Signals Documenation')}
                <IconRight type="right" />
              </DeveloperSlack>
            </a>
          </Documenation>
          <PanelCard
            title={<Title level={3}>{t('Embedded Script')}</Title>}
            bodyStyle={{ padding: 24 }}
          >
            <TextArea value={code} readOnly />
          </PanelCard>
        </Col>
      </Row>
    </ContentWrap>
  );
};

const TextArea = styled.textarea`
  width: 100%;
  height: 254px;
  box-shadow: 0 8px 24px 0 rgb(0 0 0 / 4%);
  border: 1px solid rgba(0, 0, 0, 0.3);
`;

const DeveloperSlack = styled.div`
  color: rgba(0, 0, 0, 0.65);
  padding-bottom: 15px;
  padding-left: 15px;
  padding-top: 15px;
  display: flex;
  flex-direction: row;
`;

const Documenation = styled(List)`
  background: #fff;
  margin-bottom: 12px;
  font-size: 16px;
  box-shadow: 0 8px 24px 0 rgb(0 0 0 / 4%);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const IconRight = styled(Icon)`
  position: absolute;
  right: 15px;
  color: #0064b6;
  top: 20px;
`;

export default Settings;
