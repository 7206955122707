import { FieldProps } from "react-jsonschema-form";

// TODO: use intl with these as well
const errorMessages: { [key: string]: string } = {
  required: 'Value is required.',
  enum: 'Invalid value.',
  type: 'Invalid data.',
  minLength: 'Value is too short.',
  maxLength: 'Value is too long.',
};

export default errorMessages;

export const extractFieldError = (
  errorSchema: FieldProps['errorSchema'],
  fieldName: string,
): string | null => {
  if (!errorSchema || !errorSchema[fieldName]) {
    return null;
  }

  const { __errors } = errorSchema[fieldName] as unknown as { __errors: string[] };
  return __errors && __errors.length ? __errors[0] : null;
};
