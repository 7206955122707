import React, { useCallback, useEffect, useMemo } from 'react';
import { keyBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import CrudList from '../../../common/crud-list/crud-list.component';
import Header from '../../../common/app-layout/header/header.component';
import UserRole, {
  UserRoleFormData,
  UserRolePermissionsMatrix,
} from '../../../../store/types/user-roles';
import {
  generateUserRolePermissionSchema,
  getUserRoleFromFormData,
  getUserRolesDefaultFormValues,
} from '../../../../utils/user-roles/user-roles';
import MultiSelect from '../../../common/schema-form/widgets/multi-select/multi-select.component';
import UserRolesListItem from './user-roles-item.component';

interface UserRolesListProps extends RouteComponentProps<{ organisationId: string }> {
  userRolePermissionsMatrix: UserRolePermissionsMatrix;
  userRoles: UserRole[];
  loaded: boolean;
  fetchUserRolePermissionsMatrix: () => void;
  fetchUserRoles: (params: { organizationId: string }) => void;
  createUserRole: (userRole: Partial<UserRole>) => Promise<void>;
  updateUserRole: (userRole: Partial<UserRole>) => Promise<void>;
  deleteUserRole: (id: string) => Promise<void>;
  canCreate: boolean;
  canUpdate: boolean;
  canDelete: boolean;
  conditionalCanUpdate?: (userRole: Partial<UserRoleFormData>) => boolean;
  conditionalCanDelete?: (userRole: Partial<UserRoleFormData>) => boolean;
}

const UserRolesList = (props: UserRolesListProps) => {
  const {
    userRolePermissionsMatrix,
    userRoles,
    loaded,
    fetchUserRolePermissionsMatrix,
    fetchUserRoles,
    createUserRole,
    updateUserRole,
    deleteUserRole,
    canCreate,
    canUpdate,
    canDelete,
    conditionalCanUpdate,
    conditionalCanDelete,
    match: {
      params: { organisationId },
    },
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    fetchUserRolePermissionsMatrix();
  }, [fetchUserRolePermissionsMatrix]);

  useEffect(() => {
    fetchUserRoles({ organizationId: organisationId });
  }, [organisationId, fetchUserRoles]);

  const handleUserRoleDelete = useCallback(
    async (userRole: UserRoleFormData) => {
      await deleteUserRole(userRole.id);
    },
    [deleteUserRole],
  );

  const handleUserRoleEdit = useCallback(
    async (userRoleFormData: Partial<UserRoleFormData>) => {
      await updateUserRole({
        ...getUserRoleFromFormData(userRoleFormData),
        id: userRoleFormData.id,
        organizationId: organisationId,
      });
    },
    [organisationId, updateUserRole],
  );

  const handleUserRoleCreate = useCallback(
    async (userRoleFormData: Partial<UserRoleFormData>) => {
      await createUserRole({
        ...getUserRoleFromFormData(userRoleFormData),
        organizationId: organisationId,
      });
    },
    [createUserRole, organisationId],
  );

  const renderUserRolesListItem = useCallback(
    (userRole: UserRoleFormData) => {
      const currentUserRole = userRoles.find((role) => role.id === userRole.id);

      return currentUserRole ? (
        <UserRolesListItem
          userRoles={keyBy(userRoles, (role) => role.id)}
          userRole={currentUserRole}
          userRolePermissionsMatrix={userRolePermissionsMatrix}
        />
      ) : null;
    },
    [userRolePermissionsMatrix, userRoles],
  );

  const { schema, metaSchema } = useMemo(() => {
    const permissionsSchema = generateUserRolePermissionSchema(userRolePermissionsMatrix);
    const userRolesOptions: Partial<UserRole>[] = userRoles.length
      ? userRoles
      : [{ id: 'none', displayName: 'None' }];

    const userRoleSchema = {
      type: 'object',
      properties: {
        displayName: {
          type: 'string',
          minLength: 1,
          title: t('displayName'),
        },
        manageAllRoles: {
          type: 'boolean',
          title: t('allowToManageAllRoles'),
          default: false,
        },
      },
      required: ['manageAllRoles', 'displayName'],
      dependencies: {
        manageAllRoles: {
          oneOf: [
            {
              properties: {
                manageAllRoles: {
                  enum: [false],
                },
                allowedUserRolesToManage: {
                  title: t('allowedUserRolesToManage'),
                  type: 'array',
                  uniqueItems: true,
                  minItems: 0,
                  items: {
                    type: 'string',
                    enum: userRolesOptions.map((b) => b.id),
                    enumNames: userRolesOptions.map((b) => b.displayName),
                  },
                  default: [],
                },
                permissions: {
                  title: t('permissions'),
                  type: 'object',
                  properties: {
                    ...permissionsSchema,
                  },
                },
              },
            },
            {
              properties: {
                manageAllRoles: {
                  enum: [true],
                },
                permissions: {
                  title: t('permissions'),
                  type: 'object',
                  properties: {
                    ...permissionsSchema,
                  },
                },
              },
            },
          ],
        },
      },
    };

    return {
      schema: userRoleSchema,
      metaSchema: {
        allowedUserRolesToManage: {
          'ui:field': MultiSelect,
        },
      },
    };
  }, [t, userRolePermissionsMatrix, userRoles]);

  const defaultUserRolesFormValues = useMemo(() => {
    return getUserRolesDefaultFormValues(userRoles, userRolePermissionsMatrix);
  }, [userRolePermissionsMatrix, userRoles]);

  return (
    <>
      {!props.match.url.includes('user-management') &&
        <Header title={t('userRoles')} />
      }
      <div className="content-body">
        <CrudList<UserRoleFormData>
          onCreate={handleUserRoleCreate}
          onEdit={handleUserRoleEdit}
          onDelete={handleUserRoleDelete}
          loaded={loaded}
          createSchema={schema}
          metaSchema={metaSchema}
          updateSchema={schema}
          renderItem={renderUserRolesListItem}
          dataSource={defaultUserRolesFormValues}
          createButtonText={t('createUserRole')}
          modalTitle={t('userRole')}
          canCreate={canCreate}
          canUpdate={canUpdate}
          canDelete={canDelete}
          conditionalCanUpdate={conditionalCanUpdate}
          conditionalCanDelete={conditionalCanDelete}
        />
      </div>
    </>
  );
};

export default UserRolesList;
