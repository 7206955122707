import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import get from 'lodash/get';
import {
  GridProduct,
  PriceListTypeEnum,
  ProductStatusEnum,
  IsoLanguageIds,
  getProductName,
  ProductStatus,
  Variant,
  ProductItemQuantity,
  ProductPriceList,
  IsoCurrencyCodes,
} from '@ombori/grid-products';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import arrayMutators from 'final-form-arrays';
import { Field, Form } from 'react-final-form';
import { Button, Popconfirm, Row, Col, Divider, Table as AntdTable, Tabs, Checkbox, message } from 'antd';
import LanguagePicker from '../language-picker';
import prepareDataBeforeSending, {
  getFormattedGridProductsData,
} from './prepare-data-before-sending';
import {
  getDatePicker,
  getExtendableArraySectionWrapper,
  getSimpleInput,
  getSimpleSelect,
  Section,
  HeaderActions,
  SubHeader,
  BackButton,
  DeleteButton,
  StyledPanelCard,
  CardLabel,
  getProductTagsFieldInput,
  getSimpleTagsInput,
  getVariantOptionTagsInput,
  getPriceInput,
} from '../grid-products.components';
import {
  getBestMatchByLanguage,
  getDataForSelectMapper,
  getCurrProductFeaturesGrouping,
  getVariantOptionLabel,
  isMatchingVariantOptions,
  DEFAULT_PRODUCTS_ENVIRONMENT,
} from '../utils';
import routes from '../routes';
import { uniq, omitBy } from 'lodash';
import OrganisationSpace from '../../../../../store/types/organisation-space';
import combinate from 'combinate';
import TabBarComponent from '../../../../common/tab-bar/tab-bar.component';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ChevronDown, ChevronUp } from 'react-feather';
import useGoBack from '../../../../common/use-go-back/use-go-back';
import useQueryParams from '../../../../common/use-query-params/use-query-params';
import { MediaPreview, ProductEnvironmentSelect } from '../common';
import {
  ArrayFields,
  Input,
  LocalizedInput,
  LocalizedTextArea,
  MultiSelect,
  Select,
  getFieldNamesWithError,
  scrollIntoView,
} from '../../../../common/react-final-form';
import { v4 as uuidV4 } from 'uuid';
import Overlay from '../../../../common/overlay/overlay-v2.component';
import { appendQueryParams } from '../../../../../utils/url';
import Message from '../../../../common/message';
import { ApiError } from '../../../../../services/api/api-error';
import {
  useAddGridProduct,
  useDeleteGridProduct,
  useGridProduct,
  useUpdateGridProduct,
} from '../hooks';
import { DataResidencyEnum } from '../../../../../store/types/organisation';
import { useEnvironments } from '../../../../common/use-environments';
import { useProductTypes } from '../../../../common/use-product-type';
import { useSpaces } from '../../../../common/use-spaces';
import { getUniqueCatalogImages } from '../utils';
import transientOptions from '../../../../../utils/transient-emotion-styled-options';
import permissionKeys from '../../../../../utils/auth/permissions';
import usePermissions from '../../../../../utils/auth/use-permissions';

export interface GridProductsDetailsProps
  extends RouteComponentProps<{
    organisationId: string;
    productGroupId: string;
  }> {
  dataResidency: DataResidencyEnum;
}

const defaultLng = IsoLanguageIds.en_US;

type VariantOptionKeys = 'color' | 'size' | 'style';

export type VariantOptionObj = {
  color: string;
  size: string;
  style: string;
};

export type ProductPriceListWithVariantOption = ProductPriceList & VariantOptionObj;

export type ProductItemQuantityWithVariantOption = ProductItemQuantity & VariantOptionObj;

export type ProductFeaturesGrouping = {
  productFeatureType: string;
  valuesByLanguage: {
    isoLanguageId: IsoLanguageIds;
    productFeatureValueGrouped: string; // comma-separated values formatted
  }[];
};

type CustomFormValues = {
  isSamePriceAllLocation: boolean;
  productItemQuantityWithVariantOptions: ProductItemQuantityWithVariantOption[];
  productPriceListAllLocation: ProductPriceListWithVariantOption[];
  productPriceListStandard: ProductPriceListWithVariantOption[];
  productPriceListPromotional: ProductPriceListWithVariantOption[];
  productFeatureGroupings: ProductFeaturesGrouping[];
  variantOptions: {
    size: string[];
    color: string[];
    style: string[];
  };
};

const ALL_LOCATION_SPACE_ID = '';

type GridProductsInitialFormValues = Partial<GridProduct> & CustomFormValues;

export type GridProductsFormValues = GridProduct & CustomFormValues & { id: string };

export type GridProductsFormValuesSubmit = GridProduct &
  Partial<CustomFormValues & { id?: string }>;

export type SetValueType = ([field, value]: any, state: any) => void;

const productStatusValues = Object.keys(ProductStatusEnum)
  .filter((lang) => typeof lang === 'string')
  .map(getDataForSelectMapper);

const allowedMediaTypes = [
  { value: 'image/jpg', label: 'JPG' },
  { value: 'image/jpeg', label: 'JPEG' },
  { value: 'image/png', label: 'PNG' },
  { value: 'image/gif', label: 'GIF' },
  { value: 'image/svg', label: 'SVG' },
  { value: 'video/mp4', label: 'MP4' },
  { value: 'video/mov', label: 'MOV' },
];

const GridProductsDetails = (props: GridProductsDetailsProps) => {
  const { match, history, dataResidency } = props;
  const {
    params: { organisationId: tenantId, productGroupId: productGroupIdRaw },
    url,
  } = match;

  const [inventorySpaceTabKey, setInventorySpaceTabKey] = useState<string>('');
  const [priceSpaceTabKey, setPriceSpaceTabKey] = useState<string>('');
  const [isShowAdvancedOptions, setShowAdvancedOptions] = useState<boolean>(false);

  const { t, i18n } = useTranslation();
  const isNewPageRoute = routes.newProductPath(tenantId).includes(url);
  const { isAllowed } = usePermissions(tenantId);

  const [queryParams, setQueryParams] = useQueryParams({
    env: DEFAULT_PRODUCTS_ENVIRONMENT,
  });
  const { env } = queryParams;

  const handleProductsEnvironmentChange = useCallback(
    (env: string) => setQueryParams({ env }),
    [setQueryParams],
  );

  const productGroupId = !isNewPageRoute ? productGroupIdRaw : '';

  const [initialLanguages, setInitialLanguages] = useState<IsoLanguageIds[]>([
    defaultLng,
  ]);

  const [supportedLanguages, setSupportedLanguages] = useState<IsoLanguageIds[]>([
    defaultLng,
  ]);

  const goBack = useGoBack();

  const {
    mutateAsync: addGridProduct,
    isLoading: isAddingGridProduct,
  } = useAddGridProduct({ tenantId, dataResidency, environment: env });

  const {
    mutateAsync: updateGridProduct,
    isLoading: isUpdatingGridProduct,
  } = useUpdateGridProduct({ tenantId, dataResidency, environment: env });

  const {
    mutateAsync: deleteGridProduct,
    isLoading: isDeletingGridProduct,
  } = useDeleteGridProduct({ tenantId, dataResidency, environment: env });

  const { data: gridProductData, isLoading: isGridProductLoading } = useGridProduct({
    tenantId,
    dataResidency,
    environment: env,
    productGroupId,
  });
  const currProduct = gridProductData ? gridProductData.data : null;

  const { data: envs = [], isLoading: isEnvironmentsLoading } = useEnvironments(tenantId);

  const { data: productTypesData, isLoading: isProductTypesLoading } = useProductTypes({
    tenantId,
    dataResidency,
    environment: env,
  });
  const productTypes = productTypesData ? productTypesData.data : [];

  const { data: spacesData, isLoading: isSpacesLoading } = useSpaces({
    organisationId: tenantId,
  });
  const { spaces = [] } = spacesData || {};

  const isFormProcessing =
    isGridProductLoading ||
    isEnvironmentsLoading ||
    isProductTypesLoading ||
    isSpacesLoading ||
    isAddingGridProduct ||
    isUpdatingGridProduct ||
    isDeletingGridProduct;

  const isNewProduct = isNewPageRoute || !currProduct;

  const filterProductStatus = (
    spaceIds: string[] = [],
    currProductStatusList: ProductStatus[] = [],
  ) => {
    return spaceIds.map((currSpaceId) => {
      const currProductStatus = currProductStatusList.find(
        (item) => item.spaceId === currSpaceId,
      );

      if (!currProductStatus) {
        return {
          productStatus: ProductStatusEnum.Active,
          spaceId: currSpaceId,
        };
      }

      return currProductStatus;
    });
  };

  const defaultVariant: Variant = useMemo(
    () => ({
      productGroupId:
        currProduct && currProduct.productGroupId ? currProduct.productGroupId : '',
      productId: '',
      color: '',
      size: '',
      style: '',
      productName: currProduct && currProduct.productName ? currProduct.productName : [],
    }),
    [currProduct],
  );

  const defaultVariantValues: Variant = useMemo(() => {
    const currProductName = currProduct && currProduct.productName ? currProduct.productName : [];
    const newProductNameByLanguage = supportedLanguages.map((isoLanguageId) => ({
      isoLanguageId,
      productName: '',
    }));

    newProductNameByLanguage.forEach((productNameByLanguage) => {
      if (!currProductName.find((item) => item.isoLanguageId === productNameByLanguage.isoLanguageId)) {
        currProductName.push(productNameByLanguage);
      }
    });

    return {
      productGroupId:
        currProduct && currProduct.productGroupId ? currProduct.productGroupId : uuidV4(),
      productId: uuidV4(),
      color: '',
      size: '',
      style: '',
      productName: currProductName,
    };
  }, [currProduct, supportedLanguages]);

  const getAllSpacesVariantInventory = useCallback(
    ({
      currVariants,
      currProductItemQuantity,
      currSpaceIds,
      fieldCheck = 'default',
    }: {
      currVariants: Variant[];
      currProductItemQuantity: ProductItemQuantityWithVariantOption[];
      currSpaceIds: string[];
      fieldCheck: 'default' | 'variantOptions';
    }) => {
      const allSpacesVariantsInventory: ProductItemQuantityWithVariantOption[] = [];

      currSpaceIds.forEach((currSpaceId) => {
        currVariants.forEach((currVariant) => {
          const variantSpaceInventory =
            currProductItemQuantity &&
            currProductItemQuantity.find((inventory) => {
              if (fieldCheck === 'default') {
                // Check matching data based on existing product data (prior to form updates)
                return (
                  inventory.productId === currVariant.productId &&
                  inventory.spaceId === currSpaceId
                );
              }

              return (
                isMatchingVariantOptions(inventory, currVariant) &&
                inventory.spaceId === currSpaceId
              );
            });

          allSpacesVariantsInventory.push({
            productId: currVariant.productId,
            spaceId: currSpaceId,
            productItemQuantity: variantSpaceInventory
              ? variantSpaceInventory.productItemQuantity
              : 0,
            color: currVariant.color || '',
            size: currVariant.size || '',
            style: currVariant.style || '',
          });
        });
      });

      return allSpacesVariantsInventory;
    },
    [],
  );

  const getSpacesVariantPrice = useCallback(
    ({
      currVariants,
      currProductPriceList,
      currSpaceIds,
      fieldCheck = 'default',
    }: {
      currVariants: Variant[];
      currProductPriceList: ProductPriceListWithVariantOption[];
      currSpaceIds: string[];
      fieldCheck: 'default' | 'variantOptions';
    }) => {
      const allSpacesVariantsPrice: ProductPriceListWithVariantOption[] = [];

      currSpaceIds.forEach((currSpaceId) => {
        currVariants.forEach((currVariant) => {
          const variantSpacePrice =
            currProductPriceList &&
            currProductPriceList.find((price) => {
              // Check matching data based on existing product data (prior to form updates)
              if (fieldCheck === 'default') {
                return (
                  price.productId === currVariant.productId &&
                  price.spaceId === currSpaceId
                );
              }

              return (
                isMatchingVariantOptions(price, currVariant) &&
                price.spaceId === currSpaceId
              );
            });

          allSpacesVariantsPrice.push({
            productId: currVariant.productId,
            isoCurrencyCode: variantSpacePrice
              ? variantSpacePrice.isoCurrencyCode
              : IsoCurrencyCodes.EUR,
            listPrice: variantSpacePrice ? variantSpacePrice.listPrice : 0,
            priceListType: variantSpacePrice
              ? variantSpacePrice.priceListType
              : PriceListTypeEnum.Standard,
            suggestedRetailPrice: variantSpacePrice
              ? variantSpacePrice.suggestedRetailPrice
              : undefined,
            spaceId: currSpaceId,
            color: currVariant.color || '',
            size: currVariant.size || '',
            style: currVariant.style || '',
          });
        });
      });

      return allSpacesVariantsPrice;
    },
    [],
  );

  const handleFormSubmit = useCallback(
    async (rawValues: any) => {
      try {
        // Use type setting non-grid products fields as optional
        const values = rawValues as GridProductsFormValuesSubmit;

        // Format in accepted Grid Products format and map the logic for custom fields created
        const dataToSubmit = getFormattedGridProductsData(values, supportedLanguages);

        const gridProductsData = prepareDataBeforeSending(dataToSubmit);

        // Note: Had to map it here cause if mapped above, it returns undefined for '' value for some reason
        gridProductsData.productPriceList = gridProductsData.productPriceList.map(
          (item: ProductPriceList) => {
            if (item.priceListType === PriceListTypeEnum.Promotional) {
              return item;
            }

            return {
              ...item,
              spaceId: values.isSamePriceAllLocation ? '' : item.spaceId,
            };
          },
        );

        if (isNewProduct && gridProductsData.productGroupId) {
          await addGridProduct(gridProductsData);

          const path = appendQueryParams(routes.productsOverviewPath(tenantId), {
            env,
          });

          history.push(path);
          message.success(<Message content={t('gridProducts.successSavingData')} />);

          return;
        }

        await updateGridProduct(gridProductsData);

        message.success(<Message content={t('gridProducts.successSavingData')} />);
      } catch (error) {
        const err = (error as unknown) as ApiError;

        message.error(
          <Message content={t('gridProducts.errorSavingData')} error={err} />,
        );
      }
    },
    [
      tenantId,
      env,
      addGridProduct,
      history,
      supportedLanguages,
      isNewProduct,
      t,
      updateGridProduct,
    ],
  );

  const handleDeleteProduct = useCallback(
    async (productGroupId: string) => {
      try {
        await deleteGridProduct(productGroupId);

        message.success(<Message content={t('gridProducts.successDeletingData')} />);

        const path = appendQueryParams(routes.productsOverviewPath(tenantId), {
          env,
        });

        history.push(path);
      } catch (error) {
        const err = (error as unknown) as ApiError;

        message.error(
          <Message content={t('gridProducts.errorDeletingData')} error={err} />,
        );
      }
    },
    [deleteGridProduct, env, history, tenantId, t],
  );

  // Process language applicable to product based on product name languages
  useEffect(() => {
    if (currProduct && currProduct.productName) {
      const productNameLanguages = uniq(
        currProduct.productName.map((item) => item.isoLanguageId),
      );
      setInitialLanguages(productNameLanguages);
      setSupportedLanguages(productNameLanguages);
    }
  }, [currProduct]);

  const handleGoBackClick = useCallback(() => {
    const fallbackPath = routes.productsOverviewPath(tenantId);
    goBack(fallbackPath);
  }, [goBack, tenantId]);

  const getInitialTitle = useCallback(() => {
    let defaultTitle = t('gridProducts.newProduct');

    if (
      !currProduct ||
      !currProduct.productName ||
      currProduct.productName.length === 0
    ) {
      return defaultTitle;
    }

    const initialTitle = getProductName(currProduct, defaultLng);

    // Fallback get first language picked up
    if (!initialTitle) {
      return currProduct.productName[0].productName;
    }

    return initialTitle.productName;
  }, [currProduct, t]);

  const handleLanguagesChange = useCallback((languages: IsoLanguageIds[]) => {
    setSupportedLanguages(languages);
    // setIsExternalFieldTouched(true);
  }, []);

  const spacesDrowdownValues = useMemo(
    () => spaces.map((item) => ({ value: item.id, label: item.displayName })),
    [spaces],
  );


  const isoCurrencyCodeDrowdownValues = useMemo(
    () =>
      Object.values(IsoCurrencyCodes).map((isoCurrencyCode) => ({
        value: isoCurrencyCode,
        label: isoCurrencyCode,
      })),
    [],
  );

  const productTypesDropdownValues = useMemo(
    () =>
      productTypes.map((item) => {
        const displayedTitle = getBestMatchByLanguage(item.title, defaultLng);
        return {
          value: item.productTypeId,
          label: displayedTitle
            ? `${displayedTitle.label} (${item.productTypeId})`
            : item.productTypeId,
        };
      }),
    [productTypes],
  );

  const inventoryTableColumns = useMemo(
    () => [
      {
        title: t('gridProducts.variant'),
        key: 'variant',
        render: (variantOptions: VariantOptionObj) => {
          return <CellText>{getVariantOptionLabel(variantOptions)}</CellText>;
        },
      },
      {
        title: t('gridProducts.productProductId'),
        key: 'productId',
        render: ({ productId }: { productId: string }) => <CellText>{productId}</CellText>,
      },
      {
        title: t('gridProducts.variantsQuantity'),
        key: 'variantQuantity',
        render: ({ mainIndex }: { mainIndex: number }, _: any, rowIndex: number) =>
          getSimpleInput({
            fieldName: `productItemQuantityWithVariantOptions[${mainIndex}].productItemQuantity`,
            label: '',
            type: 'number',
            min: '0',
          }),
      },
    ],
    [t],
  );

  const priceTableColumns = useCallback(
    (mainField: string) => [
      {
        title: t('gridProducts.variant'),
        key: 'variant',
        render: (variantOptions: VariantOptionObj) => {
          return <CellText>{getVariantOptionLabel(variantOptions)}</CellText>;
        },
      },
      {
        title: t('gridProducts.productProductId'),
        key: 'productId',
        render: ({ productId }: { productId: string }) => <CellText>{productId}</CellText>,
      },
      {
        title: t('gridProducts.variantsIsoCurrencyCode'),
        key: 'variantsIsoCurrencyCode',
        render: ({ mainIndex }: { mainIndex: number }, _: any, rowIndex: number) => (
          <CurrencySelect
            name={`${mainField}[${mainIndex}].isoCurrencyCode`}
            options={isoCurrencyCodeDrowdownValues}
          />
        ),
      },
      {
        title: t('gridProducts.variantsListPrice'),
        key: 'variantsListPrice',
        render: ({ mainIndex }: { mainIndex: number }, _: any, rowIndex: number) =>
          getPriceInput({
            fieldName: `${mainField}[${mainIndex}].listPrice`,
            label: '',
          }),
      },
      {
        title: t('gridProducts.variantsSuggestedRetailPrice'),
        key: 'variantsSuggestedRetailPrice',
        render: ({ mainIndex }: { mainIndex: number }, _: any, rowIndex: number) =>
          getPriceInput({
            fieldName: `${mainField}[${mainIndex}].suggestedRetailPrice`,
            label: '',
          }),
      },
    ],
    [t, isoCurrencyCodeDrowdownValues],
  );

  const variantsTableColumns = useCallback(
    (setValue: SetValueType) => [
      {
        title: t('gridProducts.variant'),
        key: 'variant',
        render: (variantOptions: VariantOptionObj) => {
          return <CellText>{getVariantOptionLabel(variantOptions)}</CellText>;
        },
      },
      {
        title: t('gridProducts.productProductId'),
        key: 'productId',
        render: (param: unknown, _: unknown, rowIndex: number) => <Input name={`variants[${rowIndex}].productId`} isRequired  dataTestId="grid-products-details-product-id"/>,
      },
      {
        title: t('gridProducts.columnTitleProductName'),
        key: 'productName',
        render: (param: unknown, _: unknown, rowIndex: number) => {
          return (
            <ArrayFields
              propertyName={`variants[${rowIndex}].productName`}
              canAdd={false}
              canDelete={false}
              itemRowContent={(parentPropertyName) => {
                return (
                  <LocalizedInput
                    name={parentPropertyName}
                    locale={{ isReadOnly: true, label: null }}
                    input={{ name: 'productName', label: null }}
                    isRequired
                    dataTestId="grid-products-details-variants-product-name"
                  />
                );
              }}
            />
          );
        },
      },
      {
        title: t('gridProducts.variantsProductLink'),
        key: 'productLink',
        render: (param: unknown, _: unknown, rowIndex: number) =>
          getSimpleInput({
            fieldName: `variants[${rowIndex}].productLink`,
            label: '',
            dataTestId: 'grid-products-details-variants-product-link',
          }),
      },
      {
        title: t('gridProducts.variantsEAN'),
        key: 'europeanArticleNumber',
        render: (param: unknown, _: unknown, rowIndex: number) =>
          getSimpleTagsInput({
            fieldName: `variants[${rowIndex}].europeanArticleNumber`,
            placeholder: 'Press enter to submit',
            dataTestId: 'grid-products-details-variants-ean'
          }),
      },
      {
        title: t('gridProducts.variantsGTIN'),
        key: 'globalTradeItemNumber',
        render: (param: unknown, _: unknown, rowIndex: number) =>
          getSimpleTagsInput({
            fieldName: `variants[${rowIndex}].globalTradeItemNumber`,
            placeholder: 'Press enter to submit',
            dataTestId: 'grid-products-details-variants-gtin'
          }),
      },
      {
        title: t('gridProducts.variantsUPC'),
        key: 'universalProductCode',
        render: (param: unknown, _: unknown, rowIndex: number) =>
          getSimpleTagsInput({
            fieldName: `variants[${rowIndex}].universalProductCode`,
            placeholder: 'Press enter to submit',
            dataTestId: 'grid-products-details-variants-upc'
          }),
      },
      {
        title: t('gridProducts.variantsColorRgb'),
        key: 'colorRgb',
        render: (param: unknown, _: unknown, rowIndex: number) =>
          getSimpleInput({
            fieldName: `variants[${rowIndex}].colorRgb`,
            label: '',
            dataTestId: 'grid-products-details-variants-color-rgb'
          }),
      },
    ],
    [t],
  );

  const getVariantOptionsCombinations = useCallback(
    (variantOptions: { color: string[]; size: string[]; style: string[] }) =>
      combinate(
        omitBy(variantOptions, (options) => options.length === 0),
      ) as VariantOptionObj[],
    [],
  );

  const handleInventorySpaceTabChange = useCallback((key: string) => {
    setInventorySpaceTabKey(key);
  }, []);

  const handlePriceSpaceTabChange = useCallback((key: string) => {
    setPriceSpaceTabKey(key);
  }, []);

  const handleVariantsOptionChange = useCallback(
    ({
      optionFieldName,
      newFieldValues,
      setValue,
      currentFormValues,
    }: {
      optionFieldName: string;
      newFieldValues: string[];
      setValue: any;
      currentFormValues: GridProductsFormValues;
    }): void => {
      const newVariantOptions = currentFormValues.variantOptions;
      newVariantOptions[optionFieldName as VariantOptionKeys] = newFieldValues;

      // Update variants listing based on new combinations
      const newCombinations = getVariantOptionsCombinations(newVariantOptions);
      const newVariantsList: Variant[] =
        newCombinations.length > 0
          ? newCombinations.reduce((acc: Variant[], curr) => {
              const existingVariant = currentFormValues.variants.find((item) => {
                return isMatchingVariantOptions(item, curr);
              });

              if (existingVariant) {
                return [...acc, existingVariant];
              }

              // Consider variant as new combination and should be filled up
              return [
                ...acc,
                {
                  ...defaultVariantValues,
                  productId: uuidV4(),
                  color: curr.color,
                  size: curr.size,
                  style: curr.style,
                },
              ];
            }, [])
          : [defaultVariantValues];

      const newInventoryList =
        getAllSpacesVariantInventory({
          currVariants: newVariantsList,
          currProductItemQuantity:
            currentFormValues.productItemQuantityWithVariantOptions || [],
          currSpaceIds: currentFormValues.spaceIds,
          fieldCheck: 'variantOptions',
        }) || [];

      // Product Standard Prices for applicable store specific data
      const updatedPriceListStandard = getSpacesVariantPrice({
        currVariants: newVariantsList,
        currProductPriceList: currentFormValues.productPriceListStandard || [],
        currSpaceIds: currentFormValues.spaceIds,
        fieldCheck: 'variantOptions',
      });

      // Product Standard Prices for all ('') store data
      const updatedPriceListAllLocation = getSpacesVariantPrice({
        currVariants: newVariantsList,
        currProductPriceList: currentFormValues.productPriceListStandard || [],
        currSpaceIds: [''],
        fieldCheck: 'variantOptions',
      });

      // Update form fields
      setValue('variants', newVariantsList);
      setValue('variantOptions', newVariantOptions);
      setValue('productPriceListStandard', updatedPriceListStandard);
      setValue('productPriceListAllLocation', updatedPriceListAllLocation);
      setValue('productItemQuantityWithVariantOptions', newInventoryList);
    },
    [
      defaultVariantValues,
      getVariantOptionsCombinations,
      getAllSpacesVariantInventory,
      getSpacesVariantPrice,
    ],
  );

  const initializeVariantsInfo = useCallback(
    ({
      currentFormValues,
      productItemQuantityWithVariantOptions,
      productPriceListStandard,
      variantOptions,
    }: {
      currentFormValues: GridProduct;
      productItemQuantityWithVariantOptions: ProductItemQuantityWithVariantOption[];
      productPriceListStandard: ProductPriceListWithVariantOption[];
      variantOptions: {
        size: string[];
        color: string[];
        style: string[];
      };
    }) => {
      // Update variants listing based on new combinations
      const newCombinations = getVariantOptionsCombinations(variantOptions);
      const newVariantsList: Variant[] =
        newCombinations.length > 0
          ? newCombinations.reduce((acc: Variant[], curr) => {
              const existingVariant = currentFormValues.variants.find((item) => {
                return isMatchingVariantOptions(item, curr);
              });

              if (existingVariant) {
                return [...acc, existingVariant];
              }

              // Consider variant as new combination and should be filled up
              return [
                ...acc,
                {
                  ...defaultVariantValues,
                  productId: uuidV4(),
                  color: curr.color,
                  size: curr.size,
                  style: curr.style,
                },
              ];
            }, [])
          : [defaultVariantValues];

      const newInventoryList =
        getAllSpacesVariantInventory({
          currVariants: newVariantsList,
          currProductItemQuantity: productItemQuantityWithVariantOptions || [],
          currSpaceIds: currentFormValues.spaceIds,
          fieldCheck: 'variantOptions',
        }) || [];

      // Product Standard Prices for applicable store specific data
      const updatedPriceListStandard = getSpacesVariantPrice({
        currVariants: newVariantsList,
        currProductPriceList: productPriceListStandard || [],
        currSpaceIds: currentFormValues.spaceIds,
        fieldCheck: 'variantOptions',
      });

      // Product Standard Prices for all ('') store data
      const updatedPriceListAllLocation = getSpacesVariantPrice({
        currVariants: newVariantsList,
        currProductPriceList: productPriceListStandard || [],
        currSpaceIds: [''],
        fieldCheck: 'variantOptions',
      });

      return {
        newVariantsList,
        newVariantOptions: variantOptions,
        updatedPriceListStandard,
        updatedPriceListAllLocation,
        newInventoryList,
      };
    },
    [
      defaultVariantValues,
      getVariantOptionsCombinations,
      getAllSpacesVariantInventory,
      getSpacesVariantPrice,
    ],
  );

  const toggleAdvancedOptions = useCallback(() => {
    setShowAdvancedOptions((prev) => !prev);
  }, []);

  const initialValues: GridProductsInitialFormValues = useMemo(() => {
    const productGroupId = uuidV4();

    const defaultValues = {
      productGroupId,
      productName: [
        {
          isoLanguageId: defaultLng,
          productName: '',
        },
      ],
      productDescription: [
        {
          isoLanguageId: defaultLng,
          productDescription: '',
        },
      ],
      productInternalName: [
        {
          isoLanguageId: defaultLng,
          productInternalName: '',
        },
      ],
      productShortDescription: [
        {
          isoLanguageId: defaultLng,
          productShortDescription: '',
        },
      ],
      productType: [],
      spaceIds: [],
      productPriceList: [],
      productItemQuantity: [],
      variants: [
        {
          productGroupId,
          productId: uuidV4(),
          productName: [
            {
              isoLanguageId: defaultLng,
              productName: '',
            },
          ],
        },
      ],
      productStatus: [],
      variantOptions: {
        size: [],
        color: [],
        style: [],
      },

      catalogPageLocationProduct: [],
      isSamePriceAllLocation: true,
      productPriceListAllLocation: [],
      productPriceListStandard: [],
      productPriceListPromotional: [],
      productFeatureGroupings: [],
      storageInstructions: [
        {
          isoLanguageId: defaultLng,
          storageInstructions: '',
        },
      ],
      consumerStorageInstruction: [
        {
          isoLanguageId: defaultLng,
          consumerStorageInstruction: '',
        },
      ],
      productShippingInstruction: [
        {
          isoLanguageId: defaultLng,
          productShippingInstruction: '',
        },
      ],
      productItemQuantityWithVariantOptions: [],
    };

    if (!currProduct) {
      return defaultValues;
    }

    const currVariants =
      currProduct.variants && currProduct.variants.length > 0
        ? currProduct.variants
        : [defaultVariant];

    const currProductItemQuantity = get(
      currProduct,
      'productItemQuantity',
      [],
    ) as ProductItemQuantityWithVariantOption[];

    const currProductPriceList = get(
      currProduct,
      'productPriceList',
      [],
    ) as ProductPriceListWithVariantOption[];

    const currSpaceIds = get(currProduct, 'spaceIds', []);

    // Having '' spaceId in all prices data means price is applicable to all stores regardless of location
    const isSamePriceAllLocation = currProductPriceList
      ? currProductPriceList
          .filter(
            (item) =>
              item.priceListType === PriceListTypeEnum.Standard ||
              PriceListTypeEnum.StandardConditional,
          )
          .every((item) => item.spaceId === ALL_LOCATION_SPACE_ID)
      : true;

    const uniqueCatalogPageLocationProduct = getUniqueCatalogImages(currProduct.catalogPageLocationProduct);

    const variantOptions = {
      size: uniq(
        (currProduct.variants || [])
          .map((item) => item.size || '')
          .filter((item) => !!item),
      ),
      color: uniq(
        (currProduct.variants || [])
          .map((item) => item.color || '')
          .filter((item) => !!item),
      ),
      style: uniq(
        (currProduct.variants || [])
          .map((item) => item.style || '')
          .filter((item) => !!item),
      ),
    };

    const productItemQuantityWithVariantOptions = getAllSpacesVariantInventory({
      currVariants,
      currProductItemQuantity,
      currSpaceIds,
      fieldCheck: 'default',
    });

    const productPriceListStandard = getSpacesVariantPrice({
      currVariants,
      currProductPriceList: currProductPriceList.filter(
        (item) =>
          item.priceListType === PriceListTypeEnum.Standard ||
          item.priceListType === PriceListTypeEnum.StandardConditional,
      ),
      currSpaceIds,
      // Use 'default' due to initial form value
      fieldCheck: 'default',
    });

    const {
      newVariantsList,
      newVariantOptions,
      updatedPriceListStandard,
      updatedPriceListAllLocation,
      newInventoryList,
    } = initializeVariantsInfo({
      currentFormValues: currProduct,
      productItemQuantityWithVariantOptions,
      productPriceListStandard,
      variantOptions,
    });

    // New product initial values
    return {
      ...defaultValues,
      ...currProduct,
      // Workaround to group together productFeature by key and apply multi-language options
      productFeatureGroupings: getCurrProductFeaturesGrouping(currProduct),
      // Workaround - auto-populate status by space (if current spaceIds and productStatus fields don't totally match)
      productStatus: filterProductStatus(currProduct.spaceIds, currProduct.productStatus),
      // THere should be at least 1 variant object upon creation (or if no "options" like color, size, and style are set)
      variants: newVariantsList,

      productItemQuantityWithVariantOptions: newInventoryList,
      // Set to true if all standard price is set to "" spaceId
      // Ex. for Reliance where price is same across all stores
      isSamePriceAllLocation,
      // Only set '' as spaceIds
      productPriceListAllLocation: updatedPriceListAllLocation,
      productPriceListStandard: updatedPriceListStandard,
      productPriceListPromotional: currProductPriceList
        .filter((item) => item.priceListType === PriceListTypeEnum.Promotional)
        .reduce((acc: ProductPriceListWithVariantOption[], curr: ProductPriceList) => {
          const currVariant = currProduct.variants
            ? currProduct.variants.find((variant) => variant.productId === curr.productId)
            : null;

          if (!currVariant) {
            return acc;
          }

          return [
            ...acc,
            {
              ...curr,
              color: currVariant.color || '',
              size: currVariant.size || '',
              style: currVariant.style || '',
            },
          ];
        }, []),
      variantOptions: newVariantOptions,
      catalogPageLocationProduct: uniqueCatalogPageLocationProduct,
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currProduct, defaultVariant, getAllSpacesVariantInventory, getSpacesVariantPrice]);

  const isSpaceRelatedTranslationDisabled = useMemo(
    () => supportedLanguages.length === 0 || spacesDrowdownValues.length === 0,
    [supportedLanguages, spacesDrowdownValues],
  );

  return (
    <Form
      onSubmit={handleFormSubmit}
      mutators={{
        ...arrayMutators,
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      initialValues={initialValues}
      render={({
        handleSubmit,
        submitting: isSubmitting,
        values: valuesRaw,
        form: {
          mutators: { push, remove, setValue },
        },
        invalid: isFormInvalid,
        errors,
      }) => {
        const values = valuesRaw as GridProductsFormValues;

        values.catalogPageLocationProduct = getUniqueCatalogImages((valuesRaw as GridProductsFormValues).catalogPageLocationProduct);

        const isInventorySpaceTabInvalid =
          inventorySpaceTabKey === '' || !values.spaceIds.includes(inventorySpaceTabKey);

        const isPriceSpaceTabInvalid =
          priceSpaceTabKey === ALL_LOCATION_SPACE_ID ||
          !values.spaceIds.includes(priceSpaceTabKey);

        const isSpaceSpecificPrices = !values.isSamePriceAllLocation;

        const isSpaceIdsSelected = values.spaceIds.length > 0;

        // Inventory list: workaround to select first possible space tab if applicable
        if (isInventorySpaceTabInvalid && isSpaceIdsSelected) {
          setInventorySpaceTabKey(`${values.spaceIds[0]}`);
        }

        // Price list: workaround to select first possible space tab if applicable
        if (isSpaceSpecificPrices && isPriceSpaceTabInvalid && isSpaceIdsSelected) {
          setPriceSpaceTabKey(`${values.spaceIds[0]}`);
        }

        const getExtendableArraySection = getExtendableArraySectionWrapper({
          t,
          push,
        });

        const variantsOptionsCombinations = getVariantOptionsCombinations(
          values.variantOptions,
        );

        const handleSamePriceCheckboxChange = (e: CheckboxChangeEvent) => {
          const value = e.target.checked;

          setValue('isSamePriceAllLocation', value);

          if (value) {
            // If checked, set price tab key to '' default (specifying to apply price to all locations)
            setPriceSpaceTabKey('');
          }
        };

        const spaceTabKeys: string[] = values.isSamePriceAllLocation
          ? ['']
          : values.spaceIds;

        const handleAddLanguage = (language: IsoLanguageIds) => {
          push('productName', {
            isoLanguageId: language,
            productName: '',
          });

          push('productDescription', {
            isoLanguageId: language,
            productDescription: '',
          });

          const { variants = [], productFeatureGroupings = [] } = values;

          variants.forEach((_, index) => {
            push(`variants[${index}].productName`, {
              isoLanguageId: language,
              productName: '',
            });
          });

          push('productInternalName', {
            isoLanguageId: language,
            productInternalName: '',
          });

          push('productShortDescription', {
            isoLanguageId: language,
            productShortDescription: '',
          });

          productFeatureGroupings.forEach((_, index) => {
            push(`productFeatureGroupings[${index}].valuesByLanguage`, {
              isoLanguageId: language,
              productFeatureValueGrouped: '',
            });
          });

          push('storageInstructions', {
            isoLanguageId: language,
            storageInstructions: '',
          });

          push('consumerStorageInstruction', {
            isoLanguageId: language,
            consumerStorageInstruction: '',
          });

          push('productShippingInstruction', {
            isoLanguageId: language,
            productShippingInstruction: '',
          });
        };

        const handleRemoveLanguage = (_language: IsoLanguageIds, index: number) => {
          remove('productName', index);
          remove('productDescription', index);

          const { variants = [], productFeatureGroupings = [] } = values;

          variants.forEach((_, variantIndex) => {
            remove(`variants[${variantIndex}].productName`, index);
          });

          remove('productInternalName', index);
          remove('productShortDescription', index);

          productFeatureGroupings.forEach((_, productFeatureGroupingsIndex) => {
            remove(
              `productFeatureGroupings[${productFeatureGroupingsIndex}].valuesByLanguage`,
              index,
            );
          });

          remove('storageInstructions', index);
          remove('consumerStorageInstruction', index);
          remove('productShippingInstruction', index);
        };

        return (
          <form
            onSubmit={(event) => {
              handleSubmit(event);

              const errorFieldNames = getFieldNamesWithError(errors);

              if (!isFormInvalid || !errorFieldNames.length) {
                return;
              }

              scrollIntoView(errorFieldNames[0]);
            }}
          >
            <SubHeader
              pageName={t('gridProducts.titleProducts')}
              subtitle={getInitialTitle()}
            >
              <HeaderActions>
                <BackButton type="link" icon="arrow-left" onClick={handleGoBackClick} data-testid="grid-products-details-back-button"/>
                <ActionButtons>
                  {!isNewProduct && isAllowed(permissionKeys.gridProducts.remove) && (
                    <Popconfirm
                      title={t('areYouSureYouWantToDelete')}
                      onConfirm={() => handleDeleteProduct(values.productGroupId)}
                      okText={t('yes')}
                      cancelText={t('no')}
                      data-testid="grid-products-details-confirm-delete-product-message"
                    >
                      <DeleteButton
                        size="large"
                        disabled={isSubmitting || isFormProcessing}
                        loading={isDeletingGridProduct}
                        type="danger"
                        data-testid="grid-products-details-delete-product-button"
                      >
                        {t('gridProducts.deleteProduct')}
                      </DeleteButton>
                    </Popconfirm>
                  )}
                  {isAllowed(permissionKeys.gridProducts.update) && (
                    <Button
                      size="large"
                      disabled={isSubmitting || isFormProcessing}
                      loading={isSubmitting || isAddingGridProduct || isUpdatingGridProduct}
                      htmlType="submit"
                      type="primary"
                      data-testid="grid-products-details-save-product-button"
                    >
                      {t('gridProducts.saveProduct')}
                    </Button>
                  )}
                </ActionButtons>
              </HeaderActions>
            </SubHeader>

            <Overlay spinnerOverlay={{ isLoading: isFormProcessing }}>
              <Content gutter={40}>
                <Col md={{ order: 2, span: 24 }} xl={16}>
                  <StyledPanelCard
                    title={t('gridProducts.general')}
                    bodypadding={'24px'}
                    actions={[
                      <AdvancedOptionsToggle onClick={toggleAdvancedOptions}>
                        {!isShowAdvancedOptions && (
                          <>
                            <div data-testid="grid-products-details-show-advanced-options-button">Show advanced options</div>
                            <ChevronDown size={16} />
                          </>
                        )}

                        {isShowAdvancedOptions && (
                          <>
                            <div data-testid="grid-products-details-hide-advanced-options-button">Hide advanced options</div>
                            <ChevronUp size={16} />
                          </>
                        )}
                      </AdvancedOptionsToggle>,
                    ]}
                  >
                    <InputStyled
                      name="productGroupId"
                      label={t('gridProducts.productProductGroupId')}
                      isRequired
                      disabled={!isNewProduct}
                      $isVisible={!isNewProduct}
                      dataTestId="grid-products-details-product-group-id-input"
                    />

                    {!isNewProduct && <Gap />}

                    <ArrayFields
                      propertyName="productName"
                      label={t('title')}
                      isRequired
                      canAdd={false}
                      canDelete={false}
                      itemRowContent={(parentPropertyName) => {
                        return (
                          <LocalizedInput
                            name={parentPropertyName}
                            locale={{ isReadOnly: true, label: null }}
                            input={{ name: 'productName', label: null }}
                            isRequired
                            dataTestId="grid-products-details-product-name-input"
                          />
                        );
                      }}
                    />

                    <Gap />

                    <ArrayFields
                      propertyName="productDescription"
                      label={t('description')}
                      canAdd={false}
                      canDelete={false}
                      itemRowContent={(parentPropertyName) => {
                        return (
                          <LocalizedTextArea
                            name={parentPropertyName}
                            locale={{ isReadOnly: true, label: null }}
                            textarea={{ name: 'productDescription', label: null }}
                            dataTestId="grid-products-details-product-description-input"
                          />
                        );
                      }}
                    />

                    {/* ADVANCED OPTIONS AREA */}

                      <GeneralMoreOptionsContainer $isVisible={isShowAdvancedOptions}>
                        <Divider />

                        {getExtendableArraySection({
                          // onItemRemove: handleArrayFieldRemove,
                          mainPropertyName: 'productLabel',
                          required: false,
                          // Not allow creating record if there's no space list
                          hideActionButtons: isSpaceRelatedTranslationDisabled,
                          defaultValue: {
                            isoLanguageId: supportedLanguages[0],
                            productLabel: '',
                            spaceId: spaces.length ? spaces[0].id : undefined,
                          } as any,
                          content: (name) => {
                            return (
                              <>
                                <ArrayTopPicker>
                                  {getSimpleSelect({
                                    name: `${name}.spaceId`,
                                    items: spacesDrowdownValues,
                                    label: t('space'),
                                  })}
                                </ArrayTopPicker>

                                <LocalizedInputUnPadded
                                  name={name}
                                  locale={{ supportedLanguages, label: t('gridProducts.language') }}
                                  input={{ name: 'productLabel' }}
                                  isRequired
                                />
                              </>
                            );
                          },
                        })}

                        <Divider />

                        {getSimpleInput({
                          fieldName: `variantsGroupId`,
                          label: t('gridProducts.productVariantsGroupId'),
                        })}

                        <Gap />

                        {getSimpleInput({
                          fieldName: `ranking`,
                          label: t('gridProducts.productRanking'),
                          type: 'number',
                        })}

                        <Gap />

                        <ArrayFields
                          propertyName="productInternalName"
                          label={t('gridProducts.productproductInternalName')}
                          canAdd={false}
                          canDelete={false}
                          itemRowContent={(parentPropertyName) => {
                            return (
                              <LocalizedInput
                                name={parentPropertyName}
                                locale={{ isReadOnly: true, label: null }}
                                input={{ name: 'productInternalName', label: null }}
                                dataTestId="grid-products-details-product-internal-name-input"
                              />
                            );
                          }}
                        />
                        <Gap />

                        <ArrayFields
                          propertyName="productShortDescription"
                          label={t('gridProducts.productproductShortDescription')}
                          canAdd={false}
                          canDelete={false}
                          itemRowContent={(parentPropertyName) => {
                            return (
                              <LocalizedInput
                                name={parentPropertyName}
                                locale={{ isReadOnly: true, label: null }}
                                input={{ name: 'productShortDescription', label: null }}
                                dataTestId="grid-products-details-product-short-description-input"
                              />
                            );
                          }}
                        />

                        <Gap />

                        <Divider />

                        <ArrayFields
                          propertyName="productFeatureGroupings"
                          label={<CardLabel>{t('gridProducts.productproductFeatureGroupingsTitle')}</CardLabel>}
                          defaultValue={{
                            productFeatureType: '',
                            valuesByLanguage: supportedLanguages.map((isoLanguageId) => ({
                              isoLanguageId,
                              productFeatureValueGrouped: '',
                            })),
                          }}
                          itemRowContent={(parentPropertyName) => {
                            return (
                              <Section>
                                <Input
                                  name={`${parentPropertyName}.productFeatureType`}
                                  label={t(
                                    'gridProducts.productProductFeatureProductFeatureType',
                                  )}
                                  isRequired
                                  dataTestId="grid-products-details-feature-product-type"
                                />
                                <Gap />
                                <ArrayFields
                                  propertyName={`${parentPropertyName}.valuesByLanguage`}
                                  label={t(
                                    'gridProducts.productProductFeatureProductFeatureValue',
                                  )}
                                  isRequired
                                  canAdd={false}
                                  canDelete={false}
                                  itemRowContent={(valuesByLanguagePropertyName) => {
                                    return (
                                      <LocalizedInput
                                        name={valuesByLanguagePropertyName}
                                        locale={{ isReadOnly: true, label: null }}
                                        input={{ name: 'productFeatureValueGrouped', label: null }}
                                        isRequired
                                        dataTestId="grid-products-details-feature-product-value"
                                      />
                                    );
                                  }}
                                />
                              </Section>
                            );
                          }}
                        />

                        <Divider />

                        <Gap />

                        <InlineRowInput>
                          {getDatePicker({
                            fieldName: `introductionDate`,
                            t,
                            customLabel: t('gridProducts.productIntroductionDate'),
                          })}

                          {getDatePicker({
                            fieldName: `plannedAbandonmentDate`,
                            t,
                            customLabel: t('gridProducts.productPlannedAbandonmentDate'),
                          })}
                        </InlineRowInput>

                        <Gap />
                        {getSimpleInput({
                          fieldName: `shellLifeDays`,
                          label: t('gridProducts.productShellLifeDays'),
                          type: 'number',
                          dataTestId: 'grid-products-details-product-shell-life-days'
                        })}

                        <Gap />

                        <ArrayFields
                          propertyName="storageInstructions"
                          label={t('gridProducts.productStorageInstructions')}
                          canAdd={false}
                          canDelete={false}
                          itemRowContent={(parentPropertyName) => {
                            return (
                              <LocalizedInput
                                name={parentPropertyName}
                                locale={{ isReadOnly: true, label: null }}
                                input={{ name: 'storageInstructions', label: null }}
                                dataTestId="grid-products-details-product-storage-instructions"
                              />
                            );
                          }}
                        />

                        <Gap />

                        <ArrayFields
                          propertyName="consumerStorageInstruction"
                          label={t('gridProducts.productconsumerStorageInstruction')}
                          canAdd={false}
                          canDelete={false}
                          itemRowContent={(parentPropertyName) => {
                            return (
                              <LocalizedInput
                                name={parentPropertyName}
                                locale={{ isReadOnly: true, label: null }}
                                input={{ name: 'consumerStorageInstruction', label: null }}
                                dataTestId="grid-products-details-product-consumer-storage-instructions"
                              />
                            );
                          }}
                        />

                        <Gap />

                        <ArrayFields
                          propertyName="productShippingInstruction"
                          label={t('gridProducts.productShippingInstruction')}
                          canAdd={false}
                          canDelete={false}
                          itemRowContent={(parentPropertyName) => {
                            return (
                              <LocalizedInput
                                name={parentPropertyName}
                                locale={{ isReadOnly: true, label: null }}
                                input={{ name: 'productShippingInstruction', label: null }}
                                dataTestId="grid-products-details-product-shipping-instructions"
                              />
                            );
                          }}
                        />
                      </GeneralMoreOptionsContainer>

                  </StyledPanelCard>

                  <StyledPanelCard
                    title={t('gridProducts.media')}
                    bodypadding={'16px 24px'}
                  >
                    {/* // TODO: Handle matching image to specific variant only - as an option*/}
                    {/* Right now, this applies product image to all variants */}
                    {getExtendableArraySection({
                      // onItemRemove: handleArrayFieldRemove,
                      mainPropertyName: 'catalogPageLocationProduct',
                      required: false,
                      hideTitle: true,
                      defaultValue: {
                        productId: '', // Will be mapped to productId on change
                        productGroupId: values.productGroupId,
                        catalogType: '',
                        catalogPage: '',
                        catalogPageLocation: '',
                        catalogPageLocationProduct: '',
                        defaultImage: false,
                      } as any,
                      content: (name, mainIndex) => {
                        const imageUrl = values.catalogPageLocationProduct[mainIndex] ? values.catalogPageLocationProduct[mainIndex].catalogPageLocationProduct : '';
                        const imageType = values.catalogPageLocationProduct[mainIndex] ? values.catalogPageLocationProduct[mainIndex].catalogType : '';

                        return (
                          <>
                            <InlineRowInput>
                              <MediaPreview
                                src={imageUrl}
                                mediaType={imageType}
                              />
                              <InlineRowInput>
                                <MediaTypeSelect
                                  name={`${name}.catalogType`}
                                  label={t('gridProducts.mediaType')}
                                  options={allowedMediaTypes}
                                  isRequired
                                  dataTestId="grid-products-details-media-select"
                                />
                                <MediaUrlInput
                                  name={`${name}.catalogPageLocationProduct`}
                                  label={t('gridProducts.mediaURL')}
                                  isRequired
                                  dataTestId="grid-products-details-media-url-input"
                                />
                              </InlineRowInput>
                            </InlineRowInput>
                            <Field name={`${name}.defaultImage`}>
                              {({ input }) => (
                                <>
                                  {input.value && (
                                    <Button type="primary" data-testid="grid-products-details-main-button">{t('main')}</Button>
                                  )}
                                  {!input.value && (
                                    <Button
                                      type="link"
                                      onClick={() => {
                                        const newCatalogPageLocationProduct = values.catalogPageLocationProduct.map(
                                          (item, currIndex) => ({
                                            ...item,
                                            defaultImage: currIndex === mainIndex,
                                          }),
                                        );
                                        setValue(
                                          'catalogPageLocationProduct',
                                          newCatalogPageLocationProduct,
                                        );
                                      }}
                                      data-testid="grid-products-details-set-as-main-button"
                                    >
                                      {t('setAsMain')}
                                    </Button>
                                  )}
                                </>
                              )}
                            </Field>
                          </>
                        );
                      },
                    })}
                  </StyledPanelCard>

                  <StyledPanelCard
                    bodypadding={'16px 24px'}
                    // Always have 1 default variant
                    title={t('gridProducts.variantsPricing')}
                  >
                    <Checkbox
                      checked={values.isSamePriceAllLocation}
                      onChange={handleSamePriceCheckboxChange}
                      data-testid="grid-products-details-variants-pricing-checkbox"
                    >
                      {t('gridProducts.applyPriceToAllLocation')}
                    </Checkbox>

                    <>
                      {values.spaceIds.length === 0 && (
                        <div>{t('gridProducts.pleaseSetLocations')}</div>
                      )}

                      {isSpaceIdsSelected && (
                        <TabBarComponent
                          onChange={handlePriceSpaceTabChange}
                          activeKey={priceSpaceTabKey}
                          lang={i18n.language}
                          refreshTrigger={`${JSON.stringify(
                            values.variants,
                          )}${JSON.stringify(values.spaceIds)}${
                            values.isSamePriceAllLocation
                          }`}
                        >
                          {spaceTabKeys.map((spaceId) => {
                            const spaceInfo: OrganisationSpace | undefined = spaces.find(
                              (space) => space.id === spaceId,
                            );

                            if (!spaceInfo && spaceId !== ALL_LOCATION_SPACE_ID) {
                              return null;
                            }

                            const priceListBySpace = (
                              (values.isSamePriceAllLocation
                                ? values.productPriceListAllLocation
                                : values.productPriceListStandard) || []
                            ).reduce(
                              (
                                acc: (ProductPriceList & { mainIndex: number })[],
                                currPrice,
                                index,
                              ) => {
                                // Workaround to always have the latest productId matched
                                const matchedVariant = values.variants.find(
                                  (currVariant) =>
                                    isMatchingVariantOptions(currPrice, currVariant),
                                );

                                if (currPrice.spaceId === spaceId && matchedVariant) {
                                  return [
                                    ...acc,
                                    {
                                      ...currPrice,
                                      mainIndex: index,
                                    },
                                  ];
                                }

                                return acc;
                              },
                              [],
                            );

                            return (
                              <Tabs.TabPane
                                key={spaceId}
                                tab={
                                  <span>
                                    {spaceInfo ? spaceInfo.displayName : 'All Locations'}
                                  </span>
                                }
                                disabled={false}
                              >
                                <Table
                                  expandIcon={() => null}
                                  expandIconAsCell={false}
                                  dataSource={priceListBySpace}
                                  columns={priceTableColumns(
                                    spaceId === ALL_LOCATION_SPACE_ID
                                      ? 'productPriceListAllLocation'
                                      : 'productPriceListStandard',
                                  )}
                                  rowKey={(_, index) => `price-${index}`}
                                  scroll={{ x: 'max-content' }}
                                />
                              </Tabs.TabPane>
                            );
                          })}
                        </TabBarComponent>
                      )}
                    </>
                  </StyledPanelCard>

                  <StyledPanelCard
                    bodypadding={'16px 24px'}
                    title={t('gridProducts.columnTitleInventory')}
                  >
                    <>
                      {values.spaceIds.length === 0 && (
                        <div>{t('gridProducts.pleaseSetLocations')}</div>
                      )}
                      {isSpaceIdsSelected && (
                        <TabBarComponent
                          onChange={handleInventorySpaceTabChange}
                          activeKey={inventorySpaceTabKey}
                          lang={i18n.language}
                          refreshTrigger={`${JSON.stringify(
                            values.variants,
                          )}${JSON.stringify(values.spaceIds)}`}
                        >
                          {values.spaceIds.map((spaceId) => {
                            const spaceInfo: OrganisationSpace | undefined = spaces.find(
                              (space) => space.id === spaceId,
                            );

                            if (!spaceInfo) {
                              return null;
                            }

                            const inventoryListBySpace = values.productItemQuantityWithVariantOptions
                              ? values.productItemQuantityWithVariantOptions.reduce(
                                  (
                                    acc: (ProductItemQuantity & { mainIndex: number })[],
                                    currInventory,
                                    index,
                                  ) => {
                                    // Workaround to always have the latest productId matched
                                    const matchedVariant:
                                      | Variant
                                      | undefined = values.variants.find((currVariant) =>
                                      isMatchingVariantOptions(
                                        currInventory,
                                        currVariant,
                                      ),
                                    );

                                    if (
                                      currInventory.spaceId === spaceId &&
                                      matchedVariant
                                    ) {
                                      return [
                                        ...acc,
                                        {
                                          ...currInventory,
                                          mainIndex: index,
                                        },
                                      ];
                                    }

                                    return acc;
                                  },
                                  [],
                                )
                              : [];

                            return (
                              <Tabs.TabPane
                                key={`${spaceId}`}
                                tab={<span>{spaceInfo.displayName}</span>}
                                disabled={false}
                              >
                                <Table
                                  expandIcon={() => null}
                                  expandIconAsCell={false}
                                  dataSource={inventoryListBySpace}
                                  columns={inventoryTableColumns}
                                  rowKey={(_, index) => `inventory-${index}`}
                                  scroll={{ x: 'max-content' }}
                                />
                              </Tabs.TabPane>
                            );
                          })}
                        </TabBarComponent>
                      )}
                    </>
                  </StyledPanelCard>

                  <StyledPanelCard
                    bodypadding={'16px 24px'}
                    // Always have 1 default variant
                    title={`${variantsOptionsCombinations.length || 1} ${t(
                      'gridProducts.productVariantsTitle',
                    )}`}
                  >
                    <InlineRowInput>
                      <VariantOptionLabel>
                        {t('gridProducts.variantsSize')}
                      </VariantOptionLabel>
                      {getVariantOptionTagsInput({
                        fieldName: 'variantOptions.size',
                        placeholder: 'Add size option (press enter to submit)',
                        setValue,
                        currentFormValues: values,
                        customOnChange: handleVariantsOptionChange,
                        customOnChangeFieldName: 'size',
                        dataTestId: 'grid-products-details-variants-size'
                      })}
                    </InlineRowInput>
                    <Gap />
                    <InlineRowInput>
                      <VariantOptionLabel>
                        {t('gridProducts.variantsColor')}
                      </VariantOptionLabel>
                      {getVariantOptionTagsInput({
                        fieldName: 'variantOptions.color',
                        placeholder: 'Add color option (press enter to submit)',
                        setValue,
                        currentFormValues: values,
                        customOnChange: handleVariantsOptionChange,
                        customOnChangeFieldName: 'color',
                        dataTestId: 'grid-products-details-variants-color'
                      })}
                    </InlineRowInput>
                    <Gap />
                    <InlineRowInput>
                      <VariantOptionLabel>
                        {t('gridProducts.variantsStyle')}
                      </VariantOptionLabel>
                      {getVariantOptionTagsInput({
                        fieldName: 'variantOptions.style',
                        placeholder: 'Add style option (press enter to submit)',
                        setValue,
                        currentFormValues: values,
                        customOnChange: handleVariantsOptionChange,
                        customOnChangeFieldName: 'style',
                        dataTestId: 'grid-products-details-variants-style'
                      })}
                    </InlineRowInput>
                    <Divider />
                    <Table
                      expandIcon={() => null}
                      expandIconAsCell={false}
                      dataSource={values.variants || []}
                      rowKey={(_, index) => `variant-${index}`}
                      columns={variantsTableColumns(setValue)}
                      scroll={{ x: 'max-content' }}
                    />
                  </StyledPanelCard>
                </Col>

                <Col md={{ order: 1, span: 24 }} xl={8}>
                  <StyledPanelCard bodypadding={'16px 24px'}>
                    <Row>
                      <CardLabel>{t('environment')}</CardLabel>
                      <ProductEnvironmentSelect
                        isDisabled={!!currProduct}
                        options={envs}
                        value={env}
                        onChange={handleProductsEnvironmentChange}
                        dataTestId="grid-products-details-product-environment-select"
                      />
                    </Row>
                  </StyledPanelCard>

                  <LanguagePicker
                    defaultLanguages={initialLanguages}
                    onChange={handleLanguagesChange}
                    onAdd={handleAddLanguage}
                    onRemove={handleRemoveLanguage}
                  />

                  {/* Applicable to which spaces the product belongs to. Loaded dynamically based on "Spaces" field */}
                  <StyledPanelCard bodypadding={'16px 24px'}>
                    <CardLabel>{t('gridProducts.productOrganisation')}</CardLabel>

                    <MultiSelect
                      name="spaceIds"
                      label={t('spaces')}
                      isRequired
                      options={spacesDrowdownValues}
                      onChange={(selectedValues) => {
                        const newSpaceIds = selectedValues.map((selectedValue) => selectedValue.key);

                        // Product Status
                        const updatedProductStatus: ProductStatus[] = filterProductStatus(
                          newSpaceIds,
                          values.productStatus,
                        );

                        // Product Inventory
                        const updatedInventoryList = getAllSpacesVariantInventory({
                          currVariants: values.variants,
                          currProductItemQuantity:
                            values.productItemQuantityWithVariantOptions || [],
                          currSpaceIds: newSpaceIds,
                          fieldCheck: 'variantOptions',
                        });

                        // Product Standard Prices for applicable store specific data. No need to update "all" location scenario
                        const updatedPriceListStandard = getSpacesVariantPrice({
                          currVariants: values.variants,
                          currProductPriceList: values.productPriceListStandard || [],
                          currSpaceIds: newSpaceIds,
                          fieldCheck: 'variantOptions',
                        });

                        setValue('productStatus', updatedProductStatus);
                        setValue('productPriceListStandard', updatedPriceListStandard);
                        setValue(
                          'productItemQuantityWithVariantOptions',
                          updatedInventoryList,
                        );
                      }}
                      dataTestId="grid-products-details-product-spaceid"
                    />

                    <Gap />

                    <MultiSelect
                      name="productType"
                      label={t('gridProducts.titleCategories')}
                      options={productTypesDropdownValues}
                      isRequired
                      dataTestId="grid-products-details-product-categories"
                    />

                    <Gap />

                    {getProductTagsFieldInput({
                      label: t('gridProducts.productproductTagsTitle'),
                      placeholder: t('gridProducts.productTagsPlaceholder'),
                      supportedLanguages,
                      dataTestId: 'grid-products-details-product-tags',
                    })}

                    <Divider />

                    {getExtendableArraySection({
                      // onItemRemove: handleArrayFieldRemove,
                      mainPropertyName: 'productStatus',
                      required: true,
                      hideActionButtons: true,
                      content: (name, index) => {
                        const currSpace = spaces.find(
                          (item) =>
                            values.productStatus[index] &&
                            item.id === values.productStatus[index].spaceId,
                        );

                        if (!currSpace) {
                          return null;
                        }

                        return (
                          <>
                            {getSimpleSelect({
                              items: productStatusValues,
                              name: `${name}.productStatus`,
                              label: currSpace.displayName,
                              dataCy: `productStatus[${index}].productStatus`,
                            })}
                          </>
                        );
                      },
                    })}
                  </StyledPanelCard>
                </Col>
              </Content>
            </Overlay>
          </form>
        );
      }}
    />
  );
};

const Content = styled(Row)`
  margin-right: 8px !important;
  margin-left: 8px !important;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const Gap = styled.div`
  height: 16px;
`;

const InlineRowInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  width: 100%;
`;

const ArrayTopPicker = styled.div`
  margin-bottom: 12px;
`;

const VariantOptionLabel = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
  width: 80px;
`;

const AdvancedOptionsToggle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const InputStyled = styled(Input, transientOptions)<{
  $isVisible: boolean;
}>`
  ${({ $isVisible }) => ($isVisible ? '' : 'display: none;')}
`;

const LocalizedInputUnPadded = styled(LocalizedInput)`
  padding: 0 !important;
`;

const Table = styled(AntdTable)`
  width: 100%;

  .ant-table-tbody tr td {
    vertical-align: top;
  }

  .ant-table-body::-webkit-scrollbar {
    height: 20px;
  }
  .ant-table-body::-webkit-scrollbar-track {
    background: transparent;
  }
  .ant-table-body::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 20px;
    border: 7px solid transparent;
    background-clip: padding-box;
  }
  .ant-table-body::-webkit-scrollbar-thumb:hover {
    background: #7d7d7d;
    border: 7px solid transparent;
    background-clip: padding-box;
  }
`;

const CellText = styled.span`
  line-height: 32px;
`;

const CurrencySelect = styled(Select)`
  min-width: 100px;
`;

const MediaTypeSelect = styled(Select)`
  min-width: 100px;
`;

const MediaUrlInput = styled(Input)`
  flex-grow: 1;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const GeneralMoreOptionsContainer = styled('div', transientOptions)<{
  $isVisible: boolean;
}>`
  ${({ $isVisible }) => ($isVisible ? '' : 'display: none;')}
`;

export default GridProductsDetails;
