import React from 'react';
import { Field } from 'react-final-form';
import styled from '@emotion/styled';
import { Select } from 'antd';
import { LanguageOption } from '../../organisations/organisation-details/grid-products/language-picker';
import { IsoLanguageIds } from '@ombori/grid-products';
import { useTranslation } from 'react-i18next';
import { requiredAll } from '../../organisations/organisation-details/queue-details/queue-setup/validate-form';
import { SelectType } from '../../../types';
import InputWrap from './input-wrap';
import { SelectProps } from 'antd/lib/select';
import { Validator, composeValidators } from './validator';

type ChangeEvent = React.ChangeEvent<{ target: { value: IsoLanguageIds } }>;

interface LanguagePickerProps extends SelectProps {
  className?: string;
  name: string;
  label?: React.ReactNode;
  defaultLanguage?: IsoLanguageIds;
  supportedLanguages?: IsoLanguageIds[];
  isRequired?: boolean;
  validators?: Validator[];
}

const LanguagePicker = (props: LanguagePickerProps) => {
  const {
    className,
    name,
    label,
    defaultLanguage,
    supportedLanguages = [],
    isRequired = false,
    validators = [],
    ...restProps
  } = props;

  const { t } = useTranslation();

  const inputValidators = [...(isRequired ? [requiredAll] : []), ...validators];

  return (
    <Field name={name} validate={composeValidators(inputValidators)}>
      {({ input, meta }) => {
        const isError = !!meta.error && meta.touched;

        return (
          <InputWrap
            label={label}
            isRequired={isRequired}
            error={isError ? meta.error : undefined}
          >
            <SelectStyled
              {...restProps}
              className={className}
              showSearch
              value={input.value || defaultLanguage}
              placeholder={t('finalFormField.select')}
              onChange={(selectedValue) => {
                input.onChange(({
                  target: { value: selectedValue },
                } as unknown) as ChangeEvent);
              }}
              defaultValue={defaultLanguage}
            >
              {supportedLanguages.map((option) => (
                <Select.Option key={option}>
                  <LanguageOption option={option} hideFullLabel />
                </Select.Option>
              ))}
            </SelectStyled>
          </InputWrap>
        );
      }}
    </Field>
  );
};

const SelectStyled = styled(Select)`
  width: auto;
  .ant-select-selection {
    height: 32px;
  }
  .ant-select-selection-selected-value {
    height: 32px;

    div:first-of-type {
      margin: 0px;
    }
  }
` as SelectType<IsoLanguageIds>;

export default LanguagePicker;
