import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import styled from '@emotion/styled';
import { Button, Col, Row, message } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { StickyColumn } from '../../queue-details/queue-setup/styled-block';
import {
  Select,
  TextArea,
  getFieldNamesWithError,
  scrollIntoView,
} from '../../../../common/react-final-form';
import { useTranslation } from 'react-i18next';
import Message from '../../../../common/message';
import { ApiError } from '../../../../../services/api/api-error';
import Overlay from '../../../../common/overlay';
import useStoreAIAssistantsList from '../../../../../store/hooks/store-ai/use-store-ai-assistants-list';
import { useStoreAIAssistantUpdate } from '../../../../../store/hooks/store-ai/use-store-ai-assistant-update';
import { Divider, PanelCard } from '../common';
import { ButtonType } from '../../../../../types';
import StoreAIPlaygroundChatBox from './store-ai-playground-chat-box.component';
import { ChatAssistantRef, GridAssistant } from '@ombori/grid-llm-react';

export type StoreAIPlaygroundProps = RouteComponentProps<{
  organisationId: string;
  storeAIAssistantId: string;
}>;

export interface StoreAIPlaygroundFormValues {
  assistant: string;
  instructions: string;
}

const StoreAIPlayground = (props: StoreAIPlaygroundProps) => {
  const { match } = props;
  const { organisationId: tenantId } = match.params;

  const [selectedAssistant, setSelectedAssistant] = useState<GridAssistant>();
  const chatAssistantRef = useRef<ChatAssistantRef>(null);

  const { t } = useTranslation();

  const {
    data: assistantsListData,
    isLoading: isAssistantsListLoading,
    error: assistantsListError,
  } = useStoreAIAssistantsList({ tenantId, page: 1, limit: 50 }); // TODO: Fix me. If we allow more than 50, this will fail
  const assistants = assistantsListData ? assistantsListData.resources : [];

  const mappedAssistants = useMemo(() => {
    return assistants.map((assistant) => {
      return {
        value: assistant.id,
        label: assistant.name,
      };
    });
  }, [assistants]);

  const { mutateAsync: updateAssistant } = useStoreAIAssistantUpdate();

  const handleFormUpdateSubmit = useCallback(
    async (values) => {
      try {
        const params = values as StoreAIPlaygroundFormValues;

        const matchedAssistant = assistants.find(
          (assistant) => assistant.id === params.assistant,
        );

        if (!matchedAssistant) {
          throw Error('Invalid assistant');
        }

        const {
          tenantId,
          provider,
          name,
          description,
          instructions,
          status,
          knowledgeSourceIds,
        } = matchedAssistant;

        await updateAssistant({
          id: params.assistant,
          tenantId,
          provider,
          name,
          description,
          instructions: params.instructions || instructions,
          status,
          knowledgeSourceIds,
        });

        if (chatAssistantRef.current) {
          chatAssistantRef.current.restartThread();
        }

        message.success(<Message content={t('storeAI.successfullySavedAssistant')} />);
      } catch (error) {
        const err = (error as unknown) as ApiError;

        message.error(
          <Message content={t('storeAI.errorSavingAssistant')} error={err} />,
        );
      }
    },
    [assistants, t, updateAssistant],
  );

  const handleFormPreSubmit = useCallback(
    ({
      formEvent,
      isFormInvalid,
      errors,
      handleInternalFormSubmit,
    }: {
      formEvent: React.FormEvent<HTMLFormElement>;
      isFormInvalid: boolean;
      errors: Record<string, any>;
      handleInternalFormSubmit: (
        event?: React.SyntheticEvent<HTMLFormElement, Event> | undefined,
      ) => Promise<object | undefined> | undefined;
    }) => {
      handleInternalFormSubmit(formEvent);

      const errorFieldNames = getFieldNamesWithError(errors);

      if (!isFormInvalid || !errorFieldNames.length) {
        return;
      }

      scrollIntoView(errorFieldNames[0]);
    },
    [],
  );

  const handleAssistantChange = useCallback(
    (assistantId: string, setValue: (...args: any[]) => any) => {
      const matchedAssistant = assistants.find(
        (assistant) => assistant.id === assistantId,
      );

      if (!matchedAssistant) {
        return;
      }

      setValue('instructions', matchedAssistant.instructions);
      setSelectedAssistant(matchedAssistant);
    },
    [assistants],
  );

  const { id: assistantId = undefined, name: assistantName = undefined } = selectedAssistant || {};

  return (
    <OverlayStyled
      errorOverlay={{
        error: assistantsListError,
      }}
      spinnerOverlay={{
        isLoading: isAssistantsListLoading,
      }}
    >
      <StoreAIPlaygroundContainer>
        <RowStyled gutter={{ md: 20, xl: 40 }}>
          <ColStyled md={24} xl={15}>
            <StoreAIPlaygroundChatBox
              ref={chatAssistantRef}
              assistantId={assistantId}
              assistantName={assistantName}
            />
          </ColStyled>
          <StickyColumn md={24} xl={9}>
            <Form
              onSubmit={handleFormUpdateSubmit}
              keepDirtyOnReinitialize
              mutators={{
                ...arrayMutators,
                setValue: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value);
                },
              }}
              render={({
                submitting: isSubmitting,
                handleSubmit: handleInternalFormSubmit,
                invalid: isFormInvalid,
                errors,
                form: {
                  mutators: { setValue },
                },
              }) => {
                return (
                  <form
                    onSubmit={(formEvent) =>
                      handleFormPreSubmit({
                        formEvent,
                        isFormInvalid,
                        errors,
                        handleInternalFormSubmit,
                      })
                    }
                  >
                    <PanelCard>
                      <Row>
                        <Select
                          name="assistant"
                          label={t('storeAI.chooseAssistant')}
                          options={mappedAssistants}
                          isRequired
                          onChange={(value: string) =>
                            handleAssistantChange(value, setValue)
                          }
                        />
                      </Row>
                      {
                        selectedAssistant && (
                          <>
                            <Divider />
                            <Row>
                              <TextArea
                                name="instructions"
                                label={t('storeAI.instructions')}
                                placeholder={t('storeAI.assistantInstructionsPlaceholder')}
                              />
                            </Row>
                            <Divider />
                            <SaveButton
                              type="primary"
                              htmlType="submit"
                              size="large"
                              disabled={isSubmitting}
                              loading={isSubmitting}
                            >
                              {t('storeAI.saveAllChanges')}
                            </SaveButton>
                          </>
                        )
                      }
                    </PanelCard>
                  </form>
                );
              }}
            />
          </StickyColumn>
        </RowStyled>
      </StoreAIPlaygroundContainer>
    </OverlayStyled>
  );
};

const StoreAIPlaygroundContainer = styled.div`
  position: relative;
  flex: 1;
  padding: 20px 80px 0;
  height: inherit;

  @media screen and (max-width: 991px) {
    padding: 0;
  }
`;

const SaveButton = styled(Button)`
  width: 100%;
` as ButtonType;

const OverlayStyled = styled(Overlay)`
  height: 100%;
`;

const RowStyled = styled(Row)`
  height: inherit;
`;

const ColStyled = styled(Col)`
  height: inherit;
`;

export default StoreAIPlayground;
