import React, { useMemo } from 'react';
import groupBy from 'lodash/groupBy';
import toPairs from 'lodash/toPairs';
import sumBy from 'lodash/sumBy';
import orderBy from 'lodash/orderBy';
import head from 'lodash/head';
import { getProductName, IsoLanguageIds } from '@ombori/grid-products';
import { DataResidencyEnum } from '../../../../store/types/organisation';
import {
  useTenantProductsByDay,
  useInstallationProductsByDay,
  useSpaceProductsByDay,
  useDeviceProductsByDay,
  useAppProductsByDay,
} from '../../use-analytics-report';
import {
  TenantProductsByDay,
  InstallationProductsByDay,
  SpaceProductsByDay,
  DeviceProductsByDay,
  AppProductsByDay,
} from '../../use-analytics-report/aggregated-reports/types';
import { useVariantsList } from '../../use-grid-products';
import CardContainer from './card-container';
import PieChart from '../charts/pie';
import { GridStyles } from '@ombori/grid-reports';

type RawData =
  | TenantProductsByDay
  | InstallationProductsByDay
  | SpaceProductsByDay
  | DeviceProductsByDay
  | AppProductsByDay;

type CardDataItem = [string, { label: string; value: number }[]];

type CardData = CardDataItem[];

const buildDataItem = (
  date: string,
  data: { label: string; value: number }[],
): CardDataItem => [date, data];

const maxEventsCount = 7;

const aggregateData = (
  rawData: RawData[],
  eventType: string,
): { productId: string; count: number }[] => {
  const filteredData = rawData.filter((dataItem) => dataItem.eventType === eventType);

  const aggregatedEvents = orderBy(
    toPairs(groupBy(filteredData, (dataItem) => dataItem.productId)).map(
      ([productId, events]) => {
        const result = {
          productId,
          count: sumBy(events, (event) => event.count),
        };

        return result;
      },
    ),
    (dataItem) => dataItem.count,
    'desc',
  );

  return aggregatedEvents;
};

const buildData = (rawData: { productId: string; count: number }[]): CardData => {
  const result = rawData.map((dataItem) => {
    const list = [{ label: dataItem.productId, value: dataItem.count }];

    const result = buildDataItem(dataItem.productId, list);

    return result;
  });

  return result;
};

interface UseCardDataProps {
  tenantId: string;
  dataResidency: DataResidencyEnum;
  rawData: RawData[];
  eventType: string;
}

interface UseCardDataResult {
  data: CardData;
  totalEventsCount: number;
}

const useCardData = ({
  tenantId,
  dataResidency,
  rawData,
  eventType,
}: UseCardDataProps): UseCardDataResult => {
  const aggregatedEvents = useMemo(() => aggregateData(rawData, eventType), [
    rawData,
    eventType,
  ]);

  const topEvents = aggregatedEvents.slice(0, maxEventsCount);

  const variantsListState = useVariantsList({
    productIds: topEvents.map((event) => event.productId),
    dataResidency,
    tenantId,
  });

  const cardData = useMemo(() => {
    const variantsList = variantsListState.data ? variantsListState.data.data : [];

    const firstVariant = head(variantsList);

    const productName = firstVariant ? firstVariant.productName : undefined;

    const firstProductName = head(productName);

    const languageId = firstProductName
      ? firstProductName.isoLanguageId
      : IsoLanguageIds.en_GB;

    const newData = topEvents.map((event) => {
      const productVariant = variantsList.find(
        (variant) => variant.productId === event.productId,
      );

      if (!productVariant) {
        return event;
      }

      const productVariantName = getProductName(productVariant, languageId);

      if (!productVariantName) {
        return event;
      }

      return {
        ...event,
        productId: productVariantName.productName,
      };
    });

    const result = buildData(newData);

    return result;
  }, [topEvents, variantsListState]);

  const totalEventsCount = sumBy(aggregatedEvents, (event) => event.count);

  const result = useMemo(
    () => ({
      data: cardData,
      totalEventsCount,
    }),
    [cardData, totalEventsCount],
  );

  return result;
};

interface ProductsEventCountProps {
  tenantId: string;
  dateFrom: string;
  dateTo: string;
  title: string;
  eventType: string;
  dataResidency: DataResidencyEnum;
  gridStyles?: GridStyles;
  isVisibleWithoutData?: boolean;
}

export const TenantProductsEventCount: React.FC<ProductsEventCountProps> = ({
  tenantId,
  dateFrom,
  dateTo,
  title,
  eventType,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const tenantProductsEventsState = useTenantProductsByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const { data, totalEventsCount } = useCardData({
    rawData: tenantProductsEventsState.data || [],
    eventType,
    tenantId,
    dataResidency,
  });

  return (
    <CardContainer
      isLoading={tenantProductsEventsState.isLoading}
      isSuccess={tenantProductsEventsState.isSuccess}
      isError={tenantProductsEventsState.isError}
      hasData={data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {tenantProductsEventsState.isSuccess && (
        <PieChart title={title} data={data} total={totalEventsCount} />
      )}
    </CardContainer>
  );
};

interface InstallationProductsEventCountProps extends ProductsEventCountProps {
  installationId: string;
}

export const InstallationProductsEventCount: React.FC<
  InstallationProductsEventCountProps
> = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  title,
  eventType,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const installationProductsEventsState = useInstallationProductsByDay({
    tenantId,
    installationId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const { data, totalEventsCount } = useCardData({
    rawData: installationProductsEventsState.data || [],
    eventType,
    tenantId,
    dataResidency,
  });

  return (
    <CardContainer
      isLoading={installationProductsEventsState.isLoading}
      isSuccess={installationProductsEventsState.isSuccess}
      isError={installationProductsEventsState.isError}
      hasData={data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {installationProductsEventsState.isSuccess && (
        <PieChart title={title} data={data} total={totalEventsCount} />
      )}
    </CardContainer>
  );
};

interface SpaceProductsEventCountProps extends ProductsEventCountProps {
  spaceId: string;
}

export const SpaceProductsEventCount: React.FC<SpaceProductsEventCountProps> = ({
  tenantId,
  spaceId,
  dateFrom,
  dateTo,
  title,
  eventType,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const spaceProductsEventsState = useSpaceProductsByDay({
    tenantId,
    spaceId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const { data, totalEventsCount } = useCardData({
    rawData: spaceProductsEventsState.data || [],
    eventType,
    tenantId,
    dataResidency,
  });

  return (
    <CardContainer
      isLoading={spaceProductsEventsState.isLoading}
      isSuccess={spaceProductsEventsState.isSuccess}
      isError={spaceProductsEventsState.isError}
      hasData={data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {spaceProductsEventsState.isSuccess && (
        <PieChart title={title} data={data} total={totalEventsCount} />
      )}
    </CardContainer>
  );
};

interface DeviceProductsEventCountProps extends ProductsEventCountProps {
  deviceId: string;
}

export const DeviceProductsEventCount: React.FC<DeviceProductsEventCountProps> = ({
  tenantId,
  deviceId,
  dateFrom,
  dateTo,
  title,
  eventType,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const deviceProductsEventsState = useDeviceProductsByDay({
    tenantId,
    deviceId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const { data, totalEventsCount } = useCardData({
    rawData: deviceProductsEventsState.data || [],
    eventType,
    tenantId,
    dataResidency,
  });

  return (
    <CardContainer
      isLoading={deviceProductsEventsState.isLoading}
      isSuccess={deviceProductsEventsState.isSuccess}
      isError={deviceProductsEventsState.isError}
      hasData={data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {deviceProductsEventsState.isSuccess && (
        <PieChart title={title} data={data} total={totalEventsCount} />
      )}
    </CardContainer>
  );
};

interface AppProductsEventCountProps extends ProductsEventCountProps {
  appId: string;
}

export const AppProductsEventCount: React.FC<AppProductsEventCountProps> = ({
  tenantId,
  appId,
  dateFrom,
  dateTo,
  title,
  eventType,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const appProductsEventsState = useAppProductsByDay({
    tenantId,
    appId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const { data, totalEventsCount } = useCardData({
    rawData: appProductsEventsState.data || [],
    eventType,
    tenantId,
    dataResidency,
  });

  return (
    <CardContainer
      isLoading={appProductsEventsState.isLoading}
      isSuccess={appProductsEventsState.isSuccess}
      isError={appProductsEventsState.isError}
      hasData={data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {appProductsEventsState.isSuccess && (
        <PieChart title={title} data={data} total={totalEventsCount} />
      )}
    </CardContainer>
  );
};
