import { Field } from 'react-final-form';
import React, { FC } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import styled from '@emotion/styled';
import { FieldArray } from 'react-final-form-arrays';
import { FormTextInput, Label, PanelCardMain } from './styled-block';
import defaultSelectedLanguageForTextCustomization from './text-in-app-customization';

interface CustomInfoFieldsProps {
  translationKey: string;
  nameProperty: string;
}

const CustomInfoFields: FC<CustomInfoFieldsProps> = ({
  translationKey,
  nameProperty,
}) => {
  const { t } = useTranslation();

  const addNewCustomField = {
    key: uuidv4(),
    type: 'text',
    data: {
      [defaultSelectedLanguageForTextCustomization]: {
        icon: '',
        value: '',
      },
    },
  };

  return (
    <>
      <Label>{t(translationKey)}</Label>
      <PanelCardMain>
        <FieldArray
          key={`customFields[${nameProperty}]`}
          name={`customFields[${nameProperty}]`}
        >
          {({ fields }) => (
            <>
              {(fields || []).map((indexedTagName, index) => (
                <TagContainer key={`${indexedTagName}`}>
                  <WrapHeader>
                    <Header>
                      {t('fieldHeader')} №{index + 1}{' '}
                    </Header>
                    <Button onClick={() => fields.remove(index)} icon="minus" />
                  </WrapHeader>

                  <WrapFields>
                    <Field
                      name={`${indexedTagName}.data[${defaultSelectedLanguageForTextCustomization}].icon`}
                    >
                      {({ input: filedInput, meta }) => {
                        return (
                          <FormTextInput
                            label={t('iconUrlLabel')}
                            input={filedInput}
                            meta={meta}
                          />
                        );
                      }}
                    </Field>
                    <Field
                      name={`${indexedTagName}.data[${defaultSelectedLanguageForTextCustomization}].value`}
                    >
                      {({ input: filedInput, meta }) => {
                        return (
                          <FormTextInput
                            label={t('textInfoLabel')}
                            input={filedInput}
                            meta={meta}
                          />
                        );
                      }}
                    </Field>
                  </WrapFields>
                </TagContainer>
              ))}
              <Button onClick={() => fields.push(addNewCustomField)} icon="plus" />
            </>
          )}
        </FieldArray>
      </PanelCardMain>
    </>
  );
};

const Header = styled.div`
  margin-right: 16px;
  font-weight: bold;
`;

const WrapHeader = styled.div`
  display: flex;
  align-items: center;
`;

const TagContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const WrapFields = styled.div`
  margin-right: 16px;
`;

export default CustomInfoFields;
