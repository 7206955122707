import { useCallback } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { KnowledgeSourcePaginatedList, useGridLlmService } from '@ombori/grid-llm-react';
import { ApiError } from '../../../services/api/api-error';

type UseStoreAiKnowledgeBaseFileListResponse = UseQueryResult<
  KnowledgeSourcePaginatedList,
  ApiError
>;

export interface UseStoreAiKnowledgeBaseFileListProps {
  tenantId: string;
  page?: number;
  limit?: number;
}

const useStoreAiKnowledgeBaseFileList = ({
  tenantId,
  page = 1,
  limit = 10,
}: UseStoreAiKnowledgeBaseFileListProps): UseStoreAiKnowledgeBaseFileListResponse => {
  const gridLlmService = useGridLlmService();

  const fetch = useCallback(async () => {
    return await gridLlmService.getKnowledgeSourceList({
      tenantId,
      page,
      limit,
    });
  }, [gridLlmService, limit, page, tenantId]);

  const result = useQuery(
    getStoreAiKnowledgeBaseFileListKeys({ tenantId, page, limit }),
    () => fetch(),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      keepPreviousData: true,
      onError: (_error: ApiError) => {},
    },
  );

  return result;
};

export const getStoreAiKnowledgeBaseFileListKeys = ({
  tenantId,
  page,
  limit,
}: Partial<UseStoreAiKnowledgeBaseFileListProps>): (string | number)[] => {
  return [
    'get-store-ai-knowledge-base-file-list',
    ...(tenantId ? ['tenantId', tenantId] : []),
    ...(page ? ['page', page] : []),
    ...(limit ? ['limit', limit] : []),
  ];
};

export default useStoreAiKnowledgeBaseFileList;
