import { useQuery } from 'react-query';
import { useApiService } from '../api-service-provider';
import Gridapp from '../../../store/types/gridapp';

const useTenantAndMarketplaceGridapps = (organizationId: string) => {
  const apiService = useApiService();

  return useQuery(
    ['organizationId'],
    () =>
      apiService.get<Gridapp[]>('/api/gridapps', {
        organizationId,
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity
    },
  );
};

export default useTenantAndMarketplaceGridapps;
