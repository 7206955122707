import { ActiveFilter } from '../../../../store/types/filters';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import { PaginationSearchParam } from '../../../../store/types/pagination';

export const transformActiveFiltersToSearchParams = (
  filters: ActiveFilter[],
  lastRemovedFilter?: ActiveFilter | null,
): PaginationSearchParam => {

  const filterTypeIds = groupBy(filters, 'typeId');
  
  const transformedFilterParams = Object.entries(filterTypeIds).map(([id, value]) => {
    const values = Object.keys(keyBy(value, 'valueId'));
    return { [id]: values.length ? values.join(',') : undefined };
  });

  if (
    lastRemovedFilter &&
    !Object.keys(filterTypeIds).includes(lastRemovedFilter.typeId)
  ) {
    transformedFilterParams.push({ [lastRemovedFilter.typeId]: undefined });
  }

  return Object.assign({}, ...transformedFilterParams);
};
