import { useEffect } from 'react';
import Environment from '../../../store/types/environment';
import { useStoreActions, useStoreState } from '../../../store/initialize-store';

interface UseEnvironmentsProps {
  tenantId: string;
}

// TO DO: Deprecated. Retaining for the existing components that use this (for refactoring in the future).
//  Moving forward, the new react-query based useEnvironments should be used.
const useEnvironments = ({ tenantId }: UseEnvironmentsProps): Environment[] => {
  const envs = useStoreState((state) => state.environments.values(tenantId));

  const { fetchEnvironments } = useStoreActions((actions) => ({
    fetchEnvironments: actions.environments.fetch,
  }));

  useEffect(() => {
    if (!envs || envs.length === 0) {
      fetchEnvironments({ organizationId: tenantId });
    }
  }, [envs, fetchEnvironments, tenantId]);

  return envs;
};

export default useEnvironments;
