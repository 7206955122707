// copied from https://github.com/ant-design/ant-design-icons/issues/227
const AntIcons = [
  'account-book',
  'alert',
  'api',
  'appstore',
  'audio',
  'bank',
  'bell',
  'book',
  'box-plot',
  'bug',
  'build',
  'bulb',
  'calculator',
  'calendar',
  'camera',
  'car',
  'carry-out',
  'check-circle',
  'check-square',
  'clock-circle',
  'close-circle',
  'close-square',
  'cloud',
  'code',
  'compass',
  'contacts',
  'container',
  'control',
  'copy',
  'credit-card',
  'crown',
  'customer-service',
  'dashboard',
  'database',
  'delete',
  'diff',
  'dislike',
  'down-circle',
  'down-square',
  'edit',
  'environment',
  'exclamation-circle',
  'experiment',
  'eye-invisible',
  'eye',
  'file-add',
  'file-excel',
  'file-exclamation',
  'file-image',
  'file-markdown',
  'file-pdf',
  'file-ppt',
  'file-text',
  'file-unknown',
  'file-word',
  'file-zip',
  'file',
  'filter',
  'fire',
  'flag',
  'folder-add',
  'folder-open',
  'folder',
  'frown',
  'fund',
  'funnel-plot',
  'gift',
  'gold',
  'hdd',
  'heart',
  'highlight',
  'home',
  'hourglass',
  'html5',
  'idcard',
  'info-circle',
  'insurance',
  'interaction',
  'layout',
  'left-circle',
  'left-square',
  'like',
  'lock',
  'mail',
  'medicine-box',
  'meh',
  'message',
  'minus-circle',
  'minus-square',
  'mobile',
  'money-collect',
  'notification',
  'pause-circle',
  'phone',
  'picture',
  'pie-chart',
  'play-circle',
  'play-square',
  'plus-circle',
  'plus-square',
  'pound-circle',
  'printer',
  'profile',
  'project',
  'property-safety',
  'pushpin',
  'question-circle',
  'reconciliation',
  'red-envelope',
  'rest',
  'right-circle',
  'right-square',
  'rocket',
  'safety-certificate',
  'save',
  'schedule',
  'security-scan',
  'setting',
  'shop',
  'shopping',
  'skin',
  'sliders',
  'smile',
  'snippets',
  'sound',
  'star',
  'stop',
  'switcher',
  'tablet',
  'tag',
  'tags',
  'thunderbolt',
  'tool',
  'trophy',
  'unlock',
  'up-circle',
  'up-square',
  'usb',
  'video-camera',
  'wallet',
  'warning',
  'alipay-circle',
  'aliwangwang',
  'android',
  'apple',
  'backward',
  'behance-square',
  'ci',
  'caret-down',
  'caret-left',
  'caret-right',
  'caret-up',
  'copyright',
  'chrome',
  'dollar',
  'codepen-circle',
  'euro',
  'dribbble-square',
  'facebook',
  'fast-backward',
  'fast-forward',
  'forward',
  'github',
  'gitlab',
  'instagram',
  'linkedin',
  'pay-circle',
  'read',
  'robot',
  'skype',
  'slack-square',
  'step-backward',
  'step-forward',
  'taobao-circle',
  'wechat',
  'weibo-circle',
  'weibo-square',
  'windows',
  'yahoo',
  'youtube',
  'yuque',
  'alibaba',
  'align-center',
  'align-left',
  'align-right',
  'alipay',
  'aliyun',
  'amazon',
  'ant-cloud',
  'ant-design',
  'apartment',
  'area-chart',
  'arrow-down',
  'arrow-left',
  'arrow-right',
  'arrow-up',
  'arrows-alt',
  'audit',
  'bar-chart',
  'barcode',
  'bars',
  'behance',
  'bg-colors',
  'block',
  'bold',
  'border-bottom',
  'border-horizontal',
  'border-inner',
  'border-left',
  'border-outer',
  'border-right',
  'border-top',
  'border-verticle',
  'border',
  'borderless-table',
  'branches',
  'check',
  'close',
  'cloud-download',
  'cloud-server',
  'cloud-sync',
  'cloud-upload',
  'cluster',
  'code-sandbox',
  'codepen',
  'coffee',
  'column-height',
  'column-width',
  'dash',
  'deployment-unit',
  'desktop',
  'dingding',
  'disconnect',
  'dot-chart',
  'double-left',
  'double-right',
  'down',
  'download',
  'drag',
  'dribbble',
  'dropbox',
  'ellipsis',
  'enter',
  'exception',
  'exclamation',
  'export',
  'fall',
  'file-done',
  'file-jpg',
  'file-protect',
  'file-search',
  'file-sync',
  'font-colors',
  'font-size',
  'fork',
  'form',
  'fullscreen-exit',
  'fullscreen',
  'gateway',
  'global',
  'google-plus',
  'google',
  'heat-map',
  'history',
  'ie',
  'import',
  'inbox',
  'info',
  'issues-close',
  'italic',
  'key',
  'laptop',
  'left',
  'line-chart',
  'line-height',
  'line',
  'link',
  'loading-3-quarters',
  'loading',
  'login',
  'logout',
  'man',
  'medium-workmark',
  'medium',
  'menu-fold',
  'menu-unfold',
  'menu',
  'merge-cells',
  'minus',
  'monitor',
  'more',
  'number',
  'ordered-list',
  'paper-clip',
  'pause',
  'percentage',
  'pic-center',
  'pic-left',
  'pic-right',
  'plus',
  'pound',
  'poweroff',
  'pull-request',
  'qq',
  'qrcode',
  'question',
  'radar-chart',
  'radius-bottomleft',
  'radius-bottomright',
  'radius-setting',
  'radius-upleft',
  'radius-upright',
  'reddit',
  'redo',
  'reload',
  'retweet',
  'right',
  'rise',
  'rollback',
  'safety',
  'scan',
  'scissor',
  'search',
  'select',
  'shake',
  'share-alt',
  'shopping-cart',
  'shrink',
  'sketch',
  'slack',
  'small-dash',
  'solution',
  'sort-ascending',
  'sort-descending',
  'stock',
  'strikethrough',
  'swap-left',
  'swap-right',
  'swap',
  'sync',
  'table',
  'taobao',
  'team',
  'to-top',
  'trademark',
  'transaction',
  'twitter',
  'underline',
  'undo',
  'unordered-list',
  'up',
  'upload',
  'user-add',
  'user-delete',
  'user',
  'usergroup-add',
  'usergroup-delete',
  'vertical-align-bottom',
  'vertical-align-middle',
  'vertical-align-top',
  'vertical-left',
  'vertical-right',
  'weibo',
  'wifi',
  'woman',
  'zhihu',
  'zoom-in',
  'zoom-out',
];

export default AntIcons;
