import { useQuery } from 'react-query';
import { useApiService } from '../../../app/common/api-service-provider';
import segmentQueryKeys from './segment-query-keys';
import { SegmentConditions } from './types';
import Device from '../../types/device';

const useSegmentContentTargetingDevices = (tenantId: string, conditions: SegmentConditions | undefined) => {
  const apiService = useApiService();

  const conditionsStr = JSON.stringify(conditions);

  return useQuery(
    segmentQueryKeys.conditions(tenantId, conditionsStr),
    () => apiService.get<Device[]>(`/api/device-count`, { tenantId, conditions: conditionsStr }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      enabled: conditions && conditions.length ? true : false,
    },
  );
};

export default useSegmentContentTargetingDevices;
