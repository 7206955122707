import React from 'react';
import styled from '@emotion/styled';
import ReactCountryFlag from 'react-country-flag';
import LocaleCode from 'locale-code';

const OptionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 6px 8px;
`;

const Label = styled.div`
  text-align: flex-start;
  font-size: 14px;
`;

const FlagIconWrapper = styled.div`
  align-items: center;
  justify-content: center;
  margin-top: -1px;
`;

const countryFlagStyle = {
  width: '24px',
  height: '16px',
  backgroundSize: 'cover',
  borderWidth: '2px',
  borderColor: 'blue',
  marginRight: '8px',
};

interface MultiLanguageLabelProps {
  className?: string;
  language: string;
}

const MultiLanguageLabel = (props: MultiLanguageLabelProps) => {
  const { className, language } = props;

  return (
    <OptionWrapper className={className}>
      <FlagIconWrapper>
        <ReactCountryFlag
          svg
          code={LocaleCode.getCountryCode(language)}
          styleProps={countryFlagStyle}
        />
      </FlagIconWrapper>
      <Label>{language}</Label>
    </OptionWrapper>
  );
};

export default React.memo(MultiLanguageLabel);
