import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Row, Button, Modal, message } from 'antd';
import PanelCard from '../../../../../common/panel-card/panel-card.component';
import useModal from '../../../../../common/use-modal';
import ProfessionalServicesUnit from '../../../../../../store/types/professional-services-unit';
import Organisation, {
  BillingCurrencyEnum,
} from '../../../../../../store/types/organisation';
import { formatAmount } from '../../../../../../utils/currency';

interface ProfessionalServicesProps {
  organizationId: string;
  organisation: Organisation | null;
  createProfessionalServicesUnit: (payload: {
    key: string;
    data: Partial<ProfessionalServicesUnit>;
  }) => Promise<ProfessionalServicesUnit>;
}

const ProfessionalServices = (props: ProfessionalServicesProps) => {
  const { organizationId, organisation, createProfessionalServicesUnit } = props;
  const { t, i18n } = useTranslation();

  const [isCreating, setIsCreating] = React.useState<boolean>(false);

  const currency = (organisation && organisation.currency) || BillingCurrencyEnum.EUR;

  const schema = {
    type: 'object',
    properties: {
      description: {
        type: 'string',
        minLength: 1,
        title: t('billing.createProfessionalServicesUnitModalFieldDescription'),
      },
      quantity: {
        type: 'number',
        min: 1,
        title: t('billing.createProfessionalServicesUnitModalFieldAmount', { currency }),
      },
    },
    required: ['description', 'quantity'],
  };

  const createNewUnit = React.useCallback(
    async ({ description, quantity }) => {
      const modal = Modal.confirm({
        title: t('confirmAction'),
        content: (
          <ModalContent>
            {t('billing.createProfessionalServicesUnitConfirmation', {
              price: formatAmount(currency, quantity, i18n.language),
            })}
          </ModalContent>
        ),
        width: '30%',
        okText: t('billing.createProfessionalServicesUnitConfirmationOk'),
        cancelText: t('billing.createProfessionalServicesUnitConfirmationCancel'),
        className: 'modal-content-m-0',
        onOk: async () => {
          modal.update({ cancelButtonProps: { disabled: true } });
          setIsCreating(true);
          try {
            const data = {
              description,
              quantity,
              date: new Date().toISOString(),
            };
            await createProfessionalServicesUnit({ key: organizationId, data });

            const msg = t('billing.createProfessionalServicesUnitSuccess');
            modal.destroy();
            message.success(msg);
          } catch (err) {
            message.error(t('billing.createProfessionalServicesUnitFailure'));
          } finally {
            setIsCreating(false);
            modal.update({ cancelButtonProps: { disabled: false } });
          }
        },
        onCancel: () => {},
      });
    },
    [createProfessionalServicesUnit, currency, i18n.language, organizationId, t],
  );

  const [unitModal, showUnitModal] = useModal({
    title: t('billing.createProfessionalServicesUnitModalTitle'),
    schema,
    onSubmit: createNewUnit,
    showMessage: false,
  });

  return (
    <PanelCardStyled title={t('billing.professionalServicesSectionTitle')}>
      <Content>
        <Row>
          <Button size="large" icon="plus" onClick={showUnitModal} disabled={isCreating}>
            {t('add')}
          </Button>
        </Row>
      </Content>
      {unitModal}
    </PanelCardStyled>
  );
};

const PanelCardStyled = styled(PanelCard)`
  height: auto;

  .ant-card-head {
    border-bottom: 0px;
  }

  .ant-card-head-title {
    font-size: 18px;
    font-weight: 400;

    padding-bottom: 0px;
  }

  .ant-card-body {
    padding: 0;
    min-height: 80px;
    position: relative;
  }
`;

const Content = styled.div`
  padding: 16px 24px 16px 24px;
`;

const ModalContent = styled.div`
  margin-top: 20px;
`;

export default ProfessionalServices;
