export enum PipelineStatus {
  Queued = 'queued',
  Building = 'building',
  Done = 'done',
  Failed = 'error',
}

export interface PipelineWithBuildNumber extends Pipeline {
  data: {
    buildNumber: string;
  };
}

export default interface Pipeline {
  environmentName: string;
  requiredForDeploy: boolean;
  status: PipelineStatus;
  type: string;
  url: string;
  data?: {
    queueTime?: string;
    buildNumber?: string;
  };
  metadata?: {
    url?: string;
  };
}
