import { useMutation, UseMutationResult } from 'react-query';
import { ApiError } from '../../../../../services/api/api-error';
import { useGridProductsService } from '../../../../common/grid-products-service-provider';
import { DataResidencyEnum } from '../../../../../store/types/organisation';
import { GridProductData } from '../types';
import { GridProduct } from '@ombori/grid-products';

interface UseAddGridProductProps {
  tenantId: string;
  dataResidency: DataResidencyEnum;
  environment: string;
}

type UseAddGridProductResponse = UseMutationResult<
  GridProductData,
  ApiError,
  GridProduct
>;

const useAddGridProduct = (props: UseAddGridProductProps): UseAddGridProductResponse => {
  const { tenantId, dataResidency, environment } = props;

  const gridProductsService = useGridProductsService();

  const result = useMutation(
    async (body: GridProduct) => {
      const url = `/${tenantId}/${environment}/admin/products`;

      return gridProductsService.post<GridProductData>(url, dataResidency, {
        data: body,
      });
    },
    {
      onError: (_error: ApiError) => {},
    },
  );

  return result;
};

export default useAddGridProduct;
