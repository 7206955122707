import React, { FC } from 'react';
import styled from '@emotion/styled';
import {
  UniversalDeviceModuleStatus,
  UniversalDeviceModuleTelemetryOs,
} from '../../../../store/types/universal-device';
import DeviceStatusIndicator from '../device-universal-status-indicator/device-universal-status-indicator.component';

interface DeviceTypeCellProps {
  status: UniversalDeviceModuleStatus;
  os: UniversalDeviceModuleTelemetryOs;
}

const DeviceTypeCell: FC<DeviceTypeCellProps> = (props) => {
  const { status, os } = props;

  return (
    <Container>
      <Column>
        <DeviceStatusIndicator status={status} />
      </Column>
      <Column>{os.os}</Column>
      <Column>{os.arch}</Column>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 6px 134px 120px;
  grid-column-gap: 8px;
`;

const Column = styled.div`
  display: flex;
  align-items: center;
`;

export default DeviceTypeCell;
