import React, { useCallback, useMemo, useState } from 'react';
import { DataResidencyEnum } from '../../../../store/types/organisation';
import { GridStyles } from '@ombori/grid-reports';
import styled from '@emotion/styled';
import { Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts';
import {
  TenantPurchasesByDay,
  InstallationPurchasesByDay,
  DevicePurchasesByDay,
  SpacePurchasesByDay,
  AppPurchasesByDay,
  useTenantPurchasesByDay,
  useInstallationPurchasesByDay,
  useSpacePurchasesByDay,
  useDevicePurchasesByDay,
  useAppPurchasesByDay,
} from '../../use-analytics-report';
import CardContainer from './card-container';
import { useTranslation } from 'react-i18next';
import groupBy from 'lodash/groupBy';
import keys from 'lodash/keys';
import uniq from 'lodash/uniq';
import sumBy from 'lodash/sumBy';
import sortBy from 'lodash/sortBy';
import { Select, Form } from 'antd';

const { Option } = Select;

type RawData =
  | TenantPurchasesByDay
  | InstallationPurchasesByDay
  | SpacePurchasesByDay
  | DevicePurchasesByDay
  | AppPurchasesByDay;

const buildData = (rawData: RawData[]) => {
  const groupedEvents = groupBy(rawData, (dataItem) => dataItem.date);

  const result = sortBy(keys(groupedEvents)).map((date) => {
    const events = groupedEvents[date];

    const totalRevenue = sumBy(events, (event) => event.revenue);

    const avgRevenue = Math.round(totalRevenue / events.length / 1000);

    const result = {
      date,
      avgRevenue,
    };

    return result;
  });

  return result;
};

const useCurrencyList = (data: RawData[]): string[] => {
  const uniqueCurrencies = useMemo(() => {
    const currencies = data.map((item) => item.currency);

    const result = uniq(currencies);

    return result;
  }, [data]);

  return uniqueCurrencies;
};

const useCurrency = (data: RawData[]) => {
  const currencyList = useCurrencyList(data);

  const [currency, setCurrency] = useState(currencyList[0] || '');

  const handleChange = useCallback((value: string) => {
    setCurrency(value);
  }, []);

  const result = useMemo(
    () => ({
      currency,
      currencyList,
      handleChange,
    }),
    [currency, handleChange, currencyList],
  );

  return result;
};

const Container = styled.div``;

const Header = styled.div``;

const Body = styled.div``;

const Footer = styled.div`
  border-top: 1px solid rgb(232, 232, 232);
  display: grid;
  grid-auto-flow: column;
  padding: 8px 0;
  margin: 30px 0 0;
  column-gap: 16px;
  -webkit-box-pack: start;
  justify-content: start;
`;

interface AverageSalesProps {
  data: RawData[];
}

const AverageSales = ({ data }: AverageSalesProps) => {
  const { t } = useTranslation();

  const { currency, currencyList, handleChange } = useCurrency(data);

  const currencyData = data.filter((item) => item.currency === currency);

  const newData = buildData(currencyData);

  const chartData = newData.map(({ date, avgRevenue }) => {
    return {
      date,
      [currency]: avgRevenue,
    };
  });

  const totalAverage = Math.round(
    sumBy(newData, (item) => item.avgRevenue) / newData.length,
  );

  return (
    <Container>
      <Header>
        <Form layout="vertical">
          <Form.Item label={t('currency')}>
            <Select
              defaultValue={currency}
              style={{ width: 120 }}
              onChange={handleChange}
            >
              {currencyList.map((currency) => (
                <Option key={currency} value={currency}>
                  {currency}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Header>

      <Body>
        <ResponsiveContainer width="100%" height={80}>
          <AreaChart data={chartData} margin={{ top: 0, bottom: 0, left: 0, right: 0 }}>
            <Tooltip
              labelFormatter={(i: string | number) => {
                if (typeof i === 'string') {
                  return i;
                }

                const label = chartData[i] ? chartData[i].date : '';

                return label;
              }}
            />
            <Area
              key={currency}
              dataKey={currency}
              type="basis"
              stroke="#3772e0"
              strokeWidth={2}
              fill="#3772e0"
              fillOpacity={0.2}
            />
          </AreaChart>
        </ResponsiveContainer>
      </Body>

      <Footer>
        {t('averageSalesForPeriod')}: {currency} {totalAverage}
      </Footer>
    </Container>
  );
};

interface AverageSalesBaseProps {
  tenantId: string;
  dateFrom: string;
  dateTo: string;
  dataResidency: DataResidencyEnum;
  gridStyles?: GridStyles;
  isVisibleWithoutData?: boolean;
}

export const TenantAverageSales = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}: AverageSalesBaseProps) => {
  const { t } = useTranslation();

  const purchasesFetchingState = useTenantPurchasesByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const data = useMemo(() => purchasesFetchingState.data || [], [purchasesFetchingState]);

  return (
    <CardContainer
      isLoading={purchasesFetchingState.isLoading}
      isSuccess={purchasesFetchingState.isSuccess}
      isError={purchasesFetchingState.isError}
      hasData={data.length > 0}
      title={t('averageSales')}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {purchasesFetchingState.isSuccess && <AverageSales data={data} />}
    </CardContainer>
  );
};

interface InstallationAverageSalesProps extends AverageSalesBaseProps {
  installationId: string;
}

export const InstallationAverageSales = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
  installationId,
}: InstallationAverageSalesProps) => {
  const { t } = useTranslation();

  const purchasesFetchingState = useInstallationPurchasesByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
    installationId,
  });

  const data = useMemo(() => purchasesFetchingState.data || [], [purchasesFetchingState]);

  return (
    <CardContainer
      isLoading={purchasesFetchingState.isLoading}
      isSuccess={purchasesFetchingState.isSuccess}
      isError={purchasesFetchingState.isError}
      hasData={data.length > 0}
      title={t('averageSales')}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {purchasesFetchingState.isSuccess && <AverageSales data={data} />}
    </CardContainer>
  );
};

interface SpaceAverageSalesProps extends AverageSalesBaseProps {
  spaceId: string;
}

export const SpaceAverageSales = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
  spaceId,
}: SpaceAverageSalesProps) => {
  const { t } = useTranslation();

  const purchasesFetchingState = useSpacePurchasesByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
    spaceId,
  });

  const data = useMemo(() => purchasesFetchingState.data || [], [purchasesFetchingState]);

  return (
    <CardContainer
      isLoading={purchasesFetchingState.isLoading}
      isSuccess={purchasesFetchingState.isSuccess}
      isError={purchasesFetchingState.isError}
      hasData={data.length > 0}
      title={t('averageSales')}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {purchasesFetchingState.isSuccess && <AverageSales data={data} />}
    </CardContainer>
  );
};

interface DeviceAverageSalesProps extends AverageSalesBaseProps {
  deviceId: string;
}

export const DeviceAverageSales = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
  deviceId,
}: DeviceAverageSalesProps) => {
  const { t } = useTranslation();

  const purchasesFetchingState = useDevicePurchasesByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
    deviceId,
  });

  const data = useMemo(() => purchasesFetchingState.data || [], [purchasesFetchingState]);

  return (
    <CardContainer
      isLoading={purchasesFetchingState.isLoading}
      isSuccess={purchasesFetchingState.isSuccess}
      isError={purchasesFetchingState.isError}
      hasData={data.length > 0}
      title={t('averageSales')}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {purchasesFetchingState.isSuccess && <AverageSales data={data} />}
    </CardContainer>
  );
};

interface AppAverageSalesProps extends AverageSalesBaseProps {
  appId: string;
}

export const AppAverageSales = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
  appId,
}: AppAverageSalesProps) => {
  const { t } = useTranslation();

  const purchasesFetchingState = useAppPurchasesByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
    appId,
  });

  const data = useMemo(() => purchasesFetchingState.data || [], [purchasesFetchingState]);

  return (
    <CardContainer
      isLoading={purchasesFetchingState.isLoading}
      isSuccess={purchasesFetchingState.isSuccess}
      isError={purchasesFetchingState.isError}
      hasData={data.length > 0}
      title={t('averageSales')}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {purchasesFetchingState.isSuccess && <AverageSales data={data} />}
    </CardContainer>
  );
};
