import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { Typography, Progress, Tooltip, Row, Col, Badge, Popover } from 'antd';
import Icon from '@ant-design/icons-react';
import styled from '@emotion/styled';
import { chunk } from 'lodash';
import dayjs from 'dayjs';
// @ts-ignore
import Trend from 'react-trend-extended';
import Device from '../../../../../../store/types/device';
import getDeviceInfo, { Result as DeviceInfo } from '../../../../../../utils/get-device-info';
import safeRelativeTime from '../../../../../../utils/date/safe-relative-time';
import UniversalDevice from '../../../../../../store/types/universal-device';
import { useDeviceComponents, ComponentEnum } from '../../../../../common/use-device-components';
import { OutdatedDeviceComponentsPopover } from '../../../../../common/outdated-components';

const { Text } = Typography;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
`;

const getStrokeColor = (percentage: number) => {
  if (percentage > 90) {
    return '#f5222d';
  }
  if (percentage > 85) {
    return '#fa541c';
  }
  if (percentage > 80) {
    return '#fa8c16';
  }
  if (percentage > 75) {
    return '#faad14';
  }
  if (percentage > 70) {
    return '#a0d911';
  }
  if (percentage > 65) {
    return '#52c41a';
  }
  if (percentage > 60) {
    return '#13c2c2';
  }

  return '#1890ff';
};

enum NetworkType {
  ETH = 'eth',
  WIFI = 'wifi',
}

const isString = (value: any): value is string => typeof value === 'string';

const getIPAddresses = (deviceInfo: DeviceInfo) => {
  let ips: { label: string, value: string, type: NetworkType }[]   = [];

  if (deviceInfo.ips) {
    const legacyWiFiIPAddress = get(deviceInfo, 'ips.wifi', null);
    const legacyEthernetIPAddress = get(deviceInfo, 'ips.eth', null);
    const phyosEthernetIPAddress = get(deviceInfo, 'ips.lan.ip', null);
    const phyosWiFiIPAddress = get(deviceInfo, 'ips.wifi.ip', null);

    if (phyosEthernetIPAddress || phyosWiFiIPAddress) {
      if (isString(phyosEthernetIPAddress)) {
        ips.push({ label: 'Ethernet', value: phyosEthernetIPAddress, type: NetworkType.ETH });
      }
      if (isString(phyosWiFiIPAddress)) {
        ips.push({ label: 'WiFi', value: phyosWiFiIPAddress, type: NetworkType.WIFI });
      }
    } else if (legacyWiFiIPAddress || legacyEthernetIPAddress) {
      if (isString(legacyWiFiIPAddress)) {
        ips.push({ label: 'WiFi', value: legacyWiFiIPAddress, type: NetworkType.WIFI });
      }
      if (isString(legacyEthernetIPAddress)) {
        ips.push({ label: 'Ethernet', value: legacyEthernetIPAddress, type: NetworkType.ETH });
      }
    }
  }

  return ips;
};

const processSeries = (data: string) => {
  const res = data.split('').map((letter) => parseInt(letter, 16));

  // fix a bug where line is not displayed if all values are similar
  if (res[0]) {
    res[0] -= 0.00001;
  }
  return res;
};

const bytes = (bytesParam: number | undefined) => {
  if (!bytesParam) {
    return 0;
  }

  let value = bytesParam;

  // eslint-disable-next-line
  for (const unit of ['', 'K', 'M', 'G', 'T', 'OMG']) {
    if (value < 1024) {
      return `${value.toFixed(2)}${unit}`;
    }
    value /= 1024;
  }

  return value;
};

const TrendContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SmallText = styled(Text)`
  font-size: 12px;
`;

const GraphOrProgress = ({
  title,
  percent,
  series,
  total,
}: {
  title: string;
  percent?: number;
  total?: number;
  series?: string;
}) => {
  if (!percent) {
    return null;
  }

  const TooltipComponent = total
    ? ({ children }: { children: any }) => (
        <Popover
          content={
            <Text type="secondary">
              {percent}% of {bytes(total)}b
            </Text>
          }
        >
          {children}
        </Popover>
      )
    : ({ children }: { children: any }) => <>{children}</>;

  return (
    <Container>
      <Text>{title}</Text>
      <TooltipComponent>
        {series ? (
          <TrendContainer>
            <Trend
              style={{ marginRight: 10 }}
              padding={0}
              data={processSeries(series)}
              smooth
              gradient={[getStrokeColor(percent)]}
              strokeWidth={4}
              maxValue={15}
              height={50}
            />
            <SmallText type="secondary">{percent}%</SmallText>
          </TrendContainer>
        ) : (
          <Progress
            percent={percent}
            strokeColor={getStrokeColor(percent)}
            size="small"
            status="normal"
          />
        )}
      </TooltipComponent>
    </Container>
  );
};

export default function DeviceDetailsInfo({
  device,
}: {
  device: Device | UniversalDevice;
}) {
  const { t } = useTranslation();
  const { data, isError: isQueryError, error } = useDeviceComponents(device.uuid);

  const { gridOSVersion, modulesVersion, isError: isComponentsError } = useMemo(() => {
    const errorName = error && error.constructor ? error.constructor.name : '';
    const isError = isQueryError && errorName !== 'Cancel';
    const components = data || [];

    const gridOSVersion = components.filter((component) => component.component === ComponentEnum.GRIDOS);
    const modulesVersion = components.filter((component) => component.component === ComponentEnum.MODULE);

    return {
      errorName,
      isError,
      gridOSVersion,
      modulesVersion,
    }
  }, [data, isQueryError, error]);


  const { deviceInfo, labeledValues } = useMemo(() => {
    const deviceInfo = getDeviceInfo(device);
    const labeledValues = [
      {
        label: 'Manufacturer',
        value: deviceInfo.manufacturer,
      },
      {
        label: 'Model',
        value: deviceInfo.model,
      },
      {
        label: 'CPU',
        value: deviceInfo.cpuModel,
      },
      {
        label: 'OS',
        value: deviceInfo.osName,
        outdatedComponentIcon: (
          <OutdatedDeviceComponentsPopover
            components={gridOSVersion}
            popoverPlacement="top"
            isError={isComponentsError}
          />
        ),
      },
      {
        label: 'OS version',
        value: deviceInfo.osVersion,
      },
      {
        label: 'BIOS',
        value: [deviceInfo.biosVendor, deviceInfo.biosVersion].filter((v) => !!v).join(' '),
      },
      {
        label: 'BIOS release date',
        value: deviceInfo.biosReleaseDate,
      },
      {
        label: 'Supervisor version',
        value: deviceInfo.supervisorRelease,
      },
      {
        label: 'Device start',
        value: deviceInfo.deviceStart ? (
          <Tooltip title={dayjs(deviceInfo.deviceStart).format('YYYY-MM-DD HH:mm:ss')}>
            {safeRelativeTime(dayjs(deviceInfo.deviceStart))}
          </Tooltip>
        ) : (
          ''
        ),
      },
      {
        label: 'Supervisor start',
        value: deviceInfo.supervisorStart ? (
          <Tooltip title={dayjs(deviceInfo.supervisorStart).format('YYYY-MM-DD HH:mm:ss')}>
            {safeRelativeTime(dayjs(deviceInfo.supervisorStart))}
          </Tooltip>
        ) : (
          ''
        ),
      },
      {
        label: 'App start',
        value: deviceInfo.appStart ? (
          <Tooltip title={dayjs(deviceInfo.appStart).format('YYYY-MM-DD HH:mm:ss')}>
            {safeRelativeTime(dayjs(deviceInfo.appStart))}
          </Tooltip>
        ) : (
          ''
        ),
      },
      {
        label: 'Hostname',
        value: deviceInfo.hostname,
      },
      {
        label: 'UWF state',
        value: deviceInfo.uwfState,
      },
      {
        label: 'Password expiration',
        value: deviceInfo.passwordExpiration,
      },
    ].filter(({ value }) => !!value);

    return {
      deviceInfo,
      labeledValues,
    };
  }, [device, gridOSVersion, isComponentsError]);

  const ipAddresses = useMemo(() => getIPAddresses(deviceInfo), [deviceInfo]);

  return (
    <>
      {chunk(labeledValues, 2).map((row, index) => (
        /* eslint-disable-next-line */
        <Container key={index}>
          <Row>
            {row.map((item) => (
              <Col key={item.label} span={12}>
                <Info>
                  <LabelWrap>
                    <Text>{item.label}</Text>
                    {item.outdatedComponentIcon}
                  </LabelWrap>
                  <Text type="secondary">{item.value}</Text>
                </Info>
              </Col>
            ))}
          </Row>
        </Container>
      ))}
      {deviceInfo.modules && (
        <Container>
          <LabelWrap>
            <Text>Modules</Text>
            <OutdatedDeviceComponentsPopover
              components={modulesVersion}
              popoverPlacement="top"
              isError={isComponentsError}
            />
          </LabelWrap>
          {Object.entries(deviceInfo.modules).map(([name, { version, status }]) => (
            <Row key={`module_${name}`}>
              <Tooltip title={status}>
                &nbsp;
                <Badge status={status === 'running' ? 'success' : 'error'} />
              </Tooltip>
              <Text type="secondary">{name}</Text>
              <Text type="secondary" style={{ opacity: 0.6 }}>
                &nbsp;{version}
              </Text>
            </Row>
          ))}
        </Container>
      )}
      {ipAddresses.length > 0 ? (
        <Container>
          <Text>{t('ipAddresses')}</Text>
          {ipAddresses.map(({ label, value, type }, index) => (
            <Row key={`ip-address-${index}-${value}`}>
              <Tooltip title={label}>
                <Icon
                  type={type === NetworkType.ETH ? 'apartment-o' : 'wifi-o'}
                  style={{ marginLeft: 3, marginRight: 5, color: '#1890ff' }}
                />
              </Tooltip>
              <Text type="secondary">{value}</Text>
            </Row>
          ))}
        </Container>
      ) : null}
      <GraphOrProgress
        title="CPU usage"
        percent={deviceInfo.cpuUsage}
        series={deviceInfo.cpuSeries}
      />
      <GraphOrProgress
        title="Memory usage"
        percent={deviceInfo.memUsage}
        series={deviceInfo.memSeries}
        total={deviceInfo.memSize}
      />
      <GraphOrProgress
        title="Disk usage"
        percent={deviceInfo.diskUsage}
        total={deviceInfo.diskSize}
      />
      {deviceInfo.powerCharge ? (
        <Container>
          <Text>Battery level - {deviceInfo.powerStatus}</Text>
          <Progress
            percent={deviceInfo.powerCharge}
            strokeColor={getStrokeColor(100 - deviceInfo.powerCharge)}
            size="small"
            status="normal"
          />
        </Container>
      ) : null}
    </>
  );
};

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
