export enum ContentTagInputType {
  MULTI_SELECT = 'multi-select',
}

export type ContentTagInputMultiSelect = string[];

export type ContentTagValues = ContentTagInputMultiSelect;

interface ContentTagBody {
  id?: string;
  tenantId: string;
  name: string;
  description: string;
  type?: ContentTagInputType;
  values?: ContentTagValues;
}

export default ContentTagBody;
