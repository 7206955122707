export default interface ModuleVersion {
  id: string;
  version: string;
  settingsSchema: any;
  metaSchema: any;
  createdAt: string;
  stage?: GridAppBuildStage;
}

// TO DO: Deprecate, use StageEnum instead, since not every build is a gridapp
export enum GridAppBuildStage {
  PRODUCTION = 'production',
  QA = 'qa',
  OMBORI_INTERNAL = 'ombori-internal',
}
