import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Input, Icon } from 'antd';
import { SearchProps } from 'antd/lib/input';
import debounce from 'lodash/debounce';
import transientOptions from '../../../utils/transient-emotion-styled-options';

export interface SearchInputProps extends SearchProps {
  className?: string;
  onSearch?: (value: string) => void;
  loading?: boolean;
  width?: string;
  minLength?: number;
  debounceDelay?: number;
  tag?: React.ReactNode;
  extra?: React.ReactNode;
}

const SearchInput = (props: SearchInputProps) => {
  const {
    onSearch = () => {},
    loading,
    width,
    minLength = 3,
    debounceDelay = 1200,
    tag,
    extra,
    className,
    ...restProps
  } = props;
  const [isPendingOnSearch, setIsPendingOnSearch] = useState(false);

  const delayedInputChange = useCallback(
    debounce((input: string) => {
      onSearch(input);
      setIsPendingOnSearch(false);
    }, debounceDelay),
    [debounceDelay, onSearch],
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const input = event.target.value;

      if (input && input.trim().length < minLength) {
        setIsPendingOnSearch(false);
        return;
      }

      setIsPendingOnSearch(true);
      delayedInputChange(input);
    },
    [delayedInputChange, minLength],
  );

  useEffect(() => {
    return () => {
      delayedInputChange.cancel();
    };
  }, [delayedInputChange]);

  return (
    <SelectInput className={className}>
      <InputWrap>
        <InputStyled
          allowClear
          size="large"
          prefix={<Icon type="search" />}
          suffix={loading || isPendingOnSearch ? <Icon type="loading" /> : null}
          onChange={handleChange}
          {...restProps}
          $width={width}
        />
      </InputWrap>
      {tag && (
        <Tag>
          <TagContent>{tag}</TagContent>
        </Tag>
      )}
      {extra && (
        <Extra>
          <ExtraContent>{extra}</ExtraContent>
        </Extra>
      )}
    </SelectInput>
  );
};

const InputWrap = styled.div`
  flex-grow: 1;
`;

const InputStyled = styled(Input, transientOptions)<{ $width?: string }>`
  display: inline-block;
  flex-grow: 1;
  ${({ $width }) => ($width ? `width: ${$width};` : '')};

  @media (max-width: 400px) {
    width: 100%;
  }

  > input {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding-left: 5px;

    &:active,
    &:focus {
      border-color: transparent;
      outline: 0;
      box-shadow: none !important;
    }
  }
`;

const SelectInput = styled.div`
  display: flex !important;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  list-style: none;
  position: relative;
  outline: 0;
  width: 100%;
  line-height: 0;
  border-radius: 4px;
  cursor: text;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top-width: 1.02px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  height: 100%;
`;

const Extra = styled.div`
  display: inline-flex;
  align-items: center;
`;

const ExtraContent = styled.div`
  height: 100%;
  display: inline-flex;
`;

const Tag = styled.div`
  display: inline-flex;
  align-items: center;
`;

const TagContent = styled.div`
  height: 100%;
  display: inline-flex;
`;

export default SearchInput;
