import {AccessTokensListQueryInterface} from './use-access-tokens-list';

const accessTokensQueryKeys = {
  queryPrefix: () => {
    return 'access-token';
  },
  getAccessTokenListKeys: (AccessTokenListParams: AccessTokensListQueryInterface) => [
    accessTokensQueryKeys.queryPrefix(),
    'page',
    AccessTokenListParams.page,
    'limit',
    AccessTokenListParams.limit,
    'sortField',
    AccessTokenListParams.sortField,
    'sortOrder',
    AccessTokenListParams.sortOrder,
    'searchTerm',
    AccessTokenListParams.searchTerm,
  ]
};

export default accessTokensQueryKeys;
