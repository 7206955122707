import diff from 'microdiff';
import { useMemo } from 'react';
import OrganisationApp from '../../../../../../store/types/organisation-app';
import { applyDiff } from './settings.utils';

interface UseSpaceSettingsProps {
  appSettings: OrganisationApp['settings'];
  settingsDiff: ReturnType<typeof diff>;
}

const useOverriddenSettings = ({
  appSettings,
  settingsDiff,
}: UseSpaceSettingsProps): OrganisationApp['settings'] => {
  const newSettings = useMemo(() => applyDiff(settingsDiff, appSettings), [
    settingsDiff,
    appSettings,
  ]);

  return newSettings;
};

export default useOverriddenSettings;
