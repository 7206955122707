import React, { useCallback, useEffect, useMemo, useState } from 'react';
import isEqual from 'lodash/isEqual';
import SchemaForm from '../../../../../common/schema-form/schema-form.component';
import OrganisationApp from '../../../../../../store/types/organisation-app';
import { InstallationSettingsLevel } from '../../../../../../store/types/organisation-app';
import { MultiLanguageOptions } from '../../../../../../utils/language/language.util';

interface UseSettingsFormResult {
  settings: any;
  onChangeSettings: (settings: any) => void;
  isFormPristine: boolean;
}

export const useSettingsForm = (
  initialSettings: OrganisationApp['settings'],
): UseSettingsFormResult => {
  const [settings, setSettings] = useState(initialSettings);

  const onChange = useCallback((data: any) => {
    setSettings(data);
  }, []);

  const isFormPristine = useMemo(() => isEqual(initialSettings, settings), [
    initialSettings,
    settings,
  ]);

  const result = useMemo(
    () => ({
      settings,
      onChangeSettings: onChange,
      isFormPristine,
    }),
    [settings, onChange, isFormPristine],
  );

  useEffect(() => {
    onChange(initialSettings);
  }, [initialSettings, onChange]);

  return result;
};

interface SettingsFormProps {
  organisationId: string;
  metaSchema: any;
  schema: any;
  data: any;
  app: OrganisationApp;
  onChange: (settings: any) => void;
  showGridappPicker?: boolean;
  showVersionPicker?: boolean;
  showSettingsOverridingIndicator?: boolean;
  globalSettingsContext?: any;
  spaceSettingsContext?: any;
  settingsLevel: InstallationSettingsLevel;
  languageOptions: MultiLanguageOptions;
}

const SettingsForm: React.FC<SettingsFormProps> = React.memo(({
  organisationId,
  metaSchema,
  schema,
  data,
  app,
  onChange,
  showGridappPicker = false,
  showVersionPicker = false,
  showSettingsOverridingIndicator = false,
  globalSettingsContext,
  spaceSettingsContext,
  settingsLevel,
  languageOptions,
}) => {
  return (
    <SchemaForm
      organisationId={organisationId}
      uiSchema={metaSchema}
      schema={schema}
      data={data}
      formContext={{
        appId: app.id,
        hideMainGridAppPicker: !showGridappPicker,
        isGridAppVersionPickerVisible: showVersionPicker,
        languageOptions,
        showSettingsOverridingIndicator,
        globalSettingsContext,
        spaceSettingsContext,
        settingsLevel,
      }}
      onChange={onChange}
    />
  );
});

export default SettingsForm;
