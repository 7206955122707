import React from 'react';
import styled from '@emotion/styled';
import { Typography } from 'antd';
import Media from '../../../../../store/types/media';
import Preview from '../../../../common/media-library/preview/preview.component';
import { MediaItemKind, getMediaItemKind } from '../../../../../utils/media-item-utils';
import { useTranslation } from 'react-i18next';
import { formatCreatedAt } from '../../../../../utils/date-formatter';
import { formatFileSize } from '../../../../../utils/bytes';

interface ContentLibraryMediaPreviewProps {
  mediaItem: Media;
}

const ContentLibraryMediaPreview = (props: ContentLibraryMediaPreviewProps) => {
  const { mediaItem } = props;

  const { t } = useTranslation();

  return (
    <MediaPreviewContainer>
      <MediaPreview>
        <MediaItemPreview>
          <Preview mediaItem={mediaItem} />
        </MediaItemPreview>
      </MediaPreview>

      <MediaDetails>
        <MediaDetail>
          <Typography.Text>{t('contents.name')}</Typography.Text>
          <MediaName>{mediaItem.name}</MediaName>
        </MediaDetail>

        <MediaDetail>
          <Typography.Text>{t('contents.createdAt')}</Typography.Text>
          <span>{formatCreatedAt(mediaItem.createdAt)}</span>
        </MediaDetail>

        <MediaDetail>
          <Typography.Text>{t('contents.type')}</Typography.Text>
          <span>{mediaItem.type}</span>
        </MediaDetail>

        <MediaDetail>
          <Typography.Text>{t('contents.size')}</Typography.Text>
          <span>{formatFileSize(mediaItem.size)}</span>
        </MediaDetail>

        {getMediaItemKind(mediaItem) === MediaItemKind.video && (
          <MediaDetail>
            <Typography.Text>{t('contents.duration')}</Typography.Text>
            <span>
              {mediaItem.metadata &&
                mediaItem.metadata.videoDuration &&
                mediaItem.metadata.videoDuration.durationFormatted}
            </span>
          </MediaDetail>
        )}
      </MediaDetails>
    </MediaPreviewContainer>
  );
};

export default ContentLibraryMediaPreview;

const MediaPreviewContainer = styled.div`
  border: 1px solid #d9d9d9;
  height: 100%;

  background: #fafafa;
  padding: 20px;
`;

const MediaPreview = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  background: white;

  padding: 20px;
`;

const MediaItemPreview = styled.div`
  align-self: center;
  background: white;

  padding: 5px 10px;

  img {
    box-shadow: none;
  }
`;

const MediaDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 30px 50px;
  margin-bottom: 50px;
`;

const MediaDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  gap: 50px;
`;

const MediaName = styled.p`
  word-break: break-all;
`;
