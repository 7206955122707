import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import { Button, message } from 'antd';
import last from 'lodash/last';
import { RefreshCcw, CheckCircle, AlertCircle, Circle, UploadCloud } from 'react-feather';
import ProtectedSectionContainer from '../../../../../common/protected-section/protected-section.container';
import Build from '../../../../../../store/types/build';
import Environment from '../../../../../../store/types/environment';
import { permissionKeys } from '../../../../../../utils/auth/permissions';
import Deploy, { DeployStatus } from '../../../../../../store/types/deploy';
import { useDeploy, useLatestDeployedBuild } from '../../../../../common/use-app-builds';
import { useTranslation } from 'react-i18next';
import {
  PipelineWithBuildNumber,
  PipelineStatus,
} from '../../../../../../store/types/pipeline';
import useHasPermissions from '../../../../../../utils/auth/use-has-permissions';

const getDeployIcon = (deploy: Deploy | undefined): React.ReactNode => {
  if (!deploy) {
    return <Circle />;
  }

  switch (deploy.status) {
    case DeployStatus.Queued:
      return <RefreshIcon />;
    case DeployStatus.Deploying:
      return <RefreshIcon />;
    case DeployStatus.Done:
      return <CheckCircle />;
    case DeployStatus.Failed:
      return <AlertCircle />;
  }
};

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
`;

const RefreshIcon = styled(RefreshCcw)`
  animation: ${spin} 1000ms linear infinite;
`;

const ListDeployButton = styled(({ isActive, ...otherProps }) => (
  <Button {...otherProps} />
))<{
  isActive: boolean;
}>`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #111111;
  background-color: #f5f5fa;
  border: 1px solid #f5f5fa;

  &:hover,
  &:active,
  &:focus {
    color: #111111;
    background-color: #f5f5fa;
    border: 1px solid #f5f5fa;
  }

  ${({ isActive }) =>
    isActive &&
    `
    color: #FFF;
    border-color: #07AD48;
    background-color: #07AD48;
    
    &:hover,
    &:active,
    &:focus {
      color: #FFF;
      border-color: #07AD48;
      background-color: #07AD48;
    }
  `};
`;

const DeployButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  margin-right: 4px;

  svg {
    display: block;
    width: 100%;
  }
`;

const DeployButton = styled(ListDeployButton)`
  background-color: #2364aa;
  border-color: #2364aa;
  color: #fff;

  :hover,
  :active,
  :focus {
    opacity: 0.9;
    background-color: #2364aa;
    border-color: #2364aa;
  }
`;

interface DeployButtonViewProps {
  appId: string;
  environment: Environment;
  build: Build;
  isFailedBuildPipeline: boolean;
  isListView?: boolean;
  allowDeploy: boolean;
}

export const DeployButtonView: React.FC<DeployButtonViewProps> = ({
  appId,
  build,
  environment,
  isFailedBuildPipeline,
  isListView = true,
  allowDeploy,
}) => {
  const { t } = useTranslation();

  const useDeployState = useDeploy(appId, environment.environmentName);

  const onClick = useCallback(() => {
    useDeployState.mutate(
      { buildId: build.id },
      {
        onSuccess: () => {
          message.success(t('deployRequested'));
        },
        onError: () => {
          message.error(t('deployRequestFailed'));
        },
      },
    );
  }, [t, useDeployState, build]);

  const deploys = build.deploys.filter(
    (deploy) => deploy.environmentName === environment.environmentName,
  );

  const lastDeploy = last(deploys);

  const deployIcon = getDeployIcon(lastDeploy);

  const latestDeployedBuildState = useLatestDeployedBuild({
    appId,
    env: environment.environmentName,
  });

  const { ButtonComponent, icon, text, latestDeployedBuildId } = useMemo(
    () => ({
      ButtonComponent: isListView ? ListDeployButton : DeployButton,
      icon: isListView ? deployIcon : <UploadCloud />,
      text: isListView ? environment.displayName : t('builds.deploy'),
      latestDeployedBuildId: latestDeployedBuildState.isSuccess
        ? latestDeployedBuildState.data.id
        : undefined,
    }),
    [isListView, environment.displayName, t, latestDeployedBuildState, deployIcon],
  );

  return (
    <ButtonComponent
      type="primary"
      shape={isListView ? 'round' : undefined}
      isActive={build.id === latestDeployedBuildId}
      onClick={onClick}
      disabled={!allowDeploy || useDeployState.isLoading || isFailedBuildPipeline}
    >
      <DeployButtonIcon>
        {!useDeployState.isLoading && icon}

        {useDeployState.isLoading && <RefreshIcon />}
      </DeployButtonIcon>

      {text}
    </ButtonComponent>
  );
};

const Container = styled.div``;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Item = styled.div`
  margin: 4px 8px 4px 0;
`;

interface DeployStatusViewProps {
  appId: string;
  permissionPath: string;
  build: Build;
  environments: Environment[];
  organisationId: string;
  pipeline: PipelineWithBuildNumber;
}

const DeployStatusView: React.FC<DeployStatusViewProps> = ({
  appId,
  permissionPath,
  build,
  environments,
  organisationId,
  pipeline,
}) => {
  const isDeploymentAllowed = useHasPermissions(organisationId, permissionKeys.builds.deploy);
  const isFailedBuildPipeline = pipeline.status === PipelineStatus.Failed;

  return (
    <Container>
      <List>
        {environments.map((environment) => {
          return (
            <Item key={environment.id}>
              <ProtectedSectionContainer permissionPath={permissionPath}>
                <DeployButtonView
                  appId={appId}
                  build={build}
                  environment={environment}
                  isFailedBuildPipeline={isFailedBuildPipeline}
                  allowDeploy={isDeploymentAllowed}
                />
              </ProtectedSectionContainer>
            </Item>
          );
        })}
      </List>
    </Container>
  );
};

export default DeployStatusView;
