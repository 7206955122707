const { NODE_ENV } = process.env;
const QUEUE_SERVICE = process.env.GRID_QUEUE_SERVICE
  ? process.env.GRID_QUEUE_SERVICE
  : 'http://localhost:7071';

export const endpoints: { [key: string]: string } = {
  US: 'https://api.omborigrid.com/regions/us/queues/v1',
  EU: 'https://api.omborigrid.com/regions/eu/queues/v1',
  UAE: 'https://api.omborigrid.com/regions/uae/queues/v1',
  AU: 'https://api.omborigrid.com/regions/au/queues/v1',
  QA: 'https://api-qa.omborigrid.com/regions/qa/queues/v1',
  IN: 'https://api.omborigrid.com/regions/in/queues/v1',
  DEV:
    NODE_ENV === 'production'
      ? 'https://queues.dev.omborigrid.net/api'
      : QUEUE_SERVICE,
};

export const webUrls: { [key: string]: string } = {
  US: 'https://queue.us.ombori.com',
  EU: 'https://queue.eu.ombori.com',
  UAE: 'https://queue.uae.ombori.com',
  AU: 'https://queue-au.omborigrid.com',
  IN: 'https://queue-in.omborigrid.com',
  QA: 'https://queue-qa.omborigrid.com',
  DEV: 'https://queue-dev.griddeveloper.com',
};

export const tasksWebUrls: { [key: string]: string } = {
  US: 'https://tasks-us.omborigrid.com',
  EU: 'https://tasks-eu.omborigrid.com',
  UAE: 'https://tasks-uae.omborigrid.com',
  AU: 'https://tasks-au.omborigrid.com',
  IN: 'https://tasks-in.omborigrid.com',
  QA: 'https://tasks-qa.omborigrid.com',
  DEV: 'https://tasks-dev.omborigrid.com',
};

export default { endpoints, webUrls, tasksWebUrls };
