import React from 'react';
import styled from '@emotion/styled';
import { Card, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import App from '../../../../../../store/types/organisation-app';

interface EmptyDeviceView {
  app: App;
  onConnectDevice: () => void;
  canCreate: boolean;
}

const containerBodyStyle = {
  height: '100%',
  width: '100%',
  display: 'flex' as any,
  flex: 1,
  flexDirection: 'column' as any,
};

export default function EmptyDeviceView({
  app,
  onConnectDevice,
  canCreate,
}: EmptyDeviceView) {
  const { t } = useTranslation();

  return (
    <>
      <Container bodyStyle={containerBodyStyle}>
        <Body>
          <Title>{t('noConnectedDevices')}</Title>
          <Subtitle>
            {t('chooseTheDevices', { appDisplayName: app.displayName })}
          </Subtitle>
        </Body>
        {canCreate && (
          <ConnectButton
            onClick={() => onConnectDevice()}
            size="large"
            type="primary"
            icon="api"
          >
            {t('connectDevice')}
          </ConnectButton>
        )}
      </Container>
    </>
  );
}

const Container = styled(Card)`
  position: absolute;
  top: 72px;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 344px;
  height: 345px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.02),
    0px 4px 8px 0px rgba(0, 0, 0, 0.02), 0px 8px 16px 0px rgba(0, 0, 0, 0.02),
    0px 16px 32px 0px rgba(0, 0, 0, 0.04);
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  line-height: 1.2;
`;

const Subtitle = styled.div`
  font-size: 14px;
  font-weight: 200;
  line-height: 1.4;
  padding-top: 12px;
`;

const ConnectButton = styled(Button)`
  border: none;
  margin: 8px;
` as any;
