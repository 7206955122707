import { thunk, Thunk } from 'easy-peasy';
import { GridProduct, Variant, ProductType } from '@ombori/grid-products';
import omit from 'lodash/omit';
import fromPairs from 'lodash/fromPairs';
import Injections from '../../injections.interface';
import createKeyedCrudModel, {
  KeyedCrudModel,
} from '../../common/keyed-crud-model/keyed-crud.model';
import { DataResidencyEnum } from '../../types/organisation';

interface BaseProps {
  tenantId: string;
  dataResidency: DataResidencyEnum;
  env: string;
}

interface BasePropsWithGridProductId extends BaseProps {
  gridProductId: string;
}

interface SearchProps extends BaseProps {
  term: string;
  select?: string;
  filter?: string;
  additionalRawFilter?: string;
  productTypesCoverage?: string;
}

interface SearchResult {
  products: GridProduct[];
  productTypes: ProductType[];
}

export interface GridProductsModel
  extends KeyedCrudModel<GridProduct & { id: string }, { tenantId: string }> {
  fetchProducts: Thunk<
    GridProductsModel,
    BaseProps & {
      page: number;
      limit: number;
      includeAttributeFilters?: 'true';
      select?: string[];
      search?: string;
    },
    Injections,
    {},
    Promise<{
      totalCount: number;
      totalPages: number;
      hasMore: boolean;
      list: GridProduct[];
    }>
  >;
  fetchOne: Thunk<
    GridProductsModel,
    BasePropsWithGridProductId,
    Injections,
    {},
    Promise<void>
  >;
  getURLToFetchOne: Thunk<GridProductsModel, BaseProps, Injections>;
  updateOne: Thunk<GridProductsModel, BaseProps & { product: GridProduct }, Injections>;
  addOne: Thunk<GridProductsModel, BaseProps & { product: GridProduct }, Injections>;
  deleteOne: Thunk<
    GridProductsModel,
    BaseProps & { productGroupId: string },
    Injections
  >;
  selectedProducts: { [tenantId: string]: Variant[] };
  setSelectedProducts: Thunk<
    GridProductsModel,
    { tenantId: string; products: Variant[] },
    Injections
  >;
  uploadExcel: Thunk<
    GridProductsModel,
    BaseProps & { spaceId: string; fileUrl: string },
    Injections
  >;
  search: Thunk<GridProductsModel, SearchProps, Injections, {}, Promise<void>>;
  searchProducts: Thunk<GridProductsModel, SearchProps, Injections, {}, Promise<void>>;
}

const GridProductsModel: GridProductsModel = {
  ...createKeyedCrudModel<GridProduct & { id: string }, { tenantId: string }>(
    '',
    'tenantId',
  ),
  fetchProducts: thunk(
    async (
      actions,
      {
        tenantId,
        dataResidency,
        select,
        limit,
        env,
        search = '',
        page,
        includeAttributeFilters,
      },
      { injections },
    ) => {
      actions.setLoading({ key: tenantId, loading: true });
      actions.setError({ key: tenantId, error: null });
      try {
        const attributeFilters = includeAttributeFilters
          ? `&includeAttributeFilters=${includeAttributeFilters}`
          : '';

        const result = await injections.gridProductService.get<{
          data: { list: GridProduct[]; count: number };
        }>(
          `${tenantId}/${env}/products?select=${(select || []).join(
            ',',
          )}&limit=${limit}&page=${page}&search=${search}${attributeFilters}&sort=sortName&isSourceSearch=true`,
          dataResidency,
        );
        const {
          data: { list, count },
        } = result;

        const resultList = (list || []).map((product) => ({
          ...product,
          id: product.productGroupId,
        }));
        actions.setData({
          key: tenantId,
          data: resultList,
        });
        return {
          totalCount: count,
          totalPages: Math.ceil(count / (limit || 10)),
          hasMore: false,
          list: resultList,
        };
      } catch (error) {
        actions.setError({ key: tenantId, error });
        actions.setData({
          key: tenantId,
          data: [],
        });
        return { totalCount: 0, totalPages: 0, hasMore: false, list: [] };
      } finally {
        actions.setLoading({ key: tenantId, loading: false });
      }
    },
  ),
  fetchOne: thunk(
    async (actions, { tenantId, gridProductId, env, dataResidency }, { injections }) => {
      actions.setLoading({ key: tenantId, loading: true });
      actions.setError({ key: tenantId, error: null });
      try {
        const { data } = await injections.gridProductService.get<{ data: GridProduct }>(
          `/${tenantId}/${env}/products/${gridProductId}`,
          dataResidency,
        );
        actions.setSingle({ key: tenantId, data: { ...data, id: data.productGroupId } });
      } catch (error) {
        actions.setError({ key: tenantId, error });
      } finally {
        actions.setLoading({ key: tenantId, loading: false });
      }
    },
  ),
  getURLToFetchOne: thunk(
    async (actions, { tenantId, env, dataResidency }, { injections }) => {
      const baseUrl = injections.gridProductService.getBaseUrlFromInstance(dataResidency);
      const url = `${baseUrl}/${tenantId}/${env}/products`;
      return url;
    },
  ),
  updateOne: thunk(
    async (actions, { tenantId, product, env, dataResidency }, { injections }) => {
      actions.setLoading({ key: tenantId, loading: true });
      actions.setError({ key: tenantId, error: null });
      try {
        const productSafe = omit(product, 'id');
        await injections.gridProductService.post(
          `/${tenantId}/${env}/admin/products`,
          dataResidency,
          { data: productSafe },
        );
        actions.setSingle({
          key: tenantId,
          data: { ...product, id: product.productGroupId },
        });
      } catch (error) {
        actions.setError({ key: tenantId, error });
      } finally {
        actions.setLoading({ key: tenantId, loading: false });
      }
    },
  ),
  addOne: thunk(
    async (actions, { tenantId, product, env, dataResidency }, { injections }) => {
      actions.setLoading({ key: tenantId, loading: true });
      actions.setError({ key: tenantId, error: null });
      try {
        const productSafe = omit(product, 'id');
        await injections.gridProductService.post<GridProduct>(
          `/${tenantId}/${env}/admin/products`,
          dataResidency,
          { data: productSafe },
        );
        actions.setSingle({
          key: tenantId,
          data: { ...product, id: product.productGroupId },
        });
      } catch (error) {
        actions.setError({ key: tenantId, error });
      } finally {
        actions.setLoading({ key: tenantId, loading: false });
      }
    },
  ),
  deleteOne: thunk(
    async (
      actions,
      { tenantId, productGroupId, env, dataResidency },
      { injections },
    ) => {
      actions.setLoading({ key: tenantId, loading: true });
      actions.setError({ key: tenantId, error: null });
      try {
        const { data } = await injections.gridProductService.delete<{
          data: { errorMessage: string }[];
        }>(`/${tenantId}/${env}/admin/products/${productGroupId}`, dataResidency);
        return data[0].errorMessage;
      } catch (error) {
        actions.setError({ key: tenantId, error });
      } finally {
        actions.setLoading({ key: tenantId, loading: false });
      }
    },
  ),
  selectedProducts: {},
  setSelectedProducts: thunk(async (actions, { products, tenantId }) => {
    GridProductsModel.selectedProducts[tenantId] = [...products];
  }),
  uploadExcel: thunk(
    async (
      actions,
      { tenantId, env, dataResidency, spaceId, fileUrl },
      { injections },
    ) => {
      actions.setLoading({ key: tenantId, loading: true });
      actions.setError({ key: tenantId, error: null });
      try {
        const response = await injections.gridProductService.post(
          `/${tenantId}/${env}/upload/excel`,
          dataResidency,
          {
            spaceId,
            fileUrl,
          },
        );

        return response;
      } catch (error) {
        actions.setError({ key: tenantId, error });
      } finally {
        actions.setLoading({ key: tenantId, loading: false });
      }
    },
  ),
  search: thunk(
    async (
      actions,
      {
        tenantId,
        env,
        dataResidency,
        term,
        select,
        filter,
        additionalRawFilter,
        productTypesCoverage,
      },
      { injections },
    ) => {
      actions.setLoading({ key: tenantId, loading: true });

      actions.setError({ key: tenantId, error: null });

      const paramsList = [
        ['select', select],
        ['filter', filter],
        ['additionalRawFilter', additionalRawFilter],
        ['productTypesCoverage', productTypesCoverage],
      ].filter(([, paramValue]) => !!paramValue);

      const params = fromPairs(paramsList);

      try {
        const response = await injections.gridProductService.get<{ data: SearchResult }>(
          `/${tenantId}/${env}/search`,
          dataResidency,
          { term, ...params },
        );

        const { products } = response.data;

        actions.upsertData({
          key: tenantId,
          data: products.map((product) => ({ ...product, id: product.productGroupId })),
        });
      } catch (error) {
        actions.setError({ key: tenantId, error });
      } finally {
        actions.setLoading({ key: tenantId, loading: false });
      }
    },
  ),
  searchProducts: thunk(
    async (
      actions,
      {
        tenantId,
        env,
        dataResidency,
        term,
        select,
        filter,
        additionalRawFilter,
        productTypesCoverage,
      },
      { injections },
    ) => {
      actions.setLoading({ key: tenantId, loading: true });

      actions.setError({ key: tenantId, error: null });

      const paramsList = [
        ['select', select],
        ['filter', filter],
        ['additionalRawFilter', additionalRawFilter],
        ['productTypesCoverage', productTypesCoverage],
      ].filter(([, paramValue]) => !!paramValue);

      const params = fromPairs(paramsList);

      try {
        const response = await injections.gridProductService.get<{ data: SearchResult }>(
          `/${tenantId}/${env}/search`,
          dataResidency,
          { term, ...params },
        );

        const { products } = response.data;

        actions.setData({
          key: `${tenantId}${term}`,
          data: products.map((product) => ({ ...product, id: product.productGroupId })),
        });
      } catch (error) {
        actions.setError({ key: tenantId, error });
      } finally {
        actions.setLoading({ key: tenantId, loading: false });
      }
    },
  ),
};

export default GridProductsModel;
