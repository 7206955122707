import React, { useMemo, useState } from 'react';
import uniqBy from 'lodash/uniqBy';
import invert from 'lodash/invert';
import debounce from 'lodash/debounce';
import styled from '@emotion/styled';
import { Slider } from 'antd';
import { DataResidencyEnum } from '../../../../store/types/organisation';
import {
  useTenantEventsFlowByDay,
  useInstallationEventsFlowByDay,
  useSpaceEventsFlowByDay,
  useDeviceEventsFlowByDay,
  useAppEventsFlowByDay,
} from '../../use-analytics-report';
import CardContainer from './card-container';
import EventFlowChart from '../charts/events-flow-chart';
import {
  TenantEventsFlowByDay,
  InstallationEventsFlowByDay,
  SpaceEventsFlowByDay,
  DeviceEventsFlowByDay,
  AppEventsFlowByDay,
  SankeyDiagramData,
  Node,
  Link,
} from '../../use-analytics-report/aggregated-reports/types';
import { GridStyles } from '@ombori/grid-reports';

const DEBOUNCE_TIMEOUT = 1000;

const cardTitle = 'Events flow';

const buildSankeyData = (
  events: (
    | TenantEventsFlowByDay
    | InstallationEventsFlowByDay
    | SpaceEventsFlowByDay
    | DeviceEventsFlowByDay
    | AppEventsFlowByDay)[],
): SankeyDiagramData => {
  for (let i = 0; i < events.length; i += 1) {
    // eslint-disable-next-line no-param-reassign
    events[i].eventOrder = parseInt(`${events[i].eventOrder}`, 10);
    // eslint-disable-next-line no-param-reassign
    events[i].eventType = `${events[i].eventType} (${events[i].eventOrder})`;
    if (events[i].eventTypePrevious) {
      // eslint-disable-next-line no-param-reassign
      events[i].eventTypePrevious = `${events[i].eventTypePrevious} (${((events[i]
        .eventOrder as unknown) as number) - 1})`;
    }
  }

  events.sort((a, b) => (a.eventOrder > b.eventOrder ? 1 : -1));

  const nodeNames = uniqBy(
    events.map((event: any) => event.eventType as string),
    (node) => node,
  );
  const nodeIndex = invert(nodeNames);
  const nodes: Node[] = nodeNames.map((node) => ({ id: node, name: node.split(' ')[0] }));

  const links: Link[] = events
    .filter((event) => !!nodeIndex[event.eventTypePrevious as string])
    .map((event) => {
      const source = parseInt(nodeIndex[event.eventTypePrevious as string], 10);
      const target = parseInt(nodeIndex[event.eventType as string], 10);
      const value = event.count;

      return {
        source,
        target,
        value,
      };
    });

  const result = {
    nodes,
    links,
  };

  return result;
};

const defaultDepth = 15;
const minDepth = 1;
const maxDepth = 40;
const cardHeight = 450;

const useEventsFlowDepth = () => {
  const [eventsFlowDepth, setEventsFlowDepth] = useState(defaultDepth);

  const debouncedUpdate = useMemo(
    () => debounce<(depth: number) => void>(setEventsFlowDepth, DEBOUNCE_TIMEOUT),
    [],
  );

  const result = useMemo(
    () => ({ eventsFlowDepth, onChangeEventsFlowDepth: debouncedUpdate }),
    [eventsFlowDepth, debouncedUpdate],
  );

  return result;
};

interface EventsFlowProps {
  tenantId: string;
  dateFrom: string;
  dateTo: string;
  dataResidency: DataResidencyEnum;
  gridStyles?: GridStyles;
  isVisibleWithoutData?: boolean;
}

const Controller = styled.div`
  width: 100%;
  padding-top: 10px;
`;

interface ControllerPanelProps {
  eventsFlowDepth: number;
  onChangeEventsFlowDepth: (depth: number) => void;
}

const ControllerPanel: React.FC<ControllerPanelProps> = ({
  eventsFlowDepth,
  onChangeEventsFlowDepth,
}) => {
  return (
    <Controller>
      <h4>Events flow depth: {eventsFlowDepth}</h4>

      <Slider
        defaultValue={eventsFlowDepth}
        min={minDepth}
        max={maxDepth}
        onChange={(value) => {
          if (typeof value !== 'number') {
            return;
          }

          onChangeEventsFlowDepth(value);
        }}
      />
    </Controller>
  );
};

export const TenantEventsFlow: React.FC<EventsFlowProps> = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { eventsFlowDepth, onChangeEventsFlowDepth } = useEventsFlowDepth();

  const tenantEventsFlowState = useTenantEventsFlowByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
    eventsFlowDepth,
  });

  const data = useMemo(() => buildSankeyData(tenantEventsFlowState.data || []), [
    tenantEventsFlowState,
  ]);

  return (
    <CardContainer
      isLoading={tenantEventsFlowState.isLoading}
      isSuccess={tenantEventsFlowState.isSuccess}
      isError={tenantEventsFlowState.isError}
      hasData={data.nodes.length > 0}
      height={cardHeight}
      title={cardTitle}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
      footer={
        <ControllerPanel
          eventsFlowDepth={eventsFlowDepth}
          onChangeEventsFlowDepth={onChangeEventsFlowDepth}
        />
      }
    >
      {tenantEventsFlowState.isSuccess && (
        <EventFlowChart data={data} height={cardHeight} />
      )}
    </CardContainer>
  );
};

interface InstallationEventsFlowProps extends EventsFlowProps {
  installationId: string;
}

export const InstallationEventsFlow: React.FC<InstallationEventsFlowProps> = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { eventsFlowDepth, onChangeEventsFlowDepth } = useEventsFlowDepth();

  const installationEventsFlowState = useInstallationEventsFlowByDay({
    tenantId,
    installationId,
    dateFrom,
    dateTo,
    dataResidency,
    eventsFlowDepth,
  });

  const data = useMemo(() => buildSankeyData(installationEventsFlowState.data || []), [
    installationEventsFlowState,
  ]);

  return (
    <CardContainer
      isLoading={installationEventsFlowState.isLoading}
      isSuccess={installationEventsFlowState.isSuccess}
      isError={installationEventsFlowState.isError}
      hasData={data.nodes.length > 0}
      height={cardHeight}
      title={cardTitle}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
      footer={
        <ControllerPanel
          eventsFlowDepth={eventsFlowDepth}
          onChangeEventsFlowDepth={onChangeEventsFlowDepth}
        />
      }
    >
      {installationEventsFlowState.isSuccess && (
        <EventFlowChart data={data} height={cardHeight} />
      )}
    </CardContainer>
  );
};

interface SpaceEventsFlowProps extends EventsFlowProps {
  spaceId: string;
}

export const SpaceEventsFlow: React.FC<SpaceEventsFlowProps> = ({
  tenantId,
  spaceId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { eventsFlowDepth, onChangeEventsFlowDepth } = useEventsFlowDepth();

  const spaceEventsFlowState = useSpaceEventsFlowByDay({
    tenantId,
    spaceId,
    dateFrom,
    dateTo,
    dataResidency,
    eventsFlowDepth,
  });

  const data = useMemo(() => buildSankeyData(spaceEventsFlowState.data || []), [
    spaceEventsFlowState,
  ]);

  return (
    <CardContainer
      isLoading={spaceEventsFlowState.isLoading}
      isSuccess={spaceEventsFlowState.isSuccess}
      isError={spaceEventsFlowState.isError}
      hasData={data.nodes.length > 0}
      height={cardHeight}
      title={cardTitle}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
      footer={
        <ControllerPanel
          eventsFlowDepth={eventsFlowDepth}
          onChangeEventsFlowDepth={onChangeEventsFlowDepth}
        />
      }
    >
      {spaceEventsFlowState.isSuccess && (
        <EventFlowChart data={data} height={cardHeight} />
      )}
    </CardContainer>
  );
};

interface DeviceEventsFlowProps extends EventsFlowProps {
  deviceId: string;
}

export const DeviceEventsFlow: React.FC<DeviceEventsFlowProps> = ({
  tenantId,
  deviceId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { eventsFlowDepth, onChangeEventsFlowDepth } = useEventsFlowDepth();

  const deviceEventsFlowState = useDeviceEventsFlowByDay({
    tenantId,
    deviceId,
    dateFrom,
    dateTo,
    dataResidency,
    eventsFlowDepth,
  });

  const data = useMemo(() => buildSankeyData(deviceEventsFlowState.data || []), [
    deviceEventsFlowState,
  ]);

  return (
    <CardContainer
      isLoading={deviceEventsFlowState.isLoading}
      isSuccess={deviceEventsFlowState.isSuccess}
      isError={deviceEventsFlowState.isError}
      hasData={data.nodes.length > 0}
      height={cardHeight}
      title={cardTitle}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
      footer={
        <ControllerPanel
          eventsFlowDepth={eventsFlowDepth}
          onChangeEventsFlowDepth={onChangeEventsFlowDepth}
        />
      }
    >
      {deviceEventsFlowState.isSuccess && (
        <EventFlowChart data={data} height={cardHeight} />
      )}
    </CardContainer>
  );
};

interface AppEventsFlowProps extends EventsFlowProps {
  appId: string;
}

export const AppEventsFlow: React.FC<AppEventsFlowProps> = ({
  tenantId,
  appId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { eventsFlowDepth, onChangeEventsFlowDepth } = useEventsFlowDepth();

  const appEventsFlowState = useAppEventsFlowByDay({
    tenantId,
    appId,
    dateFrom,
    dateTo,
    dataResidency,
    eventsFlowDepth,
  });

  const data = useMemo(() => buildSankeyData(appEventsFlowState.data || []), [
    appEventsFlowState,
  ]);

  return (
    <CardContainer
      isLoading={appEventsFlowState.isLoading}
      isSuccess={appEventsFlowState.isSuccess}
      isError={appEventsFlowState.isError}
      hasData={data.nodes.length > 0}
      height={cardHeight}
      title={cardTitle}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
      footer={
        <ControllerPanel
          eventsFlowDepth={eventsFlowDepth}
          onChangeEventsFlowDepth={onChangeEventsFlowDepth}
        />
      }
    >
      {appEventsFlowState.isSuccess && <EventFlowChart data={data} height={cardHeight} />}
    </CardContainer>
  );
};
