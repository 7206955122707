import React from 'react';
import { FunnelChart } from 'react-funnel-pipeline';
import 'react-funnel-pipeline/dist/index.css';
import { colors } from './colors';

export interface FunnelDataItem {
  name: string;
  value: number;
}

interface EventFunnelProps {
  data: FunnelDataItem[];
}

const EventsFunnel: React.FC<EventFunnelProps> = ({ data }) => {
  return <FunnelChart data={data} pallette={colors} />;
};

export default EventsFunnel;
