import React, { useCallback, useEffect, useMemo } from 'react';
import { FieldProps } from 'react-jsonschema-form';
import { useTranslation } from 'react-i18next';
import MobileEndpoint from '../../../../../store/types/mobile-endpoint';
import Environment from '../../../../../store/types/environment';
import SchemaForm from '../../schema-form.component';
import Spinner from '../../../spinner/spinner.component';

interface MobileEndpointPickerProps extends FieldProps {
  mobileEndpoints: MobileEndpoint[];
  mobileEndpointsLoaded: boolean;
  environments: Environment[];
  fetchMobileEndpoints: (params: { organizationId: string }) => void;
  fetchEnvironments: (params: { organizationId: string }) => void;
}

interface GridappSettingsForm {
  id?: string;
  buildId?: string;
}

const MobileEndpointPicker = ({
  mobileEndpoints,
  mobileEndpointsLoaded,
  fetchMobileEndpoints,
  environments,
  fetchEnvironments,
  formContext,
  formData = {},
  onChange,
  schema,
}: MobileEndpointPickerProps) => {
  const { t } = useTranslation();
  const organizationId = formContext.organisationId;

  useEffect(() => {
    if (organizationId) {
      fetchMobileEndpoints({ organizationId });
      fetchEnvironments({ organizationId });
    }
  }, [fetchEnvironments, fetchMobileEndpoints, organizationId]);

  const mobilePickerSchema = useMemo(() => {
    const envProps = environments.map((env) => ({
      [env.environmentName]: {
        type: 'object',
        title: env.displayName,
        properties: {
          isManualInput: {
            type: 'boolean',
            title: 'Manually input URL',
            default: false,
          },
        },
        dependencies: {
          isManualInput: {
            oneOf: [
              {
                properties: {
                  isManualInput: {
                    const: true,
                  },
                  url: {
                    type: 'string',
                    title: 'Input URL',
                  },
                },
                required: ['url'],
              },
              {
                properties: {
                  isManualInput: {
                    const: false,
                  },
                  id: {
                    type: 'string',
                    title: t('selectAnEndpoint'),
                    enum: mobileEndpoints.map((mobileEndpoint) => mobileEndpoint.id),
                    enumNames: mobileEndpoints.map(
                      (mobileEndpoint) =>
                        `${mobileEndpoint.displayName} (${mobileEndpoint.urlId})`,
                    ),
                  },
                },
                required: ['id'],
              },
            ],
          },
        },
        required: ['isManualInput'],
      },
    }));
    return {
      type: 'object',
      properties: Object.assign({}, ...envProps),
      require: [environments.map((env) => env.environmentName)],
    };
  }, [environments, mobileEndpoints, t]);

  const onFormChange = useCallback(
    (settings: any) => {
      const completeSettings = Object.keys(settings).reduce((newSettings, env) => {
        const { isManualInput } = settings[env];
        return {
          ...newSettings,
          [env]: isManualInput
            ? { isManualInput, url: settings[env].url }
            : {
                isManualInput,
                ...mobileEndpoints.find(
                  (mobileEndpoint) => mobileEndpoint.id === settings[env].id,
                ),
              },
        };
      }, {});

      onChange({ ...formData, ...completeSettings });
    },
    [formData, mobileEndpoints, onChange],
  );

  if (!mobileEndpointsLoaded) {
    return <Spinner />;
  }

  return (
    <div>
      {schema.title || ''}
      <SchemaForm
        tagName="div"
        schema={mobilePickerSchema}
        data={formData || {}}
        onChange={onFormChange}
      />
    </div>
  );
};

export default MobileEndpointPicker;
