import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useApp, useRollbackAppFromBuild } from '../../../../common/use-app';
import Overlay from '../../../../common/overlay/overlay.component';
import Spinner from '../../../../common/spinner/spinner.component';
import BuildComponent from './build.component';
import { RouteComponentProps } from 'react-router';
import { message } from 'antd';

const ErrorMessage = styled.div`
  padding: 15px 0;
  color: rgb(255, 85, 0);
`;

type BuildContainerProps = RouteComponentProps<{
  organisationId: string;
  appId: string;
  buildId: string;
}>;

const BuildContainer: React.FC<BuildContainerProps> = (props) => {
  const { match } = props;
  const {
    params: { appId, buildId },
  } = match;

  const { t } = useTranslation();

  const appState = useApp({ appId });

  const {
    mutateAsync,
    isLoading: isAppUpdating,
    isError: isAppUpdatingError,
  } = useRollbackAppFromBuild({ appId, buildId });

  const { isEnableLayoutV2, deviceType } = useMemo(
    () => ({
      isEnableLayoutV2: appState.data ? appState.data.enableLayoutV2 : false,
      deviceType: appState.data ? appState.data.deviceType : undefined,
    }),
    [appState.data],
  );

  const handleSettingsRollBack = useCallback(async () => {
    if (appState.data) {
      try {
        await mutateAsync();
        message.success(t('builds.contentRollbacked'));
      } catch (e) {
        message.error(t('builds.errorRollbackContent'));
      }
    }
  }, [appState.data, t, mutateAsync]);

  return (
    <>
      {isAppUpdating && !isAppUpdatingError && (
        <StyledOverlay>
          <Spinner />
        </StyledOverlay>
      )}

      {appState.isLoading && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}

      {appState.isSuccess && (
        <BuildComponent
          match={match}
          onSettingsRollBack={handleSettingsRollBack}
          isEnableLayoutV2={isEnableLayoutV2}
          deviceType={deviceType}
        />
      )}

      {appState.isError && <ErrorMessage>{t('errorDuringFetchingApp')}</ErrorMessage>}
    </>
  );
};

export default BuildContainer;

const StyledOverlay = styled(Overlay)`
  z-index: 1000;
  background: rgb(245 246 250 / 46%);
`;
