import { Redirect, Route, RouteProps } from 'react-router-dom';
import React from 'react';
import { get as getFromObject } from 'lodash';
import User from '../../../store/types/user';
import ErrorView, { ErrorKey } from '../error-view/error-view.component';

const getRouteAuthInfo = (
  user: User | null,
  permissionPath?: string,
  loginOnly?: boolean,
) => {
  const loggedIn = !!user;

  // split permissionPath into permissionGroupKey and permissionKey
  let permissionGroupKey: string = '';
  let permissionKey: string = '';

  if (permissionPath) {
    const [tenantIdOrPermissionGroupKey, ...rest]: string[] = permissionPath.split('.');
    permissionGroupKey = tenantIdOrPermissionGroupKey;
    permissionKey = rest.join('.');
  }

  // for sales user, instead of checking <tenant_id>.[space|installation|...].[create|remove|...] check grid.[space|installation|...].[create|remove|...]
  // replacing tenantId with grid for sales type user
  const salesPermissionOverride = user && user.isSales && permissionPath && !getFromObject(user.permissions, permissionGroupKey, false) ? `grid.${permissionKey}` : permissionPath;

  const hasRouteAccess =
    !!user &&
    (loginOnly ||
      (user.isSysadmin ||
        (user.isSales && permissionPath && salesPermissionOverride && getFromObject(user.permissions, salesPermissionOverride, false)) ||
        (permissionPath && getFromObject(user.permissions, permissionPath, false))));

  return {
    loggedIn,
    hasRouteAccess,
  };
};

export interface ProtectedRouteProps extends RouteProps {
  permissionPath?: string;
  user: User | null;
  loginOnly?: boolean;
}

const ProtectedRoute = ({
  permissionPath,
  user,
  component,
  render,
  loginOnly,
  ...routeProps
}: ProtectedRouteProps) => {
  const authInfo = getRouteAuthInfo(user, permissionPath, loginOnly);
  const Component = component;

  return (
    <Route
      {...routeProps}
      render={(routerProps) => {
        if (authInfo.hasRouteAccess) {
          if (Component) {
            return <Component {...routerProps} />;
          }

          if (render) {
            return render(routerProps);
          }

          // eslint-disable-next-line
          console.warn(`no component or render method specified for ${routeProps.path}`);
          return null;
        }
        if (authInfo.loggedIn) {
          return <ErrorView error={{ message: ErrorKey.ACCESS_DENIED }} position="relative" />;
        }
        return (
          <Redirect
            to={`/login?returnPath=${encodeURIComponent(
              `${routerProps.location.pathname}${routerProps.location.search}`,
            )}`}
          />
        );
      }}
    />
  );
};

export default ProtectedRoute;
