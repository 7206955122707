import React from 'react';
import { FieldProps } from 'react-jsonschema-form';
import { useActions, useStore } from 'easy-peasy';
import MobileEndpointPicker from './mobile-endpoint-picker.component';
import { RootModel, RootState } from '../../../../../store/models/root.model';

const MobileEndpointPickerContainer = (props: FieldProps) => {
  const { formContext } = props;

  const { fetchMobileEndpoints, fetchEnvironments } = useActions<RootModel>(
    (actions) => ({
      fetchMobileEndpoints: actions.organisationMobileEndpoints.fetch,
      fetchEnvironments: actions.environments.fetch,
    }),
  );

  const { mobileEndpoints, mobileEndpointsLoaded, environments } = useStore<RootState>(
    (state: RootState) => ({
      mobileEndpoints: state.organisationMobileEndpoints.values(
        formContext.organisationId,
      ),
      mobileEndpointsLoaded: state.organisationMobileEndpoints.loaded(
        formContext.organisationId,
      ),
      environments: state.environments.values(formContext.organisationId),
    }),
  );

  return (
    <MobileEndpointPicker
      {...props}
      mobileEndpointsLoaded={mobileEndpointsLoaded}
      mobileEndpoints={mobileEndpoints}
      environments={environments}
      fetchMobileEndpoints={fetchMobileEndpoints}
      fetchEnvironments={fetchEnvironments}
    />
  );
};

export default MobileEndpointPickerContainer;
