import { RouteComponentProps } from 'react-router';
import React, { useEffect } from 'react';
import { Alert } from 'antd';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import OnboardingPanelLayout from '../onboarding-panel-layout/onboarding-panel-layout.component';
import { useQueryString } from '../../common/use-query-string-param/use-query-string-param';
import ErrorView from '../../common/error-view/error-view.component';

const ConfirmSuccessContent = styled.div`
  margin: 24px 0;
  font-size: 16px;

  p {
    margin: 16px 0;
  }
`;

// eslint-disable-next-line
const OnboardingConfirmEmail = (props: RouteComponentProps) => {
  const { status } = useQueryString();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!status || !['failed', 'success'].includes(status as string)) {
    return <ErrorView />;
  }

  const isFailed = status === 'failed';
  const isSuccess = status === 'success';

  return (
    <OnboardingPanelLayout>
      {isFailed && (
        <Alert
          message="Subscription error"
          description={t('subscriptionSetupErrorMessage')}
          type="error"
          showIcon
        />
      )}
      {isSuccess && (
        <ConfirmSuccessContent>
          <h1>Confirm your email address</h1>
          <p>
            We have sent you an email that contains link to activate your Ombori Grid
            account.
          </p>
          <p>If the email does not arrive within 5 minutes, please try again.</p>
        </ConfirmSuccessContent>
      )}
    </OnboardingPanelLayout>
  );
};

export default OnboardingConfirmEmail;
