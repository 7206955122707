import React, { useMemo, useCallback, useState, useRef } from 'react';
import styled from '@emotion/styled';
import {
  Typography,
  Table,
  Popover,
  Menu,
  Dropdown,
  Button,
  message,
  Tag,
  Tooltip,
  Row,
  Badge,
} from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import keyBy from 'lodash/keyBy';
import UniversalDevice, {
  UniversalDeviceModuleStatus,
} from '../../../../store/types/universal-device';
import DeviceStatus from '../../device-status/device-status.component';
import DeviceTypeIcon from '../devices-universal-list-item-icon.component/devices-universal-list-item-icon.component';
import OrganisationApp from '../../../../store/types/organisation-app';
import { permissionKeys } from '../../../../utils/auth/permissions';
import usePermissions from '../../../../utils/auth/use-permissions';
import UniversalDeviceType from '../../../../store/types/universal-device-type.enum';
import useDeleteModal from '../../use-delete-modal';
import { DeviceStatusHistory, Status } from '../../../grid/device-monitoring/history';
import moment from 'moment';
import { Icon } from '../../schema-form/common';
import OutdatedDeviceComponents from '../../outdated-components';
import DeviceTypeCell from '../device-universal-type-cell/device-universal-type-cell.component';

interface UniversalDevicesListViewTableProps {
  apps?: OrganisationApp[];
  devices?: UniversalDevice[];
  restartDevice: (params: { deviceUuid: string }) => Promise<void>;
  rebootDevice: (params: { deviceUuid: string }) => Promise<void>;
  deleteDevice: (params: { deviceUuid: string }) => Promise<void>;
  refreshCurrentDevicesPage?: () => Promise<void>;
  isSysAdmin: boolean;
}

interface ColumnProps {
  dataIndex?: string;
  title?: string | JSX.Element;
  key?: string;
  width?: string | number;
  align?: string;
  fixed?: boolean | 'right' | 'left' | undefined;
  render?: (device: UniversalDevice) => JSX.Element | string | undefined;
  sorter?: (a: UniversalDevice, b: UniversalDevice) => number;
}

const { Text } = Typography;

const RemoveButton = styled(Button)`
  color: rgb(255, 85, 0);
  text-transform: capitalize;
  &:hover,
  &:active,
  &:focus {
    color: rgb(255, 85, 0);
  }
` as any;

const EditButton = styled(Button)`
  color: #689bc4;
  text-transform: capitalize;
  &:hover,
  &:active,
  &:focus {
    color: #689bc4;
  }
` as any;

interface ActionComponentProps {
  device: UniversalDevice;
  restartApp: (params: { deviceUuid: string }) => Promise<void>;
  rebootDevice: (params: { deviceUuid: string }) => Promise<void>;
  deleteDevice?: (params: { deviceUuid: string }) => Promise<void>;
  refreshCurrentDevicesPage?: () => Promise<void>;
}

const ActionComponent = ({
  device,
  restartApp,
  rebootDevice,
  deleteDevice,
  refreshCurrentDevicesPage,
}: ActionComponentProps) => {
  const { t } = useTranslation();

  const { isAllowed } = usePermissions(device.organizationId);

  const { canRestart, canReboot, canDelete } = useMemo(() => {
    const canDeviceBeRebootedOrRestarted =
      device.parentDevice &&
      device.parentDevice.type &&
      [UniversalDeviceType.IOTHUB, UniversalDeviceType.ANDROID].includes(
        device.parentDevice.type,
      );

    return {
      canReboot:
        canDeviceBeRebootedOrRestarted && isAllowed(permissionKeys.devices.reboot),
      canRestart:
        canDeviceBeRebootedOrRestarted && isAllowed(permissionKeys.devices.restart),
      canDelete: deleteDevice && isAllowed(permissionKeys.devices.manageDeviceSettings),
    };
  }, [device.parentDevice, isAllowed, deleteDevice]);

  const handleRestart = useCallback(async () => {
    if (device) {
      try {
        await restartApp({ deviceUuid: device.id });
        message.success(t('appRestartRequested'));
      } catch {
        message.error(t('appRestartRequestFailed'));
      }
    }
  }, [device, restartApp, t]);

  const handleReboot = useCallback(async () => {
    if (device) {
      try {
        await rebootDevice({ deviceUuid: device.id });
        message.success(t('deviceRebootRequested'));
      } catch {
        message.error(t('deviceRebootRequestFailed'));
      }
    }
  }, [device, rebootDevice, t]);

  const [showDeleteModal] = useDeleteModal();

  const handleDelete = useCallback(() => {
    if (device) {
      showDeleteModal(
        t('confirmDeleteDevice'),
        t('areYouSureYouWantToDeleteDevice', { deviceName: device.deviceName }),
        device.deviceName,
        async () => {
          try {
            if (!deleteDevice) return;
            await deleteDevice({ deviceUuid: device.uuid });
            if (refreshCurrentDevicesPage) {
              await refreshCurrentDevicesPage();
            }
            message.success(`${t('successfullyDeletedDevice')}`);
          } catch {
            message.error(t('failedToDeleteDevice'));
          }
        },
      );
    }
  }, [deleteDevice, device, showDeleteModal, t, refreshCurrentDevicesPage]);

  if (!canReboot && !canRestart && !canDelete) {
    // no permissions, do not show anything
    return null;
  }

  return (
    <Dropdown
      placement="bottomRight"
      overlay={
        <Menu>
          {canRestart && (
            <Menu.Item key={`${device.id}-restart`}>
              <EditButton type="link" icon="reload" size="small" onClick={handleRestart}>
                {t('restartApps')}
              </EditButton>
            </Menu.Item>
          )}
          {canReboot && (
            <Menu.Item key={`${device.id}-reboot`}>
              <EditButton type="link" icon="poweroff" size="small" onClick={handleReboot}>
                {t('rebootDevice')}
              </EditButton>
            </Menu.Item>
          )}
          {canDelete && (
            <Menu.Item key={`${device.id}-remove`}>
              <RemoveButton
                type="link"
                icon="delete"
                size="small"
                value={device}
                onClick={handleDelete}
              >
                {t('delete')}
              </RemoveButton>
            </Menu.Item>
          )}
        </Menu>
      }
    >
      <Icon type="small-dash" />
    </Dropdown>
  );
};

const UniversalDevicesListViewTable = (props: UniversalDevicesListViewTableProps) => {
  const { t } = useTranslation();

  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);

  const rebootAndRestartProcessIdRef = useRef<Record<string, number>>({});

  const [updatedDeviceStatus, setUpdatedDeviceStatus] = useState<
    Record<
      string,
      | {
          status: string;
          action: string;
          iconType: string;
        }
      | undefined
    >
  >({});

  const {
    devices = [],
    apps = [],
    restartDevice,
    rebootDevice,
    deleteDevice,
    refreshCurrentDevicesPage,
  } = props;

  const handleCheckboxesChange = useCallback((ids: string[]) => {
    setSelectedDevices(ids);
  }, []);

  const keyedApps = useMemo(() => keyBy(apps, 'id'), [apps]);

  const isMassActionToolsVisible = useMemo(() => selectedDevices.length > 0, [
    selectedDevices,
  ]);

  const genearateRestartRebootProcessId = useCallback((deviceId: string) => {
    const processId =
      rebootAndRestartProcessIdRef &&
      rebootAndRestartProcessIdRef.current &&
      rebootAndRestartProcessIdRef.current[deviceId]
        ? rebootAndRestartProcessIdRef.current[deviceId] + 1
        : 1;

    rebootAndRestartProcessIdRef.current = {
      ...rebootAndRestartProcessIdRef.current,
      [deviceId]: processId,
    };

    return processId;
  }, []);

  const onMassRestart = useCallback(() => {
    selectedDevices.map(async (deviceId) => {
      const processId = genearateRestartRebootProcessId(deviceId);
      try {
        setUpdatedDeviceStatus((val) => ({
          ...val,
          [deviceId]: {
            status: 'progress',
            action: t('restarting'),
            iconType: 'loading',
          },
        }));
        await restartDevice({ deviceUuid: deviceId }).then(() => {
          setUpdatedDeviceStatus((val) => ({
            ...val,
            [deviceId]: {
              status: 'success',
              action: t('appRestartRequested'),
              iconType: 'check-circle',
            },
          }));
        });
      } catch {
        setUpdatedDeviceStatus((val) => ({
          ...val,
          [deviceId]: {
            status: 'fail',
            action: t('restartFailed'),
            iconType: 'exclamation-circle',
          },
        }));
      } finally {
        setTimeout(() => {
          if (processId === rebootAndRestartProcessIdRef.current[deviceId]) {
            setUpdatedDeviceStatus((val) => ({
              ...val,
              [deviceId]: undefined,
            }));
          }
        }, 5000);
      }
    });
  }, [genearateRestartRebootProcessId, restartDevice, selectedDevices, t]);

  const onMassReboot = useCallback(() => {
    selectedDevices.map(async (deviceId) => {
      const processId = genearateRestartRebootProcessId(deviceId);

      try {
        setUpdatedDeviceStatus((val) => ({
          ...val,
          [deviceId]: {
            status: 'progress',
            action: t('rebooting'),
            iconType: 'loading',
          },
        }));

        await rebootDevice({ deviceUuid: deviceId }).then(() => {
          setUpdatedDeviceStatus((val) => ({
            ...val,
            [deviceId]: {
              status: 'success',
              action: t('deviceRebootRequested'),
              iconType: 'check-circle',
            },
          }));
        });
      } catch {
        setUpdatedDeviceStatus((val) => ({
          ...val,
          [deviceId]: {
            status: 'fail',
            action: t('rebootFailed'),
            iconType: 'exclamation-circle',
          },
        }));
      } finally {
        setTimeout(() => {
          if (processId === rebootAndRestartProcessIdRef.current[deviceId]) {
            setUpdatedDeviceStatus((val) => ({
              ...val,
              [deviceId]: undefined,
            }));
          }
        }, 5000);
      }
    });
  }, [genearateRestartRebootProcessId, rebootDevice, selectedDevices, t]);

  const columns: ColumnProps[] = useMemo(
    () => [
      {
        title: isMassActionToolsVisible ? (
          <ActionsContainer>
            <div>
              With <b>{selectedDevices.length}</b> selected
            </div>
            <MassActionButton
              type="link"
              icon="reload"
              size="small"
              onClick={onMassRestart}
            >
              {t('restartDevice')}
            </MassActionButton>
            <MassActionButton
              type="link"
              icon="poweroff"
              size="small"
              onClick={onMassReboot}
            >
              {t('rebootDevice')}
            </MassActionButton>
          </ActionsContainer>
        ) : (
          t('device')
        ),
        key: 'deviceName',
        render: (device: UniversalDevice) => (
          <>
            <Link
              to={`/organisations/${device.organizationId}/devices/v3/${device.id}/screen`}
              data-testid="universal-devices-listview-device-name"
            >
              {device.deviceName}
            </Link>
            {device.lease && (
              <Tooltip
                title={t('lease.expires', {
                  expiry: moment(device.lease.expiry).fromNow(),
                })}
              >
                <Tag style={{ marginLeft: '1em' }}>{t('lease.tag')}</Tag>
              </Tooltip>
            )}
          </>
        ),
      },
      {
        title: isMassActionToolsVisible ? '' : t('status'),
        key: 'status',
        width: 216,
        render: (device: UniversalDevice) => {
          if (device.edgeStatusLog) {
            return (
              <div style={{ display: 'flex' }}>
                <DeviceStatusHistory
                  history={device.edgeStatusLog}
                  updateTime={device.edgeStatusUpdateTime}
                  small
                  narrow
                />
                <Status status={device.edgeStatus} />
              </div>
            );
          }

          const deviceStatus = updatedDeviceStatus && updatedDeviceStatus[device.id];

          if (deviceStatus) {
            return (
              <StatusContainer status={deviceStatus.status}>
                <DeviceStatusContainer status={deviceStatus.status}>
                  <DeviceCurrentStatusIcon
                    type={deviceStatus.iconType}
                    status={deviceStatus.status}
                  />
                  {deviceStatus.action}
                </DeviceStatusContainer>
              </StatusContainer>
            );
          }

          return <DeviceStatus device={device} key={device.id} />;
        },
      },
      {
        title: isMassActionToolsVisible ? '' : t('type'),
        key: 'deviceType',
        render: (device: UniversalDevice) => {
          const agentModule =
            device.modules && device.modules.find((device) => device.module === 'agent');

          if (agentModule && agentModule.telemetry.os) {
            return (
              <DeviceTypeCell status={agentModule.status} os={agentModule.telemetry.os} />
            );
          } else {
            return t('notAvailable');
          }
        },
      },
      {
        title: isMassActionToolsVisible ? '' : t('serialNumber'),
        key: 'deviceSerial',
        render: (device: UniversalDevice) => device.deviceSerial,
      },
      ...(apps.length
        ? [
            {
              title: isMassActionToolsVisible ? '' : t('installation'),
              key: 'installation',
              width: 250,
              render: (device: UniversalDevice) => {
                const { appIds: deviceAppIds, id: deviceId, organizationId } = device;

                return (
                  <InstallationContainer>
                    {deviceAppIds.map((deviceAppId: string): JSX.Element | null => {
                      const app = keyedApps[deviceAppId];

                      if (!app) {
                        return null;
                      }

                      return (
                        <LinkStyle
                          key={`${deviceId}-${deviceAppId}-link`}
                          to={`/organisations/${organizationId}/apps/${deviceAppId}/overview`}
                        >
                          {app.displayName}
                        </LinkStyle>
                      );
                    })}
                  </InstallationContainer>
                );
              },
            },
          ]
        : []),
      {
        title: t('environment'),
        dataIndex: 'env',
        key: 'env',
      },
      // {
      //   title: t('space').toUpperCase(),
      //   dataIndex: 'space',
      //   key: 'space',
      // },
      {
        title: isMassActionToolsVisible ? '' : <Icon type="setting" />,
        key: 'settings',
        fixed: 'right',
        width: 100,
        align: 'center',
        render: (device: UniversalDevice) => {
          return (
            <Settings>
              <OutdatedDeviceComponents
                key={`${device.id}-outdated-device-component`}
                deviceUuid={device.uuid}
              />
              {device.screenshotUrl ? (
                <>
                  <Popover
                    key={`${device.id}-popover`}
                    placement="leftBottom"
                    content={
                      <DeviceScreenshotImage
                        alt={`${device.deviceName} status`}
                        src={device.screenshotUrl}
                      />
                    }
                    overlayStyle={{
                      maxWidth: '250px',
                      maxHeight: '200px',
                    }}
                  >
                    <WithScreenshotIcon type="eye" />
                  </Popover>
                </>
              ) : (
                <NoScreenshotIcon type="eye-invisible" />
              )}
              <ActionComponent
                device={device}
                restartApp={restartDevice}
                rebootDevice={rebootDevice}
                deleteDevice={device.lease ? undefined : deleteDevice}
                refreshCurrentDevicesPage={refreshCurrentDevicesPage}
              />
            </Settings>
          );
        },
      },
    ],
    [
      isMassActionToolsVisible,
      selectedDevices.length,
      onMassRestart,
      t,
      onMassReboot,
      apps.length,
      updatedDeviceStatus,
      keyedApps,
      restartDevice,
      rebootDevice,
      deleteDevice,
      refreshCurrentDevicesPage,
    ],
  );

  return (
    <DeviceTable
      rowSelection={{
        type: 'checkbox' as any,
        onChange: handleCheckboxesChange as any,
      }}
      rowKey="id"
      dataSource={devices}
      columns={columns as any}
      scroll={{ x: true }}
      pagination={false}
      size="small"
    />
  );
};

const DeviceTable = styled(Table)`
  .ant-table-thead > tr > th {
    background: rgba(245, 246, 250, 0.8) !important;
    height: 56px;
  }

  .ant-table-scroll > .ant-table-body {
    overflow-x: auto !important;
  }
`;

const WithScreenshotIcon = styled(Icon)`
  color: #000000;
`;

const NoScreenshotIcon = styled(Icon)`
  color: #b3b3b3;
`;

const DeviceScreenshotImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
`;

const InstallationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LinkStyle = styled(Link)`
  box-sizing: border-box;
`;

const ActionsContainer = styled.div`
  position: absolute;
  display: flex;
`;

const MassActionButton = styled(Button)`
  padding: 6px 16px;
  top: -6px;
  background-color: #ffffff;
  border-color: rgba(221, 221, 221, 1);
  border-radius: 4px;
  color: rgb(0, 100, 182);
  box-shadow: rgb(0 0 0 / 4%) 0px 1px 2px 0px;
  box-sizing: border-box;
  margin-left: 16px;
  height: 100%;
  &:hover,
  &:active,
  &:focus {
    border-color: rgba(0, 100, 182, 1);
    border-radius: 4px;
    background-color: #ffffff;
  }
` as any;

const StatusContainer = styled.div<{ status: string }>`
  text-transform: capitalize;
  margin: 2px auto;
  color: ${({ status }) => {
    switch (status) {
      case 'success':
        return ' rgb(0, 174, 72);';
      case 'fail':
        return 'rgb(240, 36, 36);';
      default:
        return 'rgb(0, 100, 182);';
    }
  }};
`;

const DeviceStatusContainer = styled.span<{ status: string }>`
  padding: 5px;
  border-radius: 4px;
  background-color: ${({ status }) => {
    switch (status) {
      case 'success':
        return 'rgba(0, 174, 72, 0.12);';
      case 'fail':
        return 'rgba(240, 36, 36, 0.12);';
      default:
        return 'rgba(236, 240, 241, 0.5);';
    }
  }};
`;

const DeviceCurrentStatusIcon = styled(Icon)<{ status?: string }>`
  margin-right: 10px;
  color: ${({ status }) => {
    switch (status) {
      case 'success':
        return 'rgb(0, 174, 72);';
      case 'fail':
        return 'rgb(240, 36, 36);';
      default:
        return 'rgb(0, 100, 182);';
    }
  }};
`;

const Settings = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const ModuleVersionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default UniversalDevicesListViewTable;
