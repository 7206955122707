import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import moment from 'moment';
import { useCallback, useMemo } from 'react';

const useDateRange = (rangeStartOffsetInDays: number = 8, rangeEndOffsetInDays = 1) => {
  const history = useHistory();

  const location = useLocation();

  const { search } = location;

  const { fromDate, toDate } = qs.parse(search);

  const isDate = (date: any): date is string =>
    typeof date === 'string' && /\d{4}-\d{2}-\d{2}/.test(date);

  const dateFrom = !isDate(fromDate)
    ? moment()
        .subtract(rangeStartOffsetInDays, 'd')
        .format('YYYY-MM-DD')
    : fromDate;

  const dateTo = !isDate(toDate)
    ? moment()
        .subtract(rangeEndOffsetInDays, 'd')
        .format('YYYY-MM-DD')
    : toDate;

  const onChangeRange = useCallback(
    (date: any[], [nextFromDate, nextToDate]: [string, string]) => {
      history.replace({
        pathname: history.location.pathname,
        search: qs.stringify({
          ...qs.parse(history.location.search),
          fromDate: nextFromDate,
          toDate: nextToDate,
        }),
      });
    },
    [history],
  );

  const result = useMemo(
    () => ({
      dateFrom,
      dateTo,
      onChangeRange,
    }),
    [dateFrom, dateTo, onChangeRange],
  );

  return result;
};

export default useDateRange;
