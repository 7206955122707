import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Table, Tag } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { PaginationConfig } from 'antd/lib/pagination';
import dayjs from 'dayjs';
import InvoicesPaginatedResult, {
  InvoiceListItem,
  InvoiceStatus,
} from '../../../../../store/types/invoice';
import { ApiError } from '../../../../../services/api/api-error';
import { formatAmount } from '../../../../../utils/currency';
import { BillingCurrencyEnum } from '../../../../../store/types/organisation';
import { loadingIcon } from '../../../../common/spinner/spinner.component';
import buildInvoiceDocumentUrl from '../../../../../utils/build-invoice-document-url';

const { Column } = Table;

const formatBalance = (currency: BillingCurrencyEnum, value: number | null) =>
  value !== null ? formatAmount(currency, value) : '';

interface InvoicesListProps extends RouteComponentProps<{ organisationId: string }> {
  error: ApiError | null;
  loading: boolean;
  fetchInvoices: (params: {
    organisationId: string;
    page: number;
    limit: number;
    startDate: string;
    endDate: string;
  }) => void;
  paginatedInvoices: InvoicesPaginatedResult;
}

const InvoicesList = (props: InvoicesListProps) => {
  const { loading, fetchInvoices, paginatedInvoices, match } = props;
  const { organisationId } = match.params;

  const { t } = useTranslation();

  const paginationParams = useMemo(() => {
    const now = dayjs();
    return {
      startDate: now.subtract(1, 'year').toISOString(),
      endDate: now.toISOString(),
      organisationId,
    };
  }, [organisationId]);

  useEffect(() => {
    fetchInvoices({
      ...paginationParams,
      page: 1,
      limit: 50,
    });
  }, [fetchInvoices, paginationParams]);

  const handleTableChange = useCallback(
    (paginationConfig: PaginationConfig) => {
      const { current, pageSize } = paginationConfig;
      if (current && pageSize) {
        fetchInvoices({
          ...paginationParams,
          page: current,
          limit: pageSize,
        });
      }
    },
    [fetchInvoices, paginationParams],
  );

  const getPaginationConfig = useCallback(() => {
    return paginatedInvoices
      ? {
          total: paginatedInvoices.totalItems,
          current: paginatedInvoices.page,
          pageSize: paginatedInvoices.limit,
        }
      : {};
  }, [paginatedInvoices]);

  const renderStatus = useCallback(
    ({ status, externalId }: InvoiceListItem) => {
      if (externalId == null) {
        return null;
      }
      switch (status) {
        case InvoiceStatus.Open:
          return <Tag color="volcano">{t('billing.invoiceStatusOpen')}</Tag>;
        case InvoiceStatus.PaidInFull:
          return <Tag color="green">{t('billing.invoiceStatusPaidInFull')}</Tag>;
        default:
          return null;
      }
    },
    [t],
  );

  const renderAction = useCallback(
    (invoice: InvoiceListItem) => {
      // TODO: Enable the downloads for not-real invoices
      if (invoice.externalId == null) {
        return null;
      }
      const downloadUrl = buildInvoiceDocumentUrl(invoice);
      return (
        <Button type="link" icon="download" size="small" href={downloadUrl}>
          {t('billing.invoiceActionDownload')}
        </Button>
      );
    },
    [t],
  );

  const dataSource = paginatedInvoices ? paginatedInvoices.items : [];

  return (
    <div className="content-body">
      <Table
        rowKey="id"
        scroll={{ x: 900 }}
        pagination={getPaginationConfig()}
        onChange={handleTableChange}
        dataSource={dataSource}
        loading={{
          indicator: loadingIcon,
          spinning: loading,
        }}
      >
        <Column
          fixed="left"
          width={150}
          title={t('billing.invoiceDate')}
          dataIndex="date"
          key="date"
          render={(value: number) => dayjs(value).format('YYYY-MM-DD')}
        />
        <Column
          ellipsis
          title={t('billing.invoiceDescription')}
          dataIndex="description"
          key="description"
        />
        <Column
          ellipsis
          width={200}
          title={t('billing.invoiceTotalAmount')}
          dataIndex="total"
          render={(value: number, item: InvoiceListItem) =>
            formatBalance(item.currency, value)
          }
        />
        <Column
          width={200}
          title={t('billing.invoiceStatus')}
          dataIndex=""
          key="status"
          render={renderStatus}
        />
        <Column
          width={200}
          title={t('billing.invoiceAction')}
          dataIndex=""
          key="action"
          render={renderAction}
        />
      </Table>
    </div>
  );
};

export default InvoicesList;
