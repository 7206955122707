import { useQuery } from 'react-query';
import User from '../../../store/types/user';
import { useApiService } from '../api-service-provider';

const useUser = (organizationId: string, isWithSysAdmin = true) => {
  const apiService = useApiService();

  return useQuery(
    ['user', organizationId, isWithSysAdmin],
    () =>
      apiService.get<User[]>('/api/users', {
        organizationId,
        orgWithSysadmin: isWithSysAdmin,
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export default useUser;
