import { Button, List, Popconfirm, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import Media, { VideoAnalysisResultValue } from '../../../../store/types/media';
import MediaListItemAvatar from './media-list-item-avatar.component';
import MediaItemVideoSection from '../media-item-video-section/media-item-video-section.component';
import {
  checkMediaItemCreatedRecently,
  getMediaItemKind,
  getMediaItemMimeTypeWithMetaInfo,
  getVideoAnalysisResultValue,
  MEDIA_ITEM_VIDEO_VARIANT_TYPE,
  MediaItemKind,
} from '../../../../utils/media-item-utils';
import {
  getMediaTemplateStatus,
  isMediaTemplate as checkMediaTemplate,
  MediaTemplateStatus,
} from '../../../../utils/media-templates';
import useCurrentDate from '../../../../utils/date/use-current-date';

const { Text } = Typography;

const MediaItem = styled(List.Item)<{ disabled: boolean }>`
  padding: 16px 24px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1.0)};
`;

const MediaItemNameButton: any = styled(Button)`
  padding: 0;
  height: auto;
  white-space: normal;
  text-align: left;
`;

const MediaListItemMeta = styled(List.Item.Meta)`
  align-items: center;
`;

const MediaListItemContentContainer = styled.div`
  flex: 1;
  flex-direction: column;
`;

interface MediaListItemProps {
  item: Media;
  mediaTypesFilter: string[] | undefined;
  onDelete: (mediaItem: Media) => void;
  onEdit: (mediaItem: Media) => void;
  onSelect: (mediaItem: Media) => void;
  canViewMedia: boolean;
  canUpdateMedia: boolean;
  canRemoveMedia: boolean;
}

const MediaListItem = (props: MediaListItemProps) => {
  const {
    item,
    mediaTypesFilter,
    onDelete,
    onEdit,
    onSelect,
    canViewMedia,
    canUpdateMedia,
    canRemoveMedia,
  } = props;

  const { t } = useTranslation();

  const itemKind = getMediaItemKind(item);
  const isFolder = itemKind === MediaItemKind.folder;
  const isMediaTemplate = checkMediaTemplate(item);
  const isVideo = itemKind === MediaItemKind.video;

  const currentDate = useCurrentDate();
  const isCreatedRecently = checkMediaItemCreatedRecently(item, currentDate);
  const analysisResultValue = getVideoAnalysisResultValue(item);

  const isInvalidMediaType =
    !isFolder && mediaTypesFilter != null && !mediaTypesFilter.includes(item.type);
  const isInvalidMediaTemplate =
    isMediaTemplate && getMediaTemplateStatus(item) !== MediaTemplateStatus.VALID;
  const isInvalidVideo = (() => {
    // We only allow videos not to have the analysis result if were not uploaded recently.
    // All the newer videos, or the ones that are known to be unsupported, are not allowed
    // for selection.
    if (!isVideo) {
      return false;
    }
    if (analysisResultValue == null) {
      return isCreatedRecently;
    }
    return analysisResultValue !== VideoAnalysisResultValue.conversionNotNeeded;
  })();

  const originalItemDisabled =
    isInvalidMediaType || isInvalidMediaTemplate || isInvalidVideo;

  const videoVariantsDisabled =
    !isVideo ||
    (mediaTypesFilter != null &&
      !mediaTypesFilter.includes(MEDIA_ITEM_VIDEO_VARIANT_TYPE));

  const disabled = originalItemDisabled && videoVariantsDisabled;

  const handleDelete = React.useCallback(() => onDelete(item), [item, onDelete]);
  const handleEdit = React.useCallback(() => onEdit(item), [item, onEdit]);

  const actionButtons = useMemo(() => {
    const buttons: any = [];

    if (canRemoveMedia) {
      buttons.push(
        <Popconfirm
          title={t('areYouSureYouWantToDelete')}
          onConfirm={handleDelete}
          okText={t('yes')}
          cancelText={t('no')}
        >
          <Button size="small" type="link" icon="delete" data-testid="media-list-item-delete-button">
            {t('delete')}
          </Button>
        </Popconfirm>,
      );
    }

    if (canUpdateMedia) {
      buttons.push(
        <Button onClick={handleEdit} size="small" type="link" icon="edit" data-testid="media-list-item-edit-button">
          {t('edit')}
        </Button>,
      );
    } else if (canViewMedia) {
      buttons.push(
        <Button onClick={handleEdit} size="small" type="link" icon="info-circle-o">
          {t('details')}
        </Button>,
      );
    }

    return buttons;
  }, [canRemoveMedia, canUpdateMedia, canViewMedia, t, handleDelete, handleEdit]);

  const mediaItemDescription = useMemo(() => {
    return getMediaItemMimeTypeWithMetaInfo(item);
  }, [item]);

  return (
    <MediaItem disabled={disabled} actions={[...actionButtons]}>
      <MediaListItemContentContainer>
        <MediaListItemMeta
          avatar={<MediaListItemAvatar mediaItem={item} />}
          title={
            !originalItemDisabled ? (
              <MediaItemNameButton type="link" onClick={() => onSelect(item)}>
                {item.name}
              </MediaItemNameButton>
            ) : (
              item.name
            )
          }
          description={mediaItemDescription}
        />
        {isVideo && (
          <MediaItemVideoSection
            item={item}
            onSelect={onSelect}
            disabled={videoVariantsDisabled}
          />
        )}
      </MediaListItemContentContainer>
      <Text type="secondary">{dayjs(item.updatedAt).format('DD-MM-YYYY HH:mm')}</Text>
    </MediaItem>
  );
};

export default MediaListItem;
