import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Spin } from 'antd';
import { Icon } from '../schema-form/common';
import { ApiError } from '../../../services/api/api-error';
import ErrorView from '../error-view/error-view.component';

interface OverlayProps {
  className?: string;
  children?: React.ReactNode;
  spinnerOverlay?: {
    isLoading?: boolean;
    processingTip?: string | null;
    spinnerSize?: number;
  },
  errorOverlay?: {
    error?: ApiError | Error | null;
    layout?: 'filled' | 'card';
  }
}

const Overlay = (props: OverlayProps) => {
  const { className, children, spinnerOverlay, errorOverlay } = props;

  const { isLoading = false, processingTip = undefined, spinnerSize = 32 } = spinnerOverlay || {};
  const { error = undefined, layout = 'card' } = errorOverlay || {};

  const { t } = useTranslation();

  const loadingMessage =
    processingTip === null ? undefined : processingTip || t('processing');

  if (error) {
    return (
      <OverlayContainer className={className}>
        <ErrorViewStyled fillPage={layout === 'filled'} error={error} />
      </OverlayContainer>
    );
  }

  return (
    <OverlayContainer className={className}>
      {isLoading && (
        <>
          <Mask className="overlay-mask" />
          <Spinner
            className="overlay-spinner"
            tip={loadingMessage}
            indicator={<StyledIcon type="loading" size={spinnerSize} spin />}
          />
        </>
      )}
      {children}
    </OverlayContainer>
  );
};

const OverlayContainer = styled.div``;

const Mask = styled.div`
  position: absolute;
  height: 100%;
  height: -webkit-fill-available;
  width: 100%;
  width: -webkit-fill-available;
  opacity: 1 !important;
  background: rgb(245 246 250 / 46%);
  z-index: 99;
`;

const Spinner = styled(Spin)`
  position: absolute;
  z-index: 99;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;
  height: -webkit-fill-available;
  width: 100%;
  width: -webkit-fill-available;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

const ErrorViewStyled = styled(ErrorView)`
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  top: unset;
  bottom: unset;
`;

export default Overlay;
