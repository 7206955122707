import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Row, Typography, Modal, message } from 'antd';
import { ApiError } from '../../../../../../services/api/api-error';
import Organisation, {
  BillingCurrencyEnum,
} from '../../../../../../store/types/organisation';
import Plan, { PlanTypeEnum } from '../../../../../../store/types/organisation-plan';
import Loader from '../../../../../common/loader/loader-component';
import AvailablePlansCard from './available-plans-card.component';
import { sortOrganizationPlans } from '../../../../../../utils/plans';
import ErrorView from '../../../../../common/error-view/error-view.component';

const { Title, Text } = Typography;

interface AvailablePlansProps extends RouteComponentProps<{ organisationId: string }> {
  organization: Organisation;
  loading: boolean;
  error: ApiError;
  fetchAvailablePlans: (params: {
    organizationId: string;
    planType: string;
  }) => Promise<void>;
  schedulePlanUpdate: (params: {
    organizationId: string;
    planType: PlanTypeEnum;
    planId: string;
  }) => Promise<void>;
  fetchActivePlans: (params: {
    organizationId: string;
    silent?: boolean;
  }) => Promise<void>;
  fetchScheduledPlans: (params: {
    organizationId: string;
    silent?: boolean;
  }) => Promise<void>;
  organizationAvailablePlans: Plan[];
  activePlanId: string;
  activePlanType: PlanTypeEnum;
  onCloseParentModal: () => void;
}

const AvailablePlans = (props: AvailablePlansProps) => {
  const {
    match,
    fetchAvailablePlans,
    schedulePlanUpdate,
    fetchActivePlans,
    fetchScheduledPlans,
    organization,
    loading,
    error,
    organizationAvailablePlans,
    activePlanId,
    activePlanType,
    onCloseParentModal,
  } = props;
  const { currency } = organization;
  const tenantCurrency = currency as BillingCurrencyEnum;
  const { organisationId } = match.params;
  const { t } = useTranslation();

  // this will make sure no previous data appears before loading icon appears
  const hasMounted = useRef<boolean>(false);

  useEffect(() => {
    fetchAvailablePlans({ organizationId: organisationId, planType: activePlanType });
    hasMounted.current = true;
  }, [fetchAvailablePlans, organisationId, activePlanType]);

  const modalLang = useMemo(() => {
    switch (activePlanType) {
      case PlanTypeEnum.TENANT:
        return {
          msgConfirm: t('plans.upgradeAvailablePlanConfirmation'),
          msgSuccess: t('plans.upgradeAvailablePlanSuccess'),
          msgFailure: t('plans.upgradeAvailablePlanFailure'),
        };
      case PlanTypeEnum.SUPPORT:
        return {
          msgConfirm: t('plans.changeAvailablePlanConfirmation'),
          msgSuccess: t('plans.changeAvailablePlanSuccess'),
          msgFailure: t('plans.changeAvailablePlanFailure'),
        };
      default:
        return {
          msgConfirm: '',
          msgSuccess: '',
          msgFailure: '',
        };
    }
  }, [activePlanType, t]);

  const handleSelectAvailablePlan = useCallback(
    (plan: Plan) => {
      const { msgConfirm, msgSuccess, msgFailure } = modalLang;

      const modal = Modal.confirm({
        title: t('plans.activatePlanConfirmAction'),
        content: <ModalContent>{msgConfirm}</ModalContent>,
        okText: t('yes'),
        cancelText: t('no'),
        className: 'modal-content-m-0',
        onOk: async () => {
          modal.update({ cancelButtonProps: { disabled: true } });

          try {
            await schedulePlanUpdate({
              organizationId: organisationId,
              planId: plan.id,
              planType: plan.type,
            });
            await Promise.all([
              fetchActivePlans({ organizationId: organisationId, silent: true }),
              fetchScheduledPlans({ organizationId: organisationId, silent: true }),
            ]);
            message.success(msgSuccess);
            modal.destroy();
            onCloseParentModal();
          } catch (err) {
            message.error(msgFailure);
          } finally {
            modal.update({ cancelButtonProps: { disabled: false } });
          }
        },
        onCancel: () => {},
      });
    },
    [
      modalLang,
      t,
      schedulePlanUpdate,
      organisationId,
      fetchActivePlans,
      fetchScheduledPlans,
      onCloseParentModal,
    ],
  );

  const sortedAvailablePlans: Plan[] = useMemo(
    () => sortOrganizationPlans(organizationAvailablePlans, tenantCurrency),
    [organizationAvailablePlans, tenantCurrency],
  );

  const noPlans = !sortedAvailablePlans.length ? (
    <ErrorView title={t('plans.noPlansAvailable')} />
  ) : null;

  return (
    <Box>
      <Heading>
        <Title level={1}>{t('plans.gridPricingTitle')}</Title>
        <Text strong>{t('plans.gridPricingDescription')}</Text>
      </Heading>
      <Content className="ombori-thin-scroll">
        <Loader
          loading={loading}
          hasMounted={hasMounted.current}
          error={error || noPlans}
        >
          <RowStyled type="flex" justify="center">
            {sortedAvailablePlans.map((plan) => {
              const { id } = plan;
              return (
                <AvailablePlansCard
                  key={id}
                  plan={plan}
                  isActivePlan={activePlanId === id}
                  tenantCurrency={currency as BillingCurrencyEnum}
                  onSelectAvailablePlan={handleSelectAvailablePlan}
                />
              );
            })}
          </RowStyled>
        </Loader>
      </Content>
    </Box>
  );
};

const Box = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
`;

const Heading = styled.div`
  flex: 0 1 auto;
  text-align: center;
  margin-bottom: 15px;
  h1 {
    margin-bottom: 5px;
  }
  span {
    font-weight: 500;
    font-size: 15px;
  }
`;

const Content = styled.div`
  height: 100%;
  position: relative;

  /* always on to maintain the content padding/margin */
  overflow-y: scroll;
  overflow-x: scroll;

  /* adjust to offset the content padding/margin */
  &.ombori-thin-scroll {
    margin-right: -20px;
    margin-bottom: -20px;
  }
`;

const RowStyled = styled(Row)`
  .ant-col {
    margin-bottom: 20px;
  }

  flex-flow: nowrap;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 991px) {
    display: grid;
    flex-flow: row wrap;
    width: auto;
  }
`;

const ModalContent = styled.div`
  margin-top: 20px;
`;

export default AvailablePlans;
