import React from 'react';
import styled, { StyledComponent } from '@emotion/styled';
import { Collapse as AntdCollapse, Typography, Icon as AntdIcon, Tooltip } from 'antd';
import { CollapseProps as AntdCollapseProps } from 'antd/lib/collapse';
import { Info, HelpCircle } from 'react-feather';

const { Panel } = AntdCollapse;
const { Title } = Typography;

export interface ObjectCollapseUiOptions {
  className?: string;
  isExpanded?: boolean;
  isBordered?: boolean;
  isContentPadded?: boolean;
  hasDivider?: boolean;
  icon?: string;
  gap?: number;
}

interface ObjectCollapseProps {
  className?: string;
  id?: string;
  title?: string;
  description?: string;
  isExpanded?: boolean;
  isBordered?: boolean;
  isContentPadded?: boolean;
  hasDivider?: boolean;
  icon?: React.ReactNode;
  gap?: number;
  helpText?: string;
  children: React.ReactNode;
}

const ObjectCollapse = (props: ObjectCollapseProps) => {
  const {
    className,
    id = 'panel',
    title,
    description,
    isExpanded = true,
    isBordered = true,
    isContentPadded = true,
    hasDivider = false,
    icon,
    gap,
    helpText,
    children,
  } = props;

  return (
    <>
      {hasDivider && <Divider />}
      <CollapseContainer
        className={className}
        bordered={isBordered}
        defaultActiveKey={isExpanded ? [id] : []}
        expandIconPosition="right"
      >
        <CollapsePanel
          key={id}
          header={
            <>
              <HeaderTitle isBordered={isBordered}>
                {typeof icon === 'string' ? <Icon size={20} type={icon} /> : icon}
                {title && <Title level={3}>{title}</Title>}
              </HeaderTitle>
              {helpText && (
                <TooltipWrap>
                  <Tooltip
                    placement="left"
                    title={<div dangerouslySetInnerHTML={{ __html: helpText }} />}
                  >
                    <HelpCircle size={16} />
                  </Tooltip>
                </TooltipWrap>
              )}
            </>
          }
          isBordered={isBordered}
          isContentPadded={isContentPadded}
          gap={gap}
        >
          {description && (
            <DescriptionWrap>
              {description && (
                <>
                  <Icon size={18} component={() => <Info />} />
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                </>
              )}
            </DescriptionWrap>
          )}
          {children}
        </CollapsePanel>
      </CollapseContainer>
    </>
  );
};

const Divider = styled.div`
  border-top: 1px solid rgb(235, 237, 244);
  margin: 0 -16px;
`;

type StyledCollapseType = StyledComponent<
  AntdCollapseProps & React.ClassAttributes<AntdCollapse> & { $hasDivider?: number },
  Pick<AntdCollapseProps & React.ClassAttributes<AntdCollapse>, keyof AntdCollapseProps>,
  any
>;
const CollapseContainer = styled(AntdCollapse)<{
  $hasDivider?: boolean;
  bordered?: boolean;
}>`
  background-color: unset;
  margin-bottom: ${({ bordered }) => (bordered ? '10px' : '0')};

  .ant-collapse-arrow {
    right: ${({ bordered }) => (bordered ? '16px' : '9px')} !important;
  }

  .ant-collapse-arrow > svg {
    height: 10px;
    transform: scale(1.2, 1) rotate(90deg) !important;
    transition: transform 0.25s linear !important;
  }

  .ant-collapse-item-active .ant-collapse-arrow > svg {
    height: 10px;
    transform: scale(1.2, 1) rotate(-90deg) !important;
    transition: transform 0.25s linear !important;
  }

  .ant-collapse-header {
    display: flex;
    padding: 13px 0 !important;
  }

  & + .help-block,
  & + div > .error-detail {
    display: none;
  }
` as StyledCollapseType;

const HeaderTitle = styled.div<{ isBordered?: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: ${({ isBordered }) => (isBordered ? '0 15px' : '0 8px 0 0')};
`;

const TooltipWrap = styled.div`
  display: flex;
  align-content: center;
  margin: auto 40px auto auto;
  align-self: center;
  float: right;
  color: #808080;
`;

const CollapsePanel = styled(Panel)<{ isBordered?: boolean; isContentPadded?: boolean; gap?: number }>`
  > .ant-collapse-content {
    ${({ isContentPadded }) => isContentPadded
      ? 'padding: 10px 10px 25px 10px;'
      : ''
    }

    > .ant-collapse-content-box {
      display: flex;
      flex-direction: column;
      gap: ${({ gap = 8 }) => gap}px;
    }
  }
`;

const DescriptionWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Icon = styled(AntdIcon)<{ size?: number }>`
  svg {
    height: ${({ size = 16 }) => `${size}px`};
    width: ${({ size = 16 }) => `${size}px`};
  }
  &.anticon + span {
    margin-left: 5px;
  }
`;

export default ObjectCollapse;
