import { CatalogPageLocationProduct } from '@ombori/grid-products';

export const getUniqueCatalogImages = (
  catalogPageLocationProducts: CatalogPageLocationProduct[],
) => {
  const uniqueData: CatalogPageLocationProduct[] = [];

  catalogPageLocationProducts.forEach((item) => {
    const existingItem = uniqueData.find(
      (uniqueItem) =>
        uniqueItem.catalogPageLocation === item.catalogPageLocation &&
        uniqueItem.catalogType === item.catalogType,
    );

    if (!existingItem || (!existingItem.defaultImage && item.defaultImage)) {
      if (existingItem) {
        const index = uniqueData.indexOf(existingItem);
        uniqueData[index] = item;
      } else {
        uniqueData.push(item);
      }
    }
  });

  return uniqueData;
};
