import React from 'react';
import styled from '@emotion/styled';
import { Field } from 'react-final-form';
import { Select, Radio } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useTranslation } from 'react-i18next';
import { FormTextInput } from '../../../organisations/organisation-details/queue-details/queue-setup/styled-block';
import { Sku } from '../../../common/use-skus';
import {
  InputWrap,
  composeValidators,
  validateByRegExPattern,
  validateCharacterLength,
  validateMaxLength,
  URL_REGEX,
} from '../../../common/react-final-form';
import { ChangeEvent } from '../../../../types';
import { PlanTypeEnum } from '../../../../store/types/organisation-plan';
import { requiredAll } from '../../../organisations/organisation-details/queue-details/queue-setup/validate-form';

interface PlanFormMainSectionProps {
  skus: Sku[];
}

const PlanFormMainSection = (props: PlanFormMainSectionProps) => {
  const { skus } = props;

  const { t } = useTranslation();

  return (
    <>
      <Field
        name="sku"
        validate={requiredAll}
        render={({ input, meta }) => (
          <FieldContainer>
            <InputWrap
              label={t('plans.form.sku')}
              isRequired={true}
              error={
                (!!meta.error ||
                  (!!meta.submitError && !meta.dirtySinceLastSubmit && !meta.submitting)) &&
                meta.touched
                  ? meta.error || meta.submitError
                  : null
              }
            >
              <Select
                value={input.value ? input.value : undefined}
                disabled={!skus || !skus.length}
                placeholder={'Please select'}
                showSearch={true}
                onChange={(value) => {
                  input.onChange({
                    target: {
                      value,
                    },
                  } as unknown as ChangeEvent<string>);
                }}
              >
                {skus &&
                  skus.map(({ sku }) => (
                    <Select.Option key={sku} value={sku}>
                      {sku}
                    </Select.Option>
                  ))}
              </Select>
            </InputWrap>
          </FieldContainer>
        )}
      />

      <Field
        name="type"
        validate={requiredAll}
        render={({ input, meta }) => (
          <FieldContainer>
            <InputWrap
              label={t('plans.form.type')}
              isRequired={true}
              error={meta.error && meta.touched ? meta.error : null}
            >
              <Select
                value={input.value ? input.value : undefined}
                placeholder={'Please select'}
                onChange={(value) => {
                  input.onChange({
                    target: {
                      value,
                    },
                  } as unknown as ChangeEvent<string>);
                }}
              >
                {Object.entries(PlanTypeEnum).map(([label, value]) => (
                  <Select.Option key={value} value={value}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </InputWrap>
          </FieldContainer>
        )}
      />

      <Field
        name="name.en"
        validate={composeValidators([
          requiredAll,
          validateCharacterLength(3, 200),
        ])}
        render={({ input, meta }) => (
          <FieldContainer>
            <FormTextInput
              input={input}
              meta={meta}
              label={t('plans.form.name')}
              required={true}
            />
          </FieldContainer>
        )}
      />

      <Field
        name="description.en"
        validate={composeValidators([requiredAll, validateMaxLength(500)])}
        render={({ input, meta }) => (
          <FieldContainer>
            <InputWrap
              label={t('plans.form.description')}
              isRequired={true}
              error={meta.error && meta.touched ? meta.error : null}
            >
              <TextArea
                rows={3}
                defaultValue={input.value}
                onChange={({ target: { value } }) => {
                  input.onChange({
                    target: {
                      value,
                    },
                  } as unknown as ChangeEvent<string>);
                }}
              />
            </InputWrap>
          </FieldContainer>
        )}
      />

      <Field
        name="isDefault"
        render={({ input, meta }) => (
          <FieldContainer>
            <InputWrap label={t('plans.form.defaultPlan')}>
              <Radio.Group
                value={!!input.value}
                onChange={({ target: { value } }) => {
                  input.onChange({
                    target: {
                      value,
                    },
                  } as unknown as ChangeEvent<boolean>);
                }}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </InputWrap>
          </FieldContainer>
        )}
      />

      <Field
        name="icon"
        validate={validateByRegExPattern(URL_REGEX, t('plans.form.invalidURL'))}
        render={({ input, meta }) => (
          <FieldContainer>
            <FormTextInput
              input={input}
              meta={meta}
              label={t('plans.form.icon')}
            />
          </FieldContainer>
        )}
      />
    </>
  );
};

export default PlanFormMainSection;

const FieldContainer = styled.div`
  margin-bottom: 20px;
`;
