import { useCallback } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { ApiError } from '../../../services/api/api-error';
import { ProductType } from '@ombori/grid-products';
import { useGridProductsService } from '../grid-products-service-provider';
import { DataResidencyEnum } from '../../../store/types/organisation';

export interface ProductTypeData {
  data: ProductType[];
}

export interface UseProductTypesProps {
  tenantId: string;
  dataResidency: DataResidencyEnum;
  environment: string;
}

type UseProductTypesResponse = UseQueryResult<ProductTypeData, ApiError>;

const useProductTypes = (props: UseProductTypesProps): UseProductTypesResponse => {
  const { tenantId, dataResidency, environment } = props;

  const gridProductsService = useGridProductsService();

  const fetch = useCallback(() => {
    const url = `/${tenantId}/${environment}/product-types`;

    return gridProductsService.get<ProductTypeData>(
      url,
      dataResidency,
    );
  }, [dataResidency, environment, gridProductsService, tenantId]);

  const result = useQuery(
    getProductTypesKeys({ tenantId, dataResidency, environment }),
    () => fetch(),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      staleTime: Infinity,
      keepPreviousData: true,
      onError: (_error: ApiError) => {},
    },
  );

  return result;
};

export const getProductTypesKeys = ({
  tenantId,
  dataResidency,
  environment,
}: {
  tenantId: string;
  dataResidency: DataResidencyEnum;
  environment: string;
}): string[] => {
  return [
    'get-product-types',
    'tenantId',
    tenantId,
    'dataResidency',
    dataResidency,
    'environment',
    environment,
  ];
};

export default useProductTypes;
