import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Typography, Progress, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useContentLibraryUploadMedia, {
  ContentUploadMediaStatusEnum,
} from '../../../../../store/hooks/content-library/use-content-library-upload-media';
import { formatPercentage } from '../../../../common/overview-report/cards/data-matrix/get-percentage';
import Message from '../../../../common/message';

interface ContentLibraryUploadFileProps {
  tenantId: string;
  parentFolderId: string | undefined;
  file: File;
  onFileStatusChange: (file: File, status: ContentUploadMediaStatusEnum) => void;
}

const ContentLibraryUploadFile = (props: ContentLibraryUploadFileProps) => {
  const { tenantId, parentFolderId, file, onFileStatusChange } = props;

  const { t } = useTranslation();

  const [uploadStatus, setUploadStatus] = useState<ContentUploadMediaStatusEnum>();
  const [uploadPercent, setUploadPercent] = useState(0);

  const { mutateAsync: uploadMedia } = useContentLibraryUploadMedia(tenantId);

  // used to determine whether a file is already uploading during re-rendering and prevent it from reuploading
  const isAlreadyUploading = useRef<ContentUploadMediaStatusEnum | null>(null);

  useEffect(() => {
    async function upload() {
      try {
        if (isAlreadyUploading.current) {
          return;
        }

        await uploadMedia({
          file,
          organization: tenantId,
          folderId: parentFolderId,
          onStatusChange: (status) => {
            isAlreadyUploading.current = status;
            setUploadStatus(status);
          },
          onProgress: (percent) => {
            setUploadPercent(percent);
          },
        });

        message.success(<Message content={t('contents.uploadFileSuccess')} />);

        onFileStatusChange(file, ContentUploadMediaStatusEnum.DONE);
      } catch (err) {
        message.error(<Message content={t('contents.uploadFileFailed')} />);

        isAlreadyUploading.current = ContentUploadMediaStatusEnum.ERROR;
        setUploadPercent(0);
        setUploadStatus(ContentUploadMediaStatusEnum.ERROR);
      }
    }

    upload();
  }, [file, onFileStatusChange, parentFolderId, t, tenantId, uploadMedia]);

  return (
    <FileContainer>
      <Typography.Paragraph>
        <Typography.Text>{file.name}</Typography.Text>
        <Typography.Text> ({uploadStatus})</Typography.Text>
      </Typography.Paragraph>
      <Progress percent={uploadPercent} format={(percent) => formatPercentage(percent!)} />
    </FileContainer>
  );
};

export default ContentLibraryUploadFile;

const FileContainer = styled.div`
  width: 100%;
  padding: 35px;
  background: white;

  display: flex;
  flex-direction: column;
`;
