import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { Icon } from 'antd';
import { SortOrder } from '../../../store/types/sort';
import transientOptions from '../../../utils/transient-emotion-styled-options';

interface SortOrderItemProps {
  item: SortOrder;
  onClick: (value: SortOrder) => void;
  isActive?: boolean;
}

const SortOrderItem = (props: SortOrderItemProps) => {
  const { item, onClick, isActive = false } = props;

  const handleClick = useCallback(() => onClick(item), [onClick, item]);

  return (
    <Container onClick={handleClick}>
      <IconArrowStyle
        type={item.id === 'asc' ? 'arrow-up' : 'arrow-down'}
        $isActive={isActive}
      />
      <Label active={isActive}>{item.label}</Label>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 4px;
  cursor: pointer;
`;

const Label = styled.span<{ active?: boolean | undefined }>`
  text-transform: capitalize;
  display: inline;
  font-size: ${({ active }) => (active ? 13 : 14)}px;
  margin: auto 10px;
  color: ${({ active }) => (active ? '#0064b6' : 'unset')};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
`;

const IconArrowStyle = styled(Icon, transientOptions)<{ $isActive: boolean }>`
  color: ${({ $isActive }) => ($isActive ? '#0064b6' : 'unset')};
`;

export default SortOrderItem;
