import * as React from 'react';
import { useActions, useStore } from 'easy-peasy';
import { RootModel, RootState } from '../../../../../store/models/root.model';
import DevicesUniversal from './iot-installation-universal.component';
import { getPermissions } from '../../../../../utils/auth/permissions';
import { UniversalDeviceConnectParams } from '../../../../../store/models/app-devices-universal/app-devices-universal.model';

type DevicesContainerProps = Omit<
  React.ComponentProps<typeof DevicesUniversal>,
  'devices' | 'loaded' | 'lastUpdated' | 'fetchDevices' | 'app' | 'canCreate'
>;

const DevicesUniversalContainer = (props: DevicesContainerProps) => {
  const {
    match: {
      params: { appId, organisationId },
    },
  } = props;

  const { devices, loaded, lastUpdated, app, permissions, environments } = useStore<
    RootState
  >(
    (state) => {
      const organisationApp =
        (state.organisationApps.data &&
          state.organisationApps.data[organisationId] &&
          state.organisationApps.data[organisationId][appId]) ||
        null;

      return {
        permissions: getPermissions(state.auth.user, props.match.params.organisationId),
        environments: state.environments.values(organisationId),
        app: organisationApp,
        devices: organisationApp
          ? state.appV3DevicesUniversal.values(organisationApp.appName)
          : [],
        loaded: organisationApp
          ? state.appV3DevicesUniversal.loaded(organisationApp.appName)
          : false,
        lastUpdated: organisationApp
          ? state.appV3DevicesUniversal.lastUpdated[organisationApp.appName]
          : undefined,
      };
    },
    [appId, organisationId],
  );

  const { fetchDevices, createDevice, fetchEnvironments, disconnectDevice } = useActions<
    RootModel
  >((actions) => ({
    fetchDevices: actions.appV3DevicesUniversal.fetch,
    createDevice: actions.appV3DevicesUniversal.create,
    fetchEnvironments: actions.environments.fetch,
    disconnectDevice: async (params: UniversalDeviceConnectParams) => {
      await actions.appV3DevicesUniversal.disconnect(params);
      if (app) {
        await actions.appV3DevicesUniversal.fetch({
          appName: app.appName,
        });
      }
    },
  }));

  return (
    <DevicesUniversal
      {...props}
      app={app}
      environments={environments}
      devices={devices}
      lastUpdated={lastUpdated}
      loaded={loaded}
      fetchDevices={fetchDevices}
      createDevice={createDevice}
      fetchEnvironments={fetchEnvironments}
      canCreate={permissions.devices.create}
      disconnectDevice={disconnectDevice}
    />
  );
};

export default DevicesUniversalContainer;
