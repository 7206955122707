import getValueFromObject from 'lodash/get';
import OrganisationApp from '../../store/types/organisation-app';

const fallbackIcon =
  'https://media.omborigrid.com/media/5cbac8a388e174147b878cdd/d3242360-dd82-11eb-a238-ef4fb149aea1';

export const fallbackIconAlt =
  'https://gridappsdev.blob.core.windows.net/apps/5f61cfe4e6ef8b37cff065b3%2Fc42a94f0-f9a0-11ea-a08e-47bd1ff718eb.png';

// TODO: Remove static queue icons when cloud apps are properly supported
export enum OmboriAppsProviderEnums {
  QUEUE_MANAGEMENT = 'queue-management',
  QUEUE_MANAGEMENT_BAMBUSER = 'queue-management-bambuser',
  OCCUPANCY_CONTROL = 'occupancy-control',
  PEOPLE_COUNTER = 'people-counter',
  OMNI_VISIT = 'omni-visit',
  APPOINTMENT_BOOKING = 'appointment-booking',
  ORDER_PICKUP = 'order-pickup',
  QUEUE_TICKET_SIGNAGE = 'stations-signage',
  QUEUE_KIOSK = 'queue-kiosk-categories',
  OCCUPANCY_SIGNAGE = 'queue-signage',
  DIGITAL_SIGNAGE = 'ombori-digital-signage',
  WEB_KIOSK = 'web-kiosk',
  SHOP_THE_LOOK_SCREEN = 'shop-the-look-screen',
  SHOP_THE_LOOK_REMOTE = 'shop-the-look-remote',
  ENDLESS_AISLE_KIOSK = 'kiosk-endless-aisle',
  ENDLESS_AISLE_MOBILE = 'mobile-endless-aisle',
  SMALL_QUEUE_TICKET_SIGNAGE = 'small-queue-ticket-signage',
  SELFIE_MIRROR_SCREEN = 'selfie-mirror-screen',
  SELFIE_MIRROR_MOBILE = 'selfie-mirror-mobile',
  TASKS = 'tasks',
  LIFT_AND_LEARN = 'lift-and-learn',
}

// TODO: Remove static queue icons when cloud apps are properly supported
const staticAppIcons: Record<string, string> = {
  [OmboriAppsProviderEnums.QUEUE_MANAGEMENT]:
    'https://media.omborigrid.com/media/5cbac8a388e174147b878cdd/d35174f0-dd82-11eb-a238-ef4fb149aea1',
  [OmboriAppsProviderEnums.QUEUE_MANAGEMENT_BAMBUSER]:
    'https://media.omborigrid.com/media/5cbac8a388e174147b878cdd/d35174f0-dd82-11eb-a238-ef4fb149aea1',
  [OmboriAppsProviderEnums.OCCUPANCY_CONTROL]:
    'https://media.omborigrid.com/media/5cbac8a388e174147b878cdd/d33a1c60-dd82-11eb-a238-ef4fb149aea1',
  [OmboriAppsProviderEnums.PEOPLE_COUNTER]:
    'https://media.omborigrid.com/media/5cbac8a388e174147b878cdd/d34c44d0-dd82-11eb-a238-ef4fb149aea1',
  [OmboriAppsProviderEnums.OMNI_VISIT]:
    'https://media.omborigrid.com/media/5cbac8a388e174147b878cdd/d3416f60-dd82-11eb-a238-ef4fb149aea1',
  [OmboriAppsProviderEnums.APPOINTMENT_BOOKING]:
    'https://media.omborigrid.com/media/5cbac8a388e174147b878cdd/d329a1a0-dd82-11eb-a238-ef4fb149aea1',
  [OmboriAppsProviderEnums.ORDER_PICKUP]:
    'https://media.omborigrid.com/media/5cbac8a388e174147b878cdd/d346eda0-dd82-11eb-a238-ef4fb149aea1',
  [OmboriAppsProviderEnums.QUEUE_TICKET_SIGNAGE]:
    'https://gridapps.blob.core.windows.net/apps/601138e0eb47806ca50914ab%2Fb0f2e970-fb5a-11eb-8764-e7a9bebdce0c.png',
  [OmboriAppsProviderEnums.QUEUE_KIOSK]:
    'https://gridapps.blob.core.windows.net/apps/6090dc2d17f1724744b6fba6%2Fafd11ed0-0718-11ec-bf2a-a7a06fd0c409.png',
  [OmboriAppsProviderEnums.OCCUPANCY_SIGNAGE]:
    'https://gridapps.blob.core.windows.net/apps/5fd8dfe9a1d0eb0a63536096%2F98d7b470-fb54-11eb-8764-e7a9bebdce0c.png',
  [OmboriAppsProviderEnums.DIGITAL_SIGNAGE]:
    'https://gridapps.blob.core.windows.net/apps/6013e3c0f78cb316ad7ed2bf%2F46621e40-fe7b-11eb-9703-5750dbe13c24.png',
  [OmboriAppsProviderEnums.WEB_KIOSK]:
    'https://media.omborigrid.com/media/5cbac8a388e174147b878cdd/c550be60-073f-11ec-bf2a-a7a06fd0c409',
  [OmboriAppsProviderEnums.SHOP_THE_LOOK_SCREEN]:
    'https://media.omborigrid.com/media/5cbac8a388e174147b878cdd/1f6411b0-3196-11ec-b7be-4fe4c59cff82',
  [OmboriAppsProviderEnums.SHOP_THE_LOOK_REMOTE]:
    'https://media.omborigrid.com/media/5cbac8a388e174147b878cdd/1f877830-3196-11ec-b7be-4fe4c59cff82',
  [OmboriAppsProviderEnums.ENDLESS_AISLE_KIOSK]:
    'https://media.omborigrid.com/media/5cbac8a388e174147b878cdd/d3349e20-dd82-11eb-a238-ef4fb149aea1',
  [OmboriAppsProviderEnums.ENDLESS_AISLE_MOBILE]:
    'https://api.omborigrid.com/regions/eu/media/v1/assets/media/5cbac8a388e174147b878cdd/41e3c028-0cc0-4d69-a491-86be7646671f',
  [OmboriAppsProviderEnums.SMALL_QUEUE_TICKET_SIGNAGE]:
    'https://media.omborigrid.com/media/5cbac8a388e174147b878cdd/69f3c7a0-cd29-11ec-947f-d90186f9033c',
  [OmboriAppsProviderEnums.SELFIE_MIRROR_SCREEN]:
    'https://media.omborigrid.com/media/5cbac8a388e174147b878cdd/d04c73f0-b66b-11ec-99b6-3393f282836f',
  [OmboriAppsProviderEnums.SELFIE_MIRROR_MOBILE]:
    'https://media.omborigrid.com/media/5cbac8a388e174147b878cdd/00ac7c70-b66c-11ec-99b6-3393f282836f',
  [OmboriAppsProviderEnums.TASKS]:
    'https://media.omborigrid.com/media/5cbac8a388e174147b878cdd/be9de700-1e36-11ed-9856-095406070f5e',
  [OmboriAppsProviderEnums.LIFT_AND_LEARN]:
    'https://api.omborigrid.com/regions/eu/media/v1/assets/media/5cbac8a388e174147b878cdd/66376438-0fe3-463e-8e6b-2ae16116f7e9',
};

// TODO: Remove static queue icons when cloud apps are properly supported
export const getTemporaryStaticAppIconByProvider = (id: string) =>
  staticAppIcons[id] || fallbackIcon;

export default function getInstallationAppIcon(app?: OrganisationApp) {
  // TODO: Remove static queue icons when cloud apps are properly supported
  const provider = getValueFromObject(app, 'provider', '');

  return app
    ? getValueFromObject(app, 'settings.provider.app.gridApp.iconUrl', '') ||
        getTemporaryStaticAppIconByProvider(provider)
    : fallbackIcon;
}
