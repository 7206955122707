import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';
import keys from 'lodash/keys';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';
import {
  useInstallationEventsByDay,
  useInstallationEventsBySpaceAndDay,
  DayDataType,
  InstallationAndSpaceEventsByDay,
  InstallationEventsByDay,
  ReportContext,
  SpaceEventsByDay,
  useSpaceEventsByDay,
  useDeviceEventsByDay,
  DeviceEventsByDay,
  useDeviceEventsBySpaceAndDay,
  DeviceAndSpaceEventsByDay,
  useTenantEventsByDay,
  TenantEventsByDay,
  useTenantEventsBySpaceAndDay,
  TenantAndSpaceEventsByDay,
} from '../../use-analytics-report';
import { DataResidencyEnum } from '../../../../store/types/organisation';
import CardContainer from './card-container';
import PieChart from '../charts/succes-rate-card';
import {
  GridStyles,
  InteractionType,
  DataSourceBaseEntity,
  DataSourceTypeEntityEnum,
  EventsSuccessFailureRateCard,
  SuccessFailureInputType,
  SuccessFailureValueInput,
  AbsentSuccessValueInput,
  AbsentFailureValueInput,
} from '@ombori/grid-reports';
import { useTranslation } from 'react-i18next';
import OrganisationSpace from '../../../../store/types/organisation-space';
import OrganisationSpaceTypeEnum from '../../../../store/types/organisation-space-type.enum';
import { Link } from 'react-router-dom';
import { useSpaces, UseSpacesResponse } from '../../use-spaces';

const noneKey = 'none';

const HintContainer = styled.div`
  font-size: 12px;
  line-height: 1.4;

  p {
    margin: 0 0 1em 0;
  }
`;

const HintSection = styled.div`
  margin-bottom: 1.3em;
`;

const HintSectionTitle = styled.div`
  margin-bottom: 0.3em;
  font-weight: 600;
  text-transform: uppercase;
`;

const HintSectionContent = styled.div``;

interface TitleData {
  id: string;
  title: string;
  parentId?: string;
}

enum DataType {
  SUCCESS = 'success',
  FAIL = 'fail',
}

interface DataItemData {
  type: DataType;
  label: string;
  value: number;
  color: string;
  percentage: string;
}

type DataItem = [string, DataItemData[]][];

type GroupedDataItem = [string, DataItem][];

type DynamicCardsProps = {
  data: {
    eventsResult: GroupedDataItem;
    additionalDataResult: GroupedDataItem;
  };
  state: {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
  };
  gridStyles?: GridStyles;
  isVisibleWithoutData?: boolean;
  title: string;
  titleData?: TitleData[];
  reportContext?: ReportContext;
  tenantId: string;
  dateFrom: string;
  dateTo: string;
  inputType: EventsSuccessFailureRateCard['inputType'];
};

interface EventsSuccessFailureRatetProps {
  tenantId: string;
  dateFrom: string;
  dateTo: string;
  dataResidency: DataResidencyEnum;
  interactionType?: InteractionType;
  gridStyles?: GridStyles;
  isVisibleWithoutData?: boolean;
  inputType: EventsSuccessFailureRateCard['inputType'];
  dataSource?: DataSourceBaseEntity;
  dataSourceType?: DataSourceTypeEntityEnum;
  reportContext?: ReportContext;
}

interface InstallationEventsSuccessFailureRatetProps
  extends EventsSuccessFailureRatetProps {
  installationId: string;
  additionalData?: EventsSuccessFailureRateCard['additionalData'];
  title?: string;
}

interface InstallationEventsSuccessAndFailurerops
  extends InstallationEventsSuccessFailureRatetProps {
  eventType: string;
}

interface InstallationAndSpaceEventsSuccessAndFailureRateProps
  extends InstallationEventsSuccessFailureRatetProps {
  eventType: string;
}

interface SpaceEventsSuccessAndFailureRateProps extends EventsSuccessFailureRatetProps {
  spaceId: string;
  additionalData?: EventsSuccessFailureRateCard['additionalData'];
  title?: string;
}

interface DeviceEventsSuccessAndFailureRateProps extends EventsSuccessFailureRatetProps {
  deviceId: string;
  additionalData?: EventsSuccessFailureRateCard['additionalData'];
  title?: string;
}

interface DeviceEventsSuccessAndFailurerops
  extends DeviceEventsSuccessAndFailureRateProps {
  eventType: string;
}

interface DeviceAndSpaceEventsSuccessAndFailureRateProps
  extends DeviceEventsSuccessAndFailureRateProps {
  eventType: string;
}

interface TenantEventsSuccessAndFailureRateProps extends EventsSuccessFailureRatetProps {
  additionalData?: EventsSuccessFailureRateCard['additionalData'];
  title?: string;
}

interface TenantEventsSuccessAndFailurerops
  extends TenantEventsSuccessAndFailureRateProps {
  eventType: string;
}

interface TenantAndSpaceEventsSuccessAndFailureRateProps
  extends TenantEventsSuccessAndFailureRateProps {
  eventType: string;
}

interface BuildDataProps {
  rawData: (
    | InstallationEventsByDay
    | SpaceEventsByDay
    | InstallationAndSpaceEventsByDay
    | DeviceEventsByDay
    | DeviceAndSpaceEventsByDay
    | TenantEventsByDay
    | TenantAndSpaceEventsByDay)[];
  events: EventsSuccessFailureRateCard['inputType'];
  additionalData?: EventsSuccessFailureRateCard['additionalData'];
  dataSource?: DataSourceBaseEntity;
  reportContext?: ReportContext;
  spaces?: OrganisationSpace[];
  dataSourceType?: DataSourceTypeEntityEnum;
}

interface BuildDataResult {
  additionalDataResult: ReturnType<typeof buildAdditionalData>;
  eventsResult: [string, [string, DataItemData[]][]][];
}

const categorizeSpaces = ({ spaces, total }: UseSpacesResponse): UseSpacesResponse => {
  const parentSpaces = spaces.filter((space) => !space.parentSpaceId);

  const childSpaces = spaces.filter((space) => space.parentSpaceId);

  const result = [...parentSpaces, ...childSpaces];

  return { spaces: result, total };
};

const formatPercentage = (value: number): string => {
  return value % 1 === 0 ? `${value.toFixed(0)  }%` : `${value.toFixed(2)  }%`;
};

const extractEventsEventTypes = (events: EventsSuccessFailureRateCard['inputType']) => {
  switch (events.type) {
    case SuccessFailureInputType.AbsentFailureValue:
      return [...(events.successData.eventTypes || []), ...events.totalData.eventTypes];
    case SuccessFailureInputType.AbsentSuccessValue:
      return [...(events.failureData.eventTypes || []), ...events.totalData.eventTypes];
    default:
      return [
        ...(events.successData.eventTypes || []),
        ...(events.failureData.eventTypes || []),
      ];
  }
};

const buildAdditionalData = ({
  eventTypeCounts,
  data = [],
}: {
  eventTypeCounts: Record<string, number>;
  data: EventsSuccessFailureRateCard['additionalData'];
}): [string, { label: string; value: number; order: number }[]][] | any => {
  return data.map(({ title, eventTypes = [], icon, order }) => {
    const count = eventTypes.reduce(
      (acc, eventType) => acc + (eventTypeCounts[eventType] || 0),
      0,
    );

    const list = [
      {
        label: icon ? icon : title,
        value: count,
        order,
      },
    ];

    return [title, list];
  });
};

interface AbsentFailureValueStrategy {
  description: (input: AbsentFailureValueInput) => string | React.ReactNode;
  process: (
    input: AbsentFailureValueInput,
    eventTypeCounts: Record<string, number>,
  ) => DataItem;
}

interface AbsentSuccessValueStrategy {
  description: (input: AbsentSuccessValueInput) => string | React.ReactNode;
  process: (
    input: AbsentSuccessValueInput,
    eventTypeCounts: Record<string, number>,
  ) => DataItem;
}

interface SuccessFailureValueStrategy {
  description: (input: SuccessFailureValueInput) => string | React.ReactNode;
  process: (
    input: SuccessFailureValueInput,
    eventTypeCounts: Record<string, number>,
  ) => DataItem;
}

const absentFailureValueStrategy: AbsentFailureValueStrategy = {
  description: (input) => (
    <HintContainer>
      <HintSection>
        <HintSectionTitle>Total count</HintSectionTitle>

        <HintSectionContent>
          Sum of {input.totalData.eventTypes.join(', ')} events
        </HintSectionContent>
      </HintSection>

      <HintSection>
        <HintSectionTitle>Success count</HintSectionTitle>

        <HintSectionContent>
          Sum of {input.successData.eventTypes.join(', ')} events
        </HintSectionContent>
      </HintSection>

      <HintSection>
        <HintSectionTitle>Failure count</HintSectionTitle>

        <HintSectionContent>Total count - Success count</HintSectionContent>
      </HintSection>

      <HintSection>
        <HintSectionTitle>Success rate</HintSectionTitle>

        <HintSectionContent>Success count / Total count</HintSectionContent>
      </HintSection>

      <HintSection>
        <HintSectionTitle>Failure rate</HintSectionTitle>

        <HintSectionContent>1 - Success rate</HintSectionContent>
      </HintSection>
    </HintContainer>
  ),
  process: (input, eventTypeCounts) => {
    const successCount = sumBy(
      input.successData.eventTypes,
      (eventType) => eventTypeCounts[eventType] || 0,
    );

    const totalCount = sumBy(
      input.totalData.eventTypes,
      (eventType) => eventTypeCounts[eventType] || 0,
    );

    const successRate = totalCount === 0 ? 0 : successCount / totalCount;

    const failureRate = 1 - successRate;

    const failureCount = totalCount - successCount;

    const successList = [
      {
        label: input.successData.title,
        value: successCount,
        color: input.successData.color,
        percentage: formatPercentage(successRate * 100),
        type: DataType.SUCCESS,
      },
    ];

    const failureList = [
      {
        label: input.failureData.title,
        value: failureCount,
        color: input.failureData.color,
        percentage: formatPercentage(failureRate * 100),
        type: DataType.FAIL,
      },
    ];

    const successResult: [string, DataItemData[]] = [
      input.successData.title,
      successList,
    ];

    const failureResult: [string, DataItemData[]] = [
      input.failureData.title,
      failureList,
    ];

    return [successResult, failureResult];
  },
};

const absentSuccessValueStrategy: AbsentSuccessValueStrategy = {
  description: (input) => (
    <HintContainer>
      <HintSection>
        <HintSectionTitle>Total count</HintSectionTitle>

        <HintSectionContent>
          Sum of {input.totalData.eventTypes.join(', ')} events
        </HintSectionContent>
      </HintSection>

      <HintSection>
        <HintSectionTitle>Failure count</HintSectionTitle>

        <HintSectionContent>
          Sum of {input.failureData.eventTypes.join(', ')} events
        </HintSectionContent>
      </HintSection>

      <HintSection>
        <HintSectionTitle>Success count</HintSectionTitle>

        <HintSectionContent>Total count - Success count</HintSectionContent>
      </HintSection>

      <HintSection>
        <HintSectionTitle>Failure rate</HintSectionTitle>

        <HintSectionContent>Failure count / Total count</HintSectionContent>
      </HintSection>

      <HintSection>
        <HintSectionTitle>Success rate</HintSectionTitle>

        <HintSectionContent>1 - Failure rate</HintSectionContent>
      </HintSection>
    </HintContainer>
  ),
  process: (input, eventTypeCounts) => {
    const totalCount = sumBy(
      input.totalData.eventTypes,
      (eventType) => eventTypeCounts[eventType] || 0,
    );

    const failureCount = sumBy(
      input.failureData.eventTypes,
      (eventType) => eventTypeCounts[eventType] || 0,
    );

    const successCount = totalCount - failureCount;

    const failureRate = totalCount === 0 ? 0 : failureCount / totalCount;

    const successRate = 1 - failureRate;

    const successList = [
      {
        label: input.successData.title,
        value: successCount,
        color: input.successData.color,
        percentage: formatPercentage(successRate * 100),
        type: DataType.SUCCESS,
      },
    ];

    const failureList = [
      {
        label: input.failureData.title,
        value: failureCount,
        color: input.failureData.color,
        percentage: formatPercentage(failureRate * 100),
        type: DataType.FAIL,
      },
    ];

    const successResult: [string, DataItemData[]] = [
      input.successData.title,
      successList,
    ];

    const failureResult: [string, DataItemData[]] = [
      input.failureData.title,
      failureList,
    ];

    return [successResult, failureResult];
  },
};

const successFailureValueStrategy: SuccessFailureValueStrategy = {
  description: () => '',
  process: () => {
    throw new Error(
      'SuccessFailureValue input type is not implemented for EventsSuccessFailureRateCard',
    );
  },
};

const getStrategyDescription = (input: EventsSuccessFailureRateCard['inputType']) => {
  switch (input.type) {
    case SuccessFailureInputType.AbsentFailureValue:
      return absentFailureValueStrategy.description(input);

    case SuccessFailureInputType.AbsentSuccessValue:
      return absentSuccessValueStrategy.description(input);

    case SuccessFailureInputType.SuccessFailureValue:
      return successFailureValueStrategy.description(input);

    default:
      throw new Error(
        `Failed to get EventsSuccessFailureRateCard strategy description for input ${input}.`,
      );
  }
};

const buildSuccessFailureRateData = ({
  eventTypeCounts,
  data: input,
}: {
  eventTypeCounts: Record<string, number>;
  data: EventsSuccessFailureRateCard['inputType'];
}): DataItem => {
  switch (input.type) {
    case SuccessFailureInputType.AbsentFailureValue:
      return absentFailureValueStrategy.process(input, eventTypeCounts);

    case SuccessFailureInputType.AbsentSuccessValue:
      return absentSuccessValueStrategy.process(input, eventTypeCounts);

    case SuccessFailureInputType.SuccessFailureValue:
      return successFailureValueStrategy.process(input, eventTypeCounts);

    default:
      throw new Error(
        `Failed to get EventsSuccessFailureRateCard strategy process function for input ${input}.`,
      );
  }
};

const getDataByType = (data: DataItem, type: string) => {
  const flattenedData = data.flatMap((item) => item[1]);
  return flattenedData.find((obj) => obj.type === type);
};

export const buildData = ({
  rawData = [],
  events,
  additionalData = [],
  dataSource,
  reportContext,
  spaces = [],
  dataSourceType,
}: BuildDataProps): BuildDataResult => {
  if (!rawData.length) {
    return { additionalDataResult: [], eventsResult: [] };
  }

  const transformedRawData = rawData
    .filter((data) => {
      /**
       * Filters the rawData array. If the dataSourceType is LOCATION or SECTION, it checks if there's any space in the
       * filteredSpaces array that matches the spaceId of the current data item. If it finds a match, it includes
       * the current data item in the filtered result. If the dataSourceType is not LOCATION/SECTION, it includes all
       * data items in the filtered result.
       */
      if (dataSourceType === DataSourceTypeEntityEnum.LOCATION) {
        return spaces.some((space) =>
          'spaceId' in data ? space.id === data.spaceId : true,
        );
      } else if (dataSourceType === DataSourceTypeEntityEnum.SECTION) {
        return spaces
          .filter((space) => space.type === OrganisationSpaceTypeEnum.SECTION)
          .some((space) => ('spaceId' in data ? space.id === data.spaceId : true));
      }
      return true;
    })
    .map((dataItem) => {
      if (
        ((reportContext === ReportContext.Installation &&
          dataItem.type === DayDataType.InstallationAndSpaceEventsByDay) ||
          (reportContext === ReportContext.Tenant &&
            dataItem.type === DayDataType.TenantAndSpaceEventsByDay)) &&
        dataSource === DataSourceBaseEntity.Space &&
        dataSourceType === DataSourceTypeEntityEnum.LOCATION
      ) {
        /**
         * Group nested spaces under parent space.
         * Check, space is available or not.
         * If yes, then check if the space is nested one or not.
         * If nested, then map with parentSpaceId esle map with spaceId
         */
        const foundSpace = spaces.find((space) => dataItem.spaceId === space.id);
        return {
          key:
            foundSpace && foundSpace.parentSpaceId
              ? foundSpace.parentSpaceId
              : dataItem.spaceId,
          ...dataItem,
        };
      } else if (
        ((reportContext === ReportContext.Space &&
          dataItem.type === DayDataType.SpaceEventsByDay) ||
          (reportContext === ReportContext.Device &&
            dataItem.type === DayDataType.DeviceAndSpaceEventsByDay)) &&
        dataSource === DataSourceBaseEntity.Space &&
        dataSourceType === DataSourceTypeEntityEnum.SECTION
      ) {
        // SCO Breakdown
        return {
          key: dataItem.spaceId,
          ...dataItem,
        };
      }
      return {
        ...dataItem,
        key: noneKey,
      };
    });

  const groupedEventsByKey = groupBy(transformedRawData, (dataItem) => dataItem.key);

  const additionalDataResult: Array<
    [string, { label: string; value: number; order: number }[]]
  > = [];

  const eventsResult: [string, [string, DataItemData[]][]][] = [];

  keys(groupedEventsByKey).forEach((key) => {
    const eventTypeCounts: { [key: string]: number } = {};

    groupedEventsByKey[key].forEach((event) => {
      eventTypeCounts[event.eventType] =
        (eventTypeCounts[event.eventType] || 0) + event.count;
    });

    additionalDataResult.push([
      key,
      buildAdditionalData({ eventTypeCounts, data: additionalData }),
    ]);

    const value = buildSuccessFailureRateData({
      eventTypeCounts,
      data: events,
    });

    eventsResult.push([key, value]);
  });

  return { additionalDataResult, eventsResult };
};

const getTitle = ({
  key = noneKey,
  title,
  data = [],
  reportContext,
  tenantId,
  dateFrom,
  dateTo,
}: {
  key?: string;
  title: string;
  data?: TitleData[];
  reportContext?: ReportContext;
  tenantId?: string;
  dateFrom?: string;
  dateTo?: string;
}): { title: string; titleComponent: React.ReactNode | null } => {
  if (key === noneKey || !data.length) return { title, titleComponent: null };

  const foundData = data.find((item) => item.id === key);
  if (!foundData) return { title, titleComponent: null };

  const generateTitleComponent = ({ id, title }: { id: string; title: string }) => (
    <Link
      to={`/organisations/${tenantId}/spaces/${id}/details-v2/home?fromDate=${dateFrom}&toDate=${dateTo}`}
    >
      {title}
    </Link>
  );

  if (reportContext === ReportContext.Device && foundData.parentId) {
    const foundParentItem = data.find((item) => item.id === foundData.parentId);
    if (foundParentItem) {
      const titleStr = `${foundParentItem.title} : ${foundData.title}`;
      return {
        title: titleStr,
        titleComponent: generateTitleComponent({ id: foundData.id, title: titleStr }),
      };
    }
  }

  return {
    title: foundData.title,
    titleComponent: generateTitleComponent({ id: foundData.id, title: foundData.title }),
  };
};

const CardWrapper: React.FC<{
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  hasData: boolean;
  title: string | React.ReactNode;
  hint: string | React.ReactNode;
  gridStyles?: GridStyles;
  isVisibleWithoutData?: boolean;
  children: React.ReactNode;
}> = ({
  isLoading,
  isSuccess,
  isError,
  hasData,
  title,
  hint,
  gridStyles,
  isVisibleWithoutData,
  children,
}) => (
  <CardContainer
    isLoading={isLoading}
    isSuccess={isSuccess}
    isError={isError}
    hasData={hasData}
    title={title}
    hint={hint}
    gridStyles={gridStyles}
    isVisibleWithoutData={isVisibleWithoutData}
  >
    {children}
  </CardContainer>
);

const DynamicCards: React.FC<DynamicCardsProps> = ({
  data: { eventsResult = [], additionalDataResult = [] },
  state,
  gridStyles,
  isVisibleWithoutData,
  title,
  titleData,
  reportContext,
  tenantId,
  dateFrom,
  dateTo,
  inputType,
}) => {
  if (eventsResult.length === 0) {
    const titleStr = getTitle({ title }).title;

    return (
      <CardWrapper
        {...state}
        hasData={false}
        isVisibleWithoutData={isVisibleWithoutData}
        title={titleStr}
        hint={getStrategyDescription(inputType)}
        gridStyles={gridStyles}
      >
        {state.isSuccess && <PieChart data={[]} />}
      </CardWrapper>
    );
  }

  return (
    <>
      {sortBy(eventsResult, [
        (dataItem) =>
          getTitle({
            key: dataItem[0],
            title,
            data: titleData,
            reportContext,
          }).title.toLowerCase(),
      ]).map((dataItem) => {
        const { title: titleStr, titleComponent } = getTitle({
          key: dataItem[0],
          title,
          data: titleData,
          reportContext,
          tenantId,
          dateFrom,
          dateTo,
        });

        const data = dataItem[1];
        const foundFooterData = additionalDataResult.find(
          (additionalData) => additionalData[0] === dataItem[0],
        );
        const footerData = foundFooterData ? foundFooterData[1] : [];

        const foundData = getDataByType(data, DataType.SUCCESS);
        const successRate =
          foundData && foundData.percentage ? foundData.percentage : '0%';

        return (
          <CardWrapper
            {...state}
            hasData={data.length > 0}
            title={titleComponent ? titleComponent : titleStr}
            hint={getStrategyDescription(inputType)}
            gridStyles={gridStyles}
            isVisibleWithoutData={isVisibleWithoutData}
            key={dataItem[0]}
          >
            {state.isSuccess && (
              <PieChart data={data} successRate={successRate} footerData={footerData} />
            )}
          </CardWrapper>
        );
      })}
    </>
  );
};

export const InstallationEventsSuccessFailureRate: React.FC<
  InstallationEventsSuccessFailureRatetProps
> = (props) => {
  const { dataSource, title, additionalData = [], inputType } = props;

  const { t } = useTranslation();
  const componentTitle: string = title || t('eventsSuccesAndFailureRateCardTitle');

  // Extract eventTypes from  additionalData, if they are provided.
  const eventTypesFromAdditionalData = (additionalData || []).flatMap(
    (event) => event.eventTypes,
  );

  // Extract eventTypes from  events, if they are provided.
  const eventTypesFromEventsData = extractEventsEventTypes(inputType);

  // Combine, deduplicate and join all extracted eventTypes into a single string, separated by commas.
  const eventType = uniq([
    ...eventTypesFromEventsData,
    ...eventTypesFromAdditionalData,
  ]).join(',');

  if (dataSource === DataSourceBaseEntity.Space) {
    return (
      <InstallationAndSpaceEventsSuccessFailureRate
        title={componentTitle}
        eventType={eventType}
        {...props}
      />
    );
  }
  return (
    <InstallationEventsSuccessFailure
      title={componentTitle}
      eventType={eventType}
      {...props}
    />
  );
};

export const InstallationEventsSuccessFailure: React.FC<
  InstallationEventsSuccessAndFailurerops
> = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  dataResidency,
  interactionType = InteractionType.All,
  gridStyles,
  isVisibleWithoutData,
  inputType,
  additionalData,
  title = '',
  eventType,
  dataSource,
  reportContext,
}) => {
  const installationEventsByDayState = useInstallationEventsByDay({
    tenantId,
    installationId,
    dateFrom,
    dateTo,
    dataResidency,
    interactionType,
    eventType,
  });

  const data = useMemo(() => {
    return buildData({
      rawData: installationEventsByDayState.data || [],
      events: inputType,
      additionalData,
      dataSource,
      reportContext,
    });
  }, [
    installationEventsByDayState,
    inputType,
    additionalData,
    dataSource,
    reportContext,
  ]);

  return (
    <DynamicCards
      data={data}
      state={installationEventsByDayState}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
      title={title}
      tenantId={tenantId}
      dateFrom={dateFrom}
      dateTo={dateTo}
      inputType={inputType}
    />
  );
};

export const InstallationAndSpaceEventsSuccessFailureRate: React.FC<
  InstallationAndSpaceEventsSuccessAndFailureRateProps
> = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
  inputType,
  additionalData,
  title = '',
  reportContext,
  eventType,
  dataSource,
  dataSourceType,
}) => {
  const installationAndSpaceEventsByDayState = useInstallationEventsBySpaceAndDay({
    tenantId,
    installationId,
    dateFrom,
    dateTo,
    dataResidency,
    dataSource,
    eventType,
  });

  const spacesFetchingState = useSpaces({
    organisationId: tenantId,
    select: categorizeSpaces,
    limit: 15000,
  });

  const spaces = useMemo(() => {
    const spacesList = spacesFetchingState.isSuccess
      ? spacesFetchingState.data.spaces
      : [];

    return spacesList;
  }, [spacesFetchingState]);

  const titleData = useMemo(() => {
    const result = spaces.map((spaceData: OrganisationSpace) => ({
      id: spaceData.id,
      title: spaceData.displayName,
    }));

    return result;
  }, [spaces]);

  const data = useMemo(
    () =>
      buildData({
        rawData: installationAndSpaceEventsByDayState.data || [],
        events: inputType,
        additionalData,
        dataSource,
        reportContext,
        spaces,
        dataSourceType,
      }),
    [
      installationAndSpaceEventsByDayState,
      inputType,
      additionalData,
      reportContext,
      dataSource,
      spaces,
      dataSourceType,
    ],
  );

  return (
    <DynamicCards
      data={data}
      state={installationAndSpaceEventsByDayState}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
      title={title}
      titleData={titleData}
      tenantId={tenantId}
      dateFrom={dateFrom}
      dateTo={dateTo}
      inputType={inputType}
    />
  );
};

export const SpaceEventsSuccessAndFailureRate: React.FC<
  SpaceEventsSuccessAndFailureRateProps
> = ({
  tenantId,
  spaceId,
  dateFrom,
  dateTo,
  dataResidency,
  interactionType = InteractionType.All,
  gridStyles,
  isVisibleWithoutData,
  inputType,
  additionalData,
  title = '',
  reportContext,
  dataSource,
  dataSourceType,
}) => {
  const { t } = useTranslation();
  const componentTitle: string = title || t('eventsSuccesAndFailureRateCardTitle');

  // Extract eventTypes from  additionalData, if they are provided.
  const eventTypesFromAdditionalData = (additionalData || []).flatMap(
    (event) => event.eventTypes,
  );

  // Extract eventTypes from  events, if they are provided.
  const eventTypesFromEventsData = extractEventsEventTypes(inputType);

  // Combine, deduplicate and join all extracted eventTypes into a single string, separated by commas.
  const eventType = uniq([
    ...eventTypesFromEventsData,
    ...eventTypesFromAdditionalData,
  ]).join(',');

  const spaceEventsByDayState = useSpaceEventsByDay({
    tenantId,
    spaceId,
    dateFrom,
    dateTo,
    dataResidency,
    interactionType,
    eventType,
  });

  const spacesFetchingState = useSpaces({
    organisationId: tenantId,
    limit: 15000,
  });

  const spaces = useMemo(() => {
    const spacesList = spacesFetchingState.isSuccess
      ? spacesFetchingState.data.spaces.filter(
          (space) => space.id === spaceId || space.parentSpaceId === spaceId,
        )
      : [];

    return spacesList;
  }, [spacesFetchingState, spaceId]);

  const titleData = useMemo(() => {
    const result = spaces.map((spaceData: OrganisationSpace) => ({
      id: spaceData.id,
      title: spaceData.displayName,
    }));

    return result;
  }, [spaces]);

  const data = useMemo(() => {
    return buildData({
      rawData: spaceEventsByDayState.data || [],
      events: inputType,
      additionalData,
      dataSource,
      reportContext,
      spaces,
      dataSourceType,
    });
  }, [
    spaceEventsByDayState,
    inputType,
    additionalData,
    dataSource,
    reportContext,
    spaces,
    dataSourceType,
  ]);

  return (
    <DynamicCards
      data={data}
      state={spaceEventsByDayState}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
      title={componentTitle}
      titleData={titleData}
      tenantId={tenantId}
      dateFrom={dateFrom}
      dateTo={dateTo}
      inputType={inputType}
    />
  );
};

export const DeviceEventsSuccessFailureRate: React.FC<
  DeviceEventsSuccessAndFailureRateProps
> = (props) => {
  const { dataSource, title, additionalData = [], inputType } = props;

  const { t } = useTranslation();
  const componentTitle: string = title || t('eventsSuccesAndFailureRateCardTitle');

  // Extract eventTypes from  additionalData, if they are provided.
  const eventTypesFromAdditionalData = (additionalData || []).flatMap(
    (event) => event.eventTypes,
  );

  // Extract eventTypes from  events, if they are provided.
  const eventTypesFromEventsData = extractEventsEventTypes(inputType);

  // Combine, deduplicate and join all extracted eventTypes into a single string, separated by commas.
  const eventType = uniq([
    ...eventTypesFromEventsData,
    ...eventTypesFromAdditionalData,
  ]).join(',');

  if (dataSource === DataSourceBaseEntity.Space) {
    return (
      <DeviceAndSpaceEventsSuccessFailureRate
        title={componentTitle}
        eventType={eventType}
        {...props}
      />
    );
  }
  return (
    <DeviceEventsSuccessFailure title={componentTitle} eventType={eventType} {...props} />
  );
};

export const DeviceEventsSuccessFailure: React.FC<DeviceEventsSuccessAndFailurerops> = ({
  tenantId,
  deviceId,
  dateFrom,
  dateTo,
  dataResidency,
  interactionType = InteractionType.All,
  gridStyles,
  isVisibleWithoutData,
  inputType,
  additionalData,
  title = '',
  eventType,
  dataSource,
  reportContext,
}) => {
  const deviceEventsByDayState = useDeviceEventsByDay({
    tenantId,
    deviceId,
    dateFrom,
    dateTo,
    dataResidency,
    interactionType,
    eventType,
  });

  const data = useMemo(() => {
    return buildData({
      rawData: deviceEventsByDayState.data || [],
      events: inputType,
      additionalData,
      dataSource,
      reportContext,
    });
  }, [deviceEventsByDayState, inputType, additionalData, dataSource, reportContext]);

  return (
    <DynamicCards
      data={data}
      state={deviceEventsByDayState}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
      title={title}
      tenantId={tenantId}
      dateFrom={dateFrom}
      dateTo={dateTo}
      inputType={inputType}
    />
  );
};

export const DeviceAndSpaceEventsSuccessFailureRate: React.FC<
  DeviceAndSpaceEventsSuccessAndFailureRateProps
> = ({
  tenantId,
  deviceId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
  inputType,
  additionalData,
  title = '',
  reportContext,
  eventType,
  dataSource,
  dataSourceType,
}) => {
  const deviceAndSpaceEventsByDayState = useDeviceEventsBySpaceAndDay({
    tenantId,
    deviceId,
    dateFrom,
    dateTo,
    dataResidency,
    dataSource,
    eventType,
  });

  const spacesFetchingState = useSpaces({
    organisationId: tenantId,
    select: categorizeSpaces,
    limit: 15000,
  });

  const spaces = useMemo(() => {
    const spacesList = spacesFetchingState.isSuccess
      ? spacesFetchingState.data.spaces
      : [];

    return spacesList;
  }, [spacesFetchingState]);

  const titleData = useMemo(() => {
    const result = spaces.map((spaceData: OrganisationSpace) => ({
      id: spaceData.id,
      title: spaceData.displayName,
    }));

    return result;
  }, [spaces]);

  const data = useMemo(
    () =>
      buildData({
        rawData: deviceAndSpaceEventsByDayState.data || [],
        events: inputType,
        additionalData,
        dataSource,
        reportContext,
        spaces,
        dataSourceType,
      }),
    [
      deviceAndSpaceEventsByDayState,
      inputType,
      additionalData,
      reportContext,
      dataSource,
      spaces,
      dataSourceType,
    ],
  );
  return (
    <DynamicCards
      data={data}
      state={deviceAndSpaceEventsByDayState}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
      title={title}
      titleData={titleData}
      reportContext={reportContext}
      tenantId={tenantId}
      dateFrom={dateFrom}
      dateTo={dateTo}
      inputType={inputType}
    />
  );
};

export const TenantEventsSuccessFailureRate: React.FC<
  TenantEventsSuccessAndFailureRateProps
> = (props) => {
  const { dataSource, title, additionalData = [], inputType } = props;

  const { t } = useTranslation();
  const componentTitle: string = title || t('eventsSuccesAndFailureRateCardTitle');

  // Extract eventTypes from  additionalData, if they are provided.
  const eventTypesFromAdditionalData = (additionalData || []).flatMap(
    (event) => event.eventTypes,
  );

  // Extract eventTypes from  events, if they are provided.
  const eventTypesFromEventsData = extractEventsEventTypes(inputType);

  // Combine, deduplicate and join all extracted eventTypes into a single string, separated by commas.
  const eventType = uniq([
    ...eventTypesFromEventsData,
    ...eventTypesFromAdditionalData,
  ]).join(',');

  if (dataSource === DataSourceBaseEntity.Space) {
    return (
      <TenantAndSpaceEventsSuccessFailureRate
        title={componentTitle}
        eventType={eventType}
        {...props}
      />
    );
  }
  return (
    <TenantEventsSuccessFailure title={componentTitle} eventType={eventType} {...props} />
  );
};

const TenantEventsSuccessFailure: React.FC<TenantEventsSuccessAndFailurerops> = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  interactionType = InteractionType.All,
  gridStyles,
  isVisibleWithoutData,
  inputType,
  additionalData,
  title = '',
  eventType,
  dataSource,
  reportContext,
}) => {
  const tenantEventsByDayState = useTenantEventsByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
    interactionType,
    eventType,
  });

  const data = useMemo(() => {
    return buildData({
      rawData: tenantEventsByDayState.data || [],
      events: inputType,
      additionalData,
      dataSource,
      reportContext,
    });
  }, [tenantEventsByDayState, inputType, additionalData, dataSource, reportContext]);

  return (
    <DynamicCards
      data={data}
      state={tenantEventsByDayState}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
      title={title}
      tenantId={tenantId}
      dateFrom={dateFrom}
      dateTo={dateTo}
      inputType={inputType}
    />
  );
};

const TenantAndSpaceEventsSuccessFailureRate: React.FC<
  TenantAndSpaceEventsSuccessAndFailureRateProps
> = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
  inputType,
  additionalData,
  title = '',
  reportContext,
  eventType,
  dataSource,
  dataSourceType,
}) => {
  const tenantAndSpaceEventsByDayState = useTenantEventsBySpaceAndDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
    dataSource,
    eventType,
  });

  const spacesFetchingState = useSpaces({
    organisationId: tenantId,
    select: categorizeSpaces,
    limit: 15000,
  });

  const spaces = useMemo(() => {
    const spacesList = spacesFetchingState.isSuccess
      ? spacesFetchingState.data.spaces
      : [];

    return spacesList;
  }, [spacesFetchingState]);

  const titleData = useMemo(() => {
    const result = spaces.map((spaceData: OrganisationSpace) => ({
      id: spaceData.id,
      title: spaceData.displayName,
    }));

    return result;
  }, [spaces]);

  const data = useMemo(
    () =>
      buildData({
        rawData: tenantAndSpaceEventsByDayState.data || [],
        events: inputType,
        additionalData,
        dataSource,
        reportContext,
        spaces,
        dataSourceType,
      }),
    [
      tenantAndSpaceEventsByDayState,
      inputType,
      additionalData,
      reportContext,
      dataSource,
      spaces,
      dataSourceType,
    ],
  );

  return (
    <DynamicCards
      data={data}
      state={tenantAndSpaceEventsByDayState}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
      title={title}
      titleData={titleData}
      tenantId={tenantId}
      dateFrom={dateFrom}
      dateTo={dateTo}
      inputType={inputType}
    />
  );
};
