import React from 'react';
import AppsLibraryInstallModal from './apps-library-install-modal.component';
import { useInstallationGroups } from '../../use-installation-groups';
import useCreateInstallationGroupCallback from '../../use-create-installation-group-callback';

type AppsLibraryInstallModalContainerProps = Omit<
  React.ComponentProps<typeof AppsLibraryInstallModal>,
  'createInstallationGroup' | 'installationGroups'
> & { organisationId: string };

const AppsLibraryInstallModalContainer = (
  props: AppsLibraryInstallModalContainerProps,
) => {
  const { organisationId } = props;
  const createInstallationGroup = useCreateInstallationGroupCallback(organisationId);

  const installationGroupsQuery = useInstallationGroups({ organisationId });
  const installationGroups = installationGroupsQuery.data || [];

  return (
    <AppsLibraryInstallModal
      {...props}
      installationGroups={installationGroups}
      createInstallationGroup={createInstallationGroup}
    />
  );
};

export default AppsLibraryInstallModalContainer;
