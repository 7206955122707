import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Typography, Divider, Row, Col, Button, Select as AntdSelect } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { ArrayFields, InputWrap, Option, Select } from '../../../../common/react-final-form';
import {
  SegmentConditionGroup as TSegmentConditionGroup,
  SegmentConditionOperator,
  SegmentCondition as TSegmentCondition,
  SegmentConditions,
} from '../../../../../store/hooks/segments/types';
import { useAllSpaces } from '../../../../common/use-spaces';
import OrganisationSpaceTypeEnum from '../../../../../store/types/organisation-space-type.enum';
import { FormApi, FormState } from 'final-form';
import { OnChange } from 'react-final-form-listeners';
import SegmentConditionDivider from './segment-condition-divider.component';
import {
  getSegmentConditionOperatorOptions,
  getSegmentConditionTagIdOptions,
} from './segment-condition-options';
import { Field } from 'react-final-form';
import { requiredAll } from '../../queue-details/queue-setup/validate-form';
import { ChangeEvent } from '../../../../../types';
import useTagsList from '../../../../../store/hooks/content-tags/use-tags-list';
import { ContentTagType } from '../../../../../store/common/content-tags/content-tag-type';
import { Channel } from '../../../../../store/types/channel';
import SegmentContentTargetingDevices from './segment-content-targeting-devices.component';

interface TagsMapping {
  [x: string]: {
    tagType: ContentTagType;
    options: Option[];
  }
}

interface SegmentConditionProps {
  parentPropertyName: string;
  tagIdOptions: Option[];
  tagOperators: Option[];
  tagsMapping: TagsMapping;
  updateForm: FormApi['change'];
  fieldState: TSegmentCondition | undefined,
  filteredSegmentConditionTagIdOptions: Option[],
}

const SegmentCondition = (props: SegmentConditionProps) => {
  const {
    parentPropertyName,
    tagIdOptions,
    tagOperators,
    tagsMapping,
    updateForm,
    fieldState,
    filteredSegmentConditionTagIdOptions,
  } = props;

  const { t } = useTranslation();

  const [valueOptions, setValueOptions] = useState<Option[]>([]);
  const [isValueDisabled, setValueDisabled] = useState(false);

  // update options list for Value dropdown
  const updateValueOptions = useCallback((value: string) => {
    const key = value;

    if (tagsMapping && tagsMapping[key]) {
      setValueOptions([...tagsMapping[key].options]);
    } else {
      setValueOptions([]);
    }

  }, [tagsMapping]);

  useEffect(() => {
    if (fieldState && fieldState.tagId) {
      updateValueOptions(fieldState.tagId);
    }
  }, [fieldState, updateValueOptions]);

  // update tagType based on selected value in the dropdown
  const updateContentTagType = useCallback((propertyName, value) => {
    const key = value;
    if (tagsMapping && tagsMapping[key]) {
      const { tagType } = tagsMapping[key];
      updateForm(propertyName, tagType);
    } else {
      updateForm(propertyName, '');
    }
  }, [updateForm, tagsMapping]);

  // update enable/disable state for Value dropdown
  const updateValueState = useCallback((value: string, parentPropertyName: string) => {
    if (value === SegmentConditionOperator.SET || value === SegmentConditionOperator.NOT_SET) {
      updateForm(`${parentPropertyName}.value`, undefined);
      setValueDisabled(true);
    } else {
      setValueDisabled(false);
    }
  }, [updateForm]);

  return (
    <SegmentConditionContainer gutter={[10, 10]}>
      <Col span={8}>
        <Field
          name={`${parentPropertyName}.tagType`}
          render={() => {
            return <input type="hidden" value="space" />;
          }}
        />

        <Field
          name={`${[parentPropertyName]}.tagId`}
          validate={requiredAll}
          render={({ input, meta }) => {
            return (
              <InputWrap
                label={t('contents.target')}
                isRequired={true}
                error={meta.error && meta.touched ? meta.error : undefined}
              >
                <AntdSelect
                  value={input.value ? input.value : undefined}
                  placeholder={t('finalFormField.select')}
                  onChange={(value) => {
                    input.onChange({
                      target: {
                        value
                      }
                    } as unknown as ChangeEvent<string>)

                    updateContentTagType(`${parentPropertyName}.tagType`, value);

                    updateForm(`${parentPropertyName}.value`, undefined);
                  }}
                >
                  {tagIdOptions.filter((option) => {
                    if (option.value === input.value) {
                      return true;
                    }

                    // filteredSegmentConditionTagIdOptions received from parent are the options which are not selected
                    // and should be rendered in the dropdown
                    const found = filteredSegmentConditionTagIdOptions.find(
                      (filteredOption) => filteredOption.value === option.value
                    );

                    // return item when found in filteredSegmentConditionTagIdOptions passed by parent
                    return found ? true : false;
                  }).map(({ value, label }) => {
                    return (
                      <AntdSelect.Option key={value} data-label="label">
                        {label}
                      </AntdSelect.Option>
                    )
                  })}
                </AntdSelect>
              </InputWrap>
            )
          }}
        />
      </Col>

      <Col span={8}>
        <Field
          name={`${parentPropertyName}.operator`}
          validate={requiredAll}
          render={({ input, meta }) => {
            if (input.value) {
              updateValueState(input.value, parentPropertyName);
            }

            return (
              <InputWrap
                label={t('contents.operator')}
                isRequired={true}
                error={meta.error && meta.touched ? meta.error : undefined}
              >
                <AntdSelect
                  value={input.value ? input.value : undefined}
                  placeholder={t('finalFormField.select')}
                  onChange={(value) => {
                    input.onChange({
                      target: {
                        value
                      },
                    } as unknown as ChangeEvent<string>)
                  }}
                >
                  {tagOperators.map(({ value, label }) => {
                    return (
                      <AntdSelect.Option key={value} value={value}>{label}</AntdSelect.Option>
                    )
                  })}
                </AntdSelect>
              </InputWrap>
            )
          }}
        />
      </Col>

      <Col span={8}>
        {/* 
          below method is the only way to enable/disable validations, as due to some error in
          react-final-form, it doesn't update validations until next re-render which is not ideal
        */}
        {isValueDisabled && (
          <Select
            name={`${parentPropertyName}.value`}
            label={t('contents.value')}
            disabled={true}
            options={[]}
          />
        )}

        {!isValueDisabled && (
          <Select
            name={`${parentPropertyName}.value`}
            label={t('contents.value')}
            isRequired={true}
            options={valueOptions}
            optionFilterProp="children"
            mode="multiple"
          />
        )}
      </Col>

      <OnChange name={`${parentPropertyName}.operator`}>
        {(value) => updateValueState(value, parentPropertyName)}
      </OnChange>
    </SegmentConditionContainer>
  );
};

interface SegmentConditionGroupProps {
  conditionGroupIndex: number;
  parentPropertyName: string;
  tagIdOptions: Option[];
  tagOperators: Option[];
  tagsMapping: TagsMapping;
  updateForm: FormApi['change'];
  formState: FormState;
};

const SegmentConditionGroup = (props: SegmentConditionGroupProps) => {
  const {
    conditionGroupIndex,
    parentPropertyName,
    tagIdOptions,
    tagOperators,
    tagsMapping,
    updateForm,
    formState,
  } = props;  

  const { t } = useTranslation();

  // options which are not yet selected in any dropdown
  const filteredSegmentConditionTagIdOptions = useMemo(() => {
    const segmentConditionTagIdOptions = tagIdOptions;

    if (formState && formState.values && formState.values.conditions) {

      const conditions: SegmentConditions = formState.values.conditions;

      if (conditions.length && conditions[conditionGroupIndex]) {
        const segmentGroupConditions = conditions[conditionGroupIndex];

        return segmentConditionTagIdOptions.filter((option) => {
          const found = segmentGroupConditions.find((segmentGroupCondition) => segmentGroupCondition.tagId === option.value);

          // when there's a match selected in dropdown, then don't return it in the dropdown options
          return found ? false : true;
        });
      }

    }

    return segmentConditionTagIdOptions;
  }, [formState, tagIdOptions, conditionGroupIndex]);

  return (
    <ArrayFields<TSegmentCondition>
      propertyName={`${parentPropertyName}`}
      addButtonLabel={t('contents.and')}
      defaultValue={{
        tagType: undefined,
        tagId: undefined,
        operator: undefined,
        value: undefined,
      }}
      itemRowContent={(propertyName, _, fieldState) => {
        return (
          <SegmentCondition
            parentPropertyName={propertyName}
            tagIdOptions={tagIdOptions}
            tagOperators={tagOperators}
            tagsMapping={tagsMapping}
            updateForm={updateForm}
            fieldState={fieldState}
            filteredSegmentConditionTagIdOptions={filteredSegmentConditionTagIdOptions}
          />
        );
      }}
    />
  );
};

interface SegmentFormContentTargetingSectionProps {
  tenantId: string;
  channel: Channel;
  updateForm: FormApi['change'];
  formState: FormState;
}

const SegmentFormContentTargetingSection = (props: SegmentFormContentTargetingSectionProps) => {
  const { tenantId, channel, updateForm, formState } = props;

  const { t } = useTranslation();

  const { data: spacesData } = useAllSpaces({ organisationId: tenantId });
  const { data: tagsData } = useTagsList(tenantId, 1000);

  const channelTags = channel.tags || [];
  const channelTagsIds = channelTags.map((channelTag) => channelTag.tagId);

  const tagsMapping = useMemo(() => {
    const tagsMapping: TagsMapping = {
      [OrganisationSpaceTypeEnum.LOCATION]: { tagType: ContentTagType.SPACE, options: [] },
      [OrganisationSpaceTypeEnum.SECTION]:  { tagType: ContentTagType.SPACE, options: [] },
      [OrganisationSpaceTypeEnum.CUSTOM]:   { tagType: ContentTagType.SPACE, options: [] },
      [OrganisationSpaceTypeEnum.COUNTRY]:  { tagType: ContentTagType.SPACE, options: [] },
      [OrganisationSpaceTypeEnum.REGION]:   { tagType: ContentTagType.SPACE, options: [] },
      [OrganisationSpaceTypeEnum.CITY]:     { tagType: ContentTagType.SPACE, options: [] },
      [OrganisationSpaceTypeEnum.FLOOR]:    { tagType: ContentTagType.SPACE, options: [] },
    };

    if (spacesData) {
      spacesData.spaces.forEach((space) => {
        const { type } = space;
        if (tagsMapping[type]) {
          tagsMapping[type].options.push({ label: space.displayName, value: space.id });
        }
      });
    }

    if (tagsData && tagsData.docs) {
      tagsData.docs
        .filter((tag) => !channelTagsIds.includes(tag.id))
        .forEach((tag) => {
          const { id, values = [] } = tag;
          tagsMapping[id] = { tagType: ContentTagType.CONTENT_TAG, options: [] };
          values.forEach((value) => {
            tagsMapping[id].options.push({ label: value, value });
          });
        });
    }

    return tagsMapping;
  }, [spacesData, channelTagsIds, tagsData]);

  const tagIdOptions = useMemo<Option[]>(() => {
    const options: Option[] = [...getSegmentConditionTagIdOptions(t)];

    if (tagsData && tagsData.docs) {
      tagsData.docs
        .filter((tag) => !channelTagsIds.includes(tag.id))
        .forEach((tag) => {
          options.push({ label: tag.name, value: tag.id });
        });
    }

    return options;
  }, [tagsData, channelTagsIds, t]);

  const tagOperators = useMemo<Option[]>(() => {
    return getSegmentConditionOperatorOptions(t);
  }, [t]);

  const defaultSegmentConditionGroup: TSegmentConditionGroup = useMemo(() => {
    return [
      {
        tagType: undefined,
        tagId: undefined,
        operator: undefined,
        value: undefined,
      },
    ];
  }, []);

  // return empty array when there are validation errors in any condition group, as we don't want to send any
  // API call to fetch devices when there are validation errors to avoid edge-cases
  const conditions = useMemo<SegmentConditions>(() => {
    if (
      formState &&
      formState.values &&
      formState.values.conditions &&
      Array.isArray(formState.values.conditions)
    ) {
      const isAnyConditionGroupInvalid = formState.values.conditions.some(
        (_: TSegmentConditionGroup, conditionGroupIndex: number) => {
          if (formState.errors && formState.errors.conditions[conditionGroupIndex]) {
            return true;
          }
          return false;
        },
      );
    
      if (isAnyConditionGroupInvalid) {
        return [];
      }
    
      return formState.values.conditions;
    }    

    return [];
  }, [formState]);

  return (
    <>
      <Typography.Title level={4}>{t('contents.contentTargeting')}</Typography.Title>
      <Typography.Text>{t('contents.contentTargetingSubHeading')}</Typography.Text>

      <ContentTargetingDevicesContainer>
        <SegmentContentTargetingDevices tenantId={tenantId} conditions={conditions} />
      </ContentTargetingDevicesContainer>

      <DividerFull />

      <SegmentConditionsContainer>
        {(!spacesData || !tagsData) && (
          <AddTargetingDisabled>
            <Button size="default" type="link" icon="plus" disabled={true}>
              {t('contents.addTargeting')}
            </Button>
          </AddTargetingDisabled>
        )}

        {spacesData && (
          <ArrayFields<TSegmentConditionGroup>
            propertyName="conditions"
            addButtonLabel={t('contents.addTargeting')}
            defaultValue={defaultSegmentConditionGroup}
            itemRowContent={(parentPropertyName, index) => {
              return (
                <SegmentConditionGroupContainer>
                  <SegmentConditionGroup
                    conditionGroupIndex={index}
                    parentPropertyName={parentPropertyName}
                    tagIdOptions={tagIdOptions}
                    tagOperators={tagOperators}
                    tagsMapping={tagsMapping}
                    updateForm={updateForm}
                    formState={formState}
                  />
                  <SegmentConditionDivider />
                </SegmentConditionGroupContainer>
              );
            }}
          />
        )}
      </SegmentConditionsContainer>
    </>
  );
};

export default SegmentFormContentTargetingSection;

const DividerFull = styled(Divider)`
  width: calc(100% + 41px);
  margin-left: -20px;
  overflow: none;
`;

const SegmentConditionsContainer = styled.div`
  margin-bottom: 15px;

  .array-fields-delete-btn-container {
    align-self: flex-start;
  }
`;

const SegmentConditionGroupContainer = styled(Row)`
  width: 100%;

  .array-fields-delete-btn-container {
    align-self: start;
    margin-top: 32px;
  }
`;

const SegmentConditionContainer = styled(Row)`
  width: 100%;
  margin-bottom: 20px !important;
`;

const AddTargetingDisabled = styled.div`
  margin-top: 35px;
`;

const ContentTargetingDevicesContainer = styled.div`
  margin: 10px 0px;
`;
