import { useQuery } from 'react-query';
import { useApiService } from '../api-service-provider';
import Queue from '../../../store/types/queue';

const useOrganizationQueues = (organizationId: string) => {
  const apiService = useApiService();

  return useQuery(
    ['organizationQueues', organizationId],
    () =>
      apiService.get<Queue[]>('/api/queues/', {
        organizationId,
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export default useOrganizationQueues;
