import { useQuery } from 'react-query';
import { useApiService } from '../api-service-provider';
import { routes } from '../../../store/models/schema/schema-model';

interface UseAppMetaSchemaProps {
  release: string;
  provider: string;
}

const useAppMetaSchema = ({ release, provider }: UseAppMetaSchemaProps) => {
  const apiService = useApiService();

  return useQuery(
    ['appMetaSchema', release, provider],
    () => apiService.get<any>(routes.metaSchema(release, provider)),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      enabled: provider.length > 0 && release.length > 0,
    },
  );
};

export default useAppMetaSchema;
