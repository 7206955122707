import React, { useCallback, useMemo } from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import OrganisationSpace, { SpaceBillingContact, SpaceBillingAddress } from '../../../../../store/types/organisation-space';
import SpaceForm, {
  OrganisationSpaceSettings,
} from './space-form.component';
import { useUpdateSpace } from '../../../../common/use-spaces';
import { DEFAULT_BUSINESS_HOURS } from './space-form-add.component';

interface SpaceFormEditProps {
  spaceData: OrganisationSpace;
  tenantId: string;
  spaceId: string;
}

const SpaceFormEdit = (props: SpaceFormEditProps) => {
  const { spaceData, tenantId, spaceId } = props;

  const { t } = useTranslation();

  const initialValues = useMemo<OrganisationSpaceSettings>(() => {
    return {
      id: spaceData.id,
      organizationId: tenantId,
      displayName: spaceData.displayName,
      externalId: spaceData.externalId,
      parentSpaceId: spaceData.parentSpaceId || 'no_parent',
      type: spaceData.type,
      country: spaceData.country!,
      address: spaceData.address!,
      city: spaceData.city!,
      state: spaceData.state!,
      postcode: spaceData.postcode!,
      weeklySchedule: spaceData.weeklySchedule! || { weeklyTime: DEFAULT_BUSINESS_HOURS },
      notes: spaceData.notes,
      tags: spaceData.tags || [],
      billingContact: spaceData.billingContact || ({} as SpaceBillingContact),
      billingAddress: spaceData.billingAddress || ({} as SpaceBillingAddress),
    };
  }, [spaceData, tenantId]);

  const { mutateAsync: updateSpace } = useUpdateSpace(tenantId, spaceId);

  const handleFormSubmit = useCallback(
    async (values: OrganisationSpaceSettings) => {
      try {
        await updateSpace(values);
        message.success(t('spaceForm.spaceSaved'));
        return true;
      } catch (e) {
        message.error(t('spaceForm.spaceSavedError'));
        return false;
      }
    },
    [t, updateSpace],
  );

  return (
    <>
      <SpaceForm
        tenantId={tenantId}
        initialValues={initialValues}
        onFormSubmit={handleFormSubmit}
      />
    </>
  );
};

export default SpaceFormEdit;
