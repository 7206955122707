import { useQuery } from 'react-query';
import { useApiService } from '../../../app/common/api-service-provider';
import plansQueryKeys from './use-plans-query-keys';
import Plan from '../../types/plan';

const usePlanGet = (planId: string) => {
  const apiService = useApiService();

  return useQuery(
    plansQueryKeys.getPlanGetKeys(planId),
    () => apiService.get<Plan>(`/api/plans/${planId}`),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export default usePlanGet;