import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Badge,
  Button,
  message,
  Popconfirm,
  Table,
  Upload,
  Card,
  Tooltip,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { FileText } from 'react-feather';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { RouteComponentProps } from 'react-router';
import { loadingIcon } from '../../../spinner/spinner.component';
import GridappBuild from '../../../../../store/types/gridapp-build';
import GridappBuildStatusEnum from '../../../../../store/types/gridapp-build-status.enum';
import AppBuildIcon from '../../app-build-icon/app-build-icon.component';
import Gridapp from '../../../../../store/types/gridapp';
import usePermissions from '../../../../../utils/auth/use-permissions';
import { permissionKeys } from '../../../../../utils/auth/permissions';
import { getDefaultAppIcon } from '../../../../../utils/apps-library/apps-library.util';
import { Icon } from '../../../schema-form/common';
import ReleaseNotesModal, { ReleaseNotesModalRef } from '../../../release-notes-modal';
import useReleaseNotes from '../../../use-release-notes';
import { ButtonType } from '../../../../../types/styled-component';

const { Column } = Table;

const BuildColumnContent = styled.span`
  display: flex;
  align-items: center;
`;

const BuildListAppIcon = styled(AppBuildIcon)`
  margin-right: 16px;
`;

const BuildUpload = styled(Upload)`
  width: 100%;
  text-align: right;
  .ant-upload-list-item {
    text-align: left;
  }
`;

interface GridappVersionsProps
  extends RouteComponentProps<{ gridappId: string; organisationId: string }> {
  gridappBuilds: GridappBuild[];
  loading: boolean;
  fetchBuilds: (params: { gridappId: string; silent?: boolean }) => Promise<void>;
  deleteBuild: (id: string) => Promise<void>;
  uploadBuild: (params: {
    gridappId: string;
    file: File;
    onProgress?: (percent: number) => void;
  }) => Promise<void>;
  subscribeBuilds: (gridappId: string) => Promise<() => void>;
  gridapp: Gridapp;
  canDelete?: boolean;
  canModifyReleaseNotes?: boolean;
  canViewReleaseNotes?: boolean;
}

const createAlphabeticalSorter = (property: string) => {
  return (firstItem: any, secondItem: any) => {
    return (firstItem[property] as string).localeCompare(secondItem[property] as string);
  };
};

const statusSorter = createAlphabeticalSorter('status');
const creationDateSorter = createAlphabeticalSorter('createdAt');

const GridappVersions = (props: GridappVersionsProps) => {
  const {
    fetchBuilds,
    uploadBuild,
    gridappBuilds,
    deleteBuild,
    loading,
    subscribeBuilds,
    gridapp,
    canDelete = true,
    canModifyReleaseNotes = true,
    canViewReleaseNotes = true,
  } = props;
  const {
    match: {
      params: { gridappId, organisationId },
    },
  } = props;
  const { t } = useTranslation();
  const { isAllowed } = usePermissions(organisationId);

  const releaseNotesModalRef = useRef<
    ReleaseNotesModalRef & { gridappBuildId: string; releaseNotes?: string }
  >(null);

  useEffect(() => {
    let unsubscribe = () => {};

    const subscribe = async () => {
      try {
        unsubscribe = await subscribeBuilds(gridappId);
      } catch {
        // ignore the error
      }
    };

    subscribe();

    return () => {
      unsubscribe();
    };
  }, [subscribeBuilds, gridappId]);

  useEffect(() => {
    fetchBuilds({ gridappId });
  }, [fetchBuilds, gridappId]);

  const renderBuildColumn = useCallback(
    (item: GridappBuild) => {
      return (
        <BuildColumnContent>
          <BuildListAppIcon
            build={item}
            size={48}
            defaultIcon={getDefaultAppIcon(gridapp.type)}
          />
          <span>{item.result ? item.result.version : <i>{item.filename}</i>}</span>
        </BuildColumnContent>
      );
    },
    [gridapp.type],
  );

  const handleOpenReleaseNotesModal = useCallback(
    (gridappBuildId: string, releaseNotes?: string) => {
      if (releaseNotesModalRef.current) {
        releaseNotesModalRef.current.open(releaseNotes);
        releaseNotesModalRef.current.gridappBuildId = gridappBuildId;
      }
    },
    [],
  );

  const {
    mutateAsync: saveReleaseNotes,
    isError: isReleaseNotesError,
    reset: resetReleaseNotes,
  } = useReleaseNotes();

  const handleSaveReleaseNotes = useCallback(
    async (updatedReleaseNotes?: string) => {
      const { gridappBuildId = '' } = releaseNotesModalRef.current || {};

      await saveReleaseNotes({
        endpoint: `/api/gridapps/${gridappId}/builds/${gridappBuildId}`,
        releaseNotes: updatedReleaseNotes,
      });
      await fetchBuilds({ gridappId, silent: true });
    },
    [saveReleaseNotes, fetchBuilds, gridappId],
  );

  const { isUpdateReleaseNotesAllowed, isViewReleaseNotesAllowed } = useMemo(() => {
    return {
      isUpdateReleaseNotesAllowed:
        canModifyReleaseNotes && isAllowed(permissionKeys.gridApps.updateReleaseNotes),
      isViewReleaseNotesAllowed:
        canViewReleaseNotes && isAllowed(permissionKeys.gridApps.viewSingle),
    };
  }, [isAllowed, canModifyReleaseNotes, canViewReleaseNotes]);

  const renderActionsColumn = useCallback(
    (item: GridappBuild) => {
      const isDeleteAllowed =
        canDelete && isAllowed(permissionKeys.gridApps.removeVersion);
      const releaseNotes = item.result ? item.result.releaseNotes : undefined;

      return (
        <Action>
          {isDeleteAllowed && (
            <Popconfirm
              placement="top"
              title={t('areYouSureYouWantToDeleteThisApplication')}
              onConfirm={() => deleteBuild(item.id)}
              okText={t('yes')}
              cancelText={t('no')}
            >
              <Tooltip title={t('deleteAlt')}>
                <ActionButton size="small" type="link">
                  <Icon size={14} type="delete" />
                  {t('deleteAlt')}
                </ActionButton>
              </Tooltip>
            </Popconfirm>
          )}
          {(isViewReleaseNotesAllowed || isUpdateReleaseNotesAllowed) && (
            <Tooltip
              title={
                isViewReleaseNotesAllowed && !isUpdateReleaseNotesAllowed
                  ? t('releaseNotes.viewReleaseNotes')
                  : t('releaseNotes.addEditReleaseNotes')
              }
            >
              <ActionButton
                size="small"
                type="link"
                onClick={() => handleOpenReleaseNotesModal(item.id, releaseNotes)}
              >
                <Icon size={14} component={() => <FileText />} />{' '}
                {t('releaseNotes.title')}
              </ActionButton>
            </Tooltip>
          )}
        </Action>
      );
    },
    [
      deleteBuild,
      isAllowed,
      t,
      handleOpenReleaseNotesModal,
      isUpdateReleaseNotesAllowed,
      isViewReleaseNotesAllowed,
      canDelete,
    ],
  );

  const renderStatusColumn = useCallback(
    (item: GridappBuild) => {
      if (item.status === GridappBuildStatusEnum.ERROR) {
        return <Badge status="error" text={t('error')} />;
      }

      if (item.status === GridappBuildStatusEnum.PROCESSING) {
        return <Badge status="warning" text={t('processing')} />;
      }

      if (item.status === GridappBuildStatusEnum.DRAFT) {
        return <Badge color="green" text={t('valid')} />;
      }

      if (item.status === GridappBuildStatusEnum.PUBLISHED) {
        return <Badge status="processing" color="green" text={t('published')} />;
      }

      return null;
    },
    [t],
  );

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleUploadInputChange = (event: UploadChangeParam) => {
    if (
      event.file &&
      event.file.status &&
      ['done', 'error'].includes(event.file.status)
    ) {
      setFileList((list) => list.filter((file) => file.uid !== event.file.uid));
    } else {
      setFileList([...event.fileList]);
    }
  };

  const handleUpload = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;
    const handleProgress = (percent: number) => {
      onProgress({ percent });
    };
    try {
      await uploadBuild({
        gridappId,
        file,
        onProgress: handleProgress,
      });
      onSuccess();
      onSuccess();
    } catch (error) {
      message.error(t('errorUploadingTheFile'));
      onError(error);
    }
  };

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <Header>
        <ButtonContainer>
          <Typography.Title level={3}>{gridapp.displayName}</Typography.Title>
          <Typography.Paragraph>Application</Typography.Paragraph>
        </ButtonContainer>
        <Spacer />
        <ButtonContainer>
          {isAllowed(permissionKeys.gridApps.createVersion) && (
            <BuildUpload
              showUploadList={{ showPreviewIcon: true, showRemoveIcon: false }}
              onChange={handleUploadInputChange}
              fileList={fileList}
              listType="picture"
              customRequest={handleUpload}
              name="media"
            >
              <Button icon="upload">{t('upload')}</Button>
            </BuildUpload>
          )}
        </ButtonContainer>
      </Header>
      <Table
        rowKey="id"
        dataSource={gridappBuilds}
        pagination={false}
        loading={{
          indicator: loadingIcon,
          spinning: loading,
        }}
      >
        <Column render={renderBuildColumn} title={t('build')} key="version" />
        <Column
          sorter={statusSorter}
          title={t('status')}
          render={renderStatusColumn}
          key="status"
          width={240}
        />
        <Column
          title={t('uploaded')}
          key="createdAt"
          sorter={creationDateSorter}
          render={(item: GridappBuild) =>
            dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss')
          }
          width={240}
        />
        <Column
          title={t('actions')}
          key="actions"
          render={renderActionsColumn}
          width={200}
          align="center"
        />
      </Table>
      <ReleaseNotesModal
        ref={releaseNotesModalRef}
        isView={isViewReleaseNotesAllowed && !isUpdateReleaseNotesAllowed}
        onSubmit={handleSaveReleaseNotes}
        onClose={resetReleaseNotes}
        isError={isReleaseNotesError}
      />
    </Card>
  );
};

const ButtonContainer = styled.div`
  padding: 16px 16px 0 16px;
`;

const Spacer = styled.div`
  flex: 1;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  background-color: rgba(245, 246, 250, 0.8);
`;

const Action = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
`;

const ActionButton = styled(Button)`
  height: fit-content;
  display: grid;
` as ButtonType;

export default GridappVersions;
