import dayjs from 'dayjs';
import toPairs from 'lodash/toPairs';
import fromPairs from 'lodash/fromPairs';
import keys from 'lodash/keys';
import camelCase from 'lodash/camelCase';
import { GridStyles, GridStylesKey } from '@ombori/grid-reports';
import { gridStylesKeys } from './types';

export type Day = [string, Event[]];

export interface Event {
  label?: string;
  value: number;
  color?: string;
}

export const sumEvents = (events: Event[]) =>
  events.reduce((prev, { value }) => prev + value, 0);

export const sumDays = (days: Day[]) =>
  days.reduce((acc, [, events]) => acc + sumEvents(events), 0);

export const getWowChange = (data: Day[]) => {
  const max = dayjs()
    .subtract(1, 'd')
    .endOf('d');
  const prevLimit = max.subtract(15, 'd').startOf('d');
  const currLimit = max.subtract(8, 'd').startOf('d');
  const { prev, curr } = data.reduce(
    (acc, [date, events]) => {
      const day = dayjs(date);

      if ((day.isSame(prevLimit) || day.isAfter(prevLimit)) && day.isBefore(currLimit)) {
        acc.prev += sumEvents(events);
      }

      if ((day.isSame(currLimit) || day.isAfter(currLimit)) && day.isBefore(max)) {
        acc.curr += sumEvents(events);
      }

      return acc;
    },
    { prev: 0, curr: 0 },
  );

  if (curr && prev) {
    return Math.round(((curr - prev) / curr) * 1000) / 10;
  }

  return null;
};

export const getDodChange = (data: Day[]) => {
  const yesterday = data.find(
    ([day]) =>
      day ===
      dayjs()
        .subtract(1, 'd')
        .format('YYYY-MM-DD'),
  );
  const dayBeforeYesterday = data.find(
    ([day]) =>
      day ===
      dayjs()
        .subtract(2, 'd')
        .format('YYYY-MM-DD'),
  );

  if (yesterday && dayBeforeYesterday) {
    const totalYesterday = sumEvents(yesterday[1]);
    const totalDayBeforeYesterday = sumEvents(dayBeforeYesterday[1]);

    // Can't divide by zero
    if (totalYesterday > 0) {
      return (
        Math.round(((totalYesterday - totalDayBeforeYesterday) / totalYesterday) * 1000) /
        10
      );
    }
  }

  return null;
};

export const padData = (fromDate: string, toDate: string, data: Day[]) => {
  const result = fromPairs(data);
  const min = dayjs(fromDate);
  const diff = dayjs(toDate).diff(min, 'd');

  for (let i = 0; i < diff; i += 1) {
    const key = min.add(i, 'd').format('YYYY-MM-DD');
    if (!result[key]) {
      result[key] = [{ label: '', value: 0 }];
    }
  }

  return toPairs(result);
};

export const processGridStyles = (gridStyles: GridStyles): Record<string, string> => {
  const result = keys(gridStyles)
    .filter(
      (item): item is GridStylesKey => {
        const key = item as keyof GridStyles;

        return gridStylesKeys.includes(key);
      },
    )
    .reduce((accumulator, key) => {
      const newKey = camelCase(key);

      const newAccumulator = {
        ...accumulator,
        [newKey]: gridStyles[key],
      };

      return newAccumulator;
    }, {});

  return result;
};

export function prepareDateRangeSearchParams(dateFrom: string, dateTo: string) : URLSearchParams {
  const searchParams = new URLSearchParams();

  searchParams.append('fromDate', dateFrom);
  searchParams.append('toDate', dateTo);

  return searchParams;
};
