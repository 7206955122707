import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { JSONSchema6 } from 'json-schema';
import { FieldProps } from 'react-jsonschema-form';
import MediaPicker from '../media-picker/media-picker.container';
import DataCard from '../../../data-card/data-card.component';
import MultiLanguageWidgetTitle from './multi-language-title.component';
import MultiLanguageLabel from './multi-language-label.component';
import { MultiLanguageOptions } from '../../../../../utils/language/language.util';

const Container = styled(DataCard)`
  margin-bottom: 16px;
  padding: 8px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const MultiLanguageText = ({ schema, formData, onChange, ...props }: FieldProps) => {
  const { formContext = {} } = props;
  const {
    languageFrom,
    languageTo,
    supportedLanguages,
    defaultLanguage,
  } = (formContext.languageOptions || {}) as MultiLanguageOptions;

  const hasMultipleSupportedLanguages = supportedLanguages.length > 1;

  const currentSchema = (schema.properties ? schema.properties.media : {}) as JSONSchema6;

  const values = formData || {};

  const handleMediaChange = useCallback(
    (media: any) => {
      onChange({
        ...(formData || {}),
        [languageTo]: { media },
      });
    },
    [languageTo, onChange, formData],
  );

  return (
    <Container>
      <MultiLanguageWidgetTitle
        title={schema.title}
        values={values}
        supportedLanguages={supportedLanguages}
        defaultLanguage={defaultLanguage}
      />
      {hasMultipleSupportedLanguages && (
        <ContentWrapper>
          {languageFrom && <MultiLanguageLabel language={languageFrom} />}
          <MediaPicker
            {...props}
            disabled
            schema={currentSchema}
            uiSchema={{}}
            formData={values[languageFrom] ? values[languageFrom].media : {}} // TODO: Add proper validation
            onChange={handleMediaChange}
          />
        </ContentWrapper>
      )}
      <ContentWrapper>
        {languageTo && <MultiLanguageLabel language={languageTo} />}
        <MediaPicker
          {...props}
          schema={currentSchema}
          uiSchema={{}}
          formData={values[languageTo] ? values[languageTo].media : {}} // TODO: Add proper validation
          onChange={handleMediaChange}
        />
      </ContentWrapper>
    </Container>
  );
};

export default MultiLanguageText;
