import React, { useCallback, useMemo } from 'react';
import { ModulesStatusCard } from '@ombori/grid-reports';
import {
  RawMonitoringEvent,
  useInstallationRealtimeEvents,
} from '../../../use-analytics-report';
import { DataResidencyEnum } from '../../../../../store/types/organisation';
import {
  ModuleStatusWithDeviceId,
  formatRealtimeEventsForModulesStatus,
  mapDeviceIdToDeviceName,
} from './utils';
import { ModuleStatus } from './types';
import CardContainer from '../card-container';
import ModulesStatusTable from './modules-status-table.component';
import { useInstallationByIdDevices } from '../../../use-devices';

interface InstallationModulesStatusContainerProps {
  title: string;
  tenantId: string;
  installationId: string;
  dataResidency: DataResidencyEnum;
  eventType: string;
  statusCodes: ModulesStatusCard['statusCodes'];
}

const InstallationModulesStatusContainer = (
  props: InstallationModulesStatusContainerProps,
) => {
  const {
    title,
    tenantId,
    installationId,
    dataResidency,
    eventType,
    statusCodes,
  } = props;

  const transformDataFn = useCallback(
    (data: RawMonitoringEvent[]) => {
      return formatRealtimeEventsForModulesStatus(
        data,
        statusCodes,
      );
    },
    [statusCodes],
  );

  const {
    data: modulesStatusWithDeviceId,
    isLoading,
    isSuccess,
    isError,
  } = useInstallationRealtimeEvents<ModuleStatusWithDeviceId[]>({
    tenantId,
    dataResidency,
    eventType,
    installationId,
    transformDataFn,
  });

  const { data: installationDevices } = useInstallationByIdDevices({
    organisationId: tenantId,
    installationId,
  });

  const modulesData = useMemo((): ModuleStatus[] => {
    if (
      modulesStatusWithDeviceId &&
      installationDevices &&
      installationDevices
    ) {
      const modulesData = mapDeviceIdToDeviceName(
        modulesStatusWithDeviceId,
        installationDevices,
      );
      return modulesData.sort((a, z) =>
        a.deviceName.localeCompare(z.deviceName),
      );
    }

    return [];
  }, [modulesStatusWithDeviceId, installationDevices]);

  return (
    <>
      <CardContainer
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={isError}
        hasData={modulesData.length > 0 ? true : false}
        title={title}
      >
        <ModulesStatusTable data={modulesData} />
      </CardContainer>
    </>
  );
};

export default InstallationModulesStatusContainer;
