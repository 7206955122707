import { useMutation } from 'react-query';
import { useGridRndAI } from '../grid-rndai-provider';

export interface GridRndAIGeneratedImageResponse {
  created: number;
  data: Array<{ url: string }>;
}

export enum ModeEnum {
  SUBJECT_BASED = 'subject',
  MANUAL_PROMPT = 'prompt',
}

export interface UseGenerateAIImageProps {
  queryString: string;
  theme?: string;
  mode: ModeEnum;
  resultsCount?: number;
}

export const useGenerateAIImage = () => {
  const gridRndAIService = useGridRndAI();

  return useMutation(
    (body: UseGenerateAIImageProps) => gridRndAIService.post<GridRndAIGeneratedImageResponse>('/generate-image', body),
  );
};
