import * as React from 'react';
import i18n from 'i18next';
import { useStore, useActions } from 'easy-peasy';
import Sidebar from './sidebar.component';
import { RootState, RootModel } from '../../../../store/models/root.model';

interface SidebarProps {
  children: React.ReactNode;
  header?: React.ReactNode;
}

const SidebarContainer = ({ children, header }: SidebarProps) => {
  const user = useStore<RootState>((state) => state.auth.user);
  const { logout, updatePreferredLanguage } = useActions<RootModel>((actions) => ({
    logout: actions.auth.logout,
    updatePreferredLanguage: actions.auth.updatePreferredLanguage,
  }));
  const switchLanguage = React.useCallback(
    async (lang: string) => {
      i18n.changeLanguage(lang);
      await updatePreferredLanguage(lang);
    },
    [updatePreferredLanguage],
  );

  return (
    <Sidebar user={user} logout={logout} switchLanguage={switchLanguage} header={header}>
      {children}
    </Sidebar>
  );
};

export default SidebarContainer;
