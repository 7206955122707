import React, { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Button, Menu, Dropdown, Icon, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import pullAt from 'lodash/pullAt';
import FilterItemType, { ActiveFilter } from '../../../store/types/filters';
import FilterItem from './filter-item.component';
import { ReactComponent as Sliders } from '../../../assets/images/sliders.svg';
import transientOptions from '../../../utils/transient-emotion-styled-options';

const { Option } = Select;
export interface FilterProps {
  filterOptions?: FilterItemType[];
  onChange?: (filters: ActiveFilter[], lastRemovedFilter?: ActiveFilter | null) => void;
  filterResponse?: 'full' | 'partial';
  defaultActiveFilters?: ActiveFilter[];
  showAddFilter?: Boolean;
}

const FilterComponent = (props: FilterProps) => {
  const { filterOptions = [], onChange = () => {}, filterResponse = 'full', defaultActiveFilters = [], showAddFilter = true } = props;
  const { t } = useTranslation();
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const [activeFilters, setActiveFilters] = useState<ActiveFilter[]>(defaultActiveFilters);

  const maxFilterRows = useMemo(
    () =>
      filterOptions.reduce(
        (total, filter) =>
          filter.options.length ? total + filter.options.length : total,
        0,
      ),
    [filterOptions],
  );

  const filterIndexToRemove = useCallback(
    (removeFilterIndex: number) => {
      const pulledFilter = pullAt(activeFilters, removeFilterIndex);

      return {
        updatedActiveFilters: activeFilters,
        removedActiveFilters: pulledFilter.length ? pulledFilter[0] : null,
      };
    },
    [activeFilters],
  );

  const getNextOption = useCallback(
    (id: string) => {
      const selectedOptions = filterOptions.find((filterOption) => {
        return filterOption.id === id;
      });

      if (!selectedOptions) {
        return;
      }
      const filteredOptions = activeFilters.map((activeFilter) =>
        activeFilter.typeId === id ? activeFilter.valueId : null,
      );

      const insertOption = selectedOptions.options.filter(
        (o) => !filteredOptions.includes(o.id),
      )[0];

      return insertOption;
    },
    [activeFilters, filterOptions],
  );

  const handleAddFilter = useCallback(() => {
    activeFilters.forEach((filter) => {
      const nextAvailableOption = getNextOption(filter.typeId);

      if (nextAvailableOption && nextAvailableOption.id) {
        setActiveFilters([
          ...activeFilters,
          {
            typeId: filter.typeId,
            typeLabel: filter.typeLabel,
          },
        ]);
      } else {
        const nextFilterIndex = filterOptions.findIndex((filterOption) => {
          return !activeFilters.some((activeFilter) => {
            return filterOption.id === activeFilter.typeId;
          });
        });

        if (nextFilterIndex !== -1) {
          setActiveFilters([
            ...activeFilters,
            {
              typeId: filterOptions[nextFilterIndex].id,
              typeLabel: filterOptions[nextFilterIndex].label,
            },
          ]);
        }
      }
    });
  }, [activeFilters, getNextOption, filterOptions]);

  const handleInitialFilters = useCallback(
    (value) => {
      const { key: filterTypeId, label: filterTypeLabel } = value;
      const nextAvailableOption = getNextOption(filterTypeId);

      if (activeFilters.length > 0 || !nextAvailableOption) {
        return;
      }

      setActiveFilters((prevState) => [
        ...prevState,
        {
          typeId: filterTypeId,
          typeLabel: filterTypeLabel,
        },
      ]);
    },
    [activeFilters.length, getNextOption],
  );

  const handleFilterDropdownChange = useCallback(
    (rowIndex, value) => {
      const { key: filterTypeId, label: filterTypeLabel } = value;

      const nextAvailableOption = getNextOption(filterTypeId);

      if (nextAvailableOption && nextAvailableOption.id) {
        setActiveFilters(
          activeFilters.map((item, index) =>
            index === rowIndex
              ? {
                  typeId: filterTypeId,
                  typeLabel: filterTypeLabel,
                }
              : item,
          ),
        );
      }
    },
    [getNextOption, activeFilters],
  );

  const handleChange = useCallback(
    (updatedActiveFilters: ActiveFilter[], lastRemovedFilter?: ActiveFilter | null) => {
      const updatedFilters =
        filterResponse === 'full'
          ? updatedActiveFilters.filter(
              (updatedActiveFilter) =>
                updatedActiveFilter.typeId && updatedActiveFilter.valueId,
            )
          : updatedActiveFilters;

      onChange(updatedFilters, lastRemovedFilter);
    },
    [filterResponse, onChange],
  );

  const handleOptionsChange = useCallback(
    (rowIndex, value) => {
      const { key: filterValueId, label: filterValueLabel } = value;
      const existingActiveFilter = activeFilters.find(
        (activeFilter) => activeFilter.valueId === filterValueId,
      );

      if (!existingActiveFilter) {
        const updatedActiveFilters = activeFilters.map((item, index) => {
          return index === rowIndex
            ? {
                ...item,
                valueId: filterValueId,
                valueLabel: filterValueLabel,
              }
            : item;
        });
        setActiveFilters(updatedActiveFilters);

        handleChange(updatedActiveFilters);
      }
    },
    [activeFilters, handleChange],
  );

  const handleClickButton = useCallback(() => {
    setShowDropDown((val) => !val);
  }, []);

  const handleHoverOff = useCallback(() => {
    setShowDropDown(false);
  }, []);

  const handleRemoveItem = useCallback(
    (rowIndex: number) => {
      const { updatedActiveFilters, removedActiveFilters } = filterIndexToRemove(
        rowIndex,
      );

      handleChange(updatedActiveFilters, removedActiveFilters);
    },
    [filterIndexToRemove, handleChange],
  );

  const listActiveOptions = useCallback(
    (filterId: string) =>
      activeFilters
        .map((activeFilter) =>
          activeFilter.typeId === filterId ? activeFilter.valueId : null,
        )
        .filter((f) => f),
    [activeFilters],
  );

  const getAvailableOptions = useCallback(
    (filterId: string) => {
      const filteredData = filterOptions.find(
        (filterOption) => filterOption.id === filterId,
      );
      return filteredData && filteredData.options ? filteredData.options : [];
    },
    [filterOptions],
  );

  const selectedFilters = useCallback(
    (filterId: string) => {
      return filterOptions
        .map((device) =>
          device.id !== filterId &&
          listActiveOptions(device.id).length < getAvailableOptions(device.id).length
            ? device
            : null,
        )
        .filter((item) => item);
    },
    [filterOptions, getAvailableOptions, listActiveOptions],
  );

  const selectedFilteredOptions = useCallback(
    (filterId: string, optionValue: string) => {
      const availableDataOptions = filterOptions.find(
        (filterOption) => filterOption.id === filterId,
      );

      return (
        availableDataOptions &&
        availableDataOptions.options
          .map((option) =>
            listActiveOptions(filterId).indexOf(option.id) === -1 &&
            (listActiveOptions(filterId).indexOf(optionValue) !== -1 || !optionValue)
              ? option
              : null,
          )
          .filter((option) => option)
      );
    },
    [filterOptions, listActiveOptions],
  );

  const isAddFilterEnabled = useMemo<boolean>(() => {
    const hasValueToBeFilled = activeFilters.some(
      (filter) => filter.valueId === undefined,
    );

    return (
      activeFilters.length < maxFilterRows &&
      activeFilters.length > 0 &&
      !hasValueToBeFilled
    );
  }, [activeFilters, maxFilterRows]);

  const filterMenu = useMemo(
    () => (
      <CustomMenu>
        <MenuHeaderFooter>
          <AddFilterBy>{t('filterBy')}</AddFilterBy>
        </MenuHeaderFooter>
        {activeFilters.map((filter, filterIndex) => {
          const getAvailableFilters = selectedFilters(filter.typeId).filter(
            (item) => item,
          );
          const getAvailableSelectedOptions = selectedFilteredOptions(
            filter.typeId,
            filter.valueId || '',
          );

          return (
            <CustomMenuItem key={`${filter.typeId}-${filter.valueId}-menu-item`}>
              <FilterItem
                defaultFilter={filter}
                onFilterItemChange={handleFilterDropdownChange}
                onFilterOptionChange={handleOptionsChange}
                onRemove={handleRemoveItem}
                rowIndex={filterIndex}
                availableFilters={getAvailableFilters}
                availableOptions={getAvailableSelectedOptions}
              />
            </CustomMenuItem>
          );
        })}
        {activeFilters.length <= 0 && (
          <Menu.Item>
            <SelectComponent
              defaultValue={{ key: '', label: t('selectFilter') }}
              onChange={handleInitialFilters}
              labelInValue
            >
              {filterOptions.map((filterOption) => {
                const { id, label } = filterOption;

                return (
                  <Option key={`${id}-${label}-initial`} value={id}>
                    {label}
                  </Option>
                );
              })}
            </SelectComponent>
          </Menu.Item>
        )}
        <Menu.Divider />
        <MenuHeaderFooter>
          <AddFilterButton
            type="link"
            disabled={!showAddFilter || !isAddFilterEnabled}
            onClick={handleAddFilter}
          >
            + {t('addFilter')}
          </AddFilterButton>
        </MenuHeaderFooter>
      </CustomMenu>
    ),
    [
      t,
      activeFilters,
      handleInitialFilters,
      filterOptions,
      isAddFilterEnabled,
      handleAddFilter,
      selectedFilters,
      selectedFilteredOptions,
      handleFilterDropdownChange,
      handleOptionsChange,
      handleRemoveItem,
      showAddFilter,
    ],
  );

  return (
    <Container onMouseLeave={handleHoverOff}>
      <Dropdown overlay={filterMenu} visible={showDropDown}>
        <DropdownButton id="btn" type="link" onClick={handleClickButton}>
          <Sliders height={16} width={16} />
          <FilterLabel>{t('filter')}</FilterLabel>
          <IconDown type="down" $isActive={showDropDown} />
        </DropdownButton>
      </Dropdown>
    </Container>
  );
};

const Container = styled.div`
  height: inherit;
`;

const DropdownButton = styled(Button)`
  height: inherit;
  display: flex;
  align-items: center;
  background: unset;
  border-color: transparent !important;
  box-shadow: unset;
  padding: 0 10px;

  :hover {
    border-color: transparent !important;
    background-color: #e7ebef66;
  }
` as any;

const MenuHeaderFooter = styled(Menu.Item)`
  cursor: default;
  :hover {
    background-color: unset;
  }
`;

const CustomMenuItem = styled(Menu.Item)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 5px;
  min-width: 150px;
`;

const CustomMenu = styled(Menu)`
  min-width: 350px;
  padding: 4px;
  overflow: hidden;
  box-sizing: border-box;
`;

const AddFilterBy = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
`;

const AddFilterButton = styled(Button)`
  padding-left: 0px;
` as any;

const SelectComponent = styled(Select)`
  :nth-of-type(1) {
    margin-right: 10px;
  }
  .ant-select-selection__rendered {
    width: 100px;
    text-transform: capitalize;
  }
`;

const FilterLabel = styled.span`
  font-size: 14px;
  margin: auto 5px;
  text-transform: capitalize;

  @media (max-width: 768px) {
    width: min-content;
    max-width: 45px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const IconDown = styled(Icon, transientOptions)<{ $isActive?: boolean }>`
  display: flex !important;
  font-size: 9px !important;
  margin-left: 0px !important;

  ${({ $isActive }) =>
    $isActive
      ? `transform: scale(1.2,1) rotate(180deg) !important;
         transition: transform 0.25s linear !important;`
      : `transform: scale(1.2,1) rotate(0deg) !important;
         transition: transform 0.25s linear !important;`}
`;

export default FilterComponent;
