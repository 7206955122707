import React from 'react';
import SpaceDetails from './spaces-details/space-details.component';

type SpaceDetailsContainer = Pick<
  React.ComponentProps<typeof SpaceDetails>,
  'match' | 'history' | 'location'
>;

const SpaceDetailsContainer = (props: SpaceDetailsContainer) => {
  return (
    <SpaceDetails {...props} />
  );
};

export default SpaceDetailsContainer;
