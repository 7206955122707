import { useCallback } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { useApiService } from '../api-service-provider';
import queryKeys from './build-query-key';
import { PaginationCollection, PaginationRequest } from '../../../store/types/pagination';
import { ApiError } from '../../../services/api/api-error';
import { DeviceBundle } from './type';

const useDeviceBundles = <TModule = string, TModuleVersion = string>(
  queryParams?: PaginationRequest,
): UseQueryResult<PaginationCollection<DeviceBundle<TModule, TModuleVersion>>, ApiError> => {
  const apiService = useApiService();

  const { param = undefined, page = 1, limit = 20 } =
  queryParams || {};

  const params = {
    ...(param ? { param } : {}),
    page,
    limit,
  };

  const fetch = useCallback(
    async (request: PaginationRequest) => {
      const { page, limit, param } = request;
      const url = `/api/device-bundle`;
      return apiService.get<PaginationCollection<DeviceBundle<TModule, TModuleVersion>>>(url, {
        ...param,
        page,
        limit,
      });
    },
    [apiService],
  );

  const result = useQuery(
    queryKeys.getDeviceBundles(params),
    () => fetch(params),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      keepPreviousData: true,
      onError: (_error: ApiError) => {},
    }
  );

  return result;
};

export default useDeviceBundles;
