import { useQuery } from 'react-query';
import { useApiService } from '../api-service-provider';
import Media from '../../../store/types/media';

const useOrganisationMedia = (organisationId: string) => {
  const apiService = useApiService();

  return useQuery(
    ['organisationMedia', organisationId],
    () =>
      apiService.get<{
        files: Media[];
      }>('api/media/all', {
        organization: organisationId,
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      enabled: organisationId.length > 0,
    },
  );
};

export default useOrganisationMedia;
