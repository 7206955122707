import ItabLoginLogo from '../assets/images/itab-login-logo.png';
import OmboriIcon16 from '../assets/icons/phygrid-favicon-16x16.png';
import OmboriIcon32 from '../assets/icons/phygrid-favicon-32x32.png';
import OmboriLoginLogo from '../assets/images/phygrid-login-logo.svg';

export interface Theme {
  id: string;
  pageTitle: string;
  icon16: string;
  icon32: string;
  loginLogo: string;
  showPoweredByInIdentifiedSession: boolean;
}

const itab: Theme = {
  id: 'itab-theme',
  pageTitle: 'Phygrid by Ombori',
  icon16: OmboriIcon16,
  icon32: OmboriIcon32,
  loginLogo: ItabLoginLogo,
  showPoweredByInIdentifiedSession: true,
};

const ombori: Theme = {
  id: 'ombori-theme',
  pageTitle: 'Phygrid by Ombori',
  icon16: OmboriIcon16,
  icon32: OmboriIcon32,
  loginLogo: OmboriLoginLogo,
  showPoweredByInIdentifiedSession: false,
};

export const themeConfiguration = { itab, ombori };

export default themeConfiguration;
