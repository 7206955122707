import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FilterItem from '../../../store/types/filters';
import { SortCriteria } from '../../../store/types/sort';
import useStage from '../use-stage';
import useDeviceArchitecture from '../use-device-architecture';

interface UseDeviceBundleFilterOptionsResponse {
  filterOptions: FilterItem[];
  sortCriteriaOptions: SortCriteria[];
}

const useDeviceBundleFilterOptions = (): UseDeviceBundleFilterOptionsResponse => {
  const { t } = useTranslation();

  const { deviceArchitectureOptions } = useDeviceArchitecture();

  const { stageOptions } = useStage(t);

  const filterOptions = useMemo((): FilterItem[] => {
    return [
      {
        id: 'deviceArchitectures',
        label: t('architecture'),
        options: deviceArchitectureOptions,
      },
      {
        id: 'stage',
        label: t('stage'),
        options: stageOptions,
      },
    ];
  }, [deviceArchitectureOptions, stageOptions, t]);

  return {
    filterOptions,
    sortCriteriaOptions: [],
  };
};

export default useDeviceBundleFilterOptions;
