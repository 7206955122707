import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { StyledComponent } from '@emotion/styled';
import { Global, css } from '@emotion/core';
import { Button, Popover, Spin, Tooltip } from 'antd';
import { Icon } from '../schema-form/common';
import { ComponentEnum, ComponentVersion } from '../use-device-components';
import { ButtonProps } from 'antd/lib/button/button';
import { TooltipPlacement } from 'antd/lib/tooltip';
import OutdatedDeviceComponentsContent from './outdated-device-components-content.component';

interface OutdatedDeviceComponentsPopoverProps {
  components: ComponentVersion[];
  popoverPlacement?: TooltipPlacement;
  isLoading?: boolean;
  isError?: boolean;
  refetch?: () => void;
  canUserUpdate?: boolean;
}

const OutdatedDeviceComponentsPopover = (props: OutdatedDeviceComponentsPopoverProps) => {
  const { components, popoverPlacement = 'left', isLoading, isError, refetch, canUserUpdate } = props;

  const { t } = useTranslation();

  const [isDeviceBundleUpdating, setIsDeviceBundleUpdating] = useState<boolean>();

  const handleDeviceBundleUpdate = useCallback(
    (isUpdating) => setIsDeviceBundleUpdating(isUpdating),
    [],
  );

  const { outdatedComponents } = useMemo(() => {
    const outdatedComponents = components.filter(
      (item) => item.currentVersion !== item.latestVersion,
    );

    const deviceBundle = components.find(
      (item) => item.component === ComponentEnum.DEVICE_BUNDLE,
    );

    const isDeviceBundleOutdated = !!deviceBundle && deviceBundle.currentVersion !== deviceBundle.latestVersion;
    const { bundleComponents = [] } = deviceBundle || {};

    const filteredOutdatedBundleComponents = !isDeviceBundleOutdated ? bundleComponents.filter(
      (bundleComponent) =>
        bundleComponent.currentVersion !== bundleComponent.latestVersion,
    ) : [];

    return {
      outdatedComponents: [...outdatedComponents, ...filteredOutdatedBundleComponents],
    };
  }, [components]);

  if (isLoading) {
    return (
      <Tooltip placement="left" title={t('outdatedDeviceComponents.checkingForUpdates')}>
        <Spin indicator={<Icon type="loading" spin />} />
      </Tooltip>
    );
  }

  if (isError && refetch) {
    return (
      <Tooltip placement="left" title="Unable to fetch info">
        <RetryButton type="ghost" icon="reload" size="small" onClick={() => refetch()} />
      </Tooltip>
    );
  }

  if (isError) {
    return (
      <Tooltip placement="left" title="Unable to fetch info">
        <Icon type="close-circle" theme="filled" color="#FF4D4F" size={14} />
      </Tooltip>
    );
  }

  if (!outdatedComponents.length || isLoading) {
    return <EmptyWrap />;
  }

  return (
    <>
      <Global
        styles={css`
          .outdated-device-components-popover {
            .ant-popover-inner-content {
              padding: 0;
            }
          }
        `}
      />
      <Popover
        overlayClassName="outdated-device-components-popover"
        placement={popoverPlacement}
        overlayStyle={{
          maxWidth: 600,
        }}
        content={
          <OutdatedDeviceComponentsContent
            outdatedComponents={outdatedComponents}
            onDeviceBundleUpdate={handleDeviceBundleUpdate}
            canUserUpdate={canUserUpdate}
          />
        }
      >
        {isDeviceBundleUpdating ? (
          <Icon type="loading" spin size={14} />
        ) : (
          <Icon
            type="exclamation-circle"
            theme="filled"
            color="#40A9FF"
            size={14}
            onClick={() => {
              if (refetch) refetch();
            }}
          />
        )}
      </Popover>
    </>
  );
};

const EmptyWrap = styled.div`
  width: 14px;
  height: 14px;
`;

type StyledButtonType = StyledComponent<
  ButtonProps & React.ClassAttributes<Button>,
  Pick<ButtonProps & React.ClassAttributes<Button>, keyof ButtonProps>,
  any
>;

const RetryButton = styled(Button)`
  display: flex;
  border: none;
  width: 12px !important;
  height: 12px !important;
  font-size: 12px !important;
  color: #ff4d4f;
` as StyledButtonType;

export default OutdatedDeviceComponentsPopover;
