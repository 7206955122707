import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldProps } from 'react-jsonschema-form';
import SchemaForm from '../../schema-form.component';
import { buildFieldSchema, buildUiSchema } from './build-schema-version-select';
import { useGridAppBuilds } from '../../../use-grid-app-builds';
import { useGridapps } from '../../../use-gridapps';

interface GridappData {
  id: string;
  buildId: string;
}

interface GridappVersionSelectProps extends Pick<FieldProps, 'formData' | 'onChange'> {
  organizationId: string;
  isGridAppNamePickerVisible?: boolean;
  isGridAppVersionPickerVisible?: boolean;
}

const GridappVersionSelect = (props: GridappVersionSelectProps) => {
  const {
    organizationId,
    isGridAppNamePickerVisible = true,
    isGridAppVersionPickerVisible = true,
    formData,
    onChange,
  } = props;
  const refGridAppId = useRef<string>();

  const { id: gridAppId } = formData.gridapp || { id: null };

  const { t } = useTranslation();

  const { data: gridAppBuilds = [], isFetching } = useGridAppBuilds({ gridAppId: refGridAppId.current || gridAppId });

  const { data: gridapps = [] } = useGridapps(organizationId);

  const { fieldSchema, uiSchema } = useMemo(() => {
    const fieldSchema = buildFieldSchema({
      gridAppId,
      gridApps: gridapps,
      gridAppBuilds,
      isGridAppNamePickerVisible,
      isGridAppVersionPickerVisible,
      t,
    });

    const uiSchema = buildUiSchema(gridAppBuilds);

    return {
      fieldSchema,
      uiSchema,
    };
  }, [
    t,
    gridapps,
    gridAppId,
    gridAppBuilds,
    isGridAppNamePickerVisible,
    isGridAppVersionPickerVisible,
  ]);

  const handleChange = useCallback(
    async (gridAppPickerFormData: GridappData) => {
      const { id: gridappId, buildId } = gridAppPickerFormData;

      if (isFetching) {
        return;
      }

      const matchedBuild = gridAppBuilds.find((build) => build.id === buildId);

      const { url = null, filename = null, result = { iconUrl: null } } =
        gridAppId && buildId && matchedBuild ? matchedBuild : {};

      const updatedBuildId =
        gridappId !== refGridAppId.current && refGridAppId.current !== undefined
          ? undefined
          : buildId;

      refGridAppId.current = gridappId;

      if (!gridappId && !updatedBuildId) {
        return;
      }

      const selectionDetails = {
        ...formData,
        gridapp: { id: refGridAppId.current, buildId: updatedBuildId },
        ref: 'media',
        id: updatedBuildId,
        type: 'application/x-gridapp',
        url,
        name: filename,
        valid: true,
        iconUrl: result.iconUrl || null,
      };

      onChange(selectionDetails);
    },
    [onChange, gridAppBuilds, gridAppId, isFetching, formData],
  );

  return (
    <SchemaForm
      tagName="div"
      schema={fieldSchema}
      uiSchema={uiSchema}
      data={formData.gridapp || {}}
      onChange={handleChange}
    />
  );
};

export default React.memo(GridappVersionSelect);
