/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Table, Row, Col } from 'antd';
import styled from '@emotion/styled';
import { TFunction } from 'i18next';
import { get } from 'lodash';
import PlanPanelCard from '../../../plans/common/plan-panel-card.component';
import AccountBalancePieChart from './account-balance-pie-chart.component';
import { ApiError } from '../../../../../../services/api/api-error';
import {
  OrganizationAccountBalance,
  OrganizationAccountBalanceEntries,
} from '../../../../../../store/types/organisation';
import Loader from '../../../../../common/loader/loader-component';
import { getTranslationFromObject } from '../../../../../../utils/language/language.util';
import { formatAmount } from '../../../../../../utils/currency';
import AddVoucher from '../../../plans/common/add-voucher.component';
import ErrorView from '../../../../../common/error-view/error-view.component';

const { Title } = Typography;

interface AccountBalanceProps {
  organizationId: string;
  loading: boolean;
  error: ApiError;
  accountBalance: OrganizationAccountBalance;
  fetchAccountBalance: (params: {
    organizationId: string;
    silent?: boolean;
  }) => Promise<void>;
  addVoucher: (params: {
    organizationId: string;
    voucherCode: string;
  }) => Promise<{ valid: boolean; voucherCode?: string }>;
}

const AccountBalance = (props: AccountBalanceProps) => {
  const {
    organizationId,
    loading,
    error,
    accountBalance,
    fetchAccountBalance,
    addVoucher,
  } = props;
  const { t, i18n } = useTranslation();

  // This will make sure no previous data appears before prior to refetch
  // Either this or clear store data on unmount
  const [hasMounted, setHasMounted] = useState<boolean>(false);

  useEffect(() => {
    fetchAccountBalance({ organizationId }).then(() => {
      setHasMounted(true);
    });
  }, [fetchAccountBalance, organizationId]);

  const {
    alertType,
    alertDescription,
    formattedAccountBalance,
    formattedSponsorshipBalance,
    formattedTotalCredits,
    availableCreditsColumns,
    availableCreditsData,
  } = useMemo(() => {
    const {
      message: bannerMessage = null,
      currency = null,
      currentMonetaryBalance = 0,
      currentSponsorshipBalance = 0,
      totalCredits = 0,
      entries = [],
      isSponsorshipAccountActive = false,
    } = accountBalance || {};
    const { type = 'info', text = '' } = bannerMessage || {};

    // eslint-disable-next-line no-use-before-define
    const { creditsColumns, creditsData } = getTableContent(t, entries);

    return {
      alertType: type,
      alertDescription: text ? getTranslationFromObject(text) : undefined,
      formattedAccountBalance: currency
        ? formatAmount(currency, currentMonetaryBalance, i18n.language)
        : '',
      formattedSponsorshipBalance:
        currency && isSponsorshipAccountActive
          ? formatAmount(currency, currentSponsorshipBalance, i18n.language)
          : '',
      formattedTotalCredits: currency
        ? formatAmount(currency, totalCredits, i18n.language)
        : '',
      availableCreditsColumns: creditsColumns,
      availableCreditsData: creditsData,
    };
  }, [accountBalance, i18n.language, t]);

  const isAccessAllowed = useMemo(() => {
    const statusCode: number = get(error, 'response.statusCode');
    return statusCode !== 403;
  }, [error]);

  const isViewable = !loading && hasMounted && isAccessAllowed;

  const noBalance = !accountBalance ? (
    <ErrorView title={t('accountBalance.noBalanceAvailable')} content={null} fillPage />
  ) : null;

  return (
    <PlanPanelCard
      icon="bar-chart"
      title={t('accountBalance.title')}
      alertType={alertType}
      alertDescription={isViewable ? alertDescription : undefined}
      extra={
        isViewable ? (
          <BalancePanelHeaderExtra>
            {formattedSponsorshipBalance && (
              <Extra>
                <div>{formattedSponsorshipBalance}</div>
                <div>{t('accountBalance.sponsorshipBalance')}</div>
              </Extra>
            )}
            {formattedAccountBalance && (
              <Extra>
                <div>{formattedAccountBalance}</div>
                <div>{t('accountBalance.accountBalance')}</div>
              </Extra>
            )}
          </BalancePanelHeaderExtra>
        ) : (
          undefined
        )
      }
      footer={
        isViewable ? (
          <AddVoucher
            organizationId={organizationId}
            addVoucher={addVoucher}
            fetchAccountBalance={fetchAccountBalance}
          />
        ) : (
          undefined
        )
      }
    >
      <Content className="content-body" padded={loading || !hasMounted}>
        <Loader
          loading={loading}
          error={error || noBalance}
          hasMounted={hasMounted}
          errorFillPage
        >
          {accountBalance && (
            <Row>
              <Col xl={15}>
                <Section>
                  <TitleStyled level={4}>Available credit</TitleStyled>
                  <AvailableCreditTable
                    // @ts-ignore
                    columns={availableCreditsColumns}
                    dataSource={availableCreditsData}
                    size="small"
                    locale={{ emptyText: t('accountBalance.noAvailableCredit') }}
                    pagination={false}
                  />
                </Section>
              </Col>
              <Col xl={9}>
                <Section>
                  <AccountBalancePieChart
                    formattedTotalCredits={formattedTotalCredits}
                    accountBalance={accountBalance}
                  />
                </Section>
              </Col>
            </Row>
          )}
        </Loader>
      </Content>
    </PlanPanelCard>
  );
};

const getTableContent = (
  t: TFunction,
  availableBalanceEntries: OrganizationAccountBalanceEntries[],
) => {
  const creditsColumns = [
    {
      title: t('type'),
      dataIndex: 'typeDesc',
      key: 'typeDesc',
      align: 'left',
      render: (text: string, record: { [key: string]: string }) => (
        <TypeLabel>
          <div>{text}</div>
          <div>{record.validity}</div>
        </TypeLabel>
      ),
    },
    {
      title: t('remaining'),
      dataIndex: 'remaining',
      key: 'remaining',
      align: 'right',
    },
  ];

  const creditsData = availableBalanceEntries.map((entry, index) => {
    const { typeDesc, codes, validity, amountLeft, currency } = entry;

    return {
      key: index,
      // Show multiple device voucher codes in a tool tip
      typeDesc: codes.length === 1 ? `${typeDesc}: ${codes}` : typeDesc,
      validity: getTranslationFromObject(validity),
      remaining: formatAmount(currency, amountLeft),
    };
  });

  return { creditsColumns, creditsData };
};

const Content = styled.div<{ padded?: boolean }>`
  padding: ${({ padded }) => (padded ? `40px 0` : '0')} !important;

  @media (max-width: 1500px) {
    .ant-row > .ant-col {
      display: inline;
      width: 100%;
    }
  }
`;

const BalancePanelHeaderExtra = styled.div`
  display: flex;
`;

const Extra = styled.div`
  color: #111111;
  text-align: right;
  font-size: 20px;
  margin-left: 32px;

  div:last-child {
    color: #444444;
    font-size: 12px;
    font-weight: 300;
  }
`;

const Section = styled.div`
  padding: 20px;
`;

const TitleStyled = styled(Title)`
  margin-bottom: 10px !important;
`;

const AvailableCreditTable = styled(Table)`
  .ant-table {
    color: #111111;
    font-size: 14px;
  }

  .ant-table-body {
    margin: 0px !important;
  }

  .ant-table-thead tr > th {
    background-color: #f5f6facc !important;

    .ant-table-column-title {
      text-transform: uppercase;
    }
  }

  .ant-table-tbody .ant-table-row > td:first-of-type {
    width: 55%;
  }
`;

const TypeLabel = styled.div`
  div:first-of-type {
    word-break: break-word;
  }

  div:last-child {
    color: #00000073;
  }
`;

export default AccountBalance;
