import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { getProductTypesKeys, UseProductTypesProps } from './use-product-types';

type UseProductTypesInvalidateQueryResponse = (props: UseProductTypesProps) => Promise<void>;

const useProductTypesInvalidateQuery = (): UseProductTypesInvalidateQueryResponse => {
  const queryClient = useQueryClient();

  const invalidateProductTypeQuery = useCallback(
    async (props: UseProductTypesProps) => {
      const queryKeys = getProductTypesKeys(props);

      await queryClient.invalidateQueries(queryKeys);
    },
    [queryClient],
  );

  return invalidateProductTypeQuery;
};

export default useProductTypesInvalidateQuery;
