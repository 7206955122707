import React from 'react';
import { Menu } from 'antd';
import { MenuProps } from 'antd/lib/menu';

interface SidebarMenuProps extends MenuProps {
  children: React.ReactNode;
}

const SidebarMenu = ({ children, ...restProps }: SidebarMenuProps) => {
  return (
    <Menu {...restProps} mode="inline" theme="dark" style={{ flex: 7, padding: 8 }}>
      {children}
    </Menu>
  );
};

export default SidebarMenu;
