import React, { useCallback } from 'react';
import { Field } from 'react-final-form';
import { TreeSelect } from 'antd';
import { Validator, composeValidators } from '../validator';
import { requiredAll } from '../../../organisations/organisation-details/queue-details/queue-setup/validate-form';
import InputWrap from '../input-wrap';
import { TreeSelectProps } from 'antd/lib/tree-select';
import { TreeNode, TreeNodeValue } from 'antd/lib/tree-select/interface';
import { ChangeEvent } from '../../../../types/types';
import { SpinnerWrap } from '../../spinner';

interface FilterTreeNode {
  props: TreeNode;
}

interface SelectTreeSingleValuePickerProps<T extends TreeNodeValue>
  extends TreeSelectProps<T> {
  name: string;
  label?: React.ReactNode;
  isRequired?: boolean;
  validators?: Validator[];
  isLoading?: boolean;
}

const SelectTreeSingleValuePicker = <T extends TreeNodeValue>(
  props: SelectTreeSingleValuePickerProps<T>,
) => {
  const {
    className,
    name,
    label,
    isRequired = false,
    validators = [],
    treeDefaultExpandAll: isTreeDefaultExpandedAll = true,
    showSearch = true,
    allowClear: isAllowClear = true,
    isLoading,
    ...restTreeSelectProps
  } = props;

  const inputValidators = [...(isRequired ? [requiredAll] : []), ...validators];

  const handleSearchFilter = useCallback(
    (inputValue: string, treeNode: FilterTreeNode): boolean => {
      const { title, value } = treeNode.props;

      const input = inputValue.toLowerCase();

      return (
        (!!title &&
          title
            .toString()
            .toLowerCase()
            .indexOf(input) >= 0) ||
        (!!value &&
          value
            .toString()
            .toLowerCase()
            .indexOf(input) >= 0)
      );
    },
    [],
  );

  return (
    <Field name={name} key={isRequired.toString()} validate={composeValidators(inputValidators)}>
      {({ input, meta }) => {
        const { value, onChange } = input;

        const isError = !!meta.error && meta.touched;

        const handleChange = (value: T) => {
          onChange(({ target: { value: value } } as unknown) as ChangeEvent<T>);
        };

        return (
          <InputWrap
            className={className}
            label={label}
            isRequired={isRequired}
            error={isError ? meta.error : undefined}
          >
            <SpinnerWrap isLoading={isLoading}>
              <TreeSelect<T>
                {...restTreeSelectProps}
                value={value}
                data-name={input.name}
                treeDefaultExpandAll={isTreeDefaultExpandedAll}
                showSearch={showSearch}
                allowClear={isAllowClear}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                onChange={handleChange}
                filterTreeNode={handleSearchFilter}
              />
            </SpinnerWrap>
          </InputWrap>
        );
      }}
    </Field>
  );
};

export default SelectTreeSingleValuePicker;
