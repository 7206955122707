import { getApiUrl } from '../env';

export const getSourceUrl = (returnPath: string = ''): string =>
  encodeURIComponent(
    `${document.location.protocol}//${document.location.host}${returnPath}`,
  );

const getAuthUrl = (returnPath: string = '') => {
  return `${getApiUrl()}/auth/ombori-id?source=${getSourceUrl(returnPath)}`;
};

export default getAuthUrl;
