import React, { useMemo } from 'react';
import { keyBy } from 'lodash';
import { DataResidencyEnum } from '../../../../store/types/organisation';
import CardContainer from './card-container';
import RealtimeStatusComponent from '../charts/realtime-status.component';
import {
  useInstallationRealtimeStatus,
  useDeviceRealtimeStatus,
  DistinctColumnKeyType,
} from '../../use-analytics-report';
import { GridStyles } from '@ombori/grid-reports';
import useDevice, { useTenantDevices } from '../../use-devices';
import UniversalDevice from '../../../../store/types/universal-device';
import { useSpaces } from '../../use-spaces';
import OrganisationSpace from '../../../../store/types/organisation-space';

interface RealtimeStatusProps {
  tenantId: string;
  dataResidency: DataResidencyEnum;
  distinctColumns: DistinctColumnKeyType[];
  gridStyles?: GridStyles;
  isVisibleWithoutData?: boolean;
  eventType: string;
  title: string;
  statusReference?: Record<
    string | number,
    {
      label: string;
      color: string;
    }
  >;
}

type DevicesRecord = Record<number, UniversalDevice>;

type SpacesRecord = Record<number, OrganisationSpace>;

const DEFAULT_STATUS_REFERENCE = {
  0: {
    label: 'Offline',
    color: '#d9534f',
  },
  1: {
    label: 'Online',
    color: '#5cb85c',
  },
  [-1]: {
    label: 'Unknown',
    color: '#292b2c',
  },
};

interface InstallationRealtimeStatusProps extends RealtimeStatusProps {
  installationId: string;
}

export const InstallationRealtimeStatus: React.FC<InstallationRealtimeStatusProps> = ({
  tenantId,
  installationId,
  dataResidency,
  distinctColumns,
  gridStyles,
  isVisibleWithoutData,
  eventType,
  title,
  statusReference = DEFAULT_STATUS_REFERENCE,
}) => {
  const spacesFetchingState = useSpaces({ organisationId: tenantId });

  const devicesFetchingState = useTenantDevices({ tenantId });

  const installationSessionsState = useInstallationRealtimeStatus({
    tenantId,
    installationId,
    dataResidency,
    distinctColumns,
    eventType,
  });

  const spaces = useMemo(() => {
    if (spacesFetchingState.data) {
      return keyBy(spacesFetchingState.data.spaces || [], 'id');
    }
  
    return {};
  }, [spacesFetchingState.data]);

  const devices = useMemo(() => {
    if (devicesFetchingState.data) {
      return keyBy(devicesFetchingState.data || [], 'id');
    }

    return {};
  }, [devicesFetchingState.data]);

  const onRefetchLabel = useMemo(() => {
    if (installationSessionsState.isSuccess) {
      return new Date(installationSessionsState.dataUpdatedAt).toUTCString();
    }

    return '';
  }, [installationSessionsState]);

  const isLoading =
    installationSessionsState.isLoading ||
    devicesFetchingState.isLoading ||
    spacesFetchingState.isLoading;

  const isSuccess =
    installationSessionsState.isSuccess &&
    devicesFetchingState.isSuccess &&
    spacesFetchingState.isSuccess;

  const isError =
    installationSessionsState.isError ||
    devicesFetchingState.isError ||
    spacesFetchingState.isError;

  return (
    <CardContainer
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      hasData={
        installationSessionsState.isSuccess && installationSessionsState.data.length > 0
      }
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
      onRefetchLabel={onRefetchLabel}
      onRefetch={() => installationSessionsState.refetch()}
    >
      {installationSessionsState.isSuccess &&
        devicesFetchingState.isSuccess &&
        spacesFetchingState.isSuccess && (
          <RealtimeStatusComponent
            data={installationSessionsState.data}
            spaces={spaces}
            devices={devices}
            statusReference={statusReference}
          />
        )}
    </CardContainer>
  );
};

interface DeviceRealtimeStatusProps extends RealtimeStatusProps {
  deviceId: string;
}

export const DeviceRealtimeStatus: React.FC<DeviceRealtimeStatusProps> = React.memo(({
  tenantId,
  deviceId,
  dataResidency,
  distinctColumns,
  gridStyles,
  isVisibleWithoutData,
  eventType,
  title,
  statusReference = DEFAULT_STATUS_REFERENCE,
}) => {
  const deviceFetchingState = useDevice(deviceId);

  const spacesFetchingState = useSpaces({ organisationId: tenantId, limit: 15000 });

  const realTimeStatusFetchingState = useDeviceRealtimeStatus({
    tenantId,
    deviceId,
    dataResidency,
    distinctColumns,
    eventType,
  });

  const spaces = useMemo(() => {
    if (spacesFetchingState.data) {
      return keyBy(spacesFetchingState.data.spaces || [], 'id');
    }
  
    return {};
  }, [spacesFetchingState.data]);

  const devices = useMemo(() => {
    if (deviceFetchingState.isSuccess) {
      return {
        [deviceFetchingState.data.id]: deviceFetchingState.data,
      };
    }

    return {};
  }, [deviceFetchingState]);

  const onRefetchLabel = useMemo(() => {
    if (realTimeStatusFetchingState.isSuccess) {
      return new Date(realTimeStatusFetchingState.dataUpdatedAt).toUTCString();
    }

    return '';
  }, [realTimeStatusFetchingState]);

  const isLoading =
    realTimeStatusFetchingState.isLoading ||
    deviceFetchingState.isLoading ||
    spacesFetchingState.isLoading;

  const isSuccess =
    realTimeStatusFetchingState.isSuccess &&
    deviceFetchingState.isSuccess &&
    spacesFetchingState.isSuccess;

  const isError =
    realTimeStatusFetchingState.isError ||
    deviceFetchingState.isError ||
    spacesFetchingState.isError;

  return (
    <CardContainer
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      hasData={
        realTimeStatusFetchingState.isSuccess &&
        realTimeStatusFetchingState.data.length > 0
      }
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
      onRefetchLabel={onRefetchLabel}
      onRefetch={() => realTimeStatusFetchingState.refetch()}
    >
      {realTimeStatusFetchingState.isSuccess &&
        deviceFetchingState.isSuccess &&
        spacesFetchingState.isSuccess && (
          <RealtimeStatusComponent
            data={realTimeStatusFetchingState.data}
            spaces={spaces}
            devices={devices}
            statusReference={statusReference}
          />
        )}
    </CardContainer>
  );
});
