
/**
 * @description formats file size in bytes i.e. 10240 -> 10.20 KB, 12897484 -> 12.89 MB etc.
 * @param fileSizeInBytes 
 * @param precision 
 * @returns 
 */
export const formatFileSize = (fileSizeInBytes: number, precision = 1) : string => {
  const KiB = 1024;
  const MiB = 1024 * KiB;
  const GiB = 1024 * MiB;

  // base for bytes is 1024 which corresponds to KiB, MiB, GiB, but unit system used in operating system
  // are KB, MB, GB which use 1000 as base, we need to convert for the adjustment
  const KB = 1000;
  const MB = 1000 * KB;
  const GB = 1000 * MB;

  if (fileSizeInBytes >= GiB) {
    return `${convertToBase(fileSizeInBytes, GB, precision)} GB`;
  } else if (fileSizeInBytes >= MiB) {
    return `${convertToBase(fileSizeInBytes, MB, precision)} MB`;
  } else if (fileSizeInBytes >= KiB) {
    return `${convertToBase(fileSizeInBytes, KB, precision)} KB`;
  }

  return `${fileSizeInBytes <= 0 ? 0 : fileSizeInBytes} bytes`;
}

function convertToBase(bytes: number, base: number, precision: number) : number {
  return parseFloat((bytes / base).toFixed(precision));
}
