import i18next from 'i18next';
import { sortBy } from 'lodash';
import { BillingCurrencyEnum } from '../store/types/organisation';
import Plan from '../store/types/organisation-plan';
import CurrencyAwareValue from '../store/types/currency-aware-value';

export enum PlanChangeType {
  NO_CHANGE,
  UPGRADE,
  DOWNGRADE,
}

export const getMonthlyFee = (
  tenantCurrency: BillingCurrencyEnum,
  prices: CurrencyAwareValue[],
): {
  formattedFee: string;
  price: number;
} => {
  const price = prices.find((item) => item.currency === tenantCurrency) || {
    currency: tenantCurrency,
    valueDecimal: 0,
  };

  const formattedFee = new Intl.NumberFormat(i18next.language, {
    style: 'currency',
    currency: price.currency,
  })
    .format(price.valueDecimal)
    .replace(/^(\D+)/, '$1 ');

  return {
    formattedFee,
    price: price.valueDecimal,
  };
};

export const sortOrganizationPlans = (
  organizationPlans: Plan[],
  tenantCurrency: BillingCurrencyEnum,
): Plan[] => {
  return sortBy(organizationPlans, (plan) => {
    const { activePrice } = plan;
    const { prices = [] } = activePrice || {};

    const { price } = getMonthlyFee(tenantCurrency, prices);

    return price;
  });
};

export const getPlanChangeType = (
  activePlan: Plan | null,
  scheduledPlan: Plan | null,
): PlanChangeType => {
  if (activePlan == null || scheduledPlan == null) {
    return PlanChangeType.NO_CHANGE;
  }
  // If the plan is lower-tier, its price should be lower no matter the currency
  const currency = BillingCurrencyEnum.EUR;
  const { price: activePlanPrice } = getMonthlyFee(
    currency,
    activePlan.activePrice.prices,
  );
  const { price: scheduledPlanPrice } = getMonthlyFee(
    currency,
    scheduledPlan.activePrice.prices,
  );

  return scheduledPlanPrice > activePlanPrice
    ? PlanChangeType.UPGRADE
    : PlanChangeType.DOWNGRADE;
};
