import React, { ReactNode } from 'react';
import { Global, css } from '@emotion/core';

interface ModalFixedHeightProps {
  children: ReactNode;
}

/**
 * @description use this to wrap `<Modal />` declaration/definition to have it appear as fixed height
 * @example
 * <ModalFixedHeight>
 *   <Modal
 *    title="My Modal"
 *   >
 *    <p>Nodal body</p>
 *   </Modal>
 * </ModalFixedHeight>
 * 
 */
const ModalFixedHeight = (props: ModalFixedHeightProps) => {
  const { children } = props;

  return (
    <>
      <Global styles={MediaPreviewModalStyles} />
      {children}
    </>
  )
}

const MediaPreviewModalStyles = css`
  .ant-modal {
    height: 100vh;
    padding-bottom: 0px;
    overflow: hidden;
  }

  .ant-modal-content {
    height: 100%;

    display: flex;
    flex-direction: column;
  }

  .ant-modal-body {
    flex-grow: 1;
    overflow: scroll;
  }

  .ant-modal-footer {
    padding-bottom: 10px;
  }
`;

export default ModalFixedHeight;
