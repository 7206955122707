import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import { Typography } from 'antd';
import { castToNumber } from '../utils';
import {
  FormTextInput,
  InputCheckbox,
  Label,
  MainSectionTitle,
  PanelBody,
  PanelCardMain,
  RowStyled,
} from '../styled-block';

const { Title } = Typography;

const Sensors = ({ values }: { values: any }) => {
  const { t } = useTranslation();

  return (
    <PanelCardMain bodyStyle={{ padding: 0 }}>
      <MainSectionTitle>
        <Title level={2}>{t('queueSensorsIntegrationTitle')}</Title>
      </MainSectionTitle>
      <PanelBody>
        <Field name="maxOccupancyIncreasingWithSensors" parse={castToNumber}>
          {({ input, meta }) => (
            <FormTextInput
              input={input}
              meta={meta}
              label={t('queueMaxOccupancyIncreasingWithSensorsTitle.title')}
              type="number"
              description={t('queueMaxOccupancyIncreasingWithSensorsTitle.description')}
            />
          )}
        </Field>
        <Field name="isAutoCallEnabled" type="checkbox">
          {({ input }) => (
            <RowStyled>
              <Label>
                <InputCheckbox {...input} disabled={values.isInStore} type="checkbox" />
                {t('enableAutoCall')} <small>({t('autoCallHint')})</small>
              </Label>
            </RowStyled>
          )}
        </Field>
      </PanelBody>
    </PanelCardMain>
  );
};

export default Sensors;
