import React, { FC } from 'react';
import styled from '@emotion/styled';
import { PhyhubDevicesStatusColorEnum } from '../phyhub-devices-status';

interface PhyhubDevicesStatusCircleProps {
  statusColor: PhyhubDevicesStatusColorEnum;
}

const PhyhubDevicesStatusCircle: FC<PhyhubDevicesStatusCircleProps> = (props) => {
  const { statusColor } = props;

  return <PhyhubDevsStatusCircle statusColor={statusColor} />;
};

const CIRCLE_SIZE = '6px';

const PhyhubDevsStatusCircle = styled.span<{ statusColor: PhyhubDevicesStatusColorEnum }>`
  display: inline-block;
  width: ${CIRCLE_SIZE};
  height: ${CIRCLE_SIZE};
  border-radius: 50%;
  background-color: ${(props) => props.statusColor};
`;

export default PhyhubDevicesStatusCircle;
