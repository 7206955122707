import React from 'react';
import dayjs from 'dayjs';

const DEFAULT_REFRESH_RATE_MS = 60 * 1000;

const useCurrentDate = (refreshRate: number = DEFAULT_REFRESH_RATE_MS) => {
  const [date, setDate] = React.useState(() => dayjs());

  React.useEffect(() => {
    const interval = setInterval(() => setDate(dayjs()), refreshRate);
    return () => clearInterval(interval);
  }, [refreshRate]);

  return date;
};

export default useCurrentDate;
