import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import { RootModel, RootState } from '../../../../../../store/models/root.model';
import EnvVariables from './env-variables.component';
import usePermissions from '../../../../../../utils/auth/use-permissions';
import { permissionKeys } from '../../../../../../utils/auth/permissions';

type EnvVariablesContainerProps = Omit<
  React.ComponentProps<typeof EnvVariables>,
  'loaded' | 'envVariables' | 'fetchEnvVariables' | 'updateEnvVariables' | 'error'
>;

const EnvVariablesContainer = (props: EnvVariablesContainerProps) => {
  const {
    match: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      params: { appId, organisationId },
    },
  } = props;

  const { loaded, envVariables, error } = useStore<RootState>(
    (state) => ({
      loaded: state.classicAppEnvVariables.loaded(appId),
      envVariables: state.classicAppEnvVariables.data[appId] || null,
      error: state.classicAppEnvVariables.error[appId] || null,
    }),
    [appId],
  );

  const { fetchEnvVariables, updateEnvVariables } = useActions<RootModel>((actions) => ({
    fetchEnvVariables: actions.classicAppEnvVariables.fetch,
    updateEnvVariables: actions.classicAppEnvVariables.update,
  }));

  const { isAllowed } = usePermissions(organisationId);

  return (
    <EnvVariables
      {...props}
      envVariables={envVariables}
      fetchEnvVariables={fetchEnvVariables}
      updateEnvVariables={updateEnvVariables}
      loaded={loaded}
      error={error}
      canUpdate={isAllowed(permissionKeys.apps.updateAppVariables)}
    />
  );
};

export default EnvVariablesContainer;
