import React, { FC } from 'react';
import styled from '@emotion/styled';
import {
  PhyhubDeviceArchitectureEnum,
  PhyhubDeviceStatusEnum,
} from '../../../../store/types/phyhub-devices';
import PhyhubDevicesStatusCircle from '../phyhub-devices-status/phyhub-devices-status-circle/phyhub-devices-status-circle.component';
import usePhyhubDeviceStatusColor from '../phyhub-devices-status/hooks/use-phyhub-device-status-color';

interface PhyhubDevicesTypeProps {
  arch: PhyhubDeviceArchitectureEnum;
  osName: string;
  status: PhyhubDeviceStatusEnum;
}

const PhyhubDevicesType: FC<PhyhubDevicesTypeProps> = (props) => {
  const { arch, osName, status } = props;

  const statusColor = usePhyhubDeviceStatusColor(status);

  return (
    <Container>
      <Column>
        <PhyhubDevicesStatusCircle statusColor={statusColor} />
      </Column>
      <Column>{osName}</Column>
      <Column>{arch}</Column>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 6px 134px 120px;
  grid-column-gap: 8px;
`;

const Column = styled.div`
  display: flex;
  align-items: center;
`;

export default PhyhubDevicesType;
