import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { List, Icon } from 'antd';
import PanelCard from '../../../common/panel-card/panel-card.component';


export default function DevelopersResources() {
  const { t } = useTranslation();

  return (
    <div className="content-body">
      <ResourcesSection>
        <PanelCard>
          <List>
            <Title>{t('resources')}</Title>
          </List>
          <List itemLayout="horizontal">
            <a
              href="https://developer.omborigrid.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <DeveloperDocumentation>
                {t('developerDocumentation')}
                <IconRight type="right" />
              </DeveloperDocumentation>
            </a>
          </List>
          <List itemLayout="horizontal">
            <a
              href="https://join.slack.com/t/slack-pgo5586/shared_invite/zt-s1ajca83-k8i1f2mqgCMD0vDfpCk4Bg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <DeveloperSlack>
                {t('developerSlack')}
                <IconRight type="right" />
              </DeveloperSlack>
            </a>
          </List>
        </PanelCard>
      </ResourcesSection>
    </div>
  );
};

const Title = styled.div`
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 25px;
  line-height: 1.23;
  padding-left: 15px;
  padding-top: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 15px;
`;

const ResourcesSection = styled.div`
  margin-top: 20px;
`;

const DeveloperDocumentation = styled.div`
  color: rgba(0, 0, 0, 0.65);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 15px;
  padding-left: 15px;
  padding-top: 15px;
`;

const DeveloperSlack = styled.div`
  color: rgba(0, 0, 0, 0.65);
  padding-bottom: 15px;
  padding-left: 15px;
  padding-top: 15px;
  display: flex;
  flex-direction: row;
`;

const IconRight = styled(Icon)`
  position: absolute;
  right: 15px;
  color: #0064b6;
  top: 20px;
`;
