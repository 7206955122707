import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { getIntegrationsKeys } from './use-integrations';
import { PaginationRequest } from '../../../../../../store/types/pagination';

interface UseIntegrationInvalidateQueriesResponse {
  invalidateGetIntegrationsQuery: (
    tenantId?: string,
    request?: PaginationRequest,
  ) => Promise<void>;
}

const useIntegrationInvalidateQueries = (): UseIntegrationInvalidateQueriesResponse => {
  const queryClient = useQueryClient();

  const invalidateGetIntegrationsQuery = useCallback(
    async (tenantId?: string, request?: PaginationRequest) => {
      const queryKeys = getIntegrationsKeys(tenantId, request);

      await queryClient.invalidateQueries(queryKeys);
    },
    [queryClient],
  );

  return {
    invalidateGetIntegrationsQuery,
  };
};

export default useIntegrationInvalidateQueries;
