import { GridAssistant, useGridLlmService } from "@ombori/grid-llm-react";
import { useMutation, useQueryClient, UseMutationOptions } from 'react-query';
import storeAIAssistantQueryKeys from './store-ai-assistants-query-keys';

interface UseStoreAIAssistantDeleteProps {
  tenantId: string;
  assistantId: string;
}

export const useStoreAIAssistantDelete = () => {
  const gridLlmService = useGridLlmService();
  const queryClient = useQueryClient();

  const deleteAssistantMutation = async ({ tenantId, assistantId }: UseStoreAIAssistantDeleteProps): Promise<GridAssistant> => {
    const assistant = await gridLlmService.removeAssistant(assistantId);
    return assistant;
  };

  const options: UseMutationOptions<GridAssistant, Error, UseStoreAIAssistantDeleteProps> = {
    mutationFn: deleteAssistantMutation,
    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey: storeAIAssistantQueryKeys.paginationPrefix(data.tenantId) });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: storeAIAssistantQueryKeys.paginationPrefix(data.tenantId) });
      queryClient.setQueryData<GridAssistant[]>(storeAIAssistantQueryKeys.details, (prevAssistants) => {
        return (prevAssistants || []).filter((assistant) => assistant.id !== data.id) || [];
      });
    },
  };

  return useMutation<GridAssistant, Error, UseStoreAIAssistantDeleteProps>(options);
};
