import styled from '@emotion/styled';
import { RouteComponentProps } from 'react-router';
import { Col } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useActions, useStore } from 'easy-peasy';
import { useTranslation } from 'react-i18next';
import Header from '../../../../common/app-layout/header/header.component';
import Contact from '../../../../../store/types/contact';
import { RootModel, RootState } from '../../../../../store/models/root.model';
import { Session } from '../../../../../store/types/session';
import {
  getFirstNameFromContact,
  getInfoFromIdentities,
  getInfoFromMetadata,
  getLastNameFromContact,
} from '../utils';
import Information from './information';
import Sessions from './sessions';

type ContactDetailsProps = RouteComponentProps<{
  organisationId: string;
  contactId: string;
}>;

const ContactDetails = ({ match, history }: ContactDetailsProps) => {
  const {
    params: { organisationId: tenantId, contactId },
  } = match;
  const { t } = useTranslation();

  const { fetchSessions } = useActions<RootModel>((actions) => ({
    fetchSessions: actions.sessions.fetchSessionsForContact,
  }));

  const { sessions } = useStore<
    RootState,
    {
      sessions: Session[];
    }
  >((state) => {
    const result = {
      sessions: state.sessions.values(tenantId) || [],
      isLoading: state.sessions.loading[tenantId],
    };

    return result;
  });

  const { contact, isLoading, dataResidency } = useStore<
    RootState,
    { contact?: Contact; isLoading: boolean; dataResidency: string }
  >((state) => {
    const result = {
      contact: state.contacts
        .values(tenantId)
        .find((existingContact) => existingContact.id === contactId),
      isLoading: state.contacts.loading[tenantId],
      dataResidency:
        (state.organisations.data &&
          state.organisations.data[tenantId] &&
          state.organisations.data[tenantId].dataResidency) ||
        'not-specified-data-residency',
    };

    return result;
  });

  const { fetchOneContact, deleteOne } = useActions<RootModel>((actions) => ({
    fetchOneContact: actions.contacts.fetchOne,
    deleteOne: actions.contacts.deleteOne,
  }));

  useEffect(() => {
    fetchSessions({ tenantId, contactId, dataResidency });
  }, [contactId, fetchSessions, tenantId, dataResidency]);

  useEffect(() => {
    if (!contact && !isLoading) {
      fetchOneContact({ tenantId, contactId, dataResidency });
    }
  }, [contact, isLoading, tenantId, contactId, fetchOneContact, dataResidency]);

  const getContactFullName = useCallback(() => {
    if (!contact) {
      return '';
    }
    const firstName = getFirstNameFromContact(contact) || '';
    const lastName = getLastNameFromContact(contact) || '';
    const fullName = `${firstName} ${lastName}`;
    if (fullName === ' ') {
      return `(${t('contactDetailsSessionsNoNameTitle')})`;
    }
    return fullName;
  }, [contact, t]);

  const metaInfo = useMemo(() => {
    if (!contact) {
      return [];
    }
    const metaInfoFromContact = getInfoFromMetadata(contact);
    const result = metaInfoFromContact.map((info) => ({
      key: info.key,
      value: info.data.value,
    }));

    return result;
  }, [contact]);

  const identities = useMemo(() => {
    if (!contact) {
      return [];
    }
    const identitiesFromContact = getInfoFromIdentities(contact);
    const result = identitiesFromContact.map((info) => ({
      key: info.key,
      value: info.value,
    }));

    return result;
  }, [contact]);

  const onDelete = useCallback(async () => {
    await deleteOne({ tenantId, dataResidency, contactId });
    history.push(`/organisations/${tenantId}/contacts/overview`);
  }, [deleteOne, tenantId, dataResidency, contactId, history]);

  if (!contact) {
    return <span>Error! Contact by {contactId} is not found</span>;
  }

  return (
    <>
      <ContactContactDetailsHeader
        title={
          <span>
            {t('contactDetailsFirstSectionTitle')} {'  >  '}
            {isLoading && `${t('loading')}...`}
            {!isLoading && getContactFullName()}
          </span>
        }
      />
      <Content>
        <SessionsInfo>
          <Sessions sessions={sessions} isLoading={isLoading} />
        </SessionsInfo>
        <ContactInfoCol>
          <Information metaInfo={metaInfo} identities={identities} onDelete={onDelete} />
        </ContactInfoCol>
      </Content>
    </>
  );
};

const ContactContactDetailsHeader = styled(Header)`
  padding-bottom: 0;
`;

const Content = styled.div`
  margin: 20px;
  display: flex;
`;

const SessionsInfo = styled(Col)`
  flex: 2;
`;
const ContactInfoCol = styled(Col)`
  flex: 1;
  margin-left: 16px;
`;

export default ContactDetails;
