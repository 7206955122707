import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from '@emotion/styled';
import { Row, Col, Modal } from 'antd';
import { PlanTypeEnum } from '../../../../../store/types/organisation-plan';
import { OrganizationPlans } from '../../../../../store/types/organisation';
import TenantPlans from './tenant-plans/tenant-plans.component';
import SupportPlans from './support-plans/support-plans.component';
import { ApiError } from '../../../../../services/api/api-error';
import AvailablePlansContainer from '../common/available-plans/available-plans.container';
import { StickyColumn } from '../../queue-details/queue-setup/styled-block';
import { getPlanChangeType, PlanChangeType } from '../../../../../utils/plans';

interface ActivePlansProps extends RouteComponentProps<{ organisationId: string }> {
  loadingActivePlans: boolean;
  errorActivePlans: ApiError | null;
  activePlans: OrganizationPlans | null;
  fetchActivePlans: (params: { organizationId: string }) => Promise<void>;
  loadingScheduledPlans: boolean;
  errorScheduledPlans: ApiError | null;
  scheduledPlans: OrganizationPlans | null;
  fetchScheduledPlans: (params: { organizationId: string }) => Promise<void>;
}

const ActivePlans = (props: ActivePlansProps) => {
  const {
    match,
    loadingActivePlans,
    errorActivePlans,
    activePlans,
    fetchActivePlans,
    loadingScheduledPlans,
    errorScheduledPlans,
    scheduledPlans,
    fetchScheduledPlans,
  } = props;
  const { tenantPlan: activeTenantPlan = null, supportPlan: activeSupportPlan = null } =
    activePlans || {};
  const {
    tenantPlan: scheduledTenantPlan = null,
    supportPlan: scheduledSupportPlan = null,
  } = scheduledPlans || {};

  const { organisationId } = match.params;
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [planType, setPlanType] = useState<PlanTypeEnum>(PlanTypeEnum.TENANT);
  const [planId, setPlanId] = useState<string>('');

  // We want to hide this component behind a loader until the data is re-fetched.
  // TODO: Revisit this behavior, consider https://web.dev/stale-while-revalidate/
  const [hasFetchedData, setHasFetchedData] = useState<boolean>(false);

  useEffect(() => {
    Promise.all([
      fetchActivePlans({ organizationId: organisationId }),
      fetchScheduledPlans({ organizationId: organisationId }),
    ])
      .then(() => setHasFetchedData(true))
      .catch(() => {});
  }, [fetchActivePlans, fetchScheduledPlans, organisationId]);

  const openAvailablePlansModal = (type: PlanTypeEnum) => {
    const activePlan =
      type === PlanTypeEnum.TENANT ? activeTenantPlan : activeSupportPlan;
    const scheduledPlan =
      type === PlanTypeEnum.TENANT ? scheduledTenantPlan : scheduledSupportPlan;
    const planChangeType = getPlanChangeType(activePlan, scheduledPlan);
    const planToDisplay =
      planChangeType === PlanChangeType.UPGRADE ? scheduledPlan : activePlan;
    if (planToDisplay == null) {
      return;
    }
    setIsModalVisible(true);
    setPlanType(type);
    setPlanId(planToDisplay.id);
  };

  const isLoading = !hasFetchedData || loadingActivePlans || loadingScheduledPlans;
  const error = errorActivePlans || errorScheduledPlans;

  return (
    <>
      <div className="content-body">
        <Row gutter={{ md: 20, xl: 40 }}>
          <Col md={24} xl={15}>
            <Section>
              <TenantPlans
                loading={isLoading}
                error={error}
                activePlan={activeTenantPlan}
                scheduledPlan={scheduledTenantPlan}
                onUpgradePlan={() => openAvailablePlansModal(PlanTypeEnum.TENANT)}
              />
            </Section>
            <Section>
              <SupportPlans
                loading={isLoading}
                error={error}
                activePlan={activeSupportPlan}
                scheduledPlan={scheduledSupportPlan}
                onChangePlan={() => openAvailablePlansModal(PlanTypeEnum.SUPPORT)}
              />
            </Section>
          </Col>
          <StickyColumn md={24} xl={9}>
            {/* right-side component here */}
          </StickyColumn>
        </Row>
      </div>
      <AvailablePlansModal
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width="75vw"
        visible={isModalVisible}
        destroyOnClose
        centered
      >
        <AvailablePlansContainer
          {...props}
          activePlanType={planType}
          activePlanId={planId}
          onCloseParentModal={() => setIsModalVisible(false)}
        />
      </AvailablePlansModal>
    </>
  );
};

const AvailablePlansModal = styled(Modal)`
  height: 80%;
  .ant-modal-content,
  .ant-modal-body {
    height: 100%;
  }

  @media screen and (max-width: 991px) {
    width: 95vw !important;
  }
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

export default ActivePlans;
