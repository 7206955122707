import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import EnvironmentsList from './environments-list.component';
import { RootModel, RootState } from '../../../../store/models/root.model';
import Environment from '../../../../store/types/environment';
import { getPermissions } from '../../../../utils/auth/permissions';

type EnvironmentsListContainer = Pick<
  React.ComponentProps<typeof EnvironmentsList>,
  'match' | 'history' | 'location'
>;

const EnvironmentsListContainer = (props: EnvironmentsListContainer) => {
  const {
    match: {
      params: { organisationId },
    },
  } = props;

  // TODO: how to memoize this properly?
  const {
    fetchEnvironments,
    createEnvironment,
    updateEnvironment,
    deleteEnvironment,
  } = useActions<RootModel>((actions) => ({
    fetchEnvironments: actions.environments.fetch,
    createEnvironment: (data: Partial<Environment>) =>
      actions.environments.create({ data, key: organisationId }),
    updateEnvironment: (data: Partial<Environment>) =>
      actions.environments.update({ data, key: organisationId }),
    deleteEnvironment: (id: string) =>
      actions.environments.delete({ id, key: organisationId }),
  }));

  const { environments, loaded, permissions } = useStore<RootState>(
    (state) => ({
      environments: state.environments.values(organisationId),
      loaded: state.environments.loaded(organisationId),
      permissions: getPermissions(state.auth.user, props.match.params.organisationId),
    }),
    [organisationId],
  );

  return (
    <EnvironmentsList
      {...props}
      environments={environments}
      loaded={loaded}
      fetchEnvironments={fetchEnvironments}
      createEnvironment={createEnvironment}
      updateEnvironment={updateEnvironment}
      deleteEnvironment={deleteEnvironment}
      canCreate={permissions.environments.create}
      canUpdate={permissions.environments.update}
      canDelete={permissions.environments.remove}
    />
  );
};

export default EnvironmentsListContainer;
