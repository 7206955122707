import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Input, Select, TextArea, Validator, validateCharacterLength, validateMaxLength } from '../../../../common/react-final-form';
import { getSeverityOptions } from '../../../../../utils/alerts';
import { requiredAll, requiredNumber } from '../../queue-details/queue-setup/validate-form';

const AlertRuleFormMainSection = () => {
  const { t } = useTranslation();

  const severityOptions = useMemo(() => {
    return getSeverityOptions(t).map((severityOption) => {
      return {
        value: severityOption.id as number,
        label: severityOption.label,
      };
    });
  }, [t]);

  const severityRequiredValidator = requiredNumber as Validator<string | number>;

  return (
    <>
      <FieldContainer>
        <Input
          name="displayName"
          label={`${t('alertRules.name')} *`}
          placeholder={t('alertRules.namePlaceholder')}
          validators={[requiredAll, validateCharacterLength(3, 200)]}
        />
      </FieldContainer>

      <FieldContainer>
        <TextArea
          name="description"
          label={t('alertRules.description')}
          placeholder={t('alertRules.descriptionPlaceholder')}
          validators={[validateMaxLength(500)]}
        />
      </FieldContainer>

      <FieldContainer>
        <Select<number>
          name="severity"
          label={`${t('alertRules.severity')} *`}
          validators={[severityRequiredValidator]}
          options={severityOptions}
          isSearchEnabled={false}
        />
      </FieldContainer>
    </>
  );
};

export default AlertRuleFormMainSection;

const FieldContainer = styled.div`
  margin-bottom: 15px;
`;
