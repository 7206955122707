import { thunk, Thunk } from 'easy-peasy';
import { ProductType } from '@ombori/grid-products';
import { omit } from 'lodash';
import Injections from '../../injections.interface';
import createKeyedCrudModel, {
  KeyedCrudModel,
} from '../../common/keyed-crud-model/keyed-crud.model';
import { DataResidencyEnum } from '../../types/organisation';

interface BaseProps {
  tenantId: string;
  dataResidency: DataResidencyEnum;
  env: string;
}

interface BasePropsWithTypeId extends BaseProps {
  typeId: string;
}

export interface GridProductTypesModel
  extends KeyedCrudModel<ProductType & { id: string }, { tenantId: string }> {
  fetchProductTypes: Thunk<GridProductTypesModel, BaseProps, Injections>;
  fetchOne: Thunk<GridProductTypesModel, BasePropsWithTypeId, Injections>;
  updateOne: Thunk<GridProductTypesModel, BaseProps & { type: ProductType }, Injections>;
  addOne: Thunk<GridProductTypesModel, BaseProps & { type: ProductType }, Injections>;
  deleteOne: Thunk<GridProductTypesModel, BaseProps & { productTypeId: string }, Injections>;
}

const GridProductTypesModel: GridProductTypesModel = {
  ...createKeyedCrudModel<ProductType & { id: string }, { tenantId: string }>(
    '',
    'tenantId',
  ),
  fetchProductTypes: thunk(
    async (actions, { tenantId, dataResidency, env }, { injections }) => {
      actions.setLoading({ key: tenantId, loading: true });
      actions.setError({ key: tenantId, error: null });
      try {
        const result = await injections.gridProductService.get<{
          data: ProductType[];
        }>(`${tenantId}/${env}/product-types`, dataResidency);
        const { data } = result;

        actions.setData({
          key: tenantId,
          data: data.map((type) => ({ ...type, id: type.productTypeId })),
        });
      } catch (error) {
        actions.setError({ key: tenantId, error });
        return { totalCount: 0, totalPages: 0, hasMore: false };
      } finally {
        actions.setLoading({ key: tenantId, loading: false });
      }
    },
  ),
  fetchOne: thunk(
    async (actions, { tenantId, dataResidency, env, typeId }, { injections }) => {
      actions.setLoading({ key: tenantId, loading: true });
      actions.setError({ key: tenantId, error: null });
      try {
        const { data } = await injections.gridProductService.get<{ data: ProductType }>(
          `/${tenantId}/${env}/product-types/${typeId}`,
          dataResidency,
        );
        actions.setSingle({ key: tenantId, data: { ...data, id: data.productTypeId } });
      } catch (error) {
        actions.setError({ key: tenantId, error });
        throw error;
      } finally {
        actions.setLoading({ key: tenantId, loading: false });
      }
    },
  ),
  updateOne: thunk(
    async (actions, { tenantId, type, env, dataResidency }, { injections }) => {
      actions.setLoading({ key: tenantId, loading: true });
      actions.setError({ key: tenantId, error: null });
      try {
        const typeSafe = omit(type, 'id');
        await injections.gridProductService.post(
          `/${tenantId}/${env}/admin/product-types`,
          dataResidency,
          { data: typeSafe },
        );
        actions.setSingle({
          key: tenantId,
          data: { ...type, id: type.productTypeId },
        });
      } catch (error) {
        actions.setError({ key: tenantId, error });
        throw error;
      } finally {
        actions.setLoading({ key: tenantId, loading: false });
      }
    },
  ),
  addOne: thunk(
    async (actions, { tenantId, type, env, dataResidency }, { injections }) => {
      actions.setLoading({ key: tenantId, loading: true });
      actions.setError({ key: tenantId, error: null });
      try {
        const typeSafe = omit(type, 'id');
        await injections.gridProductService.post<ProductType>(
          `/${tenantId}/${env}/admin/product-types`,
          dataResidency,
          { data: typeSafe },
        );
        actions.setSingle({
          key: tenantId,
          data: { ...type, id: type.productTypeId },
        });
      } catch (error) {
        actions.setError({ key: tenantId, error });
        throw error;
      } finally {
        actions.setLoading({ key: tenantId, loading: false });
      }
    },
  ),
  deleteOne: thunk(
    async (actions, { tenantId, productTypeId, env, dataResidency }, { injections }) => {
      actions.setLoading({ key: tenantId, loading: true });
      actions.setError({ key: tenantId, error: null });
      try {
        await injections.gridProductService.delete<{
          data: { errorMessage: string }[];
        }>(`/${tenantId}/${env}/admin/product-types/${productTypeId}`, dataResidency);
        return null;
      } catch (error) {
        actions.setError({ key: tenantId, error });
        throw error;
      } finally {
        actions.setLoading({ key: tenantId, loading: false });
      }
    },
  ),
};

export default GridProductTypesModel;
