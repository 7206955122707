import * as React from 'react';
import { Avatar } from 'antd';
import styled from '@emotion/styled';
import Media from '../../../../store/types/media';
import { isMediaTemplate } from '../../../../utils/media-templates';
import { getMediaItemKind, MediaItemKind } from '../../../../utils/media-item-utils';

const PreviewAvatar = styled(Avatar)`
  img {
    object-fit: cover;
  }
`;

interface PreviewIconProps {
  mediaItem: Media;
  size?: number;
  className?: string;
}

const getAvatarProps = (
  mediaItem: Media,
): Partial<React.ComponentProps<typeof Avatar>> => {
  if (
    isMediaTemplate(mediaItem) &&
    mediaItem.metadata != null &&
    mediaItem.metadata.iconUrl != null
  ) {
    return { src: mediaItem.metadata.iconUrl };
  }
  switch (getMediaItemKind(mediaItem)) {
    case MediaItemKind.image:
      return { src: mediaItem.url };
    case MediaItemKind.folder:
      return { icon: 'folder' };
    case MediaItemKind.video:
      return { icon: 'video-camera' };
    default:
      return { icon: 'picture' };
  }
};

const PreviewIcon = ({ mediaItem, size, className }: PreviewIconProps) => (
  <PreviewAvatar
    className={className}
    size={size}
    shape="square"
    {...getAvatarProps(mediaItem)}
  />
);

PreviewIcon.defaultProps = {
  size: 64,
};

export default PreviewIcon;
