import React from 'react';
import styled from '@emotion/styled';
import { ResponsiveContainer, PieChart, Pie, Legend, Label } from 'recharts';
import numeral from 'numeral';
import { toPairs } from 'lodash';
import { Tooltip } from 'antd';
import { sumDays, Day, Event } from '../utils';
import { getColor } from './colors';

const truncate = (str: string) =>
  str.length > 25 ? `${str.substring(0, 25).trim()}...` : str;

const LegendItemName = styled.span`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
`;

const LegendItemPercent = styled.span`
  display: inline-block;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  padding-left: 8px;
`;

const LegendItemValue = styled.span`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  position: absolute;
  text-align: right;
  right: 0;
`;

const Footer = styled.div`
  border-top: 1px solid rgb(232, 232, 232);
  display: flex;
  flex-wrap: wrap;
  padding: 8px 0;
  margin: 30px 0 0;
  justify-content: center;
`;

const FooterItems = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 8px;
  justify-content: center;
`;

const FooterItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FooterImg = styled.img`
  height: 24px;
`;

const ItemsCount = styled.p`
  padding-top: 10px;
`;

const isValidImageUrl = (url: string) => {
  const pattern = /^(http|https):\/\/.*\.(jpg|jpeg|png|gif|svg)$/i;
  return pattern.test(url);
};

const legendFormatter = (name: string, entry: any) => {
  const {
    payload: { percent, value },
  } = entry;

  return (
    <>
      <Tooltip placement="top" title={name}>
        <LegendItemName>{truncate(name)}</LegendItemName>
      </Tooltip>
      <LegendItemPercent>{`${Math.round(percent * 10000) / 100}%`}</LegendItemPercent>
      <LegendItemValue>{value}</LegendItemValue>
    </>
  );
};

export const renderFooterItems = (data: Day[]) => {
  return (
    <>
      {data.map(([title, items]) => (
        <Tooltip placement="top" title={title} key={title}>
          <FooterItems>
            {items.map((item) => (
              <FooterItem key={title}>
                {item.label && isValidImageUrl(item.label) ? (
                  <FooterImg src={item.label} alt={title} />
                ) : (
                  <p>{title}</p>
                )}
                <ItemsCount>{item.value.toLocaleString()}</ItemsCount>
              </FooterItem>
            ))}
          </FooterItems>
        </Tooltip>
      ))}
    </>
  );
};

export default function PieCard({
  title,
  data,
  showTotal = true,
  total,
  footerData,
}: {
  title: string;
  data: Day[];
  showTotal?: boolean;
  total?: number;
  footerData?: Day[];
}) {
  const pieChartData = toPairs(
    data.reduce((acc: any, [, events]) => {
      events.forEach(({ label, value, color }: Event) => {
        if (label) {
          acc[label] = {
            value: (acc[label] ? acc[label].value : 0) + value,
            color: color,
          };
        }
      });

      return acc;
    }, {}),
  )
    .sort(([, a]: any, [, b]: any) => b.value - a.value)
    .map(([name, { value, color }]: any, i, arr) => ({
      name,
      value,
      fill: color ? color : getColor(i, arr.length),
    }));

  const totalTitle = `Total ${title.toLowerCase()}`;
  const totalValue =
    typeof total === 'undefined' ? numeral(sumDays(data)).format('0,0') : total;

  return (
    <>
      <ResponsiveContainer height={224}>
        <PieChart>
          <Legend
            layout="vertical"
            align="right"
            verticalAlign="middle"
            wrapperStyle={{
              maxHeight: '100%',
              overflowY: 'auto',
              width: '50%',
              left: '50%',
            }}
            iconType="circle"
            iconSize={8}
            formatter={legendFormatter}
          />
          <Pie
            data={pieChartData}
            dataKey="value"
            nameKey="name"
            startAngle={450}
            endAngle={90}
            strokeWidth={1}
            innerRadius={showTotal ? 80 : 0}
            outerRadius={112}
          >
            {showTotal && [
              <Label
                key={totalTitle}
                value={totalTitle}
                offset={0}
                position="center"
                fontSize={14}
                fill="rgba(0,0,0,0.65)"
                transform="translate(0 -16)"
                width={140}
              />,
              <Label
                key={`${totalTitle}_value`}
                value={totalValue}
                offset={0}
                position="center"
                fontSize={24}
                fill="rgba(0,0,0,0.85)"
                transform="translate(0 16)"
              />,
            ]}
          </Pie>
        </PieChart>
      </ResponsiveContainer>

      {!!(footerData && footerData.length) && (
        <Footer>{renderFooterItems(footerData)}</Footer>
      )}
    </>
  );
}
