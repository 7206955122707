import React, { useEffect, useMemo } from 'react';
import { Col } from 'antd';
import { FormApi } from 'final-form';
import { useTranslation } from 'react-i18next';
import { Input, Select, SelectProps } from '../../../../../common/react-final-form';
import { getOperatorOptions } from '../../../../../../utils/alerts';
import { requiredNumber } from '../../../queue-details/queue-setup/validate-form';

const DeviceAnalyticsSessionsCountConditionFields = (props: {
  parentFieldName: string;
  updateForm: FormApi['change'];
}) => {
  const { parentFieldName, updateForm } = props;

  const { t } = useTranslation();

  const operatorOptions = useMemo<SelectProps['options']>(() => {
    return getOperatorOptions(t).map((operator) => ({
      value: operator.id as string,
      label: operator.label,
    }));
  }, [t]);

  // unset fields from parent when the component unmounts to avoid having un-necessary fields on form submission
  useEffect(() => {
    return () => {
      updateForm(`${parentFieldName}.operator`, undefined);
      updateForm(`${parentFieldName}.threshold`, undefined);
    };
  }, [parentFieldName, updateForm]);

  return (
    <>
      <Col span={12}>
        <Select
          name={`${parentFieldName}.operator`}
          label={t('alertRules.operator')}
          isRequired={true}
          options={operatorOptions}
          isSearchEnabled={false}
        />
      </Col>

      <Col span={12}>
        <Input
          name={`${parentFieldName}.threshold`}
          label={t('alertRules.count')}
          validators={[requiredNumber]}
          type="number"
          min={0}
        />
      </Col>
    </>
  );
};

export default DeviceAnalyticsSessionsCountConditionFields;
