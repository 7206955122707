import { useCallback, useMemo, useState } from 'react';

interface UseSearchTermResult {
  term: string;
  onChangeTerm: (term: string) => void;
}

const useSearchTerm = (): UseSearchTermResult => {
  const [term, setTerm] = useState('');

  const onChangeTerm = useCallback((newTerm: string) => setTerm(newTerm), []);

  const result = useMemo(() => ({ term, onChangeTerm }), [term, onChangeTerm]);

  return result;
};

export default useSearchTerm;
