import React, { useState, useMemo } from 'react';
import { uniqBy } from 'lodash';
import { FieldProps } from 'react-jsonschema-form';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import MediaPicker from './media-picker.component';
import { SchemaFormContext } from '../../schema-form.component';
import { useStoreState } from '../../../../../store/initialize-store';
import useOrganisationMedia from '../../../use-organisation-media';
import Spinner from '../../../spinner/spinner.component';

const ErrorMessage = styled.div`
  padding: 15px 0;
  color: rgb(255, 85, 0);
`;

interface MediaPickerContainerProps extends FieldProps {
  formContext: SchemaFormContext;
}

const MediaPickerContainer = (props: MediaPickerContainerProps) => {
  const { t } = useTranslation();

  const {
    formContext: { organisationId = '' },
  } = props;

  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);

  const { ancestors, mediaItems } = useStoreState((state) => ({
    mediaItems: state.organisationMedia.values,
    ancestors: state.organisationMedia.ancestors || [],
  }));

  const media = useOrganisationMedia(organisationId);

  const onBackClick = (currentFolderId: string | null) => {
    if (currentFolderId && ancestors && ancestors.length) {
      const parentFolderId = ancestors.length > 1 ? ancestors.slice(-2)[0].id : null;

      setSelectedFolderId(parentFolderId);
    }
  };

  const mediaItemsCombined = useMemo(() => {
    if (media.isSuccess) {
      return uniqBy([...mediaItems, ...media.data.files], 'id');
    }

    return [];
  }, [mediaItems, media]);

  return (
    <>
      {media.isLoading && <Spinner />}

      {media.isSuccess && (
        <MediaPicker
          {...props}
          mediaItems={mediaItemsCombined}
          onFolderSelect={setSelectedFolderId}
          selectedFolderId={selectedFolderId}
          onBackClick={onBackClick}
        />
      )}

      {media.isError && <ErrorMessage>{t('errorDuringFetchingMedia')}</ErrorMessage>}
    </>
  );
};

export default MediaPickerContainer;
