import { Field } from 'react-final-form';
import React from 'react';
import identity from 'lodash/identity';
import { Button } from 'antd';
import styled from '@emotion/styled';
import { FieldArray } from 'react-final-form-arrays';
import { FormTextInput, Label, PanelCardMain } from './styled-block';

interface EditableListOfInputsProps {
  title: string;
  keyFromValues: string;
  getFieldName: (key: string) => string;
  getInitialValue: () => any;
}

export default function EditableListOfInputs(props: EditableListOfInputsProps) {
  const { title, keyFromValues, getFieldName, getInitialValue = identity } = props;

  return (
    <>
      <Label>{title}</Label>
      <PanelCardMain>
        <FieldArray key={keyFromValues} name={keyFromValues}>
          {({ fields }) => (
            <>
              {(fields || []).map((keyFromInput, index) => (
                <TagContainer key={`${getFieldName(keyFromInput)}`}>
                  <Field name={getFieldName(keyFromInput)}>
                    {({ input: filedInput, meta }) => {
                      return <FormTextInput input={filedInput} meta={meta} />;
                    }}
                  </Field>
                  <ChangeItem onClick={() => fields.remove(index)} icon="minus" />
                </TagContainer>
              ))}
              <ChangeItem onClick={() => fields.push(getInitialValue())} icon="plus" />
            </>
          )}
        </FieldArray>
      </PanelCardMain>
    </>
  );
}

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ChangeItem = styled(Button)`` as any;
