import React from 'react';
import styled from '@emotion/styled';
import { Tag } from 'antd';

interface TitleWithTagProps {
  title?: string;
  titleSize?: number;
  tagText?: string;
  tagSize?: number;
  tagColor?: string;
}

const TitleWithTag = (props: TitleWithTagProps) => {
  const { title, titleSize, tagText, tagSize, tagColor } = props;
  return (
    <Title>
      {title && (
        <TitleGrid>
          <TitleText titleSize={titleSize}>{title}</TitleText>
        </TitleGrid>
      )}
      {tagText && (
        <TitleTag color={tagColor || '#202020'} tagsize={tagSize}>
          {tagText}
        </TitleTag>
      )}
    </Title>
  );
};

const Title = styled.div`
  display: inline;

  > span {
    display: inline-block;
    vertical-align: middle;
  }
`;

const TitleTag = styled(Tag)<{ tagsize?: number }>`
  font-size: ${({ tagsize }) => tagsize || 12}px;
  font-weight: 300;
  padding: 2px 8px;
  margin-top: -2px;

  margin-left: -145px;

  @media screen and (max-width: 500px) {
    display: block !important;
    width: fit-content;
    margin-left: 0px;
  }
`;

const TitleGrid = styled.div`
  display: contents;
`;

const TitleText = styled.span<{ titleSize?: number }>`
  margin-right: 155px;

  font-size: ${({ titleSize }) => titleSize || 16}px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: 500px) {
    margin-right: 0px;
  }
`;

export default TitleWithTag;
