import React, { useCallback, useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import AceEditor, { AceEditorProps } from 'react-ace';
import { Alert, Tooltip, Typography } from 'antd';
import { Container, Icon } from '.';
import { HelpCircle, Info } from 'react-feather';
import trim from 'lodash/trim';

const { Title: AntdTitle } = Typography;

interface EditorProps extends AceEditorProps {
  className?: string;
  isBordered?: boolean;
  isReadonly?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  rawErrors?: string[];
  title?: string;
  defaultValue?: string;
  description?: string;
  helpText?: string;
}

const Editor = (props: EditorProps) => {
  const {
    mode,
    theme = 'github',
    height = '300px',
    width = '100%',
    debounceChangePeriod = 1000,
    className,
    isBordered = true,
    isReadonly,
    isRequired,
    isDisabled,
    rawErrors = [],
    title,
    defaultValue,
    description,
    helpText,
    onChange = () => {},
    onBlur = () => {},
  } = props;

  const refCss = useRef<string>(defaultValue || '');

  const errors = useMemo(() => {
    return rawErrors
      .map((err) => trim(err, '.'))
      .join(', ')
      .toLowerCase();
  }, [rawErrors]);

  const handleChange = useCallback(
    (cssValue: string) => {
      refCss.current = cssValue;
      onChange(cssValue);
    },
    [onChange],
  );

  const handleBlur = useCallback(
    () => {
        onBlur(refCss.current);
    },
    [onBlur],
  );

  return (
    <EditorContainer
      className={className}
      $isBordered={isBordered}
      $isDisabled={isDisabled}
    >
      <InputWrap isBottomPadded={!description} isBordered={isBordered}>
        <TitleWrap>
          {title && <Title>{`${title}${isRequired ? ' *' : ''}`}</Title>}
          {helpText && (
            <div>
              <Tooltip
                placement="left"
                title={<div dangerouslySetInnerHTML={{ __html: helpText }} />}
              >
                <Icon size={18} component={() => <HelpCircle />} />
              </Tooltip>
            </div>
          )}
        </TitleWrap>
        {errors && <ErrorAlert message={errors} type="error" showIcon />}
        <AceEditorContainer>
          <AceEditor
            defaultValue={refCss.current}
            value={refCss.current}
            height={height}
            width={width}
            mode={mode}
            theme={theme}
            onChange={handleChange}
            onBlur={handleBlur}
            readOnly={isReadonly}
            editorProps={{
              $blockScrolling: Infinity,
            }}
            style={{
              backgroundColor: '#fbfbfd',
            }}
            debounceChangePeriod={debounceChangePeriod}
          />
        </AceEditorContainer>
      </InputWrap>
      {description && (
        <DescriptionWrap isBordered={isBordered}>
          <Icon size={18} component={() => <Info />} />
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </DescriptionWrap>
      )}
    </EditorContainer>
  );
};

const EditorContainer = styled(Container)`
  padding-top: 0;
  background-color: #fbfbfd;
`;

const AceEditorContainer = styled.div`
  border-radius: 8px;
`;

const Title = styled(AntdTitle)`
  font-size: 14px !important;
  font-weight: 500;
  margin-bottom: 5px;
`;

const InputWrap = styled.div<{ isBottomPadded?: boolean; isBordered?: boolean }>`
  padding: ${({ isBottomPadded }) => (isBottomPadded ? '10px' : '10px 10px 5px')};
  ${({ isBordered }) => (!isBordered ? 'padding: 0 0 5px;' : '')}
`;

const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;

  .ant-typography {
    margin-bottom: 0;
  }
`;

const ErrorAlert = styled(Alert)`
  margin: 8px 0 5px;

  .ant-alert-message {
    display: inline-block;
  }
  .ant-alert-message::first-letter {
    text-transform: uppercase;
  }
`;

const DescriptionWrap = styled.div<{ isBordered?: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  ${({ isBordered }) =>
    isBordered
      ? `
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding: 5px 10px;
    `
      : `
      padding: 5px 0;
    `}
`;

const Description = styled.div`
  display: flex;
  align-items: center;
`;

export default React.memo(Editor);
