import React, { FC, memo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import Header from '../../common/app-layout/header/header.component';
import PhyhubDevicesSummary from './phyhub-devices-summary/phyhub-devices-summary.component';
import PhyhubDevicesSearchAndSort from './phyhub-devices-search-and-sort/phyhub-devices-search-and-sort.component';
import { SortCriteria, SortOrder } from '../../../store/types/sort';
import FilterItem, { ActiveFilter } from '../../../store/types/filters';
import { PhyhubDevice } from '../../../store/types/phyhub-devices';
import ErrorView from '../error-view/error-view.component';
import { PaginationCollection } from '../../../store/types/pagination';
import PhyhubDevicesList from './phyhub-devices-list/phyhub-devices-list.component';

interface PhyhubDevicesProps {
  isError: boolean;
  isDataLoading: boolean;
  titleKey?: string;
  filterOptions: FilterItem[];
  sortCriteriaOptions: SortCriteria[];
  paginationCollection: PaginationCollection<PhyhubDevice>;
  onCriteriaChange: (criteria: SortCriteria, order: SortOrder) => void;
  onFiltersChange: (filters: ActiveFilter[]) => void;
  onSearch: (phrase: string) => void;
}

const PhyhubDevices: FC<PhyhubDevicesProps> = (props) => {
  const {
    isError,
    isDataLoading,
    titleKey = 'devices',
    filterOptions,
    paginationCollection,
    sortCriteriaOptions,
  } = props;

  const { t } = useTranslation();

  return (
    <>
      <Header title={t(titleKey)} />
      <PhyhubDev>
        <PhyhubDevFiltersAndSort>
          <PhyhubDevicesSearchAndSort
            filterOptions={filterOptions}
            sortCriteriaOptions={sortCriteriaOptions}
            onCriteriaChange={props.onCriteriaChange}
            onFiltersChange={props.onFiltersChange}
            onSearch={props.onSearch}
          />
        </PhyhubDevFiltersAndSort>
        <PhyhubDevSummary>
          <PhyhubDevContainer>
            <PhyhubDevicesSummary />
          </PhyhubDevContainer>
        </PhyhubDevSummary>
        <PhyhubDevWrapper>
          <PhyhubDevContainer>
            {isError && <ErrorView position="relative" />}
            <PhyhubDevicesList
              isDataLoading={isDataLoading}
              paginationCollection={paginationCollection}
            />
          </PhyhubDevContainer>
        </PhyhubDevWrapper>
      </PhyhubDev>
    </>
  );
};

const PhyhubDev = styled.div`
  position: relative;
  width: 100%;
`;

// TODO: Reuse as a global container component
const PhyhubDevContainer = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1362px;
`;

const PhyhubDevSummary = styled.div`
  position: relative;
  margin: 0 0 40px;
`;

const PhyhubDevFiltersAndSort = styled.div`
  position: relative;
  margin: 0 0 40px;
`;

const PhyhubDevWrapper = styled.div`
  position: relative;
  margin: 0 0 40px;
`;

export default memo(PhyhubDevices);
