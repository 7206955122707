import React from 'react';
import styled from '@emotion/styled';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/lib/input/TextArea';
import { ChangeEvent } from '../../../../../types';

const SpaceFormNotesSection = () => {
  const { t } = useTranslation();

  return (
    <NotesContainer>
      <Field
        name="notes"
        render={({ input }) => (
          <>
            <label className="control-label" htmlFor={input.name}>
              {t('spaceForm.notes')}
            </label>
            <TextArea
              rows={3}
              defaultValue={input.value}
              onChange={(event) => {
                input.onChange({
                  target: {
                    value: event.target.value,
                  },
                } as unknown as ChangeEvent<string>);
              }}
            />
          </>
        )}
      />
    </NotesContainer>
  );
};

const NotesContainer = styled.div`
  margin-top: 10px;
`;

export default SpaceFormNotesSection;