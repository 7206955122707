import { action, Action, Select, select, thunk, Thunk } from 'easy-peasy';
import { ApiError } from '../../../../services/api/api-error';
import Injections from '../../../injections.interface';
import EnvVariables from '../../../types/env-variables';

export interface ClassicAppEnvVariablesModel {
  data: {
    [appId: string]: EnvVariables;
  };
  loading: {
    [appId: string]: boolean;
  };
  error: {
    [appId: string]: ApiError | null;
  };
  loaded: Select<ClassicAppEnvVariablesModel, (appId: string) => boolean>;
  setLoading: Action<ClassicAppEnvVariablesModel, { appId: string; loading: boolean }>;
  setError: Action<
    ClassicAppEnvVariablesModel,
    { appId: string; error: ApiError | null }
  >;
  setData: Action<ClassicAppEnvVariablesModel, { appId: string; data: EnvVariables }>;
  fetch: Thunk<ClassicAppEnvVariablesModel, { appId: string }, Injections>;
  update: Thunk<
    ClassicAppEnvVariablesModel,
    { appId: string; envVariables: EnvVariables },
    Injections
  >;
}

const appEnvVariablesModel: ClassicAppEnvVariablesModel = {
  data: {},
  loading: {},
  error: {},
  loaded: select((state) => (appId: string) =>
    !!state.data[appId] && !state.loading[appId],
  ),
  setData: action((state, { appId, data }) => {
    state.data[appId] = data;
  }),
  setLoading: action((state, { appId, loading }) => {
    state.loading[appId] = loading;
  }),
  setError: action((state, { appId, error }) => {
    state.error[appId] = error;
  }),
  fetch: thunk(async (actions, { appId }, { injections }) => {
    actions.setError({ appId, error: null });
    try {
      const data = await injections.apiService.get<EnvVariables>(
        `/api/apps/${appId}/variables`,
      );
      actions.setData({ appId, data });
    } catch (error) {
      actions.setError({ appId, error });
    } finally {
      actions.setLoading({ appId, loading: false });
    }
  }),
  update: thunk(async (actions, { appId, envVariables }, { injections }) => {
    const data = await injections.apiService.put<EnvVariables>(
      `/api/apps/${appId}/variables`,
      envVariables,
    );
    actions.setData({ appId, data });
  }),
};

export default appEnvVariablesModel;
