import React, { useCallback } from 'react';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { PaginationProps } from '../../../../../store/types/pagination';
import styled from '@emotion/styled';
import DataExportList from '../common/data-export-list.component';
import AnalyticsDataExport from '../../../../../store/types/analytics-data-export';
import { Icon } from '../../../../common/schema-form/common';
import { Typography } from 'antd';


const { Title: AntdTitle } = Typography;

interface DataExportOverviewComponentProps
  extends RouteComponentProps<{ organisationId: string }>,
    PaginationProps {
  reports: AnalyticsDataExport[];
  loaded: boolean;
  isLoading: boolean;
  isError: boolean;
  isFetching:boolean;
  totalDocs:number;
  fetchData: ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => void;
}

const DataExportOverviewComponent = (props: DataExportOverviewComponentProps) => {
  const {
    match: {
      params: { organisationId: organizationId },
    },
  } = props;

  const { reports, loaded, isLoading, isError, fetchData, isFetching, totalDocs } = props;

  const { t } = useTranslation();

  const fetchAnalyticsData = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) => {
      if (!fetchData) return;
      fetchData({
        page,
        pageSize,
      });
    },
    [fetchData],
  );

  return (
    <ExportContainer>
      {!!totalDocs && (
        <FileCountContainer>
          <StyledIcon type="file-zip" size={24} />
          <Title>
            {totalDocs} {t('dataExport.files')}
          </Title>
        </FileCountContainer>
      )}
      <DataExportList
        organizationId={organizationId}
        reports={reports}
        loaded={loaded}
        isLoading={isLoading}
        isFetching={isFetching}
        totalDocs={totalDocs}
        isError={isError}
        fetchData={fetchAnalyticsData}
      />
    </ExportContainer>
  );
};

const ExportContainer = styled.div`
  position: relative;
  flex: 1;
  padding: 40px 80px;

  @media screen and (max-width: 991px) {
    padding: 20px;
  }
`;

const FileCountContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
`;

const StyledIcon = styled(Icon)`
  color: black;
  margin-right: 10px;
`;

const Title = styled(AntdTitle)`
  display: flex;
  font-size: 18px !important;
  margin-bottom: 0px !important;
`;

export default DataExportOverviewComponent;
