import React from 'react';
import { RouteComponentProps } from 'react-router';
import { useActions, useStore } from 'easy-peasy';
import { RootModel, RootState } from '../../../../../../store/models/root.model';
import UniversalDeviceDetailsModules from './universal-device-details-modules.component';

interface UniversalDeviceDetailsModulesContainerProps
  extends RouteComponentProps<{ deviceUuid: string; organisationId: string }> {
  azureDeviceUuid: string;
}

const UniversalDeviceDetailsModulesContainer = (
  props: UniversalDeviceDetailsModulesContainerProps,
) => {
  const { azureDeviceUuid } = props;

  const {
    match: {
      params: { organisationId, deviceUuid },
    },
  } = props;

  const {
    azureDevice,
    azureDeviceLoaded,
    user,
    modules,
    deviceModules,
    universalDevice,
  } = useStore<RootState>(
    (state: RootState) => ({
      azureDevice: state.azureDeviceDetails.data[azureDeviceUuid] || null,
      azureDeviceLoaded: state.azureDeviceDetails.loaded(azureDeviceUuid),
      user: state.auth.user,
      modules: state.modules.values,
      deviceModules: state.deviceModules.data[azureDeviceUuid],
      universalDevice: state.universalDeviceDetails.data[deviceUuid] || null,
    }),
    [deviceUuid, organisationId],
  );

  const {
    fetchAzureDevice,
    fetchModules,
    fetchDeviceModules,
    updateDeviceModules,
  } = useActions<RootModel>((actions) => ({
    fetchAzureDevice: actions.azureDeviceDetails.fetch,
    fetchModules: actions.modules.fetch,
    fetchDeviceModules: actions.deviceModules.fetch,
    updateDeviceModules: async (modulesUpdateBody: any) => {
      await actions.deviceModules.update(modulesUpdateBody);
      await actions.universalDeviceDetails.fetch({ deviceUuid, silent: true });
    },
  }));

  return (
    <UniversalDeviceDetailsModules
      universalDevice={universalDevice}
      fetchAzureDevice={fetchAzureDevice}
      azureDeviceLoaded={azureDeviceLoaded}
      azureDevice={azureDevice}
      fetchModules={fetchModules}
      modules={modules}
      user={user}
      canManageModules
      fetchDeviceModules={fetchDeviceModules}
      updateDeviceModules={updateDeviceModules}
      deviceModules={deviceModules}
    />
  );
};

export default UniversalDeviceDetailsModulesContainer;
