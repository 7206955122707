import { useCallback } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import {
  PaginationCollectionForProductsFeeds,
  PaginationRequest,
  PaginationSearchParam,
} from '../../../../../../store/types/pagination';
import { ApiError } from '../../../../../../services/api/api-error';
import { useGridProductsFeedService } from '../../../../../common/grid-products-connector-service-provider';
import { DataResidencyEnum } from '../../../../../../store/types/organisation';
import { IntegrationSettings } from '../types';

export interface IntegrationListData
  extends Pick<IntegrationSettings, 'id' | 'name' | 'schedule' | 'status' | 'type'> {}

type UseIntegrationsResponse = UseQueryResult<
  PaginationCollectionForProductsFeeds<IntegrationListData>,
  ApiError
>;

const useIntegrations = ({
  tenantId,
  dataResidency,
  queryParams,
}: {
  tenantId: string;
  dataResidency: DataResidencyEnum;
  queryParams?: PaginationRequest;
}): UseIntegrationsResponse => {
  const gridProductsFeedService = useGridProductsFeedService(dataResidency);

  const { param = undefined, page = 1, limit = 20 } = queryParams || {};

  const params: PaginationRequest = {
    ...(param ? { param } : {}),
    page,
    limit,
  };

  const fetch = useCallback(async () => {
    const url = `/${tenantId}/integrations`;

    return gridProductsFeedService.get<
      PaginationCollectionForProductsFeeds<IntegrationListData>
    >(url, (params as unknown) as Record<string, string>);
  }, [gridProductsFeedService, params, tenantId]);

  const result = useQuery(getIntegrationsKeys(tenantId, params), () => fetch(), {
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    keepPreviousData: true,
    onError: (_error: ApiError) => {},
  });

  return result;
};

export const getIntegrationsKeys = (
  tenantId?: string,
  request?: PaginationRequest,
): (string | number | PaginationSearchParam)[] => {
  const { query = undefined, param = undefined, page = undefined, limit = undefined } =
    request || {};

  return [
    'get-integrations',
    ...(tenantId ? ['tenantId', tenantId] : []),
    ...(query ? ['query', query] : []),
    ...(param ? ['param', param] : []),
    ...(page ? ['page', page] : []),
    ...(limit ? ['limit', limit] : []),
  ];
};

export default useIntegrations;
