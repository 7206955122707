import React, { useMemo, useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from '@emotion/styled';
import { Row, Col, Button, Modal, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import OrganisationApp from '../../../../../../store/types/organisation-app';
import ConnectDeviceUniversalContainer from '../../../../../common/universal-devices/connect-device-universal/connect-device-universal.container';
import SetupDeviceUniversalContainer from '../setup-device-universal/setup-device-universal.container';
import transientOptions from '../../../../../../utils/transient-emotion-styled-options';

const { TabPane } = Tabs;

interface ConnectDeviceUniversal
  extends RouteComponentProps<{ appId: string; organisationId: string }> {
  app: OrganisationApp | null;
  onClose: () => void;
  isVisible: boolean;
}

const formModalBodyStyle = {
  backgroundColor: '#F5F5FA',
  borderRadius: '16px',
  maxHeight: '540px',
  minHeight: '540px',
  height: '532px',
  position: 'relative' as any,
  padding: '0px',
};

enum FormMode {
  CONNECT = 'connect_mode',
  SETUP = 'setup_mode',
}

export default function ConnectDeviceUniversal({
  isVisible,
  onClose,
  app,
  ...props
}: ConnectDeviceUniversal) {
  const { t } = useTranslation();
  const [didComponentVisiblyMount, setDidComponentVisiblyMount] = useState(false);
  const [isConnectMode, setIsConnectMode] = useState(true);

  // Component mount optimization
  useEffect(() => {
    if (!isVisible) {
      setIsConnectMode(true);
    }

    setDidComponentVisiblyMount(isVisible);
  }, [isVisible]);

  const titleComponent = useMemo(
    () =>
      didComponentVisiblyMount && (
        <>
          <Title>{`${isConnectMode ? t('connectDevice') : t('setupNewDevice')} → ${app &&
            app.displayName}`}</Title>
          <ActionsRow $isFlexEnd={isConnectMode}>
            {isConnectMode ? (
              <>
                <SetupNewDeviceCol lg={12} xs={8}>
                  <Button
                    size="large"
                    type="primary"
                    icon="plus"
                    onClick={() => setIsConnectMode(false)}
                    data-testid="connect-setup-device-universal-setup-new-device-button"
                  >
                    {t('setupNewDevice')}
                  </Button>
                </SetupNewDeviceCol>
              </>
            ) : (
              <DevicesListButton
                size="small"
                icon="left"
                type="secondary"
                onClick={() => setIsConnectMode(true)}
              >
                {t('backToDeviceList')}
              </DevicesListButton>
            )}
          </ActionsRow>
        </>
      ),
    [app, didComponentVisiblyMount, isConnectMode, t],
  );

  return (
    <FormModal
      footer={null}
      destroyOnClose
      title={titleComponent}
      visible={isVisible}
      onCancel={onClose}
      bodyStyle={formModalBodyStyle}
      centered
    >
      {isVisible && didComponentVisiblyMount && (
        <CustomStyledTabs
          animated={false}
          activeKey={isConnectMode ? FormMode.CONNECT : FormMode.SETUP}
          tabBarStyle={{ display: 'none' }}
          isScrollable={isConnectMode}
        >
          <TabPane tab="connect" key={FormMode.CONNECT}>
            <ConnectDeviceUniversalContainer {...props} onClose={onClose} />
          </TabPane>
          <TabPane tab="setup" key={FormMode.SETUP}>
            <SetupDeviceUniversalContainer {...props} onClose={onClose} />
          </TabPane>
        </CustomStyledTabs>
      )}
    </FormModal>
  );
}

const CustomStyledTabs = styled(Tabs)<{ isScrollable: boolean }>`
  height: 100%;
  ${({ isScrollable }) => !!isScrollable && 'overflow-y: scroll;'}
`;

const FormModal = styled(Modal)`
  border-radius: 16px;
  padding: 0px;
  overflow: hidden;

  .ant-modal-close-x {
    padding: 12px 4px 0px 0px;
  }

  @media (max-width: 940px) {
    min-width: auto;
  }

  @media (min-width: 940px) {
    min-width: 940px;
  }
`;

const ActionsRow = styled(Row, transientOptions)<{ $isFlexEnd: boolean }>`
  display: flex;
  padding: 4px 0px;
  ${({ $isFlexEnd }) => ($isFlexEnd ? 'justify-content: flex-end;' : '')}
`;

const Title = styled(Row)`
  font-size: 24px;
  padding: 8px 24px 8px 0px;
  line-height: 28px;
`;

const DevicesListButton = styled(Button)`
  padding-left: 0px;
  border: none;
  background: transparent;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
` as any;

const SetupNewDeviceCol = styled(Col)`
  display: flex;
  flex: 0;
  padding-left: 32px;
  width: auto;
`;
