import CronTime from 'cron-time-generator';
import CronParser from 'cron-parser';
import { CronParseExpressionResult, CronParseFields, ScheduleFrequencyEnum } from './types';

export const generateCronExpression = (frequency: ScheduleFrequencyEnum, time: string): string => {
  try {
    if (frequency !== ScheduleFrequencyEnum.EVERY_DAY || !time) {
      return '';
    }

    const [hour, minute] = time.split(':');

    if (Number.isNaN(Number(hour)) || Number.isNaN(Number(minute))) {
      return '';
    }

    return CronTime.everyDayAt(Number(hour), Number(minute));
  } catch {
    return '';
  }
};

export const parseCronExpression = (
  value: string,
): CronParseExpressionResult | undefined => {
  try {
    if (!value) {
      return undefined;
    }

    const cronExpression = CronParser.parseExpression(value);

    const { _fields } = JSON.parse(JSON.stringify(cronExpression));

    const { minute, hour, dayOfWeek } = (_fields as unknown) as CronParseFields;

    return {
      frequency: dayOfWeek.length === 8 ? ScheduleFrequencyEnum.EVERY_DAY : null,
      minute: minute[0],
      hour: hour[0],
      days: dayOfWeek as number[],
    };
  } catch {
    return undefined;
  }
};
