import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Tooltip } from 'antd';
import { get, isEqual } from 'lodash';
import { ErrorSchema } from 'react-jsonschema-form';
import { replace } from 'lodash';
import { InstallationSettingsLevel } from '../../../../store/types/organisation-app';
import { SchemaFormContext } from '../schema-form.component';
import SchemaFieldTitleTooltip from './schema-field-title-tooltip.component';
import { GlobalIcon, SpaceIcon, DeviceIcon } from '../../../common/settings-overriding/settings-overriding-icons.component';

type SchemaFieldCustomTitle = {
  schema: Object;
  uiSchema: Object;
  formData: any;
  idSchema: any;
  onChange: (e: any, es?: ErrorSchema | undefined) => any;
  formContext: SchemaFormContext;
}

const settingsOverridingIconFieldTypes = ['string', 'number', 'boolean', 'array'];

const isValidSettingsOverrideField = (uiSchema: any) => {
  return Object.keys(uiSchema || {}).some(key => ['ui:field', 'ui:widget'].includes(key));
}

const getSettingsOverridingInfo = (props: SchemaFieldCustomTitle) => {
  const fieldSettingsPath = replace((props.idSchema.$id as any || ''), 'root_', '');
  const formContext = props.formContext || {};
  const showSettingsOverridingIndicator = formContext.showSettingsOverridingIndicator;
  const globalSettingsContext = formContext.globalSettingsContext;
  const spaceSettingsContext = formContext.spaceSettingsContext;
  const settingsLevel = formContext.settingsLevel;
  const { title = '', type: fieldType } = props.schema as any;

  return {
    onChange: props.onChange,
    formData: props.formData,
    fieldSettingsPath,
    showSettingsOverridingIndicator,
    globalSettingsContext,
    spaceSettingsContext,
    settingsLevel,
    title,
    fieldType,
  }
}

const SchemaFieldCustomTitle = React.memo((props: SchemaFieldCustomTitle): any => {  
  const {
    onChange,
    formData,
    fieldSettingsPath,
    showSettingsOverridingIndicator,
    globalSettingsContext,
    spaceSettingsContext,
    settingsLevel,
    title,
    fieldType,
  } = useMemo(() => getSettingsOverridingInfo(props), [props]);

  const icon = useMemo(() => {
    if (!showSettingsOverridingIndicator || settingsLevel === InstallationSettingsLevel.GLOBAL) {
      return null;
    }

    let icon: React.ReactElement | null = null;

    const globalIcon = <GlobalIcon />;
    const spaceIcon = <SpaceIcon />;
    const deviceIcon = <DeviceIcon />;

    const arrFieldSettingsPath = fieldSettingsPath.split('_');
    const globalSettingsFieldValue = get(globalSettingsContext, arrFieldSettingsPath);
    const spaceSettingsFieldValue = get(spaceSettingsContext || {}, arrFieldSettingsPath);

    let globalValue = null;
    let spaceValue = null;
    let onSpaceValueReset;
    let onGlobalValueReset;
    let isGlobalIcon = false;

    if (settingsOverridingIconFieldTypes.includes(fieldType)
      || isValidSettingsOverrideField(props.uiSchema)
    ) {
      if (settingsLevel === InstallationSettingsLevel.SPACE) {
        if (
          globalSettingsFieldValue !== undefined
          && isEqual(formData, globalSettingsFieldValue)
        ) {
          icon = globalIcon;
          isGlobalIcon = true;
        } else {
          icon = spaceIcon;
          globalValue = globalSettingsFieldValue;
          onGlobalValueReset = () => onChange(globalSettingsFieldValue);
        }
      } else if (settingsLevel === InstallationSettingsLevel.DEVICE) {
        const isCurrentGlobalValue = [globalSettingsFieldValue, spaceSettingsFieldValue]
          .every(s => isEqual(s, formData));
      
        if (isCurrentGlobalValue) {
          icon = globalIcon;
          isGlobalIcon = true;
        } else if (
          spaceSettingsFieldValue !== undefined
          && isEqual(formData, spaceSettingsFieldValue)
        ) {
          icon = spaceIcon;
          globalValue = globalSettingsFieldValue;
        } else {
          icon = deviceIcon;
          onSpaceValueReset = () => onChange(spaceSettingsFieldValue);
          spaceValue = spaceSettingsFieldValue;
          globalValue = globalSettingsFieldValue;
        }
      }
    }

    if (icon) {
      if (isGlobalIcon) {
        return <IconWrapper>{icon}</IconWrapper>;
      }

      return (
        <Tooltip
          title={
            <SchemaFieldTitleTooltip
              spaceValue={spaceValue}
              globalValue={globalValue}
              onGlobalValueReset={onGlobalValueReset}
              onSpaceValueReset={onSpaceValueReset}
            />
          }
          overlayStyle={{ width: "fit-content", maxWidth: "fit-content" }}
        >
          <IconWrapper>{icon}</IconWrapper>
        </Tooltip>
      )
    }
    return icon;
  }, [
    fieldType,
    formData,
    fieldSettingsPath,
    globalSettingsContext,
    spaceSettingsContext,
    onChange,
    settingsLevel,
    showSettingsOverridingIndicator,
    props.uiSchema,
  ]);

  if (!title && !settingsOverridingIconFieldTypes.includes(fieldType)) {
    return title;
  }

  return (
    <SettingsOverridingTitle>
      {icon}
      {title ? <span>{title}</span> : null}
    </SettingsOverridingTitle>
  );
});

const SettingsOverridingTitle = styled.div`
  display: inherit !important;
  width: auto !important;
  min-width: auto !important;
  text-align: center !important;
  display: inline-flex !important;
  align-items: center;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export default React.memo(SchemaFieldCustomTitle);
