import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import {
  UseStoreAiKnowledgeBaseFileListProps,
  getStoreAiKnowledgeBaseFileListKeys,
} from './use-store-ai-knowledge-base-file-list';

type UseStoreAiKnowledgeBaseFileListInvalidateQueryResponse = ({
  tenantId,
  page,
  limit,
}: Partial<UseStoreAiKnowledgeBaseFileListProps>) => Promise<void>;

const useStoreAiKnowledgeBaseFileListInvalidateQuery = (): UseStoreAiKnowledgeBaseFileListInvalidateQueryResponse => {
  const queryClient = useQueryClient();

  const invalidateQuery = useCallback(
    async (args: Partial<UseStoreAiKnowledgeBaseFileListProps>) => {
      const queryKeys = getStoreAiKnowledgeBaseFileListKeys(args);

      await queryClient.invalidateQueries(queryKeys);
    },
    [queryClient],
  );

  return invalidateQuery;
};

export default useStoreAiKnowledgeBaseFileListInvalidateQuery;
