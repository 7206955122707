import React from 'react';
import { Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ContentTagInputMultiSelect } from '../../../../../store/hooks/content-tags/types';
import { ArrayFields, Input, validateCharacterLength } from '../../../../common/react-final-form';
import { requiredAll } from '../../queue-details/queue-setup/validate-form';
import styled from '@emotion/styled';

interface TagFormMultiSelectSectionProps {}

const TagFormMultiSelectSection = (props: TagFormMultiSelectSectionProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography.Title level={4}>{t('contents.tagValues')}</Typography.Title>

      <TagValueContainer>
        <ArrayFields<ContentTagInputMultiSelect>
          propertyName="values"
          defaultValue={['']}
          itemRowContent={(parentPropertyName) => {
            return (
              <TagValue>
                <Input
                  name={`${parentPropertyName}`}
                  validators={[requiredAll, validateCharacterLength(3, 50)]}
                />
              </TagValue>
            );
          }}
        />
      </TagValueContainer>
    </>
  );
};

export default TagFormMultiSelectSection;

const TagValueContainer = styled.div`
  margin-bottom: 15px;
  width: 100% !important;

  .array-fields-delete-btn-container {
    align-self: start;
    margin-top: 32px;
  }
`;

const TagValue = styled(Row)`
  width: 100% !important;
  margin-top: 20px;
`;
