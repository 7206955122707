import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import { Divider, TimePicker, Row, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import styled from '@emotion/styled';
import { DailyBusinessHours } from '../../../../../store/types/organisation-space';
import { ChangeEvent } from '../../../../../types';
import { prepareWeeklyTimeFieldNameForFormField } from './utils';
import { OnChange } from 'react-final-form-listeners';

interface SpaceFormScheduleSectionProps {
}

interface WeeklyBusinessHours {
  1: DailyBusinessHours; // Monday
  2: DailyBusinessHours; // Tuesday
  3: DailyBusinessHours; // Wednesday
  4: DailyBusinessHours; // Thursday
  5: DailyBusinessHours; // Friday
  6: DailyBusinessHours; // Saturday
  0: DailyBusinessHours; // Sunday
}

const TIME_FORMAT_12_HOURS = 'hh:mm A';
const TIME_FORMAT_24_HOURS = 'HH:mm';

export const DEFAULT_OPENING_TIME = '08:00';
export const DEFAULT_CLOSING_TIME = '17:30';

const DAYS_ORDER = ['1', '2', '3', '4', '5', '6', '0'];

const SpaceFormScheduleSection = (props: SpaceFormScheduleSectionProps) => {
  const { t } = useTranslation();

  const DAYS = useMemo(() => {
    return [
      t('sunday'),
      t('monday'),
      t('tuesday'),
      t('wednesday'),
      t('thursday'),
      t('friday'),
      t('saturday'),
    ];
  }, [t]);

  return (
    <>
      <WeeklyScheduleContainer>
        <TimeControlsContainer>
          <TimeLabel>
            <span>{t('weeklySchedule.normalOpeningHours')}</span>
          </TimeLabel>

          <TimeControls>
            <TimeControl>
              <label>From</label>
              <Field
                name="openingHours.from"
                render={({ input, meta }) => (
                  <TimePicker
                    allowClear={false}
                    use12Hours={true}
                    format={TIME_FORMAT_12_HOURS}
                    defaultValue={moment(
                      DEFAULT_OPENING_TIME,
                      TIME_FORMAT_12_HOURS,
                    )}
                    onChange={(time) => {
                      if (time) {
                        input.onChange({
                          target: {
                            value: time,
                          }
                        } as unknown as ChangeEvent<moment.Moment>)
                      }
                    }}
                  />
                )}
              />
            </TimeControl>

            <TimeControl>
              <label>To</label>
              <Field
                name="openingHours.to"
                render={({ input, meta }) => (
                  <TimePicker
                    allowClear={false}
                    use12Hours={true}
                    format={TIME_FORMAT_12_HOURS}
                    defaultValue={moment(
                      DEFAULT_CLOSING_TIME,
                      TIME_FORMAT_12_HOURS,
                    )}
                    onChange={(time) => {
                      if (time) {
                        input.onChange({
                          target: {
                            value: time,
                          }
                        } as unknown as ChangeEvent<moment.Moment>)
                      }
                    }}
                  />
                )}
              />
            </TimeControl>
          </TimeControls>

        </TimeControlsContainer>

        <Divider />

        {DAYS_ORDER.map((day) => {
          return (
            <Row key={day}>
              <TimeControlsContainer>
                <TimeLabel>
                  <Field
                    name={`weeklySchedule.weeklyTime.${prepareWeeklyTimeFieldNameForFormField(
                      day,
                    )}.isOpen`}
                    render={({ input, meta }) => (
                      <Switch 
                        checked={!!input.value}
                        onChange={(value: boolean) => {
                          input.onChange({
                            target: {
                              value
                            }
                          } as unknown as ChangeEvent<boolean>)
                        }}
                      />
                    )}
                  />
                  <span>{DAYS[Number(day)]}</span>
                </TimeLabel>

                <TimeControls>
                  <TimeControl>
                    <label>From</label>
                    <Field
                      name={`weeklySchedule.weeklyTime.${prepareWeeklyTimeFieldNameForFormField(
                        day,
                      )}.from`}
                      render={({ input, meta }) => (
                        <>
                          <TimePicker
                            allowClear={false}
                            use12Hours={true}
                            format={TIME_FORMAT_12_HOURS}
                            value={moment(input.value, TIME_FORMAT_12_HOURS)}
                            onChange={(time) => {
                              if (time) {
                                input.onChange({
                                  target: {
                                    value: time.format(TIME_FORMAT_24_HOURS),
                                  },
                                } as unknown as ChangeEvent<string>);
                              }
                            }}
                          />
                          <OnChange name='openingHours.from'>
                            {(time: moment.Moment) => {
                              if (time) {
                                input.onChange({
                                  target: {
                                    value: time.format(TIME_FORMAT_24_HOURS)
                                  }
                                } as unknown as ChangeEvent<string>)
                              }
                            }}
                          </OnChange>
                        </>
                      )}
                    />
                  </TimeControl>

                  <TimeControl>
                    <label>To</label>
                    <Field
                      name={`weeklySchedule.weeklyTime.${prepareWeeklyTimeFieldNameForFormField(
                        day,
                      )}.to`}
                      render={({ input, meta }) => (
                        <>
                          <TimePicker
                            allowClear={false}
                            use12Hours={true}
                            format={TIME_FORMAT_12_HOURS}
                            value={moment(input.value, TIME_FORMAT_12_HOURS)}
                            onChange={(time) => {
                              if (time) {
                                input.onChange({
                                  target: {
                                    value: time.format(TIME_FORMAT_24_HOURS),
                                  },
                                } as unknown as ChangeEvent<string>);
                              }
                            }}
                          />
                          <OnChange name='openingHours.to'>
                            {(time: moment.Moment) => {
                              if (time) {
                                input.onChange({
                                  target: {
                                    value: time.format(TIME_FORMAT_24_HOURS)
                                  }
                                } as unknown as ChangeEvent<string>)
                              }
                            }}
                          </OnChange>
                        </>
                      )}
                    />
                  </TimeControl>
                </TimeControls>
              </TimeControlsContainer>

              <SingleWeeklyScheduleDivider />
            </Row>
          );
        })}
      </WeeklyScheduleContainer>
    </>
  );
};

export default SpaceFormScheduleSection;

const WeeklyScheduleContainer = styled.div`
  margin-top: 50px;
`;

const TimeControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const TimeLabel = styled.div`
  display: flex;
  gap: 20px;
`;

const TimeControls = styled.div`
  display: flex;
  gap: 15px;
`;

const TimeControl = styled.div`
  display: flex;
  flex-direction: column;
`;

const SingleWeeklyScheduleDivider = styled(Divider)`
  margin: 10px;
`;
