import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryObserverResult } from 'react-query';
import { GridStyles } from '@ombori/grid-reports';
import groupBy from 'lodash/groupBy';
import flatMap from 'lodash/flatMap';
import Table, { TableCell, TableHeader, TableRow, useTablePagination } from './table';
import CardContainer from '../card-container';
import { RawData } from './types';
import buildData from './build-data';

const aggregateDataByMediaTag = (rawData: RawData[]) => {
  const newDate = flatMap(rawData, (dataItem) => {
    const result = dataItem.mediaTags.map((tag) => ({
      ...dataItem,
      mediaTag: tag,
    }));

    return result;
  });

  const aggregatedEvents = groupBy(newDate, (dataItem) => dataItem.mediaTag);

  const result = buildData(aggregatedEvents);

  return result;
};

interface ViewByTagProps {
  dataFetchingState: QueryObserverResult<RawData[], unknown>;
  title?: string;
  gridStyles?: GridStyles;
  isVisibleWithoutData?: boolean;
}

const ViewByTag: React.FC<ViewByTagProps> = ({
  dataFetchingState,
  title,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { t } = useTranslation();

  const data = useMemo(() => aggregateDataByMediaTag(dataFetchingState.data || []), [
    dataFetchingState,
  ]);

  const { currentPage, onChangePage, totalPages, getDataByPage } = useTablePagination(
    data.length,
  );

  const headers = [t('tag'), t('mediaPlaysCount'), t('mediaPlaysDuration')];

  const rows = useMemo(
    () =>
      getDataByPage({ data, currentPage, totalPages }).map((item, index) => {
        return (
          <TableRow key={index}>
            <TableCell>{item.rowName}</TableCell>

            <TableCell>{item.playsCount}</TableCell>

            <TableCell>{Math.round(item.playsDuration / 60000)}</TableCell>
          </TableRow>
        );
      }),
    [currentPage, data, getDataByPage, totalPages],
  );

  return (
    <CardContainer
      isLoading={dataFetchingState.isLoading}
      isSuccess={dataFetchingState.isSuccess}
      isError={dataFetchingState.isError}
      hasData={data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {dataFetchingState.isSuccess && (
        <Table
          header={<TableHeader columns={headers} />}
          rows={rows}
          currentPage={currentPage}
          total={data.length}
          onChangePage={onChangePage}
        />
      )}
    </CardContainer>
  );
};

export default ViewByTag;
