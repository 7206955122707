import { useQuery } from 'react-query';
import { useApiService } from '../../../app/common/api-service-provider';
import { PaginationCollection } from '../../types/pagination';
import Token from '../../types/token';
import accessTokensQueryKeys from './use-access-token-query-keys';

export interface AccessTokensListQueryInterface {
  page: number;
  limit: number;
  sortField: string;
  sortOrder: string;
  searchTerm: string;
}

const useAccessTokenList = (accessTokenListParams: AccessTokensListQueryInterface) => {
  const apiService = useApiService();
  const { page, limit, sortField, sortOrder, searchTerm } = accessTokenListParams;

  return useQuery(
    accessTokensQueryKeys.getAccessTokenListKeys(accessTokenListParams),
    () =>
      apiService.get<PaginationCollection<Token>>(`/api/tokens`, {
        page,
        limit,
        sortField,
        sortOrder,
        searchTerm
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    }
  );
};

export default useAccessTokenList;