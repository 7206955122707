import { useCallback } from 'react';
import { get as getFromObject } from 'lodash';
import { useStore } from 'easy-peasy';
import { RootModel } from '../../store/models/root.model';

export default function usePermissions(permissionGroupKey: string) {
  const { user, isSysAdmin } = useStore<RootModel>((state) => ({
    user: state.auth.user,
    isSysAdmin: state.auth.isSysAdmin,
  }));
  const isAllowed = useCallback(
    (permissionKey) => {
      let permissionPath = permissionGroupKey;

      // if sys admin or if sales user does not belong to the tenant
      if(user.isSysadmin || (user.isSales && !getFromObject(user.permissions, `${permissionGroupKey}`,false))){
        permissionPath = 'grid'
      }

      return user
        ? !!getFromObject(
            user.permissions,
            `${permissionPath}.${permissionKey}`,
          )
        : false;
    },
    [permissionGroupKey, user],
  );

  return { isAllowed, isSysAdmin };
}