import { ContentTagType } from "./content-tag-type";

export enum OrientationTagValue {
  PORTRAIT = 'portrait',
  LANDSCAPE = 'landscape',
}

interface IOrientationTag {
  type: ContentTagType.ORIENTATION,
  values:  OrientationTagValue[],
}

export const OrientationTag: IOrientationTag =  {
  type: ContentTagType.ORIENTATION,
  values: [
    OrientationTagValue.PORTRAIT,
    OrientationTagValue.LANDSCAPE,
  ]
}

export default OrientationTag;
