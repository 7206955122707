import React from 'react';
import get from 'lodash/get';
import keys from 'lodash/keys';
import { Field } from 'react-final-form';
import { FormTextInput } from './styled-block';

interface TextGroup {
  key: string;
  value: {
    label: string;
    description?: string;
  };
}

interface CustomizableTextsGroupProps {
  textsGroup: TextGroup;
  activeLanguage: string;
}

const CustomizableTextsGroup: React.FC<CustomizableTextsGroupProps> = ({
  textsGroup,
  activeLanguage,
}) => (
  <>
    {keys(textsGroup).map((key) => {
      const name = `textInAppCustomization.${activeLanguage}.${key}`;

      return (
        <Field name={name} key={name}>
          {({ input, meta }) => (
            <FormTextInput
              input={input}
              meta={meta}
              label={get(textsGroup, `${key}.label`)}
              description={get(textsGroup, `${key}.description`)}
            />
          )}
        </Field>
      );
    })}
  </>
);

export default CustomizableTextsGroup;
