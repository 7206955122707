import React, { useCallback, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { Report as ReportType, service, factories } from 'powerbi-client';
import AnalyticsEmbed from '../../../../store/types/analytics-embed';
import { getReportConfig } from './embedded-report-config';

interface ReportProps {
  embed: AnalyticsEmbed;
  onLoaded: () => void;
  className?: string;
}

const powerbiService = new service.Service(
  factories.hpmFactory,
  factories.wpmpFactory,
  factories.routerFactory,
);

const ReportWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const getPage = async (report: ReportType, displayName: string) => {
  const pages = await report.getPages();
  return pages.find((page) => page.displayName === displayName);
};

const Report = ({ embed, onLoaded, className }: ReportProps) => {
  const reportContainer = useRef<HTMLDivElement>(null);
  const report = useRef<ReportType | null>(null);
  const isMounted = useRef<boolean>(false);
  const isReady = useRef<boolean>(false);

  const setPage = useCallback(async (displayName: string) => {
    if (report.current && isMounted.current) {
      const page = await getPage(report.current, displayName);
      if (page && isMounted.current) {
        await report.current.setPage(page.name);
      }
    }
  }, []);

  useEffect(() => {
    // cleanup on unmount
    isMounted.current = true;
    const containerRef = reportContainer.current;

    return () => {
      isMounted.current = false;
      if (containerRef) {
        powerbiService.reset(containerRef);
      }
    };
  }, []);

  useEffect(() => {
    if (reportContainer.current && isMounted.current) {
      // @ts-ignore
      if (!report.current || report.current.config.id !== embed.id || !isReady.current) {
        isReady.current = false;
        powerbiService.reset(reportContainer.current);
        const embedConfiguration = getReportConfig(embed);
        if (report.current) {
          report.current.off('loaded');
        }
        report.current = powerbiService.embed(
          reportContainer.current,
          embedConfiguration,
        ) as ReportType;
        report.current.on('loaded', async () => {
          isReady.current = true;
          if (embed.page) {
            await setPage(embed.page);
          }
          onLoaded();
        });
      } else {
        report.current.setAccessToken(embed.token);
        report.current.setFilters(embed.filters || []);
        if (embed.page) {
          setPage(embed.page);
        }
      }
    }
  }, [embed, onLoaded, setPage]);

  return <ReportWrapper className={className} ref={reportContainer} />;
};

export default Report;
