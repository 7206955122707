import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { StyledComponent } from '@emotion/styled';
import { Alert, Tooltip, Collapse as AntdCollapse } from 'antd';
import { Container as FieldContainerWrap, Icon, Button, ButtonRow, Title } from '.';
import { ChevronUp, HelpCircle, Info } from 'react-feather';
import trim from 'lodash/trim';
import transientOptions from '../../../../utils/transient-emotion-styled-options';
import { CollapseProps } from 'antd/lib/collapse';

const PANEL_ID = 'panel';

interface FieldContainerProps {
  className?: string;
  type?: 'field' | 'container';
  isBordered?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  isExpandContent?: boolean;
  title?: string;
  subtitle?: string;
  helpText?: string;
  errors?: string[];
  description?: string;
  children?: React.ReactNode;
  expandableContent?: JSX.Element;
  onCloseExpandableContent?: () => void;
}

const FieldContainer = (props: FieldContainerProps) => {
  const {
    className,
    type = 'container',
    isBordered,
    isDisabled,
    isRequired,
    isExpandContent,
    title,
    helpText,
    subtitle,
    errors = [],
    description,
    children,
    expandableContent,
    onCloseExpandableContent = () => {},
  } = props;

  const { t } = useTranslation();

  const errorMessage = useMemo(() => {
    const uniqErrors = Array.from(new Set(errors));

    return uniqErrors
      .map((err) => trim(err, '.'))
      .join(', ')
      .toLowerCase();
  }, [errors]);

  const titleFontSize = type === 'field' ? 14 : undefined;

  return (
    <FieldContainerWrap className={className} $isBordered={isBordered} $isDisabled={isDisabled}>
      <SectionPadded $isBordered={isBordered}>
        <TitleWrap>
          {title && <Title size={titleFontSize}>{title}{isRequired ? ' *' : ''}</Title>}
          {helpText && (
            <div>
              <Tooltip
                placement="left"
                title={<div dangerouslySetInnerHTML={{ __html: helpText }} />}
              >
                <Icon size={18} component={() => <HelpCircle />} />
              </Tooltip>
            </div>
          )}
        </TitleWrap>
        {subtitle && <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />}
        {errorMessage && <ErrorAlert message={errorMessage} type="error" showIcon />}
        {children}
      </SectionPadded>
      <CollapseContainer $isExpanded={isExpandContent}>
        <Collapse
          activeKey={isExpandContent ? [PANEL_ID] : []}
          expandIconPosition="right"
          bordered={false}
        >
          <CollapsePanel key={PANEL_ID} header={null} showArrow={false} forceRender>
            {expandableContent}
            {isExpandContent && (
              <ButtonRow align="center">
                <Button type="secondary" onClick={onCloseExpandableContent}>
                  <Icon size={18} component={() => <ChevronUp />} />
                  {t('close')}
                </Button>
              </ButtonRow>
            )}
          </CollapsePanel>
        </Collapse>
      </CollapseContainer>
      {description && (
        <DescriptionWrap isBordered={isBordered}>
          <Icon size={18} component={() => <Info />} />
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </DescriptionWrap>
      )}
    </FieldContainerWrap>
  );
};

const SectionPadded = styled('div', transientOptions)<{ $isBordered?: boolean }>`
  ${({ $isBordered }) => $isBordered ? 'padding: 10px;' : ''}
`;

const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;

  .ant-typography {
    margin-bottom: 0;
  }
`;

const Subtitle = styled.div`
  margin: 3px 0;
`;

const ErrorAlert = styled(Alert)`
  margin: 8px 0;

  .ant-alert-message {
    display: inline-block;
  }
  .ant-alert-message::first-letter {
    text-transform: uppercase;
  }
`;

const CollapseContainer = styled('div', transientOptions)<{ $isExpanded?: boolean }>`
  & :has(.ant-collapse-item-active) {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }
`;

type StyledCollapseType = StyledComponent<
  CollapseProps & React.ClassAttributes<AntdCollapse>,
  Pick<CollapseProps & React.ClassAttributes<AntdCollapse>, keyof CollapseProps>,
  any
>;
const Collapse = styled(AntdCollapse)`
  border-top: 0;
  border-radius: inherit;
  background-color: inherit;
` as StyledCollapseType;

const CollapsePanel = styled(AntdCollapse.Panel)`
  border-radius: inherit !important;

  > .ant-collapse-header {
    display: none;
  }

  > .ant-collapse-content {
    border-radius: inherit !important;

    > .ant-collapse-content-box {
      border-radius: inherit;
    }
  }
`;

const DescriptionWrap = styled.div<{ isBordered?: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  ${({ isBordered }) =>
    isBordered
      ? `
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding: 5px 10px;
    `
      : `
      padding: 5px 0;
    `}
`;

export default FieldContainer;
