import React, { useMemo, useCallback } from 'react';
import styled from '@emotion/styled';
import { Menu, Dropdown, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { ButtonType } from '../../../../../../types';
import { permissionKeys } from '../../../../../../utils/auth/permissions';
import usePermissions from '../../../../../../utils/auth/use-permissions';
import useDeleteModal from '../../../../../common/use-delete-modal';
import { Icon } from '../../../../../common/schema-form/common';
import { GridAssistant } from '@ombori/grid-llm-react';
import { useStoreAIAssistantDelete } from '../../../../../../store/hooks/store-ai/use-store-ai-assistant-delete';

interface ActionComponentProps {
  assistant: GridAssistant;
}

const AssistantListItemActions = ({
  assistant,
}: ActionComponentProps) => {
  const { t } = useTranslation();

  const { mutateAsync: removeAssistant } = useStoreAIAssistantDelete();

  const { isAllowed } = usePermissions(assistant.tenantId);

  const { canDelete } = useMemo(() => {
    return {
      canDelete: isAllowed(permissionKeys.storeAIAssistants.remove),
    };
  }, [isAllowed]);

  const [showDeleteModal] = useDeleteModal();

  const handleDelete = useCallback(() => {
    showDeleteModal(
      t('storeAI.confirmDeleteAssistant'),
      t('storeAI.deleteAssistantInstruction', { assistantName: assistant.name }),
      assistant.name,
      async () => {
        try {
          await removeAssistant({ assistantId: assistant.id, tenantId: assistant.tenantId });
          message.success(`${t('storeAI.successfullyDeletedAssistant')}`);
        } catch {
          message.error(t('storeAI.failedToDeleteAssistant'));
        }
      },
    );
  }, [assistant, removeAssistant, showDeleteModal, t]);

  return (
    <Dropdown
      placement="bottomRight"
      overlay={
        <Menu>
          {canDelete && (
            <Menu.Item key={`${assistant.id}-remove`}>
              <RemoveButton
                type="link"
                icon="delete"
                size="small"
                value={assistant.id}
                onClick={handleDelete}
              >
                {t('delete')}
              </RemoveButton>
            </Menu.Item>
          )}
        </Menu>
      }
    >
      <StyledIcon type="small-dash" />
    </Dropdown>
  );
};

const RemoveButton = styled(Button)`
  color: rgb(255, 85, 0);
  text-transform: capitalize;
  &:hover,
  &:active,
  &:focus {
    color: rgb(255, 85, 0);
  }
` as ButtonType;

const StyledIcon = styled(Icon)`
  transform: rotate(90deg);
`;

export default AssistantListItemActions;
