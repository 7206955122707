import { action, Action, Select, select, thunk, Thunk } from 'easy-peasy';
import { ApiError } from '../../../services/api/api-error';
import Injections from '../../injections.interface';
import EnvVariables from '../../types/env-variables';

export interface DeviceEnvVariablesModel {
  data: {
    [deviceUuid: string]: EnvVariables;
  };
  loading: {
    [deviceUuid: string]: boolean;
  };
  error: {
    [deviceUuid: string]: ApiError | null;
  };
  loaded: Select<DeviceEnvVariablesModel, (deviceUuid: string) => boolean>;
  setLoading: Action<DeviceEnvVariablesModel, { deviceUuid: string; loading: boolean }>;
  setError: Action<
    DeviceEnvVariablesModel,
    { deviceUuid: string; error: ApiError | null }
  >;
  setData: Action<DeviceEnvVariablesModel, { deviceUuid: string; data: EnvVariables }>;
  fetch: Thunk<DeviceEnvVariablesModel, { deviceUuid: string }, Injections>;
  update: Thunk<
    DeviceEnvVariablesModel,
    { deviceUuid: string; envVariables: EnvVariables },
    Injections
  >;
}

const deviceEnvVariablesModel: DeviceEnvVariablesModel = {
  data: {},
  loading: {},
  error: {},
  loaded: select((state) => (deviceUuid: string) =>
    !!state.data[deviceUuid] && !state.loading[deviceUuid],
  ),
  setData: action((state, { deviceUuid, data }) => {
    state.data[deviceUuid] = data;
  }),
  setLoading: action((state, { deviceUuid, loading }) => {
    state.loading[deviceUuid] = loading;
  }),
  setError: action((state, { deviceUuid, error }) => {
    state.error[deviceUuid] = error;
  }),
  fetch: thunk(async (actions, { deviceUuid }, { injections }) => {
    actions.setError({ deviceUuid, error: null });
    try {
      const data = await injections.apiService.get<EnvVariables>(
        `/api/devices/${deviceUuid}/variables`,
      );
      actions.setData({ deviceUuid, data });
    } catch (error) {
      actions.setError({ deviceUuid, error });
    } finally {
      actions.setLoading({ deviceUuid, loading: false });
    }
  }),
  update: thunk(async (actions, { deviceUuid, envVariables }, { injections }) => {
    const data = await injections.apiService.put<EnvVariables>(
      `/api/devices/${deviceUuid}/variables`,
      envVariables,
    );
    actions.setData({ deviceUuid, data });
  }),
};

export default deviceEnvVariablesModel;
