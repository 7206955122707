import styled from '@emotion/styled';
import transientOptions from '../../../../utils/transient-emotion-styled-options';

const Container = styled('div', transientOptions)<{
  $isBordered?: boolean;
  $isDisabled?: boolean;
}>`
  background-color: inherit;

  ${({ $isBordered }) =>
    $isBordered
      ? `
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 8px;
        `
      : ''}

  ${({ $isDisabled }) =>
    !$isDisabled
      ? `
          transition: border-color ease-in-out 0.15s;
          :hover {
            border-color: rgba(103, 105, 115, 1);
          }
        `
      : ''}

  & + .help-block, & + div > .error-detail {
    display: none;
  }
`;

export default Container;
