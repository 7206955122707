import React from 'react';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useApp } from '../../../../common/use-app';
import Overlay from '../../../../common/overlay/overlay.component';
import Spinner from '../../../../common/spinner/spinner.component';
import BuildsV2Component from './builds-v2.component';

const ErrorMessage = styled.div`
  padding: 15px 0;
  color: rgb(255, 85, 0);
`;

type BuildsV2Props = RouteComponentProps<{ appId: string; organisationId: string }>;

const BuildsV2Container: React.FC<BuildsV2Props> = (props) => {
  const {
    match: {
      params: { appId, organisationId },
    },
  } = props;

  const { t } = useTranslation();

  const appState = useApp({ appId });

  return (
    <>
      {appState.isLoading && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}

      {appState.isSuccess && (
        <BuildsV2Component app={appState.data} organisationId={organisationId} />
      )}

      {appState.isError && <ErrorMessage>{t('errorDuringFetchingApp')}</ErrorMessage>}
    </>
  );
};

export default BuildsV2Container;
