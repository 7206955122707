import React, { useCallback } from 'react';
import { Divider, Tag, Typography } from 'antd';

import { ArrayFields } from '../../../../common/react-final-form';
import { Field } from 'react-final-form';
import { SegmentTagsContent } from '../../../../../store/types/segment';
import styled from '@emotion/styled';
import { Row } from 'antd/es/grid';
import MediaContentPicker, { MediaContentPickerInput, MediaContentPickerSelection } from '../../../../common/react-final-form/media-content-picker';
import { useTranslation } from 'react-i18next';

interface TagsContentSectionProps {
  tenantId: string;
  parentPropertyName: string;
  tagsContent?: SegmentTagsContent;
  handleSelection: (input: MediaContentPickerInput, value: MediaContentPickerSelection) => void;
}

const TagsContentSection = (props: TagsContentSectionProps) => {
  const { tenantId, tagsContent, parentPropertyName, handleSelection } = props;

  const handleOnSelection = useCallback((input: MediaContentPickerInput, value: MediaContentPickerSelection) => {
    handleSelection(input, value);
  }, [handleSelection]);

  return (
    <TagsContentSectionContainer>
      <MediaContentPicker
        tenantId={tenantId}
        content={tagsContent && tagsContent.content ? tagsContent.content : undefined}
        name={`${parentPropertyName}.content`}
        isRequired={true}
        onSelection={(input, value) => {
          handleOnSelection(input, value)
        }}
      />

      <Field
        name={`${parentPropertyName}.tags`}
        render={({ input }) => {
          const tags = input.value as SegmentTagsContent['tags'];
          return (
            <TagsLabelContainer>
              {tags &&
                tags.length &&
                tags.map((tag) => {
                  return <TagLabel key={tag.value}>{tag.value}</TagLabel>;
                })}
            </TagsLabelContainer>
          );
        }}
      />
    </TagsContentSectionContainer>
  );
};

interface SegmentFormTagsContentSectionProps {
  tenantId: string;
  tagsContent?: SegmentTagsContent[];
  handleSelection: (input: MediaContentPickerInput, value: MediaContentPickerSelection) => void;
}

const SegmentFormTagsContentSection = (props: SegmentFormTagsContentSectionProps) => {
  const { tenantId, tagsContent, handleSelection } = props;

  const { t } = useTranslation();

  return (
    <>
      <Typography.Title level={4}>{t('contents.content')}</Typography.Title>
      <SegmentTagsContentSectionSubHeading>
        {t('contents.segmentTagsContentSectionSubheading')}
      </SegmentTagsContentSectionSubHeading>

      <DividerFull />

      <ArrayFields
        propertyName="tagsContent"
        canAdd={false}
        canDelete={false}
        itemRowContent={(parentPropertyName, index) => {
          return (
            <SegmentTagsContentSectionContainer>
              <TagsContentSection
                tenantId={tenantId}
                parentPropertyName={parentPropertyName}
                tagsContent={tagsContent && tagsContent[index] ? tagsContent[index] : undefined}
                handleSelection={handleSelection}
              />
              <DividerFull />
            </SegmentTagsContentSectionContainer>
          );
        }}
      />
    </>
  );
};

export default SegmentFormTagsContentSection;

const SegmentTagsContentSectionSubHeading = styled(Typography.Text)`
  white-space: pre-line;
`;

const SegmentTagsContentSectionContainer = styled(Row)`
  width: 100% !important;
`;

const TagsLabelContainer = styled.div``;

const TagLabel = styled(Tag)`
  text-transform: capitalize;
`;

const TagsContentSectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DividerFull = styled(Divider)`
  width: calc(100% + 41px);
  margin-left: -20px;
  overflow: none;
`;
