import React, { useCallback, useMemo } from 'react';
import { useActions, useStore } from 'easy-peasy';
import moment from 'moment';
import { get, set, toPairs } from 'lodash';
import qs from 'query-string';
import LegacyOverview from './legacy-overview.component';
import { RootModel, RootState } from '../../../../../store/models/root.model';
import OrganisationApp from '../../../../../store/types/organisation-app';

type LegacyOverviewContainerProps = Omit<
  React.ComponentProps<typeof LegacyOverview>,
  'fetchReport' | 'report' | 'app' | 'error'
>;

const isAppInGridappIds = (app: OrganisationApp, gridAppIds: string[]) => {
  return (
    app &&
    app.settings &&
    app.settings.provider &&
    app.settings.provider.app &&
    app.settings.provider.app.gridApp &&
    app.settings.provider.app.gridApp.gridapp &&
    gridAppIds.includes(app.settings.provider.app.gridApp.gridapp.id)
  );
};

const getDefaultTimeZone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
  } catch (error) {
    // eslint-disable-next-line
    console.warn(error);
    return 'UTC';
  }
};

const getParams = (
  app: any,
  fromDate: string,
  toDate: string,
  timeZone: string,
  device?: string,
  location?: string,
) => {
  const params: any = {
    fromDate,
    toDate,
    timeZone,
    events: ['SESSION_START'],
    labels: {},
  };

  if (device) {
    // eslint-disable-next-line @typescript-eslint/camelcase
    params.deviceName = device;
  }

  if (location) {
    params.location = location;
  }

  if (/^@ombori\/legacy.*-target-aisle.*-provider.*$/.test(app.provider)) {
    params.events = [
      // 'TIMEOUT',
      'INTERACTION',
      'FACE',
      'SELECT_CATEGORY',
      'SELECT_PRODUCT',
      'BARCODE',
      'QR_HANDOFF',
      // 'SELECT_WAYFINDER',
      // 'SELECT_WAYFINDER_PRODUCT',
      // 'SELECT_WAYFINDER_CATEGORY',
      // 'SELECT_WAYFINDER_SEARCH',
    ];
  }

  if (/^@ombori\/gdm-.+-endless-aisle.*-provider.*$/.test(app.provider)) {
    params.events = [
      'SESSION_START',
      'CATEGORY',
      'PRODUCT',
      'SCAN',
      'SEARCH',
      'SHOPPING_GUIDE',
      'FLIGHT',
      'CAMPAIGN',
      'QR_HANDOFF',
    ];
    params.labels = {
      SESSION_START: ['deviceName'],
      CATEGORY: ['categoryName'],
      CAMPAIGN: ['categoryName'],
      PRODUCT: ['productVendorName', 'productName'],
      SCAN: ['type', 'compartmentCode', 'ean'],
      SHOPPING_GUIDE: ['type'],
      FLIGHT: ['flightDestination'],
    };
  }

  if (/^@ombori\/gdm-.+-voice-assistant.*-provider$/.test(app.provider)) {
    params.events = ['SESSION_START', 'PHOTO_TAKEN', 'PRINT_SUCCESS', 'QR_HANDOFF'];
    params.labels = {
      SESSION_START: ['deviceName'],
    };
  }

  if (/^@ombori\/gdm-.+-playlist.*-provider$/.test(app.provider)) {
    params.events = ['ITEM_SHOWN'];
    params.labels = {
      ITEM_SHOWN: ['type', 'name', 'tags'],
    };
  }

  if (/^@ombori\/gdm-.+-banners-grid-provider$/.test(app.provider)) {
    params.events = ['BANNER', 'CONTENT_ITEM', 'QR_HANDOFF'];
    params.labels = {
      BANNER: ['title'],
      CONTENT_ITEM: ['title'],
    };
  }

  if (/^@ombori\/gdm-.+-remote-provider$/.test(app.provider)) {
    params.events = ['QR_HANDOFF'];
  }

  if (app.provider === '@ombori/gdm-boconcept-inspirations-provider') {
    params.events = ['SESSION_START', 'QR_HANDOFF', 'VIDEO_START', 'LANGUAGE_SWITCH'];
    params.labels = {
      SESSION_START: ['deviceName'],
      VIDEO_START: ['title'],
      LANGUAGE_SWITCH: ['from', 'to'],
    };
  }

  if (app.provider === '@ombori/gdm-boconcept-quiz-provider') {
    params.events = [
      'SESSION_START',
      'LANGUAGE_SWITCH',
      'CLICK_START',
      'QUESTION',
      'IMAGE_CLICK',
      'QUIZ_RESULTS',
      'RESTART',
      'QR_HANDOFF',
      'DROPOUT',
    ];
    params.labels = {
      SESSION_START: ['deviceName'],
      LANGUAGE_SWITCH: ['from', 'to'],
      QUESTION: ['title'],
      IMAGE_CLICK: ['title'],
      QUIZ_RESULTS: ['title'],
      DROPOUT: ['title'],
    };
  }

  if (
    app.provider === '@ombori/gdm-boconcept-rfid-provider' ||
    isAppInGridappIds(app, ['5f8ee8e6e551fcefeecd3fc3', '612bf53e25ed11000670ead2'])
  ) {
    params.events = [
      'SESSION_START',
      'SWATCH',
      'LANGUAGE_SWITCH',
      'IMAGE_CLICK',
      'IMAGE_SWIPE',
      'QR_HANDOFF',
    ];
    params.labels = {
      SESSION_START: ['deviceName'],
      SWATCH: ['title'],
      LANGUAGE_SWITCH: ['from', 'to'],
      IMAGE_CLICK: ['title'],
      IMAGE_SWIPE: ['title'],
      QR_HANDOFF: ['long_url'],
    };
  }

  // Store Assistant Gridapp
  if (isAppInGridappIds(app, ['603bf73331fc19a07df3ef0d'])) {
    params.events = [
      'SESSION_START',
      'CATEGORY',
      'PRODUCT',
      'SEARCH',
      'CHECKOUT',
      'ADD_BAG_PRODUCT',
    ];
    params.labels = {
      SESSION_START: ['deviceName'],
      CATEGORY: ['categoryName'],
      PRODUCT: ['productVendorName', 'productName'],
      SEARCH: ['query'],
      ADD_BAG_PRODUCT: ['productName', 'categoryName'],
    };
  }

  // Web Kiosk Gridapp
  if (isAppInGridappIds(app, ['611e219ce8f77d000685faf3', '6093826971abf102c17130c9'])) {
    params.events = [
      'SESSION_START',
      'PAGE_VIEW',
      'MENU_CLICK',
      'BARCODE',
      'CLICK_START',
    ];
    params.labels = {
      SESSION_START: ['deviceName'],
      PAGE_VIEW: ['url'],
    };
  }

  // Shop the Look GridApp
  if (
    isAppInGridappIds(app, [
      '6157165bc5b1710006d78b76',
      '616d85704b60740006fc1c00',
      '616d36984b60740006fbe669',
    ])
  ) {
    params.events = [
      'SESSION_START',
      'SELECT_PRODUCT',
      'SELECT_CATEGORY',
      'QR_HANDOFF',
      'SHOW_LOOK',
    ];

    params.labels = {
      SESSION_START: ['deviceName'],
      SELECT_CATEGORY: ['title'],
      SHOW_LOOK: ['title'],
      SELECT_PRODUCT: ['title'],
      QR_HANDOFF: ['long_url'],
    };
  }

  return params;
};

const LegacyOverviewContainer = (props: LegacyOverviewContainerProps) => {
  const {
    match: {
      params: { appId, organisationId },
    },
    location: { search },
  } = props;

  const { app, devices, loading, report, error } = useStore<RootState>(
    (state) => {
      const appData =
        (state.organisationApps.data &&
          state.organisationApps.data[organisationId] &&
          state.organisationApps.data[organisationId][appId]) ||
        null;
      const result = {
        loading: state.analytics.loading[appId],
        report: state.analytics.reports[appId],
        error: state.analytics.error[appId],
        app: appData,
        devices: appData ? state.appDevices.values(appData.appName) : [],
      };

      return result;
    },
    [appId],
  );

  const { fetchAppDevices, fetchAppReport } = useActions<RootModel>((actions) => ({
    fetchAppReport: actions.analytics.fetchAppReport,
    fetchAppDevices: actions.appDevices.fetch,
  }));

  let {
    fromDate,
    toDate,
    location: locationParam,
    device: deviceParam,
    timeZone,
  } = qs.parse(search);

  const isDate = (date: any): date is string =>
    typeof date === 'string' && /\d{4}-\d{2}-\d{2}/.test(date);

  if (!isDate(fromDate)) {
    fromDate = moment()
      .subtract(8, 'd')
      .format('YYYY-MM-DD');
  }

  if (!isDate(toDate)) {
    toDate = moment()
      .subtract(1, 'd')
      .format('YYYY-MM-DD');
  }

  if (typeof locationParam !== 'string') {
    locationParam = undefined;
  }

  if (typeof deviceParam !== 'string') {
    deviceParam = undefined;
  }

  if (typeof timeZone !== 'string') {
    timeZone = getDefaultTimeZone();
  }

  const fetchReport = useCallback(() => {
    if (app) {
      fetchAppReport({
        appId: app.id,
        appName: app.appName,
        // @ts-ignore
        params: getParams(app, fromDate, toDate, timeZone, deviceParam, locationParam),
      });
    }
  }, [fetchAppReport, app, fromDate, toDate, timeZone, deviceParam, locationParam]);

  const fetchDevices = useCallback(
    () =>
      fetchAppDevices({
        appName: app.appName,
      }),
    [fetchAppDevices, app],
  );

  const options = useMemo(() => {
    const result: {
      [location: string]: string[];
    } = {};

    devices.forEach((device: any) => {
      const location: string =
        get(device, ['properties', 'desired', 'variables', '_ANALYTICS_LOCATION']) ||
        'Other';
      const name: string =
        get(device, ['properties', 'desired', 'variables', '_ANALYTICS_DEVICE_NAME']) ||
        device.deviceName;

      set(result, [location], [...(result[location] || []), name]);
    });

    return toPairs(result).map(([location, children]) => ({
      value: location === 'Other' ? null : location,
      label: location,
      children: children.map((device) => ({
        value: device,
        label: device,
      })),
    }));
  }, [devices]);

  return (
    <LegacyOverview
      {...props}
      app={app}
      fetchReport={fetchReport}
      fetchDevices={fetchDevices}
      fromDate={fromDate}
      toDate={toDate}
      deviceLocation={locationParam}
      device={deviceParam}
      timeZone={timeZone}
      loading={loading}
      report={report}
      options={options}
      error={error}
    />
  );
};

export default LegacyOverviewContainer;
