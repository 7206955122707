import { thunk, Thunk } from 'easy-peasy';
import Injections from '../../injections.interface';
import AndroidEnrollmentToken from '../../types/android-enrollment-token';

export const DEVICE_ENROLLED_EVENT_NAME = 'device_enrolled';

export interface DeviceEnrolledEventData {
  enrollmentTokenName: string;
  remoteId: string;
  displayName: string;
  deviceSerial: string;
}

export interface AppDevicesAndroidModel {
  createEnrollmentToken: Thunk<
    AppDevicesAndroidModel,
    { appId: string },
    Injections,
    {},
    Promise<AndroidEnrollmentToken>
  >;
  subscribeToEnrollment: Thunk<
    AppDevicesAndroidModel,
    (data: DeviceEnrolledEventData) => void,
    Injections,
    {},
    Promise<() => void>
  >;
}

const appDevicesAndroidModel: AppDevicesAndroidModel = {
  createEnrollmentToken: thunk((actions, params, { injections }) =>
    injections.apiService.post<AndroidEnrollmentToken>(
      `/api/v3/devices/android/enrollment-tokens`,
      params,
    ),
  ),
  subscribeToEnrollment: thunk((actions, onEnrollment, { injections }) =>
    injections.socketService.subscribeToEvent<DeviceEnrolledEventData>(
      DEVICE_ENROLLED_EVENT_NAME,
      onEnrollment,
    ),
  ),
};

export default appDevicesAndroidModel;
