import dayjs from 'dayjs';
import Media from '../store/types/media';
import { getMediaItemKind, MediaItemKind } from './media-item-utils';

export enum MediaTemplateStatus {
  VALID = 'valid',
  PENDING = 'pending',
  ERROR = 'error',
}

const mediaTemplatesProcessingTimeoutMinutes = 30;

export const getMediaTemplateStatus = (mediaItem: Media) => {
  if (mediaItem.metadata) {
    if (!mediaItem.metadata.error && mediaItem.metadata.schema) {
      return MediaTemplateStatus.VALID;
    }

    return MediaTemplateStatus.ERROR;
  }

  if (
    dayjs().diff(dayjs(mediaItem.createdAt), 'minute') >
    mediaTemplatesProcessingTimeoutMinutes
  ) {
    return MediaTemplateStatus.ERROR;
  }

  return MediaTemplateStatus.PENDING;
};

export const isMediaTemplate = (mediaItem: Media) =>
  [MediaItemKind.gridApp, MediaItemKind.gdmTemplate].includes(
    getMediaItemKind(mediaItem),
  );
