import React, { useMemo } from 'react';
import styled, { StyledComponent } from '@emotion/styled';
import { Row, Col, Button, Popconfirm } from 'antd';
import BuildsDeployments from './build-deployments/build-deployments';
import { match } from 'react-router';
import { Subheader } from '../../../../common/app-layout/header';
import { Link } from 'react-router-dom';
import { ButtonProps } from 'antd/lib/button';
import { useAppBuildByBuildId } from '../../../../common/use-app-builds';
import { ApiError } from '../../../../../services/api/api-error';
import Build from '../../../../../store/types/build';
import PreviewBuildApp from './build-preview/preview-build-app';
import { useTranslation } from 'react-i18next';
import { SyncOutline } from '@ant-design/icons';
import { Icon } from '../../../../common/schema-form/common';
import permissionKeys from '../../../../../utils/auth/permissions';
import usePermissions from '../../../../../utils/auth/use-permissions';
import DeviceTypeEnum from '../../../../../store/types/device-type.enum';

interface BuildComponentProps {
  match: match<{ organisationId: string; appId: string; buildId: string }>;
  onSettingsRollBack: () => void;
  isEnableLayoutV2: boolean;
  deviceType: DeviceTypeEnum;
}

type StyledButtonType = StyledComponent<
  ButtonProps & React.ClassAttributes<Button>,
  Pick<ButtonProps & React.ClassAttributes<Button>, keyof ButtonProps>,
  any
>;

const BuildComponent: React.FC<BuildComponentProps> = (props) => {
  const { t } = useTranslation();
  const {
    match: {
      params: { organisationId, buildId, appId },
      url,
    },
    onSettingsRollBack,
    isEnableLayoutV2,
    deviceType,
  } = props;

  const { data, isLoading, isSuccess, error } = useAppBuildByBuildId({ buildId, appId });
  const { isAllowed } = usePermissions(organisationId);

  const appBuildData = useMemo(() => (isSuccess ? data : {}), [isSuccess, data]) as Build;

  const { canUpdateContentOrSettings } = useMemo(() => {
    return {
      canUpdateContentOrSettings: isEnableLayoutV2
        ? isAllowed(permissionKeys.apps.updateSettings)
        : isAllowed(permissionKeys.apps.updateContents),
    };
  }, [isEnableLayoutV2, isAllowed]);

  const viewPath = useMemo(() => {
    const segments = url.split('/');
    segments.pop();
    return segments.join('/');
  }, [url]);

  const SubheaderComponents = useMemo(() => {
    const components = [];

    components.push(
      <Link key="back-to-list" to={viewPath}>
        <BackToListButton type="default" icon="arrow-left" />
      </Link>,
    );

    if (canUpdateContentOrSettings) {
      components.push(
        <Popconfirm
          key="roll-back-confirm"
          title={
            isEnableLayoutV2
              ? t('builds.areYouSureYouWantToRollBackSettingsContent')
              : t('builds.areYouSureYouWantToRollBackContent')
          }
          onConfirm={onSettingsRollBack}
          okText={t('yes')}
          cancelText={t('no')}
        >
          <RollBackButton
            size="large"
            type="primary"
            disabled={!(appBuildData.data && appBuildData.data.app)}
          >
            <Icon size={15} type={SyncOutline.name} />
            {t('builds.rollBackSettings')}
          </RollBackButton>
        </Popconfirm>,
      );
    }
    return components;
  }, [
    canUpdateContentOrSettings,
    isEnableLayoutV2,
    appBuildData.data,
    viewPath,
    t,
    onSettingsRollBack,
  ]);

  const deploymentColProps = useMemo(() => {
    let deploymentColProps = { md: 100, xl: 100 };
    if (deviceType === DeviceTypeEnum.WPA || deviceType === DeviceTypeEnum.BALENA) {
      deploymentColProps.md = 24;
      deploymentColProps.xl = 15;
    }
    return deploymentColProps;
  }, [deviceType]);

  return (
    <>
      <StyledSubheader components={SubheaderComponents} />
      <div className="content-body">
        <Row gutter={{ md: 20, xl: 40 }}>
          <Col {...deploymentColProps}>
            <Section>
              <BuildsDeployments
                organisationId={organisationId}
                isLoading={isLoading}
                isSuccess={isSuccess}
                error={error as ApiError}
                buildData={appBuildData}
              />
            </Section>
          </Col>
          {(deviceType === DeviceTypeEnum.WPA ||
            deviceType === DeviceTypeEnum.BALENA) && (
            <Col md={24} xl={9}>
              <Section>
                <PreviewBuildApp
                  isLoading={isLoading}
                  isSuccess={isSuccess}
                  error={error as ApiError}
                  buildData={appBuildData}
                />
              </Section>
            </Col>
          )}
        </Row>
        {/* <Row>TODO: Device list</Row> */}
      </div>
    </>
  );
};

export default BuildComponent;

const StyledSubheader = styled(Subheader)`
  padding: 0 36px;
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const BackToListButton = styled(Button)`
  height: 36px;
  font-size: 14px;
  color: #676973;
  border: none;
  margin-right: 5px;
  background-color: transparent;

  :hover,
  :active,
  :focus {
    background-color: #f2f4f8;
  }
` as StyledButtonType;

const RollBackButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #2364aa;
  border-color: #2364aa;
  height: 36px;
  font-size: 14px;
  margin-left: auto;
  color: #fff;

  :hover,
  :active,
  :focus {
    opacity: 0.9;
    background-color: #2364aa;
    border-color: #2364aa;
  }
` as StyledButtonType;
