import * as React from 'react';
import { FieldProps } from 'react-jsonschema-form';
import MultiLanguageTextComponent from './multi-language-text.component';
import MultiLanguageMediaComponent from './multi-language-media-picker.component';

export const MultiLanguageText = (props: FieldProps) => {
  return <MultiLanguageTextComponent {...props} />;
};

export const MultiLanguageMediaPicker = (props: FieldProps) => {
  return <MultiLanguageMediaComponent {...props} />;
};

export default {
  MultiLanguageText,
  MultiLanguageMediaPicker,
};
