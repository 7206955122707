import { useMutation, useQuery } from 'react-query';
import { useApiService } from '../api-service-provider';
import GlobalConfiguration from '../../../store/types/global-configuration';

const URL = '/api/global-configuration';

export const useGlobalConfiguration = () => {
  const apiService = useApiService();

  return useQuery(
    ['global-configuration'],
    () => apiService.get<GlobalConfiguration>(URL),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
}

export const useUpdateGlobalConfiguration = () => {
  const apiService = useApiService();


  return useMutation(
    async (updateGlobalConfigurationBody: GlobalConfiguration) =>
      apiService.put<GlobalConfiguration>(URL, updateGlobalConfigurationBody),
  );
};
