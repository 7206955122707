import React, { useCallback, useState, useMemo } from 'react';
import styled from '@emotion/styled';
import { Button, Col, message, Row } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Gridapp, { GroupedGridapps } from '../../../../store/types/gridapp';
import CrudModal from '../../crud-list/crud-modal/crud-modal.component';
import AppLibraryItem from '../../apps-library/gridapp-list-item/gridapp-list-item.component';
import usePermissions from '../../../../utils/auth/use-permissions';
import { permissionKeys } from '../../../../utils/auth/permissions';
import GridappTypeEnum from '../../../../store/types/gridapp-type.enum';
import AppsLibraryInstallModalContainer from '../apps-library-install-modal/apps-library-install-modal.container';
import { sortGridAppByType } from '../../../../utils/apps-library/apps-library.util';
import Overlay from '../../../common/overlay/overlay.component';
import Spinner from '../../../common/spinner/spinner.component';
import ErrorView from '../../../common/error-view/error-view.component';

interface AppsLibraryListProps extends RouteComponentProps<{ organisationId: string }> {
  groupedGridapps: GroupedGridapps[];
  createGridapp?: (App: Partial<Gridapp>) => Promise<string>;
  isLoading: boolean;
  isError: boolean;
  installGridapp: (params: {
    id: string;
    type: GridappTypeEnum;
    displayName: string;
    installationGroupId: string;
  }) => Promise<void>;
}

const AppsLibraryList = (props: AppsLibraryListProps) => {
  const {
    isLoading,
    isError,
    groupedGridapps,
    createGridapp,
    installGridapp,
    match: {
      params: { organisationId },
      url,
    },
    history,
  } = props;
  const { t } = useTranslation();
  const { isAllowed } = usePermissions(organisationId);
  const [appToInstall, setAppToInstall] = useState<Gridapp | null>(null);
  const [isCreateAppModalVisible, setIsCreateAppModalVisible] = useState<boolean>(false);

  const handleModalClose = useCallback(() => {
    setIsCreateAppModalVisible(false);
  }, [setIsCreateAppModalVisible]);

  const handleModalSubmit = useCallback(
    async (values: Partial<Gridapp>) => {
      if (createGridapp) {
        try {
          const gridappId = await createGridapp({
            ...values,
            organizationId: organisationId,
          });
          handleModalClose();
          history.push(`${url}/${gridappId}`);
        } catch (e) {
          message.error(t('errorSavingData'));
          throw e;
        }
      }
    },
    [handleModalClose, createGridapp, history, organisationId, url, t],
  );

  const handleCreate = useCallback(() => {
    setIsCreateAppModalVisible(true);
  }, [setIsCreateAppModalVisible]);

  const appsLibraryCreateFormSchema = {
    type: 'object',
    properties: {
      displayName: {
        type: 'string',
        title: t('displayName'),
        minLength: 3,
      },
      type: {
        type: 'string',
        title: t('type'),
        minLength: 3,
        enum: [
          GridappTypeEnum.SCREEN,
          GridappTypeEnum.MOBILE,
          GridappTypeEnum.IOT_MODULE,
          GridappTypeEnum.CLOUD,
        ],
        enumNames: [t('screen'), t('mobile'), t('iot'), t('cloud')],
      },
      packageName: {
        type: 'string',
        title: t('packageName'),
        pattern: '^([a-z][a-z0-9]*)(-[a-z0-9]+)*$',
        description: t('mustBeInSmallLettersAndKebabCase'),
      },
    },
    required: ['displayName', 'type', 'packageName'],
  };

  const handleInstallModalSubmit = useCallback(
    async ({
      displayName,
      installationGroupId,
    }: {
      appName: string;
      displayName: string;
      installationGroupId: string;
    }) => {
      if (appToInstall) {
        const { id, type } = appToInstall;
        await installGridapp({
          id,
          displayName,
          type,
          installationGroupId,
        });
      }
    },
    [installGridapp, appToInstall],
  );

  const showInstallModal = useCallback(
    (app: Gridapp) => {
      setAppToInstall(app);
    },
    [setAppToInstall],
  );

  const handleInstallModalClose = useCallback(() => {
    setAppToInstall(null);
  }, [setAppToInstall]);

  const sortedGridappsGroup = useMemo(() => {
    return groupedGridapps.map(appsGroup => appsGroup.apps.length > 0 ? (
      <AppsCol key={appsGroup.type}>
        <Title>{appsGroup.title}</Title>
        <AppsRow gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          {sortGridAppByType(appsGroup.apps).map((app) => (
            <Col xs={24} sm={24} md={24} lg={24} xl={12} key={app.id}>
              <AppLibraryItem
                hoverable
                item={app}
                url={`${url}/${app.id}`}
                onInstall={() => showInstallModal(app)}
                activeOrganisationId={organisationId}
              />
            </Col>
          ))}
        </AppsRow>
      </AppsCol>
    ) : null );
  }, [organisationId, groupedGridapps, showInstallModal, url]);

  if (isError) {
    return <ErrorView title={t('failedToLoadApplications')} content={null} fillPage />;
  }

  if (isLoading) {
    return (
      <Overlay>
        <Spinner />
      </Overlay>
    );
  }

  return (
    <>
      <div className="content-body">
        {createGridapp ? (
          <ListActionRow type="flex" justify="end" align="middle">
            {isAllowed(permissionKeys.gridApps.create) && (
              <Col>
                <Button size="large" icon="plus" onClick={handleCreate}>
                  {t('newApplication')}
                </Button>
              </Col>
            )}
          </ListActionRow>
        ) : null}
        {sortedGridappsGroup}
      </div>
      {createGridapp ? (
        <CrudModal
          title={t('createApplication')}
          schema={appsLibraryCreateFormSchema}
          metaSchema={{}}
          initialValues={{}}
          onSubmit={handleModalSubmit}
          visible={isCreateAppModalVisible}
          onClose={handleModalClose}
        />
      ) : null}
      <AppsLibraryInstallModalContainer
        app={appToInstall}
        onClose={handleInstallModalClose}
        organisationId={organisationId}
        onSubmit={handleInstallModalSubmit}
      />
    </>
  );
};

const ListActionRow = styled(Row)`
  margin-bottom: -16px;
`;

const AppsCol = styled(Col)`
  padding-top: 24px;
`;

const AppsRow = styled(Row)`
  padding-top: 12px;
`;


const Title = styled.label`
  font-size: 16px;
  font-weight: bold;
`;

export default AppsLibraryList;
