import { useCallback } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { ApiError } from '../../../../../../services/api/api-error';
import { useGridProductsFeedService } from '../../../../../common/grid-products-connector-service-provider';
import { DataResidencyEnum } from '../../../../../../store/types/organisation';
import { IntegrationData } from '../types';

type UseIntegrationsResponse = UseQueryResult<IntegrationData, ApiError>;

const useIntegration = ({
  tenantId,
  dataResidency,
  integrationId,
}: {
  tenantId: string;
  dataResidency: DataResidencyEnum;
  integrationId?: string;
}): UseIntegrationsResponse => {
  const gridProductsFeedService = useGridProductsFeedService(dataResidency);

  const fetch = useCallback(async () => {
    const url = `/${tenantId}/integrations/${integrationId}`;

    return gridProductsFeedService.get<IntegrationData>(url);
  }, [gridProductsFeedService, integrationId, tenantId]);

  const result = useQuery(getIntegrationKeys(tenantId, integrationId), () => fetch(), {
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    keepPreviousData: true,
    onError: (_error: ApiError) => {},
  });

  return result;
};

export const getIntegrationKeys = (
  tenantId?: string,
  integrationId?: string,
): string[] => {
  return [
    'get-integration',
    ...(tenantId ? ['tenantId', tenantId] : []),
    ...(integrationId ? ['integrationId', integrationId] : []),
  ];
};

export default useIntegration;
