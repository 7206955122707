/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Chart, Geom, Label, Guide, Tooltip, Coord, Legend } from 'bizcharts';
import { keyBy, memoize } from 'lodash';
import { TFunction } from 'i18next';
import { OrganizationAccountBalance } from '../../../../../../store/types/organisation';
import { formatAmount } from '../../../../../../utils/currency';

export enum AccountBalanceRatioTypeEnum {
  CONSUMPTION = 'consumption',
  REMAINING_BALANCE = 'remaining_balance',
}

const ColorMap = {
  CONSUMPTION: { foreground: '#111111', background: '#c9daee' },
  REMAINING_BALANCE: { foreground: '#fff', background: '#0064b6' },
};

interface AccountBalancePieChartProps {
  formattedTotalCredits: string;
  accountBalance: OrganizationAccountBalance;
}

const AccountBalancePieChart = (props: AccountBalancePieChartProps) => {
  const { formattedTotalCredits, accountBalance } = props;
  const { t, i18n } = useTranslation();

  const { ratio, keyedRatio } = useMemo(() => {
    // eslint-disable-next-line no-use-before-define
    const data = getPieChartContent(t, i18n.language, accountBalance);

    return {
      ratio: data,
      keyedRatio: keyBy(data, 'item'),
    };
  }, [accountBalance, t, i18n]);

  const getGeomColor = memoize((item: AccountBalanceRatioTypeEnum) => {
    switch (item) {
      case AccountBalanceRatioTypeEnum.CONSUMPTION:
        return ColorMap.CONSUMPTION.background;
      case AccountBalanceRatioTypeEnum.REMAINING_BALANCE:
        return ColorMap.REMAINING_BALANCE.background;
      default:
        return ColorMap.REMAINING_BALANCE.background;
    }
  });

  const getLabelTextStyle = memoize((formattedPercent: string, data) => {
    const { point } = data;
    const { item } = point;

    const style = { textAlign: 'center', fill: '' };

    switch (item) {
      case AccountBalanceRatioTypeEnum.CONSUMPTION:
        style.fill = ColorMap.CONSUMPTION.foreground;
        break;
      case AccountBalanceRatioTypeEnum.REMAINING_BALANCE:
        style.fill = ColorMap.REMAINING_BALANCE.foreground;
        break;
      default:
    }

    return style;
  });

  return (
    <div>
      <Chart
        width={250}
        height={300}
        data={ratio}
        padding="auto"
        forceFit
        animate={false}
      >
        <Coord type="theta" radius={0.85} innerRadius={0.55} />
        <Legend
          position="bottom"
          offsetY={-25}
          layout="vertical"
          allowAllCanceled={false}
          itemFormatter={(val) => {
            return `${keyedRatio[val].description} - ${keyedRatio[val].formattedAmount}`;
          }}
        />
        <Tooltip
          showTitle={false}
          itemTpl='<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
        />
        <Geom
          type="intervalStack"
          position="percent"
          color={['item', getGeomColor]}
          tooltip={[
            'description*formattedAmount',
            (description: string, formattedAmount: string) => {
              return {
                name: description,
                value: formattedAmount,
              };
            },
          ]}
          style={{
            lineWidth: 1,
            stroke: '#fff',
          }}
          select={false}
        >
          <Label
            content={[
              'percent*item',
              (percent: number) => {
                return `${(percent * 100).toFixed(2)}%`;
              },
            ]}
            offset={-25}
            autoRotate={false}
            // @ts-ignore
            textStyle={getLabelTextStyle}
          />
        </Geom>
        <Guide>
          <Guide.Html
            position={['50%', '50%']}
            html={
              formattedTotalCredits
                ? `<div style="text-align: center"><div>${t(
                    'total',
                  )}</div> <div><strong>${formattedTotalCredits}</strong></div></div>`
                : '<div></div>'
            }
          />
        </Guide>
      </Chart>
    </div>
  );
};

const getPieChartContent = (
  t: TFunction,
  currentLanguage: string,
  accountBalance: OrganizationAccountBalance,
): {
  item: AccountBalanceRatioTypeEnum;
  description: string;
  formattedAmount: string;
  percent: number;
}[] => {
  const { currency = null, totalCredits = 0, remainingCredits = 0 } =
    accountBalance || {};

  const consumedCredits = totalCredits - remainingCredits;

  return [
    {
      item: AccountBalanceRatioTypeEnum.REMAINING_BALANCE,
      description: t('accountBalance.remainingBalance'),
      formattedAmount: currency
        ? formatAmount(currency, remainingCredits, currentLanguage)
        : '',
      percent: remainingCredits / totalCredits,
    },
    {
      description: t('accountBalance.consumedCredit'),
      item: AccountBalanceRatioTypeEnum.CONSUMPTION,
      formattedAmount: currency
        ? formatAmount(currency, consumedCredits, currentLanguage)
        : '',
      percent: consumedCredits / totalCredits,
    },
  ];
};

export default React.memo(AccountBalancePieChart);
