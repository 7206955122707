import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import styled from '@emotion/styled';
import { Tabs } from 'antd';
import { AnalyticsSchema, Card, CardType } from '@ombori/grid-reports';
import { useStoreState } from '../../../../store/initialize-store';
import ErrorView from '../../error-view/error-view.component';
import Organisation from '../../../../store/types/organisation';
import { TenantEventsList } from '../cards/events-list';
import { TenantSessions } from '../cards/sessions';
import { TenantEventsFlow } from '../cards/events-flow';
import { TenantNps } from '../cards/nps';
import { TenantProductsEventCount } from '../cards/products-event-count';
import { TenantCategoriesEventCount } from '../cards/categories-event-count';
import { TenantEventsFunnel } from '../cards/events-funnel';
import { TenantWeekHeatmap } from '../cards/week-heatmap';
import { TenantEventsCount } from '../cards/events-count';
import { TenantAverageSales } from '../cards/average-sales';
import { TenantAverageTimeBetweenTransactions } from '../cards/average-time-between-transactions';
import { TenantAverageNumberOfPurchasedProducts } from '../cards/average-number-of-purchased-products';
import { TenantPurchasedProductsCategoriesEventCount } from '../cards/purchased-products-categories-event-count';
import { TenantPurchasedProductsEventCount } from '../cards/purchased-products-event-count';
import { TenantQrCodesEventCount } from '../cards/qr-codes-event-count';
import { TenantMedia } from '../cards/media';
import DataMatrix from '../cards/data-matrix';
import AnalyticsReport from '../analytics-report';
import Controls from '../controls';
import RangePicker from '../../range-picker/range-picker.component';
import useDateRange from '../../use-date-range';
import ErrorBoundary from '../../error-boundary/error-boundary.component';
import DownloadReport from '../download-report.component';
import useTenantSchema from '../../use-tenant-schema/use-tenant-schema';
import { useTranslation } from 'react-i18next';
import Overlay from '../../overlay/overlay.component';
import Spinner from '../../spinner/spinner.component';
import validateSchema from '../validate-schema';
import { ReportContext } from '../../use-analytics-report';
import { TenantEventsSuccessFailureRate } from '../cards/events-succes-failure-rate';
import { TenantModulesStatusContainer } from '../cards/modules-status';
import { useAnalyticsParams } from '../../use-analytics-params';
import { DevicesMonitoringStatusHistory } from '../cards/monitoring-status-history';

const { TabPane } = Tabs;

const Container = styled.div``;

const WrapperContainer = styled.div``;

const ErrorMessage = styled.div`
  padding: 15px 0;
  color: rgb(255, 85, 0);
`;

interface CheckSchemaValidResult {
  isValid: true;
  analyticsSchema: AnalyticsSchema;
}

interface CheckSchemaInvalidResult {
  isValid: false;
  analyticsSchema: undefined;
}

type CheckSchemaResult = CheckSchemaValidResult | CheckSchemaInvalidResult;

const checkSchema = (analyticsSchema: unknown): CheckSchemaResult => {
  try {
    const validatedSchema = validateSchema(analyticsSchema);

    return { isValid: true, analyticsSchema: validatedSchema };
  } catch (error) {
    return { isValid: false, analyticsSchema: undefined };
  }
};

interface ReportProps {
  schema: AnalyticsSchema;
  tenant: Organisation;
}

const Report: React.FC<ReportProps> = ({ schema: { groups }, tenant }) => {
  const { dateFrom, dateTo, onChangeRange } = useDateRange();

  const { updateAnalyticsParams } = useAnalyticsParams();

  useEffect(() => {
    updateAnalyticsParams({ fromDate: dateFrom, toDate: dateTo });
  }, [dateFrom, dateTo, updateAnalyticsParams]);

  const getComponent = useCallback(
    (reportCard: Card): React.ReactNode => {
      switch (reportCard.type) {
        case CardType.EventsList:
          return (
            <TenantEventsList
              tenantId={tenant.id}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              interactionType={reportCard.interactionType}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.EventsCount:
          return (
            <TenantEventsCount
              tenantId={tenant.id}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              title={reportCard.title}
              eventType={reportCard.eventType}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.Sessions:
          return (
            <TenantSessions
              tenantId={tenant.id}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              interactionType={reportCard.interactionType}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.EventsFlow:
          return (
            <TenantEventsFlow
              tenantId={tenant.id}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.Nps:
          return (
            <TenantNps
              tenantId={tenant.id}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.ProductsEventCount:
          return (
            <TenantProductsEventCount
              tenantId={tenant.id}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              eventType={reportCard.eventType}
              title={reportCard.title}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.CategoriesEventCount:
          return (
            <TenantCategoriesEventCount
              tenantId={tenant.id}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.EventsFunnel:
          return (
            <TenantEventsFunnel
              tenantId={tenant.id}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              title={reportCard.title}
              events={reportCard.events}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.WeekHeatmap:
          return (
            <TenantWeekHeatmap
              tenantId={tenant.id}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              title={reportCard.title}
              dataSource={reportCard.dataSource}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.DataMatrix:
          return (
            <DataMatrix
              tenantId={tenant.id}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              title={reportCard.title}
              dataSource={reportCard.dataSource}
              dataSourceType={reportCard.dataSourceType}
              columns={reportCard.columns}
              downloadFileColumns={reportCard.downloadFileColumns}
              tableColumns={reportCard.tableColumns}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.AverageSales:
          return (
            <TenantAverageSales
              tenantId={tenant.id}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.AverageTimeBetweenTransactions:
          return (
            <TenantAverageTimeBetweenTransactions
              tenantId={tenant.id}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.AverageNumberOfPurchasedProducts:
          return (
            <TenantAverageNumberOfPurchasedProducts
              tenantId={tenant.id}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.PurchasedProductsEventCount:
          return (
            <TenantPurchasedProductsEventCount
              tenantId={tenant.id}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.PurchasedProductsCategoriesEventCount:
          return (
            <TenantPurchasedProductsCategoriesEventCount
              tenantId={tenant.id}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.QrCodesCount:
          return (
            <TenantQrCodesEventCount
              tenantId={tenant.id}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.EventsSuccessFailureRate:
          return (
            <TenantEventsSuccessFailureRate
              tenantId={tenant.id}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              interactionType={reportCard.interactionType}
              gridStyles={reportCard.gridStyles}
              title={reportCard.title}
              inputType={reportCard.inputType}
              additionalData={reportCard.additionalData}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
              dataSource={reportCard.dataSource}
              dataSourceType={reportCard.dataSourceType}
              reportContext={ReportContext.Tenant}
            />
          );

        case CardType.Media:
          return (
            <TenantMedia
              tenantId={tenant.id}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              title={reportCard.title}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
              primaryKey={reportCard.primaryKey}
            />
          );
        
        case CardType.ModulesStatus:
          return (
            <TenantModulesStatusContainer
              tenantId={tenant.id}
              dataResidency={tenant.dataResidency}
              eventType={reportCard.eventType}
              statusCodes={reportCard.statusCodes}
              title="Modules Status"
            />
          );

        case CardType.MonitoringStatusHistory:
          return (
            <DevicesMonitoringStatusHistory
              dateFrom={dateFrom}
              dateTo={dateTo}
              tenantId={tenant.id}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              eventType={reportCard.eventType}
              title={reportCard.title}
              statusReference={reportCard.statusReference}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        default:
          // @ts-ignore
          throw new Error(`Unknown schema card type ${reportCard.type}`);
      }
    },
    [tenant, dateFrom, dateTo],
  );

  return (
    <WrapperContainer>
      <Controls>
        <RangePicker dateFrom={dateFrom} dateTo={dateTo} onChangeRange={onChangeRange} />
        <DownloadReport
          tenantId={tenant.id}
          dataResidency={tenant.dataResidency}
          filters={{
            dateFrom,
            dateTo,
          }}
        />
      </Controls>

      <Tabs defaultActiveKey="1" animated={false}>
        {groups.map((group, index) => {
          const components = group.cards.map(getComponent);

          return (
            <TabPane tab={group.name} key={`${index + 1}`}>
              <AnalyticsReport
                cards={components}
                columnsCount={group.columnsCount}
                gridStyles={group.gridStyles}
              />
            </TabPane>
          );
        })}
      </Tabs>
    </WrapperContainer>
  );
};

interface WrapperProps extends ReportProps {
  analyticsSchema: AnalyticsSchema;
}

const Wrapper: React.FC<WrapperProps> = React.memo(({ schema, tenant, analyticsSchema }) => {
  const checkSchemaResult = useMemo(() => checkSchema(analyticsSchema), [
    analyticsSchema,
  ]);

  if (checkSchemaResult.isValid) {
    return <Report tenant={tenant} schema={checkSchemaResult.analyticsSchema} />;
  }

  return (
    <Container>
      <ErrorBoundary>
        <Report tenant={tenant} schema={schema} />
      </ErrorBoundary>
    </Container>
  );
});

interface TenantReportProps {
  schema: AnalyticsSchema;
}

const TenantReport: React.FC<TenantReportProps> = ({ schema }) => {
  const { organisationId: tenantId } = useParams<{ organisationId: string }>();

  const { t } = useTranslation();

  const { tenant } = useStoreState(
    (state) => ({
      tenant: state.organisations.data && state.organisations.data[tenantId],
    }),
    [tenantId],
  );

  const tenantSchemaState = useTenantSchema({ organizationId: tenantId });

  if (!tenant) {
    return <ErrorView />;
  }

  return (
    <Container>
      <ErrorBoundary>
        {tenantSchemaState.isLoading && (
          <Overlay>
            <Spinner />
          </Overlay>
        )}

        {tenantSchemaState.isSuccess && (
          <Wrapper
            tenant={tenant}
            schema={schema}
            analyticsSchema={tenantSchemaState.data}
          />
        )}

        {tenantSchemaState.isError && (
          <ErrorMessage>{t('errorDuringFetchingSchema')}</ErrorMessage>
        )}
      </ErrorBoundary>
    </Container>
  );
};

export default TenantReport;
