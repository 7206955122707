import React, { useMemo } from 'react';
import { get, isEmpty } from 'lodash';
import UniversalDevice from '../../../../../store/types/universal-device';
import { useAppsSettings } from '../../../use-app';
import ImageCard from './image-card.component';
import Media from '../../../../../store/types/media';

interface SpaceImageCardProps {
  title: string;
  imageProperty: string;
  isVisibleWithoutData: boolean;
  devices: UniversalDevice[];
  spaceId: string;
  tenantId: string;
}

const SpaceImageCard = (props: SpaceImageCardProps) => {
  const { title, imageProperty, isVisibleWithoutData, devices, spaceId, tenantId } = props;

  const appsIds: string[] = [];
  devices.forEach((device) => {
    appsIds.push(...device.appIds);
  });

  const { data: appsSettings, isLoading, isError } = useAppsSettings(tenantId, appsIds);

  const images = useMemo<Media[] | null>(() => {
    if (!appsSettings) {
      return null;
    }

    const spaceImages = appsIds.map((appId) => {
      const appSettings = appsSettings[appId];
      if (appSettings) {
        if (appSettings.spacesSettings && appSettings.spacesSettings[spaceId as keyof Object]) {
          const spaceSettings = appSettings.spacesSettings[spaceId as keyof Object];
          const spaceImage = get(spaceSettings, imageProperty, null);
          if (spaceImage && !isEmpty(spaceImage)) {
            return spaceImage;
          }
        }

        const globalImage = get(appSettings, `provider.app.gridApp.settings.${imageProperty}`, null);
        if (globalImage && !isEmpty(globalImage)) {
          return globalImage;
        }
      }

      return null;
    });

    const images = spaceImages.filter((spaceImage) => spaceImage);

    return images.length ? images : null;
  }, [appsIds, spaceId, appsSettings, imageProperty]);

  return (
    <ImageCard
      title={title}
      isLoading={isLoading}
      isError={isError}
      images={images}
      isVisibleWithoutData={isVisibleWithoutData}
    />
  );
};

export default SpaceImageCard;
