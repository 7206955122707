import { useQuery, useMutation, useQueryClient } from "react-query";
import { useApiService } from "../api-service-provider";
import { ApiError } from "../../../services/api/api-error";

export interface NetworkSettings {
  tenantId: string;
  networkWhitelistAllowAll: boolean;
};

const networkWhitelistSettingsQueryKeys: Record<string, string[]> = {};

const getNetworkSettingsQueryKeys = (tenantId: string) => {
  return [
    'network-whitelist-settings',
    'tenantId',
    tenantId,
  ]
};

export const useGetNetworkSettings = (tenantId: string) => {
  const apiService = useApiService();

  const queryKeys = getNetworkSettingsQueryKeys(tenantId);

  // storing them to later get correct keys for updating network settings
  networkWhitelistSettingsQueryKeys[tenantId] = queryKeys;

  return useQuery(
    queryKeys,
    () => {
      return apiService.get<NetworkSettings>(`/api/network-settings/${tenantId}`);
    }, {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
  });
};

export const useUpdateNetworkSettings = () => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  return useMutation(async (payload: NetworkSettings) => {
    const { tenantId, ...networkSettings } = payload;
    return apiService.put<NetworkSettings>(`/api/network-settings/${tenantId}`, { ...networkSettings });
  }, {
    onSuccess: (updatedNetworkSettings: NetworkSettings) => {
      const { tenantId } = updatedNetworkSettings;
      queryClient.setQueryData<NetworkSettings | undefined>(networkWhitelistSettingsQueryKeys[tenantId], (_) => {
        return updatedNetworkSettings;
      });
    },
    onError: (error: ApiError) => {} 
  });
}
