import GridappBuild from '../../../../../store/types/gridapp-build';
import { BuildSchemaResponse } from '../../../../../types';

export const buildFieldSchema = (
  selectedBuild?: GridappBuild,
): BuildSchemaResponse | undefined => {
  const schema =
    selectedBuild && selectedBuild.result && selectedBuild.result.settingsSchema
      ? selectedBuild.result.settingsSchema
      : null;

  if (!schema) {
    return undefined;
  }

  return {
    ...schema,
    title: '',
  };
};

export const buildUiSchema = (
  isGridAppVersionPickerVisible: boolean,
  selectedBuild?: GridappBuild,
): BuildSchemaResponse => {
  const uiSchema =
    selectedBuild && selectedBuild.result && selectedBuild.result.metaSchema
      ? selectedBuild.result.metaSchema
      : null;

  if (!uiSchema) {
    return {
      'ui:field': 'main',
    };
  }

  return {
    ...uiSchema,
    ...(isGridAppVersionPickerVisible ? { 'ui:field': 'main' } : {}),
  };
};
