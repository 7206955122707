import { useMemo } from 'react';
import sortBy from 'lodash/sortBy';
import OrganisationApp from '../store/types/organisation-app';
import InstallationGroup from '../store/types/installation-group';
import { useTranslation } from 'react-i18next';

export const noInstallationGroupKey = 'no-installation-group';

const useGroupedAppsByInstallationGroups = ({
  apps,
  installationGroups,
}: {
  apps: OrganisationApp[];
  installationGroups: InstallationGroup[];
}) => {
  const { t } = useTranslation();

  const groupedApps: any = useMemo(() => {
    const installationGroupsIds = apps.map(
      (app) => app.installationGroupId || noInstallationGroupKey,
    );
    const emptyInstallationGroups = installationGroups.reduce<InstallationGroup[]>(
      (acc, installationGroup) => {
        const isUsed = installationGroupsIds.includes(installationGroup.id);
        if (isUsed) {
          return acc;
        }
        return [...acc, installationGroup];
      },
      [],
    );

    const groupsWithApps = apps.reduce(
      (groupedAppsObj, app) => {
        const appGroupId = app.installationGroupId || noInstallationGroupKey;
        const appGroup = app.installationGroupId
          ? installationGroups.find((group: { id: any }) => group.id === appGroupId)
          : null;

        if (!groupedAppsObj[appGroupId]) {
          return {
            ...groupedAppsObj,
            [appGroupId]: {
              groupId: appGroupId,
              groupName: appGroup ? appGroup.displayName : t('unknownGroupName'),
              apps: [app],
            },
          };
        }
        return {
          ...groupedAppsObj,
          [appGroupId]: {
            ...groupedAppsObj[appGroupId],
            apps: [...groupedAppsObj[appGroupId].apps, app],
          },
        };
      },
      {
        [noInstallationGroupKey]: {
          groupId: noInstallationGroupKey,
          groupName: t('myInstallations'),
          apps: [],
        },
      } as {
        [groupKey: string]: {
          groupId: string;
          groupName: string;
          apps: OrganisationApp[];
        };
      },
    );

    emptyInstallationGroups.forEach((emptyInstallation) => {
      groupsWithApps[emptyInstallation.id] = {
        groupId: emptyInstallation.id,
        groupName: emptyInstallation.displayName
          ? emptyInstallation.displayName
          : t('unknownGroupName'),
        apps: [],
      };
    });

    return sortBy(Object.values(groupsWithApps), (group: any) => group.groupName);
  }, [apps, installationGroups, t]);
  return groupedApps;
};

export default useGroupedAppsByInstallationGroups;
