import { useQuery } from 'react-query';
import { useApiService } from '../api-service-provider';
import DeviceTypeEnum from '../../../store/types/device-type.enum';

interface UseAppSchemaProps {
  type: DeviceTypeEnum;
}

const useAppSchema = ({ type }: UseAppSchemaProps) => {
  const apiService = useApiService();

  return useQuery(
    ['boot-releases', type],
    () => apiService.get<string[]>(`/api/schemas/releases?deviceType=${  type}`),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export default useAppSchema;
