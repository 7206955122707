import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface ScrollContentToTopProps {
  contentId?: string;
}

const ScrollContentToTop = (props: ScrollContentToTopProps) => {
  const { contentId = "scrollable-content" } = props;

  const { pathname } = useLocation();

  useEffect(() => {
    const scrollableContent = document.getElementById(contentId);

    if (scrollableContent) {
      scrollableContent.scrollTop = 0;
    }
  }, [contentId, pathname]);

  return null;
};

export default ScrollContentToTop;
