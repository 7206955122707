import { action, Action, Select, select, thunk, Thunk } from 'easy-peasy';
import { ApiError } from '../../../services/api/api-error';
import Injections from '../../injections.interface';
import MarketplaceSolution from '../../types/marketplace-solution';

export interface OrganisationMarketplaceSolutionsModel {
  data: { [organisationId: string]: MarketplaceSolution[] };
  error: { [organisationId: string]: Error | null };
  loading: { [organisationId: string]: boolean };
  loaded: Select<
    OrganisationMarketplaceSolutionsModel,
    (organisationId: string) => boolean
  >;
  setData: Action<
    OrganisationMarketplaceSolutionsModel,
    { key: string; data: MarketplaceSolution[] }
  >;
  setError: Action<
    OrganisationMarketplaceSolutionsModel,
    { key: string; error: ApiError | null }
  >;
  setLoading: Action<
    OrganisationMarketplaceSolutionsModel,
    { key: string; loading: boolean }
  >;
  fetch: Thunk<
    OrganisationMarketplaceSolutionsModel,
    { organisationId: string },
    Injections
  >;
}

const organisationMarketplaceSolutionsModel: OrganisationMarketplaceSolutionsModel = {
  loading: {},
  error: {},
  data: {},
  loaded: select((state) => (key: string) => !!state.data[key] && !state.loading[key]),
  setLoading: action((state, { key, loading }) => {
    state.loading[key] = loading;
  }),
  setError: action((state, { key, error }) => {
    state.error[key] = error;
  }),
  setData: action((state, { key, data }) => {
    state.data[key] = data;
  }),
  fetch: thunk(async (actions, { organisationId }, { injections }) => {
    actions.setLoading({ key: organisationId, loading: true });
    actions.setError({ key: organisationId, error: null });
    try {
      const data = await injections.apiService.get<MarketplaceSolution[]>(
        `/api/organizations/${organisationId}/marketplace-solutions`,
      );
      actions.setData({ key: organisationId, data });
    } catch (error) {
      actions.setError({ key: organisationId, error });
    } finally {
      actions.setLoading({ key: organisationId, loading: false });
    }
  }),
};

export default organisationMarketplaceSolutionsModel;
