import React from 'react';
import styled from '@emotion/styled';
import { List, Avatar, Icon } from 'antd';
import { PlanFeature } from '../../../../../store/types/organisation-plan';
import { getTranslationFromObject } from '../../../../../utils/language/language.util';
import { fallbackIconAlt } from '../../../../../utils/installations/get-installation-app-icon-url';

interface PlanItems {
  items: PlanFeature[];
}

const PlanItems = ({ items }: { items: PlanFeature[] }) => {
  return (
    <PlanItemList
      itemLayout="horizontal"
      bordered
      size="large"
      dataSource={items}
      shownodataui="false"
      renderItem={(item: PlanFeature) => {
        const { name, description, icon } = item;

        const itemName = getTranslationFromObject(name);
        const itemDescription = getTranslationFromObject(description);
        const iconUrl = icon.includes('http') ? icon : undefined;
        const iconKey = !iconUrl ? icon : undefined;

        return (
          <List.Item>
            <List.Item.Meta
              avatar={
                <PlanItemAvatar shape="square" icon={iconKey} src={iconUrl}>
                  <FallbackIcon component={() => <img src={fallbackIconAlt} alt="" />} />
                </PlanItemAvatar>
              }
              title={<PlanItemName>{itemName}</PlanItemName>}
              description={<PlanItemDetails>{itemDescription}</PlanItemDetails>}
            />
          </List.Item>
        );
      }}
    />
  );
};

const PlanItemList = styled(List)<{ shownodataui?: 'true' | 'false' }>`
  border-radius: 0px;
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;

  .ant-list-item {
    padding-left: 20px;
    padding-right: 20px;
  }

  ${({ shownodataui }) => {
    return (
      shownodataui === 'false' &&
      `
        .ant-list-empty-text {
          padding: 0px;
        }
        .ant-empty {
          display: none
        }
      `
    );
  }}
` as any;

const PlanItemAvatar = styled(Avatar)`
  font-size: 25px !important;
  top: 3px;

  .ant-avatar-string {
    transform: none !important;
    position: unset !important;
  }
`;

const PlanItemName = styled.div`
  font-size: 16px;
`;

const PlanItemDetails = styled.div`
  font-size: 14px;
`;

const FallbackIcon = styled(Icon)`
  min-height: 25px;
  min-width: 25px;
  position: relative;
  top: -5px;
`;

export default React.memo(PlanItems);
