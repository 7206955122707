import { action, Action, select, Select, thunk, Thunk } from 'easy-peasy';
import Injections from '../../injections.interface';
import { ApiError } from '../../../services/api/api-error';
import ModuleVersion from '../../types/module-version';

export interface CreateVersionParams {
  moduleId: string;
  version: string;
  settingsSchema: any;
  metaSchema: any;
  url: string;
}

interface Crud2Model<T extends { id: string } = any, P = void> {
  data: {
    [moduleId: string]: {
      [id: string]: T;
    };
  };
  loading: {
    [moduleId: string]: boolean;
  };
  error: {
    [moduleId: string]: ApiError | null;
  };
  values: Select<Crud2Model<T, P>, (moduleId: string) => T[]>;
  ids: Select<Crud2Model<T, P>, (moduleId: string) => string[]>;
  loaded: Select<Crud2Model<T, P>, (moduleId: string) => boolean>;
  setLoading: Action<Crud2Model<T, P>, { moduleId: string; loading: boolean }>;
  setError: Action<Crud2Model<T, P>, { moduleId: string; error: ApiError | null }>;
  setData: Action<Crud2Model<T, P>, { moduleId: string; data: T[] }>;
  setSingle: Action<Crud2Model<T, P>, { moduleId: string; data: T }>;
  unsetSingle: Action<Crud2Model<T, P>, { moduleId: string; id: string }>;
  fetch: Thunk<Crud2Model<T, P>, { moduleId: string }, Injections>;
}

export type ModuleVersionsModel = Crud2Model<ModuleVersion>;

const model: ModuleVersionsModel = {
  data: {},
  loading: {},
  error: {},
  values: select((state) => (moduleId: string) =>
    Object.values(state.data[moduleId] || {}).sort(
      (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    ),
  ),
  ids: select((state) => (moduleId: string) => Object.keys(state.data[moduleId] || {})),
  loaded: select((state) => (moduleId: string) =>
    !!state.data[moduleId] && !state.loading[moduleId],
  ),
  setSingle: action((state, { moduleId, data }) => {
    if (!state.data[moduleId]) {
      state.data[moduleId] = {};
    }
    state.data[moduleId][data.id] = data;
  }),
  unsetSingle: action((state, { moduleId, id }) => {
    if (state.data[moduleId]) {
      delete state.data[moduleId][id];
    }
  }),
  setData: action((state, { moduleId, data }) => {
    if (!state.data[moduleId]) {
      state.data[moduleId] = {};
    }
    data.forEach((item) => {
      state.data[moduleId][item.id] = item;
    });
  }),
  setLoading: action((state, { moduleId, loading }) => {
    state.loading[moduleId] = loading;
  }),
  setError: action((state, { moduleId, error }) => {
    state.error[moduleId] = error;
  }),
  fetch: thunk(async (actions, { moduleId }, { injections }) => {
    actions.setError({ moduleId, error: null });
    actions.setLoading({ moduleId, loading: true });
    try {
      const data = await injections.apiService.get<ModuleVersion[]>(
        `/api/modules/${moduleId}/versions`,
      );
      actions.setData({ moduleId, data });
    } catch (error) {
      actions.setError({ moduleId, error });
    } finally {
      actions.setLoading({ moduleId, loading: false });
    }
  }),
};

export default model;
