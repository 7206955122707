import { useQuery, UseQueryResult } from 'react-query';
import { useApiService } from '../api-service-provider';
import Environment from '../../../store/types/environment';
import { ApiError } from '../../../services/api/api-error';

type UseEnvironmentsResponse = UseQueryResult<
  Environment[],
  ApiError
>;

const useEnvironments = (tenantId: string): UseEnvironmentsResponse => {
  const apiService = useApiService();

  const result = useQuery(
    getEnvironmentsKeys(tenantId),
    () =>
      apiService.get<Environment[]>('/api/environments', {
        organizationId: tenantId,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      keepPreviousData: true,
      onError: (_error: ApiError) => {},
    },
  );

  return result;
};

export const getEnvironmentsKeys = (tenantId?: string): string[] => {
  return ['get-environments', ...(tenantId ? ['tenantId', tenantId] : [])];
};

export default useEnvironments;
