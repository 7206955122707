import React, { useCallback } from 'react';
import useQueryStringParam from '../common/use-query-string-param/use-query-string-param';
import Tos from '../common/tos/tos.component';

export default function TermsOfServiceComponent() {
  const callback = useQueryStringParam('callback');

  // eslint-disable-next-line consistent-return
  const handleTosConfirm = useCallback(() => {
    if (!callback) {
      return '';
    }

    const url = new URL(callback || '');
    url.searchParams.append('consent', 'true');
    document.location.href = url.href;
  }, [callback]);

  return <Tos onConfirm={handleTosConfirm} />;
}
