import toNumber from 'lodash/toNumber';

export const isNumberInRangeRegexp = new RegExp(
  '^(?:[1-9][0-9]{3}|[1-9][0-9]{2}|[1-9][0-9]|[1-9])$',
);

export const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const castToNumber = (value: string): number | undefined =>
  value ? toNumber(value) : undefined;
