import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useApiService } from '../api-service-provider';
import { DeviceTunnelLink } from '../../../store/types/device-tunnel-link';

interface UseCreateDeviceTunnelLink extends Omit<DeviceTunnelLink, 'id'> {};

const getQueryKeys = ({ tenantId, deviceId }: { tenantId?: string; deviceId?: string }) => {
  const queryKey: string[] = ['device-tunnel-link'];

  if (tenantId) {
    queryKey.push('tenant');
    queryKey.push(tenantId);
  }

  if (deviceId) {
    queryKey.push('device');
    queryKey.push(deviceId);
  }

  return queryKey;
}

export const useCreateDeviceTunnelLink = () => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  const url = `/api/device-tunnel-link`;

  return useMutation(
    (body: UseCreateDeviceTunnelLink) =>
      apiService.post(
        url,
        body,
      ),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(
          getQueryKeys({ deviceId: variables.deviceId }),
        );
        queryClient.invalidateQueries(
          getQueryKeys({ tenantId: variables.organizationId }),
        );
      },
    },
  );
};

export const useDeviceTunnelLinksByDeviceId = (deviceId: string) => {
  const apiService = useApiService();

  const url = `/api/device-tunnel-link?deviceId=${deviceId}`;

  return useQuery(
    getQueryKeys({ deviceId }),
    () => apiService.get<DeviceTunnelLink[]>(url),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      keepPreviousData: true,
    },
  );
};

export const useUpdateDeviceTunnelLink = () => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  return useMutation(
    (body: DeviceTunnelLink) =>
      apiService.put(
        `/api/device-tunnel-link/${body.id}`,
        body,
      ),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(
          getQueryKeys({ deviceId: variables.deviceId }),
        );
        queryClient.invalidateQueries(
          getQueryKeys({ tenantId: variables.organizationId }),
        );
      },
    },
  );
};

export const useDeleteDeviceTunnelLink = ({
  deviceTunnelLinkId,
  organizationId,
  deviceId,
}: {
  deviceTunnelLinkId: string;
  organizationId: string;
  deviceId: string;
}) => {

  const apiService = useApiService();
  const queryClient = useQueryClient();

  const url = `/api/device-tunnel-link/${deviceTunnelLinkId}`;

  return useMutation(
    () =>
      apiService.delete(url),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(
          getQueryKeys({ deviceId }),
        );
        queryClient.invalidateQueries(
          getQueryKeys({ tenantId: organizationId }),
        );
      },
    },
  );
};