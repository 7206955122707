import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useApiService } from '../api-service-provider';
import CurrencyAwareValue from '../../../store/types/currency-aware-value';
import { PaginationCollection } from '../../../store/types/pagination';

export enum SkuAvailability {
  PRIVATE = 'private',
  PUBLIC = 'public',
};

export type SkuPriceInformation = {
  startDate: string;
  endDate: string;
  discountPercent: number
  prices: CurrencyAwareValue[],
}

export interface SkuTags {
  pricingSection?: string;
}

export interface Sku {
  id: string;
  sku: string;
  name: string;
  unit: string;
  availability: SkuAvailability;
  tags?: SkuTags;
  revenueType: string;
  priceInformation: SkuPriceInformation[];
  tenants: string[];
  activePrice?: SkuPriceInformation;
  activePriceValue?: CurrencyAwareValue;
}

export interface SkuPayload {
  id?: string;
  sku: string;
  name: string;
  unit: string;
  availability: SkuAvailability;
  tags?: SkuTags;
  revenueType: string;
  priceInformation: SkuPriceInformation[];
}

const queryKeys = {
  getSkusList: (page: number = 1, limit: number = 50, sortField = 'sku', sortOrder = 'asc', searchTerm = '') => [
    'page',
    page,
    'limit',
    limit,
    'sortField',
    sortField,
    'sortOrder',
    sortOrder,
    'searchTerm',
    searchTerm,
  ],
  getSku: (skuId: string) => [
    'skuId',
    skuId,
  ],
}

export const useAddSku = () => {
  const apiService = useApiService();
  return useMutation(
    async (body: SkuPayload) => {
      const url = '/api/skus';
      return apiService.post<Sku>(url, {
        ...body
      });
    },
  )

}

export const useUpdateSku = (skuId: string) => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  const url = `/api/skus/${skuId}`;

  return useMutation(
    async (body: SkuPayload) => {
      return apiService.put<Sku>(url, body);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.getSku(skuId));
      }
    },
  )
}

export const useSku = (skuId: string) => {
  const apiService = useApiService();

  return useQuery(
    queryKeys.getSku(skuId),
    () => apiService.get<Sku>(`/api/skus/${skuId}`),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  )
}

export const useSkus = (page = 1, limit = 50, sortField = 'sku', sortOrder = 'asc', searchTerm = '') => {
  const apiService = useApiService();

  return useQuery(
    queryKeys.getSkusList(page, limit, sortField, sortOrder, searchTerm),
    () => 
      apiService.get<PaginationCollection<Sku>>('/api/skus', {
        page,
        limit,
        sortField,
        sortOrder,
        ...( searchTerm && searchTerm.trim().length >= 3 ? { searchTerm: searchTerm.trim()} : {}),
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    }
  )
}

export default useSkus;
