import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { List, Avatar, Icon, Tag } from 'antd';
import { EnterpriseAgreement } from '../../../store/types/enterprise-agreement';
import { stringToColour, colorInverse } from '../../../utils/color';
import {
  getStatusColor,
  convertEmailToInitials,
  getFormattedAgreementCoverage,
} from '../../../utils/enterprise-agreement';
import { formatCurrencyAwareValue } from '../../../utils/currency';

interface AgreementItemProps {
  agreement: EnterpriseAgreement;
  onClick: (agreement?: EnterpriseAgreement) => void;
}

const AgreementItem = (props: AgreementItemProps) => {
  const { agreement, onClick } = props;
  const {
    name,
    startDate,
    durationMonths,
    status,
    createdBy,
    committedConsumption,
  } = agreement;

  const { fromDate, toDate } = useMemo(() => {
    return getFormattedAgreementCoverage({
      durationMonths,
      startDate: startDate as Date,
      status,
      format: 'DD/MM/YYYY',
    });
  }, [durationMonths, startDate, status]);

  // TO DO: Create a reusable avatar component instead
  const { avatarInitials, avatarBackgroundColor, avatarForegroundColor } = useMemo(() => {
    const { email = '?' } = createdBy || {};
    const initials = convertEmailToInitials(email);
    const backgroundColor = stringToColour(email);
    const foregroundColor = colorInverse(backgroundColor);

    return {
      avatarInitials: initials,
      avatarBackgroundColor: backgroundColor,
      avatarForegroundColor: foregroundColor,
    };
  }, [createdBy]);

  const { statusDesc, statusColor } = useMemo(() => {
    return {
      statusDesc: status.replace('_', ' '),
      statusColor: getStatusColor(status),
    };
  }, [status]);

  return (
    <AgreementListItem
      extra={
        <AgreementMakerAvatar
          foregroundcolor={avatarForegroundColor}
          backgroundcolor={avatarBackgroundColor}
          size={50}
        >
          {avatarInitials}
        </AgreementMakerAvatar>
      }
      onClick={() => onClick(agreement)}
    >
      <List.Item.Meta
        title={
          <AgreementTitle>
            <div>{name}</div>
            <StatusTag color={statusColor.background} textcolor={statusColor.foreground}>
              {statusDesc}
            </StatusTag>
          </AgreementTitle>
        }
        description={
          <AgreementSummary>
            <div>
              <Icon type="dollar" />
              <span>
                {committedConsumption
                  ? formatCurrencyAwareValue(committedConsumption)
                  : '-'}
              </span>
            </div>
            <div>
              <Icon type="calendar" />
              <span>{`${fromDate} - ${toDate}`}</span>
            </div>
          </AgreementSummary>
        }
      />
    </AgreementListItem>
  );
};

const AgreementListItem = styled(List.Item)`
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: #eeeeeeef;
  }

  .ant-list-item-extra {
    margin: auto auto;
  }
`;

// eslint-disable-next-line no-unexpected-multiline
const AgreementMakerAvatar = styled(Avatar)<{
  foregroundcolor?: string;
  backgroundcolor?: string;
}>`
  ${({ foregroundcolor }) => foregroundcolor && `color: ${foregroundcolor};`}
  ${({ backgroundcolor }) => backgroundcolor && `background-color: ${backgroundcolor};`}
`;

const AgreementTitle = styled.div`
  > div {
    display: inline-grid;
    margin-right: 10px;
  }
`;

export const StatusTag = styled(Tag)<{ textcolor?: string }>`
  text-transform: capitalize;
  ${({ textcolor }) => textcolor && `color: ${textcolor}`}
`;

const AgreementSummary = styled.div`
  div {
    margin-top: 7px;
  }
  .anticon {
    margin-right: 5px;
    color: #000000a6;
    font-size: 20px;
  }
  span {
    position: absolute;
    margin-top: 0px;
  }
`;

export default AgreementItem;
