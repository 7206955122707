export enum ScheduleFrequencyEnum {
  EVERY_DAY = 'EveryDay',
}

export interface CronParseExpressionResult {
  frequency: ScheduleFrequencyEnum | null;
  minute: number;
  hour: number;
  days: number[];
}

export interface CronParseFields {
  second: number[];
  minute: number[];
  hour: number[];
  dayOfMonth: number[];
  month: number[];
  dayOfWeek: number[];
}

export interface CronSchedule {
  frequency: ScheduleFrequencyEnum;
  time?: string;
  cronExpression: string;
  cronExpressionUtc: string;
}
