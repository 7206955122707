import { useCallback, useState } from 'react';
import ApiService from '../../../services/api/api.service';
import { getApiUrl } from '../../../utils/env';

const apiService = new ApiService(getApiUrl());

enum Status {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

interface ValidateDeviceNameResponse {
  valid: boolean;
  code: number;
  message: string;
}

interface UseValidateDeviceNameResponse {
  isIdle: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isFailure: boolean;
  data: ValidateDeviceNameResponse;
  validate: () => Promise<ValidateDeviceNameResponse>;
  reset: () => void;
}

const useValidateDeviceName = () => {
  const [status, setStatus] = useState<Status>(Status.IDLE);
  const [data, setData] = useState<ValidateDeviceNameResponse>();

  const validate = useCallback(
    async (
      organizationId: string,
      deviceName: string,
    ): Promise<ValidateDeviceNameResponse | null> => {
      try {
        setStatus(Status.LOADING);

        const url = `/api/v4/devices/${deviceName}/validate?organizationId=${organizationId}`;
        const result = await apiService.get<ValidateDeviceNameResponse>(url);

        setData(result);
        setStatus(Status.SUCCESS);

        return result;
      } catch {
        setStatus(Status.FAILURE);

        return null;
      }
    },
    [],
  );

  const reset = useCallback(() => {
    if (status !== Status.IDLE) {
      setStatus(Status.IDLE);
      setData(undefined);
    }
  }, [status]);

  return {
    isIdle: status === Status.IDLE,
    isLoading: status === Status.LOADING,
    isSuccess: status === Status.SUCCESS,
    isFailure: status === Status.FAILURE,
    data,
    validate,
    reset,
  };
};

export default useValidateDeviceName;
