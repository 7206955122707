import React, { useMemo } from 'react';
import groupBy from 'lodash/groupBy';
import keys from 'lodash/keys';
import {
  useTenantEventsByDay,
  useInstallationEventsByDay,
  useSpaceEventsByDay,
  useDeviceEventsByDay,
  useAppEventsByDay,
  useInstallationEventsBySpaceAndDay,
} from '../../use-analytics-report';
import { DataResidencyEnum } from '../../../../store/types/organisation';
import CardContainer from './card-container';
import AreaCard from '../charts/area-card.component';
import {
  DeviceEventsByDay,
  AppEventsByDay,
  InstallationEventsByDay,
  SpaceEventsByDay,
  TenantEventsByDay,
  DayDataType,
  InstallationAndSpaceEventsByDay,
  ReportContext,
} from '../../use-analytics-report/aggregated-reports/types';
import { GridStyles } from '@ombori/grid-reports';
import { DataSourceBaseEntity } from '@ombori/grid-reports/dist/types';
import { useStore } from 'easy-peasy';
import { RootState } from '../../../../store/models/root.model';
import OrganisationSpace from '../../../../store/types/organisation-space';

const noneKey = 'none';

type DataItem = [
  string,
  {
    label: string;
    value: number;
  }[]
][];

type GroupedDataItem = [string, DataItem][];

interface EventsCountProps {
  tenantId: string;
  dateFrom: string;
  dateTo: string;
  title: string;
  eventType: string;
  dataResidency: DataResidencyEnum;
  gridStyles?: GridStyles;
  isVisibleWithoutData?: boolean;
  dataSource?: DataSourceBaseEntity;
  reportContext?: ReportContext;
}

interface InstallationEventsProps {
  tenantId: string;
  installationId: string;
  dateFrom: string;
  dateTo: string;
  dataResidency: DataResidencyEnum;
  title: string;
  gridStyles?: GridStyles;
  isVisibleWithoutData?: boolean;
  eventType: string;
}

type DynamicCardsProps = {
  data: GroupedDataItem;
  state: {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
  };
  gridStyles?: GridStyles;
  isVisibleWithoutData?: boolean;
  title: string;
  titleData?: TitleData[];
};

interface SpaceEventsCountProps extends EventsCountProps {
  spaceId: string;
}

interface InstallationAndSpaceEventsProps extends InstallationEventsCountProps {}
interface DeviceEventsCountProps extends EventsCountProps {
  deviceId: string;
}
interface AppEventsCountProps extends EventsCountProps {
  appId: string;
}

interface InstallationEventsCountProps extends EventsCountProps {
  installationId: string;
  dataSource?: DataSourceBaseEntity;
  reportContext?: ReportContext;
}

interface TitleData {
  id: string;
  title: string;
}

const buildDataItem = (
  date: string,
  data: { label: string; value: number }[],
): [string, { label: string; value: number }[]] => [date, data];

const buildData = (
  rawData: (
    | TenantEventsByDay
    | InstallationEventsByDay
    | SpaceEventsByDay
    | DeviceEventsByDay
    | AppEventsByDay
    | InstallationAndSpaceEventsByDay)[],
  eventType: string,
  reportContext?: ReportContext,
  dataSource?: DataSourceBaseEntity,
): GroupedDataItem => {
  const filteredData = rawData
    .filter((dataItem) => dataItem.eventType === eventType)
    .map((dataItem) => {
      if (
        reportContext === ReportContext.Installation &&
        dataItem.type === DayDataType.InstallationAndSpaceEventsByDay &&
        dataSource === DataSourceBaseEntity.Space
      ) {
        return { key: dataItem.spaceId, ...dataItem };
      }
      return {
        ...dataItem,
        key: noneKey,
      };
    });

  const contextGroupedEvents = groupBy(filteredData, (dataItem) => dataItem.key);
  return keys(contextGroupedEvents).map((key) => {
    const events = contextGroupedEvents[key];

    const dateGroupedEvents = groupBy(events, (dataItem) => dataItem.date);
    const result = keys(dateGroupedEvents).map((date) => {
      const list = dateGroupedEvents[date].map((dataItem) => ({
        label: dataItem.eventType,
        value: dataItem.count,
      }));

      const result = buildDataItem(date, list);

      return result;
    });
    return [key, result];
  });
};

const getTitle = ({
  key = noneKey,
  title,
  data = [],
}: {
  key?: string;
  title: string;
  data?: TitleData[];
}): string => {
  if (key === noneKey) {
    return title;
  } else {
    const foundData = data.find((item) => item.id === key);
    return foundData ? foundData.title : title;
  }
};

export const InstallationEvents: React.FC<InstallationEventsProps> = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  dataResidency,
  title,
  gridStyles,
  isVisibleWithoutData,
  eventType,
}) => {
  const installationEventsByDayState = useInstallationEventsByDay({
    tenantId,
    installationId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const data = useMemo(
    () => buildData(installationEventsByDayState.data || [], eventType),
    [installationEventsByDayState, eventType],
  );

  return (
    <DynamicCards
      data={data}
      state={installationEventsByDayState}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
      title={title}
    />
  );
};

export const TenantEventsCount: React.FC<EventsCountProps> = ({
  tenantId,
  dateFrom,
  dateTo,
  title,
  eventType,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const tenantEventsByDayState = useTenantEventsByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const data = useMemo(() => buildData(tenantEventsByDayState.data || [], eventType), [
    tenantEventsByDayState,
    eventType,
  ]);

  return (
    <DynamicCards
      data={data}
      state={tenantEventsByDayState}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
      title={title}
    />
  );
};

export const InstallationEventsCount: React.FC<InstallationEventsCountProps> = (
  props,
) => {
  const { dataSource } = props;

  if (dataSource === DataSourceBaseEntity.Space) {
    return <InstallationAndSpaceEvents {...props} />;
  }
  return <InstallationEvents {...props} />;
};

export const InstallationAndSpaceEvents: React.FC<InstallationAndSpaceEventsProps> = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  dataResidency,
  title,
  gridStyles,
  isVisibleWithoutData,
  eventType,
  dataSource,
  reportContext,
}) => {
  const installationAndSpaceEventsByDayState = useInstallationEventsBySpaceAndDay({
    tenantId,
    installationId,
    dateFrom,
    dateTo,
    dataResidency,
    dataSource,
  });

  const { titleData } = useStore<RootState>(
    (state) => {
      const spacesData = state.organisationSpaces.values(tenantId);
      const titleData = spacesData.map((spaceData: OrganisationSpace) => ({
        id: spaceData.id,
        title: spaceData.displayName,
      }));
      return { titleData };
    },
    [tenantId],
  );

  const data = useMemo(
    () =>
      buildData(
        installationAndSpaceEventsByDayState.data || [],
        eventType,
        reportContext,
        dataSource,
      ),
    [installationAndSpaceEventsByDayState, eventType, reportContext, dataSource],
  );

  return (
    <DynamicCards
      data={data}
      state={installationAndSpaceEventsByDayState}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
      title={title}
      titleData={titleData}
    />
  );
};

const CardWrapper: React.FC<{
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  hasData: boolean;
  title: string;
  gridStyles?: GridStyles;
  isVisibleWithoutData?: boolean;
  children: React.ReactNode;
}> = ({
  isLoading,
  isSuccess,
  isError,
  hasData,
  title,
  gridStyles,
  isVisibleWithoutData,
  children,
}) => (
  <CardContainer
    isLoading={isLoading}
    isSuccess={isSuccess}
    isError={isError}
    hasData={hasData}
    title={title}
    gridStyles={gridStyles}
    isVisibleWithoutData={isVisibleWithoutData}
  >
    {children}
  </CardContainer>
);

const DynamicCards: React.FC<DynamicCardsProps> = ({
  data,
  state,
  gridStyles,
  isVisibleWithoutData,
  title,
  titleData,
}) => {
  if (data.length === 0) {
    const titleStr = getTitle({ title });

    return (
      <CardWrapper
        {...state}
        hasData={false}
        isVisibleWithoutData={isVisibleWithoutData}
        title={titleStr}
        gridStyles={gridStyles}
      >
        {state.isSuccess && <AreaCard title={titleStr} data={[]} showCard={false} />}
      </CardWrapper>
    );
  }

  return (
    <>
      {data &&
        data.map((dataItem) => {
          const titleStr = getTitle({ key: dataItem[0], title, data: titleData });

          const data = dataItem[1];

          return (
            <CardWrapper
              {...state}
              hasData={data.length > 0}
              title={titleStr}
              gridStyles={gridStyles}
              isVisibleWithoutData={isVisibleWithoutData}
              key={dataItem[0]}
            >
              {state.isSuccess && (
                <AreaCard title={titleStr} data={data} showCard={false} />
              )}
            </CardWrapper>
          );
        })}
    </>
  );
};

export const SpaceEventsCount: React.FC<SpaceEventsCountProps> = ({
  tenantId,
  spaceId,
  dateFrom,
  dateTo,
  title,
  eventType,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const spaceEventsByDayState = useSpaceEventsByDay({
    tenantId,
    spaceId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const data = useMemo(() => buildData(spaceEventsByDayState.data || [], eventType), [
    spaceEventsByDayState,
    eventType,
  ]);

  return (
    <DynamicCards
      data={data}
      state={spaceEventsByDayState}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
      title={title}
    />
  );
};

export const DeviceEventsCount: React.FC<DeviceEventsCountProps> = ({
  tenantId,
  deviceId,
  dateFrom,
  dateTo,
  title,
  eventType,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const deviceEventsByDayState = useDeviceEventsByDay({
    tenantId,
    deviceId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const data = useMemo(() => buildData(deviceEventsByDayState.data || [], eventType), [
    deviceEventsByDayState,
    eventType,
  ]);

  return (
    <DynamicCards
      data={data}
      state={deviceEventsByDayState}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
      title={title}
    />
  );
};

export const AppEventsCount: React.FC<AppEventsCountProps> = ({
  tenantId,
  appId,
  dateFrom,
  dateTo,
  title,
  eventType,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const appEventsByDayState = useAppEventsByDay({
    tenantId,
    appId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const data = useMemo(() => buildData(appEventsByDayState.data || [], eventType), [
    appEventsByDayState,
    eventType,
  ]);

  return (
    <DynamicCards
      data={data}
      state={appEventsByDayState}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
      title={title}
    />
  );
};
