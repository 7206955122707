import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { Select as AntdSelect } from 'antd';
import { Field, FormSpy } from 'react-final-form';
import { TimePicker } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import InputWrap from '../input-wrap';
import { Option } from '../select';
import { ChangeEvent, SelectType } from '../../../../types';
import { FormTextInput } from '../../../organisations/organisation-details/queue-details/queue-setup/styled-block';
import { TimePickerProps } from 'antd/lib/time-picker';
import get from 'lodash/get';
import { CronSchedule, ScheduleFrequencyEnum } from './types';
import { generateCronExpression } from './utils';
import { requiredAll } from '../../../organisations/organisation-details/queue-details/queue-setup/validate-form';
import { Validator, composeValidators } from '../validator';

const timeFormat = 'HH:mm';

interface CronScheduleTime extends Omit<TimePickerProps, 'defaultValue' | 'disabled'> {
  defaultValue?: string;
  isEnabled?: boolean;
  validators?: Validator[];
}

interface CronSchedulePickerProps {
  name: string;
  isRequired?: boolean;
  options: Option[];
  frequency?: {
    defaultValue?: ScheduleFrequencyEnum;
    isEnabled?: boolean;
    validators?: Validator[];
  }
  time?: CronScheduleTime;
}

const CronSchedulePicker = (props: CronSchedulePickerProps) => {
  const { name, isRequired = false, options, frequency = {}, time = {} } = props;

  const { defaultValue: defaultFrequencyValue = undefined, isEnabled: isFrequencyEnabled = true, validators: frequencyValidators = [] } = frequency;
  const { defaultValue: defaultTimeValue = undefined, isEnabled: isTimeEnabled = true, validators: timeValidators = [], ...restTimeProps } = time;

  const hasFrequencyMounted = useRef<boolean>(false);
  const hasTimeMounted = useRef<boolean>(false);

  const { t } = useTranslation();

  const inputFrequencyValidators = [...(isRequired ? [requiredAll] : []), ...frequencyValidators];
  const inputTimeValidators = [...(isRequired ? [requiredAll] : []), ...timeValidators];

  return (
    <>
      <CronSchedulePickerContainer>
        <Field name={`${name}.frequency`} validate={composeValidators(inputFrequencyValidators)}>
          {({ input, meta }) => {
            const isError = !!meta.error && meta.touched;
            const inputValue = input.value as ScheduleFrequencyEnum | undefined;

            const handleChange = (value: ScheduleFrequencyEnum) => {
              input.onChange({ target: { value: value } } as unknown as ChangeEvent<ScheduleFrequencyEnum>);
            };

            if (!hasFrequencyMounted.current) {
              const initialValue = inputValue || defaultFrequencyValue;

              if (initialValue) {
                handleChange(initialValue);
              }

              hasFrequencyMounted.current = true;
            }

            return (
              <FrequencyInputField
                label={t('frequency')}
                isRequired={isRequired}
                error={isError ? meta.error : undefined}
              >
                <SelectStyled
                  data-name={input.name}
                  placeholder={t('selectValue')}
                  disabled={!isFrequencyEnabled}
                  value={input.value}
                  onChange={handleChange}
                >
                  {options.map((option) => {
                    const { value, label, icon } = option;

                    return (
                      <AntdSelect.Option key={value} value={value} data-label={label}>
                        <OptionWrap>
                          {icon}
                          {label || value}
                        </OptionWrap>
                      </AntdSelect.Option>
                    );
                  })}
                </SelectStyled>
              </FrequencyInputField>
            );
          }}
        </Field>
        <Field name={`${name}.time`} validate={composeValidators(inputTimeValidators)}>
          {({ input, meta }) => {
            const isError = !!meta.error && meta.touched;

            const handleChange = (_time: moment.Moment, timeString: string) => {
              input.onChange({ target: { value: timeString } } as unknown as ChangeEvent<string>);
            };

            if (!hasTimeMounted.current) {
              const initialValue = input.value || defaultTimeValue;

              if (initialValue) {
                handleChange(moment(initialValue, "HH:mm"), initialValue);
              }

              hasTimeMounted.current = true;
            }

            return (
              <TimeInputField
                label={t('productIntegration.atWhatTime')}
                isRequired={isRequired}
                error={isError ? meta.error : undefined}
              >
                <TimePicker
                  data-name={input.name}
                  defaultOpenValue={moment('00:00', timeFormat)}
                  format={timeFormat}
                  disabled={!isTimeEnabled}
                  value={input.value ? moment(input.value, timeFormat) : undefined}
                  onChange={handleChange}
                  {...restTimeProps}
                />
              </TimeInputField>
            );
          }}
        </Field>
        <Field name={`${name}.cronExpression`}>
          {({ input, meta }) => (
            <FormTextInput
              input={input}
              meta={meta}
              type="hidden"
            />
          )}
        </Field>
        <Field name={`${name}.cronExpressionUtc`}>
          {({ input, meta }) => (
            <FormTextInput
              input={input}
              meta={meta}
              type="hidden"
            />
          )}
        </Field>
        <FormSpy subscription={{ values: true }}>
          {({ form, values }) => {
            const schedule = get(values, name) as CronSchedule | undefined;

            if (schedule && schedule.time) {
              const { frequency, time } = schedule;

              const cronExpression = generateCronExpression(frequency, time);
  
              form.change(`${name}.cronExpression`, cronExpression);
  
              const timeLocal = moment(time, timeFormat);
              const timeUtcString = timeLocal.utc().format(timeFormat);

              const cronExpressionUtc = generateCronExpression(frequency, timeUtcString);
  
              form.change(`${name}.cronExpressionUtc`, cronExpressionUtc);
            }

            return null;
          }}
        </FormSpy>
      </CronSchedulePickerContainer>
    </>
  );
};

const CronSchedulePickerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const FrequencyInputField = styled(InputWrap)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const TimeInputField = styled(InputWrap)`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
`;

const SelectStyled = styled(AntdSelect)`
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;

  .ant-select-selection {
    height: 32px;
  }
  .ant-select-selection-selected-value {
    height: 32px;

    div:first-of-type {
      margin: 0px;
    }
  }
` as SelectType<ScheduleFrequencyEnum>;

const OptionWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export default CronSchedulePicker;
