import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { Row, Col, Button, Typography, Divider } from 'antd';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useTranslation } from 'react-i18next';
import PanelCard from '../../../../common/panel-card/panel-card.component';
import { StickyColumn } from '../../queue-details/queue-setup/styled-block';
import { ButtonType, ChangeEvent } from '../../../../../types';
import {
  Input,
  TextArea,
  validateCharacterLength,
  validateMaxLength,
} from '../../../../common/react-final-form';
import { ContentTagInputType, ContentTagValues } from '../../../../../store/hooks/content-tags/types';
import { requiredAll } from '../../queue-details/queue-setup/validate-form';
import TagFormMultiSelectSection from './tag-form-multi-select-section.componen';

export interface TagFormValues {
  name: string;
  description: string;
  type?: ContentTagInputType;
  values?: ContentTagValues;
}

interface TagFormProps {
  title: string;
  initialValues: TagFormValues;
  onFormSubmit: (values: TagFormValues) => Promise<TagFormApiValidationErrors>;
}

export interface TagFormApiValidationErrors {
  name?: string;
}

const TagFormFields = (props: TagFormProps) => {
  const { title, initialValues, onFormSubmit } = props;

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (values: TagFormValues | Object) => {
      setIsFormSubmitting(true);

      const errors = await onFormSubmit(values as TagFormValues);

      setIsFormSubmitting(false);

      return errors;
    },
    [onFormSubmit],
  );

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
      }}
      render={({ handleSubmit }) => {
        return (
          <form id="tag-form" onSubmit={handleSubmit}>
            <Row gutter={{ md: 20, xl: 40 }}>
              <Col md={40} xl={15}>
                <FormFieldsContainer>
                  <Row>
                    <Typography.Title level={3}>{title}</Typography.Title>
                    <Typography.Text>{t('contents.tagFormHelpNote')}</Typography.Text>
                  </Row>

                  <DividerFull />

                  <FieldsContainer>
                    <Input
                      name="name"
                      label={`${t('contents.name')} *`}
                      validators={[requiredAll, validateCharacterLength(3, 200)]}
                    />
                  </FieldsContainer>

                  <FieldsContainer>
                    <TextArea
                      name="description"
                      label={t('contents.description')}
                      validators={[validateMaxLength(500)]}
                    />
                  </FieldsContainer>
                </FormFieldsContainer>

                {/* TODO: for now only have multi-select as type of the tags */}
                <Field
                  name="type"
                  render={({ input }) => {
                    input.onChange({
                      target: {
                        value: ContentTagInputType.MULTI_SELECT,
                      },
                    } as unknown as ChangeEvent<string>);

                    return null;
                  }}
                />

                <FormFieldsContainer>
                  <TagFormMultiSelectSection />
                </FormFieldsContainer>
              </Col>

              <StickyColumn md={24} xl={9}>
                <PanelCard>
                  <SaveButton
                    type="primary"
                    form="tag-form"
                    htmlType="submit"
                    size="large"
                    loading={isFormSubmitting}
                  >
                    {t('contents.saveAllChanges')}
                  </SaveButton>
                </PanelCard>
              </StickyColumn>
            </Row>
          </form>
        );
      }}
    />
  );
};

export default TagFormFields;

const FormFieldsContainer = styled(PanelCard)`
  padding: 20px;
  width: 100% !important;
  margin-bottom: 20px;

  .ant-card-body {
    padding: 0px !important;
  }
`;

const FieldsContainer = styled.div`
  margin-bottom: 15px;
`;

const SaveButton = styled(Button)`
  width: 100%;
` as ButtonType;

const DividerFull = styled(Divider)`
  width: calc(100% + 41px);
  margin-left: -20px;
  overflow: none;
`;
