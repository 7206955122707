import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { Button, Icon } from 'antd';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/lib/table';
import Media from '../../../../../store/types/media';
import MediaListItemAvatar from '../../../../common/media-library/media-list-item/media-list-item-avatar.component';
import { MediaItemKind, getMediaItemKind } from '../../../../../utils/media-item-utils';
import { extractImagePreset } from './library-utils';
import ContentLibraryMediaListItemActions from './content-library-media-list-item-actions.component';
import { formatCreatedAt } from '../../../../../utils/date-formatter';
import { PaginationCollection } from '../../../../../store/types/pagination';
import ContentLibraryTable, { ContentLibraryTablePaginationProps } from './content-library-table.component';

interface ContentLibraryMediaListProps {
  tenantId: string;
  items: PaginationCollection<Media>;
  isLoadingItems: boolean;
  pagination: ContentLibraryTablePaginationProps;
  onSelectMedia?: (selectedMedia: Media) => void;
}

const ContentLibraryMediaList = (props: ContentLibraryMediaListProps) => {
  const { tenantId, items, isLoadingItems, onSelectMedia, pagination } = props;

  const { t } = useTranslation();

  const handleMediaItemClick = useCallback(
    (clickedMedia: Media) => {
      if (onSelectMedia) {
        onSelectMedia(clickedMedia);
      }
    },
    [onSelectMedia],
  );

  const columns = useMemo<ColumnProps<Media>[]>(() => {
    return [
      {
        title: t('contents.name'),
        key: 'name',
        width: '40%',
        style: { cursor: 'pointer' },
        // sorter: (recordA, recordB) => {
        //   return recordA.name.localeCompare(recordB.name);
        // },
        onCellClick: handleMediaItemClick,
        render: (_, record) => {
          return (
            <MediaItemName>
              <MediaListItemAvatar key={record.id} mediaItem={record} />
              <Button type="link">{record.name}</Button>
            </MediaItemName>
          );
        },
      },
      {
        title: t('contents.type'),
        key: 'type',
        width: '20%',
        // sorter: (recordA, recordB) => {
        //   return recordA.type.localeCompare(recordB.type);
        // },
        render: (_, record) => {
          return record.type;
        },
      },
      {
        title: t('contents.orientation'),
        key: 'orientation',
        width: '20%',
        // sorter: (recordA, recordB) => {
        //   const recordAPreset = extractImagePreset(recordA);
        //   const recordBPreset = extractImagePreset(recordB);

        //   return recordAPreset.localeCompare(recordBPreset);
        // },
        render: (_, record) => {
          if (getMediaItemKind(record) === MediaItemKind.image) {
            const preset = extractImagePreset(record);
            const presetLowercase = preset.toLowerCase();
            if (presetLowercase.includes('horizontal')) {
              return t('contents.landscape');
            } else if (presetLowercase.includes('vertical')) {
              return t('contents.portrait');
            }
          }

          return '';
        },
      },
      {
        title: t('contents.createdAt'),
        key: 'createdAt',
        width: '15%',
        // sorter: (recordA, recordB) => {
        //   return recordA.createdAt.localeCompare(recordB.createdAt);
        // },
        render: (_, record) => formatCreatedAt(record.createdAt),
      },
      {
        title: <Icon type="setting" />,
        key: 'actions',
        width: '5%',
        render: (_, record) => {
          return <ContentLibraryMediaListItemActions tenantId={tenantId} mediaItem={record} />;
        },
      },
    ];
  }, [t, tenantId, handleMediaItemClick]);

  return (
    <MediaList>
      <ContentLibraryTable<Media>
        items={items && items.docs ? items.docs : []}
        isLoadingItems={isLoadingItems}
        columns={columns}
        pagination={pagination}
      />
    </MediaList>
  );
};

export default ContentLibraryMediaList;

const MediaItemName = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const MediaList = styled.div``;
