import DeviceTypeEnum from '../store/types/device-type.enum';

const gridappTypeIcons: { [deviceType: string]: string } = {
  [DeviceTypeEnum.IOT]: 'hdd',
  [DeviceTypeEnum.MOBILE_WPA]: 'mobile',
  [DeviceTypeEnum.BALENA]: 'code-sandbox',
  [DeviceTypeEnum.LINUX]: 'linux',
  [DeviceTypeEnum.WINDOWS]: 'windows',
  [DeviceTypeEnum.WPA]: 'desktop',
  [DeviceTypeEnum.CLOUD]: 'cloud',
};

const getGridappTypeIcon = (deviceType: string) => {
  return gridappTypeIcons[deviceType] || '';
};

export default getGridappTypeIcon;
