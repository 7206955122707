import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import { FormTextInput } from './styled-block';
import { castToNumber } from './utils';

export default function AutoCheckOut() {
  const { t } = useTranslation();
  return (
    <>
      <Field name="autoCheckOutAfterMinutes" parse={castToNumber}>
        {({ input, meta }) => (
          <FormTextInput
            input={input}
            meta={meta}
            type="number"
            label={t('autoCheckOutAfterMinutes')}
          />
        )}
      </Field>
    </>
  );
}
