import React, { useEffect } from 'react';
import Modules from '../../common/azure-device-details-modules/modules.component';
import Overlay from '../../../../../common/overlay/overlay.component';
import Spinner from '../../../../../common/spinner/spinner.component';
import Device from '../../../../../../store/types/device';
import User from '../../../../../../store/types/user';
import Module from '../../../../../../store/types/module';
import { DeviceModules } from '../../../../../../store/types/device-module';
import UniversalDevice from '../../../../../../store/types/universal-device';

interface UniversalDeviceDetailsModulesProps {
  user: User | null;
  fetchModules: () => {};
  modules: Module[];
  deviceModules: DeviceModules;
  fetchDeviceModules: ({ id }: { id: string }) => Promise<void>;
  updateDeviceModules: ({
    id,
    modules,
  }: {
    id: string;
    modules: DeviceModules;
  }) => Promise<void>;
  canManageModules: boolean;
  universalDevice: UniversalDevice;
  fetchAzureDevice: (params: { deviceUuid: string; silent?: boolean }) => void;
  azureDeviceLoaded: boolean;
  azureDevice: Device | null;
}

const UniversalDeviceDetailsModules = (props: UniversalDeviceDetailsModulesProps) => {
  const {
    fetchModules,
    modules,
    user,
    fetchAzureDevice,
    universalDevice,
    azureDevice,
    azureDeviceLoaded,
    canManageModules,
    fetchDeviceModules,
    updateDeviceModules,
    deviceModules,
  } = props;

  const universalDeviceParentId =
    universalDevice && universalDevice.parentDevice
      ? universalDevice.parentDevice.id
      : null;

  useEffect(() => {
    if (universalDeviceParentId != null) {
      fetchAzureDevice({ deviceUuid: universalDeviceParentId });
    }
  }, [fetchAzureDevice, universalDeviceParentId]);

  if (!azureDeviceLoaded) {
    return (
      <Overlay>
        <Spinner />
      </Overlay>
    );
  }

  return (
    <Modules
      fetchModules={fetchModules}
      modules={modules}
      user={user}
      device={azureDevice}
      canManageModules={canManageModules}
      fetchDeviceModules={fetchDeviceModules}
      updateDeviceModules={updateDeviceModules}
      deviceModules={deviceModules}
    />
  );
};

export default UniversalDeviceDetailsModules;
