import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import styled from '@emotion/styled';
import { Tabs, Alert } from 'antd';
import get from 'lodash/get';
import { AnalyticsSchema, Card, CardType, VisibilityEnum } from '@ombori/grid-reports';
import { useStoreState } from '../../../../store/initialize-store';
import ErrorView from '../../error-view/error-view.component';
import Organisation from '../../../../store/types/organisation';
import { InstallationEventsList } from '../cards/events-list';
import { InstallationSessions } from '../cards/sessions';
import { InstallationRealtimeStatus } from '../cards/realtime-status';
import { InstallationMonitoringStatusHistory } from '../cards/monitoring-status-history';
import { InstallationEventsFlow } from '../cards/events-flow';
import { InstallationNps } from '../cards/nps';
import { InstallationProductsEventCount } from '../cards/products-event-count';
import { InstallationCategoriesEventCount } from '../cards/categories-event-count';
import { InstallationEventsFunnel } from '../cards/events-funnel';
import { InstallationWeekHeatmap } from '../cards/week-heatmap';
import { InstallationEventsCount } from '../cards/events-count';
import { InstallationAverageSales } from '../cards/average-sales';
import { InstallationAverageTimeBetweenTransactions } from '../cards/average-time-between-transactions';
import { InstallationAverageNumberOfPurchasedProducts } from '../cards/average-number-of-purchased-products';
import { InstallationPurchasedProductsCategoriesEventCount } from '../cards/purchased-products-categories-event-count';
import { InstallationPurchasedProductsEventCount } from '../cards/purchased-products-event-count';
import { InstallationQrCodesEventCount } from '../cards/qr-codes-event-count';
import { InstallationMedia } from '../cards/media';
import AnalyticsReport from '../analytics-report';
import Controls from '../controls';
import RangePicker from '../../range-picker/range-picker.component';
import useDateRange from '../../use-date-range';
import ErrorBoundary from '../../error-boundary/error-boundary.component';
import { useApp } from '../../use-app';
import Overlay from '../../overlay/overlay.component';
import Spinner from '../../spinner/spinner.component';
import { useTranslation } from 'react-i18next';
import OrganisationApp from '../../../../store/types/organisation-app';
import GridappBuild from '../../../../store/types/gridapp-build';
import validateSchema from '../validate-schema';
import { useGridAppBuilds } from '../../use-grid-app-builds';
import DownloadReport from '../download-report.component';
import { ReportContext } from '../../use-analytics-report';
import DataMatrix from '../cards/data-matrix';
import { InstallationEventsSuccessFailureRate } from '../cards/events-succes-failure-rate';
import { InstallationModulesStatusContainer } from '../cards/modules-status';
import { useAnalyticsParams } from '../../use-analytics-params';

const { TabPane } = Tabs;

const Container = styled.div``;

const WrapperContainer = styled.div``;

const AlertContainer = styled.div`
  margin-bottom: 30px;
`;

const getGridAppId = (app: OrganisationApp) =>
  get(app, 'settings.provider.app.gridApp.gridapp.id');

interface GetAppAnalyticsSchemaProps {
  app: OrganisationApp;
  appBuilds: GridappBuild[];
}

const getAppAnalyticsSchema = ({
  app,
  appBuilds,
}: GetAppAnalyticsSchemaProps): unknown => {
  try {
    const gridAppBuildId = get(app, 'settings.provider.app.gridApp.gridapp.buildId');
    const gridAppBuild = appBuilds.find((build) => build.id === gridAppBuildId);

    if (!gridAppBuild) {
      return undefined;
    }

    const analyticsSchema = get(gridAppBuild, 'metadata.analyticsSchema') as unknown;

    return analyticsSchema;
  } catch (error) {
    return undefined;
  }
};

interface CheckSchemaValidResult {
  isValid: true;
  analyticsSchema: AnalyticsSchema;
}

interface CheckSchemaInvalidResult {
  isValid: false;
  analyticsSchema: undefined;
}

type CheckSchemaResult = CheckSchemaValidResult | CheckSchemaInvalidResult;

const checkSchema = (analyticsSchema: unknown): CheckSchemaResult => {
  try {
    const validatedSchema = validateSchema(analyticsSchema);

    return { isValid: true, analyticsSchema: validatedSchema };
  } catch (error) {
    return { isValid: false, analyticsSchema: undefined };
  }
};

interface ReportProps {
  schema: AnalyticsSchema;
  tenant: Organisation;
  installationId: string;
  warningMessage?: string;
}

const Report: React.FC<ReportProps> = ({
  schema: { groups },
  tenant,
  installationId,
  warningMessage,
}) => {
  const { dateFrom, dateTo, onChangeRange } = useDateRange();

  const { updateAnalyticsParams } = useAnalyticsParams();

  useEffect(() => {
    updateAnalyticsParams({ fromDate: dateFrom, toDate: dateTo });
  }, [dateFrom, dateTo, updateAnalyticsParams]);

  const getComponent = useCallback(
    (reportCard: Card): React.ReactNode => {
      switch (reportCard.type) {
        case CardType.EventsList:
          return (
            <InstallationEventsList
              tenantId={tenant.id}
              installationId={installationId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              interactionType={reportCard.interactionType}
              gridStyles={reportCard.gridStyles}
              title={reportCard.title}
              events={reportCard.events}
              additionalData={reportCard.additionalData}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.EventsCount:
          return (
            <InstallationEventsCount
              tenantId={tenant.id}
              installationId={installationId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              title={reportCard.title}
              eventType={reportCard.eventType}
              gridStyles={reportCard.gridStyles}
              dataSource={reportCard.dataSource}
              reportContext={ReportContext.Installation}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.MonitoringRealtimeStatus:
          return (
            <InstallationRealtimeStatus
              tenantId={tenant.id}
              installationId={installationId}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              distinctColumns={reportCard.distinctColumns}
              eventType={reportCard.eventType}
              title={reportCard.title}
              statusReference={reportCard.statusReference}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.MonitoringStatusHistory:
          return (
            <InstallationMonitoringStatusHistory
              dateFrom={dateFrom}
              dateTo={dateTo}
              tenantId={tenant.id}
              installationId={installationId}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              eventType={reportCard.eventType}
              title={reportCard.title}
              statusReference={reportCard.statusReference}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.Sessions:
          return (
            <InstallationSessions
              tenantId={tenant.id}
              installationId={installationId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              interactionType={reportCard.interactionType}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.EventsFlow:
          return (
            <InstallationEventsFlow
              tenantId={tenant.id}
              installationId={installationId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.Nps:
          return (
            <InstallationNps
              tenantId={tenant.id}
              installationId={installationId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.ProductsEventCount:
          return (
            <InstallationProductsEventCount
              tenantId={tenant.id}
              installationId={installationId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              eventType={reportCard.eventType}
              title={reportCard.title}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.CategoriesEventCount:
          return (
            <InstallationCategoriesEventCount
              tenantId={tenant.id}
              installationId={installationId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.EventsFunnel:
          return (
            <InstallationEventsFunnel
              tenantId={tenant.id}
              installationId={installationId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              title={reportCard.title}
              events={reportCard.events}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.WeekHeatmap:
          return (
            <InstallationWeekHeatmap
              tenantId={tenant.id}
              installationId={installationId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              title={reportCard.title}
              dataSource={reportCard.dataSource}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.DataMatrix:
          return (
            <DataMatrix
              tenantId={tenant.id}
              installationId={installationId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              title={reportCard.title}
              dataSource={reportCard.dataSource}
              downloadFileColumns={reportCard.downloadFileColumns}
              tableColumns={reportCard.tableColumns}
              columns={reportCard.columns}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.EventsSuccessFailureRate:
          return (
            <InstallationEventsSuccessFailureRate
              tenantId={tenant.id}
              installationId={installationId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              interactionType={reportCard.interactionType}
              gridStyles={reportCard.gridStyles}
              title={reportCard.title}
              inputType={reportCard.inputType}
              additionalData={reportCard.additionalData}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
              dataSource={reportCard.dataSource}
              dataSourceType={reportCard.dataSourceType}
              reportContext={ReportContext.Installation}
            />
          );

        case CardType.AverageSales:
          return (
            <InstallationAverageSales
              tenantId={tenant.id}
              installationId={installationId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.AverageTimeBetweenTransactions:
          return (
            <InstallationAverageTimeBetweenTransactions
              tenantId={tenant.id}
              installationId={installationId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.AverageNumberOfPurchasedProducts:
          return (
            <InstallationAverageNumberOfPurchasedProducts
              tenantId={tenant.id}
              installationId={installationId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.PurchasedProductsEventCount:
          return (
            <InstallationPurchasedProductsEventCount
              tenantId={tenant.id}
              installationId={installationId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.PurchasedProductsCategoriesEventCount:
          return (
            <InstallationPurchasedProductsCategoriesEventCount
              tenantId={tenant.id}
              installationId={installationId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.QrCodesCount:
          return (
            <InstallationQrCodesEventCount
              tenantId={tenant.id}
              installationId={installationId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.Media:
          return (
            <InstallationMedia
              tenantId={tenant.id}
              installationId={installationId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              title={reportCard.title}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
              primaryKey={reportCard.primaryKey}
            />
          );

        case CardType.ModulesStatus:
          return (
            <InstallationModulesStatusContainer
              tenantId={tenant.id}
              installationId={installationId}
              dataResidency={tenant.dataResidency}
              eventType={reportCard.eventType}
              statusCodes={reportCard.statusCodes}
              title="Modules Status"
            />
          );

        default:
          // @ts-ignore
          throw new Error(`Unknown schema card type ${reportCard.type}`);
      }
    },
    [tenant, installationId, dateFrom, dateTo],
  );

  return (
    <WrapperContainer>
      {!!warningMessage && (
        <AlertContainer>
          <Alert message="Warning" description={warningMessage} type="warning" showIcon />
        </AlertContainer>
      )}

      <Controls>
        <RangePicker dateFrom={dateFrom} dateTo={dateTo} onChangeRange={onChangeRange} />
        <DownloadReport
          tenantId={tenant.id}
          dataResidency={tenant.dataResidency}
          installationId={installationId}
          filters={{
            dateFrom,
            dateTo,
          }}
        />
      </Controls>

      <Tabs defaultActiveKey="1" animated={false}>
        {groups.map((group, index) => {
          const components = group.cards.map(getComponent);

          return (
            <TabPane tab={group.name} key={`${index + 1}`}>
              <AnalyticsReport
                cards={components}
                columnsCount={group.columnsCount}
                gridStyles={group.gridStyles}
              />
            </TabPane>
          );
        })}
      </Tabs>
    </WrapperContainer>
  );
};

const useAppAnalyticsSchema = (installationId: string) => {
  const appState = useApp({ appId: installationId });

  const gridAppId = useMemo(
    () => (appState.isSuccess ? getGridAppId(appState.data) : ''),
    [appState],
  );

  const appBuildsState = useGridAppBuilds({ gridAppId });

  const isLoading = appState.isLoading || appBuildsState.isLoading;
  const isSuccess = appState.isSuccess && appBuildsState.isSuccess;
  const isError = appState.isError || appBuildsState.isError;

  const appAnalyticsSchema =
    appState.isSuccess && appBuildsState.isSuccess
      ? getAppAnalyticsSchema({
          app: appState.data,
          appBuilds: appBuildsState.data,
        })
      : undefined;

  const result = useMemo(
    () => ({
      isLoading,
      isSuccess,
      isError,
      appAnalyticsSchema,
    }),
    [isLoading, isSuccess, isError, appAnalyticsSchema],
  );

  return result;
};

type AppAnalyticsReportProps = {
  schema: unknown;
  fallbackSchema: AnalyticsSchema;
  tenant: Organisation;
  installationId: string;
};

const AppAnalyticsReport: React.FC<AppAnalyticsReportProps> = ({
  schema,
  fallbackSchema,
  tenant,
  installationId,
}) => {
  const { t } = useTranslation();

  const checkSchemaResult = useMemo(() => checkSchema(schema), [schema]);

  if (checkSchemaResult.isValid) {
    const validAnalyticsSchema = checkSchemaResult.analyticsSchema.groups.filter(
      (group) =>
        !group.visibility || group.visibility.includes(VisibilityEnum.Installation),
    );

    return (
      <Report
        tenant={tenant}
        schema={{ groups: validAnalyticsSchema }}
        installationId={installationId}
      />
    );
  }

  return (
    <Report
      tenant={tenant}
      schema={fallbackSchema}
      installationId={installationId}
      warningMessage={t('analyticsSchemaWarning')}
    />
  );
};

interface AppAnalyticsReportContainerProps {
  tenant: Organisation;
  installationId: string;
  fallbackSchema: AnalyticsSchema;
}

const AppAnalyticsReportContainer: React.FC<AppAnalyticsReportContainerProps> = ({
  tenant,
  installationId,
  fallbackSchema,
}) => {
  const appAnalyticsSchemaFetchingState = useAppAnalyticsSchema(installationId);

  return (
    <>
      {appAnalyticsSchemaFetchingState.isLoading && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}

      {appAnalyticsSchemaFetchingState.isSuccess && (
        <AppAnalyticsReport
          tenant={tenant}
          installationId={installationId}
          schema={appAnalyticsSchemaFetchingState.appAnalyticsSchema}
          fallbackSchema={fallbackSchema}
        />
      )}

      {appAnalyticsSchemaFetchingState.isError && (
        <Report tenant={tenant} installationId={installationId} schema={fallbackSchema} />
      )}
    </>
  );
};

export enum SchemaType {
  Dynamic = 'dynamic',
  Static = 'static',
}

interface BaseSchemaInput {
  type: SchemaType;
}

interface DynamicSchemaInput extends BaseSchemaInput {
  type: SchemaType.Dynamic;
  fallbackSchema: AnalyticsSchema;
}

interface StaticSchemaInput extends BaseSchemaInput {
  type: SchemaType.Static;
  schema: AnalyticsSchema;
}

type SchemaInput = DynamicSchemaInput | StaticSchemaInput;

interface InstallationReportProps {
  schemaInput: SchemaInput;
}

const InstallationReport: React.FC<InstallationReportProps> = ({ schemaInput }) => {
  const { organisationId: tenantId, appId: installationId } = useParams<{
    organisationId: string;
    appId: string;
  }>();

  const { tenant } = useStoreState(
    (state) => ({
      tenant: state.organisations.data && state.organisations.data[tenantId],
    }),
    [tenantId],
  );

  if (!tenant) {
    return <ErrorView />;
  }

  return (
    <Container>
      <ErrorBoundary>
        {schemaInput.type === SchemaType.Dynamic && (
          <AppAnalyticsReportContainer
            tenant={tenant}
            installationId={installationId}
            fallbackSchema={schemaInput.fallbackSchema}
          />
        )}

        {schemaInput.type === SchemaType.Static && (
          <Report
            tenant={tenant}
            schema={schemaInput.schema}
            installationId={installationId}
          />
        )}
      </ErrorBoundary>
    </Container>
  );
};

export default InstallationReport;
