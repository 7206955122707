import Browser from '../../store/types/browser';

export const getBrowserData = (browser: Partial<Browser>) => ({
  uuid: browser.uuid,
  displayName: browser.displayName,
  url: browser.url,
  env: browser.env,
  notes: browser.notes,
  isProvisioned: browser.isProvisioned,
  appId: browser.appId,
  organizationId: browser.organizationId,
  deviceId: browser.deviceId,
  spaces: browser.spaces || [],
});

export default getBrowserData;
