import { useQuery, UseQueryOptions } from 'react-query';
import { useGridLlmService, AssistantsPaginatedList } from '@ombori/grid-llm-react';
import storeAIAssistantQueryKeys from './store-ai-assistants-query-keys';

interface UseStoreAIAssistantsListProps {
  tenantId: string;
  page: number;
  limit?: number;
  sortOrder?: 'asc' | 'desc';
  sortField?: string;
  searchTerm?: string;
}

export const useStoreAIAssistantsList = ({ tenantId, page = 1, limit = 10, sortOrder = 'asc', sortField = 'name', searchTerm = '' }: UseStoreAIAssistantsListProps) => {
  const gridLlmService = useGridLlmService();

  const queryOptions: UseQueryOptions<AssistantsPaginatedList, Error> = {
    queryKey: storeAIAssistantQueryKeys.pagination({
      tenantId,
      page,
      limit,
      sortOrder,
      sortField,
      searchTerm,
    }),
    queryFn: async () => {
      const paginatedAssistants = await gridLlmService.getAssistantsList({
        tenantId,
        page,
        limit,
      });
      return paginatedAssistants;
    },
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
  };

  return useQuery(queryOptions);
};

export default useStoreAIAssistantsList;
