import React, { useRef, useCallback, useState, useEffect } from 'react';
import { Row, Col, Checkbox, Modal, Tooltip, message } from 'antd';
import { FormApi } from 'final-form';
import { Form, Field } from 'react-final-form';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ChangeEvent } from '../../../../../types';
import { Icon } from '../../../../common/schema-form/common';
import { useGetNetworkSettings, useUpdateNetworkSettings, NetworkSettings } from '../../../../common/use-network-settings';
import useHasPermissions from '../../../../../utils/auth/use-has-permissions';
import permissionKeys from '../../../../../utils/auth/permissions';

interface NetworkWhitelistSettingsProps {
  tenantId: string;
}

type NetworkSettingsFormValues = Omit<NetworkSettings, 'tenantId'>;

const NetworkWhitelistSettings = (props: NetworkWhitelistSettingsProps) => {
  const { t } = useTranslation();
  
  const { tenantId } = props;

  const isEditAllowed = useHasPermissions(tenantId, permissionKeys.networkWhitelistRules.update);
  
  const { data: networkSettingsData, isLoading: isLoadingNetworkSettings } = useGetNetworkSettings(tenantId);

  const defaultInitialValues: NetworkSettingsFormValues ={
    networkWhitelistAllowAll: false,
  };

  const [ initialValues, updateInitialValues ] = useState(defaultInitialValues);

  useEffect(() => {
    if (networkSettingsData) {
      updateInitialValues({
        networkWhitelistAllowAll: networkSettingsData.networkWhitelistAllowAll,
      })
    }
  }, [networkSettingsData]);

  const { mutateAsync: updateNetworkSettings, isLoading: isUpdatingNetworkSettings } = useUpdateNetworkSettings();

  const handleFormSubmit = useCallback((values, form: FormApi) => {
    Modal.confirm({
      title: t('confirmAction'),
      content: (
        <span>
          {t('networking.networkWhitelistSettingUpdateConfirm')}
        </span>
      ),
      width: '30%',
      onOk: async () => {
        try {
          const updatedNetworkSettings = await updateNetworkSettings({
            tenantId: tenantId,
            ...values
          });
          form.initialize(updatedNetworkSettings);
          message.success(t('networking.networkWhitelistSettingUpdateSuccess'));
        } catch (error) {
          message.error(t('networking.networkWhitelistSettingUpdateFailure'));
          form.reset(initialValues);
        }
      },
      onCancel: () => {
        form.reset(initialValues);
      }
    });
  }, [t, initialValues, tenantId, updateNetworkSettings]);
  
  const networkSettingsForm = useRef<HTMLFormElement>(null);

  return (
    <>
      <Row>
        <Form
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          render={({
            handleSubmit,
          }) => {
            return (
              <form
                ref={networkSettingsForm}
                onSubmit={handleSubmit}
              >
                <Field
                  name='networkWhitelistAllowAll'
                  render={({ input }) => {
                    return (
                      <AllowAllContainer>
                        <Checkbox
                          disabled={!isEditAllowed || isLoadingNetworkSettings || isUpdatingNetworkSettings}
                          defaultChecked={!!input.checked}
                          checked={!!input.value}
                          onChange={({ target: { checked } }) => {
                            input.onChange({
                              target: {
                                value: checked,
                              }
                            } as unknown as ChangeEvent<boolean>)
                            if (networkSettingsForm && networkSettingsForm.current) {
                              networkSettingsForm.current.requestSubmit();
                            }
                          }}
                        >
                          <span>
                            {t('networking.allowAll')}
                          </span>
                        </Checkbox>
                        <Tooltip
                            placement='top'
                            title={t('networking.networkWhitelistSettingUpdateWarning')}
                          >
                            <QuestionMarkIcon type='question-circle' size={16} />
                        </Tooltip>
                      </AllowAllContainer>
                    )
                  }}
                />
              </form>
            )
          }}
        />
      </Row>
    </>
  );
};

const AllowAllContainer = styled(Col)`
  display: flex;
  gap: 10px;
`;

const QuestionMarkIcon = styled(Icon)`
  opacity: 0.5;
`;

export default NetworkWhitelistSettings;
