import React, { useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import styled from '@emotion/styled';
import { Col, Row, message } from 'antd';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { GridAssistantStatusEnum, GridAssistantProviderEnum } from '@ombori/grid-llm-react';
import { StickyColumn } from '../../../queue-details/queue-setup/styled-block';
import StoreAIAssistantFormFields, { StoreAIAssistantFormValues } from './store-ai-assistant-form-fields.component';
import StoreAISidePanel from './store-ai-assistant-form-side-panel.component';
import {
  getFieldNamesWithError,
  scrollIntoView,
} from '../../../../../common/react-final-form';
import { useTranslation } from 'react-i18next';
import Message from '../../../../../common/message';
import { ApiError } from '../../../../../../services/api/api-error';
import Overlay from '../../../../../common/overlay';
import useGoBack from '../../../../../common/use-go-back/use-go-back';
import { useStoreAIAssistantCreate } from '../../../../../../store/hooks/store-ai/use-store-ai-assistant-create';
import { BackToListButton } from '../../common';

export type StoreAIAssistantCreateFormProps = RouteComponentProps<{
  organisationId: string;
}>;

const StoreAIAssistantCreateForm = (props: StoreAIAssistantCreateFormProps) => {
  const { match } = props;
  const { organisationId: tenantId } = match.params;
  const { mutateAsync: createAssistant } = useStoreAIAssistantCreate();

  const { t } = useTranslation();

  const history = useHistory();
  const goBack = useGoBack();

  const storeAIAssistantListPath = `/organisations/${tenantId}/store-ai/assistants?page=1`;

  const handleBackClick = useCallback(() => {
    goBack(storeAIAssistantListPath);
  }, [goBack, storeAIAssistantListPath]);

  const handleFormCreateSubmit = useCallback(
    async (values) => {
      const formValues = values as StoreAIAssistantFormValues;

      try {
        const { knowledgeBaseFiles = [], ...restFormValues } = formValues;

        const knowledgeSourceIds = knowledgeBaseFiles.map((knowledgeBaseFile) => knowledgeBaseFile.fileId);

        await createAssistant({
          tenantId,
          provider: GridAssistantProviderEnum.OPENAI_ASSISTANT,
          description: '',
          ...restFormValues,
          knowledgeSourceIds,
        });

        message.success(<Message content={t('storeAI.successfullySavedAssistant')} />);

        history.push(storeAIAssistantListPath);
      } catch (error) {
        const err = (error as unknown) as ApiError;

        message.error(
          <Message content={t('storeAI.errorSavingAssistant')} error={err} />,
        );
      }
    },
    [
      history,
      storeAIAssistantListPath,
      t,
      tenantId,
      createAssistant,
    ],
  );

  const initialValues = useMemo((): Partial<StoreAIAssistantFormValues> => {
    return {
      status: GridAssistantStatusEnum.ACTIVE,
    };
  }, []);

  return (
    <StoreAIAssistantCreateFormContainer>
      <Overlay>
        <ActionButtons>
          <BackToListButton type="default" icon="arrow-left" onClick={handleBackClick}>
            {t('storeAI.back')}
          </BackToListButton>
        </ActionButtons>
        <Form
          onSubmit={handleFormCreateSubmit}
          keepDirtyOnReinitialize
          initialValues={initialValues}
          mutators={{
            ...arrayMutators,
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            },
          }}
          render={({
            submitting: isSubmitting,
            handleSubmit,
            invalid: isFormInvalid,
            errors,
            form: formApi,
          }) => {
            return (
              <form
                onSubmit={(event) => {
                  handleSubmit(event);

                  const errorFieldNames = getFieldNamesWithError(errors);

                  if (!isFormInvalid || !errorFieldNames.length) {
                    return;
                  }

                  scrollIntoView(errorFieldNames[0]);
                }}
              >
                <Row gutter={{ md: 20, xl: 40 }}>
                  <Col md={24} xl={15}>
                    <StoreAIAssistantFormFields formApi={formApi} tenantId={tenantId} title={t('storeAI.addAssistant')} />
                  </Col>
                  <StickyColumn md={24} xl={9}>
                    <StoreAISidePanel
                      isSubmitEnabled={!isSubmitting}
                      isSubmitting={isSubmitting}
                      isStatusEnabled={false}
                    />
                  </StickyColumn>
                </Row>
              </form>
            );
          }}
        />
      </Overlay>
    </StoreAIAssistantCreateFormContainer>
  );
};

const StoreAIAssistantCreateFormContainer = styled.div`
  position: relative;
  flex: 1;
  padding: 0 80px 40px;
  @media screen and (max-width: 991px) {
    padding: 0;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export default StoreAIAssistantCreateForm;
