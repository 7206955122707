import React, { memo } from 'react';
import {
  AnalyticsExportSchedule,
  ScheduleTypeEnum,
} from '../../../../../store/types/analytics-data-export';
import { ClockCircleTwoTone } from '@ant-design/icons';
import AntdIcon from '@ant-design/icons-react';
import { Tooltip } from 'antd';
import styled from '@emotion/styled';
import convertHourTo12Hour, {
  convertDayToOrdinal,
  convertDayToWeekday,
} from '../utils/util';
import { useTranslation } from 'react-i18next';

interface ScheduledTextComponentProps {
  scheduledJobData: AnalyticsExportSchedule;
}

const ScheduledTextComponent = (props: ScheduledTextComponentProps) => {
  const { type, dateFrom, dateTo, scheduledHour, day } = props.scheduledJobData;

 const {t} =  useTranslation()

  const title = () => {
    let toolTipText = t('manageSchedule.noScheduledJob');
    if (type) {
      const stateDate = dateFrom.split('T')[0];
      const endDate = dateTo.split('T')[0];
      switch (type) {
        case ScheduleTypeEnum.DAILY:
          toolTipText = `Daily ${convertHourTo12Hour(
            scheduledHour,
          )}\n from ${stateDate} to ${endDate}`;
          break;
        case ScheduleTypeEnum.WEEKLY:
          toolTipText = `Every ${convertDayToWeekday(day)}\n, ${convertHourTo12Hour(
            scheduledHour,
          )} from ${stateDate} to ${endDate}`;
          break;
        case ScheduleTypeEnum.MONTHLY:
          toolTipText = `Monthly every ${convertDayToOrdinal(
            day,
          )}\n, ${convertHourTo12Hour(scheduledHour)} from ${stateDate} to ${endDate}`;
          break;
        default:
          toolTipText = '';
      }
    }
    return toolTipText;
  };

  return (
      <Tooltip title={title}>
        <StyledIcon type={ClockCircleTwoTone} />
      </Tooltip>
  );
};

const StyledIcon = styled(AntdIcon)`
  cursor: pointer;
`;

export default memo(ScheduledTextComponent);
