import { useMemo } from 'react';
import { uniq, keyBy } from 'lodash';
import { DownloadFileColumn, InteractionType } from '@ombori/grid-reports';
import {
  useSpacesEventsAndSessionsByDay,
  useDeviceListEventsBySpaceAndDay,
} from '../../../../use-analytics-report';
import { DataResidencyEnum } from '../../../../../../store/types/organisation';
import { DataSourceBaseEntity } from '@ombori/grid-reports';
import { useSpaces } from '../../../../use-spaces';
import OrganisationSpace from '../../../../../../store/types/organisation-space';
import { useTenantDevices } from '../../../../use-devices';
import UniversalDevice from '../../../../../../store/types/universal-device';
import { getColumnParams, getSpaceTableAndDownloadDataMatrix } from './data-matrix-util';

export type SpaceDataMatrixDataRow = {
  key: string;
  devices: UniversalDevice[];
  space: OrganisationSpace;
  parentSpace: OrganisationSpace | null;
  columns: Record<string, string | number>;
};

export type SpaceDataMatrixStateData = {
  tableData: SpaceDataMatrixDataRow[];
  downloadData: SpaceDataMatrixDataRow[];
  spacesWithData: OrganisationSpace[];
}

export interface SpaceDataMatrixState {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: SpaceDataMatrixStateData | null;
  spaces: OrganisationSpace[];
  spacesRecord: Record<string, OrganisationSpace>;
  devices: UniversalDevice[];
}

interface SpaceDataMatrixDataProps {
  tenantId: string;
  dateFrom: string;
  dateTo: string;
  dataResidency: DataResidencyEnum;
  columns: DownloadFileColumn[];
  downloadColumns: DownloadFileColumn[];
  interactionType: InteractionType.Interactive;
}

const useSpaceDataMatrixData = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  columns,
  downloadColumns,
  interactionType,
}: SpaceDataMatrixDataProps): SpaceDataMatrixState => {
  const { tableColumnsParams, downloadColumnsParams, eventTypesQueryString } = useMemo(() => {
    const tableDataParams = getColumnParams(columns);
    const downloadDataParams = getColumnParams(downloadColumns);

    const eventTypesArray = [
      ...tableDataParams.eventTypesQueryString.split(','),
      ...downloadDataParams.eventTypesQueryString.split(','),
    ];

    return {
      tableColumnsParams: tableDataParams,
      downloadColumnsParams: downloadDataParams,
      eventTypesQueryString: uniq(eventTypesArray).join(','),
    };
  }, [columns, downloadColumns]);

  const spacesEventsAndSessionsState = useSpacesEventsAndSessionsByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
    interactionType,
    eventType: eventTypesQueryString,
    isEventsQueryEnabled:
      tableColumnsParams.isEventsQueryEnabled || downloadColumnsParams.isEventsQueryEnabled || (
        (tableColumnsParams.inputTypeColumns && tableColumnsParams.inputTypeColumns.length > 0)
        || (downloadColumnsParams.inputTypeColumns && downloadColumnsParams.inputTypeColumns.length > 0)
      ),
    isSessionsQueryEnabled: tableColumnsParams.isSessionsQueryEnabled || downloadColumnsParams.isSessionsQueryEnabled,
  });

  const tenantDevicesState = useTenantDevices({
    tenantId,
  });

  const spacesState = useSpaces({ organisationId: tenantId, limit: 15000 });

  const tenantDevices = useMemo(() => {
    if (!tenantDevicesState.data) {
      return [];
    }

    return tenantDevicesState.data;
  }, [tenantDevicesState]);

  const deviceAndSpaceDataState = useDeviceListEventsBySpaceAndDay({
    tenantId,
    dateFrom,
    deviceIds: tenantDevices.map((device) => device.uuid),
    dateTo,
    dataResidency,
    interactionType: InteractionType.Interactive,
    eventType: eventTypesQueryString,
    dataSource: DataSourceBaseEntity.Space,
  });

  const isLoading = tenantDevicesState.isLoading
    || spacesEventsAndSessionsState.isLoading
    || deviceAndSpaceDataState.isLoading
    || spacesState.isLoading;
  const isSuccess = tenantDevicesState.isSuccess
    && spacesEventsAndSessionsState.isSuccess
    && deviceAndSpaceDataState.isSuccess
    && spacesState.isSuccess;
  const isError = tenantDevicesState.isError
    || spacesEventsAndSessionsState.isError
    || deviceAndSpaceDataState.isError
    || spacesState.isError;

  const { spaces, spacesRecord } = useMemo(() => {
    const spacesArray = spacesState.data ? spacesState.data.spaces : [];

    return {
      spaces: spacesArray,
      spacesRecord: keyBy(spacesArray, 'id'),
    };
  }, [spacesState.data]);
  
  const spacesDataMatrixData = useMemo(() => {
    if (!isSuccess) {
      return null;
    }

    const analyticsDataFallback = {
      sessions: [],
      events: [],
    };

    const data = getSpaceTableAndDownloadDataMatrix({
      spacesEventsAndSessionsData: spacesEventsAndSessionsState.data || analyticsDataFallback,
      deviceAndSpaceData: deviceAndSpaceDataState.data || [],
      spaces,
      devices: tenantDevicesState.data || [],
      tableColumnsParams,
      downloadColumnsParams,
    });

    return data;
  }, [
    tableColumnsParams,
    isSuccess,
    spaces,
    spacesEventsAndSessionsState.data,
    deviceAndSpaceDataState.data,
    tenantDevicesState.data,
    downloadColumnsParams,
  ]);

  return {
    isLoading,
    isSuccess,
    isError,
    spaces,
    spacesRecord,
    devices: tenantDevicesState.data || [],
    data: spacesDataMatrixData,
  };
};

export default useSpaceDataMatrixData;

