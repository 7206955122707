import React, { useMemo } from 'react';
import { Chart, Geom, Axis, Tooltip, Legend, View } from 'bizcharts';
import DataSet from '@antv/data-set';
import { SankeyDiagramData } from '../../use-analytics-report/aggregated-reports/types';

// https://bizcharts.taobao.com/product/BizCharts4/demo/589

const scale = {
  x: {
    sync: true,
  },
  y: {
    sync: true,
  },
};

interface EventsFlowChartProps {
  data: SankeyDiagramData;
  height?: number;
}

const EventsFlowChart: React.FC<EventsFlowChartProps> = ({ data, height = 400 }) => {
  const dataView = useMemo(() => {
    const ds = new DataSet();

    const dv = ds.createView().source(
      { nodes: data.nodes, links: data.links },
      {
        type: 'graph',
        edges: (d) => d.links,
      },
    );

    dv.transform({
      type: 'diagram.sankey',
      sort: (a, b) => {
        if (a.value > b.value) {
          return 0;
        } else if (a.value < b.value) {
          return -1;
        }
        return 0;
      },
    });

    return dv;
  }, [data]);

  const edges = useMemo(
    () =>
      dataView.edges.map((edge) => {
        return {
          source: edge.source.id,
          target: edge.target.id,
          x: edge.x,
          y: edge.y,
          value: edge.value,
        };
      }),
    [dataView],
  );

  return (
    <Chart
      interactions={['element-highlight']}
      height={height}
      forceFit={true}
      scale={scale}
      padding="auto"
    >
      <Tooltip showTitle={false} />
      <Axis name="x" visible={false} />
      <Axis name="y" visible={false} />
      <Legend name="source" visible={false} />
      <View data={edges}>
        <Geom
          type="edge"
          position="x*y"
          shape="arc"
          style={{ fillOpacity: 0.3, lineWidth: 0 }}
          tooltip={[
            'target*source*value',
            (target, source, value) => {
              return {
                name: `${source  } to ${  target  }</span>`,
                value,
              };
            },
          ]}
        />
      </View>
      <View data={dataView.nodes}>
        <Geom
          type="polygon"
          position="x*y"
          color="name"
          style={{
            stroke: '#fff',
          }}
          tooltip={[
            'name*value',
            (name, value) => {
              return {
                name: `${name  }</span>`,
                value,
              };
            },
          ]}
        />
      </View>
    </Chart>
  );
};

export default EventsFlowChart;
