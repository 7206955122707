import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import PanelCard from '../../../../../common/panel-card/panel-card.component';
import PanelHeader from '../panel-header';
import Loader from '../../../../../common/loader/loader-component';
import { ApiError } from '../../../../../../services/api/api-error';
import Build from '../../../../../../store/types/build';
import AppLink from '../builds-table/app-link';
import { ExternalLink } from 'react-feather';

interface PreviewBuildAppProps {
  isLoading: boolean;
  isSuccess: boolean;
  error: ApiError;
  buildData: Build;
}

const PreviewBuildApp: React.FC<PreviewBuildAppProps> = ({
  isLoading,
  isSuccess,
  error,
  buildData,
}) => {
  const { t } = useTranslation();

  return (
    <PreviewAppPanelCard
      extra={
        <PanelHeader
          icon={<ExternalLink size={20} />}
          title={t('builds.previewApp')}
          style={{ fontSize: '16px' }}
          subHeader={
            <Loader loading={isLoading} error={error} errorFillPage>
              {isSuccess && <AppLink pipelines={buildData.pipelines} showUrl={true} />}
            </Loader>
          }
        />
      }
      bodyStyle={{
        padding: 0,
      }}
    />
  );
};

export default PreviewBuildApp;

const PreviewAppPanelCard = styled(PanelCard)`
  & .ant-card-extra {
    margin-left: 0;
    display: flex;
    flex: 1;
  }
  height: auto;
  .ant-card-head {
    border-radius: 8px;
  }
`;
