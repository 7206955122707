import React, { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Modal, Button } from 'antd';
import { FieldProps } from 'react-jsonschema-form';
import { useTranslation } from 'react-i18next';
import UniversalDevice from '../../../../../store/types/universal-device';
import DevicePicker from '../../../universal-devices/connect-device-universal/connect-device-universal.container';
import DevicesUniversalListItem from '../../../universal-devices/devices-universal-list-item/devices-universal-list-item.component';
import useDevice from '../../../use-devices';
import Spinner from '../../../spinner/spinner.component';

interface InstallationPickerProps extends FieldProps {}

const formModalBodyStyle = {
  backgroundColor: '#F5F5FA',
  borderRadius: '16px',
  maxHeight: '540px',
  minHeight: '540px',
  height: '532px',
  position: 'relative' as any,
  padding: '0px',
  overflowY: 'scroll' as any,
};

const FormWrapper = styled.div<{ hasError: boolean }>`
  flex-direction: column;
  padding-top: 4px;

  ${({ hasError }) =>
    hasError &&
    `
    .control-label {
      color: #a94442;
    }
    .form-control {
      border-color: red;

      :focus {
        border-color: #843534;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
      }
    }
  `}
`;

const DevicePreview = ({ device }: { device: UniversalDevice }) => {
  return (
    <DevicePreviewWrapper>
      <DevicesUniversalListItem device={device} />
    </DevicePreviewWrapper>
  );
}

const DevicePreviewFromId = ({ deviceId }: { deviceId: string }) => {
  const { t } = useTranslation();
  const deviceState = useDevice(deviceId);

  if (deviceState.isLoading) {
    return <Spinner />;
  }

  if (deviceState.isError) {
    return <ErrorMessage>{t('errorDuringFetchingSpaces')}</ErrorMessage>;
  }

  if (deviceState.isSuccess) {
    return <DevicePreview device={deviceState.data} />;
  }

  return null;
}

const DevicePickerComponent = (props: InstallationPickerProps) => {
  const {
    schema,
    errorSchema,
    formData = {},
    onChange,
    formContext,
    disabled,
  } = props;
  const { deviceId } = formData;
  const [selectedDevice, setSelectedDevice] = useState<UniversalDevice | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const hasError = useMemo(() => !!errorSchema && !!Object.keys(errorSchema).length, [
    errorSchema,
  ]);

  const deviceListProps = {
    ...formContext,
    appId: undefined,
  };

  const onDeviceSelect = useCallback(
    (device: UniversalDevice) => {
      onChange({ deviceId: device.id });
      setSelectedDevice(device);
      setIsModalOpen(false);
    },
    [onChange],
  );

  const onModalPickerClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const onModalPickerOpen = useCallback(() => {
    setIsModalOpen(true);
  }, [])

  const devicePreview = useMemo(() => {
    if (selectedDevice) {
      return <DevicePreview device={selectedDevice} />;
    } else if (deviceId) {
      return <DevicePreviewFromId deviceId={deviceId} />
    } else {
      return null;
    }
  }, [selectedDevice, deviceId]);

  return (
    <FormWrapper hasError={hasError}>
      {schema.title ? (
        <div>
          <label className="control-label">{schema.title}</label>
        </div>
      ) : null}
      {devicePreview}
      {!disabled && (
        <StyledButton
          type={hasError ? 'danger' : 'default'}
          onClick={onModalPickerOpen}
          size="large"
          icon="picture"
        >
          {t('pickADevice')}
        </StyledButton>
      )}
      <FormModal
        footer={null}
        destroyOnClose
        title={t('pickADevice')}
        visible={isModalOpen}
        bodyStyle={formModalBodyStyle}
        centered
        onCancel={onModalPickerClose}
      >
        <DevicePicker {...deviceListProps} enableAll onDeviceSelect={onDeviceSelect} />
      </FormModal>
    </FormWrapper>
  );
};

const FormModal = styled(Modal)`
  border-radius: 16px;
  padding: 0px;
  overflow: hidden;

  @media (max-width: 940px) {
    min-width: auto;
  }

  @media (min-width: 940px) {
    min-width: 940px;
  }
`;

const DevicePreviewWrapper = styled.div`
  max-width: 272px;
`;

const StyledButton = styled(Button)`
  margin-left: 8px;
` as any;

const ErrorMessage = styled.div`
  padding: 15px 0;
  color: rgb(255, 85, 0);
`;

export default DevicePickerComponent;
