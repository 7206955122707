import React, { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Row, Col, Button, Typography, Divider } from 'antd';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useTranslation } from 'react-i18next';
import PanelCard from '../../../../common/panel-card/panel-card.component';
import { StickyColumn } from '../../queue-details/queue-setup/styled-block';
import { ButtonType } from '../../../../../types';
import {
  Input,
  TextArea,
  validateCharacterLength,
  validateMaxLength,
} from '../../../../common/react-final-form';
import { requiredAll } from '../../queue-details/queue-setup/validate-form';
import ChannelFormTagsSection from './channel-form-tags-section.component';
import { convertFormTagsToChannelTags } from './utils';
import { ChannelTag } from '../../../../../store/types/channel';

export type ChannelFormTag = ChannelTag;

export interface ChannelFormValues {
  name: string;
  description: string;
  tags?: ChannelFormTag[];
}

// used internally to map ChannelFormTag -> string so that react-final-form could understand the values
interface ChannelFormInitialValues {
  name: string;
  description: string;
  tags?: string[];
}

interface ChannelFormProps {
  title: string;
  channelId?: string;
  tenantId: string;
  initialValues: ChannelFormValues;
  onFormSubmit: (values: ChannelFormValues) => Promise<ChannelFormApiValidationErrors>;
}

export interface ChannelFormApiValidationErrors {
  name?: string;
  description?: string;
}

const ChannelFormFields = (props: ChannelFormProps) => {
  const { title, channelId, tenantId, initialValues, onFormSubmit } = props;

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (values: ChannelFormInitialValues | Object) => {
      setIsFormSubmitting(true);

      const { name, description, tags = [] } = values as ChannelFormInitialValues;

      const formTags = convertFormTagsToChannelTags(tags);

      const formValues: ChannelFormValues = {
        name,
        description,
        tags: formTags,
      };

      const errors = await onFormSubmit(formValues);

      setIsFormSubmitting(false);

      return errors;
    },
    [onFormSubmit],
  );

  const formInitialValues = useMemo(() => {
    const formInitialValues: ChannelFormInitialValues = {
      name: initialValues.name,
      description: initialValues.description,
    }

    if (initialValues.tags && initialValues.tags.length) {
      formInitialValues.tags = initialValues.tags.map((tag) => tag.tagId);
    }

    return formInitialValues;
  }, [initialValues]);

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={formInitialValues}
      mutators={{
        ...arrayMutators,
      }}
      render={({ handleSubmit }) => {
        return (
          <>
            <form id="channel-form" onSubmit={handleSubmit}>
              <Row gutter={{ md: 20, xl: 40 }}>
                <Col md={40} xl={15}>
                  <FormFieldsContainer>
                    <Row>
                      <Typography.Title level={3}>{title}</Typography.Title>
                    </Row>

                    <DividerFull />

                    <FieldsContainer>
                      <Input
                        name="name"
                        label={`${t('contents.name')} *`}
                        validators={[requiredAll, validateCharacterLength(3, 200)]}
                      />
                    </FieldsContainer>

                    <FieldsContainer>
                      <TextArea
                        name="description"
                        label={t('contents.description')}
                        validators={[validateMaxLength(500)]}
                      />
                    </FieldsContainer>
                  </FormFieldsContainer>

                  <FormFieldsContainer>
                    <ChannelFormTagsSection tenantId={tenantId} disableTagsSelection={channelId ? true : false} />
                  </FormFieldsContainer>
                </Col>

                <StickyColumn md={24} xl={9}>
                  <PanelCard>
                    <SaveButton
                      type="primary"
                      form="channel-form"
                      htmlType="submit"
                      size="large"
                      loading={isFormSubmitting}
                    >
                      {t('contents.saveAllChanges')}
                    </SaveButton>
                  </PanelCard>
                </StickyColumn>
              </Row>
            </form>
          </>
        );
      }}
    />
  );
};

export default ChannelFormFields;

const FormFieldsContainer = styled(PanelCard)`
  padding: 20px;
  width: 100% !important;
  margin-bottom: 20px;

  .ant-card-body {
    padding: 0px !important;
  }
`;

const FieldsContainer = styled.div`
  margin-bottom: 15px;
`;

const SaveButton = styled(Button)`
  width: 100%;
` as ButtonType;

const DividerFull = styled(Divider)`
  width: calc(100% + 41px);
  margin-left: -20px;
  overflow: none;
`;
