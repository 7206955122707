import * as React from 'react';
import { useActions, useStore } from 'easy-peasy';
import { RootModel, RootState } from '../../../../../store/models/root.model';
import Devices from './devices.component';
import { getPermissions } from '../../../../../utils/auth/permissions';

type DevicesContainerProps = Omit<
  React.ComponentProps<typeof Devices>,
  'devices' | 'loaded' | 'lastUpdated' | 'fetchDevices' | 'app'
>;

const DevicesContainer = (props: DevicesContainerProps) => {
  const {
    match: {
      params: { appId, organisationId },
    },
  } = props;

  const { devices, loaded, lastUpdated, app, environments, permissions } = useStore<
    RootState
  >(
    (state) => {
      const organisationApp =
        (state.organisationApps.data &&
          state.organisationApps.data[organisationId] &&
          state.organisationApps.data[organisationId][appId]) ||
        null;

      return {
        permissions: getPermissions(state.auth.user, props.match.params.organisationId),
        environments: state.environments.values(organisationId),
        app: organisationApp,
        devices: organisationApp ? state.appDevices.values(organisationApp.appName) : [],
        loaded: organisationApp
          ? state.appDevices.loaded(organisationApp.appName)
          : false,
        lastUpdated: organisationApp
          ? state.appDevices.lastUpdated[organisationApp.appName]
          : undefined,
      };
    },
    [appId, organisationId],
  );

  const { fetchDevices, createDevice, fetchEnvironments } = useActions<RootModel>(
    (actions) => ({
      fetchDevices: actions.appDevices.fetch,
      createDevice: actions.appDevices.create,
      fetchEnvironments: actions.environments.fetch,
    }),
  );

  return (
    <Devices
      {...props}
      lastUpdated={lastUpdated}
      devices={devices}
      loaded={loaded}
      fetchDevices={fetchDevices}
      app={app}
      createDevice={createDevice}
      fetchEnvironments={fetchEnvironments}
      environments={environments}
      canCreate={permissions.devices.create}
    />
  );
};

export default DevicesContainer;
