import React, { useEffect, useMemo, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Form, List, Button, Icon, Typography } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { omitBy } from 'lodash';
import PanelCard from '../../../common/panel-card/panel-card.component';
import {
  EnterpriseAgreementStatusEnum,
  Filters,
} from '../../../../store/types/enterprise-agreement';
import StatusItem from './item/status-item';
import OwnerItem from './item/owner-item';
import { ApiError } from '../../../../services/api/api-error';
import Loader from '../../../common/loader/loader-component';

const { Text } = Typography;

export interface FilterValues {
  [key: string]: {
    [key: string]: string | boolean | number;
  };
}

interface EnterpriseAgreementListFilterProps extends FormComponentProps {
  fetchFilters: ({
    params,
    silent,
  }: {
    params?: { [key: string]: string };
    silent?: boolean;
  }) => Promise<Filters>;
  filters: Filters;
  loading: boolean;
  error: ApiError | null;
  formName?: string;
  isFiltering?: boolean;
  onSubmit?: (data: { [key: string]: string }) => Promise<void>;
}

function EnterpriseAgreementListFilter(props: EnterpriseAgreementListFilterProps) {
  const {
    fetchFilters,
    loading,
    error,
    filters,
    formName = 'filters',
    isFiltering = false,
    onSubmit,
    form,
  } = props;
  const { validateFields } = form;
  const { t } = useTranslation();
  const hasMounted = useRef<boolean>(false);

  useEffect(() => {
    fetchFilters({});
    hasMounted.current = true;
  }, [fetchFilters]);

  const statuses = useMemo(() => {
    return Object.values(EnterpriseAgreementStatusEnum).map((value) => {
      return {
        id: value,
        desc: value.replace('_', ' '),
      };
    });
  }, []);

  const createdByList = useMemo(() => {
    return filters && filters.createdBy ? Object.keys(filters.createdBy) : [];
  }, [filters]);

  const handleFormSubmit = useCallback(
    async (ev: React.SyntheticEvent) => {
      ev.preventDefault();

      validateFields(async (err: any, values: Filters) => {
        const { status, createdBy } = values;

        if (err || !onSubmit) {
          return;
        }

        const statusChecked = omitBy(status, (value) => !value);
        const statusParam = Object.keys(statusChecked).join(',');

        const createdByChecked = omitBy(createdBy, (value) => !value);
        const createdByParam = Object.keys(createdByChecked).join(',');

        const params = {
          status: statusParam,
          createdBy: createdByParam,
        };

        if (Object.keys(params).length > 0) {
          await onSubmit(params);
        }
      });
    },
    [onSubmit, validateFields],
  );

  return (
    <PanelCardStyled
      title={
        <>
          <Icon type="filter" /> {t('filter')}
        </>
      }
      bodypadding="0px"
    >
      <Form name={formName} onSubmit={handleFormSubmit}>
        <Loader
          loading={loading}
          error={error}
          hasMounted={hasMounted.current}
          errorFillPage
        >
          <ApplyFiltersPanel>
            <Content className="ombori-thin-scroll">
              <Filter>
                <Title strong>{t('enterpriseAgreement.dealStatus')}</Title>
                <List
                  size="small"
                  dataSource={statuses}
                  renderItem={(status) => {
                    return <StatusItem form={form} status={status} filters={filters} />;
                  }}
                />
              </Filter>
              {createdByList.length > 0 && (
                <Filter>
                  <Title strong>{t('enterpriseAgreement.dealOwner')}</Title>
                  <List
                    size="small"
                    dataSource={createdByList}
                    renderItem={(id) => (
                      <OwnerItem form={form} createdById={id} filters={filters} />
                    )}
                  />
                </Filter>
              )}
            </Content>
            <ApplyFilterButton
              htmlType="submit"
              type="primary"
              size="large"
              loading={isFiltering}
              block
            >
              {t('applyFilter')}
            </ApplyFilterButton>
          </ApplyFiltersPanel>
        </Loader>
      </Form>
    </PanelCardStyled>
  );
}

const PanelCardStyled = styled(PanelCard)`
  height: auto;

  .ant-card-head {
    padding: 0 15px;
    border-bottom: 0px;
  }

  .ant-card-head-title {
    font-size: 24px;
    padding-bottom: 0px;

    .anticon {
      margin-right: 5px;
    }
  }

  .ant-card-body {
    padding: 20px 20px 0 20px !important;
    min-height: 200px;
    position: relative;
  }

  .ant-card-actions {
    background: none;
    border-top: none;
    padding: 0 10px;
  }
`;

const Content = styled.div`
  min-height: 200px;
  max-height: calc(100vh - 500px);
  position: relative;
  overflow-y: scroll;
  overflow-x: auto;
  padding: 0px 5px 0px 0px;

  /* move thin scroll to the right */
  margin-right: -20px;
`;

const Filter = styled.div`
  .ant-list-item {
    text-transform: capitalize;
  }
  margin-bottom: 20px;
`;

const Title = styled(Text)`
  text-transform: uppercase;
`;

/* Tames loader. Re-middle align loader while showing button at the bottom */
const ApplyFiltersPanel = styled.div`
  position: relative;
  min-height: 200px;
`;

const ApplyFilterButton = styled(Button)`
  margin: 20px auto;
` as any;

export default Form.create<EnterpriseAgreementListFilterProps>()(
  EnterpriseAgreementListFilter,
);
