export default interface AnalyticsDataExport {
  status: DataExportStatusEnum;
  tenantId: string;
  createdAt: string;
  filename: string;
  id: string;
  fileSize: number;
  downloadUrl?: string;
  dataDateFrom: string;
  dataDateTo: string;
}

export enum DataExportStatusEnum {
  QUEUED = 'queued',
  PROCESSING = 'processing',
  DONE = 'done',
  ERROR = 'error',
}

export const dataExportStatusColors: { [test: string]: string } = {
  [DataExportStatusEnum.DONE]: 'green',
  [DataExportStatusEnum.QUEUED]: 'gray',
  [DataExportStatusEnum.PROCESSING]: 'blue',
  [DataExportStatusEnum.ERROR]: 'red',
};


export interface AanalyticsDataForm {
  dataDateFrom: string;
  dataDateTo: string;
}

export interface AanalyticsDataPostForm extends AanalyticsDataForm {
  tenantId: string;
}

export enum ScheduleTypeEnum {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export const ScheduleTypes = {
  DAILY: ScheduleTypeEnum.DAILY,
  WEEKLY: ScheduleTypeEnum.WEEKLY,
  MONTHLY: ScheduleTypeEnum.MONTHLY,
};

export interface AnalyticsExportSchedule {
  tenantId: string;
  enabled: boolean;
  emailRecipient: string[];
  type: ScheduleTypeEnum;
  day?: number;
  scheduledHour: number;
  dateFrom: string;
  dateTo: string;
  weekDay?:string | number;
}

export enum WeekEnum {
  SUNDAY = '6',
  MONDAY = '0',
  TUESDAY = '1',
  WEDNESDAY = '2',
  THURSDAY = '3',
  FRIDAY = '4',
  SATURDAY = '5',
}
