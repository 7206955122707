import React, { useCallback, useMemo, useState } from 'react';
import {
  DataMatrixDataSource,
  GridStyles,
  InteractionType,
  DownloadFileColumn,
  SpaceDataSourceType,
} from '@ombori/grid-reports';
import { groupBy } from 'lodash';
import { Button, message, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CardTitleDownloadButtonWrapper from '../../card-title-download-button-wrapper';
import useSpaceDataMatrixData, { SpaceDataMatrixDataRow } from '../data-matrix-hooks/use-space-data-matrix-data';
import { convertToCSVFromObject, getCsvDataFileName } from '../../../../../../utils/convert-csv';
import { prepareDateRangeSearchParams } from '../../../utils';
import { DataResidencyEnum } from '../../../../../../store/types/organisation';
import CardContainer from '../../card-container';
import { OrganisationSpaceWithChildren } from '../spaces-table';
import { TitleWithSelect } from '../common/data-matrix-common.component';
import Message from '../../../../message';
import downloadCSV from '../../../../../../utils/download-csv';
import * as Sentry from '@sentry/browser';

interface DataMatrixProps {
  tenantId: string;
  installationId?: string;
  dateFrom: string;
  dateTo: string;
  dataResidency: DataResidencyEnum;
  title?: string;
  dataSource: DataMatrixDataSource;
  dataSourceType?: SpaceDataSourceType;
  downloadFileColumns?: DownloadFileColumn[];
  tableColumns: DownloadFileColumn[];
  gridStyles?: GridStyles;
  isVisibleWithoutData?: boolean;
}

const SpacesDataMatrixV1: React.FC<Omit<DataMatrixProps, 'dataSource'>> = React.memo(({
                                                                                        tenantId,
                                                                                        dateFrom,
                                                                                        dateTo,
                                                                                        dataResidency,
                                                                                        title = '',
                                                                                        downloadFileColumns,
                                                                                        tableColumns,
                                                                                        gridStyles,
                                                                                        isVisibleWithoutData,
                                                                                        dataSourceType,
                                                                                      }) => {
  const [selectedSpace, setSelectedSpace] = useState<
    OrganisationSpaceWithChildren | undefined
  >(undefined);

  const { t } = useTranslation();

  const searchParams = useMemo(() => {
    return prepareDateRangeSearchParams(dateFrom, dateTo);
  }, [dateFrom, dateTo]);

  const spaceMatrixState = useSpaceDataMatrixData({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
    interactionType: InteractionType.Interactive,
    columns: tableColumns && tableColumns.length > 0 ? tableColumns : [],
    downloadColumns: downloadFileColumns ? downloadFileColumns : [],
  });

  const newTableColumns = useMemo(() => {
    if (!tableColumns || !spaceMatrixState.data) {
      return [];
    }

    const staticColumns = [
      // This is a hack to show the space name in the table
      // We need to refactor this entire part when we do success alerts backend aggregations
      ...(dataSourceType && dataSourceType === SpaceDataSourceType.SECTION ? [
        {
          title: t('subSpace'),
          key: 'subSpaceId',
          sorter: (source: SpaceDataMatrixDataRow, target: SpaceDataMatrixDataRow) => {
            return source.space.displayName.localeCompare(target.space.displayName);
          },
          render: (record: SpaceDataMatrixDataRow) => {
            return (
              <Button type="link">
                <Link
                  to={{
                    pathname: `/organisations/${tenantId}/spaces/${record.space.id}`,
                    search: `${searchParams}`,
                  }}>
                  {record.space.displayName}
                </Link>
              </Button>
            );
          },
        },
        {
          title: t('parentSpace'),
          key: 'parentSpaceId',
          sorter: (source: SpaceDataMatrixDataRow, target: SpaceDataMatrixDataRow) => {
            const sourceParentSpace = source.parentSpace && source.parentSpace.displayName ? source.parentSpace.displayName : '';
            const targetParentSpace = target.parentSpace && target.parentSpace.displayName ? target.parentSpace.displayName : '';
            return sourceParentSpace.localeCompare(targetParentSpace);
          },
          render: (record: SpaceDataMatrixDataRow) => {
            return (
              <Button type="link">
                {record.parentSpace && (
                  <Link
                    to={{
                      pathname: `/organisations/${tenantId}/spaces/${record.parentSpace.id}`,
                      search: `${searchParams}`,
                    }}>
                    {record.parentSpace.displayName}
                  </Link>
                )}
              </Button>
            );
          },
        },
        {
          title: t('device'),
          key: 'deviceId',
          sorter: (source: SpaceDataMatrixDataRow, target: SpaceDataMatrixDataRow) => {
            const sourceDevice = source.devices && source.devices.length === 1 ? source.devices[0] : null;
            const sourceDeviceName = sourceDevice ? sourceDevice.deviceName : '';
            const targetDevice = target.devices && target.devices.length === 1 ? target.devices[0] : null;
            const targetDeviceName = targetDevice ? targetDevice.deviceName : '';

            return sourceDeviceName.localeCompare(targetDeviceName);
          },
          render: (record: SpaceDataMatrixDataRow) => {
            const sourceDevice = record.devices && record.devices.length === 1 ? record.devices[0] : null;
            return (
              <Button type="link">
                {sourceDevice && (
                  <Link
                    to={{
                      pathname: `/organisations/${tenantId}/spaces/${sourceDevice.uuid}`,
                      search: `${searchParams}`,
                    }}>
                    {sourceDevice.deviceName}
                  </Link>
                )}
              </Button>
            );
          },
        },
      ] : [
        {
          title: t('space'),
          key: 'spaceId',
          sorter: (source: SpaceDataMatrixDataRow, target: SpaceDataMatrixDataRow) => {
            return source.space.displayName.localeCompare(target.space.displayName);
          },
          render: (record: SpaceDataMatrixDataRow) => {
            return (
              <Button type="link">
                <Link
                  to={{
                    pathname: `/organisations/${tenantId}/spaces/${record.space.id}`,
                    search: `${searchParams}`,
                  }}>
                  {record.space.displayName}
                </Link>
              </Button>
            );
          },
        },
      ])
    ];

    const otherColumns = tableColumns.map((tableColumn, index) => {
      const title = tableColumn.column.title || `Column ${index + 1}`;
      return {
        title,
        dataIndex: `columns.${title}`,
        key: title,
        sorter: (source: SpaceDataMatrixDataRow, target: SpaceDataMatrixDataRow) => {
          if (typeof source.columns[title] === 'number') {
            return (source.columns[title] as number) - (target.columns[title] as number);
          }

          const str1 = `${source.columns[title]}` || '';
          const str2 = `${target.columns[title]}` || '';

          return str1.localeCompare(str2);
        }
      }
    });

    return [...staticColumns, ...otherColumns];
  }, [tableColumns, spaceMatrixState, searchParams, tenantId, t, dataSourceType]);

  const filterByDataSourceType = useCallback(
    (dataItem: SpaceDataMatrixDataRow, dataSourceType: SpaceDataSourceType) => {
      const { type: spaceType, parentSpaceId } = dataItem.space;
      return (spaceType as string) === (dataSourceType as string) && parentSpaceId;
    },
    [],
  );

  const newTableColumnsData = useMemo(() => {
    if (!tableColumns || !spaceMatrixState.data || !spaceMatrixState.data.tableData) {
      return [];
    }

    return spaceMatrixState.data.tableData.filter((dataItem) => {
      if (selectedSpace) {
        return dataItem.space.id === selectedSpace.id;
      }

      if (dataSourceType) {
        return filterByDataSourceType(dataItem, dataSourceType);
      }

      return !dataItem.space.parentSpaceId;
    });
  }, [tableColumns, spaceMatrixState, selectedSpace, dataSourceType, filterByDataSourceType]);

  const spaceOptions = useMemo(() => {
    if (!spaceMatrixState || !spaceMatrixState.data) {
      return [];
    }

    const result = spaceMatrixState.data.tableData.filter((dataItem) => {
      if (dataSourceType) {
        return filterByDataSourceType(dataItem, dataSourceType);
      }

      return !dataItem.space.parentSpaceId;
    }).map((dataItem) => {
      return { key: dataItem.space.id, label: dataItem.space.displayName };
    });

    return result;
  }, [spaceMatrixState, dataSourceType, filterByDataSourceType]);

  const spacesWithChildren = useMemo(() => {
    const rootSpaceKey = 'root';

    const groupedSpaces = groupBy(spaceMatrixState.spaces, (space) =>
      space.parentSpaceId ? space.parentSpaceId : rootSpaceKey,
    );
    const rootSpaces = groupedSpaces[rootSpaceKey] || [];

    const result = rootSpaces.map((space) => {
      const children = groupedSpaces[space.id] || [];

      const spaceWithChildren: OrganisationSpaceWithChildren = { ...space, children };

      return spaceWithChildren;
    });

    return result;
  }, [spaceMatrixState]);

  const onSelect = useCallback(
    (key: string | undefined) => {
      if (!key || key === 'all') {
        setSelectedSpace(undefined);
        return;
      }

      if (dataSourceType) {
        spacesWithChildren.forEach((space) => {
          const selectedSpace = space.children.find((subSpace) => subSpace.id === key);
          if (selectedSpace) {
            setSelectedSpace({ ...selectedSpace, children: [] });
            return;
          }
        });
        return;
      }

      const selectedSpace = spacesWithChildren.find((space) => space.id === key);
      setSelectedSpace(selectedSpace);
    },
    [setSelectedSpace, spacesWithChildren, dataSourceType],
  );

  const {
    hasData,
    showDownloadButton,
  } = useMemo(() => {
    return {
      hasData: !!spaceMatrixState.data && spaceMatrixState.data.tableData.length > 0,
      showDownloadButton: !!spaceMatrixState.data && spaceMatrixState.data.downloadData.length > 0,
    }
  }, [spaceMatrixState]);

  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const handleDownloadButtonClick = () => {
    try {
      setIsDownloading(true);
      let csvData = '';
      const dataExportFileName = getCsvDataFileName({ dateFrom, dateTo, title });

      if (!downloadFileColumns || !spaceMatrixState.data || !spaceMatrixState.data.downloadData) {
        csvData = '';
      } else {
        const data = spaceMatrixState.data.downloadData;
        if (!data || !data.length) {
          csvData = '';
        }

        const columns = data.filter((dataItem) => {
          if (dataSourceType) {
            return filterByDataSourceType(dataItem, dataSourceType);
          }

          return !dataItem.space.parentSpaceId;
        }).map((dataItem) => {
          const baseColumns = {
            'Date from': dateFrom,
            'Date to': dateTo,
          }

          if (dataSourceType) {
            const parentSpace = dataItem.parentSpace;
            const space = dataItem.space;
            const device = dataItem.devices && dataItem.devices.length === 1 ? dataItem.devices[0] : null;

            const row = {
              ...baseColumns,
              'Subspace': space ? space.displayName : '',
              'Parent space': parentSpace ? parentSpace.displayName : '',
              'Device name': device ? device.deviceName : '',
              ...dataItem.columns,
            }

            return row;
          }

          const row = {
            ...baseColumns,
            'Space name': dataItem.space.displayName,
            'Region': dataItem.space.state || '',
            ...dataItem.columns,
          };

          return row;
        });

        csvData = convertToCSVFromObject(columns as Record<string, string>[]);
      }

      if (csvData === '') {
        message.warning(<Message content={t('fileDownload.noDataToDownload')} />);
      } else {
        downloadCSV(csvData, dataExportFileName ? dataExportFileName : 'download-report.csv');
        message.success(<Message content={t('fileDownload.downloadStart')} />);
      }
    } catch (error) {
      Sentry.captureException(error);
      message.error(<Message content={(t('fileDownload.downloadFailure'))} />);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <CardContainer
      isLoading={spaceMatrixState.isLoading}
      isSuccess={spaceMatrixState.isSuccess}
      isError={spaceMatrixState.isError}
      hasData={hasData}
      title={(
        <CardTitleDownloadButtonWrapper
          isDownloadButtonVisible={showDownloadButton}
          onDownloadButtonClicked={handleDownloadButtonClick}
          isDownloading={isDownloading}
        >
          {spaceMatrixState.isSuccess ? (
            <TitleWithSelect title={title} items={spaceOptions} onSelect={onSelect} />
          ) : (
            title
          )}
        </CardTitleDownloadButtonWrapper>
      )}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {spaceMatrixState.isSuccess && (
        <Table
          columns={newTableColumns}
          dataSource={newTableColumnsData}
          size="small"
          pagination={{
            pageSize: 5,
          }}
          scroll={{ x: 'max-content' }}
        />
      )}
    </CardContainer>
  );
});

export default SpacesDataMatrixV1;