import React, { useMemo } from 'react';
import { ArrayFieldTemplateProps } from 'react-jsonschema-form';
import { Button, Col, Row, Typography } from 'antd';
import styled from '@emotion/styled';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';

const { Text, Title } = Typography;

const ArrayItemControls = styled.div`
  min-width: 20px;
  > button {
    display: block;
    margin: 25px 1px 25px 8px;
  }
`;

const ArrayItemWrapper = styled.div`
  margin-top: 0;
  margin-bottom: 16px;
  position: relative;
  display: flex;

  & > .field-undefined {
    padding: 0;
  }
  .ant-card {
    h4 {
      font-size: 16px;
    }
  }
  .form-group {
    margin-top: 0;
    flex: 1;
  }
`;

interface CustomArrayFieldTemplateProps extends ArrayFieldTemplateProps {
  rawErrors?: string[];
}

const ActionButton = styled(Button)`
  cursor: pointer;
` as any;

type ItemsType = ArrayFieldTemplateProps['items'];

type ItemType = ItemsType[number];

interface FormContextType {
  languageOptions: {
    languageTo: string;
    languageFrom: string;
  };
}

const ArrayItemChildren = React.memo(
  ({
    element,
    title,
  }: {
    element: ItemType;
    data: any;
    title: string;
    formContext: FormContextType;
  }) => {
    return React.cloneElement(element.children, {
      schema: { ...element.children.props.schema, title },
    });
  },
  (prevProps, nextProps) => {
    const {
      data: prevData,
      element: { index: prevIndex },
      formContext: prevFormContext,
    } = prevProps;

    const {
      data: nextData,
      element: { index: nextIndex },
      formContext: nextFormContext,
    } = nextProps;

    return (
      prevIndex === nextIndex &&
      isEqual(prevData, nextData) &&
      isEqual(prevFormContext, nextFormContext)
    );
  },
);

const ArrayItem = React.memo(
  (props: {
    element: ItemType;
    index: number;
    data: any;
    formContext: FormContextType;
  }) => {
    const { element, index, data, formContext } = props;
    const elementTitle = get(element, 'children.props.schema.title', 'Item {{n}}');
    const title = elementTitle.replace('{{n}}', index + 1);
    return (
      <ArrayItemWrapper key={(element as any).key}>
        <ArrayItemChildren
          element={element}
          title={title}
          data={data}
          formContext={formContext}
        />
        <ArrayItemControls>
          {element.hasMoveUp && (
            <ActionButton
              onClick={element.onReorderClick(element.index, element.index - 1)}
              type="link"
              icon="arrow-up"
            />
          )}
          {element.hasMoveDown && (
            <ActionButton
              onClick={element.onReorderClick(element.index, element.index + 1)}
              type="link"
              icon="arrow-down"
            />
          )}
          {element.hasRemove && (
            <ActionButton
              onClick={element.onDropIndexClick(element.index)}
              type="link"
              icon="delete"
            />
          )}
        </ArrayItemControls>
      </ArrayItemWrapper>
    );
  },
  (prevProps, nextProps) => {
    const { data: prevData, index: prevIndex, formContext: prevFormContext } = prevProps;

    const { data: nextData, index: nextIndex, formContext: nextFormContext } = nextProps;

    return (
      prevIndex === nextIndex &&
      isEqual(prevData, nextData) &&
      isEqual(prevFormContext, nextFormContext)
    );
  },
);

const ArrayField = React.memo(
  (props: CustomArrayFieldTemplateProps) => {
    const { items, title, onAddClick, canAdd, rawErrors, formData, formContext } = props;
    const hasError = !!rawErrors && !!rawErrors.length;
    const handleAdd = useMemo(() => {
      if (items && items[0]) {
        return (items[0] as any).onAddIndexClick(0);
      }
      return onAddClick;
    }, [onAddClick, items]);
    const { t } = useTranslation();
    const content = useMemo((): JSX.Element | JSX.Element[] => {
      if (items.length) {
        const formDataArray = Array.isArray(formData) ? formData : [];
        return items.map((element, index) => {
          const data = formDataArray[index] || {};
          const key = get(element, 'key', index);

          return (
            <ArrayItem
              key={key}
              element={element}
              index={index}
              data={data}
              formContext={formContext}
            />
          );
        });
      }

      return (
        <Text type="secondary">
          <i>{t('emptySection')}</i>
        </Text>
      );
    }, [items, formData]);

    return (
      <>
        <Row type="flex" justify="space-between" align="middle" className="sticky-title">
          <Col>
            <Title level={4} type={hasError ? 'danger' : undefined}>
              {title}
            </Title>
          </Col>
          {canAdd && (
            <Col>
              <Button icon="plus" onClick={handleAdd}>
                {t('add')}
              </Button>
            </Col>
          )}
        </Row>
        <Row>
          <Col>{content}</Col>
        </Row>
      </>
    );
  },
  (prevProps, nextProps) => {
    const { formData: prevFormData, formContext: prevFormContext } = prevProps;

    const { formData: nextFormData, formContext: nextFormContext } = nextProps;

    return (
      isEqual(prevFormData, nextFormData) && isEqual(prevFormContext, nextFormContext)
    );
  },
);

export default ArrayField;
