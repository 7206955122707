import { useState } from 'react';
import { LayoutViewType } from './layout-view.component';

interface UseLayoutViewResponse {
  layoutView: LayoutViewType;
  setLayoutView: (layoutView: LayoutViewType) => void;
}

const useLayoutView = (defaultLayoutView: LayoutViewType): UseLayoutViewResponse => {
  const [layoutView, setLayoutView] = useState<LayoutViewType>(
    defaultLayoutView || 'list',
  );

  return {
    layoutView,
    setLayoutView,
  };
};

export default useLayoutView;
