import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import { RootModel, RootState } from '../../../../../../store/models/root.model';
import { getPermissions } from '../../../../../../utils/auth/permissions';
import UniversalDeviceDetailsScreen from './universal-device-details-screen.component';
import DeviceTypeEnum from '../../../../../../store/types/device-type.enum';
import getAppsByOrganisationName from '../../../../../../utils/get-apps-by-organisation-name';

type SettingsContainerProps = Omit<
  React.ComponentProps<typeof UniversalDeviceDetailsScreen>,
  'updateDevice' | 'canManageDeviceSettings' | 'device' | 'user'
>;

const UniversalDeviceDetailsScreenContainer = (props: SettingsContainerProps) => {
  const {
    match: {
      params: { organisationId, deviceUuid },
    },
  } = props;

  const { device, user, permissions, environments, apps } = useStore<RootState>(
    (state) => ({
      device: state.universalDeviceDetails.data[deviceUuid] || null,
      user: state.auth.user,
      permissions: getPermissions(state.auth.user, organisationId),
      environments: state.environments.values(organisationId),
      apps: getAppsByOrganisationName(
        state.organisations.values,
        state.organisationApps.data || {},
        DeviceTypeEnum.BALENA,
      ),
    }),
  );

  const { updateDevice, fetchEnvironments } = useActions<RootModel>(
    (actions) => ({
      fetchEnvironments: actions.environments.fetch,
      updateDevice: actions.universalDeviceDetails.update,
      fetchSpaces: actions.organisationSpaces.fetch,
    }),
  );

  return (
    <UniversalDeviceDetailsScreen
      {...props}
      apps={apps}
      user={user}
      updateDevice={updateDevice}
      fetchEnvironments={fetchEnvironments}
      environments={environments}
      device={device}
      canManageDeviceSettings={permissions.devices.manageDeviceSettings}
    />
  );
};

export default UniversalDeviceDetailsScreenContainer;
