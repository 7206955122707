import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import {
  InputCheckbox,
  Label,
  MainSectionTitle,
  PanelBody,
  PanelCardMain,
  RowStyled,
  FormTextInput,
  Select,
} from './styled-block';
import { DigitalCallQueuesType } from '../../../../../store/types/queue';

const { Title } = Typography;

interface Props {
  isEnabled: boolean;
  queueType: string;
}

export const isBambuserDigitalCallSectionVisible = (
  availableTagsForQueue: any[],
  agentBaseUrl: string,
) => {
  /* if array doesn't exist at all - bambuser is not presented if  agentBaseUrl is presented - lets think that the bambuser feature is
  not enabled (just in case it's a queue with Bambuser but it was disabled) */
  const isItActivatedAsAFeatureOnQueueLevel =
    availableTagsForQueue != null || !!agentBaseUrl;
  return isItActivatedAsAFeatureOnQueueLevel;
};

const DigitalCallBambuserSection: React.FC<Props> = ({ isEnabled, queueType }) => {
  const { t } = useTranslation();

  return (
    <PanelCardMain bodyStyle={{ padding: 0 }}>
      <MainSectionTitle>
        <Title level={2}>{t('digitalCallTitle')}</Title>
      </MainSectionTitle>
      <PanelBody>
        <Field name="digitalCall.isEnabled" type="checkbox">
          {({ input }) => (
            <RowStyled>
              <Label>
                <InputCheckbox {...input} type="checkbox" />
                {t('digitalCallIsEnabled')}
              </Label>
            </RowStyled>
          )}
        </Field>

        {isEnabled && (
          <>
            <Field name="digitalCall.agentBaseUrl">
              {({ input, meta }) => (
                <FormTextInput
                  input={input}
                  meta={meta}
                  required
                  label={t('digitalCallAgentBaseUrl')}
                />
              )}
            </Field>
            <Field name="digitalCall.apiToken">
              {({ input, meta }) => (
                <FormTextInput
                  input={input}
                  meta={meta}
                  label={t('digitalCallApiKey')}
                  required
                />
              )}
            </Field>
            <Field name="digitalCall.customerUrlTemplate">
              {({ input, meta }) => (
                <FormTextInput
                  input={input}
                  meta={meta}
                  required
                  label={t('digitalCallCustomerUrlTemplate')}
                  description={t('digitalCallCustomerUrlTemplateDescirption')}
                />
              )}
            </Field>
            <Field name="digitalCall.routingTimeout">
              {({ input, meta }) => (
                <FormTextInput
                  input={input}
                  meta={meta}
                  label={t('digitalCallRoutingTimeout')}
                  description={t('digitalCallRoutingTimeoutDescription')}
                />
              )}
            </Field>
            <Field name="digitalCall.queueType">
              {({ input }) => (
                <RowStyled>
                  <Label>{t('digitalCalQueueType')}</Label>
                  <Select {...input}>
                    <option
                      value={DigitalCallQueuesType.DEFAULT}
                      key={DigitalCallQueuesType.DEFAULT}
                    >
                      {t('digitalCalQueueTypeDefault')}
                    </option>
                    <option
                      value={DigitalCallQueuesType.GLOBAL}
                      key={DigitalCallQueuesType.GLOBAL}
                    >
                      {t('digitalCalQueueTypeGlobal')}
                    </option>
                  </Select>
                </RowStyled>
              )}
            </Field>
            {queueType === DigitalCallQueuesType.DEFAULT && (
              <Field name="digitalCall.globalQueueId">
                {({ input, meta }) => (
                  <FormTextInput
                    input={input}
                    meta={meta}
                    label={t('digitalCallGlobalQueueId')}
                    description={t('digitalCallGlobalQueueIdDescription')}
                  />
                )}
              </Field>
            )}
          </>
        )}
      </PanelBody>
    </PanelCardMain>
  );
};

export default DigitalCallBambuserSection;
