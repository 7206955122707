import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import GridappVersions from './gridapp-versions.component';
import { RootModel, RootState } from '../../../../../store/models/root.model';

type GridappVersionsContainerProps = Pick<
  React.ComponentProps<typeof GridappVersions>,
  'match' | 'history' | 'location' | 'canDelete' | 'canModifyReleaseNotes' | 'canViewReleaseNotes'
>;

const GridappVersionsContainer = (props: GridappVersionsContainerProps) => {
  const {
    match: {
      params: { organisationId, gridappId },
    },
  } = props;

  const { uploadBuild, deleteBuild, fetchBuilds, subscribeBuilds } = useActions<
    RootModel
  >((actions) => ({
    uploadBuild: actions.organisationAppsLibraryBuilds.uploadBuild,
    deleteBuild: (id: string) =>
      actions.organisationAppsLibraryBuilds.delete({ id, gridappId }),
    fetchBuilds: actions.organisationAppsLibraryBuilds.fetch,
    subscribeBuilds: actions.organisationAppsLibraryBuilds.subscribe,
  }));

  const { gridappBuilds, loading, gridapp } = useStore<RootState>(
    (state) => ({
      gridappBuilds: state.organisationAppsLibraryBuilds.values(gridappId),
      loading: state.organisationAppsLibraryBuilds.loading[gridappId],
      gridapp: state.organisationAppsLibrary.data[organisationId]
        ? state.organisationAppsLibrary.data[organisationId][gridappId]
        : null,
    }),
    [organisationId],
  );

  return (
    <GridappVersions
      {...props}
      subscribeBuilds={subscribeBuilds}
      gridappBuilds={gridappBuilds}
      loading={loading}
      uploadBuild={uploadBuild}
      deleteBuild={deleteBuild}
      fetchBuilds={fetchBuilds}
      gridapp={gridapp}
    />
  );
};

export default GridappVersionsContainer;
