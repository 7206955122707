import React from 'react';
import { Chart, Geom, Axis, Label } from 'bizcharts';
import { Empty } from 'antd';

type X = number;

type Y = number;

type Value = number;

type DataItem = [X, Y, Value];

interface WeekHeatmapProps {
  data: DataItem[];
}

const WeekHeatmap: React.FC<WeekHeatmapProps> = ({ data }) => {
  const source = data.map((item) => ({ day: item[0], hour: item[1], value: item[2] }));

  const cols = {
    day: {
      type: 'cat',
      values: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ],
    },
    hour: {
      type: 'cat',
      values: [
        '23',
        '22',
        '21',
        '20',
        '19',
        '18',
        '17',
        '16',
        '15',
        '14',
        '13',
        '12',
        '11',
        '10',
        '9',
        '8',
        '7',
        '6',
        '5',
        '4',
        '3',
        '2',
        '1',
        '0',
      ],
    },
  };
  return (
    <>
      {data.length > 0 && (
        <Chart
          height={700}
          data={source}
          scale={cols}
          padding={{ top: 0, right: 30, bottom: 80, left: 30 }}
          forceFit
        >
          <Axis name="day" />
          <Axis name="hour" />
          <Geom
            type="polygon"
            position="day*hour"
            color={['value', '#BAE7FF-#1890FF-#0050B3']}
            style={{
              paddingTop: 20,
              stroke: '#fff',
              lineWidth: 1,
            }}
          >
            <Label
              content="value"
              offset={-2}
              textStyle={{
                fill: '#fff',
                fontWeight: 'bold',
                shadowBlur: 2,
                shadowColor: 'rgba(0, 0, 0, .45)',
              }}
            />
          </Geom>
        </Chart>
      )}

      {data.length === 0 && <Empty />}
    </>
  );
};

export default WeekHeatmap;
