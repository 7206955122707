import { GridAssistant, useGridLlmService, UpdateGridAssistantPayload } from "@ombori/grid-llm-react";
import { useMutation, useQueryClient, UseMutationOptions } from 'react-query';
import storeAIAssistantQueryKeys from './store-ai-assistants-query-keys';
import { getStoreAiAssistantKnowledgeBaseFilesKeys } from "./use-store-ai-assistant-knowledge-base-files";

export const useStoreAIAssistantUpdate = () => {
  const gridLlmService = useGridLlmService();
  const queryClient = useQueryClient();

  const updateAssistantMutation = async (payload: UpdateGridAssistantPayload): Promise<GridAssistant> => {
    const assistant = await gridLlmService.updateAssistant(payload);
    return assistant;
  };

  const options: UseMutationOptions<GridAssistant, Error, UpdateGridAssistantPayload> = {
    mutationFn: updateAssistantMutation,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: storeAIAssistantQueryKeys.paginationPrefix(data.tenantId) });
      queryClient.setQueryData(storeAIAssistantQueryKeys.detail(data.id), data);

      const assistantFilesQueryKeys = getStoreAiAssistantKnowledgeBaseFilesKeys(data.id);
      queryClient.invalidateQueries(assistantFilesQueryKeys);
    },
  };

  return useMutation<GridAssistant, Error, UpdateGridAssistantPayload>(options);
};
