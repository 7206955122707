import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import TokensComponent from './developers-section-tokens.component';
import { RootModel, RootState } from '../../../../../store/models/root.model';

type TokensContainerProps = React.ComponentProps<typeof TokensComponent>;

const TokensContainer = (props: TokensContainerProps) => {
  const { fetchTokens, createToken, updateToken, deleteToken, clearToken } = useActions<
    RootModel
  >((actions) => ({
    fetchTokens: actions.tokens.fetch,
    createToken: actions.tokens.create,
    updateToken: actions.tokens.update,
    deleteToken: actions.tokens.delete,
    clearToken: actions.tokens.clear,
  }));

  const { tokens, loaded } = useStore<RootState>((state) => ({
    tokens: state.tokens.values,
    loaded: state.tokens.loaded,
  }));
 
  return (
    <TokensComponent
      {...props}
      tokens={tokens}
      loaded={loaded}
      fetchTokens={fetchTokens}
      createToken={createToken}
      updateToken={updateToken}
      deleteToken={deleteToken}
      clearToken={clearToken}
    />
  );
};

export default TokensContainer;
