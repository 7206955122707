import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import styled from '@emotion/styled';
import { Icon } from 'antd';
import Organisation from '../../../store/types/organisation';
import useQueryStringParam from '../../common/use-query-string-param/use-query-string-param';
import Overlay from '../../common/overlay/overlay.component';
import Spinner from '../../common/spinner/spinner.component';
import OnboardingPanelLayout from '../onboarding-panel-layout/onboarding-panel-layout.component';

interface DeviceSetupComponentProps extends RouteComponentProps {
  organisations: Organisation[];
  setupTenantForExistingUser: (params: { deviceSerial: string }) => void;
}

const DeviceSetupComponent = ({
  history,
  organisations,
  setupTenantForExistingUser,
}: DeviceSetupComponentProps) => {
  const [hasInitialized, setHasInitialized] = useState(false);
  const deviceSerial = useQueryStringParam('deviceSerial') as string;

  useEffect(() => {
    if (!organisations.length) {
      setupTenantForExistingUser({ deviceSerial });
    } else if (organisations.length === 1) {
      history.replace(
        `/organisations/${organisations[0].id}/device-setup?deviceSerial=${deviceSerial}`,
      );
    } else {
      setHasInitialized(true);
    }
  }, [deviceSerial, history, organisations, setupTenantForExistingUser]);

  if (!hasInitialized) {
    return (
      <Overlay>
        <Spinner />
      </Overlay>
    );
  }

  return (
    <OnboardingPanelLayout>
      {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
      <h1>Pick the tenant where you want to add the device 🤔</h1>
      <TenantList>
        {organisations.map((org) => (
          <TenantListItem
            key={org.id}
            to={`/organisations/${org.id}/device-setup?deviceSerial=${deviceSerial}`}
          >
            <TenantName>{org.displayName}</TenantName>
            <Icon type="right" />
          </TenantListItem>
        ))}
      </TenantList>
    </OnboardingPanelLayout>
  );
};

const TenantList = styled.div`
  display: flex;
  flex-direction: column;
`;

const TenantListItem = styled(Link)`
  display: flex;
  font-weight: normal;
  flex-direction: row;
  align-items: center;
  padding: 16px 0;
  width: 100%;
  cursor: pointer;
  min-height: 48px;
  transition: all 0.1s;
  border-bottom: solid 1px rgb(235, 237, 244);
  color: rgba(0, 0, 0, 0.65);
  &:last-child {
    border-bottom: 0;
  }

  &:hover,
  &:active,
  &:focus {
    color: rgba(0, 0, 0, 0.65);
    opacity: 0.8;
  }
`;

const TenantName = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 8px;
`;

export default DeviceSetupComponent;
