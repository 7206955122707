import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Button, Typography as AntdTypography, Row as AntdRow, Col, message } from 'antd';
import { Subheader } from '../../../common/app-layout/header';
import moment from 'moment';
import SchemaForm, {
  SchemaFormRef,
} from '../../../common/schema-form/schema-form.component';
import { buildFieldSchema, buildUiSchema } from './build-schema';
import useSchemaForm from '../../../common/use-schema-form/use-schema-form';
import useBootReleases from '../../../common/use-boot-releases';
import DeviceTypeEnum from '../../../../store/types/device-type.enum';
import {
  useGlobalConfiguration,
  useUpdateGlobalConfiguration,
} from '../../../common/use-global-configuration';
import GlobalConfiguration from '../../../../store/types/global-configuration';
import Overlay from '../../../common/overlay';
import { ApiError } from '../../../../services/api/api-error';
import { ButtonType } from '../../../../types/styled-component';
import Message from '../../../common/message';

const { Text } = AntdTypography;

const BootApi = () => {
  const formElement = useRef<SchemaFormRef>(null);

  const { t } = useTranslation();

  const { data: screenBootReleases = [] } = useBootReleases({ type: DeviceTypeEnum.WPA });
  const { data: mobileBootReleases = [] } = useBootReleases({
    type: DeviceTypeEnum.MOBILE_WPA,
  });

  const {
    data: initGlobalConfigurationData,
    isLoading: isInitialLoading,
    isSuccess,
    error,
  } = useGlobalConfiguration();
  const {
    mutateAsync: updateGlobalConfiguration,
    data: updatedGlobalConfigurationData,
    isLoading: isUpdating,
  } = useUpdateGlobalConfiguration();

  const { fieldSchema, uiSchema } = useMemo(() => {
    return {
      fieldSchema: buildFieldSchema({
        t,
        screenBootReleases,
        mobileBootReleases,
      }),
      uiSchema: buildUiSchema(),
    };
  }, [t, mobileBootReleases, screenBootReleases]);

  const handleSubmit = useCallback(
    async (data: GlobalConfiguration) => {
      try {
        await updateGlobalConfiguration(data);
        message.success(
          <Message content={t('bootApiConfig.successUpdatingData')} />,
        );
      } catch (e) {
        message.error(
          <Message
            content={t('bootApiConfig.errorUpdatingData')}
            error={e as ApiError}
          />,
        );
        throw e;
      }
    },
    [updateGlobalConfiguration, t],
  );

  const handleSave = useCallback(() => {
    if (formElement.current) {
      formElement.current.submit();
    }
  }, []);

  const [formData, , , , handleFormChange, handleSubmittedForm] = useSchemaForm<
    GlobalConfiguration
  >(handleSubmit, undefined, undefined, false);

  const globalConfigurationData =
    updatedGlobalConfigurationData || initGlobalConfigurationData;
  const updatedFormData = Object.keys(formData).length
    ? formData
    : globalConfigurationData;

  const lastEditedTimestamp = useMemo(() => {
    const { updatedAt = '' } = updatedFormData || {};

    return updatedAt ? moment(updatedAt).format('DD/MM/YYYY hh:mm A') : '';
  }, [updatedFormData]);

  return (
    <Overlay
      spinnerOverlay={{
        processingTip: null,
        isLoading: isInitialLoading,
      }}
      errorOverlay={{
        error: error as ApiError,
      }}
    >
      <Subheader
        components={[
          ...(isSuccess
            ? [
                <LastEdited key="last-edited">
                  {t('lastEditedTimestamp', { timestamp: lastEditedTimestamp })}
                </LastEdited>,
              ]
            : []),
          <SaveButton
            key="save"
            size="large"
            type="primary"
            onClick={handleSave}
            disabled={isUpdating}
            loading={isUpdating}
          >
            {t('saveAllChanges')}
          </SaveButton>,
        ]}
      />
      <FormWrap>
        <Row gutter={{ md: 20, xl: 40 }}>
          <StickyColumn />
          <ContentColumn>
            <SchemaForm
              ref={formElement}
              schema={fieldSchema}
              uiSchema={uiSchema}
              data={updatedFormData}
              onSubmit={handleSubmittedForm}
              onChange={handleFormChange}
              liveValidate={false}
            />
          </ContentColumn>
        </Row>
      </FormWrap>
    </Overlay>
  );
};

const FormWrap = styled.div`
  position: relative;
  flex: 1;
  padding: 64px 80px 40px;

  @media screen and (max-width: 991px) {
    padding: 20px;
  }
`;

const LastEdited = styled(Text)`
  color: #676973;
  font-size: 13px;
`;

const SaveButton = styled(Button)`
  background-color: #00ae48;
  border-color: #00ae48;
  height: 36px;
  font-size: 14px;
  margin-left: auto;

  :hover,
  :active,
  :focus {
    background-color: #00ae48e8;
    border-color: #00ae48e8;
  }
` as ButtonType;

const Row = styled(AntdRow)`
  @media (min-width: 1200px) {
    display: flex;
  }
`;

export const StickyColumn = styled(Col)`
  width: 100%;
  z-index: 1;

  @media (min-width: 1200px) {
    position: sticky;
    display: block;
    width: 25%;
    top: 16px;
    height: fit-content;
  }
`;

const ContentColumn = styled(Col)`
  width: 100%;

  @media (min-width: 1200px) {
    display: block;
    width: 75%;
  }

  @media (min-width: 1400px) {
    display: block;
    width: 50%;
  }
`;

export default BootApi;
