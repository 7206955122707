import { useQuery } from 'react-query';
import {
  DataSourceBaseEntity,
  InteractionType,
  SessionInteractionType,
} from '@ombori/grid-reports';
import { useGridDataService } from '../../grid-data-service-provider';
import {
  TenantEventsByDay,
  BaseProps,
  TimeSpanType,
  TenantInteractiveSessionsByDay,
  TenantSessionsByDay,
  AggregatedTenantNpsByDay,
  UseEventsFlowByDayProps,
  TenantEventsFlowByDay,
  TenantProductsByDay,
  TenantCategoriesByDay,
  TenantPurchasesByDay,
  TenantPurchaseProductByDay,
  TenantQrCodesByDay,
  TenantMediaByDay,
  TenantEventsByHour,
  TenantInteractiveSessionsByHour,
  TenantSessionsByHour,
  TenantAndSpaceEventsByDay,
} from './types';
import buildQueryKey from './build-query-key';
import routes from '../routes';

interface UseTenantEventsByDayProps extends BaseProps {
  interactionType?: InteractionType;
  eventType?: string;
}

export const useTenantEventsByDay = ({
  tenantId,
  dateFrom,
  dateTo,
  interactionType = InteractionType.All,
  dataResidency,
  eventType,
}: UseTenantEventsByDayProps) => {
  const gridDataService = useGridDataService();

  const buildTenantEventsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'interactionType',
    interactionType,
    'events',
    ...(eventType ? ['eventType', eventType] : []),
  ];

  const baseQuery = {
    dateFrom,
    dateTo,
    timespanType: TimeSpanType.Day,
    interactionType,
  };

  const query = eventType ? { ...baseQuery, eventType } : baseQuery;

  return useQuery(
    buildTenantEventsQuery(),
    () =>
      gridDataService.get<TenantEventsByDay[]>(
        routes.getTenantEvents(tenantId),
        dataResidency,
        query,
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseTenantEventsBySpaceAndDay extends BaseProps {
  interactionType?: InteractionType;
  dataSource?: DataSourceBaseEntity;
  eventType?: string;
}

export const useTenantEventsBySpaceAndDay = ({
  tenantId,
  dateFrom,
  dateTo,
  interactionType = InteractionType.All,
  dataResidency,
  dataSource,
  eventType,
}: UseTenantEventsBySpaceAndDay) => {
  const gridDataService = useGridDataService();

  const buildTenantAndSpaceEventsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'interactionType',
    interactionType,
    'events',
    'dataSource',
    dataSource,
    ...(eventType ? ['eventType', eventType] : []),
  ];

  const baseQuery = {
    dateFrom,
    dateTo,
    timespanType: TimeSpanType.Day,
    interactionType,
    dataSource,
  };

  const query = eventType ? { ...baseQuery, eventType } : baseQuery;

  return useQuery(
    buildTenantAndSpaceEventsQuery(),
    () =>
      gridDataService.get<TenantAndSpaceEventsByDay[]>(
        routes.getTenantEvents(tenantId),
        dataResidency,
        query,
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseTenantSessionsByDayProps extends BaseProps {
  interactionType?: SessionInteractionType;
}

export const useTenantSessionsByDay = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  interactionType = SessionInteractionType.All,
}: UseTenantSessionsByDayProps) => {
  const gridDataService = useGridDataService();

  const buildTenantSessionsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'interactionType',
    interactionType,
    'sessions',
  ];

  return useQuery(
    buildTenantSessionsQuery(),
    () =>
      gridDataService.get<
        typeof interactionType extends SessionInteractionType.Interactive
          ? TenantInteractiveSessionsByDay[]
          : TenantSessionsByDay[]
      >(routes.getTenantSessions(tenantId), dataResidency, {
        dateFrom,
        dateTo,
        interactionType,
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export const useTenantNpsByDay = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
}: BaseProps) => {
  const gridDataService = useGridDataService();

  const buildTenantNpsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'nps',
  ];

  return useQuery(
    buildTenantNpsQuery(),
    () =>
      gridDataService.get<AggregatedTenantNpsByDay>(
        routes.getTenantNps(tenantId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export const useTenantEventsFlowByDay = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  eventsFlowDepth,
}: UseEventsFlowByDayProps) => {
  const gridDataService = useGridDataService();

  const buildTenantEventsFlowQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'eventsFlowDepth',
    eventsFlowDepth,
    'eventsFlow',
  ];

  return useQuery(
    buildTenantEventsFlowQuery(),
    () =>
      gridDataService.get<TenantEventsFlowByDay[]>(
        routes.getTenantEventsFlow(tenantId),
        dataResidency,
        {
          dateFrom,
          dateTo,
          eventsFlowDepth,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export const useTenantProductsByDay = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
}: BaseProps) => {
  const gridDataService = useGridDataService();

  const buildTenantProductsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'products',
  ];

  return useQuery(
    buildTenantProductsQuery(),
    () =>
      gridDataService.get<TenantProductsByDay[]>(
        routes.getTenantProducts(tenantId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export const useTenantCategoriesByDay = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
}: BaseProps) => {
  const gridDataService = useGridDataService();

  const buildTenantCategoriesQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'categories',
  ];

  return useQuery(
    buildTenantCategoriesQuery(),
    () =>
      gridDataService.get<TenantCategoriesByDay[]>(
        routes.getTenantCategories(tenantId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export const useTenantPurchasesByDay = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
}: BaseProps) => {
  const gridDataService = useGridDataService();

  const buildPurchasesQueryKey = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'purchases',
  ];

  return useQuery(
    buildPurchasesQueryKey(),
    () =>
      gridDataService.get<TenantPurchasesByDay[]>(
        routes.getTenantPurchases(tenantId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export const useTenantPurchasedProductsByDay = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
}: BaseProps) => {
  const gridDataService = useGridDataService();

  const buildPurchasesQueryKey = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'purchased-products',
  ];

  return useQuery(
    buildPurchasesQueryKey(),
    () =>
      gridDataService.get<TenantPurchaseProductByDay[]>(
        routes.getTenantPurchasedProducts(tenantId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export const useTenantQrCodesByDay = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
}: BaseProps) => {
  const gridDataService = useGridDataService();

  const buildQrCodesQueryKey = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'qr-codes',
  ];

  return useQuery(
    buildQrCodesQueryKey(),
    () =>
      gridDataService.get<TenantQrCodesByDay[]>(
        routes.getTenantQrCodes(tenantId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export const useTenantMediaByDay = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  isEnabled = true,
}: BaseProps) => {
  const gridDataService = useGridDataService();

  const buildMediaQueryKey = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'media',
  ];

  return useQuery(
    buildMediaQueryKey(),
    () =>
      gridDataService.get<TenantMediaByDay[]>(
        routes.getTenantMedia(tenantId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      enabled: isEnabled,
    },
  );
};

export const useTenantEventsByHour = ({
  tenantId,
  dateFrom,
  dateTo,
  interactionType = InteractionType.All,
  dataResidency,
}: UseTenantEventsByDayProps) => {
  const gridDataService = useGridDataService();

  const timespanType = TimeSpanType.Hour;

  const buildTenantEventsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType }),
    'interactionType',
    interactionType,
    'events',
  ];

  return useQuery(
    buildTenantEventsQuery(),
    () =>
      gridDataService.get<TenantEventsByHour[]>(
        routes.getTenantEvents(tenantId),
        dataResidency,
        {
          dateFrom,
          dateTo,
          timespanType,
          interactionType,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseTenantSessionsByHourProps extends BaseProps {
  interactionType?: SessionInteractionType;
}

export const useTenantSessionsByHour = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  interactionType = SessionInteractionType.All,
}: UseTenantSessionsByHourProps) => {
  const gridDataService = useGridDataService();

  const timespanType = TimeSpanType.Hour;

  const buildTenantSessionsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType }),
    'interactionType',
    interactionType,
    'sessions',
  ];

  return useQuery(
    buildTenantSessionsQuery(),
    () =>
      gridDataService.get<
        typeof interactionType extends SessionInteractionType.Interactive
          ? TenantInteractiveSessionsByHour[]
          : TenantSessionsByHour[]
      >(routes.getTenantSessions(tenantId), dataResidency, {
        dateFrom,
        dateTo,
        timespanType,
        interactionType,
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};
