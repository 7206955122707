import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Device from '../store/types/device';
import UniversalDevice from '../store/types/universal-device';

dayjs.extend(utc);

const toDateTime = (time: string) => {
  const [hour, minute] = time.split(':');

  return dayjs()
    .utc()
    .hour(parseInt(hour, 10))
    .minute(parseInt(minute, 10));
};

// TO DO: Better to define in universal-device.ts,
// but would require multiple export and changes to existing references
export interface DeviceExpectedAvailability {
  days: number[];
  start?: string;
  stop?: string;
}

const isValidExpectedAvailability = (
  deviceExpectedAvailability?: DeviceExpectedAvailability,
) => {
  if (deviceExpectedAvailability) {
    const now = dayjs().utc();
    let { days = [] } = deviceExpectedAvailability;
    const { start = '', stop = '' } = deviceExpectedAvailability;

    try {
      if (!Array.isArray(days)) {
        days = JSON.parse(days);
      }

      if (Array.isArray(days) && days.some((d) => now.isSame(now.day(d), 'day'))) {
        if (start && stop) {
          return now.isAfter(toDateTime(start)) && now.isBefore(toDateTime(stop));
        }

        return true;
      }
    } catch {
      return false;
    }

    return false;
  }

  return true;
};

export default function isMonitored(device: Device | UniversalDevice) {
  // All balena devices should be monitored
  if ((device as Device).balenaStatus) {
    return true;
  }

  // Non-iot edge devices. Schema of device does not have deviceTags
  if (!(device as UniversalDevice).iotedge && device.deviceMonitored) {
    return isValidExpectedAvailability(device.deviceExpectedAvailability);
  }

  if (device.deviceMonitored && device.deviceExpectedAvailability) {
    return isValidExpectedAvailability(device.deviceExpectedAvailability);
  }

  return false;
}
