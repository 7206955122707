import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import UniversalDevicesListviewComponent from './universal-devices-listview.component';
import { RootModel, RootState } from '../../../../store/models/root.model';

type UniversalDevicesListContainerType = Omit<
  React.ComponentProps<typeof UniversalDevicesListviewComponent>,
  'rebootDevice' | 'restartDevice' | 'deleteDevice'
>;

const UniversalDevicesListContainer = (props: UniversalDevicesListContainerType) => {
  const { rebootDevice, restartDevice, deleteDevice } = useActions<RootModel>(
    (actions) => ({
      rebootDevice: actions.universalDeviceDetails.reboot,
      restartDevice: actions.universalDeviceDetails.restart,
      deleteDevice: actions.universalDeviceDetails.delete,
    }),
  );

  const { isSysAdmin } = useStore<RootState>((state: RootState) => ({
    isSysAdmin: state.auth.isSysAdmin,
  }))

  return (
    <UniversalDevicesListviewComponent
      {...props}
      rebootDevice={rebootDevice}
      restartDevice={restartDevice}
      deleteDevice={deleteDevice}
      isSysAdmin={isSysAdmin}
    />
  );
};

export default UniversalDevicesListContainer;
