import queryString from 'query-string';

const useQueryStringParam = (param: string) => {
  const params = new URLSearchParams(document.location.search);

  return params.get(param);
};

export const useQueryString = () => {
  const params = queryString.parse(document.location.search);
  return params;
};

export const getQueryStringFromDocument = (doc: Document) => {
  const params = queryString.parse(doc.location.search);
  return params;
};

export default useQueryStringParam;
