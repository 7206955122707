import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styled from '@emotion/styled';
import { Typography, Icon, Row, Button, Modal, message } from 'antd';
import { get } from 'lodash';
import PanelCard from '../../../../../common/panel-card/panel-card.component';
import OrganisationBillingType from '../../../../../../store/types/organisation-billing-type.enum';
import { Subscription, StripeCard } from '../../../../../../store/types/subscription';
import {
  CardBrandEnum,
  BillingTypeEnum,
  SubscriptionStateEnum,
} from '../../../../../../store/models/subscriptions/subscription.model';
import Loader from '../../../../../common/loader/loader-component';
import ErrorView from '../../../../../common/error-view/error-view.component';

const { Text } = Typography;

type FetchingType = 'ongoing' | 'done' | 'failed';

interface PaymentMethodProps {
  billingType?: OrganisationBillingType;
  organizationId: string;
  subscription: Subscription;
  onCancelSubscription: (params: { organizationId: string }) => Promise<void>;
  onFetchSubscription: (params: { organizationId: string }) => Promise<void>;
}

const PaymentMethod = (props: PaymentMethodProps) => {
  const {
    billingType,
    organizationId,
    subscription,
    onCancelSubscription,
    onFetchSubscription,
  } = props;
  const { t } = useTranslation();
  const { source = '', status = '', data = {} } = subscription || {};
  const { brand = '', last4 = '' } = (get(data, 'card') as StripeCard) || {};

  const [subscriptionState, setSubscriptionState] = useState<string>(status);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [fetchingStatus, setFetchingStatus] = useState<FetchingType>('ongoing');
  const [hasMounted, setHasMounted] = useState<boolean>(false);

  useEffect(() => {
    onFetchSubscription({ organizationId })
      .then(() => {
        setFetchingStatus('done');
      })
      .catch(() => setFetchingStatus('failed'));
    setHasMounted(true);
  }, [organizationId, onFetchSubscription]);

  const handleCancelSubscription = useCallback(() => {
    const modal = Modal.confirm({
      title: t('confirmAction'),
      content: (
        <ModalContent>
          <Trans
            defaults={t('billing.cancelSubscriptionConfirmation')}
            components={{ b: <b /> }}
          />
        </ModalContent>
      ),
      width: '30%',
      okText: t('yes'),
      cancelText: t('no'),
      className: 'modal-content-m-0',
      onOk: async () => {
        modal.update({ cancelButtonProps: { disabled: true } });
        setIsProcessing(true);
        try {
          await onCancelSubscription({ organizationId });

          const msg = `${t('billing.cancelSubscriptionSuccess')}. ${t(
            'billing.cancelSubscriptionRedirection',
          )}`;
          modal.destroy();
          message.success(msg);

          setSubscriptionState(SubscriptionStateEnum.UNSUBSCRIBE);

          await new Promise((r) => setTimeout(r, 2000)); // Pause to allow user read the message

          document.location.href = `/organisations/${organizationId}/subscription`;
        } catch (err) {
          message.error(t('billing.cancelSubscriptionFailure'));
          setIsProcessing(false);
        } finally {
          modal.update({ cancelButtonProps: { disabled: false } });
        }
      },
      onCancel: () => {},
    });
  }, [onCancelSubscription, organizationId, t]);

  const { icon, text } = useMemo(() => {
    switch (source) {
      case BillingTypeEnum.STRIPE:
        return {
          icon: 'credit-card',
          text:
            brand && last4
              ? t('billing.cardBrandEndingLast4', {
                  brandName: get(CardBrandEnum, brand.toUpperCase(), brand),
                  last4,
                })
              : t('billing.creditCard'),
        };
      case BillingTypeEnum.AZURE_SAAS:
        return {
          icon: 'appstore',
          text: t('billing.azureSaas'),
        };
      case BillingTypeEnum.DIRECT_INVOICE:
        return {
          icon: 'snippets',
          text: t('billing.invoice'),
        };
      case BillingTypeEnum.PARTNER_INVOICE:
        return {
          icon: 'team',
          text: t('billing.partnerInvoice'),
        };
      default:
        return {
          icon: 'question-circle',
          text: source,
        };
    }
  }, [brand, last4, source, t]);

  const noPaymentMethod = useMemo(() => {
    return (!subscription && billingType === BillingTypeEnum.LEGACY) ||
      fetchingStatus === 'failed' ? (
      <ErrorView title={t('billing.noPaymentAvailable')} content={null} fillPage />
    ) : null;
  }, [billingType, fetchingStatus, subscription, t]);

  return (
    <PanelCardStyled title={t('billing.paymentMethod')}>
      <Loader
        loading={fetchingStatus === 'ongoing'}
        error={noPaymentMethod}
        hasMounted={hasMounted}
        errorFillPage
      >
        <Content>
          <RowStyled>
            <Icon type={icon} />
            <BoldText>{text}</BoldText>
          </RowStyled>
          {subscriptionState === SubscriptionStateEnum.UNSUBSCRIBE ? (
            <TextStyled type="danger" align="center">
              {t('billing.cancelledSubscription')}
            </TextStyled>
          ) : (
            <Row>
              <Button
                className="btn-danger"
                size="large"
                onClick={handleCancelSubscription}
                disabled={isProcessing}
              >
                {t('billing.cancelSubscription')}
              </Button>
            </Row>
          )}
        </Content>
      </Loader>
    </PanelCardStyled>
  );
};

const PanelCardStyled = styled(PanelCard)`
  height: auto;

  .ant-card-head {
    border-bottom: 0px;
  }

  .ant-card-head-title {
    font-size: 18px;
    font-weight: 400;

    padding-bottom: 0px;
  }

  .ant-card-body {
    padding: 0;
    min-height: 80px;
    position: relative;
  }
`;

const Content = styled.div`
  padding: 0 16px 16px 16px;
`;

const RowStyled = styled(Row)`
  font-size: 25px;
  color: rgba(0, 0, 0, 0.85);
  margin: 5px 0px 15px 0px;

  > span {
    margin-left: 10px;
  }
`;

const ModalContent = styled.div`
  margin-top: 20px;
`;

const TextStyled = styled(Text)<{ align?: string }>`
  font-size: 15px;
  text-align: ${({ align }) => align || 'left'};
  margin-top: 5px;
`;

const BoldText = styled.span`
  font-weight: 600;
  letter-spacing: 0.75px;
`;

export default PaymentMethod;
