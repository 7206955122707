import { action, thunk, Thunk, Action } from 'easy-peasy';
import { EnterpriseAgreement, Filters } from '../../types/enterprise-agreement';
import createKeyedCrudModel, {
  KeyedCrudModel,
} from '../../common/keyed-crud-model/keyed-crud.model';
import Injections from '../../injections.interface';
import { ApiError } from '../../../services/api/api-error';

const path = '/api/enterprise-agreements';

export const KEY_ENTERPRISE_AGREEMENTS = 'enterpriseAgreements';

export type EnterpriseAgreementsModel = KeyedCrudModel<EnterpriseAgreement> & {
  fetchEnterpriseAgreements: Thunk<
    EnterpriseAgreementsModel,
    { params?: { [key: string]: string }; silent?: boolean },
    Injections
  >;
  sendEnterpriseAgreementInvite: Thunk<
    EnterpriseAgreementsModel,
    { enterpriseAgreementId: string },
    Injections
  >;
  fetchFilters: Thunk<
    EnterpriseAgreementsModel,
    { params?: { [key: string]: string }; silent?: boolean },
    Injections
  >;
  filters: Filters | null;
  filtersLoading: boolean;
  filtersError: ApiError | null;
  setFilters: Action<EnterpriseAgreementsModel, { data: Filters }>;
  setFiltersLoading: Action<EnterpriseAgreementsModel, { loading: boolean }>;
  setFiltersError: Action<EnterpriseAgreementsModel, { error: ApiError | null }>;
};

// TODO: DK: This should probably be a CrudModel and not KeyedCrudModel
const enterpriseAgreementsModel: EnterpriseAgreementsModel = {
  ...createKeyedCrudModel<
    EnterpriseAgreement,
    { enterpriseAgreementId: string; enterpriseAgreements: any }
  >(path, 'enterpriseAgreements'),
  filters: null,
  filtersLoading: true,
  filtersError: null,
  fetchEnterpriseAgreements: thunk(
    async (actions, { params = {}, silent = false }, { injections }) => {
      const key = KEY_ENTERPRISE_AGREEMENTS;

      if (!silent) {
        actions.setLoading({ key, loading: true });
      }
      actions.setError({ key, error: null });

      try {
        const data = await injections.apiService.get<EnterpriseAgreement[]>(path, params);
        actions.setData({ key, data });
      } catch (error) {
        if (!silent) {
          actions.setError({ key, error });
        } else {
          throw error;
        }
      } finally {
        if (!silent) {
          actions.setLoading({ key, loading: false });
        }
      }
    },
  ),
  sendEnterpriseAgreementInvite: thunk(
    async (actions, { enterpriseAgreementId }, { injections }) => {
      const key = KEY_ENTERPRISE_AGREEMENTS;
      const sendInvitePath = `${path}/${enterpriseAgreementId}/send-invite`;

      const data = await injections.apiService.post<EnterpriseAgreement>(sendInvitePath);
      actions.setSingle({ key, data });
    },
  ),
  setFilters: action((state, { data }) => {
    state.filters = data;
  }),
  setFiltersLoading: action((state, { loading }) => {
    state.filtersLoading = loading;
  }),
  setFiltersError: action((state, { error }) => {
    state.filtersError = error;
  }),
  fetchFilters: thunk(
    async (actions, { params = {}, silent = false }, { injections }) => {
      if (!silent) {
        actions.setFiltersLoading({ loading: true });
      }
      actions.setFiltersError({ error: null });

      try {
        const newPath = `${path}/filters`;
        const data = await injections.apiService.get<Filters>(newPath, params);
        actions.setFilters({ data });
      } catch (error) {
        if (!silent) {
          actions.setFiltersError({ error });
        } else {
          throw error;
        }
      } finally {
        if (!silent) {
          actions.setFiltersLoading({ loading: false });
        }
      }
    },
  ),
};

export default enterpriseAgreementsModel;
