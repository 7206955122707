import React, { useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import styled from '@emotion/styled';
import { Button, Col, Row, message } from 'antd';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { ButtonType } from '../../../../../../types';
import { StickyColumn } from '../../../queue-details/queue-setup/styled-block';
import {
  IntegrationFormFields,
  IntegrationSidePanel,
  integrationTypes,
  validateIntegrationForm,
} from '../common';
import {
  scrollIntoView,
  getFieldNamesWithError,
  ScheduleFrequencyEnum,
} from '../../../../../common/react-final-form';
import { IsoLanguageIds } from '@ombori/grid-products';
import { useTranslation } from 'react-i18next';
import Message from '../../../../../common/message';
import { ApiError } from '../../../../../../services/api/api-error';
import {
  AddIntegrationVariables,
  useAddIntegration,
  useIntegrationInvalidateQueries,
} from '../hooks';
import { DataResidencyEnum } from '../../../../../../store/types/organisation';
import { useEnvironments } from '../../../../../common/use-environments';
import {
  IntegrationFormValues,
  IntegrationTypeEnum,
  IntegrationFormInitialValues,
  IntegrationStatusEnum,
} from '../types';
import Overlay from '../../../../../common/overlay';

export type IntegrationCreateFormProps = RouteComponentProps<{
  organisationId: string;
  type: IntegrationTypeEnum;
}> & {
  dataResidency: DataResidencyEnum;
};

const IntegrationCreateForm = (props: IntegrationCreateFormProps) => {
  const { match, dataResidency } = props;

  const { organisationId: tenantId, type: integrationType } = match.params;

  const { t } = useTranslation();

  const history = useHistory();

  const { mutateAsync: addIntegration, isLoading: isCreating } = useAddIntegration({
    tenantId,
    dataResidency,
  });

  const { invalidateGetIntegrationsQuery } = useIntegrationInvalidateQueries();

  const { data: environments = [], isLoading: isEnvironmentsLoading } = useEnvironments(tenantId);

  const environmentOptions = environments.map((environment) => {
    const { environmentName, displayName } = environment;

    return {
      value: environmentName,
      label: displayName,
    };
  });

  const integrationsListPath = `/organisations/${tenantId}/settings/integrations`;

  const handleBackClick = useCallback(() => {
    history.push(integrationsListPath);
  }, [history, integrationsListPath]);

  const handleFormCreateSubmit = useCallback(
    async (values) => {
      try {
        const formData = (values as unknown) as IntegrationFormValues;

        formData.type = integrationType;
        formData.config = {
          ...formData.config,
          format: 'xml',
        };

        const { schedule, ...restFormData } = formData;

        const updatedSchedule = schedule.map((sched) => sched.cronExpressionUtc);

        const payload: AddIntegrationVariables = {
          ...restFormData,
          schedule: updatedSchedule,
        };

        await addIntegration(payload);

        await invalidateGetIntegrationsQuery(tenantId);

        message.success(<Message content={t('productIntegration.successSavingData')} />);

        history.push(integrationsListPath);
      } catch (error) {
        const err = (error as unknown) as ApiError;

        message.error(
          <Message content={t('productIntegration.errorSavingData')} error={err} />,
        );
      }
    },
    [
      addIntegration,
      history,
      integrationType,
      invalidateGetIntegrationsQuery,
      t,
      tenantId,
      integrationsListPath,
    ],
  );

  const { label: integrationTypeLabel = '' } =
    integrationTypes.find((type) => type.value === integrationType) || {};

  const initialValues = useMemo((): Partial<IntegrationFormInitialValues> => {
    return {
      status: IntegrationStatusEnum.ACTIVE,
      schedule: [
        {
          frequency: ScheduleFrequencyEnum.EVERY_DAY,
          time: undefined,
          cronExpression: '',
          cronExpressionUtc: '',
        },
      ],
      ...(integrationType === IntegrationTypeEnum.GOOGLE_PRODUCT_FEED
        ? { config: { feeds: [{ locale: IsoLanguageIds.en_US, url: '' }] } }
        : {}),
    };
  }, [integrationType]);

  return (
    <IntegrationFormContent>
      <Overlay spinnerOverlay={{ isLoading: isEnvironmentsLoading }}>
        <BackToListButton type="default" icon="arrow-left" onClick={handleBackClick}>
          {t('back')}
        </BackToListButton>
        <Form
          onSubmit={handleFormCreateSubmit}
          validate={(values) =>
            validateIntegrationForm({
              ...((values as unknown) as IntegrationFormValues),
              type: integrationType,
            })
          }
          keepDirtyOnReinitialize
          initialValues={initialValues}
          mutators={{
            ...arrayMutators,
          }}
          render={({
            submitting: isSubmitting,
            handleSubmit,
            invalid: isFormInvalid,
            errors,
          }) => {
            return (
              <form
                onSubmit={(event) => {
                  handleSubmit(event);

                  const errorFieldNames = getFieldNamesWithError(errors);

                  if (!isFormInvalid || !errorFieldNames.length) {
                    return;
                  }

                  scrollIntoView(errorFieldNames[0]);
                }}
              >
                <Row gutter={{ md: 20, xl: 40 }}>
                  <Col md={24} xl={15}>
                    <IntegrationFormFields
                      integrationType={integrationType}
                      title={t('productIntegration.addIntegration', {
                        label: integrationTypeLabel,
                      })}
                      environments={environmentOptions}
                    />
                  </Col>
                  <StickyColumn md={24} xl={9}>
                    <IntegrationSidePanel
                      isSubmitEnabled={
                        !isSubmitting && !isCreating
                      }
                      isSubmitting={isCreating}
                      isStatusEnabled={false}
                    />
                  </StickyColumn>
                </Row>
              </form>
            );
          }}
        />
      </Overlay>
    </IntegrationFormContent>
  );
};

const IntegrationFormContent = styled.div`
  position: relative;
  flex: 1;
  padding: 0 80px 40px;

  @media screen and (max-width: 991px) {
    padding: 0;
  }
`;

const BackToListButton = styled(Button)`
  height: 36px;
  font-size: 14px;
  color: #676973;
  border: none;
  margin-right: 5px;
  background-color: transparent;

  :hover,
  :active,
  :focus {
    background-color: #f2f4f8;
  }
` as ButtonType;

export default IntegrationCreateForm;
