import React, { useMemo } from 'react';
import { Select as SelectSearch } from 'antd';
import {
  SelectValue,
  OptionProps,
} from 'antd/lib/select';

interface OptionPropsExt extends OptionProps {
  ['data-label']?: string;
}

type OptionValue = React.ReactElement<OptionPropsExt, string | React.JSXElementConstructor<any>>;

export default function SelectAdapter({
  options,
  input: { onChange, value },
  isLoading =  false,
}: {
  options: string[] | { key: string; label: string }[];
  input: { onChange: any; value: string };
  isLoading?: boolean;
}) {
  const optionsDisplay = useMemo(
    () => {
      const isStringArray = typeof options[0] === 'string';

      if (isStringArray) {
        const list = options as string[];
        return list.map((option) => (
          <SelectSearch.Option key={option} value={option} data-label={option}>
            {option}
          </SelectSearch.Option>
        ));
      }

      const list = options as { key: string; label: string }[];
      return list.map((option) => (
        <SelectSearch.Option key={option.key} value={option.key} data-label={option.label}>
          {option.label}
        </SelectSearch.Option>
      ));
    },
    [options],
  );

  return (
    <SelectSearch
      showSearch
      loading={isLoading}
      optionFilterProp="children"
      value={value}
      onSelect={(value: SelectValue) => onChange(value)}
      filterOption={(
        input: string,
        option: OptionValue,
      ) => {
        const optionLabel = option.props['data-label'];
        return !!optionLabel && optionLabel.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
    >
      {optionsDisplay}
    </SelectSearch>
  );
}