import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button as AntdButton, Collapse, Icon as AntdIcon } from 'antd';
import styled, { StyledComponent } from '@emotion/styled';
import { CollapseProps } from 'antd/lib/collapse';
import { ButtonProps } from 'antd/lib/button';
import { ChevronUp } from 'react-feather';
import transientOptions from '../../../../../../utils/transient-emotion-styled-options';
import ImageSettings, { ImageSettingsData } from './image-settings.component';
import { FileImageSettings, FileSettings, ImageMimeType, MimeType } from '../../../../../../store/types/media';

export type SettingsData = ImageSettingsData;

interface SettingsProps {
  id?: string;
  isExpanded?: boolean;
  mimeType: MimeType;
  onChange?: (settings: SettingsData) => void;
  onClose?: () => void;
  value: FileSettings;
  isReadonly?: boolean;
}

const Settings = (props: SettingsProps) => {
  const { isExpanded, id = 'panel', mimeType, onChange, onClose, value, isReadonly } = props;

  const { t } = useTranslation();

  const attributeFields = useMemo(() => {
    if (Object.values(ImageMimeType).some((type) => type === mimeType)) {
      return <ImageSettings onChange={onChange} value={value as FileImageSettings} isReadonly={isReadonly} />;
    }

    return null;
  }, [isReadonly, mimeType, onChange, value]);

  return (
    <SettingsContainer $isExpanded={isExpanded}>
      <CollapseContainer
        activeKey={isExpanded ? [id] : []}
        expandIconPosition="right"
        bordered={false}
      >
        <CollapsePanel key={id} header={null} showArrow={false}>
          {attributeFields}
          <Button
            type="link"
            onClick={onClose}
          >
            <Icon size={18} component={() => <ChevronUp />} />
            {t('filePicker.close')}
          </Button>
        </CollapsePanel>
      </CollapseContainer>
    </SettingsContainer>
  );
};

const SettingsContainer = styled('div', transientOptions)<{ $isExpanded?: boolean }>`
  & :has(.ant-collapse-item-active) {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }
`;

type StyledCollapseType = StyledComponent<
  CollapseProps & React.ClassAttributes<Collapse>,
  Pick<CollapseProps & React.ClassAttributes<Collapse>, keyof CollapseProps>,
  any
>;
const CollapseContainer = styled(Collapse)`
  border-top: 0;
  border-radius: inherit;
` as StyledCollapseType;

const CollapsePanel = styled(Collapse.Panel)`
  border-radius: inherit !important;

  > .ant-collapse-header {
    display: none;
  }

  > .ant-collapse-content {
    border-radius: inherit !important;

    > .ant-collapse-content-box {
      padding: 12px 16px !important;
      border-radius: inherit;
    }
  }
`;

type StyledButtonType = StyledComponent<
  ButtonProps & React.ClassAttributes<AntdButton> & { $isActive?: boolean },
  Pick<ButtonProps & React.ClassAttributes<AntdButton>, keyof ButtonProps>,
  any
>;
const Button = styled(AntdButton, transientOptions)<{ $isActive?: boolean }>`
  display: flex;
  align-items: center;
  box-shadow: unset;
  border: unset;
  background-color: unset;
  
  margin: 10px auto 0;

  :hover {
    background-color: rgb(0 100 82 / 8%);
  }

  &.ant-btn-link {
    color: #676973;
  }

  ${({ $isActive }) =>
    $isActive
      ? `
      &, :active, :focus {
        background-color: rgb(0 100 82 / 8%);
      }
    `
      : ''}
` as StyledButtonType;

const Icon = styled(AntdIcon)<{ size?: number }>`
  svg {
    height: ${({ size = 16 }) => `${size}px`};
    width: ${({ size = 16 }) => `${size}px`};
  }

  &.anticon + span {
    margin-left: 5px;
  }
`;

export default Settings;
