import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from '@emotion/styled';
import { Row, Col } from 'antd';
import AccountBalanceContainer from './account-balance/account-balance.container';
import PaymentMethodContainer from './payment-method/payment-method.container';
import { getPermissions } from '../../../../../utils/auth/permissions';
import TransactionsContainer from './transactions/transactions.container';
import ProfessionalServicesContainer from './professional-services/professional-services.container';
import { useStoreState } from '../../../../../store/initialize-store';

const OverviewBilling = (props: RouteComponentProps<{ organisationId: string }>) => {
  const { match } = props;
  const { organisationId } = match.params;

  const { permissions } = useStoreState(
    (state) => ({
      permissions: getPermissions(state.auth.user, organisationId),
    }),
    [organisationId],
  );

  return (
    <>
      <div className="content-body">
        <Row gutter={{ md: 20, xl: 40 }}>
          {permissions.accountBalance.viewSingle && (
            <Col md={24} xl={15}>
              <Section>
                <AccountBalanceContainer organizationId={organisationId} />
              </Section>
            </Col>
          )}
          <Col md={24} xl={9}>
            <Section>
              <PaymentMethodContainer organizationId={organisationId} />
            </Section>
            {permissions.professionalServicesUnits.create && (
              <Section>
                <ProfessionalServicesContainer organizationId={organisationId} />
              </Section>
            )}
          </Col>
        </Row>
        {permissions.accountBalance.viewAll && (
          <Row>
            <TransactionsContainer organisationId={organisationId} />
          </Row>
        )}
      </div>
    </>
  );
};

const Section = styled.div`
  margin-bottom: 20px;
`;

export default OverviewBilling;
