import React from 'react';
import styled from '@emotion/styled';
import CardContainer from '../card-container';
import useDevice from '../../../use-devices';
import DeviceImageCard from './device-image-card.component';

interface DeviceImageCardContainerProps {
  title: string;
  imageProperty: string;
  isVisibleWithoutData: boolean;
  deviceId: string;
}

const DeviceImageCardContainer = (props: DeviceImageCardContainerProps) => {
  const { title, deviceId, isVisibleWithoutData, imageProperty } = props;

  const { data: deviceData, isSuccess, isLoading, isFetching, isError, } = useDevice(deviceId);

  return (
    <>
      {(isLoading || isFetching || isError) && (
        <LoadingContainer isError={isError} isSuccess={false} isLoading={isLoading || isFetching} hasData={false} title={title}>
          <></>
        </LoadingContainer>
      )}

      {!isLoading && !isFetching  && !isError && isSuccess && deviceData && (
        <DeviceImageCard device={deviceData} imageProperty={imageProperty} isVisibleWithoutData={isVisibleWithoutData} title={title} />
      )}
    </>
  );
};

export default DeviceImageCardContainer;

const LoadingContainer = styled(CardContainer)``;
