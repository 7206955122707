import React from 'react';
import { Card as AntCard, Tooltip, Icon } from 'antd';
import styled from '@emotion/styled';

const CardBody = styled.div`
  flex: 1;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
`;

const CardFooter = styled.div`
  border-top: 1px solid #e8e8e8;
  display: grid;
  grid-auto-flow: column;
  padding: 8px 0;
  margin: 0 24px;
  grid-column-gap: 16px;
  justify-content: start;
`;

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  title: string | React.ReactNode;
  info?: string;
  children?: React.ReactNode;
  footer?: React.ReactNode;
}

export default function Card({ title, info, children, footer, style, ...props }: Props) {
  return (
    <AntCard
      {...props}
      title={title}
      extra={
        info ? (
          <Tooltip title={info}>
            <Icon type="info-circle" style={{ fontSize: '16px' }} />
          </Tooltip>
        ) : (
          undefined
        )
      }
      style={{ ...(style || {}), display: 'flex', flexDirection: 'column' }}
      headStyle={{ padding: '0 24px' }}
      bodyStyle={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
      }}
    >
      <CardBody>{children}</CardBody>
      {footer && <CardFooter>{footer}</CardFooter>}
    </AntCard>
  );
}
