import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { GridKnowledgeSource, useGridLlmService } from '@ombori/grid-llm-react';
import { ApiError } from '../../../services/api/api-error';
import { getStoreAiKnowledgeBaseFileListKeys } from './use-store-ai-knowledge-base-file-list';
import { getStoreAiAssistantKnowledgeBaseFilesKeys } from '../store-ai/use-store-ai-assistant-knowledge-base-files';

type UseStoreAiKnowledgeBaseFileDeleteResponse = UseMutationResult<GridKnowledgeSource, ApiError, string>;

const useStoreAiKnowledgeBaseFileDelete = (tenantId: string, assistantId?: string): UseStoreAiKnowledgeBaseFileDeleteResponse => {
  const gridLlmService = useGridLlmService();

  const queryClient = useQueryClient();

  const result = useMutation(
    async (fileId: string) => {
      return gridLlmService.removeKnowledgeSourceFile(fileId);
    },
    {
      onError: (_error: ApiError) => {},
      onSuccess: () => {
        const fileListQueryKeys = getStoreAiKnowledgeBaseFileListKeys({ tenantId });
        queryClient.invalidateQueries(fileListQueryKeys);

        if (assistantId) {
          const assistantFilesQueryKeys = getStoreAiAssistantKnowledgeBaseFilesKeys(assistantId);
          queryClient.invalidateQueries(assistantFilesQueryKeys);
        }
      }
    },
  );

  return result;
};

export default useStoreAiKnowledgeBaseFileDelete;
