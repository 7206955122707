import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import { RootModel, RootState } from '../../../../../../store/models/root.model';
import EnvVariables from './env-variables.component';
import { permissionKeys } from '../../../../../../utils/auth/permissions';
import usePermissions from '../../../../../../utils/auth/use-permissions';

type EnvVariablesContainerProps = Omit<
  React.ComponentProps<typeof EnvVariables>,
  | 'device'
  | 'loaded'
  | 'envVariables'
  | 'fetchEnvVariables'
  | 'updateEnvVariables'
  | 'error'
>;

const EnvVariablesContainer = (props: EnvVariablesContainerProps) => {
  const {
    match: {
      params: { deviceUuid, organisationId },
    },
  } = props;

  const { device, loaded, envVariables, error } = useStore<RootState>(
    (state) => ({
      device: state.deviceDetails.data[deviceUuid] || null,
      loaded: state.deviceEnvVariables.loaded(deviceUuid),
      envVariables: state.deviceEnvVariables.data[deviceUuid] || null,
      error: state.deviceEnvVariables.error[deviceUuid] || null,
    }),
    [deviceUuid],
  );

  const { fetchEnvVariables, updateEnvVariables } = useActions<RootModel>((actions) => ({
    fetchEnvVariables: actions.deviceEnvVariables.fetch,
    updateEnvVariables: actions.deviceEnvVariables.update,
  }));

  const { isAllowed } = usePermissions(organisationId);

  return (
    <EnvVariables
      {...props}
      envVariables={envVariables}
      device={device}
      fetchEnvVariables={fetchEnvVariables}
      updateEnvVariables={updateEnvVariables}
      loaded={loaded}
      error={error}
      canUpdate={isAllowed(permissionKeys.devices.updateDeviceVariables)}
    />
  );
};

export default EnvVariablesContainer;
