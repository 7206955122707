import React, { useCallback, useMemo } from 'react';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';

import { PaginationCollection } from '../../../../../store/types/pagination';
import Gridapp from '../../../../../store/types/gridapp';
import ContentLibraryTable, { ContentLibraryTablePaginationProps } from './content-library-table.component';
import { Button } from 'antd';

interface ContentLibraryAppListProps {
  apps: PaginationCollection<Gridapp>;
  isLoadingApps: boolean;
  pagination: ContentLibraryTablePaginationProps;
  onSelectApp?: (selectedApp: Gridapp) => void;
}

const ContentLibraryAppList = (props: ContentLibraryAppListProps) => {
  const { apps, isLoadingApps, pagination, onSelectApp } = props;

  const { t } = useTranslation();

  const handleAppItemClick = useCallback((selectedApp: Gridapp) => {
    if (onSelectApp) {
      onSelectApp(selectedApp);
    }
  }, [onSelectApp]);

  const columns = useMemo<ColumnProps<Gridapp>[]>(() => {
    return [
      {
        title: t('contents.name'),
        key: 'name',
        width: '20%',
        onCellClick: (record: Gridapp, event) => {
          event.preventDefault();
          handleAppItemClick(record);
        },
        render: (_, record) => {
          return <Button type="link">{record.displayName}</Button>;
        },
      },
      {
        title: t('contents.author'),
        key: 'author',
        width: '20%',
        render: (_, record) => {
          return record.author;
        }
      },
      {
        title: t('contents.description'),
        key: 'description',
        width: '60%',
        ellipsis: true,
        render: (_, record) => {
          return record.description;
        },
      },
    ]
  }, [t, handleAppItemClick]);

  return (
    <ContentLibraryTable<Gridapp>
      items={apps && apps.docs ? apps.docs : []}
      isLoadingItems={isLoadingApps}
      columns={columns}
      pagination={pagination}
    />
  )
}

export default ContentLibraryAppList;
