import {
  Col,
  Input,
  Modal,
  Row,
  Form as AntdForm,
  Typography,
  Button,
  message,
} from 'antd';
import { Field, Form } from 'react-final-form';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Media from '../../../../store/types/media';
import DataCard from '../../data-card/data-card.component';
import Preview from '../preview/preview.component';
import { getMediaItemKind, MediaItemKind } from '../../../../utils/media-item-utils';
import { formatFileSize } from '../../../../utils/bytes';

const { Text } = Typography;

interface MediaEditModalProps {
  mediaItem: Media | null;
  visible: boolean;
  onClose: () => void;
  onSubmit: (data: Media) => void;
  canEdit: boolean;
  heading?: string;
}

const PreviewCol = styled(Col)`
  background-color: #f5f6fa;
  padding: 24px 0;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const DataCol = styled(Col)`
  padding: 48px 0 24px 0;
`;

const InfoWrapper = styled.div`
  margin-bottom: 24px;
`;

const InfoRow = styled(Row)`
  margin-bottom: 8px;
`;

const MediaEditModal = (props: MediaEditModalProps) => {
  const { mediaItem, visible, onClose, onSubmit, canEdit, heading } = props;
  const { t } = useTranslation();
  const urlField = useRef<Input>(null);

  const handleFormSubmit = async (values: any) => {
    await onSubmit(values);
    message.success('Changes saved!');
    onClose();
  };

  if (!mediaItem) {
    return null;
  }

  const handleUrlCopy = () => {
    if (urlField.current && urlField.current.input) {
      urlField.current.input.select();
      document.execCommand('copy');
      message.success(t('urlCopied'));
    }
  };

  const renderInfoRow = (label: string, value: string) => {
    return (
      <InfoRow type="flex">
        <Col span={5}>
          <Text>{label}:</Text>
        </Col>
        <Col span={19}>
          <Text>{value}</Text>
        </Col>
      </InfoRow>
    );
  };

  const isFolder = getMediaItemKind(mediaItem) === MediaItemKind.folder;

  return (
    <Form initialValues={mediaItem} onSubmit={handleFormSubmit}>
      {({ handleSubmit, submitting, invalid }) => (
        <Modal
          bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
          width="80%"
          okText={t('saveChanges')}
          okButtonProps={{
            disabled: invalid,
          }}
          footer={canEdit ? undefined : null}
          confirmLoading={submitting}
          title={heading ? heading : t('editMedia')}
          visible={visible}
          // @ts-ignore
          onOk={handleSubmit}
          onCancel={onClose}
          centered
        >
          <Row type="flex" gutter={48}>
            <PreviewCol sm={24} lg={12}>
              <Preview mediaItem={mediaItem} />
            </PreviewCol>
            <DataCol sm={24} lg={12}>
              <InfoWrapper>
                {renderInfoRow('File name', mediaItem.name)}
                {renderInfoRow('File type', mediaItem.type)}
                {renderInfoRow(
                  t('uploadedOn'),
                  dayjs(mediaItem.createdAt).format('DD-MM-YYYY HH:mm'),
                )}
                {!isFolder && renderInfoRow('File size', formatFileSize(mediaItem.size))}
              </InfoWrapper>
              <DataCard>
                <AntdForm layout="vertical">
                  {!isFolder && (
                    <Field name="url">
                      {(fieldProps) => (
                        <AntdForm.Item label="URL">
                          <Input
                            ref={urlField}
                            readOnly
                            {...fieldProps.input}
                            addonAfter={
                              <Button
                                onClick={handleUrlCopy}
                                icon="copy"
                                type="link"
                                size="small"
                                data-testid="media-edit-modal-url-copy-button"
                              >
                                {t('copy')}
                              </Button>
                            }
                          />
                        </AntdForm.Item>
                      )}
                    </Field>
                  )}
                  <Field name="name">
                    {(fieldProps) => (
                      <AntdForm.Item label={t('name')}>
                        <Input {...fieldProps.input} data-testid="media-edit-modal-name-input" readOnly={canEdit ? false : true} />
                      </AntdForm.Item>
                    )}
                  </Field>
                </AntdForm>
              </DataCard>
            </DataCol>
          </Row>
        </Modal>
      )}
    </Form>
  );
};

export default MediaEditModal;
