import { useMutation, useQueryClient } from 'react-query';
import { useApiService } from '../../../app/common/api-service-provider';
import { Channel } from '../../types/channel';
import channelsQueryKeys from './channels-query-keys';
import { ChannelBody } from './types';

const useUpdateChannel = (tenantId: string, channelId: string) => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  return useMutation(
    (channelBody: ChannelBody) => {
      return apiService.put<Channel>(`/api/content-channel/${channelId}`, channelBody, { tenantId });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(channelsQueryKeys.tenantChannels(tenantId));
        queryClient.invalidateQueries(channelsQueryKeys.getChannel(channelId));
      },
    },
  );
};

export default useUpdateChannel;
