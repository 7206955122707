import { thunk, Thunk } from 'easy-peasy';
import createCrudModel, { CrudModel } from '../../common/crud-model/crud.model';
import User from '../../types/user';
import Injections from '../../injections.interface';

export type UsersModel = Omit<CrudModel<User>, 'delete'> & {
  validateUserEmail: Thunk<
    CrudModel<User>,
    { email: string; organizationId: string },
    Injections
  >;
  delete: Thunk<CrudModel<User>, { id: string; organizationId: string }, Injections>;
};

const path = '/api/users';

const usersModel: UsersModel = {
  ...createCrudModel<User>(path),
  validateUserEmail: thunk(async (actions, payload, { injections }) => {
    const data: { exist: boolean } = await injections.apiService.post(
      `${path}/check-exist`,
      payload,
    );

    return data.exist;
  }),
  delete: thunk(async (actions, { id, organizationId }, { injections }) => {
    await injections.apiService.delete(`${path}/${id}?organizationId=${organizationId}`);
    actions.unsetSingle(id);
  }),
};

export default usersModel;
