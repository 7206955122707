export enum SeverityEnum {
  CRITICAL = 0,
  ERROR = 1,
  WARNING = 2,
  INFORMATION = 3,
}

export enum StatusEnum {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export enum UserRoleEnum {
  ADMIN = 'admin',
  EDITOR = 'editor',
  VIEW = 'view',
}

export enum NumericOperatorEnum {
  GREAT_THAN = 'greater-than',
  EQUAL_TO = 'equal-to',
  GREATER_THAN_OR_EQUAL_TO = 'greater-than-or-equal-to',
  LESS_THAN_OR_EQUAL_TO = 'less-than-or-equal-to',
  LESS_THAN = 'less-than',
}

export enum StringOperatorEnum {
  EQUAL_TO = 'equal-to',
}

export enum AggregationOperatorEnum {
  SUM = 'sum',
  AVERAGE = 'average',
}

export enum AlertConditionTypeEnum {
  DEVICE_STATUS = 'device-status',
  CPU_USAGE = 'cpu-usage',
  MEMORY_USAGE = 'memory-usage',
  DISK_USAGE = 'disk-usage',
  BLACK_SCREEN = 'black-screen',
  WHITE_SCREEN = 'white-screen',
  SCREEN_MODULE_CRASH = 'screen-module-crash',
  NO_SCREEN_CONNTECTED = 'no-screen-detected',
  CUSTOM_MODULE_REPORTED_PROP = 'custom-screen-module-prop',
  DEVICE_ANALYTICS_SESSION_COUNT = 'device-analytics-session-count',
  DEVICE_ANALYTICS_EVENTS_COUNT = 'device-analytics-events-count',
  DEVICE_ANALYTICS_EVENTS_AGGREGATION = 'device-analytics-events-aggregation',
}

interface AlertConditionDeviceStatus {
  type: AlertConditionTypeEnum.DEVICE_STATUS;
  duration: number; // seconds - duration of time we have the detected that device status
}

interface AlertConditionCpuUsagePercentage {
  type: AlertConditionTypeEnum.CPU_USAGE;
  operator: NumericOperatorEnum;
  threshold: number;
  duration: number; // seconds - duration of time we have the CPU usage percentage on the specific operator and threshold detected
}

interface AlertConditionMemoryUsagePercentage {
  type: AlertConditionTypeEnum.MEMORY_USAGE;
  operator: NumericOperatorEnum;
  threshold: number;
  duration: number; // seconds - duration of time we have the Memory usage percentage on the specific operator and threshold detected
}

interface AlertConditionDiskUsagePercentage {
  type: AlertConditionTypeEnum.DISK_USAGE;
  operator: NumericOperatorEnum;
  threshold: number;
  duration: number; // seconds - duration of time we have the Disk Usage percentage on the specific operator and threshold detected
}

interface AlertConditionBlackScreen {
  type: AlertConditionTypeEnum.BLACK_SCREEN;
}

interface AlertConditionWhiteScreen {
  type: AlertConditionTypeEnum.WHITE_SCREEN;
}

interface AlertConditionScreenModuleCrash {
  type: AlertConditionTypeEnum.SCREEN_MODULE_CRASH;
}

interface AlertConditionNoScreenConnected {
  type: AlertConditionTypeEnum.NO_SCREEN_CONNTECTED;
}

interface AlertConditionCustomModuleReportedProp {
  type: AlertConditionTypeEnum.CUSTOM_MODULE_REPORTED_PROP;
  propertyPath: string;
  expectedValue: string | number | boolean;
}

interface AlertConditionDeviceAnalyticsSessionCount {
  type: AlertConditionTypeEnum.DEVICE_ANALYTICS_SESSION_COUNT;
  operator: NumericOperatorEnum;
  threshold: number;
}

interface AlertConditionDeviceAnalyticsEventsCount {
  type: AlertConditionTypeEnum.DEVICE_ANALYTICS_EVENTS_COUNT;
  eventType: string;
  operator: NumericOperatorEnum | StringOperatorEnum;
  threshold: number;
  filters: AnalyticsEventFilter[];
  groupBy: AnalyticsEventPropertyEnum[];
  duration: number;
}

export enum AnalyticsEventPropertyEnum {
  STR1 = 'str1',
  STR2 = 'str2',
  STR3 = 'str3',
  STR4 = 'str4',
  STR5 = 'str5',
  INT1 = 'int1',
  INT2 = 'int2',
  INT3 = 'int3',
  INT4 = 'int4',
  INT5 = 'int5',
}

export type AnalyticsEventPropertyNumeric =
  | AnalyticsEventPropertyEnum.INT1
  | AnalyticsEventPropertyEnum.INT2
  | AnalyticsEventPropertyEnum.INT3
  | AnalyticsEventPropertyEnum.INT4
  | AnalyticsEventPropertyEnum.INT5;

export type AnalyticsEventPropertyString =
  | AnalyticsEventPropertyEnum.STR1
  | AnalyticsEventPropertyEnum.STR2
  | AnalyticsEventPropertyEnum.STR3
  | AnalyticsEventPropertyEnum.STR4
  | AnalyticsEventPropertyEnum.STR5;

export const AnalyticsEventPropertyNumericValues: AnalyticsEventPropertyNumeric[] = [
  AnalyticsEventPropertyEnum.INT1,
  AnalyticsEventPropertyEnum.INT2,
  AnalyticsEventPropertyEnum.INT3,
  AnalyticsEventPropertyEnum.INT4,
  AnalyticsEventPropertyEnum.INT5,
];

export const AnalyticsEventPropertyStringValues: AnalyticsEventPropertyString[] = [
  AnalyticsEventPropertyEnum.STR1,
  AnalyticsEventPropertyEnum.STR2,
  AnalyticsEventPropertyEnum.STR3,
  AnalyticsEventPropertyEnum.STR4,
  AnalyticsEventPropertyEnum.STR5,
];

export enum AnalyticsEventFilterTypeEnum {
  NUMERIC = 'numeric',
  STRING = 'string',
}

export interface NumericAnalyticsEventFilter {
  type: AnalyticsEventFilterTypeEnum.NUMERIC;
  operator: NumericOperatorEnum;
  eventProperty: AnalyticsEventPropertyNumeric;
  value: number;
}

export interface StringAnalyticsEventFilter {
  type: AnalyticsEventFilterTypeEnum.STRING;
  operator: StringOperatorEnum;
  eventProperty: AnalyticsEventPropertyString;
  value: string;
}

export type AnalyticsEventFilter = NumericAnalyticsEventFilter | StringAnalyticsEventFilter;

export interface AlertConditionDeviceAnalyticsEventsAggregation {
  type: AlertConditionTypeEnum.DEVICE_ANALYTICS_EVENTS_AGGREGATION,
  eventType: string;
  eventProperty: AnalyticsEventPropertyNumeric;
  aggregationOperator: AggregationOperatorEnum;
  operator: NumericOperatorEnum;
  threshold: number;
  duration: number;
}

export type AlertCondition =
  | AlertConditionCpuUsagePercentage
  | AlertConditionMemoryUsagePercentage
  | AlertConditionDiskUsagePercentage
  | AlertConditionDeviceStatus
  | AlertConditionBlackScreen
  | AlertConditionWhiteScreen
  | AlertConditionScreenModuleCrash
  | AlertConditionNoScreenConnected
  | AlertConditionCustomModuleReportedProp
  | AlertConditionDeviceAnalyticsSessionCount
  | AlertConditionDeviceAnalyticsEventsCount
  | AlertConditionDeviceAnalyticsEventsAggregation;

export interface AlertScope {
  type: 'global' | 'location' | 'installation-group' | 'installation';
  id: string;
}

export interface AlertRecipient {
  type: 'UserRole' | 'User';
  id: string;
}

export enum ActionTypeEnum {
  EMAIL = 'email',
  SMS = 'sms',
  WEBHOOK = 'webhook',
}

interface BaseAlertAction {
  type: ActionTypeEnum;
}

export interface EmailAlertAction extends BaseAlertAction {
  type: ActionTypeEnum.EMAIL;
  recipients: AlertRecipient[];
}

export interface SmsAlertAction extends BaseAlertAction {
  type: ActionTypeEnum.SMS;
  recipients: AlertRecipient[];
}

export interface WebhookAlertAction extends BaseAlertAction {
  type: ActionTypeEnum.WEBHOOK;
  urls: string[];
}

export type AlertAction = EmailAlertAction | SmsAlertAction | WebhookAlertAction;

export interface AlertActionGroup {
  id: string;
  displayName: string;
  description: string;
  actions: AlertAction[];
  updatedAt: string;
  createdAt: string;
  createdBy: string;
  updatedBy: string;
}

export interface AlertActionSchema {
  general: {
    name: string;
    description: string;
  };
  notificationRecipients: {
    emails: AlertRecipient[];
    phoneNumbers: AlertRecipient[];
    webHookUrl: string;
  };
}

export interface AlertRule {
  id: string;
  displayName: string;
  description: string;
  severity: SeverityEnum;
  enabled: boolean;
  scope: AlertScope[];
  conditions: AlertCondition[];
  actions: string[];
  updatedAt: string;
  createdAt: string;
  createdBy: string;
  updatedBy: string;
}

export interface AlertRuleSchemaForm {
  general: {
    displayName: string;
    description: string;
    severity: SeverityEnum;
    enabled: boolean;
    scope: AlertScope[];
  };
  conditions?: AlertCondition[];
  actions?: string[];
}
