import React, { useCallback, useMemo } from 'react';
import { useActions } from 'easy-peasy';
import { RootModel } from '../../../../store/models/root.model';
import MediaGenerateWithAIModalComponent from './media-generate-with-ai-modal.component';
import { useGenerateAIImage, UseGenerateAIImageProps } from '../../use-rndai';

type MediaEditModalContainerProps = Omit<
  React.ComponentProps<typeof MediaGenerateWithAIModalComponent>,
  'isLoading' | 'isError' | 'onGenerateImage' | 'results' | 'uploadMedia'
>

const MediaEditModalContainer = (props: MediaEditModalContainerProps) => {
  const generateAIImageState = useGenerateAIImage();

  const onGenerateImage = useCallback((props: UseGenerateAIImageProps) => {
    generateAIImageState.mutate(props);
  }, [generateAIImageState]);

  const { uploadMedia } = useActions<
    RootModel
  >((actions) => ({
    uploadMedia: actions.organisationMedia.upload,
  }));

  const results = useMemo(() => {
    if (!generateAIImageState.isLoading && !generateAIImageState.isError && generateAIImageState.isSuccess) {
      return generateAIImageState.data.data.map(resData => resData.url);
    }

    return [];
  }, [generateAIImageState]);

  return (
    <MediaGenerateWithAIModalComponent
      {...props}
      onGenerateImage={onGenerateImage}
      isLoading={generateAIImageState.isLoading}
      isError={generateAIImageState.isError}
      results={results}
      uploadMedia={uploadMedia}
    />
  );
};

export default MediaEditModalContainer;
