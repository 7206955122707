import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useActions, useStore, Actions } from 'easy-peasy';
import { get } from 'lodash';
import qs from 'query-string';
import { Cascader, Select, DatePicker } from 'antd';
import styled from '@emotion/styled';
import moment from 'moment';
import { listTimeZones } from 'timezone-support';
import { RootModel, RootState } from '../../../../../../store/models/root.model';
import Report from '../../../../../common/report/report.component';
import UniversalDevice from '../../../../../../store/types/universal-device';
import OverviewReport from './overview-report.component';
import Spinner from '../../../../../common/spinner/spinner.component';
import ContentWrap from '../../../../../common/app-layout/content/content-wrap.component';

const Controls = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  padding-bottom: 24px;
  width: 100%;

  @media (min-width: 1280px) {
    width: calc(75% - ${24 / 4}px);
  }

  @media (min-width: 1920px) {
    width: calc(50% - ${24 / 2}px);
  }
`;

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';

const getDefaultTimeZone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
  } catch (error) {
    // eslint-disable-next-line
    console.warn(error);
    return 'UTC';
  }
};

type OverviewContainerProps = Pick<
  React.ComponentProps<typeof Report>,
  'match' | 'history' | 'location'
>;

interface StoreSelectorResult {
  app: any;
  devices: UniversalDevice[];
  loading: any;
  report: any;
  error: any;
  tenant: any;
  isDevicesLoading?: boolean;
}

const SignalsOverviewContainer = ({
  match,
  history,
  location,
}: OverviewContainerProps) => {
  const { appId: installationId, organisationId: tenantId } = match.params;
  const { search } = location;

  const { t } = useTranslation();

  const { app, devices, loading, report, error, tenant, isDevicesLoading } = useStore<
    RootState,
    StoreSelectorResult
  >(
    (state) => {
      const appData =
        (state.organisationApps.data &&
          state.organisationApps.data[tenantId] &&
          state.organisationApps.data[tenantId][installationId]) ||
        null;

      const isDeviceFetching = appData
        ? state.appDevicesUniversal.isPaginationLoading[appData.appName]
        : true;

      const result = {
        loading: state.analytics.loading[installationId],
        report: state.analytics.reports[installationId],
        error: state.analytics.error[installationId],
        app: appData,
        devices: appData ? state.appDevicesUniversal.values(appData.appName) : [],
        tenant: state.organisations.data && state.organisations.data[tenantId],
        isDevicesLoading: isDeviceFetching === undefined ? true : isDeviceFetching,
      };

      return result;
    },
    [installationId],
  );

  const { fetchAllAppDevices, fetchAppSignalsReport } = useActions<
    RootModel,
    {
      fetchAllAppDevices: Actions<RootModel>['appDevicesUniversal']['fetchAll'];
      fetchAppSignalsReport: Actions<RootModel>['analytics']['fetchAppSignalsReport'];
    }
  >((actions) => ({
    fetchAllAppDevices: actions.appDevicesUniversal.fetchAll,
    fetchAppSignalsReport: actions.analytics.fetchAppSignalsReport,
  }));

  const { fromDate, toDate, device: deviceParam, timeZone: timeZoneParam } = qs.parse(
    search,
  );

  const isDate = (date: any): date is string =>
    typeof date === 'string' && /\d{4}-\d{2}-\d{2}/.test(date);

  const dateFrom = !isDate(fromDate)
    ? moment()
        .subtract(8, 'd')
        .format('YYYY-MM-DD')
    : fromDate;

  const dateTo = !isDate(toDate)
    ? moment()
        .subtract(1, 'd')
        .format('YYYY-MM-DD')
    : toDate;

  const deviceId = typeof deviceParam !== 'string' ? undefined : deviceParam;

  const timeZone =
    typeof timeZoneParam !== 'string' ? getDefaultTimeZone() : timeZoneParam;

  const fetchReport = useCallback(() => {
    if (app && tenant) {
      fetchAppSignalsReport({
        tenantId,
        installationId,
        dateFrom,
        dateTo,
        timeZone,
        deviceId,
        dataResidency: tenant.dataResidency,
      });
    }
  }, [
    app,
    tenant,
    fetchAppSignalsReport,
    tenantId,
    installationId,
    dateFrom,
    dateTo,
    timeZone,
    deviceId,
  ]);

  const fetchDevices = useCallback(
    () =>
      fetchAllAppDevices({
        appName: app.appName,
        silent: true,
      }),
    [fetchAllAppDevices, app],
  );

  const options = useMemo(() => {
    const result = devices.map((device) => {
      const name: string = get(
        device,
        ['properties', 'desired', 'variables', '_ANALYTICS_DEVICE_NAME'],
        device.deviceName,
      );

      const option = {
        value: device.uuid,
        label: name,
      };

      return option;
    });

    return result;
  }, [devices]);

  const timeZones = useMemo(() => listTimeZones(), []);

  const onChangeRange = useCallback(
    (date: any[], [nextFromDate, nextToDate]: [string, string]) => {
      history.replace({
        pathname: history.location.pathname,
        search: qs.stringify({
          ...qs.parse(history.location.search),
          fromDate: nextFromDate,
          toDate: nextToDate,
        }),
      });
    },
    [history],
  );

  const onChangeTimeZone = useCallback(
    (nextTimeZone: string) => {
      history.replace({
        pathname: history.location.pathname,
        search: qs.stringify({
          ...qs.parse(history.location.search),
          timeZone: nextTimeZone,
        }),
      });
    },
    [history],
  );

  const onChangeLocationDevice = useCallback(
    ([selectedDeviceId]: string[]) => {
      history.replace({
        pathname: history.location.pathname,
        search: qs.stringify({
          ...qs.parse(history.location.search),
          device: selectedDeviceId,
        }),
      });
    },
    [history],
  );

  const cascaderValue = useMemo(() => {
    if (deviceId) {
      return [deviceId];
    }

    return [];
  }, [deviceId]);

  useEffect(() => {
    fetchDevices();
    fetchReport();
  }, [fetchReport, fetchDevices]);

  const { devicesLoadingIcon, devicesLoadingText } = useMemo(() => {
    return {
      devicesLoadingIcon: isDevicesLoading ? <CascaderLoading size={15} /> : undefined,
      devicesLoadingText: isDevicesLoading ? t('loadingDeviceList') : t('selectDevice'),
    };
  }, [isDevicesLoading, t]);

  return (
    <ContentWrap>
      <Controls>
        <RangePicker
          value={[moment(dateFrom, dateFormat), moment(dateTo, dateFormat)]}
          format={dateFormat}
          ranges={{
            Today: [moment(), moment()],
            'Past 7 days': [moment().subtract(8, 'd'), moment().subtract(1, 'd')],
            'Past 30 days': [moment().subtract(31, 'd'), moment().subtract(1, 'd')],
            'Past 60 days': [moment().subtract(61, 'd'), moment().subtract(1, 'd')],
          }}
          onChange={onChangeRange}
          disabledDate={(current: moment.Moment | undefined) =>
            !!current &&
            (current.isBefore(moment().subtract(61, 'd')) || current.isAfter(moment()))
          }
        />

        <Select
          showSearch
          placeholder="Select a time zone"
          value={timeZone}
          optionFilterProp="children"
          onChange={onChangeTimeZone}
          filterOption={(input, option) => {
            if (typeof option.props.children === 'string') {
              return (
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }
            return false;
          }}
        >
          {timeZones.map((tz) => (
            <Select.Option key={tz} value={tz}>
              {tz.replace(/\//g, ' / ').replace('_', ' ')}
            </Select.Option>
          ))}
        </Select>

        <Cascader
          options={options}
          onChange={onChangeLocationDevice}
          value={cascaderValue}
          changeOnSelect={false}
          placeholder={devicesLoadingText}
          suffixIcon={devicesLoadingIcon}
          disabled={isDevicesLoading}
        />
      </Controls>

      <OverviewReport
        devices={devices}
        fetchReport={fetchReport}
        loading={loading}
        report={report}
        error={error}
      />
    </ContentWrap>
  );
};

const CascaderLoading = styled(Spinner)`
  position: absolute;
  top: 0;
  right: 12px;
  height: 100%;

  > .anticon-loading {
    height: 100%;
    align-items: center;
    display: inline-flex;
  }
`;

export default SignalsOverviewContainer;
