import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import { get } from 'lodash';
import { RootModel, RootState } from '../../../../../../store/models/root.model';
import { KEY_AVAILABLE_PLANS } from '../../../../../../store/models/organization-plans/organization-plans.model';
import AvailablePlans from './available-plans.component';

type AvailabePlansContainerProps = Pick<
  React.ComponentProps<typeof AvailablePlans>,
  | 'activePlanId'
  | 'activePlanType'
  | 'onCloseParentModal'
  | 'match'
  | 'history'
  | 'location'
>;

const AvailablePlansContainer = (props: AvailabePlansContainerProps) => {
  const {
    match: {
      params: { organisationId },
    },
    activePlanId,
    activePlanType,
    onCloseParentModal,
  } = props;

  const { loading, error, organizationAvailablePlans, organization } = useStore<
    RootState
  >((state) => {
    const key = KEY_AVAILABLE_PLANS;
    return {
      loading: state.organizationPlans.loading[key],
      error: state.organizationPlans.error[key],
      organizationAvailablePlans: get(
        state.organizationPlans.data,
        `${key}.${organisationId}`,
      ),
      organization: get(state.organisations.data, organisationId),
    };
  }, []);

  const {
    fetchActivePlans,
    fetchScheduledPlans,
    fetchAvailablePlans,
    schedulePlanUpdate,
  } = useActions<RootModel>((actions) => ({
    fetchActivePlans: actions.organizationPlans.fetchActivePlans,
    fetchScheduledPlans: actions.organizationPlans.fetchScheduledPlans,
    fetchAvailablePlans: actions.organizationPlans.fetchAvailablePlans,
    schedulePlanUpdate: actions.organizationPlans.schedulePlanUpdate,
  }));

  return (
    <AvailablePlans
      {...props}
      organization={organization}
      loading={loading}
      error={error}
      fetchAvailablePlans={fetchAvailablePlans}
      schedulePlanUpdate={schedulePlanUpdate}
      fetchActivePlans={fetchActivePlans}
      fetchScheduledPlans={fetchScheduledPlans}
      organizationAvailablePlans={organizationAvailablePlans}
      activePlanId={activePlanId}
      activePlanType={activePlanType}
      onCloseParentModal={onCloseParentModal}
    />
  );
};

export default AvailablePlansContainer;
