import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Button, Table, Tooltip, Typography, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import AnalyticsDataExport, {
  DataExportStatusEnum,
  dataExportStatusColors,
} from '../../../../../store/types/analytics-data-export';
import dayjs from 'dayjs';
import { formatFileSize } from '../utils/util';

const { Text } = Typography;

interface DataExportListViewComponentProps {
  reports: AnalyticsDataExport[];
}

interface ColumnProps {
  dataIndex?: string;
  title?: string | JSX.Element;
  key?: string;
  width?: string | number;
  align?: string;
  fixed?: boolean | 'right' | 'left' | undefined;
  render?: (report: AnalyticsDataExport) => JSX.Element | string | number;
  sorter?: (a: AnalyticsDataExport, b: AnalyticsDataExport) => string | number;
}

// const createAlphabeticalSorter = (property: string) => {
//   return (firstItem: any, secondItem: any) => {
//     return (firstItem[property] as string).localeCompare(secondItem[property] as string);
//   };
// };

// const statusSorter = createAlphabeticalSorter('status');
// const fileNameSorter = createAlphabeticalSorter('filename');

const DataExportListViewComponent = (props: DataExportListViewComponentProps) => {
  const { t } = useTranslation();

  const { reports = [] } = props;

  const dataExportStatus = useMemo<{ [test: string]: string }>(() => {
    return {
      [DataExportStatusEnum.DONE]: t('dataExport.done'),
      [DataExportStatusEnum.QUEUED]: t('dataExport.queued'),
      [DataExportStatusEnum.PROCESSING]: t('dataExport.processing'),
      [DataExportStatusEnum.ERROR]: t('dataExport.error'),
    };
  }, [t]);

  const columns: ColumnProps[] = useMemo(
    () => [
      {
        title: t('dataExport.fileName').toUpperCase(),
        key: 'fileName',
        width: '25%',
        // sorter: fileNameSorter,
        render: (report: AnalyticsDataExport) => (
          <>
            {report.status === DataExportStatusEnum.DONE && report.downloadUrl ? (
              <a href={report.downloadUrl} target="_blank" rel="noopener noreferrer">
                {report.filename}
              </a>
            ) : (
              report.filename
            )}
          </>
        ),
      },
      {
        title: '',
        key: 'download',
        width: '10%',
        render: (report: AnalyticsDataExport) => (
          <>
            {report.status === DataExportStatusEnum.DONE && report.downloadUrl && (
              <Tooltip title={t('dataExport.download')}>
                <Button
                  type="link"
                  href={report.downloadUrl}
                  target="_blank"
                  icon="download"
                  size="large"
                />
              </Tooltip>
            )}
          </>
        ),
      },
      {
        title: t('dataExport.createdOn').toUpperCase(),
        key: 'createdOn',
        align: 'right',
        width: '20%',
        // sorter: (dataRowA, dataRowB) => {
        //   const dateA = new Date(dataRowA.createdAt).getTime();
        //   const dateB = new Date(dataRowB.createdAt).getTime();
        //   return dateA > dateB ? 1 : -1;
        // },
        render: (report: AnalyticsDataExport) => (
          <Text>{dayjs(report.createdAt).format('DD-MM-YYYY HH:mm')}</Text>
        ),
      },
      {
        title: t('dataExport.fileSize').toUpperCase(),
        key: 'fileSize',
        align: 'right',
        width: '20%',
        // sorter: (dataRowA, dataRowB) => {
        //   const fileSizeA = dataRowA.fileSize || 0;
        //   const fileSizeB = dataRowB.fileSize || 0;
        //   return fileSizeA > fileSizeB ? 1 : -1;
        // },
        render: (report: AnalyticsDataExport) => {
          const formatedFileSize = report.fileSize && formatFileSize(report.fileSize);
          return formatedFileSize
            ? formatedFileSize
            : (t('dataExport.notApplicable') as string);
        },
      },
      {
        title: t('dataExport.status').toUpperCase(),
        key: 'status',
        align: 'center',
        width: '30%',
        // sorter: statusSorter,
        render: (report: AnalyticsDataExport) => {
          return (
            <StyledTag color={dataExportStatusColors[report.status]}>
              {dataExportStatus[report.status]}
            </StyledTag>
          );
        },
      },
    ],
    [t, dataExportStatus],
  );

  return (
    <ReportsTable
      rowKey="id"
      dataSource={reports}
      columns={columns as any}
      scroll={{ x: true }}
      pagination={false}
      size="small"
    />
  );
};

const ReportsTable = styled(Table)`
  .ant-table-thead > tr > th {
    height: 40px;
  }
  .ant-table-scroll > .ant-table-body {
    overflow-x: auto !important;
  }
  .ant-table-small {
    border-right-width: 0px;
    border-left-width: 0px;
    border-radius: 0px;
  }
`;

const StyledTag = styled(Tag)`
  min-width: 8em;
  text-align: center;
`;

export default DataExportListViewComponent;
