import React, { useCallback, useEffect, useMemo } from 'react';
import { keyBy, noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import Browser from '../../../../store/types/browser';
import Environment from '../../../../store/types/environment';
import Space from '../../../../store/types/organisation-space';
import BrowsersListComponent from '../../../common/browsers/browsers-list/browsers-list.component';
import Header from '../../../common/app-layout/header/header.component';

interface BrowsersProps extends RouteComponentProps<{ organisationId: string }> {
  browsers: Browser[];
  spaces: Space[];
  loaded: boolean;
  fetchSpaces: (params: { organizationId: string; silent?: boolean; }) => void;
  fetchBrowsers: (params: { organizationId: string; silent: boolean }) => void;
  fetchEnvironments: (params: { organizationId: string }) => void;
  environments: Environment[];
}

const BrowsersComponent = (props: BrowsersProps) => {
  const {
    loaded,
    browsers,
    environments,
    spaces,
    fetchSpaces,
    fetchBrowsers,
    fetchEnvironments,
    match: {
      params: { organisationId },
    },
  } = props;
  const { t } = useTranslation();

  const fetchOrganizaionBrowsers = useCallback(
    (params: { silent: boolean }) => {
      fetchBrowsers({ organizationId: organisationId, silent: !!params.silent });
    },
    [fetchBrowsers, organisationId],
  );

  useEffect(() => {
    fetchEnvironments({ organizationId: organisationId });
    fetchSpaces({ organizationId: organisationId, silent: true });
  }, [fetchEnvironments, fetchSpaces, organisationId]);

  const keyedSpaces = useMemo(() => keyBy(spaces, (s) => s.id), [spaces]);
  const environmentsData = useMemo(() => {
    return environments.reduce(
      (
        envs: {
          [orgId: string]: {
            [environmentName: string]: Environment;
          };
        },
        env,
      ) => {
        const updatedEnvs = { ...envs };
        if (!envs[env.organizationId]) {
          updatedEnvs[env.organizationId] = {};
        }
        updatedEnvs[env.organizationId][env.environmentName] = env;
        return updatedEnvs;
      },
      {},
    );
  }, [environments]);

  if (!organisationId) return null;

  return (
    <>
      <Header title={t('managedBrowsers')} />
      <div className="content-body">
        <BrowsersListComponent
          loaded={loaded}
          browsers={browsers}
          spaces={keyedSpaces}
          environments={environmentsData}
          canCreate={false}
          canUpdate={false}
          canDelete={false}
          onCreate={noop}
          onUpdate={noop}
          onDelete={noop}
          onConnect={noop}
          fetchBrowsers={fetchOrganizaionBrowsers}
        />
      </div>
    </>
  );
};

export default BrowsersComponent;
