import React from 'react';
import styled from '@emotion/styled';
import { Variant } from '@ombori/grid-products';
import { Button } from 'antd';
import { get } from 'lodash';
import { VariantColorImage } from './use-product-variants';

export type VariantType = 'color' | 'style' | 'size';

export interface VariantOptions {
  color: string;
  colorImageUrl: string;
  size: string;
  style: string;
}

const isValidColorCssText = (strColor: string) => {
  const s = new Option().style;
  s.color = strColor;

  return s.color !== '';
};

interface VariantPickerProps {
  variants: Variant[];
  variantColorImages?: VariantColorImage[];
  type: VariantType;
  value: string;
  label: string;
  onChange: (variant: Variant) => void;
}

const VariantPicker: React.FC<VariantPickerProps> = ({
  variants,
  variantColorImages,
  type,
  value = '',
  label,
  onChange,
}) => {
  return (
    <Container>
      <Header>{label}</Header>

      <Content>
        <List>
          {variants.map((variant) => {
            const variantValue = variant[type];
            const isActive = variantValue === value;
            const colorImage = variantColorImages
              ? variantColorImages.find((item) => item.color === variant.color)
              : {
                  color: '',
                  colorImageUrl: '',
                };
            const isColorImageValid =
              type === 'color' && get(colorImage, 'color', '') !== '';
            const isCSSColorValid =
              type === 'color' && isValidColorCssText(variant.color || '');
            const isColorValid = isColorImageValid || isCSSColorValid;

            return (
              <Item key={variant.productId} data-active={isActive}>
                {type === 'color' && isColorValid && (
                  <VariantColorButton
                    onClick={() => onChange(variant)}
                    selected={isActive}
                    color={variant.color}
                    isImage={isColorImageValid}
                  >
                    {isColorImageValid ? (
                      <ColorImage src={get(colorImage, 'colorImageUrl', '')} />
                    ) : (
                      ''
                    )}
                  </VariantColorButton>
                )}

                {(type !== 'color' || !isColorValid) && (
                  <VariantButton
                    type={isActive ? 'primary' : ''}
                    onClick={() => onChange(variant)}
                  >
                    <TextDisplay>{variantValue}</TextDisplay>
                  </VariantButton>
                )}
              </Item>
            );
          })}
        </List>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 3px solid #fafafa;
`;

const Header = styled.div`
  font-weight: bold;
  width: 10%;
  vertical-align: middle;
  text-align: left;
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const Item = styled.div`
  display: flex;
  margin-top: 3px;
  margin-left: 7px;
  margin-right: 7px;
`;

const VariantButton = styled(Button)`
  position: relative;
  display: flex;
  margin-top: 10px;
  min-width: 64px;
  border-radius: 24px;
  align-items: center;
` as any;

const TextDisplay = styled.div`
  width: 100%;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const VariantColorButton = styled(Button)<{
  isImage: boolean;
  selected?: boolean;
  color?: string;
}>`
  ${({ selected }) => selected && 'border: 2px solid;'}
  padding: 16px;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: ${({ color, isImage }) => (isImage ? 'transparent' : color)};
  &:hover {
    background-color: ${({ color, isImage }) => (isImage ? 'transparent' : color)};
  }
  &:focus {
    background-color: ${({ color, isImage }) => (isImage ? 'transparent' : color)};
  }
` as any;

const ColorImage = styled.img`
  display: flex;
  align-items: center;
  width: 47px;
  height: 47px;
  max-width: 47px;
  border-radius: 50%;
`;

export default VariantPicker;
