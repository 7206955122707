import React from 'react';
import styled from '@emotion/styled';
import { Typography } from 'antd';
import { Helmet } from 'react-helmet';
import transientOptions from '../../../../utils/transient-emotion-styled-options';

const TITLE_SUFFIX = "Ombori Grid";

const { Title } = Typography;

interface HeaderProps {
    tabTitle?: string;
    testId?: string;
    icon?: React.ReactNode;
    title?: React.ReactNode;
    isBordered?: boolean;
    children?: React.ReactNode;
    style?: React.CSSProperties;
}

const Header = (props: HeaderProps) => {
    const { icon, tabTitle, title, testId, isBordered = true, children, style } = props;

    return (
        <HeaderContainer $isBordered={isBordered} style={style}>
            <Helmet>
                <title>{tabTitle ? `${tabTitle} - ${TITLE_SUFFIX}` : TITLE_SUFFIX}</title>
            </Helmet>
            <HeaderTitle>
                {icon && <Icon>{icon}</Icon>}
                {title && <TitleStyled level={3} data-testid={testId}>{title}</TitleStyled>}
            </HeaderTitle>
            {children}
        </HeaderContainer>
    );
};

const HeaderContainer = styled('header', transientOptions)<{ $isBordered?: boolean }>`
  background: #f2f2f7;
  ${({ $isBordered }) => $isBordered ? 'box-shadow: inset 0 -1px 0 0 #eaecf4, 0 2px 24px 0 rgba(0, 0, 0, 0.02);' : ''}
  padding: 72px 80px 16px;

  @media screen and (max-width: 991px) {
    padding: 72px 20px 16px;
  }
`;

const HeaderTitle = styled.div`
    display: flex;
    gap: 10px;
`;

const TitleStyled = styled(Title)`
    display: flex;
    align-items: center;
`;

export const Icon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #202020;
    border-radius: 4px;
    height: 40px;
    width: 40px;
`;

export default Header;
