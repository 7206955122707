import React, { useMemo } from 'react';
import { Button as AntdButton } from 'antd';
import { ButtonProps as AntdButtonProps, ButtonType } from 'antd/lib/button';
import styled, { StyledComponent } from '@emotion/styled';
import transientOptions from '../../../../utils/transient-emotion-styled-options';

interface ButtonProps extends Omit<AntdButtonProps, 'type' | 'icon'> {
  className?: string;
  type?: ButtonType | 'secondary';
  onClick?: () => void;
  isActive?: boolean;
  icon?: React.ReactNode;
  children?: React.ReactNode;
}

const Button = (props: ButtonProps) => {
  const { children, className, icon, isActive, onClick, type, disabled } = props;

  const { color, buttonType } = useMemo<{ color: string; buttonType: ButtonType }>(() => {
    // TO DO: support other types here if needed
    return {
      color: type === 'secondary' ? '#676973' : '',
      buttonType: (type === 'secondary' ? 'default' : type) || 'default',
    };
  }, [type]);

  return (
    <ButtonStyled
      className={className}
      type={buttonType}
      onClick={onClick}
      $isActive={isActive}
      color={color}
      disabled={disabled}
    >
      {icon}
      {children}
    </ButtonStyled>
  );
};

type StyledButtonType = StyledComponent<
  ButtonProps &
    React.ClassAttributes<AntdButton> & { $isActive?: boolean; color: string },
  Pick<ButtonProps & React.ClassAttributes<AntdButton>, keyof ButtonProps>,
  any
>;
const ButtonStyled = styled(AntdButton, transientOptions)<{
  $isActive?: boolean;
  color?: string;
}>`
  display: flex;
  align-items: center;
  box-shadow: unset;
  border: unset;
  background-color: unset;
  padding: 0 5px;

  :hover {
    background-color: #e7edee;
    ${({ color }) => (color ? `color: ${color};` : '')}
  }

  ${({ color }) => (color ? `color: ${color};` : '')}

  :active, :focus {
    // background-color: unset;
    ${({ color }) => (color ? `color: ${color};` : '')}
  }

  ${({ $isActive }) =>
    $isActive
      ? `
        &, :active, :focus {
            background-color: #e7edee;
        }
    `
      : ''}

  &[disabled] {
    background-color: unset !important;
  }

  &[ant-click-animating-without-extra-node="true"]::after{
    display:none;
  }
` as StyledButtonType;

export default Button;
