import { action, Action, Actions, select, Select, thunk, Thunk } from 'easy-peasy';
import { set } from 'lodash';
import Device from '../../types/device';
import Injections from '../../injections.interface';
import { ApiError } from '../../../services/api/api-error';
import transformDeviceExpectedAvailability from '../../../utils/transform-device-expected-availability';

export interface DeviceCreateParams {
  appName: string;
  deviceName: string;
  deviceSerial: string;
  env: string;
  type: string;
}

export interface AppDevicesModel {
  data: {
    [key: string]: {
      [id: string]: Device;
    };
  };
  lastUpdated: {
    [key: string]: Date;
  };
  loading: {
    [key: string]: boolean;
  };
  error: {
    [key: string]: ApiError | null;
  };
  values: Select<AppDevicesModel, (appName: string) => Device[]>;
  ids: Select<AppDevicesModel, (appName: string) => string[]>;
  loaded: Select<AppDevicesModel, (appName: string) => boolean>;
  setLoading: Action<AppDevicesModel, { key: string; loading: boolean }>;
  setError: Action<AppDevicesModel, { key: string; error: ApiError | null }>;
  setData: Action<AppDevicesModel, { key: string; data: Device[] }>;
  setSingle: Action<AppDevicesModel, { key: string; data: Device }>;
  setLastUpdated: Action<AppDevicesModel, { key: string; data: Date }>;
  fetch: Thunk<AppDevicesModel, { appName: string; silent?: boolean }, Injections>;
  create: Thunk<AppDevicesModel, DeviceCreateParams, Injections>;
}

const fetchDevices = async (
  url: string,
  actions: Actions<AppDevicesModel>,
  { key, silent }: { key: string; silent: boolean },
  injections: Injections,
) => {
  if (!silent) {
    actions.setLoading({ key, loading: true });
  }
  actions.setError({ key, error: null });
  try {
    const data = await injections.apiService
      .get<Device[]>(url)
      .then((d) => d.map(transformDeviceExpectedAvailability));
    actions.setLastUpdated({ key, data: new Date() });
    actions.setData({ key, data });
  } catch (error) {
    actions.setError({ key, error });
  } finally {
    if (!silent) {
      actions.setLoading({ key, loading: false });
    }
  }
};

const appDevicesModel: AppDevicesModel = {
  data: {},
  loading: {},
  error: {},
  lastUpdated: {},
  loaded: select((state) => (appName: string) =>
    !!state.data[appName] && !state.loading[appName],
  ),
  values: select((state) => (appName: string) =>
    Object.values(state.data[appName] || {}).sort(
      (a, b) => a.status.localeCompare(b.status) || a.deviceName.localeCompare(b.deviceName),
    ),
  ),
  ids: select((state) => (appName: string) => Object.keys(state.data[appName] || {})),
  setLoading: action((state, { key, loading }) => {
    state.loading[key] = loading;
  }),
  setError: action((state, { key, error }) => {
    state.error[key] = error;
  }),
  setData: action((state, { key, data }) => {
    state.data[key] = {};
    data.forEach((item) => {
      state.data[key][item.id] = item;
    });
  }),
  setSingle: action((state, { key, data }) => {
    set(state.data, [key, data.id], data);
  }),
  setLastUpdated: action((state, { key, data }) => {
    state.lastUpdated[key] = data;
  }),
  fetch: thunk(async (actions, { appName, silent = false }, { injections }) => {
    await fetchDevices(
      `/api/v2/devices?appName=${appName}`,
      actions,
      { key: appName, silent },
      injections,
    );
  }),
  create: thunk(async (actions, params, { injections }) => {
    const data = await injections.apiService.post<Device>('/api/v2/devices', params);
    actions.setSingle({ key: params.appName, data });
    return data.id;
  }),
};

export default appDevicesModel;
