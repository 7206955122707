export enum VoucherType {
  DEVICE_VOUCHER = 'device_voucher',
  REFERRAL_CODE = 'referral_code',
  VOUCHER = 'voucher',
}

export default interface Voucher {
  title: string;
  description: string;
  validity?: string;
  extraInfo: string;
  code: string;
  type: VoucherType;
}
