import React, { useMemo } from 'react';
import { Popover, Tag, Button, Card } from 'antd';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import AppIcon from '../app-icon/app-icon.component';
import { permissionKeys } from '../../../../utils/auth/permissions';
import usePermissions from '../../../../utils/auth/use-permissions';
import Gridapp from '../../../../store/types/gridapp';
import { getFormattedPricingFromGridapp } from '../../../../utils/apps-library/gridapp-price';

interface Props {
  item: Gridapp;
  hoverable?: boolean;
  onInstall?: (a: any) => void;
  url?: string;
  activeOrganisationId: string;
}

const tagsColors: { [tagKey: string]: string } = {
  screen: 'blue',
  mobile: 'green',
  module: 'purple',
};

const NBSP = '\u2002';
const DOT = `${NBSP}\u2022${NBSP}`;

const Wrapper = ({ children, to }: { children: any; to: any }) =>
  to ? (
    <Link style={{ flex: 1 }} to={to}>
      {children}
    </Link>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );

const AppLibraryItem = ({ activeOrganisationId, item, hoverable = false, onInstall, url }: Props) => {
  const { t } = useTranslation();

  let version = (
    <span>
      {t('noVersions')}
      {NBSP}
    </span>
  );
  if (item.latestBuild) {
    const created = dayjs(item.latestBuild.createdAt);
    version = (
      <Popover content={`${created.fromNow()}, ${created.format('YYYY-MM-DD HH:mm')}`}>
        {item.latestBuild.result
          ? `${t('ver')}${item.latestBuild.result.version}`
          : t('appBuildingTag')}
        {NBSP}
      </Popover>
    );
  }

  const { isAllowed } = usePermissions(activeOrganisationId);
  const pricingFormatted = useMemo(() => getFormattedPricingFromGridapp(item), [item]);

  return (
    <Container
      bodyStyle={containerStyles}
      hoverable={hoverable}
    >
      <Header>
        <Wrapper to={url}>
          <AppCard
            avatar={<AppIcon size={64} gridapp={item} />}
            title={
              <AppTitleWrapper>
                <p>{item.displayName}</p>
                <AppMarketplaceInfo>
                  <AppTypeTag color={tagsColors[item.type]}>{t(item.type)}</AppTypeTag>
                  {item.sku ? <AppMarketplaceProp>{item.sku}</AppMarketplaceProp> : null }
                  {pricingFormatted ? <AppMarketplaceProp>{pricingFormatted}</AppMarketplaceProp> : null }
                </AppMarketplaceInfo>
              </AppTitleWrapper>
            }
            description={
              <>
                {item.author ? `${t('appBy', { author: item.author })}${DOT}` : null}
                {version}
              </>
            }
          />
        </Wrapper>
        {item.latestBuild && onInstall && isAllowed(permissionKeys.apps.create) && (
          <InstallContainer>
            <Button type="primary" size="small" onClick={onInstall}>
              {t('appInstall')}
            </Button>
          </InstallContainer>
        )}
      </Header>
      <Body>{item.description && item.description.split('\n\n').shift()}</Body>
    </Container>
  );
};

const Container = styled(Card)`
  margin-bottom: 16px;
  height: 172px;
  max-height: 300px;
  cursor: auto;

  @media screen and (max-width: 1199px) {
    height: unset;
    min-height: 160px;
  }
`;

const containerStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 16,
  paddingBottom: 16,
  paddingLeft: 24,
  paddingRight: 24,
}

const Header = styled.div`
  display: flex;
  flex: 0;
`;

const Body = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  padding-top: 20px;
`;

const AppCard = styled(Card.Meta)`
  flex: 1;

  .ant-card-meta-title {
    white-space: normal;
  }
`;

const AppTitleWrapper = styled.div`
  margin-top: 2px;
  display: block;
  flex-direction: row;
  align-items: center;
  word-break: break-word;
  > p {
    margin-top: 6px;
  }
`;

const AppMarketplaceInfo = styled.div`
  flex-direction: row;
`;

const AppTypeTag = styled(Tag)`
  padding: 0px 8px;
  margin-top: 5px;
  font-size: 10px;
  line-height: 17px;
`;

const InstallContainer = styled.div`
  margin-top: 5px;
`;

const AppMarketplaceProp = styled(AppTypeTag)`
  color: #000000;
  background: transparent
`;

export default AppLibraryItem;
