import React, { createContext, useContext } from 'react';
import GridProductsConnectorService from '../../../services/api/grid-products-feed-service';
import { DataResidencyEnum } from '../../../store/types/organisation';

const GridProductsConnectorServiceContext = createContext<GridProductsConnectorService | null>(null);

interface GridProductsServiceProviderProps {
  children: React.ReactNode;
  service: GridProductsConnectorService;
}

const GridProductsConnectorServiceProvider: React.FC<GridProductsServiceProviderProps> = ({
  children,
  service,
}): JSX.Element => {
  return (
    <GridProductsConnectorServiceContext.Provider value={service}>
      {children}
    </GridProductsConnectorServiceContext.Provider>
  );
};

export const useGridProductsFeedService = (dataResidency: DataResidencyEnum): GridProductsConnectorService => {
  const ctx = useContext(GridProductsConnectorServiceContext);

  if (!ctx) {
    throw new Error(
      'Error caught while consuming GridProductsConnectorServiceContext context. Make sure you wrap the component inside the "GridProductsConnectorServiceProvider".',
    );
  }

  ctx.setDataResidency(dataResidency);

  return ctx;
};

export default GridProductsConnectorServiceProvider;
