import { action, Action, Thunk, thunk } from 'easy-peasy';
import createCrudModel, { CrudModel } from '../../common/crud-model/crud.model';
import ActionLog from '../../types/action-log';
import {
  PaginationData,
  PaginationRequest,
  PaginationCollection,
} from '../../types/pagination';
import Injections from '../../injections.interface';

export type ActionsLogsModel = CrudModel<ActionLog, PaginationRequest> & {
  pagination: PaginationData | null;
  setPagination: Action<ActionsLogsModel, PaginationData>;
  fetch: Thunk<ActionsLogsModel, PaginationRequest, Injections>;
};

const path = '/api/action-logs';

// TODO: create proper crud paginated model that will work for all other collections in the future
// TO DO: See model/pagination. Integrate.

const actionLogsModel: ActionsLogsModel = {
  ...createCrudModel<ActionLog, PaginationRequest>(path),
  pagination: null,
  setPagination: action((state, payload) => {
    state.pagination = payload;
  }),
  fetch: thunk(async (actions, payload, { injections }) => {
    actions.setLoading(true);
    actions.setError(null);
    try {
      const data = await injections.apiService.get<PaginationCollection<ActionLog>>(
        path,
        payload as any,
      );
      actions.setData(data.docs);
      actions.setPagination({
        total: data.totalDocs,
        page: data.page,
        limit: data.limit,
        query: payload.query,
      });
    } catch (err) {
      actions.setError(err);
    } finally {
      actions.setLoading(false);
    }
  }),
};

export default actionLogsModel;
