import { GridAssistant, AssistantCreateProps, useGridLlmService } from "@ombori/grid-llm-react";
import { useMutation, useQueryClient, UseMutationOptions } from 'react-query';
import storeAIAssistantQueryKeys from './store-ai-assistants-query-keys';

export const useStoreAIAssistantCreate = () => {
  const gridLlmService = useGridLlmService();
  const queryClient = useQueryClient();

  const createAssistantMutation = async (payload: AssistantCreateProps): Promise<GridAssistant> => {
    const assistant = await gridLlmService.createAssistant(payload);
    return assistant;
  };

  const options: UseMutationOptions<GridAssistant, Error, AssistantCreateProps> = {
    mutationFn: createAssistantMutation,
    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey: storeAIAssistantQueryKeys.paginationPrefix(data.tenantId) });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: storeAIAssistantQueryKeys.paginationPrefix(data.tenantId)
      });
    },
  };

  return useMutation<GridAssistant, Error, AssistantCreateProps>(options);
};
