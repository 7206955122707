import ApiService from '../services/api/api.service';
import { getApiUrl } from './env';

const api = new ApiService(getApiUrl());
export const deleteLease = (
  deviceId: string,
): Promise<any[]> =>
  api.delete(`api/v4/devices/${deviceId}/lease`);

export const createLease = (
  deviceId: string,
  {
    installationId,
    expiry,
  }: { installationId: string; expiry: string; },
): Promise<any[]> =>
  api.post(`api/v4/devices/${deviceId}/lease`, {
    installationId,
    expiry,
  });
