import React from 'react';
import Media from '../../../../store/types/media';
import MediaEditModal from '../media-edit-modal/media-edit-modal.component';
import { useTranslation } from 'react-i18next';

interface MediaPreviewProps {
  heading?: string;
  mediaItem: Media;
  onClose: () => void;
}

const MediaPreview = (props: MediaPreviewProps) => {
  const { mediaItem, heading, onClose } = props;

  const { t } = useTranslation();

  if (!mediaItem) {
    return null;
  }

  return (
    <MediaEditModal
      mediaItem={mediaItem}
      visible={true}
      onClose={onClose}
      onSubmit={() => {}}
      canEdit={false}
      heading={heading ? heading : t('preview')}
    />
  );
};

export default MediaPreview;
