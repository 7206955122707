import * as React from 'react';
import styled from '@emotion/styled';
import { Typography } from 'antd';
import Media from '../../../../store/types/media';
import { getMediaItemKind, MediaItemKind } from '../../../../utils/media-item-utils';

interface PreviewProps {
  mediaItem: Media;
}

const PreviewImage = styled.img`
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.24);
`;

const Preview = ({ mediaItem }: PreviewProps) => {
  const kind = getMediaItemKind(mediaItem);

  if (kind === MediaItemKind.image) {
    return <PreviewImage src={mediaItem.url} alt="preview" />;
  }

  if (kind === MediaItemKind.video) {
    return (
      <video controls>
        <source src={mediaItem.url} type={mediaItem.type} />
      </video>
    );
  }

  return <Typography.Text>Preview unavailable</Typography.Text>;
};

export default Preview;
