import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import MobileEndpointsList from './mobile-endpoint.component';
import { RootModel, RootState } from '../../../../../store/models/root.model';
import MobileEndpoint from '../../../../../store/types/mobile-endpoint';

type MobileEndpointsContainer = Pick<
  React.ComponentProps<typeof MobileEndpointsList>,
  'match' | 'history' | 'location'
>;

const MobileEndpointsContainer = (props: MobileEndpointsContainer) => {
  const {
    match: {
      params: { appId, organisationId },
    },
  } = props;

  const {
    fetchMobileEndpoints,
    createMobileEndpoint,
    updateMobileEndpoint,
    deleteMobileEndpoint,
    fetchEnvironments,
    fetchSpaces,
  } = useActions<RootModel>((actions) => ({
    fetchMobileEndpoints: actions.appMobileEndpoints.fetch,
    createMobileEndpoint: (data: Partial<MobileEndpoint>) =>
      actions.appMobileEndpoints.create({ data, key: appId }),
    updateMobileEndpoint: (data: Partial<MobileEndpoint>) =>
      actions.appMobileEndpoints.update({ data, key: appId }),
    deleteMobileEndpoint: (id: string) =>
      actions.appMobileEndpoints.delete({ id, key: appId }),
    fetchEnvironments: actions.environments.fetch,
    fetchSpaces: actions.organisationSpaces.fetch,
  }));

  const {
    mobileEndpoints,
    environments,
    spaces,
    mobileEndpointsLoaded,
    spacesLoaded,
    environmentsLoaded,
  } = useStore<RootState>(
    (state) => ({
      environments: state.environments.values(organisationId),
      environmentsLoaded: state.environments.loaded(organisationId),
      mobileEndpoints: state.appMobileEndpoints.values(appId),
      mobileEndpointsLoaded: state.appMobileEndpoints.loaded(appId),
      spaces: state.organisationSpaces.values(organisationId),
      spacesLoaded: state.organisationSpaces.loaded(organisationId),
    }),
    [appId],
  );

  return (
    <MobileEndpointsList
      {...props}
      mobileEndpoints={mobileEndpoints}
      environments={environments}
      spaces={spaces}
      loaded={mobileEndpointsLoaded && environmentsLoaded && spacesLoaded}
      fetchSpaces={fetchSpaces}
      fetchEnvironments={fetchEnvironments}
      fetchMobileEndpoints={fetchMobileEndpoints}
      createMobileEndpoint={createMobileEndpoint}
      updateMobileEndpoint={updateMobileEndpoint}
      deleteMobileEndpoint={deleteMobileEndpoint}
    />
  );
};

export default MobileEndpointsContainer;
