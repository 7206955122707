import React from 'react';
import { RouteComponentProps, Switch } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import ErrorBoundary from '../../../common/error-boundary/error-boundary.component';
import ProtectedRouteContainer from '../../../common/protected-route/protected-route.container';
import { getPermissionPath, permissionKeys } from '../../../../utils/auth/permissions';
import Overview, { OverviewProps } from './grid-products-overview/grid-products-overview.component';
import GridProductsDetails, { GridProductsDetailsProps } from './grid-products-details/grid-products-details.component';
import GridProductsTypes, { OverviewProps as GridProductsTypesProps } from './grid-products-types/grid-products-types.component';
import GridProductsTypesDetails, {
  GridProductTypesDetailsProps,
} from './grid-products-types-details/grid-products-types-details.component';
import GridProductsUpload from './grid-products-upload/grid-products-upload.container';
import routes from './routes';
import { useStore } from 'easy-peasy';
import { DataResidencyEnum } from '../../../../store/types/organisation';
import { RootState } from '../../../../store/models/root.model';
import { useTranslation } from 'react-i18next';
import ErrorView from '../../../common/error-view/error-view.component';
import ScrollContentToTop from '../../../common/app-layout/content/scroll-content-to-top.component';

type GridProductsProps = RouteComponentProps<{ organisationId: string }>;

const GridProducts = ({ match }: GridProductsProps) => {
  const {
    params: { organisationId: tenantId },
  } = match;

  const { t } = useTranslation();

  const dataResidency = useStore<RootState, DataResidencyEnum | null>((state) => {
    return (
      state.organisations.data &&
      state.organisations.data[tenantId] &&
      state.organisations.data[tenantId].dataResidency
    );
  });

  if (!dataResidency) {
    return <ErrorView error={{ message: t('invalidDataResidency') }} />;
  }

  return (
    <ErrorBoundary>
      <ScrollContentToTop />
      <Switch>
        <ProtectedRouteContainer
          permissionPath={getPermissionPath(tenantId, permissionKeys.gridProducts.viewAll)}
          path={routes.productsOverviewPattern()}
          render={(routeProps) => (
            <Overview
              {...routeProps as OverviewProps}
              dataResidency={dataResidency}
            />
          )}
          exact
        />

        <ProtectedRouteContainer
          permissionPath={getPermissionPath(tenantId, permissionKeys.gridProducts.viewAll)}
          path={routes.productsTypesPattern()}
          render={(routeProps) => (
            <GridProductsTypes
              {...routeProps as GridProductsTypesProps}
              dataResidency={dataResidency}
            />
          )}
          exact
        />

        <ProtectedRouteContainer
          permissionPath={getPermissionPath(tenantId, permissionKeys.gridProducts.create)}
          path={routes.uploadProductsPattern()}
          component={GridProductsUpload}
        />

        <ProtectedRouteContainer
          permissionPath={getPermissionPath(tenantId, permissionKeys.gridProducts.viewSingle)}
          path={routes.productOverviewPattern()}
          render={(routeProps) => (
            <GridProductsDetails
              {...routeProps as GridProductsDetailsProps}
              dataResidency={dataResidency}
            />
          )}
        />

        <ProtectedRouteContainer
          permissionPath={getPermissionPath(tenantId, permissionKeys.gridProducts.viewAll)}
          path={routes.productTypePattern()}
          render={(routeProps) => (
            <GridProductsTypesDetails
              {...routeProps as GridProductTypesDetailsProps}
              dataResidency={dataResidency}
            />
          )}
        />

        <Route
          exact
          path={match.path}
          render={() => <Redirect to={routes.productsOverviewPath(tenantId)} />}
        />
      </Switch>
    </ErrorBoundary>
  );
};

export default GridProducts;
