import * as React from 'react';
import { WidgetProps } from 'react-jsonschema-form';
// @ts-ignore
import TextWidget from 'react-jsonschema-form/lib/components/widgets/TextWidget';
import cronstrue from 'cronstrue';

const CronWidget = (props: WidgetProps) => {
  const [description, setDescription] = React.useState('');
  const { value } = props;

  React.useEffect(() => {
    try {
      setDescription(cronstrue.toString(value, { use24HourTimeFormat: true }));
    } catch (err) {
      setDescription('');
    }
  }, [value]);

  return (
    <>
      <TextWidget {...props} placeholder="* * * * *" />
      {description && <p className="help-block">{description}</p>}
    </>
  );
};

export default CronWidget;
