import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldProps } from 'react-jsonschema-form';
import { Input as InputField } from '../../common';
import toNumber from 'lodash/toNumber';
import toString from 'lodash/toString';

interface InputUiOptions {
  isBordered?: boolean;
  isTrimmed?: boolean;
}

interface InputProps extends FieldProps<string | number | undefined> {
  rawErrors?: string[];
}

const Input = (props: InputProps) => {
  const {
    rawErrors = [],
    formData,
    onChange,
    schema,
    uiSchema,
    required,
    readonly,
  } = props;

  const { t } = useTranslation();

  const {
    isBordered = true,
    isTrimmed = true,
  } = (uiSchema['ui:options'] || {}) as InputUiOptions;
  const helpText = uiSchema['ui:help'] as string;
  const placeholder = uiSchema['ui:placeholder'] as string;
  const isDisabled = uiSchema['ui:disabled'] as boolean;
  const type = schema.type as string | number | undefined;

  const { fallbackValue, defaultValue } = useMemo(() => {
    const fallbackValue = required || type === 'number' ? undefined : '';
    const transformedDefaultValue = type === 'number' ? toNumber(schema.default) : toString(schema.default);
    const defaultValue = transformedDefaultValue || fallbackValue;

    return {
      fallbackValue,
      defaultValue,
    }
  }, [required, schema.default, type]);

  const handleChange = useCallback((value?: string | number) => {
    const inputValue = value || fallbackValue;
    
    let transformedValue: string | number | undefined;
    
    switch(type) {
      case 'number':
        transformedValue = required && !inputValue ? undefined : toNumber(value);
        break;
      default:
        transformedValue = required && !inputValue ? undefined : toString(inputValue);
    }

    onChange(transformedValue);
  }, [onChange, fallbackValue, type, required]);

  return (
    <InputField
      type={type}
      title={schema.title}
      description={schema.description}
      defaultValue={defaultValue}
      value={formData || fallbackValue}
      placeholder={placeholder || t('input.placeholder')}
      rawErrors={rawErrors}
      helpText={helpText}
      onChange={handleChange}
      isReadonly={readonly}
      isTrimmed={isTrimmed}
      isDisabled={isDisabled}
      isRequired={required}
      isBordered={isBordered}
    />
  );
};

export default Input;
