import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FilterItem, { FilterOption } from '../../../../../store/types/filters';
import OrganisationApp from '../../../../../store/types/organisation-app';
import OrganisationSpace from '../../../../../store/types/organisation-space';
import {
  PHYHUB_DEVICE_ARCHITECTURE_NAMES_MAP,
  PHYHUB_TWIN_DEVICE_TYPE_MAP,
} from '../../../../../store/types/phyhub-devices';

export const PHYHUB_DEVICE_FILTERS_MAP = {
  DEVICE_ARCHITECTURE: 'deviceArchitecture',
  DEVICE_TYPE: 'deviceType',
  ENVIRONMENT_ID: 'environmentId',
  INSTALLATION_ID: 'installationId',
  SPACE_ID: 'spaceId',
};

interface UsePhyhubDeviceFiltersParams {
  // TODO: Add tenant installation type
  installations: OrganisationApp[];
  // TODO: Add tenant space type
  spaces: OrganisationSpace[];
}

interface UsePhyhubDeviceFiltersResponse {
  filterOptions: FilterItem[];
}

export default function usePhyhubDeviceFilters(
  params: UsePhyhubDeviceFiltersParams,
): UsePhyhubDeviceFiltersResponse {
  const { installations, spaces } = params;

  const { t } = useTranslation();

  const architectureOptions = useMemo<FilterOption[]>(
    () =>
      Object.entries(PHYHUB_DEVICE_ARCHITECTURE_NAMES_MAP).map(
        ([key, translationKey]) => ({
          id: key,
          label: t(translationKey),
        }),
      ),
    [t],
  );

  const deviceTypeOptions = useMemo<FilterOption[]>(
    () =>
      Object.entries(PHYHUB_TWIN_DEVICE_TYPE_MAP).map(([key, translationKey]) => ({
        id: key,
        label: t(translationKey),
      })),
    [t],
  );

  const installationOptions = useMemo<FilterOption[]>(
    () =>
      installations.map((installation) => ({
        id: installation.id,
        label: installation.displayName,
      })),
    [installations],
  );

  const spaceOptions = useMemo<FilterOption[]>(
    () =>
      spaces.map((space) => ({
        id: space.id,
        label: space.displayName,
      })),
    [spaces],
  );

  const filterOptions = useMemo<FilterItem[]>(() => {
    const items: FilterItem[] = [];

    items.push({
      id: PHYHUB_DEVICE_FILTERS_MAP.DEVICE_TYPE,
      label: t('filterDevice.environment'),
      options: deviceTypeOptions,
    });

    items.push({
      id: PHYHUB_DEVICE_FILTERS_MAP.DEVICE_ARCHITECTURE,
      label: t('filterDevice.architecture'),
      options: architectureOptions,
    });

    if (installationOptions.length) {
      items.push({
        id: PHYHUB_DEVICE_FILTERS_MAP.INSTALLATION_ID,
        label: t('filterDevice.installation'),
        options: installationOptions,
      });
    }

    if (spaceOptions) {
      items.push({
        id: PHYHUB_DEVICE_FILTERS_MAP.SPACE_ID,
        label: t('filterDevice.space'),
        options: spaceOptions,
      });
    }

    return items;
  }, [installationOptions, spaceOptions, deviceTypeOptions, architectureOptions, t]);

  return { filterOptions };
}
