import { TFunction } from 'i18next';

type BuildSchemaResponse = Record<string, string | number | boolean | object>;

export const buildFieldSchema = (t: TFunction): BuildSchemaResponse => {
  const fieldSchema = {
    type: 'object',
    properties: {
      general: {
        type: 'object',
        title: t('actionGroups.general'),
        properties: {
          name: {
            type: 'string',
            title: t('actionGroups.name'),
          },
          description: {
            type: 'string',
            title: t('actionGroups.description'),
          },
        },
        required: ['name']
      },
      notificationRecipients: {
        type: 'object',
        title: t('actionGroups.notificationRecipients'),
        properties: {
          emailRecipients: {
            type: 'array',
            title: t('actionGroups.emailRecipients'),
          },
          // smsRecipients: {
          //   type: 'array',
          //   title: t('actionGroups.smsRecipients'),
          // },
          webHookUrl: {
            type: 'string',
            title: t('actionGroups.webHookUrl'),
          },
        },
      },
    }
  };

  return fieldSchema;
};

export const buildUiSchema = (t: TFunction): BuildSchemaResponse => {
  const metaSchema = {
    objectFieldTemplate: 'main-section',
    general: {
      objectFieldTemplate: 'accordion',
      'ui:options': {
        isContentPadded: false,
        hasDivider: false,
        gap: 16,
        className: 'action-group-general',
      },
      name: {
        'ui:field': 'input',
      },
      description: {
        'ui:field': 'input',
      },
    },
    notificationRecipients: {
      objectFieldTemplate: 'accordion',
      'ui:options': {
        isContentPadded: false,
        hasDivider: false,
        gap: 16,
        className: 'action-group-notification-recipients',
      },
      emailRecipients: {
        'ui:field': 'userContactPicker',
        'ui:options': {
          contactProperty: 'email',
        },
      },
      // smsRecipients: {
      //   'ui:field': 'userContactPicker',
      //   'ui:options': {
      //     contactProperty: 'phoneNumber',
      //   },
      // },
      webHookUrl: {
        'ui:field': 'input',
      },
    },
  };

  return metaSchema;
};
