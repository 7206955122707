import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';
import * as Sentry from '@sentry/browser';
import { useApiService } from '../../../app/common/api-service-provider';
import { PlanFormValues as PlanPayload } from '../../../app/grid/billing/plan/plan-form.component';
import Plan from '../../types/plan';
import plansQueryKeys from './use-plans-query-keys';
import { ApiError } from '../../../services/api/api-error';

const usePlanEdit = (planId: string) => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  return useMutation(
    (body: PlanPayload) => {
      return apiService.put<Plan>(`/api/plans/${planId}`, body);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([plansQueryKeys.queryPrefix()]);
        queryClient.invalidateQueries(plansQueryKeys.getPlanGetKeys(planId));
      },
      onError: (error: ApiError) => {
        Sentry.captureException(error);
      }
    },
  );
};

export default usePlanEdit;