import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import PricingComponent from './pricing.component';
import { RootModel, RootState } from '../../../../../store/models/root.model';

type PricingContainerProps = Pick<
  React.ComponentProps<typeof PricingComponent>,
  'match' | 'history' | 'location'
>;

const PricingContainer = (props: PricingContainerProps) => {
  const {
    match: {
      params: { organisationId },
    },
  } = props;

  const { pricingSections, loaded } = useStore<RootState>(
    (state) => ({
      pricingSections: state.organisationPricing.data[organisationId] || [],
      loaded: state.organisationPricing.loaded(organisationId),
    }),
    [organisationId],
  );

  const { fetchPricingSections } = useActions<RootModel>((actions) => ({
    fetchPricingSections: actions.organisationPricing.fetch,
  }));

  return (
    <PricingComponent
      {...props}
      pricingSections={pricingSections}
      loaded={loaded}
      fetchPricingSections={fetchPricingSections}
    />
  );
};

export default PricingContainer;
