import React from 'react';
import WithMenu from '../common/with-menu/with-menu.component';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const WithSalesMenu = (props: RouteComponentProps & { children: any }) => {
  const { children, ...restProps } = props;
  const { t } = useTranslation();

  const salesMenuItems = [
    {
      label: t('enterpriseAgreement.title'),
      icon: 'file',
      path: '/sales/enterprise-agreements',
    },
    {
      label: t('deviceMonitoring.title'),
      icon: 'monitor',
      path: '/sales/monitoring',
    },
  ]
  return <WithMenu {...restProps} title={t('salesTools')} menuItems={salesMenuItems}>
    {children}
  </WithMenu>
}

export default WithSalesMenu;
