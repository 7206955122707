import { Typography, Tag } from 'antd';
import { RouteComponentProps } from 'react-router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import Markdown from 'react-markdown';
import Gridapp from '../../../../../store/types/gridapp';
import PanelCard from '../../../panel-card/panel-card.component';
import AppIcon from '../../app-icon/app-icon.component';

interface GridappInfoProps
  extends RouteComponentProps<{ gridappId: string; organisationId: string }> {
  gridapp: Gridapp | null;
  organizationName?: string;
}

const GridappInfo = (props: GridappInfoProps) => {
  const { t } = useTranslation();
  const { gridapp, organizationName } = props;

  if (!gridapp) {
    return null;
  }

  return (
    <PanelCard
      bodyStyle={{
        padding: 16,
      }}
      cover={
        <Cover>
          {gridapp.screenshots &&
            gridapp.screenshots.map((url) => (
              <Slide key={url}>
                <Screenshot src={url} />
              </Slide>
            ))}
        </Cover>
      }
    >
      <Flex>
        <AppIcon gridapp={gridapp} size={64} />
        <Header>
          <Typography.Title level={2}>
            {gridapp.displayName}
            <Private>{t('appPrivateTag')}</Private>
          </Typography.Title>
          {gridapp.author && (
            <Subheader>
              {organizationName}.{gridapp.packageName}
              {t('DOT')}
              {t('appBy', { author: gridapp.author })}
            </Subheader>
          )}
        </Header>
      </Flex>
      {/* eslint-disable-next-line */}
      {gridapp && <StyledMarkdown>{gridapp.description}</StyledMarkdown>}
    </PanelCard>
  );
};

const Slide = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #202639;
  padding: 8px;
`;

const Cover = styled.div`
  display: flex;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
`;

const Screenshot = styled.img`
  max-width: 100%;
  max-height: 100%;
  border-radius: 0 !important;
`;

const Header = styled.div`
  flex: 1;
  padding: 6px 0 0 16px;
`;

const Subheader = styled(Typography.Paragraph)`
  margin: 0 0 16px 0;
`;

const Private = styled(Tag)`
  margin-left: 8px;
  line-height: 2em;
  vertical-align: super;
`;

const Flex = styled.div`
  display: flex;
`;

const StyledMarkdown = styled(Markdown)<any>`
  margin-top: 8px;

  & code {
    background: lightgray;
    border-radius: 5px;
    padding: 0.1em 0.3em;
  }

  & p {
    margin-bottom: 1em;
  }

  & ul {
    list-style: inside;
    margin-left: 8px;
    margin-bottom: 1em;
  }

  & h2 {
    font-size: 1.5em;
    padding-bottom: 0.3em;
  }
`;

export default GridappInfo;
