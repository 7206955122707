import * as React from 'react';
import { useStore } from 'easy-peasy';
import { RouteComponentProps } from 'react-router-dom';
import { RootState } from '../../../../store/models/root.model';
import MediaLibraryContainer from '../../../common/media-library/media-library.container';

const OrganisationMediaLibraryContainer = (
  props: RouteComponentProps<{ organisationId: string; selectedFolderId: string }>,
) => {
  const {
    match: {
      params: { organisationId, selectedFolderId },
    },
    history,
  } = props;

  const { ancestors } = useStore<RootState>(({ organisationMedia }) => ({
    ancestors: organisationMedia.ancestors,
  }));

  const onSelectedMediaFolder = (folderId: string) => {
    history.push(`/organisations/${organisationId}/library/${folderId}`);
  };

  const onBackClick = (currentFolderId: string | null) => {
    if (currentFolderId && ancestors && ancestors.length) {
      let parentFolderId = '';
      if (ancestors.length > 1) {
        parentFolderId = ancestors.slice(-2)[0].id;
      }
      history.push(`/organisations/${organisationId}/library/${parentFolderId}`);
    }
  };

  return (
    <MediaLibraryContainer
      organisationId={organisationId}
      selectedFolderId={selectedFolderId || null}
      onSelectedFolder={onSelectedMediaFolder}
      onBackClick={onBackClick}
    />
  );
};

export default OrganisationMediaLibraryContainer;
