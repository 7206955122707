import React, { createContext, useContext } from 'react';
import GridProductsService from '../../../services/api/grid-products';

const GridProductsServiceContext = createContext<GridProductsService | null>(null);

interface GridProductsServiceProviderProps {
  children: React.ReactNode;
  service: GridProductsService;
}

const GridProductsServiceProvider: React.FC<GridProductsServiceProviderProps> = ({
  children,
  service,
}): JSX.Element => {
  return (
    <GridProductsServiceContext.Provider value={service}>
      {children}
    </GridProductsServiceContext.Provider>
  );
};

export const useGridProductsService = (): GridProductsService => {
  const ctx = useContext(GridProductsServiceContext);

  if (!ctx) {
    throw new Error(
      'Error caught while consuming GridProductsServiceContext context. Make sure you wrap the component inside the "GridProductsServiceProvider".',
    );
  }

  return ctx;
};

export default GridProductsServiceProvider;
