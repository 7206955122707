import React from 'react';
import { Field } from 'react-final-form';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { requiredAll } from '../../queue-details/queue-setup/validate-form';
import { FormTextInput } from '../../queue-details/queue-setup/styled-block';
import { CountryPicker } from '../../../../common/react-final-form';

const SpaceFormDetailsSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <SectionHeading className="control-label">
        {t('spaceForm.location')}
      </SectionHeading>

      <FieldsContainer>
        <CountryPicker
          name='country'
          label={t('spaceForm.country')}
          isRequired={true}
          showSearch={true}
        />
      </FieldsContainer>

      <FieldsContainer gutter={[12, 12]}>
        <Col span={12}>
          <Field
            name="address"
            validate={requiredAll}
            render={({ input, meta }) => (
              <FormTextInput
                input={input}
                meta={meta}
                label={t('spaceForm.address')}
                required={true}
              />
            )}
          />
        </Col>

        <Col span={12}>
          <Field
            name="city"
            validate={requiredAll}
            render={({ input, meta }) => (
              <FormTextInput
                input={input}
                meta={meta}
                label={t('spaceForm.city')}
                required={true}
              />
            )}
          />
        </Col>
      </FieldsContainer>

      <FieldsContainer gutter={[12, 12]}>
        <Col span={12}>
          <Field
            name="state"
            validate={requiredAll}
            render={({ input, meta }) => (
              <FormTextInput
                input={input}
                meta={meta}
                label={t('spaceForm.state')}
                required={true}
              />
            )}
          />
        </Col>

        <Col span={12}>
          <Field
            name="postcode"
            validate={requiredAll}
            render={({ input, meta }) => (
              <FormTextInput
                input={input}
                meta={meta}
                label={t('spaceForm.postcode')}
                required={true}
              />
            )}
          />
        </Col>
      </FieldsContainer>
    </>
  );
};

const SectionHeading = styled.h4`
  font-size: 16px;
  padding: 16px 0px;
`;

const FieldsContainer = styled(Row)`
  margin-bottom: 15px !important;
`;

export default SpaceFormDetailsSection;
