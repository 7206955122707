import React from 'react';
import styled from '@emotion/styled';
import { useSpaceDevices } from '../../../use-devices';
import CardContainer from '../card-container';
import SpaceImageCard from './space-image-card.component';

interface SpaceImageCardContainerProps {
  title: string;
  imageProperty: string;
  isVisibleWithoutData: boolean;
  tenantId: string;
  spaceId: string;
}

const SpaceImageCardContainer = (props: SpaceImageCardContainerProps) => {
  const { title, imageProperty, isVisibleWithoutData, tenantId, spaceId } = props;

  const {
    data: spaceDevices,
    isLoading,
    isFetching,
    isSuccess,
    isError,
  } = useSpaceDevices({ organisationId: tenantId, spaceId });

  return (
    <>
      {(isLoading || isFetching || isError) && (
        <LoadingContainer isError={isError} isLoading={isLoading || isFetching} isSuccess={false} hasData={false} title={title}>
          <></>
        </LoadingContainer>
      )}

      {!isLoading && !isFetching && !isError && isSuccess && spaceDevices && spaceDevices.length > 0 && (
        <SpaceImageCard
          title={title}
          imageProperty={imageProperty}
          isVisibleWithoutData={isVisibleWithoutData}
          devices={spaceDevices}
          spaceId={spaceId}
          tenantId={tenantId}
        />
      )}
    </>
  );
};

export default SpaceImageCardContainer;

const LoadingContainer = styled(CardContainer)``;
