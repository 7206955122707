const routes = {
  productsOverviewPath: (tenantId: string) =>
    ['', 'organisations', tenantId, 'grid-products', 'overview'].join('/'),
  productsOverviewPattern: () =>
    ['', 'organisations', ':organisationId', 'grid-products', 'overview'].join('/'),

  productOverviewPath: (tenantId: string, productGroupId: string) =>
    [
      '',
      'organisations',
      tenantId,
      'grid-products',
      'overview',
      `${productGroupId}`,
    ].join('/'),
  productOverviewPattern: () =>
    [
      '',
      'organisations',
      ':organisationId',
      'grid-products',
      'overview',
      ':productGroupId',
    ].join('/'),

  newProductPath: (tenantId: string) =>
    [
      '',
      'organisations',
      tenantId,
      'grid-products',
      'overview',
      `new-product`,
    ].join('/'),
  newProductPattern: () =>
    [
      '',
      'organisations',
      ':organisationId',
      'grid-products',
      'overview',
      `new-product`,
    ].join('/'),

  productsTypesPath: (tenantId: string) =>
    ['', 'organisations', tenantId, 'grid-products', 'product-types'].join('/'),
  productsTypesPattern: () =>
    ['', 'organisations', ':organisationId', 'grid-products', 'product-types'].join('/'),

  productTypePath: (tenantId: string, productTypeId: string) =>
    [
      '',
      'organisations',
      tenantId,
      'grid-products',
      'product-types',
      `${productTypeId}`,
    ].join('/'),
  productTypePattern: () =>
    [
      '',
      'organisations',
      ':organisationId',
      'grid-products',
      'product-types',
      ':productTypeId',
    ].join('/'),

  newProductTypePath: (tenantId: string) =>
    [
      '',
      'organisations',
      tenantId,
      'grid-products',
      'product-types',
      `new`,
    ].join('/'),
  newProductTypePattern: () =>
    [
      '',
      'organisations',
      ':organisationId',
      'grid-products',
      'product-types',
      `new`,
    ].join('/'),

  uploadProductsPath: (tenantId: string) =>
    ['', 'organisations', tenantId, 'grid-products', 'upload'].join('/'),
  uploadProductsPattern: () =>
    ['', 'organisations', ':organisationId', 'grid-products', 'upload'].join('/'),
};

export default routes;
