import React, { useCallback, useMemo, useEffect } from 'react';
import { useParams } from 'react-router';
import styled from '@emotion/styled';
import { Tabs } from 'antd';
import { CardType, AnalyticsSchema, Card } from '@ombori/grid-reports';
import { useStoreState } from '../../../../store/initialize-store';
import ErrorView from '../../error-view/error-view.component';
import Organisation from '../../../../store/types/organisation';
import { DeviceEventsList } from '../cards/events-list';
import { DeviceSessions } from '../cards/sessions';
import { DeviceEventsFlow } from '../cards/events-flow';
import { DeviceNps } from '../cards/nps';
import { DeviceProductsEventCount } from '../cards/products-event-count';
import { DeviceCategoriesEventCount } from '../cards/categories-event-count';
import { DeviceEventsFunnel } from '../cards/events-funnel';
import { DeviceWeekHeatmap } from '../cards/week-heatmap';
import { DeviceEventsCount } from '../cards/events-count';
import { DeviceAverageSales } from '../cards/average-sales';
import { DeviceAverageTimeBetweenTransactions } from '../cards/average-time-between-transactions';
import { DeviceAverageNumberOfPurchasedProducts } from '../cards/average-number-of-purchased-products';
import { DevicePurchasedProductsCategoriesEventCount } from '../cards/purchased-products-categories-event-count';
import { DevicePurchasedProductsEventCount } from '../cards/purchased-products-event-count';
import { DeviceQrCodesEventCount } from '../cards/qr-codes-event-count';
import { DeviceMedia } from '../cards/media';
import AnalyticsReport from '../analytics-report';
import Controls from '../controls';
import RangePicker from '../../range-picker/range-picker.component';
import useDateRange from '../../use-date-range';
import ErrorBoundary from '../../error-boundary/error-boundary.component';
import DownloadReport from '../download-report.component';
import DataMatrix from '../cards/data-matrix';
import useDeviceSchema from '../../use-device-schema/use-device-schema';
import Overlay from '../../overlay/overlay.component';
import Spinner from '../../spinner/spinner.component';
import { useTranslation } from 'react-i18next';
import validateSchema from '../validate-schema';
import { ReportContext } from '../../use-analytics-report';
import { DeviceEventsSuccessFailureRate } from '../cards/events-succes-failure-rate';
import { DeviceRealtimeStatus } from '../cards/realtime-status';
import { DeviceMonitoringStatusHistory } from '../cards/monitoring-status-history';
import { useAnalyticsParams } from '../../use-analytics-params';
import { DeviceImageCardContainer } from '../cards/image';

const { TabPane } = Tabs;

const Container = styled.div``;

const WrapperContainer = styled.div``;

const ErrorMessage = styled.div`
  padding: 15px 0;
  color: rgb(255, 85, 0);
`;

interface CheckSchemaValidResult {
  isValid: true;
  analyticsSchema: AnalyticsSchema;
}

interface CheckSchemaInvalidResult {
  isValid: false;
  analyticsSchema: undefined;
}

type CheckSchemaResult = CheckSchemaValidResult | CheckSchemaInvalidResult;

const checkSchema = (analyticsSchema: unknown): CheckSchemaResult => {
  try {
    const validatedSchema = validateSchema(analyticsSchema);

    return { isValid: true, analyticsSchema: validatedSchema };
  } catch (error) {
    return { isValid: false, analyticsSchema: undefined };
  }
};
interface ReportProps {
  schema: AnalyticsSchema;
  tenant: Organisation;
  deviceId: string;
}

const Report: React.FC<ReportProps> = ({ schema: { groups }, tenant, deviceId }) => {
  const { dateFrom, dateTo, onChangeRange } = useDateRange();

  const { updateAnalyticsParams } = useAnalyticsParams();

  useEffect(() => {
    updateAnalyticsParams({ fromDate: dateFrom, toDate: dateTo });
  }, [dateFrom, dateTo, updateAnalyticsParams]);

  const getComponent = useCallback(
    (reportCard: Card): React.ReactNode => {
      switch (reportCard.type) {
        case CardType.EventsList:
          return (
            <DeviceEventsList
              tenantId={tenant.id}
              deviceId={deviceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              interactionType={reportCard.interactionType}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.EventsCount:
          return (
            <DeviceEventsCount
              tenantId={tenant.id}
              deviceId={deviceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              title={reportCard.title}
              eventType={reportCard.eventType}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.Sessions:
          return (
            <DeviceSessions
              tenantId={tenant.id}
              deviceId={deviceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              interactionType={reportCard.interactionType}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.EventsFlow:
          return (
            <DeviceEventsFlow
              tenantId={tenant.id}
              deviceId={deviceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.Nps:
          return (
            <DeviceNps
              tenantId={tenant.id}
              deviceId={deviceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.ProductsEventCount:
          return (
            <DeviceProductsEventCount
              tenantId={tenant.id}
              deviceId={deviceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              eventType={reportCard.eventType}
              title={reportCard.title}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.CategoriesEventCount:
          return (
            <DeviceCategoriesEventCount
              tenantId={tenant.id}
              deviceId={deviceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.EventsFunnel:
          return (
            <DeviceEventsFunnel
              tenantId={tenant.id}
              deviceId={deviceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              title={reportCard.title}
              events={reportCard.events}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.WeekHeatmap:
          return (
            <DeviceWeekHeatmap
              tenantId={tenant.id}
              deviceId={deviceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              title={reportCard.title}
              dataSource={reportCard.dataSource}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.DataMatrix:
          return (
            <DataMatrix
              tenantId={tenant.id}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              title={reportCard.title}
              dataSource={reportCard.dataSource}
              columns={reportCard.columns}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.AverageSales:
          return (
            <DeviceAverageSales
              tenantId={tenant.id}
              deviceId={deviceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.AverageTimeBetweenTransactions:
          return (
            <DeviceAverageTimeBetweenTransactions
              tenantId={tenant.id}
              deviceId={deviceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.AverageNumberOfPurchasedProducts:
          return (
            <DeviceAverageNumberOfPurchasedProducts
              tenantId={tenant.id}
              deviceId={deviceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.PurchasedProductsEventCount:
          return (
            <DevicePurchasedProductsEventCount
              tenantId={tenant.id}
              deviceId={deviceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.PurchasedProductsCategoriesEventCount:
          return (
            <DevicePurchasedProductsCategoriesEventCount
              tenantId={tenant.id}
              deviceId={deviceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.EventsSuccessFailureRate:
          return (
            <DeviceEventsSuccessFailureRate
              tenantId={tenant.id}
              deviceId={deviceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              interactionType={reportCard.interactionType}
              gridStyles={reportCard.gridStyles}
              title={reportCard.title}
              inputType={reportCard.inputType}
              additionalData={reportCard.additionalData}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
              dataSource={reportCard.dataSource}
              dataSourceType={reportCard.dataSourceType}
              reportContext={ReportContext.Device}
            />
          );

        case CardType.QrCodesCount:
          return (
            <DeviceQrCodesEventCount
              tenantId={tenant.id}
              deviceId={deviceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.Media:
          return (
            <DeviceMedia
              tenantId={tenant.id}
              deviceId={deviceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              title={reportCard.title}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
              primaryKey={reportCard.primaryKey}
            />
          );

        case CardType.MonitoringRealtimeStatus:
          return (
            <DeviceRealtimeStatus
              tenantId={tenant.id}
              deviceId={deviceId}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              distinctColumns={reportCard.distinctColumns}
              eventType={reportCard.eventType}
              title={reportCard.title}
              statusReference={reportCard.statusReference}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.MonitoringStatusHistory:
          return (
            <DeviceMonitoringStatusHistory
              dateFrom={dateFrom}
              dateTo={dateTo}
              tenantId={tenant.id}
              deviceId={deviceId}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              eventType={reportCard.eventType}
              title={reportCard.title}
              statusReference={reportCard.statusReference}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.ModulesStatus:
          return (
            <></>
          );

        case CardType.Image:
          return (
            <DeviceImageCardContainer
              title={reportCard.title}
              imageProperty={reportCard.imageProperty}
              deviceId={deviceId}
              isVisibleWithoutData={false}
            />
          );

        default:
          // @ts-ignore
          throw new Error(`Unknown schema card type ${reportCard.type}`);
      }
    },
    [tenant, deviceId, dateFrom, dateTo],
  );

  return (
    <WrapperContainer>
      <Controls>
        <RangePicker dateFrom={dateFrom} dateTo={dateTo} onChangeRange={onChangeRange} />
        <DownloadReport
          tenantId={tenant.id}
          dataResidency={tenant.dataResidency}
          deviceId={deviceId}
          filters={{
            dateFrom,
            dateTo,
          }}
        />
      </Controls>

      <Tabs defaultActiveKey="1" animated={false}>
        {groups.map((group, index) => {
          const components = group.cards.map(getComponent);

          return (
            <TabPane tab={group.name} key={`${index + 1}`}>
              <AnalyticsReport
                cards={components}
                columnsCount={group.columnsCount}
                gridStyles={group.gridStyles}
              />
            </TabPane>
          );
        })}
      </Tabs>
    </WrapperContainer>
  );
};

interface WrapperProps extends ReportProps {
  analyticsSchema: AnalyticsSchema;
}

const Wrapper: React.FC<WrapperProps> = ({
  schema,
  tenant,
  analyticsSchema,
  deviceId,
}) => {
  const checkSchemaResult = useMemo(() => checkSchema(analyticsSchema), [
    analyticsSchema,
  ]);

  if (checkSchemaResult.isValid) {
    return (
      <Report
        tenant={tenant}
        schema={checkSchemaResult.analyticsSchema}
        deviceId={deviceId}
      />
    );
  }

  return (
    <Container>
      <ErrorBoundary>
        <Report tenant={tenant} schema={schema} deviceId={deviceId} />
      </ErrorBoundary>
    </Container>
  );
};

interface DeviceReportProps {
  schema: AnalyticsSchema;
}

const DeviceReport: React.FC<DeviceReportProps> = ({ schema }) => {
  const { organisationId: tenantId, deviceUuid } = useParams<{
    organisationId: string;
    deviceUuid: string;
  }>();

  const { t } = useTranslation();

  const { tenant } = useStoreState(
    (state) => ({
      tenant: state.organisations.data && state.organisations.data[tenantId],
    }),
    [tenantId],
  );

  const deviceSchemaState = useDeviceSchema({
    organizationId: tenantId,
    deviceId: deviceUuid,
  });

  if (!tenant) {
    return <ErrorView />;
  }

  return (
    <Container>
      <ErrorBoundary>
        {deviceSchemaState.isLoading && (
          <Overlay>
            <Spinner />
          </Overlay>
        )}

        {deviceSchemaState.isSuccess && (
          <Wrapper
            tenant={tenant}
            schema={schema}
            analyticsSchema={deviceSchemaState.data}
            deviceId={deviceUuid}
          />
        )}

        {deviceSchemaState.isError && (
          <ErrorMessage>{t('errorDuringFetchingSchema')}</ErrorMessage>
        )}
      </ErrorBoundary>
    </Container>
  );
};

export default DeviceReport;
