import React, { useMemo } from 'react';
import { DataResidencyEnum } from '../../../../store/types/organisation';
import { GridStyles } from '@ombori/grid-reports';
import styled from '@emotion/styled';
import { Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts';
import {
  TenantPurchaseProductByDay,
  InstallationPurchaseProductByDay,
  SpacePurchaseProductByDay,
  DevicePurchaseProductByDay,
  AppPurchaseProductByDay,
  useTenantPurchasedProductsByDay,
  useInstallationPurchasedProductsByDay,
  useSpacePurchasedProductsByDay,
  useDevicePurchasedProductsByDay,
  useAppPurchasedProductsByDay,
} from '../../use-analytics-report';
import CardContainer from './card-container';
import { useTranslation } from 'react-i18next';
import groupBy from 'lodash/groupBy';
import keys from 'lodash/keys';
import sumBy from 'lodash/sumBy';
import sortBy from 'lodash/sortBy';

type RawData =
  | TenantPurchaseProductByDay
  | InstallationPurchaseProductByDay
  | SpacePurchaseProductByDay
  | DevicePurchaseProductByDay
  | AppPurchaseProductByDay;

const buildData = (rawData: RawData[]) => {
  const groupedByDateEvents = groupBy(rawData, (dataItem) => dataItem.date);

  const result = sortBy(keys(groupedByDateEvents)).map((date) => {
    const events = groupedByDateEvents[date];

    const groupedByTransactionEvents = groupBy(
      events,
      (dataItem) => dataItem.transactionId,
    );

    const dataPerTransaction = keys(groupedByTransactionEvents).map((transactionId) => {
      const transactionEvents = groupedByTransactionEvents[transactionId];

      const totalQuantity = sumBy(
        transactionEvents,
        (item) => item.quantity * item.count,
      );

      const result = {
        date,
        transactionId,
        totalQuantity,
      };

      return result;
    });

    const totalQuantity = sumBy(dataPerTransaction, (item) => item.totalQuantity);

    const averageQuantity = Math.round(totalQuantity / dataPerTransaction.length);

    const result = {
      date,
      averageQuantity,
    };

    return result;
  });

  return result;
};

const Container = styled.div``;

const Body = styled.div``;

const Footer = styled.div`
  border-top: 1px solid rgb(232, 232, 232);
  display: grid;
  grid-auto-flow: column;
  padding: 8px 0;
  margin: 30px 0 0;
  column-gap: 16px;
  -webkit-box-pack: start;
  justify-content: start;
`;

interface AverageNumberOfPurchasedProductsProps {
  data: RawData[];
}

const AverageNumberOfPurchasedProducts = ({
  data,
}: AverageNumberOfPurchasedProductsProps) => {
  const { t } = useTranslation();

  const newData = buildData(data);

  const chartData = newData.map(({ date, averageQuantity }) => {
    return {
      date,
      value: averageQuantity,
    };
  });

  const totalAverage = Math.round(
    sumBy(newData, (item) => item.averageQuantity) / newData.length,
  );

  return (
    <Container>
      <Body>
        <ResponsiveContainer width="100%" height={80}>
          <AreaChart data={chartData} margin={{ top: 0, bottom: 0, left: 0, right: 0 }}>
            <Tooltip
              labelFormatter={(i: string | number) => {
                if (typeof i === 'string') {
                  return i;
                }

                const label = chartData[i] ? chartData[i].date : '';

                return label;
              }}
            />
            <Area
              key="value"
              dataKey="value"
              type="basis"
              stroke="#3772e0"
              strokeWidth={2}
              fill="#3772e0"
              fillOpacity={0.2}
            />
          </AreaChart>
        </ResponsiveContainer>
      </Body>

      <Footer>
        {t('averageNumberOfItemsPerTransactionForPeriod')}: {totalAverage}
      </Footer>
    </Container>
  );
};

interface BaseProps {
  tenantId: string;
  dateFrom: string;
  dateTo: string;
  dataResidency: DataResidencyEnum;
  gridStyles?: GridStyles;
  isVisibleWithoutData?: boolean;
}

export const TenantAverageNumberOfPurchasedProducts = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}: BaseProps) => {
  const { t } = useTranslation();

  const purchasesFetchingState = useTenantPurchasedProductsByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const data = useMemo(() => purchasesFetchingState.data || [], [purchasesFetchingState]);

  return (
    <CardContainer
      isLoading={purchasesFetchingState.isLoading}
      isSuccess={purchasesFetchingState.isSuccess}
      isError={purchasesFetchingState.isError}
      hasData={data.length > 0}
      title={t('averageNumberOfItemsPerTransaction')}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {purchasesFetchingState.isSuccess && (
        <AverageNumberOfPurchasedProducts data={data} />
      )}
    </CardContainer>
  );
};

interface InstallationProps extends BaseProps {
  installationId: string;
}

export const InstallationAverageNumberOfPurchasedProducts = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
  installationId,
}: InstallationProps) => {
  const { t } = useTranslation();

  const purchasesFetchingState = useInstallationPurchasedProductsByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
    installationId,
  });

  const data = useMemo(() => purchasesFetchingState.data || [], [purchasesFetchingState]);

  return (
    <CardContainer
      isLoading={purchasesFetchingState.isLoading}
      isSuccess={purchasesFetchingState.isSuccess}
      isError={purchasesFetchingState.isError}
      hasData={data.length > 0}
      title={t('averageNumberOfItemsPerTransaction')}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {purchasesFetchingState.isSuccess && (
        <AverageNumberOfPurchasedProducts data={data} />
      )}
    </CardContainer>
  );
};

interface SpaceProps extends BaseProps {
  spaceId: string;
}

export const SpaceAverageNumberOfPurchasedProducts = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
  spaceId,
}: SpaceProps) => {
  const { t } = useTranslation();

  const purchasesFetchingState = useSpacePurchasedProductsByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
    spaceId,
  });

  const data = useMemo(() => purchasesFetchingState.data || [], [purchasesFetchingState]);

  return (
    <CardContainer
      isLoading={purchasesFetchingState.isLoading}
      isSuccess={purchasesFetchingState.isSuccess}
      isError={purchasesFetchingState.isError}
      hasData={data.length > 0}
      title={t('averageNumberOfItemsPerTransaction')}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {purchasesFetchingState.isSuccess && (
        <AverageNumberOfPurchasedProducts data={data} />
      )}
    </CardContainer>
  );
};

interface DeviceProps extends BaseProps {
  deviceId: string;
}

export const DeviceAverageNumberOfPurchasedProducts = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
  deviceId,
}: DeviceProps) => {
  const { t } = useTranslation();

  const purchasesFetchingState = useDevicePurchasedProductsByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
    deviceId,
  });

  const data = useMemo(() => purchasesFetchingState.data || [], [purchasesFetchingState]);

  return (
    <CardContainer
      isLoading={purchasesFetchingState.isLoading}
      isSuccess={purchasesFetchingState.isSuccess}
      isError={purchasesFetchingState.isError}
      hasData={data.length > 0}
      title={t('averageNumberOfItemsPerTransaction')}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {purchasesFetchingState.isSuccess && (
        <AverageNumberOfPurchasedProducts data={data} />
      )}
    </CardContainer>
  );
};

interface AppProps extends BaseProps {
  appId: string;
}

export const AppAverageNumberOfPurchasedProducts = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
  appId,
}: AppProps) => {
  const { t } = useTranslation();

  const purchasesFetchingState = useAppPurchasedProductsByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
    appId,
  });

  const data = useMemo(() => purchasesFetchingState.data || [], [purchasesFetchingState]);

  return (
    <CardContainer
      isLoading={purchasesFetchingState.isLoading}
      isSuccess={purchasesFetchingState.isSuccess}
      isError={purchasesFetchingState.isError}
      hasData={data.length > 0}
      title={t('averageNumberOfItemsPerTransaction')}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {purchasesFetchingState.isSuccess && (
        <AverageNumberOfPurchasedProducts data={data} />
      )}
    </CardContainer>
  );
};
