import React, { useEffect } from 'react';
import { useActions, useStore } from 'easy-peasy';
import OrganisationDevicesList from './devices-universal-list.component';
import { RootModel, RootState } from '../../../../store/models/root.model';

type DeviceListContainerProps = Omit<
  React.ComponentProps<typeof OrganisationDevicesList>,
  'devices' | 'loaded' | 'lastUpdated' | 'fetchDevices' | 'error'
>;

const DevicesUniversalListContainer = (props: DeviceListContainerProps) => {
  const {
    match: {
      params: { organisationId },
    },
  } = props;

  const {
    devices,
    loaded,
    isPaginationLoading,
    lastUpdated,
    error,
    pagination,
    apps,
    spaces,
  } = useStore<RootState>(
    (state) => ({
      devices: state.organisationDevices.values(organisationId),
      loaded: state.organisationDevices.loaded(organisationId),
      isPaginationLoading: state.organisationDevices.isPaginationLoading[organisationId],
      lastUpdated: state.organisationDevices.lastUpdated[organisationId],
      error: state.organisationDevices.error[organisationId],
      pagination: state.organisationDevices.pagination[organisationId],
      apps: state.organisationApps.values(organisationId),
      spaces: state.organisationSpaces.values(organisationId),
    }),
    [organisationId],
  );

  const { fetchDevices, fetchSpaces } = useActions<RootModel>((actions) => ({
    fetchDevices: actions.organisationDevices.fetch,
    fetchSpaces: actions.organisationSpaces.fetch,
  }));

  useEffect(() => {
    fetchSpaces({ silent: true, organizationId: organisationId });
  }, [fetchSpaces, organisationId]);

  return (
    <OrganisationDevicesList
      {...props}
      apps={apps}
      lastUpdated={lastUpdated}
      devices={devices}
      loaded={loaded}
      isPaginationLoading={isPaginationLoading}
      fetchDevices={fetchDevices}
      error={error}
      pagination={pagination}
      spaces={spaces}
    />
  );
};

export default DevicesUniversalListContainer;
