import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useApiService } from '../../../app/common/api-service-provider';
import { AlertAction, AlertActionGroup } from '../../types/alerts';
import { PaginationCollection } from '../../types/pagination';

const queryKeys = {
  getAlertActionGroupList: (
    tenantId: string,
    limit: number = 10000,
    page: number = 1,
  ) => ['alert-action-group', 'tenantId', tenantId, 'page', page, 'limit', limit],
  getAlertActionById: (alertActionGroupId: string) => [
    'alert-action-group-id',
    'alertActionGroupId',
    alertActionGroupId,
  ],
};

interface UseAlertActionGroupProps {
  tenantId: string;
  page?: number;
  limit?: number;
}

interface UseAlertActionGroupBody {
  tenantId: string;
  displayName: string;
  description: string;
  actions: AlertAction[];
}

export const useAlertActionGroup = ({
  tenantId,
  page = 1,
  limit = 10000,
}: UseAlertActionGroupProps) => {
  const apiService = useApiService();

  const url = `/api/alert-action-group?tenantId=${tenantId}&page=${page}&limit=${limit}`;

  return useQuery(
    queryKeys.getAlertActionGroupList(tenantId, limit, page),
    () => apiService.get<PaginationCollection<AlertActionGroup>>(url),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      keepPreviousData: true,
    },
  );
};

export const useAlertActionGroupById = ({
  alertActionGroupId,
}: {
  alertActionGroupId: string;
}) => {
  const apiService = useApiService();

  const url = `/api/alert-action-group/${alertActionGroupId}`;

  return useQuery(
    queryKeys.getAlertActionById(alertActionGroupId),
    () => apiService.get<AlertActionGroup>(url),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      enabled: alertActionGroupId ? true : false,
    },
  );
};

export const useUpdateAlertActionGroup = (
  tenantId: string,
  alertActionGroupId: string,
) => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  const url = `/api/alert-action-group/${alertActionGroupId}`;

  return useMutation(
    async (updateAlertActionGroupBody: UseAlertActionGroupBody) =>
      apiService.put<AlertActionGroup>(url, updateAlertActionGroupBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.getAlertActionGroupList(tenantId));
      },
    },
  );
};

export const useCreateAlertActionGroup = (tenantId: string) => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  const url = `/api/alert-action-group/`;

  return useMutation(
    (createAlertActionGroupBody: UseAlertActionGroupBody) =>
      apiService.post(url, createAlertActionGroupBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.getAlertActionGroupList(tenantId));
      },
    },
  );
};

export const useDeleteAlertActionGroupById = (tenantId: string) => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  return useMutation(
    (alertActionGroupId: string) =>
      apiService.delete(`/api/alert-action-group/${alertActionGroupId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.getAlertActionGroupList(tenantId));
      },
    },
  );
};
