import React, { useCallback, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SchemaForm from '../../../../common/schema-form/schema-form.component';
import useSchemaForm from '../../../../common/use-schema-form/use-schema-form';
import { buildFieldSchema, buildUiSchema } from './build-schema';
import { ColorsTypes, TenantSettings } from '../../../../../store/types/branding';

type ColorsProps = RouteComponentProps<{ organisationId: string }> & {
  onFormChangeHandler: (data: Partial<TenantSettings>) => void;
  colorSettings: Partial<ColorsTypes>;
};

const Colors = (props: ColorsProps) => {
  const { match, onFormChangeHandler, colorSettings } = props;
  const {
    params: { organisationId: tenantId },
  } = match;

  const { t } = useTranslation();

  const { fieldSchema, uiSchema } = useMemo(() => {
    return {
      fieldSchema: buildFieldSchema(t),
      uiSchema: buildUiSchema(t),
    };
  }, [t]);

  const [, , , formInit, handleFormChange, handleSubmittedForm] = useSchemaForm(
    async (data) => {},
    t('brandingColors.successSavingData'),
    t('brandingColors.errorSavingData'),
  );

  useEffect(() => {
    if (colorSettings) {
      formInit(colorSettings);
    }
  }, [formInit, colorSettings]);

  const handleFormDataChange = useCallback(
    (formDataChange) => {
      onFormChangeHandler(formDataChange);
      handleFormChange(formDataChange);
    },
    [onFormChangeHandler, handleFormChange],
  );

  return (
    <SchemaForm
      organisationId={tenantId}
      schema={fieldSchema}
      uiSchema={uiSchema}
      data={colorSettings}
      onSubmit={handleSubmittedForm}
      onChange={handleFormDataChange}
    />
  );
};

export default Colors;
