import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CrudModalComponent from '../../../../common/crud-list/crud-modal/crud-modal.component';
import { AanalyticsDataForm } from '../../../../../store/types/analytics-data-export';
import { FormValidation } from 'react-jsonschema-form';
import DatePicker from '../../../../common/schema-form/widgets/date-picker/date-picker.component';
import moment from 'moment';
import { useUsers } from '../../../../common/use-analytics-data-export';
import MultiSelect from '../../../../common/schema-form/widgets/multi-select/multi-select.component';

interface AddNewJobModalProps {
  visible: boolean;
  onClose: (showModal: boolean) => void;
  onOk: ({ values }: { values: Partial<AanalyticsDataForm> }) => Promise<void>;
  organisationId: string;
}

const AddNewJobModal = (props: AddNewJobModalProps) => {
  const { visible, onClose, onOk, organisationId } = props;
  const { t } = useTranslation();
  const { data, isSuccess } = useUsers(organisationId, visible);

  const handleModalClose = useCallback(() => {
    onClose(false);
  }, [onClose]);

  const handleModalSubmit = useCallback(
    async (values: Partial<AanalyticsDataForm>) => {
      await onOk({ values });
    },
    [onOk],
  );

  const customFormValidation = useCallback(
    (formData: Partial<AanalyticsDataForm>, error: FormValidation) => {
      const { dataDateFrom, dataDateTo } = formData;
      if (dataDateFrom && dataDateTo) {
        const currentDate = new Date();
        if (new Date(dataDateFrom) > currentDate) {
          error.dataDateFrom.addError(
            t('dataExport.validateStartDateGreaterCurrentDate'),
          );
        }
        if (new Date(dataDateTo) > currentDate) {
          error.dataDateTo.addError(t('dataExport.validateEndDateGreaterCurrentDate'));
        } else if (new Date(dataDateFrom) > new Date(dataDateTo)) {
          error.dataDateTo.addError(t('dataExport.validateEndDateGreaterEqualStartDate'));
        }
      }
      return error;
    },
    [t],
  );

  const usersList = useMemo(() => (isSuccess ? data : []), [isSuccess, data]);

  const dataExportCreateFormSchema = useMemo(
    () => ({
      type: 'object',
      properties: {
        dataDateFrom: {
          type: 'string',
          title: t('dataExport.exportDataFrom'),
          format: 'date',
        },
        dataDateTo: {
          type: 'string',
          title: t('dataExport.exportDataTo'),
          format: 'date',
        },
        emailRecipient: {
          type: 'array',
          title: t('manageSchedule.emailRecipients'),
          items: {
            type: 'string',
            enum: usersList ? usersList.map((users) => users.id) : [],
            enumNames: usersList ? usersList.map((users) => users.email) : [],
          },
          uniqueItems: true,
        },
      },
      required: ['dataDateFrom', 'dataDateTo'],
    }),
    [t, usersList],
  );

  const uiSchema = {
    dataDateFrom: {
      'ui:widget': DatePicker,
      'ui:options': {
        minDate: moment('2020-01-01').format('YYYY-MM-DD'),
        maxDate: moment().format('YYYY-MM-DD'),
      },
    },
    dataDateTo: {
      'ui:widget': DatePicker,
      'ui:options': {
        minDate: moment('2020-01-01').format('YYYY-MM-DD'),
        maxDate: moment().format('YYYY-MM-DD'),
      },
    },
    emailRecipient: {
      'ui:field': MultiSelect,
    },
  };

  return (
    <CrudModalComponent
      title={t('dataExport.newDataExportJob')}
      schema={dataExportCreateFormSchema}
      metaSchema={uiSchema}
      initialValues={{}}
      onSubmit={handleModalSubmit}
      visible={visible}
      onClose={handleModalClose}
      customFormValidation={customFormValidation}
    />
  );
};

export default AddNewJobModal;
