import React from 'react';
import MediaFreeCollection from '../../../../common/media-library/media-free-collection/media-free-collection.component';
import useContentLibraryUploadMedia from '../../../../../store/hooks/content-library/use-content-library-upload-media';

interface ContentLibraryFreeImageProps {
  tenantId: string;
  parentFolderId: string | undefined;
  isVisible: boolean;
  onClose: () => void;
}

const ContentLibraryFreeImage = (props: ContentLibraryFreeImageProps) => {
  const { tenantId, parentFolderId, isVisible, onClose } = props;

  const { mutateAsync: uploadMedia } = useContentLibraryUploadMedia(tenantId);

  return (
    <MediaFreeCollection
      organisationId={tenantId}
      parentFolderId={parentFolderId}
      visible={isVisible}
      onClose={onClose}
      uploadMedia={uploadMedia}
    />
  );
};

export default ContentLibraryFreeImage;
