import {
  UseOrganizationUsersCreateFormFieldsProps,
} from '../store/hooks/organization-users/use-organization-users-create';
import {
  UseOrganizationUsersEditFormFieldsProps,
} from '../store/hooks/organization-users/use-organization-users-edit';
import { SpacesAccessInterface } from '../store/hooks/spaces/useUserSpacePermissions';
import {
  UserSpaceDropdownOption
} from '../app/organisations/organisation-details/user-management/users-list/user-forms/organization-user-form-fields.component';

type UserFormFieldsProps = UseOrganizationUsersCreateFormFieldsProps | UseOrganizationUsersEditFormFieldsProps;

export const formatAllowedUserSpacesData = <T extends UserFormFieldsProps>(formData: T, requestorUserSpaceAccess: SpacesAccessInterface): T => {
  const { allowedSpaceIds, ...rest } = formData;
  const spaceArray = allowedSpaceIds as string[];
  let hasAccessToAllSpaces =  spaceArray.length === 1 && spaceArray[0] === UserSpaceDropdownOption.ALL;
  let updatedAllowedSpaceIds = hasAccessToAllSpaces ? [] : spaceArray;

  // if requester user do not have access to all spaces, hasAccessToAllSpaces for the user who is being edited or created must be false
  // and managed space access (same as requester user) should be given
  if(hasAccessToAllSpaces && !requestorUserSpaceAccess.hasAccessToAllSpaces){
    hasAccessToAllSpaces = false;
    updatedAllowedSpaceIds = requestorUserSpaceAccess.spacesAccess as string[];
  }

  return {
    ...rest,
    hasAccessToAllSpaces,
    allowedSpaceIds: updatedAllowedSpaceIds,
  } as T;
};