import React, { useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { Row, Col, Typography } from 'antd';
import { FormApi } from 'final-form';
import { useTranslation } from 'react-i18next';
import {
  ArrayFields,
  Input,
  Select,
  Option,
  SelectProps,
  validateCharacterLength,
} from '../../../../../common/react-final-form';
import { getOperatorOptions } from '../../../../../../utils/alerts';
import {
  AnalyticsEventFilter,
  AnalyticsEventFilterTypeEnum,
  AnalyticsEventPropertyEnum,
  NumericOperatorEnum,
} from '../../../../../../store/types/alerts';
import DeviceAnalyticsEventsCountConditionFilters from './device-analytics-events-count-condition-filters.component';
import { requiredAll, requiredNumber } from '../../../queue-details/queue-setup/validate-form';

const DURATION_INTERVALS = [5, 10, 15, 20, 25, 30];

const durationOptions: Option[] = DURATION_INTERVALS.map((duration) => {
  return {
    label: `${duration}`,
    value: duration * 60, // convert to seconds
  };
});

const eventPropertyOptions = Object.entries(AnalyticsEventPropertyEnum).map(
  ([label, value]) => {
    return {
      label: label,
      value: value,
    };
  },
);

const DeviceAnalyticsEventsCountConditionFields = (props: {
  parentFieldName: string;
  updateForm: FormApi['change'];
}) => {
  const { parentFieldName, updateForm } = props;

  const { t } = useTranslation();

  const operatorOptions = useMemo<SelectProps['options']>(() => {
    return getOperatorOptions(t).map((operator) => ({
      value: operator.id as string,
      label: operator.label,
    }));
  }, [t]);

  // unset fields from parent when the component unmounts to avoid having un-necessary fields on form submission
  useEffect(() => {
    return () => {
      updateForm(`${parentFieldName}.eventType`, undefined);
      updateForm(`${parentFieldName}.operator`, undefined);
      updateForm(`${parentFieldName}.threshold`, undefined);
      updateForm(`${parentFieldName}.duration`, undefined);
      updateForm(`${parentFieldName}.filters`, undefined);
      updateForm(`${parentFieldName}.groupBy`, undefined);
    };
  }, [parentFieldName, updateForm]);

  return (
    <>
      <ConditionFieldsContainer>
        <Col span={24}>
          <Input
            name={`${parentFieldName}.eventType`}
            label={`${t('alertRules.eventType')} *`}
            validators={[requiredAll, validateCharacterLength(3, 200)]}
          />
        </Col>
      </ConditionFieldsContainer>

      <ConditionFieldsContainer gutter={[20, 20]}>
        <Col span={8}>
          <Select
            name={`${parentFieldName}.operator`}
            label={t('alertRules.operator')}
            isRequired={true}
            options={operatorOptions}
            isSearchEnabled={false}
          />
        </Col>

        <Col span={8}>
          <Input
            name={`${parentFieldName}.threshold`}
            label={t('alertRules.count')}
            validators={[requiredNumber]}
            type="number"
            min={0}
          />
        </Col>

        <Col span={8}>
          <Select
            name={`${parentFieldName}.duration`}
            label={t('alertRules.durationMinutes')}
            isRequired={true}
            options={durationOptions}
          />
        </Col>
      </ConditionFieldsContainer>

      <ConditionFieldsContainer>
        <Col span={24}>
          <Select
            name={`${parentFieldName}.groupBy`}
            mode="multiple"
            label={t('alertRules.groupBy')}
            hint={t('alertRules.groupByHint')}
            options={eventPropertyOptions}
          />
        </Col>
      </ConditionFieldsContainer>

      <ConditionFieldsContainer>
        <Col span={24}>
          <ArrayFields<AnalyticsEventFilter>
            propertyName={`${parentFieldName}.filters`}
            className="condition-filters-container"
            label={
              <>
                <FiltersHeading>{t('alertRules.filtersHeading')}</FiltersHeading>
                <FiltersSubHeading>{t('alertRules.filtersSubHeading')}</FiltersSubHeading>
              </>
            }
            defaultValue={{
              type: AnalyticsEventFilterTypeEnum.NUMERIC,
              operator: NumericOperatorEnum.EQUAL_TO,
              eventProperty: AnalyticsEventPropertyEnum.INT1,
              value: 0,
            }}
            addButtonLabel={t('alertRules.addFilterLabel')}
            itemRowContent={(parentPropertyName: string) => (
              <DeviceAnalyticsEventsCountConditionFilters
                parentFieldName={`${parentPropertyName}`}
                updateForm={updateForm}
              />
            )}
          />
        </Col>
      </ConditionFieldsContainer>
    </>
  );
};

export default DeviceAnalyticsEventsCountConditionFields;

const ConditionFieldsContainer = styled(Row)`
  margin-top: 15px;
  padding-left: 10px;
  padding-right: 10px;

  .condition-filters-container .array-fields-delete-btn-container {
    align-self: start;
    transform: translate(48px, 31px);
  }
`;

const FiltersHeading = styled.h5`
  font-weight: bold;
`;

const FiltersSubHeading = styled(Typography.Text)`
  color: rgba(0, 0, 0, 0.45);
  line-height: 3;
`;
