import { action, Action, Actions, select, Select, thunk, Thunk } from 'easy-peasy';
import { set } from 'lodash';
import Device from '../../types/device';
import Injections from '../../injections.interface';
import { ApiError } from '../../../services/api/api-error';
import transformDeviceExpectedAvailability from '../../../utils/transform-device-expected-availability';

export interface UniversalDeviceConnectParams {
  appId: string;
  deviceId: string;
  deviceType?: string;
}

export interface UniversalDeviceCreateParams {
  appId: string;
  appName: string;
  displayName: string;
  env: string;
  type: string;
  spaceId?: string;
  code?: string;
  deviceSerial?: string;
  extras?: {
    remoteId?: string;
  };
}

export interface AppV3DevicesUniversalModel {
  data: {
    [key: string]: {
      [id: string]: Device;
    };
  };
  lastUpdated: {
    [key: string]: Date;
  };
  loading: {
    [key: string]: boolean;
  };
  error: {
    [key: string]: ApiError | null;
  };
  values: Select<AppV3DevicesUniversalModel, (appName: string) => Device[]>;
  ids: Select<AppV3DevicesUniversalModel, (appName: string) => string[]>;
  loaded: Select<AppV3DevicesUniversalModel, (appName: string) => boolean>;
  setLoading: Action<AppV3DevicesUniversalModel, { key: string; loading: boolean }>;
  setError: Action<AppV3DevicesUniversalModel, { key: string; error: ApiError | null }>;
  setData: Action<AppV3DevicesUniversalModel, { key: string; data: Device[] }>;
  setSingle: Action<AppV3DevicesUniversalModel, { key: string; data: Device }>;
  setLastUpdated: Action<AppV3DevicesUniversalModel, { key: string; data: Date }>;
  fetch: Thunk<
    AppV3DevicesUniversalModel,
    { appName: string; silent?: boolean },
    Injections
  >;
  connect: Thunk<AppV3DevicesUniversalModel, UniversalDeviceConnectParams, Injections>;
  disconnect: Thunk<AppV3DevicesUniversalModel, UniversalDeviceConnectParams, Injections>;
  create: Thunk<AppV3DevicesUniversalModel, UniversalDeviceCreateParams, Injections>;
}

const fetchDevices = async (
  url: string,
  actions: Actions<AppV3DevicesUniversalModel>,
  { key, silent }: { key: string; silent: boolean },
  injections: Injections,
) => {
  if (!silent) {
    actions.setLoading({ key, loading: true });
  }
  actions.setError({ key, error: null });
  try {
    const data = await injections.apiService
      .get<Device[]>(url)
      .then((d) => d.map(transformDeviceExpectedAvailability));
    actions.setLastUpdated({ key, data: new Date() });
    actions.setData({ key, data });
  } catch (error) {
    actions.setError({ key, error });
  } finally {
    if (!silent) {
      actions.setLoading({ key, loading: false });
    }
  }
};

const appV3DevicesUniversalModel: AppV3DevicesUniversalModel = {
  data: {},
  loading: {},
  error: {},
  lastUpdated: {},
  loaded: select((state) => (appName: string) =>
    !!state.data[appName] && !state.loading[appName],
  ),
  values: select((state) => (appName: string) =>
    Object.values(state.data[appName] || {}).sort(
      (a, b) => a.status.localeCompare(a.status) || a.deviceName.localeCompare(b.deviceName),
    ),
  ),
  ids: select((state) => (appName: string) => Object.keys(state.data[appName] || {})),
  setLoading: action((state, { key, loading }) => {
    state.loading[key] = loading;
  }),
  setError: action((state, { key, error }) => {
    state.error[key] = error;
  }),
  setData: action((state, { key, data }) => {
    state.data[key] = {};
    data.forEach((item) => {
      state.data[key][item.id] = item;
    });
  }),
  setSingle: action((state, { key, data }) => {
    set(state.data, [key, data.id], data);
  }),
  setLastUpdated: action((state, { key, data }) => {
    state.lastUpdated[key] = data;
  }),
  fetch: thunk(async (actions, { appName, silent = false }, { injections }) => {
    const url = `/api/v3/devices?appName=${appName}`;

    await fetchDevices(url, actions, { key: appName, silent }, injections);
  }),
  connect: thunk(async (actions, { deviceId, appId, deviceType }, { injections }) => {
    await injections.apiService.post<void>(
      `/api/v3/devices/${deviceId}/connect/${appId}`,
      {
        deviceType,
      },
    );
  }),
  disconnect: thunk(async (actions, { deviceId, appId, deviceType }, { injections }) => {
    await injections.apiService.post<void>(
      `/api/v3/devices/${deviceId}/disconnect/${appId}`,
      {
        deviceType,
      },
    );
  }),
  create: thunk(async (actions, params, { injections }) => {
    const data = await injections.apiService.post<Device>(`/api/v3/devices`, {
      ...params,
      ...(params.spaceId && { spaces: [params.spaceId] }),
    });
    actions.setSingle({ key: params.appName, data });
    return data.id;
  }),
};

export default appV3DevicesUniversalModel;
