import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { OptionProps } from 'antd/lib/select';

const { Option } = Select;

const SelectComponent = styled(Select)`
  :nth-of-type(1) {
    margin-right: 10px;
  }
  .ant-select-selection__rendered {
    width: 100px;
    text-transform: capitalize;
  }
`;
const Title = styled.div`
  font-size: 16px;
  margin-right: 8px;
`;

const TitleWithSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

type TitleWithSelectProps = {
  title: string;
  items: {
    key: string;
    label: string;
  }[];
  onSelect: (key: string | undefined) => void;
};

export const TitleWithSelect: React.FC<TitleWithSelectProps> = ({ title, items, onSelect }) => {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (value: unknown) => {
      const { key } = value as { key: string | undefined };
      onSelect(key);
    },
    [onSelect],
  );

  const handleFilterOption = useCallback(
    (inputValue: string, option: React.ReactElement<OptionProps>): boolean => {
      return typeof option.props.children === 'string'
        ? option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        : false;
    },
    [],
  );

  return (
    <TitleWithSelectWrapper>
      <Title>{title}</Title>

      <SelectComponent
        defaultValue={{ key: 'all', label: t('all') }}
        onChange={handleChange}
        labelInValue
        showSearch
        filterOption={handleFilterOption}
      >
        <Option value="all">{t('all')}</Option>
        {items.map((item) => (
          <Option
            key={item.key}
            value={item.key}
            style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}
          >
            {item.label}
          </Option>
        ))}
      </SelectComponent>
    </TitleWithSelectWrapper>
  );
};
