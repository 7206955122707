import { action, Action, thunk, Thunk } from 'easy-peasy';
import Injections from '../../injections.interface';

export interface ProvidersModel {
  data: {
    [release: string]: string[];
  };
  reset: Action<ProvidersModel>;
  setData: Action<ProvidersModel, { release: string; data: string[] }>;
  fetch: Thunk<ProvidersModel, { release: string }, Injections>;
}

const providersModel: ProvidersModel = {
  data: {},
  reset: action((state) => {
    state.data = {};
  }),
  setData: action((state, { release, data }) => {
    state.data[release] = data;
  }),
  fetch: thunk(async (actions, { release }, { injections }) => {
    actions.reset();
    if (release) {
      const data = await injections.apiService.get<string[]>('/api/schemas/providers', {
        release,
      });
      actions.setData({ release, data });
    }
  }),
};

export default providersModel;
