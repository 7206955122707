import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Select as AntdSelect } from 'antd';
import { SelectType } from '../../../../../types';
import Environment from '../../../../../store/types/environment';

interface ProductEnvironmentSelectProps {
  label?: string;
  value?: string;
  options: Environment[];
  isDisabled?: boolean;
  onChange?: (value: string) => void;
  dataTestId?: string;
}

const ProductEnvironmentSelect = (props: ProductEnvironmentSelectProps) => {
  const { label, value, options, isDisabled, onChange = () => {}, dataTestId } = props;

  const { t } = useTranslation();

  const environmentOptions = options.map(({ environmentName, displayName }) => ({ value: environmentName, label: displayName }));

  return (
    <ProductEnvironmentSelectContainer>
      {label && <LabelWrap>{label}</LabelWrap>}
      <SelectStyled
        value={value}
        showSearch={!isDisabled}
        showArrow={!isDisabled}
        placeholder={t('selectValue')}
        disabled={isDisabled}
        onChange={onChange}
        data-testid={dataTestId}
      >
        {environmentOptions.map((option) => {
          const { value, label } = option;

          return (
            <AntdSelect.Option key={value} value={value} data-label={label}>
              {label || value}
            </AntdSelect.Option>
          );
        })}
      </SelectStyled>
    </ProductEnvironmentSelectContainer>
  );
};

const ProductEnvironmentSelectContainer = styled.div`
  flex-basis: min-content;
`;

const SelectStyled = styled(AntdSelect)`
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;

  min-width: 200px;

  .ant-select-selection {
    height: 32px;
  }
  .ant-select-selection-selected-value {
    height: 32px;

    div:first-of-type {
      margin: 0px;
    }
  }
` as SelectType<string>;

const LabelWrap = styled.div`
  display: flex;
  width: auto;
`;

export default ProductEnvironmentSelect;
