import React from 'react';
import styled from '@emotion/styled';
import { Field } from 'react-final-form';
import { Select, Row, Col, Typography, Icon } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useTranslation } from 'react-i18next';
import {
  ArrayFields,
  composeValidators,
  validateCharacterLength,
  validateMaxLength,
} from '../../../common/react-final-form';
import { PlanFormFeature } from './plan-form.component';
import { requiredAll } from '../../../organisations/organisation-details/queue-details/queue-setup/validate-form';
import { FormTextInput } from '../../../organisations/organisation-details/queue-details/queue-setup/styled-block';
import { InputWrap } from '../../../common/react-final-form';
import { ChangeEvent } from '../../../../types';
import AntIcons from './ant-icons';

const PlanFormFeaturesSection = () => {
  const { t } = useTranslation();

  return (
    <PlanFeaturesContainer>
      <Typography.Title level={4}>Features</Typography.Title>
      <ArrayFields<PlanFormFeature>
        propertyName="features"
        defaultValue={{
          name: { en: '' },
          description: { en: '' },
          icon: '',
        }}
        itemRowContent={(parentPropertyName: string) => (
          <FeaturesContainer gutter={[20, 20]}>
            <Col span={8}>
              <Field
                name={`${parentPropertyName}.name.en`}
                validate={composeValidators([
                  requiredAll,
                  validateCharacterLength(3, 200),
                ])}
                render={({ input, meta }) => (
                  <FormTextInput
                    required={true}
                    input={input}
                    meta={meta}
                    label={t('plans.form.name')}
                  />
                )}
              />
            </Col>

            <Col span={10}>
              <Field
                name={`${parentPropertyName}.description.en`}
                validate={composeValidators([requiredAll, validateMaxLength(500)])}
                render={({ input, meta }) => (
                  <InputWrap
                    label={t('plans.form.description')}
                    isRequired={true}
                    error={meta.error && meta.touched ? meta.error : null}
                  >
                    <TextArea
                      rows={3}
                      defaultValue={input.value}
                      onChange={({ target: { value } }) => {
                        input.onChange({
                          target: {
                            value,
                          },
                        } as unknown as ChangeEvent<string>);
                      }}
                    />
                  </InputWrap>
                )}
              />
            </Col>

            <Col span={6}>
              <Field
                name={`${parentPropertyName}.icon`}
                validate={requiredAll}
                render={({ input, meta }) => (
                  <InputWrap
                    label={t('plans.form.icon')}
                    isRequired={true}
                    error={meta.touched && meta.error ? meta.error : null}
                  >
                    <Select
                      defaultValue={input.value ? input.value : undefined}
                      placeholder={'Please select'}
                      showSearch={true}
                      allowClear={true}
                      onChange={(value) => {
                        input.onChange({
                          target: {
                            value,
                          },
                        } as unknown as ChangeEvent<string>);
                      }}
                    >
                      {AntIcons.map((antIcon) => (
                        <Select.Option key={antIcon} value={antIcon}>
                          <IconContainer>
                            <Icon type={antIcon} />
                            <span>{antIcon}</span>
                          </IconContainer>
                        </Select.Option>
                      ))}
                    </Select>
                  </InputWrap>
                )}
              />
            </Col>
          </FeaturesContainer>
        )}
      />
    </PlanFeaturesContainer>
  );
};

export default PlanFormFeaturesSection;

const PlanFeaturesContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const FeaturesContainer = styled(Row)`
  width: 100% !important;
  display: flex;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
`;

const IconContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
