import React, { useCallback, useMemo } from 'react';
import Media from '../../../../../store/types/media';
import { BreadcrumbProps } from 'antd/lib/breadcrumb';
import { Breadcrumb, Button } from 'antd';

interface ContentLibraryFolderHierarchyProps {
  tenantId: string;
  ancestors: Media[];
  onFolderHierarchyClick: (folderId: string) => void;
}

const ContentLibraryFolderHierarchy = (props: ContentLibraryFolderHierarchyProps) => {
  const { ancestors, onFolderHierarchyClick } = props;

  const breadCrumbs = useMemo<BreadcrumbProps['routes']>(() => {
    const base = [
      {
        path: ``,
        breadcrumbName: 'Home',
      },
    ];

    const paths = ancestors.map((ancestor) => {
      return {
        path: `${ancestor.id}`,
        breadcrumbName: ancestor.name,
      };
    });

    return [...base, ...paths];
  }, [ancestors]);

  const lastPath = useMemo(() => {
    return ancestors && ancestors.length ? ancestors[ancestors.length - 1].id : '';
  }, [ancestors]);

  const handleClick = useCallback(
    (folderId: string) => {
      onFolderHierarchyClick(folderId);
    },
    [onFolderHierarchyClick],
  );

  return (
    <Breadcrumb
      routes={breadCrumbs}
      itemRender={(route, params, paths) => {
        return (
          <>
            {route.path === lastPath && <span>{route.breadcrumbName}</span>}
            {route.path !== lastPath && (
              <Button type="link" onClick={() => handleClick(route.path)}>
                {route.breadcrumbName}
              </Button>
            )}
          </>
        );
      }}
    />
  );
};

export default ContentLibraryFolderHierarchy;
