import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useEnvironmentsV1 } from '../../../../../common/use-environments';
import PanelCard from '../../../../../common/panel-card/panel-card.component';
import PanelHeader from '.././panel-header';
import Loader from '../../../../../common/loader/loader-component';
import { ApiError } from '../../../../../../services/api/api-error';
import DeploymentStatusTable from './deployment-status-table';
import { UploadCloud } from 'react-feather';
import Build from '../../../../../../store/types/build';

interface BuildsDeploymentsProps {
  organisationId: string;
  isLoading: boolean;
  isSuccess: boolean;
  error: ApiError;
  buildData: Build;
}

const BuildsDeployments: React.FC<BuildsDeploymentsProps> = ({
  organisationId,
  isLoading,
  isSuccess,
  error,
  buildData,
}) => {
  const { t } = useTranslation();

  const environments = useEnvironmentsV1({ tenantId: organisationId });

  return (
    <DeploymentsPanelCard
      extra={
        <PanelHeader
          icon={<UploadCloud size={20} />}
          title={t('builds.deployments')}
          subHeader={t('builds.deployBuildEnv')}
        />
      }
      bodyStyle={{
        padding: 0,
      }}
    >
      <Loader loading={isLoading} error={error} errorFillPage>
        {isSuccess && (
          <DeploymentStatusTable
            build={buildData}
            environments={environments}
            organisationId={organisationId}
          />
        )}
      </Loader>
    </DeploymentsPanelCard>
  );
};

export default BuildsDeployments;

const DeploymentsPanelCard = styled(PanelCard)`
  & .ant-card-extra {
    margin-left: 0;
  }
  height: auto;
  .ant-card-body {
    padding: 0;
    min-height: 80px;
    position: relative;
  }
`;
