import { useMutation, UseMutationResult } from 'react-query';
import { ApiError } from '../../../../../../services/api/api-error';
import { IntegrationFormValues, IntegrationSettings } from '../types';
import { useGridProductsFeedService } from '../../../../../common/grid-products-connector-service-provider';
import { DataResidencyEnum } from '../../../../../../store/types/organisation';

interface UseAddIntegrationProps {
  tenantId: string;
  dataResidency: DataResidencyEnum;
}

export interface AddIntegrationVariables extends Omit<IntegrationFormValues, 'schedule'> {
  schedule: string[];
}

type UseAddIntegrationResponse = UseMutationResult<
  IntegrationSettings,
  ApiError,
  AddIntegrationVariables
>;

const useAddIntegration = (props: UseAddIntegrationProps): UseAddIntegrationResponse => {
  const { tenantId, dataResidency } = props;

  const gridProductsFeedService = useGridProductsFeedService(dataResidency);

  const result = useMutation(
    async (body: AddIntegrationVariables) => {
      const url = `/${tenantId}/integrations`;

      return gridProductsFeedService.post<IntegrationSettings>(url, {
        ...body,
        dataResidency,
      });
    },
    {
      onError: (_error: ApiError) => {},
    },
  );

  return result;
};

export default useAddIntegration;
