import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Tag } from 'antd';
import InstallationGroup from '../../../../store/types/installation-group';
import CrudModal from '../../crud-list/crud-modal/crud-modal.component';
import {
  MarketplaceSolutionApp,
} from '../../../../store/types/marketplace-solution';
import Gridapp from '../../../../store/types/gridapp';
import { getFormattedPricingFromGridapp } from '../../../../utils/apps-library/gridapp-price';
import DropDownWithCreate from '../../../common/schema-form/internal-widgets/dropdown-with-create/dropdown-with-create.component';

interface AppsLibraryInstallModalProps {
  app: Gridapp | MarketplaceSolutionApp | null;
  onClose: () => void;
  installationGroups: InstallationGroup[];
  createInstallationGroup: (params: Partial<InstallationGroup>) => Promise<string | undefined>;
  onSubmit: any;
}

const modalFormInitialValues = {};

const AppsLibraryInstallModal = ({
  onClose,
  app,
  installationGroups,
  onSubmit,
  createInstallationGroup,
}: AppsLibraryInstallModalProps) => {
  const { t } = useTranslation();

  const appsLibraryInstallAppSchema = {
    type: 'object',
    properties: {
      displayName: {
        type: 'string',
        title: t('name'),
        minLength: 3,
        maxLength: 50,
        pattern: `^[a-zA-Z][a-zA-Z0-9_&-]+(?: +[a-zA-Z0-9_&-]+)*$`,
      },
      installationGroupId: {
        type: 'string',
        title: t('addToGroup'),
        enum: ['', ...installationGroups.map((group) => group.id)],
        enumNames: [
          t('myInstallationsDefault'),
          ...installationGroups.map((group) => group.displayName),
        ],
        default: '',
      },
    },
    required: ['displayName'],
  };

  const appsLibraryInstallAppMetaSchema = {
    installationGroupId: {
      'ui:field': (fromProps: any) => (
        <DropDownWithCreate {...fromProps} onCreate={createInstallationGroup} />
      ),
    },
  };

  const title = useMemo(() => {
    if (!app) {
      return '';
    }

    const pricingFormatted = getFormattedPricingFromGridapp(app);

    return (
      <>
        {`${app.displayName} ${t('setup')}`}
        {app.sku || pricingFormatted ? (
          <AppMarketplaceInfo>
            {app.sku ? <AppMarketplaceProp>{`${t('sku')}: ${app.sku}`}</AppMarketplaceProp> : null }
            {pricingFormatted ? <AppMarketplaceProp>{`${t('price')}: ${pricingFormatted}`}</AppMarketplaceProp> : null }
          </AppMarketplaceInfo>
        ) : null}
      </>
    );
  }, [app, t]);

  return (
    <CrudModal
      title={title}
      schema={appsLibraryInstallAppSchema}
      metaSchema={appsLibraryInstallAppMetaSchema}
      initialValues={modalFormInitialValues}
      onSubmit={onSubmit}
      visible={!!app}
      onClose={onClose}
      contentScrollable={false}
    />
  );
};

const AppTypeTag = styled(Tag)`
  padding: 0px 8px;
  margin-top: 5px;
  font-size: 10px;
  line-height: 17px;
`;

const AppMarketplaceInfo = styled.div`
  padding-top: 4px;
  flex-direction: row;
`;

const AppMarketplaceProp = styled(AppTypeTag)`
  color: #000000;
  background: transparent
`;


export default AppsLibraryInstallModal;
