import React, { useMemo } from 'react';
import {
  WeekHeatmapProps,
  WeekHeatmapSessionsProps,
  WeekHeatmapEventsProps,
} from './types';
import CardContainer from '../card-container';
import WeekHeatmap from '../../charts/week-heatmap';
import {
  useTenantSessionsByHour,
  useTenantEventsByHour,
} from '../../../use-analytics-report';
import { buildSessionsData, buildEventsData } from './generate';
import { WeekHeatmapDataSourceType } from '@ombori/grid-reports';

const TenantWeekHeatmapBySessions: React.FC<WeekHeatmapSessionsProps> = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  title,
  dataSource,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const tenantSessionsState = useTenantSessionsByHour({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
    interactionType: dataSource.interactionType,
  });

  const data = useMemo(() => buildSessionsData(tenantSessionsState.data || []), [
    tenantSessionsState,
  ]);

  return (
    <CardContainer
      isLoading={tenantSessionsState.isLoading}
      isSuccess={tenantSessionsState.isSuccess}
      isError={tenantSessionsState.isError}
      hasData={tenantSessionsState.isSuccess && tenantSessionsState.data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      <WeekHeatmap data={data} />
    </CardContainer>
  );
};

const TenantWeekHeatmapByEvents: React.FC<WeekHeatmapEventsProps> = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  title,
  dataSource,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const tenantEventsState = useTenantEventsByHour({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const data = useMemo(
    () => buildEventsData(tenantEventsState.data || [], dataSource.eventType),
    [tenantEventsState, dataSource],
  );

  return (
    <CardContainer
      isLoading={tenantEventsState.isLoading}
      isSuccess={tenantEventsState.isSuccess}
      isError={tenantEventsState.isError}
      hasData={tenantEventsState.isSuccess && tenantEventsState.data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      <WeekHeatmap data={data} />
    </CardContainer>
  );
};

const TenantWeekHeatmap: React.FC<WeekHeatmapProps> = ({
  tenantId,
  dateFrom,
  dateTo,
  title,
  dataResidency,
  dataSource,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const view = useMemo(() => {
    switch (dataSource.type) {
      case WeekHeatmapDataSourceType.Sessions:
        return (
          <TenantWeekHeatmapBySessions
            tenantId={tenantId}
            dateFrom={dateFrom}
            dateTo={dateTo}
            title={title}
            dataResidency={dataResidency}
            dataSource={dataSource}
            gridStyles={gridStyles}
            isVisibleWithoutData={isVisibleWithoutData}
          />
        );
      case WeekHeatmapDataSourceType.Events:
        return (
          <TenantWeekHeatmapByEvents
            tenantId={tenantId}
            dateFrom={dateFrom}
            dateTo={dateTo}
            title={title}
            dataResidency={dataResidency}
            dataSource={dataSource}
            gridStyles={gridStyles}
            isVisibleWithoutData={isVisibleWithoutData}
          />
        );
      default:
        throw new Error(`Unknown data source: ${JSON.stringify(dataSource)}`);
    }
  }, [
    tenantId,
    dateFrom,
    dateTo,
    title,
    dataResidency,
    dataSource,
    gridStyles,
    isVisibleWithoutData,
  ]);

  return <>{view}</>;
};

export default TenantWeekHeatmap;
