const gridAppsQueryKeys = {
  getGridapps: (organizationId: string) => {
    return [
      'gridapps',
      organizationId,
    ];
  },
};

export default gridAppsQueryKeys;
