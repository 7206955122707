import { TreeNodeNormal } from 'antd/lib/tree-select/interface';

export interface TreeDataItem {
  isRoot: boolean;
  parentId: string;
  id: string;
  title: React.ReactNode;
  children?: TreeDataItem[];
}

export const transformToTreeData = (categoryData: TreeDataItem[]): TreeNodeNormal[] => {
  const map: { [key: string]: TreeDataItem } = {};

  categoryData.forEach(category => {
    const item = { ...category, children: [] };
    map[item.id] = item;
  });

  const treeData: TreeDataItem[] = [];

  for (let id in map) {
    const item = map[id];

    if (item.isRoot) {
      treeData.push(item);
    } else if (item.parentId in map) {
      const parent = map[item.parentId];

      if (parent.children) {
        parent.children.push(item);
      }
    }
  }

  return treeData.map(transformNode);
};

const transformNode = (item: TreeDataItem): TreeNodeNormal => {
  return {
    title: item.title,
    value: item.id,
    key: item.id,
    children: item.children ? item.children.map(transformNode) : [],
  };
};
