import styled from '@emotion/styled';

const Controls = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  padding-bottom: 24px;
  width: 100%;

  @media (min-width: 1280px) {
    width: calc(75% - ${24 / 4}px);
  }

  @media (min-width: 1920px) {
    width: calc(50% - ${24 / 2}px);
  }
`;

export default Controls;
