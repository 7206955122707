import moment from 'moment/moment';

interface FormatDateOptions {
  dateToFormat?: string | Date;
  format?: string;
  undefinedDateLabel?: string;
  includeTimeZone?: boolean;
}

export const formatDate = ({
                             dateToFormat,
                             format = 'DD/MM/YYYY',
                             undefinedDateLabel = '',
                             includeTimeZone = false,
                           }: FormatDateOptions) => {
  if (dateToFormat === undefined || !moment(dateToFormat).isValid()) {
    return undefinedDateLabel;
  }
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const convertedDate = moment.tz(dateToFormat, userTimezone).format(format);
  return convertedDate + (includeTimeZone ? ` (${userTimezone})` : '');
};

const CREATED_AT_RETURN_DATE_FORMAT = 'YYYY-MM-DD hh:mm A';
const CREATED_AT_PARSE_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

// could be used as a standard format method to format createdAt in the entire Console, that's why it's not accepting
// any date format to make sure it always return the date in a specific format
export const formatCreatedAt = (dateStr: string) : string  => {
  const date = moment(dateStr, CREATED_AT_PARSE_DATE_FORMAT);
  if (!date.isValid()) {
    return 'Invalid date';
  }

  return date.format(CREATED_AT_RETURN_DATE_FORMAT);
}

export const formatUpdatedAt = (dateStr: string) : string => {
  return formatCreatedAt(dateStr);
}
