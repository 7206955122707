import { BillingCurrencyEnum, DataResidencyEnum } from './organisation';
import Plan from './organisation-plan';
import CurrencyAwareValue from './currency-aware-value';
import Address from './address';

export enum EnterpriseAgreementStatusEnum {
  DRAFT = 'draft',
  INVITE_SENT = 'invite_sent',
  PENDING = 'pending',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface EnterpriseAgreement {
  id: string;
  name: string;
  durationMonths: number;
  // We'll allow string, bec of date format limitation in json schema
  startDate?: Date | string;
  endDate?: Date | string; // retain for display
  plans: {
    tenantPlanId: Plan;
    supportPlanId: Plan;
  };
  organizationId?: string;
  organizationConfiguration?: {
    tenantName?: string;
    country?: string;
    currency?: BillingCurrencyEnum;
    dataResidency?: DataResidencyEnum;
    vatId?: string;
  };
  tenantAdminUser: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  };
  discountPercent?: number;
  committedConsumption?: CurrencyAwareValue;
  extraCredits?: CurrencyAwareValue;
  extraCreditsValidDurationMonths?: number;
  billingAddress: Address;
  status: EnterpriseAgreementStatusEnum;
  customMessages?: {
    signUpLandingMessage?: string;
    signUpCallToActionMessage?: string;
    emailInviteMessage?: string;
  };
  createdBy: {
    email: string;
    id: string;
  };
  createdAt: Date;
}

export interface Filters {
  status: {
    [id: string]: {
      total: {
        [currency: string]: { count: number; sum: number };
      };
    };
  };
  createdBy: {
    [id: string]: {
      email: string;
      total: {
        [currency: string]: { count: number; sum: number };
      };
    };
  };
}
