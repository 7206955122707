import React from 'react';
import { FieldProps } from 'react-jsonschema-form';
import { useStore } from 'easy-peasy';
import InstallationPickerComponent from './installation-picker.component';
import { RootState } from '../../../../../store/models/root.model';
import DeviceTypeEnum from '../../../../../store/types/device-type.enum';

const ScreenInstallationPickerContainer = (props: FieldProps) => {
  const {
    formContext: { organisationId, appId },
  } = props;

  const { installations } = useStore<RootState>((state: RootState) => ({
    installations: (
      (state.organisationApps.values && state.organisationApps.values(organisationId)) ||
      []
    ).filter(
      (installation) =>
        installation.id !== appId && installation.deviceType === DeviceTypeEnum.WPA,
    ),
  }));

  return <InstallationPickerComponent installations={installations} {...props} />;
};

export default ScreenInstallationPickerContainer;
