import { Action, action, thunk, Thunk } from 'easy-peasy';
import { RootModel } from '../root.model';
import Injections from '../../injections.interface';

export interface AppModel {
  isReady: boolean;
  setIsReady: Action<AppModel, boolean>;
  init: Thunk<AppModel, void, Injections, RootModel>;
}

const appModel: AppModel = {
  isReady: false,
  setIsReady: action((state) => {
    state.isReady = true;
  }),
  init: thunk(async (actions, _, { injections, dispatch }) => {
    try {
      await Promise.all([dispatch.auth.getCurrentUser(), dispatch.organisations.fetch()]);
      dispatch.appBuilds.initUpdateListener();
    } catch (e) {
      // auth failed
    }
    injections.apiService.setInterceptors();
    injections.gridDataService.setInterceptors();
    injections.gridProductService.setInterceptors();
    injections.gridRndAI.setInterceptors();
    actions.setIsReady(true);
  }),
};

export default appModel;
