import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button as AntdButton, Icon as AntdIcon, Tooltip, Typography } from 'antd';
import styled, { StyledComponent } from '@emotion/styled';
import { css } from '@emotion/core';
import { FieldProps } from 'react-jsonschema-form';
import { ButtonProps } from 'antd/lib/button';
import { HelpCircle } from 'react-feather';
import trim from 'lodash/trim';
import { Edit } from 'react-feather';
import { ColorSet, getColorSet } from '../color-picker';
import { generateButtonCss } from './utils';
import PropertySettings from './settings/property-settings.component';
import { Container, Text } from '../../common';

const { Title: AntdTitle } = Typography;

const DEFAULT_BACKGROUND_COLOR = '#000000';
const DEFAULT_TEXT_COLOR = '#FFFFFF';

interface ButtonPickerPropsUiOptions {
  isBordered?: boolean;
  previewButtonIcon?: string;
  previewButtonTitle?: string;
  editButton?: {
    text?: string;
    icon?: string;
  };
}

interface DefaultValue {
  backgroundColor: string;
  textColor: string;
}

interface FormData extends DefaultValue {
  cssPartial: string;
  cssFull: string;
}

interface ButtonPickerProps extends FieldProps<FormData> {
  rawErrors?: string[];
}

const ButtonPicker = (props: ButtonPickerProps) => {
  const {
    rawErrors = [],
    formData,
    onChange,
    schema,
    uiSchema,
    required,
    readonly,
  } = props;

  const {
    isBordered = true,
    previewButtonIcon,
    previewButtonTitle,
    editButton,
  } = (uiSchema['ui:options'] || {}) as ButtonPickerPropsUiOptions;
  const helpText = uiSchema['ui:help'] as string;
  const {
    backgroundColor: defaultBackgroundColor,
    textColor: defaultTextColor,
  } = (schema.default || {}) as unknown as DefaultValue;

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const { t } = useTranslation();

  const { initialBackgroundColor, initialTextColor } = useMemo<{
    initialBackgroundColor: ColorSet;
    initialTextColor: ColorSet;
  }>(() => {
    return {
      initialBackgroundColor: getColorSet(
        defaultBackgroundColor,
        DEFAULT_BACKGROUND_COLOR,
      ),
      initialTextColor: getColorSet(defaultTextColor, DEFAULT_TEXT_COLOR),
    };
  }, [defaultBackgroundColor, defaultTextColor]);

  const [backgroundColor, setBackgroundColor] = useState<ColorSet>(
    initialBackgroundColor,
  );
  const [textColor, setTextColor] = useState<ColorSet>(initialTextColor);
  const [cssPartial, setCssPartial] = useState<string>('');
  const [previewCss, setPreviewCss] = useState<string>('');

  const editButtonIcon = editButton && editButton.icon;
  const editButtonText = (editButton && editButton.text) || t('buttonPicker.edit');

  useEffect(() => {
    setBackgroundColor((prevState) =>
    formData && formData.backgroundColor
        ? getColorSet(formData.backgroundColor, DEFAULT_BACKGROUND_COLOR)
        : prevState,
    );
    setTextColor((prevState) =>
    formData && formData.textColor
        ? getColorSet(formData.textColor, DEFAULT_TEXT_COLOR)
        : prevState,
    );

    setCssPartial((prevState) => (formData && formData.cssPartial ? formData.cssPartial : prevState));
  }, [formData, onChange]);

  useEffect(() => {
    const previewCssValue = generateButtonCss({
      backgroundColor: backgroundColor.value,
      textColor: textColor.value,
      css: cssPartial,
    });
    setPreviewCss(previewCssValue);
  }, [backgroundColor, cssPartial, formData, textColor]);

  const errors = useMemo(() => {
    return rawErrors
      .map((err) => trim(err, '.'))
      .join(',')
      .toLowerCase();
  }, [rawErrors]);

  const handleEdit = useCallback(() => setIsEdit((prevState) => !prevState), []);

  const handleClose = useCallback(() => {
    setIsEdit((prevState) => !prevState);
  }, []);

  const handleChange = useCallback(
    ({
      backgroundColor,
      textColor,
      cssValue,
    }: {
      backgroundColor: ColorSet;
      textColor: ColorSet;
      cssValue: string;
    }) => {
      onChange({
        ...(formData || {}),
        backgroundColor: backgroundColor.value,
        textColor: textColor.value,
        cssPartial: cssValue.replace(/  +/g, ' '),
        cssFull: generateButtonCss({
          backgroundColor: backgroundColor.value,
          textColor: textColor.value,
          css: cssValue,
        }).replace(/  +/g, ' '),
      });
    },
    [formData, onChange],
  );

  return (
    <Container $isBordered={isBordered} $isDisabled={readonly}>
      <SectionPadded>
        <TitleWrap>
          {schema.title && <Title>{schema.title}{required ? ' *' : ''}</Title>}
          {helpText && (
            <div>
              <Tooltip
                placement="left"
                title={<div dangerouslySetInnerHTML={{ __html: helpText }} />}
              >
                <Icon size={18} component={() => <HelpCircle />} />
              </Tooltip>
            </div>
          )}
        </TitleWrap>
        {schema.description && (
          <Description dangerouslySetInnerHTML={{ __html: schema.description }} />
        )}
        {errors && <ErrorAlert message={errors} type="error" showIcon />}
        <Preview onClick={handleEdit}>
          <PreviewText type="secondary" size={12} weight={300}>{t('buttonPicker.preview')}</PreviewText>
          <SampleButton
            type="button"
            css={css(previewCss)}
          >
            {previewButtonIcon && <Icon size={20} type={previewButtonIcon} />}
            <span>{previewButtonTitle || t('buttonPicker.previewButtonTitle')}</span>
          </SampleButton>
        </Preview>
        {!isEdit && !readonly && (
          <EditButton type="default" onClick={handleEdit}>
            {editButtonIcon ? (
              <Icon size={18} type={editButtonIcon} />
            ) : (
              <Icon size={18} component={() => <Edit />} />
            )}
            {editButtonText}
          </EditButton>
        )}
      </SectionPadded>
      {isEdit && (
        <PropertySettings
          initialBackgroundColor={backgroundColor}
          initialTextColor={textColor}
          initialCss={cssPartial}
          onChange={handleChange}
          onClose={handleClose}
          isReadonly={readonly}
        />
      )}
    </Container>
  );
};

const Title = styled(AntdTitle)`
  font-size: 16px !important;
`;

const SectionPadded = styled.div`
  padding: 10px;
`;

const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;

  .ant-typography {
    margin-bottom: 0;
  }
`;

const ErrorAlert = styled(Alert)`
  margin: 8px 10px;

  .ant-alert-message {
    display: inline-block;
  }
  .ant-alert-message::first-letter {
    text-transform: uppercase;
  }
`;

const Preview = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: rgb(242, 242, 247);
  border-radius: 4px;
  width: -webkit-fill-available;
  padding: 10px 25px;
  margin-top: 10px;
  gap: 10px;
  cursor: pointer;
  padding: 16px 24px 24px;
`;

const SampleButton = styled.button`
  width: fit-content;
  margin: auto !important;
`;

const Description = styled.div`
  padding: 0;
`;

type StyledButtonType = StyledComponent<
  ButtonProps & React.ClassAttributes<AntdButton>,
  Pick<ButtonProps & React.ClassAttributes<AntdButton>, keyof ButtonProps>,
  any
>;
const Button = styled(AntdButton)`
  display: flex;
  align-items: center;
  box-shadow: unset;
  border: unset;
  background-color: unset;
  padding: 0 5px;

  :hover {
    background-color: rgb(0 100 82 / 8%);
  }
` as StyledButtonType;

const EditButton = styled(Button)`
  margin-top: 10px;
`;

const Icon = styled(AntdIcon)<{ size?: number; color?: string }>`
  svg {
    height: ${({ size = 16 }) => `${size}px`};
    width: ${({ size = 16 }) => `${size}px`};
  }

  &.anticon + span {
    margin-left: 5px;
  }

  ${({ color }) => (color ? `color: ${color};` : '')}
`;

const PreviewText = styled(Text)`
  margin-right: auto;
`;

export default ButtonPicker;
