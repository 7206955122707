import { OrganizationUsersListQueryInterface } from './use-organization-users-list';

const organizationUsersQueryKeys = {
  queryPrefix: () => {
    return 'organization-users';
  },
  getOrganizationUsersListKeys: (OrganizationUserListParams: OrganizationUsersListQueryInterface) => [
    organizationUsersQueryKeys.queryPrefix(),
    'page',
    OrganizationUserListParams.page,
    'limit',
    OrganizationUserListParams.limit,
    'sortField',
    OrganizationUserListParams.sortField,
    'sortOrder',
    OrganizationUserListParams.sortOrder,
    'searchTerm',
    OrganizationUserListParams.searchTerm,
    'organizationId',
    OrganizationUserListParams.organizationId
  ],
  getOrganizationUserById: (userId: string, organizationId: string) => [
    'user-by-id',
    'userId',
    userId,
    'organizationId',
    organizationId
  ],
};

export default organizationUsersQueryKeys;
