import React, { useState, useCallback } from 'react';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { Edit } from 'react-feather';
import { NetworkWhitelistRule } from '../../../../common/use-network-whitelist-rules';
import { Icon } from '../../../../common/schema-form/common';
import NetworkWhitelistRuleForm, { NetworkWhitelistRuleFormFields } from './networking-whitelist-rule-form.component';
import { useUpdateNetworkWhitelistRule, NetworkWhitelistRuleUpdatePayload } from '../../../../common/use-network-whitelist-rules';

interface NetworkWhitelistRuleEditProps {
  tenantId: string;
  networkWhitelistRule: NetworkWhitelistRule;
}

const NetworkWhitelistRuleEdit = (props: NetworkWhitelistRuleEditProps) => {
  const { tenantId, networkWhitelistRule } = props;

  const { t } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const { mutateAsync: updateWhitelistRule } = useUpdateNetworkWhitelistRule();

  const handleFormSubmit = useCallback(async (values: NetworkWhitelistRuleFormFields | Object) => {
    const { id, env, hostname } = values as NetworkWhitelistRuleFormFields;

    try {
      const updatePayload: NetworkWhitelistRuleUpdatePayload = {
        id: id!,
        env,
        hostname,
        tenantId,
      };
      await updateWhitelistRule(updatePayload);
      setIsModalVisible(false);
      message.success(t('networking.networkWhitelistRuleSaveSuccess'));
    } catch(err) {
      message.error(t('networking.networkWhitelistRuleSaveFailure'));
    }
  }, [updateWhitelistRule, tenantId, t]);

  return (
    <>
      <Button
        type="link"
        onClick={() => {
          setIsModalVisible(true);
        }}
      >
        <Icon component={() => <Edit />} />
      </Button>

      <NetworkWhitelistRuleForm
        tenantId={tenantId}
        isModalVisible={isModalVisible}
        updateIsModalVisibile={setIsModalVisible}
        initialValues={{
          id: networkWhitelistRule.id,
          env: networkWhitelistRule.env,
          hostname: networkWhitelistRule.hostname,
        }}
        handleFormSubmit={handleFormSubmit}
      />
    </>
  )
};

export default NetworkWhitelistRuleEdit;
