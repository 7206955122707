import React, { useCallback, useState } from 'react';
import { Button, Col, Row, message, Dropdown, Menu } from 'antd';
import styled from '@emotion/styled';
import { ListProps } from 'antd/lib/list';
import { useTranslation } from 'react-i18next';
import { FormValidation } from 'react-jsonschema-form';
import CrudModal from '../../../../common/crud-list/crud-modal/crud-modal.component';
import { ButtonType } from '../../../../../types';
import { Icon } from '../../../../common/schema-form/common';

interface CrudListProps<T> extends ListProps<T> {
  createButtonText?: string;
  modalTitle?: string;
  selectedToken?: T;
  onCreate?: (item: Partial<T>) => Promise<void>;
  onEdit?: (item: Partial<T>) => Promise<void>;
  onDelete?: (item: T) => Promise<void>;
  loaded: boolean;
  createSchema: any;
  updateSchema: any;
  metaSchema?: any;
  onFormChange?: (values: Partial<T>, rootFormChange?: (data: T) => void) => void;
  canCreate: boolean;
  canUpdate: boolean;
  canDelete: boolean;
  conditionalCanUpdate?: (item: Partial<T>) => boolean;
  conditionalCanDelete?: (item: Partial<T>) => boolean;
  externalFormChange?: boolean;
  customFormValidation?: (data: Partial<T>, errors: FormValidation) => FormValidation;
  createButtonTestId?: string;
  showCreateButton?: boolean;
  showActionButton?: boolean;
}

const ListActionRow = styled(Row)`
  margin-bottom: 16px;
`;

function DevelopersSectionTokensListItemActions<T extends { name: string }>(props: CrudListProps<T>) {
  const {
    showActionButton = false,
    showCreateButton = false,
    selectedToken = null,
    createSchema,
    updateSchema,
    metaSchema,
    onCreate,
    onEdit,
    onDelete,
    createButtonText,
    modalTitle,
    onFormChange,
    canCreate,
    externalFormChange,
    customFormValidation,
    createButtonTestId,
  } = props;
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState<T | undefined>(undefined);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const handleEdit = useCallback(
    (item: T) => {
      setSelectedItem(item);
      setIsModalVisible(true);
    },
    [],
  );

  const handleDelete = useCallback(
    // eslint-disable-next-line consistent-return
    async (item: T) => {
      if (onDelete) {
        try {
          await onDelete(item);
          message.success(t('successfullyDeleted'));
        } catch (error) {
          if (error.response && error.response.data && error.response.data.message) {
            message.error(error.response.data.message);
          }
          message.error(t('failedToDeleteItem'));
        }
      }
    },
    [onDelete, t],
  );

  const handleCreate = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setIsModalVisible(false);
    setSelectedItem(undefined);
  }, []);

  const handleModalSubmit = useCallback(
    async (values: Partial<T>) => {
      if (selectedItem && onEdit) {
        await onEdit(values);
      } else if (onCreate) {
        await onCreate(values);
      }
      handleModalClose();
    },
    [onEdit, onCreate, handleModalClose, selectedItem],
  );

  const handleFormChange = useCallback(
    (values: Partial<T>, rootFormChange) => {
      if (onFormChange) {
        if (externalFormChange) {
          onFormChange(values, rootFormChange);
        } else {
          onFormChange(values);
        }
      }
    },
    [onFormChange, externalFormChange],
  );


  return (
    <>
      {showActionButton && selectedToken && (
        <Dropdown
          placement="bottomRight"
          overlay={
            <Menu>
              <Menu.Item key={`${selectedToken.name}-edit`}>
                <EditButton
                  type="link"
                  icon="edit"
                  size="small"
                  value={selectedToken.name}
                  onClick={() => {
                    handleEdit(selectedToken)
                  }}
                >
                  {t('edit')}
                </EditButton>
              </Menu.Item>
              <Menu.Item key={`${selectedToken.name}-remove`}>
                <RemoveButton
                  type="link"
                  icon="delete"
                  size="small"
                  value={selectedToken.name}
                  onClick={() => {
                    handleDelete(selectedToken);
                  }}
                >
                  {t('delete')}
                </RemoveButton>
              </Menu.Item>
            </Menu>
          }
        >
          <StyledIcon type="small-dash" />
        </Dropdown>
      )}



      {canCreate && showCreateButton && (
        <ListActionRow type="flex" justify="end">
          <Col>
            <Button size="large" type="primary" icon="plus" onClick={handleCreate} data-testid={createButtonTestId}>
              {createButtonText || t('create')}
            </Button>
          </Col>
        </ListActionRow>
      )}
      <CrudModal
        title={modalTitle}
        schema={selectedItem ? updateSchema : createSchema}
        metaSchema={metaSchema}
        initialValues={selectedItem}
        onSubmit={handleModalSubmit}
        visible={isModalVisible}
        onClose={handleModalClose}
        onChange={handleFormChange}
        externalFormChange={externalFormChange}
        customFormValidation={customFormValidation}
      />
    </>
  );
}


const RemoveButton = styled(Button)`
    color: rgb(255, 85, 0);
    text-transform: capitalize;

    &:hover,
    &:active,
    &:focus {
        color: rgb(255, 85, 0);
    }
` as ButtonType;

const StyledIcon = styled(Icon)`
    transform: rotate(90deg);
`;

const EditButton = styled(Button)`
    color: rgb(35, 100, 170);
    text-transform: capitalize;

    &:hover,
    &:active,
    &:focus {
        color: rgb(40, 113, 194);
    }
` as ButtonType;

export default DevelopersSectionTokensListItemActions;
