import React, { useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useStore } from 'easy-peasy';
import { RootState } from '../../../../store/models/root.model';
import InstallationGroup from '../../../../store/types/installation-group';
import {
  useDeleteInstallationGroup,
  useInstallationGroup,
  useInstallationGroups,
  useUpdateInstallationGroup,
} from '../../../common/use-installation-groups';
import { InstallationGroupComponent } from './installation-group.component';
import useGroupAppsByInstallationGroups from '../../../../utils/group-apps-by-installation-group';

interface InstallationGroupContainerProps
  extends RouteComponentProps<{ organisationId: string; groupId: string }> {}

export const InstallationGroupContainer = (props: InstallationGroupContainerProps) => {
  const { match, history, location } = props;
  const {
    params: { organisationId, groupId },
  } = match;

  const updateInstallationGroup = useUpdateInstallationGroup(organisationId, groupId);
  const deleteInstallationGroup = useDeleteInstallationGroup(organisationId, groupId);
  const installationGroupsQuery = useInstallationGroups({ organisationId });
  const installationGroups = installationGroupsQuery.data || [];

  const { apps } = useStore<RootState & { installationGroups: InstallationGroup[] }>(
    ({ organisationApps }) => ({
      apps: organisationApps.values(organisationId),
    }),
    [organisationId],
  );

  const installationGroupInfo = useInstallationGroup(groupId, organisationId);
  const installationGroup = useMemo(() => {
    const result: InstallationGroup = installationGroupInfo.data || {
      displayName: 'Loading...',
      notes: '',
      id: groupId,
      organizationId: organisationId,
    };
    return result;
  }, [installationGroupInfo.data, organisationId, groupId]);

  const groupedApps = useGroupAppsByInstallationGroups({ apps, installationGroups });

  const handleSubmit = useCallback(
    async (formData: any) => {
      if (groupId) {
        const data = { ...formData, organizationId: organisationId, notes: formData.notes || '' };
        updateInstallationGroup.mutate(data);
      }
    },
    [updateInstallationGroup, groupId, organisationId],
  );

  const handleDelete = useCallback(() => {
    if (groupId) {
      deleteInstallationGroup.mutate();
    }
    history.goBack();
  }, [history, groupId, deleteInstallationGroup]);

  const isDeletable = useMemo(() => {
    const findGroupedApps = groupedApps.filter(
      (groupApp: { groupId: string }) => groupApp.groupId === groupId,
    )[0];
    return findGroupedApps.apps.length === 0;
  }, [groupedApps, groupId]);

  return (
    <InstallationGroupComponent
      installationGroup={installationGroup}
      handleDelete={handleDelete}
      handleSubmit={handleSubmit}
      isDeletable={isDeletable}
      history={history}
      location={location}
      match={match}
    />
  );
};
