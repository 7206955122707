import { mapValues } from 'lodash';
import PermissionRules from '../../store/types/permissions';
import User from '../../store/types/user';

export const permissionKeys: PermissionRules<string> = {
  grid: {
    viewRootGrid: 'grid.viewRootGrid',
    manageAllOrganizations: 'grid.manageAllOrganizations',
    manageSales: 'grid.manageSales',
  },
  users: {
    create: 'users.create',
    remove: 'users.remove',
    update: 'users.update',
    viewSingle: 'users.viewSingle',
    viewAll: 'users.viewAll',
  },
  organizations: {
    create: 'organizations.create',
    remove: 'organizations.remove',
    update: 'organizations.update',
    viewSingle: 'organizations.viewSingle',
    viewAll: 'organizations.viewAll',
    viewSingleAnalytics: 'organizations.viewSingleAnalytics',
    viewAllAnalytics: 'organizations.viewAllAnalytics',
    viewSkus: 'organizations.viewSkus',
  },
  apps: {
    create: 'apps.create',
    remove: 'apps.remove',
    update: 'apps.update',
    viewSingle: 'apps.viewSingle',
    viewAll: 'apps.viewAll',
    createAppVariables: 'apps.createAppVariables',
    updateAppVariables: 'apps.updateAppVariables',
    removeAppVariables: 'apps.removeAppVariables',
    viewAllAppVariables: 'apps.viewAllAppVariables',
    viewAnalytics: 'apps.viewAnalytics',
    viewContents: 'apps.viewContents',
    updateContents: 'apps.updateContents',
    publishContents: 'apps.publishContents',
    viewSettings: 'apps.viewSettings',
    updateSettings: 'apps.updateSettings',
  },
  browsers: {
    create: 'browsers.create',
    remove: 'browsers.remove',
    update: 'browsers.update',
    viewSingle: 'browsers.viewSingle',
    viewAll: 'browsers.viewAll',
  },
  builds: {
    create: 'builds.create',
    update: 'builds.update',
    remove: 'builds.remove',
    viewSingle: 'builds.viewSingle',
    viewAll: 'builds.viewAll',
    deploy: 'builds.deploy',
  },
  devices: {
    create: 'devices.create',
    update: 'devices.update',
    remove: 'devices.remove',
    viewSingle: 'devices.viewSingle',
    viewAll: 'devices.viewAll',
    reboot: 'devices.reboot',
    restart: 'devices.restart',
    move: 'devices.move',
    purge: 'devices.purge',
    viewAllDeviceVariables: 'devices.viewAllDeviceVariables',
    updateDeviceVariables: 'devices.updateDeviceVariables',
    viewAllDeviceTags: 'devices.viewAllDeviceTags',
    updateDeviceTags: 'devices.updateDeviceTags',
    viewDeviceLogs: 'devices.viewDeviceLogs',
    viewDeviceId: 'devices.viewDeviceId',
    visibleSchemaDeviceId: 'devices.visibleSchemaDeviceId',
    visibleSchemaDeviceAppBuild: 'devices.visibleSchemaDeviceAppBuild',
    visibleSchemaDeviceConnectionString: 'devices.visibleSchemaDeviceConnectionString',
    visibleSchemaDeviceEnv: 'devices.visibleSchemaDeviceEnv',
    manageDeviceSettings: 'devices.manageDeviceSettings',
    viewDeviceModules: 'devices.viewDeviceModules',
    manageModules: 'devices.manageModules',
    createLease: 'devices.createLease',
    deleteLease: 'devices.deleteLease',
    updateComponents: 'devices.updateComponents',
  },
  environments: {
    create: 'environments.create',
    update: 'environments.update',
    remove: 'environments.remove',
    viewAll: 'environments.viewAll',
    viewSingle: 'environments.viewSingle',
  },
  actionLogs: {
    viewAll: 'actionLogs.viewAll',
  },
  queues: {
    create: 'queues.create',
    update: 'queues.update',
    viewAll: 'queues.viewAll',
    viewSingle: 'queues.viewSingle',
    viewAnalytics: 'queues.viewAnalytics',
  },
  spaces: {
    create: 'spaces.create',
    update: 'spaces.update',
    remove: 'spaces.remove',
    viewSingle: 'spaces.viewSingle',
    viewAll: 'spaces.viewAll',
    viewAnalytics: 'spaces.viewAnalytics',
  },
  gridApps: {
    create: 'gridApps.create',
    update: 'gridApps.update',
    remove: 'gridApps.remove',
    viewSingle: 'gridApps.viewSingle',
    viewAll: 'gridApps.viewAll',
    viewAllVersions: 'gridApps.viewAllVersions',
    createVersion: 'gridApps.createVersion',
    removeVersion: 'gridApps.removeVersion',
    updateReleaseNotes: 'gridApps.updateReleaseNotes',
  },
  media: {
    upload: 'media.upload',
    update: 'media.update',
    remove: 'media.remove',
    viewSingle: 'media.viewSingle',
    viewAll: 'media.viewAll',
  },
  contentChannels: {
    create: 'contentChannels.create',
    update: 'contentChannels.update',
    remove: 'contentChannels.remove',
    viewSingle: 'contentChannels.viewSingle',
    viewAll: 'contentChannels.viewAll',
  },
  contentSegments: {
    create: 'contentSegments.create',
    update: 'contentSegments.update',
    remove: 'contentSegments.remove',
    viewSingle: 'contentSegments.viewSingle',
    viewAll: 'contentSegments.viewAll',
  },
  contentTags: {
    create: 'contentTags.create',
    update: 'contentTags.update',
    remove: 'contentTags.remove',
    viewSingle: 'contentTags.viewSingle',
    viewAll: 'contentTags.viewAll',
  },
  mobileEndpoints: {
    create: 'mobileEndpoints.create',
    remove: 'mobileEndpoints.remove',
    update: 'mobileEndpoints.update',
    viewSingle: 'mobileEndpoints.viewSingle',
    viewAll: 'mobileEndpoints.viewAll',
  },
  customShortUrls: {
    create: 'customShortUrls.create',
    remove: 'customShortUrls.remove',
    update: 'customShortUrls.update',
    viewAll: 'customShortUrls.viewAll',
    viewSingle: 'customShortUrls.viewSingle',
  },
  userRoles: {
    create: 'userRoles.create',
    remove: 'userRoles.remove',
    update: 'userRoles.update',
    viewAll: 'userRoles.viewAll',
    viewSingle: 'userRoles.viewSingle',
  },
  marketplace: {
    create: 'marketplace.create',
    remove: 'marketplace.remove',
    update: 'marketplace.update',
    viewAll: 'marketplace.viewAll',
    viewSingle: 'marketplace.viewSingle',
  },
  storeAIAssistants: {
    create: 'storeAIAssistants.create',
    remove: 'storeAIAssistants.remove',
    update: 'storeAIAssistants.update',
    viewAll: 'storeAIAssistants.viewAll',
    viewSingle: 'storeAIAssistants.viewSingle',
    run: 'storeAIAssistants.run',
  },
  subscriptions: {
    create: 'subscriptions.create',
    remove: 'subscriptions.remove',
    update: 'subscriptions.update',
    viewAll: 'subscriptions.viewAll',
    viewSingle: 'subscriptions.viewSingle',
  },
  accountBalance: {
    create: 'accountBalance.create',
    remove: 'accountBalance.remove',
    update: 'accountBalance.update',
    viewSingle: 'accountBalance.viewSingle',
    viewAll: 'accountBalance.viewAll',
  },
  plans: {
    create: 'plans.create',
    remove: 'plans.remove',
    update: 'plans.update',
    viewSingle: 'plans.viewSingle',
    viewAll: 'plans.viewAll',
  },
  professionalServicesUnits: {
    create: 'professionalServicesUnits.create',
    viewAll: 'professionalServicesUnits.viewAll',
  },
  invoices: {
    viewAll: 'invoices.viewAll',
  },
  contacts: {
    create: 'contacts.create',
    remove: 'contacts.remove',
    update: 'contacts.update',
    viewSingle: 'contacts.viewSingle',
    viewAll: 'contacts.viewAll',
  },
  gridProducts: {
    create: 'gridProducts.create',
    remove: 'gridProducts.remove',
    update: 'gridProducts.update',
    viewSingle: 'gridProducts.viewSingle',
    viewAll: 'gridProducts.viewAll',
  },
  analyticsExportJob: {
    create: 'analyticsExportJob.create',
    remove: 'analyticsExportJob.remove',
    update: 'analyticsExportJob.update',
    viewSingle: 'analyticsExportJob.viewSingle',
    viewAll: 'analyticsExportJob.viewAll',
  },
  analyticsExportSchedule: {
    create: 'analyticsExportSchedule.create',
    remove: 'analyticsExportSchedule.remove',
    update: 'analyticsExportSchedule.update',
    viewSingle: 'analyticsExportSchedule.viewSingle',
    viewAll: 'analyticsExportSchedule.viewAll',
  },
  alerts: {
    create: 'alerts.create',
    remove: 'alerts.remove',
    update: 'alerts.update',
    viewSingle: 'alerts.viewSingle',
    viewAll: 'alerts.viewAll',
  },
  networkSettings: {
    create: 'networkSettings.create',
    update: 'networkSettings.update',
    view: 'networkSettings.view',
  },
  networkWhitelistRules: {
    create: 'networkWhitelistRules.create',
    update: 'networkWhitelistRules.update',
    remove: 'networkWhitelistRules.remove',
    viewSingle: 'networkWhitelistRules.viewSingle',
    viewAll: 'networkWhitelistRules.viewAll',
  },
  integrations: {
    create: 'integrations.create',
    remove: 'integrations.remove',
    update: 'integrations.update',
    viewAll: 'integrations.viewAll',
    runJob: 'integrations.runJob',
  },
  developerPAT: {
    create: 'developerPAT.create',
    remove: 'developerPAT.remove',
    update: 'developerPAT.update',
    viewAll: 'developerPAT.viewAll',
  },
  cli: {
    deviceList: 'cli.deviceList',
    deviceVnc: 'cli.deviceVnc',
    deviceLogs: 'cli.deviceLogs',
    deviceSubscribeMessageBus: 'cli.deviceSubscribeMessageBus',
    deviceRdp: 'cli.deviceRdp',
    deviceShell: 'cli.deviceShell',
  },
  phygridCheckout: {
    create: 'phygridCheckout.create',
    remove: 'phygridCheckout.remove',
    update: 'phygridCheckout.update',
    viewSingle: 'phygridCheckout.viewSingle',
    viewAll: 'phygridCheckout.viewAll',
  },
};

const emptyPermissions = mapValues(permissionKeys, (permissions) =>
  mapValues(permissions, () => false),
) as PermissionRules<boolean>;

export const getPermissionPath = (permissionGroupId: string, permissionKey: string) =>
  [permissionGroupId, permissionKey].join('.');

export const getPermissions = (
  user: User | null,
  permissionGroupId: string,
): PermissionRules<boolean> => {
  if (!user) return emptyPermissions;

  if (user.isSysadmin) {
    return user.permissions.grid;
  }

  return user.permissions[permissionGroupId]
    ? user.permissions[permissionGroupId]
    : user.permissions.fallback;
};

export const isInRole = (
  user: User | null,
  organizationId: string,
  roleIds: string[],
) => {
  if (!user) return false;

  const userOrgWithRole = user.organizationsWithRole.find(
    (o) => o.organizationId === organizationId,
  );

  if (!userOrgWithRole) return false;

  return roleIds.includes(userOrgWithRole.roleId);
};

export default permissionKeys;
