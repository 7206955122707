import styled from '@emotion/styled';
import { Typography } from 'antd';

const { Text: AntdText } = Typography;

const Text = styled(AntdText)<{ size?: number; weight?: number | string }>`
  ${({ size }) => (size ? `font-size: ${size}px;` : '')}
  ${({ weight }) => {
    if (!weight) {
      return '';
    }

    if (typeof weight === 'number') {
      return `font-weight: ${weight};`;
    }

    return `font-weight: ${weight};`;
  }};
`;

export default Text;
