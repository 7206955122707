import React from 'react';
import { useStoreActions, useStoreState } from '../../../../../../store/initialize-store';
import ProfessionalServices from './professional-services.component';

interface ProfessionalServicesContainerProps {
  organizationId: string;
}

const ProfessionalServicesContainer = (props: ProfessionalServicesContainerProps) => {
  const { organizationId } = props;

  const { organisation } = useStoreState(
    (state) => ({
      organisation: state.organisations.data && state.organisations.data[organizationId],
    }),
    [organizationId],
  );

  const { createProfessionalServicesUnit } = useStoreActions((actions) => ({
    createProfessionalServicesUnit: actions.organisationProfessionalServicesUnits.create,
  }));

  return (
    <ProfessionalServices
      organisation={organisation}
      organizationId={organizationId}
      createProfessionalServicesUnit={createProfessionalServicesUnit}
    />
  );
};

export default ProfessionalServicesContainer;
