import React from 'react';
import styled from '@emotion/styled';
import { Avatar, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import MediaThumbnail from '../media-thumbnail/media-thumbnail.component';
import PreviewIcon from '../preview-icon/preview-icon.component';
import Media from '../../../../store/types/media';
import {
  getMediaTemplateStatus,
  isMediaTemplate,
  MediaTemplateStatus,
} from '../../../../utils/media-templates';

const ErrorTemplateAvatar = styled(Avatar)`
  color: #a94442;
`;

const MediaListItemAvatar = ({ mediaItem }: { mediaItem: Media }) => {
  const { t } = useTranslation();

  if (isMediaTemplate(mediaItem)) {
    const status = getMediaTemplateStatus(mediaItem);
    switch (status) {
      case MediaTemplateStatus.PENDING:
        return <Avatar size={64} icon="loading" />;
      case MediaTemplateStatus.ERROR:
        return (
          <Tooltip placement="right" title={t('errorDuringGdmTemplateProcessing')}>
            <ErrorTemplateAvatar size={64} icon="exclamation-circle" />
          </Tooltip>
        );
      case MediaTemplateStatus.VALID:
        return <PreviewIcon mediaItem={mediaItem} />;
      default:
        return <MediaThumbnail mediaItem={mediaItem} />;
    }
  }

  return <MediaThumbnail mediaItem={mediaItem} />;
};

export default MediaListItemAvatar;
