import { useMutation, useQueryClient } from 'react-query';
import { useApiService } from '../../../app/common/api-service-provider';
import { PlanFormValues as PlanPayload } from '../../../app/grid/billing/plan/plan-form.component';
import * as Sentry from '@sentry/browser';
import Plan from '../../types/plan';
import { ApiError } from '../../../services/api/api-error';
import plansQueryKeys from './use-plans-query-keys';

const usePlanCreate = () => {
  const queryClient = useQueryClient();

  const apiService = useApiService();
  return useMutation(
    async (body: PlanPayload) => {
      return apiService.post<Plan>('/api/plans', body);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([plansQueryKeys.queryPrefix()]);
      },
      onError: (error: ApiError) => {
        Sentry.captureException(error);
      },
    },
  );
};

export default usePlanCreate;