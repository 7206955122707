import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useApiService } from '../../../app/common/api-service-provider';
import {
  AlertActionGroup,
  AlertCondition,
  AlertRule,
  AlertScope,
  SeverityEnum,
} from '../../types/alerts';
import { PaginationCollection } from '../../types/pagination';

const queryKeys = {
  getAlertRulesList: (
    tenantId: string,
    actionGroups: string = '',
    limit: number = 10000,
    page: number = 1,
  ) => [
    'alert-rule',
    'tenantId',
    tenantId,
    'actionGroups',
    actionGroups,
    'page',
    page,
    'limit',
    limit,
  ],
  getAlertRuleById: (alertRuleId: string) => [
    'alert-rule-by-id',
    'alertRuleId',
    alertRuleId,
  ],
};

interface UseAlertRulesProps {
  tenantId: string;
  actionGroups?: string[];
  page?: number;
  limit?: number;
  isEnabled?: boolean;
}

interface UseAlertRulesResponse {
  docs: AlertActionGroup[];
  totalDocs: number;
}

interface UseAlertRulesBody {
  tenantId: string;
  displayName: string;
  description: string;
  severity: SeverityEnum;
  enabled: boolean;
  scope: AlertScope[];
  conditions: AlertCondition[];
  actions: string[];
}

export const useAlertRules = ({
  tenantId,
  actionGroups,
  page = 1,
  limit = 10000,
  isEnabled = true,
}: UseAlertRulesProps) => {
  const apiService = useApiService();

  const url = `/api/alert-rule`;

  const actionGroupsParam =
    actionGroups && actionGroups.length ? actionGroups.join(',') : '';

  return useQuery(
    queryKeys.getAlertRulesList(tenantId, actionGroupsParam, limit, page),
    () =>
      apiService.get<PaginationCollection<AlertRule>>(url, {
        tenantId,
        ...(actionGroupsParam ? { actionGroups: actionGroupsParam } : {}),
        page,
        limit,
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      keepPreviousData: true,
      enabled: isEnabled,
    },
  );
};

export const useAlertRuleById = ({ alertRuleId }: { alertRuleId: string }) => {
  const apiService = useApiService();

  const url = `/api/alert-rule/${alertRuleId}`;

  return useQuery(
    queryKeys.getAlertRuleById(alertRuleId),
    () => apiService.get<AlertRule>(url),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      enabled: alertRuleId ? true : false,
    },
  );
};

export const useUpdateAlertRule = (tenantId: string, alertRuleId: string) => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  const url = `/api/alert-rule/${alertRuleId}`;

  return useMutation(
    async (updateAlertActionGroupBody: UseAlertRulesBody) =>
      apiService.put<AlertRule>(url, updateAlertActionGroupBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.getAlertRulesList(tenantId));
        queryClient.invalidateQueries(queryKeys.getAlertRuleById(alertRuleId));
      },
    },
  );
};

export const useCreateAlertRule = (tenantId: string) => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  const url = `/api/alert-rule/`;

  return useMutation(
    (createAlertActionGroupBody: UseAlertRulesBody) =>
      apiService.post<AlertRule>(url, createAlertActionGroupBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.getAlertRulesList(tenantId));
      },
    },
  );
};

export const useDeleteAlertRuleById = (tenantId: string) => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  return useMutation(
    (alertRuleId: string) => apiService.delete(`/api/alert-rule/${alertRuleId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.getAlertRulesList(tenantId));
      },
    },
  );
};
