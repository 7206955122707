import { useMemo } from 'react';
import { PhyhubDeviceStatusEnum } from '../../../../../store/types/phyhub-devices';
import { PhyhubDevicesStatusColorEnum } from '../phyhub-devices-status';

export default function usePhyhubDeviceStatusColor(
  status: PhyhubDeviceStatusEnum,
): PhyhubDevicesStatusColorEnum {
  return useMemo<PhyhubDevicesStatusColorEnum>(() => {
    switch (status) {
      case PhyhubDeviceStatusEnum.OK:
      case PhyhubDeviceStatusEnum.ONLINE: {
        return PhyhubDevicesStatusColorEnum.Ok;
      }

      case PhyhubDeviceStatusEnum.OFFLINE: {
        return PhyhubDevicesStatusColorEnum.Error;
      }

      default: {
        return PhyhubDevicesStatusColorEnum.Warning;
      }
    }
  }, [status]);
}
