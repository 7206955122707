import React, {
  createContext,
  useContext,
  useMemo,
  useEffect,
  useCallback,
  useState,
} from 'react';
import Helmet from 'react-helmet';
import { themeConfiguration, Theme } from '../themes/themes';

const { REACT_APP_ITAB_QA_APP_HOSTNAME, REACT_APP_ITAB_PROD_APP_HOSTNAME } = process.env;

const AppThemeContext = createContext<Theme>(themeConfiguration.ombori);

const OmboriAntdTheme = React.lazy(() =>
  import('../assets/styles/ombori-theme/ombori-theme'),
);

const ItabAntdTheme = React.lazy(() => import('../assets/styles/itab-theme/itab-theme'));

const SuspenseFallback = ({ onUnmount }: { onUnmount: () => void }) => {
  useEffect(() => {
    return () => {
      onUnmount();
    };
  }, [onUnmount]);

  return <></>;
};

const AppThemeProvider: React.FC<{}> = ({ children }): JSX.Element => {
  const [isThemeLoaded, setIsThemeLoaded] = useState(false);
  const theme = useMemo(() => {
    const { hostname } = document.location;
    switch (hostname) {
      case REACT_APP_ITAB_QA_APP_HOSTNAME:
      case REACT_APP_ITAB_PROD_APP_HOSTNAME:
        return themeConfiguration.itab;
      default:
        return themeConfiguration.ombori;
    }
  }, []);

  const onItabThemeLoaded = useCallback(() => {
    setIsThemeLoaded(true);
  }, []);

  return (
    <>
      <Helmet>
        <title>{theme.pageTitle}</title>
        <link rel="icon" type="image/png" href={theme.icon16} sizes="16x16" />
        <link rel="icon" type="image/png" href={theme.icon32} sizes="32x32" />
      </Helmet>
      <React.Suspense fallback={<SuspenseFallback onUnmount={onItabThemeLoaded} />}>
        {theme.id === 'ombori-theme' && <OmboriAntdTheme />}
        {theme.id === 'itab-theme' && <ItabAntdTheme />}
        {isThemeLoaded && (
          <AppThemeContext.Provider value={theme}>{children}</AppThemeContext.Provider>
        )}
      </React.Suspense>
    </>
  );
};

export const useAppTheme = () => {
  const appTheme = useContext(AppThemeContext);
  return appTheme;
};

export default AppThemeProvider;
