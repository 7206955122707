import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { Menu, Dropdown, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { ButtonType } from '../../../../../types';
import { Icon } from '../../../../common/schema-form/common';
import { Channel } from '../../../../../store/types/channel';
import useDeleteModal from '../../../../common/use-delete-modal';
import { useDeleteChannel } from '../../../../../store/hooks/channels/use-delete-channel';
import Message from '../../../../common/message';
import { Link } from 'react-router-dom';

interface ActionComponentProps {
  channel: Channel
  organizationId: string;
}

const OrganizationMediaChannelsListActions = (props: ActionComponentProps) => {
  const { channel, organizationId: tenantId } = props;

  const { t } = useTranslation();

  const [showDeleteModal] = useDeleteModal();

  const { mutateAsync: deleteChannel } = useDeleteChannel(tenantId);

  const handleDelete = useCallback(() => {
    showDeleteModal(
      t('contents.delete'),
      t('contents.channelDelete'),
      undefined,
      async () => {
        try {
          await deleteChannel(channel.id);
          message.success(<Message content={t('contents.channelDeleteSuccess')} />);
        } catch(err) {
          message.error(<Message content={t('contents.channelDeleteFailed')} />);
        }
      }
    )
  }, [showDeleteModal, deleteChannel, channel, t]);

  return (
    <Dropdown
      placement="bottomRight"
      overlay={
        <Menu>
           <Menu.Item key={`${channel.id}-edit`}>
            <Link to={`/organisations/${channel.tenantId}/content/channels/update/${channel.id}`}>
              <EditButton
                type="link"
                icon="edit"
                size="small"
              >
                {t('edit')}
              </EditButton>
            </Link>
            </Menu.Item>
            <Menu.Item key={`${channel.id}-remove`}>
              <RemoveButton
                type="link"
                icon="delete"
                size="small"
                onClick={handleDelete}
              >
                {t('delete')}
              </RemoveButton>
            </Menu.Item>
        </Menu>
      }
    >
      <StyledIcon type="small-dash" />
    </Dropdown>
  );
};

const RemoveButton = styled(Button)`
  color: rgb(255, 85, 0);
  text-transform: capitalize;

  &:hover,
  &:active,
  &:focus {
    color: rgb(255, 85, 0);
  }
` as ButtonType;

const EditButton = styled(Button)`
  color: rgb(35, 100, 170);
  text-transform: capitalize;

  &:hover,
  &:active,
  &:focus {
    color: rgb(40, 113, 194);
  }
` as ButtonType;

const StyledIcon = styled(Icon)`
  transform: rotate(90deg);
`;

export default OrganizationMediaChannelsListActions;