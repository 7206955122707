import React, { FC } from 'react';
import styled from '@emotion/styled';
import { PhyhubDeviceStatusEnum } from '../../../../store/types/phyhub-devices';
import PhyhubDevicesStatusCircle from './phyhub-devices-status-circle/phyhub-devices-status-circle.component';
import usePhyhubDeviceStatusColor from './hooks/use-phyhub-device-status-color';

export enum PhyhubDevicesStatusColorEnum {
  Error = 'rgba(255, 77, 79, 1)',
  Ok = 'rgba(82, 196, 26, 1)',
  Warning = 'rgba(250, 173, 20, 1)',
}

interface PhyhubDevicesStatusProps {
  status: PhyhubDeviceStatusEnum;
}

const PhyhubDevicesStatus: FC<PhyhubDevicesStatusProps> = (props) => {
  const { status } = props;

  const statusColor = usePhyhubDeviceStatusColor(status);

  return (
    <Status>
      {/* TODO: Add status bar when corresponding logic is available on backend */}
      <PhyhubDevicesStatusCircle statusColor={statusColor} />
      <StatusName>{status}</StatusName>
    </Status>
  );
};

const Status = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StatusName = styled.span`
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
`;

export default PhyhubDevicesStatus;
