import styled from '@emotion/styled';
import DeviceScreenshot from '../../../../../common/device-screenshot/device-screenshot.component';

const DeviceDetailsScreenshot = styled(DeviceScreenshot)`
  margin-bottom: 16px;
  height: 300px;
  border-radius: 8px;
`;

export default DeviceDetailsScreenshot;
