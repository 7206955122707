import { TFunction } from 'i18next';
import { ImageMimeType } from '../../../../../store/types/media';

type BuildSchemaResponse = Record<string, string | number | boolean | object>;

export const buildFieldSchema = (t: TFunction): BuildSchemaResponse => {
  const fileSchemaTemplate = {
    type: 'object',
    title: '',
    properties: {
      ref: {
        const: 'media',
      },
      type: {
        type: 'string',
        enum: [
          ImageMimeType.PNG,
          ImageMimeType.JPEG,
          ImageMimeType.SVG,
          ImageMimeType.WEBP,
        ],
      },
      id: {
        type: 'string',
      },
    },
  };

  const fieldSchema = {
    type: 'object',
    properties: {
      logos: {
        type: 'object',
        title: t('brandingLogos.title'),
        properties: {
          defaultLogo: {
            ...fileSchemaTemplate,
            title: t('brandingLogos.defaultLogo'),
            description: t('brandingLogos.defaultLogoDescription'),
          },
          squareLogo: {
            ...fileSchemaTemplate,
            title: t('brandingLogos.squareLogo'),
            description: t('brandingLogos.squareLogoDescription'),
          },
        },
      },
    },
  };

  return fieldSchema;
};

export const buildUiSchema = (t: TFunction): BuildSchemaResponse => {
  const metaSchema = {
    objectFieldTemplate: 'none',
    logos: {
      objectFieldTemplate: 'accordion',
      'ui:options': {
        isContentPadded: false,
        hasDivider: false,
        gap: 16,
      },
      defaultLogo: {
        'ui:field': 'filePicker',
        'ui:options': {
          subtitle: t('brandingLogos.defaultLogoSubtitle'),
          addFileButton: {
            text: t('brandingLogos.addDefaultLogo'),
          },
        }
      },
      squareLogo: {
        'ui:field': 'filePicker',
        'ui:options': {
          subtitle: t('brandingLogos.squareLogoSubtitle'),
          addFileButton: {
            text: t('brandingLogos.addSquareLogo'),
          },
        }
      }
    },
  };

  return metaSchema;
};
