import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import { Typography } from 'antd';
import {
  FormTextInput,
  InputRadio,
  Label,
  MainSectionTitle,
  PanelBody,
  PanelCardMain,
  RowStyled,
} from './styled-block';
import { LoginMethod } from './types';

const { Title } = Typography;

interface LoginMethodsSectionProps {
  loginMethod: LoginMethod;
  emailComponent: JSX.Element;
}

const LoginMethodsSection: React.FC<LoginMethodsSectionProps> = ({
  loginMethod,
  emailComponent,
}) => {
  const { t } = useTranslation();

  return (
    <PanelCardMain bodyStyle={{ padding: 0 }}>
      <MainSectionTitle>
        <Title level={2}>{t('loginMethod')}</Title>
      </MainSectionTitle>

      <PanelBody>
        <Label>{t('selectLoginMethod')}</Label>

        <Field name="isEmailLogin" type="radio">
          {({ input }) => (
            <RowStyled>
              <Label>
                <InputRadio
                  {...input}
                  type="radio"
                  checked={loginMethod === 'pin'}
                  onChange={(event) =>
                    input.onChange({
                      ...event,
                      target: { ...event.target, value: false },
                    })
                  }
                />
                {t('pinCode')}
              </Label>
            </RowStyled>
          )}
        </Field>

        <Field name="isEmailLogin" type="radio">
          {({ input }) => (
            <RowStyled>
              <Label>
                <InputRadio
                  {...input}
                  type="radio"
                  checked={loginMethod === 'email'}
                  onChange={(event) =>
                    input.onChange({
                      ...event,
                      target: { ...event.target, value: true },
                    })
                  }
                />
                {t('loginViaEmail')}
              </Label>
            </RowStyled>
          )}
        </Field>

        <Field name="pin">
          {({ input, meta }) => (
            <FormTextInput input={input} meta={meta} label={t('pinCode')} required />
          )}
        </Field>

        {emailComponent}
      </PanelBody>
    </PanelCardMain>
  );
};

export default LoginMethodsSection;
