const plansQueryKeys = {
  queryPrefix: () => {
    return 'billing-plans'
  },
  getPlansListKeys: (
    page: number,
    limit: number,
    sortField: string,
    sortOrder: string,
    search: string,
  ) => [
    plansQueryKeys.queryPrefix(),
    'page',
    page,
    'limit',
    limit,
    'sortField',
    sortField,
    'sortOrder',
    sortOrder,
    'searchTerm',
    search,
  ],
  getPlanGetKeys: (planId: string) => [
    plansQueryKeys.queryPrefix(),
    planId,
  ]
};

export default plansQueryKeys;
