import React from 'react';
import { useStore } from 'easy-peasy';
import { RootState } from '../../../../store/models/root.model';
import OrganisationOverview from './organisation-overview.component';
import ErrorView, { ErrorKey } from '../../../common/error-view/error-view.component';
import useUserSpacePermissions from '../../../../store/hooks/spaces/useUserSpacePermissions';

type OrganisationOverviewContainerProps = Omit<
  React.ComponentProps<typeof OrganisationOverview>,
  'user'
>;

const OrganisationOverviewContainer = (props: OrganisationOverviewContainerProps) => {
  const {
    match: {
      params: { organisationId },
    },
  } = props;

  const { user, organisation } = useStore<RootState>(
    (state) => ({
      user: state.auth.user,
      organisation:
        (state.organisations.data && state.organisations.data[organisationId]) || null,
    }),
    [organisationId],
  );

  const userSpacePermissions = useUserSpacePermissions(organisationId);


  if (userSpacePermissions.hasAccessToAllSpaces) {
    return <OrganisationOverview {...props} user={user} organisation={organisation} />;
  } else {
    return <ErrorView error={{message: ErrorKey.UNAUTHORIZED}} />;
  }
};

export default OrganisationOverviewContainer;
