import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Icon, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'lodash';
import moment from 'moment';
import PanelCard from '../../../../common/panel-card/panel-card.component';
import { Session, Event } from '../../../../../store/types/session';
import Spinner from '../../../../common/spinner/spinner.component';

interface SessionsProps {
  sessions: Session[];
  isLoading: boolean;
}

const MomentEventsByDateFormatting = 'dddd Do';

const getCustomPropertiesFromEvent = (event: Event) => {
  return [
    event.str1,
    event.str2,
    event.str3,
    event.str4,
    event.str5,
    event.int1,
    event.int2,
    event.int3,
    event.int4,
    event.int5,
  ].filter((i) => i != null) as string[];
};

const getAmountOfEventsFromSessions = (sessions: Session[]) => {
  const result = sessions.reduce((acc, session) => {
    return acc + session.events.length;
  }, 0);
  return result;
};

const Sessions = ({ sessions, isLoading = false }: SessionsProps) => {
  const { t } = useTranslation();
  const sessionsGroupedByMonth = useMemo(() => {
    return groupBy(sessions, (session) => moment(session.created).format('MMMM YYYY'));
  }, [sessions]);

  return (
    <>
      {isLoading && <Spinner dataCy="loading-contact-details" />}
      {!isLoading &&
        Object.keys(sessionsGroupedByMonth).map((monthDate) => {
          const sessionsByMonth = sessionsGroupedByMonth[monthDate];
          const sessionsByDates = groupBy(sessionsByMonth, (session) =>
            moment(session.created).format(MomentEventsByDateFormatting),
          );
          const amountOfEventsForTheMonth = getAmountOfEventsFromSessions(
            sessionsByMonth,
          );

          return (
            <>
              <SessionInfo
                bodyStyle={{
                  width: '100%',
                }}
                key={monthDate}
              >
                <MonthDate>
                  <Icon type="calendar" /> {monthDate}
                </MonthDate>
                <MonthStatistic>
                  <MonthStatisticItem>
                    <MonthStatisticTitle>
                      {t('contactDetailsSessionsTotalTitle')}
                    </MonthStatisticTitle>
                    <MonthStatisticValue>{sessionsByMonth.length}</MonthStatisticValue>
                  </MonthStatisticItem>
                  <MonthStatisticItem>
                    <MonthStatisticTitle>
                      {t('contactDetailsEventsTotalTitle')}
                    </MonthStatisticTitle>
                    <MonthStatisticValue>{amountOfEventsForTheMonth}</MonthStatisticValue>
                  </MonthStatisticItem>
                </MonthStatistic>

                {Object.keys(sessionsByDates).map((dateForSessions) => {
                  const sessionsByDate = sessionsByDates[dateForSessions];

                  const amountOfSessionsByDate = sessionsByDate.length;
                  const amountOfEventsByDate = getAmountOfEventsFromSessions(
                    sessionsByDate,
                  );

                  return (
                    <>
                      <EventsContent>
                        <EventsDate>{dateForSessions}</EventsDate>
                        <StatisticItem>
                          <EventsAmountInfo>
                            {amountOfSessionsByDate}{' '}
                            {t('contactDetailsSessionsTotalTitle')}
                          </EventsAmountInfo>
                          <EventsAmountInfo>
                            {amountOfEventsByDate} {t('contactDetailsEventsTotalTitle')}
                          </EventsAmountInfo>
                        </StatisticItem>
                        {sessionsByDate.map((session) => {
                          const { events, created } = session;

                          return (
                            <EventsContentByDate key={created}>
                              <EventsEventsListWrapper>
                                {events.map((event) => {
                                  const time = moment(event.eventTime).format('hh:mm');
                                  const type = event.eventType
                                    .toLowerCase()
                                    .split('_')
                                    .join(' ');
                                  const customInfo = getCustomPropertiesFromEvent(event);
                                  const timezone = '(UTC)'; // TODO PG-1098: add timezone exctraction

                                  return (
                                    <EventRow
                                      key={`${event.tenantId}-${event.sessionId}-${
                                        event.eventTime
                                      }`}
                                    >
                                      <EventTime>
                                        {time}
                                        {timezone}
                                      </EventTime>
                                      <EventItem>
                                        <EventType>{type}</EventType>
                                        <EventCustomPropertiesList>
                                          {customInfo.map((customInfoItem) => {
                                            return (
                                              <EventCustomProperty
                                                key={`${customInfoItem}`}
                                              >
                                                {customInfoItem}
                                              </EventCustomProperty>
                                            );
                                          })}
                                        </EventCustomPropertiesList>
                                      </EventItem>
                                    </EventRow>
                                  );
                                })}
                              </EventsEventsListWrapper>
                            </EventsContentByDate>
                          );
                        })}
                      </EventsContent>
                    </>
                  );
                })}
              </SessionInfo>
            </>
          );
        })}
    </>
  );
};

const SessionInfo = styled(PanelCard)`
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  .ant-card-body {
    padding: 0;
  }
`;

const MonthDate = styled(Row)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-weight: bold;
  font-size: 20px;
  color: #111;
  width: 100%;
  padding: 16px;
`;

const MonthStatistic = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px;
`;

const StatisticItem = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const MonthStatisticItem = styled(StatisticItem)`
  padding: 40px 48px 40px 0;
`;

const MonthStatisticTitle = styled.span`
  color: grey;
  text-transform: capitalize;
  width: 100%;
`;

const MonthStatisticValue = styled.span`
  font-size: 24px;
  color: #111;
`;

const EventsContent = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  padding: 0 16px;
`;

const EventsContentByDate = styled.div`
  width: 100%;
  padding-bottom: 15px;
`;

const EventsDate = styled.div`
  font-weight: bold;
  width: 100%;
  color: #111;
`;

const EventsAmountInfo = styled.div`
  color: grey;
  text-transform: lowercase;
  padding-right: 10px;
`;

const EventsEventsListWrapper = styled.div`
  background-color: #fafafc;
  border-radius: 2px;
  border: 1px solid #ddd;
  padding: 16px;
  margin-top: 4px;
`;

const EventRow = styled(Row)`
  display: flex;
  justify-content: start;
  align-items: center;
`;

const EventItem = styled.div`
  flex: 1 100%;
  padding: 12px 4px;
  border-bottom: 1px solid #ddd;
  margin-left: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const EventTime = styled.span`
  background: 0;
  border: 0;
  flex: 0 auto;
  opacity: 0.8;
`;

const EventType = styled.span`
  text-transform: capitalize;
  color: #111;
  font-weight: 600;
`;

const EventCustomPropertiesList = styled.div`
  flex: 2;
`;

const EventCustomProperty = styled.span``;

export default Sessions;
