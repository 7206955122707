import { AnalyticsSchema } from '@ombori/grid-reports';
import Joi from 'joi';
import validationSchema from './validation-schema';

const validateSchema = (analyticsSchema: unknown): AnalyticsSchema => {
  Joi.attempt(analyticsSchema, validationSchema.required(), '', {
    abortEarly: false,
    allowUnknown: true,
    stripUnknown: true,
  });

  return (analyticsSchema as unknown) as AnalyticsSchema;
};

export default validateSchema;
