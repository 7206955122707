import React, { useMemo } from 'react';
import Build from '../../../../../../store/types/build';
import Environment from '../../../../../../store/types/environment';
import { Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { Container } from '../builds-table';
import {
  PipelineStatus,
  PipelineWithBuildNumber,
} from '../../../../../../store/types/pipeline';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import Text from 'antd/lib/typography/Text';
import dayjs from 'dayjs';
import { Icon } from '../../../../../common/schema-form/common';
import {
  CheckCircleFill,
  CloseCircleFill,
  ExclamationCircleFill,
  LoadingOutline,
} from '@ant-design/icons';
import { DeployButtonView } from '../builds-table/deploy-status';
import ProtectedSectionContainer from '../../../../../common/protected-section/protected-section.container';
import permissionKeys, {
  getPermissionPath,
} from '../../../../../../utils/auth/permissions';
import usePermissions from '../../../../../../utils/auth/use-permissions';
import { last } from 'lodash';
import Deploy, {
  DeployStatus,
  DeployStatusColors,
} from '../../../../../../store/types/deploy';
import { Circle } from 'react-feather';

type DeploymentStatusTableProps = {
  build: Build;
  environments: Environment[];
  organisationId: string;
};

interface DataRow {
  key: string;
  build: Build;
  environment: Environment;
  lastDeploy: Deploy;
}

const DeployStatusIcon: { [key: string]: string } = {
  [DeployStatus.Done]: CheckCircleFill.name,
  [DeployStatus.Queued]: ExclamationCircleFill.name,
  [DeployStatus.Deploying]: LoadingOutline.name,
  [DeployStatus.Failed]: CloseCircleFill.name,
};

const getBuildPipeline = (build: Build) => {
  const pipelines = build.pipelines.filter(
    (pipeline): pipeline is PipelineWithBuildNumber =>
      !!pipeline.data && !!pipeline.data.buildNumber,
  );
  const pipeline = pipelines[0];

  return pipeline;
};

const DeploymentStatusTable: React.FC<DeploymentStatusTableProps> = ({
  build,
  environments,
  organisationId,
}) => {
  const { t } = useTranslation();
  const { isAllowed } = usePermissions(organisationId);

  const deployStatus = useMemo<{ [test: string]: string }>(() => {
    return {
      [DeployStatus.Done]: t('deployStatus.done'),
      [DeployStatus.Queued]: t('deployStatus.queued'),
      [DeployStatus.Deploying]: t('deployStatus.deploying'),
      [DeployStatus.Failed]: t('deployStatus.error'),
    };
  }, [t]);

  const columns: ColumnProps<DataRow>[] = useMemo(
    () => [
      {
        title: t('builds.environment'),
        key: 'environment',
        align: 'left' as 'left',
        render: ({ environment, lastDeploy }: DataRow) => {
          return (
            <EnviornmentColumWrapper>
              {lastDeploy ? (
                <IconWrapper>
                  <Icon
                    className="iconWrapper"
                    size={17}
                    type={DeployStatusIcon[lastDeploy.status]}
                    theme={
                      lastDeploy.status === DeployStatus.Deploying ? 'outlined' : 'filled'
                    }
                    color={
                      DeployStatusColors[
                        lastDeploy ? lastDeploy.status : DeployStatus.Queued
                      ]
                    }
                  />
                </IconWrapper>
              ) : (
                <Circle
                  size={21}
                  fill={DeployStatusColors[DeployStatus.Queued]}
                  strokeWidth={0}
                />
              )}
              <StyledText>{environment.displayName}</StyledText>
            </EnviornmentColumWrapper>
          );
        },
      },
      {
        title: t('builds.status'),
        key: 'status',
        align: 'left' as 'left',
        render: ({ lastDeploy }: DataRow) => {
          return (
            <>
              {lastDeploy ? (
                <StatusColumWrapper>
                  {deployStatus[lastDeploy.status]}
                  <StyledList>
                    <StyledDate>
                      {dayjs(lastDeploy.data && lastDeploy.data.queueTime).format(
                        'DD/MM/YY, HH:mm',
                      )}
                    </StyledDate>
                  </StyledList>
                </StatusColumWrapper>
              ) : (
                <>{t('deployStatus.notDeployed')}</>
              )}
            </>
          );
        },
      },
      {
        title: '',
        key: 'deploy',
        align: 'center' as 'center',
        render: ({ environment, build }: DataRow) => {
          const pipeLine = getBuildPipeline(build);
          const isFailedBuildPipeline = pipeLine.status === PipelineStatus.Failed;

          const isDeploymentAllowed = isAllowed(permissionKeys.builds.deploy);

          return (
            <>
              <ProtectedSectionContainer
                permissionPath={getPermissionPath(
                  organisationId,
                  permissionKeys.builds.viewSingle,
                )}
              >
                <DeployButtonView
                  appId={build.appId}
                  build={build}
                  environment={environment}
                  isFailedBuildPipeline={isFailedBuildPipeline}
                  isListView={false}
                  allowDeploy={isDeploymentAllowed}
                />
              </ProtectedSectionContainer>
            </>
          );
        },
      },
    ],
    [deployStatus, t, isAllowed, organisationId],
  );

  const dataSource = useMemo(() => {
    return environments.map((environment) => {
      const deploys = build.deploys.filter(
        (deploy) => deploy.environmentName === environment.environmentName,
      );
      const lastDeploy = last(deploys) as Deploy;

      const dataRow: DataRow = {
        key: environment.id,
        environment,
        build,
        lastDeploy,
      };

      return dataRow;
    });
  }, [environments, build]);

  return (
    <StyledContainer>
      <Table<DataRow>
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{ x: true }}
      />
    </StyledContainer>
  );
};

export default React.memo(DeploymentStatusTable);

const IconWrapper = styled.div`
  & .iconWrapper {
    display: flex;
  }
`;

const StatusColumWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const StyledList = styled.li`
  list-style-type: disc;
`;
const StyledDate = styled(Text)`
  position: relative;
  left: -10px;
`;

const StyledText = styled(Text)`
  font-weight: bold;
`;

const EnviornmentColumWrapper = styled(StatusColumWrapper)`
  padding: 14px 0 14px 15px;
  align-items: center;
`;

const StyledContainer = styled(Container)`
  .ant-table-thead > tr:first-of-type > th:first-of-type {
    padding-left: 20px;
  }
  .ant-table {
    border: none;
    border-top: 1px solid #dddddd;
    border-radius: 0px;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none !important;
  }
`;
