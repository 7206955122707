import { useMemo } from 'react';
import useQueryParams from '../use-query-params';
import { ParseOptions } from 'query-string';
import useDeepCompareMemoize from '../use-deep-compare-memoize';

export interface PaginationQueryParams {
  search?: string;
  page?: number;
  limit?: number;
}

export type UsePaginationQueryParamsResponse<T> = [
  T,
  (newParams: T | ((prevParams: T) => T)) => void
];

const usePaginationQueryParams = <T extends PaginationQueryParams>(
  defaultParamValues: T,
  options?: ParseOptions,
): UsePaginationQueryParamsResponse<T> => {
  const { page: defaultPage, limit: defaultLimit } = defaultParamValues;

  const updatedOptions = {
    ...options,
    ...(options && options.parseNumbers === undefined ? { parseNumbers: true } : {}),
  };

  const [queryParams, setQueryParams] = useQueryParams<T>(defaultParamValues, updatedOptions);

  const queryParamValues = useMemo(() => {
    const pageNum = Number(queryParams.page);
    const pageLimit = Number(queryParams.limit);

    return {
      ...queryParams,
      page: Number.isNaN(pageNum) ? defaultPage : pageNum,
      limit: Number.isNaN(pageLimit) ? defaultLimit : pageLimit,
    };
  }, [defaultPage, defaultLimit, queryParams]);

  const memoizedQueryParams = useDeepCompareMemoize<T>(queryParamValues);

  return [memoizedQueryParams, setQueryParams];
};

export default usePaginationQueryParams;
