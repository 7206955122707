import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import BrowserCreateContainer from './browser-create/browser-create.container';

const Browsers = (props: RouteComponentProps) => {
  const { match } = props;

  return (
    <Switch>
      <Route path={`${match.path}/add`} component={BrowserCreateContainer} />
      <Route
        path={`${match.path}/:uuid`}
        render={({ match: detailsMatch }) => (
          <Redirect to={`${match.path}/add?uuid=${detailsMatch.params.uuid}`} />
        )}
      />
    </Switch>
  );
};

export default Browsers;
