import React, {
  useCallback,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Modal, Spin, Icon } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormValidation } from 'react-jsonschema-form';
import styled from '@emotion/styled';
import useSchemaForm from '../../use-schema-form/use-schema-form';
import SchemaForm, { SchemaFormRef } from '../../schema-form/schema-form.component';

interface CrudModalProps<T> {
  onSubmit: (data: Partial<T>) => Promise<void>;
  visible: boolean;
  onClose: () => void;
  initialValues?: Partial<T>;
  schema: any;
  metaSchema: any;
  title?: string | React.ReactNode;
  onChange?: (data: Partial<T>, rootFormChange?: (data: T) => void) => void;
  onError?: (e: any) => void;
  externalFormChange?: boolean;
  customFormValidation?: (data: Partial<T>, errors: FormValidation) => FormValidation;
  footer?: React.ReactNode;
  centered?: boolean;
  width?: string | number;
  showMessage?: boolean;
  processingTip?: string;
  contentScrollable?: boolean;
  scrollToErrorView?:
    | boolean
    | {
    block?: 'start' | 'center' | 'end' | 'nearest';
    inline?: 'start' | 'center' | 'end' | 'nearest';
  };
  enableErrorMessage?: boolean;
}

function CrudModal<T>(props: CrudModalProps<T>, ref?: React.Ref<unknown>) {
  const { t } = useTranslation();
  const {
    metaSchema,
    title,
    schema,
    onSubmit,
    visible,
    onClose,
    initialValues,
    onChange,
    onError,
    externalFormChange,
    customFormValidation,
    footer,
    centered = true,
    width,
    showMessage = true,
    processingTip,
    contentScrollable = true,
    scrollToErrorView,
    enableErrorMessage,
  } = props;
  const [
    formData,
    formLoading,
    ,
    formInit,
    handleFormChange,
    handleFormSubmit,
  ] = useSchemaForm<any>(onSubmit, t('dataSaved'), t('errorSavingData'), showMessage);
  const formElement = useRef<SchemaFormRef>(null);

  useEffect(() => {
    formInit(initialValues);
    if (initialValues && onChange) {
      onChange(initialValues);
    }
  }, [initialValues, formInit, onChange]);

  useEffect(() => {
    if (!visible) {
      if (formInit) formInit(initialValues);
    }
  }, [visible, initialValues, formInit]);

  const handleSubmit = useCallback(() => {
    if (formElement.current) {
      formElement.current.submit();
    }
  }, [formElement]);

  const handleChange = useCallback(
    (values: Partial<T>) => {
      handleFormChange(values);

      if (onChange) {
        if (externalFormChange) {
          onChange(values, handleFormChange);
        } else {
          onChange(values);
        }
      }
    },
    [externalFormChange, handleFormChange, onChange],
  );

  useImperativeHandle(ref, () => ({
    submit: handleSubmit,
  }));

  return (
    <ModalStyled
      onOk={handleSubmit}
      confirmLoading={formLoading}
      visible={visible}
      onCancel={() => !formLoading && onClose()}
      title={title}
      destroyOnClose
      footer={footer}
      centered={centered}
      width={width}
    >
      {formLoading && (
        <Loading>
          <Spin
            tip={processingTip || t('processing')}
            indicator={<Icon type="loading" spin />}
          />
        </Loading>
      )}
      <Content className="ombori-thin-scroll" scrollable={contentScrollable}>
        <SchemaForm
          uiSchema={metaSchema}
          schema={schema}
          onSubmit={handleFormSubmit}
          ref={formElement}
          data={formData}
          onChange={handleChange}
          onError={onError}
          liveValidate={false}
          customFormValidation={customFormValidation}
          scrollToErrorView={scrollToErrorView}
          enableErrorMessage={enableErrorMessage}
        />
      </Content>
    </ModalStyled>
  );
}

const ModalStyled = styled(Modal)`
  padding-bottom: 0px;

  .ant-modal-content {
    height: auto;
  }

  .ant-modal-body {
    max-height: calc(100vh - 105px);
  }

  @media screen and (max-height: 801px) {
    .ant-modal-body {
      max-height: none;
    }
  }

  .ant-modal-footer {
    min-height: 51px;
  }

  @media screen and (max-width: 576px) {
    .ant-modal-footer {
      display: grid;
      padding-bottom: 0px;

      button {
        margin-bottom: 10px;
        margin-left: 0px;
      }
    }
  }
`;

const Loading = styled.div`
  position: absolute;
  width: 100%;
  background-color: #c7c7c787;
  z-index: 10;
  height: 100%;
  top: 0px;
  left: 0px;
  border-radius: 4px;
  display: flex;

  .ant-spin-spinning {
    margin: auto;
  }

  .anticon-loading {
    font-size: 50px;
  }

  .ant-spin-text {
    text-transform: capitalize;
  }
`;

const Content = styled.div<{ scrollable: boolean }>`
  ${({ scrollable }) =>
      scrollable &&
      `
    max-height: calc(90vh - 105px);
    position: relative;
    overflow-y: scroll;
    overflow-x: auto;
    padding: 0px 5px 0px 0px;
  
    /* move thin scroll to the right */
    margin-right: -22px;
  
    .control-label {
      width: 100%;
    }
  
    @media screen and (min-height: 800px) {
      max-height: calc(90vh - 180px);
    }
  
    @media screen and (max-height: 801px) {
      max-height: none;
    }
  `}
`;

export default forwardRef(CrudModal);
