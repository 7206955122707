import { RouteComponentProps } from 'react-router';
import { Icon, Row, Table } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useActions, useStore } from 'easy-peasy';
import { PaginationConfig } from 'antd/lib/pagination';
import Header from '../../../common/app-layout/header/header.component';
import SearchInput from '../../../common/search-input/search-input.component';
import PanelCard from '../../../common/panel-card/panel-card.component';
import { RootModel, RootState } from '../../../../store/models/root.model';
import Contact from '../../../../store/types/contact';
import Spinner, { loadingIcon } from '../../../common/spinner/spinner.component';
import { getFirstNameFromContact, getLastNameFromContact } from './utils';
import Organisation from '../../../../store/types/organisation';

const { Column } = Table;

type OverviewProps = RouteComponentProps<{ organisationId: string }>;

// https://framer.com/projects/Project-Alpha-Beta--i7Nn9nmjKrdKzJlY7pAW-5nVY3?node=y0MOZU9WA
const Overview = ({ match, history }: OverviewProps) => {
  const {
    params: { organisationId: tenantId },
    url,
  } = match;
  const { t } = useTranslation();

  const { fetchForTenant } = useActions<RootModel>((actions) => ({
    fetchForTenant: actions.contacts.fetchForTenant,
  }));

  const { contacts, isLoading, tenant } = useStore<
    RootState,
    {
      contacts: { contact: Contact; key: string }[];
      isLoading: boolean;
      tenant: Organisation | null;
    }
  >((state) => {
    const result = {
      contacts: (state.contacts.values(tenantId) || []).map((contact) => ({
        contact,
        key: contact.id,
      })),
      isLoading: state.contacts.loading[tenantId],
      tenant: state.organisations.data && state.organisations.data[tenantId],
    };

    return result;
  });

  const [searchByIdentifier, setSearchByIdentifier] = useState<string>('');
  const [totalPagesAmount, setTotalPagesAmount] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);

  const handleSearch = useCallback((textForSearch: string) => {
    if (textForSearch.length < 2) {
      return;
    }
    setSearchByIdentifier(textForSearch);
  }, []);

  const goToDetails = useCallback(
    (contactId: string) => {
      history.push(`${url}/${contactId}`);
    },
    [history, url],
  );

  const handlePagination = useCallback(async (paginationConfig: PaginationConfig) => {
    const { current, pageSize } = paginationConfig;
    if (current && pageSize) {
      setLimit(pageSize);
      setCurrentPage(current);
    }
  }, []);

  useEffect(() => {
    const logic = async () => {
      if (tenant) {
        const extraInfo = await fetchForTenant({
          tenantId,
          identifier: searchByIdentifier,
          dataResidency: tenant.dataResidency,
          page: currentPage,
          limit,
        });
        setTotalPagesAmount(extraInfo.totalPages);
      }
    };
    logic();
  }, [
    fetchForTenant,
    tenantId,
    tenant,
    searchByIdentifier,
    t,
    setTotalPagesAmount,
    limit,
    currentPage,
  ]);

  const paginationConfig = useMemo(() => {
    return {
      total: totalPagesAmount,
      current: currentPage,
      pageSize: limit,
      showQuickJumper: true,
      showSizeChanger: true,
    };
  }, [totalPagesAmount, currentPage, limit]);

  const renderContactIcon = useCallback(
    (contact: Contact) => {
      return <ContactIcon type="user" onClick={() => goToDetails(contact.id)} />;
    },
    [goToDetails],
  );

  const renderRightArrowIcon = useCallback(
    (contact: Contact) => {
      return <IconRight type="right" onClick={() => goToDetails(contact.id)} />;
    },
    [goToDetails],
  );

  return (
    <>
      <ContactOverviewHeader
        title={<OverviewHeader>{t('tenantBarOptionsContacts')}</OverviewHeader>}
      />
      <Content>
        <SearchInput
          placeholder={`${t('searchContactPlaceholderLabel')}`}
          onSearch={handleSearch}
        />

        {isLoading && <Spinner dataCy="loading-search-results" />}
        {!isLoading && (
          <>
            {contacts.length === 0 && (
              <RowItem>{t('searchContactNoContactsFound')}</RowItem>
            )}
            <Table
              expandIcon={() => null}
              expandIconAsCell={false}
              pagination={paginationConfig}
              dataSource={contacts}
              onChange={handlePagination}
              loading={{
                indicator: loadingIcon,
                spinning: isLoading,
              }}
            >
              <Column
                width={50}
                render={(contact) => renderContactIcon(contact)}
                dataIndex="contact"
              />
              <Column
                title={<OverviewHeader>{t('information')}</OverviewHeader>}
                dataIndex="contact"
                render={(contact) => {
                  const firstName = getFirstNameFromContact(contact);
                  const lastName = getLastNameFromContact(contact);
                  return (
                    <RowWrapper onClick={() => goToDetails(contact.id)} key={contact.id}>
                      <SmallRow>
                        {contact.email}
                        {contact.phone}
                        {!!firstName && <MainText>{firstName}</MainText>}
                        {!!lastName && <MainText>{lastName}</MainText>}
                      </SmallRow>
                      <SmallRow>
                        {contact.sessionsLength} {t('contactDetailsSessionsTotalTitle')}
                      </SmallRow>
                    </RowWrapper>
                  );
                }}
              />
              <Column
                width={50}
                render={(contact) => renderRightArrowIcon(contact)}
                dataIndex="contact"
              />
            </Table>
          </>
        )}
      </Content>
    </>
  );
};

const Content = styled(PanelCard)`
  margin: 20px;
`;

const ContactOverviewHeader = styled(Header)`
  padding-bottom: 0;
`;

const RowItem = styled.div`
  cursor: pointer;
  display: flex;
  color: rgba(0, 0, 0, 0.65);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 15px;
  padding-left: 15px;
  padding-top: 15px;
`;

const MainText = styled.span`
  font-weight: bold;
  padding-right: 10px;
`;

const SmallRow = styled(Row)``;

const RowWrapper = styled.div`
  cursor: pointer;
`;

const ContactIcon = styled(Icon)`
  float: left;
  color: #0064b6;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 5px;
`;

const OverviewHeader = styled.span`
  text-transform: capitalize;
`;

const IconRight = styled(Icon)`
  float: right;
  color: #0064b6;
  padding-right: 20px;
  padding-top: 5px;
`;

export default Overview;
