import React, { useCallback, useEffect, useRef, useMemo } from 'react';
import { Button, Col, message, Row } from 'antd';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import PanelCard from '../../../../../common/panel-card/panel-card.component';
import SchemaForm, {
  SchemaFormRef,
} from '../../../../../common/schema-form/schema-form.component';
import DeviceTags from './device-tags/device-tags.component';
import useSchemaForm from '../../../../../common/use-schema-form/use-schema-form';
import Device from '../../../../../../store/types/device';
import Space from '../../../../../../store/types/organisation-space';
import DeviceDetailsScreenshot from '../../common/device-details-screenshot/device-details-screenshot.component';

interface SettingsProps
  extends RouteComponentProps<{ deviceUuid: string; organisationId: string }> {
  canManageDeviceSettings: boolean;
  device: Device | null;
  spaces: Space[];
  updateDevice: (device: Partial<Device>) => Promise<void>;
}

const Settings = (props: SettingsProps) => {
  const { t } = useTranslation();
  const { canManageDeviceSettings, device, spaces, updateDevice } = props;

  const formElement = useRef<SchemaFormRef>(null);

  const handleError = () => {
    message.error(t('thereAreErrorsInTheDeviceForm'));
  };

  const handleSave = useCallback(() => {
    if (formElement.current) {
      formElement.current.submit();
    }
  }, [formElement]);

  const handleSubmit = async (data: any) => {
    if (device) {
      await updateDevice({
        ...data,
        uuid: device.uuid,
      });
    }
  };

  const [
    formData,
    formLoading,
    formDirty,
    formInit,
    handleFormChange,
    handleFormSubmit,
  ] = useSchemaForm(
    handleSubmit,
    t('deviceSettingsSaved'),
    t('errorSavingDeviceSettings'),
  );

  useEffect(() => {
    // TODO: consider switching to device uuid router param instead?
    if (device && !Object.keys(formData).length) {
      formInit({
        note: device.note || '',
        deviceName: device.deviceName,
        tags: device.tags || {},
        spaces: device.spaces || [],
      });
    }
  }, [device, formInit, formData]);

  const formSchema = useMemo(() => {
    const spacesSchema: any = spaces.length
      ? {
          spaces: {
            title: t('spaces'),
            type: 'array',
            uniqueItems: true,
            minItems: 0,
            items: {
              type: 'string',
              enum: spaces.map((s) => s.id),
              enumNames: spaces.map((s) => s.displayName),
            },
            default: [],
          },
        }
      : {};

    const schema = {
      title: t('deviceSettings'),
      type: 'object',
      properties: {
        deviceName: {
          title: t('name'),
          type: 'string',
          readOnly: !canManageDeviceSettings,
        },
        note: {
          title: t('notes'),
          type: 'string',
          readOnly: !canManageDeviceSettings,
        },
        tags: {
          type: 'object',
          title: t('tags'),
          readOnly: !canManageDeviceSettings,
        },
        ...spacesSchema,
      },
      required: ['deviceName'],
    };

    return schema;
  }, [canManageDeviceSettings, spaces, t]);

  const uiSchema = {
    note: {
      'ui:widget': 'textarea',
    },
    tags: {
      'ui:field': DeviceTags,
    },
  };

  // TODO: handle error here as well

  if (!device) {
    return null;
  }

  return (
    <Row gutter={40}>
      <Col md={24} xl={16}>
        <PanelCard>
          <SchemaForm
            ref={formElement}
            uiSchema={uiSchema}
            data={formData}
            onChange={handleFormChange}
            schema={formSchema}
            onSubmit={handleFormSubmit}
            onError={handleError}
          />
        </PanelCard>
      </Col>
      <Col md={24} xl={8}>
        <PanelCard>
          <DeviceDetailsScreenshot device={device} />
          {canManageDeviceSettings && (
            <Button
              loading={formLoading}
              size="large"
              block
              type="primary"
              onClick={handleSave}
              disabled={!formDirty}
            >
              {t('saveChanges')}
            </Button>
          )}
        </PanelCard>
      </Col>
    </Row>
  );
};

export default Settings;
