import axios, { AxiosError, AxiosInstance } from 'axios';
import { ApiError } from './api-error';
import { DataResidencyEnum } from '../../store/types/organisation';
import { getApiUrl } from '../../utils/env';

class GridProductsConnectorService {
  private readonly axiosInstances: {
    [DataResidencyEnum.LOCAL]: AxiosInstance;
    [DataResidencyEnum.DEV]: AxiosInstance;
    [DataResidencyEnum.QA]: AxiosInstance;
    [DataResidencyEnum.EU]: AxiosInstance;
    [DataResidencyEnum.US]: AxiosInstance;
    [DataResidencyEnum.IN]: AxiosInstance;
    [DataResidencyEnum.UAE]: AxiosInstance;
    [DataResidencyEnum.AU]: AxiosInstance;
  };

  private dataResidency: DataResidencyEnum | null = null;

  public constructor() {
    const axiosInstances: any = {};

    axiosInstances[DataResidencyEnum.LOCAL] = axios.create({
      baseURL: this.buildLocalBaseUrl(),
    });

    axiosInstances[DataResidencyEnum.DEV] = axios.create({
      baseURL: this.buildDevBaseUrl(),
    });

    axiosInstances[DataResidencyEnum.QA] = axios.create({
      baseURL: this.buildQABaseUrl(),
    });

    [
      DataResidencyEnum.EU,
      DataResidencyEnum.US,
      DataResidencyEnum.IN,
      DataResidencyEnum.UAE,
      DataResidencyEnum.AU,
    ].forEach((dataResidencyEnum) => {
      const baseURL = this.buildProdBaseUrl(dataResidencyEnum);
      axiosInstances[dataResidencyEnum] = axios.create({
        baseURL,
      });
    });

    this.axiosInstances = axiosInstances;
  }

  private buildLocalBaseUrl = () => {
    const rootUrl =
      process.env.REACT_APP_GRID_PRODUCTS_CONNECTOR_LOCAL_API_URL ||
      'http://localhost:3003';

    return `${rootUrl}/tenants`;
  };

  private buildDevBaseUrl = () => {
    const rootUrl = getApiUrl();

    return `${rootUrl}/regions/dev/productsconn/tenants`;
  };

  private buildQABaseUrl = () => {
    const rootUrl = getApiUrl();

    return `${rootUrl}/regions/qa/productsconn/tenants`;
  };

  private buildProdBaseUrl = (_env: DataResidencyEnum) => {
    const rootUrl = getApiUrl();
    // TODO: remove this hard coded value from the code once we deployed to other instances
    return `${rootUrl}/regions/${DataResidencyEnum.EU.toLowerCase()}/productsconn/tenants`;
  };

  public setInterceptors = () => {
    Object.keys(this.axiosInstances).forEach((key) => {
      this.axiosInstances[key as DataResidencyEnum].interceptors.response.use(
        (response) => {
          return response;
        },
        (err) => {
          if (
            err.response.status === 401 &&
            !['/', '/login'].includes(document.location.pathname)
          ) {
            document.location.href = `/login?returnPath=${encodeURIComponent(
              `${document.location.pathname}${document.location.search}`,
            )}`;
          }
          return Promise.reject(err);
        },
      );
    });
  };

  public async get<T>(
    path: string,
    params?: { [key: string]: string },
  ): Promise<T> {
    try {
      if (!this.dataResidency) {
        throw new Error('Invalid data residency');
      }

      const result = await this.axiosInstances[this.dataResidency].get(
        path,
        process.env.NODE_ENV === 'development'
          ? {
              params,
              withCredentials: true,
            }
          : {
              params,
              withCredentials: true,
            },
      );
      return result.data;
    } catch (error) {
      return this.handleError(error as AxiosError);
    }
  }

  public async patch<T>(
    path: string,
    body: any,
    params?: { [key: string]: string },
  ): Promise<T> {
    try {
      if (!this.dataResidency) {
        throw new Error('Invalid data residency');
      }

      const result = await this.axiosInstances[this.dataResidency].patch(
        path,
        body,
        process.env.NODE_ENV === 'development'
          ? {
              params,
              withCredentials: false,

              headers: {
                'x-api-key': process.env.REACT_APP_GRID_PRODUCTS_DEV_API_KEY,
              },
            }
          : {
              params,
              withCredentials: true,
            },
      );

      return result.data;
    } catch (error) {
      return this.handleError(error as AxiosError);
    }
  }

  public async post<T>(
    path: string,
    body?: any,
    params?: { [key: string]: string },
  ): Promise<T> {
    try {
      if (!this.dataResidency) {
        throw new Error('Invalid data residency');
      }

      const result = await this.axiosInstances[this.dataResidency].post(
        path,
        body,
        process.env.NODE_ENV === 'development'
          ? {
              params,
              withCredentials: false,
              headers: {
                'x-api-key': process.env.REACT_APP_GRID_PRODUCTS_DEV_API_KEY,
              },
            }
          : {
              params,
              withCredentials: true,
            },
      );

      return result.data;
    } catch (error) {
      return this.handleError(error as AxiosError);
    }
  }

  public async delete<T>(path: string): Promise<T> {
    try {
      if (!this.dataResidency) {
        throw new Error('Invalid data residency');
      }

      const result = await this.axiosInstances[this.dataResidency].delete(
        path,
        process.env.NODE_ENV === 'development'
          ? {
              withCredentials: false,
              headers: {
                'x-api-key': process.env.REACT_APP_GRID_PRODUCTS_DEV_API_KEY,
              },
            }
          : {
              withCredentials: true,
            },
      );

      return result.data;
    } catch (error) {
      return this.handleError(error as AxiosError);
    }
  }

  public getBaseUrlFromInstance(dataResidencyEnum: DataResidencyEnum): string {
    return this.axiosInstances[dataResidencyEnum].defaults.baseURL || '';
  }

  public setDataResidency(dataResidency: DataResidencyEnum) {
    this.dataResidency = dataResidency;
  };

  private handleError = (error: AxiosError): never => {
    throw new ApiError(
      error.message,
      error.response
        ? { statusCode: error.response.status, data: error.response.data }
        : undefined,
    );
  };
}

export default GridProductsConnectorService;
