import React, { useMemo, useState } from 'react';
import { Input } from 'antd';
// import { useTranslation } from 'react-i18next';
import TenantPicker from './tenant-picker';
import InstallationPicker from './installation-picker';
import useModal from '../use-modal';
import moment from 'moment';
import { createLease } from '../../../utils/api';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

interface CustomTenantPickerWidgetType {
  value: any;
  onChange: (val: any) => void;
  currentTenantId: string;
}

const CustomTenantPickerWidget = ({ currentTenantId, onChange }: CustomTenantPickerWidgetType) => {
  const [tenantId, setTenantId] = useState<string | null>(null);
  return (
    <Input.Group compact>
      <TenantPicker onChange={(val) => setTenantId(val)} excludeTenantId={currentTenantId} />
      <InstallationPicker tenantId={tenantId} onChange={(appId) => onChange(appId)} />
    </Input.Group>
  );
};

const useLeaseModal = (currentTenantId: string) => {
  const { t } = useTranslation();
  const [dataIsSent, setDataIsSent] = useState(false);

  // this way we will recreate the widget component and clean up the data for the picker after data is sent
  const widget = useMemo(() => {
    if (dataIsSent) {
      setDataIsSent(false);
      return null;
    }
    return (props: CustomTenantPickerWidgetType) => <CustomTenantPickerWidget {...props} currentTenantId={currentTenantId} />;
  }, [dataIsSent, currentTenantId]);

  const timeEnumNames = [
    t('lease.create.expires.tomorrow'),
    t('lease.create.expires.1day'),
    t('lease.create.expires.2days'),
    t('lease.create.expires.3days'),
    t('lease.create.expires.4days'),
  ];
  const timeEnum = [
    moment().endOf('day').toISOString(),
    moment().add(1, 'day').endOf('day').toISOString(),
    moment().add(2, 'day').endOf('day').toISOString(),
    moment().add(3, 'day').endOf('day').toISOString(),
    moment().add(4, 'day').endOf('day').toISOString(),
  ]
  return useModal({
    title: t('lease.create.ok'),
    uiSchema: {
      objectFieldTemplate: 'none',
      installationId: {
        'ui:widget': widget,
      },
    },
    schema: {
      type: 'object',
      properties: {
        installationId: {
          type: 'string',
          title: <InstallationAndTenantTitle><SmallTitleContainer>{t('lease.create.tenant')}</SmallTitleContainer><SmallTitleContainer>{t('lease.create.installation')}</SmallTitleContainer></InstallationAndTenantTitle>,
        },
        expiry: {
          type: 'string',
          title: t('lease.create.expires.title'),
          enum: timeEnum,
          enumNames: timeEnumNames,
          default: timeEnum[0],
        },
      },
    },
    onSubmit: async (
      { installationId, expiry },
      { deviceId, callback },
    ) => {
      await createLease(deviceId, { installationId, expiry });
      setDataIsSent(true);
      callback();
    },
  });
};

const InstallationAndTenantTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 61%;
`
const SmallTitleContainer = styled.div``;

export default useLeaseModal;
