import { Progress } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import Build from '../../../store/types/build';
import { isBuildDone, isBuildError, isBuildFinished } from '../../../utils/builds/build-status';

interface BuildProgress {
  build: Build;
}

const approxBuildTimeInMinutes = 15;
const maxRunningPercent = 90;

const isBuildStatusValid = (build: Build) => {
  // const now = dayjs();
  // const createdAt = dayjs(build.createdAt);
  // return now.diff(createdAt, 'minute') > 1;
  return build.pipelines.length > 0
};

const getBuildProgressPercent = (build: Build) => {
  const now = dayjs();
  const createdAt = dayjs(build.createdAt);
  if ((isBuildError(build) || isBuildDone(build)) && isBuildStatusValid(build)) {
    return 100;
  }
  const diffInMinutes = now.diff(createdAt, 'minute', true);
  return Math.min((diffInMinutes / approxBuildTimeInMinutes) * 100, maxRunningPercent);
};

const BuildProgress = ({ build }: BuildProgress) => {
  const [progress, setProgress] = useState<number>(0);

  const hasError = isBuildError(build) && isBuildStatusValid(build);
  const isDone = isBuildDone(build) && isBuildStatusValid(build);

  const updateProgress = useCallback(() => {
    const currentProgress = getBuildProgressPercent(build);
    const isFinished = isBuildFinished(build);
    setProgress(isFinished ? 100 : currentProgress);
  }, [build]);

  useEffect(() => {
    updateProgress();
    if (progress < maxRunningPercent) {
      const interval = setInterval(() => {
        updateProgress();
      }, 1000 * 2);

      return () => {
        clearInterval(interval);
      };
    }

    return () => { };
  }, [updateProgress, progress]);

  let status: string = 'active';
  if (hasError) status = 'exception';
  if (isDone) status = 'success';

  if (progress === 100) return null;

  return <Progress percent={progress} status={status as any} showInfo={status !== 'active'} />;
};

export default BuildProgress;
