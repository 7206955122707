import { TFunction } from 'i18next';

type BuildSchemaResponse = Record<string, string | number | boolean | object>;

export const buildFieldSchema = (t: TFunction): BuildSchemaResponse => {
  const fieldSchema = {
    type: 'object',
    properties: {
      buttons: {
        type: 'object',
        title: t('brandingUserInterface.buttonsTitle'),
        properties: {
          primaryButton: {
            type: 'object',
            title: t('brandingUserInterface.buttonPrimaryTitle'),
            description: t('brandingUserInterface.buttonPrimaryDescription'),
            default: {
              backgroundColor: '#5F06E4',
              textColor: '#FFFFFF',
            },
          },
          secondaryButton: {
            type: 'object',
            title: t('brandingUserInterface.buttonSecondaryTitle'),
            description: t('brandingUserInterface.buttonSecondaryDescription'),
            default: {
              backgroundColor: '#E3DA0C',
              textColor: '#111111',
            },
          },
        },
      },
    },
  };

  return fieldSchema;
};

export const buildUiSchema = (t: TFunction): BuildSchemaResponse => {
  const metaSchema = {
    objectFieldTemplate: 'none',
    buttons: {
      objectFieldTemplate: 'accordion',
      'ui:options': {
        isContentPadded: false,
        hasDivider: false,
        gap: 16,
      },
      primaryButton: {
        'ui:field': 'buttonPicker',
        'ui:options': {
          previewButtonTitle: t('brandingUserInterface.buttonPrimaryButtonPreviewText'),
          editButton: {
            text: t('brandingUserInterface.buttonPrimaryButtonEditText'),
          },
        },
      },
      secondaryButton: {
        'ui:field': 'buttonPicker',
        'ui:options': {
          previewButtonTitle: t('brandingUserInterface.buttonSecondaryButtonPreviewText'),
          editButton: {
            text: t('brandingUserInterface.buttonSecondaryButtonEditText'),
          },
        },
      },
    },
  };

  return metaSchema;
};
