import { action } from 'easy-peasy';
import createCrudModel, { CrudModel } from '../../common/crud-model/crud.model';
import Token from '../../types/token';

export type TokensModel = CrudModel<Token> & {
  clear: (state: any, action: string) => void;
};

const tokensModel: TokensModel = {
  ...createCrudModel<Token>('/api/tokens'),
  clear: action((state: any, id) => {
    const token = state.data ? state.data[id] : null;
    if (!token) return;

    const newToken = { ...token };
    newToken.value = undefined;
    state.data = { ...state.data, [newToken.id]: newToken };
  }),
};

export default tokensModel;
