import { action, Action, thunk, Thunk } from 'easy-peasy';
import Injections from '../../injections.interface';

export interface ReleasesModel {
  data: string[];
  reset: Action<ReleasesModel>;
  setData: Action<ReleasesModel, { data: string[] }>;
  fetch: Thunk<ReleasesModel, { deviceType?: string }, Injections>;
}

const releasesModel: ReleasesModel = {
  data: [],
  reset: action((state) => {
    state.data = [];
  }),
  setData: action((state, { data }) => {
    state.data = data;
  }),
  fetch: thunk(async (actions, { deviceType = '' }, { injections }) => {
    actions.reset();
    const data = await injections.apiService.get<string[]>(`/api/schemas/releases`, {
      deviceType,
    });
    actions.setData({ data });
  }),
};

export default releasesModel;
