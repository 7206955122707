import React, { createContext, useContext } from 'react';
import OrganisationSpace from '../../../store/types/organisation-space';

interface TenantSpacesContextState {
  spaces: OrganisationSpace[];
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  tenantId: string;
  total: number;
}

const TenantSpacesContext = createContext<TenantSpacesContextState | null>(null);

interface TenantSpacesProviderProps extends TenantSpacesContextState {
  children: React.ReactNode;
}

const ActiveTenantAllSpacesProvider: React.FC<TenantSpacesProviderProps> = ({
  children,
  spaces,
  isLoading,
  isError,
  isSuccess,
  tenantId,
  total,
}): JSX.Element => {
  return (
    <TenantSpacesContext.Provider value={{ spaces, isLoading, isError, isSuccess, tenantId, total }}>
      {children}
    </TenantSpacesContext.Provider>
  );
};

export const useActiveTenantAllSpaces = (): TenantSpacesContextState => {
  const ctx = useContext(TenantSpacesContext);

  if (!ctx) {
    throw new Error(
      'Error caught while consuming TenantSpacesContext context. Make sure you wrap the component inside the "TenantSpacesProvider".',
    );
  }

  return ctx ? {
    spaces: ctx.spaces,
    isLoading: ctx.isLoading,
    isError: ctx.isError,
    isSuccess: ctx.isSuccess,
    tenantId: ctx.tenantId,
    total: ctx.total,
  } : {
    spaces: [],
    isLoading: true,
    isError: false,
    isSuccess: false,
    tenantId: '',
    total: 0,
  };
};

export default ActiveTenantAllSpacesProvider;
