import { thunk, Thunk } from 'easy-peasy';
import createCrudModel, { CrudModel } from '../../common/crud-model/crud.model';
import Organisation from '../../types/organisation';
import Injections from '../../injections.interface';

export type OrganisationsModel = CrudModel<Organisation> & {
  validateSlug: Thunk<
    CrudModel<Organisation>,
    { id: string; organizationName: string },
    Injections
  >;
};

const path = '/api/organizations';

const organisationsModel: OrganisationsModel = {
  ...createCrudModel<Organisation>(path),
  validateSlug: thunk(async (actions, payload, { injections }) => {
    const data: { valid: boolean; message: string } = await injections.apiService.post(
      `${path}/validate-slug`,
      payload,
    );

    return data;
  }),
};

export default organisationsModel;
