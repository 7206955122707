import DeviceStatusEnum from '../store/types/device-status.enum';
import LegacyWindowsDevice from '../store/types/legacy-windows-device';

export default function sortLegacyWindowsDevices<T extends LegacyWindowsDevice>(
  devices: T[],
): T[] {
  return devices.sort((a, b) => {
    if (a.status === DeviceStatusEnum.OFFLINE && b.status === DeviceStatusEnum.OK) {
      return -1;
    }

    if (a.status === DeviceStatusEnum.OK && b.status === DeviceStatusEnum.OFFLINE) {
      return 1;
    }

    if (
      a.status === DeviceStatusEnum.OFFLINE &&
      b.status === DeviceStatusEnum.OFFLINE &&
      a.screenshotUpdated &&
      !b.screenshotUpdated
    ) {
      return -1;
    }

    // Sort offline devices by how recently they went offline (screenshotUpdated)
    if (
      a.status === DeviceStatusEnum.OFFLINE &&
      b.status === DeviceStatusEnum.OFFLINE &&
      a.screenshotUpdated &&
      b.screenshotUpdated
    ) {
      return (
        new Date(b.screenshotUpdated).getTime() - new Date(a.screenshotUpdated).getTime()
      );
    }

    // Sort online devices by app name
    if (
      a.status === DeviceStatusEnum.OK &&
      b.status === DeviceStatusEnum.OK &&
      a.appName &&
      b.appName
    ) {
      // Sort devices running the same app by device name
      if (a.appName === b.appName) {
        if (a.deviceName < b.deviceName) return -1;
        if (a.deviceName > b.deviceName) return 1;
      }
      if (a.appName < b.appName) return -1;
      if (a.appName > b.appName) return 1;
    }

    return 0;
  });
}
