import React from 'react';
import { get } from 'lodash';
import {
  KEY_ACTIVE_PLANS,
  KEY_SCHEDULED_PLANS,
} from '../../../../../store/models/organization-plans/organization-plans.model';
import ActivePlans from './active-plans.component';
import { useStoreActions, useStoreState } from '../../../../../store/initialize-store';

type ActivePlansContainerProps = Pick<
  React.ComponentProps<typeof ActivePlans>,
  'match' | 'history' | 'location'
>;

const ActivePlansContainer = (props: ActivePlansContainerProps) => {
  const {
    match: {
      params: { organisationId },
    },
  } = props;

  const {
    loadingActivePlans,
    errorActivePlans,
    activePlans,
    loadingScheduledPlans,
    errorScheduledPlans,
    scheduledPlans,
  } = useStoreState((state) => {
    return {
      loadingActivePlans: state.organizationPlans.loading[KEY_ACTIVE_PLANS],
      errorActivePlans: state.organizationPlans.error[KEY_ACTIVE_PLANS],
      activePlans: get(
        state.organizationPlans.data,
        `${KEY_ACTIVE_PLANS}.${organisationId}`,
      ) as any,
      loadingScheduledPlans: state.organizationPlans.loading[KEY_SCHEDULED_PLANS],
      errorScheduledPlans: state.organizationPlans.error[KEY_SCHEDULED_PLANS],
      scheduledPlans: get(
        state.organizationPlans.data,
        `${KEY_SCHEDULED_PLANS}.${organisationId}`,
      ) as any,
    };
  }, []);

  const { fetchActivePlans, fetchScheduledPlans } = useStoreActions((actions) => ({
    fetchActivePlans: actions.organizationPlans.fetchActivePlans,
    fetchScheduledPlans: actions.organizationPlans.fetchScheduledPlans,
  }));

  return (
    <ActivePlans
      {...props}
      loadingActivePlans={loadingActivePlans}
      errorActivePlans={errorActivePlans}
      activePlans={activePlans}
      fetchActivePlans={fetchActivePlans}
      loadingScheduledPlans={loadingScheduledPlans}
      errorScheduledPlans={errorScheduledPlans}
      scheduledPlans={scheduledPlans}
      fetchScheduledPlans={fetchScheduledPlans}
    />
  );
};

export default ActivePlansContainer;
