import React, { useMemo } from 'react';
import { FieldProps } from 'react-jsonschema-form';
import { useParams } from 'react-router-dom';
import Joi from 'joi';
import { updateSpacesDisplayNameWithParentPath } from '../../../../../utils/spaces';
import useDevice from '../../../use-devices';
import { useActiveTenantAllSpaces } from '../../../spaces-provider/spaces-provider.component';
import SpacePickerComponent from './space-picker.component';

type ParamsType = {
  configType: 'device';
  id: string;
};

const paramsSchema = Joi.object<ParamsType>({
  configType: Joi.string()
    .valid('device')
    .required(),
  id: Joi.string().required(),
});

const validateSchema = (data: unknown, validationSchema: Joi.Schema): ParamsType => {
  Joi.attempt(data, validationSchema.required(), '', {
    abortEarly: false,
    allowUnknown: true,
    stripUnknown: true,
  });

  return (data as unknown) as ParamsType;
};

const getDeviceId = (params: unknown): string => {
  try {
    const { id } = validateSchema(params, paramsSchema);

    return id;
  } catch (error) {
    return '';
  }
};

const SpacePickerContainer = React.memo((props: FieldProps) => {
  const params = useParams();

  const deviceId = getDeviceId(params);

  const deviceFetchingState = useDevice(deviceId);

  const deviceSpaces = useMemo(() => {
    const result = deviceFetchingState.isSuccess ? deviceFetchingState.data.spaces : [];

    return result;
  }, [deviceFetchingState]);

  const activeTenantAllSpacesState = useActiveTenantAllSpaces();

  const filteredSpaces = useMemo(() => {
    if (!deviceId) {
      return updateSpacesDisplayNameWithParentPath(activeTenantAllSpacesState.spaces);
    }

    if (!deviceFetchingState.isSuccess) {
      return [];
    }

    const result = activeTenantAllSpacesState.spaces.filter((space) => {
      if (deviceSpaces.includes(space.id)) {
        return true;
      }

      const spaceParentId = space.parentSpaceId;

      if (!spaceParentId) {
        return false;
      }

      return deviceSpaces.includes(spaceParentId);
    });

    return result;
  }, [activeTenantAllSpacesState.spaces, deviceSpaces, deviceId, deviceFetchingState.isSuccess]);

  return <SpacePickerComponent spaces={filteredSpaces} {...props} />;
});

export default SpacePickerContainer;
