import React, { createContext, useState, useCallback, useContext } from 'react';
import qs from 'query-string';

export interface AnalyticsParams {
  fromDate: string;
  toDate: string;
}

const AnalyticsParamsContext = createContext<{
  analyticsParams: string;
  updateAnalyticsParams: (params: AnalyticsParams) => void;
} | null>(null);

/**
 * Hook to manage state for analytics params
 * analyticsParams -> 'fromDate=2024-04-01&toDate=2024-05-01'
 * analyticsQueryParams -> '?fromDate=2024-04-01&toDate=2024-05-01'
 * updateAnalyticsParams -> update analytics params
 */

export const useAnalyticsParams = () => {
  const ctx = useContext(AnalyticsParamsContext);

  if (!ctx) {
    throw new Error(
      'Error caught while consuming AnalyticsParamsContext context. Make sure you wrap the component inside the "AnalyticsParamsProvider".',
    );
  }

  const { analyticsParams } = ctx;

  return {
    ...ctx,
    analyticsQueryParams: analyticsParams ? `?${analyticsParams}` : '',
  };
};

const AnalyticsParamsProvider: React.FC<{}> = ({ children }) => {
  const [analyticsParams, setAnalyticsParams] = useState('');

  const updateAnalyticsParams = useCallback(
    (params: AnalyticsParams) => {
      const analyticsQueryParams = qs.stringify({
        ...(params.fromDate && { fromDate: params.fromDate }),
        ...(params.toDate && { toDate: params.toDate }),
      });

      setAnalyticsParams(analyticsQueryParams);
    },
    [setAnalyticsParams],
  );

  return (
    <>
      <AnalyticsParamsContext.Provider value={{ analyticsParams, updateAnalyticsParams }}>
        {children}
      </AnalyticsParamsContext.Provider>
    </>
  );
};

export default AnalyticsParamsProvider;
