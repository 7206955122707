import { Avatar, Tag, List, Divider, Collapse } from 'antd';
import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import UserRole, { UserRolePermissionsMatrix } from '../../../../store/types/user-roles';

const { Panel } = Collapse;

interface UserRolesListItemProps {
  userRoles: {
    [id: string]: UserRole;
  };
  userRole: UserRole;
  userRolePermissionsMatrix: UserRolePermissionsMatrix;
}

const Title = styled.div`
  margin: 6px 0;
  font-weight: bold;
  font-size: 20px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
`;

const PermissionsContainer = styled.div`
  margin-right: 32px;
`;

const PermissionsTitle = styled(Panel)`
  padding: 8px;
`;

const PermissionsContent = styled.div`
  padding: 8px;
  margin: 0;
`;

const LinkTag = styled(Tag)`
  margin: 4px 0;
  font-size: 10.5px;
`;

const ManagedRoles = styled.div`
  padding: 2px 0 8px 0;
  flex-direction: row;
`;

const InfoRow = styled.div`
  padding: 2px 0px 0px 0px;
  flex-direction: row;
`;

const UserRolesListItem = ({
  userRole,
  userRoles,
  userRolePermissionsMatrix,
}: UserRolesListItemProps) => {
  const { t } = useTranslation();

  const managedRoles = useMemo(() => {
    if (userRole.manageAllRoles) {
      return [{ id: 'all', displayName: t('all') }];
    }
    return userRole.allowedUserRolesToManage.reduce(
      (roleNames: { id: string; displayName: string }[], id) => {
        if (userRoles[id]) {
          return [...roleNames, { id, displayName: userRoles[id].displayName }];
        }
        return roleNames;
      },
      [],
    );
  }, [t, userRole.allowedUserRolesToManage, userRole.manageAllRoles, userRoles]);

  const renderBrowserDetails = useMemo(() => {
    return (
      <InfoContainer>
        {!!managedRoles.length && (
          <ManagedRoles>
            {t('managedRoles')}:{' '}
            {managedRoles.map((role, index) => (
              <React.Fragment key={role.id}>
                {!!index && <Divider type="vertical" />}
                <LinkTag color="blue">{role.displayName}</LinkTag>
              </React.Fragment>
            ))}
          </ManagedRoles>
        )}
        <PermissionsContainer>
          <Collapse bordered>
            <PermissionsTitle header={t('permissions')} key="1">
              <PermissionsContent>
                {userRolePermissionsMatrix.map((permissionGroup) => (
                  <InfoRow key={userRole.id + permissionGroup.id}>
                    {permissionGroup.title}:{' '}
                    {permissionGroup.list.map((permissionItem, index) => (
                      <React.Fragment key={userRole.id + permissionItem.id}>
                        {!!index && <Divider type="vertical" />}
                        <LinkTag
                          color={
                            userRole.permissions.includes(permissionItem.id)
                              ? 'green'
                              : 'red'
                          }
                        >
                          {permissionItem.title}
                        </LinkTag>
                      </React.Fragment>
                    ))}
                  </InfoRow>
                ))}
              </PermissionsContent>
            </PermissionsTitle>
          </Collapse>
        </PermissionsContainer>
      </InfoContainer>
    );
  }, [managedRoles, t, userRole.id, userRole.permissions, userRolePermissionsMatrix]);

  return (
    <List.Item.Meta
      key={userRole.id}
      title={<Title>{userRole.displayName}</Title>}
      avatar={<Avatar icon="lock" />}
      description={renderBrowserDetails}
    />
  );
};

export default UserRolesListItem;
