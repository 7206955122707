import { Select as SelectSearch } from 'antd';
import React from 'react';
import classNames from 'classnames';

interface Meta {
  active: boolean;
  data: object;
  dirty: boolean;
  dirtySinceLastSubmit: boolean;
  error: any;
  initial: any;
  invalid: boolean;
  pristine: boolean;
  submitError: any;
  submitFailed: boolean;
  submitSucceeded: boolean;
  submitting: boolean;
  touched: boolean;
  valid: boolean;
  visited: boolean;
}

export default function SelectMultipleAdapter({
  options,
  placeholder,
  input: { onChange, value },
  meta,
  disabled = false,
  className,
}: {
  options: JSX.Element[];
  placeholder: string;
  input: { onChange: any; value: string };
  meta?: Partial<Meta>;
  disabled?: boolean;
  className?: string;
}) {
  const error = meta && meta.error;

  return (
    <div className={classNames('form-group', error && 'has-error has-danger', className)}>
      <SelectSearch
        mode="multiple"
        value={value}
        placeholder={placeholder}
        onChange={(selection) => onChange(selection)}
        disabled={disabled}
      >
        {options}
      </SelectSearch>
      {error && (
        <div>
          <ul className="error-detail bs-callout bs-callout-info">
            <li className="text-danger">{error}</li>
          </ul>
        </div>
      )}
    </div>
  );
}
