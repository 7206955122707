import UniversalDevice from '../../../../../../store/types/universal-device';
import { ReportItem, ReportData } from './types';

const transformDeviceId = (
  reportChunks: ReportItem[],
  devices: UniversalDevice[],
): ReportItem[] => {
  const newReportChunks = reportChunks.map(([date, reportData]) => {
    const newReportData = reportData.map((item) => {
      const deviceId = item.label;

      const device = devices.find((deviceItem) => deviceItem.uuid === deviceId);

      if (!device) return item;

      const { deviceName } = device;

      if (!deviceName) return item;

      const newItem = { label: deviceName, value: item.value };

      return newItem;
    });

    const newReportChunk: [string, ReportData[]] = [date, newReportData];

    return newReportChunk;
  });

  return newReportChunks;
};

export default transformDeviceId;
