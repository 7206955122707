import React from 'react';
import { Icon } from 'antd';
import styled from '@emotion/styled';
import { rgba } from 'polished';
import hardDriveIcon from '../../../../../assets/images/hard-drive.svg';

export enum ThemeType {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
}

export enum IconType {
  Location = 'LOCATION',
  Device = 'DEVICE',
}

const Container = styled.div<{ theme: ThemeType }>`
  display: inline-flex;
  align-items: center;
  height: 22px;
  padding: 4px 8px;
  border-radius: 80px;

  ${({ theme }) =>
    theme === ThemeType.Primary &&
    `
      color: #E40C86;
      background-color: ${rgba('#E40C86', 0.08)};
  `}

  ${({ theme }) =>
    theme === ThemeType.Secondary &&
    `
      color: #5F06E4;
      background-color: ${rgba('#5F06E4', 0.08)};
  `}
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  font-size: 12px;
`;

const TotalCount = styled.div`
  padding: 0 0 0 4px;
`;

const ChangedCount = styled.div`
  padding: 0 4px;
`;

const HardDriveImage = styled.img`
  width: 14px;
  height: 14px;
  filter: invert(18%) sepia(89%) saturate(7496%) hue-rotate(266deg) brightness(83%)
    contrast(119%);
`;

interface OverridesStatusProps {
  totalCount: number;
  changedCount: number;
  iconType?: IconType;
  themeType?: ThemeType;
}

const OverridesStatus: React.FC<OverridesStatusProps> = ({
  totalCount,
  changedCount,
  iconType = IconType.Location,
  themeType = ThemeType.Primary,
}) => {
  return (
    <Container theme={themeType}>
      <IconContainer>
        {iconType === IconType.Location && <Icon type="environment" />}

        {iconType === IconType.Device && <HardDriveImage src={hardDriveIcon} />}
      </IconContainer>

      <Content>
        <ChangedCount>{changedCount}</ChangedCount> /{' '}
        <TotalCount>{totalCount}</TotalCount>
      </Content>
    </Container>
  );
};

export default OverridesStatus;
