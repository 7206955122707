import React, { useCallback } from 'react';
import { Field } from 'react-final-form';
import { Input as AntdInput } from 'antd';
import { TextAreaProps as AntdTextAreaProps } from 'antd/lib/input';
import { requiredAll } from '../../organisations/organisation-details/queue-details/queue-setup/validate-form';
import { composeValidators, Validator } from './validator';
import InputWrap, { InputWrapProps } from './input-wrap';
import { ChangeEvent } from '../../../types/types';
import trim from 'lodash/trim';

const AntdTextArea = AntdInput.TextArea;

export interface TextAreaProps extends AntdTextAreaProps, InputWrapProps {
  name: string;
  validators?: Validator<string>[];
}

const TextArea = (props: TextAreaProps) => {
  const {
    className,
    name,
    label,
    isRequired = false,
    hint,
    validators = [],
    rows = 3,
    ...restTextAreaProps
  } = props;

  const inputValidators = [...(isRequired ? [requiredAll] : []), ...validators];

  const handleChange = useCallback(
    (
      event: React.ChangeEvent<HTMLTextAreaElement>,
      onFieldChange: (event: React.ChangeEvent<unknown>) => void,
    ): void => {
      onFieldChange(({
        target: { value: event.target.value },
      } as unknown) as ChangeEvent<string>);
    },
    [],
  );

  const handleOnBlur = useCallback(
    (
      event: React.FocusEvent<HTMLTextAreaElement>,
      onFieldChange: (event: React.ChangeEvent<unknown>) => void,
    ): void => {
      onFieldChange(({
        target: { value: trim(event.target.value) },
      } as unknown) as ChangeEvent<string>);
    },
    [],
  );

  return (
    <>
      <Field name={name} parse={value => value} validate={composeValidators(inputValidators)}>
        {({ input, meta }) => {
          const { value, onChange, ...restInput } = input;
          const isError = !!meta.error && meta.touched;

          return (
            <InputWrap
              className={className}
              label={label}
              isRequired={isRequired}
              hint={hint}
              error={isError ? meta.error : undefined}
            >
              <AntdTextArea
                {...restTextAreaProps}
                {...restInput}
                rows={rows}
                value={value}
                onChange={(event) => handleChange(event, onChange)}
                onBlur={(event) => handleOnBlur(event, onChange)}
              />
            </InputWrap>
          );
        }}
      </Field>
    </>
  );
};

export default TextArea;
