import { useQuery } from 'react-query';
import { useApiService } from '../../../app/common/api-service-provider';
import libraryQueryKeys from './library-query-keys';
import Media from '../../types/media';

const useContentLibraryMedia = (params: { tenantId: string, mediaId: string }) => {
  const apiService = useApiService();

  const { tenantId, mediaId } = params;

  return useQuery(
    libraryQueryKeys.getSingleMedia(
      tenantId,
      mediaId,
    ),
    () =>
      apiService.get<{url: string, _doc:  Media}>(`/api/media/${mediaId}`, {
        tenantId,
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      enabled: tenantId && mediaId ? true : false,
    },
  );
};

export default useContentLibraryMedia;

