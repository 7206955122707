import {
  CatalogPageLocationProduct,
  GridProduct,
  IsoLanguageIds,
  ProductBrand,
  ProductConsumerStorageInstruction,
  ProductDescription,
  ProductFeature,
  ProductInternalName,
  ProductName,
  ProductPriceList,
  ProductShippingInstruction,
  ProductShortDescription,
  ProductStorageInstructions,
  Variant,
} from '@ombori/grid-products/dist';
import { forOwn, get } from 'lodash';
import omit from 'lodash/omit';
import { getMatchingProductId, removeVariantOptionPriceList } from '../utils';
import {
  GridProductsFormValuesSubmit,
  ProductFeaturesGrouping,
  ProductItemQuantityWithVariantOption,
} from './grid-products-details.component';

export type ValueOfGridProduct = GridProduct[keyof GridProduct];

export type MultiLanguageField =
  | ProductName
  | ProductDescription
  | ProductInternalName
  | ProductShortDescription
  | ProductStorageInstructions
  | ProductConsumerStorageInstruction
  | ProductShippingInstruction;

// Note: set to ' ' intentionally because using empty string converts the value to undefined for some reason
const DEFAULT_STRING_VALUE = ' ';

const formatBySupportedLanguages = (
  data: MultiLanguageField[],
  supportedLanguages: IsoLanguageIds[],
  defaultValue: Omit<MultiLanguageField, 'isoLanguageId'>,
): MultiLanguageField[] => {
  // Filter languages that are not part of supportedLanguages
  const fieldValue = data.filter((item) =>
    supportedLanguages.includes(item.isoLanguageId),
  );
  const currValuesLanguageIds = fieldValue.map((item) => item.isoLanguageId);

  // Set default values based on missing supportedLanguages
  supportedLanguages.forEach((isoLanguageId) => {
    if (!currValuesLanguageIds.includes(isoLanguageId)) {
      fieldValue.push({ ...defaultValue, isoLanguageId } as MultiLanguageField);
    }
  });

  return fieldValue;
};

export default function prepareDataBeforeSending(updatedProduct: GridProduct) {
  const updatedSafeProduct: GridProduct & {
    [key: string]: ValueOfGridProduct;
  } = updatedProduct;

  const visitAndUpdateNullableProps = (
    forIteration: (GridProduct | ValueOfGridProduct) & {
      [key: string]: ValueOfGridProduct;
    },
  ) => {
    forOwn(forIteration, (value: ValueOfGridProduct, key: string) => {
      // all dates shouldn't be send
      // all null should be replaced with undefined - will be
      if (value == null || value === '') {
        forIteration[key] = undefined;
        return;
      }
      // if we didn't change something in react-select, it keeps the {label, value} format. But we need only the value
      if (get(value, 'label') && get(value, 'value') != null) {
        forIteration[key] = get(value, 'value');
        return;
      }
      if (Array.isArray(value)) {
        value.forEach((arrayItem: any) => visitAndUpdateNullableProps(arrayItem));
      }
    });
  };

  const visitAndUpdateArrayUndefinedPropsToEmptyString = (
    forIteration: (GridProduct | ValueOfGridProduct) & {
      [key: string]: ValueOfGridProduct;
    },
  ) => {
    forOwn(forIteration, (values: ValueOfGridProduct, key: string) => {
      if (!Array.isArray(values)) {
        return;
      }
      const arrayWithNoEmptyValues: any[] = (values as any[]).reduce(
        (acc: any[], item: any) => {
          if (!item) {
            return acc;
          }
          // the check here is a special one - if the field is not specified at all - it doesn't exist there
          // but if it's specified and it's undefined - the value wasn't provided
          if (item.isoLanguageId && key in item && item[key] == null) {
            item[key] = '';
          }
          return [...acc, item];
        },
        [],
      );
      forIteration[key] = arrayWithNoEmptyValues;
    });
  };

  visitAndUpdateNullableProps(updatedSafeProduct);
  visitAndUpdateArrayUndefinedPropsToEmptyString(updatedSafeProduct);
  return updatedSafeProduct;
}

export const getFormattedGridProductsData = (
  values: GridProductsFormValuesSubmit,
  supportedLanguages: IsoLanguageIds[],
) => {
  values.variants = values.variants.map((item) => ({
    ...item,
    productGroupId: values.productGroupId,
    productName: item.productName
      ? (formatBySupportedLanguages(item.productName, supportedLanguages, {
          productName: DEFAULT_STRING_VALUE,
        }) as ProductName[])
      : [],
  }));

  // Map 'catalogPageLocationProduct' to all variant
  values.catalogPageLocationProduct = values.variants.reduce(
    (acc: CatalogPageLocationProduct[], variant: Variant) => {
      const catalogPerVariant: CatalogPageLocationProduct[] = values.catalogPageLocationProduct.map(
        (item) => ({
          ...item,
          productGroupId: values.productGroupId,
          productId: variant.productId,
        }),
      );

      return acc.concat(catalogPerVariant);
    },
    [],
  );

  // Map 'productFeatureGroupings' to individual objects in Product Feature and apply to all variant by languages
  values.productFeature = values.variants.reduce(
    (allFeatureAcc: ProductFeature[], variant: Variant) => {
      if (values.productFeatureGroupings) {
        const productFeaturePerVariant: ProductFeature[] = values.productFeatureGroupings.reduce(
          (currFeatureAcc: ProductFeature[], featureGroup: ProductFeaturesGrouping) => {
            const mappedFeatures = featureGroup.valuesByLanguage.reduce(
              (
                currFeatureTypeAcc: ProductFeature[],
                languageValue: {
                  isoLanguageId: IsoLanguageIds;
                  productFeatureValueGrouped: string; // comma-separated values formatted
                },
              ) => {
                // Do not include non-supported languages in form
                if (!languageValue.productFeatureValueGrouped || !supportedLanguages.includes(languageValue.isoLanguageId)) {
                  return currFeatureTypeAcc;
                }

                const splitLanguageValue: string[] = languageValue.productFeatureValueGrouped.split(
                  ',',
                );

                if (splitLanguageValue.length === 0) {
                  return currFeatureTypeAcc;
                }

                const mappedLanguageValue: ProductFeature[] = splitLanguageValue.map(
                  (featureValue) => ({
                    isoLanguageId: languageValue.isoLanguageId,
                    productFeatureType: featureGroup.productFeatureType,
                    productFeatureValue: featureValue,
                    productId: variant.productId,
                  }),
                );
                return currFeatureTypeAcc.concat(mappedLanguageValue);  
              },
              [],
            );

            return currFeatureAcc.concat(mappedFeatures);
          },
          [],
        );

        return allFeatureAcc.concat(productFeaturePerVariant);
      }

      return allFeatureAcc;
    },
    [],
  );

  // Map 'productItemQuantityWithVariantOptions' and set to 'productItemQuantity' and remove color, size, style
  values.productItemQuantity = (values.productItemQuantityWithVariantOptions || []).map(
    (item: ProductItemQuantityWithVariantOption) => ({
      // Need to check if productId is populated after updated variant's productId value in the form
      productId: getMatchingProductId(values, item),
      productItemQuantity: item.productItemQuantity,
      spaceId: item.spaceId,
    }),
  );

  // Check boolean of 'isSamePriceAllLocation' and define if price is based on 'productPriceListAllLocation' OR 'productPriceListStandard'
  //   ---> map through item and remove color, size, style
  //   ---> attach the 'productPriceListPromotional'
  const productPriceListPromotional: ProductPriceList[] = removeVariantOptionPriceList(
    values.productPriceListPromotional || [],
  );

  const rawProductStandardPriceList = values.isSamePriceAllLocation
    ? values.productPriceListAllLocation || []
    : values.productPriceListStandard || [];

  // Need to check if productId is populated after updated variant's productId value in the form
  const safeProductStandardPriceList = rawProductStandardPriceList.map((item) => ({
    ...item,
    productId: getMatchingProductId(values, item),
  }));

  const productStandardPriceList = removeVariantOptionPriceList(
    safeProductStandardPriceList,
  );

  values.productPriceList = productStandardPriceList.concat(productPriceListPromotional);

  // Brand - map through each language and set brandName
  values.brand = values.brand
    ? values.brand.reduce((acc: ProductBrand[], brand) => {
        const brandByLanguage: ProductBrand[] = supportedLanguages.map(
          (isoLanguageId) => ({
            brandName: brand.brandName,
            isoLanguageId,
          }),
        );

        return acc.concat(brandByLanguage);
      }, [])
    : [];

  values.productName = formatBySupportedLanguages(
    values.productName,
    supportedLanguages,
    {
      productName: DEFAULT_STRING_VALUE,
    },
  ) as ProductName[];

  values.productDescription = formatBySupportedLanguages(
    values.productDescription,
    supportedLanguages,
    {
      productDescription: DEFAULT_STRING_VALUE,
    },
  ) as ProductDescription[];

  values.productInternalName = values.productInternalName
    ? (formatBySupportedLanguages(values.productInternalName, supportedLanguages, {
        productInternalName: DEFAULT_STRING_VALUE,
      }) as ProductInternalName[])
    : [];

  values.productShortDescription = values.productShortDescription
    ? (formatBySupportedLanguages(values.productShortDescription, supportedLanguages, {
        productShortDescription: DEFAULT_STRING_VALUE,
      }) as ProductShortDescription[])
    : [];

  values.storageInstructions = values.storageInstructions
    ? (formatBySupportedLanguages(values.storageInstructions, supportedLanguages, {
        storageInstructions: DEFAULT_STRING_VALUE,
      }) as ProductStorageInstructions[])
    : [];

  values.consumerStorageInstruction = values.consumerStorageInstruction
    ? (formatBySupportedLanguages(values.consumerStorageInstruction, supportedLanguages, {
        consumerStorageInstruction: DEFAULT_STRING_VALUE,
      }) as ProductConsumerStorageInstruction[])
    : [];

  values.productShippingInstruction = values.productShippingInstruction
    ? (formatBySupportedLanguages(values.productShippingInstruction, supportedLanguages, {
        productShippingInstruction: DEFAULT_STRING_VALUE,
      }) as ProductShippingInstruction[])
    : [];

  values.productStatus.forEach((item: any) => delete item.updatedAt);
  values.productItemQuantity.forEach((item: any) => delete item.updatedAt);
  values.variants.forEach((item: any) => delete item.updatedAt);
  values.catalogPageLocationProduct.forEach((item: any) => delete item.updatedAt);
  values.productPriceList.forEach((item: any) => delete item.updatedAt);
  values.productFeature.forEach((item: any) => delete item.updatedAt);

  values.variants.forEach((item: any) => {
    delete item.originalEuropeanArticleNumber;
    delete item.originalGlobalTradeItemNumber;
    delete item.originalUniversalProductCode;
  });

  // Remove additional fields added in the form
  const formattedData = omit(values, [
    'sortName',
    'sortPrice',
    'sortQuantity',
    'id',
    'isSamePriceAllLocation',
    'variantOptions',
    'productPriceListAllLocation',
    'productPriceListStandard',
    'productPriceListPromotional',
    'productItemQuantityWithVariantOptions',
    'productFeatureGroupings',
  ]);

  return formattedData;
};
