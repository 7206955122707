import { useQuery } from 'react-query';
import GridappBuild from '../../../store/types/gridapp-build';
import { useApiService } from '../api-service-provider';
import { routes } from '../../../store/models/organisation-apps-library-builds/organisation-apps-library-builds.model';

export const buildQueryKey = (gridAppId: string) => ['gridAppBuilds', gridAppId];

interface UseGridAppBuildsProps {
  gridAppId: string;
}

export const useGridAppBuilds = ({ gridAppId }: UseGridAppBuildsProps) => {
  const apiService = useApiService();

  return useQuery(
    buildQueryKey(gridAppId),
    () => apiService.get<GridappBuild[]>(routes.fetchBuilds(gridAppId)),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      enabled: !!gridAppId,
    },
  );
};
