import { useQuery } from 'react-query';
import OrganisationApp from '../../../store/types/organisation-app';
import { useApiService } from '../api-service-provider';

const useInstallations = (organizationId: string) => {
  const apiService = useApiService();

  return useQuery(
    ['installations', organizationId],
    () =>
      apiService.get<OrganisationApp[]>('/api/installations', {
        organizationId,
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export default useInstallations;
