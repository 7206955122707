import i18next from 'i18next';
import errorMessages from '../../../../common/schema-form/error-messages';
import { isNumberInRangeRegexp } from './utils';
import { ValuesProps } from './types';
import { FeatureListOptions } from '../../../../../store/types/queue';

export const required = (value: any) => (value ? undefined : i18next.t('requiredField'));

export const requiredAll = (value: any) => {
  let valueToValidate = value;
  if (value && typeof value === 'string') {
    valueToValidate = value.trim();
  }
  return valueToValidate && valueToValidate.length !== 0
    ? undefined
    : i18next.t('requiredField');
};

export const requiredNumber = (value: number | undefined) => {
  return typeof value === 'undefined' ? i18next.t('requiredField') : undefined;
};

export default function validateForm(values: ValuesProps) {
  const isFeatureEnabled = (featureName: FeatureListOptions) => {
    return values && values.featureList && values.featureList.includes(featureName);
  };

  const errors: any = {};
  if (!values.title) {
    errors.title = errorMessages.required;
  }
  if (!values.pin || !values.pin.match('^[0-9]{6,6}$')) {
    errors.pin = 'Must have 6 digits';
  }

  if (
    values.autoCheckOutAfterMinutes &&
    !values.autoCheckOutAfterMinutes.toString().match(isNumberInRangeRegexp)
  ) {
    errors.autoCheckOutAfterMinutes = 'Must be a number (1-9999)';
  }

  if (isFeatureEnabled('OCCUPANCY')) {
    if (
      !values.storeCapacity ||
      !values.storeCapacity.toString().match(isNumberInRangeRegexp)
    ) {
      errors.storeCapacity = 'Must be a number (1-9999)';
    }
    if (
      values.maximumStoreCapacity &&
      !values.maximumStoreCapacity.toString().match(isNumberInRangeRegexp)
    ) {
      errors.maximumStoreCapacity = 'Must be a number (1-9999)';
    }
  }

  if (isFeatureEnabled('QUEUE')) {
    if (
      !values.notifyExtraPendingPositionOffset ||
      !values.notifyExtraPendingPositionOffset.toString().match('^[0-9]{1,4}$')
    ) {
      errors.notifyExtraPendingPositionOffset = 'Must be a number (0-9999)';
    }
    if (
      values.leaveAfterMinutes &&
      !values.leaveAfterMinutes.toString().match(isNumberInRangeRegexp)
    ) {
      errors.leaveAfterMinutes = 'Must be a number (1-9999)';
    }
    if (
      values.leaveReminderNotificationAfterMinutes &&
      !values.leaveReminderNotificationAfterMinutes
        .toString()
        .match(isNumberInRangeRegexp)
    ) {
      errors.leaveReminderNotificationAfterMinutes = 'Must be a number (1-9999)';
    }
    if (values.staffNotifications && values.staffNotifications.isEnabled) {
      const {
        emailList,
        thresholdWaitingTime,
        thresholdPeopleCount,
        sendInterval,
      } = values.staffNotifications;

      if (emailList && !emailList.length) {
        errors.staffNotifications = {
          ...errors.staffNotifications,
          emailList: errorMessages.required,
        };
      }
      if (
        thresholdWaitingTime &&
        !thresholdWaitingTime.toString().match(isNumberInRangeRegexp)
      ) {
        errors.staffNotifications = {
          ...errors.staffNotifications,
          thresholdWaitingTime: 'Must be a number (1-9999)',
        };
      }
      if (
        thresholdPeopleCount &&
        !thresholdPeopleCount.toString().match(isNumberInRangeRegexp)
      ) {
        errors.staffNotifications = {
          ...errors.staffNotifications,
          thresholdPeopleCount: 'Must be a number (1-9999)',
        };
      }
      if (sendInterval && !sendInterval.toString().match(isNumberInRangeRegexp)) {
        errors.staffNotifications = {
          ...errors.staffNotifications,
          sendInterval: 'Must be a number (1-9999)',
        };
      }
    }
  }

  if (isFeatureEnabled('BOOKINGS')) {
    if (
      values.booking &&
      values.booking.blockedDaysAhead &&
      !values.booking.blockedDaysAhead.toString().match(isNumberInRangeRegexp)
    ) {
      errors.booking = { blockedDaysAhead: 'Must be a number (1-9999)' };
    }
    if (
      !values.timeslotDuration ||
      !values.timeslotDuration.toString().match(isNumberInRangeRegexp)
    ) {
      errors.timeslotDuration = 'Must be a number (1-9999)';
    }
    if (
      !values.timeslotCapacity ||
      !values.timeslotCapacity.toString().match(isNumberInRangeRegexp)
    ) {
      errors.timeslotCapacity = 'Must be a number (1-9999)';
    }
    if (
      values.bookingReminderNotificationBeforeMinutes &&
      !values.bookingReminderNotificationBeforeMinutes.toString().match('^[0-9]{1,4}$')
    ) {
      errors.bookingReminderNotificationBeforeMinutes = 'Must be a number (0-9999)';
    }
  }

  if (isFeatureEnabled('CURBSIDEPICKUP')) {
    if (
      !values.curbsidePickupArrivalWindowDuration ||
      !values.curbsidePickupArrivalWindowDuration.toString().match(isNumberInRangeRegexp)
    ) {
      errors.curbsidePickupArrivalWindowDuration = 'Must be a number (1-9999)';
    }
    if (
      !values.curbsidePickupArrivalWindowCapacity ||
      !values.curbsidePickupArrivalWindowCapacity.toString().match(isNumberInRangeRegexp)
    ) {
      errors.curbsidePickupArrivalWindowCapacity = 'Must be a number (1-9999)';
    }
  }

  return errors;
}
