import { action, Action, Actions, select, Select, thunk, Thunk } from 'easy-peasy';
import qs from 'query-string';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import set from 'lodash/set';
import axios from 'axios';
import Device from '../../types/device';
import UniversalDevice from '../../types/universal-device';
import Injections from '../../injections.interface';
import { ApiError } from '../../../services/api/api-error';
import { PaginationCollection, PaginationSearchParam } from '../../types/pagination';
import PaginationModel, { createPaginationModel } from '../pagination/pagination.model';

export interface UniversalDeviceConnectParams {
  appId: string;
  deviceId: string;
  deviceType?: string;
}

export interface UniversalDeviceCreateParams {
  appId: string;
  appName: string;
  displayName: string;
  env: string;
  type: string;
  spaceId?: string;
  code?: string;
  deviceSerial?: string;
  extras?: {
    remoteId?: string;
  };
}

export interface AppDevicesUniversalModel
  extends PaginationModel<AppDevicesUniversalModel> {
  data: {
    [key: string]: {
      [id: string]: UniversalDevice;
    };
  };
  lastUpdated: {
    [key: string]: Date;
  };
  loading: {
    [key: string]: boolean;
  };
  error: {
    [key: string]: ApiError | null;
  };
  values: Select<AppDevicesUniversalModel, (appName: string) => UniversalDevice[]>;
  ids: Select<AppDevicesUniversalModel, (appName: string) => string[]>;
  loaded: Select<AppDevicesUniversalModel, (appName: string) => boolean>;
  setLoading: Action<AppDevicesUniversalModel, { key: string; loading: boolean }>;
  setError: Action<AppDevicesUniversalModel, { key: string; error: ApiError | null }>;
  setData: Action<AppDevicesUniversalModel, { key: string; data: UniversalDevice[] }>;
  setSingle: Action<AppDevicesUniversalModel, { key: string; data: Device }>;
  setLastUpdated: Action<AppDevicesUniversalModel, { key: string; data: Date }>;
  fetch: Thunk<
    AppDevicesUniversalModel,
    {
      appName: string;
      silent?: boolean;
      searchParam?: PaginationSearchParam;
      page: number;
      pageSize: number;
    },
    Injections
  >;
  connect: Thunk<AppDevicesUniversalModel, UniversalDeviceConnectParams, Injections>;
  disconnect: Thunk<AppDevicesUniversalModel, UniversalDeviceConnectParams, Injections>;
  create: Thunk<AppDevicesUniversalModel, UniversalDeviceCreateParams, Injections>;
  fetchAll: Thunk<
    AppDevicesUniversalModel,
    {
      appName: string;
      silent?: boolean;
    },
    Injections
  >;
}

const fetchDevices = async (
  url: string,
  actions: Actions<AppDevicesUniversalModel>,
  { key, param, silent }: { key: string; param?: PaginationSearchParam; silent: boolean },
  injections: Injections,
) => {
  injections.apiService.handleCancelRequestGet();

  if (!silent) {
    actions.setLoading({ key, loading: true });
  }
  actions.setPaginationLoading({ key, isLoading: true });
  actions.setError({ key, error: null });

  try {
    const data = await injections.apiService.get<PaginationCollection<UniversalDevice>>(
      url,
    );

    actions.setLastUpdated({ key, data: new Date() });
    actions.setData({ key, data: data.docs });
    actions.setPagination({
      key,
      payload: {
        total: data.totalDocs,
        page: data.page,
        limit: data.limit,
        param,
      },
    });

    if (!silent) {
      actions.setLoading({ key, loading: false });
    }
    actions.setPaginationLoading({ key, isLoading: false });
  } catch (error) {
    if (!axios.isCancel(error)) {
      actions.setError({ key, error });

      if (!silent) {
        actions.setLoading({ key, loading: false });
      }
      actions.setPaginationLoading({ key, isLoading: false });
    }
  }
};

const appDevicesUniversalModel: AppDevicesUniversalModel = {
  ...createPaginationModel<AppDevicesUniversalModel>(),
  data: {},
  loading: {},
  error: {},
  lastUpdated: {},
  loaded: select((state) => (appName: string) =>
    !!state.data[appName] && !state.loading[appName],
  ),
  values: select((state) => (appName: string) =>
    Object.values(state.data[appName] || {}),
  ),
  ids: select((state) => (appName: string) => Object.keys(state.data[appName] || {})),
  setLoading: action((state, { key, loading }) => {
    state.loading[key] = loading;
  }),
  setError: action((state, { key, error }) => {
    state.error[key] = error;
  }),
  setData: action((state, { key, data }) => {
    state.data[key] = {};
    data.forEach((item) => {
      state.data[key][item.id] = item;
    });
  }),
  setSingle: action((state, { key, data }) => {
    set(state.data, [key, data.id], data);
  }),
  setLastUpdated: action((state, { key, data }) => {
    state.lastUpdated[key] = data;
  }),
  fetch: thunk(
    async (
      actions,
      { appName, silent = false, searchParam, page, pageSize },
      { injections },
    ) => {
      const updatedSearchParam = omitBy(searchParam, isEmpty);
      const searchQueryParam =
        updatedSearchParam && Object.keys(updatedSearchParam).length
          ? `&${qs.stringify(updatedSearchParam, { arrayFormat: 'comma' })}`
          : '';

      const url = `/api/v4/devices?appName=${appName}${searchQueryParam}&page=${page}&limit=${pageSize}`;

      await fetchDevices(
        url,
        actions,
        { key: appName, param: searchParam, silent },
        injections,
      );
    },
  ),
  connect: thunk(async (actions, { deviceId, appId, deviceType }, { injections }) => {
    await injections.apiService.post<void>(
      `/api/v3/devices/${deviceId}/connect/${appId}`,
      {
        deviceType,
      },
    );
  }),
  disconnect: thunk(async (actions, { deviceId, appId, deviceType }, { injections }) => {
    await injections.apiService.post<void>(
      `/api/v3/devices/${deviceId}/disconnect/${appId}`,
      {
        deviceType,
      },
    );
  }),
  create: thunk(async (actions, params, { injections }) => {
    const data = await injections.apiService.post<Device>(`/api/v3/devices`, {
      ...params,
      ...(params.spaceId && { spaces: [params.spaceId] }),
    });
    actions.setSingle({ key: params.appName, data });
    return data.id;
  }),
  fetchAll: thunk(async (actions, { appName, silent = false }, { injections }) => {
    const key = appName;
    if (!silent) {
      actions.setLoading({ key, loading: true });
    }
    actions.setPaginationLoading({ key, isLoading: true });
    actions.setError({ key, error: null });

    let devices: UniversalDevice[] = [];
    let hasNext = true;
    let pageNum = 1;
    let total = 0;
    const limit = 50;

    try {
      do {
        const url = `/api/v4/devices?appName=${appName}&page=${pageNum}&limit=${limit}`;
        // eslint-disable-next-line no-await-in-loop
        const data = await injections.apiService.get<
          PaginationCollection<UniversalDevice>
        >(url);

        devices = devices.concat(data.docs);
        hasNext = data.hasNextPage;
        pageNum = data.nextPage || pageNum;
        total += data.totalDocs;
      } while (hasNext);

      actions.setLastUpdated({ key, data: new Date() });
      actions.setData({ key, data: devices });
      actions.setPagination({
        key,
        payload: {
          total,
          page: 1,
          limit: devices.length || limit,
        },
      });
    } catch (error) {
      actions.setError({ key, error });
    } finally {
      if (!silent) {
        actions.setLoading({ key, loading: false });
      }
      actions.setPaginationLoading({ key, isLoading: false });
    }
  }),
};

export default appDevicesUniversalModel;
