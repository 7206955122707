import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { RouteComponentProps } from 'react-router';
import { Button, Col, Empty, message, Popconfirm, Row } from 'antd';
import { WidgetProps } from 'react-jsonschema-form';
import styled from '@emotion/styled';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import TextWidget from 'react-jsonschema-form/lib/components/widgets/TextWidget';
import PanelCard from '../../../../../common/panel-card/panel-card.component';
import SchemaForm, {
  SchemaFormRef,
} from '../../../../../common/schema-form/schema-form.component';
import useSchemaForm from '../../../../../common/use-schema-form/use-schema-form';
import EnvVariablesType from '../../../../../../store/types/env-variables';
import Spinner from '../../../../../common/spinner/spinner.component';
import Overlay from '../../../../../common/overlay/overlay.component';
import ModalForm from '../../../../../common/modal-form/modal-form.component';
import DataCard from '../../../../../common/data-card/data-card.component';
import ModalFormField from '../../../../../common/modal-form/modal-form-field/modal-form-field.component';
import FormInput from '../../../../../common/form-input/form-input.component';
import { ApiError } from '../../../../../../services/api/api-error';
import ErrorView from '../../../../../common/error-view/error-view.component';

interface EnvVariablesProps
  extends RouteComponentProps<{ appId: string; organisationId: string }> {
  envVariables: EnvVariablesType | null;
  loaded: boolean;
  fetchEnvVariables: (params: { appId: string }) => void;
  updateEnvVariables: (params: {
    appId: string;
    envVariables: EnvVariablesType;
  }) => Promise<void>;
  error: ApiError | null;
  canUpdate: boolean;
}

const EnvVariablesSchemaFormWrapper = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
`;

const EnvVariableWidgetRow = styled.div`
  display: flex;
  align-items: flex-end;
`;

const EnvVariableRemoveButton = styled(Button)`
  height: 34px;
  margin-left: 4px;
` as any;

const EnvVariablesContext = createContext<any>({
  onRemove: () => {},
});

const EnvVariableWidget = (props: WidgetProps, canUpdate: boolean) => {
  const { t } = useTranslation();
  const { onRemove } = useContext(EnvVariablesContext);
  const { label, value } = props;

  const handleRemove = useCallback(() => {
    onRemove(label);
  }, [label, onRemove]);

  return (
    <EnvVariableWidgetRow>
      {canUpdate ? <TextWidget {...props} /> : value}

      {canUpdate && (
        <Popconfirm
          title={
            <span>
              {t('areYouSureYouWantToDeleteEnv')} <strong>{label}</strong>?
            </span>
          }
          onConfirm={handleRemove}
          okText={t('yes')}
          cancelText={t('no')}
        >
          <EnvVariableRemoveButton type="link" size="small" icon="delete" />
        </Popconfirm>
      )}
    </EnvVariableWidgetRow>
  );
};

const EnvVariables = (props: EnvVariablesProps) => {
  const {
    match: {
      params: { appId },
    },
    loaded,
    envVariables,
    fetchEnvVariables,
    updateEnvVariables,
    error,
    canUpdate,
  } = props;
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const formElement = useRef<SchemaFormRef>(null);

  const handleError = useCallback(() => {
    message.error(t('thereAreErrorsInTheContentForm'));
  }, [t]);

  const handleSave = useCallback(() => {
    if (formElement.current) {
      formElement.current.submit();
    }
  }, [formElement]);

  const handleSubmit = useCallback(
    async (values: EnvVariablesType) => {
      await updateEnvVariables({ appId, envVariables: values });
    },
    [updateEnvVariables, appId],
  );
  const [
    formData,
    formLoading,
    formDirty,
    formInit,
    handleFormChange,
    handleFormSubmit,
  ] = useSchemaForm<any>(
    handleSubmit,
    t('environmentVariablesSaved'),
    t('errorSavingEnvironmentVariables'),
  );

  const envVariableKeys = Object.keys(formData);

  const variableFormSchema = {
    type: 'object',
    properties: {
      key: {
        title: t('key'),
        type: 'string',
        minLength: 1,
        pattern: '^[^\\s]*$',
      },
      value: {
        title: t('value'),
        type: 'string',
        minLength: 1,
      },
    },
    required: ['key', 'value'],
  };

  const formSchema = {
    title: t('environmentVariables'),
    type: 'object',
    properties: envVariableKeys.reduce(
      (result, key) => ({
        ...result,
        [key]: {
          type: 'string',
          title: key,
          minLength: 1,
        },
      }),
      {},
    ),
    required: envVariableKeys,
  };

  const handleRemove = useCallback(
    (key: string) => {
      handleFormChange(omit(formData, key));
    },
    [formData, handleFormChange],
  );

  const handleAddClick = useCallback(() => {
    setIsModalVisible(true);
  }, [setIsModalVisible]);

  const handleModalCancel = useCallback(() => {
    setIsModalVisible(false);
  }, [setIsModalVisible]);

  const handleModalSubmit = useCallback(
    async (values: any) => {
      handleFormChange({
        ...formData,
        [values.key]: values.value,
      });
    },
    [handleFormChange, formData],
  );

  const EnvVariableWidgetCallback = useCallback(
    (widgetProps: WidgetProps) => {
      return EnvVariableWidget(widgetProps, canUpdate);
    },
    [canUpdate],
  );

  const uiSchema = envVariableKeys.reduce(
    (result: any, key) => ({
      ...result,
      [key]: {
        'ui:widget': EnvVariableWidgetCallback,
      },
    }),
    {},
  );

  useEffect(() => {
    if (envVariables) {
      formInit(envVariables);
    }
  }, [envVariables, formInit]);

  useEffect(() => {
    fetchEnvVariables({ appId });
  }, [appId, fetchEnvVariables]);

  if (error) {
    return <ErrorView />;
  }

  if (!loaded) {
    return (
      <Overlay>
        <Spinner />
      </Overlay>
    );
  }

  if (!envVariables) {
    return null;
  }

  const isFormHidden = !envVariableKeys.length;

  return (
    <Row gutter={40}>
      <Col md={24} xl={16}>
        <PanelCard>
          <EnvVariablesContext.Provider value={{ onRemove: handleRemove }}>
            <EnvVariablesSchemaFormWrapper hidden={isFormHidden}>
              <SchemaForm
                ref={formElement}
                schema={formSchema}
                data={formData}
                onSubmit={handleFormSubmit}
                onChange={handleFormChange}
                onError={handleError}
                uiSchema={uiSchema}
              />
            </EnvVariablesSchemaFormWrapper>
          </EnvVariablesContext.Provider>
          {isFormHidden && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('noEnvironmentVariablesPresent')}
            />
          )}

          {canUpdate && (
            <Row type="flex" justify="end">
              <Col>
                <Button icon="plus" onClick={handleAddClick}>
                  {t('add')}
                </Button>
              </Col>
            </Row>
          )}

          <ModalForm
            schema={variableFormSchema}
            title={t('environmentVariable')}
            visible={isModalVisible}
            onClose={handleModalCancel}
            onSubmit={handleModalSubmit}
          >
            <DataCard>
              <ModalFormField
                name="key"
                label={t('key')}
                component={FormInput}
                required
              />
              <ModalFormField
                name="value"
                label={t('value')}
                component={FormInput}
                required
              />
            </DataCard>
          </ModalForm>
        </PanelCard>
      </Col>

      {canUpdate && (
        <Col md={24} xl={8}>
          <PanelCard>
            <Button
              loading={formLoading}
              size="large"
              block
              type="primary"
              onClick={handleSave}
              disabled={!formDirty}
            >
              {t('saveChanges')}
            </Button>
          </PanelCard>
        </Col>
      )}
    </Row>
  );
};

export default EnvVariables;
