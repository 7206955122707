import React, { useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import UserRole from '../../../../../../store/types/user-roles';
import ErrorView from '../../../../../common/error-view/error-view.component';
import TenantUser from '../../../../../../store/types/tenant-user';
import PaginatedListTable from '../../../../../common/paginated-list/paginatied-list-table';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment/moment';
import { formatDate } from '../../../../../../utils/date-formatter';
import { CapitalizeText, EmailText, NameText } from '../../../user-management/users-list/users-list.component';
import { useTranslation } from 'react-i18next';
import { PaginationCollection } from '../../../../../../store/types/pagination';
import { useActions, useStore } from 'easy-peasy';
import { RootModel, RootState } from '../../../../../../store/models/root.model';
import styled from '@emotion/styled';

interface SpaceUsersProps
  extends RouteComponentProps<{
    organisationId: string;
    spaceId: string;
  }> {
  isUserListLoading: boolean;
  isUserListFetching: boolean;
  isError: boolean;
  organisationId: string;
  usersList: PaginationCollection<TenantUser> | undefined;
}

type OrgRoles = Record<string, Record<string, UserRole>>;

function generateUserRolesMapping(usersData: PaginationCollection<TenantUser> | undefined, orgRoles: OrgRoles, organisationId: string): Record<string, UserRole> {

  if (!usersData || !orgRoles) return {};

  const rolesMapping: Record<string, UserRole> = {};
  const { docs: users } = usersData;

  users.forEach((user) => {
    if (orgRoles[organisationId] !== undefined && user.roleId !== undefined) {
      const orgRole = orgRoles[organisationId];
      rolesMapping[user.id] = orgRole[user.roleId];
    }
  });

  return rolesMapping;
}

const SpaceUsers = (props: SpaceUsersProps) => {
  const {
    usersList,
    isUserListLoading,
    isUserListFetching,
    isError,
    organisationId,
  } = props;

  const { t } = useTranslation();

  const {
    fetchUserRoles,
  } = useActions<RootModel>((actions) => ({
    fetchUserRoles: actions.userRoles.fetch,
  }));

  useEffect(() => {
    fetchUserRoles({ organizationId: organisationId });
  }, [fetchUserRoles, organisationId]);

  const { userRoles } = useStore<
    RootState
  >((state) => ({
    userRoles: state.userRoles.data,
  }));

  const userRolesMapping = useMemo(() =>
  generateUserRolesMapping(usersList, userRoles, organisationId), [
    usersList,
    userRoles,
    organisationId,
  ]);

  const columns = useMemo<ColumnProps<TenantUser>[]>(() => {
    return [
      {
        title: t('user'),
        key: 'organization-user',
        width: '27%',
        render: (_, record) => {
          record.firstName = record.firstName || '';
          record.lastName = record.lastName || '';
          const fullName = record.firstName + ' ' + record.lastName;
          return (
            <>
              <NameText>{fullName}</NameText>
              <EmailText>{record.email}</EmailText>
            </>
          );
        },
      },
      {
        title: t('role'),
        key: 'organization-user-role',
        width: '15%',
        render: (_, record) => (
          <StyledRow>
            <CapitalizeText>
              {userRolesMapping[record.id] ? userRolesMapping[record.id].displayName : ''}
            </CapitalizeText>
          </StyledRow>
        ),
      },
      {
        title: t('status'),
        key: 'organization-status',
        width: '15%',
        render: (_, record) => (
          <CapitalizeText>
            {t('active')}
          </CapitalizeText>
        ),
      },
      {
        title: t('lastSeen'),
        key: 'organization-last-seen',
        width: '15%',
        render: (_, record) => (
          <>
            <p>
              {record.lastSeenAt ? moment(record.lastSeenAt).fromNow() : t('notAvailable')}
            </p>
            <small>
              {formatDate({ dateToFormat: record.lastSeenAt })}
            </small>
          </>
        ),
      },
    ];
  }, [t, userRolesMapping]);

  return (
    <>
      {isError && !isUserListLoading &&
        <ErrorView />
      }
      {!isError &&
        <>
          <PaginatedListTable<TenantUser>
            data={usersList!}
            columns={columns}
            isDataLoading={isUserListLoading || isUserListFetching}
            />
          </>
      }
    </>
  );
};

const StyledRow = styled.div`
  padding: 1.3em 0em;
`;

export default SpaceUsers;
