import React, { useCallback, useEffect, useMemo } from 'react';
import { Avatar, List } from 'antd';
import { useTranslation } from 'react-i18next';
import FederatedDomain, {
  FederatedIdentityProviderEnum,
} from '../../../store/types/federated-domain';
import Header from '../../common/app-layout/header/header.component';
import CrudList from '../../common/crud-list/crud-list.component';

interface OrganisationsListProps {
  federatedDomains: FederatedDomain[];
  loaded: boolean;
  fetchFederatedDomains: () => void;
  createFederatedDomain: (params: Partial<FederatedDomain>) => Promise<void>;
  updateFederatedDomain: (params: Partial<FederatedDomain>) => Promise<void>;
  deleteFederatedDomain: (id: string) => Promise<void>;
}

const identityProviderNames: any = {
  [FederatedIdentityProviderEnum.AZURE_AD]: 'Azure AD',
  [FederatedIdentityProviderEnum.GOOGLE]: 'Google Identity',
};

const OrganisationsList = (props: OrganisationsListProps) => {
  const {
    loaded,
    federatedDomains,
    fetchFederatedDomains,
    createFederatedDomain,
    updateFederatedDomain,
    deleteFederatedDomain,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    fetchFederatedDomains();
  }, [fetchFederatedDomains]);

  const handleDelete = useCallback(
    async (federatedDomain: FederatedDomain) => {
      await deleteFederatedDomain(federatedDomain.id);
    },
    [deleteFederatedDomain],
  );

  const handleEdit = useCallback(
    async (federatedDomain: Partial<FederatedDomain>) => {
      await updateFederatedDomain({
        ...federatedDomain,
        id: federatedDomain.id,
      });
    },
    [updateFederatedDomain],
  );

  const handleCreate = useCallback(
    async (federatedDomain: Partial<FederatedDomain>) => {
      await createFederatedDomain({
        ...federatedDomain,
      });
    },
    [createFederatedDomain],
  );

  const createSchema = useMemo(
    () => ({
      type: 'object',
      properties: {
        domain: {
          type: 'string',
          minLength: 1,
          title: t('domain'),
          pattern: '^[a-zA-Z0-9-_.]+$',
        },
        identityProvider: {
          title: t('identityProvider'),
          type: 'string',
          enum: Object.values(FederatedIdentityProviderEnum),
          enumNames: Object.values(FederatedIdentityProviderEnum).map(
            (idProvider) => identityProviderNames[idProvider],
          ),
        },
      },
      required: ['domain', 'identityProvider'],
    }),
    [t],
  );

  return (
    <>
      <Header title={t('federatedDomains')} />
      <div className="content-body">
        <CrudList<FederatedDomain>
          onCreate={handleCreate}
          onEdit={handleEdit}
          onDelete={handleDelete}
          loaded={loaded}
          createSchema={createSchema}
          updateSchema={createSchema}
          renderItem={(federatedDomain) => (
            <List.Item.Meta
              avatar={<Avatar icon="safety-certificate" />}
              title={federatedDomain.domain}
              description={identityProviderNames[federatedDomain.identityProvider]}
            />
          )}
          dataSource={federatedDomains}
          createButtonText={t('addFederatedDomain')}
          modalTitle={t('federatedDomains')}
          canCreate
          canUpdate
          canDelete
        />
      </div>
    </>
  );
};

export default OrganisationsList;
