import React, { useMemo } from 'react';
import { Card, Icon, Typography } from 'antd';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import Overlay from '../common/overlay/overlay.component';
import useQueryStringParam from '../common/use-query-string-param/use-query-string-param';

const { Title } = Typography;

const ErrorCard = styled(Card)`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
`;

const ErrorIcon = styled(Icon)`
  font-size: 64px;
  margin-bottom: 16px;
`;

const Message = styled(Title)`
  margin-top: 12px;
`;

enum SubscriptionStatusEnum {
  PENDING = 'pending',
  SUBSCRIBED = 'subscribed',
  SUSPENDED = 'suspended',
  UNSUBSCRIBED = 'unsubscribed',
}

export default function SubscriptionFallback() {
  const { t } = useTranslation();
  const query = useQueryStringParam('status');

  const message = useMemo(() => {
    switch (query) {
      case SubscriptionStatusEnum.SUSPENDED:
        return t('suspendedSubscriptionMessage');
      default:
        return t('noSubscriptionFoundMessage');
    }
  }, [t, query]);

  return (
    <Overlay>
      <ErrorCard>
        <ErrorIcon type="frown" />
        <Message level={3}>{message}</Message>
      </ErrorCard>
    </Overlay>
  );
}
