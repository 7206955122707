import { InteractionType, SessionInteractionType } from '@ombori/grid-reports';
import { DataSourceBaseEntity } from '@ombori/grid-reports/dist/types';
import { useQuery } from 'react-query';
import {
  AggregatedInstallationNpsByDay,
  BaseProps,
  InstallationAndSpaceEventsByDay,
  InstallationCategoriesByDay,
  InstallationEventsByDay,
  InstallationEventsByHour,
  InstallationEventsFlowByDay,
  InstallationInteractiveSessionsByDay,
  InstallationInteractiveSessionsByHour,
  InstallationProductsByDay,
  InstallationPurchaseProductByDay,
  InstallationPurchasesByDay,
  InstallationQrCodesByDay,
  InstallationMediaByDay,
  InstallationSessionsByDay,
  InstallationSessionsByHour,
  TimeSpanType,
  UseEventsFlowByDayProps,
} from './types';
import { useGridDataService } from '../../grid-data-service-provider';
import buildQueryKey from './build-query-key';
import routes from '../routes';

interface UseInstallationEventsByDayProps extends BaseProps {
  installationId: string;
  interactionType?: InteractionType;
  eventType?: string;
}

export const useInstallationEventsByDay = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  interactionType = InteractionType.All,
  dataResidency,
  eventType,
}: UseInstallationEventsByDayProps) => {
  const gridDataService = useGridDataService();

  const buildInstallationEventsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'installationId',
    installationId,
    'interactionType',
    interactionType,
    'events',
    ...(eventType ? ['eventType', eventType] : []),
  ];

  const baseQuery = {
    dateFrom,
    dateTo,
    timespanType: TimeSpanType.Day,
    interactionType,
  };

  const query = eventType ? { ...baseQuery, eventType } : baseQuery;

  return useQuery(
    buildInstallationEventsQuery(),
    () =>
      gridDataService.get<InstallationEventsByDay[]>(
        routes.getInstallationEvents(tenantId, installationId),
        dataResidency,
        query,
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseInstallationEventsBySpaceAndDay extends BaseProps {
  installationId: string;
  interactionType?: InteractionType;
  dataSource?: DataSourceBaseEntity;
  eventType?: string;
}

export const useInstallationEventsBySpaceAndDay = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  interactionType = InteractionType.All,
  dataResidency,
  dataSource,
  eventType,
}: UseInstallationEventsBySpaceAndDay) => {
  const gridDataService = useGridDataService();

  const buildInstallationAndSpaceEventsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'installationId',
    installationId,
    'interactionType',
    interactionType,
    'events',
    'dataSource',
    dataSource,
    ...(eventType ? ['eventType', eventType] : []),
  ];

  const baseQuery = {
    dateFrom,
    dateTo,
    timespanType: TimeSpanType.Day,
    interactionType,
    dataSource,
  };

  const query = eventType ? { ...baseQuery, eventType } : baseQuery;

  return useQuery(
    buildInstallationAndSpaceEventsQuery(),
    () =>
      gridDataService.get<InstallationAndSpaceEventsByDay[]>(
        routes.getInstallationEvents(tenantId, installationId),
        dataResidency,
        query,
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseInstallationSessionsByDayProps extends BaseProps {
  installationId: string;
  interactionType?: SessionInteractionType;
}

export const useInstallationSessionsByDay = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  dataResidency,
  interactionType = SessionInteractionType.All,
}: UseInstallationSessionsByDayProps) => {
  const gridDataService = useGridDataService();

  const buildInstallationSessionsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'interactionType',
    interactionType,
    'installationId',
    installationId,
    'sessions',
  ];

  return useQuery(
    buildInstallationSessionsQuery(),
    () =>
      gridDataService.get<
        typeof interactionType extends SessionInteractionType.Interactive
          ? InstallationInteractiveSessionsByDay[]
          : InstallationSessionsByDay[]
      >(routes.getInstallationSessions(tenantId, installationId), dataResidency, {
        dateFrom,
        dateTo,
        interactionType,
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseInstallationsEventsAndSessionsByDayProps extends BaseProps {
  interactionType?: InteractionType;
  eventType?: string;
  isEventsQueryEnabled?: boolean;
  isSessionsQueryEnabled?: boolean;
}

export const useInstallationsEventsAndSessionsByDay = ({
  tenantId,
  dateFrom,
  dateTo,
  interactionType = InteractionType.All,
  dataResidency,
  eventType,
  isEventsQueryEnabled = true,
  isSessionsQueryEnabled = true,
}: UseInstallationsEventsAndSessionsByDayProps) => {
  const gridDataService = useGridDataService();

  const buildInstallationEventsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'installations',
    'interactionType',
    interactionType,
    'eventsAndSessions',
    ...(eventType ? ['eventType', eventType] : []),
  ];

  const baseQuery = {
    dateFrom,
    dateTo,
    timespanType: TimeSpanType.Day,
    interactionType,
  };

  const query = eventType ? { ...baseQuery, eventType } : baseQuery;

  const fetchEvents = () =>
    isEventsQueryEnabled
      ? gridDataService.get<InstallationEventsByDay[]>(
          routes.getInstallationsEvents(tenantId),
          dataResidency,
          query,
        )
      : Promise.resolve([]);

  const fetchSessions = () =>
    isSessionsQueryEnabled
      ? gridDataService.get<InstallationSessionsByDay[]>(
          routes.getInstallationsSessions(tenantId),
          dataResidency,
          query,
        )
      : Promise.resolve([]);

  const queryFn = async () => {
    const [events, sessions] = await Promise.all([fetchEvents(), fetchSessions()]);

    return { events, sessions };
  };

  return useQuery(buildInstallationEventsQuery(), queryFn, {
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
  });
};

interface UseInstallationNpsByDayProps extends BaseProps {
  installationId: string;
}

export const useInstallationNpsByDay = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  dataResidency,
}: UseInstallationNpsByDayProps) => {
  const gridDataService = useGridDataService();

  const buildInstallationNpsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'installationId',
    installationId,
    'nps',
  ];

  return useQuery(
    buildInstallationNpsQuery(),
    () =>
      gridDataService.get<AggregatedInstallationNpsByDay>(
        routes.getInstallationNps(tenantId, installationId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseInstallationEventsFlowByDayProps extends UseEventsFlowByDayProps {
  installationId: string;
}

export const useInstallationEventsFlowByDay = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  dataResidency,
  eventsFlowDepth,
}: UseInstallationEventsFlowByDayProps) => {
  const gridDataService = useGridDataService();

  const buildInstallationEventsFlowQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'installationId',
    installationId,
    'eventsFlowDepth',
    eventsFlowDepth,
    'eventsFlow',
  ];

  return useQuery(
    buildInstallationEventsFlowQuery(),
    () =>
      gridDataService.get<InstallationEventsFlowByDay[]>(
        routes.getInstallationEventsFlow(tenantId, installationId),
        dataResidency,
        {
          dateFrom,
          dateTo,
          eventsFlowDepth,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseInstallationProductsByDayProps extends BaseProps {
  installationId: string;
}

export const useInstallationProductsByDay = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  dataResidency,
}: UseInstallationProductsByDayProps) => {
  const gridDataService = useGridDataService();

  const buildInstallationProductsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'installationId',
    installationId,
    'products',
  ];

  return useQuery(
    buildInstallationProductsQuery(),
    () =>
      gridDataService.get<InstallationProductsByDay[]>(
        routes.getInstallationProducts(tenantId, installationId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseInstallationCategoriesByDayProps extends BaseProps {
  installationId: string;
}

export const useInstallationCategoriesByDay = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  dataResidency,
}: UseInstallationCategoriesByDayProps) => {
  const gridDataService = useGridDataService();

  const buildInstallationCategoriesQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'installationId',
    installationId,
    'categories',
  ];

  return useQuery(
    buildInstallationCategoriesQuery(),
    () =>
      gridDataService.get<InstallationCategoriesByDay[]>(
        routes.getInstallationCategories(tenantId, installationId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseInstallationPurchasesByDayProps extends BaseProps {
  installationId: string;
}

export const useInstallationPurchasesByDay = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  dataResidency,
}: UseInstallationPurchasesByDayProps) => {
  const gridDataService = useGridDataService();

  const buildInstallationPurchasesQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'installationId',
    installationId,
    'purchases',
  ];

  return useQuery(
    buildInstallationPurchasesQuery(),
    () =>
      gridDataService.get<InstallationPurchasesByDay[]>(
        routes.getInstallationPurchases(tenantId, installationId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseInstallationPurchasedProductsByDayProps extends BaseProps {
  installationId: string;
}

export const useInstallationPurchasedProductsByDay = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  dataResidency,
}: UseInstallationPurchasedProductsByDayProps) => {
  const gridDataService = useGridDataService();

  const buildInstallationPurchasesQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'installationId',
    installationId,
    'purchased-products',
  ];

  return useQuery(
    buildInstallationPurchasesQuery(),
    () =>
      gridDataService.get<InstallationPurchaseProductByDay[]>(
        routes.getInstallationPurchasedProducts(tenantId, installationId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseInstallationQrCodesByDayProps extends BaseProps {
  installationId: string;
}

export const useInstallationQrCodesByDay = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  dataResidency,
}: UseInstallationQrCodesByDayProps) => {
  const gridDataService = useGridDataService();

  const buildInstallationQrCodesQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'installationId',
    installationId,
    'qr-codes',
  ];

  return useQuery(
    buildInstallationQrCodesQuery(),
    () =>
      gridDataService.get<InstallationQrCodesByDay[]>(
        routes.getInstallationQrCodes(tenantId, installationId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseInstallationMediaByDayProps extends BaseProps {
  installationId: string;
}

export const useInstallationMediaByDay = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  dataResidency,
  isEnabled = true,
}: UseInstallationMediaByDayProps) => {
  const gridDataService = useGridDataService();

  const buildInstallationMediaQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType: TimeSpanType.Day }),
    'installationId',
    installationId,
    'media',
  ];

  return useQuery(
    buildInstallationMediaQuery(),
    () =>
      gridDataService.get<InstallationMediaByDay[]>(
        routes.getInstallationMedia(tenantId, installationId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      enabled: isEnabled,
    },
  );
};

export const useInstallationSessionsByHour = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  dataResidency,
  interactionType = SessionInteractionType.All,
}: UseInstallationSessionsByDayProps) => {
  const gridDataService = useGridDataService();

  const timespanType = TimeSpanType.Hour;

  const buildInstallationSessionsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType }),
    'interactionType',
    interactionType,
    'installationId',
    installationId,
    'sessions',
  ];

  return useQuery(
    buildInstallationSessionsQuery(),
    () =>
      gridDataService.get<
        typeof interactionType extends SessionInteractionType.Interactive
          ? InstallationInteractiveSessionsByHour[]
          : InstallationSessionsByHour[]
      >(routes.getInstallationSessions(tenantId, installationId), dataResidency, {
        dateFrom,
        dateTo,
        timespanType,
        interactionType,
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export const useInstallationEventsByHour = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  interactionType = InteractionType.All,
  dataResidency,
}: UseInstallationEventsByDayProps) => {
  const gridDataService = useGridDataService();

  const timespanType = TimeSpanType.Hour;

  const buildInstallationEventsQuery = () => [
    ...buildQueryKey({ tenantId, dateFrom, dateTo, timespanType }),
    'installationId',
    installationId,
    'interactionType',
    interactionType,
    'events',
  ];

  return useQuery(
    buildInstallationEventsQuery(),
    () =>
      gridDataService.get<InstallationEventsByHour[]>(
        routes.getInstallationEvents(tenantId, installationId),
        dataResidency,
        {
          dateFrom,
          dateTo,
          timespanType,
          interactionType,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};
