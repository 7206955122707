import i18next from 'i18next';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/sv';

dayjs.extend(relativeTime);

export const utcToLocalDate = (dateTime?: Date): dayjs.Dayjs => {
  const utcDateTime = dayjs.utc(dateTime);
  const localDateTime = utcDateTime
    .local()
    .locale(i18next.language);

  return localDateTime;
};

export default dayjs;
