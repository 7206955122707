import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import { RouteComponentProps } from 'react-router';
import { RootModel, RootState } from '../../../../../store/models/root.model';
import GridProductsUploadComponent from './grid-products-upload.component';
import { MainHeader } from '../grid-products.components';

type GridProductsUploadProps = RouteComponentProps<{
  organisationId: string;
}>;

const GridProductsUploadContainer = ({ match }: GridProductsUploadProps) => {
  const {
    params: { organisationId: tenantId },
  } = match;

  const { environments, spaces, user, dataResidency } = useStore<RootState>(
    (state) => ({
      environments: state.environments.values(tenantId),
      spaces: state.organisationSpaces.values(tenantId),
      user: state.auth.user,
      dataResidency:
        (state.organisations.data &&
          state.organisations.data[tenantId] &&
          state.organisations.data[tenantId].dataResidency) ||
        'not-specified-data-residency',
    }),
    [tenantId],
  );

  const { fetchEnvironments, fetchSpaces, uploadExcel, fetchMedia } = useActions<
    RootModel
  >((actions) => ({
    fetchEnvironments: actions.environments.fetch,
    fetchMedia: actions.organisationMedia.fetchAll,
    fetchSpaces: actions.organisationSpaces.fetch,
    uploadExcel: actions.gridProducts.uploadExcel,
  }));

  return (
    <>
      <MainHeader match={match} />

      <GridProductsUploadComponent
        fetchMedia={fetchMedia}
        dataResidency={dataResidency}
        environments={environments}
        spaces={spaces}
        tenantId={tenantId}
        user={user}
        fetchEnvironments={fetchEnvironments}
        fetchSpaces={fetchSpaces}
        uploadExcel={uploadExcel}
      />
    </>
  );
};
export default GridProductsUploadContainer;
