import {
  DataMatrixColumn,
  DataMatrixColumnDataType,
  DataMatrixColumnOperationType,
} from '@ombori/grid-reports';
import toLower from 'lodash/toLower';
import snakeCase from 'lodash/snakeCase';
import toNumber from 'lodash/toNumber';
import toString from 'lodash/toString';
import { TableRecord } from './types';

const getSortFn = (column: DataMatrixColumn) => {
  const columnName = toLower(snakeCase(column.title));

  const compareNumbers = (a: TableRecord, b: TableRecord) => {
    const aVal = toNumber(a[columnName]);
    const bVal = toNumber(b[columnName]);

    return aVal - bVal;
  };

  const compareStrings = (a: TableRecord, b: TableRecord) => {
    const aVal = toString(a[columnName]);
    const bVal = toString(b[columnName]);

    return aVal.localeCompare(bVal);
  };

  switch (column.dataType.type) {
    case DataMatrixColumnDataType.Sessions: {
      return compareNumbers;
    }

    case DataMatrixColumnDataType.Events: {
      if (!column.dataType.operationType) {
        return compareNumbers;
      }

      switch (column.dataType.operationType.type) {
        case DataMatrixColumnOperationType.Sum: {
          return compareNumbers;
        }

        case DataMatrixColumnOperationType.Avg: {
          return compareStrings;
        }

        case DataMatrixColumnOperationType.Lowest: {
          return compareStrings;
        }
      }
    }
  }
};

export default getSortFn;
