import * as React from 'react';
import { get as getFromObject } from 'lodash';
import User from '../../../store/types/user';
import { isInRole } from '../../../utils/auth/permissions';

interface ProtectionSectionProps {
  permissionPath?: string;
  permissionGroupId?: string;
  roleIds?: string[];
  user?: User | null;
  children: React.ReactNode;
  settingsOverridingRule?: string[];
}

const settingsOverridingRulePathRegex: Record<string, string> = {
  global: '/settings-configuration/global-settings$',
  space: '/settings-configuration/space/',
  device: '/settings-configuration/device/',
};
const ProtectedSection = ({
  permissionPath,
  user,
  permissionGroupId,
  roleIds,
  children,
  settingsOverridingRule,
}: ProtectionSectionProps) => {
  const hasRouteAccess = React.useMemo(() => {
    let hasAccessByRole = false;
    if (roleIds === undefined) {
      hasAccessByRole = true;
    } else {
      hasAccessByRole =
        !!user &&
        !!(
          user.isSysadmin ||
          ((permissionPath && getFromObject(user.permissions, permissionPath, false)) ||
            (permissionGroupId && roleIds && isInRole(user, permissionGroupId, roleIds)))
        );
    }

    let hasAccessBySettingsOverridingRule = false;
    if (settingsOverridingRule === undefined) {
      hasAccessBySettingsOverridingRule = true;
    } else {
      const urlPath = window.location.pathname;

      hasAccessBySettingsOverridingRule = settingsOverridingRule.some((ruleId) => {
        const regEx = settingsOverridingRulePathRegex[ruleId];
        return !!regEx && RegExp(regEx).test(urlPath);
      });
    }

    return hasAccessByRole && hasAccessBySettingsOverridingRule;
  }, [permissionGroupId, permissionPath, roleIds, settingsOverridingRule, user]);

  return hasRouteAccess ? <>{children}</> : null;
};

export default ProtectedSection;
