import React, { useEffect, useMemo, useState } from 'react';
import { useActions, useStore } from 'easy-peasy';
import { RootModel } from '../../../store/models/root.model';
import OrganisationsList from '../../common/organisations-list/organisations-list.component';
import { getPermissions } from '../../../utils/auth/permissions';
import Organisation from '../../../store/types/organisation';
import User from '../../../store/types/user';
import usePlansList from '../../../store/hooks/plans/use-plans-list';

type OrganisationsListContainerProps = Omit<
  React.ComponentProps<typeof OrganisationsList>,
  | 'organisations'
  | 'loaded'
  | 'fetchOrganisations'
  | 'createOrganisation'
  | 'updateOrganisation'
  | 'deleteOrganisation'
  | 'canCreate'
  | 'canUpdate'
  | 'canDelete'
  | 'validateSlug'
  | 'isCurrentUserSysadmin'
  | 'managingUsers'
  | 'plans'
>;

const OrganisationsListContainer = (props: OrganisationsListContainerProps) => {
  const {
    fetchOrganisations,
    createOrganisation,
    updateOrganisation,
    deleteOrganisation,
    validateSlug,
    fetchUsers,
    fetchCurrentUser,
  } = useActions<RootModel>((actions) => ({
    fetchOrganisations: actions.organisations.fetch,
    createOrganisation: actions.organisations.create,
    updateOrganisation: actions.organisations.update,
    deleteOrganisation: actions.organisations.delete,
    fetchUsers: actions.users.fetch,
    fetchCurrentUser: actions.auth.getCurrentUser,
    validateSlug: actions.organisations.validateSlug,
  }));

  const {
    organisations,
    users,
    loaded,
    permissions,
    isCurrentUserSysadmin,
  } = useStore<RootModel>((state) => ({
    organisations: state.organisations.values,
    users: state.users.values,
    loaded: state.organisations.loaded,
    permissions: getPermissions(state.auth.user, props.match.params.organisationId),
    isCurrentUserSysadmin: state.auth.isSysAdmin,
  }));

  // hack - we have a lot of rerenderings due to reuploading organizations and refreshing this page. Limit amount of users requests to one
  const [hasAskedForUsers, setHasAskedForUsers] = useState(false);
  const managingOrgId = useMemo(() => {
    const managingOrg: Organisation = organisations.find(
      (org: Organisation) => org.organizationName === 'ombori',
    );
    return managingOrg ? managingOrg.id : null;
  }, [organisations]);

  useEffect(() => {
    if (managingOrgId && !hasAskedForUsers) {
      setHasAskedForUsers(true);
      fetchUsers({ organizationId: isCurrentUserSysadmin ? '' : managingOrgId });
    }
  }, [fetchUsers, hasAskedForUsers, isCurrentUserSysadmin, managingOrgId]);

  const managingUsers = useMemo(() => {
    const managingOrg: Organisation = organisations.find(
      (org: Organisation) => org.organizationName === 'ombori',
    );
    return managingOrg
      ? (users || []).filter((user: User) => {
          return (
            user.isSysadmin ||
            !!user.organizationsWithRole.find(
              (orgWithRole) => orgWithRole.organizationId === managingOrg.id,
            )
          );
        })
      : [];
  }, [users, organisations]);

  const { data: plans } = usePlansList(1, 1000);

  return (
    <OrganisationsList
      {...props}
      organisations={organisations}
      loaded={loaded}
      fetchOrganisations={fetchOrganisations}
      createOrganisation={createOrganisation}
      updateOrganisation={updateOrganisation}
      deleteOrganisation={deleteOrganisation}
      validateSlug={validateSlug}
      canCreate={false}
      canUpdate={permissions.organizations.update}
      canDelete={permissions.organizations.remove}
      isCurrentUserSysadmin={isCurrentUserSysadmin}
      managingUsers={managingUsers}
      plans={plans && plans.docs ? plans.docs : []}
      fetchCurrentUser={fetchCurrentUser}
    />
  );
};

export default OrganisationsListContainer;
