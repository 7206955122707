import React from 'react';
import { useTranslation } from 'react-i18next';
import { GridAssistantStatusEnum } from '@ombori/grid-llm-react';
import styled from '@emotion/styled';
import { Tag } from 'antd';

interface StoreAIAssistantStatusTagProps {
  className?: string;
  status: GridAssistantStatusEnum;
}

const StoreAIAssistantStatusTag = (props: StoreAIAssistantStatusTagProps) => {
  const { className, status } = props;

  const { t } = useTranslation();

  switch (status) {
    case GridAssistantStatusEnum.ACTIVE:
      return <ActiveTag className={className}>{t('storeAI.active')}</ActiveTag>;
    case GridAssistantStatusEnum.DISABLED:
      return <InactiveTag className={className}>{t('storeAI.disabled')}</InactiveTag>;
    default:
      return <UnknownTag className={className}>{t('storeAI.unknown')}</UnknownTag>;
  }
};

const BaseTag = styled(Tag)`
  border-radius: 40px;
  padding: 1px 8px;
  font-size: 12px;
`;

const ActiveTag = styled(BaseTag)`
  background-color: #d9f7be;
  color: #135200;
  border-color: #135200;
  border-width: 0px;
`;

const InactiveTag = styled(BaseTag)`
  background-color: #ffa39e;
  color: #a8071a;
  border-color: #a8071a;
  border-width: 0px;
`;

const UnknownTag = styled(BaseTag)`
  border-width: 0.5px;
`;

export default React.memo(StoreAIAssistantStatusTag);
