import { useRef } from 'react';
import isEqual from 'lodash/isEqual';

const useDeepCompareMemoize = <T = any>(value: T): T => {
  const refMemoizedValue = useRef<T>(value);

  if (!isEqual(value, refMemoizedValue.current)) {
    refMemoizedValue.current = value;
  }

  return refMemoizedValue.current;
};

export default useDeepCompareMemoize;
