import { createStore, createTypedHooks } from 'easy-peasy';
import rootModel, { RootModel, RootState } from './models/root.model';
import Injections from './injections.interface';

const initializeStore = (initialState: Partial<RootState>, injections: Injections) => {
  return createStore<RootModel>(rootModel, { initialState, injections });
};

const typedHooks = createTypedHooks<RootModel>();

export const useStoreActions = typedHooks.useActions;
export const useStoreDispatch = typedHooks.useDispatch;
export const useStoreState = typedHooks.useStore;

export default initializeStore;
