import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import toLower from 'lodash/toLower';
import { Button, Alert } from 'antd';
import { FieldArray } from 'react-final-form-arrays';
import { OnChange } from 'react-final-form-listeners';
import {
  FormTextInput,
  InputCheckbox,
  Label as LabelComponent,
  PanelCardMain,
  RowStyled,
} from '../styled-block';
import {
  RegistrationFieldCheckbox,
  RegistrationFieldEmail,
  RegistrationFieldInput,
  RegistrationFieldPhone,
  RegistrationFieldRadio,
  RegistrationFieldTextarea,
  RegistrationFieldCategory,
} from '../../../../../../store/types/queue';
import ValidationRulesBuilder from './validation-rules-builder';
import LanguagePickerComponent from '../text-in-app-customization-lanugage-picker';
import { required } from '../validate-form';
import { buildRadioOption } from './data-builder';

const transformLabelToValue = (label: string) =>
  label
    .split(' ')
    .map(toLower)
    .join('-');

interface FieldTitleProps {
  title: string;
  onRemove: () => void;
}

const FieldTitle: React.FC<FieldTitleProps> = ({ title, onRemove }) => (
  <FieldTitleContainer>
    <FieldTitleText>{title}</FieldTitleText>

    <LanguagePicker />

    <RemoveFieldButton onClick={onRemove} icon="minus" />
  </FieldTitleContainer>
);

interface FieldNameProps {
  fieldName: string;
  disabled?: boolean;
}

const FieldName: React.FC<FieldNameProps> = ({ fieldName, disabled = false }) => {
  const { t } = useTranslation();

  return (
    <Field name={`${fieldName}.name`} validate={required}>
      {({ input, meta }) => {
        return (
          <FormTextInput
            required
            label={t('fieldName')}
            input={input}
            meta={meta}
            fullWidthLabel
            hint={t('fieldNameHint')}
            disabled={disabled}
          />
        );
      }}
    </Field>
  );
};

const FieldTitleContainer = styled(LabelComponent)`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  text-transform: uppercase;
`;

const FieldTitleText = styled.div`
  margin-right: 15px;
`;

interface BaseFieldProps {
  fieldName: string;
  isNameDisabled?: boolean;
  activeLanguage: string;
  onRemove: () => void;
  hint?: string;
  change: (name: string, value: any) => void;
}

interface InputFieldProps extends RegistrationFieldInput, BaseFieldProps {}

export const InputField: React.FC<InputFieldProps> = ({
  fieldName,
  activeLanguage,
  onRemove,
  isNameDisabled = false,
  hint,
}) => {
  const { t } = useTranslation();

  return (
    <FieldContainer>
      <FieldTitle title={t('inputField')} onRemove={onRemove} />

      <FieldRow>
        <FieldName fieldName={fieldName} disabled={isNameDisabled} />
      </FieldRow>

      <FieldRow>
        <Field name={`${fieldName}.label[${activeLanguage}]`}>
          {({ input, meta }) => {
            return <FormTextInput label={t('fieldLabel')} input={input} meta={meta} />;
          }}
        </Field>
      </FieldRow>

      <FieldRow>
        <Field name={`${fieldName}.placeholder[${activeLanguage}]`}>
          {({ input, meta }) => {
            return (
              <FormTextInput label={t('fieldPlaceholder')} input={input} meta={meta} />
            );
          }}
        </Field>
      </FieldRow>

      <FieldRow>
        <Field name={`${fieldName}.value`}>
          {({ input, meta }) => {
            return <FormTextInput label={t('fieldValue')} input={input} meta={meta} />;
          }}
        </Field>
      </FieldRow>

      <FieldRow>
        <ValidationRulesBuilder fieldName={fieldName} />
      </FieldRow>

      {!!hint && (
        <FieldRow>
          <Alert message={hint} type="info" showIcon />
        </FieldRow>
      )}
    </FieldContainer>
  );
};

interface TextareaFieldProps extends RegistrationFieldTextarea, BaseFieldProps {}

export const TextareaField: React.FC<TextareaFieldProps> = ({
  fieldName,
  activeLanguage,
  onRemove,
  isNameDisabled = false,
  hint,
}) => {
  const { t } = useTranslation();

  return (
    <FieldContainer>
      <FieldTitle title={t('textareaField')} onRemove={onRemove} />

      <FieldRow>
        <FieldName fieldName={fieldName} disabled={isNameDisabled} />
      </FieldRow>

      <FieldRow>
        <Field name={`${fieldName}.label[${activeLanguage}]`} validate={required}>
          {({ input, meta }) => {
            return (
              <FormTextInput required label={t('fieldLabel')} input={input} meta={meta} />
            );
          }}
        </Field>
      </FieldRow>

      <FieldRow>
        <Field name={`${fieldName}.value`}>
          {({ input, meta }) => {
            return <FormTextInput label={t('fieldValue')} input={input} meta={meta} />;
          }}
        </Field>
      </FieldRow>

      <FieldRow>
        <ValidationRulesBuilder fieldName={fieldName} />
      </FieldRow>

      {!!hint && (
        <FieldRow>
          <Alert message={hint} type="info" showIcon />
        </FieldRow>
      )}
    </FieldContainer>
  );
};

interface CheckboxFieldProps extends RegistrationFieldCheckbox, BaseFieldProps {}

export const CheckboxField: React.FC<CheckboxFieldProps> = ({
  fieldName,
  activeLanguage,
  onRemove,
  isNameDisabled = false,
  hint,
}) => {
  const { t } = useTranslation();

  return (
    <FieldContainer>
      <FieldTitle title={t('checkboxField')} onRemove={onRemove} />

      <FieldRow>
        <FieldName fieldName={fieldName} disabled={isNameDisabled} />
      </FieldRow>

      <FieldRow>
        <Field name={`${fieldName}.label[${activeLanguage}]`} validate={required}>
          {({ input, meta }) => {
            return (
              <FormTextInput required label={t('fieldLabel')} input={input} meta={meta} />
            );
          }}
        </Field>
      </FieldRow>

      <FieldRow>
        <Field name={`${fieldName}.checked`} type="checkbox">
          {({ input }) => (
            <RowStyled>
              <Label>
                <InputCheckbox {...input} type="checkbox" />
                {t('precheckedField')}
              </Label>
            </RowStyled>
          )}
        </Field>
      </FieldRow>

      <FieldRow>
        <ValidationRulesBuilder fieldName={fieldName} />
      </FieldRow>

      {!!hint && (
        <FieldRow>
          <Alert message={hint} type="info" showIcon />
        </FieldRow>
      )}
    </FieldContainer>
  );
};

interface RadioFieldProps extends RegistrationFieldRadio, BaseFieldProps {}

export const RadioField: React.FC<RadioFieldProps> = ({
  fieldName,
  activeLanguage,
  onRemove,
  isNameDisabled = false,
  hint,
  change,
}) => {
  const { t } = useTranslation();

  return (
    <FieldContainer>
      <FieldTitle title={t('radioField')} onRemove={onRemove} />

      <FieldRow>
        <FieldName fieldName={fieldName} disabled={isNameDisabled} />
      </FieldRow>

      <FieldRow>
        <Field name={`${fieldName}.label[${activeLanguage}]`} validate={required}>
          {({ input, meta }) => {
            return (
              <FormTextInput required label={t('fieldLabel')} input={input} meta={meta} />
            );
          }}
        </Field>
      </FieldRow>

      <FieldRow>
        <Label>{t('radioOptions')}</Label>

        <FieldArray key={`${fieldName}.options`} name={`${fieldName}.options`}>
          {({ fields }) => {
            return (
              <>
                {fields.map((optionName, index) => {
                  return (
                    <RadioFieldOption key={optionName}>
                      <PanelCardMain>
                        <Field
                          name={`${optionName}.label[${activeLanguage}]`}
                          validate={required}
                        >
                          {({ input, meta }) => {
                            return (
                              <FormTextInput
                                required
                                label={t('radioOptionLabel')}
                                input={input}
                                meta={meta}
                              />
                            );
                          }}
                        </Field>

                        <Field name={`${optionName}.value`}>
                          {({ input, meta }) => {
                            return (
                              <>
                                <FormTextInput
                                  label={t('radioOptionValue')}
                                  input={input}
                                  meta={meta}
                                />

                                <OnChange name={`${optionName}.label.en`}>
                                  {(value) => {
                                    change(
                                      `${optionName}.value`,
                                      transformLabelToValue(value),
                                    );
                                  }}
                                </OnChange>
                              </>
                            );
                          }}
                        </Field>

                        <RemoveOptionButton
                          onClick={() => fields.remove(index)}
                          icon="minus"
                        />
                      </PanelCardMain>
                    </RadioFieldOption>
                  );
                })}

                <AddOptionButton
                  onClick={() => fields.push(buildRadioOption())}
                  icon="plus"
                />
              </>
            );
          }}
        </FieldArray>
      </FieldRow>

      <FieldRow>
        <ValidationRulesBuilder fieldName={fieldName} />
      </FieldRow>

      {!!hint && (
        <FieldRow>
          <Alert message={hint} type="info" showIcon />
        </FieldRow>
      )}
    </FieldContainer>
  );
};

interface PhoneFieldProps extends RegistrationFieldPhone, BaseFieldProps {}

export const PhoneField: React.FC<PhoneFieldProps> = ({
  fieldName,
  activeLanguage,
  onRemove,
  isNameDisabled = false,
  hint,
}) => {
  const { t } = useTranslation();

  return (
    <FieldContainer>
      <FieldTitle title={t('phoneField')} onRemove={onRemove} />

      <FieldRow>
        <FieldName fieldName={fieldName} disabled={isNameDisabled} />
      </FieldRow>

      <FieldRow>
        <Field name={`${fieldName}.label[${activeLanguage}]`}>
          {({ input, meta }) => {
            return <FormTextInput label={t('fieldLabel')} input={input} meta={meta} />;
          }}
        </Field>
      </FieldRow>

      <FieldRow>
        <Field name={`${fieldName}.placeholder[${activeLanguage}]`}>
          {({ input, meta }) => {
            return (
              <FormTextInput label={t('fieldPlaceholder')} input={input} meta={meta} />
            );
          }}
        </Field>
      </FieldRow>

      <FieldRow>
        <Field name={`${fieldName}.value`}>
          {({ input, meta }) => {
            return <FormTextInput label={t('fieldValue')} input={input} meta={meta} />;
          }}
        </Field>
      </FieldRow>

      <FieldRow>
        <ValidationRulesBuilder fieldName={fieldName} />
      </FieldRow>

      {!!hint && (
        <FieldRow>
          <Alert message={hint} type="info" showIcon />
        </FieldRow>
      )}
    </FieldContainer>
  );
};

interface EmailFieldProps extends RegistrationFieldEmail, BaseFieldProps {}

export const EmailField: React.FC<EmailFieldProps> = ({
  fieldName,
  activeLanguage,
  onRemove,
  isNameDisabled = false,
  hint,
}) => {
  const { t } = useTranslation();

  return (
    <FieldContainer>
      <FieldTitle title={t('emailField')} onRemove={onRemove} />

      <FieldRow>
        <FieldName fieldName={fieldName} disabled={isNameDisabled} />
      </FieldRow>

      <FieldRow>
        <Field name={`${fieldName}.label[${activeLanguage}]`}>
          {({ input, meta }) => {
            return <FormTextInput label={t('fieldLabel')} input={input} meta={meta} />;
          }}
        </Field>
      </FieldRow>

      <FieldRow>
        <Field name={`${fieldName}.placeholder[${activeLanguage}]`}>
          {({ input, meta }) => {
            return (
              <FormTextInput label={t('fieldPlaceholder')} input={input} meta={meta} />
            );
          }}
        </Field>
      </FieldRow>

      <FieldRow>
        <Field name={`${fieldName}.value`}>
          {({ input, meta }) => {
            return <FormTextInput label={t('fieldValue')} input={input} meta={meta} />;
          }}
        </Field>
      </FieldRow>

      <FieldRow>
        <ValidationRulesBuilder fieldName={fieldName} />
      </FieldRow>

      {!!hint && (
        <FieldRow>
          <Alert message={hint} type="info" showIcon />
        </FieldRow>
      )}
    </FieldContainer>
  );
};

interface CategoryFieldProps extends RegistrationFieldCategory, BaseFieldProps {}

export const CategoryField: React.FC<CategoryFieldProps> = ({
  fieldName,
  activeLanguage,
  onRemove,
  isNameDisabled = false,
  hint,
}) => {
  const { t } = useTranslation();

  return (
    <FieldContainer>
      <FieldTitle title={t('categoryField')} onRemove={onRemove} />

      <FieldRow>
        <FieldName fieldName={fieldName} disabled={isNameDisabled} />
      </FieldRow>

      <FieldRow>
        <Field name={`${fieldName}.label[${activeLanguage}]`} validate={required}>
          {({ input, meta }) => {
            return (
              <FormTextInput required label={t('fieldLabel')} input={input} meta={meta} />
            );
          }}
        </Field>
      </FieldRow>

      <FieldRow>
        <Field name={`${fieldName}.placeholder[${activeLanguage}]`}>
          {({ input, meta }) => {
            return (
              <FormTextInput label={t('fieldPlaceholder')} input={input} meta={meta} />
            );
          }}
        </Field>
      </FieldRow>

      <FieldRow>
        <Label>{t('categoryFieldOptions')}</Label>

        <FieldArray key={`${fieldName}.options`} name={`${fieldName}.options`}>
          {({ fields }) => {
            return (
              <>
                {fields.map((optionName) => {
                  return (
                    <CategoryFieldOption key={optionName}>
                      <PanelCardMain>
                        <Field
                          name={`${optionName}.label[${activeLanguage}]`}
                          validate={required}
                        >
                          {({ input, meta }) => {
                            return (
                              <FormTextInput
                                required
                                label={t('categoryFieldOptionLabel')}
                                input={input}
                                meta={meta}
                                disabled
                              />
                            );
                          }}
                        </Field>

                        <Field name={`${optionName}.value`}>
                          {({ input, meta }) => {
                            return (
                              <>
                                <FormTextInput
                                  label={t('categoryFieldOptionValue')}
                                  input={input}
                                  meta={meta}
                                  disabled
                                />
                              </>
                            );
                          }}
                        </Field>
                      </PanelCardMain>
                    </CategoryFieldOption>
                  );
                })}
              </>
            );
          }}
        </FieldArray>
      </FieldRow>

      <FieldRow>
        <ValidationRulesBuilder fieldName={fieldName} />
      </FieldRow>

      {!!hint && (
        <FieldRow>
          <Alert message={hint} type="info" showIcon />
        </FieldRow>
      )}
    </FieldContainer>
  );
};

const FieldContainer = styled.div``;

const FieldRow = styled.div`
  margin-bottom: 10px;
`;

const LanguagePicker = styled(LanguagePickerComponent)`
  margin-bottom: 0;
`;

const RemoveFieldButton = styled(Button)`
  margin-left: auto;
` as any;

const RadioFieldOption = styled.div``;

const RemoveOptionButton = styled(Button)`` as any;

const AddOptionButton = styled(Button)`` as any;

const Label = styled(LabelComponent)`
  display: block;
`;

const CategoryFieldOption = styled.div``;
