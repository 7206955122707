import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import { RootModel, RootState } from '../../../../store/models/root.model';
import SubscriptionSetup from './setup-subscription.component';

type OrganisationSetupContainerProps = Omit<
  React.ComponentProps<typeof SubscriptionSetup>,
  'createSubscription'
>;

const SubscriptionSetupContainer = (props: OrganisationSetupContainerProps) => {
  const {
    match: {
      params: { organisationId },
    },
  } = props;

  const createSubscription = useActions<RootModel>(
    (actions) => actions.subscriptions.createStripeSubscriptionAndRedirect,
  );

  const { organisation } = useStore<RootState>(
    ({ organisations }) => ({
      organisation: organisations.data && organisations.data[organisationId],
    }),
    [organisationId],
  );

  return (
    <SubscriptionSetup
      {...props}
      organisation={organisation}
      createSubscription={createSubscription}
    />
  );
};

export default SubscriptionSetupContainer;
