import { useMutation, useQueryClient } from 'react-query';
import { useApiService } from '../../../app/common/api-service-provider';
import libraryQueryKeys from './library-query-keys';
import Media from '../../types/media';

const useContentLibraryDeleteMedia = (tenantId: string) => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  return useMutation(
    async (mediaId: string) => {
      return apiService.delete<Media[]>(`/api/media/${mediaId}`, { tenantId });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(libraryQueryKeys.tenantContentLibraryMediaList(tenantId));
      },
    },
  );
};

export default useContentLibraryDeleteMedia;
