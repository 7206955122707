import React, { useCallback } from 'react';
import { RouteComponentProps } from 'react-router';
import ContentLibrary from './content-library.component';
import { useTranslation } from 'react-i18next';

interface ContentLibraryContainerProps
  extends RouteComponentProps<{ organisationId: string; selectedFolderId?: string }> {}

const ContentLibraryContainer = (props: ContentLibraryContainerProps) => {
  const {
    match: {
      params: { organisationId, selectedFolderId },
    },
    history,
  } = props;

  const { t } = useTranslation();

  const handleSelectedFolder = useCallback(
    (selectedFolder: string | undefined) => {
      let parentFolderId = '';
      if (selectedFolder) {
        parentFolderId = selectedFolder;
      }
      history.push(`/organisations/${organisationId}/content/library/${parentFolderId}`);
    },
    [history, organisationId],
  );

  return (
    <ContentLibrary
      titleForPreviewModal={t('contents.contentPreview')}
      tenantId={organisationId}
      selectedFolderId={selectedFolderId}
      onFolderSelect={handleSelectedFolder}
      disableAppSettingsForm={true}
    />
  );
};

export default ContentLibraryContainer;
