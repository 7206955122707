import { action, Action, Select, select, thunk, Thunk } from 'easy-peasy';
import { ApiError } from '../../../services/api/api-error';
import Injections from '../../injections.interface';

export interface CrudModel<T extends { id: string } = any, P = void> {
  data: { [id: string]: T } | null;
  error: ApiError | null;
  loading: boolean;
  ids: Select<CrudModel<T, P>, string[]>;
  values: Select<CrudModel<T, P>, T[]>;
  loaded: Select<CrudModel<T, P>, boolean>;
  setData: Action<CrudModel<T, P>, T[]>;
  setSingle: Action<CrudModel<T, P>, T>;
  unsetSingle: Action<CrudModel<T, P>, string>;
  setError: Action<CrudModel<T, P>, ApiError | null>;
  setLoading: Action<CrudModel<T, P>, boolean>;
  fetch: Thunk<CrudModel<T, P>, P, Injections>;
  create: Thunk<CrudModel<T, P>, Partial<T>, Injections>;
  update: Thunk<CrudModel<T, P>, Partial<T>, Injections>;
  delete: Thunk<CrudModel<T, P>, string, Injections>;
}

export default function createCrudModel<T extends { id: string } = any, P = void>(
  path: string,
) {
  const model: CrudModel<T, P> = {
    loading: false,
    error: null,
    data: null,
    ids: select((state) => Object.keys(state.data || {})),
    values: select((state) => Object.values(state.data || {})),
    loaded: select((state) => !!state.data && !state.loading),
    setData: action((state, payload) => {
      state.data = {};
      payload.forEach((item) => {
        // @ts-ignore
        state.data[item.id] = item;
      });
    }),
    setSingle: action((state, payload) => {
      if (!state.data) {
        state.data = {};
      }
      state.data[payload.id] = payload;
    }),
    unsetSingle: action((state, id) => {
      if (state.data) {
        delete state.data[id];
      }
    }),
    setLoading: action((state, payload) => {
      state.loading = payload;
    }),
    setError: action((state, payload) => {
      state.error = payload;
    }),
    fetch: thunk(async (actions, payload, { injections }) => {
      actions.setLoading(true);
      actions.setError(null);
      try {
        const data = await injections.apiService.get<T[]>(path, payload || {});
        actions.setData(data);
      } catch (err) {
        actions.setError(err);
      } finally {
        actions.setLoading(false);
      }
    }),
    create: thunk(async (actions, payload, { injections }) => {
      const data = await injections.apiService.post<T>(path, payload);
      actions.setSingle(data);
      return data.id;
    }),
    update: thunk(async (actions, payload, { injections }) => {
      const data = await injections.apiService.put<T>(`${path}/${payload.id}`, payload);
      actions.setSingle(data);
    }),
    delete: thunk(async (actions, id, { injections }) => {
      await injections.apiService.delete<T>(`${path}/${id}`);
      actions.unsetSingle(id);
    }),
  };

  return model;
}
