import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Station } from '../../../../../store/types/queue';
import EditableListOfInputs from './editable-list-of-inputs';

const Stations: React.FC = () => {
  const { t } = useTranslation();

  return (
    <EditableListOfInputs
      title={t('queueStations')}
      keyFromValues="stations"
      getFieldName={(key) => `${key}.label`}
      getInitialValue={(): Station => ({
        id: uuidv4(),
        label: '',
      })}
    />
  );
};

export default Stations;
