import * as React from 'react';
import { ObjectFieldTemplateProps } from 'react-jsonschema-form';
import ObjectFieldTypeEnum from './object-field-type.enum';
import MainSection from './main-section/main-section.component';
import ObjectCard from './object-card/object-card.component';
import ObjectCollapse, {
  ObjectCollapseUiOptions,
} from './object-collapse/object-collapse.component';

const ObjectField = (props: ObjectFieldTemplateProps) => {
  const { properties, idSchema, uiSchema, description, title } = props;
  const content = properties.map((element) => element.content);

  if (
    uiSchema.objectFieldTemplate === ObjectFieldTypeEnum.NONE ||
    uiSchema['ui:field'] === 'none'
  ) {
    return <div>{content}</div>;
  }

  if (
    uiSchema.objectFieldTemplate === ObjectFieldTypeEnum.MAIN_SECTION ||
    uiSchema['ui:field'] === 'main'
  ) {
    return <MainSection title={title}>{content}</MainSection>;
  }

  // TO DO: Please deprecate the use of ui:field to set if Accordion is open or not for new gridapps. Use `ui:options.isExpanded` instead
  // `ui:field.collapsible/expandable` is actually not a custom schema field
  const isFieldAccordion =
    uiSchema['ui:field'] === 'collapsible' || uiSchema['ui:field'] === 'expandable';

  if (
    uiSchema.objectFieldTemplate === ObjectFieldTypeEnum.ACCORDION ||
    isFieldAccordion
  ) {
    const options = (uiSchema['ui:options'] || {}) as ObjectCollapseUiOptions;
    const {
      isExpanded = true,
      isBordered = false,
      isContentPadded = true,
      hasDivider = true,
      icon,
      gap = 8,
      className,
    } = options;

    const isOpen = isExpanded || uiSchema['ui:field'] === 'collapsible';

    return (
      <ObjectCollapse
        className={className}
        title={title}
        description={description}
        id={idSchema.$id}
        isExpanded={isOpen}
        isBordered={isBordered}
        isContentPadded={isContentPadded}
        hasDivider={hasDivider}
        helpText={uiSchema['ui:help'] as string}
        icon={icon}
        gap={gap}
      >
        {content}
      </ObjectCollapse>
    );
  }

  return (
    <ObjectCard title={title} description={description}>
      {content}
    </ObjectCard>
  );
};

export default ObjectField;
