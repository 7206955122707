const formatTimeComponent = (component: number): string =>
  component < 10 ? `0${component}` : `${component}`;

const formatTime = (hour: number, minute: number) =>
  `${formatTimeComponent(hour)}:${formatTimeComponent(minute)}`;

const generateTimeOptions = (minutes: number[]) => {
  const hours = new Array(24).fill(null).map((_, index) => index);
  return hours.flatMap((hour) => minutes.map((minute) => formatTime(hour, minute)));
};

export default generateTimeOptions;
