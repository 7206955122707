import React from 'react';
import { RouteComponentProps, Switch } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import ErrorBoundary from '../../../common/error-boundary/error-boundary.component';
import ProtectedRouteContainer from '../../../common/protected-route/protected-route.container';
import { getPermissionPath, permissionKeys } from '../../../../utils/auth/permissions';
import Overview from './contact-overview';
import ContactDetails from './contact-details';

type ContactsProps = RouteComponentProps<{ organisationId: string }>;

enum Paths {
  overview = 'overview',
}

const Contacts = ({ match }: ContactsProps) => {
  const {
    params: { organisationId: tenantId },
  } = match;

  return (
    <ErrorBoundary>
      <Switch>
        <ProtectedRouteContainer
          permissionPath={getPermissionPath(tenantId, permissionKeys.users.viewSingle)}
          path={`${match.path}/${Paths.overview}/:contactId`}
          component={ContactDetails}
        />
        <ProtectedRouteContainer
          permissionPath={getPermissionPath(tenantId, permissionKeys.users.viewAll)}
          path={`${match.path}/${Paths.overview}`}
          component={Overview}
        />
        <Route
          exact
          path={match.path}
          render={() => <Redirect to={`${match.url}/${Paths.overview}`} />}
        />
      </Switch>
    </ErrorBoundary>
  );
};

export default Contacts;
