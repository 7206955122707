import * as React from 'react';
import { useActions, useStore } from 'easy-peasy';
import MediaLibrary from './media-library.component';
import { RootModel, RootState } from '../../../store/models/root.model';
import { getPermissions } from '../../../utils/auth/permissions';

type MediaLibraryContainerProps = Omit<
  React.ComponentProps<typeof MediaLibrary>,
  | 'mediaItems'
  | 'fetchMediaItems'
  | 'loaded'
  | 'uploadMedia'
  | 'deleteMedia'
  | 'subscribeChanges'
  | 'canViewMedia'
  | 'canUpdateMedia'
  | 'canUploadMedia'
  | 'canRemoveMedia'
>;

const MediaLibraryContainer = (props: MediaLibraryContainerProps) => {
  const { onSelectMediaItem, organisationId } = props;

  const { mediaItems, loaded, permissions } = useStore<RootState>((state) => ({
    mediaItems: state.organisationMedia.values,
    loaded: state.organisationMedia.loaded,
    permissions: getPermissions(state.auth.user, organisationId),
  }));

  const { fetchMediaItems, uploadMedia, deleteMedia, subscribeChanges } = useActions<
    RootModel
  >((actions) => ({
    fetchMediaItems: actions.organisationMedia.fetch,
    uploadMedia: actions.organisationMedia.upload,
    deleteMedia: actions.organisationMedia.delete,
    subscribeChanges: actions.organisationMedia.subscribe,
  }));

  return (
    <MediaLibrary
      mediaItems={mediaItems}
      fetchMediaItems={fetchMediaItems}
      loaded={loaded}
      uploadMedia={uploadMedia}
      deleteMedia={deleteMedia}
      onSelectMediaItem={onSelectMediaItem}
      subscribeChanges={subscribeChanges}
      canViewMedia={permissions.media.viewAll}
      canUpdateMedia={permissions.media.update}
      canRemoveMedia={permissions.media.remove}
      canUploadMedia={permissions.media.upload}
      {...props}
    />
  );
};

export default MediaLibraryContainer;
