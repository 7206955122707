import React, { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Row, Col, Button, Typography, Divider } from 'antd';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useTranslation } from 'react-i18next';
import PanelCard from '../../../../common/panel-card/panel-card.component';
import { StickyColumn } from '../../queue-details/queue-setup/styled-block';
import { ButtonType, ChangeEvent } from '../../../../../types';
import { Input, scrollIntoView, validateCharacterLength } from '../../../../common/react-final-form';
import DatePicker from '../../../../common/react-final-form/date-picker';
import SegmentFormContentSection from './segment-form-content-section.component';
import SegmentFormContentTargetingSection from './segment-form-content-targeting-section.component';
import { SegmentConditions } from '../../../../../store/hooks/segments/types';
import { requiredAll } from '../../queue-details/queue-setup/validate-form';
import { SegmentAppContent, SegmentContentTypeEnum, SegmentMediaContent, SegmentTagsContent } from '../../../../../store/types/segment';
import { generateSegmentTagsCombinations, generateSegmentTagsFromChannelTags, hasChannelTags } from './utils';
import useTagsList from '../../../../../store/hooks/content-tags/use-tags-list';
import SegmentFormTagsContentSection from './segment-form-tags-content-section.component';
import { Channel } from '../../../../../store/types/channel';
import { MediaContentPickerInput, MediaContentPickerSelection, MediaContentPickerSelectionEnum } from '../../../../common/react-final-form/media-content-picker';

export interface SegmentFormValues {
  name: string;
  startDate: string;
  endDate: string;
  conditions: SegmentConditions;
  content?: SegmentMediaContent | SegmentAppContent;
  tagsContent?: SegmentTagsContent[];
}

interface SegmentFormProps {
  title: string;
  tenantId: string;
  channel: Channel;
  initialValues: SegmentFormValues;
  onFormSubmit: (values: SegmentFormValues) => Promise<SegmentFormApiValidationErrors>;
}

type SegmentConditionValidationError = {
  _error: string;
};

interface SegmentFormValidationErrors {
  name?: string;
  startDate?: string;
  endDate?: string;
  conditions?: SegmentConditionValidationError[][];
}

export interface SegmentFormApiValidationErrors extends SegmentFormValidationErrors {}

const SegmentFormFields = (props: SegmentFormProps) => {
  const { tenantId, channel, title, initialValues, onFormSubmit } = props;

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const { t } = useTranslation();

  const { data: tagsData } = useTagsList(tenantId, 1000);

  const channelTags = channel.tags || [];

  const formInitialValues = useMemo<SegmentFormValues>(() => {
    if (!hasChannelTags(channelTags) || !tagsData) {
      return initialValues;
    }

    const formInitialValues: SegmentFormValues = {...initialValues};

    const segmentTags = generateSegmentTagsFromChannelTags(tagsData.docs, channelTags!);

    const segmentTagsCombinations: SegmentTagsContent['tags'][] = generateSegmentTagsCombinations(segmentTags);

    if (!initialValues.tagsContent || !initialValues.tagsContent.length) {
      const tagsContent = segmentTagsCombinations.map<SegmentTagsContent>((segmentTagCombination) => {
        return {
          content: undefined,
          tags: segmentTagCombination,
        }
      });
  
      formInitialValues.tagsContent = tagsContent;
    }

    return formInitialValues;
  }, [initialValues, channelTags, tagsData]);

  const handleSubmit = useCallback(
    async (values: SegmentFormValues | Object) => {
      setIsFormSubmitting(true);

      const errors = await onFormSubmit(values as SegmentFormValues);

      setIsFormSubmitting(false);

      return errors;
    },
    [onFormSubmit],
  );

  const handleSelection = useCallback((input: MediaContentPickerInput, value: MediaContentPickerSelection) => {
    if (value.type === MediaContentPickerSelectionEnum.MEDIA) {
      const mediaSelection: SegmentMediaContent = {
        type: SegmentContentTypeEnum.MEDIA,
        mediaId: value.mediaItem.id,
        mediaData: {
          ref: 'media',
          id: value.mediaItem.id,
          name: value.mediaItem.name,
          url: value.mediaItem.url,
          type: value.mediaItem.type,
        }
      }
      input.onChange({
        target: {
          value: mediaSelection,
        }
      } as unknown as ChangeEvent<SegmentMediaContent>)
    } else if (value.type === MediaContentPickerSelectionEnum.APP) {
      const appSelection: SegmentAppContent = {
        type: SegmentContentTypeEnum.APP,
        gridAppId: value.gridApp.id,
        gridAppBuildId: value.gridAppBuild.id,
        appSettingsData: value.appSettingsData,
      };
      input.onChange({
        target: {
          value: appSelection
        }
      } as unknown as ChangeEvent<SegmentAppContent>)
    }
  }, []);

  const checkValidations = useCallback(
    (values: SegmentFormValues | Object): SegmentFormValidationErrors => {
      const errors: SegmentFormValidationErrors = {
        conditions: [],
      };

      const { startDate, endDate, conditions } = values as SegmentFormValues;

      if (startDate && endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);
        if (end <= start) {
          errors.endDate = t('contents.segmentEndDateBeforeStartDateError');
        }
      }

      if (conditions && conditions.length) {
        conditions.forEach((conditionGroup, index) => {
          if (!conditionGroup || !conditionGroup.length) {
            errors.conditions![index] = [{ _error: t('formValidation.atLeastOneEntryRequired') }];
          }
        });
      }

      return errors;
    },
    [t],
  );

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={formInitialValues}
      validate={checkValidations}
      mutators={{
        ...arrayMutators,
      }}
      render={({ handleSubmit, invalid, form: { change: updateForm, getState } }) => {
        return (
          <form
            id="segment-form"
            onSubmit={(event) => {
              handleSubmit(event);

              if (invalid) {
                scrollIntoView('.error-detail');
              }
            }}
          >
            <Row gutter={{ md: 20, xl: 40 }}>
              <Col md={40} xl={15}>
                <FormFieldsContainer>
                  <Row>
                    <Typography.Title level={3}>{title}</Typography.Title>
                  </Row>

                  <DividerFull />

                  <Row>
                    <FieldsContainer>
                      <Input
                        name="name"
                        label={t('contents.name')}
                        validators={[requiredAll, validateCharacterLength(3, 200)]}
                      />
                    </FieldsContainer>
                  </Row>

                  <Row gutter={[10, 10]}>
                    <FieldsContainer>
                      <Col span={12}>
                        <DatePicker
                          name="startDate"
                          label={t('contents.startDate')}
                          dateFormat="YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"
                          showTime={true}
                          isRequired={true}
                        />
                      </Col>

                      <Col span={12}>
                        <DatePicker
                          name="endDate"
                          label={t('contents.endDate')}
                          dateFormat="YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"
                          showTime={true}
                          isRequired={true}
                        />
                      </Col>
                    </FieldsContainer>
                  </Row>
                </FormFieldsContainer>

                {!hasChannelTags(channelTags) && (
                  <FormFieldsContainer>
                    <SegmentFormContentSection tenantId={tenantId} content={formInitialValues.content} handleSelection={handleSelection} />
                  </FormFieldsContainer>
                )}

                {hasChannelTags(channelTags) && (
                  <FormFieldsContainer>
                    <SegmentFormTagsContentSection tenantId={tenantId} tagsContent={formInitialValues.tagsContent} handleSelection={handleSelection} />
                  </FormFieldsContainer>
                )}

                <FormFieldsContainer>
                  <SegmentFormContentTargetingSection
                    tenantId={tenantId}
                    channel={channel}
                    updateForm={updateForm}
                    formState={getState()}
                  />
                </FormFieldsContainer>
              </Col>

              <StickyColumn md={24} xl={9}>
                <PanelCard>
                  <SaveButton
                    type="primary"
                    form="segment-form"
                    htmlType="submit"
                    size="large"
                    loading={isFormSubmitting}
                  >
                    {t('contents.saveAllChanges')}
                  </SaveButton>
                </PanelCard>
              </StickyColumn>
            </Row>
          </form>
        );
      }}
    />
  );
};

export default SegmentFormFields;

const FormFieldsContainer = styled(PanelCard)`
  padding: 20px;
  width: 100% !important;
  margin-bottom: 20px;

  .ant-card-body {
    padding: 0px !important;
  }
`;

const FieldsContainer = styled.div`
  margin-bottom: 15px;
`;

const SaveButton = styled(Button)`
  width: 100%;
` as ButtonType;

const DividerFull = styled(Divider)`
  width: calc(100% + 41px);
  margin-left: -20px;
  overflow: none;
`;
