import styled from '@emotion/styled';
import { Button, Divider as AntdDivider } from 'antd';
import PanelCardComponent from '../../../../../common/panel-card/panel-card.component';
import { ButtonType } from '../../../../../../types';

export const PanelCard = styled(PanelCardComponent)`
  padding: 20px;
  margin-bottom: 16px;
  width: 100%;
  .ant-card-head {
    border-bottom: unset;
  }
  .ant-card-body {
    padding: 0;
  }
`;

export const Title = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
`;

export const Description = styled.p`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 8px;
`;

export const Divider = styled(AntdDivider)`
  width: calc(100% + 40px);
  margin: 20px -20px;
`;


export const BackToListButton = styled(Button)`
  height: 36px;
  font-size: 14px;
  color: #676973;
  border: none;
  margin-right: 5px;
  background-color: transparent;
  box-shadow: none;

  :hover,
  :active,
  :focus {
    background-color: #f2f4f8;
  }
` as ButtonType;

export const FormContainer = styled.div`
    position: relative;
    flex: 1;
    padding: 30px 80px 40px;

    @media screen and (max-width: 991px) {
        padding: 0;
    }
`;

export const NotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;