import styled from '@emotion/styled';
import { Typography } from 'antd';

const { Title: AntdTitle } = Typography;

const Title = styled(AntdTitle)<{ size?: number; weight?: number | string }>`
  ${({ size = 16 }) => `font-size: ${size}px !important;`}
  ${({ weight }) => {
    if (!weight) {
      return '';
    }

    if (typeof weight === 'number') {
      return `font-weight: ${weight} !important;`;
    }

    return `font-weight: ${weight} !important;`;
  }};
`;

export default Title;
