import React from 'react';
import styled from '@emotion/styled';
import { toPairs } from 'lodash';
import { List, Tooltip } from 'antd';
import Card from './card.component';
import { Day, Event } from '../utils';

const truncate = (str: string) =>
  str.length > 50 ? `${str.substring(0, 50).trim()}...` : str;

export default function ListCard({
  title,
  info,
  data,
  hidePercentage,
}: {
  title: string;
  info?: string;
  data: Day[];
  hidePercentage?: boolean;
}) {
  const listData = toPairs<number>(
    data.reduce((acc: any, [, events]) => {
      events.forEach(({ label, value }: Event) => {
        if (label) {
          acc[label] = (acc[label] || 0) + value;
        }
      });

      return acc;
    }, {}),
  ).sort(([, a]: any, [, b]: any) => b - a);

  const total = listData.reduce((acc, [, value]) => acc + value, 0);

  return (
    <Card title={title} info={info}>
      <List
        size="small"
        dataSource={listData}
        style={{ maxHeight: 224, overflowY: 'auto' }}
        renderItem={([label, value]) => (
          <ListItem>
            <Tooltip placement="top" title={label}>
              <ItemName>{truncate(label)}</ItemName>
            </Tooltip>
            <ItemPercent>
              {hidePercentage ? '' : `${Math.floor((value / total) * 10000) / 100}%`}
            </ItemPercent>
            <ItemValue>{value}</ItemValue>
          </ListItem>
        )}
      />
    </Card>
  );
}

const ListItem = styled(List.Item)`
  display: flex;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
`;

const ItemName = styled.span`
  color: rgba(0, 0, 0, 0.65);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 320px;
`;

const ItemPercent = styled.span`
  display: inline-block;
  padding-left: 8px;
`;

const ItemValue = styled.span`
  flex: 1;
  text-align: right;
`;
