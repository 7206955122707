import React from 'react';
import styled from '@emotion/styled';
import { RouteComponentProps } from 'react-router';
import AppsLibraryListItem from './apps-library-solutions-list-item.component';

type PublicAppsProps = RouteComponentProps<{ organisationId: string }>;

interface AppsLibraryListProps {
  onItemSelect: (params: any) => void;
  gridapps: any[];
  onInstall: (params: any) => void;
  hoverable?: boolean;
  className?: string;
}

const AppsLibraryList = ({
  gridapps,
  onInstall,
  onItemSelect,
  hoverable = true,
  className,
}: AppsLibraryListProps) => {
  return (
    <div>
      <Solutions className={className}>
        {gridapps.map((gridapp) => (
          <AppsLibraryListItem
            onItemSelect={onItemSelect}
            key={gridapp.id}
            hoverable={hoverable}
            author={gridapp.author}
            title={gridapp.displayName}
            description=""
            coverPhoto={gridapp.coverPhoto}
            onInstall={() => onInstall(gridapp)}
          />
        ))}
      </Solutions>
    </div>
  );
};

const Solutions = styled.div`
  display: block;
  padding: 8px 4px;

  @media screen and (min-width: 768px) {
    padding: 56px;
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: 1200px) {
    gap: 24px;
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (min-width: 1400px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export default AppsLibraryList;
