import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Tag, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ActiveFilter } from '../../../store/types/filters';

interface FilterTagsProps {
  activeFilters?: ActiveFilter[];
}

const FilterTags = (props: FilterTagsProps) => {
  const { activeFilters = [] } = props;

  const [moreFilterCount, setMoreFilterCount] = useState<number>(0);
  const refSelectedFilters = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (!refSelectedFilters.current) {
      return;
    }

    const hiddenTags = Array.from(refSelectedFilters.current.children).filter(
      (tag) => !tag.clientWidth,
    );

    setMoreFilterCount(hiddenTags.length);
  }, [activeFilters]);

  const activeFilterTags = useMemo((): JSX.Element[] => {
    return activeFilters.map((filter) => {
      const filterTypeLabel = filter.typeLabel;
      const filterValueLabel = filter.valueLabel;

      return (
        <Tooltip title={filterValueLabel} key={`${filterTypeLabel}-${filterValueLabel}`}>
          <TagFilter>
            {filterTypeLabel}
            <TagValue>{filterValueLabel}</TagValue>
          </TagFilter>
        </Tooltip>
      );
    });
  }, [activeFilters]);

  return (
    <>
      <SelectedFilters ref={refSelectedFilters}>{activeFilterTags}</SelectedFilters>
      <TagPlusMore visible={!!moreFilterCount}>
        {t('tagsPlusMore', { count: moreFilterCount })}
      </TagPlusMore>
      <TagPlusFilters visible={!!activeFilterTags.length} count={activeFilterTags.length}>
        {t('tagsPlusFilters', { count: activeFilterTags.length })}
      </TagPlusFilters>
    </>
  );
};

const SelectedFilters = styled.div`
  display: flex;
  align-self: center;
`;

const TagPlusMore = styled(Tag)`
  font-size: 14px;
  line-height: 25px;
  margin: auto 0px auto 8px;

  @media (max-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
    display: none;
  }
`;

const TagPlusFilters = styled(Tag)<{ count?: number }>`
  font-size: 14px;
  line-height: 25px;
  margin: auto 0px auto 8px;
  display: none;

  @media (max-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
    ${({ count }) => (count ? `display: inline-flex;` : '')}
  }
`;

const TagFilter = styled(Tag)`
  font-size: 14px;
  line-height: 25px;
  margin: auto 0px auto 8px;
  display: inline-flex;

  :first-of-type {
    margin-left: 0px;
  }

  :nth-of-type(n + 3) {
    display: none;
  }

  @media (max-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
    :nth-of-type(n + 1) {
      display: none;
    }
  }
`;

const TagValue = styled.span`
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  margin-left: 3px;
  color: #2364aa;
`;

export default FilterTags;
