import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import OrganisationApp from '../../../store/types/organisation-app';
import OrganisationSpace from '../../../store/types/organisation-space';
import FilterItem from '../../../store/types/filters';
import { SortCriteria } from '../../../store/types/sort';

interface UseDeviceFilterOptionsResponse {
  filterOptions: FilterItem[];
  sortCriteriaOptions: SortCriteria[];
}

interface UseDeviceFilterOptionsProps {
  apps?: OrganisationApp[];
  spaces?: OrganisationSpace[];
}

// TO DO: Rename file
const useDeviceFilterOptions = (props?: UseDeviceFilterOptionsProps): UseDeviceFilterOptionsResponse => {
  const { apps = [], spaces = [] } = (props as UseDeviceFilterOptionsProps) || {};

  const { t } = useTranslation();

  const filterOptions = useMemo((): FilterItem[] => {
    const installationOptions = apps.map((installation) => {
      return {
        id: installation.id,
        label: installation.displayName,
      };
    });

    const spaceOptions = spaces.map((space) => {
      return {
        id: space.id,
        label: space.displayName,
      };
    });

    return [
      {
        id: 'deviceType',
        label: t('filterDevice.deviceType'),
        options: [
          { id: 'iothub', label: 'Grid OS' },
          { id: 'android', label: 'Android' },
          { id: 'android_unmanaged', label: 'Android (Unmanaged)' },
          { id: 'tizen', label: 'Tizen' },
          { id: 'windows', label: 'Windows' },
          { id: 'macos', label: 'Mac OS' },
          { id: 'browser', label: 'Generic Browser' },
        ],
      },
      ...(installationOptions.length
        ? [
          {
            id: 'installationId',
            label: t('filterDevice.installation'),
            options: installationOptions,
          },
        ]
        : []),
      ...(spaceOptions.length
        ? [{ id: 'spaceId', label: t('filterDevice.space'), options: spaceOptions }]
        : []),
    ];
  }, [apps, spaces, t]);

  const sortCriteriaOptions = useMemo((): SortCriteria[] => {
    return [
      {
        id: 'createdAt',
        label: t('sortDevice.dateAdded'),
        type: 'date',
      },
      // TO DO: Enable if type/id (backend) vs labels (frontend) are resolved
      // {
      //   id: 'parentDevice.type',
      //   label: t('sortDevice.deviceType'),
      //   type: 'string',
      // },
      {
        id: 'updatedAt',
        label: t('sortDevice.lastUpdated'),
        type: 'date',
      },
      {
        id: 'displayName',
        label: t('sortDevice.deviceName'),
        type: 'string',
      },
    ];
  }, [t]);

  return {
    filterOptions,
    sortCriteriaOptions,
  };
};

export default useDeviceFilterOptions;
