import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RichTable from '../../../../../common/rich-table/rich-table.component';
import OrganisationApp from '../../../../../../store/types/organisation-app';
import getInstallationAppIcon from '../../../../../../utils/installations/get-installation-app-icon-url';
import UniversalDevice from '../../../../../../store/types/universal-device';

interface UniversalDeviceDetailsAssociateInstallations {
  device: UniversalDevice;
  organisationId: string;
  apps: {
    [organisationName: string]: OrganisationApp[];
  };
}

const ImageStyle = styled.img`
  margin-right: 4px;
`;

const UniversalDeviceDetailsAssociatedInstallations = ({
  device,
  apps,
  organisationId,
}: UniversalDeviceDetailsAssociateInstallations) => {
  const { t } = useTranslation();

  const appsList = useMemo(() => {
    return apps
      ? Object.keys(apps).reduce(
        (result: OrganisationApp[], organisationName: string) => [
          ...result,
          ...apps[organisationName],
        ],
        [],
      )
      : [];
  }, [apps]);

  const associatedInstallationsDataSource = useMemo(() => {
    if (!device) return [];
    let relatedApps: Partial<OrganisationApp>[] = [];

    if (device.appIds && device.appIds.length) {
      relatedApps = device.appIds.map(
        (appId) => appsList.find((app) => app.id === appId) || { id: appId },
      );
    }

    return relatedApps.map((app) => ({
      id: app.id,
      installation: app,
      installationSlug: app.appName || t('unknown'),
      gridappVersion: app.release || t('unknown'),
    }));
  }, [appsList, device, t]);

  const columns = useMemo(
    () => [
      {
        title: t('installation'),
        key: 'installation',
        align: 'left',
        render: (item: { installation: OrganisationApp }) => {
          return (
            <Link
              key={`link-${item.installation.id}`}
              to={`/organisations/${organisationId}/apps/${item.installation.id}`}
            >
              <ImageStyle
                alt="app"
                width={32}
                height={32}
                src={getInstallationAppIcon(item.installation)}
              />
              {item.installation.displayName}
            </Link>
          );
        },
      },
      {
        title: t('installationSlug'),
        dataIndex: 'installationSlug',
        key: 'installationSlug',
      }
    ],
    [organisationId, t],
  );

  return (
    <RichTable
      dataSource={associatedInstallationsDataSource}
      columns={columns}
      scroll={{ x: columns.length > 3 }}
    />
  );
};

export default UniversalDeviceDetailsAssociatedInstallations;
