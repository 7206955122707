import React, { useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { GridAssistantStatusEnum } from '@ombori/grid-llm-react';
import { StickyColumn } from '../../../queue-details/queue-setup/styled-block';
import StoreAIAssistantFormFields, {
  StoreAIAssistantFormValues,
} from './store-ai-assistant-form-fields.component';
import StoreAISidePanel from './store-ai-assistant-form-side-panel.component';
import { useTranslation } from 'react-i18next';
import Overlay from '../../../../../common/overlay';
import useGoBack from '../../../../../common/use-go-back/use-go-back';
import { useStoreAIAssistant } from '../../../../../../store/hooks/store-ai/use-store-ai-assistant-get';
import { BackToListButton } from '../../common';

export type StoreAIAssistantViewFormProps = RouteComponentProps<{
  organisationId: string;
  storeAIAssistantId: string;
}>;

const StoreAIAssistantViewForm = (props: StoreAIAssistantViewFormProps) => {
  const { match } = props;
  const { organisationId: tenantId, storeAIAssistantId } = match.params;

  const storeAIAssistantState = useStoreAIAssistant(storeAIAssistantId);

  const { t } = useTranslation();

  const goBack = useGoBack();

  const storeAIAssistantListPath = `/organisations/${tenantId}/store-ai/assistants?page=1`;

  const handleBackClick = useCallback((): void => {
    goBack(storeAIAssistantListPath);
  }, [goBack, storeAIAssistantListPath]);

  const initialValues = useMemo((): Partial<StoreAIAssistantFormValues> => {
    if (storeAIAssistantState.data) {
      const {
        name,
        instructions,
        status,
        knowledgeSourceIds,
      } = storeAIAssistantState.data;

      const knowledgeBaseFiles = knowledgeSourceIds.map((fileId) => {
        return {
          fileId,
          fileName: fileId,
        };
      });

      return {
        name,
        instructions,
        status,
        knowledgeBaseFiles,
      };
    }

    return {
      status: GridAssistantStatusEnum.ACTIVE,
    };
  }, [storeAIAssistantState.data]);

  const lastProcessedDate = useMemo((): Date | undefined => {
    return storeAIAssistantState.data && storeAIAssistantState.data.updatedAt
      ? new Date(storeAIAssistantState.data.updatedAt)
      : undefined;
  }, [storeAIAssistantState.data]);

  return (
    <Overlay
    errorOverlay={{
      error: storeAIAssistantState.error,
    }}
    spinnerOverlay={{
      isLoading: storeAIAssistantState.isLoading,
    }}
    >
      <StoreAIAssistantViewFormContainer>
        <ActionButtons>
          <BackToListButton type="default" icon="arrow-left" onClick={handleBackClick}>
            {t('storeAI.back')}
          </BackToListButton>
        </ActionButtons>
        {storeAIAssistantState.isSuccess && (
          <Form
            onSubmit={() => {}}
            keepDirtyOnReinitialize
            initialValues={initialValues}
            mutators={{
              ...arrayMutators,
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            render={({ form: formApi }) => {
              return (
                <Row gutter={{ md: 20, xl: 40 }}>
                  <Col md={24} xl={15}>
                    <StoreAIAssistantFormFields
                      formApi={formApi}
                      tenantId={tenantId}
                      assistantId={storeAIAssistantId}
                      title={t('storeAI.aiAssistant')}
                      isViewOnly
                    />
                  </Col>
                  <StickyColumn md={24} xl={9}>
                    <StoreAISidePanel
                      lastProcessedDate={lastProcessedDate}
                      isSubmitAllowed={false}
                    />
                  </StickyColumn>
                </Row>
              );
            }}
          />
        )}
      </StoreAIAssistantViewFormContainer>
    </Overlay>
  );
};

const StoreAIAssistantViewFormContainer = styled.div`
  position: relative;
  flex: 1;
  padding: 0 80px 40px;
  @media screen and (max-width: 991px) {
    padding: 0;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export default StoreAIAssistantViewForm;
