import React, { useMemo, useCallback } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Button, message } from 'antd';
import { RouteComponentProps } from 'react-router';
import {
  SkuAvailability,
  SkuPayload,
  SkuPriceInformation,
  useSku,
  useUpdateSku,
} from '../../../common/use-skus';
import SkuFormFields, {
  PricesFieldsForAllCurrencies,
  SkuFormValues,
} from './sku-form-fields';
import {
  SkuRevenueTypeOptions,
  SkuUnitOptions,
} from './common/sku-field-options';
import { ButtonType } from '../../../../types';
import Overlay from '../../../common/overlay/overlay.component';
import { Spinner } from '../../../common/spinner';
import useGoBack from '../../../common/use-go-back';
import CurrencyAwareValue from '../../../../store/types/currency-aware-value';

interface SkuEditProps extends RouteComponentProps<{ skuId: string }> {}

const decimalScaleMultiplier = 100000;

const getDecimalPrice = (value: number) => {
  return value ? Math.round(Number(value)) / decimalScaleMultiplier : value;
};

const formatPrices = (prices: CurrencyAwareValue[]) => {
  return prices.map((price) => {
    return {
      currency: price.currency,
      value: getDecimalPrice(price.value!),
    };
  });
};

const formatPriceInformation = (priceInformation: SkuPriceInformation[]) => {
  return priceInformation.map((priceInfo) => {
    return {
      ...priceInfo,
      prices: priceInfo.prices ? formatPrices(priceInfo.prices) : [],
    };
  });
};

const SkuEditForm = (props: SkuEditProps) => {
  const {
    match: {
      params: { skuId },
    },
  } = props;

  const { t } = useTranslation();

  const { data: skuData, isLoading: isLoadingSku } = useSku(skuId);

  const initialValues = useMemo<SkuFormValues>(() => {
    return {
      id: skuData ? skuData.id : '',
      sku: skuData ? skuData.sku : '',
      name: skuData ? skuData.name : '',
      unit: skuData ? skuData.unit : SkuUnitOptions[0].value,
      availability: skuData ? skuData.availability : SkuAvailability.PRIVATE,
      tags: skuData ? skuData.tags : {},
      revenueType: skuData
        ? skuData.revenueType
        : SkuRevenueTypeOptions[0].value,
      priceInformation: skuData
        ? formatPriceInformation(skuData.priceInformation)
        : [
            {
              startDate: '',
              endDate: '',
              prices: PricesFieldsForAllCurrencies,
            },
          ],
    };
  }, [skuData]);

  const goBack = useGoBack();
  const handleGoBack = useCallback(() => {
    goBack();
  }, [goBack]);

  const { mutateAsync: updateSku } = useUpdateSku(skuId);

  const onFormSubmit = useCallback(
    async (values: SkuPayload | Object) => {
      try {
        const {
          id,
          sku,
          name,
          unit,
          availability,
          tags,
          revenueType,
          priceInformation,
        } = values as SkuPayload;

        await updateSku({
          id,
          sku,
          name,
          unit,
          availability,
          tags,
          revenueType,
          priceInformation,
        });

        message.success('SKU updated successfully!');

        return null;
      } catch (err) {
        message.error('Error while updating SKU');
        return {};
      }
    },
    [updateSku],
  );

  return (
    <>
      {isLoadingSku && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}

      {skuData && (
        <SkuFormContainer>
          <BackToListButton
            type="default"
            icon="arrow-left"
            onClick={handleGoBack}
          >
            {t('back')}
          </BackToListButton>
          <SkuFormFields
            title="Edit Sku"
            initialValues={initialValues}
            onFormSubmit={onFormSubmit}
          />
        </SkuFormContainer>
      )}

      {!isLoadingSku && !skuData && (
        <NotFoundContainer>SKU not found!</NotFoundContainer>
      )}
    </>
  );
};

const SkuFormContainer = styled.div`
  position: relative;
  flex: 1;
  padding: 30px 80px 40px;

  @media screen and (max-width: 991px) {
    padding: 0;
  }
`;

const BackToListButton = styled(Button)`
  height: 36px;
  font-size: 14px;
  color: #676973;
  border: none;
  margin-right: 5px;
  background-color: transparent;

  :hover,
  :active,
  :focus {
    background-color: #f2f4f8;
  }
` as ButtonType;

const NotFoundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export default SkuEditForm;
