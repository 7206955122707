import { Button } from 'antd';
import styled from '@emotion/styled';

const AppButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #5524da;
  color: #fff;
  font-weight: 500;
  padding: 8px 16px;
  border: 0;
  border-radius: 4px;
  height: 40px;

  &:hover,
  &:focus,
  &:active {
    background: #5524da;
    opacity: 0.8;
    color: #fff;
  }

  &:disabled {
    background: #5524da;
    opacity: 0.64;
    color: #fff;
  }
` as any;

export default AppButton;
