import React, { useEffect } from 'react';
import { useStore, useActions } from 'easy-peasy';
import { RootModel, RootState } from '../../../../../../store/models/root.model';

import ModuleSettings, { Props } from './module-settings.component';

const EMPTY_OBJECT = {};

type ControllerProps = Omit<Props, 'versions'>;

const ModuleSettingsContainer = React.memo(({ moduleId, ...rest }: ControllerProps) => {
  const { fetchModuleVersions } = useActions<RootModel>((actions) => ({
    fetchModuleVersions: actions.moduleVersions.fetch,
  }));

  useEffect(() => {
    if (moduleId) {
      fetchModuleVersions({ moduleId });
    }
  }, [fetchModuleVersions, moduleId]);

  const { versions, isSysAdmin } = useStore<RootState>(
    (state) => ({
      versions: state.moduleVersions.data[moduleId] || EMPTY_OBJECT,
      isSysAdmin: state.auth.isSysAdmin,
    }),
    [moduleId],
  );

  return (
    <ModuleSettings
      isSysAdmin={isSysAdmin}
      versions={versions}
      moduleId={moduleId}
      {...rest}
    />
  );
});

export default ModuleSettingsContainer;
