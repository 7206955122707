import React, { useMemo } from 'react';
import { useSpaces } from '../../../../common/use-spaces';
import SelectAdapter from './select-adapter';

interface SpacePickerProps {
  tenantId: string;
  input: { onChange: any; value: string };
}

const SpacePicker = (props: SpacePickerProps) => {
  const { tenantId: organisationId, input } = props;

  // TO DO: handle infinite scroll
  const { isLoading, data } = useSpaces({ organisationId, limit: 15000 });

  const spaces = useMemo<{ key: string; label: string }[]>(() => {
    const spaceList = data ? data.spaces : [];

    return spaceList.map((space) => {
      return { key: space.id, label: space.displayName };
    });
  }, [data]);

  return (
    <SelectAdapter options={spaces} input={input} isLoading={isLoading} />
  );
};

export default SpacePicker;