import React, { useCallback, useRef } from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import { RangePickerValue, RangePickerPresetRange } from 'antd/lib/date-picker/interface';

const { RangePicker: RangePickerComponent } = DatePicker;

export interface RangePickerProps {
  dateFrom: string;
  dateTo: string;
  maxDaysInRange?: number;
  ranges?: Record<string, RangePickerPresetRange>;
  onChangeRange: (date: any[], [nextFromDate, nextToDate]: [string, string]) => void;
}

const RangePicker: React.FC<RangePickerProps> = ({ dateFrom, dateTo, maxDaysInRange = 60, ranges, onChangeRange }) => {
  const startDateRef = useRef<moment.Moment | null>(null);

  const dateFormat = 'YYYY-MM-DD';

  const handleCalendarChange = useCallback((dates: RangePickerValue) => {
    if (dates && dates.length === 1 && dates[0]) {
      startDateRef.current = dates[0];
    } else {
      startDateRef.current = null;
    }
  }, []);

  const rangePickerRanges = ranges || {
    Today: [moment(), moment()],
    'Past 7 days': [moment().subtract(8, 'd'), moment().subtract(1, 'd')],
    'Past 30 days': [moment().subtract(31, 'd'), moment().subtract(1, 'd')],
    'Past 60 days': [moment().subtract(61, 'd'), moment().subtract(1, 'd')],
  };

  return (
    <RangePickerComponent
      value={[moment(dateFrom, dateFormat), moment(dateTo, dateFormat)]}
      format={dateFormat}
      ranges={rangePickerRanges}
      onChange={onChangeRange}
      onCalendarChange={handleCalendarChange}
      disabledDate={(current: moment.Moment | undefined) => {
        return !!current && ((
          startDateRef.current
            && (
              current > startDateRef.current.clone().add(maxDaysInRange, 'days')
                || current < startDateRef.current.clone().subtract(maxDaysInRange, 'days')
            )
        ) || current.isAfter(moment()));
      }}
    />
  );
};

export default RangePicker;
