import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useSpace } from '../../../../common/use-spaces';
import Overlay from '../../../../common/overlay/overlay.component';
import Spinner from '../../../../common/spinner/spinner.component';
import SpaceFormEdit from '../space-form/space-form-edit.component';

interface SpaceSettingsContainer extends RouteComponentProps<{ spaceId: string; organisationId: string }>{};

const SpaceSettings = (props: SpaceSettingsContainer) => {
  const {
    match: {
      params: { organisationId, spaceId },
    },
  } = props;

  const { t } = useTranslation();
  const spaceState = useSpace(spaceId, organisationId);

  return (
    <>
      {spaceState.isLoading && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}

      {spaceState.isSuccess && (
        <SpaceFormEdit spaceData={spaceState.data} tenantId={organisationId} spaceId={spaceId} />
      )}

      {spaceState.isError && (
        <ErrorMessage>{t('errorDuringFetchingSpaces')}</ErrorMessage>
      )}
    </>
  );
};

const ErrorMessage = styled.div`
  padding: 15px 0;
  color: rgb(255, 85, 0);
`;

export default SpaceSettings;
