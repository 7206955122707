import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

type UseGoBackResponse = (fallbackPath?: string) => void;

const useGoBack = (): UseGoBackResponse => {
  const history = useHistory();

  const goBack = useCallback(
    (fallbackPath?: string) => {
      if (history.length) {
        history.goBack();
        return;
      }

      if (fallbackPath) {
        history.push(fallbackPath);
      }
    },
    [history],
  );

  return goBack;
};

export default useGoBack;
