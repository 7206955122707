import { useQuery } from 'react-query';
import { useApiService } from '../../../app/common/api-service-provider';
import channelsQueryKeys from './channels-query-keys';
import { Channel } from '../../types/channel';
import { PaginationCollection } from '../../types/pagination';

const useChannelsList = (tenantId: string, limit = 1000, page = 1) => {
  const apiService = useApiService();

  const url = `/api/content-channel`;

  return useQuery(
    channelsQueryKeys.getChannelsList(tenantId, limit, page),
    () => apiService.get<PaginationCollection<Channel>>(url, { tenantId, limit, page }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export default useChannelsList;
