import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, List, Tag, Descriptions } from 'antd';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import Organisation from '../../../../store/types/organisation';
import { getTranslationFromObject } from '../../../../utils/language/language.util';
import { getCountryNameByCode } from '../../../../utils/countries';

interface OrganisationListItemProps {
  organisation: Organisation;
  organisations: Organisation[];
}

enum OrganizationTypeNames {
  GENERIC = 'Generic',
  PARENT_PARTNER_ORGANIZATION = 'CSP',
  SUB_PARTNER_ORGANIZATION = 'Managed by CSP',
}

const organizationTypeColors: { [test: string]: string } = {
  [OrganizationTypeNames.GENERIC]: 'green',
  [OrganizationTypeNames.PARENT_PARTNER_ORGANIZATION]: 'darkblue',
  [OrganizationTypeNames.SUB_PARTNER_ORGANIZATION]: 'blue',
};

const OrganisationListItem = React.memo(
  ({ organisation, organisations }: OrganisationListItemProps) => {
    const { t } = useTranslation();

    const parentOrganisation = useMemo(
      () => organisations.find((org) => org.id === organisation.parentOrganizationId),
      [organisations, organisation],
    );

    const itemTitle = useMemo(() => {
      let organizationType: OrganizationTypeNames = OrganizationTypeNames.GENERIC;

      if (organisation.isPartnerOrganization) {
        if (!organisation.parentOrganizationId) {
          organizationType = OrganizationTypeNames.PARENT_PARTNER_ORGANIZATION;
        } else {
          organizationType = OrganizationTypeNames.SUB_PARTNER_ORGANIZATION;
        }
      }
      return (
        <InfoContainer>
          <Link to={`/organisations/${organisation.id}`}>{organisation.displayName}</Link>
          <StyledTag color={organizationTypeColors[organizationType]}>
            {!parentOrganisation ? (
              organizationType
            ) : (
              <OrganizationType>
                <Link to={`/organisations/${parentOrganisation.id}`}>
                  {`${organizationType} / ${parentOrganisation.displayName}`}
                </Link>
              </OrganizationType>
            )}
          </StyledTag>
        </InfoContainer>
      );
    }, [organisation, parentOrganisation]);

    const tenantInfo = useMemo(() => {
      const {
        organizationName,
        apps,
        environments,
        country,
        planSubscriptions,
        vatId = '',
        dataResidency = '',
        salesEmail = '',
        csmEmail = '',
      } = organisation;
      const {
        tenant: {
          current: { planData: tenantPlanData },
        },
        support: {
          current: { planData: supportPlanData },
        },
      } = planSubscriptions;

      const tenantPlanName = tenantPlanData && tenantPlanData.name
        ? getTranslationFromObject(tenantPlanData.name)
        : '';
      const supportPlanName = supportPlanData && supportPlanData.name
        ? getTranslationFromObject(supportPlanData.name)
        : '';

      return (
        <TenantDetails column={{ xxl: 4, md: 2, sm: 1, xs: 1 }}>
          <Descriptions.Item label={t('slug')}>{organizationName}</Descriptions.Item>
          <Descriptions.Item label={t('installations')}>{apps.length}</Descriptions.Item>
          <Descriptions.Item label={t('environments')}>
            {environments.length}
          </Descriptions.Item>
          <Descriptions.Item label={t('country')}>
            {getCountryNameByCode(country)}
          </Descriptions.Item>
          <Descriptions.Item label={t('vatId')}>{vatId}</Descriptions.Item>
          <Descriptions.Item label={t('dataResidency')}>
            {dataResidency}
          </Descriptions.Item>
          <Descriptions.Item label={t('tenantPlanLabel')}>
            {tenantPlanName}
          </Descriptions.Item>
          <Descriptions.Item label={t('supportPlanLabel')}>
            {supportPlanName}
          </Descriptions.Item>
          <Descriptions.Item label={t('salesResponsible')}>
            {salesEmail}
          </Descriptions.Item>
          <Descriptions.Item label={t('csmResponsible')}>{csmEmail}</Descriptions.Item>
        </TenantDetails>
      );
    }, [organisation, t]);

    return (
      <List.Item.Meta
        avatar={<Avatar icon="solution" />}
        title={itemTitle}
        description={tenantInfo}
      />
    );
  },
);

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;

  @media only screen and (max-width: 576px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 186px;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 300px) {
    word-break: break-all;
    width: 130px;
  }
`;

const StyledTag = styled(Tag)`
  margin-left: 8px;

  @media only screen and (max-width: 576px) {
    margin-top: 10px;
    margin-left: 0px;
    outline: none;
  }
`;

const OrganizationType = styled.div`
  white-space: pre-wrap;
`;

const TenantDetails = styled(Descriptions)`
  .ant-descriptions-row > th,
  .ant-descriptions-row > td {
    padding-bottom: 0px;

    span.ant-descriptions-item-label,
    span.ant-descriptions-item-content {
      color: #00000073;
    }
  }
`;

export default OrganisationListItem;
