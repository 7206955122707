/* stylelint-disable property-no-vendor-prefix */
/* stylelint-disable value-no-vendor-prefix */
import React from 'react';
import { Tooltip } from 'antd';
import styled from '@emotion/styled';
import UserMenu from './user-menu.container';

interface NavbarProps {
  title?: string;
}

const Title = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #fff;
  vertical-align: middle;
  margin-left: 55px;
  width: 60%;
  @media only screen and (min-width: 992px), screen and (max-width: 299px) {
    width: 100%;
  }
  @media only screen and (min-width: 376px) and (max-width: 500px) {
    width: 200px;
  }
  @media only screen and (min-width: 326px) and (max-width: 375px) {
    width: 150px;
  }
  @media only screen and (min-width: 300px) and (max-width: 325px) {
    width: 130px;
  }
  @media only screen and (max-width: 324px) {
    width: calc(50% - 55px);
  }

  > span {
    overflow: hidden;
    line-height: 20px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const Nav = styled.nav`
  background-color: #202020;
  height: 55px;
  line-height: 53px;
  box-shadow: 0 0 10px;
`;

const Navbar = ({ title }: NavbarProps) => {
  return (
    <Nav>
      <Tooltip placement="bottom" title={title} overlayClassName="tooltip">
        <Title id="header-title">
          <span>{title}</span>
        </Title>
      </Tooltip>
      <UserMenu />
    </Nav>
  );
};

export default Navbar;
