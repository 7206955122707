import moment from 'moment';
import 'moment-timezone';
import { Country } from 'react-phone-number-input';

export interface ClientLocation {
  ip: string;
  country: string;
  countryCode: string;
  city: string;
  timezone: string;
}

export const getCountryByTimezone = (timezone?: string): string => {
  const tzone = timezone || moment.tz.guess();
  const momentZone = moment.tz.zone(tzone);

  if (!momentZone) {
    return '';
  }

  // @ts-ignore
  const countries = momentZone.countries();

  return countries && countries.length ? countries[0] : '';
};

export const guessClientCountry = async (): Promise<string> => {
  const countryCode = getCountryByTimezone();
  if (countryCode) {
    return countryCode;
  }

  return 'SE';
};

export const guessClientCountrySync = (): Country => {
  const countryCode = getCountryByTimezone();
  if (countryCode) {
    return countryCode as Country;
  }

  return 'SE';
};
