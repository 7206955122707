import React from 'react';
import styled from '@emotion/styled';
import { ResponsiveContainer, PieChart, Pie, Legend, Label } from 'recharts';
import numeral from 'numeral';
import { toPairs } from 'lodash';
import Card from './card.component';
import { sumDays, Day, Event } from '../utils';
import { getColor } from './colors';
import { Tooltip } from 'antd';

const truncate = (str: string) =>
  str.length > 25 ? `${str.substring(0, 25).trim()}...` : str;

const LegendItemName = styled.span`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
`;

const LegendItemPercent = styled.span`
  display: inline-block;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  padding-left: 8px;
`;

const LegendItemValue = styled.span`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  position: absolute;
  text-align: right;
  right: 0;
`;

const legendFormatter = (name: string, entry: any) => {
  const {
    payload: { percent, value },
  } = entry;

  return (
    <>
      <Tooltip placement="top" title={name}>
        <LegendItemName>{truncate(name)}</LegendItemName>
      </Tooltip>
      <LegendItemPercent>{`${Math.round(percent * 10000) / 100}%`}</LegendItemPercent>
      <LegendItemValue>{value}</LegendItemValue>
    </>
  );
};

export default function PieCard({
  title,
  info,
  data,
  showTotal = true,
}: {
  title: string;
  info?: string;
  data: Day[];
  showTotal?: boolean;
}) {
  const pieChartData = toPairs(
    data.reduce((acc: any, [, events]) => {
      events.forEach(({ label, value }: Event) => {
        if (label) {
          acc[label] = (acc[label] || 0) + value;
        }
      });

      return acc;
    }, {}),
  )
    .sort(([, a]: any, [, b]: any) => b - a)
    .map(([name, value], i, arr) => ({
      name,
      value,
      fill: getColor(i, arr.length),
    }));

  const totalTitle = `Total ${title.toLowerCase()}`;
  const totalValue = numeral(sumDays(data)).format('0,0');

  return (
    <Card title={title} info={info}>
      <ResponsiveContainer minHeight={224}>
        <PieChart>
          <Legend
            layout="vertical"
            align="right"
            verticalAlign="middle"
            wrapperStyle={{
              maxHeight: '100%',
              overflowY: 'scroll',
              width: '50%',
              left: '50%',
            }}
            iconType="circle"
            iconSize={8}
            formatter={legendFormatter}
          />
          <Pie
            data={pieChartData}
            dataKey="value"
            nameKey="name"
            startAngle={450}
            endAngle={90}
            strokeWidth={1}
            innerRadius={showTotal ? 80 : 0}
            outerRadius={112}
          >
            {showTotal && [
              <Label
                key={totalTitle}
                value={totalTitle}
                offset={0}
                position="center"
                fontSize={14}
                fill="rgba(0,0,0,0.65)"
                transform="translate(0 -16)"
                width={140}
              />,
              <Label
                key={`${totalTitle}_value`}
                value={totalValue}
                offset={0}
                position="center"
                fontSize={24}
                fill="rgba(0,0,0,0.85)"
                transform="translate(0 16)"
              />,
            ]}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Card>
  );
}
