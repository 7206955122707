import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldProps } from 'react-jsonschema-form';
import SelectTree, { TreeNode, SelectTag } from '../../common/select-tree.component';
import useLocation from '../../../use-all-spaces';
import useInstallations from '../../../use-installation';

interface UserContactPickerPropsUiOptions {
  isBordered?: boolean;
  subtitle?: string;
}

enum Type {
  GLOBAL = 'global',
  LOCATION = 'location',
  INSTALLATION = 'installation',
}

interface FormValue {
  type: Type;
  id: string;
}

interface ScopeLevelPickerProps extends FieldProps<FormValue[]> {
  rawErrors?: string[];
}

const ScopeLevelPicker = (props: ScopeLevelPickerProps) => {
  const {
    rawErrors = [],
    formData,
    onChange,
    schema,
    uiSchema,
    required: isRequired,
    readonly: isReadonly,
    formContext: { organisationId = '' },
  } = props;

  const { isBordered = true, subtitle } = (uiSchema['ui:options'] ||
    {}) as UserContactPickerPropsUiOptions;

  const helpText = uiSchema['ui:help'] as string;
  const placeholder = uiSchema['ui:placeholder'] as string;
  const isDisabled = uiSchema['ui:disabled'] as boolean;

  const { t } = useTranslation();

  // TO DO: Support default values
  const fallbackValue = isRequired ? undefined : [];

  const {
    isLoading: isLocationsLoading,
    isError: isLocationsError,
    data: locations = [],
  } = useLocation(organisationId);

  const {
    isLoading: isInstallationsLoading,
    isError: isInstallationsError,
    data: installations = [],
  } = useInstallations(organisationId);

  const isLoading = isLocationsLoading || isInstallationsLoading;

  const error =
    isLocationsError || isInstallationsError
      ? [t('userContactPicker.errorFetching')]
      : rawErrors;

  const parentOptions = useMemo(() => {
    return [
      { key: Type.GLOBAL, title: t('scopeLevel.global') },
      { key: Type.LOCATION, title: t('scopeLevel.allLocations') },
      { key: Type.INSTALLATION, title: t('scopeLevel.allInstallations') },
    ];
  }, [t]);

  const treeOptions = useMemo<TreeNode[]>(() => {
    if (!locations) {
      return [];
    }

    const locationList = locations.map((location) => {
      return {
        icon: 'environment',
        key: location.id,
        title: location.displayName,
      };
    });

    const installationList = installations.map((installation) => {
      return {
        icon: 'setting',
        key: `${installation.id}`,
        title: installation.displayName,
      };
    });

    return [
      {
        icon: 'global',
        key: Type.GLOBAL,
        title: t('scopeLevel.global'),
        children: [
          {
            icon: 'environment',
            key: Type.LOCATION,
            title: t('scopeLevel.allLocations'),
            children: locationList,
          },
          {
            icon: 'setting',
            key: Type.INSTALLATION,
            title: t('scopeLevel.allInstallations'),
            children: installationList,
          },
        ],
      },
    ];
  }, [locations, installations, t]);

  const mappedFormData = useMemo<SelectTag[]>(() => {
    if (!formData) {
      return [];
    }

    return formData.map((data) => {
      const matchedParentOption = parentOptions.find((option) => {
        return option.key === data.id;
      });

      if (matchedParentOption) {
        return {
          key: data.id,
          label: matchedParentOption.title,
          parentKey: 'root',
        };
      }

      const matchedLocation = locations.find((location) => location.id === data.id);

      if (matchedLocation) {
        return {
          key: data.id,
          label: matchedLocation.displayName,
          parentKey: data.type,
        };
      }

      const matchedInstallation = installations.find(
        (installation) => installation.id === data.id,
      );

      return {
        key: data.id,
        label: matchedInstallation ? matchedInstallation.displayName : data.id,
        parentKey: data.type,
      };
    });
  }, [formData, parentOptions, locations, installations]);

  const handleChange = useCallback(
    (value: SelectTag[]) => {
      const mappedValue = value.map((tag) => {
        return {
          id: tag.key,
          type: tag.parentKey === 'root' ? tag.key : tag.parentKey,
        };
      });

      const updatedValue = mappedValue.length ? mappedValue : fallbackValue;

      onChange(updatedValue);
    },
    [onChange, fallbackValue],
  );

  return (
    <SelectTree
      title={schema.title}
      subtitle={subtitle}
      description={schema.description}
      placeholder={placeholder}
      rawErrors={error}
      helpText={helpText}
      isReadonly={isReadonly}
      isDisabled={isDisabled}
      isRequired={isRequired}
      isBordered={isBordered}
      treeOptions={treeOptions}
      onChange={handleChange}
      value={mappedFormData}
      isLoading={isLoading}
    />
  );
};

export default ScopeLevelPicker;
