import React from 'react';
import { RouteComponentProps, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ErrorBoundary from '../../../../common/error-boundary/error-boundary.component';
import ProtectedRouteContainer from '../../../../common/protected-route/protected-route.container';
import {
  IntegrationCreateForm,
  IntegrationUpdateForm,
  IntegrationList,
  IntegrationCreateFormProps,
  IntegrationUpdateFormProps,
  IntegrationsListProps,
} from './components';
import { useStore } from 'easy-peasy';
import { RootState } from '../../../../../store/models/root.model';
import Organisation from '../../../../../store/types/organisation';
import ErrorView from '../../../../common/error-view/error-view.component';
import permissionKeys, { getPermissionPath } from '../../../../../utils/auth/permissions';

interface IntegrationsProps extends RouteComponentProps<{ organisationId: string }> {}

const Integration = (props: IntegrationsProps) => {
  const { match } = props;

  const { organisationId: tenantId } = match.params;

  const { t } = useTranslation();

  const { tenant } = useStore<
    RootState,
    {
      tenant: Organisation | null;
    }
  >((state) => {
    const result = {
      tenant: state.organisations.data && state.organisations.data[tenantId],
    };

    return result;
  });

  const { dataResidency = null } = tenant || {};

  if (!dataResidency) {
    return <ErrorView error={{ message: t('invalidDataResidency') }} />;
  }

  return (
    <ErrorBoundary>
      <Switch>
        <ProtectedRouteContainer
          exact
          permissionPath={getPermissionPath(
            tenantId,
            permissionKeys.integrations.create,
          )}
          path={`${match.path}/add/:type`}
          render={(routeProps) => (
            <IntegrationCreateForm
              {...routeProps as IntegrationCreateFormProps}
              dataResidency={dataResidency}
            />
          )}
        />
        <ProtectedRouteContainer
          exact
          permissionPath={getPermissionPath(
            tenantId,
            permissionKeys.integrations.update,
          )}
          path={`${match.path}/:integrationId/:type`}
          render={(routeProps) => (
            <IntegrationUpdateForm
              {...routeProps as IntegrationUpdateFormProps}
              dataResidency={dataResidency}
            />
          )}
        />
        <ProtectedRouteContainer
          exact
          permissionPath={getPermissionPath(
            tenantId,
            permissionKeys.integrations.viewAll,
          )}
          render={(routeProps) => (
            <IntegrationList
              {...routeProps as IntegrationsListProps}
              dataResidency={dataResidency}
            />
          )}
        />
      </Switch>
    </ErrorBoundary>
  );
};

export default Integration;
