import { action, Action, Select, select, thunk, Thunk } from 'easy-peasy';
import { ApiError } from '../../../services/api/api-error';
import Injections from '../../injections.interface';

export interface LoadableCollectionModel<T extends { id: string } = any, P = void> {
  data: { [id: string]: T } | null;
  error: ApiError | null;
  loading: boolean;
  ids: Select<LoadableCollectionModel<T, P>, string[]>;
  values: Select<LoadableCollectionModel<T, P>, T[]>;
  loaded: Select<LoadableCollectionModel<T, P>, boolean>;
  setData: Action<LoadableCollectionModel<T, P>, T[]>;
  setError: Action<LoadableCollectionModel<T, P>, ApiError | null>;
  setLoading: Action<LoadableCollectionModel<T, P>, boolean>;
  fetch: Thunk<LoadableCollectionModel<T, P>, P, Injections>;
}

export default function createLoadableCollectionModel<
  T extends { id: string } = any,
  P = void
>(path: string) {
  const model: LoadableCollectionModel<T, P> = {
    loading: false,
    error: null,
    data: null,
    ids: select((state) => Object.keys(state.data || {})),
    values: select((state) => Object.values(state.data || {})),
    loaded: select((state) => !!state.data && !state.loading),
    setData: action((state, payload) => {
      state.data = {};
      payload.forEach((item) => {
        // @ts-ignore
        state.data[item.id] = item;
      });
    }),
    setLoading: action((state, payload) => {
      state.loading = payload;
    }),
    setError: action((state, payload) => {
      state.error = payload;
    }),
    fetch: thunk(async (actions, payload, { injections }) => {
      actions.setLoading(true);
      actions.setError(null);
      try {
        const data = await injections.apiService.get<T[]>(path, payload || {});
        actions.setData(data);
      } catch (err) {
        actions.setError(err);
      } finally {
        actions.setLoading(false);
      }
    }),
  };

  return model;
}
