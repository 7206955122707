import React, { useMemo } from 'react';
import { Typography, Row, Col, Divider } from 'antd';
import { Field } from 'react-final-form';
import { FormApi } from 'final-form';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { ArrayFields } from '../../../../common/react-final-form';
import { Select } from '../../../../common/react-final-form';
import { AlertConditionTypeEnum } from '../../../../../store/types/alerts';
import DeviceStatusConditionFields from './alert-rule-form-conditions/device-status-condition-fields.component';
import DeviceAnalyticsSessionsCountConditionFields from './alert-rule-form-conditions/device-analytics-session-count-condition-fields.component';
import DeviceAnalyticsEventsCountConditionFields from './alert-rule-form-conditions/device-analytics-events-count-condition-fields.component';
import DeviceAnalyticsEventsAggregationConditionFields from './alert-rule-form-conditions/device-analytics-events-aggregation-conditiom-fields.component';
import { useLocation } from 'react-router';

interface ConditionProps {
  parentFieldName: string;
  index: number;
  updateForm: FormApi['change'];
}

const Condition = (props: ConditionProps) => {
  const { parentFieldName, index, updateForm } = props;

  const location = useLocation();

  const { t } = useTranslation();

  const conditionTypes = useMemo(() => {
    const result = [
      {
        value: AlertConditionTypeEnum.DEVICE_STATUS,
        label: t('condition.deviceStatus'),
      },
      {
        value: AlertConditionTypeEnum.DEVICE_ANALYTICS_SESSION_COUNT,
        label: t('condition.deviceAnalyticsSessionCount'),
      },
      {
        value: AlertConditionTypeEnum.DEVICE_ANALYTICS_EVENTS_COUNT,
        label: t('condition.deviceAnalyticsEventsCount'),
      },
      {
        value: AlertConditionTypeEnum.DEVICE_ANALYTICS_EVENTS_AGGREGATION,
        label: t('condition.deviceAnalyticsEventsAggregation'),
      },
    ].filter((conditionType) => {
      if (conditionType.value === AlertConditionTypeEnum.DEVICE_ANALYTICS_EVENTS_AGGREGATION) {
        return location.search.indexOf('DEVICE_ANALYTICS_EVENTS_AGGREGATION') > -1 ? true : false;
      }

      return true;
    });

    return result;
  }, [t, location]);

  return (
    <ConditionContainer>
      <ConditionNumber>
        {t('alertRules.conditionN', { n: `${index + 1}` })}
      </ConditionNumber>
      <FieldsContainer gutter={[20, 20]}>
        <Col span={24}>
          <Select
            name={`${parentFieldName}.type`}
            label={t('alertRules.type')}
            isRequired={true}
            options={conditionTypes}
            isSearchEnabled={false}
          />
        </Col>
      </FieldsContainer>

      <FieldsContainer gutter={{ md: 40, xl: 20 }}>
        <Field
          name={`${parentFieldName}.type`}
          render={({ input }) => (
            <>
              {input.value === AlertConditionTypeEnum.DEVICE_STATUS && (
                <DeviceStatusConditionFields
                  key="device-status-fields"
                  parentFieldName={parentFieldName}
                  updateForm={updateForm}
                />
              )}
              {input.value === AlertConditionTypeEnum.DEVICE_ANALYTICS_SESSION_COUNT && (
                <DeviceAnalyticsSessionsCountConditionFields
                  key="device-analytics-sessions-count-fields"
                  parentFieldName={parentFieldName}
                  updateForm={updateForm}
                />
              )}
              {input.value === AlertConditionTypeEnum.DEVICE_ANALYTICS_EVENTS_COUNT && (
                <DeviceAnalyticsEventsCountConditionFields
                  parentFieldName={parentFieldName}
                  updateForm={updateForm}
                />
              )}
              {input.value === AlertConditionTypeEnum.DEVICE_ANALYTICS_EVENTS_AGGREGATION && (
                <DeviceAnalyticsEventsAggregationConditionFields
                  parentFieldName={parentFieldName}
                  updateForm={updateForm}
                />
              )}
            </>
          )}
        />
      </FieldsContainer>

      <DividerFull />
    </ConditionContainer>
  );
};

interface AlertRuleFormConditionsSectionProps {
  updateForm: FormApi['change'];
}

const AlertRuleFormConditionsSection = (props: AlertRuleFormConditionsSectionProps) => {
  const { updateForm } = props;

  const { t } = useTranslation();

  return (
    <>
      <Typography.Title level={4}>
        {t('alertRules.conditionsSectionTitle')}
      </Typography.Title>
      <SubTitle>{t('alertRules.conditionsSectionSubTitle')}</SubTitle>

      <ArrayFieldsContainer>
        <ArrayFields
          propertyName="conditions"
          className="condition-container"
          defaultValue={{
            type: '',
          }}
          addButtonLabel={t('alertRules.addConditionLabel')}
          itemRowContent={(parentPropertyName: string, index: number) => (
            <Condition
              parentFieldName={parentPropertyName}
              index={index}
              updateForm={updateForm}
            />
          )}
        />
      </ArrayFieldsContainer>
    </>
  );
};

export default AlertRuleFormConditionsSection;

const ArrayFieldsContainer = styled.div`
  .condition-container .array-fields-delete-btn-container {
    align-self: start;
    transform: translateY(73px);
  }
`;

const ConditionContainer = styled.div`
  width: 100% !important;
`;

const ConditionNumber = styled.h5`
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const FieldsContainer = styled(Row)`
  width: 100% !important;
`;

const SubTitle = styled(Typography.Text)`
  color: rgba(0, 0, 0, 0.45);
  line-height: 3;
`;

const DividerFull = styled(Divider)`
  width: calc(100% + 86px);
  margin-left: -28px;
`;
