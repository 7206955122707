import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { message } from 'antd';
import { ApiError } from '../../../services/api/api-error';

function useSchemaForm<T = any>(
  onSubmit: (data: T) => Promise<void>,
  successMessage?: string,
  errorMessage?: string,
  showMessage = true,
): [
  T,
  boolean,
  boolean,
  (data: T) => void,
  (data: T) => void,
  (data: T) => Promise<void>,
  Dispatch<SetStateAction<T>>
] {
  const [formData, setFormData] = useState<T>({} as any);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [formDirty, setFormDirty] = useState<boolean>(false);

  const handleFormChange = useCallback((data: T) => {
    setFormData(data);
    setFormDirty(true);
  }, []);

  const handleFormSubmit = useCallback(
    async (data: T) => {
      setFormLoading(true);
      try {
        await onSubmit(data);
        if (successMessage && showMessage) {
          message.success(successMessage);
        }
        setFormDirty(false);
      } catch (err) {
        const error = err as ApiError;
        const apiError =
          error.response && error.response.data
            ? error.response.data.message
            : errorMessage;
        if (apiError && showMessage) message.error(apiError);
      } finally {
        setFormLoading(false);
      }
    },
    [onSubmit, successMessage, showMessage, errorMessage],
  );

  const formInit = useCallback((data: T) => {
    setFormData(data);
    setTimeout(() => {
      setFormDirty(false);
    });
  }, []);

  return [
    formData,
    formLoading,
    formDirty,
    formInit,
    handleFormChange,
    handleFormSubmit,
    setFormData,
  ];
}

export default useSchemaForm;
