import * as React from 'react';
import { useStore, useActions } from 'easy-peasy';
import get from 'lodash/get';
import AppDetails from './app-details.component';
import Gridapp from '../../../../store/types/gridapp';
import { RootState, RootModel } from '../../../../store/models/root.model';
import useTenantAndMarketplaceGridapps from '../../../common/use-tenant-and-marketplace-gridapps/use-tenant-and-marketplace-gridapps';

type AppDetailsContainerProps = Omit<
  React.ComponentProps<typeof AppDetails>,
  'app' | 'loaded' | 'user' | 'permissions'
>;

const AppDetailsContainer = (props: AppDetailsContainerProps) => {
  const {
    match: {
      params: { organisationId, appId },
    },
  } = props;

  const gridappsState = useTenantAndMarketplaceGridapps(organisationId);

  const { app, appsLoaded, user, permissions, organisations } = useStore<RootState>(
    (state) => {
      const providerApp =
        (state.organisationApps.data &&
          state.organisationApps.data[organisationId] &&
          state.organisationApps.data[organisationId][appId]) ||
        null;

      return {
        app: providerApp,
        appsLoaded: state.organisationApps.loaded,
        user: state.auth.user,
        permissions: state.auth.permissions,
        organisations: state.organisations ? state.organisations.data : null,
      };
    },
    [appId, organisationId],
  );

  const { deleteApp } = useActions<RootModel>((actions) => ({
    deleteApp: actions.organisationApps.delete,
  }));

  const { gridapp, gridappsLoading } = React.useMemo(() => {
    const currentGridApp = get(
      app,
      'settings.provider.app.gridApp.gridapp',
      null,
    ) as Gridapp | null;

    return {
      gridapp:
        (currentGridApp &&
          gridappsState.data &&
          gridappsState.data.find((gridapp) => gridapp.id === currentGridApp.id)) ||
        null,
      gridappsLoading: gridappsState.isLoading,
      gridappsError: gridappsState.isError,
    };
  }, [gridappsState, app]);

  return (
    <AppDetails
      {...props}
      user={user}
      permissions={permissions}
      app={app}
      deleteApp={deleteApp}
      gridapp={gridapp}
      loaded={appsLoaded && !gridappsLoading}
      organisations={organisations}
    />
  );
};

export default AppDetailsContainer;
