import React, { ComponentProps } from 'react';
import { useStore } from 'easy-peasy';
import { RootState } from '../../../store/models/root.model';
import ProtectedSection from './protected-section.component';

type ProtectedSectionContainerProps = Omit<
  ComponentProps<typeof ProtectedSection>,
  'user'
>;

const ProtectedSectionContainer = (props: ProtectedSectionContainerProps) => {
  const user = useStore<RootState>((state) => state.auth.user);
  const { children } = props;

  return (
    <ProtectedSection user={user} {...props}>
      {children}
    </ProtectedSection>
  );
};

export default ProtectedSectionContainer;
