import React from 'react';
import { Card, Table } from 'antd';

export default function TableCard({
  label = 'Value',
  data,
}: {
  label?: string;
  data: any[];
}) {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: label,
      dataIndex: 'value',
      key: 'value',
    },
  ];

  return (
    <Card style={{ padding: 0 }} bodyStyle={{ padding: 0 }}>
      <Table
        size="middle"
        bordered={false}
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </Card>
  );
}
