import { ModulesStatusCard } from '@ombori/grid-reports';
import { RawMonitoringEvent } from '../../../use-analytics-report';
import { ModuleStatus } from './types';
import UniversalDevice from '../../../../../store/types/universal-device';

export interface ModuleStatusWithDeviceId extends ModuleStatus {
  deviceId: string;
}

export const formatRealtimeEventsForModulesStatus = (
  realtimeEvents: RawMonitoringEvent[],
  statusCodes: ModulesStatusCard['statusCodes'],
): ModuleStatusWithDeviceId[] => {
  if (realtimeEvents && realtimeEvents.length) {
    // object to store visited deviceId + moduleName combination to access them in O(1) time
    let deviceMap: Record<string, ModuleStatusWithDeviceId> = {};

    realtimeEvents.forEach((realtimeEvent) => {
      const key = `${realtimeEvent.deviceId}-${realtimeEvent.str1}`;
      if (!deviceMap[key]) {
        deviceMap[key] = {
          deviceId: realtimeEvent.deviceId,
          deviceName: realtimeEvent.deviceId,
          moduleName: realtimeEvent.str1!,
          offlineCount: 0,
          onlineCount: 0,
        };
      }

      if (statusCodes.offline.includes(realtimeEvent.int1!)) {
        deviceMap[key].offlineCount += 1;
      }

      if (statusCodes.online.includes(realtimeEvent.int1!)) {
        deviceMap[key].onlineCount += 1;
      }

    });

    return Object.values(deviceMap);
  }

  return [];
};

interface DataWithDeviceMeta {
  deviceId: string;
  deviceName: string;
}

export const mapDeviceIdToDeviceName = <T extends DataWithDeviceMeta>(
  data: T[],
  devices: UniversalDevice[],
): T[] => {
  // store deviceId and deviceName for O(1) access
  const devicesMap: Record<string, string> = {};
  devices.forEach((device) => {
    devicesMap[device.id] = device.deviceName;
  });

  // map deviceId to deviceName in O(n) time (we could use map + filter/find combination, but that'll take
  // O(n^2) time and degrade performance as the input size grows)
  return data.map((item) => {
    if (devicesMap[item.deviceId]) {
      item.deviceName = devicesMap[item.deviceId];
    }

    return {
      ...item,
    };
  });
};
