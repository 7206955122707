import { Thunk, Action, Select, action, thunk, select } from 'easy-peasy';
import { isEmpty } from 'lodash';
import UserRole from '../../types/user-roles';
import { ApiError } from '../../../services/api/api-error';
import createKeyedCrudModel, {
  KeyedCrudModel,
} from '../../common/keyed-crud-model/keyed-crud.model';
import Injections from '../../injections.interface';

const path = '/api/user-roles';

export type UserRolesModel = KeyedCrudModel<UserRole, { organizationId: string }> & {
  fetchAll: Thunk<UserRolesModel, { silent?: true }, Injections>;
  loadedAll: Select<UserRolesModel, () => boolean>;
  loadingAll: boolean;
  errorAll: ApiError | null;
  setAllError: Action<UserRolesModel, { error: ApiError | null }>;
  setLoadingAll: Action<UserRolesModel, { loading: boolean }>;
  setAllData: Action<UserRolesModel, { data: UserRole[] }>;
};

const userRolesModel: UserRolesModel = {
  ...createKeyedCrudModel<UserRole, { organizationId: string }>(path, 'organizationId'),
  errorAll: null,
  loadingAll: false,
  loadedAll: select((state) => () => !isEmpty(state.data)),
  setAllData: action((state, { data }) => {
    state.data = {};

    data.forEach((userRole) => {
      if (!state.data[userRole.organizationId]) {
        state.data[userRole.organizationId] = {};
      }

      state.data[userRole.organizationId][userRole.id] = userRole;
    });
  }),
  setLoadingAll: action((state, { loading }) => {
    state.loadingAll = loading;
  }),
  setAllError: action((state, { error }) => {
    state.errorAll = error;
  }),
  fetchAll: thunk(async (actions, { silent }, { injections }) => {
    if (!silent) {
      actions.setLoadingAll({ loading: true });
    }
    actions.setAllError({ error: null });
    try {
      const data = await injections.apiService.get<UserRole[]>(path);
      actions.setAllData({ data });
    } catch (error) {
      actions.setAllError({ error });
    } finally {
      if (!silent) {
        actions.setLoadingAll({ loading: false });
      }
    }
  }),
};

export default userRolesModel;
