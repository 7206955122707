import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import { RootModel, RootState } from '../../../store/models/root.model';
import { KEY_ENTERPRISE_AGREEMENTS } from '../../../store/models/enterprise-agreements/enterprise-agreements.model';
import EnterpriseAgreementList from './enterprise-agreement-list.component';
import usePlansList from '../../../store/hooks/plans/use-plans-list';

const EnterpriseAgreementContainer = () => {
  const {
    loading,
    error,
    enterpriseAgreements,
    organizations,
    organisationUsers,
  } = useStore<RootState>((state) => {
    const key = KEY_ENTERPRISE_AGREEMENTS;

    return {
      loading: state.enterpriseAgreements.loading[key],
      error: state.enterpriseAgreements.error[key],
      enterpriseAgreements: state.enterpriseAgreements.values(key),
      organizations: state.organisations.values,
      organisationUsers: state.organisationUsers.values,
    };
  }, []);

  const { data: plans } = usePlansList(1, 1000);

  const {
    fetchEnterpriseAgreements,
    createEnterpriseAgreement,
    updateEnterpriseAgreement,
    deleteEnterpriseAgreement,
    sendEnterpriseAgreementInvite,
    fetchFilters,
    fetchOrganizations,
    fetchOrganisationUsers,
  } = useActions<RootModel>((actions) => ({
    fetchEnterpriseAgreements: actions.enterpriseAgreements.fetchEnterpriseAgreements,
    createEnterpriseAgreement: actions.enterpriseAgreements.create,
    updateEnterpriseAgreement: actions.enterpriseAgreements.update,
    deleteEnterpriseAgreement: actions.enterpriseAgreements.delete,
    sendEnterpriseAgreementInvite:
      actions.enterpriseAgreements.sendEnterpriseAgreementInvite,
    fetchFilters: actions.enterpriseAgreements.fetchFilters,
    fetchOrganizations: actions.organisations.fetch,
    fetchOrganisationUsers: actions.organisationUsers.fetch,
  }));

  return (
    <EnterpriseAgreementList
      fetchEnterpriseAgreements={fetchEnterpriseAgreements}
      createEnterpriseAgreement={createEnterpriseAgreement}
      updateEnterpriseAgreement={updateEnterpriseAgreement}
      deleteEnterpriseAgreement={deleteEnterpriseAgreement}
      sendEnterpriseAgreementInvite={sendEnterpriseAgreementInvite}
      fetchFilters={fetchFilters}
      fetchOrganizations={fetchOrganizations}
      enterpriseAgreements={enterpriseAgreements}
      plans={plans && plans.docs ? plans.docs : []}
      organizations={organizations}
      loading={loading}
      error={error}
      fetchOrganisationUsers={fetchOrganisationUsers}
      organisationUsers={organisationUsers}
    />
  );
};

export default EnterpriseAgreementContainer;
