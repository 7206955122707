import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { LinkOutline, DownloadOutline } from '@ant-design/icons';
import AntdIcon from '@ant-design/icons-react';
import { useTranslation } from 'react-i18next';
import Queue from '../../../../../../store/types/queue';
import { getApiUrl } from '../../../../../../utils/env';
import { webUrls, tasksWebUrls } from '../../../../../../utils/queue-urls';
import { OmboriAppsProviderEnums } from '../../../../../../utils/installations/get-installation-app-icon-url';

AntdIcon.add(LinkOutline, DownloadOutline);

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const LinkContainer = styled.div`
  margin-bottom: 8px;
`;

const Link = styled.a`
  margin-left: 4px;
`;

interface QueueExtraDetailsProps {
  provider?: OmboriAppsProviderEnums;
  queue: Queue;
  featureList: string[];
}

const QueueExtraDetails = (props: QueueExtraDetailsProps) => {
  const { provider, queue, featureList } = props;

  const { t } = useTranslation();

  const baseUrl = useMemo(() => {
    if (provider === OmboriAppsProviderEnums.TASKS) {
      return tasksWebUrls[queue.region] || '';
    }

    if (queue) {
      return webUrls[queue.region] || '';
    }
    return '';
  }, [provider, queue]);

  const baseApiUrl = useMemo(() => getApiUrl(), []);

  if (provider === OmboriAppsProviderEnums.TASKS) {
    return (
      <Container>
        <LinkContainer>
          <AntdIcon type="link-o" />
          <Link
            href={`${baseUrl}/${queue.organization}/queues/${queue.id}/admin`}
            target="_blank"
          >
            {t('queueInstallation.tasksManager')}
          </Link>
        </LinkContainer>
      </Container>
    );
  }

  return (
    <Container>
      {featureList.includes('OCCUPANCY') && (
        <LinkContainer>
          <AntdIcon type="link-o" />
          <Link
            href={`${baseUrl}/${queue.organization}/queues/${queue.id}/occupancy-admin`}
            target="_blank"
          >
            Occupancy manager
          </Link>
        </LinkContainer>
      )}
      {(featureList.includes('QUEUE') || featureList.includes('BOOKINGS')) && (
        <LinkContainer>
          <AntdIcon type="link-o" />
          <Link
            href={`${baseUrl}/${queue.organization}/queues/${queue.id}/admin`}
            target="_blank"
          >
            Queue manager
          </Link>
        </LinkContainer>
      )}
      {featureList.includes('BOOKINGS') && (
        <LinkContainer>
          <AntdIcon type="link-o" />
          <Link
            href={`${baseUrl}/${queue.organization}/queues/${queue.id}/book`}
            target="_blank"
          >
            Booking
          </Link>
        </LinkContainer>
      )}

      {featureList.includes('CURBSIDEPICKUP') && (
        <LinkContainer>
          <AntdIcon type="link-o" />
          <Link
            href={`${baseUrl}/${queue.organization}/queues/${
              queue.id
            }/click-and-collect-admin`}
            target="_blank"
          >
            Curbside pickup administrator
          </Link>
        </LinkContainer>
      )}
      {featureList.includes('BOOKINGS') && (
        <LinkContainer>
          <AntdIcon type="download-o" />
          <Link
            href={`${baseApiUrl}/api/qr-code?url=${encodeURIComponent(
              `${baseUrl}/${queue.organization}/queues/${queue.id}/book`,
            )}&width=1800`}
            download
          >
            Download Booking QR code
          </Link>
        </LinkContainer>
      )}
      {featureList.includes('QUEUE') && (
        <LinkContainer>
          <AntdIcon type="download-o" />
          <Link
            href={`${baseApiUrl}/api/qr-code?url=${encodeURIComponent(baseUrl)}/${
              queue.organization
            }/queues/${queue.id}&width=1800`}
            download
          >
            Download Ticket QR code
          </Link>
        </LinkContainer>
      )}
    </Container>
  );
};

export default QueueExtraDetails;
