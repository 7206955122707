import React from 'react';
import { Row, Col, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import { FormTextInput } from '../../queue-details/queue-setup/styled-block';
import { requiredAll } from '../../queue-details/queue-setup/validate-form';
import { CountryPicker, InputWrap, composeValidators, optionalCharacterLength, validateCharacterLength, validateMaxLength } from '../../../../common/react-final-form';
import { ChangeEvent } from '../../../../../types';

const SpaceFormBillingSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <BillingContactInfoSection>
        <BillingSectionHeading>
          {t('spaceForm.billingContact.contactPerson')}
        </BillingSectionHeading>

        <Typography.Text>{t('spaceForm.billingContact.contactPersonHelpnote')}</Typography.Text>

        <FieldsContainer gutter={[12, 12]}>
          <Col span={12}>
            <Field
              name='billingContact.firstName'
              validate={optionalCharacterLength(2, 50)}
              render={({ input, meta }) => (
                <FormTextInput
                  input={input}
                  meta={meta}
                  label={t('spaceForm.billingContact.firstName')}
                />
              )}
            />
          </Col>
          <Col span={12}>
            <Field
              name='billingContact.lastName'
              validate={optionalCharacterLength(2, 50)}
              render={({ input, meta }) => (
                <FormTextInput
                  input={input}
                  meta={meta}
                  label={t('spaceForm.billingContact.lastName')}
                />
              )}
            />
          </Col>
        </FieldsContainer>

        <FieldsContainer gutter={[12, 12]}>
          <Col span={12}>
            <Field
              name='billingContact.email'
              render={({ input, meta }) => (
                <FormTextInput
                  input={input}
                  meta={meta}
                  label={t('spaceForm.billingContact.email')}
                />
              )}
            />
          </Col>
          <Col span={12}>
            <Field
              name='billingContact.phone'
              validate={optionalCharacterLength(5, 50)}
              render={({ input, meta }) => (
                <div className='form-group'>
                  <FormTextInput
                    input={input}
                    meta={meta}
                    label={t('spaceForm.billingContact.phone')}
                  />
                </div>
              )}
            />
          </Col>
        </FieldsContainer>

      </BillingContactInfoSection>

      <BillingAddressInfoSection>

        <BillingSectionHeading>
          {t('spaceForm.billingAddress.billingAddress')}
        </BillingSectionHeading>

        <Typography.Text>{t('spaceForm.billingAddress.billingAddressHelpnote')}</Typography.Text>

        <FieldsContainer gutter={[12, 12]}>
          <Col span={12}>
            <Field
              name='billingAddress.name'
              validate={composeValidators([requiredAll, validateMaxLength(200)])}
              render={({ input, meta }) => (
                <FormTextInput
                  input={input}
                  meta={meta}
                  label={t('spaceForm.billingAddress.name')}
                  required={true}
                />
              )}
            />
          </Col>

          <Col span={12}>
            <Field
              name='billingAddress.email'
              render={({ input, meta }) => (
                <FormTextInput
                  input={input}
                  meta={meta}
                  label={t('spaceForm.billingAddress.email')}
                />
              )}
            />
          </Col>
        </FieldsContainer>

        <FieldsContainer gutter={[12, 12]}>
          <Col span={12}>
            <Field
              name='billingAddress.addressLine1'
              validate={composeValidators([requiredAll, validateCharacterLength(2, 200)])}
              render={({ input, meta }) => (
                <FormTextInput
                  input={input}
                  meta={meta}
                  label={t('spaceForm.billingAddress.addressLine1')}
                  required
                />
              )}
            />
          </Col>

          <Col span={12}>
            <Field
              name='billingAddress.addressLine2'
              validate={optionalCharacterLength(2, 200)}
              render={({ input, meta }) => (
                <FormTextInput
                  input={input}
                  meta={meta}
                  label={t('spaceForm.billingAddress.addressLine2')}
                />
              )}
            />
          </Col>
        </FieldsContainer>

        <FieldsContainer gutter={[12, 12]}>
          <Col span={12}>
            <Field
              name='billingAddress.city'
              validate={composeValidators([requiredAll, validateCharacterLength(2, 200)])}
              render={({ input, meta }) => (
                <FormTextInput
                  input={input}
                  meta={meta}
                  label={t('spaceForm.billingAddress.city')}
                  required
                />
              )}
            />
          </Col>

          <Col span={12}>
            <Field
              name='billingAddress.state'
              validate={optionalCharacterLength(2, 200)}
              render={({ input, meta }) => (
                <FormTextInput
                  input={input}
                  meta={meta}
                  label={t('spaceForm.billingAddress.state')}
                />
              )}
            />
          </Col>
        </FieldsContainer>

        <FieldsContainer>
          <Col>
            <CountryPicker
              name='billingAddress.country'
              label={t('spaceForm.billingAddress.country')}
              isRequired={true}
              showSearch={true}
            />
          </Col>
        </FieldsContainer>

        <FieldsContainer gutter={[12, 12]}>
          <Col span={12}>
            <Field
              name='billingAddress.postal'
              validate={composeValidators([requiredAll, validateCharacterLength(2, 20)])}
              render={({ input, meta }) => (
                <FormTextInput
                  input={input}
                  meta={meta}
                  label={t('spaceForm.billingAddress.postal')}
                  required
                />
              )}
            />
          </Col>

          <Col span={12}>
            <Field
              name='billingAddress.vatId'
              validate={optionalCharacterLength(8, 14)}
              render={({ input, meta }) => (
                <FormTextInput
                  input={input}
                  meta={meta}
                  label={t('spaceForm.billingAddress.vatId')}
                />
              )}
            />
          </Col>
        </FieldsContainer>

        <FieldsContainer>
          <Field
            name='billingAddress.notes'
            validate={optionalCharacterLength(1, 250)}
            render={({ input, meta }) => (
              <>
                <InputWrap
                  label={t('spaceForm.billingAddress.notes')}
                  error={meta.error && meta.touched ? meta.error : null}
                >
                  <TextArea
                    rows={3}
                    defaultValue={input.value}
                    onChange={(event) => {
                      input.onChange({
                        target: {
                          value: event.target.value,
                        }
                      } as unknown as ChangeEvent<string>)
                    }}
                  />
                </InputWrap>
              </>
            )}
          />
        </FieldsContainer>

      </BillingAddressInfoSection>
    </>
  );
};

const BillingSectionHeading = styled.h4`
  font-size: 16px;
  font-weight: 700;
`;

const BillingContactInfoSection = styled(Row)`
  margin-top: 20px;
`;

const BillingAddressInfoSection = styled(Row)`
  margin-top: 20px;
`;

const FieldsContainer = styled(Row)`
  margin-bottom: 10px !important;
`;

export default SpaceFormBillingSection;
