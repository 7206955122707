import { Tag as TagComponent, Typography } from 'antd';
import styled from '@emotion/styled';
import React from 'react';
import { rgba } from 'polished';
import { MoreHorizontal, RefreshCcw, CheckCircle, X } from 'react-feather';
import {
  PipelineStatus,
  PipelineWithBuildNumber,
} from '../../../../../../store/types/pipeline';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

const getPipelineIcon = (status: PipelineStatus): React.ReactNode => {
  switch (status) {
    case PipelineStatus.Queued:
      return <MoreHorizontal />;
    case PipelineStatus.Building:
      return <RefreshCcw />;
    case PipelineStatus.Done:
      return <CheckCircle />;
    case PipelineStatus.Failed:
      return <X />;
  }
};

const { Title } = Typography;

const Container = styled.div``;

const Heading = styled(Title)`
  && {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
` as any;

const AppLink = styled(Link)`
  margin-right: 12px;
  color: #689bc4 !important;
  font-weight: 400;
  &:hover,
  &:active,
  &:focus {
    color: #689bc4 !important;
  }
`;

const HeadingTag = styled.div`
  display: flex;
`;

const Wrapper = styled.div``;

const getTagStyles = (status: PipelineStatus): string => {
  switch (status) {
    case PipelineStatus.Queued:
      return 'background: #F2F2FA;color: #676973;';
    case PipelineStatus.Building:
      return `background: ${rgba('#0064B6', 0.16)};color: #0064B6;`;
    case PipelineStatus.Done:
      return `background: ${rgba('#07AD48', 0.16)};color: #07AD48;`;
    case PipelineStatus.Failed:
      return `background: #F02424;color: #FFFFFF;`;
  }
};

const Tag = styled(({ status, ...otherProps }) => <TagComponent {...otherProps} />)<{
  status: PipelineStatus;
}>`
  && {
    display: inline-flex;
    align-items: center;
    font-size: 10px;
    margin: 0;
    text-transform: uppercase;
    border: none;

    ${({ status }) => getTagStyles(status)};
  }
`;

const TagIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  margin-right: 2px;

  svg {
    display: block;
    width: 100%;
  }
`;

interface ReleaseProps extends RouteComponentProps {
  pipeline: PipelineWithBuildNumber;
  buildId: string;
}

const Release: React.FC<ReleaseProps> = ({
  pipeline,
  buildId,
  match: { url },
}: ReleaseProps) => {
  const icon = getPipelineIcon(pipeline.status);

  return (
    <Container>
      <Wrapper>
        <Heading level={4}>
          <AppLink to={`${url}/${buildId}`}>{`#${pipeline.data.buildNumber}`}</AppLink>
          {pipeline.status !== PipelineStatus.Done && (
            <HeadingTag>
              <Tag status={pipeline.status}>
                <TagIcon>{icon}</TagIcon>

                {pipeline.status}
              </Tag>
            </HeadingTag>
          )}
        </Heading>
      </Wrapper>
    </Container>
  );
};

export default withRouter(Release);
