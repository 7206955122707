import { useQuery } from 'react-query';
import { useApiService } from '../api-service-provider';
import { AnalyticsSchema } from '@ombori/grid-reports';

const useDeviceSchema = ({
  organizationId,
  deviceId,
}: {
  organizationId: string;
  deviceId: string;
}) => {
  const apiService = useApiService();

  return useQuery(
    ['tenantId', organizationId, 'deviceId', deviceId],
    () =>
      apiService.get<AnalyticsSchema>(
        `/api/analytics/device-schema/${deviceId}/${organizationId}`,
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export default useDeviceSchema;
