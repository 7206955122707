import React, { useMemo, useCallback } from 'react';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import SpaceForm, { OrganisationSpaceSettings } from './space-form.component';
import { DEFAULT_OPENING_TIME, DEFAULT_CLOSING_TIME } from './space-form-schedule-section.component';
import { WeeklyTime } from '../../../../../store/types/organisation-space';
import OrganisationSpaceTypeEnum from '../../../../../store/types/organisation-space-type.enum';
import { useCreateSpace } from '../../../../common/use-spaces';
import { message } from 'antd';

interface SpaceFormAddProps extends RouteComponentProps<{ organisationId: string }>{};

export const DEFAULT_BUSINESS_HOURS: WeeklyTime = {
  '0': { from: DEFAULT_OPENING_TIME, to: DEFAULT_CLOSING_TIME, isOpen: true }, // Sunday
  '1': { from: DEFAULT_OPENING_TIME, to: DEFAULT_CLOSING_TIME, isOpen: true }, // Monday
  '2': { from: DEFAULT_OPENING_TIME, to: DEFAULT_CLOSING_TIME, isOpen: true }, // Tuesday
  '3': { from: DEFAULT_OPENING_TIME, to: DEFAULT_CLOSING_TIME, isOpen: true }, // Wednesday
  '4': { from: DEFAULT_OPENING_TIME, to: DEFAULT_CLOSING_TIME, isOpen: true }, // Thursday
  '5': { from: DEFAULT_OPENING_TIME, to: DEFAULT_CLOSING_TIME, isOpen: true }, // Friday
  '6': { from: DEFAULT_OPENING_TIME, to: DEFAULT_CLOSING_TIME, isOpen: true }, // Saturday
};

const SpaceFormAdd = (props: SpaceFormAddProps) => {
  const {
    match: {
      params: {
        organisationId: tenantId,
      }
    },
    history
  } = props;

  const { t } = useTranslation();

  const initialValues = useMemo<OrganisationSpaceSettings>(() => {
    return {
      organizationId: tenantId,
      displayName: '',
      externalId: '',
      parentSpaceId: '' || 'no_parent',
      type: OrganisationSpaceTypeEnum.LOCATION,
      country: '',
      address: '',
      city: '',
      state: '',
      postcode: '',
      weeklySchedule: {  weeklyTime: DEFAULT_BUSINESS_HOURS },
      notes: '',
      billingContact: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      },
      billingAddress: {
        name: '',
        email: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: '',
        postal: '',
        vatId: '',
        notes: ''
      }
    }
  }, [tenantId]);

  const { mutateAsync: createSpace } = useCreateSpace(tenantId);

  const handleFormSubmit = useCallback(async (values: OrganisationSpaceSettings) => {
    try {
      const newlyCreatedSpace = await createSpace(values);
      history.push(`/organisations/${tenantId}/spaces/${newlyCreatedSpace.id}/details-v2/settings`);
      message.success(t('spaceForm.spaceSaved'));
      return true;
    } catch (e) {
      message.error(t('spaceForm.spaceSavedError'));
      return false;
    }
  }, [createSpace, history, tenantId, t]);

  return (
    <>
      <SpaceForm tenantId={tenantId} initialValues={initialValues} onFormSubmit={handleFormSubmit} />
    </>
  )
}

export default SpaceFormAdd;
