import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Input, Modal, Alert as AntdAlert, message } from 'antd';

const { TextArea: AntdTextArea } = Input;

export interface ReleaseNotesModalRef {
  open: (releaseNotes?: string) => void;
  close: () => void;
}

interface ReleaseNotesModalProps {
  isView?: boolean;
  isError?: boolean;
  onSubmit?: (releaseNotes?: string) => Promise<void>;
  onClose?: () => void;
}

const ReleaseNotesModal = (
  props: ReleaseNotesModalProps,
  ref?: React.Ref<ReleaseNotesModalRef>,
) => {
  const {
    onSubmit = () => {},
    onClose = () => {},
    isView: isViewMode = true,
    isError,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(isViewMode);
  const [initValue, setInitValue] = useState<string | undefined>();
  const [value, setValue] = useState<string | undefined>();
  const [isProcessing, setIsProcessing] = useState<boolean | undefined>();

  const { t } = useTranslation();

  const handleSave = useCallback(async () => {
    try {
      setIsProcessing(true);
      await onSubmit(value);
      setIsProcessing(false);
      message.success(t('releaseNotes.successSavingData'));
      setIsModalOpen(false);
    } catch {
      message.error(t('releaseNotes.errorSavingData'));
    }
  }, [onSubmit, value, t]);

  const handleCancel = useCallback(() => {
    setValue(undefined);
    setInitValue(undefined);
    setIsModalOpen(false);
    setIsProcessing(false);
    onClose();
  }, [onClose]);

  const handleEdit = useCallback(() => setIsReadOnly(false), []);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      open: (releaseNotes?: string) => {
        setValue(releaseNotes);
        setInitValue(releaseNotes);
        setIsReadOnly(!!releaseNotes || isViewMode);
        setIsModalOpen(true);
      },
      close: () => handleCancel(),
    }),
    [handleCancel, isViewMode],
  );

  const { isOkDisabled, okText, handleOk } = useMemo(() => {
    if (isViewMode) {
      return {
        isOkDisabled: false,
        okText: undefined,
        handleOk: handleCancel,
      };
    }

    return {
      isOkDisabled: !isReadOnly && (initValue === value || !value),
      okText: isReadOnly ? t('releaseNotes.edit') : t('releaseNotes.save'),
      handleOk: isReadOnly ? handleEdit : handleSave,
    };
  }, [handleCancel, handleEdit, handleSave, isReadOnly, isViewMode, initValue, value, t]);

  return (
    <Modal
      title={t('releaseNotes.title')}
      visible={isModalOpen}
      onOk={handleOk}
      okText={okText}
      okButtonProps={{ disabled: isOkDisabled }}
      confirmLoading={isProcessing && !isError}
      cancelButtonProps={{ style: { display: 'none' } }}
      onCancel={handleCancel}
      closable={!isProcessing || isError}
      maskClosable={false}
      centered
    >
      {isError && (
        <Alert type="error" showIcon message={t('releaseNotes.errorSavingData')} />
      )}
      <TextArea rows={10} readOnly={isReadOnly} value={value} onChange={handleChange} />
    </Modal>
  );
};

const TextArea = styled(AntdTextArea)`
  &[readonly] {
    background-color: #f5f6facc;
  }
`;

const Alert = styled(AntdAlert)`
  margin-bottom: 10px;
`;

export default forwardRef(ReleaseNotesModal);
