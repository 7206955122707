import React, { useEffect, useRef, useState, useCallback } from 'react';
import dayjs from 'dayjs';
import { Avatar } from 'antd';
import styled from '@emotion/styled';
import Media from '../../../../store/types/media';
import FilePreviewIcon from '../preview-icon/file-preview-icon.component';

const MediaAvatar = styled(Avatar)`
  img {
    object-fit: cover;
  }
`;

interface MediaThumbnailProps {
  mediaItem: Media;
  size?: number;
  className?: string;
}

const retryMaxCount = 5;

const isNew = (media: Media) => dayjs().diff(dayjs(media.createdAt), 'minute') < 5;

const MediaThumbnail = ({ mediaItem, size, className }: MediaThumbnailProps) => {
  const [validThumbUrl, setValidThumbUrl] = useState<string | null>(null);
  const tryCount = useRef<number>(0);
  const retryTimeout = useRef<number | undefined>(undefined);
  const isMounted = useRef(true);

  const tryLoad = useCallback((media: Media) => {
    if (media.thumbnailUrl) {
      tryCount.current += 1;
      const image = new Image();
      image.onload = () => {
        if (media.thumbnailUrl && isMounted.current) {
          setValidThumbUrl(media.thumbnailUrl);
        }
      };

      image.onerror = () => {
        if (tryCount.current < retryMaxCount && isMounted.current && isNew(media)) {
          retryTimeout.current = window.setTimeout(() => {
            tryLoad(media);
          }, 5000);
        }
      };
      image.src = media.thumbnailUrl;
    }
  }, []);

  useEffect(() => {
    isMounted.current = true;
    tryCount.current = 0;
    tryLoad(mediaItem);
    return () => {
      isMounted.current = false;
      clearTimeout(retryTimeout.current);
    };
  }, [mediaItem, tryLoad]);

  return validThumbUrl ? (
    <MediaAvatar className={className} size={size} shape="square" src={validThumbUrl} />
  ) : (
    <FilePreviewIcon className={className} size={size} file={mediaItem} />
  );
};

MediaThumbnail.defaultProps = {
  size: 64,
};

export default MediaThumbnail;
