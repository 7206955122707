import React from 'react';
import { FieldProps } from 'react-jsonschema-form';
import GridappVersionSelect from './gridapp-version-select.component';
import GridappItemSettings from './gridapp-item-settings.component';

interface GridappPickerUiOptions {
  isVisible?: boolean;
}

// TO DO: Define type of formData
interface GridappPickerProps extends FieldProps {}

const GridappPicker = ({
  formContext,
  formData = {},
  onChange,
  uiSchema,
}: GridappPickerProps) => {
  const { hideMainGridAppPicker, isGridAppVersionPickerVisible = true } = formContext;

  const { isVisible = true } = (uiSchema['ui:options'] || {}) as GridappPickerUiOptions;

  return (
    <div>
      <GridappVersionSelect
        organizationId={formContext.organisationId}
        formData={formData}
        isGridAppNamePickerVisible={!hideMainGridAppPicker && isVisible}
        isGridAppVersionPickerVisible={isGridAppVersionPickerVisible && isVisible}
        onChange={onChange}
      />
      <GridappItemSettings
        isGridAppVersionPickerVisible={isGridAppVersionPickerVisible}
        formContext={formContext}
        formData={formData}
        onChange={onChange}
      />
    </div>
  );
};

export default GridappPicker;
