import { TFunction } from 'i18next';

type BuildSchemaResponse = Record<string, string | number | boolean | object>;

export const buildFieldSchema = (t: TFunction): BuildSchemaResponse => {
  const fieldSchema = {
    type: 'object',
    properties: {
      typography: {
        type: 'object',
        title: t('brandingTypography.title'),
        properties: {
          heading: {
            type: 'object',
            title: t('brandingTypography.headingTitle'),
            default: {
              sampleText: t('brandingTypography.headingSampleText'),
              asElement: 'h2',
              fontWeight: 'bold',
            },
          },
          normalText: {
            type: 'object',
            title: t('brandingTypography.normalTextTitle'),
            default: {
              sampleText: t('brandingTypography.normalTextSampleText'),
              asElement: 'span',
            },
          },
        },
      },
    },
  };

  return fieldSchema;
};

export const buildUiSchema = (t: TFunction): BuildSchemaResponse => {
  const metaSchema = {
    objectFieldTemplate: 'none',
    typography: {
      objectFieldTemplate: 'accordion',
      'ui:options': {
        isContentPadded: false,
        hasDivider: false,
        gap: 16,
      },
      heading: {
        'ui:field': 'typographyPicker',
        'ui:options': {
          editButton: {
            text: t('brandingTypography.headingEditButton'),
          },
          subtitle: t('brandingTypography.headingSubtitle'),
        },
      },
      normalText: {
        'ui:field': 'typographyPicker',
        'ui:options': {
          editButton: {
            text: t('brandingTypography.normalTextEditButton'),
          },
          subtitle: t('brandingTypography.normalTextSubtitle'),
        },
      },
    },
  };

  return metaSchema;
};
