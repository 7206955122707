import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import {
  InputCheckbox,
  Label,
  MainSectionTitle,
  PanelBody,
  PanelCardMain,
  RowStyled,
} from './styled-block';
import TextInAppCustomizationLanguagePicker from './text-in-app-customization-lanugage-picker';
import CustomizableTextsGroup from './customizable-texts-group';

const { Title } = Typography;

interface FeedbackProps {
  isActive: boolean;
  activeLanguage: string;
}

const Feedback: React.FC<FeedbackProps> = ({ isActive, activeLanguage }) => {
  const { t } = useTranslation();

  return (
    <PanelCardMain bodyStyle={{ padding: 0 }}>
      <MainSectionTitle>
        <Title level={2}>{t('feedbackSurvey')}</Title>
      </MainSectionTitle>

      <PanelBody>
        <Field name="feedback.isActive" type="checkbox">
          {({ input }) => (
            <RowStyled>
              <Label>
                <InputCheckbox {...input} type="checkbox" />
                {t('isFeedbackSurveyActive')}
              </Label>
            </RowStyled>
          )}
        </Field>

        {isActive && (
          <>
            <TextInAppCustomizationLanguagePicker />

            <CustomizableTextsGroup
              textsGroup={t('feedback')}
              activeLanguage={activeLanguage}
            />
          </>
        )}
      </PanelBody>
    </PanelCardMain>
  );
};

export default Feedback;
