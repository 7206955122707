import { useMutation } from 'react-query';
import { useApiService } from '../api-service-provider';

interface UseReleaseNotesMutationProps {
    endpoint: string;
    releaseNotes?: string;
}

const useReleaseNotes = <T = any>() => {
  const apiService = useApiService();

  return useMutation(
    ({ endpoint, releaseNotes }: UseReleaseNotesMutationProps) => apiService.put<T>(endpoint, { releaseNotes }));
};

export default useReleaseNotes;
