import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import Settings from './settings.component';
import { RootModel, RootState } from '../../../../../../store/models/root.model';
import { getPermissions } from '../../../../../../utils/auth/permissions';

type SettingsContainerProps = Omit<React.ComponentProps<typeof Settings>, 'updateDevice'>;

const SettingsContainer = (props: SettingsContainerProps) => {
  const {
    spaces,
    match: {
      params: { deviceUuid, organisationId },
    },
  } = props;

  const { device, permissions } = useStore<RootState>(
    (state) => ({
      device: state.deviceDetails.data[deviceUuid] || null,
      permissions: getPermissions(state.auth.user, organisationId),
      spaces: state.organisationSpaces.values(organisationId),
    }),
    [deviceUuid, organisationId],
  );

  const { updateDevice } = useActions<RootModel>((actions) => ({
    updateDevice: actions.deviceDetails.update,
  }));

  return (
    <Settings
      {...props}
      updateDevice={updateDevice}
      canManageDeviceSettings={permissions.devices.manageDeviceSettings}
      device={device}
      spaces={spaces}
    />
  );
};

export default SettingsContainer;
