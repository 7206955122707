import { action, Action, select, Select, thunk, Thunk } from 'easy-peasy';
// import io from 'socket.io-client';
import Device from '../../types/device';
import { ApiError } from '../../../services/api/api-error';
import Injections from '../../injections.interface';
import DeviceLog from '../../types/device-log';
// import { getApiUrl } from '../../../utils/env';

export interface DeviceDetailsModel {
  data: {
    [deviceUuid: string]: Device;
  };
  loading: {
    [deviceUuid: string]: boolean;
  };
  error: {
    [deviceUuid: string]: ApiError | null;
  };
  lastUpdated: {
    [deviceUuid: string]: Date;
  };

  loaded: Select<DeviceDetailsModel, (deviceUuid: string) => boolean>;
  setLastUpdated: Action<DeviceDetailsModel, { deviceUuid: string; data: Date }>;
  setLoading: Action<DeviceDetailsModel, { deviceUuid: string; loading: boolean }>;
  setError: Action<DeviceDetailsModel, { deviceUuid: string; error: ApiError | null }>;
  setData: Action<DeviceDetailsModel, { data: Device }>;
  fetch: Thunk<DeviceDetailsModel, { deviceUuid: string; silent?: boolean }, Injections>;
  update: Thunk<DeviceDetailsModel, Partial<Device>, Injections>;
  restart: Thunk<DeviceDetailsModel, { deviceUuid: string }, Injections>;
  reboot: Thunk<DeviceDetailsModel, { deviceUuid: string }, Injections>;
  move: Thunk<DeviceDetailsModel, { appName: string; deviceUuid: string }, Injections>;
  subscribeLogs: Thunk<
    DeviceDetailsModel,
    { deviceUuid: string; onLog: (log: DeviceLog) => void },
    Injections
  >;
}

const deviceDetailsModel: DeviceDetailsModel = {
  data: {},
  loading: {},
  error: {},
  lastUpdated: {},
  loaded: select((state) => (deviceUuid: string) =>
    !!state.data[deviceUuid] && !state.loading[deviceUuid],
  ),
  setLastUpdated: action((state, { deviceUuid, data }) => {
    state.lastUpdated[deviceUuid] = data;
  }),
  setData: action((state, { data }) => {
    state.data[data.uuid] = data;
  }),
  setLoading: action((state, { deviceUuid, loading }) => {
    state.loading[deviceUuid] = loading;
  }),
  setError: action((state, { deviceUuid, error }) => {
    state.error[deviceUuid] = error;
  }),
  fetch: thunk(async (actions, { deviceUuid, silent = false }, { injections }) => {
    actions.setError({ deviceUuid, error: null });
    if (!silent) {
      actions.setLoading({ deviceUuid, loading: true });
    }
    try {
      const data = await injections.apiService.get<Device>(`/api/devices/${deviceUuid}`);
      actions.setData({ data });
      actions.setLastUpdated({ deviceUuid, data: new Date() });
    } catch (error) {
      actions.setError({ deviceUuid, error });
    } finally {
      if (!silent) {
        actions.setLoading({ deviceUuid, loading: false });
      }
    }
  }),
  update: thunk(async (actions, device, { injections }) => {
    const data = await injections.apiService.put<Device>(
      `/api/devices/${device.uuid}`,
      device,
    );
    actions.setData({ data });
  }),
  restart: thunk(async (actions, { deviceUuid }, { injections }) => {
    await injections.apiService.get<void>(`/api/devices/${deviceUuid}/restart`);
  }),
  reboot: thunk(async (actions, { deviceUuid }, { injections }) => {
    await injections.apiService.get<void>(`/api/devices/${deviceUuid}/reboot`);
  }),
  move: thunk(async (actions, { deviceUuid, appName }, { injections }) => {
    await injections.apiService.post<void>(`/api/devices/${deviceUuid}/move/${appName}`);
  }),
  subscribeLogs: thunk(async (actions, { deviceUuid, onLog }, { injections }) => {
    // const socket = io(`${getApiUrl()}/console`);
    // TODO: RESTORE BELOW CODE WHEN SOCKET.IO IS FIXED IN NEW INFRA
    // await new Promise((resolve, reject) => {
    //   const failTimeout = setTimeout(() => {
    //     reject(new Error('Socket connection failed'));
    //   }, 3000);

    //   socket.on('connect', () => {
    //     clearTimeout(failTimeout);
    //     resolve();
    //   });
    // });

    // socket.on(`logs_${deviceUuid}`, (log: DeviceLog) => {
    //   onLog(log);
    // });

    const triggerLogs = async () => {
      try {
        await injections.apiService.post<void>(`/api/v2/devices/${deviceUuid}/logs`);
      } catch {
        onLog({
          level: 'error',
          message: 'Failed to send device logs command\r\n',
          timestamp: new Date().toString(),
        });
      }
    };

    const triggerLogsInterval = setInterval(async () => {
      await triggerLogs();
    }, 60 * 1000);

    await triggerLogs();

    return () => {
      // socket.close();
      clearInterval(triggerLogsInterval);
    };
  }),
};

export default deviceDetailsModel;
