import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import PublicApps from './public-apps.component';
import { RootModel, RootState } from '../../../../../../store/models/root.model';

type PricingContainerProps = Pick<
  React.ComponentProps<typeof PublicApps>,
  'match' | 'history' | 'location'
>;

const PublicAppsContainer = (props: PricingContainerProps) => {
  const {
    match: {
      params: { organisationId },
    },
  } = props;

  const { marketplaceSolutions, loaded } = useStore<RootState>(
    (state) => ({
      marketplaceSolutions: state.marketplaceSolutions.data[organisationId] || [],
      loaded: state.marketplaceSolutions.loaded(organisationId),
    }),
    [organisationId],
  );

  const { fetchMarketplaceSolutions } = useActions<RootModel>((actions) => ({
    fetchMarketplaceSolutions: actions.marketplaceSolutions.fetch,
  }));

  return (
    <PublicApps
      {...props}
      marketplaceSolutions={marketplaceSolutions}
      loaded={loaded}
      fetchMarketplaceSolutions={fetchMarketplaceSolutions}
    />
  );
};

export default PublicAppsContainer;
