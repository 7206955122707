import React, { useMemo } from 'react';
import {
  WeekHeatmapProps,
  WeekHeatmapSessionsProps,
  WeekHeatmapEventsProps,
} from './types';
import CardContainer from '../card-container';
import WeekHeatmap from '../../charts/week-heatmap';
import {
  useInstallationSessionsByHour,
  useInstallationEventsByHour,
} from '../../../use-analytics-report';
import { buildSessionsData, buildEventsData } from './generate';
import { WeekHeatmapDataSourceType } from '@ombori/grid-reports';

interface InstallationWeekHeatmapBySessionsProps extends WeekHeatmapSessionsProps {
  installationId: string;
}

const InstallationWeekHeatmapBySessions: React.FC<
  InstallationWeekHeatmapBySessionsProps
> = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  dataResidency,
  title,
  dataSource,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const installationSessionsState = useInstallationSessionsByHour({
    tenantId,
    installationId,
    dateFrom,
    dateTo,
    dataResidency,
    interactionType: dataSource.interactionType,
  });

  const data = useMemo(() => buildSessionsData(installationSessionsState.data || []), [
    installationSessionsState,
  ]);

  return (
    <CardContainer
      isLoading={installationSessionsState.isLoading}
      isSuccess={installationSessionsState.isSuccess}
      isError={installationSessionsState.isError}
      hasData={
        installationSessionsState.isSuccess && installationSessionsState.data.length > 0
      }
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      <WeekHeatmap data={data} />
    </CardContainer>
  );
};

interface InstallationWeekHeatmapByEventsProps extends WeekHeatmapEventsProps {
  installationId: string;
}

const InstallationWeekHeatmapByEvents: React.FC<InstallationWeekHeatmapByEventsProps> = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  dataResidency,
  title,
  dataSource,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const installationEventsState = useInstallationEventsByHour({
    tenantId,
    installationId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const data = useMemo(
    () => buildEventsData(installationEventsState.data || [], dataSource.eventType),
    [installationEventsState, dataSource],
  );

  return (
    <CardContainer
      isLoading={installationEventsState.isLoading}
      isSuccess={installationEventsState.isSuccess}
      isError={installationEventsState.isError}
      hasData={
        installationEventsState.isSuccess && installationEventsState.data.length > 0
      }
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      <WeekHeatmap data={data} />
    </CardContainer>
  );
};

interface InstallationWeekHeatmapProps extends WeekHeatmapProps {
  installationId: string;
}

const InstallationWeekHeatmap: React.FC<InstallationWeekHeatmapProps> = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  title,
  dataResidency,
  dataSource,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const view = useMemo(() => {
    switch (dataSource.type) {
      case WeekHeatmapDataSourceType.Sessions:
        return (
          <InstallationWeekHeatmapBySessions
            tenantId={tenantId}
            installationId={installationId}
            dateFrom={dateFrom}
            dateTo={dateTo}
            title={title}
            dataResidency={dataResidency}
            dataSource={dataSource}
            gridStyles={gridStyles}
            isVisibleWithoutData={isVisibleWithoutData}
          />
        );
      case WeekHeatmapDataSourceType.Events:
        return (
          <InstallationWeekHeatmapByEvents
            tenantId={tenantId}
            installationId={installationId}
            dateFrom={dateFrom}
            dateTo={dateTo}
            title={title}
            dataResidency={dataResidency}
            dataSource={dataSource}
            gridStyles={gridStyles}
            isVisibleWithoutData={isVisibleWithoutData}
          />
        );
      default:
        throw new Error(`Unknown data source: ${JSON.stringify(dataSource)}`);
    }
  }, [
    tenantId,
    installationId,
    dateFrom,
    dateTo,
    title,
    dataResidency,
    dataSource,
    gridStyles,
    isVisibleWithoutData,
  ]);

  return <>{view}</>;
};

export default InstallationWeekHeatmap;
