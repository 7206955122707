import React, { useState, useEffect, useCallback } from 'react';
import { useActions, useStore } from 'easy-peasy';
import { omit, get } from 'lodash';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import QueueSetup from './queue-setup.component';
import { RootModel, RootState } from '../../../../../store/models/root.model';
import Queue from '../../../../../store/types/queue';
import { QueueWithoutExtraProperties } from './convert-data-from-form';
import { getPermissions } from '../../../../../utils/auth/permissions';

type QueueCreateContainerProps = Omit<
  React.ComponentProps<typeof QueueSetup>,
  | 'queue'
  | 'onSubmit'
  | 'loaded'
  | 'regions'
  | 'submitButtonText'
  | 'headerTitle'
  | 'loading'
  | 'error'
  | 'successMessage'
  | 'onSuccess'
  | 'errorMessage'
  | 'buttonLoading'
  | 'canSave'
>;

const QueueEditContainer = (props: QueueCreateContainerProps) => {
  const {
    match: {
      params: { organisationId, appId },
    },
  } = props;
  const { t } = useTranslation();
  const [queue, setQueue] = useState<Queue | null>(null);
  const [onSuccess, setOnSuccess] = useState<Record<string, any> | null>(null);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const {
    installation,
    queueSettings,
    loaded,
    loading,
    error,
    permissions,
    queueRegions,
    user,
  } = useStore<RootState>((state) => {
    const appSettings =
      state.organisationApps.data &&
      state.organisationApps.data[organisationId] &&
      state.organisationApps.data[organisationId][appId] &&
      state.organisationApps.data[organisationId][appId].settings;
    return {
      queueRegions:
        (state.organisations.data &&
          state.organisations.data[organisationId] &&
          state.organisations.data[organisationId].dataResidency && [
            state.organisations.data[organisationId].dataResidency,
          ]) ||
        null,
      installation:
        state.organisationApps.data &&
        state.organisationApps.data[organisationId] &&
        state.organisationApps.data[organisationId][appId],
      queueSettings: get(appSettings, 'provider.app.settings', null),
      loaded: state.organisationApps.loaded,
      loading: state.organisationApps.loading,
      error: state.organisationApps.error,
      permissions: getPermissions(state.auth.user, props.match.params.organisationId),
      user: state.auth.user,
    };
  });

  const { update, setAppData } = useActions<RootModel>((actions) => ({
    update: actions.queues.update,
    setAppData: actions.organisationApps.setSingle,
  }));

  useEffect(() => {
    if (queueSettings) {
      setQueue(queueSettings);
    }
  }, [queueSettings]);

  const onSubmit = useCallback(
    async (value: QueueWithoutExtraProperties) => {
      try {
        setButtonLoading(true);
        const body = {
          ...omit(value, 'id', 'organization'),
        };

        const updatedQueue = await update({ organisationId, id: appId, body });
        setAppData({
          ...installation,
          displayName:
            updatedQueue.title || updatedQueue.shortDisplayName || 'Unnamed queue',
          settings: {
            ...installation.settings,
            provider: {
              ...installation.settings.provider,
              app: {
                ...installation.settings.provider.app,
                settings: updatedQueue,
              },
            },
          },
        });
        setOnSuccess({});
      } catch (err) {
        if (err.statusCode === 429) {
          message.error(t('failedToUpdateQueueStaleVersion'));
        } else {
          message.error(t('failedToUpdateQueue'));
        }
      }
      setButtonLoading(false);
    },
    [update, organisationId, appId, setAppData, installation, t],
  );

  return (
    <QueueSetup
      {...props}
      organisationId={organisationId}
      queue={queue}
      onSubmit={onSubmit}
      loaded={loaded}
      regions={queueRegions}
      submitButtonText={t('queueInstallation.saveChanges')}
      canSave={permissions.queues.update}
      headerTitle={queue ? queue.title : ''}
      loading={loading}
      error={error}
      successMessage={t('queueInstallation.successfullyUpdated')}
      errorMessage={t('queueInstallation.failedToUpdate')}
      onSuccess={onSuccess}
      buttonLoading={buttonLoading}
      user={user}
      provider={installation.provider}
    />
  );
};

export default QueueEditContainer;
