import React, { useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import styled from '@emotion/styled';
import { Button, Col, Popconfirm, Row, message } from 'antd';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { ButtonType } from '../../../../../../types';
import { StickyColumn } from '../../../queue-details/queue-setup/styled-block';
import {
  IntegrationFormFields,
  IntegrationJobHistoryList,
  IntegrationSidePanel,
  integrationTypes,
  validateIntegrationForm,
} from '../common';
import {
  scrollIntoView,
  getFieldNamesWithError,
  CronSchedule,
  parseCronExpression,
  generateCronExpression,
} from '../../../../../common/react-final-form';
import { useTranslation } from 'react-i18next';
import Message from '../../../../../common/message';
import { ApiError } from '../../../../../../services/api/api-error';
import {
  UpdateIntegrationVariables,
  useDeleteIntegration,
  useIntegration,
  useIntegrationInvalidateQueries,
  useUpdateIntegration,
} from '../hooks';
import { DataResidencyEnum } from '../../../../../../store/types/organisation';
import { useEnvironments } from '../../../../../common/use-environments';
import {
  IntegrationFormValues,
  IntegrationTypeEnum,
  IntegrationFormInitialValues,
} from '../types';
import moment from 'moment';
import Overlay from '../../../../../common/overlay/overlay-v2.component';
import usePermissions from '../../../../../../utils/auth/use-permissions';
import permissionKeys from '../../../../../../utils/auth/permissions';

export type IntegrationUpdateFormProps = RouteComponentProps<{
  organisationId: string;
  integrationId: string;
  type: IntegrationTypeEnum;
}> & {
  dataResidency: DataResidencyEnum;
};

const IntegrationUpdateForm = (props: IntegrationUpdateFormProps) => {
  const { match, dataResidency } = props;

  const { organisationId: tenantId, integrationId, type: integrationType } = match.params;

  const { t } = useTranslation();

  const history = useHistory();

  const { mutateAsync: updateIntegration, isLoading: isUpdating } = useUpdateIntegration({
    tenantId,
    dataResidency,
    integrationId,
  });

  const { data: integrationData, isLoading: isIntegrationDataLoading } = useIntegration({
    tenantId,
    dataResidency,
    integrationId,
  });

  const { mutateAsync: deleteIntegration, isLoading: isDeleting } = useDeleteIntegration({
    tenantId,
    dataResidency,
  });

  const { invalidateGetIntegrationsQuery } = useIntegrationInvalidateQueries();

  const { data: environments = [], isLoading: isEnvironmentsLoading } = useEnvironments(tenantId);

  const environmentOptions = environments.map((environment) => {
    const { environmentName, displayName } = environment;

    return {
      value: environmentName,
      label: displayName,
    };
  });

  const integrationsListPath = `/organisations/${tenantId}/settings/integrations`;

  const { isAllowed } = usePermissions(tenantId);

  const isDeleteAllowed = useMemo(() => isAllowed(permissionKeys.integrations.remove), [
    isAllowed,
  ]);

  const handleBackClick = useCallback(() => {
    history.push(integrationsListPath);
  }, [history, integrationsListPath]);

  const handleFormUpdateSubmit = useCallback(
    async (values: object) => {
      try {
        const formData = (values as unknown) as IntegrationFormValues;

        formData.type = integrationType;
        formData.config = {
          ...formData.config,
          format: 'xml',
        };

        const { schedule, ...restFormData } = formData;

        const updatedSchedule = schedule.map((sched) => sched.cronExpressionUtc);

        const payload: UpdateIntegrationVariables = {
          ...restFormData,
          schedule: updatedSchedule,
        };

        await updateIntegration(payload);

        await invalidateGetIntegrationsQuery(tenantId);

        message.success(
          <Message content={t('productIntegration.successUpdatingData')} />,
        );
      } catch (error) {
        const err = (error as unknown) as ApiError;

        message.error(
          <Message content={t('productIntegration.errorUpdatingData')} error={err} />,
        );
      }
    },
    [integrationType, invalidateGetIntegrationsQuery, t, tenantId, updateIntegration],
  );

  const handleDelete = useCallback(async () => {
    try {
      await deleteIntegration(integrationId);

      await invalidateGetIntegrationsQuery(tenantId);

      message.success(
        <Message content={t('productIntegration.successDeletingIntegration')} />,
      );

      handleBackClick();
    } catch {
      message.error(
        <Message content={t('productIntegration.errorDeletingIntegration')} />,
      );
    }
  }, [
    deleteIntegration,
    handleBackClick,
    integrationId,
    invalidateGetIntegrationsQuery,
    t,
    tenantId,
  ]);

  const { label: integrationTypeLabel = '' } =
    integrationTypes.find((type) => type.value === integrationType) || {};

  const initialValues = useMemo((): Partial<IntegrationFormInitialValues> | undefined => {
    if (!integrationData) {
      return undefined;
    }

    const { name, status, schedule, environment, config } = integrationData.data;

    const mappedSchedule = schedule.map(
      (sched): CronSchedule | null => {
        try {
          const { frequency = null, hour = null, minute = null } =
            parseCronExpression(sched) || {};

          if (frequency === null || hour === null || minute === null) {
            return null;
          }

          const utcTimeString = `${hour}:${minute}`;
          const utcTime = moment.utc(utcTimeString, 'HH:mm');
          const localTime = utcTime.local();
          const localTimeString = localTime.format('HH:mm');

          const cronExpression = generateCronExpression(frequency, localTimeString);

          return {
            frequency,
            time: localTimeString,
            cronExpression,
            cronExpressionUtc: sched,
          };
        } catch {
          return null;
        }
      },
    );

    const filteredSchedule = mappedSchedule.filter(
      (schedule) => schedule !== null,
    ) as CronSchedule[];

    const transformedIntegrationData = {
      name,
      status,
      schedule: filteredSchedule,
      environment,
      config,
    };

    return transformedIntegrationData;
  }, [integrationData]);

  return (
    <IntegrationsFormContent>
      <Overlay
        spinnerOverlay={{ isLoading: isIntegrationDataLoading || isEnvironmentsLoading }}
      >
        <ActionButtons>
          <BackToListButton type="default" icon="arrow-left" onClick={handleBackClick}>
            {t('back')}
          </BackToListButton>
          {isDeleteAllowed && (
            <Popconfirm
              title={t('productIntegration.deleteIntegrationConfirmation')}
              onConfirm={handleDelete}
              okText={t('yes')}
              cancelText={t('no')}
            >
              <DeleteButton
                size="default"
                icon="delete"
                type="link"
                disabled={isDeleting}
                loading={isDeleting}
              >
                {t('deleteAlt')}
              </DeleteButton>
            </Popconfirm>
          )}
        </ActionButtons>
        <Form
          onSubmit={handleFormUpdateSubmit}
          validate={(values) =>
            validateIntegrationForm({
              ...((values as unknown) as IntegrationFormValues),
              type: integrationType,
            })
          }
          keepDirtyOnReinitialize
          initialValues={initialValues}
          mutators={{
            ...arrayMutators,
          }}
          render={({
            submitting: isSubmitting,
            handleSubmit,
            invalid: isFormInvalid,
            errors,
          }) => {
            return (
              <form
                onSubmit={(event) => {
                  handleSubmit(event);

                  const errorFieldNames = getFieldNamesWithError(errors);

                  if (!isFormInvalid || !errorFieldNames.length) {
                    return;
                  }

                  scrollIntoView(errorFieldNames[0]);
                }}
              >
                <Row gutter={{ md: 20, xl: 40 }}>
                  <Col md={24} xl={15}>
                    <IntegrationFormFields
                      integrationType={integrationType}
                      title={t('productIntegration.updateIntegration', {
                        label: integrationTypeLabel,
                      })}
                      environments={environmentOptions}
                    />
                    <Row>
                      <IntegrationJobHistoryList
                        tenantId={tenantId}
                        dataResidency={dataResidency}
                        integrationId={integrationId}
                      />
                    </Row>
                  </Col>
                  <StickyColumn md={24} xl={9}>
                    <IntegrationSidePanel
                      isSubmitEnabled={!isSubmitting && !isDeleting && !isUpdating}
                      isSubmitting={isUpdating}
                      lastProcessedDate={
                        integrationData ? integrationData.data.updatedAt : undefined
                      }
                    />
                  </StickyColumn>
                </Row>
              </form>
            );
          }}
        />
      </Overlay>
    </IntegrationsFormContent>
  );
};

const IntegrationsFormContent = styled.div`
  position: relative;
  flex: 1;
  padding: 0 80px 40px;

  @media screen and (max-width: 991px) {
    padding: 0;
  }
`;

const BackToListButton = styled(Button)`
  height: 36px;
  font-size: 14px;
  color: #676973;
  border: none;
  margin-right: 5px;
  background-color: transparent;

  :hover,
  :active,
  :focus {
    background-color: #f2f4f8;
  }
` as ButtonType;

const ActionButtons = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const DeleteButton = styled(Button)`
  height: 36px;
  font-size: 14px;
  color: #676973;
  border: none;
  margin-left: auto;
  background-color: transparent;

  :hover,
  :active,
  :focus {
    background-color: #f2f4f8;
  }
` as ButtonType;

export default IntegrationUpdateForm;
