import React, { PureComponent } from "react";
import { Select } from "antd";
import DropDownWrap from "./DropDownWrap";

// The actual number of dropdown menu items rendered on the page, which is 2 * ITEM_ELEMENT_NUMBER
const ITEM_ELEMENT_NUMBER = 30;
// Select size configuration
const ITEM_HEIGHT_CFG = {
  small: 24,
  large: 40,
  default: 32
};

const ARROW_CODE = {
  40: "down",
  38: "up"
};

const DROPDOWN_HEIGHT = 224;

const Option = Select.Option;

class SuperSelect extends PureComponent {
  constructor(props) {
    super(props);

    const { mode, defaultValue, value, optionHeight } = props;
    this.isMultiple = ["tags", "multiple"].includes(mode);

    // Set default value
    let defaultV = this.isMultiple ? [] : "";
    defaultV = value || defaultValue || defaultV;

    this.state = {
      children: props.children || [],
      filterChildren: null, // Filtered options, displayed first, so set to null manually after clearing the filter
      value: defaultV
    };
    // Dropdown menu item row height
    this.ITEM_HEIGHT = optionHeight || ITEM_HEIGHT_CFG[props.size || "default"];
    // Visible DOM height
    this.visibleDomHeight = this.ITEM_HEIGHT * ITEM_ELEMENT_NUMBER;
    // ScrollTop threshold for re-rendering during scrolling; refresh the dropdown list if greater than reactDelta
    this.reactDelta = this.visibleDomHeight / 3;
    // Indicates if the scrollbar is being dragged quickly
    this.isStopReact = false;
    // Last scrollTop value
    this.prevScrollTop = 0;
    // Last scrollTop value when the arrow key was pressed
    this.prevTop = 0;

    this.scrollTop = 0;

    // className
    this.dropdownClassName = `dc${+new Date()}`;

    this.id = `sid${+new Date()}`;
  }

  componentDidMount() {
    // Add scroll event when defaultOpens=true
    setTimeout(() => {
      this.addEvent();
    }, 500);
  }

  componentDidUpdate(prevProps) {
    const { mode, defaultValue, value, children } = this.props;
    if (prevProps.children !== children) {
      this.isMultiple = ["tags", "multiple"].includes(mode);

      this.setState({
        children: children || [],
        filterChildren: null
      });
    }
    if (prevProps.value !== value) {
      // Update and set default value
      let defaultV = this.isMultiple ? [] : "";
      defaultV = value || defaultValue || defaultV;
      this.setState({ value: defaultV });
    }
  }

  componentWillUnmount() {
    this.removeEvent();
  }

  // Scroll to the position of the value if it exists
  scrollToValue = () => {
    if (!this.scrollEle) return;
    const { children } = this.props;
    const { value } = this.state;
    const index = children.findIndex(item => item.key === value) || 0;

    const y = this.ITEM_HEIGHT * index;
    this.scrollEle.scrollTop = y;
    setTimeout(() => {
      this.forceUpdate();
    }, 0);
  };

  getItemStyle = i => ({
    position: "absolute",
    top: this.ITEM_HEIGHT * i,
    width: "100%",
    height: this.ITEM_HEIGHT
  });

  addEvent = () => {
    this.scrollEle = document.querySelector(`.${this.dropdownClassName}`);
    // Element does not exist when the dropdown menu is not expanded
    if (!this.scrollEle) return;

    this.scrollEle.addEventListener("scroll", this.onScroll, false);
    this.inputEle = document.querySelector(`#${this.id}`);

    if (!this.inputEle) return;
    this.inputEle.addEventListener("keydown", this.onKeyDown, false);
  };

  // Simulate scrolling the list when pressing up or down arrow keys in antd select
  onKeyDown = e => {
    const { keyCode } = e || {};

    setTimeout(() => {
      const activeItem = document.querySelector(
        `.${this.dropdownClassName} .ant-select-dropdown-menu-item-active`
      );
      if (!activeItem) return;

      const { offsetTop } = activeItem;
      const isUp = ARROW_CODE[keyCode] === "up";
      const isDown = ARROW_CODE[keyCode] === "down";

      // Press up key on the first row of the entire list
      if (offsetTop - this.prevTop > DROPDOWN_HEIGHT && isUp) {
        this.scrollEle.scrollTo(0, this.allHeight - DROPDOWN_HEIGHT);
        this.prevTop = this.allHeight;

        return;
      }

      // Press down key on the last row of the entire list
      if (this.prevTop > offsetTop + DROPDOWN_HEIGHT && isDown) {
        this.scrollEle.scrollTo(0, 0);
        this.prevTop = 0;

        return;
      }

      this.prevTop = offsetTop;
      // Scroll down one row height when scrolling to the last row of the dropdown
      if (
        offsetTop >
        this.scrollEle.scrollTop + DROPDOWN_HEIGHT - this.ITEM_HEIGHT + 10 &&
        isDown
      ) {
        this.scrollEle.scrollTo(0, this.scrollTop + this.ITEM_HEIGHT);
        return;
      }
      // Scroll up one row height when scrolling to the first row of the dropdown
      if (offsetTop < this.scrollEle.scrollTop && isUp) {
        this.scrollEle.scrollTo(0, this.scrollTop - this.ITEM_HEIGHT);
      }
    }, 100);
  };

  onScroll = () => this.throttleByHeight(this.onScrollReal);

  onScrollReal = () => {
    this.allList = this.getUseChildrenList();
    const { startIndex, endIndex } = this.getStartAndEndIndex();

    this.prevScrollTop = this.scrollTop;
    // Re-render the list component Wrap
    const allHeight = this.allList.length * this.ITEM_HEIGHT || 100;
    this.wrap.reactList(allHeight, startIndex, endIndex);
  };

  throttleByHeight = () => {
    this.scrollTop = this.scrollEle.scrollTop;
    // Scrolled height
    let delta = this.prevScrollTop - this.scrollTop;
    delta = delta < 0 ? 0 - delta : delta;

    delta > this.reactDelta && this.onScrollReal();
  };

  // List can display all children
  getUseChildrenList = () => this.state.filterChildren || this.state.children;

  getStartAndEndIndex = () => {
    // Index of the first item displayed in the list view after scrolling
    const showIndex = Number((this.scrollTop / this.ITEM_HEIGHT).toFixed(0));

    const startIndex =
      showIndex - ITEM_ELEMENT_NUMBER < 0
        ? 0
        : showIndex - ITEM_ELEMENT_NUMBER / 2;
    const endIndex = showIndex + ITEM_ELEMENT_NUMBER;
    return { startIndex, endIndex };
  };

  // Must use setTimeout to ensure events are added after DOM is loaded
  setSuperDrowDownMenu = visible => {
    if (!visible) return;

    this.allList = this.getUseChildrenList();

    if (!this.eventTimer) {
      this.eventTimer = setTimeout(() => this.addEvent(), 0);
    } else {
      const allHeight = this.allList.length * this.ITEM_HEIGHT || 100;
      // Re-render the dropdown list separately
      const { startIndex, endIndex } = this.getStartAndEndIndex();
      setTimeout(() => {
        this.wrap && this.wrap.reactList(allHeight, startIndex, endIndex);
      }, 0);
    }
  };

  onDropdownVisibleChange = visible => {
    const { onDropdownVisibleChange } = this.props;
    onDropdownVisibleChange && onDropdownVisibleChange(visible);

    // Clear filter conditions before closing the dropdown to prevent displaying filtered options next time
    if (!visible) {
      // Timer to ensure filterChildren is set after closing, preventing list refresh flicker
      setTimeout(() => {
        this.setState({ filterChildren: null });
      });
    } else {
      // If there is a value, set the default scroll position
      this.setDefaultScrollTop();
      // Set the dropdown list display data
      this.setSuperDrowDownMenu(visible);
    }
  };

  onDeselect = value => {
    const { onDeselect } = this.props;
    onDeselect && onDeselect(value);
  };

  // Recalculate the height of the dropdown scrollbar on search
  onChange = (value, opt) => {
    const { showSearch, onChange, autoClearSearchValue } = this.props;
    if (showSearch || this.isMultiple) {
      // Reset search state after selection in search mode if needed
      if (autoClearSearchValue !== false) {
        this.setState({ filterChildren: null }, () => {
          // Reset the total height of the list after successful search
          this.setSuperDrowDownMenu(true);
        });
      }
    }

    this.setState({ value });
    onChange && onChange(value, opt);
  };

  onSearch = v => {
    const { showSearch, onSearch, filterOption, children } = this.props;
    if (showSearch && filterOption !== false) {
      // Filter the list manually based on filterOption (if this custom function exists)
      let filterChildren = null;
      if (typeof filterOption === "function") {
        filterChildren = children.filter(item => filterOption(v, item));
      } else if (filterOption === undefined) {
        filterChildren = children.filter(item => this.filterOption(v, item));
      }

      // Set the dropdown list display data
      this.setState(
        { filterChildren: v === "" ? null : filterChildren },
        () => {
          setTimeout(() => {
            // Reset scroll position to 0 after search to prevent data at previous scrollTop position
            if (filterChildren) {
              this.scrollTop = 0;
              this.scrollEle.scrollTo(0, 0);
            }
            // Reset the total height of the list after successful search
            this.setSuperDrowDownMenu(true);
          }, 0);
        }
      );
    }
    onSearch && onSearch(v);
  };

  filterOption = (v, option) => {
    // Custom filter for the corresponding option property configuration
    const filterProps = this.props.optionFilterProp || "value";
    return `${option.props[filterProps]}`.indexOf(v) >= 0;
  };

  setDefaultScrollTop = () => {
    const { value } = this.state;
    const { children } = this.props;

    for (let i = 0; i < children.length; i++) {
      const item = children[i];
      const itemValue = item.props.value;
      if (
        itemValue === value ||
        (Array.isArray(value) && value.includes(itemValue))
      ) {
        const targetScrollTop = i * this.ITEM_HEIGHT;

        setTimeout(() => {
          this.scrollEle.scrollTo(0, targetScrollTop);
        }, 100);
        return;
      }
    }
  };

  removeEvent = () => {
    if (!this.scrollEle) return;
    this.scrollEle.removeEventListener("scroll", this.onScroll, false);
    if (!this.inputEle) return;
    this.inputEle.removeEventListener("keydown", this.onKeyDown, false);
  };

  render() {
    let {
      dropdownStyle,
      optionLabelProp,
      notFoundContent,
      dropdownClassName,
      ...props
    } = this.props;

    this.allList = this.getUseChildrenList();

    this.allHeight = this.allList.length * this.ITEM_HEIGHT || 100;
    const { startIndex, endIndex } = this.getStartAndEndIndex();

    dropdownStyle = {
      maxHeight: `${DROPDOWN_HEIGHT}px`,
      ...dropdownStyle,
      overflow: "auto",
      position: "relative"
    };

    const { value } = this.state;
    // Determine whether to automatically set value when inside antd Form
    const _props = { ...props };
    // Remove value first, then manually assign to prevent empty value affecting placeholder
    delete _props.value;

    // Empty string value hides placeholder, change to undefined
    if (typeof value === "string" && !value) {
      _props.value = undefined;
    } else {
      _props.value = value;
    }

    optionLabelProp = optionLabelProp || "children";

    return (
      <Select
        {..._props}
        id={this.id}
        onSearch={this.onSearch}
        onChange={this.onChange}
        dropdownClassName={`${this.dropdownClassName} ${dropdownClassName ||
        ""}`}
        optionLabelProp={optionLabelProp}
        dropdownStyle={dropdownStyle}
        onDropdownVisibleChange={this.onDropdownVisibleChange}
        onDeselect={this.onDeselect}
        ref={ele => (this.select = ele)}
        dropdownRender={menu => {
          if (this.allList.length === 0) {
            return <div style={{ padding: "5px 12px" }}>{notFoundContent}</div>;
          }

          return (
            <DropDownWrap
              {...{
                startIndex,
                endIndex,
                allHeight: this.allHeight,
                menu,
                itemHeight: this.ITEM_HEIGHT
              }}
              ref={ele => {
                this.wrap = ele;
              }}
            />
          );
        }}
      >
        {this.allList}
      </Select>
    );
  }
}

SuperSelect.Option = Option;

export default SuperSelect;
