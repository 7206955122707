import CustomShortUrl from '../../types/custom-short-urls';
import createKeyedCrudModel, {
  KeyedCrudModel,
} from '../../common/keyed-crud-model/keyed-crud.model';

export type OrganisationCustomShortUrlModel = KeyedCrudModel<
  CustomShortUrl,
  { organizationId: string }
>;

const organisationCustomShortUrlModel: OrganisationCustomShortUrlModel = createKeyedCrudModel<
  CustomShortUrl,
  { organizationId: string }
>('/api/custom-short-urls', 'organizationId');

export default organisationCustomShortUrlModel;
