import axios from 'axios';

const urlToFile = async (
  url: string,
  filename: string,
  onDownloadProgress: (percent: number) => void,
) => {
  const response = await axios.get<Blob>(url, {
    responseType: 'blob',
    onDownloadProgress: (progressEvent: ProgressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

      onDownloadProgress(percentCompleted);
    },
  });

  const file = new File([response.data], filename, { type: response.headers['content-type'] });

  return file;
};

export default urlToFile;
