import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import { RootModel, RootState } from '../../../store/models/root.model';
import FederatedDomainList from './federated-domain-list.component';

type FederatedDomainListContainerProps = Omit<
  React.ComponentProps<typeof FederatedDomainList>,
  | 'federatedDomains'
  | 'loaded'
  | 'fetchFederatedDomains'
  | 'createFederatedDomain'
  | 'updateFederatedDomain'
  | 'deleteFederatedDomain'
>;

const FederatedDomainListContainer = (props: FederatedDomainListContainerProps) => {
  const {
    fetchFederatedDomains,
    createFederatedDomain,
    updateFederatedDomain,
    deleteFederatedDomain,
  } = useActions<RootModel>((actions) => ({
    fetchFederatedDomains: actions.federatedDomains.fetch,
    createFederatedDomain: actions.federatedDomains.create,
    updateFederatedDomain: actions.federatedDomains.update,
    deleteFederatedDomain: actions.federatedDomains.delete,
  }));

  const { federatedDomains, loaded } = useStore<RootState>((state) => ({
    federatedDomains: state.federatedDomains.values,
    loaded: state.federatedDomains.loaded,
  }));

  return (
    <FederatedDomainList
      {...props}
      federatedDomains={federatedDomains}
      loaded={loaded}
      fetchFederatedDomains={fetchFederatedDomains}
      createFederatedDomain={createFederatedDomain}
      updateFederatedDomain={updateFederatedDomain}
      deleteFederatedDomain={deleteFederatedDomain}
    />
  );
};

export default FederatedDomainListContainer;
