import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Button, Col, Row, Table } from 'antd';
import ReactJson from 'react-json-view';
import dayjs from 'dayjs';
import { PaginationConfig } from 'antd/lib/pagination';
import { useTranslation } from 'react-i18next';
import Header from '../../common/app-layout/header/header.component';
import { loadingIcon } from '../../common/spinner/spinner.component';
import ActionLog from '../../../store/types/action-log';
import { ApiError } from '../../../services/api/api-error';
import SearchInput from '../../common/search-input/search-input.component';
import { PaginationData, PaginationRequest } from '../../../store/types/pagination';

const { Column } = Table;

const FiltersRow = styled(Row)`
  margin-bottom: 16px;
`;

interface ActionLogsListProps {
  logs: ActionLog[];
  error: ApiError | null;
  loading: boolean;
  fetchLogs: (payload: PaginationRequest) => void;
  pagination: PaginationData | null;
}

const defaultPaginationParams = {
  page: 1,
  limit: 50,
};

const ActionLogsList = (props: ActionLogsListProps) => {
  const { loading, fetchLogs, logs, pagination } = props;
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    fetchLogs(defaultPaginationParams);
  }, [fetchLogs]);

  useEffect(() => {
    setExpandedRows([]);
  }, [logs]);

  const renderExpandedRow = useCallback(
    (item: ActionLog, index: number, indent: number, expanded: boolean) => {
      return expanded && item.body ? (
        <ReactJson name={null} displayDataTypes={false} src={JSON.parse(item.body)} />
      ) : null;
    },
    [],
  );

  const toggleRowExpanded = useCallback(
    (item: ActionLog) => {
      if (expandedRows.includes(item.id)) {
        setExpandedRows(expandedRows.filter((rows) => rows !== item.id));
      } else {
        setExpandedRows([...expandedRows, item.id]);
      }
    },
    [setExpandedRows, expandedRows],
  );

  const renderExpandButton = useCallback(
    (item: ActionLog) => {
      return item.body ? (
        <Button icon="plus" onClick={() => toggleRowExpanded(item)} />
      ) : null;
    },
    [toggleRowExpanded],
  );

  const handleTableChange = useCallback(
    (paginationConfig: PaginationConfig) => {
      const { current, pageSize } = paginationConfig;
      if (current && pageSize) {
        fetchLogs({
          query: pagination ? pagination.query : undefined,
          page: current,
          limit: pageSize,
        });
      }
    },
    [fetchLogs, pagination],
  );

  const handleSearch = useCallback(
    (query: string) => {
      if (pagination && query !== pagination.query) {
        fetchLogs({
          ...defaultPaginationParams,
          query,
        });
      }
    },
    [fetchLogs, pagination],
  );

  const getPaginationConfig = useCallback(() => {
    return pagination
      ? {
          total: pagination.total,
          current: pagination.page,
          pageSize: pagination.limit,
        }
      : {};
  }, [pagination]);

  return (
    <>
      <Header title={t('actionLogs')} />
      <div className="content-body">
        <FiltersRow type="flex" justify="end">
          <Col lg={12} xs={16}>
            <SearchInput
              placeholder={t('searchByUrlMethodUserOrBodyContent')}
              onSearch={handleSearch}
              loading={loading}
            />
          </Col>
        </FiltersRow>
        <Table
          expandIcon={() => null}
          expandIconAsCell={false}
          expandedRowKeys={expandedRows}
          expandedRowRender={renderExpandedRow}
          rowKey="id"
          pagination={getPaginationConfig()}
          onChange={handleTableChange}
          dataSource={logs}
          loading={{
            indicator: loadingIcon,
            spinning: loading,
          }}
        >
          <Column width={50} render={renderExpandButton} key="expand" />
          <Column
            width={200}
            title={t('date')}
            dataIndex="timestamp"
            key="timestamp"
            render={(value: number) => dayjs(value).format('YYYY-MM-DD HH:mm:ss')}
          />
          <Column width={100} title={t('method')} dataIndex="method" key="method" />
          <Column title="URL" dataIndex="url" key="url" />
          <Column title={t('user')} dataIndex="user.email" key="user" />
          <Column
            width={280}
            ellipsis
            title="User Agent"
            dataIndex="userAgent"
            key="userAgent"
          />
        </Table>
      </div>
    </>
  );
};

export default ActionLogsList;
