import React from 'react';
import { useActions, useStore } from 'easy-peasy';
import DeviceCreate from './device-create.component';
import { RootModel, RootState } from '../../../store/models/root.model';

type DeviceAddContainerProps = Omit<
  React.ComponentProps<typeof DeviceCreate>,
  'environments' | 'apps'
>;

const DeviceCreateContainer = (props: DeviceAddContainerProps) => {
  const { apps, environments, organisations } = useStore<RootState>((state) => {
    return {
      environments: state.environments.data,
      apps: state.organisationApps.data,
      organisations: state.organisations.values,
    };
  }, []);

  const { fetchApps, createDevice, fetchEnvironments } = useActions<RootModel>(
    (actions) => ({
      fetchApps: actions.organisationApps.fetch,
      createDevice: actions.appDevices.create,
      fetchEnvironments: actions.environments.fetch,
    }),
  );

  return (
    <DeviceCreate
      {...props}
      organisations={organisations}
      environments={environments}
      apps={apps}
      fetchApps={fetchApps}
      createDevice={createDevice}
      fetchEnvironments={fetchEnvironments}
    />
  );
};

export default DeviceCreateContainer;
