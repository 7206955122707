import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Button } from 'antd';
import { get } from 'lodash';
import PlanPanelCard from '../../common/plan-panel-card.component';
import Plan from '../../../../../../store/types/organisation-plan';
import PlanItems from '../../common/plan-items.component';
import { getTranslationFromObject } from '../../../../../../utils/language/language.util';
import Loader from '../../../../../common/loader/loader-component';
import { ApiError } from '../../../../../../services/api/api-error';
import ErrorView from '../../../../../common/error-view/error-view.component';
import { getPlanChangeType, PlanChangeType } from '../../../../../../utils/plans';

interface TenantPlansProps {
  loading: boolean;
  error: ApiError | null;
  activePlan: Plan | null;
  scheduledPlan: Plan | null;
  onUpgradePlan: () => void;
}

const TenantPlans = (props: TenantPlansProps) => {
  const { loading, error, activePlan, scheduledPlan, onUpgradePlan } = props;

  const planChangeType = getPlanChangeType(activePlan, scheduledPlan);
  const planToDisplay =
    planChangeType === PlanChangeType.UPGRADE ? scheduledPlan : activePlan;

  const { name = undefined, features = [] } = planToDisplay || {};
  const { t } = useTranslation();

  const { planName, planButton } = useMemo(() => {
    const statusCode: number = get(error, 'response.statusCode');
    const isAccessAllowed = statusCode !== 403;

    return {
      planName:
        !loading && name && isAccessAllowed ? getTranslationFromObject(name) : undefined,
      planButton:
        !loading && name && isAccessAllowed ? (
          <Button onClick={onUpgradePlan}>{t('plans.upgradePlan')}</Button>
        ) : (
          undefined
        ),
    };
  }, [error, loading, name, onUpgradePlan, t]);

  const noPlan =
    planToDisplay == null ? (
      <ErrorView title={t('plans.noPlanAvailable')} content={null} fillPage />
    ) : null;

  const alertDescription =
    planChangeType === PlanChangeType.DOWNGRADE
      ? t('plans.planDowngradeScheduled')
      : undefined;

  return (
    <PlanPanelCard
      icon="solution"
      title={t('plans.tenantPlan')}
      tag={planName}
      extra={planButton}
      footer={planButton}
      alertType="info"
      alertDescription={alertDescription}
    >
      <Content className="content-body" padded={loading}>
        <Loader loading={loading} error={error || noPlan} errorFillPage>
          <PlanItems items={features} />
        </Loader>
      </Content>
    </PlanPanelCard>
  );
};

const Content = styled.div<{ padded?: boolean }>`
  padding: ${({ padded }) => (padded ? `40px 0` : '0')} !important;

  @media (max-width: 1500px) {
    .ant-row > .ant-col {
      display: inline;
      width: 100%;
    }
  }
`;

export default TenantPlans;
