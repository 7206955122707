import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { message } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { Icon } from '../../../../common/schema-form/common';
import { useStoreAiKnowledgeBaseFileListInvalidateQuery, useStoreAiKnowledgeBaseFileUpload } from '../../../../../store/hooks/store-ai-knowledge-base';
import { RcCustomRequestOptions, UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import Message from '../../../../common/message/message.component';
import { ApiError } from '../../../../../services/api/api-error';

interface KnowledgeBaseFileUploaderProps {
  tenantId: string;
}

const KnowledgeBaseFileUploader = (props: KnowledgeBaseFileUploaderProps) => {
  const { tenantId } = props;
  const [filesToUpload, setFilesToUpload] = useState<UploadFile[]>([]);

  const { t } = useTranslation();

  const { mutateAsync: uploadFile } = useStoreAiKnowledgeBaseFileUpload();

  const invalidateKnowledgeBaseFileListQuery = useStoreAiKnowledgeBaseFileListInvalidateQuery();

  const handleFileUpload = useCallback(
    async (options: RcCustomRequestOptions) => {
      const { onSuccess, onError, file, onProgress } = options;
      try {
        await uploadFile({
          tenantId,
          file,
          onProgress: (percent) => {
            onProgress({ percent }, file);
          },
        });

        await invalidateKnowledgeBaseFileListQuery({ tenantId });

        onSuccess({}, file);

        message.success(
          <Message content={t('knowledgeBase.successUploadingFile')} />,
        );
      } catch (error) {
        onError(error as Error);
        message.error(
          <Message content={t('knowledgeBase.errorUploadingFile')} error={error as ApiError} />,
        );
      }
    },
    [invalidateKnowledgeBaseFileListQuery, tenantId, uploadFile, t],
  );

  const handleFileUploadStatusChange = useCallback((event: UploadChangeParam) => {
    if (
      event.file &&
      event.file.status &&
      ['done', 'error'].includes(event.file.status)
    ) {
        setFilesToUpload((list) => list.filter((file) => file.uid !== event.file.uid));
        return;
    }

    setFilesToUpload([...event.fileList]);
  }, []);

  return (
    <DraggerPanel
      multiple
      showUploadList={{ showPreviewIcon: true, showRemoveIcon: false }}
      fileList={filesToUpload}
      customRequest={handleFileUpload}
      onChange={handleFileUploadStatusChange}
    >
      <p className="ant-upload-drag-icon">
        <Icon type="upload" size={30} />
      </p>
      <p className="ant-upload-text">{t('knowledgeBase.uploadDescription')}</p>
    </DraggerPanel>
  );
};

const DraggerPanel = styled(Dragger)`
  .ant-upload-btn {
    padding: 8px 0 !important;
  }

  .ant-upload-drag-icon {
    margin-bottom: 10px !important;
  }

  .ant-upload-text {
    font-size: 14px !important;
  }

  .ant-upload-hint {
    font-size: 12px !important;
  }
`;

export default KnowledgeBaseFileUploader;
