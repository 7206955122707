import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Button } from 'antd';

interface ToolTipTitleProps {
  globalValue: string;
  spaceValue: string | null;
  onSpaceValueReset?: () => void;
  onGlobalValueReset?: () => void;
}

const getValueLabel = (value: any) => {
  if (value && value.ref) {
    switch (value.ref) {
      case 'media':
      case 'queue':
        return value.name || '';
      default:
        return value;
    }
  }

  return JSON.stringify(value);
}

const ToolTipTitle = ({
  globalValue,
  spaceValue,
  onSpaceValueReset,
  onGlobalValueReset,
}: ToolTipTitleProps) => {
  const { showGlobalValue, showSpaceValue } = useMemo(() => {
    return {
      showGlobalValue: globalValue !== null,
      showSpaceValue: spaceValue !== null,
    };
  }, [globalValue, spaceValue]);

  return (
    <TooltipTitleContainer>
      {showGlobalValue ? (
        <TooltipValueWrapper>
          {`Global value: ${globalValue ? getValueLabel(globalValue) : '<Not found>'}`}
          {onGlobalValueReset && globalValue ? (
            <ResetButton onClick={() => onGlobalValueReset()}>Use</ResetButton>
          ) : null}
        </TooltipValueWrapper>
      ) : null}
      {showSpaceValue ? (
        <TooltipValueWrapper>
          {`Location value: ${spaceValue ? getValueLabel(spaceValue) : '<Not found>'}`}
          {onSpaceValueReset && spaceValue ? (
            <ResetButton onClick={() => onSpaceValueReset()}>Use</ResetButton>
          ) : null}
        </TooltipValueWrapper>
      ) : null}
    </TooltipTitleContainer>
  )
}

const ResetButton = styled(Button)`
  margin-left: 8px;
` as any;

const TooltipTitleContainer = styled.div`
`;

const TooltipValueWrapper = styled.div`
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
`;

export default React.memo(ToolTipTitle);
