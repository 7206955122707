import { Modal, Dropdown, Button, Menu, Icon, Input, Form, Row, Col, Tag, Card, Radio } from 'antd';
import styled from '@emotion/styled';
import { ClickParam } from 'antd/lib/menu';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseGenerateAIImageProps, ModeEnum } from '../../use-rndai';
import urlToFile from '../../../../utils/url-to-file';

enum DownloadStatus {
  'DOWNLOADING' = 'downloading',
  'DONE' = 'done',
  'ERROR' = 'error',
  'NOT_DOWNLOADED' = 'stale',
}

const StyledIcon = styled(Icon)<{ color?: string }>`
  margin-right: 8px;
  ${({ color }) => color ? `color: ${color};` : ''}
`;

const ImagesRow = styled(Row)`
  padding-top: 24px;
  padding-bottom: 40px;
`;

const StyledTag = styled(Tag)`
  font-size: 14px;
  padding: 2px 8px;
`;

const GenerateButton = styled(Button)`
  width: 100%;
` as any;

interface MediaEditModalProps {
  organisationId: string;
  visible: boolean;
  onClose: () => void;
  onGenerateImage: (props: UseGenerateAIImageProps) => void;
  isLoading: boolean;
  isError: boolean;
  results: string[];
  uploadMedia: (params: {
    organization: string;
    file: File;
    folderId: string | null;
    onProgress?: (percent: number) => void;
  }) => Promise<void>;
}

type DownloadedMediaObject = Record<string, { status: string; progress: number }>;

const getMediaDownloadStatus = (downloadedMediaObj: DownloadedMediaObject, id: string) => {
  return downloadedMediaObj[id] && downloadedMediaObj[id]
    ? {
      status: downloadedMediaObj[id].status,
      progress: downloadedMediaObj[id].progress,
    } : {
      status: DownloadStatus.NOT_DOWNLOADED,
      progress: 0,
    };
}

const MediaEditModal = (props: MediaEditModalProps) => {
  const { visible, onClose, onGenerateImage, results, uploadMedia, organisationId, isLoading } = props;
  const { t } = useTranslation();

  const [activeTheme, setActiveTheme] = useState<string>('realistic');
  const [subject, setSubject] = useState<string>('');
  const [showResults, setShowResults] = useState<boolean>(false);
  const [downloadedMedia, setDownloadedMedia] = useState<DownloadedMediaObject>({});
  const [inputMode, setInputMode] = useState<ModeEnum>(ModeEnum.SUBJECT_BASED);
  const [inputPrompt, setInputPrompt] = useState<string>('');

  const themes = useMemo(() => [
    {
      key: 'realistic',
      label: t('realistic')
    },
    {
      key: 'classic',
      label: t('classic')
    },
    {
      key: 'summer',
      label: t('summer')
    },
    {
      key: 'autumn',
      label: t('autumn')
    },
    {
      key: 'rainy',
      label: t('rainy')
    },
    {
      key: 'winter',
      label: t('winter')
    },
  ], [t]);

  const handleMenuClick = useCallback((e: ClickParam) => {
    setActiveTheme(e.key);
  }, []);

  const handleSubjectChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(e.target.value);
  }, []);

  const handleFormBackClick = useCallback(() => {
    setShowResults(false);
  }, []);

  const handleGenerate = useCallback(() => {
    switch(inputMode) {
      case ModeEnum.SUBJECT_BASED:
        onGenerateImage({
          theme: activeTheme,
          queryString: subject,
          mode: inputMode,
          resultsCount: 3,
        });
        break;
      case ModeEnum.MANUAL_PROMPT:
        onGenerateImage({
          queryString: inputPrompt,
          mode: inputMode, 
          resultsCount: 3,
        });
      break;
    }

    setShowResults(true);
  }, [subject, activeTheme, inputMode, inputPrompt, onGenerateImage]);

  const handleClose = useCallback(() => {
    setShowResults(false);
    setSubject('');
    setActiveTheme('realistic');
    setDownloadedMedia({});
    setInputMode(ModeEnum.SUBJECT_BASED);
    setInputPrompt('');
    onClose();
  }, [onClose]);

  const handleSaveResult = useCallback(async (url: string) => {
    let downloadProgress = 0;
    let uploadProgress = 0;
    let totalProgress = 0;

    const setTotalProgress = (totalProgress: number) => {
      setDownloadedMedia(val => ({
        ...val,
        [url]: {
          status: +totalProgress !== 100 ? DownloadStatus.DOWNLOADING : DownloadStatus.DONE,
          progress: +totalProgress,
        }
      }));
    };

    const handleDownloadProgress = (percent: number) => {
      downloadProgress = (+percent);
      totalProgress = (downloadProgress + uploadProgress) / 2;
      setTotalProgress(totalProgress);
    }

    const handleUploadProgress = (percent: number) => {
      uploadProgress = (+percent);
      totalProgress = (downloadProgress + uploadProgress) / 2;
      setTotalProgress(totalProgress);
    }

    const fileName = inputMode === ModeEnum.MANUAL_PROMPT
      ? `ai-generated-image`
      : `${activeTheme}_${subject}`;
    const file = await urlToFile(url, `${fileName}-${new Date().getTime()}.jpeg`, handleDownloadProgress);

    try {
      await uploadMedia({
        file,
        organization: organisationId,
        folderId: null,
        onProgress: handleUploadProgress,
      });
    } catch (error) {
      console.error(error);
    }
  }, [subject, activeTheme, inputMode, organisationId, uploadMedia]);

  const themeMenu = useMemo(() => {
    return (
      <Menu onClick={handleMenuClick}>
        {themes.map((theme) => (
          <Menu.Item key={theme.key}>
            {theme.label}
          </Menu.Item>
        ))}
      </Menu>
    );
  }, [themes, handleMenuClick]);

  const activeThemeLabel = useMemo(() => {
    const curTheme: any = themes.find(theme => theme.key === activeTheme);

    if (!curTheme) {
      return '';
    }

    return curTheme.label;
  }, [activeTheme, themes]);
  
  const getAction = useCallback((id: string, currentMediaStatus: DownloadedMediaObject) => {
    const mediaStatus = getMediaDownloadStatus(currentMediaStatus, id);

    switch (mediaStatus.status) {
      case DownloadStatus.DOWNLOADING:
        return (
          <span>
            <StyledIcon type="loading" key="loading"/>
            {`Saving... ${mediaStatus.progress}%`}
          </span>
        );
      case DownloadStatus.DONE:
        return (
          <span>
            <StyledIcon type="check-circle" key="done" color="green" />
            Saved to library
          </span>
        );
      case DownloadStatus.ERROR:
        return (
          <span onClick={() => handleSaveResult(id)}>
            <StyledIcon type="cloud-download" key="done" color="red" />
            Failed to save. Try again
          </span>
        );
      default:
        return (
          <span onClick={() => handleSaveResult(id)}>
            <StyledIcon type="cloud-download" key="setting"/>
            Save to library
          </span>
        );
    }
  }, [handleSaveResult]);

  return (
    <Modal
      bodyStyle={{ paddingTop: '32px', paddingBottom: '40px' }}
      width={showResults ? '80%' : '412px'} 
      footer={null}
      title={t('filePicker.generateImageWithAI')}
      visible={visible}
      onCancel={handleClose}
      centered
    >
      {showResults ? (
        <>
          <Row type="flex" justify="center" align="middle">
            {inputMode === ModeEnum.SUBJECT_BASED && (
              <Col>
                <StyledTag color="purple">{subject}</StyledTag>
                <StyledTag color="blue">{activeTheme}</StyledTag>
              </Col>
            )}
            {inputMode === ModeEnum.MANUAL_PROMPT && (
              <Col>
                <StyledTag color="purple">{inputPrompt}</StyledTag>
              </Col>
            )}
          </Row>
          <ImagesRow gutter={[16, 16]} type="flex" justify="center" align="middle">
            {isLoading ? (
              Array(3)
                .fill("")
                .map(result => (
                  <Col span={6}>
                    <Card
                      bodyStyle={{ padding: 0, height: '282px' }}
                      hoverable
                      title={null}
                      loading
                    />
                  </Col>
                ))
            ) : (
              results.map(result => (
                <Col span={6}>
                  <Card
                    bodyStyle={{ padding: 0 }}
                    hoverable
                    cover={<img alt="result" src={result} />}
                    actions={[getAction(result, downloadedMedia)]}
                    title={null}
                  />
                </Col>
              ))
            )}
          </ImagesRow>
          <Row type="flex" justify="center" align="middle">
            <Button onClick={handleFormBackClick}>
              <Icon type="arrow-left" />
              Back
            </Button>
          </Row>
        </>
      ) : (
        <Form>
          <Row>
            <Col>
              <Form.Item label="">
                <Radio.Group onChange={(ev) => setInputMode(ev.target.value)} value={inputMode} data-testid="mmedia-generate-with-ai-type">
                  <Radio value={ModeEnum.SUBJECT_BASED}>Subject-based</Radio>
                  <Radio value={ModeEnum.MANUAL_PROMPT}>Manual AI prompt</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          {inputMode === ModeEnum.SUBJECT_BASED && (
            <Row>
              <Col>
                <Form.Item label="Theme">
                  <Dropdown overlay={themeMenu}>
                    <Button data-testid="media-generate-with-ai-dropdown">
                      {activeThemeLabel} <Icon type="down" />
                    </Button>
                  </Dropdown>
                </Form.Item>
              </Col>
            </Row>
          )}
          {inputMode === ModeEnum.SUBJECT_BASED && (
            <Row>
              <Col>
                <Form.Item label="Subject">
                  <Input placeholder="Basic usage" onChange={handleSubjectChange} data-testid="media-generate-with-ai-subject"/>
                </Form.Item>
              </Col>
            </Row>
          )}
          {inputMode === ModeEnum.MANUAL_PROMPT && (
            <Row>
              <Col>
                <Form.Item label="Prompt">
                  <Input placeholder="Enter a prompt" onChange={(ev) => setInputPrompt(ev.target.value)} />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row>
            <GenerateButton type="primary" onClick={handleGenerate} data-testid="media-generate-with-ai-generate-button">
              Generate
            </GenerateButton>
          </Row>
        </Form>
      )}
    </Modal>
  );
};

export default MediaEditModal;
