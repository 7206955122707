const channelsQueryKeys = {
  getQueryPrefix: () => {
    return 'content-channels';
  },
  getChannelsList: (tenantId: string, limit = 1000, page = 1) => {
    return [channelsQueryKeys.getQueryPrefix(), 'tenantId', tenantId, 'limit', limit, 'page', page];
  },
  getChannel: (channelId: string) => {
    return [channelsQueryKeys.getQueryPrefix(), 'channelId', channelId];
  },
  tenantChannels: (tenantId: string) => {
    return [channelsQueryKeys.getQueryPrefix(), 'tenantId', tenantId];
  },
};

export default channelsQueryKeys;
