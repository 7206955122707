import React, { useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from '@emotion/styled';
import { Modal, message } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import DevicesList from '../devices-universal-list/devices-universal-list.component';
import OrganisationApp from '../../../../store/types/organisation-app';
import UniversalDevice from '../../../../store/types/universal-device';
import Environment from '../../../../store/types/environment';
import { ApiError } from '../../../../services/api/api-error';
import { UniversalDeviceConnectParams } from '../../../../store/models/app-devices-universal/app-devices-universal.model';
import UniversalDeviceType from '../../../../store/types/universal-device-type.enum';
import DeviceTypeEnum from '../../../../store/types/device-type.enum';
import {
  PaginationProps,
  PaginationSearchParam,
} from '../../../../store/types/pagination';
import LegacyWindowsDevice from '../../../../store/types/legacy-windows-device';
import SearchBar, {
  SearchBarWrap,
} from '../../search-bar/search-bar.component';
import usePagination from '../../pagination/hook/use-pagination';

interface ConnectDeviceUniversal
  extends RouteComponentProps<{ appId?: string; organisationId: string }>,
    PaginationProps {
  devices: UniversalDevice[];
  loaded: boolean;
  fetchDevicesToConnect: (params: {
    organizationId: string;
    searchParam?: PaginationSearchParam;
    silent?: boolean;
    page: number;
    pageSize: number;
  }) => Promise<void>;
  lastUpdated?: Date;
  app: OrganisationApp | null;
  onClose: () => void;
  error: ApiError | null;
  environments: Environment[];
  connectDevice: (params: UniversalDeviceConnectParams) => Promise<void>;
  fetchEnvironments: (params: { organizationId: string }) => void;
  // TODO: temporary for backward compatibility
  query?: string;
  onDeviceSelect?: (device: UniversalDevice) => void;
  enableAll?: boolean;
}

const devicesGridStyle = `
  grid-template-columns: repeat(auto-fit, 280px);
  grid-gap: 4px;
`;

export default function ConnectDeviceUniversal({
  onClose,
  app,
  devices,
  loaded,
  isPaginationLoading,
  lastUpdated,
  error,
  fetchDevicesToConnect,
  connectDevice,
  query,
  pagination,
  onDeviceSelect,
  enableAll = false,
  match: {
    params: { organisationId },
  },
}: ConnectDeviceUniversal) {
  const { t } = useTranslation();
  const { pageSize: pageSizeNum, defaultPage } = usePagination();

  const fetchDevices = useCallback(
    async ({
      searchParam,
      page,
      pageSize,
    }: {
      searchParam?: PaginationSearchParam;
      page: number;
      pageSize: number;
    }) => {
      if (fetchDevicesToConnect && app) {
        const deviceType =
          app && app.deviceType === DeviceTypeEnum.IOT
            ? UniversalDeviceType.IOTHUB.toString()
            : undefined;

        await fetchDevicesToConnect({
          organizationId: organisationId,
          searchParam: {
            ...searchParam,
            ...(deviceType ? { deviceType } : {}),
          },
          silent: true,
          page,
          pageSize,
        });
      }
    },
    [fetchDevicesToConnect, app, organisationId],
  );

  const handleConnectDevice = useCallback(
    async (device: UniversalDevice) => {
      if (app) {
        try {
          await connectDevice({
            appId: app.id,
            deviceId: device.id,
            deviceType: device.type,
          });
          message.success(t('successfullyConnectedDevice'));
          onClose();
        } catch (err) {
          message.error(t('failedToConnectDevice'));
        }
      }
    },
    [app, connectDevice, onClose, t],
  );

  const onDeviceListItemClick = useCallback(
    (device: UniversalDevice | LegacyWindowsDevice) => {
      if (onDeviceSelect) {
        return onDeviceSelect(device as UniversalDevice);
      }

      return Modal.confirm({
        centered: true,
        title: t('confirmAction'),
        content: (
          <>
            <Message>
              <Trans
                i18nKey="deviceCurrentlyConnectedToDifferentInstallation"
                values={{ deviceName: device.deviceName }}
                components={{ strong: <strong /> }}
              />
              {` ${t('onConnectMessage')}`}
            </Message>
            <Message>
              <Trans
                i18nKey="areYouSureYouWantToConnectDevice"
                values={{
                  deviceName: device.deviceName,
                  appName: (app && app.appName) || '',
                }}
                components={{ strong: <strong /> }}
              />
            </Message>
          </>
        ),
        width: '30%',
        okText: t('confirm'),
        onOk: () => {
          return handleConnectDevice(device as UniversalDevice);
        },
        onCancel: () => {},
      });
    },
    [app, handleConnectDevice, t, onDeviceSelect],
  );

  const disableDevices = useMemo<string[]>(() => {
    if (!app || enableAll) {
      return [];
    }

    return devices
      .filter((device) => device.appIds.includes(app.id))
      .map((device) => device.id);
  }, [app, devices, enableAll]);

  const handleSearch = useCallback(
    (value: string): void => {
      const val = value.trim();
      const searchParam = {
        deviceName: val,
        deviceSerial: val,
      };

      const pageLimit = (pagination && pagination.limit) || pageSizeNum;

      fetchDevices({
        searchParam: pagination ? { ...pagination.param, ...searchParam } : undefined,
        page: defaultPage,
        pageSize: pageLimit,
      });
    },
    [defaultPage, fetchDevices, pageSizeNum, pagination],
  );

  return (
    <>
      {loaded && (
        <SearchBarWrap padding="10px">
          <SearchBar
            searchInputProps={{
              placeholder: t('searchDevicePlaceholderLabel'),
              onSearch: handleSearch,
            }}
          />
        </SearchBarWrap>
      )}
      <ContentWrap>
        <DevicesList
          organizationId={organisationId}
          error={error}
          lastUpdated={lastUpdated}
          devices={devices}
          disableDevices={disableDevices}
          loaded={loaded}
          isPaginationLoading={isPaginationLoading}
          fetchDevices={fetchDevices}
          defaultShowAll
          isPolling={false}
          devicesGridStyle={devicesGridStyle}
          customQuery={query}
          onDeviceListItemClick={onDeviceListItemClick}
          pagination={pagination}
          layout="grid"
        />
      </ContentWrap>
    </>
  );
}

const Message = styled.div`
  padding: 12px 0px;
`;

const ContentWrap = styled.div`
  padding: 20px;
  position: relative;
  flex: 1;
`;
