import { GridProduct, PriceListTypeEnum, Variant } from '@ombori/grid-products/dist';

export const getProductVariantPrice = ({
  productGroup,
  productId,
  type,
}: {
  productGroup: GridProduct;
  productId: string;
  type: PriceListTypeEnum;
}) => {
  const variantPrice = productGroup.productPriceList.find(
    (price) => price.priceListType === type && price.productId === productId,
  );
  return variantPrice;
};

export const getSizePriceStyleText = (product: Variant) => {
  const { size, color, style } = product;
  const info = [size, color, style].filter((value) => !!value);
  return info.join(' / ');
};
