import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import arrayMutators from 'final-form-arrays';
import { Field, Form } from 'react-final-form';
import { useActions, useStore } from 'easy-peasy';
import {
  getProductTypeTitle,
  IsoLanguageIds,
  ProductType,
  ProductTypeTitle,
} from '@ombori/grid-products/dist';
import { Button, Popconfirm, Col, Row, Checkbox, message } from 'antd';
import styled from '@emotion/styled';
import { uniq } from 'lodash';
import {
  defaultLng,
  SubHeader,
  StyledPanelCard,
  HeaderActions,
  BackButton,
  DeleteButton,
  CardLabel,
} from '../grid-products.components';
import { RootModel, RootState } from '../../../../../store/models/root.model';
import routes from '../routes';
import LanguagePicker from '../language-picker';
import Environment from '../../../../../store/types/environment';
import { DEFAULT_PRODUCTS_ENVIRONMENT } from '../utils';
import {
  ArrayFields,
  Input,
  LocalizedInput,
  SelectTreeSingleValuePicker,
  getFieldNamesWithError,
  scrollIntoView,
  transformToTreeData,
  validateSimpleId,
  validateCharacterLength,
} from '../../../../common/react-final-form';
import { appendQueryParams } from '../../../../../utils/url';
import { ApiError } from '../../../../../services/api/api-error';
import Message from '../../../../common/message';
import { ChangeEvent } from '../../../../../types/types';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { v4 as uuidV4 } from 'uuid';
import { DataResidencyEnum } from '../../../../../store/types/organisation';
import { useProductTypes, useProductTypesInvalidateQuery } from '../../../../common/use-product-type';
import useGoBack from '../../../../common/use-go-back';
import useQueryParams from '../../../../common/use-query-params';
import Overlay from '../../../../common/overlay/overlay-v2.component';
import { ProductEnvironmentSelect } from '../common';

interface FormData extends ProductType {
  isSubcategory?: boolean;
}

export interface GridProductTypesDetailsProps
  extends RouteComponentProps<{
    organisationId: string;
    productTypeId: string;
  }> {
  dataResidency: DataResidencyEnum;
}

const GridProductTypesDetails = (props: GridProductTypesDetailsProps) => {
  const { match, history, dataResidency } = props;
  const {
    params: { organisationId: tenantId, productTypeId },
    url,
  } = match;

  const { t } = useTranslation();

  const isNewPageRoute = routes.newProductTypePath(tenantId).includes(url);
  const typeId = !isNewPageRoute ? productTypeId : '';

  const [queryParams, setQueryParams] = useQueryParams({
    env: DEFAULT_PRODUCTS_ENVIRONMENT,
  });
  const { env } = queryParams;

  const handleProductsEnvironmentChange = useCallback(
    (env: string) => setQueryParams({ env }),
    [setQueryParams],
  );

  const [initialLanguages, setInitialLanguages] = useState<IsoLanguageIds[]>([
    defaultLng,
  ]);

  const fetchEnvironmentsRef = React.useRef({ fetchCount: 0 });

  const { updateOne, deleteOne, addOne, fetchEnvironments } = useActions<RootModel>(
    (actions) => {
      const results = {
        updateOne: actions.gridProductTypes.updateOne,
        deleteOne: actions.gridProductTypes.deleteOne,
        addOne: actions.gridProductTypes.addOne,
        fetchEnvironments: actions.environments.fetch,
      };
      return results;
    },
  );

  const { isEnvLoading, envs } = useStore<
    RootState,
    {
      isEnvLoading: boolean;
      envs: Environment[];
    }
  >((state) => {
    const result = {
      isEnvLoading: state.environments.loading[tenantId],
      envs: state.environments.values(tenantId),
    };

    return result;
  });

  const {
    data: productTypeData,
    isLoading: isProductTypesLoading,
    error: productTypesError,
  } = useProductTypes({
    tenantId,
    dataResidency,
    environment: env,
  });

  const invalidateProductTypesQuery = useProductTypesInvalidateQuery();

  const { productTypeTreeData, currProductType } = useMemo(() => {
    if (!productTypeData) {
      return {
        productTypeTreeData: [],
        currProductType: null,
      };
    }

    const productTypes = productTypeData.data;

    const currProductType =
      productTypes.find((productType) => productType.productTypeId === typeId) || null;

    const filteredProductTypes = !currProductType
      ? productTypes
      : productTypes.filter(
          (productType) => productType.productTypeId !== currProductType.productTypeId,
        );

    const mappedProductTypes = filteredProductTypes.map((productType) => {
      const { isRoot, parentId, productTypeId: id, title: localizedTitle } = productType;
      const title = localizedTitle[0].label;

      return {
        isRoot,
        parentId,
        id,
        title,
      };
    });

    return {
      productTypeTreeData: transformToTreeData(mappedProductTypes),
      currProductType,
    };
  }, [productTypeData, typeId]);

  const goBack = useGoBack();

  const isNew = isNewPageRoute || !currProductType;

  const initialValues = useMemo((): FormData => {
    if (currProductType) {
      return currProductType;
    }

    return {
      isRoot: true,
      title: [{ isoLanguageId: defaultLng, label: '' }],
      parentId: '',
      productTypeId: uuidV4(),
    };
  }, [currProductType]);

  const getInitialTitle = useCallback(() => {
    if (!currProductType || currProductType.title.length === 0) {
      return t('gridProducts.newCategory').toString();
    }

    const initialTitle = getProductTypeTitle(currProductType, defaultLng);

    // Fallback get first language picked up
    if (!initialTitle) {
      return currProductType.title[0].label;
    }

    return initialTitle.label;
  }, [currProductType, t]);

  const handleFormSubmit = useCallback(
    async (values: unknown) => {
      try {
        const formData = values as FormData;

        if (formData.isRoot) {
          formData.parentId = '';
        }

        if (isNew) {
          await addOne({ tenantId, type: formData, dataResidency, env });
          await invalidateProductTypesQuery({ tenantId, dataResidency, environment: env });

          const path = appendQueryParams(routes.productsTypesPath(tenantId), {
            env,
          });

          history.push(path);
          message.success(<Message content={t('gridProductTypes.successSavingData')} data-testid="grid-products-category-save-success-message" />);

          return;
        }

        await updateOne({ tenantId, type: formData, dataResidency, env });
        await invalidateProductTypesQuery({ tenantId, dataResidency, environment: env });

        message.success(<Message content={t('gridProductTypes.successSavingData')} data-testid="grid-products-category-save-success-message" />);
      } catch (error) {
        const err = (error as unknown) as ApiError;

        message.error(
          <Message content={t('gridProductTypes.errorSavingData')} error={err} data-testid="grid-products-category-save-error-message" />,
        );
      }
    },
    [invalidateProductTypesQuery, isNew, updateOne, tenantId, dataResidency, env, addOne, history, t],
  );

  const handleDelete = useCallback(
    async (productTypeId: string) => {
      try {
        const errorMessage = await deleteOne({
          tenantId,
          productTypeId,
          dataResidency,
          env,
        });

        if (!errorMessage) {
          history.goBack();

          message.success(
            <Message content={t('gridProductTypes.successDeletingData')} data-testid="grid-products-category-delete-success-message" />,
          );

          return;
        }

        message.error(
          <Message
            content={t('gridProductTypes.errorDeletingData')}
            error={new ApiError(errorMessage)}
          />,
        );
      } catch (error) {
        const err = (error as unknown) as ApiError;

        message.error(
          <Message content={t('gridProductTypes.errorDeletingData')} error={err} data-testid="grid-products-category-delete-error-message"/>,
        );
      }
    },
    [dataResidency, deleteOne, env, history, tenantId, t],
  );

  const handleGoBackClick = useCallback(() => {
    const fallbackPath = routes.productsTypesPath(tenantId);
    goBack(fallbackPath);
  }, [goBack, tenantId]);

  useEffect(() => {
    if (currProductType) {
      const productTypeLanguages = uniq(
        currProductType.title.map((item) => item.isoLanguageId),
      );
      setInitialLanguages(productTypeLanguages);
    }
  }, [currProductType]);

  useEffect(() => {
    if (
      envs.length === 0 &&
      fetchEnvironmentsRef.current.fetchCount === 0 &&
      !isEnvLoading
    ) {
      fetchEnvironmentsRef.current.fetchCount += 1;
      fetchEnvironments({ organizationId: tenantId });
    }
  }, [envs, fetchEnvironments, tenantId, isEnvLoading]);

  return (
    <>
      <Form
        onSubmit={handleFormSubmit}
        mutators={{
          ...arrayMutators,
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
          resetFieldError: ([name], state, { changeValue }) => {
            changeValue(state, name, () => undefined);
          },
        }}
        initialValues={initialValues}
        keepDirtyOnReinitialize
        render={({
          handleSubmit,
          submitting: isSubmitting,
          invalid: isFormInvalid,
          errors,
          values: valuesRaw,
          form: {
            mutators: { push, remove },
          },
        }) => {
          const values = (valuesRaw as unknown) as FormData;

          const handleAddLanguage = (language: IsoLanguageIds) => {
            push('title', {
              isoLanguageId: language,
              label: '',
            });
          };

          const handleRemoveLanguage = (_language: IsoLanguageIds, index: number) => {
            remove('title', index);
          };

          return (
            <form
              onSubmit={(event) => {
                handleSubmit(event);

                const errorFieldNames = getFieldNamesWithError(errors);

                if (!isFormInvalid || !errorFieldNames.length) {
                  return;
                }

                scrollIntoView(errorFieldNames[0]);
              }}
            >
              <SubHeader
                pageName={t('gridProducts.titleCategory')}
                subtitle={getInitialTitle()}
              >
                <HeaderActions>
                  <BackButton type="link" icon="arrow-left" onClick={handleGoBackClick} data-testid="grid-products-category-back-button"/>
                  <ActionButtons>
                    {!isNew && (
                      <Popconfirm
                        title={t('areYouSureYouWantToDelete')}
                        onConfirm={() => handleDelete(values.productTypeId)}
                        okText={t('yes')}
                        cancelText={t('no')}
                        data-testid="grid-products-category-confirm-delete-message"
                      >
                        <DeleteButton size="large" type="danger" disabled={isSubmitting} data-testid="grid-products-category-delete-button">
                          {t('gridProductTypes.deleteCategory')}
                        </DeleteButton>
                      </Popconfirm>
                    )}
                    <Button
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      size="large"
                      htmlType="submit"
                      type="primary"
                      data-testid="grid-products-category-save-button"
                    >
                      {t('gridProductTypes.saveCategory')}
                    </Button>
                  </ActionButtons>
                </HeaderActions>
              </SubHeader>

              <Overlay
                spinnerOverlay={{ isLoading: isProductTypesLoading }}
                errorOverlay={{ error: productTypesError }}
              >
                <Content gutter={40}>
                  <Col md={{ order: 2, span: 24 }} xl={16}>
                    <StyledPanelCard
                      title={t('gridProducts.general')}
                      bodypadding={'24px'}
                    >
                        <Field name="isRoot">
                          {({ input }) => {
                            const handleCheckboxChange = (event: CheckboxChangeEvent) => {
                              const isChecked = !event.target.checked;

                              input.onChange(({
                                target: { value: isChecked },
                              } as unknown) as ChangeEvent<boolean>);
                            };

                            return (
                              <Checkbox
                                checked={!input.value}
                                onChange={handleCheckboxChange}
                                data-testid="grid-products-category-subcategory-checkbox"
                              >
                                {t('gridProductTypes.thisIsSubcategory')}
                              </Checkbox>
                            );
                          }}
                        </Field>
                        {!values.isRoot && <Gap />}
                        <SelectTreeSingleValuePickerStyled
                          name="parentId"
                          isRequired={!values.isRoot}
                          label={t('gridProductTypes.parentCategory')}
                          treeData={productTypeTreeData}
                          placeholder={t('gridProductTypes.chooseParentCategory')}
                          disabled={values.isRoot}
                          searchPlaceholder={t(
                            'gridProductTypes.searchCategoryPlaceholder',
                          )}
                          isVisible={!values.isRoot}
                        />
                        <Gap />
                        <Input
                          name="productTypeId"
                          label={t('gridProductTypes.identifier')}
                          isRequired
                          validators={[validateCharacterLength(3, 50), validateSimpleId]}
                          data-testid="grid-products-category-identifier-input"
                        />
                        <Gap />
                        <ArrayFields<ProductTypeTitle>
                          propertyName="title"
                          label={t('title')}
                          isRequired
                          canAdd={false}
                          canDelete={false}
                          itemRowContent={(parentPropertyName) => {
                            return (
                              <LocalizedInput
                                name={parentPropertyName}
                                locale={{ isReadOnly: true, label: null }}
                                isRequired
                                input={{ label: null, validators: [validateCharacterLength(3, 200)] }}
                                data-testid="grid-products-category-title-input"
                              />
                            );
                          }}
                        />
                    </StyledPanelCard>
                  </Col>
                  <Col md={{ order: 1, span: 24 }} xl={8}>
                    <StyledPanelCard bodypadding={'16px 24px'}>
                      <Row>
                        <CardLabel>{t('environment')}</CardLabel>
                        <ProductEnvironmentSelect
                          isDisabled={!!currProductType}
                          options={envs}
                          value={env}
                          onChange={handleProductsEnvironmentChange}
                          dataTestId="grid-products-category-environment-select"
                        />
                      </Row>
                    </StyledPanelCard>
     
                    <LanguagePicker
                      defaultLanguages={initialLanguages}
                      onAdd={handleAddLanguage}
                      onRemove={handleRemoveLanguage}
                      data-testid="grid-products-category-language-picker"
                    />
                  </Col>
                </Content>
              </Overlay>
            </form>
          );
        }}
      />
    </>
  );
};

const Gap = styled.div`
  height: 16px;
`;

const Content = styled(Row)`
  margin-right: 8px !important;
  margin-left: 8px !important;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const SelectTreeSingleValuePickerStyled = styled(SelectTreeSingleValuePicker)<{
  isVisible: boolean;
}>`
  ${({ isVisible }) => (isVisible ? '' : 'display: none;')}
`;

export default GridProductTypesDetails;
