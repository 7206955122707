import React, { useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Button, message } from 'antd';
import useTagById from '../../../../../store/hooks/content-tags/use-tag-by-id.';
import useUpdateTag from '../../../../../store/hooks/content-tags/use-update-tag';
import Overlay from '../../../../common/overlay/overlay.component';
import { Spinner } from '../../../../common/spinner';
import TagFormFields, { TagFormApiValidationErrors, TagFormValues } from './tag-form-fields.component';
import { ButtonType } from '../../../../../types';
import useGoBack from '../../../../common/use-go-back';
import { extractApiValidationErrors } from '../../../../common/react-final-form';
import { ApiError } from '../../../../../services/api/api-error';
import Message from '../../../../common/message';

interface TagEditFormProps extends RouteComponentProps<{ organisationId: string; tagId: string }> {}

const TagEditForm = (props: TagEditFormProps) => {
  const {
    match: {
      params: { organisationId: tenantId, tagId },
    },
  } = props;

  const { t } = useTranslation();

  const { data: tag, isLoading, isSuccess, isError } = useTagById(tenantId, tagId);

  const initialValues = useMemo((): TagFormValues => {
    if (!tag) {
      return {
        name: '',
        description: '',
      };
    }

    return {
      name: tag.name,
      description: tag.description,
      ...(tag.values ? { type: tag.type, values: tag.values } : {}),
    };
  }, [tag]);

  const { mutateAsync: updateTag } = useUpdateTag(tenantId, tagId);

  const onFormSubmit = useCallback(
    async (values: TagFormValues): Promise<TagFormApiValidationErrors> => {
      try {
        await updateTag({
          tenantId,
          ...values,
        });

        message.success(<Message content={t('contents.tagEditSuccess')} />);

        return {};
      } catch (error) {
        message.error(<Message content={t('contents.tagEditFailed')} />);

        const errors = extractApiValidationErrors(error as ApiError);
        return errors;
      }
    },
    [t, tenantId, updateTag],
  );

  const goBack = useGoBack();
  const handleBack = useCallback(() => {
    goBack();
  }, [goBack]);

  return (
    <>
      {isLoading && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}

      {isError && <NotFound>{t('contents.tagFetchFailed')}</NotFound>}

      {isSuccess && (
        <TagFormContainer>
          <BackToListButton type="default" icon="arrow-left" onClick={handleBack}>
            {t('back')}
          </BackToListButton>

          <TagFormFields
            title={t('contents.updateTag')}
            initialValues={initialValues}
            onFormSubmit={onFormSubmit}
          />
        </TagFormContainer>
      )}
    </>
  );
};

export default TagEditForm;

const NotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const BackToListButton = styled(Button)`
  height: 36px;
  font-size: 14px;
  color: #676973;
  border: none;
  margin-right: 5px;
  background-color: transparent;

  :hover,
  :active,
  :focus {
    background-color: #f2f4f8;
  }
` as ButtonType;

const TagFormContainer = styled.div`
  position: relative;
  flex: 1;
  padding: 30px 80px 40px;

  @media screen and (max-width: 991px) {
    padding: 0;
  }
`;
