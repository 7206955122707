import React, { useEffect, useMemo } from 'react';
import { Col } from 'antd';
import { FormApi } from 'final-form';
import { useTranslation } from 'react-i18next';
import { Select, SelectProps } from '../../../../../common/react-final-form';

const DURATION_INTERVALS = [5, 10, 15, 20, 25, 30];

const DeviceStatusConditionFields = (props: {
  parentFieldName: string;
  updateForm: FormApi['change'];
}) => {
  const { parentFieldName, updateForm } = props;

  const { t } = useTranslation();

  const durationOptions = useMemo<SelectProps<number>['options']>(() => {
    return DURATION_INTERVALS.map((interval) => ({
      value: interval,
      label: `${interval}`,
    }));
  }, []);

  // unset field from parent when the component unmounts to avoid having un-necessary fields on form submission
  useEffect(() => {
    return () => {
      updateForm(`${parentFieldName}.duration`, undefined);
    };
  }, [parentFieldName, updateForm]);

  return (
    <>
      <Col span={24}>
        <Select
          name={`${parentFieldName}.duration`}
          label={t('alertRules.duration')}
          isRequired={true}
          options={durationOptions}
          isSearchEnabled={false}
        />
      </Col>
    </>
  );
};

export default DeviceStatusConditionFields;
