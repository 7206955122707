import React from 'react';
import { useActions } from 'easy-peasy';
import { RootModel } from '../../../../store/models/root.model';
import MediaGenerateWithAIModalComponent from './media-free-collection.component';

type MediaEditModalContainerProps = Omit<
  React.ComponentProps<typeof MediaGenerateWithAIModalComponent>,
  'uploadMedia'
>

const MediaEditModalContainer = (props: MediaEditModalContainerProps) => {

  const { uploadMedia } = useActions<
    RootModel
  >((actions) => ({
    uploadMedia: actions.organisationMedia.upload,
  }));

  return (
    <MediaGenerateWithAIModalComponent
      {...props}
      uploadMedia={uploadMedia}
    />
  );
};

export default MediaEditModalContainer;
