import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Icon as AntdIcon } from 'antd';
import { Music, Type, Video } from 'react-feather';
import Media, { MimeType } from '../../../../store/types/media';
import { isMediaTemplate } from '../../../../utils/media-templates';

interface FilePreviewIconProps {
  className?: string;
  file?: Media;
  mimeType?: MimeType | string;
  size?: number;
}

const FilePreviewIcon = (props: FilePreviewIconProps) => {
  const { className, file, mimeType, size } = props;

  const previewThumbnail = useMemo<JSX.Element>(() => {
    const { type = '' } = file || {};
    const mime = type || mimeType || '';

    if (
      file &&
      isMediaTemplate(file) &&
      file.metadata != null &&
      file.metadata.iconUrl != null
    ) {
      return <Image src={file.metadata.iconUrl} />;
    }

    if (mime === 'folder') {
      return <Icon type="folder" />;
    }

    if (mime.includes('image')) {
      return file && file.url ? <Image src={file.url} /> : <Icon type="picture" />;
    }

    if (mime.includes('sheet') || mime.includes('excel')) {
      return <Icon type="file-excel" />;
    }

    if (mime === 'text/plain' || mime === 'application/json') {
      return <Icon type="file-text" />;
    }

    if (mime === 'application/pdf') {
      return <Icon type="file-pdf" />;
    }

    if (mime.includes('video')) {
      return <Icon component={() => <Video />} />;
    }

    if (mime.includes('font')) {
      return <Icon component={() => <Type />} />;
    }

    if (mime.includes('audio')) {
      return <Icon component={() => <Music />} />;
    }

    return <Icon type="file" />;
  }, [file, mimeType]);

  return (
    <FileIconContainer className={className} size={size}>
      {previewThumbnail}
    </FileIconContainer>
  );
};

const FileIconContainer = styled.div<{ size?: number }>`
  display: flex;
  background: #ffffff;
  border-radius: 4px;
  background: transparent;

  height: ${({ size = 64 }) => `${size}px`};
  width: ${({ size = 64 }) => `${size}px`};
`;

const BaseIcon = styled(AntdIcon)<{ size?: number }>`
  svg {
    height: ${({ size = 16 }) => `${size}px`};
    width: ${({ size = 16 }) => `${size}px`};
  }

  &.anticon + span {
    margin-left: 5px;
  }
`;

const Icon = styled(BaseIcon)`
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  margin: 15px;

  svg {
    width: inherit;
    height: inherit;
  }
`;

const Image = styled.img`
  object-fit: cover;
`;

export default FilePreviewIcon;
