import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PhyhubDeviceOs,
  PhyhubDeviceStatusEnum,
} from '../../../../../store/types/phyhub-devices';
import PhyhubDevicesType from '../../phyhub-devices-type/phyhub-devices-type.component';

interface PhyhubDevicesListTypeCellProps {
  status: PhyhubDeviceStatusEnum;
  os: PhyhubDeviceOs | null;
}

const PhyhubDevicesListTypeCell: FC<PhyhubDevicesListTypeCellProps> = (props) => {
  const { status, os } = props;

  const { t } = useTranslation();

  if (!os) {
    return t('notAvailable');
  }

  return <PhyhubDevicesType arch={os.arch} osName={os.displayName} status={status} />;
};

export default PhyhubDevicesListTypeCell;
