import React, { useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Button, message } from 'antd';
import { ButtonType } from '../../../../../types';
import useGoBack from '../../../../common/use-go-back';
import AlertRuleForm, { AlertRuleFormValues } from './alert-rule-form.component';
import {
  useAlertRuleById,
  useUpdateAlertRule,
} from '../../../../../store/hooks/alert-rules';
import { SeverityEnum } from '../../../../../store/types/alerts';
import Message from '../../../../common/message';
import { extractApiValidationErrors } from '../../../../common/react-final-form';
import { ApiError } from '../../../../../services/api/api-error';
import Overlay from '../../../../common/overlay/overlay.component';
import { Spinner } from '../../../../common/spinner';

interface AlertRuleEditFormProps
  extends RouteComponentProps<{
    organisationId: string;
    alertRuleId: string;
  }> {}

const AlertRuleEditForm = (props: AlertRuleEditFormProps) => {
  const {
    match: {
      params: { organisationId, alertRuleId },
    },
  } = props;

  const { t } = useTranslation();

  const goBack = useGoBack();

  const handleGoBack = useCallback(() => {
    goBack();
  }, [goBack]);

  const alertRuleFetchingState = useAlertRuleById({
    alertRuleId,
  });

  const initialValues = useMemo<AlertRuleFormValues>(() => {
    if (alertRuleFetchingState.isSuccess) {
      const enabled = alertRuleFetchingState.data.enabled ? 1 : 0;

      return {
        displayName: alertRuleFetchingState.data.displayName,
        description: alertRuleFetchingState.data.description,
        enabled,
        severity: alertRuleFetchingState.data.severity,
        scope: alertRuleFetchingState.data.scope,
        conditions: alertRuleFetchingState.data.conditions,
        actions: alertRuleFetchingState.data.actions.map((action) => ({
          actionId: action,
        })),
      };
    }

    return {
      displayName: '',
      description: '',
      enabled: 0,
      severity: SeverityEnum.CRITICAL,
      scope: [],
      conditions: [],
      actions: [],
    };
  }, [alertRuleFetchingState.isSuccess, alertRuleFetchingState.data]);

  const {
    mutateAsync: updateAlertRule,
    isLoading,
    isSuccess,
    isError,
  } = useUpdateAlertRule(organisationId, alertRuleId);

  const onFormSubmit = useCallback(
    async (values: AlertRuleFormValues) => {
      try {
        const {
          displayName,
          description,
          enabled: isEnabled,
          severity,
          scope,
          conditions,
          actions: actionsList,
        } = values;

        const enabled = Boolean(isEnabled);

        const actions = actionsList.map((action) => action.actionId);

        await updateAlertRule({
          ...{ tenantId: organisationId },
          displayName,
          description,
          enabled,
          severity,
          scope,
          conditions,
          actions,
        });

        message.success(<Message content={t('alertRules.editSuccess')} />);

        return {};
      } catch (err) {
        message.error(<Message content={t('alertRules.editFailed')} />);

        const errors = extractApiValidationErrors(err as ApiError);

        return errors;
      }
    },
    [organisationId, updateAlertRule, t],
  );

  return (
    <>
      {alertRuleFetchingState.isLoading && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}

      {alertRuleFetchingState.isSuccess && (
        <AlertRuleFormContainer>
          <BackToListButton type="default" icon="arrow-left" onClick={handleGoBack}>
            {t('back')}
          </BackToListButton>

          <AlertRuleForm
            title={t('alertRules.editAlertRuleTitle')}
            tenantId={organisationId}
            initialValues={initialValues}
            onFormSubmit={onFormSubmit}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
          />
        </AlertRuleFormContainer>
      )}

      {alertRuleFetchingState.isError && (
        <NotFound>{t('alertRules.fetchFailed')}</NotFound>
      )}
    </>
  );
};

export default AlertRuleEditForm;

const NotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const BackToListButton = styled(Button)`
  height: 36px;
  font-size: 14px;
  color: #676973;
  border: none;
  margin-right: 5px;
  background-color: transparent;

  :hover,
  :active,
  :focus {
    background-color: #f2f4f8;
  }
` as ButtonType;

const AlertRuleFormContainer = styled.div`
  position: relative;
  flex: 1;
  padding: 30px 80px 40px;

  @media screen and (max-width: 991px) {
    padding: 0;
  }
`;
