import React from 'react';
import styled from '@emotion/styled';
import { toPairs } from 'lodash';
import { List, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { Day, Event } from '../utils';

const truncate = (str: string) =>
  str.length > 50 ? `${str.substring(0, 50).trim()}...` : str;

const buildTotal = (data: [string, number][]) =>
  data.reduce((acc, [, value]) => acc + value, 0);

const defaultSort = ([, a]: [string, number], [, b]: [string, number]) => b - a;

interface ListCardProps {
  data: Day[];
  precedingPeriodData?: Day[];
  hidePercentage?: boolean;
  header?: string;
  sort?: (a: [string, number], b: [string, number]) => number;
}

export default function ListCard({
  data,
  precedingPeriodData,
  hidePercentage,
  header,
  sort = defaultSort,
}: ListCardProps) {
  const { t } = useTranslation();

  const buildData = (data: Day[]) =>
    toPairs<number>(
      data.reduce((acc: Record<string, number>, [, events]) => {
        events.forEach(({ label, value }: Event) => {
          if (label) {
            acc[label] = (acc[label] || 0) + value;
          }
        });

        return acc;
      }, {}),
    ).sort(sort);

  const listData = buildData(data);

  const total = buildTotal(listData);

  return (
    <>
      <List
        header={header && <Header>{header}</Header>}
        size="small"
        dataSource={listData}
        style={{ maxHeight: 224, overflowY: 'auto' }}
        renderItem={([label, value]) => (
          <ListItem>
            <Tooltip placement="top" title={label}>
              <ItemName>{truncate(label)}</ItemName>
            </Tooltip>
            <ItemPercent>
              {hidePercentage ? '' : `${Math.floor((value / total) * 10000) / 100}%`}
            </ItemPercent>
            <ItemValue>{value}</ItemValue>
          </ListItem>
        )}
      />

      {precedingPeriodData && (
        <>
          <Divider />

          <ListCard
            data={precedingPeriodData}
            hidePercentage={hidePercentage}
            header={t('precedingPeriod')}
            sort={sort}
          />
        </>
      )}
    </>
  );
}

const ListItem = styled(List.Item)`
  display: flex;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
`;

const ItemName = styled.span`
  color: rgba(0, 0, 0, 0.65);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 320px;
`;

const ItemPercent = styled.span`
  display: inline-block;
  padding-left: 8px;
`;

const ItemValue = styled.span`
  flex: 1;
  text-align: right;
`;

const Divider = styled.div`
  height: 15px;
`;

const Header = styled.div`
  margin-bottom: -7px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
`;
