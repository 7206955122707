import { TFunction } from 'i18next';
import { StageOption } from '../../../common/use-stage';

type BuildSchemaResponse = Record<string, string | number | boolean | object>;

export const buildFieldSchema = (t: TFunction, stageOptions: StageOption[]): BuildSchemaResponse => {

  const mappedStages = stageOptions.map((option) => ({ const: option.id, title: option.label }));

  const fieldSchema = {
    type: 'object',
    properties: {
      stage: {
        type: 'string',
        minLength: 1,
        title: t('stage'),
        oneOf: mappedStages,
      },
      releaseNotes: {
        type: 'string',
        title: t('releaseNotes.title'),
      },
    },
    required: ['stage'],
  };

  return fieldSchema;
};

export const buildUiSchema = (): BuildSchemaResponse => {
  const metaSchema = {
    objectFieldTemplate: 'main-section',
    stage: {
      'ui:field': 'select',
    },
    releaseNotes: {
      'ui:widget': 'textarea',
      "ui:options": {
        "rows": 5
      }
    },
  };

  return metaSchema;
};
