import React, { useMemo, useState, useEffect } from 'react';
import { FieldProps } from 'react-jsonschema-form';
import { Select, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import useTagsList from '../../../../../store/hooks/content-tags/use-tags-list';
import { InputWrap, Option } from '../../../react-final-form';
import { UniversalDeviceTags } from '../../../../../store/types/universal-device';
import { ContentTagType } from '../../../../../store/common/content-tags/content-tag-type';
import { extractFieldError } from '../../error-messages';

interface ContentTagPickerProps extends FieldProps<UniversalDeviceTags> {}

const ContentTagPicker = (props: ContentTagPickerProps) => {
  const {
    onChange: updateFormField,
    errorSchema,
    schema: { title },
    formData,
    formContext,
  } = props;

  const { tagId = '' } = formData || {};

  const [tagValueOptions, setTagValueOptions] = useState<Option[]>([]);

  const { t } = useTranslation();

  const { organisationId: tenantId } = formContext;

  const { data: tagsData } = useTagsList(tenantId, 1000);

  const tagsOptions = useMemo<Option[]>(() => {
    if (!tagsData) {
      return [];
    }

    const tagsOptions = tagsData.docs.map<Option>((tag) => {
      return { label: tag.name, value: tag.id };
    });

    return tagsOptions;
  }, [tagsData]);

  const tagsMapping = useMemo<Record<string, Option[]>>(() => {
    if (!tagsData) {
      return {};
    }

    const tagsMapping: Record<string, Option[]> = {};
    tagsData.docs.forEach((tag) => {
      let tagOptions: Option[] = [];
      if (tag.values && tag.values.length) {
        tagOptions = tag.values.map((tag) => ({ label: tag, value: tag }));
      }

      tagsMapping[tag.id] = tagOptions;
    });

    return tagsMapping;
  }, [tagsData]);

  useEffect(() => {
    if (tagId && tagsMapping[tagId]) {
      const tagValueOptions = tagsMapping[tagId];
      setTagValueOptions(tagValueOptions);
    }
  }, [tagId, tagsMapping]);

  return (
    <>
      <label className="control-label">{title}</label>

      <Row gutter={[20, 20]}>
        <Col span={12}>
          <InputWrap label={`${t('contents.tags')}*`} error={extractFieldError(errorSchema, 'tagId')}>
            <Select
              value={tagId || undefined}
              placeholder={t('selectValue')}
              onChange={(value: string) => {
                if (tagsMapping[value]) {
                  setTagValueOptions(tagsMapping[value]);
                }
                updateFormField({
                  tagType: ContentTagType.CONTENT_TAG,
                  tagId: value,
                  values: undefined,
                });
              }}
            >
              {tagsOptions.map((tagOption) => {
                return (
                  <Select.Option key={tagOption.value} value={tagOption.value}>
                    {tagOption.label}
                  </Select.Option>
                );
              })}
            </Select>
          </InputWrap>
        </Col>

        <Col span={12}>
          <InputWrap label={`${t('contents.value')}*`} error={extractFieldError(errorSchema, 'values')}>
            <Select
              value={formData && formData.values && formData.values.length ? formData.values[0] : undefined}
              placeholder={t('selectValue')}
              onChange={(value: string) => {
                updateFormField({
                  ...formData,
                  values: [value],
                });
              }}
            >
              {tagValueOptions.map((tagValueOption) => {
                return (
                  <Select.Option key={tagValueOption.value} value={tagValueOption.value}>
                    {tagValueOption.label}
                  </Select.Option>
                );
              })}
            </Select>
          </InputWrap>
        </Col>
      </Row>
    </>
  );
};

export default ContentTagPicker;
