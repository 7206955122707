import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AnalyticsExportSchedule,
  ScheduleTypeEnum,
  WeekEnum,
} from '../../../../../store/types/analytics-data-export';
import { FormValidation } from 'react-jsonschema-form';
import CrudModalComponent from '../../../../common/crud-list/crud-modal/crud-modal.component';
import DatePicker from '../../../../common/schema-form/widgets/date-picker/date-picker.component';
import moment from 'moment';
import MultiSelect from '../../../../common/schema-form/widgets/multi-select/multi-select.component';
import { useUsers } from '../../../../common/use-analytics-data-export';

interface ManageScheduledModalProps {
  visible: boolean;
  onClose: (showModal: boolean) => void;
  onOk: ({ values }: { values: Partial<AnalyticsExportSchedule> }) => Promise<void>;
  organisationId: string;
  scheduledJob: AnalyticsExportSchedule;
}

const ManageScheduledModal = (props: ManageScheduledModalProps) => {
  const { visible, onClose, onOk, organisationId, scheduledJob } = props;
  const { t } = useTranslation();
  const { data, isSuccess } = useUsers(organisationId, visible);

  const handleModalClose = useCallback(() => {
    onClose(false);
  }, [onClose]);

  const handleModalSubmit = useCallback(
    async (values: Partial<AnalyticsExportSchedule>) => {
      await onOk({ values });
    },
    [onOk],
  );

  const usersList = useMemo(() => (isSuccess ? data : []), [isSuccess, data]);

  const defaultValues = useMemo(() => {
    const initialValues = {} as AnalyticsExportSchedule;
    if (Object.keys(scheduledJob).length) {
      const { type, day, dateFrom, dateTo } = scheduledJob;
      if (type === ScheduleTypeEnum.WEEKLY) {
        scheduledJob.weekDay = day ? day.toString() : '0';
        delete scheduledJob.day;
      }
      scheduledJob.dateFrom = dateFrom.split('T')[0];
      scheduledJob.dateTo = dateTo.split('T')[0];
      return { ...initialValues, ...scheduledJob };
    }
    return initialValues;
  }, [scheduledJob]);

  const customFormValidation = useCallback(
    (formData: Partial<AnalyticsExportSchedule>, error: FormValidation) => {
      const { dateFrom, dateTo, scheduledHour } = formData;
      if (dateFrom && dateTo) {
        const currentDate = new Date(moment().format('YYYY-MM-DD'));

        if (new Date(dateFrom) < currentDate) {
          error.dateFrom.addError(t('manageSchedule.validateStartDateLessCurrentDate'));
        }
        if (new Date(dateTo) < currentDate) {
          error.dateTo.addError(t('manageSchedule.validateEndDateLessCurrentDate'));
        } else if (new Date(dateFrom) > new Date(dateTo)) {
          error.dateTo.addError(t('manageSchedule.validateEndDateGreaterCurrentDate'));
        }
      }
      if (scheduledHour && (+scheduledHour > 23 || +scheduledHour < 0)) {
        error.scheduledHour.addError(t('manageSchedule.validateScheduledHour'));
      }
      return error;
    },
    [t],
  );

  const scheduledFormSchema = useMemo(
    () => ({
      type: 'object',
      properties: {
        type: {
          title: t('manageSchedule.selectOptionTitle'),
          type: 'string',
          enum: [
            ScheduleTypeEnum.DAILY,
            ScheduleTypeEnum.WEEKLY,
            ScheduleTypeEnum.MONTHLY,
          ],
          enumNames: [
            t('manageSchedule.daily'),
            t('manageSchedule.weekly'),
            t('manageSchedule.monthly'),
          ],
          default: ScheduleTypeEnum.DAILY,
        },
        dateFrom: {
          type: 'string',
          title: 'Start date',
          format: 'date',
          default: moment().format('YYYY-MM-DD'),
        },
        dateTo: {
          type: 'string',
          title: 'End date',
          format: 'date',
          default: moment().format('YYYY-MM-DD'),
        },
        scheduledHour: {
          title: t('manageSchedule.scheduledHour'),
          type: 'number',
          default: 1,
        },
        emailRecipient: {
          type: 'array',
          title: t('manageSchedule.emailRecipients'),
          items: {
            type: 'string',
            enum: usersList ? usersList.map((users) => users.id) : [],
            enumNames: usersList ? usersList.map((users) => users.email) : [],
          },
          uniqueItems: true,
        },
        enabled: {
          title: t('manageSchedule.enableSchedule'),
          type: 'boolean',
          default: false,
        },
      },
      dependencies: {
        type: {
          oneOf: [
            {
              properties: {
                type: { enum: [ScheduleTypeEnum.WEEKLY] },
                weekDay: {
                  title: 'Day',
                  type: 'string',
                  enum: [
                    WeekEnum.SUNDAY,
                    WeekEnum.MONDAY,
                    WeekEnum.TUESDAY,
                    WeekEnum.WEDNESDAY,
                    WeekEnum.THURSDAY,
                    WeekEnum.FRIDAY,
                    WeekEnum.SATURDAY,
                  ],
                  enumNames: [
                    t('sunday'),
                    t('monday'),
                    t('tuesday'),
                    t('wednesday'),
                    t('thursday'),
                    t('friday'),
                    t('saturday'),
                  ],
                  default: WeekEnum.SUNDAY,
                },
              },
            },
            {
              properties: {
                type: { enum: [ScheduleTypeEnum.MONTHLY] },
              },
            },
            {
              properties: {
                type: { enum: [ScheduleTypeEnum.DAILY] },
              },
            },
          ],
        },
      },
      required: ['dateFrom', 'dateTo', 'scheduledHour'],
    }),
    [t, usersList],
  );

  const uiSchema = {
    'ui:order': [
      'type',
      '*',
      'dateFrom',
      'dateTo',
      'scheduledHour',
      'emailRecipient',
      'enabled',
    ],
    dateFrom: {
      'ui:widget': DatePicker,
      'ui:options': {
        minDate: moment().format('YYYY-MM-DD'),
      },
    },
    dateTo: {
      'ui:widget': DatePicker,
      'ui:options': {
        minDate: moment().format('YYYY-MM-DD'),
      },
    },
    emailRecipient: {
      'ui:field': MultiSelect,
    },
  };

  return (
    <CrudModalComponent
      title={t('manageSchedule.title')}
      schema={scheduledFormSchema}
      metaSchema={uiSchema}
      initialValues={defaultValues}
      onSubmit={handleModalSubmit}
      visible={visible}
      onClose={handleModalClose}
      customFormValidation={customFormValidation}
    />
  );
};

export default ManageScheduledModal;
