import React from 'react';
import { RouteComponentProps, Switch } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import ErrorBoundary from '../../../common/error-boundary/error-boundary.component';
import ProtectedRouteContainer from '../../../common/protected-route/protected-route.container';
import { getPermissionPath, permissionKeys } from '../../../../utils/auth/permissions';
import SolutionDetailsContainer from './solution-details/solution-details.container';
import MarketplaceBrowse from './marketplace-browse/marketplace-browse.component';

type MarketplaceProps = RouteComponentProps<{ organisationId: string }>;

const Marketplace = ({ match }: MarketplaceProps) => {
  const {
    params: { organisationId },
  } = match;

  return (
    <ErrorBoundary>
      <Switch>
        <Route
          exact
          path={match.path}
          render={() => <Redirect to={`${match.url}/browse`} />}
        />
        <ProtectedRouteContainer
          permissionPath={getPermissionPath(
            organisationId,
            permissionKeys.marketplace.viewAll,
          )}
          path={`${match.path}/browse`}
          component={MarketplaceBrowse}
        />
        <ProtectedRouteContainer
          permissionPath={getPermissionPath(
            organisationId,
            permissionKeys.marketplace.viewSingle,
          )}
          path={`${match.path}/solution/:solutionId`}
          component={SolutionDetailsContainer}
        />
      </Switch>
    </ErrorBoundary>
  );
};

export default Marketplace;
