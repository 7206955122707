import { BillingCurrencyEnum } from './organisation';

export enum BillingReason {
  SubscriptionCycle = 'subscriptionCycle',
  EnterpriseAgreement = 'enterpriseAgreement',
}

export enum InvoiceStatus {
  Open = 'open',
  PaidInFull = 'paidInFull',
}

export interface InvoiceListItem {
  id: string;
  externalId: number | null;
  billingReason: BillingReason;
  currency: BillingCurrencyEnum;
  date: string;
  organizationId: string;
  total: number;
  description: string;
  status: InvoiceStatus;
}

export default interface InvoicesPaginatedResult {
  items: InvoiceListItem[];
  totalItems: number;
  totalPages: number;
  page: number;
  limit: number;
}
