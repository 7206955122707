import * as React from 'react';
import { useActions, useStore } from 'easy-peasy';
import WebSignalsSettings from './web-signals-settings.component';
import { RootModel, RootState } from '../../../../store/models/root.model';

type WebSignalsSettingsContainerProps = Omit<
  React.ComponentProps<typeof WebSignalsSettings>,
  'app' | 'organisation' | 'fetchSpaces'
>;

const WebSignalsSettingsContainer = (props: WebSignalsSettingsContainerProps) => {
  const {
    match: {
      params: { appId, organisationId },
    },
  } = props;

  const { app, organisation, spaces, spacesLoaded } = useStore<RootState>(
    (state) => ({
      app:
        (state.organisationApps.data &&
          state.organisationApps.data[organisationId] &&
          state.organisationApps.data[organisationId][appId]) ||
        null,
      organisation: state.organisations.data && state.organisations.data[organisationId],
      spaces: state.organisationSpaces.values(organisationId),
      spacesLoaded: state.organisationSpaces.loaded(organisationId),
    }),
    [appId],
  );

  const { fetchSpaces } = useActions<RootModel>((actions) => ({
    fetchSpaces: actions.organisationSpaces.fetch,
  }));

  return (
    <WebSignalsSettings
      {...props}
      app={app}
      organisation={organisation}
      spaces={spaces}
      loaded={spacesLoaded}
      fetchSpaces={fetchSpaces}
    />
  );
};

export default WebSignalsSettingsContainer;
