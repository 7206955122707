import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Field } from 'react-final-form';
import { Button } from 'antd';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { FieldArray } from 'react-final-form-arrays';
import { FormTextInput, Label, PanelCardMain } from './styled-block';
import { Organization } from '../../../../../store/types/queue';
import ApiService from '../../../../../services/api/api.service';
import { getApiUrl } from '../../../../../utils/env';
import Spinner from '../../../../common/spinner/spinner.component';
import TextInAppCustomizationLanguagePicker from './text-in-app-customization-lanugage-picker';

const fieldName = 'availableTagsForQueue';

type TagsFetchingState = 'requested' | 'failed' | 'finished' | 'idle';

interface AvailableTagsForQueueProps {
  organizationId: string;
  region: string;
  activeLanguage: string;
  change: (name: string, value: any) => void;
}

const AvailableTagsForQueue: React.FC<AvailableTagsForQueueProps> = ({
  organizationId,
  region,
  activeLanguage,
  change,
}) => {
  const { t } = useTranslation();

  const [tagsFetchingState, setTagsFetchingState] = useState<TagsFetchingState>('idle');

  const fetchTagsRequest = useCallback(() => setTagsFetchingState('requested'), []);
  const fetchTagsSuccess = useCallback(() => setTagsFetchingState('finished'), []);
  const fetchTagsFailure = useCallback(() => setTagsFetchingState('failed'), []);

  const apiService = useMemo(() => new ApiService(getApiUrl()), []);

  const fetchTags = useCallback(async () => {
    fetchTagsRequest();

    try {
      const organization: Organization = await apiService.get(
        `/api/queues/organization/${organizationId}?region=${region}`,
      );

      const organizationTags = organization.tags;

      change(fieldName, organizationTags);

      fetchTagsSuccess();
    } catch (error) {
      fetchTagsFailure();
    }
  }, [
    fetchTagsRequest,
    fetchTagsSuccess,
    fetchTagsFailure,
    apiService,
    organizationId,
    region,
    change,
  ]);

  useEffect(() => {
    fetchTags();
  }, [fetchTags]);

  return (
    <FieldArray
      key="availableTagsForQueue"
      name="availableTagsForQueue"
      isEqual={isEqual}
    >
      {({ fields }) => {
        const fieldsLength = fields.length || 0;
        const isAnyTagExist = fieldsLength > 0;

        return (
          <>
            {isAnyTagExist && (
              <>
                <Label>{t('availableTags')}</Label>

                <TextInAppCustomizationLanguagePicker />

                <PanelCardMain>
                  {fields.map((indexedTagName, index) => (
                    <TagContainer key={indexedTagName}>
                      <WrapHeader>
                        <Header>
                          {t('tag')} №{index + 1}{' '}
                        </Header>
                      </WrapHeader>

                      <WrapFields>
                        <Field name={`${indexedTagName}.iconUrl`}>
                          {({ input: filedInput, meta }) => {
                            return (
                              <FormTextInput
                                label={t('tagIconUrl')}
                                input={filedInput}
                                meta={meta}
                                disabled
                              />
                            );
                          }}
                        </Field>

                        <Field name={`${indexedTagName}.title[${activeLanguage}]`}>
                          {({ input: filedInput, meta }) => {
                            return (
                              <FormTextInput
                                label={t('tagTitle')}
                                input={filedInput}
                                meta={meta}
                                disabled
                              />
                            );
                          }}
                        </Field>
                      </WrapFields>
                    </TagContainer>
                  ))}

                  {(tagsFetchingState === 'idle' || tagsFetchingState === 'finished') && (
                    <Button onClick={fetchTags}>{t('updateQueueTags')}</Button>
                  )}

                  {tagsFetchingState === 'requested' && <Spinner />}

                  {tagsFetchingState === 'failed' && (
                    <Warning>{t('errorDuringFetchingTags')}</Warning>
                  )}
                </PanelCardMain>
              </>
            )}
          </>
        );
      }}
    </FieldArray>
  );
};

const TagContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const WrapHeader = styled.div`
  display: flex;
  align-items: center;
`;

const Header = styled.div`
  margin-right: 16px;
  font-weight: bold;
`;

const WrapFields = styled.div`
  margin-right: 16px;
`;

const Warning = styled.div`
  padding: 15px 0;
  color: rgb(255, 85, 0);
`;

export default AvailableTagsForQueue;
