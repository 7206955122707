import React, { FC } from 'react';
import styled from '@emotion/styled';
import * as antdColors from '@ant-design/colors';
import { UniversalDeviceModuleStatus } from '../../../../store/types/universal-device';

interface DeviceStatusIndicatorProps {
  status: UniversalDeviceModuleStatus;
}

const DeviceStatusIndicator: FC<DeviceStatusIndicatorProps> = (props) => {
  const { status } = props;

  switch (status) {
    case UniversalDeviceModuleStatus.RUNNING: {
      return <SuccessIndicator />;
    }

    case UniversalDeviceModuleStatus.FAILED: {
      return <ErrorIndicator />;
    }

    default: {
      return <WarningIndicator />;
    }
  }
};

const INDICATOR_SIZE = '6px';

const Indicator = styled.div`
  display: inline-flex;
  width: ${INDICATOR_SIZE};
  height: ${INDICATOR_SIZE};
  border-radius: 50%;
`;

const SuccessIndicator = styled(Indicator)`
  background-color: ${antdColors.green[6]};
`;

const WarningIndicator = styled(Indicator)`
  background-color: ${antdColors.orange.primary};
`;

const ErrorIndicator = styled(Indicator)`
  background-color: ${antdColors.red[4]};
`;

export default DeviceStatusIndicator;
