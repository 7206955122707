import { useQuery } from 'react-query';
import { useApiService } from '../api-service-provider';
import { routes } from '../../../store/models/schema/schema-model';

interface UseAppSchemaProps {
  release: string;
  provider: string;
}

const useAppSchema = ({ provider, release }: UseAppSchemaProps) => {
  const apiService = useApiService();

  return useQuery(
    ['appSchema', release, provider],
    () => apiService.get<any>(routes.schema(release, provider)),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      enabled: provider.length > 0 && release.length > 0,
    },
  );
};

export default useAppSchema;
