/* stylelint-disable property-no-vendor-prefix */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FieldProps } from 'react-jsonschema-form';
import { useTranslation } from 'react-i18next';
import { Icon, Input, Tooltip } from 'antd';
import styled from '@emotion/styled';
import transientOptions from '../../../../../utils/transient-emotion-styled-options';
import Spinner from '../../../spinner/spinner.component';
import useValidateDeviceName from '../../../use-validate-device-name';

export interface DeviceNameInputValidationRef {
  setDefaultValue: (val: string) => void;
  input: Input | null;
}

const DeviceNameInputValidation = (
  props: FieldProps,
  ref?: React.Ref<DeviceNameInputValidationRef>,
) => {
  const {
    id,
    value,
    onChange,
    rawErrors,
    formContext: { organisationId },
  } = props;

  const hasError = rawErrors && rawErrors.length;

  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState<string>();
  const [initValue, setInitValue] = useState<string>();
  const inputRef = useRef() as React.RefObject<Input>;

  const {
    validate,
    reset,
    data: validationResult,
    isIdle,
    isLoading,
    isSuccess,
    isFailure,
  } = useValidateDeviceName();

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const val = event.target.value;
      onChange(val);
    },
    [onChange],
  );

  const handleFocus = useCallback(() => {
    reset();
    onChange(inputValue);
  }, [inputValue, onChange, reset]);

  const handleBlur = useCallback(async (): Promise<void> => {
    if (inputValue && !hasError && inputValue !== initValue) {
      await validate(organisationId, inputValue);
    }

    onChange(inputValue);
  }, [hasError, initValue, inputValue, onChange, organisationId, validate]);

  const { icon: validationIcon, error: validationError } = useMemo<{
    icon: React.ReactNode;
    error: string;
  }>(() => {
    if (isLoading) {
      return { icon: <Spinner size={15} />, error: '' };
    }

    const iconProps = {
      type: 'exclamation-circle',
      color: '#a94442',
      message: t('somethingWentWrong'),
    };
    if (isSuccess) {
      const { valid: exists = false, code = 404 } = validationResult || {};

      if (exists) {
        iconProps.type = 'close-circle';
        iconProps.color = '#a94442';
        iconProps.message = t('deviceNameAlreadyTaken');
      }

      if (!exists && code === 404) {
        iconProps.type = 'check-circle';
        iconProps.color = 'green';
        iconProps.message = t('deviceNameAvailable');
      }

      if (!exists && code === 400) {
        iconProps.type = 'exclamation-circle';
        iconProps.color = '#a94442';
        iconProps.message = t('mustBeInSmallLettersAndKebabCase');
      }

      return {
        icon: (
          <Tooltip title={iconProps.message}>
            <IconStyled type={iconProps.type} color={iconProps.color} size={15} />
          </Tooltip>
        ),
        error: code === 404 ? '' : iconProps.message,
      };
    }

    if (isFailure) {
      return {
        icon: (
          <Tooltip title={iconProps.message}>
            <IconStyled type={iconProps.type} color={iconProps.color} size={15} />
          </Tooltip>
        ),
        error: iconProps.message,
      };
    }

    return {
      icon: undefined,
      error: '',
    };
  }, [isLoading, isSuccess, isFailure, t, validationResult]);

  useImperativeHandle(
    ref,
    () => ({
      setDefaultValue: (val?: string) => {
        setInitValue(val);
      },
      input: inputRef.current,
    }),
    [inputRef],
  );

  return (
    <InputStyled
      ref={inputRef}
      id={id}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      $hasError={hasError}
      suffix={validationIcon}
      data-error-message={validationError}
      data-validating={isLoading}
      autoFocus={isIdle}
    />
  );
};

const InputStyled = styled(Input, transientOptions)<{ $hasError?: boolean }>`
  color: #555555 !important;
  background-image: none !important;
  border-color: ${({ $hasError }) => ($hasError ? '#a94442' : '#ccc')} !important;
  border-radius: 4px !important;

  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%) !important;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%) !important;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s !important;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s !important;

  > input {
    border-color: ${({ $hasError }) => ($hasError ? '#a94442' : '#ccc')} !important;
  }

  :hover {
    border-color: ${({ $hasError }) => ($hasError ? '#a94442' : '#ccc')} !important;
  }

  :focus {
    border-color: ${({ $hasError }) => ($hasError ? '#a94442' : '#66afe9')} !important;
    outline: 0 !important;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%),
      ${({ $hasError }) =>
        $hasError ? '0 0 6px #a94442' : '0 0 8px rgb(102 175 233 / 60%) '} !important;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%),
      ${({ $hasError }) =>
        $hasError ? '0 0 6px #a94442' : '0 0 8px rgb(102 175 233 / 60%) '} !important;
  }
`;

const IconStyled = styled(Icon)<{ size?: number; color?: string }>`
  font-size: ${({ size = 12 }) => size}px;
  ${({ color }) => (color ? `color: ${color};` : '')}
  font-weight: bold;
`;

export default forwardRef(DeviceNameInputValidation);
