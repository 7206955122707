import React from 'react';
import styled from '@emotion/styled';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import {
  FormTextInput,
  InputCheckbox,
  Label,
  PanelCardMain,
  RowStyled,
} from '../styled-block';

interface ValidationRulesBuilderProps {
  fieldName: string;
}

const ValidationRulesBuilder: React.FC<ValidationRulesBuilderProps> = ({ fieldName }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Label>{t('fieldValidationRules')}</Label>

      <PanelCardMain>
        <Field name={`${fieldName}.validationRules.required`} type="checkbox">
          {({ input }) => (
            <RowStyled>
              <Label>
                <InputCheckbox {...input} type="checkbox" />
                {t('fieldRequired')}
              </Label>
            </RowStyled>
          )}
        </Field>

        <Field name={`${fieldName}.validationRules.regexp`}>
          {({ input, meta }) => {
            return (
              <FormTextInput
                label={t('fieldRegexp')}
                input={input}
                meta={meta}
                hint={t('regularExpressionHint')}
              />
            );
          }}
        </Field>
      </PanelCardMain>
    </Container>
  );
};

const Container = styled.div``;

export default ValidationRulesBuilder;
