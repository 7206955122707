import { useApiService } from '../../../app/common/api-service-provider';
import organizationUsersQueryKeys from './use-organization-users-query-keys';
import { useMutation, useQueryClient } from 'react-query';
import { ApiError } from '../../../services/api/api-error';
import * as Sentry from '@sentry/browser';
import TenantUser from '../../types/tenant-user';

export interface ScopeLevelPickerSpaceValues {
  type: string;
  id: string;
}

export interface UseOrganizationUsersCreateFormFieldsProps {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  password?: string;
  roleId?: string;
  allowedSpaceIds: string[] | ScopeLevelPickerSpaceValues[];
  hasAccessToAllSpaces: boolean;
}


const useOrganizationUsersCreate = () => {
  const queryClient = useQueryClient();

  const apiService = useApiService();
  return useMutation(
    async ({payload, tenantId}: {payload: UseOrganizationUsersCreateFormFieldsProps, tenantId: string}) => {
      return apiService.post<TenantUser>(`/api/organizations/${tenantId}/users`, payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([organizationUsersQueryKeys.queryPrefix()]);
      },
      onError: (error: ApiError) => {
        Sentry.captureException(error);
      },
    },
  );
};

export default useOrganizationUsersCreate;
