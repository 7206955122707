import { useMutation, useQueryClient } from 'react-query';
import { useApiService } from '../../../app/common/api-service-provider';
import { Channel } from '../../types/channel';
import channelsQueryKeys from './channels-query-keys';
import { ChannelBody } from './types';

const useCreateChannel = (tenantId: string) => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  const url = `/api/content-channel?tenantId=${tenantId}`;

  return useMutation(
    (channelBody: ChannelBody) => {
      return apiService.post<Channel>(url, channelBody);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(channelsQueryKeys.getChannelsList(tenantId));
      },
    },
  );
};

export default useCreateChannel;
