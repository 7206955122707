import UserRole, {
  UserRolePermissionsMatrix,
  UserRoleFormData,
} from '../../store/types/user-roles';

export const generateUserRolePermissionSchema: any = (
  permissionMatrix: UserRolePermissionsMatrix,
) => {
  return (permissionMatrix || []).reduce((permissionsObj: any, permissionGroup) => {
    const permissionDetails = permissionGroup.list.reduce((details: any, rule) => {
      return {
        ...details,
        [rule.id]: {
          type: 'boolean',
          title: rule.title,
          default: true,
        },
      };
    }, {});

    return {
      ...permissionsObj,
      [permissionGroup.id]: {
        title: permissionGroup.title,
        type: 'object',
        properties: {
          ...permissionDetails,
        },
      },
    };
  }, {});
};

export const getUserRoleFromFormData = (formData: any): Partial<UserRole> => {
  const permissions = Object.keys(formData.permissions).reduce(
    (permissionList: string[], permissionGroupKey) => {
      const permissionGroup = formData.permissions[permissionGroupKey];
      if (!permissionGroup) {
        return permissionList;
      }

      return [
        ...permissionList,
        ...Object.keys(permissionGroup)
          .map((id) => (permissionGroup[id] ? id : ''))
          .filter((id) => !!id),
      ];
    },
    [],
  );
  return {
    displayName: formData.displayName || '',
    permissions,
    manageAllRoles: !!formData.manageAllRoles,
    allowedUserRolesToManage: formData.allowedUserRolesToManage || [],
  };
};

export const getUserRolesDefaultFormValues = (
  userRoles: UserRole[],
  permissionMatrix: UserRolePermissionsMatrix,
): UserRoleFormData[] => {
  return userRoles.map(({ permissions, ...restProps }) => ({
    ...restProps,
    permissions: (permissionMatrix || []).reduce(
      (permissionsObj: any, permissionGroup) => {
        const permissionDetails = permissionGroup.list.reduce(
          (permissionItems: { [id: string]: boolean }, rule) => {
            return {
              ...permissionItems,
              [rule.id]: permissions.includes(rule.id),
            };
          },
          {},
        );

        return {
          ...permissionsObj,
          [permissionGroup.id]: permissionDetails,
        };
      },
      {},
    ),
  }));
};

export default {
  getUserRoleFromFormData,
  generateUserRolePermissionSchema,
};
