import React, { createContext, useCallback, useState } from 'react';
import { Form } from 'react-final-form';
import { Modal } from 'antd';
import { FormApi } from 'final-form';
import validateForm from '../../../utils/validation/validate-form';

interface ExtraHandlers {
  onKeyDown: (event: KeyboardEvent, form: FormApi) => void;
}

interface ModalFormProps<T> {
  title: string;
  children: React.ReactNode;
  visible: boolean;
  onClose: () => void;
  onSubmit: (values: T) => Promise<void>;
  initialValues?: any;
  schema?: any;
}

export const ModalFormContext = createContext<{ handlers: ExtraHandlers }>({
  handlers: {
    onKeyDown: () => {},
  },
});

function ModalForm<T = object>(props: ModalFormProps<T>) {
  const [isFormLoading, setIsFormLoading] = useState(false);
  const { title, children, schema, visible, onClose, onSubmit, initialValues } = props;

  const validate = useCallback(
    (values: any) => {
      if (schema) {
        return validateForm(values, schema);
      }
      return {};
    },
    [schema],
  );

  const handleConfirm = useCallback(async (form: FormApi) => {
    await form.submit();
    form.reset();
  }, []);

  const handleClose = useCallback(
    async (form: FormApi) => {
      form.reset();
      onClose();
    },
    [onClose],
  );

  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        setIsFormLoading(true)
        await onSubmit(values);
        setIsFormLoading(false)
      } catch (e) {
        setIsFormLoading(false)
        throw e;
      }

      onClose();
    },
    [onSubmit, onClose],
  );

  const handleInputKeyDown = useCallback(
    async (event: KeyboardEvent, form: FormApi) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        await handleConfirm(form);
      }
    },
    [handleConfirm],
  );

  return (
    <Form initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
      {({ invalid, form }) => (
        <ModalFormContext.Provider
          value={{
            handlers: {
              onKeyDown: (event) => handleInputKeyDown(event, form),
            },
          }}
        >
          <Modal
            title={title}
            visible={visible}
            onCancel={() => handleClose(form)}
            onOk={() => handleConfirm(form)}
            destroyOnClose
            okButtonProps={{ disabled: invalid }}
            confirmLoading={isFormLoading}
            data-testid="media-folder-form"
          >
            {children}
          </Modal>
        </ModalFormContext.Provider>
      )}
    </Form>
  );
}

export default ModalForm;
