import React, { useEffect, useMemo } from 'react';
import {
  Button,
  Typography,
  Dropdown,
  Icon,
  Menu,
  Alert,
  Popconfirm,
  Popover,
  Badge,
} from 'antd';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import SchemaForm from '../../../../../common/schema-form/schema-form.component';
import Module from '../../../../../../store/types/module';
import ModuleVersion, {
  GridAppBuildStage,
} from '../../../../../../store/types/module-version';
import Spinner from '../../../../../common/spinner/spinner.component';

export interface Props {
  module: Module | undefined;
  moduleId: string;
  versionId: string;
  settings: any;
  onChange: (moduleId: string, settings: any) => void;
  onChangeVersion: (moduleId: string, versionId: string) => void;
  onDelete: (moduleId: string) => void;
  versions: { [id: string]: ModuleVersion };
  status: { status: string; version: string };
  organisationId?: string;
  isSysAdmin?: boolean;
}

const ModuleSettings = ({
  module,
  moduleId,
  versionId,
  settings,
  onChange,
  onDelete,
  versions,
  onChangeVersion,
  status,
  organisationId,
  isSysAdmin,
}: Props) => {
  const { t } = useTranslation();

  const { menu, latest } = useMemo(() => {
    const { prodModuleVersions, qaModuleVersions } = Object.values(versions).reduce(
      (
        acc: { prodModuleVersions: ModuleVersion[]; qaModuleVersions: ModuleVersion[] },
        version,
      ) => {
        if (
          version.stage === GridAppBuildStage.QA ||
          (version.stage === GridAppBuildStage.OMBORI_INTERNAL && isSysAdmin)
        ) {
          acc.qaModuleVersions.push({
            ...version,
            version: `${version.version} - ${version.stage}`.toUpperCase(),
          });
        }

        if (
          version.stage === undefined ||
          version.stage === GridAppBuildStage.PRODUCTION
        ) {
          acc.prodModuleVersions.push(version);
        }

        return acc;
      },
      { prodModuleVersions: [], qaModuleVersions: [] },
    );

    const menuComponent = (
      <Menu onClick={({ key }) => onChangeVersion(moduleId, key)}>
        {prodModuleVersions.map((moduleVersion) => (
          <Menu.Item key={moduleVersion.id}>{moduleVersion.version}</Menu.Item>
        ))}
        {qaModuleVersions.length > 0 && (
          <Menu.ItemGroup title={t('qaVersions')}>
            {qaModuleVersions.map((moduleVersion) => (
              <Menu.Item key={moduleVersion.id}>{moduleVersion.version}</Menu.Item>
            ))}
          </Menu.ItemGroup>
        )}
      </Menu>
    );

    return {
      menu: menuComponent,
      latest: prodModuleVersions[0],
    };
  }, [versions, t, isSysAdmin, onChangeVersion, moduleId]);

  const [currentVersion, currentVersionLabel] = useMemo(() => {
    const curVersion = versions[versionId];
    const labelHasSuffix =
      curVersion &&
      curVersion.stage &&
      [GridAppBuildStage.OMBORI_INTERNAL, GridAppBuildStage.QA].includes(
        curVersion.stage,
      );
    let curVersionLabel = t('versionNotFound');

    if (curVersion) {
      curVersionLabel = curVersion.version;
      if (labelHasSuffix) {
        curVersionLabel = `${curVersionLabel} - ${curVersion.stage}`.toUpperCase();
      }
    }

    return [curVersion, curVersionLabel];
  }, [t, versionId, versions]);

  const schema = useMemo(
    () =>
      currentVersion && currentVersion.settingsSchema
        ? JSON.parse(currentVersion.settingsSchema)
        : {},
    [currentVersion],
  );
  const metaSchema = useMemo(
    () =>
      currentVersion && currentVersion.metaSchema
        ? JSON.parse(currentVersion.metaSchema)
        : {},
    [currentVersion],
  );

  // Auto-select latest version if no version is specified for some reason
  useEffect(() => {
    if (versionId) return;
    if (!latest) return;

    onChangeVersion(moduleId, latest.id);
  }, [versionId, latest, onChangeVersion, moduleId]);


  const fixedMetaSchema = useMemo(() => {
    try {
      return { ...metaSchema, 'ui:field': 'main' };
    } catch (e) {
      return metaSchema;
    }
  }, [metaSchema]);

  const fixedSchema = useMemo(() => {
    try {
      return { ...schema, 'title': '' };
    } catch (e) {
      return schema;
    }
  }, [schema]);


  if (!module) {
    const deleteIcon = (
      <Right>
        <Popconfirm
          title={t('areYouSureYouWantToDelete')}
          onConfirm={() => onDelete(moduleId)}
          okText={t('yes')}
          cancelText={t('no')}
        >
          <Button style={{ float: 'right' }} size="small" icon="delete" type="link" />
        </Popconfirm>
      </Right>
    );

    return (
      <Alert
        message={
          <>
            {t('moduleNotFound', { module: moduleId })}
            {deleteIcon}
          </>
        }
        type="warning"
        showIcon
      />
    );
  }

  const canDelete = module.moduleName !== 'agent';
  const hasSchema = schema && schema.properties && Object.keys(schema.properties).length;

  return (
    <>
      <Columns>
        <FlexColumn>
          <ModuleTitle level={3}>
            {status.status !== '' && (
              <Popover content={status.status}>
                <StatusBadge status={status.status === 'running' ? 'success' : 'error'} />
              </Popover>
            )}
            <Popover content={`${module.moduleName}@${status.version}`}>
              {module.displayName}
            </Popover>
          </ModuleTitle>
        </FlexColumn>
        {latest && latest !== currentVersion && (
          <Popover content={t('updateToLatest')}>
            <Button
              type="dashed"
              size="small"
              shape="round"
              onClick={() => onChangeVersion(moduleId, latest.id)}
            >
              {t('updateAvailable')}
            </Button>
          </Popover>
        )}
        {latest && (
          <Dropdown overlay={menu} trigger={['click']}>
            <Button type="link">
              {currentVersionLabel}
              <Icon type="down" />
            </Button>
          </Dropdown>
        )}
        {canDelete && (
          <Popconfirm
            title={t('areYouSureYouWantToDelete')}
            onConfirm={() => onDelete(moduleId)}
            okText={t('yes')}
            cancelText={t('no')}
          >
            <Button type="link" icon="delete" />
          </Popconfirm>
        )}
      </Columns>
      {hasSchema && (
        <div style={{ padding: '0 8px' }}>
          <SchemaForm
            schema={fixedSchema}
            uiSchema={fixedMetaSchema || {}}
            data={settings}
            onChange={(changes) => onChange(module.id, changes)}
            formContext={{
              organisationId,
            }}
          />
        </div>
      )}
      {!currentVersion && (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}
    </>
  );
};

const SpinnerContainer = styled.div`
  text-align: center;
  margin: 32px;
`;

const ModuleTitle = styled(Typography.Title)`
  padding: 8px;
`;

const Columns = styled.div`
  display: flex;
  align-items: center;
`;

const FlexColumn = styled.div`
  flex: 1;
`;

const Right = styled.span`
  float: right;
`;

const StatusBadge = styled(Badge)`
  .ant-badge-status-dot {
    width: 8px;
    height: 8px;
    margin-bottom: 6px;
  }
`;

export default ModuleSettings;
