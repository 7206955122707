import { useMemo, useState, useEffect } from 'react';
import sortBy from 'lodash/sortBy';
import keyBy from 'lodash/keyBy';
import OrganisationSpace from '../store/types/organisation-space';
import OrganisationSpaceTypeEnum from '../store/types/organisation-space-type.enum';
import { TreeData } from '../app/common/tree/tree.component';

export interface NestedSpace {
  space: OrganisationSpace;
  children: NestedSpace[];
}

export const useGroupedSpaces = ({ spaces }: { spaces: OrganisationSpace[] }) => {
  const groupedSpaces = useMemo(() => {
    const parentSpaces = spaces.filter((space) => !space.parentSpaceId);
    const childSpaces = spaces.filter((space) => space.parentSpaceId);
    
    const getNestedSpaces = (space: OrganisationSpace): NestedSpace => {
      const curChildren = childSpaces.filter(cSpace => cSpace.parentSpaceId === space.id);
      
      return {
        space,
        children: curChildren.map(getNestedSpaces),
      }
    }
    
    const nestedSpaces = parentSpaces.map(space => getNestedSpaces(space));
    return sortBy(nestedSpaces, (space) => space.space.displayName);
  }, [spaces]);

  return groupedSpaces;
};

const getSpaceIconProps = (spaceType: OrganisationSpaceTypeEnum) => {
  switch (spaceType) {
    case OrganisationSpaceTypeEnum.LOCATION:
      return { color: '#ffffff', icon: 'environment' };
    case OrganisationSpaceTypeEnum.COUNTRY:
      return { color: '#ffffff', icon: 'global' };
    case OrganisationSpaceTypeEnum.REGION:
      return { color: '#ffffff', icon: 'table' };
    case OrganisationSpaceTypeEnum.CITY:
      return { color: '#ffffff', icon: 'appstore' };
    case OrganisationSpaceTypeEnum.FLOOR:
      return { color: '#ffffff', icon: 'border' };
    case OrganisationSpaceTypeEnum.SECTION:
      return { color: '#ffffff', icon: 'box-plot' };
    default:
      return { color: '#ffffff', icon: 'tool' };
  }
}

export const useGroupedSpacesTreeProps = ({ spaces }: { spaces: OrganisationSpace[] }) => {
  const groupedSpaces = useMemo(() => {
    const parentSpaces = spaces.filter((space) => !space.parentSpaceId);
    const childSpaces = spaces.filter((space) => space.parentSpaceId);
    
    const getNestedSpaces = (space: OrganisationSpace): TreeData => {
      const curChildren = childSpaces.filter(cSpace => cSpace.parentSpaceId === space.id);
      
      return {
        data: {
          title: space.displayName,
          key: space.id,
          ...getSpaceIconProps(space.type),
        },
        children: curChildren.map(getNestedSpaces),
      }
    }
    
    const nestedSpaces = parentSpaces.map(space => getNestedSpaces(space));
    return sortBy(nestedSpaces, (space) => space.data.title);
  }, [spaces]);

  return groupedSpaces;
};

export const updateSpacesDisplayNameWithParentPath = (spaces: OrganisationSpace[]): OrganisationSpace[] => {
  const idToSpaceMap = new Map(spaces.map(space => [space.id, space]));

  const updateDisplayName = (space: OrganisationSpace): OrganisationSpace => {
    let parentPath = '';
    let currentSpace = space;

    while (currentSpace.parentSpaceId) {
      const parentSpace = idToSpaceMap.get(currentSpace.parentSpaceId);
      if (!parentSpace) break;
      parentPath = parentSpace.displayName + (parentPath ? ' > ' : '') + parentPath;
      currentSpace = parentSpace;
    }

    let title = space.displayName;
  
    if (parentPath) {
      title = `${parentPath} > ${space.displayName}`;
    }

    if (space.externalId) {
      title = `${title} [${space.externalId}]`;
    }

    return { ...space, displayName: title };
  };

  return spaces.map(space => updateDisplayName(space))
    .sort((a, b) => a.displayName.localeCompare(b.displayName));
};

export const useSpacesWithParentPath = (spaces: OrganisationSpace[]) => {
  const [spacesWithPath, setSpacesWithPath] = useState<OrganisationSpace[]>([]);

  useEffect(() => {
    if (!spaces.length) {
      setSpacesWithPath([]);
      return;
    }

    const idToSpaceMap = keyBy(spaces, 'id');
    const updateDisplayName = (space: OrganisationSpace): OrganisationSpace => {
      let parentPath = '';
      let currentSpace = space;

      while (currentSpace.parentSpaceId) {
        const parentSpace = idToSpaceMap[currentSpace.parentSpaceId];
        if (!parentSpace) break;
        parentPath = parentSpace.displayName + (parentPath ? ' > ' : '') + parentPath;
        currentSpace = parentSpace;
      }

      let title = space.displayName;

      if (parentPath) {
        title = `${parentPath} > ${space.displayName}`;
      }

      if (space.externalId) {
        title = `${title} [${space.externalId}]`;
      }

      return { ...space, displayName: title };
    };

    const calculateSpacesPathNonBlockingAsync = async() => {
      setSpacesWithPath([]);
      const updatedSpaces = await Promise.all(spaces.map(space => 
        new Promise<OrganisationSpace>(resolve => {
          setTimeout(() => resolve(updateDisplayName(space)), 0);
        })
      ));

      setSpacesWithPath(updatedSpaces.sort((a, b) => a.displayName.localeCompare(b.displayName)));
    }

    calculateSpacesPathNonBlockingAsync();
  }, [spaces]);

  return spacesWithPath;
};

export default {
  useGroupedSpaces,
  useGroupedSpacesTreeProps,
  updateSpacesDisplayNameWithParentPath,
};
