import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import PhyhubDevicesContainer from '../../../common/phyhub-devices/phyhub-devices.container';
import usePhyhubDeviceFilters from '../../../common/phyhub-devices/phyhub-devices-search-and-sort/hooks/use-phyhub-device-filters';
import usePhyhubDeviceSortOptions from '../../../common/phyhub-devices/phyhub-devices-search-and-sort/hooks/use-phyhub-device-sort-options';

interface OrganisationPhyhubDevicesContainerProps
  extends RouteComponentProps<{ organisationId: string }> {}

const OrganisationPhyhubDevicesContainer: FC<OrganisationPhyhubDevicesContainerProps> = (
  props,
) => {
  const {
    match: {
      // TODO: Rename organisationId to organizationId to match the API
      params: { organisationId },
    },
  } = props;

  // TODO: Implement installations and spaces requesting from the API
  const { filterOptions } = usePhyhubDeviceFilters({ installations: [], spaces: [] });

  const { sortCriteriaOptions } = usePhyhubDeviceSortOptions();

  return (
    <PhyhubDevicesContainer
      tenantId={organisationId}
      filterOptions={filterOptions}
      sortCriteriaOptions={sortCriteriaOptions}
    />
  );
};

export default OrganisationPhyhubDevicesContainer;
