import { useQuery } from 'react-query';
import { useApiService } from '../../../app/common/api-service-provider';
import channelsQueryKeys from './channels-query-keys';
import { Channel } from '../../types/channel';

const useChannelById = (tenantId: string, channelId: string) => {
  const apiService = useApiService();

  return useQuery(
    channelsQueryKeys.getChannel(channelId),
    () => apiService.get<Channel>(`/api/content-channel/${channelId}`, { tenantId }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export default useChannelById;
